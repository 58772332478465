import React, { useState } from 'react';
import { ReactSpreadsheetImport } from 'react-spreadsheet-import';
import { Container } from 'src/components/IMUI';
import { defaultGrantFields } from '../utils/grant_fields';
import AutoFormatter from '../utils/autoformatter';
import importerTheme from '../utils/importerTheme';
import importerTranslations from '../utils/importerTranslations';
import uniq from 'ramda/src/uniq';
import { titleize, toSnakeCase } from 'src/utils/string';

const GrantImporter = ({ autoFormatConfig, isOpen, onClose, onSubmit }) => {
  const defaultFields = defaultGrantFields();
  const [fields, setFields] = useState(defaultFields);
  const [isNavigationEnabled, setIsNavigationEnabled] = useState(true);

  const onSelectHeaderHook = async (headerValues, data) => {
    const fieldNames = defaultFields.map((field) => field.label);

    const customFieldNames = headerValues.filter(
      (headerValue) => !fieldNames.includes(headerValue)
    );
    const customFields = customFieldNames.map((customFieldName) => {
      // Replace underscores with spaces
      const noUnderScores = customFieldName.replace(/_/g, ' ');

      // Remove 'custom' (case insensitive) at the start of the field name
      const formattedCustomFieldName = noUnderScores
        .replace(/^(custom)/i, '')
        .trim();

      return {
        label: `CUSTOM: ${titleize(formattedCustomFieldName)}`,
        alternateMatches: [],
        key: `CUSTOM ${titleize(formattedCustomFieldName)}`,
        fieldType: { type: 'input' },
      };
    });

    setFields([...fields, ...customFields]);

    return {
      headerValues,
      data,
    };
  };

  const onMatchColumnsStepHook = (table, _rawData, columns) => {
    const requiredFields = defaultFields.filter((field) => {
      return field?.validations?.find(
        (validation) => validation.rule === 'required'
      );
    });
    const newFields = columns.map((column) => {
      return fields.find((field) => field.key === column.value);
    });

    const finalFields = uniq([...requiredFields, ...newFields]).filter(
      (field) => field !== undefined && field !== null
    );
    setFields(finalFields);
    setIsNavigationEnabled(false);

    const autoFormatter = new AutoFormatter(autoFormatConfig);
    const formattedData = autoFormatter.formatTable(table);

    /**
     * NOTE: Hack to re-trigger RSI native validation:
     * RSI native validation doesn't trigger for the first
     * time when fields are changed from this hook (onMatchColumnStepHook)
     * The hack here is to prepend a dummyRow to the data
     * then programatically discard it through
     * a timeout (click dummyRow checkbox, and discard)
     */
    const keysArray = finalFields.map((field) => field.key);
    const dummyObject = keysArray.reduce((dummy, key) => {
      dummy[key] = 'DUMMY';
      return dummy;
    }, {});
    formattedData.unshift(dummyObject);

    setTimeout(() => {
      const a = document.querySelectorAll('.chakra-checkbox__control');
      const dummyRowCheckbox = a[0];
      dummyRowCheckbox?.click();

      const buttons = document.querySelectorAll('.chakra-modal__body button');
      buttons.forEach((button) => {
        if (button.textContent.trim() === 'Discard selected rows') {
          button?.click();
        }
      });
    }, 300);

    return formattedData;
  };

  return (
    <Container>
      <ReactSpreadsheetImport
        selectHeaderStepHook={onSelectHeaderHook}
        matchColumnsStepHook={onMatchColumnsStepHook}
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={onSubmit}
        fields={fields}
        customTheme={importerTheme}
        translations={importerTranslations}
        isNavigationEnabled={isNavigationEnabled}
        autoMapSelectValues={true}
        autoMapDistance={9999}
      />
    </Container>
  );
};

export default GrantImporter;
