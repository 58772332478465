import classNames from 'classnames/bind';
import { T } from 'lioness';
import PropTypes from 'prop-types';
import React from 'react';

import { Toggle, Container } from 'src/components/IMUI';

import cls from './DownloadAsPdfWidgetContent.module.css';

const cx = classNames.bind(cls);

export default class DownloadAsPdfWidgetContent extends React.Component {
  static propTypes = {
    hideAnswers: PropTypes.bool,
    onToggleHideAnswers: PropTypes.func.isRequired,
  };

  render() {
    const { hideAnswers } = this.props;

    return (
      <Container horizontal className={cx('download-pdf-content-container')}>
        <Toggle
          toggled={!hideAnswers}
          onToggle={(ev, value) => this.props.onToggleHideAnswers(ev, !value)}
          compact
          label={<T>Include my answers and tags</T>}
          labelPosition="right"
        />
      </Container>
    );
  }
}
