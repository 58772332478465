import React from 'react';

import { splitFilterKey } from 'src/utils/queryParamFilters';

const getLastIndex = (map) =>
  Object.keys(map).length > 0 ? Number(Object.keys(map).pop()) : -1;

const withJsonapiSupport = (WrappedComponent) => {
  class FilterJsonapiSupport extends React.PureComponent {
    static propTypes = { ...WrappedComponent.propTypes };

    handleTransformOutput = (_filters) => {
      const filters = Array.isArray(_filters) ? _filters : [_filters];

      return filters.map(({ variable, matcher, result, condition }) => {
        const conditionFormatted = condition ? `_${condition}` : '';
        const filterKey = `${variable || ''}_${
          matcher || ''
        }${conditionFormatted}`;
        const transformKey = this.props.config?.find(
          (c) => c.name === variable && c.transformKeyBeforeRequest
        )?.transformKeyBeforeRequest;
        const transformVal = this.props.config?.find(
          (c) => c.name === variable && c.transformBeforeRequest
        )?.transformBeforeRequest;

        return {
          key: transformKey?.(filterKey) ?? filterKey,
          value: transformVal?.(result) ?? result,
        };
      });
    };

    handleTransformInput = (filters, config) => {
      return Object.keys(filters).reduce((acc, filterKey) => {
        const { variable, matcher, condition } = splitFilterKey(filterKey);
        const currentItem = config?.find(({ name }) => name === variable);
        if (!currentItem) {
          return acc;
        }

        const index = getLastIndex(acc) + 1;
        const results = Array.isArray(filters[filterKey])
          ? filters[filterKey]
          : [filters[filterKey]];
        return {
          ...acc,
          ...results.reduce(
            (resultAcc, result, resultIndex) => ({
              ...resultAcc,
              [index + resultIndex]: {
                variable: currentItem.transformVariable?.(variable) ?? variable,
                matcher: currentItem.transformMatcher?.(matcher) ?? matcher,
                condition:
                  currentItem.transformCondition?.(condition) ?? condition,
                type:
                  currentItem.transformType?.(currentItem.type) ??
                  currentItem.type,
                result: currentItem.transformResponse?.(result) ?? result,
              },
            }),
            {}
          ),
        };
      }, {});
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          onTransformInput={this.handleTransformInput}
          onTransformOutput={this.handleTransformOutput}
        />
      );
    }
  }

  return FilterJsonapiSupport;
};

export default withJsonapiSupport;
