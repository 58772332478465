import StandardSyncActions from '../actionCreators/StandardSyncActions';
import { isType } from '../helpers/actionTypes';

const standardSyncActionsReducer = (action, state, initialState) => {
  if (isType(action.type, StandardSyncActions.actionTypes.clear)) {
    return initialState;
  }

  return state;
};

export default standardSyncActionsReducer;
