// https://developers.intercom.com/docs/intercom-javascript // http://blog.learningspaces.io/customizing-the-intercom-launcher/#createacustomlauncher
/* global Intercom */
const UPDATE_INTERVAL = 90 * 1000; // intercom update is throttled to 20 updates / 30 min - max 1 update every 90s
const config = {
  app_id: 'xwb6e285',
  hide_default_launcher: true,
  custom_launcher_selector: '#intercom-launcher',
};
window.Intercom = window.Intercom || undefined;
(() => {
  if (typeof window.Intercom === 'function') {
    window.Intercom('reattach_activator');
    window.Intercom('update', config);
    return;
  }
  const i = function () {
    i.c(arguments);
  };
  i.q = [];
  i.c = function (args) {
    i.q.push(args);
  };
  window.Intercom = i;
  const load = () => {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = 'https://widget.intercom.io/widget/xwb6e285';
    const x = document.getElementsByTagName('script')[0];
    x?.parentNode?.insertBefore(s, x);
  };
  if (document.readyState === 'complete') load();
  window.attachEvent
    ? window.attachEvent('onload', load)
    : window.addEventListener('load', load, false);
})();

let updateIntervalRef;
const IS_ENABLED =
  !!Intercom &&
  process.env.NODE_ENV === 'production' &&
  process.env.IMPACTMAPPER_API_SERVER?.includes('//api-prod.impactmapper.com');
const IntercomService = {
  boot: (properties) => {
    IS_ENABLED && window.Intercom('boot', { ...config, ...properties });
    updateIntervalRef = setInterval(IntercomService.update, UPDATE_INTERVAL);
  },
  shutdown: () => {
    IS_ENABLED && window.Intercom('shutdown');
    updateIntervalRef = clearInterval(updateIntervalRef);
  },
  update: (userProps) => {
    IS_ENABLED && window.Intercom('update', userProps);
  },
  show: () => IS_ENABLED && window.Intercom('show'),
  onUnreadCountChange: (fn) =>
    IS_ENABLED && window.Intercom('onUnreadCountChange', fn),

  hide: () => IS_ENABLED && window.Intercom('hide'),
  showMessages: () => IS_ENABLED && window.Intercom('showMessages'),
  showNewMessage: (content) =>
    IS_ENABLED && window.Intercom('showNewMessage', content),
  onHide: (fn) => IS_ENABLED && window.Intercom('onHide', fn),
  onShow: (fn) => IS_ENABLED && window.Intercom('onShow', fn),
  trackEvent: (eventName, metadata) =>
    IS_ENABLED && window.Intercom('trackEvent', eventName, metadata),
  getVisitorId: () => IS_ENABLED && window.Intercom('getVisitorId'),
};

export default IntercomService;
