import { CUSTOM_ATTRIBUTE_NAMES } from '../entities/advancedFilters/AdvancedFilters';
import { actionStates } from '../entities/AsyncActionsGenerator';
import { isType } from '../helpers/actionTypes';

import {
  getIncreasedPending,
  getDecreasedPending,
} from './numericPendingHandlers';
import { handleRequest, handleSuccess, handleFailure } from './reducerHandlers';

const getUniqueValues = (values, all, { config, identifiable }) => {
  return values.reduce((acc, _el) => {
    if (!_el) return acc;

    const el =
      !config.included && typeof _el !== 'object' ? _el : _el[config.attr];

    if (!el || all.indexOf(el) > -1 || acc.indexOf(el) > -1) return acc;

    return acc.concat(!identifiable ? el : _el);
  }, []);
};

const getFilterAttrData = (data, attrName, config, meta = {}) =>
  data.reduce((acc, attributes) => {
    const key = CUSTOM_ATTRIBUTE_NAMES[config.attr] || config.attr;
    if (
      (config.included && !attributes[key]) ||
      (!config.included && !attributes[key])
    )
      return acc;

    const result = config.included ? attributes[config.type] : attributes[key];
    const resultSet = Array.isArray(result) ? result : [result];
    const extendedResultSet = !meta.identifiable
      ? resultSet
      : resultSet.map((res) => {
          if (config.included || typeof res === 'object')
            return { ...res, id: res.id };
          return { [config.attr]: res, id: attributes.id };
        });

    return acc.concat(
      getUniqueValues(extendedResultSet, acc, {
        config,
        identifiable: meta.identifiable,
      })
    );
  }, []);

const getAttrName = (actionType) => {
  const typeElements = (actionType || '').split('/');
  return typeElements.slice(-2, -1)[0];
};

const advancedFiltersReducer = (getAttrConfig, action, state) => {
  const filterAttr = getAttrName(action.type);

  if (isType(action.type, actionStates.request)) {
    const pending = getIncreasedPending(action, state);

    return {
      ...handleRequest(state),
      pending: { ...state.pending, ...pending },
    };
  }

  if (isType(action.type, actionStates.success)) {
    const newState = handleSuccess(action, state);

    const config = getAttrConfig(filterAttr);
    const pending = getDecreasedPending(action, state);

    return {
      ...state,
      ...newState,
      pending: { ...state.pending, ...pending },
      data: {
        ...state.data,
        [filterAttr]: getFilterAttrData(
          newState.data,
          filterAttr,
          config,
          action.meta
        ),
      },
      links: {
        ...state.links,
        [filterAttr]: newState.links,
      },
    };
  }

  if (isType(action.type, actionStates.failure)) {
    const pending = getDecreasedPending(action, state);

    return {
      ...handleFailure(action, state),
      pending: { ...state.pending, ...pending },
    };
  }

  return state;
};

export default advancedFiltersReducer;
