import { RadioButton } from 'material-ui/RadioButton';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Button,
  Container,
  TextField,
  PopoverLight,
  RadioButtonGroup,
} from 'src/components/IMUI';
import colors from 'src/css/constants.json';
import { getOrgText } from 'src/services/DictionaryService';
import { Icon } from 'im/ui/Icon';
import ColumnsSelector from './ColumnsSelector';
import cls from './MappingItemSelector.module.css';
import { isSameField } from './MappingItemEditable';

export default class MappingItemSelector extends React.Component {
  static propTypes = {
    open: PropTypes.bool,
    input: PropTypes.object,
    formColumns: PropTypes.object,
    selectedStatus: PropTypes.string,
    showColumnsMergeWarning: PropTypes.bool,
    mergeColumn: PropTypes.string,

    onSelectorOpen: PropTypes.func,
    onSelectorClose: PropTypes.func,

    onColumnFilter: PropTypes.func,
    onColumnSkip: PropTypes.func,
    onAddCustomField: PropTypes.func,
    onColumnsMerge: PropTypes.func,
    onColumnsMergeCancel: PropTypes.func,
    onColumnSelect: PropTypes.func,
  };

  static defaultProps = { formColumns: {} };
  state = {
    showAddCustomField: false,
    customField: '',
    customFieldHeaderName: 'grant_headers',
  };

  // Open custom field content when input has custom field mapped
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.open &&
      !this.props.open &&
      nextProps.input.value?.customField
    ) {
      this.setState({
        showAddCustomField: true,
        customFieldHeaderName: nextProps.input.value?.headerName,
        customField: nextProps.input.value?.customField,
      });
    }
  }

  onSelectorClose = () => {
    this.setState({
      showAddCustomField: false,
      customFieldHeaderName: 'grant_headers',
      customField: '',
    });
    this.props.onSelectorClose();
  };

  onGoBack = () => {
    this.setState({
      showAddCustomField: false,
      customFieldHeaderName: 'grant_headers',
      customField: '',
    });
    this.props.onColumnsMergeCancel();
  };

  onGoSelect = () => {
    this.setState({
      showAddCustomField: false,
      customFieldHeaderName: 'grant_headers',
      customField: '',
    });
    this.props.onSelectorOpen();
  };

  renderDropdownColumnIcon = (headerName, imColumn) => {
    const isTaken = Object.values(this.props.formColumns).some(
      (v) => v.column && isSameField(v, { headerName, imColumn })
    );
    const isCurrent = isSameField(this.props.input.value, {
      headerName,
      imColumn,
    });
    if (!isCurrent && !isTaken) return null;
    return (
      <Icon
        name="check"
        className={cls.mappingItemSelectorTick}
        color={isCurrent ? colors.green : colors['mid-grey']}
      />
    );
  };

  renderMenu = (searchText) => {
    return (
      <PopoverLight.Menu>
        {this.props
          .onColumnFilter(searchText)
          ?.map(({ headerName, imColumn, readableColumn, customField }) => (
            <PopoverLight.MenuItem
              key={`${headerName}_${imColumn}_${readableColumn}_${customField}`}
              value={{ headerName, imColumn, readableColumn, customField }}
              primaryText={getOrgText(readableColumn)}
              rightIcon={this.renderDropdownColumnIcon(headerName, imColumn)}
              onClick={(ev) =>
                this.props.onColumnSelect(ev, {
                  headerName,
                  imColumn,
                  readableColumn,
                  customField,
                })
              }
            />
          ))}
      </PopoverLight.Menu>
    );
  };

  renderColumnsMergeWarning = () => {
    return (
      <PopoverLight.Content>
        <p>
          Mapping this field will merge data with other fields already mapped to
          &quot;{getOrgText(this.props.mergeColumn)}&quot;.
        </p>

        <PopoverLight.ContentActions>
          <Button secondary label="Merge" onClick={this.props.onColumnsMerge} />
          <Button negative label="Cancel" onClick={this.onGoBack} />
        </PopoverLight.ContentActions>
      </PopoverLight.Content>
    );
  };

  renderAddCustomField = () => {
    const onCustomFieldHeaderNameChange = (ev, customFieldHeaderName) => {
      ev?.preventDefault();
      this.setState({ customFieldHeaderName });
    };
    const onCustomFieldChange = (customField) => {
      this.setState({ customField });
    };
    const onAddCustomField = () => {
      this.props.onAddCustomField(
        this.state.customFieldHeaderName,
        this.state.customField
      );
      this.onSelectorClose();
    };

    return (
      <PopoverLight.Content>
        <Container style={{ width: 250 }}>
          <div className={cls.mappingItemSelectorRadios}>
            <RadioButtonGroup
              name="header_type"
              valueSelected={this.state.customFieldHeaderName}
              onChange={onCustomFieldHeaderNameChange}
            >
              <RadioButton
                style={{
                  width: 'auto',
                  display: 'inline-block',
                  marginRight: 24,
                }}
                value="grant_headers"
                label={getOrgText('Grant')}
              />
              <RadioButton
                style={{ width: 'auto', display: 'inline-block' }}
                value="grantee_headers"
                label={getOrgText('Grantee')}
              />
            </RadioButtonGroup>
          </div>

          <TextField
            noValidation
            fullWidth
            flat
            border
            hintText="Type in..."
            name="custom_field"
            value={this.state.customField}
            onChange={onCustomFieldChange}
          />
        </Container>

        <PopoverLight.ContentActions>
          <Button
            secondary
            label="Add"
            disabled={
              !this.state.customField || !this.state.customFieldHeaderName
            }
            onClick={onAddCustomField}
          />
          <Button negative label="Cancel" onClick={this.onGoBack} />
          <Button negative label="Select" onClick={this.onGoSelect} />
        </PopoverLight.ContentActions>
      </PopoverLight.Content>
    );
  };

  renderHeader = () => {
    const { showColumnsMergeWarning } = this.props;
    const { showAddCustomField } = this.state;

    if (showColumnsMergeWarning) {
      return <span>Merge fields</span>;
    }
    if (showAddCustomField) {
      return <span>Adding custom field</span>;
    }

    return null;
  };

  renderFooter = () => {
    const { showAddCustomField } = this.state;
    const { showColumnsMergeWarning } = this.props;
    const footerHidden = showColumnsMergeWarning || showAddCustomField;
    const onAddCustomFieldRequest = () =>
      this.setState({ showAddCustomField: true });
    const onColumnSkip = () => {
      this.props.onColumnSkip();
      this.onSelectorClose();
    };

    return footerHidden ? null : (
      <PopoverLight.Actions>
        <PopoverLight.ActionItem
          iconName="add-element"
          text="Add a custom field"
          onClick={onAddCustomFieldRequest}
        />
        <PopoverLight.ActionItem
          iconName="skip"
          text="Skip this field"
          onClick={onColumnSkip}
        />
      </PopoverLight.Actions>
    );
  };

  renderColumnSelectorContent = (searchText) => {
    if (this.props.showColumnsMergeWarning) {
      return this.renderColumnsMergeWarning();
    }
    if (this.state.showAddCustomField) {
      return this.renderAddCustomField();
    }

    return this.renderMenu(searchText);
  };

  render() {
    const { input, selectedStatus, open } = this.props;
    return (
      <ColumnsSelector
        search
        open={open}
        value={input.value?.column || ''}
        status={selectedStatus}
        renderHeader={this.renderHeader}
        renderFooter={this.renderFooter}
        renderChildren={this.renderColumnSelectorContent}
        onSelectorOpen={this.props.onSelectorOpen}
        onRequestClose={this.onSelectorClose}
        onGoBack={this.onGoBack}
      />
    );
  }
}
