import {
  REPORT__EXPAND_STRATEGY_WORD,
  REPORT__EXPAND_STRATEGY_SENTENCE,
  REPORT__EXPAND_STRATEGY_PARAGRAPH,
} from 'src/constants';

function expand(text, start, delimiters, offset) {
  let pointer = start;
  while (
    pointer + offset >= 0 &&
    pointer < text.length &&
    !delimiters.includes(text[pointer])
  ) {
    pointer += offset;
  }

  return pointer;
}

function expandLeft(text, start, delimiters) {
  const expandedStart = expand(text, start, delimiters, -1);
  const firstAlphanumeric = text.slice(expandedStart).match(/\w+/);
  const firstAlphanumericIndex = firstAlphanumeric
    ? firstAlphanumeric.index
    : 0;
  return expandedStart + firstAlphanumericIndex;
}

function expandRight(text, start, delimiters) {
  return expand(text, start, delimiters, 1);
}

export function expandToWord(text, startIndex, endIndex) {
  return {
    start: expandLeft(text, startIndex, [
      ' ',
      ',',
      '.',
      ':',
      ';',
      '\n',
      '!',
      '?',
      '\t',
      '<',
      '>',
    ]),
    end: expandRight(text, endIndex, [
      ' ',
      ',',
      '.',
      ':',
      ';',
      '\n',
      '!',
      '?',
      '>',
      '<',
    ]),
  };
}

export function expandToSentence(text, startIndex, endIndex) {
  return {
    start: expandLeft(text, startIndex, ['.', '\n', '!', '?', '>', '<']),
    end: expandRight(text, endIndex, ['.', ';', '\n', '!', '?', '>', '<']),
  };
}

export function expandToParagraph(text, startIndex, endIndex) {
  return {
    start: expandLeft(text, startIndex, ['\n']),
    end: expandRight(text, endIndex, ['\n']),
  };
}

export function noopStrategy(text, startIndex, endIndex) {
  return {
    start: startIndex,
    end: endIndex,
  };
}

export function applyExpansionStrategy(strategy, text, startIndex, endIndex) {
  switch (strategy) {
    case REPORT__EXPAND_STRATEGY_WORD:
      return expandToWord(text, startIndex, endIndex);
    case REPORT__EXPAND_STRATEGY_SENTENCE:
      return expandToSentence(text, startIndex, endIndex);
    case REPORT__EXPAND_STRATEGY_PARAGRAPH:
      return expandToParagraph(text, startIndex, endIndex);
    default:
      return noopStrategy(text, startIndex, endIndex);
  }
}
