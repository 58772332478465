import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  reduxForm,
  getFormSyncErrors,
  getFormValues,
  registerField,
  Form,
} from 'redux-form';

import tagCategoriesApi from 'src/api/TagCategories';
import tagCategoriesSelectorApi from 'src/api/TagCategoriesSelector';
import Warning from 'src/components/Warning/Warning';
import createValidator from 'src/utils/validation';

import { where } from 'im/api/Query';

import TagsDetailed from './TagsDetailed';
import TagsQuickAdd from './TagsQuickAdd';

import cls from './TagForm.module.css';
import TagCategorySelector from '../TagCategorySelector/TagCategorySelector';

const TAG_BACKEND_ERRORS = {
  code: {
    source: { pointer: '/data/attributes/code' },
    details: 'Shortcode has already been taken',
  },
  title: {
    source: { pointer: '/data/attributes/title' },
    details: 'Tag name already exists in the selected tag group',
  },
  category: {
    source: { pointer: '/data/relationships/tag' },
    details: 'Tag title already exists in this category',
  },
};
const TAG_CATEGORY_BACKEND_ERRORS = {
  title: {
    source: { pointer: '/data/attributes/title' },
    details: 'Title has already been taken',
  },
  code: {
    source: { pointer: '/data/attributes/code' },
    details: 'Shortcode has already been taken',
  },
  category: {
    source: { pointer: '/data/relationships/tag' },
    details: 'Tag title already exists in this category',
  },
};

function validate(values) {
  const not = (fn) => (v) => !fn(v);
  const isDefined = (v) => Boolean(v);
  const minLength = (n) => (v) => v.length >= n;
  const tagsErrors = [];

  const tagsValidator = createValidator({
    title: [[not(isDefined), () => 'Tag name is required']],
  });

  if (values && values.tags) {
    values.tags.forEach((tag, index) => {
      tagsErrors[index] = tagsValidator(tag);
    });
  }

  const errors = createValidator({
    category: [[not(isDefined), () => 'Tag group is required']],
    tagNames: [
      [(v) => not(minLength(1))(v || []), () => 'Tag name is required'],
    ],
  })(values);

  return {
    ...errors,
    tags: tagsErrors,
  };
}

@reduxForm({ validate })
@connect(
  (state, props) => ({
    project: state.project,
    organizationCurrent: state.organizationCurrent,
    tagCategories: state.tagCategoriesSelectorForm,
    tagCategoriesOfProject: state.projectTagCategories,
    projectTags: state.projectTags,
    tagCategoryTags: state.tagCategoryTags,
    formErrors: getFormSyncErrors(props.form)(state),
    formValues: getFormValues(props.form)(state),
  }),
  {
    createTagCategory: tagCategoriesApi.ofProject.create,
    getTagCategories: tagCategoriesSelectorApi.form.findAllPerProjectNested,
    registerFormField: registerField,
  }
)
export default class TagForm extends Component {
  static propTypes = {
    project: PropTypes.object,
    organizationCurrent: PropTypes.object,
    tagCategories: PropTypes.object,
    tagCategoriesOfProject: PropTypes.object,
    projectTags: PropTypes.object,
    tagCategoryTags: PropTypes.object,

    mode: PropTypes.oneOf(['quick', 'detailed', 'edit']),
    showTagErrors: PropTypes.bool,
    showTagCategoryTagsErrors: PropTypes.bool,
    formErrors: PropTypes.object,
    formValues: PropTypes.object,
    array: PropTypes.object,
    submitFailed: PropTypes.bool,
    submitSucceeded: PropTypes.bool,
    form: PropTypes.string,

    handleSubmit: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    touch: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    registerFormField: PropTypes.func.isRequired,
    onModeChange: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,

    createTagCategory: PropTypes.func.isRequired,
    getTagCategories: PropTypes.func.isRequired,

    onMergeStart: PropTypes.func,
  };

  static defaultProps = {
    formErrors: {},
    formValues: {},
  };

  state = {
    showLocalErrors: false,
  };

  componentDidMount() {
    const { project } = this.props;
    // Needed to have category initialized
    this.props.registerFormField(this.props.form, 'category', 'Field');
    if (!project.id || !(project.enabled_tag_categories || []).length) return;
    this.doRequestTagCategories(this.props.project.enabled_tag_categories);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.project.enabled_tag_categories &&
      nextProps.project.enabled_tag_categories.length &&
      nextProps.project.id !== this.props.project.id
    ) {
      this.doRequestTagCategories(nextProps.project.enabled_tag_categories);
    }
  }

  getLocalError = () => {
    const { tagCategoriesOfProject } = this.props;
    const { showLocalErrors } = this.state;
    const tagCategoryErrors =
      showLocalErrors && (tagCategoriesOfProject.errors || []);
    if (!tagCategoryErrors) return null;
    return this.getBackendErrors(tagCategoryErrors, TAG_CATEGORY_BACKEND_ERRORS)
      ?.title;
  };

  getBackendErrors(errors, errorsToText) {
    return Object.keys(errorsToText).reduce((acc, attrName) => {
      const error = errors.find(
        ({ source: { pointer } }) =>
          pointer === errorsToText[attrName].source.pointer
      );
      return !error
        ? acc
        : { ...acc, [attrName]: errorsToText[attrName].details };
    }, {});
  }

  doRequestTagCategories = (projectTagCategoryIds, searchText) => {
    const query = where({ projectTagCategoryIds })
      .fields('tag_category', 'title', 'tags', 'child_categories', 'parent')
      .filter('parent_id_null', true)
      .fields('tag', 'title')
      .sort('title');

    if (searchText) {
      query.filter('nested_categories_title_in_any', searchText);
    }

    this.props.getTagCategories(query);
  };

  handleAddTagCategory = async (title, metatags, parentCategory) => {
    const { organizationCurrent, project } = this.props;

    const query = where()
      .payload({
        metatags: metatags || [],
        title,
        relationships: [
          {
            relName: 'organization',
            type: 'organizations',
            id: organizationCurrent.data.id,
          },
          {
            relName: 'parent',
            type: 'tag_category',
            id: (parentCategory || {}).id,
          },
          { relName: 'project', type: 'projects', id: project.uid },
        ],
      })
      .actionMeta();

    const result = await this.props.createTagCategory(query);
    this.setState({ showLocalErrors: !result });

    return result ? result.data : result;
  };

  handleAddedTagCategoryInputs = async (title, metatags, parentCategory) => {
    const newTagCategory = await this.handleAddTagCategory(
      title,
      metatags,
      parentCategory
    );

    if (newTagCategory) {
      this.handleTagCategoryChange(newTagCategory);
      this.handleMetatagsChange(newTagCategory.metatags);
    }

    return newTagCategory;
  };

  handleTagCategoryChange = (tagCategory) => {
    this.props.change('category', tagCategory);
  };

  handleModeChange = (mode) => {
    this.props.reset();
    if (this.props.onModeChange) this.props.onModeChange(mode);
  };

  handleMetatagsChange = (metatags) => {
    this.props.change('metatags', metatags);
  };

  handleFieldsTouch = (...fieldNames) => {
    this.props.touch(...fieldNames);
  };

  handleAddItem = (newItem) => {
    this.props.array.push('tags', newItem);
  };

  handleEditItem = (index, counter, newItem) => {
    this.props.array.splice('tags', index, counter, newItem);
  };

  handleSubmit = (event) => {
    const { mode, formValues } = this.props;

    if (!formValues.tags && formValues.tagNames) {
      this.props.handleSubmit(this.handleValidSubmit)(event);
      return;
    }

    const dataClean = (formValues.tags || []).reduce(
      (acc, tag) =>
        !tag.title && !tag.description && !tag.code ? acc : acc.concat([tag]),
      []
    );

    if (
      mode !== 'detailed' ||
      (mode === 'detailed' &&
        (formValues.tags.length === 1 ||
          dataClean.length === formValues.tags.length))
    ) {
      this.props.handleSubmit(this.handleValidSubmit)(event);
      return;
    }

    this.props.change('tags', dataClean);
  };

  handleValidSubmit = async (data) => {
    const existingTagCategory = data.category.id
      ? data.category
      : await this.handleAddTagCategory(data.category, data.metatags);
    if (!existingTagCategory) return;
    this.props.onSubmit({ ...data, category: existingTagCategory });
  };

  renderBackendErrors() {
    const {
      projectTags,
      tagCategoryTags,
      submitSucceeded,
      showTagErrors,
      showTagCategoryTagsErrors,
    } = this.props;

    const tagErrors =
      (showTagErrors && (projectTags.errors || [])) ||
      (showTagCategoryTagsErrors && (tagCategoryTags.errors || []));

    if (!tagErrors.length || !submitSucceeded) return null;
    const parsedTagErrors = this.getBackendErrors(
      tagErrors,
      TAG_BACKEND_ERRORS
    );

    return (
      <Warning show compact mode="error" ignorable={false}>
        <ul className={cls.tagFormErrorList}>
          {Object.values(parsedTagErrors).map((err, index) => (
            <li key={`tag_${index}`}>{err}</li>
          ))}
        </ul>
      </Warning>
    );
  }

  handleTagCategorySelectorChange = (newCategory) => {
    this.handleTagCategoryChange(newCategory);
    this.handleMetatagsChange(newCategory?.metatags ?? []);
  };

  render() {
    const {
      formErrors,
      formValues,
      project,
      tagCategories,
      submitFailed,
      mode,
      onMergeStart,
    } = this.props;
    return (
      <Form onSubmit={this.handleSubmit} noValidate className={cls.tagForm}>
        {this.renderBackendErrors()}

        <div>
          <TagCategorySelector
            required
            mode={mode === 'quick' ? 'quick' : 'selector'}
            customValueEnabled={mode === 'quick'}
            enableCreate={mode === 'selector'}
            value={formValues.category}
            error={submitFailed ? formErrors.category : ''}
            inputLabel="Tag group"
            labelHint={`Select${
              mode === 'selector' ? ' or add a tag group' : ''
            }`}
            backendError={this.getLocalError()}
            allTagCategories={tagCategories.data}
            allMetatags={project.metatags}
            onAdd={this.handleAddedTagCategoryInputs}
            onChange={this.handleTagCategorySelectorChange}
            onSearchText={(text) =>
              this.doRequestTagCategories(project.enabled_tag_categories, text)
            }
          />
        </div>

        {mode === 'quick' ? (
          <TagsQuickAdd
            mode={mode}
            onModeChange={() => this.handleModeChange('detailed')}
          />
        ) : (
          <TagsDetailed
            mode={mode}
            tags={formValues.tags}
            errors={formErrors.tags}
            onAddItem={this.handleAddItem}
            onEditItem={this.handleEditItem}
            onTouchFields={this.handleFieldsTouch}
            onModeChange={() => this.handleModeChange('quick')}
            onMergeStart={onMergeStart}
          />
        )}
      </Form>
    );
  }
}
