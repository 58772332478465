import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import UserOrgDataSourceFluxx from '../components/UserOrgDataSourceFluxx';
import UserOrgDataSourceTrint from '../components/UserOrgDataSourceTrint';
import { Container, Section } from 'src/components/IMUI';
import cls from './UserOrgEditDataSources.module.css';
import { submit } from 'redux-form';

import ReactRouterPropTypes from 'react-router-prop-types';
import { where } from 'im/api/Query';

import { fromFormDataToApi, fromApiToFormData } from '../components/utils';

import organizationApi from 'src/api/Organization';

import { canImportFromTrint } from 'src/userStorage';

@connect(
  (state) => ({
    user: state.user,
    organization: state.organization,
  }),
  {
    submit,
    putOrganization: organizationApi.put,
  }
)
export default class UserOrgEditDataSources extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.getFormInitValues(props.organization),
    };
  }

  static propTypes = {
    submit: PropTypes.func.isRequired,
    putOrganization: PropTypes.func.isRequired,
    organization: PropTypes.object,
  };

  static defaultProps = {
    user: {},
    organization: {},
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.organization !== this.props.organization) {
      this.setState(this.getFormInitValues(nextProps.organization.data));
    }
  }

  onSubmitOrganization = async ({ ...data }) => {
    const formattedData = fromFormDataToApi(data);

    const organization = this.props.organization.data;

    const query = where({ id: organization.id }).payload({
      ...formattedData,
      id: organization.id,
    });
    this.props.putOrganization(query);
  };

  getFormInitValues(organization) {
    return {
      initialValues: fromApiToFormData(organization),
    };
  }

  render() {
    const { initialValues } = this.state;

    return (
      <div>
        <Section collapsed>
          <Container>
            <Container className={cls.dataSourcesContainer}>
              <h3 className={cls.dataSourcesHeader}>
                Sync with external data sources
              </h3>
            </Container>
            <Container className={cls.dataSourcesContainer}>
              <UserOrgDataSourceFluxx
                submit={this.props.submit}
                putOrganization={this.props.putOrganization}
                initialValues={initialValues}
                onSubmit={this.onSubmitOrganization}
              />
              {canImportFromTrint() && (
                <UserOrgDataSourceTrint
                  submit={this.props.submit}
                  initialValues={initialValues}
                  onSubmit={this.onSubmitOrganization}
                />
              )}
            </Container>
          </Container>
        </Section>
      </div>
    );
  }
}
