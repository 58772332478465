import React from 'react';
import { Button } from 'src/components/IMUI';
import cls from './TipInfo.module.css';
const GRANTS_TEMPLATE_URL =
  'https://s3.amazonaws.com/im-public/CSV_Templates/GrantsTemplate.xlsx';
const CSV_IMPORTER_GUIDE_URL =
  'https://help.impactmapper.com/en/articles/9214376-how-do-i-import-grantee-grant-and-funding-data';

const TipInfo = () => {
  return (
    <div>
      <p className={cls.subTitle}>
        <strong>Important: </strong>Make sure to prepare your data in a
        compatible CSV format according to this&nbsp;
        <Button
          text
          underlined
          target="_blank"
          className={cls.templateButton}
          href={GRANTS_TEMPLATE_URL}
          label="Grant Template"
        />
        .
        <br />
        You may also follow this&nbsp;
        <Button
          text
          underlined
          target="_blank"
          className={cls.templateButton}
          href={CSV_IMPORTER_GUIDE_URL}
          label="CSV Importing Guide"
        />{' '}
        to ensure a seamless importing process.
      </p>
      <p className={cls.subTitle}>
        The ImpactMapper CSV importer provides the following features:
      </p>
    </div>
  );
};
export default TipInfo;
