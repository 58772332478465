import ReactGA from 'react-ga4';

const GOOGLE_UA = {
  production: 'G-2LEH3QMHNY',
  staging: 'G-VD25ESY79K',
  development: 'G-GR24WWZNWV',
  demo: 'G-GR24WWZNWV',
};
const getTrackingEnv = () => {
  if (window.location.hostname.includes('staging')) return 'staging';
  if (window.location.hostname.includes('demo')) return 'demo';
  return ['127.0.0.1', 'localhost'].includes(window.location.hostname)
    ? 'development'
    : 'production';
};
const shouldStart = ['demo', 'production', 'staging'].includes(
  getTrackingEnv()
);

if (shouldStart) {
  ReactGA.initialize(GOOGLE_UA[getTrackingEnv()], {
    debug: false,
    titleCase: false,
    testMode: process.env.NODE_ENV !== 'production',
    redactEmail: false,
  });
}

export default {
  pageview: (url) =>
    ReactGA.send({ hitType: 'pageview', page: url, title: document.title }),
  setUserId: (userId) => ReactGA.set({ userId }),
  event: (category, action, label, value) =>
    ReactGA.event({ category: String(category || ''), action, label, value }),
};
