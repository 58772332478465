import classNames from 'classnames/bind';
import React, { useMemo } from 'react';
import { Tooltip } from 'src/components/IMUI/Tooltip/Tooltip';
import { IconType } from './types';
import cls from './Icon.module.css';
const cx = classNames.bind(cls);

interface IconProps {
  name?: IconType;
  error?: boolean;
  disabled?: boolean;
  color?: string;
  style?: React.CSSProperties;
  tip?: string;
  placeTip?: 'top' | 'right' | 'bottom' | 'left';
  className?: string;
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLSpanElement>;
}

let iconId = 0;

export const Icon = React.forwardRef<HTMLSpanElement, IconProps>(
  (
    {
      name,
      className,
      color,
      error,
      disabled,
      style,
      tip,
      onClick,
      placeTip,
      children,
    },
    ref
  ) => {
    const callback = disabled ? undefined : onClick;
    const cssStyles = useMemo(() => ({ color, ...style }), [color, style]);
    const tipId = `icon-${iconId++}`;
    return (
      <span
        ref={ref}
        className={cx(
          `icon-${name}`,
          'imui-icon',
          {
            'imui-icon-error': error,
            'imui-icon-disabled': disabled,
            'imui-icon-clickable': !!onClick,
          },
          className
        )}
        style={cssStyles}
        role={onClick ? 'button' : 'img'}
        onClick={callback}
        data-tip={!!tip}
        data-for={tipId}
      >
        {tip && (
          <Tooltip id={tipId} place={placeTip}>
            {tip}
          </Tooltip>
        )}
        {children}
      </span>
    );
  }
);

Icon.displayName = 'Icon';
