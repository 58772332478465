import { where } from 'im/api/Query';
import reducerRegistry from 'im/api/registry';
import ResourceCollection from 'im/api/ResourceCollection';

const apiConfig = {
  root: '/api/v3/tag_categories',
  type: 'tag_categories',
  api: {
    custom: {
      findAllPerProjectNested: {
        method: 'GET',
        path: where()
          .include('child_categories', 'tags')
          .filter('id_eq_any', ':tagCategoriesExpandedIds')
          .fields('tag_category', 'title', 'tags', 'child_categories', 'parent')
          .fields('tag', 'title', 'report_content_start', 'report_content_end')
          .fields('child_categories', 'title')
          .sort('title')
          .paginate({ size: 1000 })
          .toString(),
      },
    },
  },
};

export const TagCategoriesSelectorExpanded = new ResourceCollection(
  apiConfig,
  'tagCategoriesSelectorExpanded'
);
reducerRegistry.register(TagCategoriesSelectorExpanded);

export default TagCategoriesSelectorExpanded.api;
