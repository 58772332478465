import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Toggle } from 'src/components/IMUI';
import TagSelector from 'src/components/TagSelector/TagSelector';

import { Icon } from 'im/ui/Icon';

import BaseQuestionProperties from './BaseQuestionProperties';
import PropertyBox from './PropertyBox';

export default class DateQuestionProperties extends Component {
  static propTypes = {
    question: PropTypes.object,
    tagsWithTagGroups: PropTypes.array,
    onChangeQuestionProperty: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    isAnswerMode: PropTypes.bool,
  };

  static defaultProps = {
    errors: {},
    question: {},
  };

  static validate() {
    return {};
  }

  static iconName = 'file';

  handleMultiSelectToggle = () => {
    const { question, onChangeQuestionProperty } = this.props;
    question.multiTags = !question.multiTags;
    onChangeQuestionProperty(question.id, 'multiTags', question.multiTags);
  };

  handleTagsChanged = (tagIds) => {
    const { question, onChangeQuestionProperty } = this.props;
    question.tags = tagIds || [];
    onChangeQuestionProperty(question.id, 'tags', question.tags);
  };

  handleTagRemoved = (tagId) => {
    const { question, onChangeQuestionProperty } = this.props;
    const tagIndex = question.tags.indexOf(tagId);
    if (tagIndex !== -1) {
      const newTags = question.tags.filter((id) => id !== tagId);
      onChangeQuestionProperty(question.id, 'tags', newTags);
    }
  };

  render() {
    return (
      <BaseQuestionProperties {...this.props}>
        <h3>
          <Icon name="tag" />
          &nbsp;Tags
        </h3>

        <PropertyBox>
          <Toggle
            mini
            name="required"
            label="Allow selecting multiple tags"
            labelPosition="right"
            toggled={this.props.question.multiTags}
            onToggle={this.handleMultiSelectToggle}
          />
          <TagSelector
            isAnswerMode={this.props.isAnswerMode}
            label={false}
            multiple
            tagsWithTagGroups={this.props.tagsWithTagGroups}
            readOnly={this.props.readOnly}
            selected={this.props.question.tags}
            onChange={this.handleTagsChanged}
            onRemove={this.handleTagRemoved}
            noTagProps={{
              label: 'Add tag',
              grey: true,
              square: true,
              outline: false,
            }}
          />
        </PropertyBox>
      </BaseQuestionProperties>
    );
  }
}
