import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'im/ui/Icon';
import { Container } from '../index';
import FilterBreadcrumbItem from './FilterBreadcrumbItem';
import cls from './FilterBreadcrumbs.module.css';

export class FilterBreadcrumbs extends React.PureComponent {
  static propTypes = {
    filters: PropTypes.array,
    onAdd: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
    renderSelected: PropTypes.func,
  };

  static defaultProps = { filters: [], renderSelected: () => null };

  render() {
    if (!this.props.filters.length) {
      return null;
    }
    return (
      <Container horizontal className={cls.filterSelected}>
        {this.props.renderSelected()}
        <Icon
          name="filter"
          className={cls.filterSelectedIcon}
          onClick={this.props.onEdit}
        />

        {this.props.filters.map((filterItem) => (
          <FilterBreadcrumbItem
            key={`${filterItem.variable}-${filterItem.matcher}-${filterItem.result}`}
            variable={filterItem.variable}
            matcher={filterItem.matcher}
            result={filterItem.result}
            onEdit={this.props.onEdit}
            onClear={() => this.props.onClear(filterItem)}
          />
        ))}

        <Icon
          name="plus"
          onClick={this.props.onAdd}
          className={cls.filterSelectedIconAdd}
        />
      </Container>
    );
  }
}
