import React, { useCallback } from 'react';
import { v1 as uuidv1 } from 'uuid';

import {
  Actions,
  Button,
  Container,
  TextField,
  NumberField,
} from 'src/components/IMUI';
import { Error } from 'src/components/IMUI/Forms/base';
import TranslateButton from 'src/components/TranslateButton/TranslateButton';
import {
  FINANCIAL,
  MULTIPLE_CHOICE,
  NUMERICAL,
  SHORT_TEXT,
  SINGLE_CHOICE,
} from 'src/data/questionTypes';
import { getSurveyI18n } from 'src/utils/surveysI18n';

import { Icon } from 'im/ui/Icon';

import Option from './Option';

export const SingleMatrixQuestionPropertiesColumns = ({
  survey,
  readOnly,
  columns = [],
  question,
  completeness,
  errors,
  onUpdateI18n,
  onUpdateColumns,
}) => {
  const onColumnTitleChange = useCallback(
    (index, value) =>
      onUpdateI18n(value, columns[index].id, [
        'settings',
        'columns',
        index,
        'title',
      ]),
    [columns, onUpdateI18n]
  );

  const onColumnValueChange = useCallback(
    (index, value) => {
      columns[index].value = value;
      onUpdateColumns([...columns]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columns, onUpdateI18n]
  );

  const onColumnAdd = useCallback(
    (tagId, title = '') => {
      columns.push({ title, tag_id: tagId, id: uuidv1() });
      onUpdateColumns([...columns]);
    },
    [columns, onUpdateColumns]
  );

  const onColumnDelete = useCallback(
    (index) => {
      columns.splice(index, 1);
      onUpdateColumns([...columns]);
    },
    [columns, onUpdateColumns]
  );

  const onColumnOrderChange = useCallback(
    (oldIndex, newIndex, relative = false) => {
      const elementToMove = columns[oldIndex];
      const targetIndex = relative ? oldIndex + newIndex : newIndex;
      columns.splice(oldIndex, 1);
      columns.splice(targetIndex, 0, elementToMove);
      onUpdateColumns([...columns]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columns]
  );

  return (
    <div>
      <h4 style={{ margin: '0 0 16px 0' }}>
        <Icon style={{ fontSize: 16 }} name="table" />
        &ensp;Column labels
      </h4>

      {columns.map((column = {}, index) => {
        const optionTitle = getSurveyI18n(survey, question, column.id, [
          'settings',
          'columns',
          index,
          'title',
        ]);
        const optionValue = question.settings?.columns?.[index]?.value;
        const OptionValueField =
          question.matrixQuestionType === SHORT_TEXT ? TextField : NumberField;

        return (
          <Option
            index={index}
            count={columns.length}
            onAddRow={() => onColumnAdd()}
            actionsLeft={() => (
              <TranslateButton
                owner={question}
                languages={survey.languages}
                error={!!completeness.options[column.id]?.title?.length}
                values={question.i18n}
                path={column.id}
                altPath={['settings', 'columns', index, 'title']}
                title="Translate column title"
                onChange={(val) => onColumnTitleChange(index, val)}
              />
            )}
            onOptionDelete={onColumnDelete}
            onOrderChange={onColumnOrderChange}
            key={`column-${column.id || index}`}
            spaceBetween
            alignToEnd
          >
            <Container horizontal>
              <TextField
                multiLine
                rowsMax={3}
                flatDark
                name={`column_title_${index}`}
                hintText={!optionTitle && 'Type in...'}
                value={optionTitle}
                onChange={(value) => onColumnTitleChange(index, value)}
                error={errors?.columns?.[index]?.title}
                disabled={readOnly}
                icon={
                  <span>
                    {question.matrixQuestionType === FINANCIAL && (
                      <span>&ensp;{question.currency || '$'}</span>
                    )}
                    {question.matrixQuestionType === NUMERICAL &&
                      question.matrixReachType === 'year' && <span />}
                    {question.matrixQuestionType === NUMERICAL &&
                      question.matrixReachType === 'unit' && <span />}
                    {/* {question.matrixQuestionType === NUMERICAL && */}
                    {/*   question.matrixReachType === 'percent' && ( */}
                    {/*     <span>&ensp;%</span> */}
                    {/*   )} */}
                  </span>
                }
              />
            </Container>
          </Option>
        );
      })}
      <Container horizontal>
        <Actions>
          {columns.length === 0 && (
            <Button
              action
              size="s"
              label="Add column"
              onClick={() => onColumnAdd()}
            />
          )}
        </Actions>
        {errors.noColumns && <Error error={errors.noColumns} />}
      </Container>
    </div>
  );
};
