import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'im/ui/Icon';
import {
  Button,
  Container,
  TextField,
  SelectField,
  Slider,
  Toggle,
  SelectPopoverLight,
  Drawer,
  Actions,
  Label,
} from 'src/components/IMUI';
import colors from 'src/css/constants.json';
import cls from './ChartDownloadMenu.module.css';
import classNames from 'classnames';
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import highchartsAnnotations from 'highcharts/modules/annotations';
import highchartsData from 'highcharts/modules/data';
import highchartsExporting from 'highcharts/modules/exporting';
import highchartsHeatmap from 'highcharts/modules/heatmap';
import highchartsOfflineExporting from 'highcharts/modules/offline-exporting';
import highchartsExportCSV from 'highcharts-export-csv';
import {
  CHART_LABELS,
  CHART_DEFAULTS,
  IMAGETYPES,
  OPTIONS,
} from 'src/components/Chart/chartUtils';
[
  highchartsMore,
  highchartsHeatmap,
  highchartsAnnotations,
  highchartsData,
  highchartsExportCSV,
  highchartsExporting,
  highchartsOfflineExporting,
].forEach((fn) => fn(Highcharts));
const cx = classNames.bind(cls);

export default class ChartDownloadMenu extends React.PureComponent {
  static propTypes = {
    showIcon: PropTypes.bool,
    disabled: PropTypes.bool,
    buttonSize: PropTypes.oneOf(['s', 'm', 'l']),
    className: PropTypes.string,
    options: PropTypes.array.isRequired,
    title: PropTypes.string,
    displayTitle: PropTypes.string,
  };
  static defaultProps = { showIcon: false, buttonSize: 'l', options: OPTIONS };
  constructor(props) {
    super(props);
    this.state = {
      imageDialogVisible: false,
      fileFormat: 'png',
      showTitle: false,
      isFixedWidth: true,
      exportFixedWidth: 1920,
      exportTitle: props.displayTitle || props.title || '',
      filename: props.title || '',
      imageScale: 1,
      fontScale: 1,
      aspectRatio: '16×9',
      exportTarget: 'reports',
      baseFontSize: 11,
    };
  }

  chartInstance() {
    return (
      window.highchart ??
      window.highmap ??
      Highcharts.charts?.filter(Boolean)?.findLast((x) => x)
    );
  }
  onChangeScale = (_, imageScale) => {
    if (this.state.imageScale == imageScale) return;
    this.setState({ imageScale });
    this.onDownloadImage(false);
  };
  onChangeFontScale = (_, fontScale) => {
    if (this.state.fontScale == fontScale) return;
    this.setState({ fontScale });
    this.onDownloadImage(false);
  };
  onAspectRatioChange = (selectedVal) => {
    if (this.state.aspectRatio == selectedVal.value) return;
    this.setState({ aspectRatio: selectedVal.value });
    this.onDownloadImage(false);
  };
  onFilenameChange = (filename) => {
    if (this.state.filename == filename) return;
    this.setState({ filename });
  };
  onExportTitleChange = (exportTitle) => {
    if (this.state.exportTitle == exportTitle) return;
    this.setState({ exportTitle });
  };
  onCloseMenu = () => {
    if (this.state.imageDialogVisible == false) return;
    this.setState({ imageDialogVisible: false });
  };
  onDownloadCSV = () => {
    const link = document.createElement('a');
    link.setAttribute(
      'href',
      encodeURI(`data:text/csv;charset=utf-8,${this.chartInstance()?.getCSV()}`)
    );
    link.setAttribute('download', 'data.csv');
    link.click();
    this.onCloseMenu();
  };
  onFileFormatChange = (fileFormat) => {
    if (this.state.fileFormat == fileFormat) return;
    return fileFormat == 'csv'
      ? this.onDownloadCSV()
      : this.setState({ fileFormat });
  };
  onOpenMenu = (e) => {
    e.preventDefault();
    this.setState({
      imageDialogVisible: true,
      filename:
        (this.state.filename || '').toLowerCase().replace(/\s+/gm, '_') ||
        'chart',
      exportTitle: this.state.exportTitle || 'Chart title',
    });
  };
  onExportFixedWidthChange = (_, exportFixedWidth) => {
    if (!(Number(exportFixedWidth) > 0 && Number.isFinite(exportFixedWidth)))
      return;
    if (this.state.exportFixedWidth == Number(exportFixedWidth)) return;
    this.setState({ exportFixedWidth: Number(exportFixedWidth) });
  };
  handleTargetChanged = (exportTarget) => {
    this.onExportFixedWidthChange(null, CHART_DEFAULTS[exportTarget].width);
    this.onChangeFontScale(null, CHART_DEFAULTS[exportTarget].fontScale);
    this.onAspectRatioChange(CHART_DEFAULTS[exportTarget].aspectRatio);
    this.onFileFormatChange(CHART_DEFAULTS[exportTarget]);
    this.setState({ exportTarget });
    this.onDownloadImage(false);
  };
  onDownloadImage = (doDownload) => {
    const chart = this.chartInstance();
    const showTitle = this.state.showTitle;
    const exportTitle = this.state.exportTitle;
    const targetWidth =
      this.state.isFixedWidth || CHART_DEFAULTS[this.state.exportTarget].width
        ? this.state.exportFixedWidth ||
          CHART_DEFAULTS[this.state.exportTarget].width
        : chart.chartWidth;
    const aspectRatioMod = (
      this.state.aspectRatio ||
      CHART_DEFAULTS[this.state.exportTarget].aspectRatio
    )
      .split('×')
      .reduce((a, b) => Number(a) / Number(b));
    const scaling =
      (this.state.fontScale ||
        CHART_DEFAULTS[this.state.exportTarget].fontScale) *
      (targetWidth > 1000 ? 0.51 : 1);
    const targetHeight = Math.round(targetWidth / aspectRatioMod);
    const baseFontSize = this.state.baseFontSize * this.state.fontScale;

    function onLoad() {
      const instance =
        window.highchart ||
        window.highmap ||
        Highcharts.charts?.filter(Boolean)?.findLast((x) => x);
      const isPie = /pie/.test(instance.options?.chart?.type);
      const isBar = instance.options?.chart?.type === 'bar';
      const hasTitle = showTitle && exportTitle;
      const sizeBase = { fontSize: `${baseFontSize}px` };
      const sizeMediumSmall = { fontSize: `${baseFontSize * 1.15}px` };
      const sizeMedium = { fontSize: `${baseFontSize * 1.3}px` };
      const sizeLarge = { fontSize: `${baseFontSize * 2.0}px` };
      const dataLabelsSize = isPie ? sizeMediumSmall : sizeBase;
      const baseMargin = baseFontSize * 2 * 3;
      const marginTop = hasTitle ? baseMargin : baseMargin * 0.33;
      const pieSize = instance.spacingBox.height * 0.5;
      instance.update({
        legend: {
          itemStyle: { fontSize: `${baseFontSize * 0.9}px` },
          symbolHeight: baseFontSize * 0.6,
          symbolPadding: 5 * scaling,
          margin: baseFontSize,
        },
        chart: { spacing: 0, margin: undefined, marginTop },
      });
      instance.update({
        title: {
          text: exportTitle,
          style: {
            display: hasTitle ? 'block' : 'none',
            ...(hasTitle ? sizeLarge : {}),
          },
        },
      });
      if (isPie) {
        instance.update({
          chart: { margin: [marginTop, 0, baseMargin / 2, 0] },
          plotOptions: {
            pie: {
              size: pieSize,
              minSize: pieSize,
              dataLabels: {
                style: { textOverflow: 'auto', width: targetWidth * 0.5 },
                distance: instance.spacingBox.height * 0.08,
                connectorWidth:
                  (instance.options?.plotOptions.pie.dataLabels
                    .connectorWidth || 1) *
                  scaling *
                  2,
                padding:
                  instance.options?.plotOptions.pie.dataLabels.padding *
                  scaling,
                x: 5 * scaling,
                y: -20 * scaling,
              },
            },
          },
        });
      }
      if (isBar) {
        instance.update({
          plotOptions: {
            bar: {
              dataLabels: {
                x: instance.options?.plotOptions.bar.dataLabels.x * scaling,
              },
            },
          },
        });
      }
      instance.colorAxis.forEach((item) =>
        item.update({ labels: { style: sizeBase } })
      );
      instance.xAxis.forEach((item) =>
        item.update({
          labels: {
            style: sizeBase,
            overflow: 'left',
            y: isBar
              ? undefined
              : Math.max(baseFontSize, baseFontSize * scaling),
          },
          title: { style: sizeMedium, overflow: 'left' },
        })
      );
      instance.yAxis.forEach((item) =>
        item.update({
          labels: { style: sizeBase, overflow: 'left' },
          title: {
            style: {
              fontSize: `${baseFontSize}px`,
              margin: (baseFontSize / 2) * scaling,
            },
            overflow: 'left',
          },
        })
      );
      instance.series.forEach((item) =>
        item.update({
          dataLabels: { style: { ...dataLabelsSize, textOutline: 'none' } },
          showInLegend: item.visible,
        })
      );
    }
    if (chart.options?.chart?.events)
      chart.options.chart.events = { load: onLoad };
    if (!doDownload) {
      onLoad();
      return;
    } //This updates changes in real time.
    try {
      onLoad();
      chart.exportChartLocal({
        filename: this.state.filename,
        fallbackToExportServer: false,
        type: IMAGETYPES[this.state.fileFormat] || Object.values(IMAGETYPES)[0],
        scale: this.state.imageScale,
        sourceWidth: targetWidth,
        sourceHeight: targetHeight,
      });
    } catch {
      chart.exportChart({
        filename: this.state.filename,
        fallbackToExportServer: false,
        type: IMAGETYPES[this.state.fileFormat] || Object.values(IMAGETYPES)[0],
        scale: this.state.imageScale,
        sourceWidth: targetWidth,
        sourceHeight: targetHeight,
      });
    }
  };

  renderExportTargetItems = () => (
    <SelectPopoverLight.Menu>
      {Object.keys(CHART_LABELS).map((key) => (
        <SelectPopoverLight.MenuItem
          key={key}
          leftIcon={
            <Icon
              name="check"
              color={
                this.state.exportTarget === key
                  ? colors.seafoam
                  : colors['light-grey']
              }
            />
          }
          primaryText={CHART_LABELS[key]}
          onClick={() => this.handleTargetChanged(key)}
        />
      ))}
    </SelectPopoverLight.Menu>
  );

  renderFooter = () => {
    const hasXLS = typeof this.chartInstance()?.downloadXLS == 'function';
    const hasCSV = typeof this.chartInstance()?.downloadCSV == 'function';
    const hasExport =
      typeof this.chartInstance()?.exportChartLocal == 'function';
    return (
      <div>
        <Container
          horizontal
          style={{ marginBottom: 16, marginLeft: 0, marginRight: 0 }}
        >
          <Actions>
            <Button size="l" label="Close" onClick={this.onCloseMenu} />
          </Actions>
          <Actions>
            {hasXLS && (
              <Button
                size="l"
                primary
                label="XLS"
                onClick={() => this.chartInstance()?.downloadXLS()}
              />
            )}
            {hasCSV && (
              <Button
                size="l"
                primary
                label="CSV"
                onClick={() => this.chartInstance()?.downloadCSV()}
              />
            )}
            {hasExport && (
              <Button
                size="l"
                primary
                label={`Download ${this.state.fileFormat}`}
                onClick={() => this.onDownloadImage(true)}
              />
            )}
          </Actions>
        </Container>
      </div>
    );
  };
  render() {
    const extension = String(
      this.state.fileFormat || this.props.options?.[0] || ''
    ).toLocaleLowerCase();
    return (
      <div className={cx('wrapper', this.props.className)}>
        {this.props.showIcon && (
          <Icon
            disabled={this.props.disabled}
            tip="Export Chart"
            name="download"
            onClick={this.onOpenMenu}
            className={cx('wrapper', this.props.className)}
          />
        )}
        {!this.props.showIcon && (
          <Button
            secondary
            disabled={this.props.disabled}
            size={this.props.buttonSize}
            onClick={this.onOpenMenu}
            label="Download"
          />
        )}
        {this.state.imageDialogVisible && (
          <Drawer
            openSecondary={false}
            containerClassName={cx('chart-drawer')}
            className={cx('chart-drawer-class')}
            closable
            docked={true}
            zIndex={1400}
            width={460}
            open={this.state.imageDialogVisible}
            onRequestClose={this.onCloseMenu}
            overlayStyle={{ padding: 20 }}
            renderFooter={this.renderFooter}
          >
            <section
              style={{ display: 'flex', flexDirection: 'column', gap: 12 }}
            >
              <TextField
                fullWidth
                name="filename"
                label={`Filename (.${extension})`}
                value={`${this.state.filename}`}
                onChange={this.onFilenameChange}
              />
              <Toggle
                mini
                label="Display title for export"
                labelPosition="right"
                toggled={this.state.showTitle}
                onToggle={(_ev, toggled) =>
                  this.setState({ showTitle: toggled })
                }
              />
              <TextField
                fullWidth
                disabled={!this.state.showTitle}
                name="exportTitle"
                value={this.state.exportTitle}
                onChange={this.onExportTitleChange}
              />

              <Label label="Export target style template" />
              <SelectPopoverLight
                label={CHART_LABELS[this.state.exportTarget]}
                popoverProps={{ zIndex: 1410 }}
                renderItems={this.renderExportTargetItems}
              />
              <br />

              <SelectField
                label="File format"
                value={this.state.fileFormat || this.props.options[0]}
                onChange={(e) => this.onFileFormatChange(e.value)}
              >
                {this.props.options.map((fileFormat) => (
                  <SelectField.Item
                    key={fileFormat}
                    value={fileFormat}
                    primaryText={fileFormat.toUpperCase()}
                  />
                ))}
              </SelectField>

              <SelectField
                label="Image aspect ratio"
                value={this.state.aspectRatio}
                onChange={this.onAspectRatioChange}
              >
                <SelectField.Item
                  value="16×9"
                  primaryText="Full HD"
                  secondaryText="16×9"
                />
                <SelectField.Item
                  value="16×7"
                  primaryText="Ultra Wide"
                  secondaryText="16×7"
                />
                <SelectField.Item
                  value="4×3"
                  primaryText="PPT/Photo"
                  secondaryText="4×3"
                />
                <SelectField.Item
                  value="1×1"
                  primaryText="Square"
                  secondaryText="1×1"
                />
                <SelectField.Item
                  value="3×4"
                  primaryText="↻ PPT/Photo"
                  secondaryText="3×4"
                />
                <SelectField.Item
                  value="9×16"
                  primaryText="↻ Full HD"
                  secondaryText="9×16"
                />
              </SelectField>

              <Toggle
                mini
                label={`Fixed target image width: ${this.state.exportFixedWidth} px`}
                labelPosition="right"
                toggled={this.state.isFixedWidth}
                onToggle={(_ev, toggled) =>
                  this.setState({ isFixedWidth: toggled })
                }
              />
              <Slider
                disabled={this.state.isFixedWidth}
                value={this.state.exportFixedWidth}
                onChange={this.onExportFixedWidthChange}
                min={800}
                max={4000}
                step={4}
              />
              <Slider
                value={this.state.fontScale}
                onChange={this.onChangeFontScale}
                label={
                  <div>
                    Font size scale: {this.state.fontScale} x{' '}
                    <small style={{ float: 'right', display: 'contents' }}>
                      (approx.{' '}
                      {Math.round(
                        this.state.baseFontSize * this.state.fontScale
                      )}
                      px)
                    </small>
                  </div>
                }
                min={0.5}
                max={3}
                step={0.1}
              />
              <Slider
                value={this.state.imageScale}
                onChange={this.onChangeScale}
                label={
                  <div>
                    Chart image scale: {this.state.imageScale} dpi{' '}
                    <small style={{ float: 'right', display: 'contents' }}>
                      (for improved resolution)
                    </small>
                  </div>
                }
                min={0.5}
                max={4}
                step={0.5}
              />
            </section>
          </Drawer>
        )}
      </div>
    );
  }
}
