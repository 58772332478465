import PropTypes from 'prop-types';
import React, { Component } from 'react';

import ExpandableHeader from './ExpandableHeader';
import ExpandableList from './ExpandableList';

export class Expandable extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    isExpanded: PropTypes.bool,
  };

  static Header = ExpandableHeader;
  static List = ExpandableList;

  constructor(props) {
    super(props);
    this.state = {
      isExpanded: props.isExpanded,
    };
  }

  onExpandToggle = () => {
    this.setState({ isExpanded: !this.state.isExpanded });
  };

  applyProps = (child) => {
    const { isExpanded } = this.state;
    const isHeader = child.type === (<ExpandableHeader />).type;
    const isList = child.type === (<ExpandableList />).type;

    if (!isHeader && !isList) {
      return child;
    }

    const props = {
      isExpanded,
      ...(isHeader && { onClick: this.onExpandToggle }),
    };

    return React.cloneElement(child, props);
  };

  render() {
    const { children, className } = this.props;

    return (
      <div className={className}>
        {React.Children.map(children, (child) => this.applyProps(child))}
      </div>
    );
  }
}
