import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import cls from './tables.module.css';
import { Link } from 'react-router-dom';
import { dark } from 'src/css/constants.json';
const cx = classNames.bind(cls);

export default class Cell extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
    style: PropTypes.object,
    className: PropTypes.string,
    header: PropTypes.bool,
    subHeader: PropTypes.bool,
    nowrap: PropTypes.bool,
    numerical: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    rowSpan: PropTypes.number,
    colSpan: PropTypes.number,
    vAlign: PropTypes.oneOf(['bottom', 'middle', 'top']),
    textAlign: PropTypes.oneOf(['left', 'right', 'center', 'justify']),
    text: PropTypes.node,
    label: PropTypes.node,
    actions: PropTypes.bool,
    icons: PropTypes.bool,
    dim: PropTypes.bool,
    onClick: PropTypes.func,
    tip: PropTypes.string,
    to: PropTypes.string,
  };

  render() {
    const {
      className,
      children,
      text,
      label,
      width,
      rowSpan,
      colSpan,
      nowrap,
      tip,
      style,
      vAlign,
      actions,
      onClick,
      textAlign,
      icons,
      dim,
      header,
      subHeader,
      numerical,
      to,
    } = this.props;
    const CellElement = header ? 'th' : 'td';
    let styles = style;
    if (width) {
      styles ||= {};
      styles.width = typeof width === 'number' ? `${width}px` : width;
    }
    const hasText = typeof text !== 'undefined';
    const onClickValid =
      hasText && to
        ? (e) => {
            e.stopPropagation();
          }
        : onClick;

    return (
      <CellElement
        onClick={onClickValid}
        rowSpan={rowSpan}
        colSpan={colSpan}
        style={styles}
        data-tip={tip}
        className={cx(
          header ? 'imui-table-header-cell' : 'imui-table-cell',
          {
            'imui-table-cell-actions': actions,
            'imui-table-cell-sub-header': subHeader,
            'imui-table-cell-nowrap': nowrap,
            'imui-table-cell-icons': icons,
            'imui-table-cell-numerical': numerical,
            'imui-table-cell-dim': dim,
            'imui-table-cell-clickable': !!onClick,
            [`imui-table-cell-vAlign-${vAlign}`]: vAlign,
            [`imui-table-cell-textAlign-${textAlign}`]: textAlign,
          },
          className
        )}
      >
        {hasText ? (
          to ? (
            <Link style={{ color: dark }} to={to}>
              <div>{text}</div>
            </Link>
          ) : (
            <div>{text}</div>
          )
        ) : (
          children
        )}
        {label && <small>{label}</small>}
      </CellElement>
    );
  }
}
