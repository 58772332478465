import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';

import Error from './Error';

export default class ErrorIndex extends Component {
  static propTypes = {
    match: ReactRouterPropTypes.match,
  };

  render() {
    const { match } = this.props;
    const rootPath = match.path;
    return (
      <Switch>
        <Route path={`${rootPath}/:errorCode?`} component={Error} />
        <Redirect from="*" to={rootPath} />
      </Switch>
    );
  }
}
