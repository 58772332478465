import {
  DICTIONARY__PROJECT_SET,
  DICTIONARY__PROJECT_CLEAR,
  DICTIONARY__ORGANIZATION_SET,
} from 'src/constants';

export const projectDictionarySet = (dictionary) => ({
  type: DICTIONARY__PROJECT_SET,
  payload: { dictionary },
});
export const projectDictionaryClear = () => ({
  type: DICTIONARY__PROJECT_CLEAR,
});

export const organizationDictionarySet = (dictionary) => ({
  type: DICTIONARY__ORGANIZATION_SET,
  payload: { dictionary },
});
