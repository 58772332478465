import React from 'react';
import classNames from 'classnames/bind';
import MUITextField from 'material-ui/TextField';
import PropTypes from 'prop-types';
import { InputReduxForm, InputSticky, Label, Error, ClearButton } from './base';
import cls from './TextField.module.css';
const cx = classNames.bind(cls);

export const INPUT_STYLE = { paddingLeft: 20, height: 42 };
const TEXTAREA_STYLE = {};
export class TextField extends React.PureComponent {
  static propTypes = {
    wrapperClassName: PropTypes.string,
    noValidation: PropTypes.bool,
    theme: PropTypes.object,
    label: PropTypes.node,
    labelClassName: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    flat: PropTypes.bool,
    flatDark: PropTypes.bool,
    flatWhite: PropTypes.bool,
    border: PropTypes.bool,
    borderDark: PropTypes.bool,
    clearable: PropTypes.bool,
    required: PropTypes.bool,
    icon: PropTypes.node,
    ...InputReduxForm.propTypes,
    ...InputSticky.propTypes,
  };

  static defaultProps = {
    type: 'text',
    underlineShow: false,
    noValidation: false,
    icon: null,
  };
  constructor(props) {
    super(props);
    this.state = { value: props.input?.value ?? props.value ?? '' };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ value: nextProps.input?.value || nextProps.value });
  }
  handleClear = () => {
    this.handleChange(null, '');
  };
  handleDragStart = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
  };
  handleChange = (ev, value) => {
    ev?.stopPropagation();
    ev?.preventDefault();
    this.setState({ value });
    InputReduxForm.onChange?.(this.props.input, this.props.onChange)?.(value);
  };

  /* IMPORTANT - don't use hintText with multiLine.
  Textarea's height with default value !== "" will be totally messed up */
  render() {
    const {
      children,
      className,
      wrapperClassName,
      noValidation,
      meta,
      input,
      label,
      stick,
      required,
      placeholder,
      flat,
      flatDark,
      flatWhite,
      error,
      border,
      borderDark,
      clearable,
      labelClassName,
      icon,
      disabled,
      ...muiTextField
    } = this.props;
    const inputStyle = this.props.multiLine ? TEXTAREA_STYLE : INPUT_STYLE;
    return (
      <div
        className={cx(
          { 'imui-input-wrapper-fullwidth': this.props.fullWidth },
          wrapperClassName
        )}
      >
        <Label label={label} className={labelClassName} required={required} />
        <MUITextField
          autoComplete="off"
          role="presentation"
          {...input}
          {...muiTextField}
          className={cx(
            'imui-text-field',
            {
              'imui-text-field-flat': flat,
              'imui-text-field-flatDark': flatDark,
              'imui-text-field-flatWhite': flatWhite,
              'imui-text-field-border': border,
              'imui-text-field-border-dark': borderDark,
              'imui-text-field-has-icon': icon,
              'imui-text-field-has-hint':
                !!this.props.hintText && !this.state.value?.length,
              [`imui-text-field-stick-${stick}`]: stick?.length > 0,
            },
            className
          )}
          inputStyle={{ ...inputStyle, ...(this.props.inputStyle || {}) }}
          onDragStart={this.handleDragStart}
          onChange={this.handleChange}
          value={this.state.value ?? ''}
          placeholder={this.state.value?.length > 0 ? undefined : placeholder}
          disabled={disabled}
        >
          {children}
        </MUITextField>
        <ClearButton
          visible={clearable && this.state.value != undefined}
          onClear={this.handleClear}
        />
        {!icon ? null : (
          <div className={cx('imui-text-field-custom-icon')}>{icon}</div>
        )}
        {!noValidation && <Error meta={meta} error={error} />}
      </div>
    );
  }
}

export default TextField;
