import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getFundings } from 'src/actionCreators/grantsActionCreators';
import {
  updateProject,
  deleteProject,
} from 'src/actionCreators/projectActionCreators';
import { confirm } from 'src/components/ConfirmModal/ConfirmModal';
import Page from 'src/components/Page/Page';
import {
  retrieveDataSets,
  retrieveReportSet,
  toApiFromCreateProject,
} from 'src/serializers/projectSerializer';
import { isGrantless } from 'src/utils/analysis';
import reportsApi from 'src/api/Reports';
import surveysListApi from 'src/api/SurveysList';
import { where } from 'im/api/Query';
import GrantSelectorForm from 'src/components/AddProjectForm/GrantSelectorForm';
import { canManageProject } from 'src/userStorage';
import { Progress } from 'src/components/IMUI';

const getInitialValues = ({
  name,
  grantee_type,
  description,
  project_reports,
  enabled_tag_categories,
  dictionary = {},
}) => ({
  name,
  description: description || '',
  enabled_tag_categories,
  project_reports: (project_reports || []).map((i) => ({
    grantee_id: i.grantee_id,
    grant_id: i.grant_id,
    report_id: i.report_id,
  })),
  grantee_type: grantee_type || 'organization',
  grant: dictionary.grant || '',
  grantee: dictionary.grantee || '',
});

const FORM_NAME = 'projectFormEdit';

class AnalysisEdit extends React.PureComponent {
  static propTypes = {
    updateProject: PropTypes.func.isRequired,
    getFundings: PropTypes.func.isRequired,
    deleteProject: PropTypes.func.isRequired,
    getReports: PropTypes.func.isRequired,
    surveysFindAll: PropTypes.func.isRequired,
    grants: PropTypes.object,
    match: PropTypes.object,
    project: PropTypes.object,
    reports: PropTypes.array,
    surveysList: PropTypes.array,
  };

  static defaultProps = { project: {}, grants: {} };
  state = { skipPrompt: false, updating: false };

  componentDidMount() {
    this.props.getFundings();
    this.props
      .getReports(
        where({ project_id: this.props.match.params.projectId })
          .include('project_reports')
          .fields('project_report', 'id', 'grant', 'grantee', 'report')
          .fields('grantee', 'id', 'name')
          .fields('grant', 'id', 'name')
          .fields(
            'report',
            'id',
            'uid',
            'name',
            'date',
            'report_type',
            'grant',
            'grantee',
            'code',
            'period',
            'project_reports',
            'crated_at',
            'enabled_tag_categories',
            'visible_tag_categories'
          )
          .paginate({ size: 9999 })
      )
      .then(() => this.forceUpdate());
    this.props
      .surveysFindAll(
        where()
          .fields(
            'survey',
            'anonymous',
            'batches',
            'created_at',
            'languages',
            'project',
            'public',
            'status',
            'title',
            'uid',
            'enabled_tag_categories',
            'visible_tag_categories'
          )
          .sort('-created_at')
          .paginate({ size: 9999 })
      )
      .then(() => this.forceUpdate());
  }
  handleProjectFormSubmit = (payload) => {
    canManageProject() && this.setState({ skipPrompt: true, updating: true });
    this.props
      .updateProject(
        this.props.match.params.projectId,
        toApiFromCreateProject(payload)
      )
      .then(() => this.setState({ updating: false }));
  };

  handleDeleteProject = () => {
    canManageProject() &&
      confirm(
        'Delete Project',
        'Do you really want to delete this project? This action cannot be reversed.',
        true
      )
        .then(() => this.setState({ skipPrompt: true, updating: true }))
        .then(() => this.props.deleteProject(this.props.match.params.projectId))
        .then(() => this.setState({}))
        .catch(() => void 0);
  };

  render() {
    const filterDataSetByType = (e) =>
      e?.grantee?.type == 'organization' ||
      e?.grantee?.type == 'individual' ||
      !e?.grantee?.type;
    const dataSet =
      retrieveDataSets(this.props.grants.items, this.props.reports).filter(
        filterDataSetByType
      ) || [];
    const reportSet =
      retrieveReportSet(this.props.grants.items, this.props.reports).filter(
        filterDataSetByType
      ) || [];

    return (
      <Page
        hasFooter
        helpAppcue="-LHsFKNQKAI8jIdNwI4O"
        title="Project Data"
        pending={this.props.grants.pending}
      >
        {(this.props.grants.pending == true || this.state.updating == true) && (
          <Progress large className="absolute" />
        )}
        {this.props.project.name ? (
          <GrantSelectorForm
            key={this.props.project.id}
            project_uid={this.props.project.uid}
            isEdit={true}
            grantee_type={this.props.project.grantee_type}
            skipPrompt={this.state.skipPrompt}
            onSubmit={this.handleProjectFormSubmit}
            onDeleteProject={this.handleDeleteProject}
            dataSet={dataSet}
            reportSet={reportSet}
            surveySet={this.props.surveysList}
            initial_project_surveys={this.props.project.surveys}
            initialValues={getInitialValues(this.props.project)}
            noGrantAndGrantee={isGrantless(this.props.project)}
            form={FORM_NAME}
            submitButtonLabel="Update"
          />
        ) : (
          <div />
        )}
      </Page>
    );
  }
}

export default connect(
  (state) => ({
    grants: state.grants,
    project: state.project,
    surveysList: state.surveysList.data || [],
    reports: state.reports.data || [],
  }),
  (dispatch) =>
    bindActionCreators(
      {
        updateProject,
        deleteProject,
        getFundings,
        surveysFindAll: surveysListApi.findAll,
        getReports: reportsApi.findAll,
      },
      dispatch
    )
)(AnalysisEdit);
