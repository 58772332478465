import React from 'react';
import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import { connect } from 'react-redux';
import organizationsApi from 'src/api/Organizations';
import userApi from 'src/api/User';
import HeaderSteps from 'src/components/HeaderSteps/HeaderSteps';
import {
  Button,
  Section,
  Container,
  Actions,
  TabSegments,
} from 'src/components/IMUI';
import Page from 'src/components/Page/Page';
import history from 'src/historyInstance';
import { where } from 'im/api/Query';
import { Icon } from 'im/ui/Icon';
import { canManageOrganization } from 'src/userStorage';
import OrganizationsList from './components/OrganizationsList';
import OrganizationsTiles from './components/OrganizationsTiles';
import OrgSearchInput from './components/OrgSearchInput';
import classNames from 'classnames/bind';
import cls from './UserOrganizations.module.css';
const cx = classNames.bind(cls);

@connect(pick(['user', 'userOrganizations', 'organizationCurrent']), {
  changeCurrentOrganization: userApi.changeCurrentOrganization,
  getUserOrganizations: organizationsApi.ofUser.findAll,
  updateQuery: organizationsApi.ofUser.findAll.updateQuery,
})
export default class UserOrganizations extends React.PureComponent {
  static propTypes = {
    ...HeaderSteps.propTypes,
    user: PropTypes.object,
    userOrganizations: PropTypes.object,
    organizationCurrent: PropTypes.object,
    location: PropTypes.object,
    getUserOrganizations: PropTypes.func.isRequired,
    updateQuery: PropTypes.func.isRequired,
    changeCurrentOrganization: PropTypes.func.isRequired,
  };
  state = { isListView: true };
  componentDidMount() {
    this.props.getUserOrganizations(
      where({ id: this.props.user.data.id })
        .filter('id_not_eq', this.props.user.data.current_organization_id)
        .fromString(this.props.location.search)
    );
  }
  onAddRequest() {
    history.push('/user/organizations/add');
  }
  onEditRequest(id) {
    history.push(`/user/organizations/edit/${id}`);
  }
  onUpdateQuery = (query) => {
    this.props.updateQuery(query);
  };
  onChangeOrganization = (organizationId) => {
    this.props.changeCurrentOrganization(
      where({ id: this.props.user.data.id }).payload({
        current_organization_id: organizationId,
      })
    );
  };
  onViewTypeChange = (isListView) => {
    this.setState({ isListView });
  };
  render() {
    return (
      <Page
        noSeparator
        title="Account"
        steps={this.props.steps}
        activeStep={this.props.activeStep}
      >
        <Section type="sub-header" collapsed>
          <Container horizontal>
            <Actions>
              <OrgSearchInput
                queryParams={
                  this.props.userOrganizations.meta.getAction?.queryParams
                }
                onSearchChange={this.onUpdateQuery}
              />
            </Actions>
            <Actions className={cx('user-orgs-sub-header-centered')}>
              <TabSegments
                compact
                flat
                dark
                onToggle={this.onViewTypeChange}
                segments={[
                  {
                    tooltipText: 'List',
                    id: true,
                    text: (
                      <Icon name="list" className={cx('user-orgs-tab-icon')} />
                    ),
                    active: this.state.isListView,
                  },
                  {
                    tooltipText: 'Tiles',
                    id: false,
                    text: (
                      <Icon
                        name="thumbnails"
                        className={cx('user-orgs-tab-icon')}
                      />
                    ),
                    active: !this.state.isListView,
                  },
                ]}
              />
            </Actions>
            <Actions>
              <Button
                disabled={!canManageOrganization()}
                size="l"
                label="Add organization"
                onClick={this.onAddRequest}
              />
            </Actions>
          </Container>
        </Section>
        <Section>
          {this.state.isListView ? (
            <OrganizationsList
              currentOrg={this.props.organizationCurrent?.data}
              organizations={this.props.userOrganizations}
              onEditClick={this.onEditRequest}
              onSearchChange={this.onUpdateQuery}
              onPaginationChange={this.onUpdateQuery}
              onChangeOrganization={this.onChangeOrganization}
            />
          ) : (
            <OrganizationsTiles
              currentOrg={this.props.organizationCurrent?.data}
              organizations={this.props.userOrganizations}
              onEditClick={this.onEditRequest}
              onSearchChange={this.onUpdateQuery}
              onPaginationChange={this.onUpdateQuery}
              onChangeOrganization={this.onChangeOrganization}
            />
          )}
        </Section>
      </Page>
    );
  }
}
