import { capitalize } from 'src/utils/string';
import { parse, isValid, format } from 'date-fns';

export default class AutoFormatter {
  constructor(config) {
    this.config = config;
  }

  formatTable(table) {
    return table.map((row) => this.formatRow(row));
  }

  formatRow(row) {
    Object.entries(row).forEach(([key, value]) => {
      if (key === 'Funding Amount') {
        this.formatAmount(row);
        return;
      }
      if (key === 'Funding Currency') {
        this.formatCurrency(row);
        return;
      }

      row[key] = this.formatColumn(key, value);
    });

    return row;
  }

  formatColumn(key, value) {
    let newValue = value;

    if (typeof newValue === 'string') {
      newValue = newValue?.trim();
    }

    if (!newValue) {
      return newValue;
    }

    switch (key) {
      case 'Funding Start Date':
      case 'Funding End Date':
        return this.parseAndFormatDate(newValue.toString());
      case 'Grant Donor List':
      case 'Grant Strategies List':
      case 'Grant Cohort List':
      case 'Grant Area Of Work List':
      case 'Grant Portfolio List':
      case 'Grant Issue List':
      case 'Grant Population List':
      case 'Grant Donor Type List':
        return this.normalizeList(newValue.toString());
      default:
        return newValue;
    }
  }

  parseAndFormatDate(dateString) {
    // List of possible date formats
    const dateFormats = [
      'yyyy-MM-dd',
      'MM-dd-yyyy',
      'dd-MM-yyyy',
      'dd/MM/yyyy',
      'MMMM dd, yyyy',
      'yyyy, MMMM dd',
      'MMM dd, yyyy',
      'dd MMM yyyy',
      'M-d-yyyy',
      'M/d/yyyy',
      'd-MMM-yy',
      'd/MMM/yy',
      // Add more formats as needed
    ];

    // Loop through each format and try to parse the date
    for (const dateFormat of dateFormats) {
      const parsedDate = parse(dateString?.trim(), dateFormat, new Date());
      if (isValid(parsedDate)) {
        // Format the parsed date to the desired format (YYYY-MM-dd)
        return format(parsedDate, 'yyyy-MM-dd');
      }
    }

    return dateString; // format not recognized
  }

  normalizeList(list) {
    if (!this.config.normalizeList) return list;

    return list
      .split(';')
      .map((item) => capitalize(item?.trim()))
      .join(';')
      .trim();
  }

  formatAmount(row) {
    let newAmount = row['Funding Amount'];
    const newCurrency = row['Funding Currency']?.trim();

    if (typeof newAmount === 'string') {
      newAmount = newAmount?.trim();
    }

    if (!newAmount) {
      row['Funding Amount'] = newAmount;
      return;
    }

    const { currency, amount } = this.parseCurrencyAmount(newAmount.toString());

    if (!newCurrency) {
      row['Funding Currency'] = currency;
    }

    row['Funding Amount'] = amount;
  }

  formatCurrency(row) {
    let newAmount = row['Funding Amount'];
    const newCurrency = row['Funding Currency']?.trim();

    if (typeof newAmount === 'string') {
      newAmount = newAmount?.trim();
    }

    if (!newCurrency && newAmount) {
      const { currency } = this.parseCurrencyAmount(newAmount.toString());
      row['Funding Currency'] = currency;
    }

    row['Funding Currency'] = newCurrency;
  }

  parseCurrencyAmount(amountString) {
    // Define regular expression patterns for currency symbols and amounts
    const amountWithoutCommas = amountString.replace(/,/g, '');
    const currencyPattern = /[A-Z]{3}/; // Matches three uppercase letters (currency symbol)
    const amountPattern = /(0\.\d+)|([1-9]\d*(\.\d+)?)/; // Matches digits with optional decimal part

    // Match currency and amount
    const currencyMatch = amountWithoutCommas.match(currencyPattern);
    const amountMatch = amountWithoutCommas.match(amountPattern);

    let newCurrency = null;
    let newAmount = amountWithoutCommas;

    // Check if both currency and amount are found
    if (currencyMatch) {
      newCurrency = currencyMatch[0];
    }

    if (amountMatch) {
      newAmount = parseFloat(amountMatch[0]);
    }

    return { currency: newCurrency, amount: newAmount }; // Return null if currency or amount is not found
  }
}
