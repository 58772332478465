import reducerRegistry from 'im/api/registry';
import Resource from 'im/api/Resource';

const apiConfig = {
  root: '/api/v3/import_uploads',
  type: 'import_uploads',
  api: {
    allow: ['find', 'put'],
    custom: {
      put: {
        path: ':id',
        method: 'PUT',
        meta: { pending: 'background' },
      },
    },
  },
};

export const UploaderSecureMapping = new Resource(
  apiConfig,
  'importUploadMapping'
);
reducerRegistry.register(UploaderSecureMapping);

export default UploaderSecureMapping.api;
