import reducerRegistry from 'im/api/registry';
import ResourceCollection from 'im/api/ResourceCollection';

const apiConfig = {
  root: '/api/v3/activities',
  type: 'activities',
  api: {
    allow: ['findAll'],
  },
};

export const Activities = new ResourceCollection(apiConfig);
reducerRegistry.register(Activities);

export default Activities.api;
