import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { where } from 'im/api/Query';
import { Route, Redirect, Switch } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import tagCategoriesAllApi from 'src/api/TagCategoriesAll';
import Page from 'src/components/Page/Page';
import TagsEdit from 'src/pages/App/Analysis/Tags/TagsEdit/TagsEdit';
import TagsReview from 'src/pages/App/Analysis/Tags/TagsReview/TagsReview';
import UploadCSV from 'src/pages/App/Grants/Import/UploadCSV';
import TagsReport from 'src/pages/App/Analysis/Tags/TagsReport/Report';
import AnalysisTagCategoriesEdit from 'src/components/AddProjectForm/AnalysisTagCategoriesEdit';

@connect(
  (state) => ({
    project: state.project,
    tagCategoriesAll: state.tagCategoriesAll,
  }),
  { getTagCategoriesAll: tagCategoriesAllApi.findAllNestedIds }
)
export default class TagsIndex extends React.PureComponent {
  static propTypes = {
    ...ReactRouterPropTypes,
    tagCategoriesAll: PropTypes.object,
    getTagCategoriesAll: PropTypes.func.isRequired,
  };
  componentDidMount() {
    this.props.project?.uid &&
      this.doRequestProjectTagCategories(
        this.props.project.enabled_tag_categories
      );
  }
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.project.uid !== this.props.project.uid ||
      nextProps.project.enabled_tag_categories !==
        this.props.project.enabled_tag_categories
    ) {
      this.doRequestProjectTagCategories(
        nextProps.project.enabled_tag_categories
      );
    }
  }
  doRequestProjectTagCategories = (ids) =>
    this.props.getTagCategoriesAll(
      where().filter(ids?.length > 0 ? 'id_eq_any' : undefined, ids)
    );
  render() {
    const steps = [
      { url: `${this.props.match.url}/review`, title: 'Review Taggings' },
      { url: `${this.props.match.url}/edit`, title: 'Edit Tags' },
      { url: `${this.props.match.url}/active`, title: 'Active Tags' },
      {
        url: `${this.props.match.url}/import`,
        alturl: `/report`,
        title: 'Import Tags',
      },
    ];
    const activeStep = steps.findIndex(
      (step) =>
        this.props.location.pathname.includes(step?.url) ||
        this.props.location.pathname.includes(step?.alturl)
    );
    const projectKey = `${(
      this.props.project?.enabled_tag_categories || []
    ).join(',')}-${String(this.props.project?.uid)}`;
    return (
      <Page noSeparator title="Tags" steps={steps} activeStep={activeStep}>
        <Switch>
          <Route
            key={projectKey}
            exact
            path={`${this.props.match.path}/:fileId/report`}
            component={TagsReport}
          />
          <Route
            key={projectKey}
            exact
            path={`${this.props.match.path}/import`}
            render={(props) => (
              <UploadCSV
                {...props}
                title="Tag and Tag Group Import"
                target_type="Project"
                target_id={this.props.project.uid}
                type="Tag"
              />
            )}
          />
          <Route
            key={projectKey}
            exact
            path={`${this.props.match.path}/active`}
            render={(props) => (
              <div style={{ padding: 24 }}>
                <AnalysisTagCategoriesEdit
                  {...props}
                  title="Analysis Tag Groups"
                />
              </div>
            )}
          />
          <Route
            key={projectKey}
            path={`${this.props.match.path}/review`}
            render={(props) => (
              <TagsReview
                key={this.props.match.path}
                {...props}
                tagCategoryIds={this.props.tagCategoriesAll.meta.flatIds}
              />
            )}
          />
          <Route
            key={projectKey}
            path={`${this.props.match.path}/edit/:editType?/:entityId?`}
            render={(props) => (
              <TagsEdit
                key={this.props.match.path}
                {...props}
                tagCategoryIds={this.props.tagCategoriesAll.meta.flatIds}
              />
            )}
          />
          <Redirect
            from={`${this.props.match.path}`}
            to={`${this.props.match.url}/review`}
          />
        </Switch>
      </Page>
    );
  }
}
