import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import {
  Container,
  Table,
  CardEmpty,
  Button,
  Divider,
  EmptyText,
} from 'src/components/IMUI';

import { Icon } from 'im/ui/Icon';

import cls from './DrawerDetailsElements.module.css';
import TableComposition from 'src/components/IMUI/Tables/TableComposition';

const cx = classNames.bind(cls);

export const DrawerDetailsSectionTitle = ({ text, className }) => (
  <h4 className={cx(cls.drawerDetailsSectionTitle, className)}>{text}</h4>
);

DrawerDetailsSectionTitle.propTypes = {
  text: PropTypes.node,
  className: PropTypes.string,
};

export const DrawerDetailsEmpty = ({
  title,
  description,
  onCreate,
  createButtonLabel,
}) => (
  <CardEmpty
    compact
    className={cx({ [cls.drawerDetailsEmpty_ExtraSpacing]: !onCreate })}
    title={title}
    description={description}
  >
    {onCreate && (
      <Button secondary label={createButtonLabel} onClick={onCreate} />
    )}
  </CardEmpty>
);

DrawerDetailsEmpty.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  createButtonLabel: PropTypes.string,
  onCreate: PropTypes.func,
};

export const DrawerDetailsTitle = ({ iconName, iconLabel, title }) => (
  <Container className={cls.drawerDetailsTitle}>
    <Container horizontal centered>
      <Icon name={iconName} />
      {iconLabel}
    </Container>

    <h3>{title}</h3>
  </Container>
);

DrawerDetailsTitle.propTypes = {
  iconName: PropTypes.string,
  iconLabel: PropTypes.node,
  title: PropTypes.node,
};

export const DrawerDetailsSubTitleItem = ({ icon, text }) => (
  <li>
    <Container horizontal centered className={cls.drawerDetailsSubtitleItem}>
      <div className={cls.drawerDetailsSubtitleItemIcon}>{icon}</div>
      <span>{text}</span>
    </Container>
  </li>
);

DrawerDetailsSubTitleItem.propTypes = {
  icon: PropTypes.node,
  text: PropTypes.node,
};

export const DrawerDetailsTable = ({ title, head, body, renderEmpty }) => (
  <div>
    {title && <DrawerDetailsSectionTitle text={title} />}
    {renderEmpty && Array.isArray(body) && !body.length ? (
      renderEmpty()
    ) : (
      <TableComposition>
        {head}
        <Table.Body>{body}</Table.Body>
      </TableComposition>
    )}
    <Divider className={cls.drawerDetailsTableDivider} />
  </div>
);

DrawerDetailsTable.propTypes = {
  title: PropTypes.node,
  head: PropTypes.node,
  body: PropTypes.node,
  renderEmpty: PropTypes.func,
};

export const DrawerDetailsItem = ({ name, value }) => {
  return (
    <li className={cls.drawerDetailsItem}>
      <Container horizontal nowrap>
        {name && <strong className={cls.drawerDetailsItemKey}>{name}</strong>}
        {typeof value === 'string' ? <span>{value}</span> : value}
      </Container>
    </li>
  );
};

DrawerDetailsItem.propTypes = {
  name: PropTypes.node,
  value: PropTypes.node,
};

export const DrawerDetailsList = ({
  children,
  className,
  showEmptyPlaceholder,
}) => {
  if (
    showEmptyPlaceholder &&
    !React.Children.toArray(children).filter((v) => v).length
  ) {
    return <EmptyText text="No details" />;
  }

  return <ul className={cx(cls.drawerDetailsList, className)}>{children}</ul>;
};

DrawerDetailsList.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  showEmptyPlaceholder: PropTypes.bool,
};
