import reducerRegistry, { advancedFiltersRegistry } from 'im/api/registry';
import ResourceCollection from 'im/api/ResourceCollection';

const apiConfig = {
  root: '/v3/grants',
  type: 'grants',
  api: {
    allow: ['findAll', 'destroy'],
  },
};

export const Grants = new ResourceCollection(apiConfig, 'jsonapiGrants');
export const GrantsDetailed = new ResourceCollection(
  apiConfig,
  'grantsDetailed'
);

reducerRegistry.register(Grants);
reducerRegistry.register(GrantsDetailed);

const advancedFiltersConfig = {
  actionPrefix: 'grantsAF',
  allow: [
    'name',
    'number',
    'status',
    'portfolio_list',
    'strategies_list',
    'cohort_list',
    'area_of_work_list',
    'population_list',
  ],
};

advancedFiltersRegistry.register(Grants, advancedFiltersConfig);

export const grantsGetAdvancedFilters = Grants.advancedFilters;

export default {
  ...Grants.api,
  detailed: GrantsDetailed.api,
};
