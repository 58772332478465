import { where } from 'im/api/Query';
import reducerRegistry from 'im/api/registry';
import ResourceCollection from 'im/api/ResourceCollection';

const apiConfig = {
  root: '/api/v3/organization_memberships',
  type: 'organization_memberships',
  api: {
    allow: ['destroy'],
    findAll: {
      path: where()
        .filter('organization_of_IM::Organization_type_id_eq', ':id')
        .toString(),
    },
  },
};
export const OrganizationMemberships = new ResourceCollection(
  apiConfig,
  'organizationMemberships'
);
reducerRegistry.register(OrganizationMemberships);

export default OrganizationMemberships.api;
