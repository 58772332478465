import { T } from 'lioness';
import PropTypes from 'prop-types';
import React from 'react';

import { Button } from 'src/components/IMUI';

import { PrintWidget } from './PrintWidget';

import cls from './DonwloadAsPdfWidget.module.css';

export class DownloadAsPdfWidget extends React.Component {
  static propTypes = {
    howTo: PropTypes.oneOf(['full', 'tip', 'none']),
    tooltip: PropTypes.node,
    iconProps: PropTypes.object,
    dialog: PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
    children: PropTypes.node,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
  };

  static defaultProps = {
    dialog: {
      title: <T>Download PDF or Print</T>,
      buttonText: <T>Download</T>,
    },
    howTo: 'full',
    tooltip: <T>Download PDF or Print</T>,
  };

  state = {
    showTutorial: false,
  };

  onToggleTutorial = () => {
    this.setState({ showTutorial: !this.state.showTutorial });
  };

  renderHowToTip = () => {
    const { showTutorial } = this.state;

    return (
      <div>
        <Button
          text
          label={<T>How to download PDF</T>}
          onClick={this.onToggleTutorial}
        />
        {showTutorial ? this.renderHowToTutorial() : null}
      </div>
    );
  };

  renderHowToTutorial() {
    return (
      <ol className={cls.downloadAsPdfList}>
        <li>
          <T context="print button tutorial">
            Click Download button and a pop-up will appear
          </T>
        </li>
        <li>
          <T context="print button tutorial">
            In the Destination section, select Save as PDF
          </T>
        </li>
        <li>
          <T context="print button tutorial">
            Expand More settings and tick Background graphics
          </T>
        </li>
        <li>
          <T context="print button tutorial">Click Save</T>
        </li>
      </ol>
    );
  }

  render() {
    const { tooltip, dialog, howTo, children, iconProps, onClose, onOpen } =
      this.props;

    return (
      <PrintWidget
        iconProps={iconProps}
        hasDialog={howTo !== 'none' || children}
        tooltip={tooltip}
        dialog={dialog}
        onClose={onClose}
        onOpen={onOpen}
      >
        {children}
        {howTo === 'full' ? this.renderHowToTutorial() : null}
        {howTo === 'tip' ? this.renderHowToTip() : null}
      </PrintWidget>
    );
  }
}

export default DownloadAsPdfWidget;
