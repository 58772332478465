import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';

import StoriesList from './List';
import Story from './Story';

export default class StoriesIndex extends Component {
  static propTypes = {
    ...ReactRouterPropTypes,
  };

  render() {
    const { match } = this.props;
    const rootPath = match.path;

    return (
      <Switch>
        <Route path={`${rootPath}/list`} component={StoriesList} exact />
        <Route path={`${rootPath}/:surveyId`} component={Story} />
        <Redirect from="*" to={`${match.url}/list`} />
      </Switch>
    );
  }
}
