export const updateObject = (oldObject, newValues) => ({
  ...oldObject,
  ...newValues,
});

export const updateItem = (array, itemId, updateItemCallback) =>
  (array || []).map((item) =>
    item?.id !== itemId ? item : updateItemCallback(item)
  );

export const removeItem = (array, itemId) =>
  (array || []).filter(({ id }) => id !== itemId);

export const currentProject = (
  storage = {},
  currentProjectId,
  organizationId
) =>
  storage.last_projects.find((p) => p.id == currentProjectId) ??
  storage.last_projects.find(
    (item) => item.organization_id == organizationId
  ) ??
  storage.last_projects?.[0];
