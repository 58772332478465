import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  formValueSelector,
  getFormSyncErrors,
  getFormValues,
  reduxForm,
} from 'redux-form';
import createValidator, { handleSubmitFail } from 'src/utils/validation';
import FundingInfo, { validateFundings } from '../../components/FundingInfo';
import { isAfter } from 'src/utils/date';

class FundingInfoAdd extends React.PureComponent {
  static propTypes = {
    quickMode: PropTypes.bool,
    fundingTypes: PropTypes.array,
    change: PropTypes.func.isRequired,
    fundings: PropTypes.array,
    formErrors: PropTypes.object,
    form: PropTypes.string,
  };

  onFieldValueChange = ({ fieldName, value }) =>
    this.props.change(fieldName, value || null);

  render() {
    const noSubmit = (e) => {
      e.stopPropagation();
      e.preventDefault();
    };
    return (
      <form onSubmit={noSubmit} noValidate autoComplete="off">
        <FundingInfo
          quickMode={this.props.quickMode}
          formName={this.props.form}
          isEditMode={false}
          fundings={this.props.fundings}
          fundingTypes={this.props.fundingTypes}
          formErrors={this.props.formErrors}
          onFieldValueChange={this.onFieldValueChange}
        />
      </form>
    );
  }
}

function validate(errors, props) {
  const not = (fn) => (v) => !fn(v);
  const isDefined = (v) => Boolean(v);
  const validateDates = createValidator({
    start_date: [[not(isDefined), () => 'Start date is required']],
    end_date: [
      [not(isDefined), () => 'End date is required'],
      [
        (v) => isAfter(errors.start_date, v, true),
        () => 'End date must be later than start date',
      ],
    ],
  });
  return {
    ...validateFundings(errors),
    ...validateDates(errors),
  };
}

export default connect((state, ownProps) => ({
  initialValues: ownProps.initialValues,
  formValues: getFormValues('newFunding')(state),
  formErrors: getFormSyncErrors('newFunding')(state),
  fundingType: formValueSelector('newFunding')(state, 'funding_type'),
  fundings: formValueSelector('newFunding')(state, 'fundings'),
}))(
  reduxForm({
    form: 'newFunding',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    updateUnregisteredFields: true,
    destroyOnUnmount: true,
    validate,
    onSubmitFail: (errors) => handleSubmitFail(errors, true),
  })(FundingInfoAdd)
);
