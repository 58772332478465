import axios from 'src/axiosInstance';
import {
  GRANTS__GET_REQUEST,
  GRANTS__GET_SUCCESS,
  GRANTS__GET_FAILURE,
  GRANT__CREATE_REQUEST,
  GRANT__CREATE_SUCCESS,
  GRANT__CREATE_FAILURE,
  GRANT__UPDATE_REQUEST,
  GRANT__UPDATE_SUCCESS,
  GRANT__UPDATE_FAILURE,
  GRANT__DELETE_REQUEST,
  GRANT__DELETE_SUCCESS,
  GRANT__DELETE_FAILURE,
  GRANT__CONTACT_CREATE_REQUEST,
  GRANT__CONTACT_CREATE_SUCCESS,
  GRANT__CONTACT_CREATE_FAILURE,
  GRANT__CONTACT_DELETE_REQUEST,
  GRANT__CONTACT_DELETE_SUCCESS,
  GRANT__CONTACT_DELETE_FAILURE,
  GRANT__LOCATION_CREATE_REQUEST,
  GRANT__LOCATION_CREATE_SUCCESS,
  GRANT__LOCATION_CREATE_FAILURE,
  GRANT__LOCATION_UPDATE_REQUEST,
  GRANT__LOCATION_UPDATE_SUCCESS,
  GRANT__LOCATION_UPDATE_FAILURE,
  GRANT__LOCATION_DELETE_REQUEST,
  GRANT__LOCATION_DELETE_SUCCESS,
  GRANT__LOCATION_DELETE_FAILURE,
} from 'src/constants';
const getGrants_grantFields =
  'fields[grant]=name,start_date,end_date,created_at,cohort_list,donor_list,fundings,meta,portfolio_list,issue_list,strategies_list,population_list';
const getGrants_granteeFields = 'fields[grantee]=name,status,type,id,meta';
const getGrants_fundingFields =
  'fields[funding]=amount,start_date,end_date,grant_name,grantee_name,id,type,grantee,meta';

const convertGrantsToFundings = (grants, included = []) => {
  const buildFundings = (fundingId) => {
    const funding = included
      .filter(({ type }) => type === 'funding')
      .find((f) => f.id === fundingId);
    const grantee =
      included
        .filter(({ type }) => type === 'grantee')
        .find((g) => g?.id === funding.relationships.grantee.data?.id) ?? {};
    return {
      ...funding.attributes,
      id: Number(funding.id),
      grantee: { id: Number(grantee.id), ...grantee.attributes },
    };
  };
  const preparedGrants = grants.map((g) => ({
    ...g.attributes,
    id: Number(g.id),
    meta: g.meta,
    fundings:
      g.relationships.fundings?.data?.map((f) => buildFundings(f.id)) ?? [],
  }));

  return preparedGrants.flatMap((grant) =>
    (grant.fundings || []).map((funding) => ({
      funding_id: funding.id,
      start_date: funding.start_date,
      end_date: funding.end_date,
      grantee: funding.grantee || { id: null, type: 'organization' },
      grant_id: grant.id,
      meta: grant.meta,
      id: grant.id,
      donor_list: grant.donor_list,
      portfolio_list: grant.portfolio_list,
      issue_list: grant.issue_list,
      strategies_list: grant.strategies_list,
      population_list: grant.population_list,
      cohort_list: grant.cohort_list,
      name: grant.name,
      status: grant.status,
      created_at: grant.created_at,
      ...grant.meta,
    }))
  );
};

const convertGrantsToV2 = (grants) =>
  grants
    .map((g) => ({ ...g.attributes, id: Number(g.id), meta: g.meta }))
    .map((grant) => ({
      funding_id: grant.fundings?.[0]?.id,
      grantee: { id: null, type: 'organization' },
      grant_id: grant.id,
      id: grant.id,
      name: grant.name,
      status: grant.status,
      created_at: grant.created_at,
    }))
    .sort((a, b) => a.name.localeCompare(b.name));

const doCreateGrant = (payload) =>
  axios
    .post('/v2/grants/', payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
const doUpdateGrant = (payload) =>
  axios
    .put(`/v2/grants/${payload.id}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
const doDeleteGrant = (grantId) =>
  axios
    .delete(`/v2/grants/${grantId}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
const doCreateGrantContact = (grantId, contact) =>
  axios
    .post('/v2/contacts', { ...contact, grant_id: grantId })
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
const doDeleteGrantContact = (contactId) =>
  axios
    .delete(`/v2/contacts/${contactId}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
const doCreateGrantLocation = (grantId, location) =>
  axios
    .post(`/v2/grants/${grantId}/locations`, location)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
const doUpdateGrantLocation = (grantId, location) =>
  axios
    .put(`/v2/grants/${grantId}/locations/${location.id}`, location)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
const doDeleteGrantLocation = (grantId, locationId) =>
  axios
    .delete(`/v2/grants/${grantId}/locations/${locationId}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));

export const getFundings = () => async (dispatch) => {
  dispatch({ type: GRANTS__GET_REQUEST });
  new Promise((resolve, reject) =>
    axios
      .get(
        `/v3/grants?include=fundings,grantees&${getGrants_granteeFields}&${getGrants_grantFields}&${getGrants_fundingFields}&sort=-created_at&page[size]=9999`
      )
      .then((response) =>
        resolve(
          convertGrantsToFundings(response.data.data, response.data.included)
        )
      )
      .catch((error) => reject(error))
  )
    .then((response) =>
      dispatch({ type: GRANTS__GET_SUCCESS, payload: { data: response } })
    )
    .catch((error) =>
      dispatch({ type: GRANTS__GET_FAILURE, payload: error, error: true })
    );
};
export const getGrants = () => async (dispatch) => {
  dispatch({ type: GRANTS__GET_REQUEST });
  new Promise((resolve, reject) =>
    axios
      .get(
        `/v3/grants?include=&fields[grant]=name&sort=-created_at&page[size]=9999`
      )
      .then((response) => resolve(convertGrantsToV2(response.data.data)))
      .catch((error) => reject(error))
  )
    .then((response) =>
      dispatch({ type: GRANTS__GET_SUCCESS, payload: { data: response } })
    )
    .catch((error) =>
      dispatch({ type: GRANTS__GET_FAILURE, payload: error, error: true })
    );
};

export const createGrant = (payload) => async (dispatch) => {
  dispatch({ type: GRANT__CREATE_REQUEST });
  const { response, error } = await doCreateGrant(payload);
  response
    ? dispatch({
        type: GRANT__CREATE_SUCCESS,
        payload: { grant: response.data },
      })
    : dispatch({
        type: GRANT__CREATE_FAILURE,
        payload: {
          title: "Can't create grant",
          response: { errors: error.response.data.table.errors },
        },
        error: true,
      });
  if (response) return response.data;
};

export const updateGrant = (grant) => async (dispatch) => {
  dispatch({ type: GRANT__UPDATE_REQUEST });
  const { response, error } = await doUpdateGrant(grant);
  response
    ? dispatch({
        type: GRANT__UPDATE_SUCCESS,
        payload: { grant: response.data },
      })
    : dispatch({
        type: GRANT__UPDATE_FAILURE,
        payload: {
          title: "Can't update grant",
          response: { errors: error.response.data.table.errors },
        },
        error: true,
      });
  if (response) return response.data;
};

export const deleteGrant = (grantId) => async (dispatch) => {
  dispatch({ type: GRANT__DELETE_REQUEST });
  const { response, error } = await doDeleteGrant(grantId);
  response
    ? dispatch({ type: GRANT__DELETE_SUCCESS, payload: { grantId } })
    : dispatch({ type: GRANT__DELETE_FAILURE, payload: error, error: true });
};

export const createGrantContact = (grantId, contact) => async (dispatch) => {
  dispatch({ type: GRANT__CONTACT_CREATE_REQUEST });
  const { response, error } = await doCreateGrantContact(grantId, contact);
  response
    ? dispatch({
        type: GRANT__CONTACT_CREATE_SUCCESS,
        payload: { grantId, contact: response.data },
      })
    : dispatch({
        type: GRANT__CONTACT_CREATE_FAILURE,
        payload: error,
        error: true,
      });
};

export const deleteGrantContact = (grantId, contactId) => async (dispatch) => {
  dispatch({ type: GRANT__CONTACT_DELETE_REQUEST });
  const { response, error } = await doDeleteGrantContact(contactId);
  response
    ? dispatch({
        type: GRANT__CONTACT_DELETE_SUCCESS,
        payload: { grantId, contactId },
      })
    : dispatch({
        type: GRANT__CONTACT_DELETE_FAILURE,
        payload: error,
        error: true,
      });
};

export const createGrantLocation = (grantId, location) => async (dispatch) => {
  dispatch({ type: GRANT__LOCATION_CREATE_REQUEST });
  const { response, error } = await doCreateGrantLocation(grantId, location);
  response
    ? dispatch({
        type: GRANT__LOCATION_CREATE_SUCCESS,
        payload: { grantId, location: response.data },
      })
    : dispatch({
        type: GRANT__LOCATION_CREATE_FAILURE,
        payload: error,
        error: true,
      });
};

export const updateGrantLocation = (grantId, location) => async (dispatch) => {
  dispatch({ type: GRANT__LOCATION_UPDATE_REQUEST });
  const { response, error } = await doUpdateGrantLocation(grantId, location);
  response
    ? dispatch({
        type: GRANT__LOCATION_UPDATE_SUCCESS,
        payload: { grantId, location: response.data },
      })
    : dispatch({
        type: GRANT__LOCATION_UPDATE_FAILURE,
        payload: error,
        error: true,
      });
};

export const deleteGrantLocation =
  (grantId, locationId) => async (dispatch) => {
    dispatch({ type: GRANT__LOCATION_DELETE_REQUEST });
    const { response, error } = await doDeleteGrantLocation(
      grantId,
      locationId
    );
    response
      ? dispatch({
          type: GRANT__LOCATION_DELETE_SUCCESS,
          payload: { grantId, locationId },
        })
      : dispatch({
          type: GRANT__LOCATION_DELETE_FAILURE,
          payload: error,
          error: true,
        });
  };
