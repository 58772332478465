import PropTypes from 'prop-types';
import React from 'react';

import { Status } from 'src/components/IMUI';
import { isAfter, isBefore } from 'src/utils/date';

import cls from './DatesIndicator.module.css';

const DatesIndicator = ({ startDate, endDate }) => {
  if (!startDate || !endDate) return null;

  const now = new Date();

  const statusProps = {
    className: cls.datesIndicator,
    done: isBefore(startDate, now) && isAfter(endDate, now),
    alarming: isBefore(endDate, now),
    disabled: isAfter(startDate, now),
  };

  return <Status {...statusProps} />;
};

DatesIndicator.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
};

export default DatesIndicator;
