import classNames from 'classnames/bind';
import MUIToggle from 'material-ui/Toggle';
import PropTypes from 'prop-types';
import React from 'react';
import { InputReduxForm } from 'src/components/IMUI/Forms/base';
import cls from './Toggle.module.css';
const cx = classNames.bind(cls);

export const Toggle = (props) => {
  const {
    className,
    input,
    mini,
    toggled,
    compact,
    onToggle,
    meta,
    ...muiToggleProps
  } = props;
  return (
    <MUIToggle
      className={cx(
        'imui-toggle',
        {
          'imui-toggle-compact': !!compact,
          imuiToggleMini: !!mini,
          'imui-toggle-active': input ? input.value : toggled,
          'imui-toggle-label-left': muiToggleProps.labelPosition === 'left',
        },
        className
      )}
      {...(input || {})}
      {...muiToggleProps}
      toggled={input ? input.value : toggled}
      onToggle={InputReduxForm.onChange(input, onToggle)}
    />
  );
};

Toggle.propTypes = {
  ...InputReduxForm.propTypes,
  label: PropTypes.string,
  labelPosition: PropTypes.string,
  className: PropTypes.string,
  toggled: PropTypes.bool,
  compact: PropTypes.bool,
  mini: PropTypes.bool,
  onToggle: PropTypes.func,
};

export default Toggle;
