import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Actions, Button, Container } from 'src/components/IMUI';
import { Icon } from 'im/ui/Icon';
import PropertyBox from './PropertyBox';
import cls from './Option.module.css';

export default class Option extends React.PureComponent {
  static propTypes = {
    actionsLeft: PropTypes.func,
    actionsRight: PropTypes.func,
    onOptionDelete: PropTypes.func,
    onOrderChange: PropTypes.func,
    index: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    onAddRow: PropTypes.func,
    children: PropTypes.node,
    alignToEnd: PropTypes.bool,
  };
  static defaultProps = { count: 0 };

  render() {
    const {
      index,
      count,
      actionsLeft,
      actionsRight,
      children,
      alignToEnd,
      onOrderChange,
      onOptionDelete,
    } = this.props;
    const isFirst = index === 0;
    const isLast = index === count - 1;

    return (
      <div className={classNames(cls.option, { [cls.alignToEnd]: alignToEnd })}>
        <PropertyBox>
          <Container horizontal nowrap>
            {this.props.onAddRow && (
              <Button
                style={{
                  width: 24,
                  minWidth: 24,
                  maxHeight: 44,
                  display: 'block',
                  textAlign: 'left',
                }}
                icon={
                  <Icon
                    tip="Add another"
                    name="plus"
                    style={{
                      fontSize: 14,
                      placeSelf: 'center',
                      width: '100%',
                      marginTop: 8,
                    }}
                  />
                }
                action
                size="s"
                onClick={this.props.onAddRow}
              />
            )}

            <Actions grid>{children}</Actions>

            <Actions className={cls.actions}>
              {actionsLeft && actionsLeft()}
              {onOrderChange && (
                <Icon
                  name="arrow-up"
                  tip={isFirst ? undefined : 'move question up'}
                  disabled={isFirst}
                  onClick={
                    isFirst ? undefined : () => onOrderChange(index, -1, true)
                  }
                />
              )}
              {onOrderChange && (
                <Icon
                  name="arrow-down"
                  tip={isLast ? undefined : 'move question down'}
                  disabled={isLast}
                  onClick={
                    isLast ? undefined : () => onOrderChange(index, 1, true)
                  }
                />
              )}
              {onOptionDelete && (
                <Icon
                  name="trash"
                  tip="Remove question"
                  onClick={() => onOptionDelete(index)}
                />
              )}
              {actionsRight && (
                <Actions className={cls.actions}>{actionsRight()}</Actions>
              )}
            </Actions>
          </Container>
        </PropertyBox>
      </div>
    );
  }
}
