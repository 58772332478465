import PropTypes from 'prop-types';
import React, { Component } from 'react';

import {
  Button,
  ChipInput,
  TextField,
  PopoverLight,
  SelectPopoverLight,
  Actions,
  Container,
  FormField,
} from 'src/components/IMUI';
import { KEYCODE_ENTER } from 'src/components/IMUI/Forms/ChipInput';
import { Error, Label } from 'src/components/IMUI/Forms/base';
import Warning from 'src/components/Warning/Warning';

import { Icon } from 'im/ui/Icon';

import TagCategorySelectorItem from './TagCategorySelectorItem';

import cls from './TagCategorySelector.module.css';
import { canManageTag } from 'src/userStorage';

export default class TagCategorySelector extends Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    error: PropTypes.string,
    backendError: PropTypes.string,
    allTagCategories: PropTypes.array,
    allMetatags: PropTypes.array,
    inputLabel: PropTypes.string,
    labelHint: PropTypes.string,
    enableCreate: PropTypes.bool,
    required: PropTypes.bool,
    customValueEnabled: PropTypes.bool,
    popoverProps: PropTypes.object,
    hasError: PropTypes.bool,
    hasLabel: PropTypes.bool,
    isParentOptional: PropTypes.bool,

    renderSelected: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    onAdd: PropTypes.func,
    onSearchText: PropTypes.func.isRequired,
  };

  static defaultProps = {
    formColumns: {},
    hasError: true,
    hasLabel: true,
  };

  state = {
    open: false,
    addMode: false,
    categoryTitle: '',
    categoryMetatags: [],
    parentCategory: null,
    collapsedCategoryIds: [],
  };

  handleSelectorOpen = () => {
    this.setState({ open: true });
  };

  handleSelectorClose = () => {
    this.setState({
      addMode: false,
      categoryTitle: '',
      categoryMetatags: [],
      open: false,
    });
  };

  handleClear = () => {
    this.setState({ addMode: false, categoryTitle: '', categoryMetatags: [] });
  };

  handleAdd = async () => {
    const { categoryTitle, categoryMetatags, parentCategory } = this.state;
    const success = await this.props.onAdd(
      categoryTitle,
      categoryMetatags,
      parentCategory
    );

    if (success) {
      this.handleSelectorClose();
    }
  };

  handleChange = (category) => {
    this.props.onChange(category);
    this.handleSelectorClose();
  };

  handleAddRequest = () => {
    this.setState({ addMode: true });
  };

  handleAddToCategory = (ev, parentCategory) => {
    ev.stopPropagation();
    this.setState({ parentCategory, addMode: true });
  };

  handleCategoryMetatagsChange = (categoryMetatags) => {
    this.setState({ categoryMetatags });
  };

  handleCateogryTitleChange = (categoryTitle) => {
    this.setState({ categoryTitle });
  };

  handleParentCateogryChange = (parentCategory) => {
    this.setState({ parentCategory });
  };

  handleSearchTextChange = (searchText) => {
    this.props.onSearchText(searchText);
  };

  handleToggleVisible = (ev, category) => {
    ev.preventDefault();
    ev.stopPropagation();

    const { collapsedCategoryIds } = this.state;
    const isCollapsed = collapsedCategoryIds.includes(category.id);
    let newCollapesCategoryIds = [...collapsedCategoryIds];

    if (isCollapsed) {
      newCollapesCategoryIds = newCollapesCategoryIds.filter(
        (id) => id !== category.id
      );
    } else {
      newCollapesCategoryIds = newCollapesCategoryIds.concat([category.id]);
    }

    this.setState({ collapsedCategoryIds: newCollapesCategoryIds });
  };

  renderMenuItem = (category, { treeLevel = 0 } = {}) => {
    const { enableCreate } = this.props;
    const { collapsedCategoryIds } = this.state;

    return (
      <TagCategorySelectorItem
        className={cls.tagCategoryItem}
        category={category}
        treeLevel={treeLevel}
        isExpanded={!collapsedCategoryIds.includes(category.id)}
        onClick={this.handleChange}
        onToggleVisible={this.handleToggleVisible}
        renderChildren={
          !enableCreate
            ? undefined
            : () => (
                <Icon
                  className={cls.tagCategoryHoverIcon}
                  name="plus"
                  onClick={(ev) => this.handleAddToCategory(ev, category)}
                />
              )
        }
      />
    );
  };

  renderMenuItems = (categories, treeLevel = 0) =>
    categories
      // .sort((tag1, tag2) => tag1.title.localeCompare(tag2.title))
      .map((category) => {
        const isCollapsed = this.state.collapsedCategoryIds.includes(
          category.id
        );
        const hasChildren = (category.child_categories || []).length;
        const singleItem = [this.renderMenuItem(category, { treeLevel })];

        return !hasChildren || isCollapsed
          ? singleItem
          : singleItem.concat(
              this.renderMenuItems(category.child_categories, treeLevel + 1)
            );
      });

  renderMenu = () => {
    const { allTagCategories } = this.props;
    return (
      <SelectPopoverLight.Menu data-qa="TagCategorySelector:SelectPopoverLight.Menu">
        {this.renderMenuItems(allTagCategories)}
      </SelectPopoverLight.Menu>
    );
  };

  renderAddMode = () => {
    const { allMetatags, backendError, allTagCategories } = this.props;
    const { categoryTitle, categoryMetatags, parentCategory } = this.state;

    return (
      <SelectPopoverLight.Content>
        {backendError && (
          <Warning show compact mode="error" ignorable={false}>
            {backendError}
          </Warning>
        )}

        <FormField extraSpacing>
          <TextField
            noValidation
            fullWidth
            flat
            borderDark
            required
            label="Tag group name"
            hintText="Type in a tag group name..."
            name="title"
            value={categoryTitle}
            onChange={this.handleCateogryTitleChange}
          />
        </FormField>

        <TagCategorySelector
          value={parentCategory}
          inputLabel="Parent tag group"
          labelHint="Skip or select"
          allTagCategories={allTagCategories}
          onChange={this.handleParentCateogryChange}
          onSearchText={this.handleSearchTextChange}
        />

        <ChipInput
          flat
          borderDark
          label={
            <Container horizontal nowrap>
              <Actions>
                <span>Metatags</span>
              </Actions>
              <Actions>
                <small>
                  Press <code>enter</code> to confirm creating metatag.
                </small>
              </Actions>
            </Container>
          }
          newChipKeyCodes={[KEYCODE_ENTER]}
          name="metatags"
          chipProps={{ square: true, alt: true }}
          dataSource={allMetatags}
          hintText="Type in a metatag..."
          value={categoryMetatags || []}
          onChange={this.handleCategoryMetatagsChange}
        />

        <SelectPopoverLight.ContentActions>
          <Button
            secondary
            label="Add Group"
            disabled={!canManageTag() || !categoryTitle}
            onClick={this.handleAdd}
          />
          <Button negative label="Cancel" onClick={this.handleClear} />
        </SelectPopoverLight.ContentActions>
      </SelectPopoverLight.Content>
    );
  };

  renderHeader = () => {
    const { addMode } = this.state;

    if (!addMode) return null;
    return <span>Create a tag group</span>;
  };

  renderFooter = () => {
    const { enableCreate } = this.props;
    const { addMode } = this.state;

    return !enableCreate || addMode ? null : (
      <SelectPopoverLight.Actions>
        <PopoverLight.ActionItem
          iconName="add-element"
          text="Create a tag group"
          onClick={this.handleAddRequest}
        />
      </SelectPopoverLight.Actions>
    );
  };

  renderContent = (searchText) => {
    const { addMode } = this.state;

    if (addMode) {
      return this.renderAddMode();
    }
    return this.renderMenu(searchText);
  };

  render() {
    const {
      value,
      error,
      inputLabel,
      labelHint,
      required,
      customValueEnabled,
      popoverProps,
      renderSelected,
      hasError,
      hasLabel,
      isParentOptional,
    } = this.props;
    const { open } = this.state;

    const popoverPropsExtended = {
      header: this.renderHeader(),
      footer: this.renderFooter(),
      renderChildren: this.renderContent,
      searchHint: 'Type in to filter the tag group list',
      onGoBack: this.handleClear,
      customHeight: '60vh',
      style: { minWidth: 500 },
      canAutoPosition: true,
      targetOrigin: { horizontal: 'left', vertical: 'top' },
      onSearchTextChange: this.handleSearchTextChange,
      zIndex: 1400,
      ...popoverProps,
    };

    return (
      <React.Fragment>
        {hasLabel && <Label required={required} label={inputLabel} />}

        <SelectPopoverLight
          customWidth={600}
          borderDark
          small
          customValueEnabled={customValueEnabled}
          open={open}
          selectedItems={
            typeof value === 'string' ? value : (value || {}).title
          }
          popoverProps={popoverPropsExtended}
          labelHint={labelHint}
          renderSelected={renderSelected}
          onClose={this.handleSelectorClose}
          onOpen={this.handleSelectorOpen}
          onSelect={this.handleChange}
        />
        {value && isParentOptional ? (
          <Button
            className={cls.buttonText}
            text
            label="Clear Selection"
            onClick={() => this.handleChange(null)}
          />
        ) : null}
        {hasError && <Error error={error} />}
      </React.Fragment>
    );
  }
}
