import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import cls from './Stats.module.css';

const cx = classNames.bind(cls);

const StatItem = ({ value, label, className, ...rest }) => (
  <li className={cx('stat', className)} {...rest}>
    <span className={cx('value')}>{value}</span>
    <span className={cx('label')}>{label}</span>
  </li>
);

StatItem.propTypes = {
  value: PropTypes.node,
  label: PropTypes.node,
  className: PropTypes.string,
};

export const Stats = ({ stats, className }) => (
  <ul className={cx('stats', className)}>
    {stats.map(({ value, label, className: statCls, ...rest }, index) => (
      <StatItem
        key={index}
        value={value}
        label={label}
        className={statCls}
        {...rest}
      />
    ))}
  </ul>
);

Stats.propTypes = {
  stats: PropTypes.arrayOf(PropTypes.shape(StatItem.propTypes)).isRequired,
  className: PropTypes.string,
};

export default Stats;
