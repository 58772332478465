import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import cls from './TileText.module.css';

const cx = classNames.bind(cls);

const TileText = ({ children, className, limitTextWidth }) => (
  <p
    className={cx(
      'imui-tile-text',
      limitTextWidth ? 'large-tile-max-text-width' : null,
      className
    )}
  >
    {children}
  </p>
);
TileText.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  limitTextWidth: PropTypes.bool,
};

export default TileText;
