import StandardSyncActions from '../actionCreators/StandardSyncActions';
import UpdatePending from '../actionCreators/UpdatePending';
import UpdateQuery from '../actionCreators/UpdateQuery';

export const isType = (actionType, type) =>
  (actionType || '').split('/').pop() === type;
export const isUpdateQueryAction = (actionType) =>
  isType(actionType, UpdateQuery.actionType);
export const isPendingStatusAction = (actionType) =>
  isType(actionType, UpdatePending.actionType);
export const isStandardSyncAction = (actionType) =>
  Object.values(StandardSyncActions.actionTypes).some((type) =>
    isType(actionType, type)
  );
