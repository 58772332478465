import React from 'react';
import PropTypes from 'prop-types';
import path from 'ramda/src/path';
import { Table, Checkbox, Status } from 'src/components/IMUI';
import { Icon } from 'im/ui/Icon';

export default class GrantTableItem extends React.Component {
  static propTypes = {
    values: PropTypes.array,
    entity: PropTypes.object,
    selected: PropTypes.bool,
    multiSelected: PropTypes.bool,
    currentAdded: PropTypes.bool,
    previousAdded: PropTypes.bool,
    onRemove: PropTypes.func,
    onAdd: PropTypes.func,
    onToggleSelect: PropTypes.func,
  };
  shouldComponentUpdate(nextProps) {
    return (
      nextProps.selected !== this.props.selected ||
      nextProps.currentAdded !== this.props.currentAdded ||
      nextProps.previousAdded !== this.props.previousAdded
    );
  }
  renderStatus() {
    if (this.props.currentAdded && this.props.previousAdded)
      return <Status tip="currently added" done />;
    if (this.props.currentAdded && !this.props.previousAdded)
      return <Status tip="will be included after update" updated />;
    if (!this.props.currentAdded && this.props.previousAdded)
      return <Status tip="will be removed after update" alarming />;
    if (!this.props.currentAdded && !this.props.previousAdded)
      return <Status tip="Not Added" disabled />;
    return <Status tip="No change" disabled />;
  }
  getValueByPath = (key, fnTransf) => {
    const displayValue = []
      .concat(path(key.split('.'), this.props.entity) || [])
      .join(', ');
    return fnTransf?.(displayValue) || displayValue || '-';
  };
  onClickRow = (ev, checked) => {
    ev?.stopPropagation();
    ev?.preventDefault();
    this.props.onToggleSelect(this.props.entity, checked);
  };
  onClickIcon = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    this.props.currentAdded
      ? this.props.onRemove(this.props.entity)
      : this.props.onAdd(this.props.entity);
  };
  render() {
    return (
      <Table.Row>
        <Table.Cell
          width={24}
          onClick={(ev) => this.onClickRow(ev, !this.props.selected)}
        >
          {this.renderStatus()}
        </Table.Cell>
        <Table.Cell actions width={24}>
          {!this.props.multiSelected && (
            <span
              style={{ lineHeight: 2, cursor: 'pointer' }}
              onClick={this.onClickIcon}
            >
              {!this.props.currentAdded ? (
                <Icon tip="add" name="plus" />
              ) : (
                <Icon tip="remove" name="close" />
              )}
            </span>
          )}
          {this.props.multiSelected && (
            <span style={{ lineHeight: 2, cursor: 'pointer' }}>
              <Checkbox
                noLabel
                checked={this.props.selected}
                onCheck={(ev, isChecked) => this.onClickRow(ev, isChecked)}
              />
            </span>
          )}
        </Table.Cell>
        {this.props.values.map((settings) => (
          <Table.Cell key={settings.path} textAlign={settings.textAlign}>
            {this.getValueByPath(settings.path, settings.valueTransform)}
          </Table.Cell>
        ))}
      </Table.Row>
    );
  }
}
