import reducerRegistry from 'im/api/registry';
import Resource from 'im/api/Resource';

const apiConfig = {
  root: '/api/v3/passwords',
  type: 'passwords',
  api: {
    put: {
      path: ':resetToken',
      meta: { noSync: true },
    },
    custom: {
      requestResetToken: {
        method: 'POST',
        meta: { noSync: true },
      },
      requestResetTokenPublic: {
        method: 'POST',
        meta: { noSync: true },
      },
    },
  },
};

export const Password = new Resource(apiConfig, 'password');
reducerRegistry.register(Password);

export default Password.api;
