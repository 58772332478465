import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import tagCategoriesApi from 'src/api/TagCategories';
import AnalysisTagCategories from 'src/components/AddProjectForm/AnalysisTagCategories';
import { where } from 'im/api/Query';
import { bindActionCreators } from 'redux';
import { updateProject } from 'src/actionCreators/projectActionCreators';
import { Container, Actions, Button, Section } from 'src/components/IMUI';
import { Prompt } from 'react-router-dom';
import { canManageProject } from 'src/userStorage';

class AnalysisTagCategoriesEdit extends React.PureComponent {
  static propTypes = {
    organizationCurrent: PropTypes.object,
    tagCategories: PropTypes.object,
    tagCategoriesList: PropTypes.object,
    project: PropTypes.object,
    getTagCategories: PropTypes.func.isRequired,
    getTagCategoriesList: PropTypes.func.isRequired,
    updateProject: PropTypes.func.isRequired,
    skipPrompt: PropTypes.bool,
    submitSucceeded: PropTypes.bool,
  };

  state = {
    commited: false,
    tagCategoryId: null,
    searchText: '',
    enabled_tag_categories: undefined,
  };

  componentDidMount() {
    this.doRequesTagCategories();
    this.doRequestTagCategoriesList();
    if (!this.props.project.id) return;
    this.handleChange(this.props.project.enabled_tag_categories);
  }

  doRequesTagCategories = ({ searchText, tagCategoryId } = {}) => {
    const { organizationCurrent } = this.props;
    const query = where({ organizationId: organizationCurrent.data.id })
      .include('tags')
      .fields(
        'tag_category',
        'title',
        'tags',
        'child_categories',
        'parent',
        'code'
      )
      .fields('tag', 'title', 'code')
      .filter('parent_id_null', true)
      .sort('title')
      .paginate({ size: 2000 });
    if (searchText) {
      query.filter('nested_categories_with_tags_title_in_any', searchText);
    }
    if (tagCategoryId) {
      query.filter('id_eq_any', tagCategoryId);
    }
    this.props.getTagCategories(query);
  };

  doRequestTagCategoriesList = ({ searchText } = {}) => {
    const { organizationCurrent } = this.props;
    const query = where()
      .include('nested_child_categories')
      .filter('organization_id_eq', organizationCurrent.data.id)
      .fields(
        'tag_category',
        'title',
        'child_categories',
        'nested_child_categories',
        'parent',
        'code'
      )
      .filter('parent_id_null', true)
      .sort('title')
      .paginate({ size: 2000 })
      .actionMeta({
        includeMapper: { nested_child_categories: 'child_categories' },
      });

    if (searchText) {
      query.filter('nested_categories_title_in_any', searchText);
    }

    this.props.getTagCategoriesList(query);
  };

  handleCategoriesFilter = (searchText) => {
    this.doRequestTagCategoriesList({ searchText });
  };

  handleSearch = (searchText) => {
    const { tagCategoryId } = this.state;
    this.setState({ searchText });
    this.doRequesTagCategories({ searchText, tagCategoryId });
  };

  handleCategorySelect = (tagCategoryId) => {
    const { searchText } = this.state;
    this.setState({ tagCategoryId });
    this.doRequesTagCategories({ searchText, tagCategoryId });
  };

  handleChange = (payload) => {
    this.setState({ enabled_tag_categories: payload });
  };
  handleReset = () => {
    this.setState({
      enabled_tag_categories: this.props.project.enabled_tag_categories,
    });
  };
  handleSubmit = (ev) => {
    ev?.preventDefault();
    const enabled_tag_categories =
      this.state.enabled_tag_categories ||
      this.props.project.enabled_tag_categories;
    this.props
      .updateProject(this.props.project.id, {
        project: { enabled_tag_categories },
      })
      .then(() => this.setState({ commited: true }));
  };

  render() {
    const currentEnabled =
      this.state.enabled_tag_categories ||
      this.props.project.enabled_tag_categories ||
      [];
    const initialEnabled = this.props.project.enabled_tag_categories || [];
    const dataNewAdded =
      currentEnabled.filter((el) => !initialEnabled.includes(el)) || [];
    const dataNewRemoved =
      initialEnabled.filter((el) => !currentEnabled.includes(el)) || [];
    const isDirty = dataNewAdded.length > 0 || dataNewRemoved.length > 0;
    const submitLabel = isDirty
      ? `Update Tags (${dataNewAdded.length + dataNewRemoved.length} changes)`
      : 'Update Tags';
    const saveText = (len, text) =>
      `\n- There ${
        len > 1 ? `are ${len} items` : `is ${len} item`
      } ${text} but not saved.\nClick Update button to save it`;
    return (
      <div style={{ zIndex: 11 }}>
        <AnalysisTagCategories
          tagCategorySelectedId={this.state.tagCategoryId}
          tagCategoryFilterText={this.state.searchText}
          enabledTagCategories={currentEnabled}
          tagCategories={this.props.tagCategories}
          tagCategoriesList={this.props.tagCategoriesList}
          change={this.handleChange}
          onSearch={this.handleSearch}
          onCategorySelect={this.handleCategorySelect}
          onCategoriesFilter={this.handleCategoriesFilter}
          projectId={this.props.project.id}
        />
        {canManageProject() && (
          <Section type="sticky-footer" secondary={true}>
            <Container horizontal>
              <Actions />
              <Actions>
                {isDirty && (
                  <Button
                    size="l"
                    alert
                    negative
                    disabled={!canManageProject()}
                    label="Reset Changes"
                    onClick={this.handleReset}
                  />
                )}
                <Button
                  size="l"
                  label={submitLabel}
                  disabled={!canManageProject() || !isDirty}
                  onClick={this.handleSubmit}
                />
              </Actions>
            </Container>
            <Prompt
              when={
                isDirty &&
                !this.props.submitSucceeded &&
                !this.props.skipPrompt &&
                !this.state.commited
              }
              message={[
                'Are you sure you want to leave without saving?',
                dataNewAdded.length > 0
                  ? saveText(dataNewAdded.length, 'added')
                  : '',
                dataNewRemoved.length > 0
                  ? saveText(dataNewRemoved.length, 'removed')
                  : '',
              ]
                .join(' ')
                .trim()}
            />
          </Section>
        )}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    organizationCurrent: state.organizationCurrent,
    project: state.project,
    tagCategories: state.organizationTagCategories,
    tagCategoriesList: state.tagCategories,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        updateProject,
        getTagCategoriesList: tagCategoriesApi.findAll,
        getTagCategories: tagCategoriesApi.ofOrganization.findAllNestedWithTags,
      },
      dispatch
    )
)(AnalysisTagCategoriesEdit);
