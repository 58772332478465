import React from 'react';
import PropTypes from 'prop-types';
import FlexRow from 'src/components/FlexRow/FlexRow';
import {
  Divider,
  ChipInput,
  FormField,
  ReduxFormField,
  Actions,
} from 'src/components/IMUI';
import { KEYCODE_ENTER } from 'src/components/IMUI/Forms/ChipInput';
import ActionButtons from './ActionButtons';
import cls from './TagsQuickAdd.module.css';

export default class TagsQuickAdd extends React.PureComponent {
  static propTypes = {
    mode: PropTypes.oneOf(['quick', 'detailed', 'edit']),
    titles: PropTypes.array,
    onModeChange: PropTypes.func.isRequired,
  };
  static defaultProps = { titles: [] };

  render() {
    return (
      <div>
        <Divider className={cls.tagsQuickAddDivider} />
        <FormField>
          <ReduxFormField
            flat
            borderDark
            label={
              <FlexRow>
                <Actions>
                  <span>Tag names</span>
                </Actions>
                <Actions>
                  <small>
                    Press <code>enter</code> to confirm creating tag name.
                  </small>
                </Actions>
              </FlexRow>
            }
            newChipKeyCodes={[KEYCODE_ENTER]}
            type="array"
            name="tagNames"
            component={ChipInput}
            dataSource={this.props.titles}
            hintText="Type in tag name..."
          />
        </FormField>

        <ActionButtons
          mode={this.props.mode}
          onModeChange={this.props.onModeChange}
        />
      </div>
    );
  }
}
