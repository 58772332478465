import { where } from 'im/api/Query';
import reducerRegistry from 'im/api/registry';
import ResourceCollection from 'im/api/ResourceCollection';

const apiConfig = {
  root: '/api/v3/tag_category_tags',
  type: 'tag_category_tags',
  api: {
    allow: ['findAll', 'create', 'destroy'],
    custom: {
      findAllPerProject: {
        method: 'GET',
        path: where()
          .filter('tag_category_id_eq_any', ':projectTagCategoryIds')
          .toString(),
      },
    },
  },
};

export const TagCategoryTags = new ResourceCollection(
  apiConfig,
  'tagCategoryTags'
);
reducerRegistry.register(TagCategoryTags);

export default TagCategoryTags.api;
