import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import isEmail from 'validator/lib/isEmail';
import { showNotification } from 'src/actionCreators/notificationsActionCreators';
import {
  Button,
  Card,
  CardEmpty,
  Dialog,
  Table,
  TextField,
  Status,
} from 'src/components/IMUI';
import { Icon } from 'im/ui/Icon';
import cls from './ContactsModal.module.css';
import { canManageContact, canManageSurvey } from 'src/userStorage';
import TableComposition from 'src/components/IMUI/Tables/TableComposition';

@connect(null, { showNotification })
export default class CreateContactsModal extends React.Component {
  static propTypes = {
    open: PropTypes.bool,
    respondents: PropTypes.array,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    showNotification: PropTypes.func,
  };

  static defaultProps = { onCancel: () => void 0, onSubmit: () => void 0 };
  state = { contacts: [], candidate: {} };

  handleSubmitContacts = () => {
    this.props.onSubmit(this.state.contacts);
  };

  handleDeleteContact = (index) => {
    const contacts = [...this.state.contacts];
    contacts.splice(index, 1);
    this.setState({ contacts });
  };

  handleAddContact = () => {
    const { candidate } = this.state;
    if (!candidate.email || !isEmail(candidate.email)) {
      this.props.showNotification({
        title: 'Contact Adding Error',
        message: 'Contact requires a valid email address',
        level: 'error',
      });
      return;
    }
    this.setState({
      contacts: [candidate, ...this.state.contacts],
      candidate: {},
    });
  };

  handleCandidateNameChange = (name) => {
    this.setState({ candidate: { ...this.state.candidate, name } });
  };

  handleCandidateEmailChange = (email) => {
    this.setState({ candidate: { ...this.state.candidate, email } });
  };

  isAdded = (contact) => {
    return !!this.props.respondents.find(({ attributes }) => {
      return (
        contact.attributes &&
        contact.attributes.name === attributes.name &&
        contact.attributes.email === attributes.email
      );
    });
  };

  renderStatus = (status) => {
    return status ? <Status done /> : <Status disabled />;
  };

  renderContactsListItem = (contact, index) => {
    return (
      <Table.Row key={index}>
        <Table.Cell actions>
          {this.renderStatus(this.isAdded(contact))}
        </Table.Cell>
        <Table.Cell text={contact.name} />
        <Table.Cell text={contact.email} />
        <Table.Cell>
          {canManageContact() && (
            <Icon
              name="trash"
              tip="Remove"
              onClick={() => this.handleDeleteContact(index)}
            />
          )}
        </Table.Cell>
      </Table.Row>
    );
  };

  renderContactsList(contacts) {
    return (
      <Card>
        {!contacts.length ? (
          <CardEmpty>Add first contact using the form above.</CardEmpty>
        ) : (
          <TableComposition compact>
            <Table.Head>
              <Table.HCell width={46} text="Added" />
              <Table.HCell text="Name" />
              <Table.HCell text="Email" />
              <Table.HCell />
            </Table.Head>
            <Table.Body>{contacts.map(this.renderContactsListItem)}</Table.Body>
          </TableComposition>
        )}
      </Card>
    );
  }

  render() {
    const { open, onCancel } = this.props;
    const { contacts, candidate } = this.state;

    return (
      <Dialog
        extraLarge
        title={'Add new contacts'}
        rightActions={[
          <Button
            key="addAll"
            disabled={!canManageSurvey()}
            secondary
            size="l"
            label="Add contacts"
            onClick={this.handleSubmitContacts}
          />,
        ]}
        leftActions={
          <Button size="l" negative label="Cancel" onClick={onCancel} />
        }
        open={open}
        onRequestClose={onCancel}
      >
        <div className={cls.addContactForm}>
          <TextField
            noValidation
            name="name"
            placeholder="Name"
            value={candidate.name || ''}
            onChange={this.handleCandidateNameChange}
          />
          <TextField
            noValidation
            name="email"
            placeholder="Email"
            value={candidate.email || ''}
            onChange={this.handleCandidateEmailChange}
          />
          <Button
            size="m"
            label="Add contact"
            onClick={this.handleAddContact}
          />
        </div>
        <div>{this.renderContactsList(contacts)}</div>
      </Dialog>
    );
  }
}
