import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import { isValid, isDirty, hasSubmitSucceeded } from 'redux-form';
import { UploaderSecureMapping } from 'src/api/UploaderSecureMapping';
import Page from 'src/components/Page/Page';
import { getOrgText } from 'src/services/DictionaryService';
import Map from './Map';
import Report from './Report';
import Review from './Review';
import UploadCSV from './UploadCSV';

@connect(
  (state) => ({
    importUploadMapping: state.importUploadMapping,
    isFormValid: isValid('grantMappingForm')(state),
    isFormDirty: isDirty('grantMappingForm')(state),
    formSubmitSucceeded: hasSubmitSucceeded('grantMappingForm')(state),
  }),
  { clearPreviousFile: UploaderSecureMapping.actions.clear }
)
export default class GrantsImport extends React.PureComponent {
  static propTypes = {
    ...ReactRouterPropTypes,
    importUploadMapping: PropTypes.object,
    isFormValid: PropTypes.bool,
    isFormDirty: PropTypes.bool,
    formSubmitSucceeded: PropTypes.bool,
    clearPreviousFile: PropTypes.func,
  };

  componentDidMount() {
    this.props.clearPreviousFile();
  }
  render() {
    const canReview =
      Boolean(
        this.props.importUploadMapping.data?.grant_headers ||
          this.props.importUploadMapping.data?.grantee_headers ||
          this.props.importUploadMapping.data?.funding_headers
      ) &&
      this.props.isFormValid &&
      (!this.props.isFormDirty || this.props.formSubmitSucceeded);
    const steps = [
      { url: `${this.props.match.url}`, title: 'Import' },
      {
        url: `${this.props.match.url}/${this.props.importUploadMapping.data?.id}/map`,
        title: 'Map',
        disabled: !this.props.importUploadMapping.data?.id,
      },
      {
        url: `${this.props.match.url}/${this.props.importUploadMapping.data?.id}/review`,
        title: 'Review',
        disabled: !canReview,
      },
    ];
    return (
      <Page
        hasFooter
        back="/grants/add"
        title={
          this.props.importUploadMapping.data?.file_name ||
          getOrgText('Import grants')
        }
        activeStep={steps
          .map((e) => e.url)
          .indexOf(this.props.location.pathname)}
        steps={steps}
      >
        <Switch>
          <Route
            exact
            path={this.props.match.path}
            render={(props) => (
              <UploadCSV
                {...props}
                title={getOrgText('Upload grant, grantee and fundings data')}
                target_type="IM::Organization"
                type="Grant"
              />
            )}
          />
          <Route
            exact
            path={`${this.props.match.path}/:fileId/map`}
            component={Map}
          />
          <Route
            exact
            path={`${this.props.match.path}/:fileId/review`}
            component={Review}
          />
          <Route
            exact
            path={`${this.props.match.path}/:fileId/report`}
            component={Report}
          />
          <Redirect from="*" to="/error/404" />
        </Switch>
      </Page>
    );
  }
}
