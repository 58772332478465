import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Button, Divider } from '../IMUI';
import TagSelector from '../TagSelector/TagSelector';

import cls from './MergeTagForm.module.css';

const SelectTagsToMergeText = ({ title }) => (
  <span className={cls.mergeTagFormSelectTagsToMerge}>
    Select tags to merge into{' '}
    <b className={cls.mergeTagFormTagTitle}>{`"${title}"`}</b> tag name
  </span>
);

SelectTagsToMergeText.propTypes = {
  title: PropTypes.string.isRequired,
};

class MergeTagForm extends Component {
  state = {
    selectedTagIds: [],
  };

  handleAddSelectTag = (selectedTagIds) => {
    this.setState({ selectedTagIds });
  };

  handleRemoveSelectTag = (_, __, selectedTagIds) => {
    this.setState({ selectedTagIds });
  };

  handleMergeClick = () => {
    this.props.onMergeClick(this.state.selectedTagIds);
  };

  render() {
    const { onCancel } = this.props;
    let onClick;
    if (this.state.selectedTagIds.length) {
      onClick = this.handleMergeClick;
    }

    return (
      <div className={cls.mergeTagForm} data-qa="MergeTagForm:div">
        <TagSelector
          popoverClassName={cls.mergeTagSelectorPopover}
          multiple
          alt
          allowCreate={false}
          noTagProps={{
            label: <SelectTagsToMergeText title={this.props.tag.title} />,
            outline: true,
            className: cls.tagSelectorButton,
          }}
          onChange={this.handleAddSelectTag}
          onRemove={this.handleRemoveSelectTag}
          openOnTagClick={false}
        />
        <div className={cls.mergeTagFormFooter}>
          <Button secondary onClick={onCancel} label="Cancel" />
          <Button alert onClick={onClick} label="Merge Tags" />
        </div>
        <Divider className={cls.mergeTagFormDivider} />
      </div>
    );
  }
}

MergeTagForm.propTypes = {
  onMergeClick: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  tag: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default MergeTagForm;
