import {
  CHART__SAVE_FAILURE,
  CHART__SAVE_REQUEST,
  CHART__SAVE_SUCCESS,
  CHART__GET_FAILURE,
  CHART__GET_REQUEST,
  CHART__GET_SUCCESS,
  CHART__DELETE_FAILURE,
  CHART__DELETE_REQUEST,
  CHART__DELETE_SUCCESS,
  CHART__EDITED,
  CHART__CLEANUP_STORE,
} from 'src/constants';

const initialState = {
  pending: false,
  dirty: false,
  saving: false,
  error: null,
  data: {},
};

export default function chartReducer(state = initialState, { type, payload }) {
  switch (type) {
    case CHART__GET_REQUEST:
      return { ...state, pending: true };
    case CHART__GET_FAILURE:
      return { ...state, pending: false };
    case CHART__SAVE_REQUEST:
      return { ...state, saving: true };
    case CHART__SAVE_SUCCESS:
      return {
        ...state,
        data: payload.data,
        saving: false,
        pending: false,
        dirty: false,
      };
    case CHART__DELETE_REQUEST:
      return { ...state, saving: true };
    case CHART__SAVE_FAILURE:
    case CHART__DELETE_FAILURE:
      // handle error somehow?
      return { ...state, saving: true };
    case CHART__GET_SUCCESS:
      return {
        ...state,
        data: payload.data,
        saving: false,
        pending: false,
        dirty: false,
      };
    case CHART__DELETE_SUCCESS:
      return { ...initialState };
    case CHART__EDITED:
      return { ...state, dirty: true };
    case CHART__CLEANUP_STORE:
      return { ...initialState };
    default:
      return state;
  }
}
