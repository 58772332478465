// WARNING
// when using with fat arrow method assignment in components it's important
// to be aware that the way fat arrow methods are processed by babel is these
// methods get copied to constructor and assigned directly on instance
// because of that if we try to override the method ref in the constructor and debounce it there
// this will get overriden in build step and it will never work
export default function debounce(func, wait, immediate) {
  let timeout;
  const later = (context, args) => {
    timeout = null;
    if (!immediate) func.apply(context, args);
  };

  return function debounced(...args) {
    const callNow = immediate && !timeout;

    clearTimeout(timeout);
    timeout = setTimeout(() => later(this, args), wait);
    if (callNow) func.apply(this, args);
  };
}
