import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Button, Card, Dialog, Progress, Table } from 'src/components/IMUI';
import { getText } from 'src/services/DictionaryService';
import TableComposition from 'src/components/IMUI/Tables/TableComposition';
import cls from './ModalLinks.module.css';
const cx = classNames.bind(cls);

export default class ModalLinks extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    children: PropTypes.node,
    projectId: PropTypes.number,
    taggings: PropTypes.array,
    projectReports: PropTypes.array,
    onRequestTaggings: PropTypes.func,
    pending: PropTypes.bool,
  };
  state = { dialogOpen: false };
  onChildrenClick = () => {
    this.props.onRequestTaggings();
    this.setState({ dialogOpen: true });
  };
  onDialogRequestClose = () => {
    this.setState({ dialogOpen: false });
  };
  render() {
    return (
      <div className={cx('wrapper', this.props.className)}>
        <a className={cx('children')} onClick={this.onChildrenClick}>
          {this.props.children ?? this.props.title}
        </a>

        {this.state.dialogOpen && (
          <Dialog
            extraLarge
            title={this.props.title ?? getText('Grantees')}
            rightActions={
              <Button
                key="close"
                size="m"
                negative
                label="Close"
                onClick={this.onDialogRequestClose}
              />
            }
            open={this.state.dialogOpen}
            onRequestClose={this.onDialogRequestClose}
            bodyStyle={{ overflowY: 'scroll' }}
          >
            {this.props.pending && <Progress large className="absolute" />}

            <Card smallPadding>
              <TableComposition>
                {!this.props.pending &&
                  this.props.projectReports.length > 0 && (
                    <Table.Head>
                      <Table.HCell>Report name</Table.HCell>
                      <Table.HCell>{getText('Grantee')}</Table.HCell>
                      <Table.HCell textAlign="right">Taggings</Table.HCell>
                    </Table.Head>
                  )}
                <Table.Body>
                  {this.props.projectReports.map((pr) => {
                    const tagging = this.props.taggings.find(
                      (t) => pr.report?.id === t.report?.id
                    );
                    const totalTg = this.props.taggings.filter(
                      (t) => pr.report?.id === t.report?.id
                    ).length;
                    if (!tagging) return null;
                    return (
                      <Table.Row key={pr.id}>
                        <Table.Cell>
                          <a
                            href={`/analysis/${this.props.projectId}/reports/${pr.id}?start=${tagging.report_content_start}&end=${tagging.report_content_end}`}
                            className={cx('granteeLabel')}
                          >
                            {pr.report?.name}
                          </a>
                        </Table.Cell>
                        <Table.Cell>{pr.grantee?.name}</Table.Cell>
                        <Table.Cell textAlign="right">{totalTg}</Table.Cell>
                      </Table.Row>
                    );
                  })}

                  {!this.props.pending && this.props.taggings.length > 0 && (
                    <Table.Row key="total">
                      <Table.HCell>
                        <strong>Total</strong>
                      </Table.HCell>
                      <Table.HCell />
                      <Table.HCell textAlign="right">
                        <strong>{this.props.taggings.length}</strong>
                      </Table.HCell>
                    </Table.Row>
                  )}
                </Table.Body>
              </TableComposition>
            </Card>
          </Dialog>
        )}
      </div>
    );
  }
}
