import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Toggle, FormField } from 'src/components/IMUI';
import { Label } from 'src/components/IMUI/Forms/base';
import TooltipIcon from 'src/components/TooltipIcon';
import TranslateButton from 'src/components/TranslateButton/TranslateButton';
import {
  SINGLE_CHOICE,
  MULTIPLE_CHOICE,
  SHORT_TEXT,
} from 'src/data/questionTypes';
import { capitalize } from 'src/utils/string';
import { getSurveyI18n } from 'src/utils/surveysI18n';
import { Icon } from 'im/ui/Icon';
import { ConditionalLogicProperty } from './ConditionalLogicProperty';
import PropertyBox from './PropertyBox';
import cls from './BaseQuestionProperties.module.css';
import { canBuildSurvey } from 'src/userStorage';
const REQUIRED_TIP = 'Enable to make responses<br>to this question mandatory';
const IDENTIFY_TIP = 'Enable to identify Respondent based on this answer';
const ATTACHMENT_TIP =
  'Enable to allow users to upload one media file up to 10mb to this question';

export default class BaseQuestionProperties extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
    survey: PropTypes.object,
    question: PropTypes.object,
    completeness: PropTypes.object,
    errors: PropTypes.object,
    questionLabel: PropTypes.string,
    questionMultiline: PropTypes.number,
    contextLabel: PropTypes.string,
    hasRequired: PropTypes.bool,
    hasDescription: PropTypes.bool,
    hasConditionalLogic: PropTypes.bool,
    renderSettings: PropTypes.func,
    onChangeI18nProperty: PropTypes.func,
    onChangeQuestionProperty: PropTypes.func,
    handleOtherChoice: PropTypes.func,
    hasOther: PropTypes.bool,
  };

  static defaultProps = {
    questionLabel: 'question',
    contextLabel: 'question',
    questionMultiline: 1,
    hasRequired: true,
    hasDescription: true,
    hasConditionalLogic: true,
  };

  handleRequiredToggle = (ev, value) => {
    this.props.onChangeQuestionProperty(
      this.props.question.id,
      'required',
      value
    );
  };
  handleRespondentToggle = (ev, value) => {
    if (value == true) {
      this.props.survey?.questions
        ?.filter((q) => q.is_respondent)
        .forEach((question) =>
          this.props.onChangeQuestionProperty(
            question.id,
            'is_respondent',
            false
          )
        );
    }
    this.props.onChangeQuestionProperty(
      this.props.question.id,
      'is_respondent',
      value
    );
  };
  handleAttachmentToggle = (ev, value) => {
    this.props.onChangeQuestionProperty(
      this.props.question.id,
      'has_attachment',
      value
    );
  };

  render() {
    const {
      children,
      survey,
      question,
      questionLabel,
      hasDescription,
      errors,
      onChangeI18nProperty,
      completeness,
      questionMultiline,
    } = this.props;
    const {
      hasRequired,
      contextLabel,
      hasConditionalLogic,
      onChangeQuestionProperty,
      renderSettings,
      handleOtherChoice,
      hasOther,
    } = this.props;

    const questionTitle = getSurveyI18n(
      survey,
      question,
      'question',
      'question'
    );
    const questionDescription =
      hasDescription &&
      getSurveyI18n(survey, question, 'description', 'description');
    const showManageSettings =
      hasRequired || renderSettings || hasConditionalLogic;

    return (
      <div>
        <FormField>
          <section className={cls.propertyLabelWrapper}>
            <Label label={capitalize(questionLabel)} />
            <TranslateButton
              owner={question}
              languages={survey.languages}
              error={!!completeness.questions[question.id].question.length}
              values={question.i18n}
              path="question"
              altPath="question"
              title={`Translate ${questionLabel}`}
              onChange={onChangeI18nProperty}
              multiLine
            />
          </section>
          <TextField
            disabled={!canBuildSurvey()}
            autoFocus
            name="question"
            multiLine
            rows={questionMultiline}
            rowsMax={10}
            fullWidth
            flatDark
            hintText={!questionTitle && 'Type in...'}
            value={questionTitle}
            onChange={(value) =>
              onChangeI18nProperty(value, 'question', 'question')
            }
            error={errors.question}
          />
        </FormField>

        {hasDescription && (
          <FormField>
            <section className={cls.propertyLabelWrapper}>
              <Label label="Description" />
              <TranslateButton
                owner={question}
                languages={survey.languages}
                error={!!completeness.questions[question.id].description.length}
                values={question.i18n}
                path="description"
                altPath="description"
                multiLine
                title="Translate question description"
                onChange={onChangeI18nProperty}
              />
            </section>
            <TextField
              multiLine
              rows={3}
              rowsMax={10}
              fullWidth
              flatDark
              hintText={!questionDescription && 'Type in...'}
              name="description"
              className={cls.description}
              value={questionDescription || ''}
              onChange={(value) =>
                onChangeI18nProperty(value, 'description', 'description')
              }
              error={errors.description}
            />
          </FormField>
        )}

        {!!children && <section className={cls.settings}>{children}</section>}

        {showManageSettings && (
          <PropertyBox>
            <h3 className={cls.settings}>
              <Icon name="settings" />
              &nbsp;Settings
            </h3>
            {(question.type === SINGLE_CHOICE ||
              question.type === MULTIPLE_CHOICE) && (
              <Toggle
                mini
                label={<span>Enable Other option for comments</span>}
                labelPosition="right"
                toggled={hasOther}
                onToggle={handleOtherChoice}
              />
            )}
            {hasRequired && (
              <Toggle
                mini
                label={
                  <span>
                    Require an answer to this question
                    <TooltipIcon tip={REQUIRED_TIP} />
                  </span>
                }
                labelPosition="right"
                toggled={question.required}
                onToggle={this.handleRequiredToggle}
              />
            )}
            {question.type === SHORT_TEXT && (
              <Toggle
                mini
                label={
                  <span>
                    Use this response as the name in the Survey Table
                    <TooltipIcon tip={IDENTIFY_TIP} />
                  </span>
                }
                labelPosition="right"
                toggled={question.is_respondent}
                onToggle={this.handleRespondentToggle}
              />
            )}
            {this.props.renderSettings?.()}
            <Toggle
              mini
              label={
                <span>
                  Users can include one attachment for this question
                  <TooltipIcon tip={ATTACHMENT_TIP} id="has_attachment" />
                </span>
              }
              labelPosition="right"
              toggled={question.has_attachment}
              onToggle={this.handleAttachmentToggle}
            />
            <ConditionalLogicProperty
              survey={survey}
              question={question}
              contextLabel={contextLabel}
              onChangeQuestionProperty={onChangeQuestionProperty}
            />
          </PropertyBox>
        )}
      </div>
    );
  }
}
