import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Status, Table } from 'src/components/IMUI';
import reportTypes from 'src/data/reportTypes.json';
import { toLocaleDate } from 'src/utils/date';
import { dedummy } from 'src/utils/string';
import { Icon } from 'im/ui/Icon';
import { canManageReport } from 'src/userStorage';
import colors from 'src/css/constants.json';
import cls from './ReportItem.module.css';

const reportTypeNameForValue = reportTypes.reduce(
  (prev, next) => ({ ...prev, [next.value]: next.text }),
  {}
);

class ReportItem extends React.PureComponent {
  static propTypes = {
    report: PropTypes.object,
    noGrantAndGrantee: PropTypes.bool,
    onDeleteReport: PropTypes.func.isRequired,
    onEditReport: PropTypes.func.isRequired,
    projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  taggingCount() {
    return Number(
      this.props.report.meta?.tagging_counts[this.props.projectId] || 0
    );
  }

  renderStatus() {
    if (this.props.report.status === 'empty')
      return <Status tip="Failed to import" alarming />;
    if (this.projectReport().tagging_completed_at)
      return <Status tip="Tagging completed" done />;
    if (this.taggingCount() > 0)
      return <Status tip="Tagging in progress" updated />;
    return <Status tip="Not tagged" disabled />;
  }

  projectReport() {
    return (
      this.props.report.project_reports?.find(
        (pr) =>
          pr.report?.id == this.props.report.id &&
          pr.project_id == this.props.projectId
      ) ?? {}
    );
  }
  render() {
    return (
      <Table.Row>
        <Table.Cell actions>{this.renderStatus()}</Table.Cell>
        <Table.Cell>
          <Link
            style={{ color: colors.dark }}
            to={`/analysis/${this.props.projectId}/reports/${
              this.projectReport().id
            }`}
          >
            {this.props.report.name}
          </Link>
        </Table.Cell>
        <Table.Cell>
          {reportTypeNameForValue[this.props.report.report_type]}
        </Table.Cell>
        {this.props.noGrantAndGrantee ? null : (
          <Table.Cell>
            {dedummy((this.props.report.grantee || {}).name, '-')}
          </Table.Cell>
        )}
        {this.props.noGrantAndGrantee ? null : (
          <Table.Cell>
            {dedummy((this.props.report.grant || {}).name, '-')}
          </Table.Cell>
        )}
        <Table.Cell textAlign="right">{this.props.report.code}</Table.Cell>
        <Table.Cell textAlign="right">
          {this.props.report.period || this.props.report.year}
        </Table.Cell>
        <Table.Cell textAlign="right" className={cls.reportItemNoWrap}>
          {this.props.report.global_region}
        </Table.Cell>
        <Table.Cell textAlign="right" className={cls.reportItemNoWrap}>
          {toLocaleDate(this.props.report.date)}
        </Table.Cell>
        <Table.Cell textAlign="right" className={cls.reportItemNoWrap}>
          {toLocaleDate(this.props.report.created_at)}
        </Table.Cell>
        <Table.Cell textAlign="right" className={cls.reportItemNoWrap}>
          {this.taggingCount()}
        </Table.Cell>
        <Table.Cell actions icons className={cls.reportItemActions}>
          {canManageReport() && (
            <Icon
              name="settings"
              tip="Edit"
              onClick={() => this.props.onEditReport(this.props.report.id)}
            />
          )}
          {canManageReport() && (
            <Icon
              name="trash"
              tip="Remove"
              onClick={() => this.props.onDeleteReport(this.props.report.id)}
            />
          )}
        </Table.Cell>
      </Table.Row>
    );
  }
}

export default ReportItem;
