import { where } from 'im/api/Query';
import reducerRegistry from 'im/api/registry';
import ResourceCollection from 'im/api/ResourceCollection';

const apiConfig = {
  root: '/api/v3/tag_categories',
  type: 'tag_categories',
  api: {
    allow: ['findAll', 'create', 'put', 'destroy'],
    custom: {
      findAllAncestors: {
        method: 'GET',
        path: where()
          .include('nested_parent_categories', 'nested_parent_categories.tags')
          .toString(),
        meta: {
          includeMapper: {
            nested_parent_categories: [
              'child_categories',
              'parent',
              'parent.parent',
            ],
            'nested_parent_categories.tags': 'child_categories.tags',
          },
        },
      },
      findAllPerProject: {
        method: 'GET',
        path: where().filter('id_eq_any', ':projectTagCategoryIds').toString(),
      },
      findAllPerProjectNested: {
        method: 'GET',
        path: where()
          .include('nested_child_categories', 'nested_child_categories.tags')
          .filter('id_eq_any', ':projectTagCategoryIds')
          .toString(),
        meta: {
          includeMapper: {
            nested_child_categories: 'child_categories',
            'nested_child_categories.tags': 'child_categories.tags',
          },
        },
      },
    },
  },
};

export const TagCategoriesSelector = new ResourceCollection(
  apiConfig,
  'tagCategoriesSelector'
);
reducerRegistry.register(TagCategoriesSelector);

export const TagCategoriesSelectorList = new ResourceCollection(
  apiConfig,
  'tagCategoriesSelectorList'
);
reducerRegistry.register(TagCategoriesSelectorList);

export const TagCategoriesSelectorForm = new ResourceCollection(
  apiConfig,
  'tagCategoriesSelectorForm'
);
reducerRegistry.register(TagCategoriesSelectorForm);

export default {
  ...TagCategoriesSelector.api,
  list: TagCategoriesSelectorList.api,
  form: TagCategoriesSelectorForm.api,
};
