import classNames from 'classnames/bind';
import CircularProgress from 'material-ui/CircularProgress';
import PropTypes from 'prop-types';
import React from 'react';

import { Container } from '../';

import cls from './Progress.module.css';

const cx = classNames.bind(cls);

export const Progress = ({ large, size, className }) => {
  const conf = {
    color: '#52cccc',
    thickness: large ? 7 : 5,
    size: large ? 60 : size || 40,
  };

  return (
    <Container className={cx('imui-progress', className)}>
      <CircularProgress {...conf} />
    </Container>
  );
};

Progress.propTypes = {
  size: PropTypes.number,
  large: PropTypes.bool,
  className: PropTypes.string,
};

Progress.defaultProps = {
  size: 40,
};

export default Progress;
