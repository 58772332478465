import { clearCache } from 'src/actionCreators/surveyAnalysisActionCreators';
import axios from 'src/axiosInstance';
import {
  SURVEYS__GET_FAILURE,
  SURVEYS__GET_REQUEST,
  SURVEYS__GET_SUCCESS,
  SURVEY__ADD_QUESTION,
  SURVEY__MOVE_QUESTION,
  SURVEY__CHANGE_NAME,
  SURVEY__CHANGE_PROPERTY,
  SURVEY__QUESTION_CHANGE_PROPERTY,
  SURVEY__QUESTION_CHANGE_I18N,
  SURVEY__QUESTION_CLONE,
  SURVEY__QUESTION_DELETE,
  SURVEY__RESET,
  SURVEY__GET_FAILURE,
  SURVEY__GET_REQUEST,
  SURVEY__GET_SUCCESS,
  SURVEY__SAVE_FAILURE,
  SURVEY__SAVE_REQUEST,
  SURVEY__SAVE_SUCCESS,
  SURVEY__DUPLICATE_REQUEST,
  SURVEY__DUPLICATE_SUCCESS,
  SURVEY__DUPLICATE_FAILURE,
  SURVEY__ADD_QUESTION_TAG,
  SURVEY__REMOVE_QUESTION_TAG,
  SURVEY__DELETE_FAILURE,
  SURVEY__DELETE_REQUEST,
  SURVEY__DELETE_SUCCESS,
  SURVEY__REVIEW_GET_FAILURE,
  SURVEY__REVIEW_GET_REQUEST,
  SURVEY__REVIEW_GET_SUCCESS,
  SURVEY__REVIEW_SURVEY_ANSWER_POST_REQUEST,
  SURVEY__REVIEW_SURVEY_ANSWER_POST_SUCCESS,
  SURVEY__REVIEW_SURVEY_ANSWER_POST_FAILURE,
  SURVEY__REVIEW_SURVEY_ANSWER_PUT_REQUEST,
  SURVEY__REVIEW_SURVEY_ANSWER_PUT_SUCCESS,
  SURVEY__REVIEW_SURVEY_ANSWER_PUT_FAILURE,
  SURVEY__REVIEW_CHANGE_ANSWER_VALUE,
  SURVEY__REVIEW_CHANGE_ANSWER_TAGS,
  SURVEY__REVIEW_CHANGE_ANSWER_TAGGINGS,
  SURVEY__REVIEW_CHANGE_ANSWER_TAGGINGS_REMOVE,
  SURVEY__UPDATE_QUESTION,
  SURVEY__REVIEW_CHANGE_ANSWER_OTHER_CHOICE_VALUE,
  SURVEY_PUBLIC__PUT_SUCCESS,
  SURVEY_PUBLIC__PUT_FAILURE,
} from 'src/constants';
import { fromSurveyToApi } from 'src/serializers/surveySerializer';
import { capitalize } from 'src/utils/string';

export const addQuestion = (type, isLayout, position) => {
  return { type: SURVEY__ADD_QUESTION, payload: { type, isLayout, position } };
};
export const addQuestionTag = (questionId, tagId) => ({
  type: SURVEY__ADD_QUESTION_TAG,
  payload: { questionId, tagId },
});
export const changeName = (name, langCode) => ({
  type: SURVEY__CHANGE_NAME,
  payload: { name, langCode },
});
export const changeQuestionI18n = (
  question,
  value,
  path,
  altPath,
  langCode
) => ({
  type: SURVEY__QUESTION_CHANGE_I18N,
  payload: { question, value, path, altPath, langCode },
});
export const changeQuestionProperty = (
  questionId,
  propertyName,
  propertyValue,
  meta
) => ({
  type: SURVEY__QUESTION_CHANGE_PROPERTY,
  payload: { questionId, propertyName, propertyValue },
  meta,
});
export const changeReviewAnswerOtherChoiceValue = (
  questionId,
  otherValue,
  tagIds
) => ({
  type: SURVEY__REVIEW_CHANGE_ANSWER_OTHER_CHOICE_VALUE,
  payload: { questionId, otherValue, tagIds },
});
export const changeReviewAnswerTaggings = (questionId, taggings) => ({
  type: SURVEY__REVIEW_CHANGE_ANSWER_TAGGINGS,
  payload: { questionId, taggings },
});
export const changeReviewAnswerTaggingsRemove = (questionId, taggings) => ({
  type: SURVEY__REVIEW_CHANGE_ANSWER_TAGGINGS_REMOVE,
  payload: { questionId, taggings },
});
export const changeReviewAnswerTags = (questionId, tagIds, tags) => ({
  type: SURVEY__REVIEW_CHANGE_ANSWER_TAGS,
  payload: { questionId, tagIds, tags },
});
export const changeReviewAnswerValue = (questionId, value, tagIds) => ({
  type: SURVEY__REVIEW_CHANGE_ANSWER_VALUE,
  payload: { questionId, value, tagIds },
});
export const changeSurveyProperty = (name, value, meta = {}) => ({
  type: SURVEY__CHANGE_PROPERTY,
  payload: { name, value },
  meta,
});
export const cloneQuestion = (question, position) => ({
  type: SURVEY__QUESTION_CLONE,
  payload: { question, position },
});
export const deleteQuestion = (questionId) => ({
  type: SURVEY__QUESTION_DELETE,
  payload: questionId,
});
export const moveQuestion = (currentIndex, newIndex) => ({
  type: SURVEY__MOVE_QUESTION,
  payload: { currentIndex, newIndex },
});
export const removeQuestionTag = (questionId, tagId) => ({
  type: SURVEY__REMOVE_QUESTION_TAG,
  payload: { questionId, tagId },
});
export const resetSurvey = () => ({ type: SURVEY__RESET });
export const updateQuestion = (questionId, newQuestion) => ({
  type: SURVEY__UPDATE_QUESTION,
  payload: { questionId, newQuestion },
});
export const updateGranteeSurvey =
  ({ id, permalink, isFinished }) =>
  async () => {
    const doPutGranteeSurvey = (link, payload) =>
      axios
        .put(`/v1/survey/${link}`, payload)
        .then((response) => ({ response }))
        .catch((error) => ({ error }));
    const { response, error } = await doPutGranteeSurvey(permalink, {
      grantee_survey: { id, is_finished: isFinished },
    });
    return response ? response : error;
  };

const doDeleteSurvey = (surveyId, projectId) =>
  axios
    .delete(`/v1/projects/${projectId}/surveys/${surveyId}`)
    .then(() => ({ response: { id: surveyId } }))
    .catch((error) => ({ error }));
const doDuplicateSurvey = (surveyId, projectId) =>
  axios
    .post(`/v3/surveys/${surveyId}/duplicate`, { project_id: projectId })
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
const doGetSurvey = (id, projectId) =>
  axios
    .get(`/v1/projects/${projectId}/surveys/${id}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
const doGetSurveyReview = (projectId, granteeSurveyId) =>
  axios
    .get(`/v1/projects/${projectId}/grantee_surveys/${granteeSurveyId}/answers`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
const doGetSurveys = (projectId) =>
  axios
    .get(`/v1/projects/${projectId}/surveys`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
const doPostSurvey = (survey, projectId) =>
  axios
    .post(`/v1/projects/${projectId}/surveys/`, {
      survey: fromSurveyToApi(survey),
    })
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
const doPostSurveyAnswer = (payload) =>
  axios
    .post('/v1/survey_answers/', payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
const doPutSurvey = (survey, projectId) =>
  axios
    .put(`/v1/projects/${projectId}/surveys/${survey.id}`, {
      survey: fromSurveyToApi(survey),
    })
    .then((response) => ({ response }))
    .catch((error) => {
      if (Object.keys(error.response?.data?.errors || {}).length > 0) {
        const errorMessages = error.response.data.errors;
        error.message = Object.values(errorMessages)
          .flat()
          .map((err) => (typeof err === 'string' ? err : Object.values(err)))
          .flat()
          .join(', ');
      }

      return {
        error,
      };
    });
const doPutSurveyAnswer = (surveyAnswerId, payload) =>
  axios
    .put(`/v1/survey_answers/${surveyAnswerId}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));

export const createSurveyAnswer = (payload) => async (dispatch) => {
  dispatch({ type: SURVEY__REVIEW_SURVEY_ANSWER_POST_REQUEST });
  const { response, error } = await doPostSurveyAnswer(payload);
  response
    ? dispatch({
        type: SURVEY__REVIEW_SURVEY_ANSWER_POST_SUCCESS,
        payload: response,
      })
    : dispatch({
        type: SURVEY__REVIEW_SURVEY_ANSWER_POST_FAILURE,
        payload: error,
        error: true,
      });
  dispatch(clearCache());
  return response;
};
export const deleteSurvey = (survey, projectId) => async (dispatch) => {
  dispatch({ type: SURVEY__DELETE_REQUEST });
  const { response, error } = await doDeleteSurvey(survey, projectId);
  response
    ? dispatch({ type: SURVEY__DELETE_SUCCESS, payload: response })
    : dispatch({ type: SURVEY__DELETE_FAILURE, payload: error, error: true });
};
export const duplicateSurvey = (surveyId, projectId) => async (dispatch) => {
  dispatch({ type: SURVEY__DUPLICATE_REQUEST });
  const { response, error } = await doDuplicateSurvey(surveyId, projectId);
  response
    ? dispatch({ type: SURVEY__DUPLICATE_SUCCESS, payload: response })
    : dispatch({
        type: SURVEY__DUPLICATE_FAILURE,
        payload: error,
        error: true,
      });
};
export const getSurvey = (surveyId, projectId, meta) => async (dispatch) => {
  dispatch({ type: SURVEY__GET_REQUEST, meta });
  const { response, error } = await doGetSurvey(surveyId, projectId);
  response
    ? dispatch({ type: SURVEY__GET_SUCCESS, payload: response, meta })
    : dispatch({
        type: SURVEY__GET_FAILURE,
        payload: error,
        error: true,
        meta,
      });
};
export const getSurveyReview =
  (projectId, granteeSurveyId, background) => async (dispatch) => {
    dispatch({
      type: SURVEY__REVIEW_GET_REQUEST,
      meta: { background, silent: background },
    });
    const { response, error } = await doGetSurveyReview(
      projectId,
      granteeSurveyId
    );
    response
      ? dispatch({ type: SURVEY__REVIEW_GET_SUCCESS, payload: response })
      : dispatch({
          type: SURVEY__REVIEW_GET_FAILURE,
          payload: error,
          error: true,
        });
  };
export const getSurveys = (projectId) => async (dispatch) => {
  dispatch({ type: SURVEYS__GET_REQUEST });
  const { response, error } = await doGetSurveys(projectId);
  response
    ? dispatch({ type: SURVEYS__GET_SUCCESS, payload: response })
    : dispatch({ type: SURVEYS__GET_FAILURE, payload: error, error: true });
};
export const saveSurvey =
  (survey, projectId, silent = false) =>
  async (dispatch) => {
    dispatch({ type: SURVEY__SAVE_REQUEST });
    const doRequest = survey.id ? doPutSurvey : doPostSurvey;
    const { response, error } = await doRequest(survey, projectId);
    response
      ? dispatch({
          type: SURVEY__SAVE_SUCCESS,
          payload: response,
          meta: { silent, projectId },
        })
      : dispatch({ type: SURVEY__SAVE_FAILURE, payload: error, error: true });
  };
export const updateSurveyAnswer =
  (surveyAnswerId, payload) => async (dispatch) => {
    dispatch({ type: SURVEY__REVIEW_SURVEY_ANSWER_PUT_REQUEST });
    const { response, error } = await doPutSurveyAnswer(
      surveyAnswerId,
      payload
    );
    response
      ? dispatch({
          type: SURVEY__REVIEW_SURVEY_ANSWER_PUT_SUCCESS,
          payload: response,
        })
      : dispatch({
          type: SURVEY__REVIEW_SURVEY_ANSWER_PUT_FAILURE,
          payload: error,
          error: true,
        });
    dispatch(clearCache());
  };
