import cssVars from 'src/css/constants.json';

export const injectCSSVariables = () => {
  const variablesStylesheet = document.createElement('style');
  const parsedCSSVars = Object.keys(cssVars).map((key) => {
    return `--${key}: ${cssVars[key]};`;
  });
  variablesStylesheet.innerHTML = `:root {
    ${parsedCSSVars.join('\n')}
  }`;

  document.head.appendChild(variablesStylesheet);
};
