import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field } from 'redux-form';
import isEmail from 'validator/lib/isEmail';

import { TextField, FormField, Container } from 'src/components/IMUI/index';
import createValidator from 'src/utils/validation';

import { Icon } from 'im/ui/Icon';

import cls from './InvitationItem.module.css';
import { canManageMember, canManageOrganization } from 'src/userStorage';

export function validateInvitationItem(values = {}) {
  const not = (fn) => (v) => !fn(v);
  const isDefined = (v) => Boolean(v);

  const validator = createValidator({
    // fullName: [[not(isDefined), () => "Full name is required"],
    // [not(longerThan(1)), () => "Full name is too short"]],
    email: [
      [not(isDefined), () => 'Email is required'],
      [not(isEmail), () => 'It is not a valid email'],
    ],
  });

  return validator(values);
}

const cx = classNames.bind(cls);

export default class InvitationItem extends Component {
  static propTypes = {
    member: PropTypes.string,
    index: PropTypes.number,
    fields: PropTypes.object,
  };

  onItemRemove = () => {
    const { fields, index } = this.props;
    fields.remove(index);
  };

  render() {
    const { member, index } = this.props;

    return (
      <li>
        <Container
          horizontal
          nowrap
          centered
          className={cx('invitation-item-container')}
        >
          <span>{`${index + 1}.`}</span>
          <FormField className={cx('invitation-item-input', 'space-right')}>
            <Field
              fullWidth
              label="Full name"
              component={TextField}
              name={`${member}.fullName`}
              hintText="Type in..."
            />
          </FormField>
          <FormField className={cx('invitation-item-input')}>
            <Field
              fullWidth
              type="email"
              label="Email"
              component={TextField}
              name={`${member}.email`}
              hintText="Type in..."
            />
          </FormField>
          {(canManageMember() || canManageOrganization()) && (
            <Icon name="trash" tip="Remove" onClick={this.onItemRemove} />
          )}
        </Container>
      </li>
    );
  }
}
