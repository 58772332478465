import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import tagCategoriesApi, {
  tagCategoriesGetAdvancedFilters,
} from 'src/api/TagCategories';
import reportContentExportApi from 'src/api/ReportContentExport';
import tagCategoriesSelectorApi from 'src/api/TagCategoriesSelector';
import tagsApi from 'src/api/Tags';
import CreateTag from 'src/components/CreateTag/CreateTag';
import EditTag from 'src/components/CreateTag/EditTag';
import EditTagCategory from 'src/components/EditTagCategory/EditTagCategory';
import {
  Button,
  Card,
  Section,
  FilterJsonapi,
  FilterTrigger,
  CardFooter,
  Container,
  Actions,
  PopoverLight,
  TabSegments,
  CardEmpty,
} from 'src/components/IMUI';
import PaginationIm from 'src/components/IMUI/Pagination/PaginationIm';
import history from 'src/historyInstance';

import { where } from 'im/api/Query';
import { Icon } from 'im/ui/Icon';

import CategoryTree from './components/CategoryTree';
import CreateTagCategory from './components/CreateTagCategory';
import TagsTable from './components/TagsTable';
import { canManageTag } from 'src/userStorage';
import ExportConfirmation from 'src/components/ExportConfirmation/ExportConfirmation';

@connect(
  (state) => ({
    tags: state.projectTags,
    tagCategories: state.projectTagCategories,
    project: state.project,
    advancedFilters: state.advancedFilters.tagCategories,
  }),
  {
    getTagCategories: tagCategoriesApi.ofProject.findAllNestedWithTags,
    updateQuery: tagCategoriesApi.ofProject.findAllNested.updateQuery,
    getTags: tagsApi.ofProject.findAll,
    destroyTag: tagsApi.ofProject.destroy,
    destroyTagCategory: tagCategoriesApi.ofProject.destroy,
    getTagSelectorTagCategories:
      tagCategoriesSelectorApi.findAllPerProjectNested,
    getTagsExport: reportContentExportApi.tags,
    getOrganizationTagsExport: reportContentExportApi.tags_for_organization,
    ...tagCategoriesGetAdvancedFilters,
  }
)
export default class TagsEdit extends React.PureComponent {
  static propTypes = {
    match: ReactRouterPropTypes.match,
    location: PropTypes.object,

    getTagCategories: PropTypes.func.isRequired,
    getTagsExport: PropTypes.func.isRequired,
    updateQuery: PropTypes.func.isRequired,
    destroyTag: PropTypes.func.isRequired,
    destroyTagCategory: PropTypes.func.isRequired,
    getTags: PropTypes.func.isRequired,

    project: PropTypes.object,
    tagCategories: PropTypes.object,
    advancedFilters: PropTypes.object,
    tagCategoryIds: PropTypes.array,
    tags: PropTypes.object,
    getTagSelectorTagCategories: PropTypes.func.isRequired,
  };

  state = {
    createTagVisible: false,
    createTagGroupVisible: false,
    showFilterCreator: false,
    reloadTagCategories: false,
    tagCategory: null,
    isTableView: false,
    allExpanded: true,
    showCodes: true,
    exportModalShow: false,
    per_page: 30,
  };

  componentDidMount() {
    if (!this.props.project.id) return;
    this.doRequestTagCategories(this.props.project.enabled_tag_categories, {
      combineUrlParams: true,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.project.id !== this.props.project.id) {
      this.doRequestTagCategories(nextProps.project.enabled_tag_categories, {
        combineUrlParams: !this.props.project.id,
      });
    }

    // After creating a tag group, we have to wait until project.enabled_tag_categories are synced
    if (
      this.state.reloadTagCategories &&
      this.props.project.id &&
      nextProps.project.enabled_tag_categories !==
        this.props.project.enabled_tag_categories
    ) {
      this.setState({ reloadTagCategories: false });
      this.doRequestTagCategories(nextProps.project.enabled_tag_categories, {
        combineUrlParams: !this.props.project.id,
      });
    }

    if (nextProps.tagCategories.data !== this.props.tagCategories.data) {
      this.doRequestTags(nextProps.tagCategories, nextProps.tagCategoryIds);
    }
  }

  get filterConfig() {
    return [
      {
        name: 'nested_categories_title',
        displayName: 'Tag Group',
        type: 'text',
        matchers: ['in_any'],
      },
      {
        name: 'nested_tags_title',
        displayName: 'Tag Name',
        type: 'text',
        matchers: ['in_any'],
      },
      {
        name: 'metatags',
        displayName: 'Metatags',
        type: 'array',
        values: this.props.project.metatags,
      },
    ];
  }

  get filterAutopopulationConfig() {
    return {
      allow: ['nested_categories_title', 'nested_tags_title'],
      resource: this.props.advancedFilters,
      onRequestOptions: this.handleRequestFilterOptions,
    };
  }

  doRequestTags = (tagCategories, tagCategoryIds) => {
    const { queryParams = {} } = tagCategories.meta.getAction || {};
    const tagKeys = Object.keys(queryParams.filter || {}).filter(
      (key) =>
        key.includes('nested_tags_title') && !!(queryParams.filter || {})[key]
    );
    const query = where({ projectTagCategoryIds: tagCategoryIds })
      .fields('title')
      .sort('title')
      .filter('parent_id_null', true)
      .paginate({ size: 1000 }); // TODO this looks like a trap :(

    tagKeys?.forEach((tKey) =>
      query.filter(
        tKey.replace('nested_tags_title', 'title'),
        (queryParams.filter || {})[tKey]
      )
    );
    this.props.getTags(query);
  };

  doRequestTagCategories = (
    projectTagCategoryIds,
    { combineUrlParams = true } = {}
  ) => {
    if (!projectTagCategoryIds?.length) return;
    const query = where({ projectTagCategoryIds })
      .filter('parent_id_null', true)
      .paginate({ size: this.state.per_page })
      .sort('title');
    if (combineUrlParams) {
      query.fromString(this.props.location.search);
    }
    this.props.getTagCategories(query);
  };

  handleAddTagToTagCategory = (tagCategory) => {
    this.setState({ createTagVisible: true, tagCategory });
  };

  handleCreateTagRequest = () => {
    this.setState({ createTagVisible: true, popoverOpen: false });
  };

  handleCreateTagGroupRequest = () => {
    this.setState({ createTagGroupVisible: true, popoverOpen: false });
  };

  closeDialogs = () => {
    this.setState({
      createTagVisible: false,
      createTagGroupVisible: false,
      anchorEl: null,
      popoverOpen: false,
      tagCategory: null,
    });
  };

  handlePaginationChange = ({ page, per_page }) => {
    this.setState({ per_page });
    this.props.updateQuery(where().paginate({ number: page, size: per_page }));
  };

  handleSortChange = (sortKey) => {
    this.props.updateQuery(where().sort(sortKey).paginate({ number: 1 }));
  };

  handleToggleFilter = (showFilterCreator) => {
    this.setState({ showFilterCreator });
  };

  handleFilterApply = (filterValues) => {
    this.props.updateQuery(
      where().filter(filterValues).paginate({ number: 1 })
    );
  };

  handleClearFilter = (filterValues) => {
    this.props.updateQuery(
      where().filter(filterValues).actionMeta({ doDelete: true })
    );
  };

  handleRequestFilterOptions = (key, value) => {
    this.props[`tagCategoriesAF_${key}`](
      { key, value },
      where({ tagCategoryIds: this.props.tagCategoryIds })
    );
  };

  handleCreatedTagCategoryReload = () => {
    this.setState({ reloadTagCategories: true });
  };

  handleDeleteTag = (tag) =>
    EditTag.getDeleteConfirmation(tag)
      .then(async () => {
        await this.props.destroyTag({ id: tag.id });
        this.props.getTagSelectorTagCategories();
        this.doRequestTagCategories(this.props.project.enabled_tag_categories);
      })
      .catch(() => void 0);

  handleDeleteTagCategory = (tagCategory) =>
    EditTagCategory.getDeleteConfirmation(
      tagCategory,
      this.props.tags.data.filter(({ tag_categories }) =>
        tag_categories.find(({ id }) => id === tagCategory.id)
      )
    )
      .then(async () => {
        await this.props.destroyTagCategory({ id: tagCategory.id });
        this.props.getTagSelectorTagCategories();
      })
      .catch(() => void 0);

  handleEdit = (editType, entityId) => {
    history.replace({
      pathname: `/analysis/${this.props.match.params.projectId}/tags/edit/${editType}/${entityId}`,
      search: this.props.location.search,
    });
  };

  handleEditTagFromCreatePane = (id) => {
    this.setState({ createTagVisible: false, anchorEl: null });
    this.handleEdit('tag', id);
  };

  handleEditTagCategoryFromCreatePane = (id) => {
    this.setState({ createTagGroupVisible: false, anchorEl: null });
    this.handleEdit('category', id);
  };

  handleEditClose = () => {
    history.replace({
      pathname: `/analysis/${this.props.match.params.projectId}/tags/edit`,
      search: this.props.location.search,
    });
  };

  handleCreateEntityRequest = (ev, tagCategory) => {
    ev?.preventDefault();
    this.setState({
      popoverOpen: true,
      anchorEl: ev.currentTarget,
      tagCategory,
    });
  };

  handleViewTypeChange = () => {
    this.setState({ isTableView: !this.state.isTableView });
  };

  handleReloadData = () => {
    this.props.getTagSelectorTagCategories();
    this.doRequestTagCategories(this.props.project.enabled_tag_categories);
  };

  renderTree() {
    return (
      <CategoryTree
        tagCategories={this.props.tagCategories}
        tags={this.props.tags}
        onCreateEntityRequest={this.handleCreateEntityRequest}
        onEditCategory={(id) => this.handleEdit('category', id)}
        onEditTag={(id) => this.handleEdit('tag', id)}
        allExpanded={this.state.allExpanded}
        showCodes={this.state.showCodes}
      />
    );
  }

  renderTagsTable() {
    return (
      <TagsTable
        tagCategories={this.props.tagCategories}
        tags={this.props.tags}
        onAddTagToTagCategory={this.handleAddTagToTagCategory}
        onEdit={this.handleEdit}
        onCreateTagRequest={this.handleCreateTagRequest}
        onSortChange={this.handleSortChange}
        onDeleteTag={this.handleDeleteTag}
        onDeleteTagCategory={this.handleDeleteTagCategory}
        onReloadData={this.handleReloadData}
        allExpanded={this.state.allExpanded}
        showCodes={this.state.showCodes}
      />
    );
  }

  renderNoTags() {
    return (
      <CardEmpty title="There are no tags">
        <Button
          disabled={!canManageTag()}
          secondary
          label="Create a Tag"
          onClick={this.handleCreateTagRequest}
        />
      </CardEmpty>
    );
  }
  handleToggleExpandAll = () => {
    this.setState({ allExpanded: !this.state.allExpanded });
  };
  handleToggleShowCodes = () => {
    this.setState({ showCodes: !this.state.showCodes });
  };
  handleCloseExportModal = () => {
    this.setState({ exportModalShow: false });
  };
  handleExport = () => {
    this.props
      .getTagsExport(where({ projectId: this.props.match.params.projectId }))
      .then(() => this.setState({ exportModalShow: true }));
  };

  render() {
    const initialLoaded = !this.props.tagCategories.pending.init; //  == undefined init first false, then true, then undefined
    const showEditTag =
      this.props.match.params.editType === 'tag' &&
      !!this.props.match.params.entityId;
    const showEditTagCategory =
      this.props.match.params.editType === 'category' &&
      !!this.props.match.params.entityId;
    const segments = [
      {
        id: false,
        text: <Icon name="conditional" />,
        active: !this.state?.isTableView,
        tooltipText: 'Tree View',
      },
      {
        id: true,
        text: <Icon name="table" />,
        active: this.state?.isTableView,
        tooltipText: 'Table View',
      },
    ];

    return (
      <div>
        <Section type="sub-header" collapsed>
          <Container horizontal>
            <Actions grow>
              <FilterTrigger
                active={this.state.showFilterCreator}
                filters={
                  this.props.tagCategories.meta.getAction?.queryParams.filter
                }
                config={this.filterConfig}
                onToggle={() =>
                  this.handleToggleFilter(!this.state.showFilterCreator)
                }
              />
              <span style={{ display: 'flex', marginLeft: 6 }}>
                &ensp;
                <Icon placeTip="bottom" name="collapse" color="#626264" />
                &ensp;
                <Button
                  text
                  size="l"
                  label={this.state.allExpanded ? 'Collapse all' : 'Expand all'}
                  onClick={this.handleToggleExpandAll}
                />
              </span>
              <span style={{ display: 'flex', marginLeft: 6 }}>
                &ensp;
                <Icon
                  placeTip="bottom"
                  name={this.state.showCodes ? 'text-field' : 'number'}
                  color="#626264"
                />
                &ensp;
                <Button
                  text
                  size="l"
                  label={this.state.showCodes ? 'Hide Codes' : 'Show Codes'}
                  onClick={this.handleToggleShowCodes}
                />
              </span>
            </Actions>
            <Actions grow style={{ justifyContent: 'center' }}>
              <TabSegments
                compact
                flat
                dark
                segments={segments}
                onToggle={this.handleViewTypeChange}
              />
            </Actions>
            <Actions grow>
              <Button
                secondary
                disabled={!this.props.tagCategories.data.length}
                size="l"
                label="Export"
                onClick={this.handleExport}
              />
              <Button
                disabled={!canManageTag()}
                label="Create"
                size="l"
                onClick={this.handleCreateEntityRequest}
              />
            </Actions>
          </Container>
        </Section>

        <Section noBorder>
          <FilterJsonapi
            showCreator={this.state.showFilterCreator}
            filters={
              this.props.tagCategories.meta.getAction?.queryParams.filter
            }
            config={this.filterConfig}
            configAutopopulation={this.filterAutopopulationConfig}
            onToggleCreator={this.handleToggleFilter}
            onClear={this.handleClearFilter}
            onSubmit={this.handleFilterApply}
          />
          <div
            style={{ position: 'relative', padding: 8 }}
            ref={(ref) => (this.innerRef = ref)}
          />
          <Card>
            {initialLoaded &&
              !this.props.tagCategories.data.length &&
              this.renderNoTags()}
            {!!this.props.tagCategories.data.length &&
              this.state.isTableView &&
              this.renderTagsTable()}
            {!!this.props.tagCategories.data.length &&
              !this.state.isTableView &&
              this.renderTree()}
            <CardFooter>
              <PaginationIm
                {...this.props.tagCategories.links.meta}
                label="tag groups"
                items_label="tag groups"
                onChange={this.handlePaginationChange}
              />
            </CardFooter>
          </Card>
        </Section>

        {this.state.createTagVisible && (
          <CreateTag
            noSync
            defaultMode="detailed"
            open={this.state.createTagVisible}
            initialValues={{ category: this.state.tagCategory }}
            onTagEdit={this.handleEditTagFromCreatePane}
            onRequestClose={this.closeDialogs}
            onSuccessfulAdd={this.handleReloadData}
          />
        )}
        {this.state.createTagGroupVisible && (
          <CreateTagCategory
            noSync
            open={this.state.createTagGroupVisible}
            initialValues={{ parent: this.state.tagCategory }}
            onTagCategoryEdit={this.handleEditTagCategoryFromCreatePane}
            onRequestClose={this.closeDialogs}
            onSuccessfulAdd={this.handleCreatedTagCategoryReload}
          />
        )}
        {showEditTag && (
          <EditTag
            noSync
            open
            urlParam={this.props.match.params.entityId}
            tagId={this.props.match.params.entityId}
            onRequestClose={this.handleEditClose}
            onSuccessfulEdit={this.handleReloadData}
            onDeleteTag={() =>
              this.doRequestTagCategories(
                this.props.project.enabled_tag_categories
              )
            }
          />
        )}
        {showEditTagCategory && (
          <EditTagCategory
            open
            urlParam={this.props.match.params.entityId}
            categoryId={this.props.match.params.entityId}
            onSuccessfulEdit={this.handleCreatedTagCategoryReload}
            onRequestClose={this.handleEditClose}
          />
        )}

        <PopoverLight
          anchorEl={this.state.anchorEl ?? this.innerRef}
          open={this.state.popoverOpen}
          customWidth={300}
          anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
          targetOrigin={{ horizontal: 'left', vertical: 'center' }}
          canAutoPosition={true}
          onRequestClose={this.closeDialogs}
          header={<span>Create</span>}
        >
          <PopoverLight.Menu>
            <PopoverLight.MenuItem
              primaryText="Tag Group"
              leftIcon={<Icon name="folder" />}
              onClick={this.handleCreateTagGroupRequest}
            />
            <PopoverLight.MenuItem
              primaryText="Tag"
              leftIcon={<Icon name="tag" />}
              onClick={this.handleCreateTagRequest}
            />
          </PopoverLight.Menu>
        </PopoverLight>
        <ExportConfirmation
          open={this.state.exportModalShow}
          onRequestClose={this.handleCloseExportModal}
        />
      </div>
    );
  }
}
