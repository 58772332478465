import PropTypes from 'prop-types';
import isNil from 'ramda/src/isNil';
import React from 'react';
import { Field } from 'redux-form';

const FIELD_TYPES = { array: 'array', date: 'date' };
const format = (value, defaultValue) => (isNil(value) ? defaultValue : value);
const FIELD_TYPE_FORMAT = {
  [FIELD_TYPES.array]: (value) => format(value, []),
  [FIELD_TYPES.date]: (value) => value,
};
export const ReduxFormField = ({ type, defaultValue, ...rest }) => (
  <Field
    format={(value) =>
      FIELD_TYPE_FORMAT[type]?.(value) ?? format(value, defaultValue)
    }
    {...rest}
  />
);
ReduxFormField.propTypes = {
  type: PropTypes.oneOf(Object.values(FIELD_TYPES)),
  defaultValue: PropTypes.any,
};
ReduxFormField.defaultProps = { defaultValue: '' };
export default ReduxFormField;
