import reducerRegistry from 'im/api/registry';
import ResourceCollection from 'im/api/ResourceCollection';
import { where } from 'im/api/Query';

const apiConfig = {
  root: '/v3/grantee_surveys',
  type: 'grantee_surveys',
  api: {
    allow: ['findAll', 'put', 'destroy'], // find available
  },
};
const custom = {
  findRespondents: {
    method: 'GET',
    path: where()
      .fields(
        'grantee_survey',
        'id',
        'grant_name',
        'grantee_name',
        'respondent_email',
        'respondent_name',
        'is_finished',
        'batch'
      )
      .paginate({ number: 0, size: 10000 })
      // .filter("is_finished_eq", false)
      .filter('survey_id_eq', ':surveyId')
      .sort(
        '-is_finished',
        '-finished_at',
        'id',
        '-is_notified',
        '-notified_at',
        '-created_at'
      )
      .toString(),
  },
};

export const GranteeSurveys = new ResourceCollection(
  apiConfig,
  'granteeSurveys'
);
reducerRegistry.register(GranteeSurveys);

export const GranteeSurveysSummary = new ResourceCollection(
  apiConfig,
  'granteeSurveysSummary'
);
reducerRegistry.register(GranteeSurveysSummary);

export const GranteeSurveysResponses = new ResourceCollection(
  { ...apiConfig, api: { custom: custom } },
  'granteeSurveysResponses'
);
reducerRegistry.register(GranteeSurveysResponses);

export default {
  ...GranteeSurveys.api,
  summary: GranteeSurveysSummary.api,
  responses: GranteeSurveysResponses.api,
};
