import { getGrantsFilters } from 'src/actionCreators/grantsFiltersActionCreators';
import { GRANT__UPDATE_SUCCESS } from 'src/constants';

export default function grantEffects(state, action, dispatch) {
  switch (action.type) {
    case GRANT__UPDATE_SUCCESS:
      dispatch(getGrantsFilters());
      break;
    default:
      break;
  }
}
