import QueryParamsManager from '../QueryParamsManager';

const getPageQueryParams = (url) =>
  !QueryParamsManager.hasQueryParams(url || '')
    ? {}
    : QueryParamsManager.get(url, 'page');

const getNumberParam = (params, key, defaultValue = 1) =>
  Number(params[key]) || defaultValue;

const normalizeLinks = (links = {}) => {
  if (!links.self) {
    return links;
  }

  const PAGE_NUMBER = 'number';
  const PAGE_SIZE = 'size';

  const currentParams = getPageQueryParams(links.self) || {};
  const lastParams =
    getPageQueryParams(links.last || links.next || links.self) || {};
  const firstParams =
    getPageQueryParams(links.first || links.prev || links.self) || {};

  const paginationCords = {
    number: getNumberParam(currentParams, PAGE_NUMBER),
    size: getNumberParam(currentParams, PAGE_SIZE, 30),
    first: getNumberParam(firstParams, PAGE_NUMBER),
    last: getNumberParam(lastParams, PAGE_NUMBER),
  };

  return {
    ...links,
    meta: paginationCords,
  };
};

export default normalizeLinks;
