import reducerRegistry from 'im/api/registry';
import Resource from 'im/api/Resource';

const apiConfig = {
  root: '/api/v3/users',
  type: 'users',
  api: {
    allow: ['put', 'find'],
    custom: {
      changeCurrentOrganization: { method: 'PATCH', path: ':id' },
      changePermissions: { method: 'PATCH', path: ':id' },
    },
  },
  actions: {
    authenticated: (type, userId, token) => (dispatch) =>
      dispatch({ type, payload: { userId, token } }),
    logOut: (type) => (dispatch) => dispatch({ type }),
  },
};

export const User = new Resource(apiConfig, 'user');
reducerRegistry.register(User);

export default User.api;
