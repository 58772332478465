import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import cls from './FlexColumn.module.css';

class FlexColumn extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    size: PropTypes.number,
    style: PropTypes.object,
    neighbourSpacing: PropTypes.bool,
  };

  static defaultProps = {
    size: 1,
    style: {},
  };

  getWidth() {
    return this.props.style.width || `${this.props.size * 100}%`;
  }

  render() {
    const { className, neighbourSpacing, ...rest } = this.props;
    return (
      <div
        {...rest}
        style={{
          ...this.props.style,
          width: this.getWidth(),
        }}
        className={cx(
          'flexColumn',
          {
            flexColumnNeighbourSpacing: neighbourSpacing,
          },
          cls.wrapper,
          className
        )}
      >
        {this.props.children}
      </div>
    );
  }
}

export default FlexColumn;
