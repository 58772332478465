import classNames from 'classnames/bind';
import React from 'react';

import { Tooltip } from '../IMUI/Tooltip/Tooltip';

import cls from './AppcuesTooltip.module.css';

const cx = classNames.bind(cls);

export const AppcuesTooltip = ({ className, ...rest }) => (
  <Tooltip {...rest} className={cx(cls.helpAppcuesTooltip, className)} />
);

AppcuesTooltip.propTypes = {
  ...Tooltip.props,
};
