import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';

import List from './List/List';
import Survey from './Survey';

export default class SurveysIndex extends Component {
  static propTypes = {
    ...ReactRouterPropTypes,
  };

  render() {
    const { match } = this.props;
    const rootPath = match.path;

    return (
      <Switch>
        <Route path={`${rootPath}/list`} component={List} exact />
        <Route path={`${rootPath}/:surveyId`} component={Survey} />
        <Redirect from="*" to={`${match.url}/list`} />
      </Switch>
    );
  }
}
