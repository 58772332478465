import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import identity from 'ramda/src/identity';
import isEmpty from 'ramda/src/isEmpty';
import pickBy from 'ramda/src/pickBy';
import { connect } from 'react-redux';
import { FieldArray, arrayPush, clearFields, touch, change } from 'redux-form';
import {
  Container,
  SelectField,
  Button,
  EmptyText,
  Actions,
} from 'src/components/IMUI/index';
import CustomArrayFieldItem from './CustomArrayFieldItem';
import cls from './CustomArrayFields.module.css';
import { canManageOrganization } from 'src/userStorage';
const PREVIEW_TYPES = { dropdown: 'dropdown', list: 'list' };
const cx = classNames.bind(cls);

export function validateCustomArray(values = {}, name) {
  const not = (fn) => (v) => !fn(v);
  const isDefined = (v) => Boolean(v);
  const customArray = values[name] || [];
  const validator = (arr) =>
    arr.map((el) => not(isDefined)(el) && 'Value cannot be empty');
  return validator(customArray);
}

@connect(null, { arrayPush, clearFields, touch, change })
export default class CustomArrayFields extends React.PureComponent {
  static propTypes = {
    form: PropTypes.string,
    hintText: PropTypes.string,
    formErrors: PropTypes.object,
    items: PropTypes.array,
    name: PropTypes.string,
    title: PropTypes.string,
    touch: PropTypes.func,
    arrayPush: PropTypes.func,
    change: PropTypes.func,
    previewType: PropTypes.oneOf(Object.values(PREVIEW_TYPES)),
    defaultValues: PropTypes.array,
  };

  static defaultProps = {
    items: [],
    defaultValues: [],
    formErrors: {},
    previewType: PREVIEW_TYPES.dropdown,
  };
  state = { manageInProgress: false };

  onSubmit = () => {
    if (
      !isEmpty(pickBy(identity, this.props.formErrors[this.props.name] || {}))
    ) {
      const fieldsNames = this.props.items.reduce(
        (acc, el, index) => acc.concat(`${this.props.name}[${index}]`),
        []
      );
      this.props.touch(this.props.form, ...fieldsNames);
      return;
    }
    this.setState({ manageInProgress: false });
  };
  onManageItemsRequest = () => {
    if (!this.props.items.length) {
      this.onAddItem();
    }
    this.setState({
      manageInProgress: true,
      initialItems: [...this.props.items],
    });
  };
  onCancel = () => {
    this.props.change(
      this.props.form,
      `${this.props.name}`,
      this.state.initialItems
    );
    this.setState({ manageInProgress: false });
  };
  onAddItem = () => {
    this.props.arrayPush(this.props.form, `${this.props.name}`, '');
  };
  onRestoreDefaults = () => {
    this.props.change(
      this.props.form,
      `${this.props.name}`,
      this.props.defaultValues
    );
  };

  renderEditableList = ({ fields }) => {
    const { hintText, defaultValues, items } = this.props;

    return (
      <ol className={cx('list-items')}>
        {fields.map((member, index, i) => (
          <CustomArrayFieldItem
            key={`${member}_${index}`}
            member={member}
            index={index}
            fields={i}
            hintText={hintText}
          />
        ))}
        <li className={cx('list-item-action', 'buttons-separated')}>
          <Container horizontal className={cx('flex-items-end')}>
            {!defaultValues.length ? null : (
              <Button
                negative
                disabled={defaultValues === items}
                label="Restore defaults"
                onClick={this.onRestoreDefaults}
              />
            )}
            <Button secondary label="Add more" onClick={this.onAddItem} />
          </Container>
        </li>
      </ol>
    );
  };

  renderPreviewDropdown = (items, name, title) => (
    <SelectField
      fullWidth
      hintText={`Added ${(title || '').toLowerCase()}`}
      name={name}
      value={items[0]}
    >
      {items.map((f) => (
        <SelectField.Item key={f} value={f} primaryText={f} />
      ))}
    </SelectField>
  );

  renderPreviewList(items) {
    return <div>{items.join(', ')}</div>;
  }

  renderPreview = () => {
    if (!this.props.items.length) {
      return (
        <EmptyText text={`No ${(this.props.title || '').toLowerCase()}`} />
      );
    }
    switch (this.props.previewType) {
      case PREVIEW_TYPES.dropdown:
        return this.renderPreviewDropdown(
          this.props.items,
          this.props.name,
          this.props.title
        );
      case PREVIEW_TYPES.list:
        return this.renderPreviewList(this.props.items);
      default:
        return this.renderPreviewDropdown(
          this.props.items,
          this.props.name,
          this.props.title
        );
    }
  };

  render() {
    return (
      <div
        className={cx('custom-array-field', {
          'custom-array-fields-full-width': this.state.manageInProgress,
        })}
      >
        <Container horizontal className={cx('custom-array-fields-header')}>
          <h4>{this.props.title}</h4>

          {!this.state.manageInProgress ? (
            <Button
              disabled={!canManageOrganization()}
              text
              label="Manage"
              onClick={this.onManageItemsRequest}
            />
          ) : (
            <Actions>
              <Button negative label="CANCEL" onClick={this.onCancel} />
              <Button label="SAVE" onClick={this.onSubmit} />
            </Actions>
          )}
        </Container>
        {!this.state.manageInProgress ? (
          this.renderPreview()
        ) : (
          <FieldArray
            name={this.props.name}
            component={this.renderEditableList}
          />
        )}
      </div>
    );
  }
}
