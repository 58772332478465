class PayloadNormalization {
  constructor(included, includeFilter, maxDepth = 25) {
    this.included = included || [];
    this.includeFilter = [].concat(includeFilter);
    this.maxDepth = maxDepth; // Maximum depth for drilling down
  }

  drillDown = (payload, parentType, type, depth = 0) => {
    if (depth >= this.maxDepth) return payload.data;

    const shouldDrilldown =
      this.includeFilter.includes(`${String(parentType)}.${type}`) ||
      this.includeFilter.includes(type);
    if (!shouldDrilldown) return payload.data;

    const relationships = Array.isArray(payload.data)
      ? payload.data
          .map((rel) => this.included.find((i) => i.id === rel.id))
          .filter(Boolean)
      : this.included.find((i) => i.id === payload.data?.id);

    const isEmpty =
      !relationships ||
      (Array.isArray(relationships) && relationships.length === 0);
    return isEmpty
      ? payload.data
      : this.formatJsonapi(relationships, type, depth + 1);
  };

  jsonapiFlatten = (payload, parent, depth = 0) => {
    return Object.keys(payload || {}).reduce((obj, type) => {
      return {
        ...obj,
        [type]: this.drillDown(payload[type], parent, type, depth),
      };
    }, {});
  };

  formatJsonapi = (payload, type, depth = 0) => {
    if (!payload) return undefined;

    return Array.isArray(payload)
      ? payload.map((item) => this.formatJsonapi(item, type, depth + 1))
      : {
          id: payload.id,
          meta: payload.meta,
          ...payload.attributes,
          ...this.jsonapiFlatten(payload.relationships, type, depth),
        };
  };
}

export default (data, included, includeFilter) =>
  new PayloadNormalization(included, includeFilter).formatJsonapi(data);
