import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import cls from './BackButton.module.css';

const cx = classNames.bind(cls);

export const BackButton = ({ to }) => {
  const onClick = () => !to && window.history.back();
  const Anchor = !to ? 'a' : Link;

  return (
    <Anchor className={cx('imui-back-button')} to={to} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
      >
        <path
          fill="none"
          strokeWidth="1.75"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M16.14 9.93L22.21 16l-6.07 6.07M8.23 16h13.98"
        />
      </svg>
    </Anchor>
  );
};

BackButton.propTypes = {
  to: PropTypes.string,
};

export default BackButton;
