import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Icon } from 'im/ui/Icon';

import Cell from './Cell';

import cls from './tables.module.css';

const cx = classNames.bind(cls);

export default class HeaderCell extends PureComponent {
  static propTypes = {
    ...Cell.propTypes,
    sortBy: PropTypes.string,
    cellSortedActive: PropTypes.object,
    sortTransform: PropTypes.func,
    onSortChange: PropTypes.func,
    className: PropTypes.string,
  };

  static defaultProps = {
    header: true,
    cellSortedActive: {},
  };

  onCellClick = (...params) => {
    const { sortBy } = this.props;

    if (sortBy) {
      this.onSortClick();
    }
    if (this.props.onClick) {
      this.props.onClick(...params);
    }
  };

  onSortClick = () => {
    const {
      sortBy,
      sortTransform,
      cellSortedActive: { direction },
    } = this.props;

    const newDirection = this.isActive ? !direction : true;
    this.props.onSortChange(sortBy, newDirection, sortTransform);
  };

  get isActive() {
    const {
      sortBy,
      cellSortedActive: { key: keyActive, direction: directionActive },
    } = this.props;
    return (
      keyActive === sortBy || (!directionActive && keyActive === `-${sortBy}`)
    );
  }

  renderSortable() {
    const {
      text,
      children,
      cellSortedActive: { direction: directionActive },
    } = this.props;

    return (
      <div className={cx('imui-table-header-cell-sort-content')}>
        <span>{text || children}</span>
        <div className={cx('imui-table-sort-icons')}>
          <Icon
            name="filled-up"
            className={cx('imui-table-sort-icon', {
              'imui-table-sort-icon-active': this.isActive && directionActive,
            })}
          />

          <Icon
            name="filled-down"
            className={cx('imui-table-sort-icon', {
              'imui-table-sort-icon-active': this.isActive && !directionActive,
            })}
          />
        </div>
      </div>
    );
  }

  render() {
    const { sortBy, className, children, text, ...rest } = this.props;

    return (
      <Cell
        {...rest}
        className={cx('imui-table-header-cell-sortable', className)}
        text={sortBy ? undefined : text}
        onClick={this.onCellClick}
      >
        {sortBy ? this.renderSortable() : children}
      </Cell>
    );
  }
}
