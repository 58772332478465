export const textMatchers = {
  eq: 'equals',
  not_eq: 'not equal to',

  cont: 'contains',
  not_cont: "doesn't contain",
  start: 'starts with',
  not_start: "doesn't start with",
  end: 'ends with',
  not_end: "doesn't end with",
  in_any: 'matches',
};
export const numberMatchers = {
  eq: 'equals',
  not_eq: 'not equal to',

  lt: 'less than',
  lteq: 'less than or equal to',
  gt: 'greater than',
  gteq: 'greater than or equal to',
};
export const selectMatchers = {
  //We can add "in_any" here if we'd like to include matches again in the filters.
  eq: 'equals',
  not_eq: 'not equal to',
};
export const booleanMatchers = {
  true: 'is true',
  false: 'is false',
  present: 'is present',
  blank: 'is blank',
  null: 'is null',
  not_null: 'is not null',
};
export const arrayMatchers = {
  contains: 'equals',
};
const countMatchers = {
  count: 'count',
};
export const dateMatchers = {
  lt: 'less than',
  gt: 'greater than',
};

export const andPredicate = 'all';
export const orPredicate = 'any';

export const autopopulationTriggers = ['eq', 'not_eq', 'in_any', 'contains']; //Without being added here it won't autopopulate for these matchers

export const scopeValues = [
  { name: 'quote', displayName: 'Quote' },
  { name: 'content', displayName: 'Tags' },
];
export const taggableValues = [
  { name: 'Report', displayName: 'Report' },
  { name: 'SurveyAnswer', displayName: 'Survey' },
];

export default {
  ...textMatchers,
  ...numberMatchers,
  ...selectMatchers,
  ...booleanMatchers,
  ...dateMatchers,
  ...arrayMatchers,
  ...countMatchers,
};
