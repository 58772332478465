class SyncActionsGenerator {
  _getAllSyncActions(actions, defaultActions) {
    return { ...actions, ...defaultActions };
  }

  makeActions(configActions = {}, actionTypeRoot, defaultActions) {
    const allSyncActions = this._getAllSyncActions(
      configActions,
      defaultActions
    );

    if (!allSyncActions) {
      return {};
    }

    return Object.keys(allSyncActions).reduce(
      (acc, actionName) => ({
        ...acc,
        [actionName]: {
          type: `${actionTypeRoot}/${actionName}`,
          action: configActions[actionName] || defaultActions[actionName],
        },
      }),
      {}
    );
  }
}

export default new SyncActionsGenerator();
