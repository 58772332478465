import React from 'react';
import { T } from 'lioness';
import PropTypes from 'prop-types';
import { NumberField, CardFooter, Container, Tag } from 'src/components/IMUI';
import createQuestion from './createQuestion';
import { TagNotProvided } from './TextQuestion';
import UploadAttachment from 'src/components/Survey/UploadAttachment';
const isNumeric = (v) => v != null && !isNaN(Number(v)) && isFinite(Number(v));

@createQuestion
export default class NumericalQuestion extends React.PureComponent {
  static propTypes = {
    answer: PropTypes.object,
    question: PropTypes.object,
    surveyAnswers: PropTypes.object,
    isReview: PropTypes.bool,
    isEditing: PropTypes.bool,
    onRefresh: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    readOnly: PropTypes.bool,
    index: PropTypes.number,
    isAnswerMode: PropTypes.bool,
  };

  static validate(question, _value) {
    if (typeof _value === 'object' && _value !== null)
      return <T>Unexpected value type</T>;
    const parsed = String(_value ?? '')?.trim();
    const shouldValidate = question.required;
    if (parsed.length == 0 && shouldValidate) return <T>Missing answer</T>;
    if (parsed.length > 0 && !isNumeric(parsed)) {
      return <T>Provide valid value</T>;
    }
    return null;
  }

  onChange(value) {
    this.props.onChange?.(value);
  }
  // only in review mode, always read-only
  renderTag() {
    if (!this.props.isReview) return null;
    return (
      <CardFooter minimal className="printHidden">
        <Container horizontal>
          {!this.props.answer.question_taggings.length &&
            this.props.isEditing && <TagNotProvided />}
          {this.props.answer.question_taggings?.map((tagging) => (
            <Tag label={tagging.tag.title} key={tagging.id} />
          ))}
        </Container>
      </CardFooter>
    );
  }

  render() {
    const { value, readOnly, index, isAnswerMode } = this.props;
    return (
      <div>
        <NumberField
          name={`numerical_${index}`}
          value={value}
          onChange={(v) => this.onChange(v)}
          readOnly={readOnly}
          noValidation
          flat={!isAnswerMode}
          min={this.props.question.positiveOnly ? 0 : undefined}
          border={!isAnswerMode}
          flatDark={isAnswerMode}
        />
        {this.renderTag()}

        {this.props.question.has_attachment && this.props.isAnswerMode && (
          <CardFooter noPadding className="printHidden">
            <UploadAttachment
              isReview={false}
              questionId={this.props.question.id}
              surveyAnswerId={this.props.answer.uid}
              importUpload={this.props.answer.import_upload}
              projectId={this.props.surveyAnswers.info?.projectId}
              granteeSurveyId={this.props.surveyAnswers.info?.grantee_survey_id}
              onRefresh={this.props.onRefresh}
            />
          </CardFooter>
        )}
      </div>
    );
  }
}
