import React from 'react';
import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import flatten from 'ramda/src/flatten';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';
import {
  getProject,
  getProjectGrantees,
} from 'src/actionCreators/projectActionCreators';
import {
  createReport,
  editReport,
  importTrintDocuments,
} from 'src/actionCreators/reportActionCreators';
import reportsApi, { reportsGetAdvancedFilters } from 'src/api/Reports';
import ReportUploadApi from 'src/api/ReportUpload';
import { confirm } from 'src/components/ConfirmModal/ConfirmModal';
import {
  Actions,
  Button,
  Card,
  CardFooter,
  Container,
  Dialog,
  Section,
  FilterTrigger,
  FilterJsonapi,
  Tag,
  Status,
} from 'src/components/IMUI';
import PaginationIm from 'src/components/IMUI/Pagination/PaginationIm';
import Page from 'src/components/Page/Page';
import REPORT_TYPES from 'src/data/reportTypes.json';
import history from 'src/historyInstance';
import { getOrgText } from 'src/services/DictionaryService';
import {
  canAutoCode,
  canManageReport,
  hasTrintApiKey,
  canImportFromTrint,
  surveyOnly,
} from 'src/userStorage';
import { isGrantless } from 'src/utils/analysis';
import { dummyGrant, dummyGrantee } from 'src/utils/string';
import { where } from 'im/api/Query';
import { Icon } from 'im/ui/Icon';
import ReportList from './components/ReportList';
import UploadReportForm, { FORM_NAME } from './components/UploadReportForm';
import SyncReportForm from './components/SyncReportForm';
import { UpgradeComponent } from 'src/pages/App/Analysis/Upgrade/Upgrade';
import { formatYYYYMMDDRaw } from 'src/utils/date';
import Stats from 'src/components/Stats/Stats';
import ImportConfirmation from 'src/components/ImportConfirmation/ImportConfirmation';

const getReportTypeValue = (v) =>
  (REPORT_TYPES.find(({ text }) => text === v) || {}).value;
const getReportTypeText = (v) =>
  (REPORT_TYPES.find(({ value }) => value === v) || {}).text;

@connect(
  pick([
    'project',
    'reports',
    'user',
    'organizationCurrent',
    'advancedFilters',
  ]),
  {
    getReports: reportsApi.findAllPerProject,
    reportsUpdateQuery: reportsApi.findAllPerProject.updateQuery,
    deleteReport: reportsApi.destroy,
    initializeForm: initialize,
    uploadReport: ReportUploadApi.uploadReport,
    createReport,
    editReport,
    getProject,
    getProjectGrantees,
    importTrintDocuments,
    ...reportsGetAdvancedFilters,
  }
)
export default class ProjectReports extends React.PureComponent {
  static propTypes = {
    getReports: PropTypes.func.isRequired,
    reportsUpdateQuery: PropTypes.func.isRequired,

    uploadReport: PropTypes.func.isRequired,
    createReport: PropTypes.func.isRequired,
    editReport: PropTypes.func.isRequired,
    deleteReport: PropTypes.func.isRequired,
    importTrintDocuments: PropTypes.func.isRequired,
    getProject: PropTypes.func.isRequired,
    getProjectGrantees: PropTypes.func.isRequired,
    initializeForm: PropTypes.func.isRequired,

    match: PropTypes.object,
    location: PropTypes.object.isRequired,
    reports: PropTypes.object,
    advancedFilters: PropTypes.object,
    organizationCurrent: PropTypes.object,
    project: PropTypes.object,
  };
  state = {
    modalVisible: false,
    reportToEdit: null,
    showFilterCreator: false,
    allGrants: [],
  };
  static defaultProps = { project: {} };

  componentDidMount() {
    this.doRequestData(true);
    this.props.getProjectGrantees(this.props.match.params.projectId).then(() =>
      this.setState({
        allGrants: flatten(
          (this.props.project.grantees || [])?.map(
            ({ id: grantee_id, grants }) =>
              (grants || [])?.map((grant) => ({ ...grant, grantee_id }))
          )
        ),
      })
    );
  }
  componentDidUpdate() {
    if (window.resetState == 'reload') {
      window.resetState = undefined;
      this.doRequestData(true);
      this.props.getProject(this.props.match.params.projectId);
    }
  }

  filterConfig = () =>
    [
      { name: 'name', displayName: 'Name', type: 'text' },
      !isGrantless(this.props.project) && {
        name: 'grant_name',
        displayName: getOrgText('Grant Name'),
        type: 'select',
        values: Array.from(
          new Set(
            (this.state.allGrants ?? [])
              .filter((g) => g.name !== dummyGrant)
              .map((g) => g.name)
          )
        ).map((name) => ({ name })),
      },
      !isGrantless(this.props.project) && {
        name: 'grantee_name',
        displayName: getOrgText('Grantee Name'),
        type: 'select',
        values: Array.from(
          new Set(
            (this.props.project?.grantees ?? [])
              .filter((g) => g.name !== dummyGrantee)
              .map((g) => g.name)
          )
        ).map((name) => ({ name })),
      },
      {
        name: 'report_type',
        displayName: 'Type',
        type: 'text',
        transformBeforeRequest: (v) => getReportTypeValue(v),
        transformResponse: (v) => getReportTypeText(v),
      },
      { name: 'period', displayName: 'Period', type: 'text' },
      { name: 'code', displayName: 'Code', type: 'text' },
      { name: 'date', displayName: 'Date', type: 'date' },
      { name: 'global_region', displayName: 'Region', type: 'text' },
      {
        name: 'project_reports_tagging_completed_at',
        displayName: 'In progress',
        type: 'boolean',
        matchers: { ['null']: 'is true' },
        transformMatcher: (_) => {
          return '';
        },
        transformResponse: (_) => {
          if (_ == 'false') return 'false';
          if (_ == 'true') return 'true';
          return 'is true';
        },
      },
      {
        name: 'total_taggings',
        displayName: 'Untagged',
        matchers: { ['null']: 'true' },
        transformMatcher: (_) => '',
        type: 'boolean',
        transformResponse: (_) => 'is true',
        transformKeyBeforeRequest: (_) => 'total_taggings_count',
        transformBeforeRequest: () => '2',
      },
    ].filter(Boolean);

  filterAutopopulationConfig = () => ({
    allow: ['name', 'report_type', 'period', 'global_region'],
    report_type: {
      transformValues: (values) =>
        values.map(
          (v) => (REPORT_TYPES.find(({ value }) => value === v) || {}).text
        ),
    },
    resource: this.props.advancedFilters.reports,
    onRequestOptions: this.handleRequestFilterOptions,
  });

  doRequestData = (init = false) => {
    const query = where({ project_id: this.props.match.params.projectId })
      .distinct()
      .include('grant', 'grantee', 'project_reports')
      .sort('-created_at')
      .fields('grantee', 'name')
      .fields('grant', 'name')
      .fields(
        'report',
        'uid',
        'created_at',
        'updated_at',
        'name',
        'date',
        'report_type',
        'period',
        'code',
        'global_region',
        'strategies_list',
        'status',
        'year',
        'grant',
        'grantee',
        'project_reports',
        'enabled_tag_categories',
        'visible_tag_categories',
        'taggings_count'
      );
    if (init) {
      query.fromString(this.props.location.search);
      query.paginate({ number: 1, size: 30 });
    }
    this.props.getReports(query);
  };

  handleUploadSubmit = (values) => {
    const reader = new FileReader();
    return new Promise((resolve) => {
      reader.onload = (e) => {
        this.props
          .createReport({
            report: {
              name: values.name,
              date: formatYYYYMMDDRaw(values.date),
              report_type: values.report_type,
              period: values.period,
              code: values.code,
              project_ids: [parseInt(this.props.match.params.projectId, 10)],
              grantee_id: values.granteeId || values.grantee_id,
              grant_id: values.grantId || values.grant_id,
              global_region: values.global_region,
              original_document: {
                file_name: values.file[0].name,
                file_contents: btoa(e.target.result),
                file_content_type:
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
              },
            },
          })
          .then((report) => {
            this.props.uploadReport(
              where().payloadMultipart(
                {
                  target_id: report?.uid,
                  target_type: 'report',
                  organization_id: this.props.organizationCurrent.data.id,
                  asset: values.file[0],
                },
                'report_upload'
              )
            );
            resolve(true);
            this.doRequestData();
            this.setState({ modalVisible: false });
          });
      };
      reader.readAsBinaryString(values.file[0]);
    });
  };

  handleTrintImportSubmit = (values) => {
    if (Object.keys(values).length === 0) {
      this.setState({ modalVisible: false });
      return;
    }

    this.props
      .importTrintDocuments({
        ...values,
        project_id: this.props.project.id,
      })
      .then(() => {
        this.doRequestData(true);
        this.setState({ modalVisible: false });
        this.setState({ importModalShow: true });
      });
  };

  handleEditSubmit = (payload) => {
    this.props
      .editReport(this.state.reportToEdit, {
        report: {
          id: payload.project_report?.id,
          project_id: payload.project_report?.project_id,
          name: payload.name,
          date: formatYYYYMMDDRaw(payload.date),
          report_type: payload.report_type,
          period: payload.period,
          code: payload.code,
          global_region: payload.global_region,
          grantee_id: payload.grantee_id,
          grant_id: payload.grant_id,
        },
      })
      .then(() => {
        this.doRequestData();
        this.setState({ modalVisible: false });
      });
  };

  handleDeleteReport = (reportId) => {
    const report = this.props.reports.data.find((r) => r.id === reportId);
    confirm(
      'Delete Report',
      <div>
        {report?.taggings_count > 0 && (
          <p>
            This report overall has{' '}
            <Tag
              inline
              label={`${report?.taggings_count} taggings`}
              renderIconLeft={() => (
                <span>
                  <Icon name="tag" />
                  &nbsp;
                </span>
              )}
            />{' '}
            across
            <strong> {report?.project_reports?.length} projects </strong>
            within this organization where it&apos;s associated to.
          </p>
        )}
        {report?.taggings_count > 0 && (
          <p>
            <strong>
              Deleting this report will also remove all those related taggings
            </strong>
          </p>
        )}
        <p>Are you sure you want to proceed with deleting this report?</p>
        <p>This action cannot be reversed</p>
      </div>,
      true
    ).then(
      () =>
        this.props.deleteReport({
          id: reportId,
          project_id: this.props.match.params.projectId,
        }),
      () => void 0
    );
  };

  handleEditReport = (reportId) => {
    const report = this.props.reports.data.find((rep) => rep.id == reportId);
    const grantee =
      this.props.project.grantees?.find(({ id }) => id == report.grantee.id) ||
      {};
    const grant =
      grantee?.grants?.find(({ id }) => id == report.grant.id) || {};
    const project_report = report.project_reports.find(
      (p) =>
        p.project_id == this.props.match.params.projectId &&
        p.report?.id == reportId
    );

    this.props.initializeForm(FORM_NAME, {
      project_report: project_report,
      name: report.name,
      date: report.date ? new Date(report.date) : null,
      report_type: report.report_type,
      period: report.period,
      code: report.code,
      global_region: report.global_region,
      grant_id: grant.id,
      grantee_id: grantee.id,
      grant: grant.name,
      grantee: grantee.name,
    });
    this.setState({ modalVisible: true, reportToEdit: reportId });
  };

  handleOpenUploadModal = () => {
    this.setState({ modalVisible: true, syncReportModal: false });
  };
  handleSyncReportModal = () => {
    this.setState({ modalVisible: true, syncReportModal: true });
  };
  handleModalClose = () => {
    this.setState({ modalVisible: false, reportToEdit: null });
  };
  handleToggleFilter = (showFilterCreator) => {
    this.setState({ showFilterCreator });
  };
  handleClearFilter = (filterValues) => {
    this.props.reportsUpdateQuery(
      where().filter(filterValues).actionMeta({ doDelete: true })
    );
  };
  handleFilterApply = (filterValues) => {
    this.props.reportsUpdateQuery(
      where().filter(filterValues).paginate({ number: 1 })
    );
  };
  handleRequestFilterOptions = (key, value) => {
    this.props[`reportsAF_${key}`]?.(
      { key, value },
      where().filter(
        'project_reports_project_id_eq',
        this.props.match.params.projectId
      )
    );
  };
  handlePaginationChange = ({ page, per_page }) => {
    this.props.reportsUpdateQuery(
      where().paginate({ number: page, size: per_page })
    );
  };
  handleSortChange = (sortKey) => {
    this.props.reportsUpdateQuery(
      where().sort(sortKey).paginate({ number: 1 })
    );
  };
  goAutoCode = () =>
    history.push(`/analysis/${this.props.match.params.projectId}/autocode`);

  handleCloseImportModal = () => {
    this.setState({ importModalShow: false });
  };

  render() {
    if (surveyOnly()) return <UpgradeComponent title="Reports" />;
    const filters = this.props.reports.meta?.getAction?.queryParams?.filter;
    const hasFilters =
      Object.keys(this.props.reports.meta?.getAction?.queryParams?.filter || {})
        .length > 2;
    const reportStatus = [
      {
        title: 'Tagged',
        value: this.props.project.reports_completed_count,
        insight: <Status done />,
        onClick: () => {
          this.handleFilterApply([
            {
              key: 'project_reports_tagging_completed_at_null',
              value: 'false',
            },
          ]);
        },
      },
      {
        title: 'In Progress',
        value: this.props.project.reports_in_progress_count,
        insight: <Status disabled />,
        onClick: () => {
          this.handleFilterApply([
            { key: 'project_reports_tagging_completed_at_null', value: 'true' },
          ]);
        },
      },
      {
        title: 'Untagged',
        value: this.props.project.reports_not_touched_count,
        insight: <Status alarming />,
        onClick: () => {
          this.handleFilterApply([
            { key: 'total_taggings_count', value: '2' },
            { key: 'project_reports_tagging_completed_at_null', value: 'true' },
          ]);
        },
      },
    ];

    return (
      <Page
        title="Reports"
        pending={this.props.reports.pending.init}
        helpAppcue="-LHs3UL5KAOmeiT6E9u9"
      >
        <div>
          <Section type="sub-header" collapsed>
            <Container horizontal nowrap>
              <Actions>
                <FilterTrigger
                  active={this.state.showFilterCreator}
                  filters={filters}
                  config={this.filterConfig()}
                  onToggle={() =>
                    this.handleToggleFilter(!this.state.showFilterCreator)
                  }
                />
              </Actions>
              <Actions>
                {/* TODO: Batch AI::TaggingRequests
                {/* {canAutoCode() && ( */}
                {/*   <Button */}
                {/*     secondary */}
                {/*     size="l" */}
                {/*     label="Auto-tagging" */}
                {/*     onClick={this.goAutoCode} */}
                {/*   /> */}
                {/* )} */}
                {canImportFromTrint() && hasTrintApiKey() && (
                  <Button
                    size="l"
                    label="Import report"
                    onClick={this.handleSyncReportModal}
                  />
                )}
                <Button
                  disabled={!canManageReport()}
                  size="l"
                  label="Upload report"
                  onClick={this.handleOpenUploadModal}
                />
              </Actions>
            </Container>
          </Section>

          {!this.state.showFilterCreator && (
            <Section type="header" collapsed>
              <Container horizontal>
                <Stats small data={reportStatus} />
              </Container>
            </Section>
          )}

          <Section noBorder>
            <FilterJsonapi
              showCreator={this.state.showFilterCreator}
              filters={filters}
              config={this.filterConfig()}
              configAutopopulation={this.filterAutopopulationConfig()}
              onToggleCreator={this.handleToggleFilter}
              onClear={this.handleClearFilter}
              onSubmit={this.handleFilterApply}
            />
            <Card>
              <Container>
                <ReportList
                  noGrantAndGrantee={isGrantless(this.props.project)}
                  reports={this.props.reports}
                  projectId={this.props.match.params.projectId}
                  onDeleteReport={this.handleDeleteReport}
                  onEditReport={this.handleEditReport}
                  onCreateReport={this.handleOpenUploadModal}
                  onSortChange={this.handleSortChange}
                />
              </Container>
              <CardFooter>
                <PaginationIm
                  {...this.props.reports.links.meta}
                  label="reports"
                  items_label="reports"
                  onChange={this.handlePaginationChange}
                />
              </CardFooter>
            </Card>
          </Section>

          {this.state.syncReportModal ? (
            <Dialog
              extraLarge
              open={this.state.modalVisible}
              onRequestClose={this.handleModalClose}
              forceTop={true}
            >
              <SyncReportForm
                onSubmit={this.handleTrintImportSubmit}
                project={this.props.project}
                grantees={this.props.project.grantees}
                grants={this.state.allGrants}
              />
            </Dialog>
          ) : (
            <Dialog
              key={this.state.reportToEdit}
              extraLarge
              open={this.state.modalVisible}
              onRequestClose={this.handleModalClose}
            >
              <UploadReportForm
                editMode={this.state.reportToEdit != null}
                noGrantAndGrantee={isGrantless(this.props.project)}
                onSubmit={
                  this.state.reportToEdit != null
                    ? this.handleEditSubmit
                    : this.handleUploadSubmit
                }
                grantees={this.props.project.grantees}
                grants={this.state.allGrants}
                handleClose={this.handleModalClose}
                regions={
                  this.props.organizationCurrent.data.custom_region_mapping ||
                  {}
                }
                project_id={this.props.project.id}
              />
            </Dialog>
          )}
          <ImportConfirmation
            open={this.state.importModalShow}
            onRequestClose={this.handleCloseImportModal}
          />
        </div>
      </Page>
    );
  }
}
