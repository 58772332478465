// TODO: Adapter for historyInstance or some native solution
import history from 'src/historyInstance';

import { isUpdateQueryAction } from '../helpers/actionTypes';
import QueryParamsManager from '../QueryParamsManager';

export default function runUrlSearchParams(dispatch, action, storeElement) {
  const actionMeta = action.meta || {};
  const actionQueryParams = (action.payload || {}).queryParams;

  if (!isUpdateQueryAction(action.type) || actionMeta.doNotUpdateUrl) {
    return;
  }

  const { getAction = {} } = storeElement.meta;
  const currentParams = QueryParamsManager.parse(location.search);
  const cleanParams = !actionMeta.doDelete
    ? currentParams
    : QueryParamsManager.remove(location.search, actionQueryParams);
  const allParams = QueryParamsManager.stringifyAndUpdate(
    cleanParams,
    getAction.queryParams,
    { encode: false, override: true }
  );

  const run = actionMeta.historyPush ? history.push : history.replace;
  run.call(this, { search: allParams });
}
