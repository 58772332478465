import React from 'react';
import PropTypes from 'prop-types';
import { Actions, Button, Container } from 'src/components/IMUI';

const ProceedCancelButtons = ({
  size,
  hideCancel,
  cancelProps,
  proceedProps,
}) => (
  <Container horizontal>
    <Actions>
      {hideCancel ? null : (
        <Button
          size={size}
          negative
          type="button"
          {...cancelProps}
          label={cancelProps.label || 'Cancel'}
        />
      )}
    </Actions>
    <Actions>
      <Button
        size={size}
        type="submit"
        {...proceedProps}
        label={proceedProps.label || 'Save'}
      />
    </Actions>
  </Container>
);

ProceedCancelButtons.propTypes = {
  size: PropTypes.oneOf(['s', 'm', 'l']),
  cancelProps: PropTypes.object,
  proceedProps: PropTypes.object,
  hideCancel: PropTypes.bool,
};
export default ProceedCancelButtons;
