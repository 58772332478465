import React from 'react';
import PropTypes from 'prop-types';
import { Table, Tooltip } from 'src/components/IMUI';
import { getText } from 'src/services/DictionaryService';
import { formatValueLabel } from '../helpers/formatters';
import TableComposition from 'src/components/IMUI/Tables/TableComposition';
import { prepareData } from 'src/components/Chart/chartUtils';
const HIDDEN_COLUMNS = ['meta'];

export default class ChartDataTable extends React.PureComponent {
  static propTypes = {
    chartData: PropTypes.object,
    settings: PropTypes.object,
    calculationType: PropTypes.array,
  };
  static defaultProps = { chartData: { data: [], meta: {} }, settings: {} };

  render() {
    if (!this.props.chartData.data?.length) return null;
    const _meta = this.props.chartData?.meta || {
      x: { attribute_values: [] },
      y: { attribute_values: [] },
    };
    const _data = this.props.chartData?.data || [{}];
    const { raw, meta, isXY, filteredTable, flatten } = prepareData(
      _data,
      _meta,
      this.props.settings,
      this.props.calculationType
    );
    const lastColumns = Object.keys(isXY ? raw[0]?.[0] : raw[0]).filter(
      (key) => !HIDDEN_COLUMNS.includes(key)
    );

    return (
      <TableComposition compact stickyHeader>
        <Table.Head>
          <Table.HCell
            text={getText(meta?.x?.model || meta?.x?.attribute_name)}
            label={getText(meta?.x?.model)}
          />
          {isXY && (
            <Table.HCell
              text={getText(meta?.y?.model || meta?.y?.attribute_name)}
              label={getText(meta?.y?.model)}
            />
          )}
          {lastColumns.map((header) => (
            <Table.HCell
              text={getText(header)}
              key={header}
              textAlign="right"
            />
          ))}
        </Table.Head>

        <Table.Body>
          {filteredTable.map((row, i) =>
            Array.isArray(row)
              ? row.map((subEntry, j) => (
                  <Table.Row key={`${i}-${j}`}>
                    <Table.Cell subHeader text={flatten(subEntry?.meta?.x)} />
                    <Table.Cell
                      subHeader
                      text={getText(flatten(subEntry?.meta?.y))}
                      tip={getText(flatten(subEntry?.meta?.y))}
                    />
                    {lastColumns.map((type) => (
                      <Table.Cell
                        numerical
                        text={formatValueLabel(subEntry?.[type], type)}
                        key={`${i}-${j}-${type}`}
                      />
                    ))}
                  </Table.Row>
                ))
              : row != undefined && (
                  <Table.Row key={i}>
                    <Table.Cell
                      subHeader
                      text={flatten(row?.meta?.x)}
                      tip={flatten(row?.meta?.x)}
                    />
                    {lastColumns.map((type) => (
                      <Table.Cell
                        numerical
                        text={formatValueLabel(row?.[type], type)}
                        key={`${i}-${type}`}
                      />
                    ))}
                  </Table.Row>
                )
          )}
        </Table.Body>
        <Tooltip />
      </TableComposition>
    );
  }
}
