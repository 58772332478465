import reducerRegistry, { advancedFiltersRegistry } from 'im/api/registry';
import ResourceCollection from 'im/api/ResourceCollection';

const apiConfig = {
  root: '/v3/grantees',
  type: 'grantees',
  api: {
    allow: ['findAll', 'destroy'],
  },
};

export const Grantees = new ResourceCollection(apiConfig, 'jsonapiGrantees');
export const GranteesDetailed = new ResourceCollection(
  apiConfig,
  'granteesDetailed'
);

reducerRegistry.register(Grantees);
reducerRegistry.register(GranteesDetailed);

const advancedFiltersConfig = {
  actionPrefix: 'granteesAF',
  allow: ['name', 'region', 'country_code', 'partner_list'],
};

advancedFiltersRegistry.register(Grantees, advancedFiltersConfig);

export const granteesGetAdvancedFilters = Grantees.advancedFilters;

export default {
  ...Grantees.api,
  detailed: GranteesDetailed.api,
};
