import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dialog } from 'src/components/IMUI/index';
import FundingInfoEdit from '../../Edit/FundingInfoEdit';
import cls from './EditDialogs.module.css';

@connect((state) => ({
  organizationCurrent: state.organizationCurrent,
}))
export default class EditDialogsFunding extends React.PureComponent {
  static propTypes = {
    organizationCurrent: PropTypes.object,
    fundingToEdit: PropTypes.object,
    grant: PropTypes.object,
    onCloseModal: PropTypes.func.isRequired,
    onSubmitSuccessful: PropTypes.func.isRequired,
  };

  render() {
    const { grant, fundingToEdit } = this.props;
    const initialValues = {
      start_date: fundingToEdit?.start_date || null,
      end_date: fundingToEdit?.end_date || null,
      fundings: [
        {
          id: +fundingToEdit.id,
          grantee_id: +(fundingToEdit.grantee?.id || fundingToEdit.grantee_id),
          grant_id: +(fundingToEdit.grant?.id || fundingToEdit.grant_id),
          amount: fundingToEdit.amount,
          currency: fundingToEdit.currency,
          start_date: fundingToEdit.start_date,
          end_date: fundingToEdit.end_date,
          funding_type: fundingToEdit.type,
        },
      ],
    };

    return (
      <Dialog
        open
        extraLarge
        onRequestClose={this.props.onCloseModal}
        title={
          <h3 className={cls.noMargin}>
            Edit funding of{' '}
            <span className={cls.textItalic}> {grant?.name}</span>
          </h3>
        }
      >
        <FundingInfoEdit
          initialValues={initialValues}
          grant={grant}
          fundingTypes={this.props.organizationCurrent.data?.funding_types}
          onRequestClose={this.props.onCloseModal}
          onSubmitSuccessful={this.props.onSubmitSuccessful}
        />
      </Dialog>
    );
  }
}
