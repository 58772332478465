import React from 'react';
import classNames from 'classnames/bind';
import { T, withTranslators } from 'lioness';
import RadioButton from 'material-ui/RadioButton/RadioButton';
import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import { connect } from 'react-redux';
import {
  RadioButtonGroup,
  Tag,
  Container,
  CardFooter,
} from 'src/components/IMUI';
import { getSurveyI18n } from 'src/utils/surveysI18n';
import { Icon } from 'im/ui/Icon';
import createQuestion from './createQuestion';
import cls from './Rating.module.css';
import { TagNotProvided } from './TextQuestion';
import UploadAttachment from 'src/components/Survey/UploadAttachment';
const cx = classNames.bind(cls);

@createQuestion
@connect(pick(['survey', 'surveyAnswers']))
class Rating extends React.PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.any,
    question: PropTypes.object,
    answer: PropTypes.object,
    index: PropTypes.number.isRequired,
    isReview: PropTypes.bool,
    readOnly: PropTypes.bool,
    isAnswerMode: PropTypes.bool,
    isEditing: PropTypes.bool,
    onRefresh: PropTypes.func,
    survey: PropTypes.object,
    surveyAnswers: PropTypes.object,
    tagsWithTagGroups: PropTypes.array,
  };

  state = {};

  static validate(question, answers) {
    if (question.required && typeof answers !== 'number') {
      return <T>Please select a valid answer</T>;
    }
    if (question.required && !answers) {
      return <T>Please select an answer</T>;
    }
    return null;
  }

  onChange = (event, value) => {
    const newValue = value || event;
    const tagIds = [];
    for (let i = 0; i < this.props.question.options.length; i += 1) {
      if (this.props.question.options[i].title == newValue) {
        tagIds.push(this.props.question.options[i].tag_id);
        break;
      }
    }
    this.props.onChange?.(Number(newValue), tagIds);
  };

  getTag = (tagId) => this.props.tagsWithTagGroups.find((st) => st.id == tagId);

  render() {
    const { index, value, readOnly, question, survey } = this.props;
    const options = [1, 2, 3, 4, 5];
    const minLabel = getSurveyI18n(survey, question, ['settings', 'minLabel']);
    const maxLabel = getSurveyI18n(survey, question, ['settings', 'maxLabel']);

    return (
      <div>
        <RadioButtonGroup
          name={`radio-group_${index}`}
          onChange={this.onChange}
          valueSelected={value}
          readOnly={readOnly}
          className={cls.ratingOptionGroup}
        >
          {options.map((optionValue, i) => (
            <RadioButton
              className={cx('radio', 'ratingOption', {
                ratingReadonly: this.props.readOnly,
                ratingEmpty: this.props.value < i + 1,
                ratingSelected: this.props.value >= i + 1,
              })}
              onMouseEnter={() => this.setState({ hoveredValue: i + 1 })}
              onMouseOut={() => this.setState({ hoveredValue: null })}
              key={i}
              label={
                <Icon
                  name={`star-${
                    (this.state.hoveredValue || this.props.value) >= i + 1
                      ? 'filled'
                      : 'empty'
                  }`}
                />
              }
              value={optionValue}
            />
          ))}
        </RadioButtonGroup>

        <div className={cls.ratingDescription}>
          <div>{minLabel}</div>
          <div>{maxLabel}</div>
        </div>

        {this.props.isReview && (
          <CardFooter minimal className="printHidden">
            <Container horizontal>
              {!this.props.answer.tags.length && this.props.isEditing && (
                <TagNotProvided />
              )}
              {this.props.answer.tags
                .map((i) => this.getTag(i))
                .map(
                  (tag) =>
                    tag && (
                      <Tag
                        key={tag.id}
                        inline
                        label={tag.code || tag.title}
                        tip={`${(tag.tagGroup || {}).title} ${
                          tag.code ? tag.title : ''
                        }`}
                      />
                    )
                )}
            </Container>
          </CardFooter>
        )}

        {this.props.question.has_attachment && this.props.isAnswerMode && (
          <CardFooter noPadding className="printHidden">
            <UploadAttachment
              isReview={false}
              questionId={this.props.question.id}
              surveyAnswerId={this.props.answer.uid}
              importUpload={this.props.answer.import_upload}
              projectId={this.props.surveyAnswers.info?.projectId}
              granteeSurveyId={this.props.surveyAnswers.info?.grantee_survey_id}
              onRefresh={this.props.onRefresh}
            />
          </CardFooter>
        )}
      </div>
    );
  }
}

const enhancedComponent = withTranslators(Rating);
enhancedComponent.validate = Rating.validate;
export default enhancedComponent;
