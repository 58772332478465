import React from 'react';
import { FullBgView } from 'src/components/FullBgView';
import { Button } from 'src/components/IMUI/index';
import history from 'src/historyInstance';

export default class RegisterSuccess extends React.PureComponent {
  render() {
    return (
      <FullBgView hasLogo>
        <FullBgView.Box
          title="Account created"
          rightAction={
            <Button
              dark
              label="Go to login"
              onClick={() => history.push('/login')}
            />
          }
          info="Account has been created successfully"
        />
      </FullBgView>
    );
  }
}
