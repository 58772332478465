import tinycolor from 'tinycolor2';
import COLORS from './colors.json';

const DEFAULT_COLOR = '#81D4FA';
const FOCUSED_COLOR = '#FFF59D';
const DARKEN_RATIO = 13;
const DARKEN_RATIO_2ND = 26;
const DARKEN_RATIO_3RD = 33;

const hasIntersect = (focusedTags, ids) =>
  Boolean(focusedTags?.some((tag) => ids.includes(tag)));

const getTagColor = (tagId) => COLORS[tagId % COLORS.length];

const mixAndDarkenColor = (color1, color2) => {
  const hasDark = Boolean(
    tinycolor(color1)?.isDark() || tinycolor(color2)?.isDark()
  );
  const darkenRatio = hasDark ? DARKEN_RATIO : DARKEN_RATIO / 2;
  return tinycolor.mix(color1, color2, 50)?.darken(darkenRatio).toHexString();
};

const calculateUniqueColor = (tagIds, taggings) =>
  tagIds.reduce((acc, id) => {
    const hColor = getTagColor(taggings.find((t) => t.id === id)?.tag_id);
    return acc ? mixAndDarkenColor(hColor, acc) : hColor;
  }, null);

const calculateUniqueGroupColor = (tagIds, taggings, focusedTags) => {
  if (hasIntersect(focusedTags, tagIds)) return FOCUSED_COLOR;
  const taggingsByTagCategoryIds = [
    ...new Set(
      taggings.flatMap((t) => t.tag?.tag_categories?.map((c) => c.id))
    ),
  ]
    .filter(Boolean)
    .sort();
  return tagIds.reduce((acc, id) => {
    const index = taggingsByTagCategoryIds.indexOf(
      taggings.find((t) => t.id === id)?.tag?.tag_categories?.[0]?.id
    );
    const color = COLORS[index % COLORS.length] ?? DEFAULT_COLOR;
    return acc ? mixAndDarkenColor(color, acc) : color;
  }, null);
};

const calculateSingleColorWithOverlaps = (tagIds) => {
  if (tagIds.length > 2)
    return tinycolor(DEFAULT_COLOR)?.darken(DARKEN_RATIO_3RD).toHexString();
  if (tagIds.length == 2)
    return tinycolor(DEFAULT_COLOR)?.darken(DARKEN_RATIO_2ND).toHexString();
  if (tagIds.length == 1)
    return tinycolor(DEFAULT_COLOR)?.darken(DARKEN_RATIO).toHexString();
  return tinycolor(DEFAULT_COLOR)?.toHexString();
};

const calculateSingleColorWithOverlapsAndHover = (
  tagIds,
  taggings,
  focusedTags
) =>
  hasIntersect(
    focusedTags,
    tagIds.concat(taggings.map((t) => t.tag?.id)).filter(Boolean)
  )
    ? FOCUSED_COLOR
    : calculateSingleColorWithOverlaps(tagIds);

export const COLORING_STRATEGIES = {
  SINGLE_COLOR_SHOW_OVERLAPS: 'SINGLE_COLOR_SHOW_OVERLAPS',
  SINGLE_COLOR_SHOW_OVERLAPS_SHOW_HOVER:
    'SINGLE_COLOR_SHOW_OVERLAPS_SHOW_HOVER',
  TAGS_UNIQUE: 'TAGS_UNIQUE',
  TAG_GROUPS_UNIQUE: 'TAG_GROUPS_UNIQUE',
};

export const COLORING_STRATEGIES_READABLE = {
  SINGLE_COLOR_SHOW_OVERLAPS_SHOW_HOVER: 'Single color',
  TAG_GROUPS_UNIQUE: 'Multi color',
};

export const applyColoringStrategy = (
  strategy,
  tagIds,
  taggings,
  focusedTags
) => {
  switch (strategy) {
    case COLORING_STRATEGIES.SINGLE_COLOR_SHOW_OVERLAPS:
      return calculateSingleColorWithOverlaps(tagIds);

    case COLORING_STRATEGIES.SINGLE_COLOR_SHOW_OVERLAPS_SHOW_HOVER:
      return calculateSingleColorWithOverlapsAndHover(
        tagIds,
        taggings,
        focusedTags
      );

    case COLORING_STRATEGIES.TAG_GROUPS_UNIQUE:
      return calculateUniqueGroupColor(tagIds, taggings, focusedTags);

    case COLORING_STRATEGIES.TAGS_UNIQUE:
    default:
      return calculateUniqueColor(tagIds, taggings);
  }
};
