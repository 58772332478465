import {
  USER__GET_TOKEN_SUCCESS,
  USER__GET_TOKEN_FAILURE,
  USER__GET_TOKEN_REQUEST,
  USER__AUTHENTICATED,
  USER__GET_2FA_TOKEN_SUCCESS,
} from 'src/constants';

const initialState = {
  pending: false,
  errors: null,
  token: null,
  userId: null,
  twoFactor: false,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case USER__GET_TOKEN_REQUEST:
      return { ...initialState, pending: true };
    case USER__GET_TOKEN_SUCCESS:
      return {
        pending: false,
        errors: null,
        token: action.payload.token,
        twoFactor: false,
      };
    case USER__GET_2FA_TOKEN_SUCCESS:
      return { pending: true, errors: null, token: null, twoFactor: true };
    case USER__GET_TOKEN_FAILURE:
      return {
        pending: false,
        token: null,
        errors: action.payload.response?.errors,
      };
    case USER__AUTHENTICATED:
      return {
        ...state,
        userId: action.payload.userId,
        token: action.payload.token,
      };
    default:
      return state;
  }
}
