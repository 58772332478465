import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import colors from 'src/css/constants.json';
import { getOrgText } from 'src/services/DictionaryService';
import { Icon } from 'im/ui/Icon';
import TripleItem from './TripleItem';
import cls from './MappingItem.module.css';
import { isSameField } from './MappingItemEditable';
const cx = classNames.bind(cls);

const ICON_PER_STATE = {
  skipped: { name: 'skip', color: colors['mid-grey'] },
  mapped: { name: 'arrow-right', color: colors.green },
  merged: { name: 'merge', color: colors.green },
  edit: { name: 'edit', color: colors['mid-grey'] },
  editWarning: { name: 'edit', color: colors.red },
  unmapped: { name: 'warning-triangle', color: colors.red },
  customField: { name: 'add-element', color: colors.green },
  default: { name: 'arrow-right', color: colors.dark },
};

export const isMerged = (input, formColumns) =>
  Boolean(
    formColumns &&
      Object.values(formColumns).filter(
        (v) => v?.column && isSameField(v, input)
      ).length > 1
  );

export const isSkipped = (input) => Boolean(input?.skip);

export const isUnmapped = (input) =>
  Boolean(!isSkipped(input) && !input?.column);

export const isMapped = (input) =>
  Boolean(!isSkipped(input) && Boolean(input?.column));

export const isCustomField = (input) =>
  Boolean(
    input?.customField ||
      input?.column?.startsWith('Custom field:') ||
      input?.imColumn?.startsWith('CUSTOM:')
  );

export const isUnmappedWithWarning = (
  input,
  meta,
  isReview = false,
  isEdit = false
) =>
  isUnmapped(input) &&
  (isReview || isEdit || meta.submitFailed || meta.touched);

export default class MappingItem extends React.Component {
  static propTypes = {
    value: PropTypes.object,
    csvColumn: PropTypes.string,
    meta: PropTypes.object,
    isEdit: PropTypes.bool,
    isReview: PropTypes.bool,
    formColumns: PropTypes.object,
    className: PropTypes.string,

    renderDropdown: PropTypes.func,
    onRedirectToEdit: PropTypes.func,
  };

  static defaultProps = { formColumns: {}, meta: {} };

  state = { isIconHovered: false };

  get isMerged() {
    return isMerged(this.props.value, this.props.formColumns);
  }
  get isSkipped() {
    return isSkipped(this.props.value);
  }
  get isMapped() {
    return isMapped(this.props.value);
  }
  get isCustomField() {
    return isCustomField(this.props.value);
  }
  get isUnmappedWithWarning() {
    return isUnmappedWithWarning(
      this.props.value,
      this.props.meta,
      this.props.isReview,
      this.props.isEdit
    );
  }

  get icon() {
    if (this.isSkipped) {
      return ICON_PER_STATE.skipped;
    }
    if (this.isCustomField) {
      return ICON_PER_STATE.customField;
    }
    if (this.isMerged) {
      return ICON_PER_STATE.merged;
    }
    if (this.isMapped) {
      return ICON_PER_STATE.mapped;
    }
    if (this.props.isReview && this.state.isIconHovered) {
      return ICON_PER_STATE.edit;
    }
    if (this.props.isReview && this.isUnmappedWithWarning) {
      return ICON_PER_STATE.editWarning;
    }
    if (this.isUnmappedWithWarning) {
      return ICON_PER_STATE.unmapped;
    }
    return ICON_PER_STATE.default;
  }

  renderText = (text, doTranslate = false, className = '') => {
    return (
      <span
        className={cx(
          {
            [cls.mappingItemSkipped]: this.isSkipped,
            [cls.mappingItemUnmapped]: this.isUnmappedWithWarning,
          },
          className
        )}
      >
        {doTranslate ? getOrgText(text) : text}
      </span>
    );
  };

  renderMiddle = () => {
    const onIconMouseHover = () =>
      this.setState({ isIconHovered: !this.state.isIconHovered });
    return (
      <Icon
        name={this.icon.name}
        color={this.icon.color}
        className={cx(cls.mappingItemIcon, {
          [cls.mappingItemIconClickable]: this.props.isReview,
        })}
        onClick={this.props.isReview && this.props.onRedirectToEdit}
        onMouseEnter={onIconMouseHover}
        onMouseLeave={onIconMouseHover}
      />
    );
  };

  renderRight = () => {
    return this.props.isReview
      ? this.renderText(
          this.props.value?.column || 'Unkown',
          true,
          cls.mappingItemToRight
        )
      : this.props.renderDropdown();
  };

  renderLeft = () => {
    return this.renderText(this.props.csvColumn);
  };

  render() {
    return (
      <TripleItem
        border
        className={cx(cls.mappingItem, this.props.className)}
        left={this.renderLeft}
        middle={this.renderMiddle}
        right={this.renderRight}
      />
    );
  }
}
