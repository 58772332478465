import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import {
  Page,
  Actions,
  BackButton,
  Container,
  Section,
} from 'src/components/IMUI';
import HeaderSteps from '../HeaderSteps/HeaderSteps';
import AccountNavigationButton from 'src/components/AccountNavigationButton/AccountNavigationButton';
import HelpButton from 'src/components/HelpButton/HelpButton';
import cls from './Page.module.css';
const cx = classNames.bind(cls);

export default class PageWithHeader extends React.PureComponent {
  static propTypes = {
    ...Page.propTypes,
    ...HeaderSteps.propTypes,
    back: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    headerActions: PropTypes.node,
    headerClassName: PropTypes.string,
    headerFixed: PropTypes.bool,
    hasFooter: PropTypes.bool,
  };
  componentDidMount() {
    typeof this.props.title == 'string' && this.setTitle(this.props.title);
  }
  componentDidUpdate() {
    typeof this.props.title == 'string' && this.setTitle(this.props.title);
  }
  setTitle(title) {
    const newTitle = `Impact Mapper ${title ? `: ${title}` : ''}`;
    if (document.title !== newTitle) document.title = newTitle;
  }

  renderHeader = () => {
    const {
      back,
      title,
      headerFixed,
      steps,
      activeStep,
      noSeparator,
      headerClassName,
    } = this.props;
    return (
      <Section
        type="header"
        className={cx(headerClassName, {
          [cls.headerFixed]: headerFixed,
          [cls.headerWithSteps]: steps?.length > 0,
        })}
      >
        <Container horizontal nowrap className={cls.headerFirstRow}>
          <Actions className={cls.titleWrapper} nowrap>
            {back && <BackButton to={back?.length > 1 ? String(back) : null} />}
            {title && <h2 className={cls.pageTitle}>{title}</h2>}
          </Actions>
          <Actions className={cx('header-actions')} nogrow nowrap>
            {this.props.headerActions}
            <HelpButton />
            <AccountNavigationButton />
          </Actions>
        </Container>

        {steps?.length > 0 && (
          <HeaderSteps
            steps={steps}
            activeStep={activeStep}
            noSeparator={noSeparator}
          />
        )}
      </Section>
    );
  };

  render() {
    const { className, children, pending, noPadding, id, hasFooter } =
      this.props;
    const classes = cx({ [cls.hasFooter]: !!hasFooter }, className);
    return (
      <Page
        id={id}
        className={classes}
        noPadding={noPadding}
        pending={pending}
        renderHeader={this.renderHeader}
      >
        {children}
      </Page>
    );
  }
}
