import {
  CHART__AXIS_VALUE_TYPES_GET_REQUEST,
  CHART__AXIS_VALUE_TYPES_GET_SUCCESS,
  CHART__AXIS_VALUE_TYPES_GET_FAILURE,
} from 'src/constants';

const initialState = {
  data: [],
  pending: false,
  error: null,
};

export default function chartsAxisTypesReducer(state = initialState, action) {
  switch (action.type) {
    case CHART__AXIS_VALUE_TYPES_GET_REQUEST:
      return {
        ...initialState,
        pending: true,
      };
    case CHART__AXIS_VALUE_TYPES_GET_SUCCESS:
      return {
        ...initialState,
        data: action.payload.data,
      };
    case CHART__AXIS_VALUE_TYPES_GET_FAILURE:
      return {
        ...initialState,
        error: 'Failed to fetch charts axis value types',
      };
    default:
      return state;
  }
}
