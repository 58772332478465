import React from 'react';
import { Icon } from 'im/ui/Icon/Icon';
import PropTypes from 'prop-types';
import {
  getTaggingType,
  getTaggingValue,
  TAGGING_OPTIONS,
  TAGGING_TYPE_INPUTS,
} from 'src/components/TaggingType/taggingTypeHelper';
import {
  TextField,
  SelectField,
  NumberField,
  Divider,
} from 'src/components/IMUI';
import CurrencyInput from './CurrencyInput';
import DateDropdownExtended from './DateDropdownExtended';
import cls from './TaggingDetailsSelector.module.css';
import { canManageTag } from 'src/userStorage';
import colors from 'src/css/constants.json';
import store from 'src/store';
import { showNotification } from 'src/actionCreators/notificationsActionCreators';
import saveSvg from 'src/static/save.svg';

const showWarning = ({ title, message }) =>
  store.dispatch(showNotification({ title, message, level: 'warning' }));
const INPUT_PER_TYPE = {
  text: TextField,
  number: NumberField,
  currency: CurrencyInput,
  date: DateDropdownExtended,
};
const INPUT_PROPS_PER_TYPE = {
  date: { outline: true },
  default: { borderDark: true },
};
const isValidCurrency = ({ amount, currency }) =>
  amount != undefined && currency != undefined;
const safeCastNumber = (v) =>
  v != null && !isNaN(v) && isFinite(Number(v)) ? Number(v) : undefined;

export default class TaggingDetailsSelector extends React.PureComponent {
  static propTypes = {
    isEdit: PropTypes.bool,
    open: PropTypes.bool,
    tagging: PropTypes.object,
    position: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    onRequestClose: PropTypes.func,
    onTaggingRemove: PropTypes.func,
  };

  constructor(props) {
    super(props);
    const taggingType = getTaggingType(props.tagging);
    const inputValue = getTaggingValue(taggingType, props.tagging);
    this.state = { taggingType, initialType: taggingType, inputValue };
  }

  componentDidMount() {
    document.body.addEventListener('click', this.handleHideOnClickOutside);
    document.body.addEventListener('keyup', this.onKeyUp);
  }
  componentWillUnmount() {
    document.body.removeEventListener('click', this.handleHideOnClickOutside);
    document.body.removeEventListener('keyup', this.onKeyUp);
  }
  handleHideOnClickOutside = (ev) => {
    if (
      this.innerRef?.contains(ev.target) ||
      document.querySelector("div[role='menu']")?.contains(ev.target)
    )
      return;
    const hasOverlay = [...document.querySelectorAll('#root ~ div')].some(
      (o) => o?.children?.length && o?.style?.length
    );
    const { x, y, width, height } =
      this.innerRef?.getBoundingClientRect?.() ?? {};
    const clickedInside =
      hasOverlay &&
      ev.x >= x &&
      ev.x <= x + width &&
      ev.y >= y &&
      ev.y <= y + height;
    if (!clickedInside) this.onSave(true);
  };

  onChangeInputValue = (inputValue) => {
    this.setState({ inputValue });
  };
  onChangeSelectType = ({ value: taggingType }) => {
    if (taggingType == this.state.taggingType) return;
    const rawValue =
      typeof this.state.inputValue == 'object'
        ? this.state.inputValue?.amount || this.state.inputValue?.year
        : this.state.inputValue;
    if (taggingType === 'currency')
      return this.setState({
        taggingType,
        inputValue: { amount: safeCastNumber(rawValue), currency: undefined },
      });
    if (taggingType === 'number')
      return this.setState({
        taggingType,
        inputValue: safeCastNumber(rawValue),
      });
    if (taggingType === 'location')
      return this.setState({ taggingType, inputValue: rawValue });
    if (taggingType === 'default')
      return this.setState({ taggingType, inputValue: undefined });
  };

  isInputValid = () => {
    if (this.state.taggingType === 'default') return true;
    if (this.state.taggingType === 'currency')
      return (
        this.state.inputValue != undefined &&
        isValidCurrency(this.state.inputValue)
      );
    if (this.state.taggingType === 'location')
      return (
        this.state.inputValue != undefined && this.state.inputValue.length > 0
      );
    if (this.state.taggingType === 'number')
      return (
        this.state.inputValue != undefined &&
        !isNaN(Number(this.state.inputValue)) &&
        isFinite(Number(this.state.inputValue))
      );
    return true;
  };

  onClickRemove = () => {
    this.props.onTaggingRemove(this.props.tagging);
    this.props.onRequestClose();
  };
  onKeyUp = (ev) => {
    if (ev.keyCode === 13) this.onSave();
    if (ev.keyCode === 27) this.props.onRequestClose();
  };
  isChanged = () => {
    if (this.state.taggingType !== this.state.initialType) return true;
    if (this.state.taggingType == 'default' && !this.state.inputValue)
      return true;
    if (
      this.state.taggingType == 'currency' &&
      this.state.inputValue?.amount != this.props.tagging?.amount
    )
      return true;
    if (
      this.state.taggingType == 'currency' &&
      this.state.inputValue?.currency != this.props.tagging?.currency
    )
      return true;
    if (
      this.state.taggingType == 'location' &&
      this.state.inputValue != this.props.tagging?.location
    )
      return true;
    if (
      this.state.taggingType == 'number' &&
      this.state.inputValue != this.props.tagging?.quantity
    )
      return true;
    return false;
  };

  onClose = () => {
    return this.props.onRequestClose();
  };
  onSave = (clickedOutside) => {
    if (!canManageTag()) {
      showWarning({
        title: 'Not Saved',
        message: 'You are not allowed to change tags',
      });
      return this.props.onRequestClose();
    }
    if (!this.isChanged()) {
      if (clickedOutside)
        showWarning({ title: 'Not Saved', message: 'No changes' });
      return this.props.onRequestClose();
    }
    this.isInputValid()
      ? this.handleSubmit()
      : !clickedOutside &&
        showWarning({
          title: 'Not Saved',
          message: 'Values are not valid. Plase fix them',
        });
    return this.props.onRequestClose();
  };

  handleSubmit = () =>
    this.props.onSubmit(
      this.props.tagging,
      (() => {
        if (this.state.taggingType === 'currency')
          return {
            amount: this.state.inputValue?.amount,
            currency: this.state.inputValue?.currency,
          };
        if (this.state.taggingType === 'location')
          return { location: this.state.inputValue || '' };
        if (this.state.taggingType === 'number')
          return { quantity: this.state.inputValue || '' };
        if (this.state.taggingType === 'default') return {};
        return {};
      })()
    );

  statusIcon = () => {
    if (!this.isChanged()) return null;
    if (!this.isInputValid()) return <Icon name="close" color={colors.red} />;
    return <Icon name="check" color={colors.green} />;
  };
  render() {
    if (!this.props.open) return null;
    const inputType =
      this.state.taggingType &&
      TAGGING_TYPE_INPUTS[this.state.taggingType].inputType;
    const Input = INPUT_PER_TYPE[inputType] || null;
    const inputProps =
      INPUT_PROPS_PER_TYPE[inputType] || INPUT_PROPS_PER_TYPE.default;
    const statusIconInstance = this.statusIcon();
    return (
      <td
        className={cls.taggingDetailsSelector}
        style={{ ...this.props.position }}
        ref={(ref) => (this.innerRef = ref)}
      >
        <div className={cls.detailsSelector}>
          <div className={cls.detailsSelectorHeader}>
            <h4 className={cls.detailsSelectorTitle}>
              <div className={cls.detailsSelectorTitleContent}>
                <div>Text tagged</div>
                <div className={cls.detailsSelectorTitleActions}>
                  <div>
                    <Icon tip="Save" onClick={this.onSave}>
                      <img alt="Save" width={17} src={saveSvg} />
                    </Icon>
                  </div>
                  {canManageTag() && this.props.onTaggingRemove && (
                    <div
                      style={{
                        marginLeft: 10,
                        fontSize: 14,
                      }}
                    >
                      <Icon
                        name="trash"
                        tip="Delete"
                        onClick={this.onClickRemove}
                      />
                    </div>
                  )}
                  <div
                    style={{
                      marginLeft: 8,
                      fontSize: 20,
                    }}
                  >
                    <Icon name="close" tip="Close" onClick={this.onClose} />
                  </div>
                </div>
              </div>
            </h4>
          </div>

          <Divider className={cls.detailsSelectorDivider} />

          <div className={cls.detailsSelectorContent}>
            <SelectField
              flat
              outline
              noValidation
              value={this.state.taggingType}
              selectedMenuItemStyle={{ fontWeight: 'bold', color: colors.dark }}
              onChange={this.onChangeSelectType}
            >
              {TAGGING_OPTIONS.map((option) => (
                <SelectField.Item
                  className={cls.detailsSelectorDropdownItem}
                  key={option.value}
                  value={option.value}
                  primaryText={option.label}
                  leftIcon={<Icon {...option.leftIconProps} color="##666666" />}
                  rightIcon={
                    <Icon
                      {...option.rightIconProps}
                      color={
                        this.state.taggingType == option.value
                          ? colors.green
                          : '#cccccc'
                      }
                    />
                  }
                />
              ))}
            </SelectField>
            {Input && (
              <Input
                {...inputProps}
                flat
                noValidation
                className={cls.detailsSelectorInput}
                name={inputType}
                value={this.state.inputValue}
                hintText="Type in..."
                onKeyUp={this.onKeyUp}
                onChange={this.onChangeInputValue}
                icon={statusIconInstance}
              />
            )}
          </div>
        </div>
      </td>
    );
  }
}
