/* eslint no-undef: 0 */
module.exports = {
  USER__AUTHENTICATED: 'USER__AUTHENTICATED',
  USER__GET_TOKEN_REQUEST: 'USER__GET_TOKEN_REQUEST',
  USER__GET_TOKEN_SUCCESS: 'USER__GET_TOKEN_SUCCESS',
  USER__GET_2FA_TOKEN_SUCCESS: 'USER__GET_2FA_TOKEN_SUCCESS',
  USER__GET_TOKEN_FAILURE: 'USER__GET_TOKEN_FAILURE',
  STORAGE__UPDATE: 'STORAGE__UPDATE',
  STORAGE__LOAD: 'STORAGE__LOAD',
  PROJECTS__GET_FAILURE: 'PROJECTS__GET_FAILURE',
  PROJECTS__GET_REQUEST: 'PROJECTS__GET_REQUEST',
  PROJECTS__GET_SUCCESS: 'PROJECTS__GET_SUCCESS',
  PROJECT__GET_FAILURE: 'PROJECT__GET_FAILURE',
  PROJECT__GET_REQUEST: 'PROJECT__GET_REQUEST',
  PROJECT__GET_SUCCESS: 'PROJECT__GET_SUCCESS',
  PROJECT__GET_INIT_FAILURE: 'PROJECT__GET_INIT_FAILURE',
  PROJECT__GET_INIT_REQUEST: 'PROJECT__GET_INIT_REQUEST',
  PROJECT__GET_INIT_SUCCESS: 'PROJECT__GET_INIT_SUCCESS',
  PROJECT__CREATE_FAILURE: 'PROJECT__CREATE_FAILURE',
  PROJECT__CREATE_REQUEST: 'PROJECT__CREATE_REQUEST',
  PROJECT__CREATE_SUCCESS: 'PROJECT__CREATE_SUCCESS',
  PROJECT__UPDATE_FAILURE: 'PROJECT__UPDATE_FAILURE',
  PROJECT__UPDATE_REQUEST: 'PROJECT__UPDATE_REQUEST',
  PROJECT__UPDATE_SUCCESS: 'PROJECT__UPDATE_SUCCESS',
  PROJECT__DELETE_REQUEST: 'PROJECT__DELETE_REQUEST',
  PROJECT__DELETE_SUCCESS: 'PROJECT__DELETE_SUCCESS',
  PROJECT__DELETE_FAILURE: 'PROJECT__DELETE_FAILURE',
  PROJECT__GRANTS__GET_FAILURE: 'PROJECT__GRANTS__GET_FAILURE',
  PROJECT__GRANTS__GET_REQUEST: 'PROJECT__GRANTS__GET_REQUEST',
  PROJECT__GRANTS_GET_SUCCESS: 'PROJECT__GRANTS_GET_SUCCESS',
  PROJECT__GRANTEES_GET_REQUEST: 'PROJECT__GRANTEES_GET_REQUEST',
  PROJECT__GRANTEES_GET_SUCCESS: 'PROJECT__GRANTEES_GET_SUCCESS',
  PROJECT__GRANTEES_GET_FAILURE: 'PROJECT__GRANTEES_GET_FAILURE',
  PROJECT__CHART_DATA_GET_REQUEST: 'PROJECT__CHART_DATA_GET_REQUEST',
  PROJECT__CHART_DATA_GET_SUCCESS: 'PROJECT__CHART_DATA_GET_SUCCESS',
  PROJECT__CHART_DATA_GET_FAILURE: 'PROJECT__CHART_DATA_GET_FAILURE',
  PROJECT__REPORTS_GET_REQUEST: 'PROJECT__REPORTS_GET_REQUEST',
  PROJECT__REPORTS_GET_SUCCESS: 'PROJECT__REPORTS_GET_SUCCESS',
  PROJECT__REPORTS_GET_FAILURE: 'PROJECT__REPORTS_GET_FAILURE',
  PROJECT__SUMMARY_GET_FAILURE: 'PROJECT__SUMMARY_GET_FAILURE',
  PROJECT__SUMMARY_GET_REQUEST: 'PROJECT__SUMMARY_GET_REQUEST',
  PROJECT__SUMMARY_GET_SUCCESS: 'PROJECT__SUMMARY_GET_SUCCESS',
  PROJECT__ACTIVE_CHANGE: 'PROJECT__ACTIVE_CHANGE',
  GRANTS__FILTERS_GET_REQUEST: 'GRANTS__FILTERS_GET_REQUEST',
  GRANTS__FILTERS_GET_SUCCESS: 'GRANTS__FILTERS_GET_SUCCESS',
  GRANTS__FILTERS_GET_FAILURE: 'GRANTS__FILTERS_GET_FAILURE',
  GRANTS__GET_REQUEST: 'GRANTS__GET_REQUEST',
  GRANTS__GET_SUCCESS: 'GRANTS__GET_SUCCESS',
  GRANTS__GET_FAILURE: 'GRANTS__GET_FAILURE',
  GRANT__DELETE_REQUEST: 'GRANT__DELETE_REQUEST',
  GRANT__DELETE_SUCCESS: 'GRANT__DELETE_SUCCESS',
  GRANT__DELETE_FAILURE: 'GRANT__DELETE_FAILURE',
  GRANT__CREATE_REQUEST: 'GRANT__CREATE_REQUEST',
  GRANT__CREATE_SUCCESS: 'GRANT__CREATE_SUCCESS',
  GRANT__CREATE_FAILURE: 'GRANT__CREATE_FAILURE',
  GRANT__UPDATE_REQUEST: 'GRANT__UPDATE_REQUEST',
  GRANT__UPDATE_SUCCESS: 'GRANT__UPDATE_SUCCESS',
  GRANT__UPDATE_FAILURE: 'GRANT__UPDATE_FAILURE',
  GRANT__CONTACT_CREATE_REQUEST: 'GRANT__CONTACT_CREATE_REQUEST',
  GRANT__CONTACT_CREATE_SUCCESS: 'GRANT__CONTACT_CREATE_SUCCESS',
  GRANT__CONTACT_CREATE_FAILURE: 'GRANT__CONTACT_CREATE_FAILURE',
  GRANT__CONTACT_DELETE_REQUEST: 'GRANT__CONTACT_DELETE_REQUEST',
  GRANT__CONTACT_DELETE_SUCCESS: 'GRANT__CONTACT_DELETE_SUCCESS',
  GRANT__CONTACT_DELETE_FAILURE: 'GRANT__CONTACT_DELETE_FAILURE',
  GRANT__LOCATION_CREATE_REQUEST: 'GRANT__LOCATION_CREATE_REQUEST',
  GRANT__LOCATION_CREATE_SUCCESS: 'GRANT__LOCATION_CREATE_SUCCESS',
  GRANT__LOCATION_CREATE_FAILURE: 'GRANT__LOCATION_CREATE_FAILURE',
  GRANT__LOCATION_UPDATE_REQUEST: 'GRANT__LOCATION_UPDATE_REQUEST',
  GRANT__LOCATION_UPDATE_SUCCESS: 'GRANT__LOCATION_UPDATE_SUCCESS',
  GRANT__LOCATION_UPDATE_FAILURE: 'GRANT__LOCATION_UPDATE_FAILURE',
  GRANT__LOCATION_DELETE_REQUEST: 'GRANT__LOCATION_DELETE_REQUEST',
  GRANT__LOCATION_DELETE_SUCCESS: 'GRANT__LOCATION_DELETE_SUCCESS',
  GRANT__LOCATION_DELETE_FAILURE: 'GRANT__LOCATION_DELETE_FAILURE',
  GRANTEES__GET_REQUEST: 'GRANTEES__GET_REQUEST',
  GRANTEES__GET_SUCCESS: 'GRANTEES__GET_SUCCESS',
  GRANTEES__GET_FAILURE: 'GRANTEES__GET_FAILURE',
  GRANTEE__CREATE_REQUEST: 'GRANTEE__CREATE_REQUEST',
  GRANTEE__CREATE_SUCCESS: 'GRANTEE__CREATE_SUCCESS',
  GRANTEE__CREATE_FAILURE: 'GRANTEE__CREATE_FAILURE',
  GRANTEE__DELETE_REQUEST: 'GRANTEE__DELETE_REQUEST',
  GRANTEE__DELETE_SUCCESS: 'GRANTEE__DELETE_SUCCESS',
  GRANTEE__DELETE_FAILURE: 'GRANTEE__DELETE_FAILURE',
  GRANTEE__UPDATE_REQUEST: 'GRANTEE__UPDATE_REQUEST',
  GRANTEE__UPDATE_SUCCESS: 'GRANTEE__UPDATE_SUCCESS',
  GRANTEE__UPDATE_FAILURE: 'GRANTEE__UPDATE_FAILURE',
  GRANTEE__CONTACT_CREATE_REQUEST: 'GRANTEE__CONTACT_CREATE_REQUEST',
  GRANTEE__CONTACT_CREATE_SUCCESS: 'GRANTEE__CONTACT_CREATE_SUCCESS',
  GRANTEE__CONTACT_CREATE_FAILURE: 'GRANTEE__CONTACT_CREATE_FAILURE',
  GRANTEE__CONTACT_UPDATE_REQUEST: 'GRANTEE__CONTACT_UPDATE_REQUEST',
  GRANTEE__CONTACT_UPDATE_SUCCESS: 'GRANTEE__CONTACT_UPDATE_SUCCESS',
  GRANTEE__CONTACT_UPDATE_FAILURE: 'GRANTEE__CONTACT_UPDATE_FAILURE',
  GRANTEE__CONTACT_DELETE_REQUEST: 'GRANTEE__CONTACT_DELETE_REQUEST',
  GRANTEE__CONTACT_DELETE_SUCCESS: 'GRANTEE__CONTACT_DELETE_SUCCESS',
  GRANTEE__CONTACT_DELETE_FAILURE: 'GRANTEE__CONTACT_DELETE_FAILURE',
  SURVEYS__GET_FAILURE: 'SURVEYS__GET_FAILURE',
  SURVEYS__GET_REQUEST: 'SURVEYS__GET_REQUEST',
  SURVEYS__GET_SUCCESS: 'SURVEYS__GET_SUCCESS',
  SURVEY__ADD_QUESTION: 'SURVEY__ADD_QUESTION',
  SURVEY__MOVE_QUESTION: 'SURVEY__MOVE_QUESTION',
  SURVEY__QUESTION_CHANGE_PROPERTY: 'SURVEY__QUESTION_CHANGE_PROPERTY',
  SURVEY__UPDATE_QUESTION: 'SURVEY__UPDATE_QUESTION',
  SURVEY__CHANGE_NAME: 'SURVEY__CHANGE_NAME',
  SURVEY__QUESTION_CHANGE_I18N: 'SURVEY__QUESTION_CHANGE_I18N',
  SURVEY__QUESTION_DELETE: 'SURVEY__QUESTION_DELETE',
  SURVEY__QUESTION_CLONE: 'SURVEY__QUESTION_CLONE',
  SURVEY__GET_FAILURE: 'SURVEY__GET_FAILURE',
  SURVEY__GET_REQUEST: 'SURVEY__GET_REQUEST',
  SURVEY__GET_SUCCESS: 'SURVEY__GET_SUCCESS',
  SURVEY__RESET: 'SURVEY__RESET',
  SURVEY__SAVE_FAILURE: 'SURVEY__SAVE_FAILURE',
  SURVEY__SAVE_REQUEST: 'SURVEY__SAVE_REQUEST',
  SURVEY__SAVE_SUCCESS: 'SURVEY__SAVE_SUCCESS',
  SURVEY__DUPLICATE_REQUEST: 'SURVEY__DUPLICATE_REQUEST',
  SURVEY__DUPLICATE_SUCCESS: 'SURVEY__DUPLICATE_SUCCESS',
  SURVEY__DUPLICATE_FAILURE: 'SURVEY__DUPLICATE_FAILURE',
  SURVEY__GRANTEES_UPDATE_SUCCESS: 'SURVEY__GRANTEES_UPDATE_SUCCESS',
  SURVEY__ADD_QUESTION_TAG: 'SURVEY__ADD_QUESTION_TAG',
  SURVEY__REMOVE_QUESTION_TAG: 'SURVEY__REMOVE_QUESTION_TAG',
  SURVEY__DELETE_FAILURE: 'SURVEY__DELETE_FAILURE',
  SURVEY__DELETE_REQUEST: 'SURVEY__DELETE_REQUEST',
  SURVEY__DELETE_SUCCESS: 'SURVEY__DELETE_SUCCESS',
  SURVEY__REVIEW_GET_FAILURE: 'SURVEY__REVIEW_GET_FAILURE',
  SURVEY__REVIEW_GET_REQUEST: 'SURVEY__REVIEW_GET_REQUEST',
  SURVEY__REVIEW_GET_SUCCESS: 'SURVEY__REVIEW_GET_SUCCESS',
  SURVEY__REVIEW_SURVEY_ANSWER_POST_REQUEST:
    'SURVEY__REVIEW_SURVEY_ANSWER_POST_REQUEST',
  SURVEY__REVIEW_SURVEY_ANSWER_POST_SUCCESS:
    'SURVEY__REVIEW_SURVEY_ANSWER_POST_SUCCESS',
  SURVEY__REVIEW_SURVEY_ANSWER_POST_FAILURE:
    'SURVEY__REVIEW_SURVEY_ANSWER_POST_FAILURE',
  SURVEY__REVIEW_SURVEY_ANSWER_PUT_REQUEST:
    'SURVEY__REVIEW_SURVEY_ANSWER_PUT_REQUEST',
  SURVEY__REVIEW_SURVEY_ANSWER_PUT_SUCCESS:
    'SURVEY__REVIEW_SURVEY_ANSWER_PUT_SUCCESS',
  SURVEY__REVIEW_SURVEY_ANSWER_PUT_FAILURE:
    'SURVEY__REVIEW_SURVEY_ANSWER_PUT_FAILURE',
  SURVEY__REVIEW_CHANGE_ANSWER_VALUE: 'SURVEY__REVIEW_CHANGE_ANSWER_VALUE',
  SURVEY__REVIEW_CHANGE_ANSWER_TAGS: 'SURVEY__REVIEW_CHANGE_ANSWER_TAGS',
  SURVEY__REVIEW_CHANGE_ANSWER_OTHER_CHOICE_VALUE:
    'SURVEY__REVIEW_CHANGE_ANSWER_TAGS',
  SURVEY__REVIEW_CHANGE_ANSWER_TAGGINGS:
    'SURVEY__REVIEW_CHANGE_ANSWER_TAGGINGS',
  SURVEY__REVIEW_CHANGE_ANSWER_TAGGINGS_REMOVE:
    'SURVEY__REVIEW_CHANGE_ANSWER_TAGGINGS_REMOVE',

  SURVEY__GRANTEES_RESEND_REQUEST: 'SURVEY__GRANTEES_RESEND_REQUEST',
  SURVEY__GRANTEES_RESEND_SUCCESS: 'SURVEY__GRANTEES_RESEND_SUCCESS',
  SURVEY__GRANTEES_RESEND_FAILURE: 'SURVEY__GRANTEES_RESEND_FAILURE',
  SURVEY__GRANTEES_DELETE_REQUEST: 'SURVEY__GRANTEES_DELETE_REQUEST',
  SURVEY__GRANTEES_DELETE_FAILURE: 'SURVEY__GRANTEES_DELETE_FAILURE',
  SURVEY__GRANTEES_DELETE_SUCCESS: 'SURVEY__GRANTEES_DELETE_SUCCESS',

  SURVEY__CHANGE_ANSWER_VALUE: 'SURVEY__CHANGE_ANSWER_VALUE',
  SURVEY__CHANGE_ANSWER_TAGS: 'SURVEY__CHANGE_ANSWER_TAGS',
  SURVEY__CHANGE_ANSWER_OTHER_CHOICE_VALUE:
    'SURVEY__CHANGE_ANSWER_OTHER_CHOICE_VALUE',

  SURVEY__CHANGE_PROPERTY: 'SURVEY__CHANGE_PROPERTY',
  SURVEY__VALIDATION_FAILURE: 'SURVEY__VALIDATION_FAILURE',
  SURVEY_PUBLIC__CLEAR: 'SURVEY_PUBLIC__CLEAR',
  SURVEY_PUBLIC__LANGUAGE_CHANGE: 'SURVEY_PUBLIC__LANGUAGE_CHANGE',
  SURVEY_PUBLIC__PUT_REQUEST: 'SURVEY_PUBLIC__PUT_REQUEST',
  SURVEY_PUBLIC__PUT_SUCCESS: 'SURVEY_PUBLIC__PUT_SUCCESS',
  SURVEY_PUBLIC__PUT_FAILURE: 'SURVEY_PUBLIC__PUT_FAILURE',
  SURVEY_PUBLIC__GET_REQUEST: 'SURVEY_PUBLIC__GET_REQUEST',
  SURVEY_PUBLIC__GET_SUCCESS: 'SURVEY_PUBLIC__GET_SUCCESS',
  SURVEY_PUBLIC__GET_FAILURE: 'SURVEY_PUBLIC__GET_FAILURE',
  REPORTS__IDS_GET_REQUEST: 'REPORTS__IDS_GET_REQUEST',
  REPORTS__IDS_GET_SUCCESS: 'REPORTS__IDS_GET_SUCCESS',
  REPORTS__IDS_GET_FAILURE: 'REPORTS__IDS_GET_FAILURE',
  REPORTS__TRINT_DOCUMENTS_GET_REQUEST: 'REPORTS__TRINT_DOCUMENTS_GET_REQUEST',
  REPORTS__TRINT_DOCUMENTS_GET_SUCCESS: 'REPORTS__TRINT_DOCUMENTS_GET_SUCCESS',
  REPORTS__TRINT_DOCUMENTS_GET_FAILURE: 'REPORTS__TRINT_DOCUMENTS_GET_FAILURE',
  REPORTS__IMPORT_TRINT_DOCUMENTS_REQUEST:
    'REPORTS__IMPORT_TRINT_DOCUMENTS_REQUEST',
  REPORTS__IMPORT_TRINT_DOCUMENTS_SUCCESS:
    'REPORTS__IMPORT_TRINT_DOCUMENTS_SUCCESS',
  REPORTS__IMPORT_TRINT_DOCUMENTS_FAILURE:
    'REPORTS__IMPORT_TRINT_DOCUMENTS_FAILURE',
  REPORT__GET_REQUEST: 'REPORT__GET_REQUEST',
  REPORT__GET_SUCCESS: 'REPORT__GET_SUCCESS',
  REPORT__GET_FAILURE: 'REPORT__GET_FAILURE',
  REPORT__UPDATE_REQUEST: 'REPORT__UPDATE_REQUEST',
  REPORT__UPDATE_SUCCESS: 'REPORT__UPDATE_SUCCESS',
  REPORT__UPDATE_FAILURE: 'REPORT__UPDATE_FAILURE',
  REPORT__EDIT_REQUEST: 'REPORT__EDIT_REQUEST',
  REPORT__EDIT_SUCCESS: 'REPORT__EDIT_SUCCESS',
  REPORT__EDIT_FAILURE: 'REPORT__EDIT_FAILURE',
  REPORT__UPLOAD_REQUEST: 'REPORT__UPLOAD_REQUEST',
  REPORT__UPLOAD_SUCCESS: 'REPORT__UPLOAD_SUCCESS',
  REPORT__UPLOAD_FAILURE: 'REPORT__UPLOAD_FAILURE',
  REPORT__EXPAND_STRATEGY_WORD: 'REPORT__EXPAND_STRATEGY_WORD',
  REPORT__EXPAND_STRATEGY_SENTENCE: 'REPORT__EXPAND_STRATEGY_SENTENCE',
  REPORT__EXPAND_STRATEGY_PARAGRAPH: 'REPORT__EXPAND_STRATEGY_PARAGRAPH',
  REPORT__EXPAND_STRATEGY_NONE: 'REPORT__EXPAND_STRATEGY_NONE',
  PROJECT__QUOTES_GET_REQUEST: 'PROJECT__QUOTES_GET_REQUEST',
  PROJECT__QUOTES_GET_SUCCESS: 'PROJECT__QUOTES_GET_SUCCESS',
  PROJECT__QUOTES_GET_FAILURE: 'PROJECT__QUOTES_GET_FAILURE',
  FUNDING__CREATE_REQUEST: 'FUNDING__CREATE_REQUEST',
  FUNDING__CREATE_FAILURE: 'FUNDING__CREATE_FAILURE',
  FUNDING__CREATE_SUCCESS: 'FUNDING__CREATE_SUCCESS',
  FUNDING__UPDATE_REQUEST: 'FUNDING__UPDATE_REQUEST',
  FUNDING__UPDATE_FAILURE: 'FUNDING__UPDATE_FAILURE',
  FUNDING__UPDATE_SUCCESS: 'FUNDING__UPDATE_SUCCESS',
  FUNDING__DELETE_REQUEST: 'FUNDING__DELETE_REQUEST',
  FUNDING__DELETE_FAILURE: 'FUNDING__DELETE_FAILURE',
  FUNDING__DELETE_SUCCESS: 'FUNDING__DELETE_SUCCESS',
  CHART__GET_FAILURE: 'CHART__GET_FAILURE',
  CHART__GET_REQUEST: 'CHART__GET_REQUEST',
  CHART__GET_SUCCESS: 'CHART__GET_SUCCESS',
  CHART__SAVE_FAILURE: 'CHART__SAVE_FAILURE',
  CHART__SAVE_REQUEST: 'CHART__SAVE_REQUEST',
  CHART__SAVE_SUCCESS: 'CHART__SAVE_SUCCESS',
  CHART__DELETE_FAILURE: 'CHART__DELETE_FAILURE',
  CHART__DELETE_REQUEST: 'CHART__DELETE_REQUEST',
  CHART__DELETE_SUCCESS: 'CHART__DELETE_SUCCESS',
  CHART__EDITED: 'CHART__EDITED',
  CHART__CSV_UPLOAD: 'CHART__CSV_UPLOAD',
  CHART__CLEANUP_STORE: 'CHART__CLEANUP_STORE',
  CHART__AXIS_VALUE_TYPES_GET_REQUEST: 'CHART__AXIS_VALUE_TYPES_GET_REQUEST',
  CHART__AXIS_VALUE_TYPES_GET_SUCCESS: 'CHART__AXIS_VALUE_TYPES_GET_SUCCESS',
  CHART__AXIS_VALUE_TYPES_GET_FAILURE: 'CHART__AXIS_VALUE_TYPES_GET_FAILURE',
  CHART__AXIS_VALUES_GET_REQUEST: 'CHART__AXIS_VALUES_GET_REQUEST',
  CHART__AXIS_VALUES_GET_SUCCESS: 'CHART__AXIS_VALUES_GET_SUCCESS',
  CHART__AXIS_VALUES_GET_FAILURE: 'CHART__AXIS_VALUES_GET_FAILURE',
  CHART__AXIS_DATA_GET_REQUEST: 'CHART__AXIS_DATA_GET_REQUEST',
  CHART__AXIS_DATA_GET_SUCCESS: 'CHART__AXIS_DATA_GET_SUCCESS',
  CHART__AXIS_DATA_GET_FAILURE: 'CHART__AXIS_DATA_GET_FAILURE',
  GEO_LOCATION__COUNTRIES_GET_REQUEST: 'GEO_LOCATION__COUNTRIES_GET_REQUEST',
  GEO_LOCATION__COUNTRIES_GET_SUCCESS: 'GEO_LOCATION__COUNTRIES_GET_SUCCESS',
  GEO_LOCATION__COUNTRIES_GET_FAILURE: 'GEO_LOCATION__COUNTRIES_GET_FAILURE',
  GEO_LOCATION__STATES_GET_REQUEST: 'GEO_LOCATION__STATES_GET_REQUEST',
  GEO_LOCATION__STATES_GET_SUCCESS: 'GEO_LOCATION__STATES_GET_SUCCESS',
  GEO_LOCATION__STATES_GET_FAILURE: 'GEO_LOCATION__STATES_GET_FAILURE',
  GEO_LOCATION__CITIES_GET_REQUEST: 'GEO_LOCATION__CITIES_GET_REQUEST',
  GEO_LOCATION__CITIES_GET_SUCCESS: 'GEO_LOCATION__CITIES_GET_SUCCESS',
  GEO_LOCATION__CITIES_GET_FAILURE: 'GEO_LOCATION__CITIES_GET_FAILURE',
  NOTIFICATIONS__SHOW: 'NOTIFICATIONS__SHOW',
  SECURE_UPLOAD__REQUEST: 'SECURE_UPLOAD__REQUEST',
  SECURE_UPLOAD__SUCCESS: 'SECURE_UPLOAD__SUCCESS',
  SECURE_UPLOAD__FAILURE: 'SECURE_UPLOAD__FAILURE',
  GET_DEFAULT_REGIONS_REQUEST: 'GET_DEFAULT_REGIONS_REQUEST',
  GET_DEFAULT_REGIONS_SUCCESS: 'GET_DEFAULT_REGIONS_SUCCESS',
  GET_DEFAULT_REGIONS_FAILURE: 'GET_DEFAULT_REGIONS_FAILURE',
  DICTIONARY__PROJECT_SET: 'DICTIONARY__PROJECT_SET',
  DICTIONARY__PROJECT_CLEAR: 'DICTIONARY__PROJECT_CLEAR',
  DICTIONARY__ORGANIZATION_SET: 'DICTIONARY__ORGANIZATION_SET',
  DICTIONARY__ORGANIZATION_CLEAR: 'DICTIONARY__ORGANIZATION_CLEAR',
  METATAGS__GET_SUCCESS: 'METATAGS__GET_SUCCESS',
  TAG__UPDATE_SUCCESS: 'TAG__UPDATE_SUCCESS',
};
