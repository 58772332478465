import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import { Button, Dialog } from 'src/components/IMUI';
import { getLanguageForCode } from 'src/services/DictionaryService';

import cls from './WarningDialog.module.css';

const cx = classNames.bind(cls);

export default class WarningDialog extends React.Component {
  static propTypes = {
    completeness: PropTypes.object,
    open: PropTypes.bool,
    onCancel: PropTypes.func,
    onGoToDesign: PropTypes.func,
  };

  renderList() {
    const { completeness } = this.props;
    const languageCompletenss = Object.keys(completeness)
      .map((lang) => ({
        language: getLanguageForCode(lang),
        complete: completeness[lang].complete,
        percentage: completeness[lang].percentage,
      }))
      .filter((el) => el.language);

    return (
      <ol className={cls.warningDialogList}>
        {languageCompletenss.map(({ language, percentage, complete }) => (
          <li key={language}>
            <span className={cx({ [cls.warningDialogError]: !complete })}>
              {percentage}
            </span>{' '}
            {language}
          </li>
        ))}
      </ol>
    );
  }

  render() {
    const { open } = this.props;

    return (
      <Dialog
        small
        title="Missing translations"
        rightActions={
          <Button
            secondary
            size="l"
            label="Go back to Design"
            onClick={this.props.onGoToDesign}
          />
        }
        leftActions={
          <Button
            text
            size="l"
            label="Proceed"
            className={cls.warningDialogError}
            onClick={this.props.onCancel}
          />
        }
        open={open}
        onRequestClose={this.props.onCancel}
      >
        <span>Your survey is not fully translated.</span>
        {this.renderList()}
      </Dialog>
    );
  }
}
