import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import React from 'react';
import { connect } from 'react-redux';
import { v1 as uuidv1 } from 'uuid';

import { changeQuestionI18n } from 'src/actionCreators/surveyActionCreators';
import { Actions, Button, Container, TextField } from 'src/components/IMUI';
import { Error } from 'src/components/IMUI/Forms/base';
import TagSelector from 'src/components/TagSelector/TagSelector';
import TranslateButton from 'src/components/TranslateButton/TranslateButton';
import { MULTIPLE_CHOICE, SINGLE_CHOICE } from 'src/data/questionTypes';
import { getSurveyI18n } from 'src/utils/surveysI18n';

import { Icon } from 'im/ui/Icon';

import BaseQuestionProperties from './BaseQuestionProperties';
import Option from 'src/pages/App/Analysis/Surveys/Survey/Builder/components/Option';
import RestrictAnswersSetting from './RestrictAnswersSetting';
import { canBuildSurvey } from 'src/userStorage';
import { T, withTranslators } from 'lioness';

@connect(pick(['survey']), { changeQuestionI18n })
class ChoiceQuestionProperties extends React.PureComponent {
  static propTypes = {
    survey: PropTypes.object,
    question: PropTypes.object,
    completeness: PropTypes.object,
    errors: PropTypes.object,
    tagsWithTagGroups: PropTypes.array,
    onChangeQuestionProperty: PropTypes.func,
    changeQuestionI18n: PropTypes.func,
    readOnly: PropTypes.bool,
    isAnswerMode: PropTypes.bool,
    type: PropTypes.string,
  };

  static defaultProps = {
    errors: {},
    question: {},
  };

  static validate(question = {}) {
    const errors = {};
    if (!question?.options?.length)
      errors.noOptions = 'Must have at least one option';
    question?.options?.forEach((option, i) => {
      if (!option?.title || !option?.title.trim()) {
        errors.options ||= [];
        errors.options[i] = 'Option title is required';
      }
    });
    return errors;
  }

  get options() {
    return this.props.question.options || [];
  }
  handleOptionChange = (value, index) => {
    this.handleUpdateI18n(value, this.options[index].id, [
      'options',
      index,
      'title',
    ]);
  };

  handleOtherChoice = () => {
    this.props.onChangeQuestionProperty(
      this.props.question.id,
      'hasOther',
      !this.props.question.hasOther
    );
  };

  handleOtherValueChange = (value) => {
    this.props.onChangeQuestionProperty(
      this.props.question.id,
      'otherValue',
      value
    );
  };

  handleOptionOrderChange = (oldIndex, newIndex, relative = false) => {
    if (!this.options) return;
    const newOptions = [...this.options];
    const targetIndex = relative ? oldIndex + newIndex : newIndex;
    const elementToMove = newOptions[oldIndex];
    newOptions.splice(oldIndex, 1);
    newOptions.splice(targetIndex, 0, elementToMove);
    this.props.onChangeQuestionProperty(this.props.question.id, 'options', [
      ...newOptions,
    ]);
  };

  handleInvertOptions = (ev) => {
    this.props.onChangeQuestionProperty(
      this.props.question.id,
      'options',
      [...this.options].reverse()
    );
  };

  handleOptionAdd = (tagId, title = '') => {
    this.props.onChangeQuestionProperty(this.props.question.id, 'options', [
      ...this.options,
      { title, tag_id: tagId, id: uuidv1() },
    ]);
  };

  handleOptionAddAt = (index = this.options.length) => {
    this.props.onChangeQuestionProperty(this.props.question.id, 'options', [
      ...this.options.slice(0, index),
      { title: '', tag_id: undefined, id: uuidv1() },
      ...this.options.slice(index),
    ]);
  };

  handleOptionDelete = (index) => {
    if (!this.options) return;
    this.options.splice(index, 1);
    this.props.onChangeQuestionProperty(
      this.props.question.id,
      'options',
      this.options
    );
  };

  handleTagRemove = (index, tag, otherValue = false) => {
    if (otherValue)
      return this.props.onChangeQuestionProperty(
        this.props.question.id,
        'other_tag_id',
        undefined
      );
    this.options[index] ||= {};
    this.options[index].tag_id = undefined;
    if (this.options[index].title == tag.name) {
      this.options[index].title = '';
    }
    this.props.onChangeQuestionProperty(
      this.props.question.id,
      'options',
      this.options
    );
  };

  handleTagsChanged = (index, tagId, tag, otherValue = false) => {
    if (otherValue) {
      this.props.onChangeQuestionProperty(
        this.props.question.id,
        'other_tag_id',
        tagId
      );
      return (this.props.tagsWithTagGroups || []).push(tag);
    }
    this.options[index].tag_id = tagId;
    this.options[index].title ||= tag.name;
    this.props.onChangeQuestionProperty(
      this.props.question.id,
      'options',
      this.options
    );
    (this.props.tagsWithTagGroups || []).push(tag);
  };

  handleTagsQuickAddChanged = (tagIds, tags) => {
    const existingIndices = [];
    const deletingIndices = [];
    this.options.forEach((option, index) =>
      tagIds.indexOf(option.tag_id) > -1
        ? existingIndices.push(tagIds[tagIds.indexOf(option.tag_id)])
        : deletingIndices.push(index)
    );
    deletingIndices
      .sort((a, b) => b - a)
      .forEach((index) => this.handleOptionDelete(index));
    tagIds.forEach((tagId, index) => {
      if (existingIndices.indexOf(tagId) === -1) {
        this.handleOptionAdd(tagId, tags[index].name);
      }
    });
  };

  handleUpdateI18n = (values, path, altPath) => {
    if (typeof values === 'string') {
      this.props.changeQuestionI18n?.(
        this.props.question,
        values,
        path,
        altPath
      );
    } else {
      this.props.survey.languages.forEach((langCode) => {
        this.props.changeQuestionI18n?.(
          this.props.question,
          values[langCode],
          path,
          altPath,
          langCode
        );
      });
    }
  };

  renderSettings = () => {
    if (this.props.question.type != MULTIPLE_CHOICE) return null;
    return (
      <RestrictAnswersSetting
        key={this.options.length}
        id={this.props.question.id}
        settings={this.props.question.settings || {}}
        options={this.options}
        onChangeQuestionProperty={this.props.onChangeQuestionProperty}
        label="Require a number of selected options"
      />
    );
  };

  renderOtherChoiceField = () => {
    const label =
      getSurveyI18n(this.props.survey, this.props.question, 'other') || '';

    return (
      <div>
        <h4 style={{ marginTop: 8, marginBottom: 8 }}>
          <T>Other</T>
          <i>&ensp;{label}</i>
        </h4>
        <Option
          key="option-other"
          index={0}
          actionsRight={() => (
            <TranslateButton
              owner={this.props.question}
              languages={this.props.survey.languages}
              error={
                !!this.props.completeness.options[this.props.question.other]
                  ?.title?.length
              }
              values={this.props.question.i18n}
              path={'other'}
              title="Translate other option title"
              onChange={this.handleUpdateI18n}
            />
          )}
        >
          <Container horizontal nowrap>
            <TextField
              multiLine
              rowsMax={3}
              fullWidth
              flatDark
              noValidation
              name="option_other"
              hintText="Type in..."
              value={this.props.question.otherValue}
              onChange={(value) => this.handleOtherValueChange(value)}
            />
            <TagSelector
              className="tagSelectorMini"
              isAnswerMode={this.props.isAnswerMode}
              readOnly={this.props.readOnly}
              tagsWithTagGroups={this.props.tagsWithTagGroups}
              noTagProps={{
                placeSelf: 'center',
                label: ' ',
                outline: true,
                className: 'tagChoice',
              }}
              selected={this.props.question.other_tag_id}
              onChange={(tagId, tag) =>
                this.handleTagsChanged(0, tagId, tag, true)
              }
              onRemove={(_, tag) => this.handleTagRemove(0, tag, true)}
            />
            <br />
          </Container>
        </Option>
      </div>
    );
  };

  render() {
    const { question, survey, errors, completeness } = this.props;
    const isSingleChoice = this.props.type == SINGLE_CHOICE;
    return (
      <BaseQuestionProperties
        {...this.props}
        renderSettings={this.renderSettings}
        hasRequired={isSingleChoice}
        handleOtherChoice={this.handleOtherChoice}
        hasOther={question.hasOther}
      >
        <h3>
          <Icon name="multiple" />
          &nbsp;Options
        </h3>

        {this.options.map((option = {}, index) => (
          <Option
            index={index}
            count={this.options.length}
            actionsLeft={() => (
              <TranslateButton
                owner={question}
                languages={survey.languages}
                error={!!completeness.options[option.id].title.length}
                values={question.i18n}
                path={option.id}
                altPath={['options', index, 'title']}
                title="Translate option title"
                onChange={this.handleUpdateI18n}
              />
            )}
            onOptionDelete={this.handleOptionDelete}
            onOrderChange={this.handleOptionOrderChange}
            onAddRow={() => this.handleOptionAddAt(index + 1)}
            key={`option-${option.id || index}`}
            spaceBetween
          >
            <Container horizontal nowrap>
              <TextField
                multiLine
                rowsMax={3}
                fullWidth
                flatDark
                noValidation
                name={`option_${index}`}
                hintText={
                  !getSurveyI18n(survey, question, option.id, [
                    'options',
                    index,
                    'title',
                  ]) && 'Type in...'
                }
                value={
                  getSurveyI18n(survey, question, option.id, [
                    'options',
                    index,
                    'title',
                  ]) || ''
                }
                onChange={(value) => this.handleOptionChange(value, index)}
                error={errors.options?.[index]}
              />

              <TagSelector
                className="tagSelectorMini"
                isAnswerMode={this.props.isAnswerMode}
                readOnly={this.props.readOnly}
                tagsWithTagGroups={this.props.tagsWithTagGroups}
                noTagProps={{
                  placeSelf: 'center',
                  label: ' ',
                  outline: true,
                  className: 'tagChoice',
                }}
                selected={option.tag_id}
                onChange={(tagId, tag) =>
                  this.handleTagsChanged(index, tagId, tag)
                }
                onRemove={(tagId, tag) => this.handleTagRemove(index, tag)}
              />
            </Container>
          </Option>
        ))}

        {!this.options.length && (
          <Container horizontal>
            <Button
              icon={<Icon name="plus" style={{ fontSize: 12 }} />}
              style={{ marginLeft: 8, placeSelf: 'start' }}
              disabled={!canBuildSurvey()}
              action
              size="s"
              label="Add an option"
              onClick={() => this.handleOptionAddAt(this.options.length)}
            />
            <br />
            <br />
          </Container>
        )}

        {this.props.question.hasOther && this.renderOtherChoiceField()}

        <Container horizontal>
          <Actions>
            <TagSelector
              multiple
              isAnswerMode={this.props.isAnswerMode}
              showTags={false}
              readOnly={this.props.readOnly}
              openOnTagClick={false}
              tagsWithTagGroups={this.props.tagsWithTagGroups}
              noTagProps={{ label: 'QUICK ADD', outline: true }}
              selected={this.props.question?.options?.map((o) => o.tag_id)}
              onChange={this.handleTagsQuickAddChanged}
            />
          </Actions>
          {errors.noOptions && <Error error={errors.noOptions} />}
        </Container>

        {this.options.length > 1 && (
          <Container horizontal>
            <Button
              size="l"
              text
              action
              label="Invert options order"
              onClick={this.handleInvertOptions}
            />
          </Container>
        )}
      </BaseQuestionProperties>
    );
  }
}
export default withTranslators(ChoiceQuestionProperties);
