import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';
import { getOrgText } from 'src/services/DictionaryService';
import { handleSubmitFail } from 'src/utils/validation';
import GrantBase, { validateGrant } from '../../components/GrantBase';
import GrantAdditional from '../../components/GrantAdditional';
import cls from '../GrantAddSteps.module.css';
import { arrToObj } from 'src/utils/array';

class GrantStepOne extends React.PureComponent {
  static propTypes = {
    hideHeader: PropTypes.bool,
    organization: PropTypes.object,
    fundingTypes: PropTypes.array,
    defaultRegions: PropTypes.array,
    change: PropTypes.func.isRequired,
    possibleValues: PropTypes.object,
    customGrantFields: PropTypes.array,
  };

  static defaultProps = {
    hideHeader: false,
    customGrantFields: [],
  };

  render() {
    if (!this.props.organization) return null;
    const noSubmit = (e) => {
      e.stopPropagation();
      e.preventDefault();
    };
    return (
      <form onSubmit={noSubmit} noValidate autoComplete="off">
        {!this.props.hideHeader && (
          <h3 className={cls.titleCustomMargin}>
            {getOrgText('Grant details')}
          </h3>
        )}
        <GrantBase
          formName="newGrant"
          fundingTypes={this.props.fundingTypes}
          statuses={this.props.organization.grant_statuses}
          regions={this.props.defaultRegions}
          onFieldValueChange={({ fieldName, value }) => {
            this.props.change(fieldName, value || null);
          }}
        />
        <GrantAdditional
          possibleValues={this.props.possibleValues}
          customFields={arrToObj(this.props.customGrantFields)}
        />
      </form>
    );
  }
}

function validate(values, props) {
  return { ...validateGrant(values, props) };
}

export default connect((state) => ({
  formValues: getFormValues('newGrant')(state),
  initialValues: { recipient_type: 'organization' },
}))(
  reduxForm({
    validate,
    form: 'newGrant',
    enableReinitialize: false,
    keepDirtyOnReinitialize: true,
    updateUnregisteredFields: true,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    onSubmitFail: (errors) => handleSubmitFail(errors),
  })(GrantStepOne)
);
