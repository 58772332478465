import { where } from 'im/api/Query';
import reducerRegistry, { advancedFiltersRegistry } from 'im/api/registry';
import ResourceCollection from 'im/api/ResourceCollection';

export const getGroupForTag = (tag) => tag?.tag_categories?.[0] ?? {};
export const mapWithGroup = (array) =>
  array?.map((t) => ({ ...t, tagGroup: getGroupForTag(t) })) || [];

// sorted starting from root to parent
export const getParentCategories = (tag = {}) => {
  const parent = getGroupForTag(tag);
  if (!parent?.nested_parent_categories) return [];
  const sortedAncestors = parent.nested_parent_categories?.sort(
    (category1, category2) => {
      if (category1.child_categories?.some((el) => el.id === category2?.id))
        return -1;
      if (category2.child_categories?.some((el) => el.id === category1?.id))
        return 1;
      return 0;
    }
  );
  return [...sortedAncestors, parent];
};

const apiConfig = {
  root: '/api/v3/tags',
  type: 'tags',
  api: {
    allow: ['findAll', 'create', 'put', 'destroy'],
    custom: {
      findAllTree: {
        method: 'GET',
        path: where()
          .include('tag_categories', 'tag_categories.nested_parent_categories')
          .paginate({ size: 1000 })
          .toString(),
        meta: {
          includeMapper: {
            'tag_categories.nested_parent_categories': [
              'tag_categories.parent',
              'parent.parent',
            ],
          },
        },
      },
    },
  },
  derived: () => ({ getGroupForTag }),
};

const apiConfigProject = {
  ...apiConfig,
  api: {
    allow: ['create', 'put', 'destroy'],
    findAll: {
      path: where()
        .filter('tag_categories_id_eq_any', ':projectTagCategoryIds')
        .paginate({ size: 1000 })
        .toString(),
    },
    custom: {
      findAllTree: {
        ...apiConfig.api.custom.findAllTree,
        path: where()
          .filter('tag_categories_id_eq_any', ':projectTagCategoryIds')
          .include('tag_categories', 'tag_categories.nested_parent_categories')
          .filter('tag_categories_id_eq_any', ':projectTagCategoryIds')
          .paginate({ size: 1000 })
          .toString(),
      },
      mergeTags: {
        path: ':tagIntoId',
        method: 'PUT',
      },
    },
  },
};

const apiConfigProjectRecent = {
  ...apiConfig,
  api: {
    findAll: {
      path: where()
        .filter('tag_categories_id_eq_any', ':projectTagCategoryIds')
        .sort('-updated_at')
        .include('tag_categories')
        .fields('tag_category', 'title')
        .paginate({ size: 10 })
        .toString(),
    },
  },
};

export const Tags = new ResourceCollection(apiConfig, 'tags');
reducerRegistry.register(Tags);

export const TagsOfProject = new ResourceCollection(
  apiConfigProject,
  'projectTags'
);
reducerRegistry.register(TagsOfProject);

export const TagsOfProjectRecent = new ResourceCollection(
  apiConfigProjectRecent,
  'projectTagsRecent'
);
reducerRegistry.register(TagsOfProjectRecent);

const advancedFiltersConfig = {
  actionPrefix: 'tagsAF',
  allow: ['title', 'group'],
  tag_category_tags_title: {
    type: 'tag_category_tags',
    root: '/api/v3/tag_category_tags',
    path: where().filter('tag_id_eq_any', ':tagIds').toString(),
  },
};

advancedFiltersRegistry.register(Tags, advancedFiltersConfig);

export const tagsGetAdvancedFilters = Tags.advancedFilters;

export default {
  ...Tags.api,
  ofProject: TagsOfProject.api,
  ofProjectRecent: TagsOfProjectRecent.api,
};
