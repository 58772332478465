import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Actions, Button, Container, TextField } from 'src/components/IMUI';
import TagCategorySelector from 'src/components/TagCategorySelector/TagCategorySelector';
import debounce from 'src/utils/debounce';
import { Icon } from 'im/ui/Icon';
import cls from './TagGroupListHeader.module.css';
import { canAutoCode, canManageTag } from 'src/userStorage';
const cx = classNames.bind(cls);
const filterIconSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 398 511.36"
  >
    <path
      fillRule="nonzero"
      d="M260.89 511.36H128.23V390.58L10.35 250.71c-4.66-5.79-7.87-11.8-9.34-17.35-1.4-5.22-1.37-10.32.25-14.84 1.8-5.01 5.39-9.04 10.82-11.58 3.74-1.75 8.37-2.74 13.88-2.76l58.32-.01V43.87c0-12.08 4.95-23.06 12.88-30.99l.69-.63C105.75 4.68 116.46 0 128.16 0h117.36c3.26 0 6.16 1.58 7.98 4.01l73.69 79.7a9.882 9.882 0 0 1 2.64 6.74l.06 113.68h37.56c6.17-.22 11.62.74 16.12 2.68 5.46 2.31 9.55 6.11 12.01 11.01 2.37 4.74 3.05 10.31 1.81 16.37-1.16 5.63-4.01 11.75-8.8 18L270.48 390.64v120.72h-9.59zm74.33-258.27-53.77 63-14.51-12.44 53.76-63 14.52 12.44zm-65.93 77.26-19 22.28-14.52-12.45 19.01-22.28 14.51 12.45zM156.31 135.26c-4.71 0-8.54-3.83-8.54-8.54 0-4.71 3.83-8.54 8.54-8.54h101.57c4.71 0 8.54 3.83 8.54 8.54 0 4.71-3.83 8.54-8.54 8.54H156.31zm2.69 43.81c-4.72 0-8.55-3.83-8.55-8.54 0-4.72 3.83-8.54 8.55-8.54h96.18c4.71 0 8.54 3.82 8.54 8.54 0 4.71-3.83 8.54-8.54 8.54H159zm90.82-149.82v29.47c.67 8.73 3.71 15.58 9.05 20.14 5.52 4.71 13.8 7.38 24.76 7.58l19.04-.03-52.85-57.16zm60.11 74.24-26.43-.04c-15.18-.24-27.13-4.32-35.71-11.65-8.93-7.63-13.95-18.45-14.97-31.86l-.09-39.98H128.16c-6.35 0-12.16 2.5-16.41 6.53l-.47.5c-4.33 4.33-7.04 10.33-7.04 16.88v160.3l205.69-.03V103.49zM97.29 223.3c-.95.31-1.98.47-3.03.47-1.04 0-2.07-.16-3.02-.47l-65.24.01c-2.6-.03-4.54.32-5.84.92-.53.25-.84.51-.92.72-.24.71-.15 1.92.26 3.47.82 3.07 2.81 6.65 5.74 10.32l119.89 142.27 2.28 6.14v105.03H251.3V387.15l2.33-6.22 119.84-140.46c2.94-3.87 4.62-7.29 5.21-10.13.36-1.71.27-3.08-.19-3.99-.37-.75-1.22-1.45-2.47-1.98-1.97-.88-4.71-1.27-8.12-1.1l-270.61.03z"
    />
  </svg>
);

export default class TagGroupListHeader extends React.PureComponent {
  static propTypes = {
    tagCategorySelectedId: PropTypes.string,
    tagCategoryFilterText: PropTypes.string,
    allTagCategories: PropTypes.array,
    onSearch: PropTypes.func,
    onCategoriesFilter: PropTypes.func,
    onCategorySelect: PropTypes.func,
    onSettingsClick: PropTypes.func,
    onMarkAsDoneClick: PropTypes.func,
    onMarkAsUndoneClick: PropTypes.func,
    onAITaggingRequestClick: PropTypes.func,
  };

  static DEFAULT_ALL_CATEGORIES_ITEM = { title: 'All tag groups', id: '-1' };
  constructor(props) {
    super(props);
    this.state = {
      showSearchInput: false,
      selectedTagCategory: this.getTagCategoryById(
        props.allTagCategories,
        props.tagCategorySelectedId
      ),
    };
  }
  getTagCategoryById(all, id) {
    return all
      .flatMap((c) => [c].concat(c.nested_child_categories))
      .filter((c) => !!c)
      .find((c) => c.id === id);
  }
  handleSelectCategory = (category) => {
    this.setState({
      selectedTagCategory: this.getTagCategoryById(
        this.props.allTagCategories,
        category.id
      ),
    });
    this.props.onCategorySelect(
      category.id === TagGroupListHeader.DEFAULT_ALL_CATEGORIES_ITEM.id
        ? undefined
        : category.id
    );
  };

  handleCategoriesFilter = debounce((searchText) => {
    this.props.onCategoriesFilter(searchText);
  }, 250);
  handleSearchToggle = () => {
    if (this.state.showSearchInput && this.props.tagCategoryFilterText) {
      this.handleSearch('');
    }
    this.setState({ showSearchInput: !this.state.showSearchInput });
  };

  handleSearch = debounce((value) => {
    this.props.onSearch(value);
  }, 250);
  renderSelectedCategory = () => {
    return (
      this.state.selectedTagCategory?.title ||
      TagGroupListHeader.DEFAULT_ALL_CATEGORIES_ITEM.title
    );
  };

  render() {
    const {
      allTagCategories,
      tagCategoryFilterText,
      tagCategorySelectedId,
      onSearch,
      onCategorySelect,
      onSettingsClick,
      onAITaggingRequestClick,
      onMarkAsDoneClick,
      onMarkAsUndoneClick,
    } = this.props;

    return (
      <Container className={cls.tagGroupListHeader}>
        <Container horizontal nowrap className={cls.tagGroupListHeaderInner}>
          {onSearch && (
            <Actions className={cls.tagGroupListHeaderItem} nogrow>
              <Button
                dark
                clean
                border
                icon={
                  <Icon style={{ padding: 1 }} tip="filter tags and groups">
                    {filterIconSvg()}
                  </Icon>
                }
                onClick={this.handleSearchToggle}
                className={cx({
                  [cls.headerItemButtonActive]: this.state.showSearchInput,
                })}
                data-tip="Search"
              />
            </Actions>
          )}

          {onCategorySelect && (
            <Actions
              className={cx(
                cls.tagGroupListHeaderItem,
                cls.tagGroupListHeaderItem_grow
              )}
            >
              <TagCategorySelector
                hasError={false}
                hasLabel={false}
                value={
                  tagCategorySelectedId ||
                  TagGroupListHeader.DEFAULT_ALL_CATEGORIES_ITEM.id
                }
                labelHint="Select tag group"
                allTagCategories={[
                  TagGroupListHeader.DEFAULT_ALL_CATEGORIES_ITEM,
                  ...allTagCategories,
                ]}
                renderSelected={this.renderSelectedCategory}
                onChange={this.handleSelectCategory}
                onSearchText={this.handleCategoriesFilter}
              />
            </Actions>
          )}

          {onSettingsClick && (
            <Actions nogrow className={cls.tagGroupListHeaderItem}>
              <Button
                dark
                clean
                border
                icon={<Icon name="settings" placeTip="left" tip="Settings" />}
                onClick={onSettingsClick}
              />
            </Actions>
          )}
          {canAutoCode() && canManageTag() && onAITaggingRequestClick && (
            <Actions nogrow className={cls.tagGroupListHeaderItem}>
              <Button
                dark
                clean
                border
                icon={
                  <Icon
                    name="intermediary"
                    placeTip="left"
                    tip="AI-assisted tagging"
                  />
                }
                onClick={onAITaggingRequestClick}
              />
            </Actions>
          )}
          {canManageTag() && onMarkAsDoneClick && (
            <Actions nogrow className={cls.tagGroupListHeaderItem}>
              <Button
                dark
                clean
                border
                icon={
                  <Icon name="check" placeTip="left" tip="Mark as tagged" />
                }
                onClick={onMarkAsDoneClick}
              />
            </Actions>
          )}
          {canManageTag() && onMarkAsUndoneClick && (
            <Actions nogrow className={cls.tagGroupListHeaderItem}>
              <Button
                dark
                clean
                border
                icon={
                  <Icon
                    name="view-off"
                    placeTip="left"
                    tip="Mark as untagged"
                  />
                }
                onClick={onMarkAsUndoneClick}
              />
            </Actions>
          )}
        </Container>

        {this.state.showSearchInput && (
          <div style={{ marginTop: 6 }}>
            <TextField
              inputStyle={{ maxHeight: 36, height: 36 }}
              fullWidth
              flat
              borderDark
              noValidation
              name="search"
              hintText="Filter tags and groups"
              value={tagCategoryFilterText}
              onChange={this.handleSearch}
            />
          </div>
        )}
      </Container>
    );
  }
}
