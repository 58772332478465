import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import isEmail from 'validator/lib/isEmail';
import { granteesGetAdvancedFilters } from 'src/api/Grantees';
import { grantsGetAdvancedFilters } from 'src/api/Grants';
import { Button, Dialog, TextField, SelectField } from 'src/components/IMUI';
import { getText } from 'src/services/DictionaryService';
import { canManageSurvey, surveyOnly } from 'src/userStorage';
import { dummyGrant, dummyGrantee } from 'src/utils/string';
import { where } from 'im/api/Query';
import { Icon } from 'im/ui/Icon';
import SelectAutocomplete from './SelectAutocomplete';

@connect(
  (state) => ({
    grantsAutocomplete: state.advancedFilters.jsonapiGrants,
    granteesAutocomplete: state.advancedFilters.jsonapiGrantees,
    project: state.project,
  }),
  {
    getGrantsAutocomplete: grantsGetAdvancedFilters.grantsAF_name,
    getGranteesAutocomplete: granteesGetAdvancedFilters.granteesAF_name,
  }
)
export default class EditContactModal extends React.Component {
  static propTypes = {
    src: PropTypes.object,
    open: PropTypes.bool,
    public: PropTypes.bool,
    project: PropTypes.object,
    batches: PropTypes.array,

    granteesAutocomplete: PropTypes.object,
    grantsAutocomplete: PropTypes.object,

    getGrantsAutocomplete: PropTypes.func.isRequired,
    getGranteesAutocomplete: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
  };

  static defaultProps = {
    batches: [],
  };

  constructor(props) {
    super(props);

    const { src } = props;

    const dataToValidate = {
      name: src.name || src.respondent_name || src.notified_name,
      email: src.email || src.respondent_email || src.notified_email,
      batch: src.batch,
      grant_id: src.grant_name === dummyGrant ? null : src.grant_id,
      grantee_id: src.grantee_name === dummyGrantee ? null : src.grantee_id,
    };

    this.state = {
      ...dataToValidate,
      id: src.id,
      errors: Object.keys(dataToValidate).reduce(
        (acc, key) => ({ ...acc, ...this.getErrors(key, dataToValidate[key]) }),
        {}
      ),
    };

    if (props.open) {
      this.handleRequestAutocomplete('grant');
      this.handleRequestAutocomplete('grantee');
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.handleRequestAutocomplete('grant');
      this.handleRequestAutocomplete('grantee');
    }
  }

  getErrors(inputName, inputValue, { errors = {} } = {}) {
    let newErrors = { ...errors };
    const grantErrorMsg = getText('Please specify both Grant and Grantee');

    switch (inputName) {
      case 'email': {
        newErrors = {
          ...newErrors,
          email:
            inputValue && !isEmail(inputValue) && 'This is not a valid email',
        };
        break;
      }
      case 'grant_id': {
        newErrors = {
          ...newErrors,
          grantId:
            !this.props.public && !surveyOnly() && !inputValue && grantErrorMsg,
        };
        break;
      }
      case 'grantee_id': {
        newErrors = {
          ...newErrors,
          granteeId:
            !this.props.public && !surveyOnly() && !inputValue && grantErrorMsg,
        };
        break;
      }
      default:
        return errors;
    }

    return newErrors;
  }

  setErrorPerInput = (inputName, inputValue) => {
    const { errors } = this.state;
    const newErrors = this.getErrors(inputName, inputValue, { errors });

    if (newErrors !== errors) {
      this.setState({ errors: newErrors });
    }
  };

  isValid() {
    return !Object.values(this.state.errors).filter((v) => v)?.length;
  }

  handleCancel = () => {
    this.props.onCancel();
  };

  handleSubmit = () => {
    const {
      id,
      name,
      email,
      batch,
      grant_id: grantId,
      grantee_id: granteeId,
    } = this.state;
    if (!this.isValid()) return;
    this.props.onSubmit({
      id,
      name,
      email,
      batch,
      grant_id: grantId,
      grantee_id: granteeId,
    });
  };

  handleAttrChange = (name, value) => {
    this.setState({ [name]: value || null }, () => {
      this.handleRequestAutocomplete(name === 'grant_id' ? 'grantee' : 'grant');
    });
    this.setErrorPerInput(name, value);
  };

  handleRequestAutocomplete = (mode, value) => {
    const { project } = this.props;
    const { grant_id: grantId, grantee_id: granteeId } = this.state;

    const query = where()
      .filter('project_reports_project_id_eq', project.id)
      .actionMeta({ identifiable: true });

    if ((mode === 'grant' && granteeId) || (mode === 'grantee' && grantId)) {
      const filterKey = mode === 'grant' ? 'grantees_id_eq' : 'grants_id_eq';
      const filterValue = mode === 'grant' ? granteeId : grantId;
      query.filter(filterKey, filterValue);
    }

    const run =
      mode === 'grant'
        ? this.props.getGrantsAutocomplete
        : this.props.getGranteesAutocomplete;
    run({ key: 'name', value }, query);
  };

  render() {
    const { open, batches, grantsAutocomplete, granteesAutocomplete } =
      this.props;
    const { name, email, grant_id, grantee_id, batch, errors } = this.state;
    const allBatches = !batch
      ? batches
      : Array.from(new Set([...batches, batch]));

    return (
      <Dialog
        small
        open={open}
        title={
          <span>
            <Icon name="account" /> Edit contact details
          </span>
        }
        rightActions={[
          <Button
            disabled={!canManageSurvey()}
            key="update"
            secondary
            size="l"
            label="Update"
            onClick={this.handleSubmit}
          />,
        ]}
        leftActions={
          <Button
            size="l"
            negative
            label="Cancel"
            onClick={this.handleCancel}
          />
        }
        onRequestClose={this.handleCancel}
      >
        <TextField
          name="name"
          placeholder="Type in"
          label="Name"
          value={name || ''}
          onChange={(val) => this.handleAttrChange('name', val)}
        />
        <TextField
          name="email"
          label="Email"
          placeholder="Type in"
          error={errors.email || ''}
          value={email || ''}
          onChange={(val) => this.handleAttrChange('email', val)}
        />
        {!surveyOnly() && (
          <SelectAutocomplete
            name="grant_id"
            label={getText('Grant')}
            error={errors.grantId || ''}
            selectedItems={grant_id} // eslint-disable-line
            storeEntity={grantsAutocomplete}
            onSelect={this.handleAttrChange}
            onRequestOptions={(val) =>
              this.handleRequestAutocomplete('grant', val)
            }
          />
        )}
        {!surveyOnly() && (
          <SelectAutocomplete
            name="grantee_id"
            label={getText('Grantee')}
            error={errors.granteeId || ''}
            selectedItems={grantee_id} // eslint-disable-line
            storeEntity={granteesAutocomplete}
            onSelect={this.handleAttrChange}
            onRequestOptions={(val) =>
              this.handleRequestAutocomplete('grantee', val)
            }
          />
        )}
        <SelectField
          name="batch"
          label="Series"
          placeholder="Series"
          value={batch || ''}
          onChange={({ value }) => this.handleAttrChange('batch', value)}
        >
          {allBatches.map((b) => (
            <SelectField.Item key={b} value={b} primaryText={b} />
          ))}
        </SelectField>
      </Dialog>
    );
  }
}
