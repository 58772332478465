import PropTypes from 'prop-types';

export const InputReduxForm = {
  propTypes: {
    input: PropTypes.object,
    meta: PropTypes.object,
  },
  onChange: (input, onChange) =>
    input && input.onChange ? input.onChange : onChange,
};

export const InputSticky = {
  propTypes: {
    stick: PropTypes.oneOf(['right', 'left']),
  },
  onChange: (input, onChange) =>
    input && input.onChange ? input.onChange : onChange,
};
