import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'src/components/IMUI';
import TableComposition from 'src/components/IMUI/Tables/TableComposition';
import cls from './TableBottomScroll.module.css';

class TableBottomScroll extends React.Component {
  static propTypes = {
    ...Table.propTypes,
    tableWidth: PropTypes.number,
  };

  componentDidMount() {
    if (!this.outerRef || !this.innerRef) return;
    this.outerRef?.addEventListener('scroll', this.handleWrapperToScroll);
    this.innerRef?.addEventListener('scroll', this.handleScrollToWrapper);
  }

  componentWillUnmount() {
    if (!this.outerRef || !this.innerRef) return;
    this.outerRef?.removeEventListener('scroll', this.handleWrapperToScroll);
    this.innerRef?.removeEventListener('scroll', this.handleScrollToWrapper);
  }

  handleScroll(containerLeft, scrollContainerLeft, doScroll) {
    if (this.ticking) return;

    window.requestAnimationFrame(() => {
      if (containerLeft === scrollContainerLeft) {
        this.ticking = false;
        return;
      }

      doScroll();
      this.ticking = false;
    });

    this.ticking = true;
  }

  handleWrapperToScroll = () => {
    this.fakeScroll = true;

    const wrapperLeft = this.outerRef?.scrollLeft;
    const doScroll = () => this.innerRef?.scrollTo(wrapperLeft, 0);
    this.handleScroll(wrapperLeft, this.innerRef?.scrollLeft, doScroll);
  };

  handleScrollToWrapper = () => {
    if (this.fakeScroll) {
      this.fakeScroll = false;
      return;
    }

    const scrollLeft = this.innerRef?.scrollLeft;
    const doScroll = () => this.outerRef?.scrollTo(scrollLeft, 0);
    this.handleScroll(this.outerRef?.scrollLeft, scrollLeft, doScroll);
  };

  applyProp = (child) => {
    if (child.type !== (<TableComposition />).type) return child;
    return React.cloneElement(child, { overflowContainerEl: this.outerRef });
  };

  render() {
    const { children, tableWidth, className } = this.props;

    return (
      <div className={className}>
        <div
          className={cls.tableBottomScrollWrapper}
          ref={(ref) => (this.outerRef = ref)}
        >
          {React.Children.map(children, this.applyProp)}
        </div>

        <div
          className={cls.tableBottomScroll}
          style={this.props.style}
          ref={(ref) => (this.innerRef = ref)}
        >
          <div style={{ width: tableWidth }} />
        </div>
      </div>
    );
  }
}

export default TableBottomScroll;
