import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, getFormValues } from 'redux-form';
import {
  Button,
  Card,
  CardEmpty,
  Container,
  Progress,
} from 'src/components/IMUI/index';
import InvitationsList, { validateInvitations } from './InvitationsList';
import MembershipList, {
  MembershipItem,
} from 'src/pages/App/User/Organizations/components/MembershipList';
import cls from './MembershipManagement.module.css';
import { canManageOrganization } from 'src/userStorage';
const cx = classNames.bind(cls);

class MembershipManagement extends React.PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDeleteMember: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
    formValues: PropTypes.object,
    usersLimit: PropTypes.number,
    memberships: PropTypes.object,
    user: PropTypes.object,
    organizationId: PropTypes.string,
  };

  static defaultProps = { formValues: {}, members: [] };
  static invited = {};
  state = { pendingInvitations: false };

  onInvitationStart = () => {
    this.setState({ pendingInvitations: true });
  };
  onInvitationCancel = () => {
    this.setState({ pendingInvitations: false });
  };
  onDeleteMember = (user, membershipId) => {
    this.props.onDeleteMember(user, membershipId);
  };
  onAddRecentlyInvite = (org, newInvite) => {
    MembershipManagement.invited[org] = [
      ...(MembershipManagement.invited[org] || []),
      ...newInvite,
    ];
  };

  handleSubmit = (ev) => {
    ev.preventDefault();
    this.props.handleSubmit((data) => {
      this.setState({ pendingInvitations: false });
      this.onAddRecentlyInvite(this.props.organizationId, data.invitations);
      this.props.onSubmit(data);
    })();
  };

  renderMembershipList = (memberships) => {
    if (
      this.props.memberships.pending.init ||
      this.props.memberships.pending.ui
    )
      return <Progress />;
    if (!memberships.length)
      return (
        <CardEmpty
          title="No users"
          description="Invite users to join organization"
        />
      );
    return (
      <Card>
        <MembershipList>
          {memberships.map(({ id, user }, n) => (
            <MembershipItem
              key={id}
              index={n}
              canEdit={canManageOrganization() && true}
              isStaff={(user?.flags || []).includes('staff')}
              firstName={user.first_name}
              lastName={user.last_name}
              email={user.email}
              isSelf={this.props.user.data.id === id}
              onClear={() => this.onDeleteMember(user, id)}
              userId={user.id}
              permissions={user.permissions?.[this.props.organizationId]}
              organizationId={this.props.organizationId}
              onUpdate={this.props.onSubmit}
            />
          ))}
        </MembershipList>
      </Card>
    );
  };

  render() {
    const memberships = this.props.memberships.data.filter((membership) => {
      return !(membership.user.flags || []).includes('staff');
    });
    const staffMemberships = this.props.memberships.data.filter(
      (membership) => {
        return (membership.user.flags || []).includes('staff');
      }
    );

    return (
      <form
        noValidate
        className={cx('inputs-wrapper')}
        onSubmit={(ev) => this.handleSubmit(ev)}
      >
        <Container horizontal className={cx('membership-management-header')}>
          <h3
            className={cx('inputs-wrapper-title-first')}
          >{`Organization users (${memberships.length}/${this.props.usersLimit})`}</h3>
          {this.state.pendingInvitations ? null : (
            <Button
              label="Invite users"
              type="button"
              disabled={
                !canManageOrganization() ||
                memberships.length >= this.props.usersLimit
              }
              onClick={this.onInvitationStart}
            />
          )}
        </Container>

        {this.renderMembershipList(memberships)}
        {staffMemberships.length > 0 && (
          <div>
            <br />
            <h3>Staff</h3>
            {this.renderMembershipList(staffMemberships)}
          </div>
        )}

        {MembershipManagement.invited[this.props.organizationId] && (
          <div>
            <br />
            <h3>Recently invited</h3>
            <Card noPadding>
              <MembershipList>
                {MembershipManagement.invited[this.props.organizationId].map(
                  (user, i) => (
                    <MembershipItem
                      canEdit={false}
                      key={i}
                      index={i}
                      firstName={user.fullName}
                      email={user.email}
                      permissions={user.permissions}
                    />
                  )
                )}
              </MembershipList>
            </Card>
          </div>
        )}

        <br />
        {!this.state.pendingInvitations ? null : (
          <InvitationsList
            form={this.props.form}
            invitations={this.props.formValues.invitations}
            maxInvitationsLimit={this.props.usersLimit - memberships.length}
            onCancel={this.onInvitationCancel}
          />
        )}
      </form>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { formValues: getFormValues(ownProps.form)(state), user: state.user };
}
function validateMembershipManagement(values = {}) {
  return validateInvitations(values);
}
function validate(values) {
  return validateMembershipManagement(values);
}
export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({}, dispatch)
)(reduxForm({ validate })(MembershipManagement));
