import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';

import Upgrade from './Upgrade';

export default class AnalysisUpgradeIndex extends Component {
  static propTypes = {
    ...ReactRouterPropTypes,
  };

  render() {
    const { match } = this.props;
    const rootPath = match.path;

    return (
      <Switch>
        <Route exact path={rootPath} component={Upgrade} />
        <Redirect from="*" to={match.url} />
      </Switch>
    );
  }
}
