import { csvParse } from 'd3-dsv';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Dropzone from 'react-dropzone';

import { Button, MAX_UPLOAD_SIZE, Tooltip } from 'src/components/IMUI';

import { transformCsvToChartData } from '../helpers/transformers';

export default class CSVUploadButton extends PureComponent {
  static propTypes = {
    label: PropTypes.string,
    accept: PropTypes.string,
    size: PropTypes.oneOf(['s', 'm', 'l']),
    onFileUploaded: PropTypes.func,
  };

  static defaultProps = {
    accept: '.csv',
  };

  onFileUploaded = (files) => {
    const file = files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const rawCSV = event.target.result;
      const csv = csvParse(rawCSV);
      this.props.onFileUploaded(
        file,
        rawCSV,
        csv,
        transformCsvToChartData(csv)
      );
    };
    reader.readAsText(file);
  };

  render() {
    const { label, accept, size = 'l' } = this.props;
    return (
      <Dropzone
        accept={accept}
        onDrop={this.onFileUploaded}
        style={{ border: 'none', width: 'auto', height: 'auto' }}
        maxSize={MAX_UPLOAD_SIZE}
      >
        <Button
          size={size}
          secondary
          label={label || 'Upload CSV'}
          onClick={() => ''}
          data-tip
          data-for="csv-upload"
        />
        <Tooltip id="csv-upload" place="right">
          {`<div>
            This is an experimental feature,<br>
            if you have problems using it get in touch.<br>
            (Tip: Try toggling label visibility if there's any issue.)
          </div>`}
        </Tooltip>
      </Dropzone>
    );
  }
}
