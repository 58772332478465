import languagesData from 'src/data/ISO-639-1-language.json';
import { capitalize, isCapitalized } from 'src/utils/string';

export const userDictionaryKeys = ['grantee', 'grant'];

export const userDictionaryShape = {
  project: {},
  organization: {},
  language: {},
};

export const apiDictionary = {
  'chart-Report-name': 'Name',
  'chart-Report-report_type': 'Report Type',
  'chart-Report-global_region': 'Global region',
  'chart-Report-status': 'Status',
  'chart-Report-year': 'Year',
  'chart-Tag-title': 'Tag name',
  'chart-TagGroup-metatags': 'Metatags',
  'chart-TagGroup-title': 'Tag Group',
  cross_tab: 'Cross-tab',
  grantees_total: 'Grantees count',
  grantees_percentage: 'Grantees %',
  grantees_in_tag_group: 'Grantees count (Tag Group)',
  grantees_in_tag_group_percentage: 'Grantees % (Tag Group)',
  grants_total: 'Grants count',
  grants_percentage: 'Grants %',
  grants_in_tag_group: 'Grants count (Tag Group)',
  grants_in_tag_group_percentage: 'Grants % (Tag Group)',
  funding_count: 'Fundings count',
  funding_total: 'Funding amount',
  funding_total_percentage: 'Funding %',
  report_type: 'Report type',
  year: 'Year',
  name: 'Name',
  status: 'Status',
  number: 'Number',
  recipient_type: 'Recipient type',
  custom_fields: 'Custom fields',
  donor_list: 'Donors',
  strategies_list: 'Strategies',
  cohort_list: 'Cohorts',
  area_of_work_list: 'Areas of work',
  portfolio_list: 'Portfolios',
  issue_list: 'Issues',
  population_list: 'Populations',
  donor_type_list: 'Donor types',
  approx_region: 'Region',
  contact_name: 'Contact person',
  contact_email: 'Contact email',
  region: 'Region',
  global_region: 'Global region',
  city: 'City',
  state: 'State',
  country_code: 'Country',
  type: 'Type',
  partner_list: 'Partners',
  date_of_birth: 'Date of birth',
  gender_identity: 'Gender Identity',
  race: 'Race',
  website: 'Website',
  nationality: 'Nationality',
  ethnicity: 'Ethnicity',
  annual_income: 'Annual Income',
  religion: 'Religion',
  sexual_orientation: 'Sexual Orientation',
  education_level: 'Education Level',
  marital_status: 'Marital Status',
  social_media_handle_list: 'Social media handles',
  amount: 'Amount',
  currency: 'Currency',
  start_date: 'Start Date',
  period: 'Period',
  code: 'Code',
  end_date: 'End Date',
  quarterly: 'by Date (grouped by quarter)',
  monthly: 'by Date (grouped by month)',
  yearly: 'by Date (grouped by year)',
  taggings_count: 'Total occurrences',
  reach_tag_avg: 'Reach tags average',
  reach_tag_total: 'Reach tags total',
  TagGroup: 'Tag group',

  baseline: 'Baseline',
  'mid-term': 'Mid-term',
  endline: 'Endline',
  'pre-test': 'Pre-test',
  'post-test': 'Post-test',
  progress_report: 'Progress Report',
  final_report: 'Final Report',
  needs_assessment: 'Needs Assessment',
  monitoring_call: 'Monitoring Call',
  survey: 'Survey',
  application: 'Application',
  interview: 'Interview',
  focus_group: 'Focus Group',
  change_stories: 'Change stories',
  staff_comments: 'Staff comments',
  publication: 'Publication',
  other: 'Other',
};

export const isInDictionary = (key) => Boolean(apiDictionary[key]);

export class DictionaryService {
  userDictionary = Object.assign(userDictionaryShape);
  apiDictionary = {};

  constructor(initUserDictionary, initApiDictionary) {
    this.setDictionary(initUserDictionary, initApiDictionary);
  }

  static getPluralForm(text) {
    if (!text) {
      return text;
    }
    return /y$/.test(text) ? text.replace(/y$/, 'ies') : `${text}s`;
  }

  setDictionary(newUserDictionary, newApiDictionary) {
    this.userDictionary = Object.assign(this.userDictionary, newUserDictionary);
    this.apiDictionary = Object.assign(this.apiDictionary, newApiDictionary);
  }

  getText = (key, _count, _shouldCapitalize, priority) => {
    if (typeof key === 'number') {
      return key;
    } else if (!key || (typeof key === 'string' && !key.trim())) {
      return '';
    }
    let shouldCapitalize = !!_shouldCapitalize;
    let count = typeof _count === 'number' ? _count : 1;
    if (typeof count === 'boolean') {
      shouldCapitalize = count;
      count = 1;
    }
    const output = this.$transformWithUserDict(
      this.apiDictionary[key] || key,
      count,
      shouldCapitalize,
      priority
    );

    return shouldCapitalize ? capitalize(output) : output;
  };

  getOrgText = (key, count, shouldCapitalize) => {
    return this.getText(key, count, shouldCapitalize, [
      'organization',
      'project',
    ]);
  };

  $transformWithUserDict(
    input,
    count,
    forceCapitalized,
    priority = ['project', 'organization']
  ) {
    if (!this.userDictionary) {
      return input;
    }
    const { language = {} } = this.userDictionary;
    const firstPriority = this.userDictionary[priority[0]] || {};
    const secondPriority = this.userDictionary[priority[1]] || {};
    // for now handle only grant/grantee in a naive way - if dictionary for user specified
    // names grows we need better handling for languages, plurals etc.
    if (Array.isArray(input)) return input.join(' ');
    const output = String(input || '').replace(
      /\b(grantee|grant)(s|\b)/gim,
      (match) => {
        const key = match.toLowerCase().replace(/s\b/, '');
        const isPlural = !!match.match(/s\b/i);
        const shouldCapitalize = forceCapitalized || isCapitalized(match);
        let transformedMatch =
          firstPriority[key] || secondPriority[key] || language[key] || key;

        if (
          isPlural ||
          (!isPlural && count !== undefined && (count === 0 || count > 1))
        ) {
          transformedMatch = DictionaryService.getPluralForm(transformedMatch);
        }

        return shouldCapitalize
          ? capitalize(transformedMatch)
          : transformedMatch;
      }
    );

    return output;
  }
}

const dictionaryService = new DictionaryService(null, apiDictionary);

export default dictionaryService;
// when exposing singleton instance method we need to remember to define it as arrow function to retain methods context
export const getText = dictionaryService.getText;
export const getOrgText = dictionaryService.getOrgText;

const languagesMap = languagesData.reduce((acc, el) => {
  acc[el.code] = el.name;
  return acc;
}, {});

const localLanguagesMap = languagesData.reduce((acc, el) => {
  acc[el.code] = el.localName;
  return acc;
}, {});

export const getLanguageForCode = (code) => languagesMap[code] || '';

export const getLocalLanguageForCode = (code) => localLanguagesMap[code] || '';
