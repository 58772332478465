import React from 'react';
import { Container, Section, Table } from 'src/components/IMUI';
import TableComposition from 'src/components/IMUI/Tables/TableComposition';

const ExamplesForAutoMapping = () => {
  return (
    <Container>
      <h5>Auto-mapping</h5>
      <TableComposition>
        <Table.Head>
          <Table.HCell width={200} text={'Attribute'} />
          <Table.HCell width={200} text={'Original'} />
          <Table.HCell width={200} text={'Auto Formatted'} />
          <Table.HCell text={'Explanation'} />
        </Table.Head>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Country</Table.Cell>
            <Table.Cell>United States</Table.Cell>
            <Table.Cell>US</Table.Cell>
            <Table.Cell>
              <li>
                Auto-maps to the Country Code <i>(ISO 3166 A-2)</i>
              </li>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Currency</Table.Cell>
            <Table.Cell>US Dollar</Table.Cell>
            <Table.Cell>USD</Table.Cell>
            <Table.Cell>
              <li>
                Auto-maps to the Currency <i>(ISO 4217)</i>
              </li>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </TableComposition>
    </Container>
  );
};

export default ExamplesForAutoMapping;
