import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import reportContentExportApi from 'src/api/ReportContentExport';
import ExportConfirmation from 'src/components/ExportConfirmation/ExportConfirmation';
import { Card, Container } from 'src/components/IMUI';
import { dedummy } from 'src/utils/string';
import { Icon } from 'im/ui/Icon';
import cls from './Quotes.module.css';
import QueryParamsManager from 'im/api/QueryParamsManager';
export const QuoteSymbol = () => (
  <svg
    width="24"
    height="20"
    viewBox="0 0 30 26"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Path"
      fill="#ededed"
      fillRule="evenodd"
      stroke="none"
      d="M 30 0.6429 L 30 13.0714 C 30 19.935459 24.628349 25.5 18 25.5 L 18 20.17347 C 21.786579 20.17347 24.85714 16.993141 24.85714 13.0714 L 18 13.0714 L 18 0.6429 L 30 0.6429 Z M 0 25.5 L 0 20.17347 C 3.7866 20.17347 6.8571 16.993141 6.8571 13.0714 L 0 13.0714 L 0 0.6429 L 12 0.6429 L 12 13.0714 C 12 19.935459 6.627001 25.5 0 25.5 Z"
    />
  </svg>
);

const cx = classNames.bind(cls);
@connect(null, { getQuotesExport: reportContentExportApi.quotes })
export default class Quotes extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    quotes: PropTypes.array.isRequired,
    flat: PropTypes.bool,
    projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    getQuotesExport: PropTypes.func.isRequired,
  };
  state = { exportModalShow: false };
  handleExport = () =>
    this.props
      .getQuotesExport({ projectId: this.props.projectId })
      .then(() => this.setState({ exportModalShow: true }));
  handleCloseExportModal = () => {
    this.setState({ exportModalShow: false });
  };
  render() {
    return (
      <div className={cx('wrapper', this.props.className)}>
        <Container horizontal>
          <h3>
            Quotes&ensp;
            <Icon
              name="download"
              className={cx(cls.quotesExportIcon, cls.quotesExportIconTitle)}
              onClick={this.handleExport}
            />
          </h3>
        </Container>
        <Card normalPadding flat={this.props.flat}>
          <Container max={1200}>
            <div className={cx('quotesList')}>
              {this.props.quotes.map((quote, i) => {
                const quoteSource = [
                  dedummy(quote.grantee?.name)
                    ? dedummy(quote.grantee?.name)
                    : null,
                  quote.name ? `[${quote.name}]` : null,
                ]
                  .filter(Boolean)
                  .join(' / ');
                const surveyUrl = quote?.survey_id
                  ? `/analysis/${this.props.projectId}/surveys/${
                      quote.survey_id
                    }/responses/${
                      quote.grantee_survey_id
                    }?${QueryParamsManager.stringify({
                      position: quote.position ?? 0,
                    })}`
                  : null;
                const reportUrl = quote?.report_id
                  ? `/analysis/${this.props.projectId}/reports/${
                      quote.project_report_id
                    }?${QueryParamsManager.stringify({
                      start: quote.report_content_start,
                      end: quote.report_content_end,
                    })}`
                  : null;
                const url = surveyUrl || reportUrl;
                const openingQuote =
                  String(quote.content).includes('“') &&
                  String(quote.content).includes('”')
                    ? '‘'
                    : '“';
                const endingQuote =
                  String(quote.content).includes('“') &&
                  String(quote.content).includes('”')
                    ? '’'
                    : '”';
                return (
                  url && (
                    <div key={i} className={cx('quote')}>
                      <a href={url}>
                        <blockquote className={cx('quoteText')}>
                          <QuoteSymbol />
                          &emsp;
                          <i>{openingQuote}</i>
                          {String(quote.content).trim()}
                          <i>{endingQuote}</i>
                        </blockquote>
                        {!!quoteSource && (
                          <label className={cx('quoteOrigin')}>
                            -- {quoteSource}
                          </label>
                        )}
                      </a>
                    </div>
                  )
                );
              })}
            </div>
          </Container>
        </Card>
        <ExportConfirmation
          open={this.state.exportModalShow}
          onRequestClose={this.handleCloseExportModal}
        />
      </div>
    );
  }
}
