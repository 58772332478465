import classNames from 'classnames/bind';
import { T } from 'lioness';
import React, { Component } from 'react';

import { Icon } from 'im/ui/Icon';

import cls from './SurveyAlert.module.css';

const cx = classNames.bind(cls);

interface SurveyAlertProps {
  onClose: () => void;
  permalink: string | null;
}

export class SurveyAlert extends Component<SurveyAlertProps> {
  render() {
    const { permalink, onClose } = this.props;

    if (!permalink) {
      return null;
    }

    return (
      <div className={cx('alert')}>
        <span className={cx('closebtn')} onClick={onClose}>
          &times;
        </span>
        <a className={cx('alertLink')} href={`/answer-survey/${permalink}`}>
          <Icon name="paperclip" />
          <T message="There is a saved copy of this survey. Click here to restore it." />
        </a>
      </div>
    );
  }
}
