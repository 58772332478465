import React from 'react';
import PropTypes from 'prop-types';
import IMLogoSmall from 'src/static/impactmapper-logo-small.svg';
import {
  Actions,
  Button,
  Checkbox,
  Container,
  ListItemSimple,
  Table,
} from 'src/components/IMUI';
import { Icon } from 'im/ui/Icon';
import {
  PERMISSIONS,
  canManageOrganization,
  canManageMember,
} from 'src/userStorage';
import { where } from 'im/api/Query';
import { useDispatch } from 'react-redux';
import userApi from 'src/api/User';
import TableComposition from 'src/components/IMUI/Tables/TableComposition';

const MembershipList = ({ children }) => (
  <TableComposition>
    <Table.Head>
      <Table.HCell>#</Table.HCell>
      <Table.HCell>Name</Table.HCell>
      <Table.HCell>Email</Table.HCell>
      <Table.HCell width={80} />
    </Table.Head>
    <Table.Body>{children}</Table.Body>
  </TableComposition>
);
MembershipList.propTypes = { children: PropTypes.node };

const MembershipPermissions = ({
  userId,
  organizationId,
  setEditFalse,
  permissions,
  onUpdate,
}) => {
  const dispatch = useDispatch();
  const [newPermissions, setNewPermissions] = React.useState(
    permissions || PERMISSIONS
  );
  const noneSelected = !PERMISSIONS.some((p) => newPermissions.includes(p));
  const hasNoChanges =
    (permissions || []).every((p) => newPermissions.includes(p)) &&
    newPermissions.every((p) => (permissions || []).includes(p));
  const handleResetChanges = () =>
    setNewPermissions(permissions || PERMISSIONS);
  const handleToggleAll = () =>
    noneSelected ? setNewPermissions(PERMISSIONS) : setNewPermissions([]);
  const handleUpdate = () => {
    setEditFalse();
    dispatch(
      userApi.changePermissions(
        where({ id: userId }).payload({
          permissions: { [organizationId]: newPermissions },
        })
      )
    ).then(() => onUpdate());
  };

  return (
    <>
      <Table.Row>
        <Table.Cell colSpan={4} style={{ background: '#f0f2f5' }}>
          <Container horizontal>
            <h4 style={{ padding: 0, margin: 0, lineHeight: 2 }}>
              <strong>Select Permissions</strong>
            </h4>
            <Actions>
              <Button
                actionSecondary
                underlined
                size="m"
                label={noneSelected ? 'SET ALL PERMISSIONS' : 'SET READ-ONLY'}
                onClick={handleToggleAll}
              />
              {!hasNoChanges && (
                <Button
                  size="m"
                  actionSecondary
                  underlined
                  label="RESET CHANGES"
                  onClick={handleResetChanges}
                />
              )}
              <Button negative label="CANCEL" onClick={setEditFalse} />
              <Button
                disabled={hasNoChanges || !canManageOrganization()}
                label={hasNoChanges ? 'NO CHANGES' : 'UPDATE'}
                onClick={handleUpdate}
              />
            </Actions>
          </Container>
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell colSpan={4} style={{ background: '#f0f2f5' }}>
          {PERMISSIONS.map((permission) => {
            const active = newPermissions.includes(permission);
            const onTick = () =>
              setNewPermissions(
                active
                  ? newPermissions.filter((a) => a !== permission)
                  : [...newPermissions, permission]
              );
            return (
              <ListItemSimple key={permission} className="" onClick={onTick}>
                <Checkbox
                  readOnly
                  label={permission.replaceAll('_', ' ')}
                  checked={active}
                />
              </ListItemSimple>
            );
          })}
        </Table.Cell>
      </Table.Row>
    </>
  );
};

const MembershipItem = ({
  canEdit = false,
  userId = null,
  organizationId = null,
  firstName,
  lastName,
  email,
  permissions,
  onClear,
  index,
  isStaff,
  isSelf,
  onUpdate,
}) => {
  const [edit, setEdit] = React.useState(false);
  const setEditFalse = () => setEdit(false);
  return (
    <>
      <Table.Row>
        <Table.Cell>{index + 1}</Table.Cell>
        <Table.Cell>
          {<span>{`${firstName || ''} ${lastName || ''}`.trim()} </span>}
          {isStaff && <Icon name="account" tip="IM Staff" placeTip="top" />}
        </Table.Cell>
        <Table.Cell>{email}</Table.Cell>
        <Table.Cell
          textAlign="center"
          width={80}
          style={{ fontSize: 16, textAlign: 'right' }}
        >
          {canEdit && (canManageMember() || canManageOrganization()) && (
            <Icon name="edit" tip="Edit" onClick={() => setEdit((e) => !e)} />
          )}
          <span>&nbsp;&emsp;</span>
          {Boolean(canManageMember() || canManageOrganization() || isSelf) && (
            <Icon name="trash" tip="Remove" onClick={onClear} />
          )}
        </Table.Cell>
      </Table.Row>
      {canEdit && edit && (
        <MembershipPermissions
          userId={userId}
          organizationId={organizationId}
          setEditFalse={setEditFalse}
          permissions={permissions}
          onUpdate={onUpdate}
        />
      )}
    </>
  );
};
MembershipItem.propTypes = {
  isSelf: PropTypes.bool,
  isStaff: PropTypes.bool,
  canEdit: PropTypes.bool,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  userId: PropTypes.string,
  organizationId: PropTypes.string,
  index: PropTypes.number,
  permissions: PropTypes.object,
  onUpdate: PropTypes.func,
  onClear: PropTypes.func,
};

export default MembershipList;
export { MembershipItem };
