import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import defaultLogo from 'src/static/impact-logo.svg';
import { Progress } from 'src/components/IMUI';

import cls from './CompanyLogo.module.css';

const cx = classNames.bind(cls);

const CompanyLogo = ({ isPending, logo, className }) =>
  !isPending ? (
    <div className={cx(cls.companyLogo, className)}>
      <div
        className={cls.companyLogoImg}
        style={{ backgroundImage: `url(${logo || defaultLogo})` }}
        role="img"
      />
    </div>
  ) : (
    <Progress />
  );

CompanyLogo.propTypes = {
  isPending: PropTypes.bool,
  logo: PropTypes.string,
  className: PropTypes.string,
};

export default CompanyLogo;
