import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import React from 'react';
import { connect } from 'react-redux';

import { updateStorage } from 'src/actionCreators/storageActionCreators';
import { Container, Button } from 'src/components/IMUI';

import { Icon } from 'im/ui/Icon';

import cls from './Warning.module.css';

const cx = classNames.bind(cls);

@connect(pick(['storage']), { updateStorage })
export default class Warning extends React.Component {
  static propTypes = {
    text: PropTypes.string,
    show: PropTypes.bool,
    children: PropTypes.node,
    ignorable: PropTypes.bool,
    className: PropTypes.string,
    compact: PropTypes.bool,
    mode: PropTypes.oneOf(['caution', 'error']),

    storage: PropTypes.object,
    updateStorage: PropTypes.func,

    // Needed to save it in local Storage. Not needed if ignorable = false
    type: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  static defaultProps = {
    ignorable: true,
    mode: 'caution',
  };

  static ADAPTER_KEY = 'warnings_ignored';

  constructor(props) {
    super(props);
    this.state = {
      warningsIgnored: (props.storage || {})[Warning.ADAPTER_KEY] || {},
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.storage[Warning.ADAPTER_KEY] !==
      this.props.storage[Warning.ADAPTER_KEY]
    ) {
      this.setState({
        warningsIgnored: (nextProps.storage || {})[Warning.ADAPTER_KEY] || {},
      });
    }
  }

  handleIgnore = () => {
    const { type, id, storage } = this.props;
    const { warningsIgnored } = this.state;

    const warningsIgnoredNew = {
      ...warningsIgnored,
      [type]: { ...warningsIgnored[type], [id]: true },
    };
    const storageNew = {
      ...storage,
      [Warning.ADAPTER_KEY]: { ...warningsIgnoredNew },
    };

    this.props.updateStorage(storageNew);
  };

  render() {
    const {
      children,
      ignorable,
      type,
      id,
      text,
      show,
      className,
      mode,
      compact,
    } = this.props;
    const { warningsIgnored } = this.state;

    return !show ||
      (warningsIgnored[type] && warningsIgnored[type][id]) ? null : (
      <Container
        horizontal
        centered
        className={cx(
          cls.warning,
          [`warning-${mode}`],
          { [cls.warningCompact]: compact },
          className
        )}
      >
        <Icon name="warning-triangle" className={cls.warningIcon} />
        {text && <span>{text}</span>}
        {children}

        {!ignorable ? null : (
          <Button
            text
            dark
            underlined
            label="Ignore"
            className={cls.warningIgnore}
            onClick={this.handleIgnore}
          />
        )}
      </Container>
    );
  }
}
