import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import IconSubItem from 'src/components/IconSubItem/IconSubItem';
import { Tooltip } from 'src/components/IMUI';
import EditIcons from './EditIcons';
import cls from './TagListItem.module.css';
import { canManageTag } from 'src/userStorage';

const cx = classNames.bind(cls);
const tagWithCode = (tag = {}, showCodes) =>
  showCodes && tag.code?.length > 0
    ? `${tag.code}${tag.code.endsWith('.') ? '' : '.'} ${tag.title}`
    : tag.title;

export class TagListItem extends React.PureComponent {
  static propTypes = {
    tag: PropTypes.object,
    tagInContext: PropTypes.object,
    showDefinitions: PropTypes.bool,
    showCodes: PropTypes.bool,
    children: PropTypes.node,

    onTagEdit: PropTypes.func,
    onTagClick: PropTypes.func,
    onTaggingHighlight: PropTypes.func,
    onTagHover: PropTypes.func,
    onTagHoverOut: PropTypes.func,
    onTaggingRemove: PropTypes.func,
    renderExtra: PropTypes.func,
  };

  render() {
    const {
      tag,
      showDefinitions,
      tagInContext,
      onTagEdit,
      onTaggingRemove,
      onTagClick,
      onTaggingHighlight,
      onTagHover,
      onTagHoverOut,
      renderExtra,
      children,
      ...rest
    } = this.props;
    const isClickable = canManageTag() && !!onTagClick;
    const onClick = onTagClick
      ? (ev) => {
          ev?.preventDefault();
          ev?.stopPropagation();
          onTagClick?.(tag);
        }
      : undefined;
    const onRightClick = onTaggingHighlight
      ? (ev) => {
          ev?.preventDefault();
          ev?.stopPropagation();
          onTaggingHighlight?.(tag);
        }
      : undefined;
    const onMouseOver = onTagHover
      ? (ev) => {
          ev?.preventDefault();
          ev?.stopPropagation();
          onTagHover?.(tag.id);
        }
      : undefined;
    const onMouseOut = onTagHoverOut
      ? (ev) => {
          ev?.preventDefault();
          ev?.stopPropagation();
          onTagHoverOut?.(tag.id);
        }
      : undefined;

    return (
      <li
        className={cls.listItem}
        {...rest}
        data-qa="TagList:TagListItem-display"
      >
        <div className={cls.listItemTextWrapper}>
          <IconSubItem />

          <div
            className={cls.listItemText}
            onClick={onClick}
            onContextMenu={onRightClick}
          >
            <div
              data-tip
              data-for={tag.description}
              onMouseOver={onMouseOver}
              onMouseOut={onMouseOut}
            >
              <span
                className={cx(cls.tagInContextHighlight, {
                  [cls.clickable]: isClickable,
                })}
                style={{
                  backgroundColor: tagInContext
                    ? 'rgba(7, 221, 94, 0.2)'
                    : 'transparent',
                }}
              >
                {tagWithCode(tag, this.props.showCodes)}
              </span>
              {renderExtra && renderExtra(tag)}
            </div>

            {showDefinitions && (
              <Tooltip
                id={tag.description}
              >{`<div class="${cls.tagContent}">${tag.description}</div>`}</Tooltip>
            )}
          </div>

          {(onTagEdit || onTaggingRemove) && (
            <div className={cls.listRightIcons}>
              <EditIcons
                onRightClick={onRightClick}
                onClickEdit={() => onTagEdit(tag)}
                onClickRemove={() => onTaggingRemove(tagInContext)}
              />
            </div>
          )}
        </div>

        {children}
      </li>
    );
  }
}
