import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'src/components/IMUI';
import TaggingTypeBlock from 'src/components/TaggingType/TaggingTypeBlock';
import { Icon } from 'im/ui/Icon';
import cls from './TaggingSuggestionInContext.module.css';
import { canManageTag } from 'src/userStorage';

export default class TaggingSuggestionInContext extends React.PureComponent {
  static propTypes = {
    taggingSuggestion: PropTypes.object,
    onTaggingSuggestionEdit: PropTypes.func.isRequired,
    onTaggingSuggestionDecline: PropTypes.func.isRequired,
  };

  state = { isHovered: false };
  render() {
    const { taggingSuggestion } = this.props;
    const title = taggingSuggestion.tag?.title;
    const tip = taggingSuggestion.tag?.tag_categories?.[0]?.title;

    return (
      <span style={{ display: 'flex' }}>
        <Tag
          title={title}
          label={<span>{title}</span>}
          tip={tip}
          grey
          square
          small
          labelProps={{ className: cls.tagHovered }}
          onRemove={
            !this.state.isHovered
              ? () =>
                  canManageTag() &&
                  this.props.onTaggingSuggestionDecline(taggingSuggestion)
              : null
          }
          onClick={() => this.props.onTaggingSuggestionEdit(taggingSuggestion)}
        />
        <TaggingTypeBlock
          key={taggingSuggestion.id}
          tagging={taggingSuggestion}
          small
          renderIcon={() =>
            canManageTag() && (
              <Icon
                name="edit"
                className={cls.taggingSuggestionInContextIcon}
              />
            )
          }
          onClick={() => this.props.onTaggingSuggestionEdit(taggingSuggestion)}
        />
      </span>
    );
  }
}
