import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  formValueSelector,
  getFormSyncErrors,
  reduxForm,
  touch,
} from 'redux-form';
import {
  updateFunding,
  createFunding,
  deleteFunding,
} from 'src/actionCreators/fundingActionCreators';
import { isAfter } from 'src/utils/date';
import createValidator, { handleSubmitFail } from 'src/utils/validation';
import FundingInfo, { validateFundings } from '../components/FundingInfo';

class FundingInfoEdit extends React.PureComponent {
  static propTypes = {
    fundingTypes: PropTypes.array,
    fundingToEdit: PropTypes.object,
    grant: PropTypes.object,
    fundings: PropTypes.array,
    startDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    endDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    formErrors: PropTypes.object,
    initialValues: PropTypes.object,
    change: PropTypes.func.isRequired,
    form: PropTypes.string,
    updateFunding: PropTypes.func.isRequired,
    createFunding: PropTypes.func.isRequired,
    deleteFunding: PropTypes.func.isRequired,
    onRequestClose: PropTypes.func,
    onSubmitSuccessful: PropTypes.func,
  };

  static defaultProps = { fundings: [], onSubmitSuccessful: () => void 0 };
  onFieldValueChange = ({ fieldName, value }) =>
    this.props.change(fieldName, value === undefined ? null : value);
  onUpdate = ({
    start_date,
    end_date,
    amount,
    currency,
    grantee_id,
    grant_id,
    id,
    funding_type,
  }) =>
    this.props
      .updateFunding({
        type: funding_type,
        start_date,
        end_date,
        id,
        grant_id,
        grantee_id,
        amount,
        currency,
      })
      .then(this.props.onSubmitSuccessful);
  onCreate = ({ amount, currency, grantee_id, funding_type }) =>
    this.props
      .createFunding({
        type: funding_type,
        grant_id: this.props.grant.id,
        start_date: this.props.grant.startDate,
        end_date: this.props.grant.endDate,
        grantee_id,
        amount,
        currency,
      })
      .then(this.props.onSubmitSuccessful);
  onDelete = (fundingId) =>
    this.props
      .deleteFunding(fundingId, this.props.grant.id)
      .then(this.props.onSubmitSuccessful);
  render() {
    const fundingIndex = this.props.initialValues.fundings.findIndex(
      (f) => f.id == this.props.fundingToEdit?.id
    );
    return (
      <div>
        <FundingInfo
          formName={this.props.form}
          isEditMode={true}
          fundings={this.props.fundings}
          fundingTypes={this.props.fundingTypes}
          formErrors={this.props.formErrors}
          currency={this.props.initialValues.fundings[0]?.currency}
          onFieldValueChange={this.onFieldValueChange}
          fundingIndex={fundingIndex === -1 ? null : fundingIndex}
          onUpdateFunding={this.onUpdate}
          onCreateFunding={this.onCreate}
          onDeleteFunding={this.onDelete}
          onRequestClose={this.props.onRequestClose}
        />
      </div>
    );
  }
}

function validate(errors, props) {
  const not = (fn) => (v) => !fn(v);
  const isDefined = (v) => Boolean(v);
  const validateDates = createValidator({
    start_date: [[not(isDefined), () => 'Start date is required']],
    end_date: [
      [not(isDefined), () => 'End date is required'],
      [
        (v) => isAfter(errors.start_date, v, true),
        () => 'End date must be later than start date',
      ],
    ],
  });
  return {
    ...validateFundings(errors),
    ...validateDates(errors),
  };
}

export default connect(
  (state, ownProps) => ({
    initialValues: ownProps.initialValues,
    fundings: formValueSelector('fundingsEdit')(state, 'fundings'),
    startDate: formValueSelector('fundingsEdit')(state, 'start_date'),
    endDate: formValueSelector('fundingsEdit')(state, 'end_date'),
    formErrors: getFormSyncErrors('fundingsEdit')(state),
  }),
  (dispatch) =>
    bindActionCreators(
      { updateFunding, createFunding, deleteFunding, touchFields: touch },
      dispatch
    )
)(
  reduxForm({
    validate,
    form: 'fundingsEdit',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    onSubmitFail: (errors) => handleSubmitFail(errors, true),
  })(FundingInfoEdit)
);
