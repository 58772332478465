import { formatMoney } from 'accounting';

import COUNTRIES from 'src/data/countries.json';
import { selectMatchers } from 'src/data/matchers';
import {
  getCountryCodeByName,
  getCountryNameByCode,
} from 'src/utils/countries';
import { capitalize } from 'src/utils/string';

const isNumeric = (v) => v != null && !isNaN(Number(v)) && isFinite(Number(v));

class FilterConfig {
  constructor({ misc }) {
    this.grantStatuses = this._getGrantStatuses(misc);
    this.fundingTypes = this._getFundingTypes(misc);
  }

  commonFilterConfig = [
    { name: 'name', displayName: 'Name', type: 'text' },
    { name: 'created_at', displayName: 'Created', type: 'date' },
  ];

  granteeTypes = [
    { name: 'individual', displayName: 'Individual' },
    { name: 'organization', displayName: 'Organization' },
  ];

  _getGrantStatuses = (misc) =>
    (misc.grant_statuses || []).map((gs) => ({
      name: gs,
      displayName: capitalize(gs),
    }));
  _getFundingTypes = (misc) =>
    (misc.funding_types || []).map((ft) => ({
      name: ft,
      displayName: capitalize(ft),
    }));

  get grantConfig() {
    return [
      ...this.commonFilterConfig,
      // ...customFields.grant,
      { name: 'number', displayName: 'Number', type: 'text' },
      {
        name: 'fundings_type',
        displayName: 'Funding Type',
        type: this.fundingTypes.length ? 'select' : 'text',
        values: this.fundingTypes,
      },
      {
        name: 'status',
        displayName: 'Status',
        type: this.grantStatuses.length ? 'select' : 'text',
        values: this.grantStatuses,
      },
      { name: 'fundings_start_date', displayName: 'Start Date', type: 'date' },
      { name: 'fundings_end_date', displayName: 'End Date', type: 'date' },
      // { name: "portfolio_list", displayName: "Portfolios", type: "array" },
      // { name: "strategies_list", displayName: "Strategies", type: "array" },
      // { name: "cohort_list", displayName: "Cohorts", type: "array" },
      // { name: "area_of_work_list", displayName: "Areas of Work", type: "array" },
      // { name: "population_list", displayName: "Populations of Focus", type: "array" },
    ];
  }

  get granteeConfig() {
    return [
      ...this.commonFilterConfig,
      // ...customFields.grantee,
      { name: 'region', displayName: 'Region', type: 'text' },
      {
        name: 'country_code',
        displayName: 'Country',
        type: 'text',
        matchers: selectMatchers,
        transformBeforeRequest: (v) =>
          (getCountryCodeByName(v) || '').toLowerCase(),
        transformResponse: (v) => getCountryNameByCode(v),
      },
      {
        name: 'type',
        displayName: 'Type',
        type: 'select',
        values: this.granteeTypes,
      },
      // { name: "partner_list", displayName: "Partners", type: "array" },
    ];
  }

  get fundingConfig() {
    return [
      { name: 'grant_name', displayName: 'Grant Name', type: 'text' },
      { name: 'grantee_name', displayName: 'Grantee Name', type: 'text' },
      { name: 'start_date', displayName: 'Start Date', type: 'date' },
      { name: 'end_date', displayName: 'End Date', type: 'date' },
      {
        name: 'amount',
        displayName: 'Total Funding',
        type: 'number',
        transformBeforeRequest: (v) => v && v * 100,
        transformResponse: (v) => (v && isNumeric(v) ? v / 100 : 0),
      },
      {
        name: 'type',
        displayName: 'Type',
        type: this.fundingTypes.length ? 'select' : 'text',
        values: this.fundingTypes,
      },
      // { name: "grant_portfolio_list", displayName: "Portfolios", type: "array" },
      // { name: "grant_locations_country", displayName: "Grant Countries", type: "array" },
      // { name: "grant_locations_region", displayName: "Grant Regions", type: "array", values: regions },
    ];
  }

  toObject = () => ({
    // const regions = defaultRegions.map(({ name, id }) => ({ name: id, displayName: name }));

    // const customFields = {
    //   grant: makeCustomFieldFilter(misc.custom_grant_fields),
    //   grantee: makeCustomFieldFilter(misc.custom_grantee_fields),
    // };

    grant: this.grantConfig,
    grantee: this.granteeConfig,
    funding: this.fundingConfig,
  });
}

class FilterAutopopulationConfig {
  constructor({ resource, onChange }) {
    this.resource = resource;
    this.onChange = onChange;
  }

  get config() {
    return {
      resource: this.resource,
      onRequestOptions: this.onChange,
    };
  }

  get grantConfig() {
    return {
      allow: ['name', 'number', 'status'],
      ...this.config,
    };
  }

  get granteeConfig() {
    return {
      allow: ['name', 'region', 'country_code'],
      country_code: {
        transformValues: (values) => values.map(getCountryNameByCode),
        onRequestOptions: (key, value) => {
          const predicate = 'eq_any';
          const countryCodes = COUNTRIES.filter(({ name }) =>
            name.match(new RegExp(value, 'i'))
          ).map(({ code }) => code.toLowerCase());

          this.onChange(key, value && countryCodes, predicate);
        },
      },
      ...this.config,
    };
  }

  get fundingConfig() {
    return {
      allow: ['amount', 'grant_name', 'grantee_name'],
      amount: {
        renderOption: (v) => formatMoney(v),
        transformValues: (values) => values.map((v) => v / 100),
        onRequestOptions: (key, value) => {
          this.onChange(key, value && value * 100, 'gteq');
        },
      },
      ...this.config,
    };
  }

  toObject = () => ({
    grant: this.grantConfig,
    grantee: this.granteeConfig,
    funding: this.fundingConfig,
  });
}

export const getFilterConfig = (misc) => new FilterConfig({ misc }).toObject();
export const getFilterAutopopulationConfig = (resource, onChange) =>
  new FilterAutopopulationConfig({ resource, onChange }).toObject();
