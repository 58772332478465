import matchers, { andPredicate, orPredicate } from 'src/data/matchers';

const byLength = (a, b) => b.length - a.length;
const getGroupingPredicate = (text) =>
  text.match(new RegExp(`${andPredicate}|${orPredicate}`, 'g'));
const findMatcher = (allMatchers, filterKey) =>
  allMatchers.find((m) => !!filterKey.match(new RegExp(`${m}$`)));

const matchersSorted = Object.keys(matchers).sort(byLength);

export const splitFilterKey = (key = '') => {
  const [condition] = getGroupingPredicate(key) || [];

  const keyShort = condition ? key.replace(`_${condition}`, '') : key;
  const currentMatcher =
    findMatcher(matchersSorted, keyShort) || findMatcher(matchersSorted, key);

  if (!currentMatcher) {
    return key;
  }

  // TODO: Remove when we have grouping by _all, _any in place
  const [variable] = key.split(`_${currentMatcher}`);

  return {
    variable,
    matcher: currentMatcher,
    condition: !getGroupingPredicate(currentMatcher) && condition,
  };
};
