import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import { Icon } from 'im/ui/Icon';

import { Container } from '../index';

import FilterItem from './FilterItem';
import FilterItemMatcher from './FilterItemMatcher';
import FilterItemResult from './FilterItemResult';
import withAutopopulation from './withAutopopulation';

import cls from './FilterSingle.module.css';

const cx = classNames.bind(cls);

@withAutopopulation
export default class FilterSingle extends React.Component {
  static propTypes = {
    variable: PropTypes.string,
    matcher: PropTypes.string,
    result: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    condition: PropTypes.string,
    currentItem: PropTypes.object,
    autopopulation: PropTypes.object,

    config: PropTypes.array,
    errors: PropTypes.object,
    submitFailed: PropTypes.bool,

    onChange: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
    onAddNext: PropTypes.func,
  };

  static defaultProps = {
    variable: '',
    matcher: '',
    result: '',
    errors: {},
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.autopopulation && !this.props.autopopulation) {
      nextProps.autopopulation.onRequestOptions(
        nextProps.variable,
        nextProps.result
      );
    }
  }

  handleVariableChange = (variable) => {
    const { config } = this.props;

    const currentItem = config.find(({ name }) => name === variable) || {};
    this.props.onChange({
      variable,
      matcher: '',
      result: '',
      type: currentItem.type,
    });
  };

  handleMatcherChange = (matcher) => {
    const { onChange, currentItem = {} } = this.props;
    if (currentItem.clearResultsOnMatcherChange) {
      onChange({ matcher, result: '' });
    } else {
      onChange({ matcher });
    }
  };

  handleResultChange = (result) => {
    this.props.onChange({ result });
  };

  render() {
    const {
      config,
      currentItem,
      variable,
      matcher,
      result,
      errors,
      submitFailed,
      condition,
      autopopulation,
    } = this.props;

    return (
      <Container horizontal nowrap className={cls.filterSingle}>
        <FilterItem
          items={config}
          value={variable || ''}
          error={submitFailed && errors.variable}
          onChange={this.handleVariableChange}
        />

        <FilterItemMatcher
          disabled={!variable}
          value={matcher || ''}
          matchers={currentItem?.matchers}
          type={currentItem?.type}
          error={submitFailed && errors.matcher}
          onChange={this.handleMatcherChange}
        />

        <FilterItemResult
          disabled={!variable}
          value={result || ''}
          currentItem={{ ...currentItem, matcher }}
          autopopulation={autopopulation}
          error={submitFailed && errors.result}
          onChange={this.handleResultChange}
        />

        <div className={cls.filterSingleActions}>
          {this.props.onAddNext && (
            <Icon
              name="plus"
              tip="Add condition"
              className={cx(cls.actionIcon, cls.addIcon)}
              onClick={this.props.onAddNext}
            />
          )}
          {condition && (
            <span className={cls.filterSingleCondition}>{condition}</span>
          )}
          &emsp;
          <Icon
            name="trash"
            tip="Remove"
            className={cx(cls.actionIcon, cls.deleteIcon)}
            onClick={this.props.onClear}
          />
        </div>
      </Container>
    );
  }
}
