import React from 'react';
import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import invitationApi from 'src/api/Invitation';
import organizationMembershipsApi from 'src/api/OrganizationMemberships';
import { confirm } from 'src/components/ConfirmModal/ConfirmModal';
import { Section } from 'src/components/IMUI';
import history from 'src/historyInstance';
import { where } from 'im/api/Query';
import MembershipManagement from '../components/MembershipManagement';
import { canManageMember, canManageOrganization } from 'src/userStorage';

const FORM_NAME_INVITATIONS = 'userOrganizationInvitations';
@connect(pick(['user', 'organization', 'organizationMemberships']), {
  getOrganizationMemberships: organizationMembershipsApi.findAll,
  sendInvitation: invitationApi.toOrganizationByEmail,
  deleteMember: organizationMembershipsApi.destroy,
})
export default class UserOrgEditMembership extends React.PureComponent {
  static propTypes = {
    getOrganizationMemberships: PropTypes.func.isRequired,
    sendInvitation: PropTypes.func.isRequired,
    deleteMember: PropTypes.func.isRequired,
    match: ReactRouterPropTypes.match,
    user: PropTypes.object,
    organization: PropTypes.object,
    organizationMemberships: PropTypes.object,
    location: PropTypes.object,
  };

  static defaultProps = { organization: {}, organizationMemberships: [] };
  componentDidMount() {
    this.getMemberships({ isInit: true });
  }
  onSubmitInvitations = async (payload) => {
    await Promise.all(
      (payload?.invitations || []).map(({ email, fullName }) =>
        this.props.sendInvitation(
          where().payload({
            attributes: { email, fullName },
            relationships: {
              type: 'organization',
              id: this.props.organization.data.id,
            },
          })
        )
      )
    );
    this.getMemberships();
  };
  onDeleteMemberConfirmation = (user, membershipId) => {
    if (!(canManageMember() || canManageOrganization())) return;
    const userName =
      user.firstName || user.lastName
        ? `${user.firstName} ${user.lastName}`
        : user.email;
    confirm(
      'Remove user from the organization',
      `Do you really want to remove ${userName} from ${this.props.organization.data.title}?`,
      true
    ).then(
      () => this.onDeleteMember(user.id, membershipId),
      () => void 0
    );
  };
  onDeleteMember = (userId, membershipId) => {
    this.props
      .deleteMember({ id: membershipId })
      .then(
        () =>
          this.props.user.data.id === userId &&
          history.replace('/user/organizations')
      );
  };

  getMemberships = ({ isInit } = {}) =>
    this.props.getOrganizationMemberships(
      where({ id: this.props.match.params.id })
        .include('user')
        .fields('organization_membership', 'disabled', 'user')
        .fields(
          'user',
          'id',
          'first_name',
          'last_name',
          'email',
          'permissions',
          'flags'
        )
        .pending(isInit && 'init')
        .fromString(this.props.location.search)
    );

  render() {
    return (
      <Section>
        <MembershipManagement
          form={FORM_NAME_INVITATIONS}
          memberships={this.props.organizationMemberships}
          usersLimit={this.props.organization.data.max_memberships}
          organizationId={this.props.match.params.id}
          onSubmit={this.onSubmitInvitations}
          onDeleteMember={this.onDeleteMemberConfirmation}
        />
      </Section>
    );
  }
}
