import React from 'react';
import PropTypes from 'prop-types';
import {
  Section,
  Actions,
  Button,
  Container,
  Tooltip,
} from 'src/components/IMUI/index';
import { canManageOrganization } from 'src/userStorage';

const UserOrgEditFooter = ({
  canRemove,
  isCurrentOrg,
  onDeleteOrganization,
  onSubmitRequest,
}) => (
  <Section type="sticky-footer">
    <Container horizontal>
      <Actions>
        {!!canRemove && (
          <div
            data-tip={
              isCurrentOrg ? 'You cannot remove current organization' : ''
            }
          >
            <Button
              disabled={isCurrentOrg || !canManageOrganization()}
              alert
              secondary
              size="l"
              label="Request deletion"
              onClick={onDeleteOrganization}
            />
          </div>
        )}
      </Actions>
      <Actions>
        <Button
          disabled={!canManageOrganization()}
          size="l"
          label="Update"
          onClick={onSubmitRequest}
        />
      </Actions>
    </Container>
    <Tooltip />
  </Section>
);

UserOrgEditFooter.propTypes = {
  canRemove: PropTypes.bool,
  isCurrentOrg: PropTypes.bool,
  onDeleteOrganization: PropTypes.func,
  onSubmitRequest: PropTypes.func.isRequired,
};

export default UserOrgEditFooter;
