import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import cls from './Status.module.css';
import { Tooltip } from 'src/components/IMUI';
import { v1 as uuidv1 } from 'uuid';
const cx = classNames.bind(cls);

export const Status = ({
  done,
  updated,
  disabled,
  alarming,
  className,
  tip,
}) => {
  const classes = cx('imui-status', className, {
    'imui-status-done': done,
    'imui-status-updated': updated,
    'imui-status-disabled': disabled,
    'imui-status-alarming': alarming,
  });
  const id = uuidv1();
  return tip ? (
    <div key={id} className={classes} data-for={id} data-tip={tip}>
      <Tooltip id={id}>{`<div>${tip}</div>`}</Tooltip>
    </div>
  ) : (
    <div key={uuidv1()} className={classes} />
  );
};

Status.propTypes = {
  done: PropTypes.bool,
  updated: PropTypes.bool,
  disabled: PropTypes.bool,
  alarming: PropTypes.bool,
  className: PropTypes.string,
  tip: PropTypes.string,
};

export default Status;
