import React from 'react';
import MUIDatePickerDialog from 'material-ui/DatePicker/DatePickerDialog';
import PropTypes from 'prop-types';

export class DatePickerDialog extends React.PureComponent {
  static propTypes = { onAccept: PropTypes.func, onDismiss: PropTypes.func };
  static defaultProps = { firstDayOfWeek: 1, autoOk: true };
  render() {
    const { ...rest } = this.props;
    return (
      <MUIDatePickerDialog
        {...rest}
        onAccept={this.props.onAccept}
        onDismiss={this.props.onDismiss}
      />
    );
  }
}

export default DatePickerDialog;
