import uniq from 'ramda/src/uniq';

export default (arr = [], max = 64) => ({
  add: (data) => uniq([data, ...arr]).slice(0, max),
  remove: (predicate) => arr.filter((el, i, array) => !predicate(el, i, array)),
  update: (predicate, payload) =>
    arr.reduce(
      (acc, el) =>
        predicate(el) ? acc.concat([{ ...el, ...payload }]) : acc.concat([el]),
      []
    ),
  all: () => [...arr],
});
