import axios from 'src/axiosInstance';
import {
  GET_DEFAULT_REGIONS_REQUEST,
  GET_DEFAULT_REGIONS_SUCCESS,
  GET_DEFAULT_REGIONS_FAILURE,
} from 'src/constants';

function doGetRegions() {
  return axios
    .get('/v2/regions')
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export function getRegions() {
  return async (dispatch) => {
    dispatch({ type: GET_DEFAULT_REGIONS_REQUEST });
    const { response, error } = await doGetRegions();
    if (response) {
      dispatch({ type: GET_DEFAULT_REGIONS_SUCCESS, payload: response });
    } else {
      dispatch({
        type: GET_DEFAULT_REGIONS_FAILURE,
        payload: error,
        error: true,
      });
    }
  };
}
