import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { changeSurveyProperty } from 'src/actionCreators/surveyActionCreators';
import { Card, Container, Toggle } from 'src/components/IMUI';

import { Icon } from 'im/ui/Icon';

import anonymousOff from 'src/static/anonymous-off.svg';
import anonymousOn from 'src/static/anonymous-on.svg';

import cls from './SettingBox.module.css';

@connect(null, { changeSurveyProperty })
export default class SettingAnonymous extends React.PureComponent {
  static propTypes = {
    projectId: PropTypes.string,
    survey: PropTypes.object,
    changeSurveyProperty: PropTypes.func,
  };

  tipText = `
    <div style="max-width: 200px">
      Anonymous surveys do not allow you to trace back repsondent
      of submitted survey response, <b>setting survey as anonymous once first
      notification is delivered is not reversible</b>.
    </div>
  `;

  handleToggleAnonymous = () => {
    const { projectId, survey } = this.props;
    this.props.changeSurveyProperty('anonymous', !survey.anonymous, {
      projectId,
      autosave: true,
    });
  };

  render() {
    const { survey } = this.props;

    const icon = survey.anonymous ? anonymousOn : anonymousOff;

    return (
      <div className={cls.settingBox}>
        <Card smallPadding border>
          <Container horizontal spaceBetween nowrap className={cls.boxTop}>
            <div>
              <h3 className={cls.boxHeader}>
                <span>Anonymous</span>
                <Icon name="question-filled" tip={this.tipText} />
              </h3>
              <Toggle
                className={cls.boxToggle}
                toggled={survey.anonymous}
                disabled={survey.anonymous && survey.status !== 'draft'}
                onToggle={this.handleToggleAnonymous}
              />
            </div>
            <div className={cls.boxContent}>
              {survey.anonymous && (
                <span style={{ fontSize: 14 }}>Responses are anonymous</span>
              )}
            </div>
            <img className={cls.boxIcon} src={icon} height={48} alt="" />
          </Container>
        </Card>
      </div>
    );
  }
}
