import Tracking from 'src/services/TrackingService';
const actionEndsWith = (action, term) =>
  String(action.type).toLocaleUpperCase().endsWith(term);

export default (_state, action) => {
  if (actionEndsWith(action, 'LOCATION_CHANGE'))
    return Tracking.pageview(
      action.payload?.location?.pathname + action.payload?.location?.search
    );
  if (actionEndsWith(action, 'SUCCESS'))
    return Tracking.event(
      'action',
      action.type.split('/')?.slice(0, 2)?.join('-'),
      action.type
    );
  if (actionEndsWith(action, 'PUT_REQUEST'))
    return Tracking.event(
      'action',
      action.type.split('/')?.slice(0, 2)?.join('-'),
      action.type
    );
  if (actionEndsWith(action, 'CHANGE_ANSWER_VALUE'))
    return Tracking.event(
      'action',
      action.type.split('/')?.slice(0, 2)?.join('-'),
      action.type
    );
};
