import PropTypes from 'prop-types';
import React from 'react';

import cls from './ColourPicker.module.css';

export default class ColourPicker extends React.Component {
  static propTypes = {
    initColour: PropTypes.string,
    onChange: PropTypes.func,
    square: PropTypes.bool,
  };

  shouldComponentUpdate() {
    return false;
  }

  onChange = (ev) => {
    ev.target.parentNode.style.background = ev.target.value;
    this.props.onChange(ev.target.value.toUpperCase());
  };

  render() {
    const { initColour } = this.props;

    return (
      <div className={this.props.square ? cls.swatchSquare : ''}>
        <div className={cls.swatch} style={{ backgroundColor: initColour }}>
          <input
            type="color"
            className={cls.swatchColor}
            defaultValue={initColour}
            onChange={this.onChange}
          />
        </div>
      </div>
    );
  }
}
