import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import isNil from 'ramda/src/isNil';
import { Container, Button } from '../index';
import FilterSelected from './FilterSelected';
import FilterSingle from './FilterSingle';
import cls from './Filter.module.css';
import { Icon } from 'im/ui';
const cx = classNames.bind(cls);

const removeByKey = (obj, index) =>
  Object.keys(obj).reduce(
    (acc, key) => (key === index ? acc : { ...acc, [key]: obj[key] }),
    {}
  );
const getLastIndex = (map) =>
  Object.keys(map).length > 0 ? Number(Object.keys(map).pop()) : -1;

export class Filter extends React.PureComponent {
  static propTypes = {
    config: PropTypes.array,
    configAutopopulation: PropTypes.object,
    filters: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    showCreator: PropTypes.bool,
    fullWidth: PropTypes.bool,
    onToggleCreator: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
    onTransformInput: PropTypes.func,
    onTransformOutput: PropTypes.func,
    onFilterChanged: PropTypes.func,
    renderSelected: PropTypes.func,
  };

  static defaultProps = {
    filters: {},
    fullWidth: true,
    onTransformInput: (v) => v,
    onTransformOutput: (v) => v,
    onFilterChanged: (v) => null,
  };

  constructor(props) {
    super(props);

    const newFilters = props.onTransformInput(props.filters, props.config);
    this.state = {
      submitFailed: false,
      filters: newFilters,
      errors: Object.keys(newFilters || {}).reduce(
        (acc, key) => ({
          ...acc,
          [key]: this.getFilterErrors(newFilters[key]),
        }),
        {}
      ),
      toRemove: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.filters !== this.props.filters) {
      let newFilters = this.props.onTransformInput(
        nextProps.filters,
        nextProps.config
      );

      // Do not remove filters being added but not yet submitted
      if (
        this.state.showCreator &&
        Object.keys(newFilters).length < Object.keys(this.state.filters).length
      ) {
        const previousFilters = this.props.onTransformInput(
          this.props.filters,
          this.props.config
        );
        newFilters = this.removeSubmittedFilters(
          previousFilters,
          this.state.filters
        );
      }

      this.setState({
        filters: newFilters,
        errors: Object.keys(newFilters || {}).reduce(
          (acc, key) => ({
            ...acc,
            [key]: this.getFilterErrors(newFilters[key]),
          }),
          {}
        ),
      });
    }

    if (
      nextProps.showCreator &&
      !this.props.showCreator &&
      nextProps.showCreator !== this.state.showCreator
    ) {
      this.handleShowCreator();
    }

    if (
      !nextProps.showCreator &&
      this.props.showCreator &&
      nextProps.showCreator !== this.state.showCreator
    ) {
      this.setState({
        filters: this.props.onTransformInput(
          nextProps.filters,
          nextProps.config
        ),
        showCreator: false,
      });
    }
  }

  componentDidUpdate() {
    if (this.state.showCreator && Object.keys(this.state.filters).length == 0) {
      this.handleFilterAdd();
    }
  }

  getFilterErrors({ variable, matcher, type, result } = {}) {
    return {
      variable: !variable && 'Please choose value',
      matcher: !matcher && 'Please choose value',
      result:
        type !== 'boolean' &&
        (isNil(result) || result === '') &&
        'Please choose value',
    };
  }

  removeEmptyValues(filters = {}, errors = {}) {
    return Object.keys(filters).reduce(
      (acc, index) => {
        const isEmpty =
          !filters[index].variable &&
          !filters[index].matcher &&
          (isNil(filters[index].result) || filters[index].result === '');
        return isEmpty
          ? acc
          : {
              filters: { ...acc.filters, [index]: filters[index] },
              errors: { ...acc.errors, [index]: errors[index] },
            };
      },
      { filters: {}, errors: {} }
    );
  }

  removeSubmittedFilters(submitted, current) {
    return Object.values(current).reduce(
      (acc, v, i) =>
        Object.values(submitted).some(
          (el) =>
            el?.variable == v?.variable &&
            el?.matcher == v?.matcher &&
            el?.result == v?.result
        )
          ? acc
          : { ...acc, [i]: v },
      {}
    );
  }

  handleHideCreator = () => {
    this.state.showCreator != false && this.setState({ showCreator: false });
    this.props.onToggleCreator(false);
  };
  handleShowCreator = () => {
    this.state.showCreator != true &&
      this.setState({ showCreator: true, submitFailed: false, toRemove: [] });
    this.props.onToggleCreator(true);
  };

  handleApply = () => {
    const { filters: currFilters, errors: currErrors } = this.state;
    const { filters, errors } = this.removeEmptyValues(currFilters, currErrors);
    const valid = Object.values(errors).every(
      ({ variable, matcher, result }) => !variable && !matcher && !result
    );
    if (!valid) {
      this.setState({ submitFailed: true });
      return;
    }

    if (this.state.toRemove.length > 0) {
      this.props.onClear(this.props.onTransformOutput(this.state.toRemove));
    }
    this.props.onSubmit(this.props.onTransformOutput(Object.values(filters)));
    this.handleHideCreator();
  };

  handleClearSingle = (index) => {
    const toRemove = this.props.onTransformOutput(this.state.filters[index]);
    const filters = removeByKey(this.state.filters, index);
    const hasRemoval =
      Object.keys(this.state.filters).length > Object.keys(filters).length;
    this.setState({
      filters: filters,
      errors: removeByKey(this.state.errors, index),
    });
    if (hasRemoval) {
      this.props.onClear(toRemove);
    }
  };

  handleClearAll = () => {
    const toRemove = this.state.filters || {};
    Object.values(toRemove).map((filter) =>
      this.props.onClear(this.props.onTransformOutput(filter))
    );
    this.props.onFilterChanged({});
    this.setState({
      filters: {},
      errors: {},
      toRemove: [],
      submitFailed: false,
    });
    this.handleHideCreator();
  };

  handleFilterAdd = () => {
    const filters = {
      ...this.state.filters,
      [getLastIndex(this.state.filters) + 1]: {},
    };
    const errors = {
      ...this.state.errors,
      [getLastIndex(this.state.filters) + 1]: this.getFilterErrors(),
    };
    this.setState({ filters, errors, submitFailed: false });
    this.handleShowCreator();
  };

  handleFilterChange = (values, index) => {
    const filters = {
      ...this.state.filters,
      [index]: { ...this.state.filters[index], ...values },
    };
    const errors = {
      ...this.state.errors,
      [index]: this.getFilterErrors({
        ...this.state.filters[index],
        ...values,
      }),
    };
    const hasRemoval =
      Object.keys(this.state.filters).length > Object.keys(filters).length;
    const toRemove = this.state.toRemove.concat(
      hasRemoval ? this.state.filters[index] : []
    );
    this.setState({ filters, errors, toRemove });
    this.props.onFilterChanged(filters);
  };

  render() {
    if (!this.state.showCreator)
      return (
        <Container>
          <FilterSelected
            config={this.props.config}
            filters={this.removeEmptyValues(this.state.filters)?.filters}
            errors={this.state.errors}
            onAdd={this.handleFilterAdd}
            onEdit={this.handleShowCreator}
            onClear={this.handleClearSingle}
            renderSelected={this.props.renderSelected}
          />
        </Container>
      );
    return (
      <Container
        className={cx(cls.filterCreator, {
          [cls.filterCreatorFullWidth]: this.props.fullWidth,
        })}
      >
        <span style={{ position: 'relative' }}>
          <Icon
            onClick={this.handleHideCreator}
            name="close"
            style={{ float: 'right', fontSize: 32, lineHeight: '8px' }}
          />
        </span>

        <Container>
          <h5 className={cls.filterCreatorHeader}>Filter by:</h5>
          {Object.keys(this.state.filters).map((index) => (
            <FilterSingle
              key={index}
              config={this.props.config}
              configAutopopulation={this.props.configAutopopulation}
              variable={this.state.filters[index].variable}
              matcher={this.state.filters[index].matcher}
              condition={
                index == getLastIndex(this.state.filters) ? null : 'AND'
              }
              result={this.state.filters[index].result}
              currentItem={this.props.config?.find(
                ({ name }) => name === this.state.filters[index]?.variable
              )}
              errors={this.state.errors[index]}
              submitFailed={this.state.submitFailed}
              onChange={(value) => this.handleFilterChange(value, index)}
              onClear={() => this.handleClearSingle(index)}
              onAddNext={
                index == getLastIndex(this.state.filters)
                  ? this.handleFilterAdd
                  : undefined
              }
            />
          ))}
        </Container>

        <Container horizontal className={cls.filterCreatorActions}>
          <Button label="Apply" onClick={this.handleApply} />
          <Button secondary label="Clear all" onClick={this.handleClearAll} />
        </Container>
      </Container>
    );
  }
}
