import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import { connect } from 'react-redux';
import {
  getProjects,
  projectActiveChange,
} from 'src/actionCreators/projectActionCreators';
import { Divider, PopoverLight } from 'src/components/IMUI';
import history from 'src/historyInstance';
import { Icon } from 'im/ui/Icon';
import cls from './ProjectSwitcher.module.css';
import { currentProject } from 'src/reducers/utils';
const cx = classNames.bind(cls);

@connect(pick(['projects', 'storage']), { getProjects, projectActiveChange })
export default class ProjectSwitcher extends React.PureComponent {
  _unmounted = false;
  state = { popoverOpen: false, hasProjects: true };
  static propTypes = {
    organizationId: PropTypes.string,
    projects: PropTypes.object,
    getProjects: PropTypes.func,
    currentProjectId: PropTypes.string,
    projectActiveChange: PropTypes.func,
    storage: PropTypes.object,
  };
  componentWillUnmount() {
    this._unmounted = true;
  }
  componentDidMount() {
    this.props
      .getProjects()
      .then(
        (res) =>
          !this._unmounted &&
          this.setState({ hasProjects: res?.data?.items?.length > 0 })
      );
  }
  handleClose = () => {
    this.state.popoverOpen == true && this.setState({ popoverOpen: false });
  };
  handleToggleMenu = (ev) => {
    ev?.preventDefault();
    if (this.state.popoverOpen == true) return this.handleClose();
    this.setState({ popoverOpen: !this.state.popoverOpen });
    if (!this.props.projects.items.length)
      this.props
        .getProjects()
        .then((res) =>
          this.setState({ hasProjects: res?.data?.items?.length > 0 })
        );
  };
  onPopoverClose = () => {
    this.handleClose();
  };
  onChange = (ev, projectId) => {
    ev?.preventDefault();
    this.handleClose();
    this.props.projectActiveChange(projectId, '/summary');
  };
  handleAllProjects = (ev) => {
    ev?.preventDefault();
    this.handleClose();
    history.replace('/analysis');
  };
  handleCreate = (ev) => {
    ev?.preventDefault();
    this.handleClose();
    history.replace('/analysis/add');
  };
  render() {
    const currentProj = currentProject(
      this.props.storage,
      this.props.currentProjectId,
      this.props.organizationId
    );

    return (
      <div
        className={cx('project-switcher')}
        onClick={this.handleToggleMenu}
        ref={(ref) => (this.innerRef = ref)}
      >
        <div>
          <div>
            <Icon name="folder" />
          </div>
          <div>{currentProj?.name || 'Select projects'}</div>
          <div>
            <Icon name="chevron-down" />
          </div>
        </div>
        <PopoverLight
          search={this.state.hasProjects}
          searchHint="Type in to filter projects"
          anchorEl={this.innerRef}
          open={this.state.popoverOpen}
          anchorOrigin={{ horizontal: 'middle', vertical: 'bottom' }}
          targetOrigin={{ horizontal: 'left', vertical: 'top' }}
          style={{
            maxWidth: 300,
            width: 300,
            height: 'auto',
            maxHeight: 500,
            marginLeft: -50,
            marginTop: -30,
          }}
          onRequestClose={this.onPopoverClose}
          renderChildren={(searchText) => (
            <PopoverLight.Menu
              showEmptyListPlaceholder={String(searchText).length > 0}
              style={{ maxHeight: 500 }}
            >
              <PopoverLight.MenuItem
                primaryText="All Projects"
                style={{
                  fontSize: 13,
                  paddingTop: 16,
                  paddingBottom: 16,
                  width: '100%',
                  fontWeight: 600,
                }}
                leftIcon={<Icon name="organization" />}
                onClick={this.handleAllProjects}
              />
              <PopoverLight.MenuItem
                primaryText="Create"
                style={{
                  fontSize: 13,
                  paddingTop: 16,
                  paddingBottom: 16,
                  width: '100%',
                  fontWeight: 600,
                }}
                leftIcon={<Icon name="plus" />}
                onClick={this.handleCreate}
              />
              <Divider compact />
              {this.props.projects.items
                .filter(
                  (project) =>
                    project.name
                      .toLowerCase()
                      .indexOf(searchText.toLowerCase()) !== -1
                )
                .map((project) => (
                  <PopoverLight.MenuItem
                    style={{
                      fontSize: 12,
                      letterSpacing: '0.5px',
                      whiteSpace: 'auto',
                      fontWeight: project.id === currentProj?.id ? 600 : 400,
                    }}
                    key={project.id}
                    value={project.name}
                    primaryText={<span>{project.name}</span>}
                    onClick={(ev) => this.onChange(ev, project.id)}
                  />
                ))}
            </PopoverLight.Menu>
          )}
        />
      </div>
    );
  }
}
