import { csvParse, csvParseRows } from 'd3-dsv';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Dropzone from 'react-dropzone';

import { Button, Tooltip } from 'src/components/IMUI';

export default class CSVUploadButton extends PureComponent {
  static propTypes = {
    ...Button.propTypes,
    accept: PropTypes.string,
    onFileUploaded: PropTypes.func,
    rowParser: PropTypes.func,
    tip: PropTypes.string,
    text: PropTypes.bool,
  };

  static defaultProps = {
    accept: '.csv',
    label: 'Upload CSV',
    tip: `<div>
      This is an experimental feature,<br>
      if you have problems using it get in touch.
    </div>`,
  };

  onFileUploaded = (files) => {
    const { rowParser } = this.props;
    const file = files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const rawCSV = event.target.result;
      const csv = !rowParser
        ? csvParse(rawCSV)
        : csvParseRows(rawCSV, rowParser);
      this.props.onFileUploaded(file, rawCSV, csv);
    };
    reader.readAsText(file);
  };

  render() {
    const { accept, tip, text, ...buttonProps } = this.props;
    return (
      <Dropzone
        accept={accept}
        onDrop={this.onFileUploaded}
        style={{ border: 'none', width: 'auto', height: 'auto' }}
      >
        {!text ? (
          <Button
            onClick={() => ''}
            data-tip
            data-for="csv-upload"
            {...buttonProps}
          />
        ) : (
          <div onClick={() => ''}>{buttonProps.label || 'Upload CSV'}</div>
        )}
        {!text && (
          <Tooltip id="csv-upload" place="right">
            {tip}
          </Tooltip>
        )}
      </Dropzone>
    );
  }
}
