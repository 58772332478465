import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getGrantsFilters } from 'src/actionCreators/grantsFiltersActionCreators';
import { Dialog, Button } from 'src/components/IMUI/index';
import { getOrgText } from 'src/services/DictionaryService';
import GrantEdit from '../../Edit/GrantEdit';
import { canManageGrants } from 'src/userStorage';

@connect(
  (state) => ({
    organizationCurrent: state.organizationCurrent,
    defaultRegions: state.defaultRegions,
    grantsFilters: state.grantsFilters,
  }),
  { getGrantsFilters }
)
export default class EditDialogsGrant extends React.PureComponent {
  static propTypes = {
    organizationCurrent: PropTypes.object,
    defaultRegions: PropTypes.array,
    grantsFilters: PropTypes.object,
    getGrantsFilters: PropTypes.func,
    grant: PropTypes.object,
    onCloseModal: PropTypes.func.isRequired,
    onSubmitSuccessful: PropTypes.func.isRequired,
    fundingsArray: PropTypes.array.isRequired,
  };

  componentDidMount() {
    this.props.getGrantsFilters();
  }
  render() {
    const validFundings = (fundings) =>
      fundings?.some((f) => f.start_date) ? fundings : null;
    const fundings =
      validFundings(this.props.fundingsArray) ||
      validFundings(this.props.grant?.fundings) ||
      [];
    const { organizationCurrent, defaultRegions, grantsFilters, grant } =
      this.props;
    const startDate = fundings.length > 0 && fundings[0].start_date;
    const endDate = fundings.length > 0 && fundings[0].end_date;
    const fundingType = fundings.length > 0 && fundings[0].type;

    const initialValues = {
      ...grant,
      start_date: startDate,
      end_date: endDate,
      funding_type: fundingType,
      programs: grant.portfolio_list,
      issues: grant.issue_list,
      strategies: grant.strategies_list,
      populations: grant.population_list,
      donors: grant.donor_list,
      cohort: grant.cohort_list,
    };

    return (
      <Dialog
        open
        extraLarge
        title={getOrgText('Edit grant')}
        onRequestClose={this.props.onCloseModal}
        rightActions={
          <Button
            disabled={!canManageGrants()}
            size="l"
            key="submit"
            type="submit"
            label="Update"
            form={'grantEditJsonapi'}
          />
        }
        leftActions={
          <Button
            negative
            size="l"
            type="button"
            label="Cancel"
            onClick={this.props.onCloseModal}
          />
        }
      >
        <GrantEdit
          form={'grantEditJsonapi'}
          initialValues={initialValues}
          fundingTypes={organizationCurrent.data?.funding_types}
          statuses={organizationCurrent.data?.grant_statuses}
          customGrantFields={organizationCurrent.data?.custom_grant_fields}
          regions={defaultRegions}
          grantsFilters={grantsFilters}
          onClose={this.props.onCloseModal}
          onSubmitSuccessful={this.props.onSubmitSuccessful}
          fundingsArray={fundings}
        />
      </Dialog>
    );
  }
}
