import { connectRouter, routerMiddleware } from 'connected-react-router';
import { loadingBarMiddleware } from 'react-redux-loading-bar';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import effects from 'src/effects';
import createEffects from 'src/effects/middleware/createEffects';
import history from 'src/historyInstance';
import allReducers from 'src/reducers';
import rootReducer from 'src/reducers/rootReducer';
import SentryService, { sentryMiddleware } from 'src/services/SentryService';
import imApiMiddleware from 'im/api/apiMiddleware';
import reducerRegistry from 'im/api/registry';

const reduxMiddleware = () =>
  process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__
    : (f) => f;

export default (() => {
  SentryService.init();
  const effectsMiddleware = createEffects(effects);
  reducerRegistry.registerInit({
    ...allReducers,
    router: connectRouter(history),
  });

  const store = createStore(
    reducerRegistry.combine(reducerRegistry.reducers, rootReducer),
    compose(
      applyMiddleware(
        thunk,
        effectsMiddleware,
        routerMiddleware(history),
        imApiMiddleware,
        sentryMiddleware,
        reduxMiddleware,
        loadingBarMiddleware({
          promiseTypeSuffixes: ['REQUEST', 'SUCCESS', 'FAILURE'],
        })
      )
    )
  );
  reducerRegistry.setChangeListener((reducers) =>
    store.replaceReducer(reducerRegistry.combine(reducers, rootReducer))
  );

  return store;
})();
