/*eslint no-undef: 0*/
import classNames from 'classnames/bind';
import { LionessProvider } from 'lioness';
import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeSurveyProperty } from 'src/actionCreators/surveyActionCreators';
import logo from 'src/static/impact-logo.svg';
import { Section, Container, Toggle } from 'src/components/IMUI';
import Survey from 'src/components/Survey/Survey';
import CompanyLogo from 'src/pages/AnswerSurvey/components/CompanyLogo';
import { fromQuestionsToLayout } from 'src/serializers/surveySerializer';
import { getSurveyName, getI18nCompleteness } from 'src/utils/surveysI18n';
import DevicePreview from '../components/DevicePreview';
import SurveyPreviewFooter from './components/SurveyPreviewFooter';
import cls from './Preview.module.css';
import { messages } from 'src/../i18n/index';
const cx = classNames.bind(cls);

@connect(pick(['survey', 'organizationCurrent']), { changeSurveyProperty })
export default class Preview extends Component {
  static propTypes = {
    survey: PropTypes.object,
    organizationCurrent: PropTypes.object,
    tagsWithTagGroups: PropTypes.array,
    changeSurveyProperty: PropTypes.func,
  };

  state = {
    isMobilePreview: false,
    isPrinting: false,
    skipConditionalLogic: false,
  };

  handleSetPreviewMode = (isMobilePreview) => {
    this.setState({ isMobilePreview });
  };

  handleLanguageChanged = ({ value }) => {
    this.props.changeSurveyProperty('activeLanguage', value, { silent: true });
    setTimeout(() => this.forceUpdate(), 500);
  };

  handlePrintOpen = () => {
    this.setState({ isPrinting: true });
  };

  handlePrintClose = () => {
    this.setState({ isPrinting: false });
  };

  render() {
    const { survey, tagsWithTagGroups, organizationCurrent } = this.props;
    const { isMobilePreview, isPrinting, skipConditionalLogic } = this.state;

    const layout = fromQuestionsToLayout(survey.questions);
    const questions = survey.questions.filter((q) => !q.isLayout);
    const surveyName = getSurveyName(survey);
    const completeness = getI18nCompleteness(survey);

    const organization = {
      title: organizationCurrent.data.title,
      email: (organizationCurrent.data || {}).contact_email,
    };
    const surveyLogo =
      survey.organization_logo && organizationCurrent.data.logo_url
        ? organizationCurrent.data.logo_url
        : logo;

    return (
      <LionessProvider
        locale={survey.activeLanguage}
        messages={messages}
        debug={false}
      >
        <Section>
          <Container centered className={cls.subHeader}>
            <p className={cls.subHeaderText}>
              This is an approximation of how your survey questions will appear
              on {isMobilePreview ? 'mobile' : 'desktop'}.
            </p>

            <div>
              <Toggle
                mini
                className={cls.surveyToggle}
                label="Show conditional questions and elements"
                labelPosition="right"
                toggled={skipConditionalLogic}
                onToggle={(ev, toggled) =>
                  this.setState({ skipConditionalLogic: toggled })
                }
              />
            </div>
          </Container>

          <DevicePreview
            isSmall={isMobilePreview}
            containerBodyProps={{ className: cls.containerBody }}
          >
            <Section
              surveyWidth
              forceMobileStyling={isMobilePreview}
              className={cx(cls.surveyCustomSection, {
                [cls.surveyCustomSectionSmall]: isMobilePreview,
              })}
            >
              <CompanyLogo logo={surveyLogo} className={cls.logo} />
              <div className={cls.surveyTitle}>{surveyName}</div>
              <Survey
                isDirty
                isPreview
                isAnswerMode
                isPrintable
                isPrinting={isPrinting}
                forceMobileStyling={isMobilePreview}
                skipConditionalLogic={skipConditionalLogic}
                layout={layout}
                questions={questions}
                answers={survey.answers}
                tagsWithTagGroups={tagsWithTagGroups}
                organization={organization}
                onSubmit={() => void 0}
              />
            </Section>
          </DevicePreview>
          <SurveyPreviewFooter
            survey={survey}
            completeness={completeness}
            isMobilePreview={isMobilePreview}
            onLanguageChanged={this.handleLanguageChanged}
            onSetPreviewMode={this.handleSetPreviewMode}
            onPrintOpen={this.handlePrintOpen}
            onPrintClose={this.handlePrintClose}
          />
        </Section>
      </LionessProvider>
    );
  }
}
