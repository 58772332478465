import PropTypes from 'prop-types';
import React from 'react';

import allMatchers, {
  textMatchers,
  dateMatchers,
  numberMatchers,
  booleanMatchers,
  selectMatchers,
  arrayMatchers,
} from 'src/data/matchers';

import FilterItem from './FilterItem';

export default class FilterItemMatcher extends React.Component {
  static propTypes = {
    ...FilterItem.propTypes,
    type: PropTypes.oneOf([
      'text',
      'select',
      'date',
      'boolean',
      'number',
      'array',
    ]),
    matchers: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  };

  static defaultProps = {
    hintText: 'Choose',
  };

  constructor(props) {
    super(props);
    this.state = {
      matchers: this.getMatchers(props.matchers, props.type),
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.type !== this.props.type ||
      nextProps.matchers !== this.props.matchers
    ) {
      this.setState({
        matchers: this.getMatchers(nextProps.matchers, nextProps.type),
      });
    }
  }

  getCustomMatchers(matchers) {
    if (!Array.isArray(matchers)) return this.transformMatchers(matchers);
    const allMatchersKeys = Object.keys(allMatchers);

    return matchers.reduce((acc, el) => {
      const matcherKeyFound = allMatchersKeys.find((mk) => mk === el);

      return !matcherKeyFound
        ? acc
        : acc.concat([{ name: el, displayName: allMatchers[matcherKeyFound] }]);
    }, []);
  }

  getMatchersByType(type) {
    switch (type) {
      case 'text':
        return textMatchers;
      case 'select':
        return selectMatchers;
      case 'date':
        return dateMatchers;
      case 'boolean':
        return booleanMatchers;
      case 'number':
        return numberMatchers;
      case 'array':
        return arrayMatchers;
      default:
        return textMatchers;
    }
  }

  getMatchers(matchers, type) {
    return matchers
      ? this.getCustomMatchers(matchers)
      : this.transformMatchers(this.getMatchersByType(type));
  }

  transformMatchers(matchers) {
    return Object.keys(matchers).map((name) => ({
      name,
      displayName: matchers[name],
    }));
  }

  render() {
    const { value, hintText, error, disabled } = this.props;
    const { matchers } = this.state;

    return (
      <FilterItem
        disabled={disabled}
        items={matchers}
        hintText={hintText}
        value={value}
        error={error}
        onChange={this.props.onChange}
      />
    );
  }
}
