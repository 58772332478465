import { STORAGE__UPDATE, STORAGE__LOAD } from 'src/constants';

export const updateStorage = (storage) => ({
  type: STORAGE__UPDATE,
  payload: { storage },
});
export const loadStorage = (storage) => ({
  type: STORAGE__LOAD,
  payload: { storage },
});
