import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { changeSurveyProperty } from 'src/actionCreators/surveyActionCreators';
import { Card, Container, Toggle } from 'src/components/IMUI';
import { Icon } from 'im/ui/Icon';
import TextWithClipboard from '../../components/TextWithClipboard';
import publicOff from 'src/static/public-off.svg';
import publicOn from 'src/static/public-on.svg';
import cls from './SettingBox.module.css';

@connect(null, { changeSurveyProperty })
export default class SettingPublic extends React.PureComponent {
  static propTypes = {
    projectId: PropTypes.string,
    survey: PropTypes.object,
    changeSurveyProperty: PropTypes.func,
  };
  handleToggle = () => {
    this.props.changeSurveyProperty('public', !this.props.survey.public, {
      projectId: this.props.projectId,
      autosave: true,
    });
  };
  tipText = `<div style="max-width: 200px">Private surveys are accessible only to invited respondents using ImpactMapper, using a unique link to access its own survey.</div>`;

  render() {
    const icon = this.props.survey.public ? publicOn : publicOff;
    const publicUrl = `${window.location.origin}/answer-survey/public/${
      this.props.survey.public_id
    }${
      this.props.survey.activeBatch
        ? `?batch=${this.props.survey.activeBatch}`
        : ''
    }`;

    return (
      <div className={cls.settingBox}>
        <Card smallPadding border>
          <Container horizontal spaceBetween nowrap className={cls.boxTop}>
            <div>
              <h3 className={cls.boxHeader}>
                <span>Private</span>
                <Icon name="question-filled" tip={this.tipText} />
              </h3>
              <Toggle
                className={cls.boxToggle}
                toggled={!this.props.survey.public}
                onToggle={this.handleToggle}
              />
            </div>
            <div className={cls.boxCenter}>
              {this.props.survey.public && (
                <TextWithClipboard
                  small
                  notificationMsg="Survey's link copied"
                  url={publicUrl}
                />
              )}
              {!this.props.survey.public && (
                <h4 style={{ textAlign: 'center', margin: 8 }}>
                  No public link
                </h4>
              )}
            </div>
            <img className={cls.boxIcon} src={icon} height={48} alt="padlock" />
          </Container>
        </Card>
      </div>
    );
  }
}
