import React from 'react';
import PropTypes from 'prop-types';
import WaitingImg from 'src/static/waiting.png';
import FlexColumn from 'src/components/FlexColumn/FlexColumn';
import {
  Card,
  Container,
  Button,
  Section,
  CardEmpty,
} from 'src/components/IMUI';
import cls from './Waiting.module.css';

const Waiting = ({ onRefresh }) => (
  <Section horizontal sunken grow className={cls.tagEditorWaitingWrapper}>
    <FlexColumn style={{ position: 'relative', height: '100%' }}>
      <Card className={cls.tagEditorWaitingCard} noPadding grow>
        <CardEmpty
          large
          title="Your report is being processed..."
          description="Please, check back later to tag your report once it is done."
        >
          <Container className={cls.tagEditorWaitingContainer}>
            <img src={WaitingImg} alt="take a coffee to wait" />
            <Button size="l" secondary label="Refresh" onClick={onRefresh} />
          </Container>
        </CardEmpty>
      </Card>
    </FlexColumn>
  </Section>
);

Waiting.propTypes = { onRefresh: PropTypes.func.isRequired };
export default Waiting;
