import UpdatePending from '../actionCreators/UpdatePending';
import { actionStates } from '../entities/AsyncActionsGenerator';
import { isType } from '../helpers/actionTypes';

export default function runPendingStatus(
  dispatch,
  action,
  storeElement,
  currentResource
) {
  const isNumericPending = (currentResource.reducerConfig || {})
    .hasNumericPending;

  if (isType(action.type, actionStates.request)) {
    const pendingKey = UpdatePending.getPendingKey(storeElement, action);

    if (!isNumericPending && storeElement.pending[pendingKey]) {
      return;
    }

    const pending = !isNumericPending || storeElement.pending[pendingKey] + 1;
    dispatch(
      currentResource.actions[UpdatePending.actionType].call(this, {
        [pendingKey]: pending,
      })
    );
  }

  if (
    isType(action.type, actionStates.success) ||
    isType(action.type, actionStates.failure)
  ) {
    const pendingKey = UpdatePending.getPendingKey(storeElement, action);

    if (!storeElement.pending[pendingKey]) {
      return;
    }
    const pending = isNumericPending && storeElement.pending[pendingKey] - 1;
    dispatch(
      currentResource.actions[UpdatePending.actionType].call(this, {
        [pendingKey]: pending,
      })
    );
  }
}
