import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field } from 'redux-form';

import { TextField, Container } from 'src/components/IMUI/index';

import { Icon } from 'im/ui/Icon';

import cls from './CustomArrayFieldItem.module.css';
import { canManageOrganization } from 'src/userStorage';

const cx = classNames.bind(cls);

export default class CustomArrayFieldItem extends Component {
  static propTypes = {
    member: PropTypes.string,
    index: PropTypes.number,
    fields: PropTypes.object,
    hintText: PropTypes.string,
  };

  onItemMove = (toIndex) => {
    const { fields, index } = this.props;
    if (toIndex >= fields.length || toIndex < 0) {
      return;
    }
    fields.move(index, toIndex);
  };

  onItemRemove = () => {
    const { fields, index } = this.props;
    fields.remove(index);
  };

  render() {
    const { member, index, fields, hintText } = this.props;

    return (
      <li>
        <Container horizontal nowrap centered>
          <div className={cx('field-item-icons')}>
            <Icon
              name="chevron-up"
              className={cx('field-item-icon')}
              disabled={index === 0}
              onClick={() => this.onItemMove(index - 1)}
            />
            <span className={cx('field-item-icon-counter')}>{index + 1}</span>
            <Icon
              name="chevron-down"
              className={cx('field-item-icon')}
              disabled={index === fields.length - 1}
              onClick={() => this.onItemMove(index + 1)}
            />
          </div>
          <Field
            fullWidth
            component={TextField}
            name={member}
            hintText={hintText}
          />
          {canManageOrganization() && (
            <Icon
              name="trash"
              className={cx('field-item-icon', 'field-item-space')}
              onClick={this.onItemRemove}
            />
          )}
        </Container>
      </li>
    );
  }
}
