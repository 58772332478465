import MUIMenu from 'material-ui/Menu';
import PropTypes from 'prop-types';
import React from 'react';

const MENU_STYLE = { display: 'block', paddingTop: 0 };

export const Menu = ({
  children,
  autoWidth,
  width,
  desktop,
  listStyle,
  ...rest
}) => (
  <MUIMenu
    {...rest}
    desktop={desktop}
    autoWidth={autoWidth}
    width={width}
    listStyle={{ ...MENU_STYLE, ...listStyle }}
  >
    {children}
  </MUIMenu>
);

Menu.propTypes = {
  children: PropTypes.node,
  autoWidth: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  desktop: PropTypes.bool,
  listStyle: PropTypes.object,
};

Menu.defaultProps = {
  autoWidth: false,
  width: '100%',
  desktop: true,
  disableAutoFocus: true,
};

export default Menu;
