import organizationApi, {
  Organization,
  OrganizationCurrent,
} from 'src/api/Organization';
import { Organizations } from 'src/api/Organizations';
import uploaderLogoApi from 'src/api/UploaderLogo';
import history from 'src/historyInstance';
import Appcues from 'src/services/AppcuesService';
import Intercom from 'src/services/IntercomService';
import Sentry from 'src/services/SentryService';
import { where } from 'im/api/Query';
import { initStorage, isStaff } from 'src/userStorage';

export default function organizationEffects(state, action, dispatch) {
  const redirectToOrganizations = () => history.replace('/user/organizations');
  const redirectOrgCreation = () =>
    history.replace(
      !action.payload.data?.disabled || isStaff()
        ? '/user/organizations'
        : '/user/organizations/add/success'
    );
  switch (action.type) {
    case OrganizationCurrent.actionTypes.put.success: {
      if (!state.organizationCurrent.data?.id) return;
      dispatch(
        organizationApi.current.find({ id: state.organizationCurrent.data.id })
      );
      break;
    }
    case OrganizationCurrent.actionTypes.find.success: {
      if (!action.payload.data?.id) return;
      initStorage(action.payload.data.id);
      Intercom.update({
        companies: [
          {
            company_id: action.payload.data.id,
            name: action.payload.data.title,
          },
        ],
      });
      Appcues.identify(state.user?.data?.id, {
        organizationName: action.payload.data.title,
      });
      Sentry.setOrganization(action.payload.data.id, action.payload.data.title);
      break;
    }
    // Organization region
    case Organization.actionTypes.put.success: {
      if (action.payload.data.id == state.organizationCurrent.data.id)
        dispatch(
          organizationApi.current.find({
            id: state.organizationCurrent.data.id,
          })
        );
      redirectToOrganizations();
      break;
    }
    case Organization.actionTypes.find.success: {
      if (action.payload.data.id == state.organizationCurrent.data.id)
        dispatch(
          organizationApi.current.find({
            id: state.organizationCurrent.data.id,
          })
        );
      break;
    }
    case Organizations.actionTypes.create.success: {
      if (action.meta?.logo)
        dispatch(
          uploaderLogoApi.uploadOrganizationLogo(
            where()
              .payloadMultipart(
                {
                  organization_id: action.payload.data.id,
                  asset: action.meta.logo,
                },
                'logo'
              )
              .actionMeta(action.meta)
          )
        );
      redirectOrgCreation();
      break;
    }
    case Organizations.actionTypes.destroy.success:
      return redirectToOrganizations();
    default:
      break;
  }
}
