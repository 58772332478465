import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Cell from './Cell';
import Row from './Row';

import cls from './tables.module.css';

const cx = classNames.bind(cls);

export default class ExpandableRow extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    colSpan: PropTypes.number,
    isExpanded: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    colSpan: 2,
  };

  state = {
    containerEl: {},
    maxHeight: 0,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.maxHeight === this.state.containerEl.scrollHeight) return;
    /* eslint-disable */
    this.setState({ maxHeight: this.state.containerEl.scrollHeight });
    /* eslint-enable */
  }

  handleRef = (ref) => {
    this.setState({ containerEl: ref, maxHeight: ref?.scrollHeight });
  };

  render() {
    const { children, colSpan, isExpanded, className, ...rest } = this.props;
    const style = isExpanded ? { maxHeight: this.state.maxHeight } : {};

    return (
      <Row
        className={cx('imui-table-expandable-row', {
          'imui-table-expandable-row-visible': isExpanded,
        })}
        {...rest}
      >
        <Cell
          className={cx('imui-table-expandable-row-cell')}
          colSpan={colSpan}
        >
          <div
            style={style}
            ref={this.handleRef}
            className={cx('imui-table-expandable-row-content', className)}
          >
            {children}
          </div>
        </Cell>
      </Row>
    );
  }
}
