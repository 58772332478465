import PropTypes from 'prop-types';
import React from 'react';
// import classNames from "classnames/bind";

import IconSubItem from 'src/components/IconSubItem/IconSubItem';
import { SelectPopoverLight } from 'src/components/IMUI';

import { Icon } from 'im/ui/Icon';

import cls from './TagCategorySelectorItem.module.css';

// const cx = classNames.bind(cls);

export default class TagCategorySelectorItem extends React.PureComponent {
  static propTypes = {
    category: PropTypes.object,
    treeLevel: PropTypes.number,
    className: PropTypes.string,
    isExpanded: PropTypes.bool,

    onClick: PropTypes.func,
    onToggleVisible: PropTypes.func,
    renderChildren: PropTypes.func,
  };

  render() {
    const { category, treeLevel, renderChildren, className, isExpanded } =
      this.props;
    const hasChildren = (category.child_categories || []).length;
    const hasParent = !!category.parent;
    const childItemNotch = 16;
    const notchStyle = { marginLeft: childItemNotch * treeLevel };
    const LeftIcon = hasChildren ? (
      <Icon
        name={isExpanded ? 'folder' : 'folder-closed'}
        className={cls.tagCategorySelectorItemIcon}
        onClick={(ev) => this.props.onToggleVisible(ev, category)}
      />
    ) : (
      <IconSubItem />
    );

    return (
      <SelectPopoverLight.MenuItem
        key={category.id}
        value={category}
        primaryText={
          <span className={className}>
            <span style={notchStyle} className={cls.tagCategoryItemText}>
              {!hasParent && !hasChildren ? (
                <Icon
                  name="folder-closed"
                  className={cls.tagCategorySelectorItemIcon}
                />
              ) : (
                LeftIcon
              )}
              {category.title}
            </span>
            {renderChildren && renderChildren()}
          </span>
        }
        onClick={() => this.props.onClick(category)}
      />
    );
  }
}
