import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import { Icon } from 'im/ui/Icon';

import cls from './TileIcon.module.css';

const cx = classNames.bind(cls);

const TileIcon = ({
  size,
  imgUrl,
  backgroundImgUrl,
  fontIconName,
  className,
  ...rest
}) => {
  const classNamesArray = [
    'imui-tile-img',
    `imui-tile-img-size-${size}`,
    className,
  ];

  if (imgUrl) {
    return (
      <img
        {...rest}
        className={cx(...classNamesArray)}
        src={imgUrl}
        alt="icon"
      />
    );
  }

  if (backgroundImgUrl) {
    return (
      <div
        {...rest}
        className={cx('imui-tile-background-img', ...classNamesArray)}
        style={{ backgroundImage: `url(${backgroundImgUrl})` }}
      />
    );
  }

  if (fontIconName) {
    return (
      <Icon
        {...rest}
        name={fontIconName}
        className={cx('imui-tile-font-icon', ...classNamesArray)}
      />
    );
  }

  return null;
};

TileIcon.propTypes = {
  size: PropTypes.oneOf(['m', 'l']),
  imgUrl: PropTypes.string,
  backgroundImgUrl: PropTypes.string,
  fontIconName: PropTypes.string,
  className: PropTypes.string,
};
TileIcon.defaultProps = {
  size: 'm',
};

export default TileIcon;
