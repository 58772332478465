import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import helpOwlIcon from 'src/components/HelpOwlButton/HelpOwlIcon';
import { Section, Container, Card, Button } from 'src/components/IMUI/index';
import history from 'src/historyInstance';

import cls from './SuccessfulScreen.module.css';

const cx = classNames.bind(cls);

const SuccessfulScreen = ({
  text,
  title,
  successLabel,
  successRedirectPath,
  altText,
  altAction,
}) => (
  <Section grow>
    <Container grow className={cx('successful-screen-container')}>
      <Card className={cx('successful-screen-card')}>
        <div className={cx('successful-screen-owl-container')}>
          {helpOwlIcon}
        </div>
        <h3>{title}</h3>
        <p className={cx('successful-screen-text')}>{text}</p>

        <Button
          secondary
          className={cx('successful-screen-action')}
          label={successLabel}
          onClick={() => history.push(successRedirectPath)}
        />
        {altText && altAction && (
          <button className={cls.altAction} onClick={altAction}>
            {altText}
          </button>
        )}
      </Card>
    </Container>
  </Section>
);

SuccessfulScreen.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  altText: PropTypes.string,
  altAction: PropTypes.func,
  successLabel: PropTypes.string,
  successRedirectPath: PropTypes.string,
};

export default SuccessfulScreen;
