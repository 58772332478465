import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';

import EditChart from './EditChart';
import List from './List';

export default class AnalysisChartsIndex extends Component {
  static propTypes = {
    ...ReactRouterPropTypes,
  };

  render() {
    const { match } = this.props;
    const rootPath = match.path;

    return (
      <Switch>
        <Route path={`${rootPath}/list`} component={List} exact />
        <Route path={`${rootPath}/new`} component={EditChart} exact />
        <Route path={`${rootPath}/:chartId`} component={EditChart} />
        <Redirect from={`${rootPath}`} to={`${match.url}/list`} />
      </Switch>
    );
  }
}
