import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Container, Tag } from 'src/components/IMUI';
import cls from './HeaderSteps.module.css';
const StepStateTypes = PropTypes.oneOf(['normal', 'positive', 'warning']);

const StepPropType = PropTypes.shape({
  url: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  title: PropTypes.string,
  disabled: PropTypes.bool,
  state: StepStateTypes,
  badgeState: StepStateTypes,
  badge: PropTypes.oneOfType([PropTypes.node, PropTypes.number]),
});

const cx = classNames.bind(cls);
const getUrl = (step) =>
  step.disabled ? null : typeof step.url === 'function' ? step.url() : step.url;

export default class HeaderSteps extends React.PureComponent {
  static propTypes = {
    activeStep: PropTypes.number,
    steps: PropTypes.arrayOf(StepPropType),
    noSeparator: PropTypes.bool,
  };
  render() {
    return (
      <Container horizontal className={cls.headerSteps}>
        {this.props.steps.map((step, i) => (
          <div
            key={step.title}
            className={cx(cls.step, {
              [cls.stepActive]: this.props.activeStep === i,
              [cls.stepDisabled]: !!step.disabled,
              [cls.stepNoSeparator]: this.props.noSeparator,
            })}
          >
            {!step.disabled ? (
              <Link to={getUrl(step)}>
                {step.title}
                {!!step.badge && <Tag label={step.badge} inheritColor small />}
              </Link>
            ) : (
              <a>
                {step.title}
                {!!step.badge && <Tag label={step.badge} inheritColor small />}
              </a>
            )}
          </div>
        ))}
      </Container>
    );
  }
}
