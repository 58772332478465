import React from 'react';
import PropTypes from 'prop-types';
import {
  Actions,
  Button,
  Card,
  Container,
  Dialog,
  TextField,
} from 'src/components/IMUI';
import cls from './TagSelectorTextDialog.module.css';
import { Icon } from 'im/ui';
import TagSelector from 'src/components/TagSelector/TagSelector';
import taggingsApi from 'src/api/Taggings';
import store from 'src/store';
import { where } from 'im/api/Query';
import { getSurveyAnswers } from 'src/actionCreators/surveyAnalysisActionCreators';
import { getUserProfileInfo } from 'src/services/AuthService';
import { NO_ANSWER } from '../../Surveys/Survey/constants';
import {
  createSurveyAnswer,
  getSurveyReview,
} from 'src/actionCreators/surveyActionCreators';
import InjectableContent from 'src/components/Survey/InjectableContent';

const retrieveAnswerValue = (answer) => {
  if (answer.response?.simple_value?.length > 0)
    return answer.response?.simple_value;
  if (answer.response?.other_value?.length > 0)
    return answer.response?.other_value;
  if (Array.isArray(answer.value)) return answer.value.join(', ');
  if (typeof answer.value == 'string') return answer.value;
  if (typeof answer.value == 'number') return answer.value;
  if (typeof answer.value == 'object') return '-';
  return NO_ANSWER;
};

export default class TagSelectorTextDialog extends React.PureComponent {
  static propTypes = {
    question: PropTypes.object,
    answer: PropTypes.object,
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    tagsWithTagGroups: PropTypes.array,
    granteeSurveyId: PropTypes.string,
    isReview: PropTypes.bool,
  };
  state = {
    selectedText: '',
    initialText: retrieveAnswerValue(this.props.answer),
  };
  toInclude = [];
  toRemoval = [];
  shouldReload = false;
  componentDidMount() {
    this.checkAnswer();
  }
  onChangeContent = (text) => {
    this.setState({ selectedText: text });
  };
  handleClose = () => {
    this.props.handleClose();
    this.reloadAnswers(true);
  };
  handleSelectedText = () => {
    const selectedText = window.getSelection()?.toString()
      ? window.getSelection()?.toString()
      : null;
    this.state.selectedText != selectedText &&
      selectedText != null &&
      this.setState({ selectedText });
  };
  handleClearSelectedText = () => {
    this.setState({ selectedText: '' });
  };
  reloadAnswers = (force = false) => {
    if (!this.shouldReload) return;
    this.props.isReview
      ? store.dispatch(
          getSurveyReview(
            store.getState()?.project?.id,
            this.props.granteeSurveyId
          )
        )
      : store.dispatch(getSurveyAnswers(this.props.question.id, { force }));
  };
  onTaggingRemoveSingle = (tagId) => {
    this.shouldReload = true;
    const toRemoval = this.currentTaggings().filter((t) => t.tag_id == tagId);
    Promise.allSettled(
      toRemoval.map((t) =>
        store.dispatch(
          taggingsApi.destroy(
            where({ id: t.id }).actionMeta({ silent: true, noSync: true })
          )
        )
      )
    ).then(() => this.reloadAnswers());
  };
  onTaggingChange = (tagIds) => {
    const currentTagIds = this.currentTaggings().map((t) => t.tag_id);
    this.toInclude = tagIds.filter((id) => !currentTagIds.includes(id));
    this.toRemoval = this.currentTaggings()
      .filter((tag) => !tagIds.includes(tag.tag_id))
      .map((t) => t.id);
    this.shouldReload = true;
  };
  checkAnswer = () => {
    if (this.props.answer?.answerId) return;
    if (!this.props.isReview) return;
    const response = {};
    if (/singleMatrix/.test(this.props.question.type))
      response.object_value = {};
    else if (/multipleChoice/.test(this.props.question.type))
      response.object_value = [];
    else if (!response?.object_value) response.simple_value = '';
    store
      .dispatch(
        createSurveyAnswer({
          survey_question_id: this.props.answer.id,
          grantee_survey_id: this.props.granteeSurveyId,
          response,
        })
      )
      .then((res) => {
        this.props.answer.answerId = res?.data?.survey_answer?.id;
      });
  };
  onTaggingCommit = () => {
    this.shouldReload =
      this.toRemoval?.length > 0 || this.toInclude?.length > 0;
    Promise.allSettled([
      ...this.toRemoval.map((taggingId) =>
        store.dispatch(
          taggingsApi.destroy(
            where({ id: taggingId }).actionMeta({ silent: true, noSync: true })
          )
        )
      ),
      ...this.toInclude.map((tag_id) =>
        store.dispatch(
          taggingsApi.create(
            where()
              .payload({
                attributes: { content: this.selectedText(), type: 'tagging' },
                relationships: [
                  { relName: 'tag', type: 'tags', id: tag_id },
                  {
                    relName: 'taggable',
                    type: 'survey_answers',
                    id: this.props.answer.uid,
                  },
                  {
                    relName: 'user',
                    type: 'users',
                    id: getUserProfileInfo().user_id,
                  },
                  {
                    relName: 'organization',
                    type: 'organizations',
                    id: store.getState()?.organizationCurrent?.data?.id,
                  },
                  {
                    relName: 'project',
                    type: 'projects',
                    id: store.getState()?.project?.uid,
                  },
                ],
              })
              .actionMeta({ silent: true, noSync: true })
          )
        )
      ),
    ])
      .then(() => {
        this.toInclude = [];
        this.toRemoval = [];
      })
      .then(() => this.reloadAnswers(true));
  };

  currentTaggings = () => {
    return [
      ...(this.props.answer.question_taggings || []),
      ...(this.props.answer.manual_taggings || []),
    ].filter(Boolean);
  };
  selectedText = () =>
    this.state.selectedText?.length > 0
      ? this.state.selectedText
      : this.state.initialText;
  render() {
    const tagsInContext = this.currentTaggings();
    const selectedTagIds = tagsInContext.map((mt) => mt.tag_id) ?? [];
    const isEmpty = selectedTagIds.length == 0;
    const labelSelected =
      this.state.selectedText?.length > 0 ? (
        <label>
          Selected text to be tagged:{' '}
          <span
            style={{ fontSize: 20, cursor: 'pointer' }}
            role="button"
            onClick={this.handleClearSelectedText}
          >
            <Icon tip="clear" name="close" />
          </span>
        </label>
      ) : (
        <label>Default answer to be tagged: </label>
      );
    const maxScreenBodyHeight = window.innerHeight - 80;

    return (
      <Dialog
        key={`h${window.innerHeight}w${window.innerWidth}`}
        extraLarge
        bodyStyle={{
          minHeight: maxScreenBodyHeight,
          maxHeight: maxScreenBodyHeight,
          height: maxScreenBodyHeight,
          display: 'flex',
          flexDirection: 'column',
        }}
        contentStyle={{
          width: window.innerWidth - 80,
          minWidth: window.innerWidth - 80,
          position: 'fixed',
          transform: 'unset',
          top: 40,
          bottom: 40,
          left: 0,
          right: 0,
        }}
        title=""
        modal={true} //prevent close
        open={this.props.open}
        onRequestClose={this.props.handleClose}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexGrow: 1,
            flexShrink: 0,
            gap: 12,
          }}
        >
          <section
            style={{
              padding: 12,
              minHeight: 300,
              flexGrow: 1,
              overflowY: 'scroll',
              minWidth: '50%',
              borderRadius: 6,
              border: '1px solid #ffffff00',
              background: '#ffffff60',
            }}
          >
            <Card
              className={cls.tagEditorCard}
              grow
              smallPadding
              flat
              style={{ background: 'transparent' }}
            >
              <label style={{ fontSize: 15 }}>Source response:</label>
              <Container grow className={cls.editorArea}>
                <div className={cls.editorWrapper}>
                  <div
                    className={cls.editor}
                    onMouseUpCapture={this.handleSelectedText}
                  >
                    <div>
                      <strong>
                        {this.props.question.position}.{' '}
                        <InjectableContent
                          convertUrls
                          text={this.props.question.question}
                        />
                      </strong>
                    </div>
                    <br />
                    {this.state.initialText}
                  </div>
                </div>
              </Container>
            </Card>
          </section>

          <section
            className={cls.tagsInContext}
            style={{
              padding: 20,
              minHeight: 300,
              flexGrow: 1,
              width: '50%',
              flexShrink: 0,
              minWidth: '50%',
              borderRadius: 6,
              border: '1px solid #c5d1d9',
              background: '#ffffff',
            }}
          >
            <TextField
              border={false}
              flat
              fullWidth
              multiLine
              noValidation
              value={this.selectedText()}
              hintText="Content..."
              rows={16}
              rowsMax={16}
              label={
                <div style={{ height: 20, minHeight: 20, maxHeight: 20 }}>
                  {labelSelected}
                </div>
              }
              onChange={this.onChangeContent}
            />
          </section>
        </div>

        <hr />

        <section>
          <Actions grow>
            <section
              className={cls.tagsInContext}
              style={{ flex: 1, paddingTop: 0 }}
            >
              <div
                style={{
                  color: '#262a2d',
                  fontWeight: 300,
                  fontSize: 14,
                  marginBottom: 8,
                }}
              >
                Tags applied to question:
              </div>
              <TagSelector
                key={'tag_selector_dialog' + tagsInContext?.length}
                openOnTagClick={false}
                zIndex={1501}
                multiple
                alt
                small
                className={cls.editorTagsSelector}
                popoverClassName={cls.popoverTagsSelector}
                noTagProps={{
                  label: ' ',
                  grey: false,
                  square: true,
                  outline: true,
                  showEmptyLabel: isEmpty,
                  icon: () => (
                    <Icon
                      tip="add new tag"
                      name="plus"
                      style={{ fontSize: 18 }}
                    />
                  ),
                }}
                tagsWithTagGroups={this.props.tagsWithTagGroups}
                selected={selectedTagIds}
                onChange={this.onTaggingChange}
                onPopoverClose={this.onTaggingCommit}
                onRemove={this.onTaggingRemoveSingle}
                popoverProps={{ customHeight: '100%' }}
              />
            </section>
            <Button
              size="l"
              key="cancel"
              label="Close"
              onClick={this.handleClose}
            />
          </Actions>
        </section>
      </Dialog>
    );
  }
}
