import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import uniq from 'ramda/src/uniq';
import activitiesApi from 'src/api/Activities';
import uploaderSecureMappingApi from 'src/api/UploaderSecureMapping';
import {
  Divider,
  Section,
  Container,
  Progress,
  Actions,
  Button,
  Tile,
  Card,
} from 'src/components/IMUI/index';
import history from 'src/historyInstance';
import { getOrgText } from 'src/services/DictionaryService';
import { where } from 'im/api/Query';
import { Icon } from 'im/ui/Icon';
import NestedTree from './components/NestedTree';
import TipInfo from './components/TipInfo';
import cls from './Report.module.css';
import { toLocaleDateTime } from 'src/utils/date';
const cx = classNames.bind(cls);
const getActivitiesStats = (activities) => {
  const sum = (array) => array.reduce((pv, cv) => pv + cv, 0);
  const allActivities = activities?.slice(-3) ?? [];
  const uniqueErrors = uniq(
    allActivities
      .flatMap((a) => a.misc.errors)
      .sort((a, b) => a.line_number - b.line_number)
  );
  return {
    created_rows: sum(allActivities.map((a) => a.misc.created_rows)),
    updated_rows: sum(allActivities.map((a) => a.misc.updated_rows)),
    extra_columns: sum(allActivities.map((a) => a.misc.extra_columns)),
    missing_columns: sum(allActivities.map((a) => a.misc.missing_columns)),
    create_skipped_rows: sum(
      allActivities.map((a) => a.misc.create_skipped_rows)
    ),
    update_skipped_rows: sum(
      allActivities.map((a) => a.misc.update_skipped_rows)
    ),
    failed_to_create_rows: sum(
      allActivities.map((a) => a.misc.failed_to_create_rows)
    ),
    failed_to_update_rows: sum(
      allActivities.map((a) => a.misc.failed_to_update_rows)
    ),
    notImportedRows: [...new Set(uniqueErrors.map((i) => i.line_number))]
      .length,
    errors: uniqueErrors.map((line, i, array) => ({
      ...line,
      new_line: line.line_number !== array[i - 1]?.line_number,
    })),
    success: Boolean(
      allActivities.length > 0 &&
        allActivities?.flatMap((a) => a.misc.status)?.every((a) => a === 'done')
    ),
    completed: Boolean(
      allActivities.length === 3 && activities.length % 3 === 0
    ),
  };
};

@connect(
  (state) => ({
    importUploadMapping: state.importUploadMapping,
    activities: state.activities,
  }),
  {
    getFile: uploaderSecureMappingApi.find,
    getActivities: activitiesApi.findAll,
  }
)
export default class Report extends PureComponent {
  static propTypes = {
    getFile: PropTypes.func,
    getActivities: PropTypes.func,
    match: PropTypes.object,
    activities: PropTypes.object,
    importUploadMapping: PropTypes.object,
  };
  intervalId = 0;
  componentDidMount() {
    this.props.getFile(
      where({ id: this.props.match.params.fileId }).include('user')
    );
    this.getActivities();
    this.setActivityUpdate();
  }
  getActivities() {
    this.props.getActivities(
      where()
        .filter('resource_id_eq', this.props.match.params.fileId)
        .filter('action_eq', 'import')
        .sort('-created_at')
    );
  }
  setActivityUpdate() {
    if (this.intervalId > 0) return;
    window.setTimeout(() => this.getActivities(), 1000);
    this.intervalId = window.setInterval(() => this.getActivities(), 5000);
  }
  componentWillUnmount() {
    window.clearInterval(this.intervalId);
  }

  renderHeader() {
    const { first_name, last_name, email } =
      this.props.importUploadMapping.data.user ?? {};
    const importDate = this.props.importUploadMapping.data?.updated_at;
    const finishDate = this.props.activities?.data?.[0]?.created_at;
    const { completed, success, notImportedRows, created_rows, updated_rows } =
      getActivitiesStats(this.props.activities.data) ?? {};
    const hasFailed =
      completed &&
      !success &&
      notImportedRows > 0 &&
      created_rows === 0 &&
      updated_rows === 0;
    const isSuccess = Boolean(success || (completed && hasFailed === false));

    const getStatusTitle = () => {
      if (isSuccess) return 'Your import was successful!';
      if (hasFailed) return 'Upload failed: errors found';
      return (
        <div>
          <h3>Import in progress</h3>
          <Progress large className="absolute" />
        </div>
      );
    };

    return (
      <div>
        <Container centered>
          <div className={cx(cls.subtitleSoft)}>
            <h1>{getStatusTitle()}</h1>
            <br />
          </div>
        </Container>
        <Container
          horizontal
          className={cx(cls.subtitleSoft, cls.textWithIcon)}
        >
          <span>
            <Icon name="file" />
            {this.props.importUploadMapping.data.file_name}
          </span>
          &emsp;&emsp;
          <span>
            <Icon name="account" />
            imported by{' '}
            {first_name && last_name ? `${first_name} ${last_name}` : email}
            &emsp;
          </span>
        </Container>
        <Container
          horizontal
          className={cx(cls.subtitleSoft, cls.textWithIcon)}
        >
          <Icon name="wait" tip="started at" />
          &nbsp;{toLocaleDateTime(importDate)}&emsp;&emsp;
          {finishDate && (
            <span>
              &emsp;
              <Icon name="check" tip="finished at" />
              &nbsp;{toLocaleDateTime(finishDate)}
            </span>
          )}
        </Container>
      </div>
    );
  }

  renderContent() {
    const allActivities = this.props.activities.data?.slice(-3) ?? [];
    const uniqueErrorParsers = uniq(
      allActivities
        .flatMap((a) => a.misc.parser_error)
        .filter((a) => a && a.length > 1)
    );
    const hasSomeChange = ({
      created_rows,
      updated_rows,
      failed_to_create_rows,
      failed_to_update_rows,
    }) =>
      created_rows +
        updated_rows +
        failed_to_create_rows +
        failed_to_update_rows >
      0;
    const grants =
      allActivities.find((a) => a.client === 'grant_import_job')?.misc ?? {};
    const grantees =
      allActivities.find((a) => a.client === 'grantee_import_job')?.misc ?? {};
    const fundings =
      allActivities.find((a) => a.client === 'funding_import_job')?.misc ?? {};
    const allEmpty = (entity) => {
      return (
        entity.created_rows == 0 &&
        entity.updated_rows == 0 &&
        entity?.errors?.length > 0 &&
        entity?.errors?.every((a) => a.message === "can't be blank")
      );
    };
    const getErrors = (entity) => {
      if (allEmpty(entity)) return [];
      const allErrors =
        entity?.errors?.sort((a, b) => a.line_number - b.line_number) ?? [];
      return allErrors.map((line, i, array) => ({
        ...line,
        new_line: line.line_number !== array[i - 1]?.line_number,
      }));
    };
    const countErrors = (entity) =>
      [...new Set(getErrors(entity).map((i) => i.line_number))].length;
    const getWarning = (entity) => {
      const two = countErrors(entity) > 1;
      return `The following ${countErrors(entity)} row${two ? 's' : ''} ${
        two ? 'were' : 'was'
      } not totally imported. You may edit these rows and import again`;
    };
    const zeroChanges =
      !hasSomeChange(grants) &&
      !hasSomeChange(grantees) &&
      !hasSomeChange(fundings) &&
      countErrors(grants) === 0 &&
      countErrors(grantees) === 0 &&
      countErrors(fundings) === 0;
    const skippedRows =
      grants.update_skipped_rows ??
      0 + grantees.update_skipped_rows ??
      0 + fundings.update_skipped_rows ??
      0 + grants.create_skipped_rows ??
      0 + grantees.create_skipped_rows ??
      0 + fundings.create_skipped_rows ??
      0;
    const allEntitiesEmpty =
      hasSomeChange(grants) &&
      hasSomeChange(grantees) &&
      hasSomeChange(fundings) &&
      allEmpty(grants) &&
      allEmpty(grantees) &&
      allEmpty(fundings);
    return (
      <div>
        {uniqueErrorParsers.length > 0 && (
          <div className={cls.subtitleError}>
            <Divider />
            Problem parsing the CSV file (check encoding):{' '}
            {uniqueErrorParsers.map((error, i) => (
              <div key={i}>
                <b>{error}</b>
              </div>
            ))}
          </div>
        )}
        <Divider />
        {zeroChanges && (
          <Card>
            <Container grow centerHorizontal centered>
              <strong className={cls.subtitleSoft}>
                The import process has finished and no changes have been made.{' '}
              </strong>
              <br />
              Skipped Rows: {skippedRows} rows
            </Container>
          </Card>
        )}

        {allEntitiesEmpty && (
          <Tile size="l">
            <h4>There are no changes to be processed</h4>
          </Tile>
        )}

        <section>
          <div className={cls.textSoft}>
            {hasSomeChange(grants) && !allEmpty(grants) && (
              <Tile size="l">
                <NestedTree
                  title={getOrgText('grants')}
                  created_rows={grants.created_rows}
                  updated_rows={grants.updated_rows}
                  failed_to_create_rows={grants.failed_to_create_rows}
                  failed_to_update_rows={grants.failed_to_update_rows}
                  update_skipped_rows={grants.update_skipped_rows}
                  create_skipped_rows={grants.create_skipped_rows}
                  icon="grants"
                />
              </Tile>
            )}
          </div>

          {countErrors(grants) > 0 && (
            <div className={cls.textSoft} style={{ margin: 16 }}>
              <Divider small />
              <span className={cx(cls.textError, cls.textWithIcon)}>
                <Icon name="warning-triangle" />
                {getWarning(grants)}
              </span>
              {getErrors(grants).map((e, i) => (
                <li className={cx(cls.listItemError)} key={i}>
                  {e.new_line && (
                    <div className={cls.textError}>
                      Row {e.line_number}&ensp;
                    </div>
                  )}
                  <span>
                    <strong>{e.column_name}</strong>&nbsp;
                    <small>{e.message}</small>
                  </span>
                </li>
              ))}
              <Divider small />
            </div>
          )}
        </section>

        <br />

        <section>
          <div className={cls.textSoft}>
            {hasSomeChange(grantees) && !allEmpty(grantees) && (
              <Tile size="l">
                <NestedTree
                  title={getOrgText('grantees')}
                  created_rows={grantees.created_rows}
                  updated_rows={grantees.updated_rows}
                  failed_to_create_rows={grantees.failed_to_create_rows}
                  failed_to_update_rows={grantees.failed_to_update_rows}
                  update_skipped_rows={grantees.update_skipped_rows}
                  create_skipped_rows={grantees.create_skipped_rows}
                  icon="grantee"
                />
              </Tile>
            )}
          </div>

          {countErrors(grantees) > 0 && (
            <div className={cls.textSoft} style={{ margin: 16 }}>
              <Divider small />
              <span className={cx(cls.textError, cls.textWithIcon)}>
                <Icon name="warning-triangle" />
                {getWarning(grantees)}
              </span>
              {getErrors(grantees).map((e, i) => (
                <li className={cx(cls.listItemError)} key={i}>
                  {e.new_line && (
                    <div className={cls.textError}>
                      Row {e.line_number}&ensp;
                    </div>
                  )}
                  <span>
                    <strong>{e.column_name}</strong>&nbsp;
                    <small>{e.message}</small>
                  </span>
                </li>
              ))}
              <Divider small />
            </div>
          )}
        </section>

        <br />

        <section>
          <div className={cls.textSoft}>
            {hasSomeChange(fundings) && !allEmpty(fundings) && (
              <Tile size="l">
                <NestedTree
                  title={getOrgText('fundings')}
                  created_rows={fundings.created_rows}
                  updated_rows={fundings.updated_rows}
                  failed_to_create_rows={fundings.failed_to_create_rows}
                  failed_to_update_rows={fundings.failed_to_update_rows}
                  update_skipped_rows={fundings.update_skipped_rows}
                  create_skipped_rows={fundings.create_skipped_rows}
                  icon="currency"
                />
              </Tile>
            )}

            {countErrors(fundings) > 0 && (
              <div className={cls.textSoft} style={{ margin: 16 }}>
                <Divider small />
                <span className={cx(cls.textError, cls.textWithIcon)}>
                  <Icon name="warning-triangle" />
                  {getWarning(fundings)}
                </span>
                {getErrors(fundings).map((e, i) => (
                  <li className={cx(cls.listItemError)} key={i}>
                    {e.new_line && (
                      <div className={cls.textError}>
                        Row {e.line_number}&ensp;
                      </div>
                    )}
                    <span>
                      <strong>{e.column_name}</strong>&nbsp;
                      <small>{e.message}</small>
                    </span>
                  </li>
                ))}
                <Divider small />
              </div>
            )}
          </div>
        </section>

        <br />

        <Divider />
        <TipInfo />
      </div>
    );
  }

  render() {
    const pending =
      this.props.importUploadMapping.pending?.init ||
      this.props.activities.pending?.init ||
      this.props.importUploadMapping.data?.id !==
        this.props.match.params.fileId;
    if (pending) return <Progress large />;

    return (
      <div>
        <Section noBorder className={cls.limitedWidth}>
          {this.renderHeader()}
          {this.renderContent()}
        </Section>

        <Section type="sticky-footer">
          <Actions>
            <Button
              secondary
              size="l"
              label="Import more"
              onClick={() => history.push('/grants/import')}
            />
          </Actions>
          <Actions>
            <Button
              size="l"
              label={getOrgText('View Grants')}
              onClick={() => history.push('/grants')}
            />
          </Actions>
        </Section>
      </div>
    );
  }
}
