import React from 'react';
import { csvParse } from 'd3-dsv';
import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import {
  getChart,
  saveChart,
  getAxisValueTypes,
  getAxisValues,
  postAxisData,
  cleanupChartsData,
  csvUploaded,
} from 'src/actionCreators/chartsActionCreators';
import ChartSettings from 'src/components/Chart/ChartSettings';
import {
  Actions,
  Button,
  Card,
  CardEmpty,
  CardFooter,
  Container,
  Progress,
  Section,
} from 'src/components/IMUI';
import Page from 'src/components/Page/Page';
import ChartAxisSelector from './components/ChartAxisSelector';
import ChartCalculationTypeSelector from './components/ChartCalculationTypeSelector';
import ChartDataTable from './components/ChartDataTable';
import ChartTypeSelector from './components/ChartTypeSelector';
import CSVUploadButton from './components/CSVUploadButton';
import DataChart from './components/DataChart';
import RenameChartModal from './components/RenameChartModal';
import {
  chartDataToState,
  transformCSVColumnsToValueType,
  transformCsvToChartData,
} from './helpers/transformers';
const SINGLE_CALC_TYPES = ['pie', 'column:stacked', 'bubble', 'heatmap'];
import cls from './EditChart.module.css';
import { Icon } from 'im/ui';
import { DEFAULT_CHART_SETTINGS } from 'src/components/Chart/chartUtils';

const defaultState = {
  showRenameChartModal: false,
  saveOnRenameChartModal: false,
  dataTableVisible: false,
  xAxisTypeConfig: null,
  yAxisTypeConfig: null,
  xAxisAttrConfig: null,
  yAxisAttrConfig: null,
  csv: null,
  rawCSV: '',
  name: '',
  settings: { ...DEFAULT_CHART_SETTINGS },
  chartConfig: null,
  chartType: 'column',
  calculationType: ['grantees_total'],
  initialized: false,
};

@connect(
  pick(['chart', 'chartsAxisData', 'chartsAxisTypes', 'chartsAxisValues']),
  {
    getChart,
    saveChart,
    getAxisValueTypes,
    getAxisValues,
    postAxisData,
    cleanupChartsData,
    csvUploaded,
  }
)
export default class EditChart extends React.PureComponent {
  static propTypes = {
    chart: PropTypes.object,
    chartsAxisData: PropTypes.object,
    chartsAxisTypes: PropTypes.object,
    chartsAxisValues: PropTypes.object,
    match: ReactRouterPropTypes.match,
    history: ReactRouterPropTypes.history,
    getChart: PropTypes.func,
    saveChart: PropTypes.func,
    cleanupChartsData: PropTypes.func,
    getAxisValueTypes: PropTypes.func,
    getAxisValues: PropTypes.func,
    postAxisData: PropTypes.func,
    csvUploaded: PropTypes.func,
  };

  constructor(props) {
    super(props);
    const chart = props.chart?.data ?? {};
    const chartData = chart?.id ? chartDataToState(chart) : {};
    this.state = {
      ...defaultState,
      ...chartData,
      dirty: false,
      initialized: props.match.params.chartId === 'new',
    };

    if (chart.x?.model && chart.x?.model !== 'CSV') {
      props.getAxisValues(
        chart.x.model,
        chart.x.attribute_name,
        props.match.params.projectId
      );
    }
    if (chart.y?.attribute_name && chart.y?.model !== 'CSV') {
      props.getAxisValues(
        chart.y.model,
        chart.y.attribute_name,
        props.match.params.projectId
      );
    }

    this.handleChartConfigChange();
  }

  componentDidMount() {
    this.props.getAxisValueTypes(this.props.match.params.projectId);
    if (this.props.match.params.chartId) {
      this.props.getChart(
        this.props.match.params.chartId,
        this.props.match.params.projectId
      );
    }
  }
  componentWillUnmount() {
    this.props.cleanupChartsData();
  }

  componentWillReceiveProps(nextProps) {
    const nextChart = nextProps.chart;
    let getAxisValuesInProgress = false;

    // change url and view state after creating new chart
    if (nextChart.data.id && this.props.chart?.data.id !== nextChart.data.id) {
      window.setTimeout(
        () =>
          this.props.history.replace(
            `/analysis/${this.props.match.params.projectId}/charts/${nextChart.data.id}`
          ),
        1
      );
    }
    // if id is gone we are creating new chart
    if (!nextChart.data.id && this.props.chart?.data.id) {
      window.setTimeout(
        () =>
          this.props.history.replace({
            pathname: `/analysis/${this.props.match.params.projectId}/charts/new`,
          }),
        1
      );
    }

    if (nextChart.data !== this.props.chart?.data && nextChart.data.x) {
      if (nextChart.data.options?.rawCSV) {
        const csv = csvParse(nextChart.data.options.rawCSV);
        this.handleCSVData(
          nextChart.data,
          nextChart.data.options.rawCSV,
          csv,
          transformCsvToChartData(csv)
        );
      } else {
        this.setState(
          chartDataToState(nextChart.data),
          this.handleChartConfigChange
        );
        // ensure we fetch axis values for x and/or y axis only if axis model and/or attributes changed
        if (
          nextChart.data.x &&
          JSON.stringify(nextChart.data.x) !==
            JSON.stringify(nextProps.chartsAxisData?.meta?.x || {})
        ) {
          getAxisValuesInProgress = true;
          this.props.getAxisValues(
            nextChart.data.x.model,
            nextChart.data.x.attribute_name,
            this.props.match.params.projectId
          );
        }
        if (
          nextChart.data.y?.model &&
          nextChart.data.y?.attribute_name &&
          JSON.stringify(nextChart.data.y) !==
            JSON.stringify(nextProps.chartsAxisData?.meta?.y || {})
        ) {
          getAxisValuesInProgress = true;
          this.props.getAxisValues(
            nextChart.data.y.model,
            nextChart.data.y.attribute_name,
            this.props.match.params.projectId
          );
        }
      }
    }

    // Clean up old chart attribute values that don't exist in the system anymore
    if (
      !this.state.initialized &&
      nextChart.data.id &&
      !nextProps.chartsAxisValues.pending &&
      !getAxisValuesInProgress
    ) {
      const cords = {
        x: nextChart.data.x && {
          model: nextChart.data.x.model,
          attribute_name: nextChart.data.x.attribute_name,
        },
        y: nextChart.data.y && {
          model: nextChart.data.y.model,
          attribute_name: nextChart.data.y.attribute_name,
        },
      };

      if (
        (!cords.x && !cords.y) ||
        (cords.x &&
          (!nextProps.chartsAxisValues.data[cords.x.model] ||
            !nextProps.chartsAxisValues.data[cords.x.model][
              cords.x.attribute_name
            ])) ||
        (cords.y &&
          (!nextProps.chartsAxisValues.data[cords.y.model] ||
            !nextProps.chartsAxisValues.data[cords.y.model][
              cords.y.attribute_name
            ]))
      ) {
        return;
      }

      this.setState(
        {
          initialized: true,
          ...chartDataToState(nextChart.data, nextProps.chartsAxisValues.data),
        },
        this.handleChartConfigChange
      );
    }
  }

  onAxisTypeChanged = (axisName, config) => {
    if (config) {
      this.props.getAxisValues(
        config.model,
        config.attribute_name,
        this.props.match.params.projectId
      );
    }
    this.setState(
      {
        [`${axisName}AxisTypeConfig`]: config,
        [`${axisName}AxisAttrConfig`]: null,
        dirty: true,
      },
      this.handleChartConfigChange
    );
  };

  onToggleDataTable = () =>
    this.setState({ dataTableVisible: !this.state.dataTableVisible });

  onAxisAttrChanged = (axisName, config) =>
    this.setState(
      { [`${axisName}AxisAttrConfig`]: config, dirty: true },
      this.handleChartConfigChange
    );

  onChangeChartType = (ev, type) => {
    ev.stopPropagation();
    const isSingle = SINGLE_CALC_TYPES.includes(type);
    if (isSingle)
      this.setState({
        calculationType: this.state.calculationType.slice(0, 1),
      });
    this.setState({ chartType: type || defaultState.chartType, dirty: true });
  };

  onCalculationTypeChanged = (calculationType) =>
    this.setState({ calculationType, dirty: true });

  onRenameChart = () => this.setState({ showRenameChartModal: true });

  onRenameChartModalClose = () =>
    this.setState({
      saveOnRenameChartModal: false,
      showRenameChartModal: false,
    });

  onRenameChartModalConfirm = (name) =>
    this.setState({ name, dirty: true }, () => {
      if (this.state.saveOnRenameChartModal) {
        this.onSaveChart();
      }
      this.onRenameChartModalClose();
    });

  onSaveChart = () => {
    const { name, rawCSV, settings, chartType, calculationType } = this.state;
    // ask for name if not set
    if (!name) {
      this.setState({ saveOnRenameChartModal: true }, this.onRenameChart);
      return;
    }
    const isXY =
      this.props.chartsAxisData?.meta?.y?.attribute_values.length > 0;
    this.props
      .saveChart(
        {
          name: name || 'test',
          id: this.props.chart.data.id,
          x: this.props.chartsAxisData?.meta?.x,
          ...(isXY
            ? { y: this.props.chartsAxisData?.meta?.y }
            : { y: undefined }),
          options: { rawCSV, chartType, calculationType, settings: settings },
        },
        this.props.match.params.projectId
      )
      .then(() => {
        this.setState({ dirty: false });
      });
  };

  onSwapAxes = () => {
    this.setState(
      {
        xAxisTypeConfig: this.state.yAxisTypeConfig,
        yAxisTypeConfig: this.state.xAxisTypeConfig,
        xAxisAttrConfig: this.state.yAxisAttrConfig,
        yAxisAttrConfig: this.state.xAxisAttrConfig,
        dirty: true,
      },
      this.handleChartConfigChange
    );
  };

  onSettingsChanged = (settings) => this.setState({ settings, dirty: true });

  handleChartConfigChange = () => {
    const postDataForAxis = (axisName) => {
      const attribute_values = this.state[`${axisName}AxisAttrConfig`];
      const type_values = this.state[`${axisName}AxisTypeConfig`];
      if (!attribute_values?.length) return undefined;
      return { ...type_values, attribute_values };
    };

    const postData = { x: postDataForAxis('x'), y: postDataForAxis('y') };
    const differentChart =
      postData.x &&
      JSON.stringify(postData) !=
        JSON.stringify(this.props.chartsAxisData?.meta);
    if (differentChart) {
      this.props.postAxisData(postData, this.props.match.params.projectId);
    }
  };

  handleCSVData = (file, rawCSV, csv, csvChartData) => {
    // handles both actual file upload and restoring csv from save
    this.props.csvUploaded(csvChartData);
    this.setState({
      ...defaultState,
      csv,
      rawCSV,
      settings: file.options?.settings || undefined,
      chartType:
        file.options?.chartType ||
        this.state.chartType ||
        defaultState.chartType,
      calculationType: [transformCSVColumnsToValueType(csv.columns, csv)],
      name: file.name,
    });
  };

  hasData() {
    if (this.state.csv) return true;
    if (!this.props.chartsAxisData.meta?.x?.attribute_name) return false;
    if (!this.state.xAxisTypeConfig?.attribute_name) return false;
    if (
      this.props.chartsAxisData.meta?.x?.model !==
      this.state.xAxisTypeConfig?.model
    )
      return false;
    if (
      this.props.chartsAxisData.meta?.x?.attribute_name !==
      this.state.xAxisTypeConfig?.attribute_name
    )
      return false;
    return true;
  }

  renderChart() {
    if (!this.hasData() && !this.props.chartsAxisData.pending) {
      return (
        <Card grow>
          <CardEmpty
            title="Select data to visualise"
            description="Specify type of data for X, or X and Y axes and select values you are interested in comparing."
          />
        </Card>
      );
    }
    if (
      !this.props.chartsAxisData.data?.length &&
      !this.props.chartsAxisData.pending
    ) {
      return (
        <Card grow>
          <CardEmpty
            title="There is no data to chart for your query"
            description="Specify type of data for X, or X and Y axes and select values you are interested in comparing."
          />
        </Card>
      );
    }
    const currentKey = this.allowMultiple()
      ? 'multiple'
      : this.state.calculationType.join(',');

    return (
      <div>
        <div className={cls.dataChartWrapper}>
          <DataChart
            key={currentKey}
            calculationType={this.state.calculationType}
            chartType={this.state.chartType}
            settings={this.state.settings}
          />
        </div>
        <Container anchor>
          <Icon
            name="table"
            className={cls.toggleDataTableButton}
            onClick={this.onToggleDataTable}
          />
        </Container>
        {this.state.dataTableVisible && (
          <CardFooter style={{ margin: '0px -16px -16px', padding: 20 }}>
            <ChartDataTable
              calculationType={this.state.calculationType}
              chartType={this.state.chartType}
              settings={this.state.settings}
              chartData={this.props.chartsAxisData}
            />
          </CardFooter>
        )}
      </div>
    );
  }
  allowMultiple() {
    return (
      Array.isArray(this.props.chartsAxisData?.data?.[0]) == false &&
      SINGLE_CALC_TYPES.includes(this.state.chartType) == false
    );
  }
  renderTitle() {
    if (!this.props.match.params.chartId) return 'New chart';
    return (
      <h3 onClick={this.onRenameChart}>
        <Icon onClick={() => false} placeTip="bottom" name="edit" tip="Edit" />
        {this.state.name || <i>Set chart name</i>}
      </h3>
    );
  }
  renderHeaderActions() {
    return (
      <Actions nowrap>
        <CSVUploadButton
          accept=".csv"
          label="Upload CSV"
          onFileUploaded={this.handleCSVData}
        />
      </Actions>
    );
  }

  render() {
    const {
      xAxisTypeConfig,
      yAxisTypeConfig,
      xAxisAttrConfig,
      yAxisAttrConfig,
    } = this.state;
    const isNew = !this.props.match.params.chartId;
    const isPending =
      this.props.chartsAxisTypes.pending ||
      (this.props.match.params.chartId && this.props.chart?.pending);
    const isActionable = !isPending && this.hasData();
    const disablePie =
      this.props.chartsAxisData.meta?.y?.attribute_values?.length > 1;

    return (
      <Page
        key={`id-${this.props.match.params.chartId}`}
        pending={isPending}
        back={`/analysis/${this.props.match.params.projectId}/charts/list`}
        className={cls.chartsPage}
        title={this.renderTitle()}
        headerActions={this.renderHeaderActions()}
      >
        <Section className={cls.chartContent} collapsed>
          {!this.state.csv && (
            <ChartAxisSelector
              isNew={isNew}
              xAxisTypeConfig={xAxisTypeConfig}
              yAxisTypeConfig={yAxisTypeConfig}
              xAxisAttrConfig={xAxisAttrConfig}
              yAxisAttrConfig={yAxisAttrConfig}
              onSwapAxes={this.onSwapAxes}
              onAxisTypeChanged={this.onAxisTypeChanged}
              onAxisAttrChanged={this.onAxisAttrChanged}
            />
          )}
        </Section>

        <Section className={cls.chartContent} grow noBorder>
          <Card
            key={`id-${this.props.match.params.chartId}type-${this.state.chartType}`}
            style={{ padding: 16 }}
          >
            <ChartTypeSelector
              chartType={this.state.chartType}
              onChangeChartType={this.onChangeChartType}
              disablePie={disablePie}
            />
            {this.props.chartsAxisData.pending && (
              <Progress large className="absolute" />
            )}
            {this.renderChart()}
          </Card>
        </Section>

        <div style={{ padding: 36 }} />

        <Section type="sticky-footer" className={cls.chartFooter}>
          <Container horizontal>
            <Actions>
              {isActionable && (
                <Icon
                  style={{ paddingRight: 16 }}
                  name="table"
                  onClick={this.onToggleDataTable}
                />
              )}
              <ChartCalculationTypeSelector
                key={`type-${this.state.chartType}`}
                chartType={this.state.chartType}
                isHidden={!isActionable}
                calculationType={this.state.calculationType}
                onCalculationTypeChanged={this.onCalculationTypeChanged}
                allowMultiple={this.allowMultiple()}
              />
            </Actions>

            <Actions>
              <ChartSettings
                config={this.state.chartConfig}
                initSettings={this.state.settings || {}}
                buttonProps={{ disabled: !isActionable }}
                onSettingsChanged={this.onSettingsChanged}
                disabled={!isActionable}
                title={this.state.name}
              />
              <Button
                disabled={!isActionable}
                size="l"
                label="Save"
                onClick={this.onSaveChart}
              />
            </Actions>
          </Container>
        </Section>

        <RenameChartModal
          open={this.state.showRenameChartModal}
          saveChartOnSave={this.state.saveOnRenameChartModal}
          name={this.state.name}
          title={isNew ? 'Set chart name' : 'Change chart name'}
          onClose={this.onRenameChartModalClose}
          onSave={this.onRenameChartModalConfirm}
        />
        <Prompt
          when={this.state.dirty}
          message="You have unsaved changes that will be lost. Are you sure you want to leave?"
        />
      </Page>
    );
  }
}
