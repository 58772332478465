import React, { useState } from 'react';
import { Actions, Button, Container, Section } from 'src/components/IMUI';
import TipInfo from './components/TipInfo';
import ExamplesForAutoCleaning from './components/ExamplesForAutoCleaning';
import ExamplesForAutoFormatting from './components/ExamplesForAutoFormatting';
import ExamplesForAutoMapping from './components/ExamplesForAutoMapping';
import GrantImporter from './components/GrantImporter';
import { where } from 'im/api/Query';
import grantsImporterApi from 'src/api/GrantsImport';
import store from 'src/store';
import {
  objectsToCsv,
  replaceFileNameExtensionWithCsv,
} from './utils/objectsToCsv';
import classNames from 'classnames/bind';
import cls from './UploadCSV.module.css';
const cx = classNames.bind(cls);

const UploadCSV = ({ history, match, title, onUploadSuccess }) => {
  const [isOpened, setIsOpened] = useState(false);
  const [normalizeList, setNormalizeList] = useState(true);

  const onClickUpload = () => setIsOpened(true);
  const onImporterClose = () => setIsOpened(false);

  const onSubmit = (data, file) => {
    const csv = objectsToCsv(data.validData);
    const fileName = replaceFileNameExtensionWithCsv(file.name);
    const fileFromData = new File([csv], fileName, {
      type: 'text/csv',
    });

    store
      .dispatch(
        grantsImporterApi.create(
          where().payloadMultipart({ asset: fileFromData }, 'import_upload')
        )
      )
      .then((response) => {
        onUploadSuccess(response);

        history.push({
          pathname: `${match.path}/validate/${response.id}`,
        });
      });
  };

  return (
    <Container horizontal centered>
      <Section noBorder className={cx(cls.sectionContent, cls.limitedWidth)}>
        <h3 className={cls.title}>{title}</h3>
        <TipInfo />
        <ExamplesForAutoCleaning />
        <ExamplesForAutoFormatting
          normalizeList={normalizeList}
          setNormalizeList={setNormalizeList}
        />
        <ExamplesForAutoMapping />
        <br />
        <br />
      </Section>
      <Section type="sticky-footer">
        <Actions />
        <Actions grid style={{ padding: 14 }}>
          <Button label="Upload" onClick={onClickUpload} />
          {isOpened && (
            <GrantImporter
              autoFormatConfig={{ normalizeList: normalizeList }}
              isOpen={isOpened}
              onClose={onImporterClose}
              onSubmit={onSubmit}
            />
          )}
        </Actions>
        <Actions />
      </Section>
    </Container>
  );
};

export default UploadCSV;
