import EntityApi from '../entities/EntityApi';
import Query from '../Query';

class UpdateQuery {
  actionType = 'updateQuery';

  makeActions = (actionsConfig, actionTypeRoot) => {
    return Object.keys(actionsConfig).reduce((acc, actionName) => {
      acc[actionName] = { ...actionsConfig[actionName] };
      acc[actionName].types[
        this.actionType
      ] = `${actionTypeRoot}/${actionName}/${this.actionType}`;

      return acc;
    }, {});
  };

  action = (type, ...params) => {
    const [query, { doNotUpdateUrl } = {}] = params;
    const { queryParams, meta } =
      query instanceof Query || params.length > 1
        ? EntityApi.getParams(params)
        : { queryParams: query };

    return (dispatch) =>
      dispatch({
        type,
        meta: { ...meta, doNotUpdateUrl },
        payload: { queryParams },
      });
  };
}

export default new UpdateQuery();
