import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';

import Add from './GrantAdd';
import AddSteps from './GrantAddSteps';

export default class GrantAddIndex extends Component {
  static propTypes = {
    match: ReactRouterPropTypes.match,
  };

  render() {
    const { match } = this.props;
    const rootPath = match.path;

    return (
      <Switch>
        <Route exact path={rootPath} component={Add} />
        <Route path={`${rootPath}/:step?`} component={AddSteps} />
        <Redirect from="*" to={rootPath} />
      </Switch>
    );
  }
}
