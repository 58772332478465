import reducerRegistry from 'im/api/registry';
import ResourceCollection from 'im/api/ResourceCollection';

const grantContactsConfig = {
  root: '/v3/contacts',
  type: 'grant_contacts',
  api: {
    allow: ['findAll'],
  },
};

const granteeContactsConfig = {
  root: '/v3/contacts',
  type: 'grantee_contacts',
  api: {
    allow: ['findAll'],
  },
};

export const GrantContacts = new ResourceCollection(
  grantContactsConfig,
  'grantContacts'
);
reducerRegistry.register(GrantContacts);

export const GranteeContacts = new ResourceCollection(
  granteeContactsConfig,
  'granteeContacts'
);
reducerRegistry.register(GranteeContacts);

export default {
  grant: GrantContacts.api,
  grantee: GranteeContacts.api,
};
