import { where } from 'im/api/Query';
import reducerRegistry from 'im/api/registry';
import ResourceCollection from 'im/api/ResourceCollection';

const apiConfig = {
  root: '/v3/project_reports',
  type: 'project_reports',
  api: {
    allow: ['findAll'],
    custom: {
      findAllPerProject: {
        method: 'GET',
        path: where().filter('project_id_eq', ':project_id').toString(),
      },
    },
  },
};

export const ProjectReports = new ResourceCollection(
  apiConfig,
  'projectReportsJsonapi'
);
reducerRegistry.register(ProjectReports);

export default ProjectReports.api;
