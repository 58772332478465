import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import surveyExportApi from 'src/api/SurveyExport';
import surveysListApi from 'src/api/SurveysList';
import { confirm } from 'src/components/ConfirmModal/ConfirmModal';
import ExportConfirmation from 'src/components/ExportConfirmation/ExportConfirmation';
import {
  Button,
  Card,
  CardEmpty,
  Section,
  Table,
  Status,
  SteppedProgress,
  Progress,
  Actions,
  Container,
} from 'src/components/IMUI';
import PaginationIm from 'src/components/IMUI/Pagination/PaginationIm';
import Page from 'src/components/Page/Page';
import colors from 'src/css/constants.json';
import { where } from 'im/api/Query';
import { Icon } from 'im/ui/Icon';
import { canManageSurvey } from 'src/userStorage';
import { toLocaleDate } from 'src/utils/date';
import TableComposition from 'src/components/IMUI/Tables/TableComposition';
import emptySurveys from 'src/static/empty_survey.png';

@connect(pick(['surveysList']), {
  surveysFindAll: surveysListApi.findAll,
  destroySurvey: surveysListApi.destroy,
  updateQuery: surveysListApi.findAll.updateQuery,
  exportSurveys: surveyExportApi.all,
})
export default class SurveysList extends React.PureComponent {
  static propTypes = {
    location: PropTypes.object,
    surveysList: PropTypes.object,
    surveysFindAll: PropTypes.func.isRequired,
    destroySurvey: PropTypes.func.isRequired,
    updateQuery: PropTypes.func.isRequired,
    exportSurveys: PropTypes.func.isRequired,
    match: ReactRouterPropTypes.match,
    history: ReactRouterPropTypes.history,
  };

  state = { exportModalShow: false };

  componentDidMount() {
    this.props.surveysFindAll(
      where()
        .filter('project_id_eq', this.props.match.params.projectId)
        .sort('-created_at')
        .fromString(this.props.location.search)
        .pending('init')
        .paginate({ size: 1000 })
    );
  }

  onCreateSurveyClick = () => {
    this.props.history.push({
      pathname: `/analysis/${this.props.match.params.projectId}/surveys/new`,
    });
  };

  onEditSurveyClick = (survey) => {
    this.props.history.push({
      pathname: `/analysis/${this.props.match.params.projectId}/surveys/${survey.id}/design`,
    });
  };

  onPreviewSurveyClick = (survey) => {
    this.props.history.push({
      pathname: `/analysis/${this.props.match.params.projectId}/surveys/${survey.id}/preview`,
    });
  };

  onSurveyResultsClick = (survey) => {
    this.props.history.push({
      pathname: `/analysis/${this.props.match.params.projectId}/surveys/${survey.id}/summary`,
    });
  };

  onDeleteSurveyClick = (survey) => {
    confirm(
      'Delete survey',
      'Are you sure that you want to delete this survey?',
      true
    ).then(
      () => this.props.destroySurvey(where({ id: survey.id })),
      () => void 0
    );
  };

  onPaginationChange = ({ page, per_page }) => {
    const query = where().paginate({ number: page, size: per_page });
    this.props.updateQuery(query);
  };

  onExportSurveys = () => {
    this.props
      .exportSurveys({ project_id: this.props.match.params.projectId })
      .then(() => this.setState({ exportModalShow: true }));
  };

  onCloseExportModal = () => {
    this.setState({ exportModalShow: false });
  };

  onSort = (sortKey) => {
    this.props.updateQuery(where().sort(sortKey));
  };

  renderStatus = (status) => {
    switch (status) {
      case 'draft':
        return <Status disabled />;
      case 'open':
        return <Status tip="survey open" updated />;
      case 'closed':
        return <Status tip="survey finished" done />;
      default:
        return null;
    }
  };

  retrieveSurveyUrl = (survey) => {
    if (survey?.meta?.finished_surveys_count > 0)
      return `/analysis/${this.props.match.params.projectId}/surveys/${survey.id}/responses`;
    //Make redirect even if there's only started surveys
    if (survey?.meta?.started_surveys_count > 0)
      return `/analysis/${this.props.match.params.projectId}/surveys/${survey.id}/responses`;
    if (!survey?.public && survey.meta.sent_surveys_count > 0)
      return `/analysis/${this.props.match.params.projectId}/surveys/${survey.id}/responses`;
    if (survey?.survey_questions?.length > 0)
      return `/analysis/${this.props.match.params.projectId}/surveys/${survey.id}/share`;
    return `/analysis/${this.props.match.params.projectId}/surveys/${survey.id}/design`;
  };

  renderSurveys() {
    const isPending =
      this.props.surveysList.pending.init || this.props.surveysList.pending.ui;
    if (this.props.surveysList.data.length == 0 && !isPending) {
      return (
        <Section>
          <Container centerHorizontal horizontal>
            <div>
              <img
                src={emptySurveys}
                alt="emptySurveys"
                style={{
                  width: 400,
                  height: 280,
                  marginTop: 24,
                  marginBottom: 140,
                }}
              />
            </div>
          </Container>
          <CardEmpty
            large
            title="No surveys to display"
            description="please create a new one"
          >
            <Button
              size="l"
              disabled={!canManageSurvey()}
              label="Create survey"
              secondary
              onClick={this.onCreateSurveyClick}
            />
          </CardEmpty>
        </Section>
      );
    }
    return (
      <TableComposition>
        {isPending && <Progress large className="absolute" />}
        <Table.Head
          jsonapi
          sortBy={this.props.surveysList.meta.getAction?.queryParams?.sort}
          onSortChange={this.onSort}
        >
          <Table.HCell width={46} />
          <Table.HCell text="Survey name" sortBy="title" />
          <Table.HCell textAlign="center" width={92} text="Recipients" />
          <Table.HCell textAlign="center" width={92} text="Notified" />
          <Table.HCell textAlign="center" width={92} text="Respondents" />
          <Table.HCell textAlign="center" width={92} text="Finished" />
          <Table.HCell textAlign="center" width={92} text="Unfinished" />
          <Table.HCell textAlign="center" width={92} text="" />
          <Table.HCell
            textAlign="center"
            width={92}
            text="Created"
            sortBy="created_at"
          />
          <Table.HCell width={120} />
        </Table.Head>
        <Table.Body>
          {this.props.surveysList.data.map((survey) => (
            <Table.Row key={survey.id}>
              <Table.Cell actions>
                {this.renderStatus(survey.status)}
              </Table.Cell>
              <Table.Cell>
                <Link
                  style={{ color: colors.dark }}
                  to={this.retrieveSurveyUrl(survey)}
                >
                  {survey.title}
                </Link>
              </Table.Cell>
              <Table.Cell text={survey.meta.grantee_count} textAlign="center" />
              <Table.Cell
                text={survey.meta.sent_surveys_count}
                textAlign="center"
              />
              <Table.Cell
                text={survey.meta.respondents_count}
                textAlign="center"
                onClick={() => this.onSurveyResultsClick(survey)}
              />
              <Table.Cell
                text={survey.meta.finished_surveys_count}
                textAlign="center"
                onClick={() => this.onSurveyResultsClick(survey)}
              />
              <Table.Cell
                text={
                  survey.meta.unfinished_surveys_count ??
                  survey.meta.respondents_count -
                    survey.meta.finished_surveys_count
                }
                textAlign="center"
                onClick={() => this.onSurveyResultsClick(survey)}
              />
              <Table.Cell textAlign="center">
                <SteppedProgress
                  noMargins
                  value={survey.meta.finished_surveys_count}
                  max={survey.meta.grantee_count}
                />
              </Table.Cell>
              <Table.Cell
                textAlign="center"
                text={toLocaleDate(survey.created_at)}
              />
              <Table.Cell actions icons>
                {canManageSurvey() && (
                  <Icon
                    name="edit"
                    tip="Edit"
                    color={'#272e42'}
                    onClick={() => this.onEditSurveyClick(survey)}
                  />
                )}
                <Icon
                  name="view"
                  tip="Preview"
                  color={'#272e42'}
                  onClick={() => this.onPreviewSurveyClick(survey)}
                />
                <Icon
                  name="bars"
                  tip="Results"
                  color={'#272e42'}
                  onClick={() => this.onSurveyResultsClick(survey)}
                />
                {canManageSurvey() && (
                  <Icon
                    name="trash"
                    tip="Remove"
                    color={'#272e42'}
                    onClick={() => this.onDeleteSurveyClick(survey)}
                  />
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </TableComposition>
    );
  }

  render() {
    return (
      <Page
        title="Surveys"
        helpAppcue="-LHsB6_Vq5UhFz0O-T39"
        pending={this.props.surveysList.pending.init == true}
      >
        <Section type="sub-header" collapsed>
          <Container horizontal nowrap>
            <Actions />
            <Actions>
              <Button
                disabled={!canManageSurvey()}
                size="l"
                label="Create survey"
                onClick={this.onCreateSurveyClick}
              />
              <Button
                disabled={!this.props.surveysList.data.length}
                secondary
                size="l"
                label="Export"
                onClick={this.onExportSurveys}
              />
            </Actions>
          </Container>
        </Section>
        <Section>
          <Card>{this.renderSurveys()}</Card>
          <PaginationIm
            {...(this.props.surveysList.links.meta || {})}
            compact
            label="surveys"
            items_label="surveys"
            onChange={this.onPaginationChange}
          />
        </Section>

        <ExportConfirmation
          open={this.state.exportModalShow}
          onRequestClose={this.onCloseExportModal}
        />
      </Page>
    );
  }
}
