import React from 'react';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import PropTypes from 'prop-types';
import ChartTypeIcon from 'src/pages/App/Analysis/Charts/components/ChartTypeIcon';
import chartTypes from './chartTypes';
import cls from './ChartTypeSelector.module.css';

export default class ChartTypeSelector extends React.PureComponent {
  static propTypes = {
    chartType: ChartTypeIcon.propTypes.type,
    onChangeChartType: PropTypes.func.isRequired,
    disablePie: PropTypes.bool.isRequired,
  };

  render() {
    return (
      <div className={cls.chartTypeSelector}>
        <RadioButtonGroup
          name="chartType"
          onChange={this.props.onChangeChartType}
          value={this.props.chartType}
          defaultSelected={this.props.chartType}
        >
          {chartTypes.map(({ value, label }) => (
            <RadioButton
              key={value}
              value={value}
              label={label}
              disabled={value === 'pie' && this.props.disablePie}
              checkedIcon={<ChartTypeIcon type={value} active />}
              uncheckedIcon={<ChartTypeIcon type={value} />}
              style={{ display: 'inline-block', width: 'auto' }}
            />
          ))}
        </RadioButtonGroup>
      </div>
    );
  }
}
