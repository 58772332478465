import {
  projectDictionarySet,
  projectDictionaryClear,
  organizationDictionarySet,
} from 'src/actionCreators/dictionaryActionCreators';
import { OrganizationCurrent, Organization } from 'src/api/Organization';
import {
  PROJECT__GET_INIT_SUCCESS,
  PROJECT__UPDATE_SUCCESS,
  PROJECT__DELETE_SUCCESS,
} from 'src/constants';
import Appcues from 'src/services/AppcuesService';
import dictionaryService, {
  DictionaryService,
} from 'src/services/DictionaryService';

export default function dictionaryEffects(_state, action, dispatch) {
  switch (action.type) {
    case PROJECT__GET_INIT_SUCCESS: {
      dictionaryService.setDictionary({
        project: action.payload.data?.dictionary || {},
      });
      return dispatch(
        projectDictionarySet(action.payload.data?.dictionary || {})
      );
    }

    case PROJECT__UPDATE_SUCCESS:
      dictionaryService.setDictionary({
        project: action.payload.data?.dictionary || {},
      });
      return dispatch(
        projectDictionarySet(action.payload.data?.dictionary || {})
      );

    case PROJECT__DELETE_SUCCESS:
      dictionaryService.setDictionary({ project: {} });
      return dispatch(projectDictionaryClear());

    case OrganizationCurrent.actionTypes.find.success: {
      dictionaryService.setDictionary({
        organization: action.payload.data?.dictionary || {},
      });
      _state.user.data.id &&
        Appcues.identify(_state.user.data.id, {
          organizationDictGrant:
            action.payload.data?.dictionary?.grant || 'Grant',
          organizationDictGrantee:
            action.payload.data?.dictionary?.grantee || 'Grantee',
          organizationDictGrantPlural:
            DictionaryService.getPluralForm(
              action.payload.data?.dictionary?.grant
            ) || 'Grants',
          organizationDictGranteePlural:
            DictionaryService.getPluralForm(
              action.payload.data?.dictionary?.grantee
            ) || 'Grantees',
        });
      return dispatch(
        organizationDictionarySet(action.payload.data?.dictionary || {})
      );
    }

    case Organization.actionTypes.put.success: {
      if (action.payload.data?.id !== _state.organizationCurrent.data?.id)
        return;
      dictionaryService.setDictionary({
        organization: action.payload.data?.dictionary || {},
      });
      return dispatch(
        organizationDictionarySet(action.payload.data?.dictionary || {})
      );
    }

    default:
      return null;
  }
}
