import PropTypes from 'prop-types';
import isNil from 'ramda/src/isNil';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formValueSelector, arrayPush, submit, arraySplice } from 'redux-form';

import { Button, Dialog } from 'src/components/IMUI/index';
import LocationInputs from 'src/components/Location/LocationInputs';
import { getOrgText } from 'src/services/DictionaryService';
import { canManageGrants } from 'src/userStorage';

const FIELDS = {
  countryCode: { name: 'country', required: true },
  state: { name: 'state' },
  city: { name: 'city', required: true },
  region: { name: 'region' },
};

@connect(
  (state, ownProps) => ({
    locationsForm: formValueSelector(ownProps.formName)(state, 'locations'),
  }),
  {
    locationsPush: arrayPush,
    locationsSplice: arraySplice,
    submitForm: submit,
  }
)
export class GrantLocationDialog extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
    locationEditIndex: PropTypes.number,
    onLocationAdd: PropTypes.func,
    onLocationEdit: PropTypes.func,

    formName: PropTypes.string.isRequired,
    locationsForm: PropTypes.array,
    locationsPush: PropTypes.func,
    locationsSplice: PropTypes.func,
    submitForm: PropTypes.func,
  };

  onRequestLocationSubmit = () => {
    const { formName, submitForm } = this.props;
    submitForm(`${formName}-locations`);
  };

  onEdit = (data) => {
    const { formName, locationEditIndex } = this.props;

    const promise = data.id
      ? this.props.onLocationEdit(data)
      : Promise.resolve();
    return promise.then(() =>
      this.props.locationsSplice(
        formName,
        'locations',
        locationEditIndex,
        1,
        data
      )
    );
  };

  onAdd = (data) => {
    const { formName } = this.props;

    const promise = this.props.onLocationAdd
      ? this.props.onLocationAdd(data)
      : Promise.resolve();
    return promise.then(() =>
      this.props.locationsPush(formName, 'locations', data)
    );
  };

  onSubmitLocation = (data) => {
    const { locationEditIndex } = this.props;
    const closeModal = this.props.onClose;
    let promise;

    if (!isNil(locationEditIndex)) {
      promise = this.onEdit(data);
    } else {
      promise = this.onAdd(data);
    }

    promise.then(closeModal);
  };

  renderLocation = () => {
    const { locationEditIndex, locationsForm, formName } = this.props;
    const editValues = isNil(locationEditIndex)
      ? {}
      : locationsForm[locationEditIndex];

    return (
      <LocationInputs
        form={`${formName}-locations`}
        isInDialog
        fields={FIELDS}
        editValues={editValues}
        onSubmitAndValid={this.onSubmitLocation}
      />
    );
  };

  render() {
    const { isOpen } = this.props;

    return (
      <Dialog
        title={getOrgText('Grant Location')}
        onRequestClose={this.props.onClose}
        open={isOpen}
        leftActions={
          <Button
            size="l"
            key="cancel"
            negative
            label="Cancel"
            onClick={this.props.onClose}
          />
        }
        rightActions={
          <Button
            disabled={!canManageGrants()}
            size="l"
            key="save"
            label="Save"
            onClick={this.onRequestLocationSubmit}
          />
        }
      >
        {this.renderLocation()}
      </Dialog>
    );
  }
}

export default GrantLocationDialog;
