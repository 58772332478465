import {
  AsYouType,
  getCountryCallingCode,
  isValidNumber,
  parseNumber,
  formatNumber,
} from 'libphonenumber-js';
import PropTypes from 'prop-types';
import React from 'react';
import { Container } from '../../Layout/Container';
import { Label } from '../base';
import { TextField } from '../TextField';
import CountryCodesDropdown from './CountryCodesDropdown';
import cls from './PhoneInput.module.css';

/*
https://github.com/googlei18n/libphonenumber/blob/master/FAQ.md
For example, when calling a mobile line from a fixed line in Argentina, you need to dial 15 before the subscriber
number,or 9 if you're calling from another country. Without these additional digits, your call may not connect at all!

Similarly, Mexico has different mobile prefixes needed when calling from a fixed line such as 044 when calling locally,
045 when calling from another state, and 1 when dialing from another country.
 */
const COUNTRY_CODES_INTERNATIONAL_FORCED = ['MX', 'AR'];

const getShortPhone = (input, defaultCountry) => {
  if (!input) {
    return '';
  }

  const parsedNumber = parseNumber(input, { defaultCountry, extended: true });
  return Object.keys(parsedNumber).length > 0 ? parsedNumber.phone : input;
};

const formatRawFullNumber = (phone, defaultCountry) => {
  if (!phone) {
    return '';
  }
  const nationalNumber = getShortPhone(phone, defaultCountry);
  const parsedNumber = parseNumber(nationalNumber, {
    defaultCountry,
    extended: true,
  });
  return Object.keys(parsedNumber).length > 0
    ? formatNumber(parsedNumber, 'INTERNATIONAL', { extended: true }).replace(
        /\s/g,
        ''
      )
    : phone;
};

const getCallingCodeCountry = (code) => {
  let twoDigit = null;
  try {
    twoDigit = getCountryCallingCode(code);
  } catch (e) {
    if (!e) console.info(e);
  }
  return twoDigit;
};
const formatAsYouType = (input, countryCode) => {
  if (!input) {
    return '';
  }
  const isInternationalForced =
    COUNTRY_CODES_INTERNATIONAL_FORCED.includes(countryCode);
  const toDisplayNumber = formatNumber(
    { country: countryCode, phone: getShortPhone(input, countryCode) },
    isInternationalForced ? 'INTERNATIONAL' : 'NATIONAL'
  );
  if (
    !toDisplayNumber ||
    toDisplayNumber === `+${getCallingCodeCountry(countryCode)}`
  ) {
    return input;
  }
  return isInternationalForced
    ? toDisplayNumber.replace(`+${getCallingCodeCountry(countryCode)}`, '')
    : toDisplayNumber;
};

export class PhoneInput extends React.PureComponent {
  static propTypes = {
    ...TextField.propTypes,
    countryCode: PropTypes.string,
    phone: PropTypes.string,
    onDropdownChange: PropTypes.func,
  };
  static defaultProps = { onDropdownChange: () => void 0 };
  static validate(phone, countryCode) {
    return phone && isValidNumber(phone, countryCode);
  }
  static getCountryCode(phone) {
    if (!phone) return null;
    const ayt = new AsYouType();
    ayt.input(phone);
    return ayt.country;
  }
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
      phone: formatRawFullNumber(
        props.input ? props.input.value : props.phone,
        props.countryCode
      ),
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.input && nextProps.phone !== this.state.phone) {
      this.setState({
        phone: formatRawFullNumber(nextProps.phone, nextProps.countryCode),
      });
    }
    if (nextProps.input && nextProps.input.value !== this.state.phone) {
      this.setState({
        phone: formatRawFullNumber(
          nextProps.input.value,
          nextProps.countryCode
        ),
      });
    }
  }
  onDropdownOpen = () => {
    this.setState({ dropdownOpen: true });
  };
  onDropdownClose = () => {
    this.setState({ dropdownOpen: false });
  };
  onDropdownChange = (countryCode) => {
    const phone = formatRawFullNumber(this.state.phone, countryCode);
    this.props.input?.onChange?.(phone);
    this.setState({ phone, countryCode });
    this.props.onDropdownChange(countryCode);
  };

  onChange = (phone) => {
    let formattedPhone = phone;
    const detectedCountryCode =
      phone.startsWith('+') && phone.length >= 3
        ? PhoneInput.getCountryCode(phone)
        : null;
    if (detectedCountryCode && detectedCountryCode !== this.state.countryCode) {
      // If a different country code is detected, update it via onDropdownChange
      this.onDropdownChange(detectedCountryCode);
    } else {
      // Otherwise, format the phone number with the current state's country code
      formattedPhone = formatRawFullNumber(phone, this.state.countryCode);
      this.setState({ phone: formattedPhone });
      this.props.input?.onChange?.(formattedPhone);
    }
  };

  onBlur = () => {
    this.props.input?.onBlur?.(this.state.phone);
  };

  render() {
    const { label, input, meta, error, name, hintText, countryCode } =
      this.props;
    return (
      <Container>
        <Label label={label} />

        <Container horizontal nowrap centered>
          <CountryCodesDropdown
            className={cls.phoneInputDropdown}
            countryCode={countryCode}
            dropdownOpen={this.state.dropdownOpen}
            onRequestOpen={this.onDropdownOpen}
            onRequestClose={this.onDropdownClose}
            onChange={this.onDropdownChange}
          />

          <TextField
            {...input}
            fullWidth
            clearable
            type="tel"
            name={name}
            meta={meta}
            error={error}
            hintText={hintText || 'Enter number'}
            value={formatAsYouType(this.state.phone, countryCode)}
            onChange={this.onChange}
            onBlur={this.onBlur}
          />
        </Container>
      </Container>
    );
  }
}
