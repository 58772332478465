import classNames from 'classnames/bind';
import MUIDialog from 'material-ui/Dialog';
import PropTypes from 'prop-types';
import React from 'react';
import { Provider } from 'react-redux';
import { Actions } from 'src/components/IMUI';
import MuiWrapper from 'src/components/MuiWrapper/MuiWrapper';
import store from 'src/store';
import cls from './Dialog.module.css';
const cx = classNames.bind(cls);

export class Dialog extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
    title: PropTypes.node,
    leftActions: PropTypes.node,
    rightActions: PropTypes.node,
    small: PropTypes.bool,
    large: PropTypes.bool,
    extraLarge: PropTypes.bool,
    light: PropTypes.bool,
    modal: PropTypes.bool,
    open: PropTypes.bool,
    zIndex: PropTypes.number,
    onRequestClose: PropTypes.func,
    showScrollbar: PropTypes.bool,
    bodyStyle: PropTypes.object,
    style: PropTypes.object,
    autoScrollBodyContent: PropTypes.bool,
    contentStyle: PropTypes.object,
    forceTop: PropTypes.bool,
  };

  static defaultProps = { zIndex: 1400, autoScrollBodyContent: false };
  constructor(props) {
    super(props);
    this.state = { dialogInitialized: props.open };
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.dialogInitialized && nextProps.open) {
      this.setState({ dialogInitialized: true });
    }
  }
  renderActions() {
    if (!this.props.rightActions && !this.props.leftActions) return null;
    return (
      <div>
        <Actions>{this.props.leftActions}</Actions>
        <Actions>{this.props.rightActions}</Actions>
      </div>
    );
  }

  render() {
    if (!this.state.dialogInitialized) {
      return null;
    }

    return (
      <MuiWrapper>
        <MUIDialog
          style={{ ...this.props.style, zIndex: this.props.zIndex }}
          overlayClassName={cx('imui-dialog-overlay')}
          contentClassName={cx('imui-dialog', {
            'imui-dialog-small': this.props.small,
            'imui-dialog-large': this.props.large,
            'imui-dialog-extra-large': this.props.extraLarge,
            'imui-dialog-light': this.props.light,
            'imui-dialog-empty-body': !this.props.children,
            'imui-dialog-force-top': this.props.forceTop,
          })}
          titleClassName={cx('imui-dialog-title-container', {
            'imui-dialog-title-container-raw':
              typeof this.props.title === 'string',
          })}
          bodyClassName={cx('imui-dialog-body-container', {
            'imui-dialog-body-container-show-scrollbar':
              this.props.showScrollbar,
          })}
          bodyStyle={this.props.bodyStyle}
          contentStyle={this.props.contentStyle}
          actionsContainerClassName={cx('imui-dialog-actions-container')}
          actions={this.renderActions()}
          title={this.props.title ? <div>{this.props.title}</div> : null}
          modal={this.props.modal}
          open={this.props.open || false}
          onRequestClose={this.props.onRequestClose}
          autoScrollBodyContent={this.props.autoScrollBodyContent}
          repositionOnUpdate={true}
        >
          <Provider store={store}>{this.props.children}</Provider>
        </MUIDialog>
      </MuiWrapper>
    );
  }
}
