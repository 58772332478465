import PropTypes from 'prop-types';
import React from 'react';

import { Progress } from '../Progress/Progress';

import cls from './SelectPopoverLight.module.css';

const withControllableSupport = (WrappedComponent) => {
  class SelectPopoverLightControllable extends React.Component {
    static propTypes = {
      ...WrappedComponent.propTypes,

      items: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
      itemsConfig: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
      hasMoreItems: PropTypes.bool,
      selectedItems: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.string,
        PropTypes.number,
      ]),
      customValueEnabled: PropTypes.bool,
      pending: PropTypes.bool,

      onSelect: PropTypes.func,
      onOpen: PropTypes.func,
      onClose: PropTypes.func,
      renderOption: PropTypes.func,
      renderSelected: PropTypes.func,
      renderItems: PropTypes.func,
    };

    static defaultProps = {
      onOpen: () => void 0,
      onClose: () => void 0,
      search: true,
      popoverProps: {},
    };

    // Copy needed to overcome HOC's overwrite
    static Actions = WrappedComponent.Actions;
    static ActionItem = WrappedComponent.ActionItem;
    static Menu = WrappedComponent.Menu;
    static MenuItem = WrappedComponent.MenuItem;
    static Content = WrappedComponent.Content;
    static ContentActions = WrappedComponent.ContentActions;
    static Divider = WrappedComponent.Divider;

    constructor(props) {
      super(props);

      const selectedItems = Array.isArray(props.selectedItems)
        ? props.selectedItems
        : [props.selectedItems];
      this.state = {
        selectedItems,
        searchText: props.searchText,
        open: false,
      };
    }

    componentWillReceiveProps(nextProps) {
      if (
        nextProps.selectedItems !== this.props.selectedItems &&
        nextProps.selectedItems !== this.state.selectedItems
      ) {
        this.setState({
          selectedItems: Array.isArray(nextProps.selectedItems)
            ? nextProps.selectedItems
            : [nextProps.selectedItems],
        });
      }

      if (
        nextProps.open !== this.props.open &&
        nextProps.open !== this.state.open
      ) {
        this.setState({ open: nextProps.open });
      }
    }

    getSelectedItemAsSearchText(
      popoverProps,
      currentValue,
      items,
      itemsConfig
    ) {
      if (!popoverProps.selectedItemAsSearchText || !currentValue) return '';
      if (!itemsConfig) return currentValue;

      const itemsArray = Array.isArray(items)
        ? items
        : Object.keys(items || []);
      const itemFound = itemsArray.find(
        (el) =>
          (items[el] || el || {})[itemsConfig.value] === currentValue.toString()
      );
      return itemFound ? itemFound[itemsConfig.text] : '';
    }

    handlePopoverOpen = () => {
      this.setState({ open: true });
      this.props.onOpen();
    };

    handlePopoverClose = ({ skipCustomValue } = {}) => {
      const { customValueEnabled } = this.props;

      if (customValueEnabled && !skipCustomValue) {
        this.handleCustomValue();
      }

      this.setState({ open: false });
      this.props.onClose();
    };

    handleSearchTextChange = (searchText) => {
      const { popoverProps, customValueEnabled } = this.props;

      if (popoverProps.onSearchTextChange)
        popoverProps.onSearchTextChange(searchText);
      if (!customValueEnabled) return;

      this.setState({ searchText });
    };

    handleCustomValue = () => {
      const { searchText, selectedItems } = this.state;

      if (selectedItems.indexOf(searchText) > -1) return;
      this.props.onSelect(searchText);
    };

    handleSelect = (value) => {
      const { open } = this.state;

      // TODO: Handle selecting multiple items
      this.setState({ selectedItems: [value] }, () => {
        this.props.onSelect(value);
        if (open) {
          this.handlePopoverClose({ skipCustomValue: true });
        }
      });
    };

    renderItems = () => {
      const { items, itemsConfig, renderOption } = this.props;
      const itemsArray = Array.isArray(items)
        ? items
        : Object.keys(items || []);
      const getPrimaryText = (item) => (!renderOption ? item : undefined);

      return itemsArray.map((el, index) => (
        <WrappedComponent.MenuItem
          key={index}
          primaryText={getPrimaryText(
            !itemsConfig ? el : (items[el] || el || {})[itemsConfig.text]
          )}
          onClick={() =>
            this.handleSelect(
              !itemsConfig ? el : (items[el] || el || {})[itemsConfig.value]
            )
          }
        >
          {renderOption && renderOption(el)}
        </WrappedComponent.MenuItem>
      ));
    };

    renderChildren = (search) => {
      const { renderItems } = this.props;

      return (
        <WrappedComponent.Menu>
          {renderItems ? renderItems(search) : this.renderItems()}
        </WrappedComponent.Menu>
      );
    };

    render() {
      const {
        label,
        labelHint,
        popoverProps,
        flat,
        border,
        search,
        pending,
        hasMoreItems,
        disabled,
        renderSelected,
        items,
        itemsConfig,
        fullWidth,
        borderDark,
        small,
      } = this.props;
      const { searchText = '', selectedItems, open } = this.state;
      const currentValue = label || selectedItems[0];

      return (
        <WrappedComponent
          fullWidth={fullWidth}
          open={open}
          label={currentValue}
          labelHint={labelHint}
          flat={flat}
          border={border}
          borderDark={borderDark}
          small={small}
          disabled={disabled}
          onOpen={this.handlePopoverOpen}
          onClose={this.handlePopoverClose}
          renderChildren={this.renderChildren}
          renderSelected={renderSelected}
          popoverProps={{
            ...popoverProps,
            search,
            searchText:
              searchText ||
              this.getSelectedItemAsSearchText(
                popoverProps,
                currentValue,
                items,
                itemsConfig
              ),
            onSearchTextChange: this.handleSearchTextChange,
          }}
        >
          {pending && (
            <Progress size={20} className={cls.selectPopoverLightProgress} />
          )}
          {pending && <div className={cls.selectPopoverLightOverlay} />}
          {!!hasMoreItems && !!searchText.length && (
            <span className={cls.selectPopoverLightTip}>
              Type in to get more results
            </span>
          )}
        </WrappedComponent>
      );
    }
  }

  return SelectPopoverLightControllable;
};

export default withControllableSupport;
