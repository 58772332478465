import pick from 'ramda/src/pick';
import prop from 'ramda/src/prop';
import { v4 as uuidv4 } from 'uuid';
import { OBJECT_VALUE_QUESTIONS } from 'src/data/questionTypes';

const isNotChoiceOrMatrix = (q) =>
  Boolean(!q.choice_values && !q.matrix_values?.rows?.length);

const toSurveyQuestionFromApi = (q) => {
  const collectAllTags = (meta) =>
    !isNotChoiceOrMatrix(meta)
      ? (meta.matrix_values?.rows?.length > 0
          ? meta.matrix_values.rows
          : meta.choice_values
        ).reduce(
          (acc, item) => (item.tag_id ? acc.concat([item.tag_id]) : acc),
          []
        )
      : [];
  const uniquefy = (options) =>
    (options || []).map((o) => ({ id: uuidv4(), ...o }));

  const metadata = q.metadata || {};
  return {
    id: q.id,
    server_id: q.id,
    question: q.title,
    i18n: q.i18n || {},
    description: q.description,
    type: q.question_type,
    options: uniquefy(metadata.choice_values),
    required: q.is_required,
    tags: q.tag_ids?.length ? q.tag_ids : collectAllTags(metadata),
    multiTags: q.tag_type === 'multiple',
    position: q.position,
    settings: metadata.settings || {},
    conditional_logic: metadata.conditional_logic || [],

    currency: metadata.currency,
    hasOther: metadata.has_other,
    other_tag_id: metadata.other_tag_id,
    has_attachment: metadata.has_attachment,
    positiveOnly: metadata.positive_only,
    is_respondent: metadata.is_respondent,
    maxSelections: metadata.max_selections,
    matrixQuestionType: metadata.matrix_question_type,
    matrixReachType: metadata.matrix_reach_type,
    matrixValues: uniquefy(metadata.matrix_values?.rows),
  };
};

export const fromQuestionsToLayout = (questions) =>
  questions.map((q) =>
    q.isLayout
      ? {
          ...q,
          i18n: q.i18n || {},
          conditional_logic: q.conditional_logic || [],
          has_attachment: q.has_attachment,
        }
      : {
          ...{},
          i18n: q.i18n || {},
          conditional_logic: q.conditional_logic || [],
          has_attachment: q.has_attachment,
          question_id: q.id,
          type: 'question',
        }
  );

const findIndex = (arr, i) =>
  i - arr.slice(0, i + 1).filter((el) => el.type !== 'question').length;

export const toSurveyFromApi = (s) => {
  const layout = s.layout.map((l) => ({
    i18n: {},
    conditional_logic: [],
    ...l,
  }));
  const surveyQuestions =
    s.survey_questions
      ?.map(toSurveyQuestionFromApi)
      .sort((q1, q2) => q1.position - q2.position)
      .map((q) => ({ ...q, order: undefined })) || [];

  return {
    id: s.id,
    uid: s.uid,
    logos_urls: s.logos_urls,
    name: s.title,
    period: s.period,
    deadline: s.deadline,
    anonymous: s.anonymous,
    organization_logo: s.organization_logo,
    question_counter: s.question_counter,
    status: s.status,
    public: s.public,
    public_id: s.public_id,
    layout,
    languages: s.languages,
    i18n: s.i18n || {},
    batches: (s.grantee_surveys || [])
      .concat(s.respondents || [])
      .map((gsR) => gsR.batch)
      .filter((b, bi, ba) => ba.indexOf(b) == bi && !!b),
    questions: layout.map((el, i) =>
      el.type === 'question'
        ? { ...surveyQuestions[findIndex(layout, i)], position: i }
        : {
            settings: {},
            conditional_logic: [],
            id: uuidv4(),
            ...el,
            position: i,
          }
    ),

    sent_surveys_count:
      typeof s.respondents_count == 'number' ? s.sent_surveys_count : 0,
    grantee_count: typeof s.respondents_count == 'number' ? s.grantee_count : 0,
    finished_surveys_count:
      typeof s.respondents_count == 'number' ? s.finished_surveys_count : 0,
    unfinished_surveys_count:
      typeof s.respondents_count == 'number' ? s.unfinished_surveys_count : 0,
    respondents_count:
      typeof s.respondents_count == 'number' ? s.respondents_count : 0,
  };
};

export const fromApiToQuestions = (s) =>
  (s.survey_questions || [])
    .map(toSurveyQuestionFromApi)
    .sort((q1, q2) => q1.position - q2.position);

const getQuestionType = (question) =>
  OBJECT_VALUE_QUESTIONS.includes(question.question_type ?? question.type)
    ? 'object_value'
    : 'simple_value';
const getAnswerValue = (answer, question) =>
  // TODO SEB-188: Serializing object_value resposne
  // MULTIPLE_CHOICE : {"object_value"=>["A", "B", "C"]}
  // SINGLE MATRIX MULTIPLE CHOICE : {"object_value"=>{"A"=>[1, 3, 2], "B"=>[3, 2, 1]}}
  answer.response?.[getQuestionType(question)] ?? answer.value;

const matchTags = (
  { choice_values, matrix_values, other_tag_id: otherTagId },
  selectedValue,
  otherValue
) => {
  if (isNotChoiceOrMatrix({ choice_values, matrix_values })) {
    return [];
  }
  if (!selectedValue && !otherValue) {
    return [];
  }
  let valuesArray;
  let valueAsChoice;
  let possibleOptions;
  if (matrix_values?.rows?.length > 0) {
    valuesArray = Object.keys(selectedValue);
    possibleOptions = matrix_values?.rows;
  } else {
    valuesArray = Array.isArray(selectedValue)
      ? selectedValue
      : [selectedValue];
    possibleOptions = choice_values;
  }

  const tags = valuesArray.reduce((acc, item) => {
    valueAsChoice = prop(
      'tag_id',
      possibleOptions.find(({ title }) => title == item) || {}
    );
    return valueAsChoice ? acc.concat([valueAsChoice]) : acc;
  }, []);
  if (otherValue && otherTagId) {
    tags.push(otherTagId);
  }
  return tags;
};
export const toSurveyAnswersAnswerFromApi = (question, a) => ({
  value: getAnswerValue(a, question),
  otherValue: a.response?.other_value,
  id: question.id,
  uid: a.uid,
  answerId: a.id,
  tags:
    a.response?.tag_ids ||
    matchTags(
      question.metadata || {},
      getAnswerValue(a, question),
      a.response?.other_value
    ),
  question_taggings: a.response?.question_taggings || a.question_taggings || [],
  manual_taggings:
    a.response?.manual_taggings?.length > 0
      ? a.response.manual_taggings
      : a.manual_taggings || [],
  import_upload: a.import_upload,
});

export const fromApiToAnswers = (s) =>
  (s.survey_questions || []).map((q) =>
    toSurveyAnswersAnswerFromApi(q, q.survey_answer)
  );

export const fromApiToInfo = pick([
  'id',
  'grantee_id',
  'grant_id',
  'survey_id',
  'permalink',
  'batch',
  'is_enabled',
  'is_finished',
  'finished_at',
  'is_notified',
  'notified_at',
  'survey_name',
  'grantee_name',
  'grant_name',
  'language',
  'notified_email',
  'notified_name',
  'report_id',
  'public_submission',
  'survey_languages',
  'survey_i18n',
  'survey_organization_logo',
  'survey_organization_logo_url',
  'survey_organization_title',
  'survey_question_counter',
  'survey_public_id',
  'respondent_id',
  'respondent_name',
  'respondent_email',
  'project_id',
  'grantee_survey_id',
]);

export const fromSurveyToApi = (s) => ({
  title: s.name,
  period: s.period,
  deadline: s.deadline,
  anonymous: s.anonymous,
  organization_logo: s.organization_logo,
  question_counter: s.question_counter,
  status: s.status,
  public: s.public,
  languages: s.languages,
  i18n: s.i18n,
  public_id: s.public_id,
  layout: fromQuestionsToLayout(s.questions),
  survey_questions: s.questions
    .map((q, i) => ({ ...q, position: i }))
    .filter((q) => !q.isLayout)
    .map((q) => ({
      id: q.server_id,
      title: q.question,
      description: q.description,
      question_type: q.type,
      is_required: q.required,
      tag_ids: q.tags,
      tag_type: q.multiTags ? 'multiple' : 'single',
      position: q.position,
      i18n: q.i18n || {},
      metadata: {
        settings: q.settings || {},
        choice_values: q.options?.map((o) => ({ ...o })),
        currency: q.currency,
        has_other: q.hasOther,
        other_value: q.otherValue,
        other_tag_id: q.other_tag_id,
        max_selections: q.maxSelections,
        matrix_question_type: q.matrixQuestionType,
        matrix_reach_type: q.matrixReachType,
        conditional_logic: q.conditional_logic || [],
        matrix_values: { rows: q.matrixValues || [] },
        has_attachment: q.has_attachment,
        is_respondent: q.is_respondent,
        positive_only: q.positiveOnly,
      },
    })),
});

export const toListSurveyFromApi = (s) => ({
  id: s.id,
  name: s.title,
  deadline: s.deadline,
  sent_surveys_count: s.sent_surveys_count,
  grantee_count: s.grantee_count,
  finished_surveys_count: s.finished_surveys_count,
});

export const retrieveAnswerValue = (question, answers) =>
  Array.isArray(answers)
    ? [
        ...new Set(
          (
            answers.filter((a) =>
              question.options?.map((o) => o?.title)?.includes(a)
            ) || []
          ).filter((a) => a != null)
        ),
      ]
    : answers;

const uniqueTagIds = (tagIds) =>
  [...new Set(tagIds || [])].filter((a) => a.length > 0);
export const toApiAnswerFromSurveyAnswers = (question, answers) => {
  const tag_ids = uniqueTagIds(answers.find((a) => a.id == question.id)?.tags);
  const other_value = answers.find((a) => a.id == question.id)?.otherValue;
  return {
    survey_question_id: question.id,
    response: {
      ...(tag_ids.length > 0 ? { tag_ids } : {}),
      ...(answers.question_taggings
        ? { question_taggings: answers.question_taggings }
        : {}),
      ...(answers.manual_taggings
        ? { manual_taggings: answers.manual_taggings }
        : {}),
      ...(other_value ? { other_value } : {}),
      [getQuestionType(question)]: retrieveAnswerValue(
        question,
        answers.find((a) => a.id == question.id)?.value
      ),
    },
  };
};

export const toApiFromSurveyAnswers = (questions, answers, info) => ({
  grantee_survey: {
    id: info.survey_id,
    is_finished: info.is_finished,
    language: info.language,
    survey_answers: questions.map((q) =>
      toApiAnswerFromSurveyAnswers(q, answers)
    ),
  },
});
