import PropTypes from 'prop-types';
import pathOr from 'ramda/src/pathOr';
import React, { Component } from 'react';

import { Button, Dialog, TextField } from 'src/components/IMUI';
import { Label } from 'src/components/IMUI/Forms/base';
import { getLanguageForCode } from 'src/services/DictionaryService';

import { Icon } from 'im/ui/Icon';
import { canBuildSurvey } from 'src/userStorage';

const flattenDict = (props) =>
  props.languages.reduce((acc, code) => {
    if (props.values[code]) {
      acc[code] = pathOr(
        '',
        Array.isArray(props.path) ? props.path : [props.path],
        props.values[code]
      );
    }
    return acc;
  }, {});

export default class TranslateButton extends Component {
  static propTypes = {
    // // owner and altPath are required to properly handle old data // to be able to retrieve default translations for surveys where // i18n was not avaialble during creation // TODO try to figure out if we still need it?
    // owner: PropTypes.object, // altPath: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    error: PropTypes.any,
    languages: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string,
    values: PropTypes.object,
    path: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    altPath: PropTypes.string,
    onChange: PropTypes.func,
    multiLine: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  };

  static DEFAULT_MAX_ROWS = 9;
  static DEFAULT_ROWS = 3;

  constructor(props) {
    super(props);
    this.state = { open: false, values: flattenDict(props) };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.values !== this.props.values) {
      this.setState({ values: flattenDict(nextProps) });
    }
  }
  handleOpen = (ev) => {
    ev.stopPropagation();
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  handleApprove = () => {
    this.props.onChange(this.state.values, this.props.path, this.props.altPath);
    this.handleClose();
  };
  handleLanguageUpdated = (langCode, value) => {
    this.setState({ values: { ...this.state.values, [langCode]: value } });
  };

  render() {
    if (!this.props.languages || this.props.languages.length < 2) return null;
    const rows =
      typeof this.props.multiLine === 'number'
        ? this.props.multiLine
        : TranslateButton.DEFAULT_ROWS;
    const rowsMax =
      typeof this.props.multiLine === 'number' &&
      this.props.multiLine > TranslateButton.DEFAULT_MAX_ROWS
        ? this.props.multiLine
        : TranslateButton.DEFAULT_MAX_ROWS;

    return (
      <div>
        <Icon
          error={this.props.error}
          name="translate"
          onClick={this.handleOpen}
        />
        <Dialog
          open={this.state.open}
          title={
            <h3>
              {' '}
              <Icon name="translate" /> {this.props.title}{' '}
            </h3>
          }
          rightActions={
            <Button
              disabled={!canBuildSurvey()}
              size="l"
              label="Update"
              onClick={this.handleApprove}
            />
          }
          leftActions={
            <Button
              size="l"
              negative
              label="Cancel"
              onClick={this.handleClose}
            />
          }
          onRequestClose={this.handleClose}
        >
          {this.props.languages?.map((langCode) => (
            <div key={langCode}>
              <Label label={getLanguageForCode(langCode)} />
              <TextField
                fullWidth
                name={langCode}
                multiLine={!!this.props.multiLine}
                rows={rows}
                rowsMax={rowsMax}
                value={this.state.values[langCode] || ''}
                onChange={(value) =>
                  this.handleLanguageUpdated(langCode, value)
                }
              />
            </div>
          ))}
        </Dialog>
      </div>
    );
  }
}
