import React from 'react';
import PropTypes from 'prop-types';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
const EMPTY_CONFIG = { title: { text: '' } };
import './Chart.module.css';
import { buildFinalChartSettings } from 'src/components/Chart/chartUtils';

export default class Chart extends React.PureComponent {
  static propTypes = {
    config: PropTypes.object,
    settings: PropTypes.object,
    useDarkTheme: PropTypes.bool,
    onConfigChanged: PropTypes.func,
  };

  static defaultProps = {
    config: EMPTY_CONFIG,
    settings: {},
    useDarkTheme: false,
  };

  componentDidUpdate(oldProps) {
    const hasChanges =
      oldProps.config !== this.props.config ||
      oldProps.settings !== this.props.settings ||
      oldProps.useDarkTheme !== this.props.useDarkTheme;
    if (!hasChanges) return;
    this.props.onConfigChanged?.(this.rebuild());
    window.highchart?.update(this.rebuild(), true, false, false);
  }

  rebuild = () =>
    buildFinalChartSettings(
      this.props.config,
      this.props.settings,
      this.props.useDarkTheme
    );
  render() {
    return (
      <HighchartsReact
        ref={(ref) => {
          if (!ref?.chart) return;
          window.highchart = ref.chart;
        }}
        highcharts={Highcharts}
        options={this.rebuild()}
        constructorType="chart"
        allowChartUpdate={true}
        immutable={false}
      />
    );
  }
}
