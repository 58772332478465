import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import { connect } from 'react-redux';
import activitiesApi from 'src/api/Activities';
import {
  Section,
  Tile,
  EmptyText,
  Container,
  Actions,
  Button,
} from 'src/components/IMUI';
import Page from 'src/components/Page/Page';
import colors from 'src/css/constants.json';
import history from 'src/historyInstance';
import { getOrgText } from 'src/services/DictionaryService';
import surveysSvg from 'src/static/surveys.svg';
import uploadSvg from 'src/static/upload-medium.svg';
import { where } from 'im/api/Query';
import { Icon } from 'im/ui/Icon';
import cls from './GrantAdd.module.css';
import { canManageGrants } from 'src/userStorage';

const cx = classNames.bind(cls);

@connect(pick(['user', 'activities']), {
  getUploadedFilesStatus: activitiesApi.findAll,
})
export default class GrantAdd extends React.PureComponent {
  static propTypes = {
    user: PropTypes.object,
    activities: PropTypes.object,
    getUploadedFilesStatus: PropTypes.func.isRequired,
  };
  static defaultProps = {};
  constructor(props) {
    super(props);
    this.state = {
      showImportHistory: false,
      activitiesGrouped: this.getGroupedActivities(props.activities.data),
    };
  }

  componentDidMount() {
    this.props.getUploadedFilesStatus(
      where()
        .filter(
          'organization_id_eq',
          this.props.user.data.current_organization_id
        )
        .filter('resource_type_cont', 'upload')
        .filter('action_eq', 'import')
        .include('resource', 'user')
        .sort('-created_at')
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.activities.data !== this.props.activities.data) {
      this.setState({
        activitiesGrouped: this.getGroupedActivities(nextProps.activities.data),
      });
    }
  }

  onToggleShowImportedFiles = () => {
    this.setState({ showImportHistory: !this.state.showImportHistory });
  };

  getGroupedActivities = (activities) =>
    Object.values(activities).reduce(
      (acc, { id, resource, user, created_at: createdAt }) => {
        if (!resource || (acc[resource.id] || {}).status === 'failure') {
          return acc;
        }
        return { ...acc, [resource.id]: { id, resource, user, createdAt } };
      },
      {}
    );

  goTo(path) {
    history.push(path);
  }

  renderImportHistoryList = () => {
    const hasFiles = Object.keys(this.state.activitiesGrouped).length > 0;
    if (!hasFiles) {
      return (
        <EmptyText className={cls.grantAddSpacing} text="No imported files" />
      );
    }
    return (
      <ul className={cls.grantAddTileList}>
        {Object.values(this.state.activitiesGrouped).map(
          ({ id, createdAt, resource, user }) => (
            <li
              key={id}
              className={cls.grantAddTileListItem}
              onClick={() => this.goTo(`/grants/import/${resource.id}/report`)}
            >
              <Container>
                <Container nowrap>
                  <strong className={cls.grantAddTileListItemName}>
                    {resource.file_name}&emsp;
                    {<Icon name="check" color={colors.green} />}
                  </strong>
                </Container>
                <Container
                  horizontal
                  nowrap
                  className={cls.grantAddTileListItemInfo}
                >
                  <Actions>
                    <Icon name="account" />
                    {user.first_name && user.last_name
                      ? `${user.first_name} ${user.last_name}`
                      : user.email}
                  </Actions>
                  <Actions>
                    <Icon name="wait" />
                    {new Date(createdAt).toLocaleString()}
                  </Actions>
                </Container>
              </Container>
            </li>
          )
        )}
      </ul>
    );
  };

  renderImportCover = () => {
    return (
      <Tile grow containerProps={{ className: cls.grantAddTileContainer }}>
        <Icon
          name="list"
          className={cls.grantAddTileSwitcher}
          onClick={this.onToggleShowImportedFiles}
        />
        <Tile.Icon imgUrl={uploadSvg} />
        <Tile.Title>{getOrgText('Import grants')}</Tile.Title>
        <Tile.Text>
          {getOrgText(
            'Upload your grants data in a CSV format and review uploaded data.'
          )}
        </Tile.Text>
        <Tile.Button
          disabled={!canManageGrants()}
          label={getOrgText('Import grants')}
          onClick={() => this.goTo('/grants/import2')}
        />
      </Tile>
    );
  };

  renderImportHistory = () => {
    const hasFiles = Object.keys(this.state.activitiesGrouped).length > 0;
    return (
      <Tile grow>
        <Tile.Header>
          <Container horizontal>
            <Actions>
              <h4 className={cls.grantAddTileTitleHistory}>
                Recently imported files
              </h4>
            </Actions>
            <Actions>
              <Icon
                name="rotate"
                className={cx(
                  cls.grantAddTileSwitcher,
                  cls.grantAddTileSwitcherHistory
                )}
                onClick={this.onToggleShowImportedFiles}
              />
            </Actions>
          </Container>
        </Tile.Header>

        {this.renderImportHistoryList()}
        <Button
          disabled={!canManageGrants()}
          secondary
          className={cls.grantAddTileHistoryButton}
          label={`Upload ${hasFiles ? 'another' : 'first'} file`}
          onClick={() => this.goTo('/grants/import2')}
        />
      </Tile>
    );
  };

  onClickBack = () => {
    history.goBack();
  };

  render() {
    return (
      <Page title={getOrgText('Add a grant')}>
        <Section>
          <Tile.Container horizontal centered className={cls.grantAddTiles}>
            <Tile
              grow
              containerProps={{ className: cls.grantAddTileContainer }}
            >
              <Tile.Icon imgUrl={surveysSvg} />
              <Tile.Title>{getOrgText('Add a grant manually')}</Tile.Title>
              <Tile.Text>
                {getOrgText(
                  'Create a grant and enter all of the grant information manually.'
                )}
              </Tile.Text>
              <Tile.Button
                disabled={!canManageGrants()}
                label={getOrgText('Add grant')}
                onClick={() => this.goTo('/grants/add/1')}
              />
            </Tile>
            {this.state.showImportHistory
              ? this.renderImportHistory()
              : this.renderImportCover()}
          </Tile.Container>
        </Section>
        <Section type="sticky-footer">
          <Container horizontal>
            <Actions>
              <Button size="l" label="BACK" onClick={this.onClickBack} />
            </Actions>
          </Container>
        </Section>
      </Page>
    );
  }
}
