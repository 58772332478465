import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'src/components/IMUI';
import TaggingTypeBlock from 'src/components/TaggingType/TaggingTypeBlock';
import { Icon } from 'im/ui/Icon';
import cls from './TaggingInContext.module.css';
import { canManageTag } from 'src/userStorage';

export default class TaggingInContext extends React.PureComponent {
  static propTypes = {
    tagging: PropTypes.object,
    onTaggingEdit: PropTypes.func.isRequired,
    onTaggingRemove: PropTypes.func.isRequired,
  };

  state = { isHovered: false };
  handleHover(isHovered) {
    this.state.isHovered !== isHovered && this.setState({ isHovered });
  }
  render() {
    const { tagging } = this.props;
    const title = tagging.tag?.title;
    const tip = tagging.tag?.tag_categories?.[0]?.title;

    return (
      <span style={{ display: 'flex' }}>
        <Tag
          title={title}
          label={
            <span
              onMouseOver={() => this.handleHover(true)}
              onMouseOut={() => this.handleHover(false)}
            >
              {title}
            </span>
          }
          tip={tip}
          grey
          square
          small
          labelProps={{ className: cls.tagHovered }}
          onRemove={
            !this.state.isHovered
              ? () => canManageTag() && this.props.onTaggingRemove(tagging)
              : null
          }
          onEdit={
            this.state.isHovered
              ? () => canManageTag() && this.props.onTaggingEdit(tagging)
              : null
          }
          onClick={
            this.state.isHovered
              ? () => canManageTag() && this.props.onTaggingEdit(tagging)
              : null
          }
        />
        <TaggingTypeBlock
          key={tagging.id}
          tagging={tagging}
          small
          renderIcon={() =>
            canManageTag() && (
              <Icon name="edit" className={cls.taggingInContextIcon} />
            )
          }
          onClick={() => this.props.onTaggingEdit(tagging)}
        />
      </span>
    );
  }
}
