import ResourceBase from './entities/resource/ResourceBase';

export default class ResourceCollection extends ResourceBase {
  static defaultApi = {
    ...ResourceBase.defaultApi,
    findAll: { method: 'GET' },
  };

  constructor(apiConfig, reducerName) {
    super(apiConfig, reducerName, { isCollection: true });
  }
}
