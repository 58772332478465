import React, { useCallback } from 'react';
import { v1 as uuidv1 } from 'uuid';
import { Actions, Button, Container, TextField } from 'src/components/IMUI';
import { Error } from 'src/components/IMUI/Forms/base';
import TagSelector from 'src/components/TagSelector/TagSelector';
import TranslateButton from 'src/components/TranslateButton/TranslateButton';
import { getSurveyI18n } from 'src/utils/surveysI18n';
import { Icon } from 'im/ui/Icon';
import Option from './Option';
import cls from './QuestionEdit.module.css';

export const SingleMatrixQuestionPropertiesRows = ({
  matrixValues,
  survey,
  question,
  completeness,
  errors,
  tagsWithTagGroups,
  onUpdateI18n,
  onUpdateMatrixValues,
  readOnly,
  isAnswerMode,
}) => {
  const onRowChange = useCallback(
    (index, value) => {
      onUpdateI18n(value, matrixValues[index].id, [
        'matrixValues',
        index,
        'title',
      ]);
    },
    [matrixValues, onUpdateI18n]
  );
  const onRowAdd = useCallback(
    (tagId, title = '') => {
      matrixValues.push({ title, tag_id: tagId, id: uuidv1() });
      onUpdateMatrixValues(matrixValues);
    },
    [matrixValues, onUpdateMatrixValues]
  );
  const onRowDelete = useCallback(
    (index) => {
      matrixValues.splice(index, 1);
      onUpdateMatrixValues(matrixValues);
    },
    [matrixValues, onUpdateMatrixValues]
  );

  const onRowOrderChange = useCallback(
    (oldIndex, newIndex, relative = false) => {
      const elementToMove = matrixValues[oldIndex];
      const targetIndex = relative ? oldIndex + newIndex : newIndex;
      matrixValues.splice(oldIndex, 1);
      matrixValues.splice(targetIndex, 0, elementToMove);
      onUpdateMatrixValues(matrixValues);
    },
    [matrixValues, onUpdateMatrixValues]
  );

  const onTagRemove = useCallback(
    (index, tag) => {
      matrixValues[index].tag_id = undefined;
      if (matrixValues[index].title === tag.name) {
        matrixValues[index].title = '';
      }
      onUpdateMatrixValues(matrixValues);
    },
    [matrixValues, onUpdateMatrixValues]
  );

  const onTagsChanged = useCallback(
    (index, tagId, tag) => {
      matrixValues[index].tag_id = tagId;
      if (!matrixValues[index].title) {
        matrixValues[index].title = tag.name;
      }
      onUpdateMatrixValues(matrixValues);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [matrixValues]
  );

  return (
    <div className={cls.subSection}>
      <h3>
        <Icon name="list" />
        &nbsp;Row labels
      </h3>

      <div>
        {matrixValues.map((matrixRow = {}, index) => (
          <Option
            index={index}
            count={matrixValues.length}
            onAddRow={() => onRowAdd()}
            actionsLeft={() => (
              <TranslateButton
                owner={question}
                languages={survey.languages}
                error={!!completeness.options[matrixRow.id]?.title.length}
                values={question.i18n}
                path={matrixRow.id}
                altPath={['matrixValues', index, 'title']}
                title="Translate row title"
                onChange={(val) => onRowChange(index, val)}
              />
            )}
            onOptionDelete={onRowDelete}
            onOrderChange={onRowOrderChange}
            key={`row-${matrixRow.id || index}`}
            spaceBetween
            disabled={readOnly}
          >
            <Container horizontal nowrap>
              <TextField
                multiLine
                rowsMax={3}
                fullWidth
                flatDark
                name={`option_${index}`}
                hintText={
                  !getSurveyI18n(survey, question, matrixRow.id, [
                    'matrixValues',
                    index,
                    'title',
                  ]) && 'Type in...'
                }
                value={getSurveyI18n(survey, question, matrixRow.id, [
                  'matrixValues',
                  index,
                  'title',
                ])}
                onChange={(value) => onRowChange(index, value)}
                error={errors.matrixValues?.[index]}
                disabled={readOnly}
              />
              <TagSelector
                className="tagSelectorMini"
                isAnswerMode={isAnswerMode}
                tagsWithTagGroups={tagsWithTagGroups}
                noTagProps={{
                  placeSelf: 'center',
                  label: ' ',
                  outline: true,
                  className: 'tagChoice',
                }}
                selected={matrixRow.tag_id}
                onChange={(tagId, tag) => onTagsChanged(index, tagId, tag)}
                onRemove={(tagId, tag) => onTagRemove(index, tag)}
                disabled={readOnly}
              />
            </Container>
          </Option>
        ))}
      </div>
      <Container horizontal>
        <Actions>
          {matrixValues.length == 0 && (
            <Button
              action
              size="s"
              label="Add row"
              onClick={() => onRowAdd()}
            />
          )}
          <TagSelector
            openOnTagClick={false}
            isAnswerMode={isAnswerMode}
            multiple
            showTags={false}
            tagsWithTagGroups={tagsWithTagGroups}
            noTagProps={{ label: 'QUICK ADD', outline: true }}
            selected={matrixValues.map((matrixRow) => matrixRow.tag_id)}
            disabled={readOnly}
          />
        </Actions>
      </Container>

      <Container horizontal>
        {errors.noRows && <Error error={errors.noRows} />}
      </Container>
    </div>
  );
};
