import PropTypes from 'prop-types';
import always from 'ramda/src/always';
import React, { Component } from 'react';

import { Button, Dialog, TextField } from 'src/components/IMUI';
import { canManageSurvey } from 'src/userStorage';

export default class RenameSurveyModal extends Component {
  static propTypes = {
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    open: PropTypes.bool,
    name: PropTypes.string,
  };

  static defaultProps = {
    onClose: always(),
    onSave: always(),
  };

  constructor(props) {
    super(props);
    this.state = {
      name: props.name,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { name } = this.props;
    if (name !== nextProps.name) {
      this.setState({ name: nextProps.name });
    }
  }

  onNameChange = (name) => {
    this.setState({ name });
  };

  onSaveButtonClick = () => {
    this.props.onSave(this.state.name);
  };

  render() {
    const { onClose, open } = this.props;
    return (
      <Dialog
        small
        title="Rename survey"
        leftActions={
          <Button size="l" negative label="Cancel" onClick={onClose} />
        }
        rightActions={
          <Button
            size="l"
            disabled={!canManageSurvey() || !this.state.name}
            label="Change"
            onClick={this.onSaveButtonClick}
          />
        }
        open={open}
        onRequestClose={onClose}
      >
        <TextField
          fullWidth
          name="name"
          hintText="Survey name"
          value={this.state.name || ''}
          onChange={this.onNameChange}
        />
      </Dialog>
    );
  }
}
