import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, Switch } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import UserOrganizationAdd from './Add/UserOrganizationAdd';
import UserOrganizationAddSuccess from './Add/UserOrganizationAddSuccess';
import UserOrganizationEdit from './Edit';
import AllOrganizations from './List/AllOrganizations';
import UserOrganizationsList from './List/UserOrganizations';
import { isStaff } from 'src/userStorage';

export default class OrganizationsIndex extends React.PureComponent {
  static propTypes = { ...ReactRouterPropTypes, user: PropTypes.object };
  render() {
    return (
      <Switch>
        <Route
          exact
          path={this.props.match.path}
          render={(props) => (
            <UserOrganizationsList
              steps={this.props.steps}
              activeStep={this.props.activeStep}
              {...props}
            />
          )}
        />
        <Route
          exact
          path={`${this.props.match.path}/staff`}
          render={(props) =>
            isStaff() ? (
              <AllOrganizations
                steps={this.props.steps}
                activeStep={this.props.activeStep}
                {...props}
              />
            ) : (
              <Redirect to="/error/404" />
            )
          }
        />
        <Route
          exact
          path={`${this.props.match.path}/add/success`}
          component={UserOrganizationAddSuccess}
        />
        <Route
          exact
          path={`${this.props.match.path}/add`}
          component={UserOrganizationAdd}
        />
        <Route
          key={this.props.location.pathname}
          path={`${this.props.match.path}/edit/:id`}
          component={UserOrganizationEdit}
        />
        <Redirect from="*" to="/error/404" />
      </Switch>
    );
  }
}
