import MUISlider from 'material-ui/Slider';
import PropTypes from 'prop-types';
import React from 'react';

import { Label } from 'src/components/IMUI/Forms/base';

export const Slider = ({ label, ...rest }) => {
  return (
    <div>
      <Label label={label} />
      <MUISlider {...rest} />
    </div>
  );
};

Slider.propTypes = {
  label: PropTypes.node,
};

export default Slider;
