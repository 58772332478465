import {
  SURVEY__CHANGE_ANSWER_VALUE,
  SURVEY__CHANGE_ANSWER_TAGS,
  SURVEY_PUBLIC__CLEAR,
  SURVEY_PUBLIC__LANGUAGE_CHANGE,
  SURVEY_PUBLIC__GET_REQUEST,
  SURVEY_PUBLIC__GET_SUCCESS,
  SURVEY_PUBLIC__GET_FAILURE,
  SURVEY_PUBLIC__PUT_REQUEST,
  SURVEY_PUBLIC__PUT_SUCCESS,
  SURVEY_PUBLIC__PUT_FAILURE,
  SURVEY__CHANGE_ANSWER_OTHER_CHOICE_VALUE,
} from 'src/constants';
import {
  fromApiToQuestions,
  fromApiToAnswers,
  fromApiToInfo,
} from 'src/serializers/surveySerializer';
import { updateObject, updateItem } from './utils';
import { UploaderSecureSurveyAnswer } from 'src/api/UploaderSecure';

const initialState = {
  done: false,
  fetching: false,
  saving: false,
  questions: [],
  error: null,
  answers: [],
  info: {},
  layout: [],
  activeLanguage: 'en',
};

export default function surveyAnswersReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case SURVEY_PUBLIC__GET_REQUEST:
      return updateObject(state, {
        fetching: true,
        error: null,
        questions: [],
        layout: [],
      });
    case SURVEY_PUBLIC__GET_SUCCESS:
      return updateObject(state, {
        fetching: false,
        error: null,
        pending: false,
        questions: fromApiToQuestions(payload?.data),
        answers: fromApiToAnswers(payload?.data),
        info: fromApiToInfo(payload?.data),
        layout: payload?.data?.layout.map((l) => ({ i18n: {}, ...l })),
      });
    case SURVEY_PUBLIC__GET_FAILURE:
      return updateObject(state, {
        fetching: false,
        error: payload.response?.data?.errors,
        questions: [],
        pending: false,
      });

    case SURVEY_PUBLIC__PUT_REQUEST:
      return updateObject(state, {
        saving: true,
        error: null,
        done: payload.isFinished,
      });
    case SURVEY_PUBLIC__PUT_SUCCESS:
      return updateObject(state, { saving: false, error: null });
    case SURVEY_PUBLIC__PUT_FAILURE:
      return updateObject(state, {
        saving: false,
        error: payload.response?.data?.errors,
        done: false,
      });

    case SURVEY__CHANGE_ANSWER_VALUE:
      if (payload.value == undefined) return state;
      return updateObject(state, {
        answers: updateItem(state.answers, payload.questionId, (a) =>
          updateObject(a, { value: payload.value })
        ),
      });
    case SURVEY__CHANGE_ANSWER_OTHER_CHOICE_VALUE:
      return updateObject(state, {
        answers: updateItem(state.answers, payload.questionId, (a) =>
          updateObject(a, { otherValue: payload.value })
        ),
      });
    case SURVEY__CHANGE_ANSWER_TAGS:
      return updateObject(state, {
        answers: updateItem(state.answers, payload.questionId, (a) =>
          updateObject(a, { tags: payload.tags })
        ),
      });

    case SURVEY_PUBLIC__LANGUAGE_CHANGE:
      return updateObject(state, {
        activeLanguage: payload || initialState.activeLanguage,
      });
    case SURVEY_PUBLIC__CLEAR:
      return { ...initialState, activeLanguage: state.activeLanguage };

    case UploaderSecureSurveyAnswer.actionTypes.create.success: {
      const index = state.answers?.findIndex(
        (a) => a?.answerId == payload.data?.target?.id
      );
      if (index < 0 || !payload.data?.target?.id) return state;
      const answer = {
        import_upload: {
          id: payload.data.id,
          user_id: payload.data.user?.id,
          organization_id: payload.data.organization?.id,
          target_id: payload.data.target.id,
          target_type: 'SurveyAnswer',
          asset_data: {
            metadata: {
              filename: payload.data.file_name,
              mime_type: payload.data.mime_type,
            },
          },
          created_at: payload.data.created_at,
          updated_at: payload.data.updated_at,
        },
      };
      return { ...state, answers: state.answers.splice(index, 1, answer) };
    }

    case UploaderSecureSurveyAnswer.actionTypes.destroy.success: {
      const index = state.answers?.findIndex(
        (a) => a.import_upload?.id == payload?.meta?.urlParams?.id
      );
      if (index < 0 || !payload?.meta?.urlParams?.id) return state;

      return {
        ...state,
        answers: state.answers.splice(index, 1, {
          import_upload: { id: null, target_id: null },
        }),
      };
    }

    default:
      return state;
  }
}
