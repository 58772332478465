import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, Button, Container } from 'src/components/IMUI/index';
import exportImg from 'src/static/export.png';
import cls from './ImportConfirmation.module.css';

export default class ImportConfirmation extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    renderTitle: PropTypes.func,
    renderContent: PropTypes.func,
    onRequestClose: PropTypes.func.isRequired,
  };

  render() {
    const { open, renderTitle, renderContent } = this.props;
    return (
      <Dialog
        title={
          renderTitle ? (
            renderTitle()
          ) : (
            <span className={cls.centeredText}>Import in progress</span>
          )
        }
        open={open}
        onRequestClose={this.props.onRequestClose}
        zIndex={1401}
        rightActions={
          <Button
            negative
            size="l"
            label="Close"
            onClick={this.props.onRequestClose}
          />
        }
      >
        {renderContent ? (
          renderContent()
        ) : (
          <Container centered>
            <img src={exportImg} height={250} alt="Export icon" />
            <p className={cls.importText}>
              {`Your request for import has been received.
               Once the import had finished processing, we'll send a notification to the email address associated with your account.`}
            </p>
          </Container>
        )}
      </Dialog>
    );
  }
}
