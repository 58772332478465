export const getIntersected = (orgCustomFields, modelCustomFields) =>
  Array.isArray(orgCustomFields) && modelCustomFields
    ? orgCustomFields.reduce(
        (acc, key) => ({
          ...acc,
          [key]: modelCustomFields[key] ? modelCustomFields[key] : '',
        }),
        {}
      )
    : {};
