import React, { PureComponent } from 'react';
import { formatMoney, formatNumber } from 'accounting';
import PropTypes from 'prop-types';
import lensPath from 'ramda/src/lensPath';
import over from 'ramda/src/over';
import prop from 'ramda/src/prop';
import { THEME } from 'src/components/Map/Map';
import cls from './Sidebar.module.css';
const ENTITY_FUNDING = 'total_funding';
const ENTITY_NAME = 'name';
const sum = (a, b) => a + b;

const EntitiesList = ({ entities, useDarkTheme }) => {
  const theme = THEME[useDarkTheme ? 'dark' : 'light'];

  return (
    <div className={cls.entitiesList}>
      {entities.map((entity) => {
        const precision = 0;
        const totalFunding = prop(ENTITY_FUNDING, entity);
        const funding = formatMoney(totalFunding / 100, '$', precision);
        const name = entity[ENTITY_NAME];
        const related = entity.relatedTaggings.reduce(
          (acc, tagging) =>
            tagging.quantity === 0
              ? acc
              : over(
                  lensPath([tagging.tagGroup, tagging.tagName]),
                  (taggings = []) => [...taggings, tagging],
                  acc
                ),
          {}
        );

        const reachData = Object.keys(related)
          .map((tagGroup) => {
            const taggings = Object.keys(related[tagGroup])
              .map((tagName) => ({
                tagName,
                quantitySum: related[tagGroup]?.[tagName]
                  .map(prop('quantity'))
                  .reduce(sum, 0),
              }))
              .filter((q) => q.quantitySum > 0);
            return {
              tagGroup,
              taggings,
              quantitySum: taggings.map(prop('quantitySum')).reduce(sum, 0),
            };
          })
          .filter((q) => q.quantitySum > 0);

        const secondaryColor = theme.labelColor;

        return (
          <div
            key={entity.id}
            className={cls.entitiesListItem}
            style={{
              color: secondaryColor,
              borderLeft: `4px solid ${theme.gradientStops[2][1]}`,
            }}
          >
            <div className={cls.entityName}>
              <span>{name}</span>
              {totalFunding > 0 && (
                <div className={cls.secondaryInfoWrapper}>
                  <i className={cls.secondaryInfo}>
                    Funding:{' '}
                    <span className={cls.secondaryValue}>{funding}</span>
                  </i>
                </div>
              )}
            </div>

            {entity.related_entity_names
              .filter((v) => v !== 'Grant')
              .map((v, i) => (
                <li className={cls.relatedEntityName} key={`${v}-${i}`}>
                  {v}
                </li>
              ))}

            {reachData.map(
              ({ tagGroup, quantitySum: aggregatedSum, taggings }) => (
                <div className={cls.secondaryInfoWrapper} key={tagGroup}>
                  <div className={cls.tagGroup}>
                    {tagGroup}: {formatNumber(aggregatedSum, 0)}
                  </div>
                  {taggings.map(({ quantitySum, tagName }, index) => (
                    <div key={index}>
                      <span className={cls.secondaryInfo}>{tagName}:</span>
                      <span className={cls.secondaryValue}>
                        {formatNumber(quantitySum, 0)}
                      </span>
                    </div>
                  ))}
                </div>
              )
            )}
          </div>
        );
      })}
    </div>
  );
};

EntitiesList.propTypes = {
  entities: PropTypes.array,
  useDarkTheme: PropTypes.bool,
};

class Sidebar extends PureComponent {
  static propTypes = {
    data: PropTypes.object,
    useDarkTheme: PropTypes.bool,
  };

  render() {
    if (!this.props.data) return null;
    const theme = THEME[this.props.useDarkTheme ? 'dark' : 'light'];

    const years = [
      Math.min(...this.props.data.activeYears),
      Math.max(...this.props.data.activeYears),
    ];

    return (
      <div
        className={`${cls.wrapper} ${
          this.props.useDarkTheme && cls.wrapperDark
        }`}
      >
        <h1
          className={cls.countryName}
          style={{ color: theme.gradientStops[2][1] }}
        >
          {this.props.data.name}
        </h1>

        <h2 className={cls.yearSpan} style={{ color: theme.labelColor }}>
          {years[0] === years[1] ? years[0] : `${years[0]} - ${years[1]}`}
        </h2>

        <div className={cls.entitiesListWrapper}>
          <EntitiesList
            entities={this.props.data.entities}
            useDarkTheme={this.props.useDarkTheme}
          />
        </div>
      </div>
    );
  }
}

export default Sidebar;
