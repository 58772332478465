import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { changeSurveyProperty } from 'src/actionCreators/surveyActionCreators';
import { Card, Container, DatePicker, Toggle } from 'src/components/IMUI';
import { Icon } from 'im/ui/Icon';
import deadlineOff from 'src/static/deadline-off.svg';
import deadlineOn from 'src/static/deadline-on.svg';
import cls from './SettingBox.module.css';

@connect(null, { changeSurveyProperty })
export default class SettingDeadline extends React.PureComponent {
  static propTypes = {
    projectId: PropTypes.string,
    survey: PropTypes.object,
    changeSurveyProperty: PropTypes.func,
  };
  constructor(props) {
    super(props);
    this.state = { toggled: Boolean(props.survey.deadline) };
  }
  tipText = `<div style="max-width: 200px">Survey cutoff date (deadline).<br />Submitting surveys after this date won't be possible</div>`;

  handleChangeDeadline = (value) => {
    this.props.changeSurveyProperty('deadline', value, {
      projectId: this.props.projectId,
      autosave: true,
    });
  };
  handleToggleDeadline = (ev) => {
    this.setState({ toggled: ev.target.checked }, () => {
      if (!this.state.toggled && this.props.survey.deadline) {
        this.handleChangeDeadline(null);
      }
    });
  };

  render() {
    const icon = this.state.toggled ? deadlineOn : deadlineOff;

    return (
      <div className={cls.settingBox}>
        <Card smallPadding border>
          <Container horizontal spaceBetween nowrap className={cls.boxTop}>
            <div>
              <h3 className={cls.boxHeader}>
                <span>Deadline</span>
                <Icon name="question-filled" tip={this.tipText} />
              </h3>

              <Toggle
                className={cls.boxToggle}
                toggled={this.state.toggled}
                onToggle={this.handleToggleDeadline}
              />
            </div>

            <div style={{ flexGrow: 1 }} className={cls.datePickerWrapper}>
              {this.state.toggled && (
                <DatePicker
                  onRef={this.handleDatepickerRef}
                  noValidation
                  className={cls.boxDatePicker}
                  name="survey-deadline"
                  value={
                    this.props.survey.deadline
                      ? new Date(this.props.survey.deadline)
                      : undefined
                  }
                  onChange={this.handleChangeDeadline}
                />
              )}
            </div>
            <img className={cls.boxIcon} src={icon} height={48} alt="" />
          </Container>
        </Card>
      </div>
    );
  }
}
