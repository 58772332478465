import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { Actions, Container, Tag } from 'src/components/IMUI';
import {
  getTaggingDisplayValue,
  getTaggingType,
  TAGGING_TYPE_READABLE,
} from './taggingTypeHelper';
import cls from './TaggingTypeBlock.module.css';
const cx = classNames.bind(cls);

export default class TaggingTypeBlock extends React.PureComponent {
  static propTypes = {
    tagging: PropTypes.object,
    className: PropTypes.string,
    small: PropTypes.bool,
    onClick: PropTypes.func,
    renderIcon: PropTypes.func,
  };

  render() {
    const taggingType = getTaggingType(this.props.tagging);
    if (taggingType === 'default') return null;
    return (
      <Tag
        square
        outline
        borderGrey
        small={this.props.small}
        className={cx(
          cls.taggingTypeBlock,
          { [cls.isClickable]: !!this.props.onClick },
          this.props.className
        )}
        label={
          <Container horizontal className={cls.taggingTypeExtra}>
            <Actions>{TAGGING_TYPE_READABLE[taggingType]}</Actions>
            <Actions>
              <span>
                {getTaggingDisplayValue(taggingType, this.props.tagging)}
              </span>
            </Actions>
          </Container>
        }
        renderIcon={this.props.renderIcon}
        onClick={this.props.onClick}
      />
    );
  }
}
