import React from 'react';
import PropTypes from 'prop-types';
import LoadingBar from 'react-redux-loading-bar';
import { NotificationsWidget } from 'im/ui/Notifications';
import Menu from './Menu/Menu';
import cls from './Layout.module.css';
import './fonts/fonts_used.css';

export default class Layout extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
    location: PropTypes.object.isRequired,
  };

  render() {
    return (
      <div className={cls.app}>
        <div className={cls.main} id="mainBody">
          <Menu location={this.props.location} />
          <div className={cls.layoutContent}>{this.props.children}</div>
        </div>
        <LoadingBar className={cls.loader} />
        <NotificationsWidget />
      </div>
    );
  }
}
