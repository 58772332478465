import { METATAGS__GET_SUCCESS } from 'src/constants';

const initialState = {
  items: [],
  cursor: {},
};

export default function metatagsReducer(state = initialState, action) {
  switch (action.type) {
    case METATAGS__GET_SUCCESS:
      return action.payload.data;
    default:
      return state;
  }
}
