import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Container, Dialog } from 'src/components/IMUI';
import { getOrgText } from 'src/services/DictionaryService';
import GranteeForm from '../../components/GranteeForm';
import { canManageGrants } from 'src/userStorage';
import { getRegions } from 'src/actionCreators/regionsActionCreators';
import pick from 'ramda/src/pick';
import { getIntersected } from 'src/utils/customFields';

@connect(pick(['organizationCurrent', 'defaultRegions']), { getRegions })
export default class CreateDialogsGrantee extends React.PureComponent {
  static propTypes = {
    organizationCurrent: PropTypes.object,
    defaultRegions: PropTypes.array,
    onCloseModal: PropTypes.func.isRequired,
    onSubmitSuccessful: PropTypes.func.isRequired,
  };

  render() {
    const { defaultRegions, organizationCurrent } = this.props;

    return (
      <Dialog
        open
        extraLarge
        title={getOrgText('Add grantee')}
        onRequestClose={this.props.onCloseModal}
        leftActions={
          <Button
            negative
            size="l"
            type="button"
            label="Cancel"
            onClick={this.props.onCloseModal}
          />
        }
        rightActions={
          <Button
            disabled={!canManageGrants()}
            size="l"
            key="save"
            label="Create"
            type="submit"
            form="newGrantee"
          />
        }
      >
        <Container style={{ padding: 10 }}>
          <GranteeForm
            type={'organization'}
            allGrantees={[]}
            customFields={getIntersected(
              this.props.organizationCurrent.data?.custom_grantee_fields || [],
              []
            )}
            regions={defaultRegions}
            customRegionMapping={organizationCurrent.data.custom_region_mapping}
            onCancel={this.props.onCloseModal}
            onSubmitSuccessful={this.props.onSubmitSuccessful}
          />
        </Container>
      </Dialog>
    );
  }
}
