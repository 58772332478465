import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';
import { reducer as form } from 'redux-form';
import { notificationsReducer as notifications } from 'im/ui/Notifications';
import auth from './authReducer';
import cachedSurveyAnswers from './cachedSurveyAnswersReducer';
import chart from './chartReducer';
import chartsAxisData from './chartsAxisDataReducer';
import chartsAxisTypes from './chartsAxisTypesReducer';
import chartsAxisValues from './chartsAxisValuesReducer';
import dictionary from './dictionaryReducer';
import geoLocation from './geoLocationReducer';
import grantees from './granteesReducer';
import grantsFilters from './grantsFiltersReducer';
import grants from './grantsReducer';
import metatags from './metatagsReducer';
import project from './projectReducer';
import projectReports from './projectReportsReducer';
import projects from './projectsReducer';
import projectSummary from './projectSummaryReducer';
import defaultRegions from './regionsReducer';
import report from './reportReducer';
import reportsIds from './reportsIdsReducer';
import storage from './storageReducer';
import surveyAnswers from './surveyAnswersReducer';
import survey from './surveyReducer';
import surveyReview from './surveyReviewReducer';
import surveys from './surveysReducer';
import ui from './uiReducer';

export default {
  auth,
  form,
  grantees,
  grants,
  grantsFilters,
  loadingBar,
  metatags,
  notifications,
  project,
  projectReports,
  projectSummary,
  dictionary,
  projects,
  report,
  reportsIds,
  survey,
  surveys,
  ui,
  storage,
  surveyAnswers,
  surveyReview,
  chart,
  chartsAxisTypes,
  chartsAxisValues,
  chartsAxisData,
  geoLocation,
  defaultRegions,
  cachedSurveyAnswers,
};
