/* eslint no-param-reassign: 0 */

// ...[..<.]..>... -> true
// ...[..<>]...... -> true
// ...[....]..<.>. -> false
// .<.[..>.]...... -> true
// <>.[....]...... -> false
// .<.[....]..>... -> true

// const tags = [
//   { id: 1, startPosition: 0, endPosition: 30 },
//   { id: 2, startPosition: 5, endPosition: 25 },
//   { id: 3, startPosition: 2, endPosition: 8 },
//   { id: 4, startPosition: 40, endPosition: 45 },
//   { id: 5, startPosition: 40, endPosition: 44 },
//  ];
//
//  ->
//
// 0-2   : [1]
// 2-5   : [1, 3]
// 5-8   : [1, 2, 3]
// 8-25  : [1, 2]
// 25-30 : [1]

function uniq(arr) {
  return arr.filter((elem, pos, currentArray) => {
    return currentArray.indexOf(elem) === pos;
  });
}

export function intersect1d(min1, max1, min2, max2) {
  const left = Math.max(min1, min2);
  const right = Math.min(max1, max2);
  return right - left > 0 ? [left, right] : null;
}

function addHighlight(arr, tagging) {
  const overlappingHighlights = arr.filter((cand) => {
    const overlap = intersect1d(
      tagging.startPosition,
      tagging.endPosition,
      cand.startPosition,
      cand.endPosition
    );
    return overlap !== null;
  });

  overlappingHighlights.forEach((el) => {
    const overlap = intersect1d(
      el.startPosition,
      el.endPosition,
      tagging.startPosition,
      tagging.endPosition
    );
    if (!overlap) return;

    let before;
    let rest;
    let beforeTag;
    let restTag;

    if (el.startPosition < overlap[0]) {
      before = {
        startPosition: el.startPosition,
        endPosition: overlap[0],
        tags: el.id ? [el.id] : [...el.tags],
      };
    }

    if (tagging.startPosition < overlap[0]) {
      beforeTag = {
        startPosition: tagging.startPosition,
        endPosition: overlap[0],
        tags: tagging.id ? [tagging.id] : [...tagging.tags],
      };
    }

    if (el.endPosition > overlap[1]) {
      rest = {
        startPosition: overlap[1],
        endPosition: el.endPosition,
        tags: el.id ? [el.id] : [...el.tags],
      };
    }

    if (tagging.endPosition > overlap[1]) {
      restTag = {
        startPosition: overlap[1],
        endPosition: tagging.endPosition,
        tags: tagging.id ? [tagging.id] : [...tagging.tags],
      };
    }

    el.startPosition = overlap[0];
    el.endPosition = overlap[1];
    el.tags = tagging.id
      ? [tagging.id, ...el.tags]
      : [...tagging.tags, ...el.tags];

    if (before) addHighlight(arr, before);
    if (rest) addHighlight(arr, rest);
    if (beforeTag) addHighlight(arr, beforeTag);
    if (restTag) addHighlight(arr, restTag);
  });

  if (!overlappingHighlights.length) {
    arr.push({
      startPosition: tagging.startPosition,
      endPosition: tagging.endPosition,
      tags: tagging.id ? [tagging.id] : [...tagging.tags],
    });
  }
}

export function tagsToHighlights(taggings) {
  const highlights = [];
  taggings
    ?.sort((a, b) => a.report_content_start - b.report_content_start)
    .map((t) => ({
      ...t,
      startPosition: t.report_content_start,
      endPosition: t.report_content_end,
    }))
    .filter(
      (t) => Number(t.startPosition) >= 0 && Number(t.report_content_end) > 0
    )
    .forEach((tagging) => addHighlight(highlights, tagging));

  return highlights.map((el) => ({ ...el, tags: uniq(el.tags) }));
}
