import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import { Icon } from 'im/ui/Icon';

import cls from './StatusIndicator.module.css';

const cx = classNames.bind(cls);

const StatusIndicator = ({
  text,
  iconName,
  renderIcon,
  renderText,
  status,
  className,
  onClick,
}) => (
  <button
    className={cx(
      cls.statusIndicator,
      {
        [cls.statusIndicatorAlert]: status === 'alert',
        [cls.statusIndicatorWarning]: status === 'warning',
      },
      className
    )}
    onClick={onClick}
  >
    {renderIcon ? renderIcon() : <Icon name={iconName} />}
    {renderText ? (
      renderText()
    ) : (
      <span className={cls.statusIndicatorText}>{text}</span>
    )}
  </button>
);

StatusIndicator.propTypes = {
  text: PropTypes.node,
  iconName: PropTypes.string,
  status: PropTypes.oneOf(['alert', 'warning']),
  className: PropTypes.string,
  renderIcon: PropTypes.func,
  renderText: PropTypes.func,
  onClick: PropTypes.func,
};

export default StatusIndicator;
