import React from 'react';
import { FullBgView } from 'src/components/FullBgView';
import { Button } from 'src/components/IMUI';
import history from 'src/historyInstance';

export default class SignUpPage extends React.PureComponent {
  componentDidMount() {
    if (document.getElementById('strip')) return;
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;
    script.id = 'strip';
    document.body.appendChild(script);
  }

  render() {
    const isProd =
      process.env.NODE_ENV === 'production' &&
      process.env.IMPACTMAPPER_API_SERVER?.includes(
        '//api-prod.impactmapper.com'
      );
    return (
      <FullBgView hasLogo>
        <FullBgView.Box
          title={
            <>
              {isProd ? (
                <stripe-pricing-table
                  pricing-table-id="prctbl_1NaJTVL1R65vxyCYKaZhrFJl"
                  publishable-key="pk_live_51NUtE9L1R65vxyCY9QtLYhKgnFuI4HmN2W3uHSeSR4mis59x45NZ3mm0bfRIVRKUGgmKUYu028IoxBRDjXBkQWYt006UY53wU7"
                />
              ) : (
                <stripe-pricing-table
                  pricing-table-id="prctbl_1NaJS9L1R65vxyCYaxDIic7i"
                  publishable-key="pk_test_51NUtE9L1R65vxyCYcIrBWwNmpDD7I6HpjLgaRNdG3v92XfyRgshEdbhirnRBsdPf4WUVFbqJaHUSI4FznD9VoSHe003FnDFqWU"
                />
              )}
            </>
          }
          leftAction={
            <Button
              dark
              text
              underlined
              label="Back to home"
              onClick={() => history.push('/login')}
            />
          }
        />
      </FullBgView>
    );
  }
}
