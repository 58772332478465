import PropTypes from 'prop-types';
import React from 'react';

import { Divider } from 'src/components/IMUI/Decorators/Divider';

import { Icon } from 'im/ui/Icon';

import TripleItem from './TripleItem';

const MappingHeader = ({ className }) => (
  <div>
    <Divider />
    <TripleItem
      className={className}
      left={<strong>Imported Fields</strong>}
      middle={<Icon name="arrow-right" />}
      right={<strong>ImpactMapper Fields</strong>}
    />
    <Divider />
  </div>
);

MappingHeader.propTypes = {
  className: PropTypes.string,
};

export default MappingHeader;
