import PropTypes from 'prop-types';
import React from 'react';

import { Container } from 'src/components/IMUI';

import cls from './SectionHeader.module.css';

export default class SectionHeader extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    leftActions: PropTypes.node,
    rightActions: PropTypes.node,
    children: PropTypes.node,
  };

  render() {
    const { leftActions, rightActions, title, children } = this.props;
    return (
      <Container horizontal nowrap>
        <div className={cls.leftActions}>{leftActions}</div>
        <div className={cls.titleWrapper}>
          {title ? <div className={cls.title}>{title}</div> : children}
        </div>
        <div className={cls.rightActions}>{rightActions}</div>
      </Container>
    );
  }
}
