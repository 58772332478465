import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import { connect } from 'react-redux';
import {
  Actions,
  Button,
  Container,
  SelectField,
  TextField,
  Tag,
  Checkbox,
} from 'src/components/IMUI';
import { getText, isInDictionary } from 'src/services/DictionaryService';
import { getCountryNameByCode } from 'src/utils/countries';

import { Icon } from 'im/ui/Icon';

import { formatValueLabel } from '../helpers/formatters';

import cls from './ChartAxisSelector.module.css';

const ADIDAS_STRING_SEPARATOR = '////';

const isTag = (text) => text.indexOf('#') === 0;

const hasText = (source, text) => {
  const parsedText = text.toLowerCase().replace(/^#/, '');
  if (!source || !source.length) {
    return false;
  } else if (Array.isArray(source)) {
    return source.some((item) => item.toLowerCase().indexOf(parsedText) !== -1);
  }
  return source.toLowerCase().indexOf(parsedText) !== -1;
};

const DEFAULT_OUTCOMES = 'Default Outcomes'.toLowerCase(); // we compare against lower case

const defaultOutcomesLastSort = (a, b) => {
  if (a?.category && typeof b === 'string') return 1; // mixed content
  if (typeof a === 'number') return a - b;
  const nameA = (a.category || a).toLowerCase();
  const nameB = (b.category || b).toLowerCase();
  if (nameA === DEFAULT_OUTCOMES) return 1;
  if (nameB === DEFAULT_OUTCOMES) return -1;
  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;
  return 0;
};

@connect(pick(['chart', 'chartsAxisTypes', 'chartsAxisValues']))
export default class ChartAxisSelector extends PureComponent {
  static propTypes = {
    onAxisTypeChanged: PropTypes.func.isRequired,
    onAxisAttrChanged: PropTypes.func.isRequired,
    onSwapAxes: PropTypes.func.isRequired,
    chartsAxisTypes: PropTypes.object,
    chartsAxisValues: PropTypes.object,
    /* eslint-disable */
    xAxisTypeConfig: PropTypes.object,
    yAxisTypeConfig: PropTypes.object,
    xAxisAttrConfig: PropTypes.array,
    yAxisAttrConfig: PropTypes.array,
    isNew: PropTypes.bool,
    /* eslint-enable */
  };

  constructor(props) {
    super(props);
    this.state = {
      filterTextx: '',
      filterTexty: '',
      expandAxis: false,
      expandSelection: props.isNew,
    };
  }

  onAxisTypeChanged = ({ value, name }) => {
    this.clearFilter(name);
    if (!value) {
      this.props.onAxisTypeChanged(name, null);
    } else {
      const [model, attributeName] = value.split(ADIDAS_STRING_SEPARATOR);
      this.props.onAxisTypeChanged(name, {
        model,
        attribute_name: attributeName,
      });
    }
  };

  onSwapAxes = () => {
    this.setState(
      {
        filterTextx: this.state.filterTexty,
        filterTexty: this.state.filterTextx,
      },
      () => this.props.onSwapAxes()
    );
  };
  onExpandAxis = () => {
    this.setState({ expandAxis: !this.state.expandAxis });
  };
  onExpandSelection = () => {
    this.setState({ expandSelection: !this.state.expandSelection });
  };

  onAxisValuesChanged = ({ value, name }) => {
    this.props.onAxisAttrChanged(name, value);
  };

  onToggleAllAxisValues = (axisName, toggleAll) => {
    if (!toggleAll)
      return this.onAxisValuesChanged({ name: axisName, value: null });

    const filterText = this.state[`filterText${axisName}`].toLowerCase().trim();
    let filteredValues = this.getModelAttributeValues(axisName);

    if (filterText)
      filteredValues = filteredValues.filter(
        (v) =>
          (v?.values || []).join(' ').toLowerCase().match(filterText) ||
          String(v?.category || '')
            .toLowerCase()
            .match(filterText) ||
          String(v)?.toLowerCase().match(filterText)
      );

    // tricky to select only root metatags but still can select individual single tag metatags
    if (this.props[`${axisName}AxisTypeConfig`]?.attribute_name === 'metatags')
      filteredValues = filteredValues.filter((x) => !x?.category && !x?.values);

    return this.onAxisValuesChanged({ name: axisName, value: filteredValues });
  };

  onAxisValueToggled = (axisName, attributeName, category, forceSelect) => {
    let value = [...(this.props[`${axisName}AxisAttrConfig`] || [])];
    [].concat(attributeName).forEach((attribute) => {
      if (category) {
        const ci = value.findIndex(
          (selected) =>
            selected.category === category && selected.values[0] === attribute
        );
        if (ci !== -1) {
          if (!forceSelect) {
            value[ci].values.splice(0, 1);
            if (!value[ci].values.length) {
              value.splice(ci, 1);
            }
          }
        } else {
          value.push({ category, values: [attribute] });
        }
      } else {
        if (value.indexOf(attribute) === -1) {
          value.push(attribute);
        } else if (!forceSelect) {
          value.splice(value.indexOf(attribute), 1);
        }
      }
    });
    if (!value.length)
      return this.onAxisValuesChanged({ name: axisName, value: null });
    return this.onAxisValuesChanged({ name: axisName, value: value });
  };

  onSelectCategory = (axisName, category, selectAll) => {
    const rawAxisAttrs = this.getModelAttributeValues(axisName, true) || [];
    const categoryIndex = rawAxisAttrs.findIndex(
      (tagGroup) => tagGroup.category === category
    );
    const categoryMatchesFilter = !!category.match(
      new RegExp(this.state[`filterText${axisName}`], 'i')
    );
    const filteredAttributes = rawAxisAttrs[categoryIndex].values.filter(
      (value) =>
        categoryMatchesFilter ||
        hasText(value, this.state[`filterText${axisName}`])
    );
    this.onAxisValueToggled(axisName, filteredAttributes, category, selectAll);
  };

  onFilterChanged = (filterText, axisName) => {
    this.setState({ [`filterText${axisName}`]: filterText });
  };

  getModelAttributeValues(axisName, forceRaw) {
    if (!this.props[`${axisName}AxisTypeConfig`]) return null;
    const { model, attribute_name } = this.props[`${axisName}AxisTypeConfig`];
    if (!this.props.chartsAxisValues?.data?.[model]?.[attribute_name])
      return null;
    const valConfig = this.props.chartsAxisValues.data[model][attribute_name];
    if (
      !forceRaw &&
      valConfig.attribute_values?.length > 0 &&
      valConfig.attribute_values?.[0]?.category
    ) {
      return valConfig.attribute_values.reduce((acc, attrConf) => {
        acc.push(
          ...attrConf.values.map((value) => ({
            category: attrConf.category,
            values: [value],
          }))
        );
        return acc;
      }, []);
    }
    return valConfig.attribute_values;
  }

  clearFilter(axisName) {
    this.setState({ [`filterText${axisName}`]: '' });
  }

  isSelected(axisName, attribute, category) {
    const selectedAxisAttrs = this.props[`${axisName}AxisAttrConfig`] || [];
    if (category) {
      const categoryIndex = selectedAxisAttrs.findIndex(
        (selected) =>
          selected.category === category && selected.values[0] === attribute
      );
      return categoryIndex !== -1;
    }
    return selectedAxisAttrs.indexOf(attribute, category) !== -1;
  }

  renderAxisTypeSelector(axisName) {
    const axisConfig = this.props[`${axisName}AxisTypeConfig`];
    const getPrimaryText = (attrName, model) =>
      isInDictionary(`chart-${model}-${attrName}`)
        ? getText(`chart-${model}-${attrName}`)
        : getText(attrName);

    return (
      <SelectField
        maxHeight={window.innerHeight}
        clearable
        name={axisName}
        selectedValueAccessory={
          axisConfig && axisConfig.model ? (
            <Tag dim label={getText(axisConfig.model)} />
          ) : null
        }
        onChange={this.onAxisTypeChanged}
        label={`${axisName.toUpperCase()} axis`}
        value={
          !axisConfig
            ? null
            : [axisConfig.model, axisConfig.attribute_name].join(
                ADIDAS_STRING_SEPARATOR
              )
        }
        fullWidth
        noValidation
      >
        {this.props.chartsAxisTypes.data.reduce((acc, type) => {
          const values = type.attribute_names.map((attName) => (
            <SelectField.Item
              key={`${type.model}-${attName}`}
              value={[type.model, attName].join(ADIDAS_STRING_SEPARATOR)}
              primaryText={getPrimaryText(attName, type.model)}
              style={{ fontSize: 11, minHeight: 'unset', lineHeight: '20px' }}
            />
          ));
          if (!values.length) return acc;
          const header = (
            <SelectField.Item
              key={type.model}
              value={-1}
              primaryText={
                <Tag
                  inline
                  borderGrey
                  disabled
                  label={getText(type.model)}
                  style={{ fontSize: 13 }}
                />
              }
              disabled
              style={{ fontSize: 13, marginTop: 4 }}
            />
          );
          return (acc || []).concat(header, values);
        }, [])}
      </SelectField>
    );
  }

  renderAxisAttrRow(axisName, attribute, category) {
    if (!attribute && category)
      return (
        <tr key={category}>
          <td className={cls.axisAttrSelctorTableItemCategory}>
            <Container
              horizontal
              className={cls.axisAttrSelctorTableItemCategoryText}
            >
              <Actions>{category}</Actions>
              <Actions>
                <Button
                  style={{ fontSize: 10, float: 'right' }}
                  size="s"
                  text
                  label="Clear all"
                  onClick={() =>
                    this.onSelectCategory(axisName, category, false)
                  }
                />
                <Button
                  style={{ fontSize: 10, float: 'right' }}
                  size="s"
                  text
                  label="Select all"
                  onClick={() =>
                    this.onSelectCategory(axisName, category, true)
                  }
                />
              </Actions>
            </Container>
          </td>
        </tr>
      );

    if (
      this.props[`${axisName}AxisTypeConfig`].attribute_name === 'metatags' &&
      attribute &&
      !category
    )
      return (
        <tr
          key={`metatag-${attribute}`}
          onClick={() => this.onAxisValueToggled(axisName, attribute, category)}
        >
          <td className={cls.axisAttrSelctorTableItemMetatag}>
            <span className={cls.axisAttrSelctorCheckbox}>
              <Checkbox
                checked={this.isSelected(axisName, attribute, category)}
              />
            </span>
            <div className={cls.axisAttrSelctorTableItemText}>{attribute}</div>
          </td>
        </tr>
      );

    let label = attribute || 'Not Provided';
    if (attribute) {
      const attrName = this.props[`${axisName}AxisTypeConfig`].attribute_name;

      switch (attrName) {
        case 'country_code':
          label = getCountryNameByCode(attribute) || attribute;
          break;
        case 'amount':
          label = formatValueLabel(+attribute, 'currency');
          break;
      }
    }
    return (
      <tr
        key={`${attribute}-${category || ''}`}
        onClick={() => this.onAxisValueToggled(axisName, attribute, category)}
      >
        <td className={cls.axisAttrSelctorTableItem}>
          <span className={cls.axisAttrSelctorCheckbox}>
            <Checkbox
              checked={this.isSelected(axisName, attribute, category)}
            />
          </span>
          <div className={cls.axisAttrSelctorTableItemText}>{label}</div>
        </td>
      </tr>
    );
  }

  renderAxisAttrSelctor(axisName) {
    const filterText = this.state[`filterText${axisName}`];
    const selectedAxisAttrs = this.props[`${axisName}AxisAttrConfig`] || [];
    const availableAxisAttrs = this.getModelAttributeValues(axisName) || [];
    const rawAxisAttrs = this.getModelAttributeValues(axisName, true) || [];

    rawAxisAttrs.sort(defaultOutcomesLastSort);

    return (
      <div
        className={
          this.state.expandSelection
            ? cls.axisAttrSelctor
            : cls.axisAttrSelctorCollapsed
        }
      >
        {availableAxisAttrs.length > 0 && (
          <Container
            horizontal
            style={{ backgroundColor: 'white', borderRadiusTop: 6 }}
          >
            <Actions>
              <TextField
                flat
                fullWidth
                className={cls.axisValuesFilter}
                name="list-filter"
                noValidation
                placeholder="Type to filter"
                value={filterText}
                onChange={(value) => this.onFilterChanged(value, axisName)}
              />
            </Actions>
            <Actions style={{ paddingRight: 16 }}>
              <Button
                text
                size="s"
                label="Clear All"
                disabled={selectedAxisAttrs.length === 0}
                className={cls.valuesListTextButton}
                onClick={() => this.onToggleAllAxisValues(axisName, false)}
              />
              &nbsp;
              <Button
                text
                size="s"
                label="Select All"
                className={cls.valuesListTextButton}
                disabled={
                  selectedAxisAttrs.length === availableAxisAttrs.length
                }
                onClick={() => this.onToggleAllAxisValues(axisName, true)}
              />
            </Actions>
          </Container>
        )}
        <div className={cls.axisAttrSelctorTableWrapper}>
          <table className={cls.axisAttrSelctorTable}>
            <tbody>
              {rawAxisAttrs.reduce((acc, attribute) => {
                const attrName =
                  this.props[`${axisName}AxisTypeConfig`].attribute_name;
                const label =
                  attrName === 'country_code'
                    ? getCountryNameByCode(attribute) || attribute
                    : attribute;

                if (!attribute.category) {
                  if (!filterText || hasText(label, filterText)) {
                    acc.push(this.renderAxisAttrRow(axisName, attribute));
                  }
                } else {
                  const renderAll =
                    !filterText ||
                    (!isTag(filterText) &&
                      hasText(attribute.category, filterText));
                  const renderSome =
                    renderAll || hasText(attribute.values || [], filterText);
                  if (renderAll || renderSome) {
                    acc.push(
                      this.renderAxisAttrRow(axisName, null, attribute.category)
                    );
                    acc.push(
                      ...(attribute.values || [])
                        .filter((val) => renderAll || hasText(val, filterText))
                        .map((categoryAttribute) =>
                          this.renderAxisAttrRow(
                            axisName,
                            categoryAttribute,
                            attribute.category
                          )
                        )
                    );
                  }
                }
                return acc;
              }, [])}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div
        className={
          this.state.expandSelection
            ? cls.axisValueTypeSelectorsExpanded
            : cls.axisValueTypeSelectors
        }
      >
        <div className={cls.axisSwitchSeparator}>
          <Icon
            name={this.state.expandSelection ? 'chevron-up' : 'filter'}
            className={cls.buttonClass}
            onClick={this.onExpandSelection}
          />
        </div>

        <div
          className={
            this.state.expandAxis
              ? cls.axisSelectorColumnY
              : cls.axisSelectorColumnX
          }
        >
          {this.renderAxisTypeSelector('x')}
          {this.renderAxisAttrSelctor('x')}
        </div>

        <div className={cls.axisSwitchSeparator}>
          <Icon name="switch" onClick={this.onSwapAxes} />
        </div>
        <div className={cls.axisSwitchSeparator}>
          <Icon
            name={this.state.expandAxis ? 'chevron-right' : 'chevron-left'}
            onClick={this.onExpandAxis}
          />
        </div>
        <div
          className={
            this.state.expandAxis
              ? cls.axisSelectorColumnX
              : cls.axisSelectorColumnY
          }
        >
          {this.renderAxisTypeSelector('y')}
          {this.renderAxisAttrSelctor('y')}
        </div>
      </div>
    );
  }
}
