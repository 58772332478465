import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import FlexRow from 'src/components/FlexRow/FlexRow';
import { Section } from 'src/components/IMUI';
import cls from './Tabs.module.css';
const cx = classNames.bind(cls);

const Tab = ({ className, value, isActive, label, onClick }) => (
  <button
    type="button"
    value={value}
    onClick={() => onClick(value)}
    className={cx('imui-tab', { 'imui-tab-active': isActive }, className)}
  >
    <span>{label}</span>
  </button>
);
Tab.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
};

const getChildIndex = (children, value) =>
  React.Children.toArray(children).findIndex((tab) => tab.props.value == value);

export class Tabs extends React.PureComponent {
  static Item = Tab;
  static propTypes = {
    hasFooter: PropTypes.bool,
    isHeader: PropTypes.bool,
    className: PropTypes.string,
    tabContentClassName: PropTypes.string,
    children: PropTypes.node,
    defaultActiveIndex: PropTypes.number,

    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
  };

  static defaultProps = { onChange: () => void 0 };
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: props.value
        ? getChildIndex(props.children, props.value)
        : props.defaultActiveIndex || 0,
    };
  }
  onActiveChange(_activeIndex, activeValue) {
    const activeIndex = activeValue
      ? getChildIndex(this.props.children, activeValue)
      : _activeIndex;
    this.setState({ activeIndex });
    this.props.onChange(activeValue || activeIndex);
  }

  render() {
    const tabs = React.Children.toArray(this.props.children).filter(Boolean);
    const activeTab = tabs[this.state.activeIndex];
    return (
      <FlexRow
        direction="vertical"
        className={cx('imui-tabs-container', {
          'imui-tabs-container-limited':
            this.props.hasFooter || activeTab.props.hasFooter,
        })}
      >
        <Section
          type="context-header"
          className={cx(
            {
              'imui-tabs-context-header': this.props.isHeader,
              'imui-tabs-header-limited':
                this.props.hasFooter || activeTab.props.hasFooter,
            },
            this.props.className
          )}
        >
          <div className={cx('imui-tabs')}>
            {tabs.map((tab, index) =>
              React.cloneElement(tab, {
                isActive: index === this.state.activeIndex,
                onClick: (v) => this.onActiveChange(index, v),
              })
            )}
          </div>
        </Section>

        <div
          key={this.state.activeIndex}
          className={cx(
            'imui-tabs-content',
            {
              'imui-tabs-content-space-top': this.props.isHeader,
              'imui-tabs-content-limited': activeTab.props.hasFooter,
            },
            this.props.tabContentClassName
          )}
        >
          {activeTab.props.children}
        </div>
      </FlexRow>
    );
  }
}
export default Tabs;
