import PropTypes from 'prop-types';
import React from 'react';
import { Button, Dialog } from 'src/components/IMUI';
import surveyIcon from 'src/static/surveySent.png';
import cls from './ShareConfirmationModal.module.css';

export default class ShareConfirmationModal extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    series: PropTypes.string,
    deliverAll: PropTypes.bool,
    deliverSelected: PropTypes.bool,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
  };

  render() {
    const { open, series, deliverAll, deliverSelected } = this.props;

    return (
      <Dialog
        title="Share survey with contacts"
        open={open}
        onRequestClose={this.props.onCancel}
        rightActions={
          <Button size="l" label="Send" onClick={this.props.onSubmit} />
        }
        leftActions={
          <Button
            negative
            size="l"
            label="Cancel"
            onClick={this.props.onCancel}
          />
        }
      >
        <div className={cls.shareConfirmationModal}>
          <img src={surveyIcon} alt="Survey sent icon" height={300} />
          <p className={cls.shareConfirmationModalText}>
            Are you sure you want to deliver invites
            {series ? ` as a part of series ${series},` : null}
            &nbsp;to all
            {deliverSelected ? ' selected respondents' : null}
            {deliverAll
              ? ' respondents who did not completed the survey yet'
              : null}
            ?
          </p>
        </div>
      </Dialog>
    );
  }
}
