import PropTypes from 'prop-types';
import React from 'react';

import { SelectField, Tag } from 'src/components/IMUI';
import {
  getLocalLanguageForCode,
  getLanguageForCode,
} from 'src/services/DictionaryService';

import cls from './LanguageSelector.module.css';

export default class LanguageSelector extends React.Component {
  static propTypes = {
    completeness: PropTypes.object,
    hideName: PropTypes.bool,
    outline: PropTypes.bool,
    fullWidth: PropTypes.bool,
    tagSquished: PropTypes.bool,

    languages: PropTypes.arrayOf(PropTypes.string),
    activeLanguage: PropTypes.string,
    onChange: PropTypes.func,
    isLocalLanguages: PropTypes.bool,
  };

  renderLanguage(languageCode) {
    const { isLocalLanguages } = this.props;
    if (isLocalLanguages) {
      return getLocalLanguageForCode(languageCode);
    }
    return getLanguageForCode(languageCode);
  }

  renderIcon(languageCode, className) {
    const { completeness, outline, tagSquished } = this.props;
    const dim = !completeness && !outline;
    let unstable = false;
    let positive = false;
    let warning = false;

    if (completeness) {
      const completenessValue = completeness[languageCode].value;
      unstable = completenessValue > 0.75 && completenessValue !== 1;
      positive = completenessValue === 1;
      warning = completenessValue <= 0.75;
    }

    return (
      <Tag
        outline={outline}
        squished={tagSquished}
        dim={dim}
        unstable={unstable}
        positive={positive}
        warning={warning}
        label={languageCode}
        className={className}
      />
    );
  }

  render() {
    const {
      languages,
      activeLanguage,
      hideName,
      outline,
      fullWidth,
      onChange,
    } = this.props;

    return (
      <SelectField
        noValidation
        fullWidth={fullWidth}
        selectedValueAccessory={this.renderIcon(activeLanguage)}
        value={activeLanguage}
        onChange={onChange}
        outline={outline}
        dropDownMenuProps={{ style: { minWidth: 200 } }}
      >
        {languages.map((languageCode) => (
          <SelectField.Item
            key={languageCode}
            value={languageCode}
            leftIcon={this.renderIcon(languageCode, cls.listLanguageCode)}
            primaryText={hideName ? ' ' : this.renderLanguage(languageCode)}
          />
        ))}
      </SelectField>
    );
  }
}
