import React, { Component } from 'react';

import Page from 'src/components/Page/Page';

export default class StoryPage extends Component {
  render() {
    return (
      <Page>
        <h1>Components for an individual story will go here</h1>
      </Page>
    );
  }
}
