import React from 'react';
import classNames from 'classnames/bind';
import MUIPopover from 'material-ui/Popover';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import PropTypes from 'prop-types';
import { Icon } from 'im/ui/Icon';
import PopoverHeader from './PopoverHeader';
import PopoverList from './PopoverList';
import PopoverListItem from './PopoverListItem';
import cls from './Popover.module.css';

const cx = classNames.bind(cls);

export const ListItemSimple = ({ children, onClick, className }) => (
  <div
    className={cx(className, cls.listItemSimple, { clickable: !!onClick })}
    onClick={onClick}
  >
    {children}
  </div>
);

export class Popover extends React.PureComponent {
  static Header = PopoverHeader;
  static List = PopoverList;
  static ListItem = PopoverListItem;
  static ListItemSimple = ListItemSimple;

  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    // mui popover props
    open: PropTypes.bool,
    anchorEl: PropTypes.object,
    onRequestClose: PropTypes.func,
    anchorOrigin: PropTypes.object,
    targetOrigin: PropTypes.object,
    // custom props
    children: PropTypes.node,
    header: PropTypes.node,
    headerProps: PropTypes.object,
    footer: PropTypes.node,
    footerAction: PropTypes.func,
    footerLabel: PropTypes.any,
    footerIcon: PropTypes.string,
    compact: PropTypes.bool,
    padded: PropTypes.bool,
    dark: PropTypes.bool,
    large: PropTypes.bool,
    small: PropTypes.bool,
    fixedWidth: PropTypes.bool,
    absoluteLeft: PropTypes.bool,
    absoluteRight: PropTypes.bool,
    customWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    customHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    zIndex: PropTypes.number,
  };

  render() {
    const {
      className,
      children,
      header,
      footer,
      footerAction,
      footerLabel,
      footerIcon,
      customWidth,
      customHeight,
      fixedWidth,
      absoluteLeft,
      absoluteRight,
      compact,
      padded,
      dark,
      large,
      small,
      zIndex,
      style,
      headerProps,
      open,
      targetOrigin,
      anchorOrigin,
      onRequestClose,
      anchorEl,
      ...muiPopoverProps
    } = this.props;
    const layerZIndexOverride = getMuiTheme({ zIndex: { layer: zIndex } });
    const anchorElement = anchorEl?.getBoundingClientRect
      ? anchorEl
      : undefined;

    return (
      <MuiThemeProvider muiTheme={layerZIndexOverride}>
        <MUIPopover
          className={cx(
            'imui-popover',
            {
              'imui-popover-dark': dark,
              'imui-popover-large': large,
              'imui-popover-small': small,
              'imui-popover-padded': padded,
              'imui-popover-compact': compact,
              'imui-popover-fixed-width': fixedWidth,
              'imui-popover-absolute-left': absoluteLeft,
              'imui-popover-absolute-right': absoluteRight,
              'imui-popover-custom-width': customWidth,
            },
            'imui-popover-container',
            className
          )}
          {...muiPopoverProps}
          style={{
            ...style,
            ...{ maxWidth: customWidth },
            zIndex,
            fontFamily: 'averta, Arial',
          }}
          anchorEl={anchorElement}
          open={open}
          targetOrigin={targetOrigin}
          anchorOrigin={anchorOrigin}
          onRequestClose={onRequestClose}
          canAutoPosition={true}
        >
          <div className={cx('imui-popover-header')} {...headerProps}>
            {header}
          </div>
          <div
            className={cx('imui-popover-content')}
            style={{ ...{ maxHeight: customHeight } }}
          >
            {children}
          </div>
          <div className={cx('imui-popover-footer')}>
            {footer}
            {!(footerAction && footerLabel) ? null : (
              <button
                className={cx('imui-popover-footer-action')}
                onClick={footerAction}
              >
                {footerIcon && (
                  <Icon name={footerIcon} style={{ marginRight: '10px' }} />
                )}
                {footerLabel}
              </button>
            )}
          </div>
        </MUIPopover>
      </MuiThemeProvider>
    );
  }
}
