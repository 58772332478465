import pathOr from 'ramda/src/pathOr';

import cssColors from 'src/css/constants.json';
import { SECTION_BREAK } from 'src/data/questionTypes';
import Storage from 'src/services/Storage';

const PREERRED_LANGUAGE_KEY = 'IM:surveyPreferredLanguage';

export const getSurveyPreferredLanguage = () => {
  if (Storage.getItem(PREERRED_LANGUAGE_KEY)) {
    return Storage.getItem(PREERRED_LANGUAGE_KEY);
  }
  if (
    window.navigator.languages &&
    typeof window.navigator.languages[0] === 'string'
  ) {
    return window.navigator.languages[0].split('-')[0];
  }
  return (
    window.navigator.language ||
    window.navigator.userLanguage ||
    window.navigator.languages[0]
  )?.split('-')[0];
};

export const storeSurveyPreferredLanguage = (langCode) => {
  Storage.setItem(PREERRED_LANGUAGE_KEY, langCode);
};

export const getI18nCompleteness = (survey) => {
  const completeness = {
    errors: 0,
    questions: {},
    survey: { name: [] },
    options: {},
  };
  const i18nComplexity = survey.questions.reduce((acc, question) => {
    let questionComplexity = 1; // q title
    if (question.description) {
      questionComplexity += 1;
    }
    if (question.options) {
      questionComplexity += question.options.length;
    }
    if (question.matrixValues) {
      questionComplexity += question.matrixValues.length;
    }
    if (question.settings?.columns) {
      questionComplexity += question.settings.columns.length;
    }
    if (question.settings?.minLabel) {
      questionComplexity += 1;
    }
    if (question.settings?.midLabel) {
      questionComplexity += 1;
    }
    if (question.settings?.maxLabel) {
      questionComplexity += 1;
    }
    return acc + questionComplexity;
  }, 1); // 1 for survey title

  survey.languages.forEach((langCode, index) => {
    const isDefault = index === 0;
    let langCompleteness = 0;
    const surveyDict = survey.i18n[langCode] || {};
    completeness[langCode] = {
      survey: {},
      questions: {},
      options: {},
      errors: 0,
    };
    const currLang = completeness[langCode];

    if (surveyDict.name || (isDefault && survey.name)) {
      langCompleteness += 1;
    } else {
      completeness.survey.name.push(langCode);
      currLang.survey.name = true;
      currLang.errors += 1;
    }

    langCompleteness += survey.questions.reduce((acc, question) => {
      let questionCompleteness = 0;
      question.i18n ??= {};
      question.i18n[langCode] ??= {};
      currLang.questions[question.id] = { errors: 0 };
      completeness.questions[question.id] = completeness.questions[
        question.id
      ] || { errors: [], question: [], description: [] };

      // title or invisible section break
      if (
        question.i18n[langCode].question ||
        (isDefault && question.question) ||
        (question.type === SECTION_BREAK && question.settings.sectionHidden)
      ) {
        questionCompleteness += 1;
      } else {
        completeness.questions[question.id].question.push(langCode);
        completeness.questions[question.id].errors.push(langCode);
        currLang.questions[question.id].question = true;
        currLang.questions[question.id].errors += 1;
        currLang.errors += 1;
      }
      // desc only if default has it set
      if (question.description) {
        if (question.i18n[langCode].description || isDefault) {
          questionCompleteness += 1;
        } else {
          completeness.questions[question.id].description.push(langCode);
          completeness.questions[question.id].errors.push(langCode);
          currLang.questions[question.id].description = true;
          currLang.questions[question.id].errors += 1;
          currLang.errors += 1;
        }
      }

      if (question.options) {
        question.options.forEach((option) => {
          completeness.options[option.id] = completeness.options[option.id] || {
            title: [],
          };
          if (
            (isDefault && option.title) ||
            question.i18n[langCode][option.id]
          ) {
            questionCompleteness += 1;
          } else {
            completeness.questions[question.id].errors.push(langCode);
            completeness.options[option.id].title.push(langCode);
            currLang.options[option.id] = true;
            currLang.questions[question.id].errors += 1;
            currLang.errors += 1;
          }
        });
      }
      if (question.matrixValues) {
        question.matrixValues.forEach((matrixValue) => {
          completeness.options[matrixValue.id] = completeness.options[
            matrixValue.id
          ] || { title: [] };
          if (
            (isDefault && matrixValue.title) ||
            question.i18n[langCode][matrixValue.id]
          ) {
            questionCompleteness += 1;
          } else {
            completeness.questions[question.id].errors.push(langCode);
            completeness.options[matrixValue.id].title.push(langCode);
            currLang.options[matrixValue.id] = true;
            currLang.questions[question.id].errors += 1;
            currLang.errors += 1;
          }
        });
      }

      if (question.settings?.columns) {
        question.settings.columns.forEach((column) => {
          completeness.options[column.id] = completeness.options[column.id] || {
            title: [],
          };
          if (
            (isDefault && column.title) ||
            question.i18n[langCode][column.id]
          ) {
            questionCompleteness += 1;
          } else {
            completeness.questions[question.id].errors.push(langCode);
            completeness.options[column.id].title.push(langCode);
            currLang.options[column.id] = true;
            currLang.questions[question.id].errors += 1;
            currLang.errors += 1;
          }
        });
      }

      completeness.questions[question.id].settings ??= {
        minLabel: [],
        maxLabel: [],
        midLabel: [],
      };

      if (question.settings.minLabel) {
        if (
          (isDefault && question.settings.minLabel) ||
          question.i18n[langCode].settings?.minLabel
        ) {
          questionCompleteness += 1;
        } else {
          completeness.questions[question.id].settings.minLabel.push(langCode);
          completeness.questions[question.id].errors.push(langCode);
          currLang.questions[question.id].errors += 1;
          currLang.errors += 1;
        }
      }

      if (question.settings.maxLabel) {
        if (
          (isDefault && question.settings.maxLabel) ||
          question.i18n[langCode].settings?.maxLabel
        ) {
          questionCompleteness += 1;
        } else {
          completeness.questions[question.id].settings.maxLabel.push(langCode);
          completeness.questions[question.id].errors.push(langCode);
          currLang.questions[question.id].errors += 1;
          currLang.errors += 1;
        }
      }

      if (question.settings.midLabel) {
        if (
          (isDefault && question.settings.midLabel) ||
          question.i18n[langCode].settings?.midLabel
        ) {
          questionCompleteness += 1;
        } else {
          completeness.questions[question.id].settings.midLabel.push(langCode);
          completeness.questions[question.id].errors.push(langCode);
          currLang.questions[question.id].errors += 1;
          currLang.errors += 1;
        }
      }

      return acc + questionCompleteness;
    }, 0);

    const completenessRatio = langCompleteness / i18nComplexity;
    completeness.errors += currLang.errors;
    completeness[langCode] = {
      ...completeness[langCode],
      percentage: `${Math.round(completenessRatio * 100)}%`,
      value: completenessRatio,
      complete: completenessRatio === 1,
      cssColor: completenessRatio === 1 ? cssColors.green : cssColors.red,
    };
  });

  return completeness;
};

export const getSurveyI18nFromSurveyAnswers = (surveyAnswersData) => ({
  name: surveyAnswersData.info?.survey_name,
  languages: surveyAnswersData.info?.survey_languages,
  i18n: surveyAnswersData.info?.survey_i18n,
  activeLanguage: getSurveyPreferredLanguage(),
});

// alt path is require to support old surveys where i18n was not set and for
// the default language we want to return value from the question if value was
// not set on question's i18n object for the default language if === active
export const getSurveyI18n = (survey, question, path, altPath) => {
  const surveyDataSrc = survey.info?.id
    ? getSurveyI18nFromSurveyAnswers(survey)
    : survey;
  const activeDict = question.i18n?.[surveyDataSrc.activeLanguage] || {};
  const isDefault = surveyDataSrc.languages[0] == surveyDataSrc.activeLanguage;

  if (typeof path === 'string' && activeDict[path]) return activeDict[path];
  if (isDefault && typeof altPath === 'string' && question[altPath])
    return question[altPath];

  if (Array.isArray(path) && pathOr(null, path, activeDict))
    return pathOr(null, path, activeDict);
  if (Array.isArray(altPath) && pathOr(null, altPath, activeDict))
    return pathOr(null, altPath, activeDict);
  if (Array.isArray(path) && pathOr(null, path, question))
    return pathOr(null, path, question);
  if (isDefault && Array.isArray(altPath) && pathOr(null, altPath, question))
    return pathOr(null, altPath, question);
  return '';
};

export const getSurveyName = (survey) =>
  survey.i18n?.[survey.activeLanguage]?.name ||
  (survey?.activeLanguage === survey?.languages[0] ? survey.name : '');
