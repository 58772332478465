import React from 'react';

import DateDropdown, {
  isValidDate as isValidDateOriginal,
} from './DateDropdown';

const isNotFullDate = ({ day, month, year }) => day && (!month || !year);
const getError = (value) =>
  !isNotFullDate(value) ? '' : 'Day requires selecting month and year';

export const isValidDate = (value = {}) => {
  if (!value.day) return true;
  return !isNotFullDate(value) && isValidDateOriginal(value);
};

const DateDropdownExtended = (props) => (
  <DateDropdown {...props} validationFn={getError} />
);

DateDropdownExtended.propTypes = {
  ...DateDropdown.propTypes,
};

export default DateDropdownExtended;
