import PropTypes from 'prop-types';
import React from 'react';
import {
  Card,
  EmptyText,
  Button,
  Actions,
  Container,
} from 'src/components/IMUI';
import {
  TagGroupList,
  TagListItem,
  TagGroupItem,
} from 'src/components/TagList';
import TagGroupListHeader from 'src/components/TagList/TagGroupListHeader';
import cls from './AnalysisTagCategories.module.css';
import { Icon } from 'im/ui';
import { where } from 'im/api/Query';
import ExportConfirmation from '../ExportConfirmation/ExportConfirmation';
import { ReportContentExport } from 'src/api/ReportContentExport';
import store from 'src/store';

const DENY_GROUPS = ['00000000-0000-0000-0000-000000000000'];
const tagWithCode = (tag = {}, showCodes) =>
  showCodes && tag.code?.length > 0
    ? `${tag.code}${tag.code.endsWith('.') ? '' : '.'} ${tag.title}`
    : tag.title;

export default class AnalysisTagCategories extends React.PureComponent {
  static propTypes = {
    tagCategories: PropTypes.object,
    tagCategoriesList: PropTypes.object,
    enabledTagCategories: PropTypes.array,
    tagCategorySelectedId: PropTypes.string,
    tagCategoryFilterText: PropTypes.string,
    change: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    onCategorySelect: PropTypes.func.isRequired,
    onCategoriesFilter: PropTypes.func.isRequired,
    projectId: PropTypes.any,
  };

  static defaultProps = { enabledTagCategories: [] };
  state = { expandAll: true, showCodes: true, exportModalShow: false };

  getCategoryAncestors = (tagCategory) => {
    if (!tagCategory.parent) return [];
    const parent = this.props.tagCategories.data.find(
      ({ id }) => id === tagCategory.parent.id
    );
    return !parent ? [] : [parent].concat(this.getCategoryAncestors(parent));
  };

  getCategoryChildrenAll = (tagCategory) => {
    if (!tagCategory.child_categories?.length) return [];
    const children = this.props.tagCategories.data.filter(({ id }) =>
      tagCategory.child_categories.find((el) => el.id === id)
    );
    return !children
      ? []
      : children.concat(
          children.flatMap((child) => this.getCategoryChildrenAll(child))
        );
  };

  handleToggleTagCategory = (isChecked, tagCategory) => {
    let newEnabledTagCategories = [];

    if (isChecked && !this.isTagCategorySelected(tagCategory)) {
      newEnabledTagCategories = this.props.enabledTagCategories.filter(
        (tcId) =>
          !this.getCategoryChildrenAll(tagCategory).find(({ id }) => id == tcId)
      );
      newEnabledTagCategories = newEnabledTagCategories.concat([
        tagCategory.id,
      ]);
    } else {
      newEnabledTagCategories = this.props.enabledTagCategories.filter(
        (tcId) =>
          tcId !== tagCategory.id &&
          !this.getCategoryAncestors(tagCategory).find(({ id }) => id == tcId)
      );
    }
    this.props.change(newEnabledTagCategories);
  };

  handleToggleSelectAll = (doSelectAll) => {
    this.props.change(
      doSelectAll
        ? this.props.tagCategories.data.map(({ id }) => id)
        : DENY_GROUPS
    );
  };
  handleToggleExpandAll = () => {
    this.setState({ expandAll: !this.state.expandAll });
  };
  handleToggleShowCodes = () => {
    this.setState({ showCodes: !this.state.showCodes });
  };
  isTagCategorySelected = (tc) => {
    return (
      this.props.enabledTagCategories.includes(tc.id) ||
      this.getCategoryAncestors(tc).some((ancestor) =>
        this.props.enabledTagCategories.includes(ancestor.id)
      )
    );
  };
  handleExportOrg = () => {
    store
      .dispatch(
        ReportContentExport.api.tags_for_organization(
          where({ projectId: this.props.projectId })
        )
      )
      .then(() => this.setState({ exportModalShow: true }));
  };
  handleCloseExportModal = () => {
    this.setState({ exportModalShow: false });
  };
  renderCategories = (categories) =>
    categories
      .sort((tag1, tag2) =>
        tagWithCode(tag1, this.state.showCodes).localeCompare(
          tagWithCode(tag2, this.state.showCodes),
          'en',
          { numeric: this.state.showCodes }
        )
      )
      .map((tagCategory) => (
        <TagGroupItem
          key={tagCategory.id}
          tagGroup={tagCategory}
          isOpen={this.state.expandAll}
          isSelected={this.isTagCategorySelected(tagCategory)}
          onToggleSelect={this.handleToggleTagCategory}
          showCodes={this.state.showCodes}
        >
          {!tagCategory.child_categories?.length &&
            !tagCategory.tags?.length && (
              <li className={cls.tagCategoryPlaceholder}>
                <EmptyText text="No tags" />
              </li>
            )}
          {(tagCategory.tags || [])
            .sort((tag1, tag2) =>
              tagWithCode(tag1, this.state.showCodes).localeCompare(
                tagWithCode(tag2, this.state.showCodes),
                'en',
                { numeric: this.state.showCodes }
              )
            )
            .map((tag) => (
              <TagListItem
                key={tag.id}
                tag={tag}
                showCodes={this.state.showCodes}
              />
            ))}
          {tagCategory.child_categories?.length > 0 &&
            this.renderCategories(tagCategory.child_categories)}
        </TagGroupItem>
      ));

  render() {
    const ACTIVE_TIP =
      'By default when you create a new analysis, all tag groups that have been used in your organization are enabled. Deselect any tags that are not relevant to this project below';
    const COLLAPSE_TIP =
      'Collapse or expand tag groups, so you have a broader or detailed vision of all your tags';
    const SELECT_ALL_TIP =
      'All tags are selected. Every Tag groups are available to be used';
    const SELECT_NONE_TIP = 'No tag group is enabled to be used';
    const realTagCategories = this.props.enabledTagCategories.filter(
      (e) => !DENY_GROUPS.includes(e)
    );
    const noneSelected = realTagCategories == 0;
    const available = (this.props.tagCategoriesList?.data || [])
      .map((t) => t.id)
      .filter((t) => !DENY_GROUPS.includes(t));
    const allSelect =
      realTagCategories.length >= available.length &&
      available.every((a) => realTagCategories.includes(a));
    const totalLabel = allSelect
      ? 'every group'
      : realTagCategories.length > 0
      ? `${realTagCategories.length} groups`
      : 'no group';
    const pending =
      this.props.tagCategories.pending.init ||
      this.props.tagCategories.pending.ui;

    return (
      <>
        <Container horizontal centered max={1024} style={{ margin: 'auto' }}>
          <Actions>
            <h3>
              Active tag groups&nbsp;
              <Icon
                placeTip="right"
                name="question-filled"
                tip={ACTIVE_TIP}
                color="#626264"
                style={{ fontSize: 14 }}
              />
              <small>
                <small>({totalLabel} selected)</small>
              </small>
            </h3>
          </Actions>

          <Actions>
            {
              <span>
                &nbsp;&nbsp;
                <Icon
                  placeTip="bottom"
                  name={this.state.showCodes ? 'text-field' : 'number'}
                  style={{ fontSize: 14 }}
                  color="#626264"
                />
                <Button
                  text
                  size="l"
                  className={cls.tagCategoriesListToggle}
                  label={this.state.showCodes ? 'Hide Codes' : 'Show Codes'}
                  onClick={() => this.handleToggleShowCodes()}
                />
              </span>
            }
            {
              <span>
                &nbsp;&nbsp;
                <Icon
                  placeTip="bottom"
                  name="collapse"
                  tip={COLLAPSE_TIP}
                  style={{ fontSize: 14 }}
                  color="#626264"
                />
                <Button
                  text
                  size="l"
                  className={cls.tagCategoriesListToggle}
                  label={this.state.expandAll ? 'Collapse' : 'Expand'}
                  onClick={() => this.handleToggleExpandAll()}
                />
              </span>
            }
            {
              <span>
                &nbsp;&nbsp;
                <Icon
                  placeTip="bottom"
                  name="question-filled"
                  tip={noneSelected ? SELECT_ALL_TIP : SELECT_NONE_TIP}
                  style={{ fontSize: 14 }}
                  color="#626264"
                />
                <Button
                  text
                  size="l"
                  className={cls.tagCategoriesListToggle}
                  label={noneSelected ? 'Select All' : 'Deselect All'}
                  onClick={() => this.handleToggleSelectAll(noneSelected)}
                />
              </span>
            }
          </Actions>
        </Container>

        <Container grow className={cls.analysisTagsContainer}>
          <br />
          <Card className={cls.tagCategoriesList}>
            <TagGroupListHeader
              tagCategorySelectedId={this.props.tagCategorySelectedId}
              tagCategoryFilterText={this.props.tagCategoryFilterText}
              allTagCategories={this.props.tagCategoriesList.data}
              onSearch={this.props.onSearch}
              onCategorySelect={this.props.onCategorySelect}
              onCategoriesFilter={this.props.onCategoriesFilter}
            />
            <TagGroupList pending={pending}>
              {this.renderCategories(this.props.tagCategories.data)}
            </TagGroupList>
          </Card>
          <ExportConfirmation
            open={this.state.exportModalShow}
            onRequestClose={this.handleCloseExportModal}
          />
        </Container>
      </>
    );
  }
}
