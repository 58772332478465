import entityActionCreator from '../actionCreators/entityActionCreator';
import entityReducer from '../reducers/entityReducer';

import AsyncActionsGenerator from './AsyncActionsGenerator';
import EntityApi from './EntityApi';
import SyncActionsGenerator from './SyncActionsGenerator';

export default class EntityBase {
  constructor(apiConfig, reducerName) {
    this.type = apiConfig.type;
    this.root = apiConfig.root;
    this.reducerConfig = apiConfig.reducerConfig;
    this.reducerName = reducerName || this.type;
    this.actionTypeRoot = `${this.type}/${this.reducerName}`;
  }

  getActionTypes(actionsConfig) {
    return Object.keys(actionsConfig).reduce(
      (acc, actionName) => ({
        ...acc,
        [actionName]:
          actionsConfig[actionName].types || actionsConfig[actionName].type,
      }),
      {}
    );
  }

  runRequest({
    action,
    params,
    cancellationSource,
    root,
    type,
    meta = {},
  } = {}) {
    const entityApi = new EntityApi(
      root || this.root,
      type || this.type,
      action,
      params,
      cancellationSource
    );
    const request = entityApi.getRequest();
    const requestMeta = { ...entityApi.meta, method: action.method, ...meta };

    return entityApi.noDispatch
      ? request()
      : entityActionCreator(action.types, request, requestMeta);
  }

  getSyncActions(configActions, defaultActions) {
    const syncActionsConfig = SyncActionsGenerator.makeActions(
      configActions,
      this.actionTypeRoot,
      defaultActions
    );

    const syncActions = Object.keys(syncActionsConfig).reduce(
      (acc, actionName) => ({
        ...acc,
        [actionName]: (...params) =>
          syncActionsConfig[actionName].action.call(
            this,
            syncActionsConfig[actionName].type,
            ...params
          ),
      }),
      {}
    );

    return { syncActions, syncActionsConfig };
  }

  getAsyncActions(configActions, actionFn, defaultActions, opts) {
    const asyncActionsConfig = AsyncActionsGenerator.makeActions(
      configActions,
      this.actionTypeRoot,
      defaultActions,
      opts
    );

    const asyncActions = Object.keys(asyncActionsConfig).reduce(
      (acc, action) => {
        return {
          ...acc,
          [action]: (...params) =>
            actionFn(asyncActionsConfig[action], ...params),
        };
      },
      {}
    );

    return { asyncActions, asyncActionsConfig };
  }

  getReducers(actionTypes, reducers) {
    if (!reducers || typeof reducers !== 'function') {
      return null;
    }
    return (...params) => reducers(actionTypes, ...params);
  }

  getReducer(actionsConfig, reducerFn, reducerFnParams) {
    const allActionTypes = Object.values(actionsConfig).reduce(
      (acc, config) => {
        const types = config.types
          ? Object.values(config.types)
          : [config.type];
        return acc.concat(types);
      },
      []
    );

    return entityReducer(reducerFn)(allActionTypes, {
      ...reducerFnParams,
      ...this.reducerConfig,
    });
  }
}
