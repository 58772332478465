import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FieldArray } from 'redux-form';

import ActionButtons from './ActionButtons';
import TagsDetailedItem from './TagDetailedItem';

// import cls from "./TagCategoryForm.module.css";

export default class TagsDetailed extends Component {
  static propTypes = {
    tags: PropTypes.array,
    errors: PropTypes.array,
    mode: PropTypes.oneOf(['quick', 'detailed', 'edit']),
    onAddItem: PropTypes.func.isRequired,
    onEditItem: PropTypes.func.isRequired,
    onModeChange: PropTypes.func.isRequired,
    onTouchFields: PropTypes.func.isRequired,
    onMergeStart: PropTypes.func,
  };

  static defaultProps = {
    errors: [],
    tags: [],
  };

  constructor(props) {
    super(props);

    if (!props.tags || !props.tags.length) {
      this.handleAddTagItem();
    }
  }

  handleRemitTags = (tags) => {
    tags.forEach(
      (t, index) =>
        !t.remitted && this.handleEditTag(index, { ...t, remitted: true })
    );
  };

  handleAddTagItem = () => {
    const { tags } = this.props;
    if (!this.areTagsValid()) return;

    this.handleRemitTags(tags);
    this.props.onAddItem({ title: '', description: '', code: '' });
  };

  handleEditTag = (index, newTag) => {
    this.props.onEditItem(index, 1, newTag);
  };

  areTagsValid = () => {
    const { errors } = this.props;
    const presentErrors = errors
      .map((e, index) => ({
        index,
        hasError: !!e.title,
      }))
      .filter((v) => v.hasError);

    if (presentErrors.length) {
      const fieldNames = presentErrors.map(
        ({ index }) => `tags[${index}].title`
      );
      this.props.onTouchFields(...fieldNames);
      return false;
    }

    return true;
  };

  renderTags = ({ fields }) => {
    return (
      <div>
        {fields.map((tag, index) => (
          <TagsDetailedItem
            key={index}
            name={tag}
            tag={fields.get(index)}
            onEdit={(newTag) => this.handleEditTag(index, newTag)}
            onMergeStart={this.props.onMergeStart}
          />
        ))}
      </div>
    );
  };

  render() {
    const { mode } = this.props;

    return (
      <div>
        <FieldArray
          rerenderOnEveryChange
          name="tags"
          component={this.renderTags}
        />
        <ActionButtons
          mode={mode}
          onAddAnother={this.handleAddTagItem}
          onModeChange={this.props.onModeChange}
        />
      </div>
    );
  }
}
