import {
  GRANTEES__GET_REQUEST,
  GRANTEES__GET_SUCCESS,
  GRANTEES__GET_FAILURE,
  GRANTEE__CREATE_SUCCESS,
  GRANTEE__CREATE_FAILURE,
  GRANTEE__UPDATE_SUCCESS,
  GRANTEE__DELETE_SUCCESS,
  GRANTEE__CONTACT_CREATE_SUCCESS,
  GRANTEE__CONTACT_DELETE_SUCCESS,
} from 'src/constants';

import { updateObject, updateItem, removeItem } from './utils';

const initialState = { items: [], lastAddedId: null, pending: false };

const createGrantee = (state, payload) =>
  updateObject(state, {
    items: state.items.concat([payload.grantee]),
    lastAddedId: payload.grantee.id,
  });
const deleteGrantee = (state, payload) =>
  updateObject(state, { items: removeItem(state.items, payload.granteeId) });
const updateGrantee = (state, payload) =>
  updateObject(state, {
    items: updateItem(state.items, payload.grantee.id, (g) =>
      updateObject(g, payload.grantee)
    ),
  });
const createGranteeContact = (state, payload) =>
  updateObject(state, {
    items: updateItem(state.items, payload.granteeId, (g) =>
      updateObject(g, {
        contacts: g.contacts.concat([payload.contact]),
        contacts_count: g.contacts_count + 1,
      })
    ),
  });
const deleteGranteeContact = (state, payload) =>
  updateObject(state, {
    items: updateItem(state.items, payload.granteeId, (g) =>
      updateObject(g, {
        contacts: removeItem(g.contacts, payload.contactId),
        contacts_count: g.contacts_count - 1,
      })
    ),
  });

export default function granteesReducer(state = initialState, action) {
  switch (action.type) {
    case GRANTEES__GET_REQUEST:
      return { ...state, pending: true };
    case GRANTEES__GET_SUCCESS:
      return { ...state, items: action.payload.data, pending: false };
    case GRANTEES__GET_FAILURE:
      return { ...state, items: [], pending: false };

    case GRANTEE__CREATE_SUCCESS:
      return createGrantee(state, action.payload);
    case GRANTEE__CREATE_FAILURE:
      return { ...state, lastAddedId: null };

    case GRANTEE__DELETE_SUCCESS:
      return deleteGrantee(state, action.payload);
    case GRANTEE__UPDATE_SUCCESS:
      return updateGrantee(state, action.payload);

    case GRANTEE__CONTACT_CREATE_SUCCESS:
      return createGranteeContact(state, action.payload);
    case GRANTEE__CONTACT_DELETE_SUCCESS:
      return deleteGranteeContact(state, action.payload);

    default:
      return state;
  }
}
