import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import cls from './Expandable.module.css';

const cx = classNames.bind(cls);

export default class ExpandableList extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    isExpanded: PropTypes.bool,
  };

  state = {
    realHeight: 0,
  };

  handleRef = (ref) => {
    this.setState({ realHeight: ref?.scrollHeight });
  };

  render() {
    return (
      <div
        ref={this.handleRef}
        className={cx(cls.expandableList, this.props.className)}
        style={{ maxHeight: this.props.isExpanded ? this.state.realHeight : 0 }}
      >
        {this.props.children}
      </div>
    );
  }
}
