import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, Button, Container } from 'src/components/IMUI/index';
import { TextField } from 'src/components/IMUI';
import isEmail from 'validator/lib/isEmail';
import { connect } from 'react-redux';
import { showNotification } from 'src/actionCreators/notificationsActionCreators';

@connect(null, { showNotification })
export default class ExportConfiguration extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    onRequestClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    showNotification: PropTypes.func,
  };

  state = { email: null };

  handleSubmit = () => {
    if (isEmail(this.state.email)) {
      this.props.onSubmit(this.state.email);
      this.setState({ email: null });
      return;
    }

    this.props.showNotification({
      title: 'Email address is invalid',
      level: 'error',
    });
  };

  render() {
    const { open } = this.props;

    return (
      <Dialog
        title={'Email'}
        small
        open={open}
        onRequestClose={this.props.onRequestClose}
        leftActions={
          <Button
            negative
            size="l"
            label="Close"
            onClick={this.props.onRequestClose}
          />
        }
        rightActions={
          <Button size="l" label="Share" onClick={this.handleSubmit} />
        }
      >
        <Container>
          <TextField
            type="email"
            name="email"
            hintText="Enter an email address"
            value={this.state.email}
            onChange={(value) => {
              this.setState({ email: value });
            }}
          />
        </Container>
      </Dialog>
    );
  }
}
