import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import Pagination from './Pagination';

export default function PaginationIm({
  number,
  size,
  last,
  totalItems,
  label,
  compact,
  onChange,
  perPageOptions,
}) {
  const handleChange = useCallback(
    ({ page, per_page }) => {
      onChange({ page: page + 1, per_page });
    },
    [onChange]
  );

  if (compact && number === 1 && last === number) {
    return null;
  }

  return (
    <Pagination
      per_page={size}
      page={number - 1}
      total_pages={last}
      total_items={totalItems}
      items_label={label}
      compact={compact}
      perPageOptions={perPageOptions}
      onChange={handleChange}
    />
  );
}

PaginationIm.propTypes = {
  number: PropTypes.number,
  size: PropTypes.number,
  last: PropTypes.number,
  totalItems: PropTypes.number,
  label: PropTypes.string,
  perPageOptions: PropTypes.array,

  compact: PropTypes.bool,
  onChange: PropTypes.func,
};
