import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { Element } from 'react-scroll';

import { normalizeFormName } from 'src/utils/string';

import cls from './FormField.module.css';

const cx = classNames.bind(cls);

export class FormField extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
    inlineBlock: PropTypes.bool,
    extraSpacing: PropTypes.bool,
    anchorScrollName: PropTypes.string,
    className: PropTypes.string,
  };

  render() {
    const { children, extraSpacing, inlineBlock, anchorScrollName, className } =
      this.props;

    return (
      <div
        className={cx(
          'imui-form-field',
          {
            'imui-form-field-inlineBlock': inlineBlock,
            'imui-form-field-extra-spacing': extraSpacing,
          },
          className
        )}
      >
        {/* Remove square brackets cause it is forbidden in element name */}
        {anchorScrollName && (
          <Element name={`position-${normalizeFormName(anchorScrollName)}`} />
        )}
        {children}
      </div>
    );
  }
}

export default FormField;
