import React from 'react';
import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import { reduxForm, Field } from 'redux-form';
import isEmail from 'validator/lib/isEmail';
import usersApi from 'src/api/Users';
import AuthForm from 'src/components/AuthForm/AuthForm';
import { Checkbox, Button } from 'src/components/IMUI';
import history from 'src/historyInstance';
import { validate as validatePassword } from 'src/pages/Password/ResetPassword';
import createValidator from 'src/utils/validation';
import { where } from 'im/api/Query';
import cls from './Register.module.css';
const CHECKBOX_STYLE = { fill: '#221e20' };
const LABEL_STYLE = { zIndex: 3 };
const FORM_NAME = 'registerForm';
const papers = [
  {
    name: 'General Terms and Conditions,',
    path: 'https://www.impactmapper.com/legal-general-terms',
  },
  { name: 'Use policy,', path: 'https://www.impactmapper.com/use-policy' },
  {
    name: 'Privacy policy,',
    path: 'https://www.impactmapper.com/privacy-policy',
  },
  {
    name: 'and SaaS Terms and Conditions',
    path: 'https://www.impactmapper.com/compliance',
  },
];

@connect(pick(['users']), { createUser: usersApi.create })
class Register extends React.PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    createUser: PropTypes.func.isRequired,
    match: ReactRouterPropTypes.match,
    users: PropTypes.object,
  };

  onSubmit = (data) => {
    this.props.createUser(
      where().payload({ ...data, code: this.props.match.params.invitationCode })
    );
  };
  onRedirectToLogin() {
    history.push('/login');
  }
  onPaperOpen(ev, url) {
    ev.stopPropagation();
    window.open(url, '_blank');
  }
  render() {
    return (
      <AuthForm
        showFullName
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        title="Create account"
        submitButtonLabel="Submit"
        errors={this.props.users.errors}
        errorsTemplate={{
          422: 'Registration failed.\nUnknown email, invalid or expired registration code',
        }}
        additionalAction={
          <Button
            text
            dark
            underlined
            type="button"
            label={'Already have an account?\nLog in'}
            onClick={this.onRedirectToLogin}
          />
        }
      >
        <Field
          name="terms_conditions"
          noValidation={false}
          component={Checkbox}
          label={
            <div>
              I agree with&nbsp;
              {papers.map(({ name, path }) => (
                <span style={{ marginLeft: 3, marginRight: 3 }} key={path}>
                  <Button
                    text
                    dark
                    underlined
                    className={cls.linkText}
                    onClick={(ev) => this.onPaperOpen(ev, path)}
                    label={name}
                  />
                </span>
              ))}
            </div>
          }
          iconStyle={CHECKBOX_STYLE}
          labelStyle={LABEL_STYLE}
          format={(v) => !!v}
        />
      </AuthForm>
    );
  }
}

function validate(values) {
  const not = (fn) => (v) => !fn(v);
  const isDefined = (v) => Boolean(v);

  const validator = createValidator({
    email: [
      [not(isDefined), () => 'Email is required'],
      [not(isEmail), () => 'It is not a valid email'],
    ],
    terms_conditions: [[not(isDefined), () => 'Accept Terms and Conditions']],
  });
  return { ...validatePassword(values), ...validator(values) };
}

export default reduxForm({ form: FORM_NAME, validate })(Register);
