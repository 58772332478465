import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import { Container } from 'src/components/IMUI';

import cls from './TileContainer.module.css';

const cx = classNames.bind(cls);

const TileContainer = ({
  children,
  className,
  horizontal,
  spacing,
  centered,
}) => {
  const classNameArray = [
    'imui-tile-container',
    `imui-tile-spacing-${spacing}`,
    `imui-tile-${horizontal ? 'horizontal' : 'vertical'}-${
      centered ? 'centered' : ''
    }`,
    className,
  ];

  return (
    <Container className={cx(...classNameArray)} horizontal={horizontal}>
      {children}
    </Container>
  );
};

TileContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  horizontal: PropTypes.bool,
  centered: PropTypes.bool,
  spacing: PropTypes.oneOf(['m']),
};

TileContainer.defaultProps = {
  horizontal: true,
  spacing: 'm',
};

export default TileContainer;
