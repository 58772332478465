import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Form, Field } from 'redux-form';
import { FullBgView } from 'src/components/FullBgView';
import { Button, TextField, FormField, Progress } from 'src/components/IMUI';
import cls from './AuthForm.module.css';
const cx = classNames.bind(cls);

export default class AuthForm extends React.PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    submitButtonLabel: PropTypes.string.isRequired,
    children: PropTypes.node,
    additionalAction: PropTypes.node,
    errors: PropTypes.array,
    errorsTemplate: PropTypes.object,
    info: PropTypes.string,
    showFullName: PropTypes.bool,
    pending: PropTypes.bool,
  };

  static defaultProps = { additionalAction: null };

  render() {
    const {
      onSubmit,
      title,
      submitButtonLabel,
      errors,
      info,
      additionalAction,
      errorsTemplate,
      pending,
    } = this.props;

    return (
      <FullBgView hasLogo>
        <Form noValidate className={cx('authForm')} onSubmit={onSubmit}>
          <FullBgView.Box
            limitedWidth
            title={title}
            rightAction={
              <Button
                dark
                type="submit"
                size="l"
                label={submitButtonLabel}
                onClick={onSubmit}
              />
            }
            leftAction={additionalAction}
            info={info}
            errors={errors}
            errorsTemplate={errorsTemplate}
          >
            {pending && <Progress large />}
            {!pending && (
              <div>
                {!this.props.showFullName ? null : (
                  <FormField>
                    <Field
                      fullWidth
                      component={TextField}
                      name="full_name"
                      hintText="Full name"
                    />
                  </FormField>
                )}
                <FormField>
                  <Field
                    fullWidth
                    component={TextField}
                    name="email"
                    type="email"
                    autoComplete="email"
                    hintText="Email"
                  />
                </FormField>
                <FormField>
                  <Field
                    fullWidth
                    component={TextField}
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    hintText="Password"
                  />
                </FormField>
                {this.props.children}
              </div>
            )}
          </FullBgView.Box>
        </Form>
      </FullBgView>
    );
  }
}
