import { UploaderSecureMapping } from 'src/api/UploaderSecureMapping';
import history from 'src/historyInstance';

export default function uploaderSecureEffects(_state, action, dispatch) {
  switch (action.type) {
    case UploaderSecureMapping.actionTypes.put.success: {
      if (!action?.meta.finish) return null;
      dispatch(UploaderSecureMapping.actions.clear());
      return history.replace(`/grants/import/${action.payload.data.id}/report`);
    }

    default:
      return null;
  }
}
