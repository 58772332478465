import { capitalize } from 'src/utils/string';

import CURRENCIES from 'src/data/currencies.json';
import { getOrgText } from 'src/services/DictionaryService';
import { getCountryNameByCode } from 'src/utils/countries';
import store from 'src/store';

const AMOUNT_REGEX = /^(0\.\d+)|([1-9]\d*(\.\d+)?)$/;
const DATE_REGEX = /\b\d{4}[-\/]\d{2}[-\/]\d{2}\b/g;
const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const fundingOptions = () => {
  const options = [];

  (store.getState()?.organizationCurrent?.data?.funding_types || []).forEach(
    (fundingOption) => {
      options.push({
        label: capitalize(fundingOption),
        value: fundingOption,
      });
    }
  );

  return options.sort((a, b) => (a.label || '-').localeCompare(b?.label));
};

const grantStatusOptions = () => {
  const options = [];

  (store.getState()?.organizationCurrent?.data?.grant_statuses || []).forEach(
    (grantStatusOption) => {
      options.push({
        label: capitalize(grantStatusOption),
        value: grantStatusOption,
      });
    }
  );

  return options.sort((a, b) => (a.label || '-').localeCompare(b?.label));
};
const NON_EMPTY = /^(?!\s*$).+/;
const GRANT_RECIPIENT_TYPES = ['individual', 'organization', 'none'];

const countryOptions = () => {
  const options = [];

  const regionMapping =
    store.getState()?.organizationCurrent?.data?.custom_region_mapping;

  Object.values(regionMapping).forEach((countryCodes) => {
    countryCodes.forEach((countryCode) => {
      options.push({
        label: getCountryNameByCode(countryCode) || countryCode,
        value: countryCode.toLowerCase(),
      });
    });
  });

  return options.sort((a, b) => (a.label || '-').localeCompare(b?.label));
};

export const defaultGrantFields = () => [
  {
    label: `${getOrgText('Grant')} Name`,
    alternateMatches: ['grant name'],
    key: 'Grant Name',
    fieldType: {
      type: 'input',
    },
    validations: [
      {
        rule: 'required',
        errorMessage: `${getOrgText('Grant')} Name is required`,
        level: 'error',
      },
    ],
  },
  {
    label: `${getOrgText('Grant')} Number`,
    alternateMatches: ['grant number'],
    key: 'Grant Number',
    fieldType: {
      type: 'input',
    },
  },
  {
    label: `${getOrgText('Grant')} Description`,
    alternateMatches: ['grant description', 'description'],
    key: 'Grant Description',
    fieldType: {
      type: 'input',
    },
  },
  {
    label: `${getOrgText('Grant')} Contact Name`,
    alternateMatches: ['grant contact name', 'contact name'],
    key: 'Grant Contact Name',
    fieldType: {
      type: 'input',
    },
  },
  {
    label: `${getOrgText('Grant')} Contact Email`,
    alternateMatches: ['grant contact email', 'contact email'],
    key: 'Grant Contact Email',
    fieldType: {
      type: 'input',
    },
    validations: [
      {
        rule: 'regex',
        value: '[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,}',
        errorMessage: `${getOrgText('Grant')} Contact Email format is invalid`,
        level: 'warning',
      },
    ],
  },
  {
    label: `${getOrgText('Grant')} Recipient Type`,
    alternateMatches: ['grant recipient type', 'recipient type'],
    key: 'Grant Recipient Type',
    fieldType: {
      type: 'select',
      options: GRANT_RECIPIENT_TYPES.map((recipient_type) => ({
        label: capitalize(recipient_type),
        value: recipient_type,
      })),
    },
    validations: [
      {
        rule: 'regex',
        value: NON_EMPTY,
        errorMessage: `${getOrgText(
          'Grant'
        )} Recipient Type will default to "Organization"`,
        level: 'warning',
      },
    ],
  },
  {
    label: `${getOrgText('Grant')} Status`,
    alternateMatches: ['grant status', 'status'],
    key: 'Grant Status',
    fieldType: {
      type: 'select',
      options: grantStatusOptions(),
    },
    validations: [
      {
        rule: 'regex',
        value: NON_EMPTY,
        errorMessage: `${getOrgText('Grant')} Status cannot be blank`,
        level: 'error',
      },
    ],
  },
  {
    label: 'Donor List',
    alternateMatches: ['grant donor list', 'donors', 'donor list'],
    key: 'Grant Donor List',
    fieldType: {
      type: 'input',
    },
  },
  {
    label: 'Strategies List',
    alternateMatches: [
      'grant strategies list',
      'partner strategies',
      'strategies',
      'strategies list',
    ],
    key: 'Grant Strategies List',
    fieldType: {
      type: 'input',
    },
  },
  {
    label: 'Cohort List',
    alternateMatches: ['grant cohort list', 'cohorts', 'cohort list'],
    key: 'Grant Cohort List',
    fieldType: {
      type: 'input',
    },
  },
  {
    label: 'Areas Of Work List',
    alternateMatches: [
      'grant area of work list',
      'areas of work',
      'thematic areas',
    ],
    key: 'Grant Area Of Work List',
    fieldType: {
      type: 'input',
    },
  },
  {
    label: 'Portfolio List',
    alternateMatches: ['grant portfolio list', 'portfolios', 'portfolio list'],
    key: 'Grant Portfolio List',
    fieldType: {
      type: 'input',
    },
  },
  {
    label: 'Issue List',
    alternateMatches: ['grant issue list', 'issues', 'issue list'],
    key: 'Grant Issue List',
    fieldType: {
      type: 'input',
    },
  },
  {
    label: 'Population List',
    alternateMatches: [
      'grant population list',
      'focus population',
      'populations',
      'population list',
    ],
    key: 'Grant Population List',
    fieldType: {
      type: 'input',
    },
  },
  {
    label: 'Donor Type List',
    alternateMatches: ['grant donor type list', 'donors', 'donor list'],
    key: 'Grant Donor Type List',
    fieldType: {
      type: 'input',
    },
  },
  {
    label: `${getOrgText('Grant')} Country`,
    alternateMatches: [
      'grant country code',
      'grant country',
      'country',
      'countries',
    ],
    key: 'Location Country',
    fieldType: {
      type: 'select',
      options: countryOptions(),
    },
    validations: [
      {
        rule: 'regex',
        value: NON_EMPTY,
        errorMessage: `${getOrgText('Grant')} Country cannot be blank`,
        level: 'error',
      },
    ],
  },
  {
    label: `${getOrgText('Grant')} Region`,
    alternateMatches: ['grant approx region', 'region'],
    key: 'Location Region',
    fieldType: {
      type: 'input',
    },
  },
  {
    label: 'Amount',
    alternateMatches: ['funding amount', 'amount'],
    key: 'Funding Amount',
    fieldType: {
      type: 'input',
    },
    validations: [
      {
        rule: 'required',
        errorMessage: 'Funding Amount is required',
        level: 'error',
      },
      {
        rule: 'regex',
        value: AMOUNT_REGEX,
        errorMessage: 'Funding Amount format is invalid',
        level: 'error',
      },
    ],
  },
  {
    label: 'Currency',
    alternateMatches: ['funding currency', 'currency'],
    key: 'Funding Currency',
    fieldType: {
      type: 'select',
      options: CURRENCIES.map((currency) => ({
        label: `${currency.name} (${currency.symbol})`,
        value: currency.code,
      })),
    },
    validations: [
      {
        rule: 'required',
        errorMessage: 'Funding Currency is required',
        level: 'error',
      },
    ],
  },
  {
    label: 'Funding Type',
    alternateMatches: ['funding type'],
    key: 'Funding Type',
    fieldType: {
      type: 'select',
      options: fundingOptions(),
    },
    validations: [
      {
        rule: 'regex',
        value: NON_EMPTY,
        errorMessage: 'Funding Type will default to "Core funding"',
        level: 'warning',
      },
    ],
  },
  {
    label: 'Start Date (YYYY-MM-DD)',
    alternateMatches: ['funding start date', 'start date', 'start'],
    key: 'Funding Start Date',
    example: '2024-10-20',
    fieldType: {
      type: 'input',
    },
    validations: [
      {
        rule: 'required',
        errorMessage: 'Funding Start Date is required',
        level: 'error',
      },
      {
        rule: 'regex',
        value: DATE_REGEX,
        errorMessage:
          'Funding Start Date format is invalid (should be YYYY-MM-DD)',
        level: 'error',
      },
    ],
  },
  {
    label: 'End Date (YYYY-MM-DD)',
    alternateMatches: ['funding end date', 'end date', 'end'],
    key: 'Funding End Date',
    example: '2024-10-20',
    fieldType: {
      type: 'input',
    },
    validations: [
      {
        rule: 'required',
        errorMessage: 'Funding End Date is required',
        level: 'error',
      },
      {
        rule: 'regex',
        value: DATE_REGEX,
        errorMessage:
          'Funding End Date format is invalid (should be YYYY-MM-DD)',
        level: 'error',
      },
    ],
  },
  {
    label: `${getOrgText('Grantee')} Name`,
    alternateMatches: ['grantee name', 'grantee', 'organization'],
    key: 'Grantee Name',
    fieldType: {
      type: 'input',
    },
  },
  {
    label: `${getOrgText('Grantee')} Contact Name`,
    alternateMatches: ['grantee contact name', 'grantee contact'],
    key: 'Grantee Contact Name',
    fieldType: {
      type: 'input',
    },
  },
  {
    label: `${getOrgText('Grantee')} Contact Email`,
    alternateMatches: ['grantee contact email', 'grantee email'],
    key: 'grantee_contact_email',
    fieldType: {
      type: 'input',
    },
    validations: [
      {
        rule: 'regex',
        value: EMAIL_REGEX,
        errorMessage: 'Grant Contact Email format is invalid',
        level: 'warning',
      },
    ],
  },
  {
    label: `${getOrgText('Grantee')} Region`,
    alternateMatches: ['grantee region'],
    key: 'Grantee Region',
    fieldType: {
      type: 'input',
    },
  },
  {
    label: `${getOrgText('Grantee')} City`,
    alternateMatches: ['grantee city'],
    key: 'Grantee City',
    fieldType: {
      type: 'input',
    },
  },
  {
    label: `${getOrgText('Grantee')} State`,
    alternateMatches: ['grantee state'],
    key: 'Grantee State',
    fieldType: {
      type: 'input',
    },
  },
  {
    label: `${getOrgText('Grantee')} Country`,
    alternateMatches: [
      'grantee country code',
      'grantee country',
      'grantee countries',
    ],
    key: 'Grantee Country Code',
    fieldType: {
      type: 'select',
      options: countryOptions(),
    },
    validations: [
      {
        rule: 'regex',
        value: NON_EMPTY,
        errorMessage: `${getOrgText('Grantee')} Country cannot be blank`,
        level: 'error',
      },
    ],
  },
];
