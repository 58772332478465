import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import cls from './IconSubItem.module.css';

const cx = classNames.bind(cls);

const IconSubItem = ({ className, style }) => (
  <span style={style} className={cx(cls.iconSubItem, className)} />
);

IconSubItem.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

export default IconSubItem;
