import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { NumberField, SelectField, Container } from 'src/components/IMUI';
import CURRENCIES from 'src/data/currencies.json';
import cls from './CurrencyInput.module.css';
const cx = classNames.bind(cls);
const DEFAULT_CURRENCY = CURRENCIES[0]?.code;

export default class CurrencyInput extends React.PureComponent {
  static propTypes = {
    flat: PropTypes.bool,
    borderDark: PropTypes.bool,
    value: PropTypes.object,
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  };
  componentDidMount() {
    const valid = CURRENCIES.some(
      ({ code }) => code == (this.props.value?.currency || '').toUpperCase()
    );
    if (!valid) this.handleChange({ currency: DEFAULT_CURRENCY });
  }
  handleChange = (pair) => {
    this.props.onChange({ ...this.props.value, ...pair });
  };
  render() {
    return (
      <Container
        horizontal
        nowrap
        className={cx(cls.currencyInput, this.props.className)}
      >
        <NumberField
          label="Amount"
          hintText="Type in"
          name="amount"
          flat={this.props.flat}
          borderDark={this.props.borderDark}
          value={this.props.value?.amount}
          onChange={(val) => this.handleChange({ amount: val })}
        />
        <SelectField
          flat={this.props.flat}
          outline={this.props.borderDark}
          value={(this.props.value?.currency || DEFAULT_CURRENCY).toLowerCase()}
          label="Currency"
          hintText="Choose"
          name="currency"
          onChange={({ value: val }) => this.handleChange({ currency: val })}
        >
          {CURRENCIES.map(({ code }) => (
            <SelectField.Item
              value={code.toLowerCase()}
              key={code}
              primaryText={code}
            />
          ))}
        </SelectField>
      </Container>
    );
  }
}
