import Resource from 'im/api/Resource';

const apiConfig = {
  root: '/api/v3',
  type: 'auth',
  api: {
    custom: {
      getToken: {
        method: 'POST',
        path: 'tokens',
        headers: { 'Content-Type': 'application/json' },
      },
    },
  },
};

export const Auth = new Resource(apiConfig);
export default Auth.api;
