import { DictionaryService } from 'src/services/DictionaryService';

export const dummyGrant = 'IM Grant (internal)';
export const dummyGrantee = 'IM Grantee (internal)';

export const capitalize = (str, { doNotLowerCaseRest } = {}) => {
  if (!str) return '';
  return (
    str.substr(0, 1).toUpperCase() +
    (doNotLowerCaseRest ? str.substr(1) : str.substr(1).toLowerCase())
  );
};

export const capitalizeAll = (str) => {
  if (!str) return '';
  return str.split(' ').map(capitalize).join(' ');
};

export const titleize = (str) => {
  return str.replace(/\b\w/g, function (char) {
    return char.toUpperCase();
  });
};

export const toSnakeCase = (str) => {
  return str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+/g, '_')
    .replace(/^_+|_+$/g, '');
};

export const escapeForRegExp = (str) => {
  if (!str) return '';
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export const normalizeFormName = (str) => {
  if (!str) return '';
  return str.replace(/[[\].]+/g, '');
};

export const isCapitalized = (str) => {
  if (!str) return '';
  const char = str[0];
  const upperChar = char.toUpperCase();
  return char === upperChar && upperChar !== char.toLowerCase();
};

export const addSoftBreaks = (str) => {
  if (typeof str === 'number') return str;
  if (!str) return '';
  return str.replace(/([/])/gim, ' $1 ');
};

export const dedummy = (name, replace) => {
  if (!name || [dummyGrantee, dummyGrant].indexOf(name) !== -1) {
    return typeof replace === 'string' ? replace : '';
  }
  return name;
};

export const repeat = (str, count) => {
  let output = '';
  let counter = count;
  while (counter > 0) {
    output += str;
    counter -= 1;
  }
  return output;
};

const LICZBY_ROMANA = {
  M: 1000,
  CM: 900,
  D: 500,
  CD: 400,
  C: 100,
  XC: 90,
  L: 50,
  XL: 40,
  X: 10,
  IX: 9,
  V: 5,
  IV: 4,
  I: 1,
};
export const romanize = (num) => {
  const keys = Object.keys(LICZBY_ROMANA);
  let output = '';
  let remaining = num;
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    output += repeat(key, Math.floor(remaining / LICZBY_ROMANA[key]));
    remaining %= LICZBY_ROMANA[key];
  }
  return output;
};

export const alphabetize = (num, uppercase = false) => {
  const baseChar = (!uppercase ? 'a' : 'A').charCodeAt(0);
  let output = '';
  let number = num;
  do {
    number -= 1;
    output = String.fromCharCode(baseChar + (number % 26)) + output;
    number = Math.floor(number / 26);
  } while (number > 0);
  return output;
};

export const numberAs = (number, type, suffix = '. ') => {
  switch (type) {
    case 'alphabetical':
      return `${alphabetize(number, true)}${suffix}`;
    case 'roman':
      return `${romanize(number)}${suffix}`;
    case 'numerical':
    default:
      return `${number}${suffix}`;
  }
};

export const containsText = (text1, text2) => {
  return new RegExp(escapeForRegExp(text2), 'i').test(text1);
};

export const pluralize = (text, counter) => {
  if (counter === 1) return text;
  return DictionaryService.getPluralForm(text);
};
