import PropTypes from 'prop-types';
import React from 'react';

import {
  Actions,
  Button,
  Container,
  Tag,
  PopoverLight,
} from 'src/components/IMUI';
import languagesData from 'src/data/ISO-639-1-language.json';
import { getLanguageForCode } from 'src/services/DictionaryService';
import { containsText } from 'src/utils/string';

import { Icon } from 'im/ui/Icon';

import OverviewItem from './OverviewItem';

import cls from './LanguagesSetup.module.css';
import { canBuildSurvey, canManageSurvey } from 'src/userStorage';

const getAvailableLanguages = (selectedLanguages, searchText = '') =>
  languagesData.filter(
    (lang) =>
      selectedLanguages.indexOf(lang.code) === -1 &&
      containsText(`${lang.code} ${lang.name}`, searchText)
  );

export default class LanguagesSetup extends React.Component {
  static propTypes = {
    completeness: PropTypes.object,
    languages: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired,
  };

  state = { popoverOpen: false, anchorEl: null };

  handlePopoverCloseRequest = () => {
    this.setState({ popoverOpen: false });
  };

  handlePopoverOpenRequest = (ev) => {
    ev?.preventDefault();
    this.setState({ popoverOpen: true, anchorEl: ev.currentTarget });
  };

  handleAddLanguage = (selectedLanguageCode) => {
    // push this.state.selectedLanguageCode if not present
    const { languages, onChange } = this.props;
    onChange([...languages, selectedLanguageCode]);
    this.handlePopoverCloseRequest();
  };

  handleSetAsDefault = (index) => {
    const { languages, onChange } = this.props;
    const newLanguages = [...languages];
    const newDefault = newLanguages.splice(index, 1)[0];
    onChange([newDefault, ...newLanguages]);
  };

  handleMoveLanguage = (index, targetIndex) => {
    const { languages, onChange } = this.props;
    if (targetIndex < 0 || targetIndex > languages.length) return;
    const newLanguages = [...languages];
    const item = newLanguages.splice(index, 1)[0];
    newLanguages.splice(targetIndex, 0, item);
    onChange(newLanguages);
  };

  handleDeleteLanguage = (index) => {
    const { languages, onChange } = this.props;
    const newLanguages = [...languages];
    newLanguages.splice(index, 1);
    onChange(newLanguages);
  };

  renderAvailableLanguages = (searchText) => {
    const { languages } = this.props;
    const availableLanguages = getAvailableLanguages(languages, searchText);

    return (
      <PopoverLight.Menu>
        {availableLanguages.map((language) => (
          <PopoverLight.MenuItem
            key={language.code}
            value={language.code}
            leftIcon={
              <Tag
                outline
                squished
                label={language.code}
                className={cls.listLanguageCode}
              />
            }
            primaryText={
              <span className={cls.listLanguageText}>{language.name}</span>
            }
            onClick={() => this.handleAddLanguage(language.code)}
          />
        ))}
      </PopoverLight.Menu>
    );
  };

  render() {
    const { languages, completeness } = this.props;
    const { anchorEl, popoverOpen } = this.state;

    return (
      <OverviewItem
        title="Languages"
        label="What languages should the survey be available in?"
      >
        <div className={cls.languagesList}>
          {languages.map((code, index) => {
            const upIconClass = index === 0 ? cls.disabledMoveIcon : '';
            const downIconClass =
              index === languages.length - 1 ? cls.disabledMoveIcon : '';
            const deleteIconClass =
              languages.length < 2 ? cls.disabledDeleteIcon : '';
            const langStatus = completeness[code];
            const langStatusStyle = langStatus.complete
              ? null
              : { color: langStatus.cssColor };
            return (
              <Container horizontal key={code} nowrap>
                <Actions nowrap small>
                  <Tag
                    label={code}
                    outline={langStatus.complete}
                    dashed={!langStatus.complete}
                    warning={!langStatus.complete}
                    minWidth={18}
                  />
                  <div style={langStatusStyle}>{getLanguageForCode(code)}</div>
                  {langStatus.complete ? null : (
                    <div className={cls.languageCompleteness}>
                      {langStatus.percentage} complete
                    </div>
                  )}
                  {index === 0 ? (
                    <button disabled>Default</button>
                  ) : (
                    <button
                      disabled={!canBuildSurvey()}
                      onClick={() => this.handleSetAsDefault(index)}
                    >
                      Set as default
                    </button>
                  )}
                </Actions>
                <Actions className={cls.languageListActions}>
                  {canManageSurvey() && (
                    <Icon
                      name="chevron-up"
                      className={upIconClass}
                      onClick={() => this.handleMoveLanguage(index, index - 1)}
                    />
                  )}
                  {canManageSurvey() && (
                    <Icon
                      name="chevron-down"
                      className={downIconClass}
                      onClick={() => this.handleMoveLanguage(index, index + 1)}
                    />
                  )}
                  {canManageSurvey() && (
                    <Icon
                      name="trash"
                      className={deleteIconClass}
                      onClick={() => this.handleDeleteLanguage(index)}
                    />
                  )}
                </Actions>
              </Container>
            );
          })}
        </div>

        <Button
          secondary
          className={cls.addLanguageButton}
          label="Add language"
          onClick={this.handlePopoverOpenRequest}
        />
        <PopoverLight
          search
          anchorEl={anchorEl}
          open={popoverOpen}
          anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
          targetOrigin={{ horizontal: 'left', vertical: 'top' }}
          className={cls.languagesPopover}
          renderChildren={this.renderAvailableLanguages}
          onRequestClose={this.handlePopoverCloseRequest}
        />
      </OverviewItem>
    );
  }
}
