import React from 'react';
import classNames from 'classnames/bind';
import MUIDatePicker from 'material-ui/DatePicker';
import PropTypes from 'prop-types';
import { InputReduxForm, Label, Error } from './base';
import { TextField } from 'src/components/IMUI';
import { Icon } from 'im/ui/Icon';
import cls from './DatePicker.module.css';
import { showNotification } from 'src/actionCreators/notificationsActionCreators';
import store from 'src/store';
const cx = classNames.bind(cls);
const localDate = (yyyymmdd) =>
  new Date(
    new Date(yyyymmdd).getTime() -
      new Date(yyyymmdd).getTimezoneOffset() * 60 * 1000
  );
const castDDMMYYYYToDate = (str) =>
  new Date(
    str.substring(4, 8) + '-' + str.substring(2, 4) + '-' + str.substring(0, 2)
  );

export class DatePicker extends React.PureComponent {
  static propTypes = {
    input: PropTypes.object,
    noValidation: PropTypes.bool,
    label: PropTypes.node,
    onRef: PropTypes.func,
    border: PropTypes.bool,
    borderDark: PropTypes.bool,
    flat: PropTypes.bool,
    flatDark: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
    hintText: PropTypes.string,
    value: PropTypes.any,
    meta: PropTypes.any,
    stick: PropTypes.any,
    error: PropTypes.any,
  };
  static defaultProps = { value: null, noValidation: false };

  onChange = (_, val) => {
    InputReduxForm.onChange(
      this.props.input,
      this.props.onChange
    )(localDate(val));
  };

  onType(date) {
    // eslint-disable-next-line no-useless-escape
    const valid =
      /^([0-2][0-9]|3[01])[\-\/\.]?(0?[0-9]|1[0-2])[\-\/\.]?[0-2][0-9]{3}$/.test(
        date
      );
    const dt = date
      .replace('/', '')
      .replace('.', '')
      .replace('-', '')
      .replace('/', '')
      .replace('.', '')
      .replace('-', '');
    const filled = dt.length === 8;
    if (!valid && filled) this.onChange(null, undefined);
    if (!valid && filled && date?.length > 0) this.showInvalidDate();
    if (!valid) return;
    if (filled) {
      this.onChange(null, castDDMMYYYYToDate(dt));
    }
  }
  showInvalidDate = () => {
    store.dispatch(
      showNotification({
        title: 'The date is invalid and cannot be saved',
        message: 'Please provide a correct value in format DD/MM/YYYY',
        level: 'error',
        position: 'br',
      })
    );
  };

  onTypeBlur(date) {
    // eslint-disable-next-line no-useless-escape
    const valid =
      /^([0-2][0-9]|3[01])[\-\/\.]?(0?[0-9]|1[0-2])[\-\/\.]?[0-2][0-9]{3}$/.test(
        date
      );
    if (!valid) this.onChange(null, this.currentValue());
    if (!valid && date?.length > 0) this.showInvalidDate();
  }

  currentValue() {
    const _date = this.props.input?.value || this.props.value;
    if (!_date) return _date;
    const date = _date instanceof Date ? _date : new Date(_date);

    if (date?.getTimezoneOffset() > 0)
      return new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
    if (date?.getTimezoneOffset() < 0)
      return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
    return date;
  }

  textDate() {
    const d = this.currentValue();
    return d
      ? `${String(d.getUTCDate()).padStart(2, '0')}/${String(
          d.getUTCMonth() + 1
        ).padStart(2, '0')}/${d.getUTCFullYear()}`
      : '';
  }

  render() {
    const {
      className,
      meta,
      label,
      stick,
      flat,
      border,
      error,
      borderDark,
      flatDark,
      disabled,
    } = this.props;
    const maxHeight = border ? 47 : 48;

    return (
      <div>
        <Label label={label} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            justifyContent: 'flex-start',
            alignItems: 'center',
            maxHeight: maxHeight,
            flexWrap: 'nowrap',
          }}
        >
          <TextField
            flat={flat}
            border={border}
            disabled={disabled}
            fullWidth={true}
            name="date"
            noValidation
            value={this.textDate()}
            onChange={(d) => this.onType(d)}
            onBlur={(d) => this.onTypeBlur(d.target.value)}
            wrapperClassName={cx('margin-0')}
          />
          <div
            style={{
              position: 'relative',
              top: -8,
              left: 0,
              width: 42,
              height: 42,
              marginLeft: -48,
              padding: 0,
            }}
            role="button"
          >
            <MUIDatePicker
              ref={(ref) => this.props.onRef?.(ref)}
              className={cx(
                'imui-text-field',
                'imui-datepicker',
                {
                  'imui-text-field-flat': flat,
                  'imui-text-field-border': border,
                  'imui-text-field-border-dark': borderDark,
                  'imui-text-field-flatDark': flatDark,
                  'imui-datepicker-has-hint': !!this.props.hintText,
                  [`imui-text-field-stick-${stick}`]: stick && stick.length,
                  'imui-text-field-disabled': disabled,
                },
                className
              )}
              autoOk
              inputStyle={{
                paddingLeft: 20,
                height: 42,
                width: 50,
                marginBottom: 8,
                top: 0,
              }}
              locale="en-US"
              onChange={this.onChange}
              value={this.currentValue()}
              container="inline"
              hideCalendarDate
              disabled={disabled}
              openToYearSelection={true}
              style={{ borderRadius: 6 }}
              textFieldStyle={{
                position: 'absolute',
                opacity: 0,
                width: 42,
                height: 42,
                cursor: 'pointer',
                fontSize: 30,
                padding: 0,
                zIndex: 3,
                userSelect: 'none',
              }}
            />
            <Icon
              name="calendar"
              className={cls.fieldIcon}
              disabled={this.props.disabled}
              style={{
                width: 36,
                height: 42,
                padding: 8,
                fontSize: 20,
                lineHeight: '26px',
                position: 'absolute',
                margin: 8,
                userSelect: 'none',
                touchAction: 'none',
              }}
            />
          </div>
        </div>
        {!this.props.noValidation && <Error meta={meta} error={error} />}
      </div>
    );
  }
}

export default DatePicker;
