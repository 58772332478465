import React from 'react';

import BaseQuestionProperties from './BaseQuestionProperties';

export default class LayoutElementProperties extends React.Component {
  render() {
    return (
      <BaseQuestionProperties
        questionLabel="content"
        questionMultiline={3}
        contextLabel="element"
        hasRequired={false}
        hasDescription={false}
        {...this.props}
      />
    );
  }
}
