import React from 'react';
import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import { connect } from 'react-redux';
import { SelectField } from 'src/components/IMUI';
import { getText } from 'src/services/DictionaryService';
const valueTypesOrder = [
  'taggings_count',
  'cross_tab',
  'grantees_total',
  'grantees_percentage',
  'grantees_in_tag_group',
  'grantees_in_tag_group_percentage',
  'grants_total',
  'grants_percentage',
  'grants_in_tag_group',
  'grants_in_tag_group_percentage',
  'funding_count',
  'funding_total',
  'funding_total_percentage',
  'reach_tag_avg',
  'reach_tag_total',
];

@connect(pick(['chartsAxisData']))
export default class ChartCalculationTypeSelector extends React.PureComponent {
  static propTypes = {
    chartsAxisData: PropTypes.object,
    calculationType: PropTypes.array,
    isHidden: PropTypes.bool,
    allowMultiple: PropTypes.bool,
    onCalculationTypeChanged: PropTypes.func,
  };
  static defaultProps = { chartsAxisData: {}, calculationType: [] };

  // eslint-disable-next-line react/no-unused-class-component-methods
  defaultValues(currentValues) {
    const dataAll = [].concat(this.props.chartsAxisData.data).flat();
    if (dataAll.every((i) => i.reach_tag_total != undefined))
      return ['reach_tag_total'];
    if (dataAll.every((i) => i.cross_tab != undefined)) return ['cross_tab'];
    if (dataAll.every((i) => i.taggings_count != undefined))
      return ['taggings_count'];
    if (dataAll.every((i) => i.grantees_total != undefined))
      return ['grantees_total'];
    if (dataAll.every((i) => i.grantees_total != undefined))
      return ['grants_total'];
    if (dataAll.every((i) => i.grantees_total != undefined))
      return ['funding_total'];

    if (dataAll.some((i) => i.reach_tag_total != undefined))
      return ['reach_tag_total'];
    if (dataAll.some((i) => i.cross_tab != undefined)) return ['cross_tab'];
    if (dataAll.some((i) => i.taggings_count != undefined))
      return ['taggings_count'];
    if (dataAll.some((i) => i.grantees_total != undefined))
      return ['grantees_total'];
    if (dataAll.some((i) => i.grantees_total != undefined))
      return ['grants_total'];
    if (dataAll.some((i) => i.grantees_total != undefined))
      return ['funding_total'];
    return currentValues;
  }

  validCalcs = [];
  componentDidUpdate() {
    const everyCalc = [
      ...(new Set(this.props.chartsAxisData.data?.flatMap(Object.keys)) || []),
    ];
    const newValids = everyCalc
      .filter((newCalc) =>
        this.props.chartsAxisData.data?.every(
          (record) => record && Object.hasOwn(record, newCalc)
        )
      )
      .filter((i) => i && valueTypesOrder.includes(i));
    if (JSON.stringify(newValids) == JSON.stringify(this.validCalcs)) return;
    this.validCalcs = newValids.slice();
    if (
      this.validCalcs?.length > 0 &&
      !this.validCalcs.some((c) => this.props.calculationType.includes(c))
    )
      this.forceSomeValidCalculation();
  }
  forceSomeValidCalculation() {
    return this.props.onCalculationTypeChanged(this.validCalcs.slice(0, 1));
  }
  valueTypes() {
    return Object.keys(
      this.props.chartsAxisData.data[0]?.[0] ||
        this.props.chartsAxisData.data[0] ||
        {}
    )
      .sort((a, b) => valueTypesOrder.indexOf(a) - valueTypesOrder.indexOf(b))
      .filter((key) => !['meta'].includes(key));
  }
  onCalculationTypeChanged = ({ value }) => {
    if (value?.length > 0) {
      return this.props.onCalculationTypeChanged(
        this.props.allowMultiple ? value.slice(0) : value.slice(-1)
      );
    }
    if (value?.length === 0) {
      this.props.onCalculationTypeChanged([]);
      return window.setTimeout(
        () => this.props.onCalculationTypeChanged([this.valueTypes()[0]]),
        10
      );
    }
  };
  render() {
    if (!this.props.chartsAxisData?.data.length || this.props.isHidden)
      return null;
    const possibleValues = this.valueTypes().filter((v) =>
      this.props.calculationType.includes(v)
    );
    const currentValues =
      possibleValues.length > 0 ? possibleValues : [this.valueTypes()[0]];
    const currentKeys = this.props.allowMultiple
      ? currentValues.slice()
      : currentValues.slice(-1);

    return (
      <SelectField
        noValidation
        compactSelection
        multiple={true}
        onChange={this.onCalculationTypeChanged}
        style={{ minWidth: 360 }}
        maxHeight={600}
        value={currentKeys}
      >
        <SelectField.Item
          key="label"
          disabled
          primaryText="Select calculation type"
        />
        {this.valueTypes().map((valueType) => (
          <SelectField.Item
            key={valueType}
            checked={currentValues.includes(valueType)}
            value={valueType}
            primaryText={getText(valueType)}
          />
        ))}
      </SelectField>
    );
  }
}
