import React from 'react';
import { Actions, Button, Container, Dialog } from 'src/components/IMUI';
import uploadGrantsPng from 'src/static/upload-grants.png';

import cls from './FinishDialog.module.css';

const FinishDialog = ({ open, uploadId, onClose, onFinishClick }) => {
  return (
    <Dialog
      forceTop
      open={open}
      large
      onRequestClose={onFinishClick}
      rightActions={
        <Actions>
          <Button size="l" label="View all grants" onClick={onFinishClick} />
        </Actions>
      }
    >
      <Container centered centerHorizontal>
        <img alt="Save" width={250} src={uploadGrantsPng} />
        <h3 className={cls.title}>Import finished</h3>
        <p className={cls.subtitle}>
          The data has been uploaded into your organization.
          {uploadId ? (
            <span className={cls.uploadReference}>
              <br />
              (Ref: {uploadId})
            </span>
          ) : null}
        </p>
      </Container>
    </Dialog>
  );
};

export default FinishDialog;
