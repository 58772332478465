import PropTypes from 'prop-types';
import React from 'react';

import cls from './SettingBox.module.css';

export default class SettingsWrapper extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    const { children } = this.props;
    return <div className={cls.settingsWrapper}>{children}</div>;
  }
}
