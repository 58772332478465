import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Icon } from 'im/ui/Icon';

import cls from './MergeTagForm.module.css';

const cx = classnames.bind(cls);

const MergeTagFinishedForm = ({ tag, tagsToMerge }) => (
  <div>
    <h3>Tags merge under process!</h3>
    <div
      className={cx(cls.editTagMergeIconWrapper, cls.editTagMergeIconSuccess)}
    >
      <Icon name="check" style={{ fontSize: '24px', color: '#15cf6d' }} />
    </div>
    <span>
      You successfully submitted merging tags <b>{tagsToMerge.titles}</b> into
      &nbsp;<b>{tag.title}</b>
      {tagsToMerge.count > 0 && (
        <span>, affecting {tagsToMerge.count} taggings.</span>
      )}
    </span>
    <p>
      As it might take a while, we will send an confirmation email when the
      merging is finished.
    </p>
  </div>
);

MergeTagFinishedForm.propTypes = {
  tagsToMerge: PropTypes.object.isRequired,
  tag: PropTypes.object.isRequired,
};

export default MergeTagFinishedForm;
