import React from 'react';
import classNames from 'classnames/bind';
import { T, withTranslators } from 'lioness';
import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import { changeSurveyProperty } from 'src/actionCreators/surveyActionCreators';
import {
  getPublicSurvey,
  changeAnswerValue,
  changeAnswerTags,
  savePublicSurveyAnswers,
  setSurveyLanguage,
  changeAnswerOtherChoiceValue,
} from 'src/actionCreators/surveyAnswersActionCreators';
import surveyPublicApi from 'src/api/SurveyPublic';
import {
  Actions,
  Button,
  Card,
  Container,
  Dialog,
  Section,
  DownloadAsPdfWidget,
} from 'src/components/IMUI';
import DownloadAsPdfWidgetContent from 'src/components/Survey/DownloadAsPdfWidgetContent';
import Survey from 'src/components/Survey/Survey';
import colors from 'src/css/constants.json';
import { getText } from 'src/services/DictionaryService';
import { getSurveyPreferredLanguage } from 'src/utils/surveysI18n';
import { where } from 'im/api/Query';
import { NotificationsWidget } from 'im/ui/Notifications';
import TextWithClipboard from '../App/Analysis/Surveys/Survey/components/TextWithClipboard';
import CompanyLogo from './components/CompanyLogo';
import { SurveyAlert } from './components/SurveyAlert';
import SurveyContainer from './components/SurveyContainer';
import SurveyLanguageSelector from './components/SurveyLanguageSelector';
import { getSurveyLanguage } from './PublicSurvey';
import cls from './AnswerSurvey.module.css';
import Storage from 'src/services/Storage';
import { getGroupForTag } from 'src/api/Tags';
const cx = classNames.bind(cls);
const AUTOSAVE_INTERVAL = 7000;
const DUMMY_GRANTEE = 'IM Grantee (internal)';
const DUMMY_GRANT = 'IM Grant (internal)';

@connect(pick(['surveyAnswers', 'notifications', 'surveyPublic']), {
  findSurvey: surveyPublicApi.find,
  getPublicSurvey,
  changeAnswerValue,
  changeAnswerOtherChoiceValue,
  changeAnswerTags,
  savePublicSurveyAnswers,
  setSurveyLanguage,
  changeSurveyProperty,
})
class AnswerSurvey extends React.PureComponent {
  static propTypes = {
    notifications: PropTypes.array,
    surveyAnswers: PropTypes.object,
    surveyPublic: PropTypes.object,
    match: ReactRouterPropTypes.match,
    getPublicSurvey: PropTypes.func,
    changeAnswerOtherChoiceValue: PropTypes.func,
    findSurvey: PropTypes.func,
    changeAnswerValue: PropTypes.func,
    changeAnswerTags: PropTypes.func,
    savePublicSurveyAnswers: PropTypes.func,
    setSurveyLanguage: PropTypes.func,
    tp: PropTypes.func,
    t: PropTypes.func,
    changeSurveyProperty: PropTypes.func,
  };
  intervalId = 0;
  clearActivityUpdate() {
    window.clearInterval(this.intervalId);
    this.intervalId = 0;
  }
  setActivityUpdate() {
    if (this.intervalId > 0) return;
    window.setTimeout(() => {
      if (this.state.dirty) this.submitSurvey(false);
    }, AUTOSAVE_INTERVAL);
    this.intervalId = window.setInterval(() => {
      if (this.state.dirty) this.submitSurvey(false);
    }, AUTOSAVE_INTERVAL);
  }
  state = {
    submitConfirmOpen: false,
    dirty: false,
    isPrintModalOpen: false,
    hideAnswersOnPrint: false,
    language: getSurveyPreferredLanguage(),
    ready: false,
    surveyTagsWithTagGroups: [],
    isAlertOpen: false,
    savedPermalink: null,
  };
  getChildContext() {
    return { language: this.state.language };
  }
  componentDidMount() {
    this.onRefresh();
  }
  onRefresh = () => {
    this.props.getPublicSurvey(this.props.match.params.id);
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.surveyAnswers.info.id !== this.props.surveyAnswers.info.id) {
      const language = getSurveyLanguage(
        getSurveyPreferredLanguage(),
        nextProps.surveyAnswers.info.survey_languages,
        nextProps.surveyAnswers.info.language
      );
      this.handleLanguageChange({ value: language });
    }

    if (!nextProps.surveyAnswers?.fetching && nextProps.surveyAnswers.done) {
      this.clearActivityUpdate();
    }
    if (
      !nextProps.surveyAnswers?.fetching &&
      !nextProps.surveyAnswers.done &&
      !nextProps.surveyAnswers.error
    ) {
      this.setActivityUpdate();
    }

    if (
      nextProps.surveyAnswers.info !== this.props.surveyAnswers.info &&
      !this.state.ready
    ) {
      this.props.findSurvey(
        where({
          public_id: nextProps.surveyAnswers.info.survey_public_id,
        }).include(
          'survey_question_tags',
          'survey_question_tags.tag_categories'
        )
      );
    }

    if (nextProps.surveyPublic.data !== this.props.surveyPublic.data) {
      const savedPermalink = Storage.getItem(
        `permalink-${nextProps.surveyPublic.data.public_id}`
      );
      this.setState({
        ready: true,
        savedPermalink,
        isAlertOpen:
          !!savedPermalink &&
          savedPermalink !== nextProps.surveyAnswers.info.permalink,
        surveyTagsWithTagGroups: this.getTagsWithTagGroups(
          nextProps.surveyPublic
        ),
      });
    }

    if (nextProps.surveyPublic.data && nextProps.surveyAnswers.info) {
      // store permalink only for public surveys, ensure permalink was fetched!
      const publicId = nextProps.surveyPublic.data.public_id;
      if (publicId && nextProps.surveyPublic.data.public) {
        const permalinkStoreKey = `permalink-${publicId}`;
        const publicPathname = `/answer-survey/public/${publicId}`;
        if (publicId && nextProps.surveyAnswers.info.permalink) {
          Storage.setItem(
            permalinkStoreKey,
            nextProps.surveyAnswers.info.permalink
          );
        }
        if (window.location.pathname !== publicPathname) {
          window.history.replaceState(null, window.title, publicPathname);
        }
      }
    }

    if (
      this.props.surveyAnswers.error &&
      nextProps.surveyPublic.data.public_id
    ) {
      Storage.removeItem(`permalink-${nextProps.surveyPublic.data.public_id}`);
    }
  }

  componentWillUnmount() {
    this.clearActivityUpdate();
  }

  onSurveySubmit = (isFinished) => {
    isFinished
      ? this.setState({ submitConfirmOpen: true })
      : this.submitSurvey(isFinished);
  };

  onSurveyAnswerChange = (question, value) => {
    this.setState({ dirty: true });
    this.props.changeAnswerValue(question.id, value);
  };

  onAnswerOtherChange = (question, value) => {
    this.setState({ dirty: true });
    this.props.changeAnswerOtherChoiceValue?.(question.id, value);
  };

  onSurveyTagsChange = (question, value) => {
    this.setState({ dirty: true });
    this.props.changeAnswerTags(question.id, value);
  };

  onConfirm = () => {
    const isFinished = true;
    this.submitSurvey(isFinished);
    this.onModalRequestClose();
  };

  onModalRequestClose = () => {
    this.setState({ submitConfirmOpen: false });
  };

  onAlertClose = () => {
    this.setState({ isAlertOpen: false });
  };

  onPrintModalOpen = () => {
    this.setState({ isPrintModalOpen: true });
  };

  onPrintModalClose = () => {
    this.setState({ isPrintModalOpen: false });
  };

  onTogglePrintAnswers = (ev, isChecked) => {
    this.setState({ hideAnswersOnPrint: isChecked });
  };

  getTagsWithTagGroups(surveyPublic) {
    return surveyPublic.data.survey_question_tags.reduce(
      (acc, tag) =>
        acc.findIndex((el) => el.id === tag.id) !== -1
          ? acc
          : acc.concat([{ ...tag, tagGroup: getGroupForTag(tag) }]),
      []
    );
  }

  getSurveyName() {
    const { info } = this.props.surveyAnswers;
    return (
      (info.survey_i18n &&
        info.survey_i18n[this.state.language] &&
        info.survey_i18n[this.state.language].name) ||
      info.survey_name
    );
  }

  handleLanguageChange = ({ value: language }) => {
    this.props.changeSurveyProperty('activeLanguage', language, {
      silent: true,
    });
    this.props.setSurveyLanguage(language);
    this.setState({ language });
    setTimeout(() => this.forceUpdate(), 500);
  };

  submitSurvey = (isFinished) => {
    const params = [
      this.props.surveyAnswers.questions,
      this.props.surveyAnswers.answers,
      this.props.surveyAnswers.info,
      isFinished,
      this.state.language,
    ];
    this.props.savePublicSurveyAnswers(...params);
    this.setState({ dirty: false });
  };

  renderRelEntity(value, label) {
    return (
      <h2 className={cx('subheader')}>
        <span className={cls.granteeInfoSmaller}>{label}</span>
        <span>{value}</span>
      </h2>
    );
  }

  renderRelEntityHeader(grant, grantee) {
    let firstItem;
    let secondItem;
    if (grant && grantee) {
      firstItem = this.renderRelEntity(grant, getText('Grant'));
      secondItem = this.renderRelEntity(grantee, getText('Grantee'));
    } else if (grant || grantee) {
      firstItem = this.renderRelEntity(
        grant || grantee,
        getText(grant ? 'Grant' : 'Grantee')
      );
    }
    if (firstItem) {
      return (
        <Container horizontal className={cls.relEntitiesHeader}>
          <Actions>{firstItem}</Actions>
          {secondItem ? <Actions>{secondItem}</Actions> : null}
        </Container>
      );
    }
    return null;
  }

  render() {
    const { surveyAnswers, notifications, surveyPublic, tp } = this.props;
    const {
      submitConfirmOpen,
      isPrintModalOpen,
      hideAnswersOnPrint,
      surveyTagsWithTagGroups,
      savedPermalink,
    } = this.state;
    const isSaved = surveyAnswers.answers.some(
      (answer) => answer.value !== undefined
    );
    const organization = {
      title: surveyAnswers.info.survey_organization_title,
      email: surveyAnswers.info.survey_organization_contact_email,
    };
    const granteeName =
      !!surveyAnswers.info.grantee_name &&
      surveyAnswers.info.grantee_name !== DUMMY_GRANTEE
        ? surveyAnswers.info.grantee_name
        : undefined;
    const grantName =
      !!surveyAnswers.info.grant_name &&
      surveyAnswers.info.grant_name !== DUMMY_GRANT
        ? surveyAnswers.info.grant_name
        : undefined;
    const isExpired = !surveyPublic.meta.canFillOut;
    return (
      <SurveyContainer>
        {isExpired
          ? null
          : this.state.isAlertOpen && (
              <SurveyAlert
                permalink={savedPermalink}
                onClose={this.onAlertClose}
              />
            )}

        <Section collapsed noBorder>
          <Container className={cls.actionsContainer} horizontal>
            <Actions />
            <Actions>
              <TextWithClipboard
                hiddenLabel
                justifyEnd
                icon="paperclip"
                tip={this.props.t(
                  'Click to copy your personal survey link. <br/>Be mindful of who you share it with.'
                )}
                notificationMsg={this.props.t('Survey personal link copied')}
                url={`${window.location.origin}/answer-survey/${this.props.surveyAnswers.info.permalink}`}
              />
              <DownloadAsPdfWidget
                howTo="tip"
                iconProps={{ color: colors['mid-grey'] }}
                onClose={() => this.onPrintModalClose()}
                onOpen={() => this.onPrintModalOpen()}
              >
                <DownloadAsPdfWidgetContent
                  hideAnswers={this.state.hideAnswersOnPrint}
                  onToggleHideAnswers={(ev, value) =>
                    this.onTogglePrintAnswers(ev, value)
                  }
                />
              </DownloadAsPdfWidget>
              <SurveyLanguageSelector
                activeLanguage={this.state.language}
                languages={this.props.surveyAnswers.info.survey_languages}
                onChange={this.handleLanguageChange}
              />
            </Actions>
          </Container>
        </Section>

        <Section surveyWidth className={cx('wrapper')}>
          <CompanyLogo
            isPending={surveyAnswers.fetching}
            logo={surveyAnswers.info.survey_organization_logo_url}
          />

          {this.props.surveyAnswers.error && (
            <Card>
              <div className={cx('errorMsg')}>
                <h3 style={{ margin: 10 }}>
                  We are unable to open a survey with that link
                </h3>
                <h4 style={{ margin: 10 }}>{this.props.surveyAnswers.error}</h4>

                <br />
                <br />
                <p>
                  <h3>Possible reasons</h3>
                </p>
                <br />
                <li>
                  If the <strong>deadline for submission has passed</strong> you
                  will not be able to open the survey
                  <br />
                </li>
                <br />
                <li>
                  If your answers <strong>have already been submitted</strong>{' '}
                  will not be able to open the survey
                  <br />
                </li>
                <br />
                <li>
                  Please check the link provided by your survey administrator
                  and try again
                  <br />
                </li>
                <br />
                <p>
                  For any further questions please contact your survey
                  administrator.
                </p>
              </div>
            </Card>
          )}

          {surveyAnswers.fetching || surveyAnswers.error ? null : (
            <Container>
              <h1 className={cx('surveyTitle')}>{this.getSurveyName()}</h1>
              {this.renderRelEntityHeader(grantName, granteeName)}
              <Survey
                isPrintable
                isAnswerMode
                organization={organization}
                isPrinting={isPrintModalOpen}
                hideAnswers={hideAnswersOnPrint}
                layout={surveyAnswers.layout}
                questions={surveyAnswers.questions}
                answers={surveyAnswers.answers}
                onAnswerChange={this.onSurveyAnswerChange}
                changeAnswerOtherChoiceValue={this.onAnswerOtherChange}
                onTagsChange={this.onSurveyTagsChange}
                tagsWithTagGroups={surveyTagsWithTagGroups}
                onSubmit={this.onSurveySubmit}
                isSaved={isSaved}
                isDirty={this.state.dirty}
                isPending={this.props.surveyAnswers.saving}
                onRefresh={this.onRefresh}
              />
            </Container>
          )}

          <Dialog
            small
            light
            open={submitConfirmOpen}
            title={tp('dialog title', 'Confirm submitting your survey.')}
            leftActions={
              <Button
                size="l"
                secondary
                onClick={this.onModalRequestClose}
                label={tp('button', 'Cancel')}
              />
            }
            rightActions={
              <Button
                size="l"
                onClick={this.onConfirm}
                label={tp('button', 'Confirm')}
              />
            }
          >
            <p>
              <T context="dialog warning">
                Are you sure you want to submit the survey? It will generate a
                report based on provided answers. Once you submit, you will not
                be able to edit or print it.
              </T>
            </p>
          </Dialog>
          <NotificationsWidget notifications={notifications} />
        </Section>
      </SurveyContainer>
    );
  }
}

export default withTranslators(AnswerSurvey);
