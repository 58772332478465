import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Tag } from '../';
import cls from './Button.module.css';
const cx = classNames.bind(cls);

const ButtonElement = ({
  children,
  disabled,
  className,
  href,
  to,
  type,
  badge,
  onClick,
  style,
  form,
  target,
  ...restProps
}) => {
  const isButton = onClick || ['submit', 'reset'].indexOf(type) > -1;
  const isDisabled =
    disabled ||
    (!href && !onClick && !to && ['submit', 'reset'].indexOf(type) < 0);
  const badgeEl =
    !badge && badge !== 0 ? null : (
      <Tag
        dim={isDisabled}
        className={cx('imui-button-badge')}
        label={badge}
        style={style}
      />
    );

  if (isButton || isDisabled)
    return (
      <button
        type={type}
        className={className}
        onClick={onClick}
        disabled={isDisabled}
        style={style}
        form={form}
        data-tip={restProps['data-tip']}
        data-for={restProps['data-for']}
      >
        {children}
        {badgeEl}
      </button>
    );
  if (to)
    return (
      <Link
        to={to}
        className={className}
        style={style}
        data-tip={restProps['data-tip']}
        data-for={restProps['data-for']}
      >
        {children}
        {badgeEl}
      </Link>
    );
  if (href)
    return (
      <a
        href={href}
        className={className}
        style={style}
        target={target}
        data-tip={restProps['data-tip']}
        data-for={restProps['data-for']}
      >
        {children}
        {badgeEl}
      </a>
    );
  return null;
};

ButtonElement.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  badge: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  href: PropTypes.string,
  to: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  onClick: PropTypes.func,
  style: PropTypes.object,
  form: PropTypes.string,
  target: PropTypes.string,
};

ButtonElement.defaultProps = { type: 'button' };

export class Button extends React.PureComponent {
  static propTypes = {
    icon: PropTypes.node,
    className: PropTypes.string,
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.func,
      PropTypes.node,
    ]), // func to handle translations ??
    secondary: PropTypes.bool,
    negative: PropTypes.bool,
    dark: PropTypes.bool,
    border: PropTypes.bool,
    alert: PropTypes.bool,
    clean: PropTypes.bool,
    action: PropTypes.bool,
    actionSecondary: PropTypes.bool,
    text: PropTypes.bool,
    underlined: PropTypes.bool,
    size: PropTypes.oneOf(['s', 'm', 'l']),
    style: PropTypes.object,
  };

  static defaultProps = {
    size: 'm',
  };

  render() {
    const {
      label,
      icon,
      size,
      alert,
      dark,
      clean,
      text,
      underlined,
      border,
      secondary,
      action,
      actionSecondary,
      negative,
      className,
      style,
    } = this.props;
    const buttonClassNames = cx(
      'imui-button',
      `imui-button-${size}`,
      {
        'imui-button-dark': dark,
        'imui-button-alert': alert,
        'imui-button-clean': clean,
        'imui-button-negative': negative,
        'imui-button-secondary': secondary,
        'imui-button-action': action,
        'imui-button-actionSecondary': actionSecondary,
        'imui-button-text': text,
        'imui-button-underlined': underlined,
        'imui-button-border': border,
        'imui-button-icon-only': !label && icon,
      },
      className
    );

    return (
      <ButtonElement {...this.props} className={buttonClassNames} style={style}>
        {icon}
        {label && <span className={cx('imui-button-label')}>{label}</span>}
      </ButtonElement>
    );
  }
}

export default Button;
