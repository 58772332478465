import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connect } from 'react-redux';

import { showNotification } from 'src/actionCreators/notificationsActionCreators';

import { Icon } from 'im/ui/Icon';

import cls from './TextWithClipboard.module.css';

const cx = classNames.bind(cls);

const isMac = navigator.platform.indexOf('Mac') !== -1;
const defaultTip = 'Copy to clipboard';

@connect(null, { showNotification })
export default class TextWithClipboard extends React.Component {
  static propTypes = {
    hiddenLabel: PropTypes.bool,
    url: PropTypes.string,
    icon: PropTypes.string,
    label: PropTypes.string,
    small: PropTypes.bool,
    darker: PropTypes.bool,
    lighter: PropTypes.bool,
    showNotification: PropTypes.func,
    tip: PropTypes.string,
    notificationMsg: PropTypes.string,
    justifyEnd: PropTypes.bool,
  };

  handleClipboard = () => {
    if (this.props.showNotification) {
      this.props.showNotification({
        title: this.props.notificationMsg || 'Text copied to clipboard',
        message: `${isMac ? '⌘' : 'Ctrl'}+v to paste it`,
      });
    }
  };

  render() {
    const {
      url,
      label,
      small,
      darker,
      lighter,
      icon,
      tip,
      hiddenLabel,
      justifyEnd,
    } = this.props;

    return (
      <div
        className={cx(cls.contentWrapper, {
          [cls.styleSmall]: small,
          [cls.styleDarker]: darker,
          [cls.styleLighter]: lighter,
          [cls.justifyEnd]: justifyEnd,
        })}
      >
        {!hiddenLabel && (
          <div>
            {!url ? (
              label
            ) : (
              <a href={url} target={'_blank'} rel="noreferrer">
                {label || url}
              </a>
            )}
          </div>
        )}
        <CopyToClipboard onCopy={this.handleClipboard} text={url || label}>
          <Icon name={icon || 'copy'} tip={tip || defaultTip} />
        </CopyToClipboard>
      </div>
    );
  }
}
