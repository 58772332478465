import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Table } from 'src/components/IMUI/index';

import TableSettings from './TableSettings';

export default class TableHCellSettings extends Component {
  static propTypes = {
    ...Table.HCell.propTypes,

    isCompact: PropTypes.bool,
    onToggleCompact: PropTypes.func,
  };

  render() {
    const { isCompact, onToggleCompact } = this.props;

    return (
      <Table.HCell width={92} textAlign="center">
        <TableSettings
          isCompact={isCompact}
          onToggleCompact={onToggleCompact}
        />
      </Table.HCell>
    );
  }
}
