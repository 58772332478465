import React from 'react';
import { formatNumber } from 'accounting';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import flatten from 'ramda/src/flatten';
import prop from 'ramda/src/prop';
import { Card, Container, Table } from 'src/components/IMUI';
import { Icon } from 'im/ui/Icon';
import cls from './ReachTags.module.css';
import TableComposition from 'src/components/IMUI/Tables/TableComposition';
const cx = classNames.bind(cls);

const calculateSum = (arr) => arr.reduce((acc, el) => acc + Number(el), 0);
const calculateMin = (arr) => Math.min(...arr);
const calculateMax = (arr) => Math.max(...arr);
const flattenGroupValues = (arr, attr) => flatten(arr.map(prop(attr)));
const groupSum = (arr) => calculateSum(flattenGroupValues(arr, 'sum'));
const groupOccurrences = (arr) => arr.reduce((acc, el) => acc + el.count, 0);
const groupMean = (arr) => groupSum(arr) / groupOccurrences(arr);
const groupMin = (arr) => calculateMin(flattenGroupValues(arr, 'min'));
const groupMax = (arr) => calculateMax(flattenGroupValues(arr, 'max'));
const formatNumber2Digits = (n) => formatNumber(n, 2);
const INVALID_TYPE = 'INVALID_TYPE';

const formatters = {
  unit: (taggings) => formatNumber2Digits(groupSum(taggings)),
  percent: (taggings) => `${formatNumber2Digits(groupMean(taggings))}% average`,
  year: (taggings) =>
    `${formatNumber2Digits(groupMin(taggings))} - ${formatNumber2Digits(
      groupMax(taggings)
    )}`,
  default: (taggings) =>
    `min. ${formatNumber2Digits(
      groupMin(taggings)
    )} - max. ${formatNumber2Digits(groupMax(taggings))}`,
  [INVALID_TYPE]: () => '(invalid - mixed reach tag type)',
};
const headers = {
  unit: () => ['Sum', 'Average', 'Tag name', 'Occurrences', 'Min', 'Max'],
  percent: () => ['Tag name', 'Occurrences', 'Min', 'Max', 'Average'],
  year: () => ['Tag name', 'Occurrences', 'Min', 'Max'],
  default: () => ['Tag name', 'Occurrences', 'Min', 'Max', 'Average', 'Sum'],
  [INVALID_TYPE]: () => ['Tag name'],
};
const rows = {
  unit: (tag) => [
    formatNumber2Digits(tag.sum),
    formatNumber2Digits(tag.avg),
    tag.title,
    tag.count,
    formatNumber2Digits(tag.min),
    formatNumber2Digits(tag.max),
  ],
  percent: (tag) => [
    tag.title,
    tag.count,
    formatNumber2Digits(tag.min),
    formatNumber2Digits(tag.max),
    formatNumber2Digits(tag.avg),
  ],
  year: (tag) => [
    tag.title,
    tag.count,
    formatNumber2Digits(tag.min),
    formatNumber2Digits(tag.max),
  ],
  default: (tag) => [
    tag.title,
    tag.count,
    formatNumber2Digits(tag.min),
    formatNumber2Digits(tag.max),
    formatNumber2Digits(tag.avg),
    formatNumber2Digits(tag.sum),
  ],
  [INVALID_TYPE]: (tag) => [`${tag.title}`],
};

const showGroupHeader = (tags) => formatters.default(tags);

export default class ReachTags extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    tagGroups: PropTypes.array.isRequired,
  };
  static defaultProps = { tagGroups: [] };
  state = { visibleGroups: new Set() };
  toggleGroup = (groupId) => {
    const gr = this.state.visibleGroups;
    gr.has(groupId) ? gr.delete(groupId) : gr.add(groupId);
    this.setState({ visibleGroups: new Set(gr) });
  };
  render() {
    return (
      <div className={cx('wrapper', this.props.className)}>
        <h3 className={cls.header}>Reach tags</h3>
        <Card noPadding flat style={{ background: 'transparent' }}>
          <Container max={9999}>
            <ul className={cx('content')}>
              {this.props.tagGroups.map((group, index) => (
                <li key={group.id} className={cls.item}>
                  <div
                    className={classNames(cls.tagGroup, {
                      toggled: this.state.visibleGroups.has(group.id),
                    })}
                    onClick={() => this.toggleGroup(group.id)}
                  >
                    <span>
                      {group.title}&nbsp;
                      <small>({showGroupHeader(group.tags)})</small>
                    </span>
                    <Icon name="chevron-down" />
                  </div>
                  <TableComposition
                    className={cx({
                      [cls.hidden]: !this.state.visibleGroups.has(group.id),
                    })}
                    compact
                    stickyHeader
                  >
                    <Table.Head key={`reachTags-header-${index}`}>
                      {headers.default().map((headerName, cellIndex) => (
                        <Table.HCell
                          textAlign={cellIndex > 0 ? 'right' : 'left'}
                          nowrap
                          text={headerName}
                          key={`reachTags-header-${index}-cell-${cellIndex}`}
                        />
                      ))}
                    </Table.Head>
                    <Table.Body>
                      {group.tags.map((tag, i) => (
                        <Table.Row key={`reachTags-row-${index}-${i}`}>
                          {rows.default(tag).map((rowCol, cellIndex) => (
                            <Table.Cell
                              key={`reachTags-row-${index}-${i}-cell-${cellIndex}`}
                              textAlign={cellIndex > 0 ? 'right' : 'left'}
                            >
                              {rowCol}
                            </Table.Cell>
                          ))}
                        </Table.Row>
                      ))}
                      <Table.Row
                        key={`reachTags-footer-${index}`}
                        className={cls.tableFooter}
                      >
                        <Table.HCell text="Total" />
                        <Table.HCell
                          textAlign="right"
                          text={groupOccurrences(group.tags)}
                        />
                        <Table.HCell
                          textAlign="right"
                          text={formatNumber2Digits(groupMin(group.tags))}
                        />
                        <Table.HCell
                          textAlign="right"
                          text={formatNumber2Digits(groupMax(group.tags))}
                        />
                        <Table.HCell
                          textAlign="right"
                          text={formatNumber2Digits(groupMean(group.tags))}
                        />
                        <Table.HCell
                          textAlign="right"
                          text={formatNumber2Digits(groupSum(group.tags))}
                        />
                      </Table.Row>
                    </Table.Body>
                  </TableComposition>
                </li>
              ))}
            </ul>
          </Container>
        </Card>
      </div>
    );
  }
}
