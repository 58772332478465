import { where } from 'im/api/Query';
import reducerRegistry from 'im/api/registry';
import Resource from 'im/api/Resource';

export const SummaryCalculations = new Resource(
  {
    root: '/api/v3/calculations',
    type: 'calculations',
    asyncReducers: (_, action, state) => {
      const [_x, _y, method, status] = action.type.split('/');
      return status === 'success'
        ? {
            ...state,
            data: { ...state.data, [method]: action.payload.calculations },
          }
        : state;
    },
    reducerConfig: { hasNumericPending: true },
    api: {
      custom: {
        granteesPerTag: {
          method: 'GET',
          path: where(null, {
            source: 'taggings',
            calculations: 'report_grantee_name_count',
            group: [
              'tag_id',
              'tag_title',
              'tag_categories_id',
              'tag_categories_title',
            ],
            'having[report_grantee_name_count_gt]': 0,
          })
            .sort('tag_categories_title')
            .filter('quantity_null', true)
            .filter('project_id_eq', ':projectId')
            .toString(),
        },
        granteesPerTagCategoryCount: {
          method: 'GET',
          path: where(null, {
            source: 'taggings',
            calculations: 'report_grantee_name_count',
            group: ['tag_categories_id', 'tag_categories_title'],
          })
            .filter('quantity_null', true)
            .filter('project_id_eq', ':projectId')
            .toString(),
        },
        grantsPerTag: {
          method: 'GET',
          path: where(null, {
            source: 'taggings',
            calculations: 'report_grant_name_count',
            group: [
              'tag_id',
              'tag_title',
              'tag_categories_id',
              'tag_categories_title',
            ],
            'having[report_grant_name_count_gt]': 0,
          })
            .sort('tag_categories_title')
            .filter('quantity_null', true)
            .filter('project_id_eq', ':projectId')
            .toString(),
        },
        grantsPerTagCategoryCount: {
          method: 'GET',
          path: where(null, {
            source: 'taggings',
            calculations: 'report_grant_name_count',
            group: ['tag_categories_id', 'tag_categories_title'],
          })
            .filter('project_id_eq', ':projectId')
            .filter('quantity_null', true)
            .toString(),
        },
        tagsPerReportsCustom: {
          method: 'GET',
          path: where(null, {
            source: 'taggings',
            calculations: 'id_count',
            group: [
              'tag_id',
              'tag_title',
              'tag_categories_id',
              'tag_categories_title',
            ],
          })
            .sort('tag_categories_title')
            .filter('quantity_null', true)
            .filter('tag_categories_title_not_eq', 'Default Outcomes')
            .filter('project_id_eq', ':projectId')
            .toString(),
        },
        tagsPerReportsMetatags: {
          method: 'GET',
          path: where(null, {
            source: 'taggings',
            calculations: 'id_count',
            group: [
              'tag_id',
              'tag_title',
              'tag_categories_id',
              'tag_categories_title',
            ],
          })
            .sort('tag_categories_title')
            .filter('quantity_null', true)
            .filter('tag_categories_metatags_in_any', '{ :metatags }')
            .filter('project_id_eq', ':projectId')
            .toString(),
        },
        taggingsPerTagCategory: {
          method: 'GET',
          path: where(null, {
            source: 'taggings',
            calculations: 'id_count',
            group: ['tag_categories_id', 'tag_categories_title'],
          })
            .sort('tag_categories_title')
            .filter('quantity_null', true)
            .filter('project_id_eq', ':projectId')
            .toString(),
        },
        reachTaggings: {
          method: 'GET',
          path: where(null, {
            source: 'taggings',
            calculations: [
              'id_count',
              'quantity_max',
              'quantity_min',
              'quantity_sum',
              'quantity_avg',
            ],
            group: [
              'tag_id',
              'tag_title',
              'tag_categories_id',
              'tag_categories_title',
            ],
          })
            .filter('quantity_null', false)
            .filter('project_id_eq', ':projectId')
            .toString(),
        },
      },
    },
  },
  'summaryCalculations'
);
reducerRegistry.register(SummaryCalculations);
export default SummaryCalculations.api;
