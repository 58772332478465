import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { submit, isDirty } from 'redux-form';
import { updateProject } from 'src/actionCreators/projectActionCreators';
import tagCategoriesApi from 'src/api/TagCategories';
import tagCategoriesSelectorApi from 'src/api/TagCategoriesSelector';
import tagCategoryApi from 'src/api/TagCategory';
import { confirm } from 'src/components/ConfirmModal/ConfirmModal';
import {
  Button,
  Container,
  Actions,
  Drawer,
  Progress,
} from 'src/components/IMUI/index';
import { toApiFromCreateProject } from 'src/serializers/projectSerializer';

import { where } from 'im/api/Query';
import { Icon } from 'im/ui/Icon';

import TagCategoryForm from './TagCategoryForm';

import cls from './EditTagCategory.module.css';
import { canManageTag } from 'src/userStorage';

const FORM_NAME = 'editTagCategoryForm';

@connect(
  (state) => ({
    project: state.project,
    tagCategory: state.tagCategory,
    isFormDirty: isDirty(FORM_NAME)(state),
  }),
  {
    getTagCategory: tagCategoryApi.find,
    updateTagCategory: tagCategoriesApi.ofProject.put,
    deleteTagCategory: tagCategoriesApi.ofProject.destroy,
    submitForm: submit,
    updateProject,
    getTagSelectorTagCategories:
      tagCategoriesSelectorApi.findAllPerProjectNested,
  }
)
export default class EditTagCategory extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    project: PropTypes.object,
    tagCategory: PropTypes.object,
    categoryId: PropTypes.string,
    isFormDirty: PropTypes.bool,
    urlParam: PropTypes.string,
    noSync: PropTypes.bool,
    updateProject: PropTypes.func.isRequired,

    getTagCategory: PropTypes.func.isRequired,
    updateTagCategory: PropTypes.func.isRequired,
    deleteTagCategory: PropTypes.func.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    onSuccessfulEdit: PropTypes.func.isRequired,
    getTagSelectorTagCategories: PropTypes.func.isRequired,
  };

  static getDeleteConfirmation = (tagCategory, tagCategoryTags = []) => {
    const taggingsCount = tagCategoryTags.reduce(
      (acc, el) => acc + el.taggings_count,
      0
    );

    const title = 'Delete a tag group';
    const textAdditional = `It contains ${tagCategory.tags?.length} tag${
      tagCategory.tags?.length > 1 ? 's' : ''
    },
      which are applied ${taggingsCount} time${
        taggingsCount === 1 ? '' : 's'
      } in total`;
    const text = `Are you sure you want to delete a tag group: ${
      tagCategory.title
    }?
      ${tagCategory.tags?.length > 0 ? textAdditional : ''}`;

    return confirm(title, text, true);
  };

  componentDidMount() {
    const query = where({ id: this.props.categoryId })
      .include('tags', 'parent')
      .fields('parent', 'title')
      .fields('tag', 'taggings_count')
      .pending('init');
    this.props.getTagCategory(query);
  }

  handleRequestClose = () => {
    this.props.onRequestClose();
  };

  handleUpdateTagCategory = async ({
    metatags,
    title,
    description,
    code,
    id,
    parent,
  }) => {
    const { noSync, project } = this.props;

    const query = where({ id })
      .payload({
        metatags,
        title,
        description,
        code,
        relationships: [
          { relName: 'parent', type: 'tag_category', id: (parent || {}).id },
        ],
      })
      .actionMeta({ noSync });

    const tagCategoryUpdated = await this.props.updateTagCategory(query);
    if (!tagCategoryUpdated) return;

    if (parent && parent !== null) {
      const payload = toApiFromCreateProject({
        ...project,
        enabled_tag_categories: project.enabled_tag_categories.filter(
          (tagCategoryId) => tagCategoryId !== id
        ),
      });
      await this.props.updateProject(project.id, {
        ...payload,
        noSideEffect: true,
      });
    } else if (!project.enabled_tag_categories.includes(id)) {
      const newEnabledTagCategories = [...project.enabled_tag_categories];
      newEnabledTagCategories.push(id);
      const payload = toApiFromCreateProject({
        ...project,
        enabled_tag_categories: newEnabledTagCategories,
      });
      await this.props.updateProject(project.id, {
        ...payload,
        noSideEffect: true,
      });
    }
    this.props.onSuccessfulEdit([{ id, title, parent }]);
    this.props.onRequestClose();
  };

  handleRequestUpdateTagCategory = () => {
    this.props.submitForm(FORM_NAME);
  };

  handleDeleteTagCategory = () => {
    const { tagCategory } = this.props;

    EditTagCategory.getDeleteConfirmation(
      tagCategory.data,
      tagCategory.data.tags
    )
      .then(async () => {
        await this.props.deleteTagCategory({ id: tagCategory.data.id });
        this.props.getTagSelectorTagCategories();
        this.props.onRequestClose();
      })
      .catch(() => void 0);
  };

  renderFooter = () => {
    const { isFormDirty } = this.props;

    return (
      <Container horizontal>
        <Actions>
          <Button
            negative
            size="l"
            label="Cancel"
            onClick={this.handleRequestClose}
          />
        </Actions>
        <Actions>
          <Button
            size="l"
            disabled={!isFormDirty}
            label="Save"
            onClick={this.handleRequestUpdateTagCategory}
          />
        </Actions>
      </Container>
    );
  };

  render() {
    const { project, open, tagCategory, urlParam } = this.props;

    return (
      <Drawer
        closable
        open={open}
        width={580}
        docked={true}
        urlParam={urlParam}
        renderFooter={this.renderFooter}
        onRequestClose={this.handleRequestClose}
      >
        {tagCategory.pending.init ? (
          <Progress />
        ) : (
          <div>
            <h3>Edit a tag group</h3>
            <TagCategoryForm
              form={FORM_NAME}
              allMetatags={project.metatags}
              initialValues={tagCategory.data}
              onSubmit={this.handleUpdateTagCategory}
            />
            {canManageTag() && (
              <Button
                text
                alert
                className={cls.buttonAlert}
                label="Delete tag group"
                onClick={this.handleDeleteTagCategory}
                icon={<Icon name="trash" tip="Remove" />}
              />
            )}
          </div>
        )}
      </Drawer>
    );
  }
}
