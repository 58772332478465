import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import {
  Button,
  Card,
  CardEmpty,
  Dialog,
  Table,
  Status,
  CardFooter,
  Checkbox,
} from 'src/components/IMUI';
import PaginationIm from 'src/components/IMUI/Pagination/PaginationIm';
import { getText } from 'src/services/DictionaryService';

import cls from './ContactsModal.module.css';
import { canManageSurvey } from 'src/userStorage';
import TableComposition from 'src/components/IMUI/Tables/TableComposition';

const cx = classNames.bind(cls);

const extractContactPayload = ({ email, id, name, owner_id, owner_type }) => ({
  email,
  id,
  name,
  owner_id,
  owner_type,
});

export default class ImportContactsModal extends React.Component {
  static propTypes = {
    open: PropTypes.bool,
    contacts: PropTypes.object,
    respondents: PropTypes.array,
    model: PropTypes.string,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    onSort: PropTypes.func,
    onPaginate: PropTypes.func,
  };

  static defaultProps = {
    onCancel: () => void 0,
    onSubmit: () => void 0,
  };

  state = {
    selectedContacts: {},
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.setState({ selectedContacts: {} });
    }
  }

  handleAddSelected = () => {
    this.props.onSubmit(
      Object.values(this.state.selectedContacts).map(extractContactPayload)
    );
  };

  handleAddAll = () => {
    const notAdded = this.props.contacts.data
      .filter((contact) => !this.isAdded(contact))
      .map(extractContactPayload);

    this.props.onSubmit(notAdded);
  };

  handleToggleContact = (contact) => {
    const selectedContacts = this.state.selectedContacts;
    const isChecked = this.isChecked(contact);
    if (isChecked) {
      delete selectedContacts[contact.id];
    } else {
      selectedContacts[contact.id] = contact;
    }
    this.setState({ selectedContacts: { ...selectedContacts } });
  };

  isChecked = (contact) => {
    return !!this.state.selectedContacts[contact.id];
  };

  isAdded = (contact) => {
    return !!this.props.respondents.find(
      ({ name, email }) => contact.name === name && contact.email === email
    );
  };

  renderStatus = (status) => {
    return status ? <Status done /> : <Status disabled />;
  };

  renderContactsListItem = (contact) => {
    const { model } = this.props;
    return (
      <Table.Row
        onClick={() => this.handleToggleContact(contact)}
        key={contact.id}
      >
        <Table.Cell>
          <Checkbox checked={this.isChecked(contact)} />
        </Table.Cell>
        <Table.Cell actions>
          {this.renderStatus(this.isAdded(contact))}
        </Table.Cell>
        <Table.Cell text={contact.name} />
        <Table.Cell text={contact.email} />
        <Table.Cell
          text={model === 'Grant' ? contact.grant?.name : contact.grantee?.name}
        />
      </Table.Row>
    );
  };

  renderContactsList = (contacts) => {
    const { model } = this.props;
    const { queryParams = {} } = contacts.meta.getAction || {};
    const ownerNameSortBy = model === 'Grant' ? 'grants.name' : 'grantees.name';

    return (
      <Card>
        {!contacts.data.length ? (
          <CardEmpty>{getText(`${model} contact book is empty`)}</CardEmpty>
        ) : (
          <TableComposition compact>
            <Table.Head
              jsonapi
              sortBy={queryParams.sort}
              onSortChange={this.props.onSort}
            >
              <Table.HCell width={20} />
              <Table.HCell width={46} text="Added" />
              <Table.HCell text="Name" sortBy="name" />
              <Table.HCell text="Email" sortBy="email" />
              <Table.HCell text={getText(model)} sortBy={ownerNameSortBy} />
            </Table.Head>
            <Table.Body>
              {contacts.data.map(this.renderContactsListItem)}
            </Table.Body>
          </TableComposition>
        )}

        <CardFooter>
          <PaginationIm
            {...contacts.links.meta}
            label={`${model.toLowerCase()} contacts`}
            items_label="contacts"
            onChange={this.props.onPaginate}
            perPageOptions={[10, 30]}
          />
        </CardFooter>
      </Card>
    );
  };

  render() {
    const { open, contacts, onCancel, model } = this.props;

    if (!model) return null;

    return (
      <Dialog
        extraLarge
        contentClassName={cx('dialog')}
        title={getText(`Import ${model} contacts`)}
        rightActions={[
          <Button
            disabled={!canManageSurvey()}
            key="addAll"
            secondary
            size="l"
            label="Add all"
            onClick={this.handleAddAll}
          />,
          <Button
            disabled={!canManageSurvey()}
            key="add"
            size="l"
            label="Add selected"
            onClick={this.handleAddSelected}
          />,
        ]}
        leftActions={
          <Button size="l" negative label="Cancel" onClick={onCancel} />
        }
        open={open}
        onRequestClose={onCancel}
      >
        <div className={cx('contacts')}>
          {this.renderContactsList(contacts)}
        </div>
      </Dialog>
    );
  }
}
