import QueryParamsManager from './QueryParamsManager';

// This is needed because API discard filters with undefined value, like `filter[id_eq_any]=`, and return wrong data as filter is not applied.
const makeValue = (value = false) =>
  Array.isArray(value) && !value.length ? false : value;

function makeParams(params) {
  if (!params) return params;

  return Object.keys(params).reduce((acc, key) => {
    acc[key] = makeValue(params[key]);
    return acc;
  }, {});
}

class Query {
  constructor(params, queryParams = {}, data = null, meta = {}) {
    this.urlParams = params;
    this.queryParams = queryParams;
    this.data = data;
    this.meta = meta;
    this.noDispatch = false;
  }

  static where = (params, queryParams, data, meta) => {
    return new Query(makeParams(params), queryParams, data, meta);
  };

  _makeRelationships(_relationships) {
    const relationships =
      _relationships && !Array.isArray(_relationships)
        ? [_relationships]
        : _relationships;
    return relationships?.reduce(
      (acc, rel) => ({
        ...acc,
        [rel.relName || rel.type]: { data: { id: rel.id, type: rel.type } },
      }),
      {}
    );
  }

  _makeArrayWhenRepeated(all = {}, key, value) {
    const filterValue = makeValue(value);
    if (!all[key] || all[key] === value) {
      return filterValue;
    }

    const currentValue = Array.isArray(all[key]) ? all[key] : [all[key]];
    return currentValue.concat(filterValue);
  }

  _appendLogicalPredicate(key) {
    if (key.match(new RegExp(`_all|_any`, 'g'))) return key;
    return key.includes('_not_') ? `${key}_all` : `${key}_any`;
  }

  // TODO: Remove when we have grouping by _all, _any in place
  _extendFilterKeys(filters) {
    return Object.keys(filters || {})?.length > 0
      ? Object.keys(filters).reduce(
          (acc, _k) => ({
            ...acc,
            [Array.isArray(filters[_k])
              ? this._appendLogicalPredicate(_k)
              : _k]: filters[_k],
          }),
          {}
        )
      : filters;
  }

  /**
   * Declares action meta
   * @param {Object} meta * identifiable, noSync, doDelete, isInit, noNotify, logo, includeMapper, headers, notified, silent
   */
  actionMeta = (meta) => {
    this.meta = meta;
    return this;
  };

  /**
   * Set pending status
   * @param {string} pendingType - "init" | "ui" | "background"
   */
  pending = (pendingType) => {
    this.meta = ['init', 'ui', 'background'].includes(pendingType)
      ? { ...this.meta, pending: pendingType }
      : (({ pending, ...rest }) => rest)(this.meta);
    return this;
  };

  /**
   * Indicates that returned entities (doesn't) have to be unique
   * @param {boolean} value
   * @returns {Query}
   */
  distinct = (value = true) => {
    this.queryParams = { ...this.queryParams, distinct: value };
    return this;
  };

  /**
   * Indicates that request won't be dispatched (saved in the store)
   * @param {boolean} doDispatch
   * @returns {Query}
   */
  dispatch = (doDispatch) => {
    this.meta = { ...this.meta, noDispatch: !doDispatch };
    return this;
  };

  /**
   * Specifies POST payload
   * @param {Object} attributes - resource attributes
   * @param {Object|Array<Object>} relationships - resource relationships
   * @param {string} relationships.relName - resource relationship name
   * @param {string} relationships.type - resource relationship JSONAPI type
   * @param {string} relationships.id - resource relationship entity id
   * @param {Object} rest - if no attributes key present then rest is wrapped in attributes
   * @param {boolean} raw - flag to omit wrapping in JSONAPI data structure
   * @returns {Query}
   */
  payload = ({ attributes, relationships, ...rest } = {}, { raw } = {}) => {
    this.data = raw
      ? rest
      : {
          attributes: attributes || rest,
          relationships: this._makeRelationships(relationships),
        };
    return this;
  };

  /**
   * Specifies POST payload as FormData
   * @param {Object<string, any>} body
   * @param {string} [prefix=""] - custom prefix prepended in formData
   * @returns {Query}
   */
  payloadMultipart = (body, prefix = '') => {
    const formData = new FormData();
    Object.keys(body).forEach((key) =>
      formData.append(`${prefix}[${key}]`, body[key])
    );

    this.data = formData;
    return this;
  };

  /**
   * Extends existing queryParams with custom ones
   * @param {string} key
   * @param {any} [value]
   * @returns {Query}
   */
  params = (key, value) => {
    this.queryParams = { ...this.queryParams, [key]: value };
    return this;
  };

  /**
   * Declares sort params
   * @param {...string} sortKeys - resource attributes to sort by
   * @returns {Query}
   */
  sort = (...sortKeys) => {
    this.queryParams = { ...this.queryParams, sort: sortKeys };
    return this;
  };

  // All available matchers - https://github.com/activerecord-hackery/ransack#search-matchers
  /**
   * Declares filters params
   * @param {string|undefined|Array<Object.<string, string|boolean>>} key - single filter key or multiple filters at once
   * @param {string|boolean} [value = true] - filter value
   * @returns {Query}
   */
  filter = (key, value = true) => {
    if (key == undefined) return this;
    this.queryParams = {
      ...this.queryParams,
      filter: this._extendFilterKeys(
        Array.isArray(key)
          ? key.reduce(
              (acc, { key: _k, value: _v }) => ({
                ...acc,
                [_k]: this._makeArrayWhenRepeated(acc, _k, _v) || true,
              }),
              this.queryParams.filter || {}
            )
          : {
              ...this.queryParams.filter,
              [key]: this._makeArrayWhenRepeated(
                this.queryParams.filter,
                key,
                value
              ),
            }
      ),
    };
    return this;
  };

  /**
   * Declares resource relationships names to be included
   * @param {...string} types - relationships names
   * @returns {Query}
   */
  include = (...types) => {
    this.queryParams = { ...this.queryParams, include: types };
    return this;
  };

  paginate = ({ number, size }) => {
    const newPage = {
      ...(this.queryParams.page || {}),
      ...(number !== undefined ? { number } : {}),
      ...(size !== undefined ? { size } : {}),
    };
    this.queryParams = { ...this.queryParams, page: { ...newPage } };
    return this;
  };

  /**
   * Restricts resource attributes or relationships
   * @param {string} key - JSONAPI type
   * @param {...string} values - JSONAPI attributes/relationships
   * @returns {Query}
   */
  fields = (key, ...values) => {
    this.queryParams = {
      ...this.queryParams,
      fields: {
        ...this.queryParams.fields,
        [key]: this._makeArrayWhenRepeated(
          this.queryParams.fields,
          key,
          values
        ),
      },
    };

    return this;
  };

  /**
   * Converts current queryParams into string, starting with "?"
   * @param {Object} opts - im/api/QueryParamsManager.js:150
   * @returns {string}
   */
  toString = (opts = {}) =>
    `?${QueryParamsManager.stringify(this.queryParams, opts)}`;

  /**
   * Extends current queryParams with new ones, formatted as a string
   * @param {string} urlSearch - formatted like location.search
   * @returns {Query}
   */
  fromString = (urlSearch) => {
    this.queryParams = {
      ...this.queryParams,
      ...QueryParamsManager.parse(urlSearch),
    };
    return this;
  };
}

export const where = Query.where;

export default Query;
