import React, { Component } from 'react';

import { FullBgView } from 'src/components/FullBgView';
import { Button } from 'src/components/IMUI/index';
import history from 'src/historyInstance';

export default class ForgotPasswordSuccess extends Component {
  onRedirectToLogin = () => {
    history.push('/login');
  };

  render() {
    return (
      <FullBgView hasLogo>
        <FullBgView.Box
          title="Check your email to continue"
          leftAction={
            <Button
              dark
              text
              underlined
              label="Back to login"
              onClick={this.onRedirectToLogin}
            />
          }
          info={
            'You should receive instructions on resetting\nyour password shortly.'
          }
        />
      </FullBgView>
    );
  }
}
