import React from 'react';
import classNames from 'classnames/bind';
import MenuItem from 'material-ui/MenuItem/MenuItem';
import PropTypes from 'prop-types';
import { Menu, EmptyText } from 'src/components/IMUI';
import cls from './PopoverMenu.module.css';
const cx = classNames.bind(cls);

const ITEM_STYLE = {
  fontSize: 14,
  lineHeight: 'normal',
  minHeight: 0,
  padding: '12px 0',
};

const Item = ({ className, style, leftIcon, rightIcon, ...rest }) => (
  <MenuItem
    {...rest}
    className={cx(
      cls.popoverMenuItem,
      {
        [cls.popoverMenuItemLeftIcon]: !!leftIcon,
        [cls.popoverMenuItemRightIcon]: !!rightIcon,
      },
      className
    )}
    style={{ ...ITEM_STYLE, ...style }}
    leftIcon={leftIcon}
    rightIcon={rightIcon}
  />
);

Item.propTypes = { ...MenuItem.propTypes };

const PopoverMenu = ({
  children,
  className,
  showEmptyListPlaceholder,
  ...rest
}) => (
  <Menu {...rest} className={className}>
    {!showEmptyListPlaceholder || React.Children.count(children) ? null : (
      <EmptyText
        className={cls.popoverMenuPlaceholder}
        text="No results found"
      />
    )}
    {children}
  </Menu>
);

PopoverMenu.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  showEmptyListPlaceholder: PropTypes.bool,
};
PopoverMenu.defaultProps = { showEmptyListPlaceholder: true };
PopoverMenu.Item = Item;
export default PopoverMenu;
