import axios from 'src/axiosInstance';
import {
  FUNDING__CREATE_REQUEST,
  FUNDING__CREATE_FAILURE,
  FUNDING__CREATE_SUCCESS,
  FUNDING__UPDATE_REQUEST,
  FUNDING__UPDATE_FAILURE,
  FUNDING__UPDATE_SUCCESS,
  FUNDING__DELETE_REQUEST,
  FUNDING__DELETE_FAILURE,
  FUNDING__DELETE_SUCCESS,
} from 'src/constants';

export function doCreateFunding(payload) {
  return axios
    .post('/v2/fundings', payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export function createFunding(payload) {
  return async (dispatch) => {
    dispatch({ type: FUNDING__CREATE_REQUEST });
    const { response, error } = await doCreateFunding(payload);
    if (response) {
      dispatch({ type: FUNDING__CREATE_SUCCESS, payload: response });
    } else {
      dispatch({ type: FUNDING__CREATE_FAILURE, payload: error, error: true });
    }
  };
}

export function doUpdateFunding(funding) {
  return axios
    .put(`/v2/fundings/${funding.id}`, funding)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export function updateFunding(payload) {
  return async (dispatch) => {
    dispatch({ type: FUNDING__UPDATE_REQUEST });
    const { response, error } = await doUpdateFunding(payload);
    if (response) {
      dispatch({ type: FUNDING__UPDATE_SUCCESS, payload: response });
    } else {
      dispatch({ type: FUNDING__UPDATE_FAILURE, payload: error, error: true });
    }
  };
}

export function doDeleteFunding(fundingId) {
  return axios
    .delete(`/v1/fundings/${fundingId}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export function deleteFunding(fundingId, grantId) {
  return async (dispatch) => {
    dispatch({ type: FUNDING__DELETE_REQUEST });
    const { response, error } = await doDeleteFunding(fundingId);
    if (response) {
      dispatch({
        type: FUNDING__DELETE_SUCCESS,
        payload: { fundingId, grantId },
      });
    } else {
      dispatch({ type: FUNDING__DELETE_FAILURE, payload: error, error: true });
    }
  };
}
