import React from 'react';
import PropTypes from 'prop-types';
import { Toggle } from 'src/components/IMUI';
import TagSelector from 'src/components/TagSelector/TagSelector';
import TooltipIcon from 'src/components/TooltipIcon';
import BaseQuestionProperties from './BaseQuestionProperties';
import PropertyBox from './PropertyBox';

export default class NumericalQuestionProperties extends React.PureComponent {
  static propTypes = {
    question: PropTypes.object,
    tagsWithTagGroups: PropTypes.array,
    onChangeQuestionProperty: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    isAnswerMode: PropTypes.bool,
  };
  static defaultProps = { errors: {}, question: {} };
  static validate() {
    return {};
  }

  handleTagsChanged = (tagId) => {
    this.props.question.tags = [tagId];
    this.props.onChangeQuestionProperty(
      this.props.question.id,
      'tags',
      this.props.question.tags
    );
  };
  handleTagRemoved = (tagId) => {
    if (this.props.question.tags.indexOf(tagId) !== -1) {
      this.props.onChangeQuestionProperty(
        this.props.question.id,
        'tags',
        this.props.question.tags.filter((id) => id !== tagId)
      );
    }
  };

  handlePositiveOnlyToggle = (ev, value) => {
    this.props.onChangeQuestionProperty(
      this.props.question.id,
      'positiveOnly',
      !this.props.question.positiveOnly
    );
  };

  renderSettings = () => {
    return (
      <Toggle
        mini
        label={
          <span>
            Only allow positive values
            <TooltipIcon tip={'Only allow positive values'} />
          </span>
        }
        labelPosition="right"
        toggled={this.props.question.positiveOnly}
        onToggle={this.handlePositiveOnlyToggle}
      />
    );
  };

  render() {
    return (
      <BaseQuestionProperties
        {...this.props}
        renderSettings={this.renderSettings}
      >
        <PropertyBox>
          <TagSelector
            isAnswerMode={this.props.isAnswerMode}
            readOnly={this.props.readOnly}
            tagsWithTagGroups={this.props.tagsWithTagGroups}
            noTagProps={{
              label: 'Add tag',
              grey: true,
              square: true,
              outline: false,
            }}
            selected={this.props.question.tags}
            onChange={this.handleTagsChanged}
            onRemove={this.handleTagRemoved}
          />
        </PropertyBox>
      </BaseQuestionProperties>
    );
  }
}
