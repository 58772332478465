import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FieldArray, arrayPush, change } from 'redux-form';
import { Actions, Divider, Container, Button } from 'src/components/IMUI/index';
import InvitationItem, { validateInvitationItem } from './InvitationItem';
import cls from './InvitationsList.module.css';
import { canManageOrganization } from 'src/userStorage';
const INVITATIONS = 'invitations';

export function validateInvitations(values = {}) {
  return {
    [INVITATIONS]: (values[INVITATIONS] || []).map(validateInvitationItem),
  };
}

@connect(null, { arrayPush, change })
export default class InvitationsList extends React.Component {
  static propTypes = {
    form: PropTypes.string,
    invitations: PropTypes.array,
    arrayPush: PropTypes.func,
    change: PropTypes.func,
    onCancel: PropTypes.func,
    maxInvitationsLimit: PropTypes.number,
  };

  static defaultProps = { invitations: [] };
  componentDidMount() {
    if (!this.props.invitations.length) {
      this.onAddInvitation();
    }
  }
  componentWillReceiveProps(nextProps) {
    if (!nextProps.invitations.length) {
      this.onAddInvitation();
    }
  }
  onAddInvitation = () => {
    this.props.arrayPush(this.props.form, INVITATIONS, {
      full_name: '',
      email: '',
    });
  };
  onCancel = () => {
    this.props.change(this.props.form, INVITATIONS, []);
    this.props.onCancel();
  };
  renderInvitations = ({ fields }) => (
    <ol className={cls.invitationsList}>
      {fields.map((member, n, i) => (
        <InvitationItem
          key={`${member}_${n}`}
          member={member}
          index={n}
          fields={i}
        />
      ))}
    </ol>
  );

  render() {
    return (
      <div>
        <Divider className={cls.spaceTop} />
        <h3>Invitations</h3>
        <FieldArray name={INVITATIONS} component={this.renderInvitations} />
        <Container horizontal>
          <Actions>
            <Button
              negative
              type="button"
              label="Cancel"
              onClick={this.onCancel}
            />
          </Actions>
          <Actions>
            <Button
              secondary
              label="Add more"
              type="button"
              disabled={
                !canManageOrganization() ||
                this.props.invitations.length >= this.props.maxInvitationsLimit
              }
              onClick={this.onAddInvitation}
            />
            <Button
              disabled={!canManageOrganization()}
              label="Send invitations"
              type="submit"
            />
          </Actions>
        </Container>
      </div>
    );
  }
}
