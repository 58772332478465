import React from 'react';
import { Container, Table, Toggle } from 'src/components/IMUI';
import TableComposition from 'src/components/IMUI/Tables/TableComposition';

const ExamplesForAutoFormatting = ({ normalizeList, setNormalizeList }) => {
  return (
    <Container>
      <h5>Auto-formatting</h5>
      <TableComposition>
        <Table.Head>
          <Table.HCell width={200} text={'Attribute'} />
          <Table.HCell width={200} text={'Original'} />
          <Table.HCell width={200} text={'Auto Formatted'} />
          <Table.HCell text={'Explanation'} />
          <Table.HCell width={200} text={'Enable?'} />
        </Table.Head>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Dates</Table.Cell>
            <Table.Cell>October 20, 2024</Table.Cell>
            <Table.Cell>2024-10-20</Table.Cell>
            <Table.Cell>
              <li>
                Follows the <i>YYYY-MM-DD</i> format
              </li>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Population List</Table.Cell>
            <Table.Cell>women ; minority Group; studenTs</Table.Cell>
            <Table.Cell>Women;Minority group;Students</Table.Cell>
            <Table.Cell>
              <li>Capitalize each item</li>
              <li>Removes extra whitespaces</li>
            </Table.Cell>
            <Table.Cell>
              <Toggle
                toggled={normalizeList}
                onToggle={(_, toggled) => setNormalizeList(toggled)}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Funding Amount</Table.Cell>
            <Table.Cell>USD 40,000</Table.Cell>
            <Table.Cell>40000</Table.Cell>
            <Table.Cell>
              <li>Extracts the amount only</li>
              <li>
                Creates a new column <strong>Funding Currency</strong> with{' '}
                <strong>USD</strong> as value
              </li>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </TableComposition>
    </Container>
  );
};

export default ExamplesForAutoFormatting;
