import PropTypes from 'prop-types';
import React from 'react';

import {
  Button,
  TextField,
  NumberField,
  SelectField,
  Toggle,
} from 'src/components/IMUI';
import { SECTION_BREAK } from 'src/data/questionTypes';
import { OPERATORS } from 'src/utils/surveysConditionalLogic';
import { getSurveyI18n } from 'src/utils/surveysI18n';

import { Icon } from 'im/ui/Icon';

import PropertyBox from './PropertyBox';

import cls from './ConditionalLogicProperty.module.css';
import { canBuildSurvey } from 'src/userStorage';

export class ConditionalLogicProperty extends React.Component {
  static propTypes = {
    survey: PropTypes.object,
    question: PropTypes.object,
    contextLabel: PropTypes.string,
    onChangeQuestionProperty: PropTypes.func,
  };

  handleToggleConditionalLogic = () => {
    const { question, onChangeQuestionProperty } = this.props;
    if (question.conditional_logic.length) {
      this.handleRemoveCondition();
    } else {
      onChangeQuestionProperty(question.id, ['conditional_logic', 0], {});
    }
  };

  handleCLBaseOperatorChange = (value) => {
    const { question, onChangeQuestionProperty } = this.props;
    onChangeQuestionProperty(
      question.id,
      ['settings', 'clBaseOperator'],
      value
    );
  };

  handleCLEarlyTerminateChange = () => {
    const { question, onChangeQuestionProperty } = this.props;
    // as string to allow other condtions in the future
    const newValue =
      question.settings && question.settings.clEarlyTerminate === 'NOT_MET'
        ? 'NONE'
        : 'NOT_MET';
    onChangeQuestionProperty(
      question.id,
      ['settings', 'clEarlyTerminate'],
      newValue
    );
  };

  handleCLEarlyDisableChange = () => {
    const { question, onChangeQuestionProperty } = this.props;
    // as string to allow other condtions in the future
    const newValue =
      question.settings && question.settings.clEarlyDisable === 'NOT_MET'
        ? 'NONE'
        : 'NOT_MET';
    onChangeQuestionProperty(
      question.id,
      ['settings', 'clEarlyDisable'],
      newValue
    );
  };

  handleCLEarlyTerminateMessageChange = (data) => {
    const { question, onChangeQuestionProperty } = this.props;
    onChangeQuestionProperty(
      question.id,
      ['settings', 'clEarlyTerminateMessage'],
      data
    );
  };

  handleCLQuestionChange = (value, index) => {
    const { question, onChangeQuestionProperty } = this.props;
    onChangeQuestionProperty(question.id, ['conditional_logic', index], {
      question_id: value,
    });
  };

  handleCLQuestionValueChange = (value, index) => {
    const { question, onChangeQuestionProperty } = this.props;
    onChangeQuestionProperty(
      question.id,
      ['conditional_logic', index, 'value'],
      value
    );
  };

  handleCLOperatorChange = (value, index) => {
    const { question, onChangeQuestionProperty } = this.props;
    onChangeQuestionProperty(
      question.id,
      ['conditional_logic', index, 'operator'],
      value
    );
  };

  handleAddCondition = () => {
    const { question, onChangeQuestionProperty } = this.props;
    const conditions = question.conditional_logic;
    if (!conditions.length) {
      onChangeQuestionProperty(question.id, ['conditional_logic'], [{}, {}]);
    } else {
      const prevCondition = conditions[conditions.length - 1];
      const newCondition = prevCondition.question_id
        ? { question_id: prevCondition.question_id }
        : {};
      onChangeQuestionProperty(
        question.id,
        ['conditional_logic', conditions.length || 1],
        newCondition
      );
    }
  };

  handleRemoveCondition = (index) => {
    const { question, onChangeQuestionProperty } = this.props;
    let conditions;
    if (typeof index === 'undefined') {
      conditions = [];
    } else {
      conditions = [...question.conditional_logic];
      if (index === 0) {
        conditions[0] = {};
      } else {
        conditions.splice(index, 1);
      }
    }
    onChangeQuestionProperty(question.id, ['conditional_logic'], conditions);
  };

  renderQuestionSelect(condition, conditionIndex) {
    const { question, survey } = this.props;
    const selectedQuestion = survey.questions.find(
      (q) => q.id === condition.question_id
    );
    return (
      <SelectField
        noValidation
        flatDark
        value={selectedQuestion ? condition.question_id : -1}
        onChange={({ value }) =>
          this.handleCLQuestionChange(value, conditionIndex)
        }
        maxHeight={300}
        hintText="Select question"
      >
        <SelectField.Item disabled value={-1} primaryText="Select question" />
        {survey.questions
          .filter((q) => !q.isLayout && q.id !== question.id)
          .map((q) => (
            <SelectField.Item
              key={q.id}
              value={q.id}
              primaryText={getSurveyI18n(survey, q, 'question', 'question')}
            />
          ))}
      </SelectField>
    );
  }

  renderLogicalOperator(condition, conditionIndex) {
    const { survey } = this.props;

    if (!condition.question_id) {
      return null;
    }

    const selectedQuestion = survey.questions.find(
      (q) => q.id === condition.question_id
    );
    if (!selectedQuestion) return null;

    const operators = [OPERATORS.NOT_EMPTY, OPERATORS.EMPTY];

    if (
      selectedQuestion.type.match(/shortText|longText/) ||
      (selectedQuestion.matrixQuestionType &&
        selectedQuestion.matrixQuestionType.match(/shortText/))
    ) {
      operators.push(
        OPERATORS.CONTAINS,
        OPERATORS.IS_EQUAL,
        OPERATORS.IS_NOT_EQUAL
      );
    } else if (selectedQuestion.type.match(/singleChoice|multipleChoice/)) {
      operators.push(OPERATORS.INCLUDES, OPERATORS.NOT_INCLUDES);
    } else if (
      selectedQuestion.type.match(/financial|numerical/) ||
      (selectedQuestion.matrixQuestionType &&
        selectedQuestion.matrixQuestionType.match(/numerical|financial/))
    ) {
      operators.push(
        OPERATORS.IS_GREATER_THAN,
        OPERATORS.IS_LESS_THAN,
        OPERATORS.IS_EQUAL,
        OPERATORS.IS_NOT_EQUAL
      );
    }

    return (
      <SelectField
        flatDark
        noValidation
        value={condition.operator || -1}
        onChange={({ value }) =>
          this.handleCLOperatorChange(value, conditionIndex)
        }
        maxHeight={300}
        hintText="select"
      >
        <SelectField.Item
          disabled
          key={-1}
          value={-1}
          primaryText={'select condition'}
        />
        {operators.map((o) => (
          <SelectField.Item
            key={o.value}
            value={o.value}
            primaryText={o.label}
          />
        ))}
      </SelectField>
    );
  }

  renderValueSelect(condition, conditionIndex) {
    const { survey } = this.props;
    let options;

    if (
      !condition.question_id ||
      !condition.operator ||
      !OPERATORS[condition.operator] ||
      OPERATORS[condition.operator].showValue === false
    ) {
      return null;
    }

    const selectedQuestion = survey.questions.find(
      (q) => q.id === condition.question_id
    );
    if (!selectedQuestion) return null;

    if (selectedQuestion.type.match(/singleChoice|multipleChoice/)) {
      options = selectedQuestion.options;
    } else if (selectedQuestion.type.match(/matrixValues/)) {
      options = selectedQuestion.matrixValues;
    }

    if (options) {
      return (
        <SelectField
          flatDark
          noValidation
          value={condition.value || -1}
          onChange={({ value }) =>
            this.handleCLQuestionValueChange(value, conditionIndex)
          }
          maxHeight={300}
          hintText="Select option"
        >
          <SelectField.Item disabled value={-1} primaryText="Select option" />
          {options.map((o, index) => (
            <SelectField.Item
              key={o.id}
              value={o.id}
              primaryText={getSurveyI18n(survey, selectedQuestion, [
                'options',
                index,
                'title',
              ])}
            />
          ))}
        </SelectField>
      );
    }

    if (
      selectedQuestion.type.match(/financial|numerical/) ||
      (selectedQuestion.matrixQuestionType &&
        selectedQuestion.matrixQuestionType.match(/numerical|financial/))
    ) {
      return (
        <NumberField
          flatDark
          noValidation
          onChange={(value) =>
            this.handleCLQuestionValueChange(value, conditionIndex)
          }
          value={condition.value || 0}
          hintText="Enter number"
        />
      );
    }

    return (
      <TextField
        flatDark
        noValidation
        onChange={(value) =>
          this.handleCLQuestionValueChange(value, conditionIndex)
        }
        value={condition.value ?? ''}
        hintText="Enter text"
      />
    );
  }

  renderCondition = (condition, index) => {
    const { settings } = this.props.question;
    const questionSelect = this.renderQuestionSelect(condition, index);
    const valueSelect = this.renderValueSelect(condition, index);
    const logicalOperatorSelect = this.renderLogicalOperator(condition, index);
    const joinLabel = settings.clBaseOperator === 'AND' ? 'and' : 'or';
    return (
      <div key={index}>
        {index !== 0 ? <div>{joinLabel}</div> : ''}
        {questionSelect} {logicalOperatorSelect} {valueSelect}
        {
          <Icon
            name="trash"
            tip="Remove"
            onClick={() => this.handleRemoveCondition(index)}
          />
        }
      </div>
    );
  };

  renderBaseOperatorSelect = () => {
    const { settings } = this.props.question;
    const options = [
      { value: 'OR', label: 'any' },
      { value: 'AND', label: 'all' },
    ];

    return (
      <SelectField
        flatDark
        noValidation
        value={settings.clBaseOperator || options[0].value}
        onChange={({ value }) => this.handleCLBaseOperatorChange(value)}
        maxHeight={300}
      >
        {options.map((o) => (
          <SelectField.Item
            key={o.value}
            value={o.value}
            primaryText={o.label}
          />
        ))}
      </SelectField>
    );
  };

  renderConditionalLogic() {
    const { question, contextLabel } = this.props;
    const conditions = question.conditional_logic.length
      ? question.conditional_logic
      : [{}];
    const baseOperatorSelect = this.renderBaseOperatorSelect();
    return (
      <div className={cls.condionalLogic} key="cl-setup">
        <div className={cls.baseOperator}>
          Show this {contextLabel} if {baseOperatorSelect} of the below match:
        </div>
        <div className={cls.conditionsList}>
          {conditions.map(this.renderCondition)}
        </div>
        <Button
          disabled={!canBuildSurvey()}
          action
          size="s"
          icon={<Icon name="conditional" />}
          label="Add condition"
          onClick={() => this.handleAddCondition()}
        />
      </div>
    );
  }

  render() {
    const { question } = this.props;
    const showConditionalLogic = !!question.conditional_logic.length;
    return (
      <PropertyBox>
        <Toggle
          mini
          label="Enable conditional logic"
          labelPosition="right"
          toggled={showConditionalLogic}
          onToggle={this.handleToggleConditionalLogic}
        />
        {showConditionalLogic && this.renderConditionalLogic()}
        {showConditionalLogic && question.type === SECTION_BREAK && (
          <div>
            <Toggle
              mini
              label="Terminate survey if conditions are not met"
              labelPosition="right"
              toggled={
                question.settings &&
                question.settings.clEarlyTerminate === 'NOT_MET'
              }
              onToggle={this.handleCLEarlyTerminateChange}
            />
            <Toggle
              mini
              label="Disable survey submission when terminated"
              labelPosition="right"
              toggled={
                question.settings &&
                question.settings.clEarlyDisable === 'NOT_MET'
              }
              onToggle={this.handleCLEarlyDisableChange}
            />
            <TextField
              name="text_clEarlyTerminateText"
              value={question.settings.clEarlyTerminateMessage || ''}
              onChange={this.handleCLEarlyTerminateMessageChange}
              noValidation
              fullWidth
              wrapperClassName="printHidden"
              label="Terminated survey message:"
              flat
              border
              flatDark
            />
          </div>
        )}
      </PropertyBox>
    );
  }
}
