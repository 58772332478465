import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Progress } from '..';
import cls from './Page.module.css';
const cx = classNames.bind(cls);

export class Page extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    pending: PropTypes.bool,
    noPadding: PropTypes.bool,
    renderHeader: PropTypes.func,
    id: PropTypes.string,
  };
  static defaultProps = { children: null, headerActions: null };
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { className, children, pending, noPadding, id, renderHeader } =
      this.props;
    return (
      <div
        id={id}
        className={cx(
          'imui-page',
          { 'imui-page-noPadding': noPadding },
          className
        )}
      >
        {!pending && renderHeader?.()}
        {!pending && children}
        {pending && <Progress large />}
      </div>
    );
  }
}
