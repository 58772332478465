import reducerRegistry from 'im/api/registry';
import ResourceCollection from 'im/api/ResourceCollection';

const apiConfig = {
  type: 'import_uploads',
  root: '/api/v3/import_uploads',
  api: {
    allow: ['destroy', 'findAll'],
    custom: {
      create: {
        headers: { 'Content-Type': 'multipart/form-data' },
        method: 'POST',
      },
    },
  },
};
const apiConfigSurveyAnswer = {
  type: 'import_uploads',
  root: '/api/v3/import_uploads',
  api: {
    allow: ['destroy'],
    custom: {
      create: {
        headers: { 'Content-Type': 'multipart/form-data' },
        method: 'POST',
        meta: { noSync: true },
      },
    },
  },
};
const apiConfigTags = {
  type: 'import_uploads',
  root: '/api/v3/import_uploads/import_tags',
  api: {
    custom: {
      create: {
        headers: { 'Content-Type': 'multipart/form-data' },
        method: 'POST',
      },
    },
  },
};

export const UploaderSecure = new ResourceCollection(
  apiConfig,
  'importUploads'
);
reducerRegistry.register(UploaderSecure);
export const UploaderSecureTags = new ResourceCollection(
  apiConfigTags,
  'importUploads'
);
reducerRegistry.register(UploaderSecureTags);
export const UploaderSecureSurveyAnswer = new ResourceCollection(
  apiConfigSurveyAnswer,
  'importUploadsSurveyAnswer'
);
reducerRegistry.register(UploaderSecureSurveyAnswer);
export default {
  ...UploaderSecure.api,
  tags: UploaderSecureTags.api,
  surveyAnswer: UploaderSecureSurveyAnswer.api,
};
