import {
  PROJECTS__GET_REQUEST,
  PROJECTS__GET_SUCCESS,
  PROJECTS__GET_FAILURE,
} from 'src/constants';

const initialState = { items: [], pending: false, cursor: {} };

export default function projectsReducer(state = initialState, action) {
  switch (action.type) {
    case PROJECTS__GET_REQUEST:
      return { ...initialState, pending: true };

    case PROJECTS__GET_SUCCESS:
      return {
        ...initialState,
        pending: false,
        items: (action.payload.data?.items || []).sort((a, b) =>
          a.name?.localeCompare(b.name)
        ),
        cursor: action.payload.data?.cursor || {},
      };

    case PROJECTS__GET_FAILURE:
      return { ...initialState, pending: false };
    default:
      return state;
  }
}
