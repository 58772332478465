import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { createConfirmation, confirmable } from 'react-confirm';

import { Button, Dialog } from 'src/components/IMUI';

@confirmable
class ConfirmModal extends Component {
  static propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    title: PropTypes.string,
    warning: PropTypes.bool,
    show: PropTypes.bool,
    cancel: PropTypes.func,
    proceed: PropTypes.func,
    dismiss: PropTypes.func,
    hideCancel: PropTypes.bool,
    confirmLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
  };

  componentDidMount() {
    document.addEventListener('keyup', this.handleKeypress, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.handleKeypress, false);
  }

  handleKeypress = (ev) => {
    if (this.props.show) {
      if (
        ev.key === 'Enter' &&
        ['INPUT', 'TEXTAREA'].indexOf(ev.target.nodeName) === -1
      ) {
        ev.stopImmediatePropagation();
        this.props.proceed();
      }
    }
  };

  render() {
    const {
      cancel,
      proceed,
      warning,
      show,
      dismiss,
      text,
      title,
      hideCancel,
      confirmLabel,
      cancelLabel,
    } = this.props;
    const showCancel = typeof hideCancel !== 'boolean' || hideCancel !== true;

    return (
      <Dialog
        small
        title={title}
        rightActions={
          <Button
            size="l"
            alert={warning}
            label={confirmLabel || 'Confirm'}
            onClick={proceed}
          />
        }
        leftActions={
          showCancel && (
            <Button
              size="l"
              negative
              label={cancelLabel || 'Cancel'}
              onClick={cancel}
            />
          )
        }
        open={show}
        onRequestClose={dismiss}
      >
        {text}
      </Dialog>
    );
  }
}

const confirmableModal = createConfirmation(ConfirmModal);

export function confirm(title, text, isWarning, hideCancel) {
  if (typeof title === 'object') {
    return confirmableModal(title);
  }
  return confirmableModal({ title, text, warning: isWarning, hideCancel });
}

export default ConfirmModal;
