import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm, Form, submit } from 'redux-form';
import passwordApi from 'src/api/Password';
import { FullBgView } from 'src/components/FullBgView';
import { Button, TextField, FormField } from 'src/components/IMUI/index';
import history from 'src/historyInstance';
import createValidator from 'src/utils/validation';
import { where } from 'im/api/Query';

class ResetPassword extends Component {
  static FORM_NAME = 'passwordChangeForm';
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    putPassword: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    submit: PropTypes.func.isRequired,
    password: PropTypes.object,
  };
  onChangePassword = (data) => {
    this.props.putPassword(
      where({ resetToken: this.props.match.params.resetToken }).payload({
        password: data.password,
      })
    );
  };
  onSubmitRequest = () => {
    this.props.submit(ResetPassword.FORM_NAME);
  };
  onRedirectToLogin = () => {
    history.push('/login');
  };
  render() {
    return (
      <FullBgView hasLogo>
        <FullBgView.Box
          limitedWidth
          title="Change password"
          rightAction={
            <Button
              dark
              type="submit"
              size="l"
              label="Save"
              onClick={this.onSubmitRequest}
            />
          }
          leftAction={
            <Button
              dark
              text
              underlined
              label="Back to login"
              onClick={this.onRedirectToLogin}
            />
          }
          errors={this.props.password.errors}
          errorsTemplate={{
            404: 'Password reset failed.\nInvalid or expired password reset code',
          }}
        >
          <Form
            onSubmit={this.props.handleSubmit(this.onChangePassword)}
            noValidate
          >
            <FormField>
              <Field
                fullWidth
                component={TextField}
                name="password"
                type="password"
                hintText="Enter new password"
              />
            </FormField>
          </Form>
        </FullBgView.Box>
      </FullBgView>
    );
  }
}

export function validate(values) {
  const not = (fn) => (v) => !fn(v);
  const isDefined = (v) => Boolean(v);
  const minLength = (n) => (v) => v.length >= n;
  const hasDigit = (v) => v && !!v.match(/\d/);
  const hasLowerCaseLetter = (v) => v && !!v.match(/\.*[a-z].*/);
  const hasUpperCaseLetter = (v) => v && !!v.match(/\.*[A-Z].*/);

  return createValidator({
    password: [
      [not(isDefined), () => 'Password is required'],
      [not(minLength(8)), () => 'Password must have at least 8 characters'],
      [not(hasDigit), () => 'Password must have at least 1 numeric digit'],
      [
        not(hasLowerCaseLetter),
        () => 'Password must have at least 1 lower case letter',
      ],
      [
        not(hasUpperCaseLetter),
        () => 'Password must have at least 1 upper case letter',
      ],
    ],
  })(values);
}

export default connect(
  ({ password }) => ({ password }),
  (dispatch) =>
    bindActionCreators({ putPassword: passwordApi.put, submit }, dispatch)
)(reduxForm({ form: ResetPassword.FORM_NAME, validate })(ResetPassword));
