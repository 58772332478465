export function convertToPercent(value, containsSuffix = false) {
  const percent = isNaN(value) ? 0 : Math.round(value * 100);
  return containsSuffix ? `${percent}%` : percent;
}

export function abbreviateNumber(_number, isFixed) {
  // https://stackoverflow.com/a/32638472/253151
  if (_number === null || typeof +_number !== 'number') {
    return null;
  } // terminate early
  if (_number === 0) {
    return '0';
  } // terminate early
  const number = +_number;
  if (number < 10000) return `${number}`;
  const fixed = !isFixed || isFixed < 0 ? 0 : isFixed; // number of decimal places to show
  const b = number.toPrecision(2).split('e'); // get power
  const k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3); // floor at decimals, ceiling at trillions
  const c =
    k < 1
      ? number.toFixed(0 + fixed)
      : (number / 10 ** (k * 3)).toFixed(1 + fixed); // divide by power
  const d = c < 0 ? c : Math.abs(c); // enforce -0 is 0
  const e = d + ['', 'K', 'M', 'B', 'T', 'Quad', 'Quin'][k]; // append power
  return e;
}

export function delimitWithCommas(x) {
  const numberToString = x.toString();
  const parts = numberToString.replace(/[^0-9.]/g, '').split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  if (parts.length > 1 && parts[1].length > 2) {
    parts[1] = Number(`0.${parts[1]}`).toFixed(2).split('.').pop();
  }
  return parts.length > 1 ? `${parts[0]}.${parts[1]}` : parts[0];
}

export const getMedian = (numbers = []) => {
  if (!numbers.length) return 0;
  const sorted = [...numbers].sort((a, b) => a - b);
  const halfIndex = Math.floor(sorted.length / 2);
  return numbers.length % 2
    ? sorted[halfIndex]
    : (sorted[halfIndex - 1] + sorted[halfIndex]) / 2;
};

export const getMode = (numbers = []) => {
  const counters = numbers.reduce(
    (acc, n) => ({
      ...acc,
      [n]: (acc[n] || 0) + 1,
    }),
    {}
  );

  return Object.keys(counters).reduce((prev, current) => {
    // there can be multiple modes
    const prevArr = Array.isArray(prev) ? prev : [prev];

    if (counters[prevArr[0]] > counters[current]) return prevArr;

    if (
      counters[prevArr[0]] === counters[current] &&
      !prevArr.includes(current)
    )
      return [...prevArr, current];

    return [current];
  }, []);
};
