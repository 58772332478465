import {
  SURVEY_ANSWERS_GET_REQUEST,
  SURVEY_ANSWERS_GET_SUCCESS,
  SURVEY_ANSWERS_GET_FAILURE,
  SURVEY_ANSWERS_CACHE_CLEAR,
} from 'src/actionCreators/surveyAnalysisActionCreators';
import normalizePayload from 'im/api/normalization/normalizePayload';
const initialState = { pending: false };

export default function cachedSurveyAnswersReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case SURVEY_ANSWERS_GET_REQUEST:
      if (action.meta?.silent) return state;
      return { ...state, pending: true };

    case SURVEY_ANSWERS_GET_SUCCESS:
      state[action.meta?.questionId] = {
        data: normalizePayload(action.payload.data?.data || {}),
        created_at: action.meta?.timestamp || new Date(),
      };
      return { ...state, pending: false };

    case SURVEY_ANSWERS_GET_FAILURE:
      delete state[action.meta?.questionId];
      return { ...state, pending: false };

    case SURVEY_ANSWERS_CACHE_CLEAR:
      return { pending: false };

    default:
      return state;
  }
}
