import { formatMoney } from 'accounting';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import flatten from 'ramda/src/flatten';
import omit from 'ramda/src/omit';
import prop from 'ramda/src/prop';
import uniq from 'ramda/src/uniq';
import uniqWith from 'ramda/src/uniqWith';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
  getGranteeSummary,
  getGrantSummary,
} from 'src/actionCreators/projectActionCreators';
import ChartDownloadMenu from 'src/components/Chart/ChartDownloadMenu';
import ChartOptionToggles from 'src/components/ChartOptionToggles/ChartOptionToggles';
import FlexColumn from 'src/components/FlexColumn/FlexColumn';
import FlexRow from 'src/components/FlexRow/FlexRow';
import {
  Actions,
  Card,
  Container,
  Section,
  TabSegments,
} from 'src/components/IMUI';
import Map from 'src/components/Map/Map';
import Page from 'src/components/Page/Page';
import history from 'src/historyInstance';
import { getText } from 'src/services/DictionaryService';
import GlobalMapFilters from './components/GlobalMapFilters';
import Sidebar from './components/Sidebar';
import cls from './GlobalMap.module.css';

const DUMMY_GRANTEE = 'IM Grantee (internal)';
const DUMMY_GRANT = 'IM Grant (internal)';

const flattenGrantsByLocation = (grants = []) =>
  grants.reduce((acc, grant) => {
    const grantsWithSingleLocation = [];
    (grant.locations || []).forEach((l) =>
      grantsWithSingleLocation.push(
        omit('locations', { ...grant, country_code: l.country })
      )
    );
    return acc.concat(
      grantsWithSingleLocation.length ? grantsWithSingleLocation : [grant]
    );
  }, []);

const flattenMapData = (filteredEntities, projectSummary) => {
  const taggingsByEntityId = (projectSummary.data.tag_groups || []).reduce(
    (acc, { name: tagGroup, tags }) => {
      tags.forEach((tag) => {
        tag.taggings.forEach((tagging) => {
          if (!acc[tagging.entity_id]) {
            acc[tagging.entity_id] = [];
          }
          acc[tagging.entity_id].push({
            quantity: tagging.quantity,
            quantityLabel: tag.quantity_label,
            tagName: tag.name,
            tagGroup,
          });
        });
      });

      return acc;
    },
    {}
  );

  // "UN" is "international"
  const entities = filteredEntities.reduce((acc, e) => {
    const code = (e.country_code || 'UN').toUpperCase();
    if ([DUMMY_GRANTEE, DUMMY_GRANT].includes(e?.name)) return acc;
    if (!acc[code]) {
      acc[code] = [];
    }
    return { ...acc, [code]: [...acc[code], e] };
  }, {});

  const mapData = Object.keys(entities).map((key) => ({
    code: key,
    entities: entities[key].map((entity) => ({
      ...entity,
      relatedTaggings: taggingsByEntityId[entity.id] || [],
    })),
    value: entities[key].length,
  }));

  return mapData;
};

@connect(
  (state) => ({ projectSummary: state.projectSummary, project: state.project }),
  { getGranteeSummary, getGrantSummary }
)
export default class ProjectGlobalMap extends PureComponent {
  static propTypes = {
    getGrantSummary: PropTypes.func.isRequired,
    getGranteeSummary: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired, // eslint-disable-line
    projectSummary: PropTypes.object.isRequired,
    project: PropTypes.object,
  };

  state = {
    showDataLabels: false,
    sidebarData: undefined,
    useDarkTheme: false,
    filteredEntities: [],
  };
  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (this.isGrantView() !== this.isGrantView(prevProps))
      return this.getData();
    if (
      this.props.projectSummary?.data?.id !== prevProps.projectSummary?.data?.id
    )
      return this.onProjectUpdate();
  }

  getData() {
    if (this.grantViewLabel === 'grant' && this.props.match.params.projectId)
      return this.props.getGrantSummary(this.props.match.params.projectId);
    if (this.grantViewLabel === 'grantee' && this.props.match.params.projectId)
      return this.props.getGranteeSummary(this.props.match.params.projectId);
  }

  onProjectUpdate = () => {
    this.setState({
      filteredEntities: flattenGrantsByLocation(
        this.props.projectSummary.data.entities
      ),
    });
  };

  onToggleDataLabels = () => {
    this.setState({ showDataLabels: !this.state.showDataLabels });
  };

  onToggleDarkTheme = () => {
    this.setState({ useDarkTheme: !this.state.useDarkTheme });
  };

  onPointClick = (point) => {
    const resetCountry = point?.code == this.state.sidebarData?.code;
    if (resetCountry) return this.setState({ sidebarData: undefined });
    this.setState({
      sidebarData: {
        ...point,
        activeYears: uniq(
          flatten(point.entities.map(prop('active_years')))
        ).sort(),
      },
    });
  };

  onUpdateFilteredData = (filteredEntities) => {
    if (
      !filteredEntities.length &&
      filteredEntities.length === this.state.filteredEntities.length
    )
      return;
    this.setState({
      filteredEntities: flattenGrantsByLocation(filteredEntities),
      sidebarData: undefined,
    });
  };

  onToggleGrantView = (isToggled) => {
    this.setState({ sidebarData: undefined });
    history.push(
      `/analysis/${this.props.match.params.projectId}/summary/map?grant-view=${isToggled}`
    );
  };

  get grantViewLabel() {
    return this.isGrantView() ? 'grant' : 'grantee';
  }

  isGrantView(props = this.props) {
    return queryString.parse(props.location.search)['grant-view'] === 'true';
  }

  getCurrencySymbol() {
    if (
      this.props.project.organization_id === 1078 ||
      this.props.project.organization_id === 1079
    )
      return 'NOK ';
    // TODO From IM-859 / IM-722: projectCurrency is not being used and needs to be reconsidered.
    if (this.props.projectSummary.data.currency === 'USD') return '$';
    else if (this.props.projectSummary.data.currency === 'EUR') return '€';
    else if (this.props.projectSummary.data.currency === 'GBP') return '£';
    return `${this.props.projectSummary.data.currency} `;
  }

  getMapTitle(entities) {
    if (!entities?.length) return '.';
    const fundings = uniqWith((a, b) => a.id === b.id, entities);
    const validSize = fundings.filter((c) => c.country_code?.length > 0).length;
    const label = getText(this.grantViewLabel, validSize);
    const reach = [
      ...new Set(entities.map((e) => e.country_code).filter(Boolean)),
    ].length;
    const countryText = reach > 1 ? 'countries' : 'country';
    const totalAmount = fundings.reduce(
      (acc, { total_funding }) => acc + total_funding,
      0
    );
    const totalSuffix =
      totalAmount > 0
        ? `, giving ${formatMoney(totalAmount / 100, this.getCurrencySymbol())}`
        : `.`;
    const globallySuffix =
      entities.length > 0 && reach === 0 && validSize === 0;
    const locationSuffix = globallySuffix
      ? `${entities?.length} ${label} globally`
      : `${validSize} ${label} in ${reach} ${countryText}`;
    return `<b>${this.props.projectSummary.data.name}</b> supports ${locationSuffix}${totalSuffix}`;
  }

  render() {
    const { showDataLabels, useDarkTheme, sidebarData, filteredEntities } =
      this.state;
    const entities = this.props.projectSummary.data.entities || [];
    const mapData = flattenMapData(filteredEntities, this.props.projectSummary);
    const MAXHEIGHT = Math.max(window.innerHeight - 300, 600);
    return (
      <Page
        title="Global map"
        helpAppcue="-LT-dwe8uF9DKNU8-VaL"
        back={`/analysis/${this.props.match.params.projectId}/summary`}
      >
        <Section type="actions" collapsed noPadding>
          <Container horizontal nowrap style={{ padding: 16 }}>
            <Actions style={{ gap: 16 }}>
              <GlobalMapFilters
                onChange={this.onUpdateFilteredData}
                data={entities}
              />
              <ChartDownloadMenu
                key={this.getMapTitle(this.state.filteredEntities)}
                buttonSize="l"
                options={['png', 'jpeg', 'pdf']}
                displayTitle={this.getMapTitle(this.state.filteredEntities)}
              />
            </Actions>
            <Actions>
              <ChartOptionToggles
                toggles={[
                  {
                    label: 'Data Labels',
                    onToggle: this.onToggleDataLabels,
                    toggled: this.state.showDataLabels,
                  },
                  {
                    label: 'Dark Theme',
                    onToggle: this.onToggleDarkTheme,
                    toggled: this.state.useDarkTheme,
                  },
                ]}
              />
              <TabSegments
                onToggle={(segmentId) =>
                  this.onToggleGrantView(segmentId === 'grant')
                }
                segments={[
                  {
                    id: 'grantee',
                    text: getText('Grantee'),
                    active: !this.isGrantView(),
                    tooltipText: getText('Aggregate by grantee'),
                  },
                  {
                    id: 'grant',
                    text: getText('Grant'),
                    active: this.isGrantView(),
                    tooltipText: getText('Aggregate by grant'),
                  },
                ]}
              />
            </Actions>
          </Container>
        </Section>
        <Card noPadding grow>
          <FlexRow
            className={cls.globalMapWrapper}
            style={{ height: MAXHEIGHT, maxHeight: MAXHEIGHT }}
          >
            <FlexColumn size={sidebarData ? 0.75 : 1} style={{ padding: 0 }}>
              {this.props.projectSummary && (
                <Map
                  key={this.grantViewLabel}
                  entity={this.grantViewLabel}
                  hasBackground
                  height={MAXHEIGHT}
                  data={mapData}
                  chartTitle={this.getMapTitle(this.state.filteredEntities)}
                  onPointClick={this.onPointClick}
                  showDataLabels={showDataLabels}
                  useDarkTheme={useDarkTheme}
                  currencySymbol={this.getCurrencySymbol()}
                />
              )}
            </FlexColumn>

            <FlexColumn
              className={cls.sidebarFlexColumn}
              size={sidebarData ? 0.25 : 0}
              style={{ padding: 0 }}
            >
              <Sidebar data={sidebarData} useDarkTheme={useDarkTheme} />
            </FlexColumn>
          </FlexRow>
        </Card>
      </Page>
    );
  }
}
