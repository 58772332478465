import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import { getRegions } from 'src/actionCreators/regionsActionCreators';
import grantsApi, { grantsGetAdvancedFilters } from 'src/api/Grants';
import {
  Button,
  Card,
  CardFooter,
  CardEmpty,
  Section,
  FilterJsonapi,
  FilterTrigger,
  Progress,
  Actions,
  Container,
  PopoverLight,
} from 'src/components/IMUI';
import { Icon } from 'im/ui/Icon';
import PaginationIm from 'src/components/IMUI/Pagination/PaginationIm';
import Page from 'src/components/Page/Page';
import ManageSubjectsSurveyOnlyCard from 'src/components/SurveyOnly/ManageSubjectsSurveyOnlyCard';
import { GRANTS } from 'src/components/SurveyOnly/surveyOnlyUpsellText';
import history from 'src/historyInstance';
import { getOrgText } from 'src/services/DictionaryService';
import { capitalize } from 'src/utils/string';
import { where } from 'im/api/Query';
import {
  getFilterConfig,
  getFilterAutopopulationConfig,
} from './components/FilterConfig';
import GrantTable from './components/GrantTable';
import TableSettings from './components/TableSettings';
import cls from './Table.module.css';
import { canManageGrants, surveyOnly } from 'src/userStorage';
const cx = classNames.bind(cls);

@connect(
  (state) => ({
    grant: state.jsonapiGrants,
    grantsAdvancedFilters: state.advancedFilters.jsonapiGrants,
    organizationCurrent: state.organizationCurrent,
    defaultRegions: state.defaultRegions,
  }),
  {
    getRegions,
    grantsFindAll: grantsApi.findAll,
    grantsUpdateQuery: grantsApi.findAll.updateQuery,
    ...grantsGetAdvancedFilters,
  }
)
export default class Table extends React.PureComponent {
  state = {
    showFilterCreator: false,
    isTableCompact: true,
    addGrantPopoverOpen: false,
    popoverAnchorEl: null,
  };
  static propTypes = {
    organizationCurrent: PropTypes.object,
    grantsFindAll: PropTypes.func.isRequired,
    grantsUpdateQuery: PropTypes.func.isRequired,
    grantsAdvancedFilters: PropTypes.object,
    getRegions: PropTypes.func.isRequired,
    match: ReactRouterPropTypes.match,
    location: PropTypes.object,
  };
  static BASE_URL = '/grants/list';
  componentDidMount() {
    this.props.getRegions();
    this.doRequestTableData({ allowUrlParams: true, pending: 'init' });
  }
  handleReloadTable = () =>
    this.doRequestTableData({ allowUrlParams: true, pending: 'init' });
  doRequestTableData = ({ allowUrlParams = false, pending = 'init' } = {}) => {
    const query = where()
      .include(...['contacts', 'fundings', 'locations', 'grantees'])
      .sort('-created_at')
      .pending(pending);
    if (allowUrlParams) {
      query.fromString(this.props.location.search);
    }
    this.props.grantsFindAll(query);
  };
  get filterConfig() {
    return getFilterConfig(this.props.organizationCurrent.data)[
      this.props.match.params.activeTab
    ];
  }
  get filterAutopopulationConfig() {
    return getFilterAutopopulationConfig(
      this.props.grantsAdvancedFilters,
      this.handleRequestFilterOptions
    )[this.props.match.params.activeTab];
  }
  handleTableCompactToggle = () => {
    this.setState({ isTableCompact: !this.state.isTableCompact });
    this.outerRef?.scrollTo({ behavior: 'smooth', top: 0 });
  };
  handleEdit = (grantId) => {
    history.replace({
      pathname: `${Table.BASE_URL}/grants/${grantId}`,
      search: this.props.location.search,
    });
  };
  handleEditClose = () => {
    history.replace({
      pathname: `${Table.BASE_URL}/${this.props.match.params.activeTab}`,
      search: this.props.location.search,
    });
  };
  handleToggleFilter = (showFilterCreator) =>
    this.setState({ showFilterCreator });
  handleRequestFilterOptions = (key, value, predicate) => {
    this.props[`grantsAF_${key}`]({
      key,
      value,
      predicate,
    });
  };

  handleAddGrantPopoverOpen = (ev) => {
    this.setState({
      addGrantPopoverOpen: true,
      popoverAnchorEl: ev.currentTarget,
    });
  };

  handleAddGrantPopoverClose = (ev) => {
    this.setState({
      addGrantPopoverOpen: false,
      popoverAnchorEl: null,
    });
  };
  handleAddFunding = () => {
    history.push('/grants/fundings/add');
  };
  handleImportGrants = () => {
    history.push('/grants/import2');
  };
  doUpdateQuery = (query) => this.props.grantsUpdateQuery(query);
  handlePaginationChange = ({ page = 1, per_page = 30 }) => {
    this.doUpdateQuery(where().paginate({ number: page, size: per_page }));
    this.outerRef?.scrollTo({ behavior: 'smooth', top: 0 });
  };
  handleSortChange = (sortKeyValue) =>
    this.doUpdateQuery(where().sort(sortKeyValue).paginate({ number: 1 }));
  handleFilterApply = (filterValues) =>
    this.doUpdateQuery(where().filter(filterValues).paginate({ number: 1 }));
  handleClearFilter = (filterValues) =>
    this.doUpdateQuery(
      where().filter(filterValues).actionMeta({ doDelete: true })
    );
  hasFilters = () =>
    Boolean(this.props.grant?.meta?.getAction?.queryParams?.filter);
  onHandleExport = () => this.childRef?.handleExport?.();

  renderTable() {
    if (this.props.grant.pending.init) return <Progress large />;
    if (!this.props.grant.data.length)
      return (
        <CardEmpty
          title={`No ${getOrgText(
            `${this.props.match.params.activeTab}s`
          )} to display`}
          description={
            this.hasFilters()
              ? 'please change filtering criteria'
              : 'please create a new one'
          }
        />
      );
    return (
      <GrantTable
        key={this.props.match.params.activeTab}
        ref={(c) => (this.childRef = c)}
        items={this.props.grant}
        urlParam={this.props.match.params.entityId}
        isCompact={this.state.isTableCompact}
        onToggleCompact={this.handleTableCompactToggle}
        onDrawerHide={this.handleEditClose}
        onSort={this.handleSortChange}
        onRowClick={this.handleEdit}
        onReload={this.handleReloadTable}
      />
    );
  }

  render() {
    const paginationCords = this.props.grant?.links.meta || {};
    const retrieveTitle = () => {
      if (this.props.match.params.activeTab === 'funding')
        return getOrgText('Overview');
      if (this.props.match.params.activeTab === 'grant')
        return getOrgText('Grants');
    };
    if (surveyOnly())
      return (
        <Page
          className={cls.pageCustom}
          headerClassName={cls.headerSticky}
          title={getOrgText(
            `${capitalize(this.props.match.params.activeTab)}s`
          )}
        >
          <ManageSubjectsSurveyOnlyCard {...GRANTS} id="GRANTS" />
        </Page>
      );

    return (
      <Page
        className={cls.pageCustom}
        headerClassName={cls.headerSticky}
        helpAppcue="-LRhLUAYS2xqLy_CmBBy"
        title={retrieveTitle()}
      >
        <div ref={(ref) => (this.outerRef = ref)}>
          <Section type="sub-header" collapsed className={cls.subHeaderSticky}>
            <Container horizontal nowrap>
              <Actions className={cls.actionSameSize}>
                <FilterTrigger
                  active={this.state.showFilterCreator}
                  filters={this.props.grant.meta.getAction?.queryParams?.filter}
                  config={this.filterConfig}
                  onToggle={() =>
                    this.handleToggleFilter(!this.state.showFilterCreator)
                  }
                />
              </Actions>
              <Actions className={cx(cls.actionSameSize, cls.actionButtons)}>
                <Button
                  disabled={!canManageGrants()}
                  secondary
                  size="m"
                  label="Export"
                  onClick={this.onHandleExport}
                />
                <Button
                  disabled={!canManageGrants()}
                  secondary
                  size="m"
                  label={`Add ${getOrgText('Grants')}`}
                  onClick={this.handleAddGrantPopoverOpen}
                />
                <PopoverLight
                  anchorEl={this.state.popoverAnchorEl}
                  open={this.state.addGrantPopoverOpen}
                  anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
                  targetOrigin={{ horizontal: 'left', vertical: 'top' }}
                  onRequestClose={this.handleAddGrantPopoverClose}
                  header={'Add Grants'}
                >
                  <PopoverLight.Menu>
                    <PopoverLight.MenuItem
                      leftIcon={<Icon name="grants" />}
                      primaryText={`Add a single ${getOrgText('Grant')}`}
                      onClick={this.handleAddFunding}
                    />
                    <PopoverLight.MenuItem
                      leftIcon={<Icon name="table" />}
                      primaryText={`Import ${getOrgText('Grants')} via CSV`}
                      onClick={this.handleImportGrants}
                    />
                  </PopoverLight.Menu>
                </PopoverLight>
              </Actions>
            </Container>
          </Section>

          <Section noBorder key={this.props.match.params.activeTab}>
            <FilterJsonapi
              showCreator={this.state.showFilterCreator}
              filters={this.props.grant.meta.getAction?.queryParams?.filter}
              config={this.filterConfig}
              configAutopopulation={this.filterAutopopulationConfig}
              onToggleCreator={this.handleToggleFilter}
              onClear={this.handleClearFilter}
              onSubmit={this.handleFilterApply}
            />
            <Card>
              {this.renderTable()}
              <CardFooter className={cls.cardFooter}>
                <TableSettings
                  hasBorder
                  isCompact={this.state.isTableCompact}
                  onToggleCompact={this.handleTableCompactToggle}
                />
                <PaginationIm
                  {...paginationCords}
                  label={getOrgText(`grants`)}
                  onChange={this.handlePaginationChange}
                />
              </CardFooter>
            </Card>
          </Section>
        </div>
      </Page>
    );
  }
}
