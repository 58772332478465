import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import { Container } from 'src/components/IMUI/Layout/Container';

import cls from './StatsBar.module.css';

const cx = classNames.bind(cls);

export const StatItem = ({ total, name, className } = {}) => {
  if (!name) {
    return null;
  }

  return (
    <span className={cx(cls.statItem, className)}>{`${total} ${name}`}</span>
  );
};

StatItem.propTypes = {
  className: PropTypes.string,
  total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
};

export const StatsBar = ({ children, className }) => (
  <Container horizontal className={cx(cls.statsBar, className)}>
    {children}
  </Container>
);

StatsBar.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
