// TODO: Add queryString as lib dependency, when im/api is opensourced
import queryString from 'query-string';

import { removeSharedProps } from './helpers/utils';

class QueryParamsManager {
  static COMMA_SEPARATED_JSONAPI_KEYS = [
    'sort',
    'filter',
    'include',
    'fields',
    'group',
    'calculations',
  ];

  _mergeObjects(obj1, obj2) {
    return Object.keys(obj2).reduce((acc, key) => {
      if (typeof obj2[key] === 'string') return { ...acc, [key]: obj2[key] };

      if (Array.isArray(obj2[key])) {
        const accKey = acc[key] || [];
        const accKeyArray = Array.isArray(accKey) ? accKey : [accKey];

        return {
          ...acc,
          [key]: obj2[key].reduce(
            (arrAcc, el) =>
              arrAcc.indexOf(el) === -1 ? [...arrAcc, ...obj2[key]] : arrAcc,
            accKeyArray
          ),
        };
      }

      return { ...acc, [key]: { ...acc[key], ...obj2[key] } };
    }, obj1 || {});
  }

  _overrideObjects(obj1, obj2) {
    return Object.keys(obj2).reduce((acc, key) => {
      if (typeof obj2[key] === 'string' || Array.isArray(obj2[key]))
        return { ...acc, [key]: obj2[key] };

      return { ...acc, [key]: { ...acc[key], ...obj2[key] } };
    }, obj1 || {});
  }

  _clearEmpty = (paramsObj = {}) => {
    return Object.keys(paramsObj || {}).reduce((acc, key) => {
      if (paramsObj[key] === undefined || paramsObj[key] === null) return acc;

      if (Array.isArray(paramsObj[key]))
        return { ...acc, [key]: paramsObj[key].filter((p) => p) };

      if (typeof paramsObj[key] !== 'object')
        return { ...acc, [key]: paramsObj[key] };

      return { ...acc, [key]: this._clearEmpty(paramsObj[key]) };
    }, {});
  };

  _removeNesting = (paramsObj = {}, prefix) => {
    return Object.keys(paramsObj || {}).reduce((acc, key) => {
      if (prefix && typeof paramsObj[key] !== 'object')
        return { ...acc, [`${prefix}[${key}]`]: paramsObj[key] };

      if (typeof paramsObj[key] !== 'object')
        return { ...acc, [key]: paramsObj[key] };

      return { ...acc, ...this._removeNesting(paramsObj[key], key) };
    }, {});
  };

  _addNesting = (paramsObj = {}) => {
    const separatorsRegex = /[[\]]/;

    return Object.keys(paramsObj || {}).reduce((acc, key) => {
      if (key.match(separatorsRegex)) {
        const [prefix, nestedKey] = key.split(separatorsRegex);
        return {
          ...acc,
          [prefix]: { ...acc[prefix], [nestedKey]: paramsObj[key] },
        };
      }

      return { ...acc, [key]: paramsObj[key] };
    }, {});
  };

  _splitByComma = (paramsObj = {}) => {
    return Object.keys(paramsObj || {}).reduce((acc, key) => {
      if (Array.isArray(paramsObj[key]?.match(/,/)))
        return { ...acc, [key]: paramsObj[key].split(',') };

      if (typeof paramsObj[key] === 'object')
        return { ...acc, [key]: this._splitByComma(paramsObj[key]) };

      return { ...acc, [key]: paramsObj[key] };
    }, {});
  };

  _joinByComma = (paramsObj = {}) => {
    return Object.keys(paramsObj || {}).reduce((acc, key) => {
      if (Array.isArray(paramsObj[key]))
        return { ...acc, [key]: paramsObj[key].join(',') };

      if (typeof paramsObj[key] === 'object')
        return { ...acc, [key]: this._joinByComma(paramsObj[key]) };

      return { ...acc, [key]: paramsObj[key] };
    }, {});
  };

  _flatten = (paramsObj = {}) => {
    if (!Object.keys(paramsObj || {}).length) {
      return '';
    }

    const paramsObjSubset = Object.keys(paramsObj || {}).reduce((acc, key) => {
      return QueryParamsManager.COMMA_SEPARATED_JSONAPI_KEYS.indexOf(key) === -1
        ? acc
        : { ...acc, [key]: paramsObj[key] };
    }, {});

    const paramsObjConnected = this._clearEmpty({
      ...paramsObj,
      ...this._joinByComma(paramsObjSubset),
    });

    return this._removeNesting(paramsObjConnected);
  };

  _deflatten = (paramsObj = {}) => {
    if (!Object.keys(paramsObj || {}).length) {
      return {};
    }

    const paramsObjectWithArrays = this._splitByComma(paramsObj);
    return this._addNesting(paramsObjectWithArrays);
  };

  _update = (
    search,
    paramsObj = {},
    { doStringify, strict, encode, arrayFormat, override } = {}
  ) => {
    const searchObj = typeof search === 'string' ? this.parse(search) : search;
    const isNested = Object.values(paramsObj || {}).some(
      (v) => typeof v === 'object'
    );
    let result = { ...searchObj, ...paramsObj };

    if (isNested) {
      result = override
        ? this._overrideObjects(searchObj, paramsObj)
        : this._mergeObjects(searchObj, paramsObj);
    }

    return doStringify
      ? this.stringify(result, { strict, encode, arrayFormat })
      : this._clearEmpty(result);
  };

  /**
   * Parses query params. Inverse of this.stringify
   * @param {string} search - location.search
   * @param decode - https://github.com/sindresorhus/query-string#decode
   * @param arrayFormat - https://github.com/sindresorhus/query-string#arrayformat
   * @returns {Object} - params as object
   */
  parse = (_search, { decode = true, arrayFormat = 'none' } = {}) => {
    const search = !this.hasQueryParams(_search)
      ? _search
      : _search.split('?').pop();
    const paramsObj = queryString.parse(search, { decode, arrayFormat });
    return this._deflatten(paramsObj);
  };

  /**
   * Stringifies query params. Inverse of this.parse
   * @param {Object} _paramsObj - params
   * @param strict - https://github.com/sindresorhus/query-string#strict
   * @param encode - https://github.com/sindresorhus/query-string#encode
   * @param arrayFormat - https://github.com/sindresorhus/query-string#arrayformat-1
   * @returns {string}
   */
  _stringifySort = (a, b) => {
    if (a > b) return 1;
    if (a < b) return -1;
    return 0;
  };

  stringify = (
    _paramsObj,
    { strict = true, encode = true, arrayFormat = 'comma' } = {}
  ) => {
    const paramsObj = this._flatten(_paramsObj);
    return queryString.stringify(paramsObj, {
      strict,
      encode,
      arrayFormat,
      sort: this._stringifySort,
    });
  };

  /**
   * Wrapper for this.add with { doStringify = true }
   * @param search - location.search | result of this.parse. all query params
   * @param paramsObj - params as object to be merged with @param search.
   * @param {boolean} strict - https://github.com/sindresorhus/query-string#strict
   * @param {boolean} encode - https://github.com/sindresorhus/query-string#encode
   * @param {boolean} arrayFormat - https://github.com/sindresorhus/query-string#arrayformat-1
   * @param {boolean} override - whether to override instead of merging
   * @returns {string|Object}
   */
  stringifyAndUpdate = (
    search,
    paramsObj = {},
    { strict, encode, arrayFormat, override } = {}
  ) => {
    return this._update(search, paramsObj, {
      doStringify: true,
      override,
      strict,
      encode,
      arrayFormat,
    });
  };

  /**
   * Adds or overrides given params to all query params (params = undefined will be removed)
   * @param search - location.search | result of this.parse. all query params
   * @param paramsObj - params as object to be merged with @param search.
   * @param doStringify - whether returned value should be stringified
   * @param {boolean} strict - https://github.com/sindresorhus/query-string#strict
   * @param {boolean} encode - https://github.com/sindresorhus/query-string#encode
   * @param {boolean} arrayFormat - https://github.com/sindresorhus/query-string#arrayformat-1
   * @param {boolean} override - whether to override instead of merging
   * @returns {string|Object}
   */
  add = (
    search,
    paramsObj,
    { doStringify, strict, encode, override, arrayFormat } = {}
  ) => {
    return this._update(search, paramsObj, {
      doStringify,
      strict,
      encode,
      override,
      arrayFormat,
    });
  };

  /**
   * Removes given params + params = undefined from all query params
   * @param {string|Object} search - location.search | result of this.parse. all query params
   * @param {Object} paramsObj - params as object, to be removed from @param search
   * @param {boolean} doStringify - whether to stringify result
   * @param {boolean} strict - https://github.com/sindresorhus/query-string#strict
   * @param {boolean} encode - https://github.com/sindresorhus/query-string#encode
   * @param {boolean} arrayFormat - https://github.com/sindresorhus/query-string#arrayformat-1
   * @returns {string|Object}
   */
  remove = (
    search,
    paramsObj = {},
    { doStringify, strict, encode, arrayFormat } = {}
  ) => {
    const searchObj = typeof search === 'string' ? this.parse(search) : search;
    const result = removeSharedProps(searchObj, paramsObj);

    return doStringify
      ? this.stringify(result, { strict, encode, arrayFormat })
      : this._clearEmpty(result);
  };

  /**
   * Returns query param value by a key in a string formatted search query
   * @param {string} search - location.search
   * @param {string} keyName - param key name
   * @returns {*} - param value by @param keyName
   */
  get = (search, keyName) => {
    const searchObj = this.parse(search) || {};
    return searchObj[keyName];
  };

  /**
   * Checks whether given url has query params
   * @param {string} url - http:// string
   * @returns {boolean}
   */
  hasQueryParams = (url = '') => {
    return url.includes('?');
  };
}

export default new QueryParamsManager();
