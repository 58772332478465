import PropTypes from 'prop-types';
import React from 'react';

import { Button, Dialog, Progress } from 'src/components/IMUI';
import { Label } from 'src/components/IMUI/Forms/base';

import TextWithClipboard from '../../components/TextWithClipboard';

export default class SurveyReminderModal extends React.Component {
  static propTypes = {
    respondentInfo: PropTypes.object,
    open: PropTypes.bool,
    isShare: PropTypes.bool,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
  };

  handleSubmit = () => {
    this.props.onSubmit(this.props.respondentInfo.respondent.id);
  };

  get isDelivering() {
    return this.props.respondentInfo.respondent.status === 'delivering';
  }

  render() {
    const { open, isShare, onCancel, respondentInfo } = this.props;

    if (!respondentInfo) {
      return null;
    }

    return (
      <Dialog
        small
        title={isShare ? 'Share permalink' : 'Send reminder'}
        rightActions={
          !isShare && (
            <Button
              size="l"
              label="Send notification"
              disabled={this.isDelivering}
              onClick={this.handleSubmit}
            />
          )
        }
        leftActions={
          <Button
            size="l"
            negative
            label="Cancel"
            disabled={this.isDelivering}
            onClick={onCancel}
          />
        }
        open={open}
        onRequestClose={onCancel}
      >
        {!isShare && this.isDelivering && <Progress />}
        {!respondentInfo.surveyUrl && (
          <Label label="Are you sure you want to send a reminder to this respondent?" />
        )}
        {(isShare || (!this.isDelivering && respondentInfo.surveyUrl)) && (
          <div>
            <Label>
              Share permalink to the survey in a way that best suits you{' '}
              {!isShare && ' or send a system reminder'}
            </Label>
            <Label label="Survey access URL permalink" />
            <TextWithClipboard
              notificationMsg="Respondent's personal link copied"
              url={respondentInfo.surveyUrl}
              darker
            />
          </div>
        )}
      </Dialog>
    );
  }
}
