import reducerRegistry, { advancedFiltersRegistry } from 'im/api/registry';
import ResourceCollection from 'im/api/ResourceCollection';

const apiConfig = {
  root: '/v3/charts',
  type: 'charts',
  api: {
    allow: ['findAll', 'destroy'],
  },
};

export const Charts = new ResourceCollection(apiConfig, 'charts');
reducerRegistry.register(Charts);

const advancedFiltersConfig = {
  actionPrefix: 'chartsAF',
  allow: ['name'],
};

advancedFiltersRegistry.register(Charts, advancedFiltersConfig);
export const chartsGetAdvancedFilters = Charts.advancedFilters;

export default Charts.api;
