import { formatNumber } from 'accounting';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Stats as IMUIStats } from 'src/components/IMUI/Stats/Stats';
import CURRENCIES from 'src/data/currencies.json';
import { getText } from 'src/services/DictionaryService';
import { formatMoneyIM } from 'src/utils/currency';

export default class Stats extends Component {
  static propTypes = {
    stats: PropTypes.object.isRequired,
    className: PropTypes.string,
  };

  getReadyStats() {
    const currency = CURRENCIES.find(
      (c) => c.code === this.props.stats?.currency
    );
    const hiddenKeys = [...(this.props.stats?.funding == 0 ? ['funding'] : [])];
    return [
      { label: 'Grant', key: 'grants' },
      { label: 'Grantee', key: 'grantees' },
      { label: 'Countries', key: 'countries' },
      {
        label: currency?.name_plural,
        key: 'funding',
        format: (
          (code) => (v) =>
            formatMoneyIM(v, code)
        )(currency?.code),
      },
    ]
      .filter((s) => !hiddenKeys.includes(s.key))
      .map((stat) => ({
        value:
          typeof this.props.stats?.[stat.key] === 'undefined'
            ? '??'
            : (stat.format || formatNumber).call(
                this,
                this.props.stats[stat.key]
              ),
        get label() {
          return getText(stat.label, parseFloat(this.value) || 0, true);
        },
      }));
  }
  render() {
    return (
      <IMUIStats
        stats={this.getReadyStats()}
        className={this.props.className}
      />
    );
  }
}
