const actionStates = {
  request: 'request',
  success: 'success',
  failure: 'failure',
};

const intersectKeys = (obj1, arr) =>
  Object.keys(obj1).reduce(
    (acc, key) =>
      arr.indexOf(key) === -1
        ? acc
        : {
            ...acc,
            [key]: obj1[key],
          },
    {}
  );

class AsyncActionsGenerator {
  _getActionStates(path) {
    return Object.keys(actionStates).reduce(
      (acc, stateKey) => ({
        ...acc,
        [stateKey]: `${path}/${actionStates[stateKey]}`,
      }),
      {}
    );
  }

  _getAllAsyncActions(configActions, defaultActions) {
    const { allow = [], custom = {}, ...apiMethods } = configActions;
    const implementedActions = intersectKeys(defaultActions, [
      ...allow,
      ...Object.keys(apiMethods),
    ]);

    const defaultAsyncActions = Object.keys(implementedActions).reduce(
      (acc, actionKey) => ({
        ...acc,
        [actionKey]: {
          ...implementedActions[actionKey],
          ...configActions[actionKey],
        },
      }),
      {}
    );

    return { ...defaultAsyncActions, ...custom };
  }

  filterGetMethods(actionsConfig) {
    return Object.keys(actionsConfig).reduce((acc, configKey) => {
      return actionsConfig[configKey].method &&
        actionsConfig[configKey].method.toLowerCase() === 'get'
        ? { ...acc, [configKey]: actionsConfig[configKey] }
        : acc;
    }, {});
  }

  makeActions(
    configActions,
    actionTypeRoot,
    defaultActions,
    { actionPrefix } = {}
  ) {
    if (!configActions) return {};

    const customActionPrefix = !actionPrefix ? '' : `${actionPrefix}_`;
    const allAsyncActions = this._getAllAsyncActions(
      configActions,
      defaultActions
    );

    return Object.keys(allAsyncActions).reduce(
      (acc, action) => ({
        ...acc,
        [`${customActionPrefix}${action}`]: {
          ...allAsyncActions[action],
          types: this._getActionStates(`${actionTypeRoot}/${action}`),
        },
      }),
      {}
    );
  }
}

export { actionStates };

export default new AsyncActionsGenerator();
