import { saveSurvey } from 'src/actionCreators/surveyActionCreators';
import { SurveyPublic } from 'src/api/SurveyPublic';
import {
  SURVEY__CHANGE_PROPERTY,
  SURVEY_PUBLIC__PUT_SUCCESS,
  SURVEY__SAVE_SUCCESS,
  SURVEY_PUBLIC__LANGUAGE_CHANGE,
} from 'src/constants';
import history from 'src/historyInstance';
import { storeSurveyPreferredLanguage } from 'src/utils/surveysI18n';

import QueryParamsManager from 'im/api/QueryParamsManager';

export default function surveyEffects(state, action, dispatch) {
  switch (action.type) {
    case SURVEY__SAVE_SUCCESS:
      if (action.meta?.silent) return;
      // double save to avoid issues with layout elemenets referencing questions by UUID rathert than server ID
      const shouldDoubleSave = state.survey.questions.some(
        (q) => state.survey.layout[q.position]?.question_id != q.id
      );
      if (shouldDoubleSave)
        dispatch(saveSurvey(state.survey, action.meta.projectId, true));
      break;
    case SURVEY__CHANGE_PROPERTY:
      if (action.meta?.autosave && action.meta?.projectId) {
        dispatch(saveSurvey(state.survey, action.meta.projectId));
      }
      if (action.payload.name === 'activeBatch') {
        history.replace({
          search: QueryParamsManager.stringifyAndUpdate(
            action.meta?.cleanSearchParam ? '' : location.search,
            { batch: action.payload.value }
          ),
        });
      }
      break;

    case SURVEY_PUBLIC__PUT_SUCCESS:
      if (action.meta?.isFinished) {
        history.replace(
          `/answer-survey/${action.payload.data.permalink}/success`
        );
      }
      break;

    case SURVEY_PUBLIC__LANGUAGE_CHANGE:
      storeSurveyPreferredLanguage(action.payload);
      break;

    case SurveyPublic.actionTypes.start.success:
      history.push(`/answer-survey/${action.payload.data.permalink}`);
      break;

    default:
      break;
  }
}
