import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import cls from './PopoverHeader.module.css';

const cx = classNames.bind(cls);

export default class PopoverHeader extends React.PureComponent {
  static propTypes = {
    text: PropTypes.string,
    label: PropTypes.string,
    className: PropTypes.string,
  };

  render() {
    const { className, label, text } = this.props;
    return (
      <div
        className={cx(
          'popoverHeader',
          { popoverHeaderWithLabel: !!label },
          className
        )}
      >
        {label && <div className={cx('popoverHeaderLabel')}>{label}</div>}
        {text}
      </div>
    );
  }
}
