import React from 'react';
import PropTypes from 'prop-types';
import { SelectField, Container } from 'src/components/IMUI';
import { Error } from 'src/components/IMUI/Forms/base';
import { monthsLong } from 'src/utils/date';

import cls from './DateDropdown.module.css';

const exceedsMonthDayLimit = ({ day, month, year }) => {
  const daysInMonth = new Date(year, month, 0).getDate();
  return day > daysInMonth;
};
export const isValidDate = ({ day, month, year }) => {
  if (!day) return true;
  return !exceedsMonthDayLimit({ day, month, year });
};

export default class DateDropdown extends React.PureComponent {
  static propTypes = {
    flat: PropTypes.bool,
    outline: PropTypes.bool,
    value: PropTypes.object,
    className: PropTypes.string,
    validationFn: PropTypes.func,
    onChange: PropTypes.func.isRequired,
  };
  static defaultProps = {
    value: { day: undefined, month: undefined, year: undefined },
  };
  constructor(props) {
    super(props);
    this.state = { error: this.getError(props.value) };
  }

  getError = (value) => {
    const { validationFn } = this.props;
    if (exceedsMonthDayLimit(value))
      return `${monthsLong[value.month - 1]} doesn't have ${value.day} days`;
    if (validationFn && validationFn(value)) return validationFn(value);
    return '';
  };

  handleChange = (pair) => {
    this.setState({ error: this.getError({ ...this.props.value, ...pair }) });
    this.props.onChange({ ...this.props.value, ...pair });
  };

  renderError = () => {
    return this.state.error && <Error error={this.state.error} />;
  };

  render() {
    const fromYear = 2050;
    const years = Array.from(Array(150), (_, i) => fromYear - i);
    const months = Array.from(Array(12), (_, i) => i + 1);
    const days = Array.from(Array(31), (_, i) => i + 1);

    return (
      <Container className={this.props.className}>
        <Container horizontal className={cls.dateDropdownInputs}>
          {this.props.onChange && (
            <SelectField
              flat={this.props.flat}
              outline={this.props.outline}
              label="Day"
              value={this.props.value.day}
              className={cls.dateDropdownSelectMini}
              onChange={({ value }) => this.handleChange({ day: value })}
            >
              <SelectField.Item value={null} primaryText={'-'} />
              {days.map((dd) => (
                <SelectField.Item key={dd} value={dd} primaryText={dd} />
              ))}
            </SelectField>
          )}

          {this.props.onChange && (
            <SelectField
              flat={this.props.flat}
              outline={this.props.outline}
              label="Month"
              value={this.props.value.month}
              className={cls.dateDropdownSelectMini}
              onChange={({ value }) => this.handleChange({ month: value })}
            >
              <SelectField.Item value={null} primaryText={'-'} />
              {months.map((mm) => (
                <SelectField.Item key={mm} value={mm} primaryText={mm} />
              ))}
            </SelectField>
          )}

          {this.props.onChange && (
            <SelectField
              flat={this.props.flat}
              outline={this.props.outline}
              label="Year"
              value={this.props.value.year}
              className={cls.dateDropdownSelectMax}
              onChange={({ value }) => this.handleChange({ year: value })}
            >
              <SelectField.Item value={null} primaryText={'-'} />
              {years.map((yy) => (
                <SelectField.Item key={yy} value={yy} primaryText={yy} />
              ))}
            </SelectField>
          )}
        </Container>
        {this.renderError()}
      </Container>
    );
  }
}
