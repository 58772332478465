import identity from 'ramda/src/identity';

import {
  REPORT__GET_REQUEST,
  REPORT__GET_SUCCESS,
  REPORT__GET_FAILURE,
  TAG__UPDATE_SUCCESS,
} from 'src/constants';
import { toReportFromApi } from 'src/serializers/reportSerializer';

const initialState = {
  pending: false,
  meta: { projectId: null, projectReportId: null },
};

export default function reportReducer(state = initialState, action) {
  switch (action.type) {
    case REPORT__GET_REQUEST:
      return { ...initialState, pending: true, meta: { ...action.meta } };
    case REPORT__GET_SUCCESS:
      return {
        ...state,
        pending: false,
        ...toReportFromApi(action.payload.data),
      };
    case REPORT__GET_FAILURE:
      return { ...state, pending: false };
    case TAG__UPDATE_SUCCESS:
      return {
        ...state,
        tagGroups: (state.tagGroups || []).filter(identity).map((tagGroup) => {
          return {
            ...tagGroup,
            tags: tagGroup.tags.map((tag) => {
              return tag.id === action.payload.tagId
                ? { ...tag, name: action.payload.data.tag.name }
                : tag;
            }),
          };
        }),
      };
    default:
      return state;
  }
}
