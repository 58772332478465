import React from 'react';
import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import { submit } from 'redux-form';
import organizationApi from 'src/api/Organization';
import { Section, Container } from 'src/components/IMUI';
import ManageSubjectsSurveyOnlyCard from 'src/components/SurveyOnly/ManageSubjectsSurveyOnlyCard';
import { CUSTOM_DEFINITIONS } from 'src/components/SurveyOnly/surveyOnlyUpsellText';
import { where } from 'im/api/Query';
import DictionaryForm from '../components/DictionaryForm';
import UserOrgEditFooter from '../components/UserOrgEditFooter';
import { surveyOnly } from 'src/userStorage';
const FORM_NAME_DICTIONARY = 'userOrganizationDictionary';

@connect(pick(['organization']), {
  submit,
  putOrganization: organizationApi.put,
})
export default class UserOrgEditDictionary extends React.PureComponent {
  static propTypes = {
    submit: PropTypes.func.isRequired,
    putOrganization: PropTypes.func.isRequired,
    match: ReactRouterPropTypes.match,
    organization: PropTypes.object,
  };
  static defaultProps = { organization: {} };
  onSubmitRequest = () => {
    this.props.submit(FORM_NAME_DICTIONARY);
  };
  onSubmitDictionary = (dictionary) => {
    this.props.putOrganization(
      where({ id: this.props.match.params.id }).payload({ dictionary })
    );
  };

  render() {
    if (surveyOnly())
      return (
        <Section>
          <Container horizontal centerHorizontal>
            <ManageSubjectsSurveyOnlyCard {...CUSTOM_DEFINITIONS} />
          </Container>
        </Section>
      );

    return (
      <div>
        <Section collapsed limitedWidth centered grow>
          <DictionaryForm
            title="Organization Dictionary"
            form={FORM_NAME_DICTIONARY}
            initialValues={this.props.organization.data?.dictionary}
            onSubmit={this.onSubmitDictionary}
          />
        </Section>
        <UserOrgEditFooter onSubmitRequest={this.onSubmitRequest} />
      </div>
    );
  }
}
