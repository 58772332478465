import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, TextField } from 'src/components/IMUI';
import { canManageTag } from 'src/userStorage';

export default class QuoteTextDialog extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    quoteText: PropTypes.string,
    handleSaveQuote: PropTypes.func,
    handleCancel: PropTypes.func,
  };
  state = {
    quoteText: this.props.quoteText ?? '',
  };

  componentDidUpdate(prevProps) {
    prevProps.quoteText !== this.props.quoteText &&
      this.onChangeQuoteText(this.props.quoteText);
  }
  onChangeQuoteText = (text) => {
    this.setState({ quoteText: text });
  };
  onClickSaveQuote = () => {
    this.props.handleSaveQuote(this.state.quoteText);
  };

  render() {
    return (
      <Dialog
        extraLarge
        bodyStyle={{ minHeight: '400px' }}
        title="Quotes"
        modal={false}
        open={this.props.open}
        onRequestClose={this.props.handleCancel}
        leftActions={
          <Button
            size="l"
            key="cancel"
            negative
            label="Cancel"
            onClick={this.props.handleCancel}
          />
        }
        rightActions={
          <Button
            disabled={!canManageTag()}
            size="l"
            key="save"
            label="Save quote"
            onClick={this.onClickSaveQuote}
          />
        }
      >
        <TextField
          fullWidth
          multiLine
          value={this.state.quoteText}
          label="Edit or annotate the quote"
          hintText="Quote Text"
          rows={15}
          rowsMax={15}
          onChange={this.onChangeQuoteText}
        />
      </Dialog>
    );
  }
}
