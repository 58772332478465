import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import cls from './FlexRow.module.css';

class FlexRow extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    direction: PropTypes.oneOf(['horizontal', 'vertical']),
  };

  static defaultProps = {
    direction: 'horizontal',
  };

  render() {
    const { className, direction } = this.props;
    return (
      <div
        {...this.props}
        className={cx('flexRow', cls.row, cls[direction], className)}
      >
        {this.props.children}
      </div>
    );
  }
}

export default FlexRow;
