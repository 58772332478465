import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'im/ui/Icon';
import cls from './EditIcons.module.css';
import { canManageTag } from 'src/userStorage';

const EditIcons = ({ onRightClick, onClickEdit, onClickRemove }) => {
  return (
    <div className={cls.editIcons}>
      {onRightClick && (
        <Icon
          name="coordinate"
          tip="Select"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onRightClick();
          }}
        />
      )}
      {canManageTag() && onClickEdit && (
        <Icon
          name="edit"
          tip="Edit"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onClickEdit();
          }}
        />
      )}
      {canManageTag() && onClickRemove && (
        <Icon
          name="trash"
          tip="Remove"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onClickRemove();
          }}
        />
      )}
    </div>
  );
};

EditIcons.propTypes = {
  onClickEdit: PropTypes.func,
  onClickRemove: PropTypes.func,
  onRightClick: PropTypes.func,
};

export default EditIcons;
