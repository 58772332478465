/* eslint camelcase: 0 */

import classNames from 'classnames/bind';
import MenuItem from 'material-ui/MenuItem';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';

import { Container, SelectField } from 'src/components/IMUI';

import { Icon } from 'im/ui/Icon';

import cls from './Pagination.module.css';

const cx = classNames.bind(cls);

const pageFirst = 0;

const previousLabel = <Icon name="chevron-left" />;
const nextLabel = <Icon name="chevron-right" />;
const breakLabel = <span>...</span>;

export default class Pagination extends Component {
  static propTypes = {
    per_page: PropTypes.number,
    page: PropTypes.number,
    total_pages: PropTypes.number,
    total_items: PropTypes.number,
    items_label: PropTypes.string,

    perPageOptions: PropTypes.array,
    compact: PropTypes.bool,
    className: PropTypes.string,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    items_label: 'items',
    perPageOptions: [10, 30, 50, 100],
  };

  handlePageClick = (data) => {
    const { selected } = data;
    const { onChange, per_page } = this.props;
    onChange({ per_page: Number(per_page), page: Number(selected) });
  };

  handlePerPageChange = (value) => {
    const { onChange } = this.props;
    onChange({ per_page: Number(value), page: pageFirst });
  };

  renderPages(className = '') {
    const { page, per_page, total_pages, total_items, items_label } =
      this.props;
    const selection_start = page * per_page;
    const selection_end = selection_start + per_page;

    return (
      <div
        className={cx(cls.pages, className, {
          [cls.pagesCompact]: this.props.compact,
        })}
      >
        {!total_items ? null : (
          <p className={cls.pagesText}>
            Showing {items_label} {selection_start + 1}-
            {Math.min(selection_end, total_items)} of {total_items}
          </p>
        )}
        {total_pages > 1 && (
          <ReactPaginate
            previousLabel={previousLabel}
            nextLabel={nextLabel}
            breakLabel={breakLabel}
            onPageChange={this.handlePageClick}
            breakClassName={cls.break}
            pageCount={total_pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            containerClassName={cx(cls.pagination, {
              [cls.paginationCompact]: this.props.compact,
            })}
            subContainerClassName={cls.pages}
            activeClassName={cls.active}
            forcePage={page}
          />
        )}
      </div>
    );
  }

  render() {
    const { per_page, items_label, compact, className, perPageOptions } =
      this.props;

    return compact ? (
      this.renderPages(className)
    ) : (
      <div className={cx(cls.wrapper, className)}>
        <Container horizontal centered>
          <SelectField
            outline
            transparent
            className={cls.select}
            noValidation
            value={per_page}
            onChange={({ value }) => this.handlePerPageChange(value)}
          >
            {perPageOptions.map((opt) => (
              <MenuItem key={opt} value={opt} primaryText={opt} />
            ))}
          </SelectField>
          <p className={cls.itemsText}>{items_label} per page</p>
        </Container>
        {this.renderPages()}
      </div>
    );
  }
}
