import { where } from '../../Query';
import advancedFiltersReducer from '../../reducers/advancedFiltersReducer';
import EntityApi from '../EntityApi';
import EntityBase from '../EntityBase';

export const CUSTOM_ATTRIBUTE_NAMES = {
  tag_categories_title: 'title',
  answer_survey_title: 'title', // this seems super sketchy
  tags_title: 'title', // this seems super sketchy
  report_period: 'period',
  grantee_survey_grantee_country_code_or_report_grantee_country_code:
    'country_code',
  tag_categories_metatags: 'metatags',
};

const getAttrNames = (config) => {
  const { allow = [], custom = {}, ...rest } = config;
  return { allow, custom: Object.keys(custom), rest: Object.keys(rest) };
};
const getActionName = (attrName, actionPrefix) =>
  `${actionPrefix ? `${actionPrefix}_` : ''}${attrName}`;

export default class AdvancedFilters extends EntityBase {
  constructor(_config, resource) {
    const config = { ..._config, type: resource.type, root: resource.root };
    super(config, resource.reducerName);
    this.actionTypeRoot = `${AdvancedFilters.actionTypePrefix}/${this.type}/${this.reducerName}`;

    this.attrNames = getAttrNames(_config);
    this.actionPrefix = _config.actionPrefix;

    const { asyncActions, asyncActionsConfig } = this.makeAsyncActions(config);
    this.actions = asyncActions;
    this._actionsConfig = asyncActionsConfig;
  }

  static defaultMethod = 'GET';

  get actionTypes() {
    return super.getActionTypes(this._actionsConfig);
  }

  _action(
    action,
    { key, value, predicate = 'cont', identifiable } = {},
    ...params
  ) {
    const {
      urlParams,
      queryParams,
      data,
      meta: _meta,
    } = EntityApi.getParams(params);

    // External resource has different root in the config
    const meta = identifiable ? { ..._meta, identifiable } : _meta;
    const filterKey = action.root ? key.replace(`${action.type}_`, '') : key;
    const query = where(urlParams, queryParams, data, meta).sort(filterKey);

    const filteredKey = CUSTOM_ATTRIBUTE_NAMES[filterKey] || filterKey;
    if (value) {
      query.filter(`${filteredKey}_${predicate}`, value);
    }
    return super.runRequest({
      action,
      params: query,
      root: action.root,
      type: action.type,
    });
  }

  _getAttrConfig = (filterKey) => {
    const action =
      this._actionsConfig[getActionName(filterKey, this.actionPrefix)];
    const attrKey =
      CUSTOM_ATTRIBUTE_NAMES[filterKey] ||
      (action?.type ? filterKey.replace(`${action.type}_`, '') : filterKey);
    return {
      type: action?.type,
      included: action && !action.root && action.type,
      attr: attrKey,
    };
  };

  makeAsyncActions(config = {}) {
    const allActionNames = [
      ...this.attrNames.allow,
      ...this.attrNames.custom,
      ...this.attrNames.rest,
    ];

    const defaultActions = allActionNames.reduce(
      (acc, el) => ({
        ...acc,
        [el]: { method: AdvancedFilters.defaultMethod },
      }),
      {}
    );
    const { asyncActions, asyncActionsConfig } = super.getAsyncActions(
      config,
      this._action.bind(this),
      defaultActions,
      { actionPrefix: this.actionPrefix }
    );
    return { asyncActions, asyncActionsConfig };
  }

  makeReducer() {
    return super.getReducer(
      this._actionsConfig,
      advancedFiltersReducer.bind(this, this._getAttrConfig.bind(this)),
      { isCollection: this.isCollection, hasNumericPending: true }
    );
  }
}
