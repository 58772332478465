import axios from 'src/axiosInstance';
import { applyPlaceholders } from '../helpers/params';
import Query from '../Query';
import QueryParamsManager from '../QueryParamsManager';

export default class EntityApi {
  static CONTENT_TYPE = 'application/vnd.api+json';
  // Include has to be first in query params
  static PRIORITY_QUERY_PARAMS = ['include'];

  static getSearchParamsToObject(params) {
    return typeof params === 'string'
      ? QueryParamsManager.parse(params)
      : params;
  }

  static getParams(params) {
    const query = Array.isArray(params) ? params[0] : params;
    if (query instanceof Query) {
      const { urlParams, queryParams, data, meta } = query;
      return { urlParams, queryParams, data, meta };
    }

    // location.search
    if (typeof query === 'string') {
      return { queryParams: EntityApi.getSearchParamsToObject(query) };
    }
    const [urlParams, queryParams, meta, data] = params;
    return {
      urlParams,
      queryParams: EntityApi.getSearchParamsToObject(queryParams),
      data,
      meta,
    };
  }

  constructor(rootPath, resourceType, action, params) {
    this.resourceType = resourceType;
    this.rootPath = rootPath;
    this.action = action;
    this.params = params;
  }

  get noDispatch() {
    return this.meta.noDispatch;
  }

  // meta - from Query or params as plain object, action.meta - from actionConfig
  get meta() {
    const { urlParams, queryParams, meta = {} } = this._getParams();
    return { urlParams, queryParams, ...meta, ...this.action.meta };
  }

  _getJsonapiPayload = (payload, { id } = {}, isJsonApiStandard) => {
    return isJsonApiStandard
      ? { data: { id: payload.id || id, type: this.resourceType, ...payload } }
      : payload;
  };

  _getParams = () => {
    const { urlParams, queryParams, data, meta } = EntityApi.getParams(
      this.params
    );
    const pathQueryParams =
      this._getPathQueryParams(this.action.path, urlParams) || {};
    const allQueryParams = QueryParamsManager.add(queryParams, pathQueryParams);
    return { urlParams, data, meta, queryParams: allQueryParams };
  };

  _getPathQueryParams(path, urlParams) {
    return !path || !path?.includes?.('?')
      ? null
      : QueryParamsManager.parse(applyPlaceholders(path, urlParams));
  }

  _getPath(actionPath = '') {
    return actionPath?.split?.('?').shift() ?? '';
  }

  _sortQueryParamsByPriority = (a, b) => {
    return EntityApi.PRIORITY_QUERY_PARAMS.includes(a) ? -1 : a === b ? 0 : 1;
  };

  getRequest = () => {
    const {
      urlParams,
      queryParams,
      data,
      meta = { headers: {} },
    } = this._getParams();
    // Default contentType is JSONAPI
    const isJsonApiStandard =
      !this.action.headers ||
      this.action.headers['Content-Type'] === EntityApi.CONTENT_TYPE;
    return () =>
      axios.request({
        url: applyPlaceholders(
          this.action.path
            ? `${this.rootPath}/${this._getPath(this.action.path)}`
            : this.rootPath,
          urlParams
        ),
        method: this.action.method?.toLowerCase(),
        headers: {
          'Content-Type': EntityApi.CONTENT_TYPE,
          ...this.action.headers,
          ...meta.headers,
        },
        responseType: this.action.responseType,
        params: queryParams,
        paramsSerializer: (params) =>
          QueryParamsManager.stringify(params, {
            sort: this._sortQueryParamsByPriority,
          }),
        data:
          data && this._getJsonapiPayload(data, urlParams, isJsonApiStandard),
      });
  };
}
