export const SHORT_TEXT = 'shortText';
export const LONG_TEXT = 'longText';
export const RATING = 'rating';
export const OPINION_SCALE = 'opinionScale';
export const SINGLE_CHOICE = 'singleChoice';
export const MULTIPLE_CHOICE = 'multipleChoice';
export const FINANCIAL = 'financial';
export const NUMERICAL = 'numerical';
export const SINGLE_MATRIX = 'singleMatrix';
export const DATE = 'date';
export const COUNTRY = 'country';

export const SECTION_BREAK = 'sectionBreak';
export const HEADER = 'header';
export const SUB_HEADER = 'subheader';
export const TEXT = 'text';

export const ANSWERABLE = [
  SHORT_TEXT,
  LONG_TEXT,
  RATING,
  OPINION_SCALE,
  SINGLE_CHOICE,
  MULTIPLE_CHOICE,
  FINANCIAL,
  NUMERICAL,
  SINGLE_MATRIX,
  DATE,
  COUNTRY,
];
export const PAGELAYOUT = [SECTION_BREAK, HEADER, SUB_HEADER, TEXT];
export const OBJECT_VALUE_QUESTIONS = [MULTIPLE_CHOICE, SINGLE_MATRIX, COUNTRY];
export const isTaggable = (question) =>
  Boolean(ANSWERABLE.includes(question?.type));
