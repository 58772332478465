import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Container } from 'src/components/IMUI/index';
import { Icon } from 'im/ui/Icon';
import cls from './OrganizationListItem.module.css';
import { canManageOrganization } from 'src/userStorage';
const cx = classNames.bind(cls);

export default class OrganizationListItem extends React.PureComponent {
  static propTypes = {
    organization: PropTypes.object,
    isCurrent: PropTypes.bool,
    isPendingApproval: PropTypes.bool,
    isPendingDeletion: PropTypes.bool,
    onChangeOrganization: PropTypes.func,
    onEditClick: PropTypes.func.isRequired,
  };

  static defaultProps = { organization: {} };
  onChangeOrganizationClick = (ev, orgId) => {
    ev.stopPropagation();
    this.props.onChangeOrganization(orgId);
  };
  render() {
    const {
      organization,
      isCurrent,
      isPendingApproval,
      isPendingDeletion,
      onChangeOrganization,
    } = this.props;
    return (
      <li className={cx('user-orgs-list-item')}>
        <Container
          horizontal
          nowrap
          className={cx('user-orgs-list-item-container')}
        >
          {onChangeOrganization && (
            <Icon
              name="compare"
              tip="Set as Current"
              className={cx('user-orgs-list-item-icon')}
              onClick={(ev) =>
                this.onChangeOrganizationClick(ev, organization.id)
              }
              style={{ marginRight: 8 }}
            />
          )}
          {canManageOrganization() && (
            <Icon
              name="edit"
              tip="Edit"
              disabled={!canManageOrganization()}
              className={cx('user-orgs-list-item-icon')}
              onClick={() => this.props.onEditClick(organization.id)}
              style={{ marginRight: 16 }}
            />
          )}
          <span className={cx({ 'user-orgs-list-item-current': isCurrent })}>
            {organization.title}
            {isCurrent && (
              <small className={cx('user-orgs-list-item-mark')}>Current</small>
            )}
          </span>
          {organization.disabled && (
            <small
              className={cx('user-orgs-list-item-mark', {
                'user-orgs-list-item-mark-pending-approval': isPendingApproval,
                'user-orgs-list-item-mark-pending-deletion': isPendingDeletion,
              })}
            >
              {organization.disabled}
            </small>
          )}
        </Container>
      </li>
    );
  }
}
