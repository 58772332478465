import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import {
  FormField,
  NumberField,
  Checkbox,
  TextField,
} from 'src/components/IMUI/index';
import createValidator from 'src/utils/validation';
import cls from './BillingSettings.module.css';
import { canManageOrganization } from 'src/userStorage';

export function validateBilling(values = {}) {
  const not = (fn) => (v) => !fn(v);
  const isDefined = (v) => Boolean(v);
  const validator = createValidator({
    maxMemberships: [[not(isDefined), () => 'Select at least 1 user']],
  });
  return validator(values);
}

class BillingSettings extends React.PureComponent {
  static propTypes = {
    onChangeFormValue: PropTypes.func.isRequired,
    disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.disabled === true && !this.props.disabled) {
      this.props.onChangeFormValue('disabledText', 'Needs a review');
    }
  }
  render() {
    return (
      <div>
        <h3>Billing settings</h3>
        <FormField
          anchorScrollName="max_memberships"
          className={cls.inputHalfWidth}
        >
          <Field
            disabled={!canManageOrganization()}
            fullWidth
            min={0}
            component={NumberField}
            name="max_memberships"
            label="Max users"
            hintText="Type in max users"
          />
        </FormField>
        <Field
          disabled={!canManageOrganization()}
          name="disabled"
          component={Checkbox}
          label="Active Organization"
          format={(v) => !v}
          normalize={(v) => !v}
        />
        <br />
        {!this.props.disabled ? null : (
          <Field
            name="disabledText"
            component={TextField}
            hintText="Type in the reason"
            label="Disable reason"
          />
        )}
      </div>
    );
  }
}

export default BillingSettings;
