import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import { Actions, Container, Toggle } from 'src/components/IMUI';
import { Label } from 'src/components/IMUI/Forms/base';

import cls from './OverviewItem.module.css';

const cx = classNames.bind(cls);

export default class OverviewItem extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
    toggled: PropTypes.bool,
    label: PropTypes.string,
    toggledLabel: PropTypes.string,
    className: PropTypes.string,
    onToggle: PropTypes.func,
    renderToggled: PropTypes.func,
    renderActions: PropTypes.func,
  };

  static defaultProps = {
    toggled: false,
  };

  renderToggled() {
    const { renderToggled, toggledLabel } = this.props;
    return (
      <div>
        {renderToggled
          ? renderToggled()
          : toggledLabel && <Label label={toggledLabel} />}
      </div>
    );
  }

  render() {
    const {
      title,
      toggled = false,
      label,
      onToggle,
      renderActions,
      children,
      className,
    } = this.props;
    const containerClasses = cx(cls.overviewItem, {
      [cls.hasToggle]: onToggle,
    });

    return (
      <Container horizontal nowrap className={cx(containerClasses, className)}>
        <Actions>
          <Container>
            <h3>{title}</h3>
            {!toggled ? label && <Label label={label} /> : this.renderToggled()}
            {children}
          </Container>
        </Actions>
        {renderActions
          ? renderActions()
          : onToggle && (
              <Actions>
                <Toggle compact toggled={toggled} onClick={onToggle} />
              </Actions>
            )}
      </Container>
    );
  }
}
