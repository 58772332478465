import { User } from 'src/api/User';

const rootReducer = (appReducer) => (_state, action) => {
  let state = _state;

  if (action.type === User.actionTypes.logOut) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
