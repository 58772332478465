const flattenCustomRegionMapping = (arr) =>
  (arr || []).reduce((acc, el) => ({ ...acc, [el.name]: el.countryCodes }), {});
const deflattenCustomRegionMapping = (obj) =>
  Object.keys(obj || {}).reduce(
    (acc, key) => acc.concat({ name: key, countryCodes: obj[key] }),
    []
  );

export const fromApiToFormData = (organization) =>
  organization
    ? {
        ...organization,
        disabledText: organization.disabled || 'Needs a review',
        custom_region_mapping: {
          items: deflattenCustomRegionMapping(
            (organization || {}).custom_region_mapping
          ),
        },
        logo: organization.logo_url,
      }
    : {};

export const fromFormDataToApi = ({ disabled, disabledText, ...rest }) => ({
  ...rest,
  custom_region_mapping: flattenCustomRegionMapping(
    (rest.custom_region_mapping || []).items
  ),
  disabled: disabled ? disabledText : null,
});
