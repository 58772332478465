import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import isNil from 'ramda/src/isNil';
import { sortBy as sortByUtil } from 'src/utils/array';
import Row from './Row';
import cls from './tables.module.css';

const cx = classNames.bind(cls);

export default class Head extends React.PureComponent {
  static propTypes = {
    jsonapi: PropTypes.bool,
    children: PropTypes.node,
    onSortChange: PropTypes.func,
    className: PropTypes.string,
    transparent: PropTypes.bool,
    bold: PropTypes.bool,
    // new JSONAPI prop
    sortBy: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    // old props
    data: PropTypes.array,
    filteredData: PropTypes.array,
    defaultSort: PropTypes.shape({
      key: PropTypes.string,
      direction: PropTypes.bool,
      transform: PropTypes.func,
    }),
  };

  constructor(props) {
    super(props);
    const sortBySingle = this.getSingleSortBy(props.sortBy) || '';
    const jsonapiCurrent = props.jsonapi && {
      key: sortBySingle,
      direction: !String(sortBySingle || '').includes('-'),
    };
    this.state = {
      cellSortedActive: jsonapiCurrent ||
        props.defaultSort || { key: null, direction: null, transform: null },
    };
  }

  componentDidMount() {
    // apply default sorting when source data initialized
    if (!this.props.jsonapi && this.props.defaultSort) {
      const { key, direction, transform } = this.state.cellSortedActive;
      this.onSortChange(key, direction, transform, { immediate: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { key, direction, transform } = this.state.cellSortedActive;

    // when source data changed and sorting applied
    if (nextProps.data !== this.props.data && key && !isNil(direction)) {
      this.onSortChange(key, direction, transform, {
        immediate: true,
        dataUpdated: nextProps.data,
      });
    }
    // apply default sorting when source data initialized
    if (this.props.defaultSort && nextProps.data && !this.props.data) {
      this.onSortChange(key, direction, transform, {
        immediate: true,
        dataUpdated: nextProps.data,
      });
    }
    if (nextProps.jsonapi && !key) {
      const sortBySingle = this.getSingleSortBy(nextProps.sortBy) || '';
      this.setState({
        cellSortedActive: {
          key: sortBySingle,
          direction: !String(sortBySingle).includes('-'),
        },
      });
    }
  }

  onSortChange = (
    sortKey,
    sortDirection,
    sortTransform,
    { immediate, dataUpdated } = {}
  ) => {
    const { data: dataNow, filteredData, jsonapi } = this.props;
    if (jsonapi) {
      return this.onSortChangeJSONAPI(sortKey, sortDirection);
    }

    const {
      cellSortedActive: { key: keyActive, direction: directionActive },
    } = this.state;
    if (
      !immediate &&
      sortKey === keyActive &&
      sortDirection === directionActive
    ) {
      return null;
    }
    this.setState({
      cellSortedActive: {
        key: sortKey,
        direction: sortDirection,
        transform: sortTransform,
      },
    });

    if (!dataNow && !filteredData && !jsonapi) {
      return this.props.onSortChange(`${sortDirection ? '' : '-'}${sortKey}`);
    }
    const data = dataUpdated || dataNow;

    const sortedItemsAll = sortByUtil(
      [...data],
      sortKey,
      sortDirection,
      sortTransform
    );
    const sortedFilteredItems =
      data.length === filteredData.length
        ? sortedItemsAll
        : sortByUtil([...filteredData], sortKey, sortDirection, sortTransform);
    return this.props.onSortChange(sortedItemsAll, sortedFilteredItems);
  };

  onSortChangeJSONAPI = (sortKey, sortDirection) => {
    this.setState({
      cellSortedActive: { key: sortKey, direction: sortDirection },
    });
    this.props.onSortChange(`${sortDirection ? '' : '-'}${sortKey}`);
  };

  getSingleSortBy = (sortBy) => {
    // TODO: Handle displaying sorting by multiple fields
    return Array.isArray(sortBy) ? sortBy[0] : sortBy;
  };

  applyOnSortProp() {
    const { children } = this.props;
    const { cellSortedActive } = this.state;

    return React.Children.map(children, (child) => {
      return !child
        ? null
        : React.cloneElement(child, {
            onSortChange: this.onSortChange,
            cellSortedActive,
          });
    });
  }

  render() {
    const { children, onSortChange, className, transparent, bold } = this.props;

    return (
      <thead
        className={cx({
          'imui-table-head-transparent': transparent,
          'imui-table-head-bold': bold,
        })}
      >
        <Row className={className}>
          {onSortChange ? this.applyOnSortProp() : children}
        </Row>
      </thead>
    );
  }
}
