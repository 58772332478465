import { where } from 'im/api/Query';
import Resource from 'im/api/Resource';

const common = {
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
  meta: { noSync: true },
};

const apiConfig = {
  root: 'api/v3/taggings/export',
  type: 'taggingsExport',
  api: {
    custom: {
      single: {
        ...common,
        path: where().filter('id_eq', ':id').toString(),
      },
      all: {
        ...common,
      },
    },
  },
};

export const TaggingsExport = new Resource(apiConfig);
export default TaggingsExport.api;
