import React from 'react';
import { Tag, Container } from 'src/components/IMUI';
import Tree from './Tree';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Icon } from 'im/ui/Icon';
import cls from './TreeNode.module.css';
import { canManageTag } from 'src/userStorage';
const cx = classNames.bind(cls);

const showTagFullName = (tag) => tag?.title?.length > 50;
const tagWithCode = (tag = {}, showCodes) =>
  showCodes && tag.code?.length > 0
    ? `${tag.code}${tag.code.endsWith('.') ? '' : '.'} ${tag.title}`
    : tag.title;

const TreeNode = ({
  className,
  children,
  allExpanded,
  renderChildren,
  isParent,
  onAdd,
  onEdit,
  ...rest
}) => (
  <li className={cx(cls.treeNode, className)} {...rest}>
    <Container
      horizontal
      centered
      className={cx(cls.treeNodeContent, {
        [cls.treeNodeContentChild]: !isParent,
      })}
    >
      {isParent && (
        <Icon
          className={cls.treeNodeOuterIcon}
          name={allExpanded && renderChildren ? 'folder' : 'folder-closed'}
        />
      )}
      {children}
      {canManageTag() && onEdit && (
        <Icon name="edit" className={cls.treeNodeEditIcon} onClick={onEdit} />
      )}
      {canManageTag() && onAdd && (
        <div className={cls.treeNodeSeparator}>
          <Icon
            name="plus"
            className={cls.treeNodeSeparatorIcon}
            onClick={onAdd}
          />
        </div>
      )}
    </Container>

    {renderChildren && renderChildren()}
  </li>
);

export default class CategoryTree extends React.PureComponent {
  static propTypes = {
    tags: PropTypes.object,
    tagCategories: PropTypes.object,
    onCreateEntityRequest: PropTypes.func,
    onEditCategory: PropTypes.func,
    onEditTag: PropTypes.func,
    allExpanded: PropTypes.bool,
    showCodes: PropTypes.bool,
  };

  renderNestedCategories = (category) => () => {
    return !category.tags?.length &&
      !category.child_categories?.length ? null : (
      <Tree pending={false}>
        {this.props.tags.data
          .filter(() => this.props.allExpanded)
          .filter((tag) => category.tags?.find((ct) => ct.id === tag?.id))
          .sort((tag1, tag2) =>
            tagWithCode(tag1, this.props.showCodes).localeCompare(
              tagWithCode(tag2, this.props.showCodes),
              'en',
              { numeric: this.props.showCodes }
            )
          )
          .map((tag) => (
            <TreeNode key={`${tag.id}-child`}>
              <Tag
                className={cls.treeNodeContentTag}
                leftAlign
                tip={showTagFullName(tag) ? tag.title : null}
                labelProps={{ className: cls.treeNodeContentLabel }}
                label={tagWithCode(tag, this.props.showCodes)}
                onEdit={() => this.props.onEditTag(tag.id)}
              />
            </TreeNode>
          ))
          .concat(this.renderHeadingCategories(category.child_categories))}
      </Tree>
    );
  };

  renderHeadingCategories = (tagCategories) =>
    tagCategories
      .sort((tag1, tag2) =>
        tagWithCode(tag1, this.props.showCodes).localeCompare(
          tagWithCode(tag2, this.props.showCodes),
          'en',
          { numeric: this.props.showCodes }
        )
      )
      .map((category) => (
        <TreeNode
          key={`${category.id}-parent`}
          isParent
          renderChildren={this.renderNestedCategories(category)}
          allExpanded={this.props.allExpanded}
          onAdd={(ev) => this.props.onCreateEntityRequest(ev, category)}
          onEdit={() => this.props.onEditCategory(category.id)}
        >
          {tagWithCode(category, this.props.showCodes)}
          <div className={cls.metatagTagsList}>
            {category.metatags?.map((m) => (
              <Tag alt square key={m} label={m} />
            ))}
          </div>
        </TreeNode>
      ));

  render() {
    return (
      <Tree
        pending={
          this.props.tagCategories.pending.init ||
          this.props.tagCategories.pending.ui
        }
      >
        {this.renderHeadingCategories(this.props.tagCategories.data)}
      </Tree>
    );
  }
}
