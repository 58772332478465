import { isGetMethod } from '../helpers/string';

class UpdatePending {
  actionType = 'updatePending';

  getPendingKey = (state, action) => {
    if (action.meta?.pending && typeof action.meta?.pending == 'string')
      return action.meta?.pending;
    if (isGetMethod(action.meta?.method) && !state.meta?.getAction)
      return 'init';
    if (
      isGetMethod(action.meta?.method) &&
      state.pending.init &&
      state.meta?.getAction
    )
      return 'init';
    return 'ui';
  };

  action = (type, pending) => (dispatch) =>
    dispatch({ type, payload: { pending } });
}

export default new UpdatePending();
