import classNames from 'classnames/bind';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import React from 'react';
import { connect } from 'react-redux';
import organizationsApi from 'src/api/Organizations';
import userApi from 'src/api/User';
import { doLogout } from 'src/services/AuthService';
import { Divider, Popover } from 'src/components/IMUI';
import history from 'src/historyInstance';
import { where } from 'im/api/Query';
import { Icon } from 'im/ui/Icon';
import cls from './AccountNavigationButton.module.css';
import { userMemberships } from 'src/userStorage';
const cx = classNames.bind(cls);
const ITEM_STYLE = { fontFamily: 'averta, Arial', fontSize: '14px' };
const ITEM_PLACEHOLDER_STYLE = {
  ...ITEM_STYLE,
  fontSize: 12,
  minHeight: 0,
  lineHeight: 1.5,
  fontWeight: 600,
  color: '#82929f',
};
const ITEM_VIEW_ALL_STYLE = {
  ...ITEM_STYLE,
  color: '#52cccc',
  textAlign: 'center',
};

@connect(pick(['user', 'userRecentOrganizations', 'organizationCurrent']), {
  findRecentUserOrganizations: organizationsApi.recentOfUser.findAll,
  changeCurrentOrganization: userApi.changeCurrentOrganization,
})
export default class AccountNavigationButton extends React.PureComponent {
  static propTypes = {
    user: PropTypes.object,
    userRecentOrganizations: PropTypes.object,
    organizationCurrent: PropTypes.object,
    findRecentUserOrganizations: PropTypes.func.isRequired,
    changeCurrentOrganization: PropTypes.func.isRequired,
  };
  static defaultProps = { user: {} };
  state = { popoverOpen: false };

  onButtonClick = (ev) => {
    ev?.preventDefault();
    ev?.stopPropagation();
    if (this.state.popoverOpen) return this.onPopoverClose();
    if (!this.props.userRecentOrganizations.data?.length) {
      this.props.findRecentUserOrganizations(
        where({ id: this.props.user.data.id })
      );
    }
    this.setState({ popoverOpen: true });
  };
  onPopoverClose = () => {
    this.setState({ popoverOpen: false });
  };
  onChange = (id) => {
    this.onPopoverClose();
    this.props.changeCurrentOrganization(
      where({ id: this.props.user.data.id }).payload({
        current_organization_id: id,
      })
    );
  };
  handleCurrentOrgEdit = () => {
    this.onPopoverClose();
    history.push(
      `/user/organizations/edit/${this.props.organizationCurrent.data.id}`
    );
  };
  handleViewAllOrgs = () => {
    this.onPopoverClose();
    history.push('/user/organizations');
  };
  render() {
    const recents = this.props.userRecentOrganizations.data.filter(
      (org) => org.id !== this.props.user.data.current_organization_id
    );
    const isMultiOrg = userMemberships() > 1;
    return (
      <div
        className={cx('account-navigation-button')}
        ref={(ref) => (this.innerRef = ref)}
      >
        <Icon name="account" onClick={this.onButtonClick} />
        <Popover
          open={this.state.popoverOpen}
          anchorEl={this.innerRef}
          anchorOrigin={{ horizontal: 'middle', vertical: 'bottom' }}
          targetOrigin={{ horizontal: 'right', vertical: 'top' }}
          onRequestClose={this.onPopoverClose}
          header={
            <Popover.Header
              label={isMultiOrg ? 'Viewing as' : null}
              text={this.props.organizationCurrent.data.title || ' ...'}
            />
          }
          footerAction={doLogout}
          footerLabel={<span style={{ fontSize: 14 }}>Logout</span>}
        >
          <Menu>
            <Divider compact />
            <Divider compact />
            <Divider compact />

            {isMultiOrg && (
              <MenuItem
                primaryText="Change organization"
                anchorOrigin={{ horizontal: 'middle', vertical: 'bottom' }}
                targetOrigin={{ horizontal: 'right', vertical: 'top' }}
                style={ITEM_STYLE}
                disabled={!recents?.length}
                menuItems={[
                  <MenuItem
                    key="recent"
                    value={null}
                    primaryText="Recent organizations"
                    style={ITEM_PLACEHOLDER_STYLE}
                  />,
                  recents.map((org) => (
                    <MenuItem
                      key={org.id}
                      value={org.id}
                      primaryText={org.title}
                      style={ITEM_STYLE}
                      onClick={(e) => {
                        e.stopPropagation();
                        this.onChange(org.id);
                      }}
                    />
                  )),
                  <MenuItem
                    key="view-all"
                    primaryText="View All"
                    style={ITEM_VIEW_ALL_STYLE}
                    onClick={this.handleViewAllOrgs}
                  />,
                ]}
              />
            )}
            <MenuItem
              primaryText="Manage organization"
              onClick={this.handleCurrentOrgEdit}
              style={ITEM_STYLE}
            />

            <Divider compact />
            <Divider compact />
            <Divider compact />

            {isMultiOrg && (
              <MenuItem
                primaryText="All organizations"
                onClick={() => history.push('/user/organizations')}
                style={ITEM_STYLE}
              />
            )}
            <MenuItem
              primaryText="My account"
              onClick={() => history.push('/user/settings')}
              style={ITEM_STYLE}
            />
          </Menu>
        </Popover>
      </div>
    );
  }
}
