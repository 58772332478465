import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import { Icon } from 'im/ui/Icon';

import cls from './DevicePreview.module.css';

const cx = classNames.bind(cls);

const DevicePreview = ({ isSmall, children, containerBodyProps }) => (
  <div
    className={cx(cls.devicePreviewContainer, {
      [cls.devicePreviewContainerSmall]: isSmall,
    })}
  >
    <div
      className={cx(cls.devicePreviewContainerPanel, {
        [cls.devicePreviewContainerPanelSmall]: isSmall,
      })}
    >
      <div
        className={cx(cls.devicePreviewDots, {
          [cls.devicePreviewDotsHidden]: isSmall,
        })}
      >
        <span role="img" />
        <span role="img" />
        <span role="img" />
      </div>

      <div className={cx(cls.devicePreviewTriangles)}>
        <Icon name="filled-left" />
        <Icon name="filled-right" />
      </div>

      <div className={cx(cls.devicePreviewBar)}>
        <span role="img" />
      </div>

      <div
        className={cx(cls.devicePreviewMenu, {
          [cls.devicePreviewMenuTop]: !isSmall,
        })}
      >
        <Icon name="burger" />
      </div>
    </div>

    <div
      {...containerBodyProps}
      className={cx(
        cls.devicePreviewContainerBody,
        containerBodyProps.className
      )}
    >
      {children}
    </div>
  </div>
);

DevicePreview.propTypes = {
  isSmall: PropTypes.bool,
  children: PropTypes.node,
  containerBodyProps: PropTypes.object,
};

export default DevicePreview;
