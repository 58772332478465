import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { retrieveReportSet } from 'src/serializers/projectSerializer';
import reportsApi from 'src/api/Reports';
import { where } from 'im/api/Query';
import Page from 'src/components/Page/Page';
import ReportSelectorForm from 'src/components/AddProjectForm/ReportSelectorForm';
import {
  getAutocodeReports,
  setAutocodeReports,
} from 'src/services/AuthService';
import ReportOverview from './ReportOverview';

class ReportSelect extends React.PureComponent {
  static defaultProps = { project: {}, grants: {} };
  state = { finished: false };
  componentDidMount() {
    this.props.getReports(
      where({ project_id: this.props.match.params.projectId })
        .include('project_reports')
        .filter(
          'project_reports_project_id_eq',
          this.props.match.params.projectId
        )
        .fields('project_report', 'id', 'grant', 'grantee', 'report')
        .fields('grantee', 'id', 'name')
        .fields('grant', 'id', 'name')
        .fields(
          'report',
          'id',
          'uid',
          'name',
          'date',
          'report_type',
          'grant',
          'grantee',
          'project_reports',
          'crated_at',
          'period',
          'code'
        )
        .pending('init')
        .paginate({ size: 1000 })
    );
  }

  render() {
    const initialValues = {
      project_reports: (this.props.project?.project_reports || []).map((i) => ({
        grantee_id: i.grantee_id,
        grant_id: i.grant_id,
        report_id: i.report_id,
      })),
      ...getAutocodeReports(),
    };
    const doFinish = () => this.setState({ finished: true });
    const unFinish = () => this.setState({ finished: false });
    const onFinishSelectTagging = (payload) => {
      setAutocodeReports(payload);
      doFinish();
    };
    return (
      <Page
        hasFooter
        pending={this.props.reports.pending.init}
        title="Reports for AI auto-tagging"
      >
        {this.state.finished && (
          <ReportOverview
            reports={this.props.reports.data}
            unFinish={unFinish}
          />
        )}
        {!this.state.finished && (
          <ReportSelectorForm
            form="AUTOCODE"
            key={this.props.project.id}
            onSubmit={onFinishSelectTagging}
            reportSet={
              retrieveReportSet(
                this.props.grants.items,
                this.props.reports.data
              ) || []
            }
            initialValues={initialValues}
            overview={doFinish}
          />
        )}
      </Page>
    );
  }
}

export default connect(
  (state) => ({
    grants: state.grants,
    project: state.project,
    reports: state.reports,
  }),
  (dispatch) => bindActionCreators({ getReports: reportsApi.findAll }, dispatch)
)(ReportSelect);
