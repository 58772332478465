import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import AnalysisInputs from 'src/components/AddProjectForm/AnalysisInputs';
import { Container, Tile } from 'src/components/IMUI';
import { Icon } from 'im/ui/Icon';
import cls from './AnalysisType.module.css';
const cx = classNames.bind(cls);

export default class AnalysisType extends React.PureComponent {
  static propTypes = {
    type: PropTypes.string,
    active: PropTypes.bool,
    singleInactive: PropTypes.bool,
    icon: PropTypes.node,
    title: PropTypes.string,
    text: PropTypes.node,
    inactiveBtn: PropTypes.node,
    activeBtn: PropTypes.node,
    onClose: PropTypes.func,
    onActivate: PropTypes.func,
  };

  render() {
    return (
      <Tile
        className={cx('analysis-type')}
        cardProps={{
          className: cx({
            'analysis-type-card-single-inactive': this.props.singleInactive,
            'analysis-type-card-inactive': !this.props.active,
            'analysis-type-card-active': this.props.active,
          }),
        }}
        onClick={(e) => {
          e.stopPropagation();
          this.props.onActivate(this.props.type);
        }}
      >
        {this.props.active ? (
          <Container>
            <Icon
              name="close"
              className={cx('analysis-type-close')}
              onClick={this.props.onClose}
            />
            <Tile.Title className={cx('analysis-type-title-active')}>
              {this.props.title}
            </Tile.Title>
            <AnalysisInputs
              onChange={() => this.props.onActivate(this.props.type)}
            />
            <div className={cx('analysis-type-actions')}>
              {this.props.activeBtn}
            </div>
          </Container>
        ) : (
          <Container>
            <Tile.Icon
              className={cx('analysis-type-icon')}
              imgUrl={this.props.icon}
              style={{ margin: '0 auto', width: 375, height: 300 }}
            />
            <Tile.Title>{this.props.title}</Tile.Title>
            <Tile.Text>{this.props.text}</Tile.Text>
            {this.props.inactiveBtn}
          </Container>
        )}
      </Tile>
    );
  }
}
