import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm } from 'redux-form';

import Dictionary from 'src/components/AddProjectForm/Dictionary';

@reduxForm()
export default class DictionaryForm extends Component {
  static propTypes = {
    title: PropTypes.string,
  };

  render() {
    const { title } = this.props;

    return <Dictionary title={title} />;
  }
}
