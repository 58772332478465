import { isCancel } from 'axios';
import clone from 'ramda/src/clone';
import history from 'src/historyInstance';
import Intercom from 'src/services/IntercomService';
import { Notifications } from 'im/ui/Notifications';
import TrackingService from 'src/services/TrackingService';

const parseErrorMessage = (action) => {
  if (!action?.payload?.response?.errors)
    return `${action?.payload?.response?.data?.table?.error || ''} ${
      action?.payload?.response?.message || action?.payload?.message || ''
    }`.trim();
  return typeof action.payload.response.errors === 'object'
    ? Object.keys(action.payload.response.errors)
        .map(
          (k) =>
            `${k
              .split('.')
              .map((str) =>
                str[0]?.toUpperCase().concat(str.slice(1, str.length))
              )
              .join(' ')} ${action.payload.response.errors[k] || ''}`
        )
        .join('; ') || ''
    : String(action.payload.response.errors || '');
};

const actionEndsWith = (action, term) =>
  String(action.type).toLocaleUpperCase().endsWith(term);
const notify = (dispatch, message = 'No response', title = 'Error') =>
  dispatch(
    Notifications.show({
      title,
      message,
      level: 'error',
      autoDismiss: 10,
      action: {
        label: 'Report issue',
        callback: (ev) => {
          ev?.stopPropagation();
          ev?.preventDefault();
          Intercom.show();
        },
      },
    })
  );

const parseErrorStatus = (action) =>
  typeof action.payload.response?.statusText === 'object' ||
  typeof action.payload.statusText === 'object'
    ? action.payload.response?.statusText || action.payload.statusText
    : `${action.payload.response?.status || action.payload.status || ''} ${
        action.payload.response?.statusText || action.payload.statusText || ''
      }`;

const parseError = (action) =>
  action.payload.response?.errors &&
  Array.isArray(action.payload.response?.errors)
    ? clone(action.payload.response.errors)?.map((error) => ({
        ...error,
        message: error.detail,
      }))
    : [
        {
          status: parseErrorStatus(action),
          message: parseErrorMessage(action),
        },
      ];

const parseTitle = (a) =>
  a?.payload?.title ||
  [
    (a?.type || 'Unknown Error')
      .toLowerCase()
      .replace(/_/g, ' ')[0]
      .toUpperCase(),
    (a?.type || 'Unknown Error').toLowerCase().replace(/_/g, ' ').slice(1),
  ].join('') ||
  'Unknown Error';

export default function errorEffects(_state, action, dispatch) {
  if (action.meta?.silent) return;
  if (isCancel(action.payload)) return;
  if (
    action.payload?.response?.status?.toString().match(/404|503/) &&
    !history.location.pathname.match(
      `/error/${action.payload?.response.status}`
    )
  ) {
    history.replace(
      `/error/${action.payload?.response.status}?source=${history.location.pathname}`
    );
  }

  if (
    action.payload?.status >= 400 ||
    actionEndsWith(action, 'FAILURE') ||
    actionEndsWith(action, 'ERROR')
  ) {
    TrackingService.event(
      'error',
      action.type.split('/')?.slice(0, 2)?.join('-'),
      action.type
    );
    parseError(action)?.forEach((e) => {
      if (!e) return;
      if (!action?.meta?.noNotify)
        notify(
          dispatch,
          e.message || e.status || '',
          e.title || parseTitle(action)
        );
      TrackingService.event('exception', action.type || '', e.message || '');
    });
  }
}
