import React from 'react';
import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import { connect } from 'react-redux';
import {
  SHORT_TEXT,
  LONG_TEXT,
  SINGLE_CHOICE,
  MULTIPLE_CHOICE,
  SINGLE_MATRIX,
  NUMERICAL,
  FINANCIAL,
  TEXT,
  HEADER,
  SUB_HEADER,
  SECTION_BREAK,
  DATE,
  RATING,
  OPINION_SCALE,
  COUNTRY,
} from 'src/data/questionTypes';
import DateQuestion from 'src/components/Survey/DateQuestion';
import Financial from 'src/components/Survey/FinancialQuestion';
import {
  Header,
  Subheader,
  Text,
  SectionBreak,
} from 'src/components/Survey/LayoutElements';
import LongTextQuestion from 'src/components/Survey/LongTextQuestion';
import MultipleChoice from 'src/components/Survey/MultipleChoice';
import Numerical from 'src/components/Survey/NumericalQuestion';
import OpinionScale from 'src/components/Survey/OpinionScale';
import Rating from 'src/components/Survey/Rating';
import SingleChoice from 'src/components/Survey/SingleChoice';
import SingleMatrix from 'src/components/Survey/SingleMatrix';
import TextQuestion from 'src/components/Survey/TextQuestion';
import CountryQuestion from 'src/components/Survey/CountryQuestion';

@connect(pick(['survey']))
export default class Question extends React.PureComponent {
  static propTypes = {
    question: PropTypes.object.isRequired,
    survey: PropTypes.object,
    isAnswerMode: PropTypes.bool,
  };
  static getQuestionComponent(question) {
    switch (question.type) {
      case SHORT_TEXT:
        return TextQuestion;
      case LONG_TEXT:
        return LongTextQuestion;
      case RATING:
        return Rating;
      case OPINION_SCALE:
        return OpinionScale;
      case SINGLE_CHOICE:
        return SingleChoice;
      case MULTIPLE_CHOICE:
        return MultipleChoice;
      case SINGLE_MATRIX:
        return SingleMatrix;
      case NUMERICAL:
        return Numerical;
      case FINANCIAL:
        return Financial;
      case DATE:
        return DateQuestion;
      case COUNTRY:
        return CountryQuestion;

      case TEXT:
        return Text;
      case HEADER:
        return Header;
      case SUB_HEADER:
        return Subheader;
      case SECTION_BREAK:
        return SectionBreak;
      default:
        throw new Error(`Invalid question type "${question.type}"`);
    }
  }

  static validate(question, answer, otherValue) {
    return Question.getQuestionComponent(question)
      ? Question.getQuestionComponent(question).validate(
          question,
          answer,
          otherValue
        )
      : true;
  }
  render() {
    const { question, survey, isAnswerMode, ...rest } = this.props;
    const QuestionComponent = Question.getQuestionComponent(question);
    return (
      <QuestionComponent
        survey={survey}
        question={question}
        isAnswerMode={isAnswerMode}
        {...rest}
      />
    );
  }
}
