import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import SectionHeader from './SectionHeader';

import cls from './Section.module.css';

const cx = classNames.bind(cls);

export const Section = ({
  children,
  type,
  grow,
  secondary,
  horizontal,
  collapsed,
  className,
  noBorder,
  noPadding,
  id,
  scrollable,
  centered,
  limitedWidth,
  surveyWidth,
  fullHeight,
  forceMobileStyling,
}) => {
  const sectionClassName = cx(
    'imui-section',
    `imui-section-${type}`,
    {
      'imui-section-grow': grow,
      'imui-section-secondary': secondary,
      'imui-section-horizontal': horizontal,
      'imui-section-collapsed': collapsed,
      'imui-section-no-border': noBorder,
      'imui-section-no-padding': noPadding,
      'imui-section-scrollable': scrollable,
      'imui-section-centered': centered,
      'imui-section-full-height': fullHeight,
      'imui-section-limited-width': limitedWidth,
      'imui-section-survey-width': surveyWidth,
      'imui-section-mobile-styling': forceMobileStyling,
    },
    className
  );
  return (
    <div className={sectionClassName} id={id}>
      {children}
    </div>
  );
};

Section.Header = SectionHeader;

Section.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  grow: PropTypes.bool,
  secondary: PropTypes.bool,
  collapsed: PropTypes.bool,
  horizontal: PropTypes.bool,
  noBorder: PropTypes.bool,
  noPadding: PropTypes.bool,
  scrollable: PropTypes.bool,
  fullHeight: PropTypes.bool,
  limitedWidth: PropTypes.bool,
  centered: PropTypes.bool,
  surveyWidth: PropTypes.bool,
  forceMobileStyling: PropTypes.bool,
  id: PropTypes.string,
  type: PropTypes.oneOf([
    'content',
    'header',
    'sub-header',
    'actions',
    'context-header',
    'footer',
    'sticky-footer',
    'sticky-footer-second',
    'sticky-footer-survey',
    'sunken',
  ]),
};

Section.defaultProps = {
  type: 'content',
};

export default Section;
