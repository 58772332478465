import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import cls from './TileTitle.module.css';

const cx = classNames.bind(cls);

const TileTitle = ({ children, className }) => (
  <h3 className={cx('imui-tile-container-title', className)}>{children}</h3>
);
TileTitle.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default TileTitle;
