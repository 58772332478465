import PropTypes from 'prop-types';
import queryString from 'query-string';
import pick from 'ramda/src/pick';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Prompt, Link } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import { getProjects } from 'src/actionCreators/projectActionCreators';
import {
  changeName,
  saveSurvey,
  changeSurveyProperty,
  deleteSurvey,
  duplicateSurvey,
} from 'src/actionCreators/surveyActionCreators';
import surveyExportApi from 'src/api/SurveyExport';
import { confirm } from 'src/components/ConfirmModal/ConfirmModal';
import ExportConfirmation from 'src/components/ExportConfirmation/ExportConfirmation';
import {
  Actions,
  Button,
  DatePicker,
  Section,
  TextField,
  SelectField,
  PopoverLight,
} from 'src/components/IMUI';
import { Label } from 'src/components/IMUI/Forms/base';
import TranslateButton from 'src/components/TranslateButton/TranslateButton';
import Warning from 'src/components/Warning/Warning';
import { isBefore } from 'src/utils/date';
import { getI18nCompleteness } from 'src/utils/surveysI18n';
import SurveyEditFooter from '../components/SurveyEditFooter';
import TextWithClipboard from '../components/TextWithClipboard';
import LanguagesSetup from './components/LanguagesSetup';
import OverviewItem from './components/OverviewItem';
import cls from './Overview.module.css';
import { canManageSurvey } from 'src/userStorage';

const COUNTER_TYPES = [
  ['numerical', '1, 2, 3, 4...'],
  ['alphabetical', 'A, B, C, D...'],
  ['roman', 'I, II, III, IV...'],
];

@connect(pick(['survey', 'organizationCurrent', 'projects']), {
  changeName,
  saveSurvey,
  changeSurveyProperty,
  deleteSurvey,
  duplicateSurvey,
  exportSurvey: surveyExportApi.single,
  getProjects,
})
export default class Overview extends PureComponent {
  static propTypes = {
    ...ReactRouterPropTypes,
    survey: PropTypes.object,
    organizationCurrent: PropTypes.object,
    changeName: PropTypes.func,
    changeSurveyProperty: PropTypes.func,
    saveSurvey: PropTypes.func,
    deleteSurvey: PropTypes.func,
    duplicateSurvey: PropTypes.func,
    exportSurvey: PropTypes.func,
    getProjects: PropTypes.func.isRequired,
    projects: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      deadlineToggled: !!props.survey.deadline,
      datepickerRef: null,
      exportModalShow: false,
      popoverOpen: false,
      anchorEl: null,
    };
  }

  componentDidMount() {
    const { history, location, survey } = this.props;
    const batchQuery = queryString.parse(location.search).batch;
    if (survey.activeBatch) {
      history.replace({
        search: queryString.stringify({ batch: survey.activeBatch }),
      });
    } else if (batchQuery) {
      this.props.changeSurveyProperty('activeBatch', batchQuery, {
        silent: true,
      });
    }
    this.props.getProjects();
  }

  componentWillReceiveProps(nextProps) {
    if (!!nextProps.survey.deadline !== !!this.props.survey.deadline) {
      this.setState({ deadlineToggled: !!nextProps.survey.deadline });
    }
  }
  handleNameChange = (name) => {
    this.handleUpdateI18n(name);
  };
  // handlePeriodChange = (value) => { this.props.changeSurveyProperty("period", value); };
  handleUpdateI18n = (values) => {
    if (typeof values === 'string') {
      this.props.changeName(values);
    } else {
      this.props.survey.languages.forEach((langCode) =>
        this.props.changeName(values[langCode], langCode)
      );
    }
  };
  handleLanguagesChanged = (languages) => {
    this.props.changeSurveyProperty('languages', languages);
    if (languages[0] !== this.props.survey.activeLanguage) {
      this.props.changeSurveyProperty('activeLanguage', languages[0]);
    }
  };
  handleDatepickerRef = (datepickerRef) => {
    this.setState({ datepickerRef });
  };
  handleOpenDatepicker = () => {
    if (this.state.datepickerRef) {
      this.state.datepickerRef.openDialog();
    }
  };
  handleToggleDeadline = () => {
    this.setState({ deadlineToggled: !this.state.deadlineToggled }, () => {
      if (this.state.deadlineToggled === false) {
        this.handleSetDeadline(null);
      } else {
        this.handleOpenDatepicker();
      }
    });
  };
  handleSetDeadline = (deadline) => {
    if (deadline !== this.props.survey.deadline) {
      this.props.changeSurveyProperty('deadline', deadline, {
        projectId: this.props.match.params.projectId,
        autosave: !!this.props.survey?.id,
      });
    }
  };
  handleTogglePublic = () => {
    this.props.changeSurveyProperty('public', !this.props.survey.public, {
      projectId: this.props.match.params.projectId,
      autosave: !!this.props.survey?.id,
    });
  };
  handleToggleAnonymous = () => {
    this.props.changeSurveyProperty('anonymous', !this.props.survey.anonymous, {
      projectId: this.props.match.params.projectId,
      autosave: !!this.props.survey?.id,
    });
  };
  handleToggleQuestionCounters = () => {
    this.handleChangeQuestionCounters(
      this.props.survey.question_counter ? '' : 'numerical'
    );
  };
  handleChangeQuestionCounters = (type) => {
    this.props.changeSurveyProperty('question_counter', type, {
      projectId: this.props.match.params.projectId,
      autosave: !!this.props.survey?.id,
    });
  };
  handleToggleBranding = () => {
    this.props.changeSurveyProperty(
      'organization_logo',
      !this.props.survey.organization_logo,
      {
        projectId: this.props.match.params.projectId,
        autosave: !!this.props.survey?.id,
      }
    );
  };
  handleSaveSurvey = () => {
    this.props
      .saveSurvey(this.props.survey, this.props.match.params.projectId)
      .then(() => {
        window.setTimeout(() =>
          this.props.history.replace(
            `/analysis/${this.props.match.params.projectId}/surveys/${this.props.survey.id}/design`
          )
        );
      });
  };
  handleExportSurvey = () => {
    this.props
      .exportSurvey({
        project_id: this.props.match.params.projectId,
        id: this.props.survey.id,
      })
      .then(() => this.setState({ exportModalShow: true }));
  };
  handleCloseExportModal = () => {
    this.setState({ exportModalShow: false });
  };
  handleDeleteSurvey = () => {
    if (!canManageSurvey()) return;
    const { history, match, survey } = this.props;
    confirm(
      'Delete survey',
      `Are you sure that you want to delete this survey? All contact details, responses and generated reports will be deleted as well`,
      true
    )
      .then(() => {
        if (survey.id) {
          this.props
            .deleteSurvey(survey.id, match.params.projectId)
            .then(() => {
              history.replace(
                `/analysis/${match.params.projectId}/surveys/list`
              );
            });
        } else {
          history.replace(`/analysis/${match.params.projectId}/surveys/list`);
        }
      })
      .catch(() => void 0);
  };

  handleDuplicatePopoverCloseRequest = () => {
    this.setState({ popoverOpen: false });
  };
  handleDuplicatePopoverOpenRequest = (ev) => {
    ev?.preventDefault();
    this.setState({ popoverOpen: true, anchorEl: ev.currentTarget });
  };

  handleConfirmDuplicateSurvey = (project) => {
    const { history, match, survey } = this.props;
    const warningText = `Are you sure that you want to duplicate this survey: "${survey.name}", to this project: "${project.name}" ?`;
    this.handleDuplicatePopoverCloseRequest();
    confirm('Duplicate survey', warningText)
      .then(() => {
        if (survey.id) {
          this.props.duplicateSurvey(survey.id, project.id).then(() => {
            history.replace(`/analysis/${match.params.projectId}/surveys/list`);
          });
        } else {
          history.replace(`/analysis/${match.params.projectId}/surveys/list`);
        }
      })
      .catch(() => void 0);
  };

  renderTitle() {
    return (
      <OverviewItem title="Survey title">
        <div>
          <Actions small nowrap>
            <TextField
              name="surveyTitle"
              fullWidth
              noValidation
              value={this.props.survey.name || ''}
              onChange={this.handleNameChange}
            />
            <TranslateButton
              owner={this.props.survey}
              languages={this.props.survey.languages}
              values={this.props.survey.i18n}
              path="name"
              altPath="name"
              title="Translate survey title"
              onChange={this.handleUpdateI18n}
            />
          </Actions>
        </div>
      </OverviewItem>
    );
  }

  renderDeadline() {
    return (
      <OverviewItem
        className="appcueDeadline"
        title="Deadline"
        toggled={this.state.deadlineToggled || !!this.props.survey.deadline}
        label="Set a deadline to disable responses after a specific date."
        onToggle={this.handleToggleDeadline}
        renderToggled={() => (
          <Actions small>
            <Label label="Survey ends on" />
            <DatePicker
              onRef={this.handleDatepickerRef}
              noValidation
              name="survey-deadline"
              value={
                this.props.survey.deadline
                  ? new Date(this.props.survey.deadline)
                  : undefined
              }
              onChange={this.handleSetDeadline}
            />
          </Actions>
        )}
      />
    );
  }

  renderShowQuestionCounter() {
    return (
      <OverviewItem
        className="appcueCounter"
        title="Show question counters"
        toggled={!!this.props.survey.question_counter}
        label="Do you want the questions to be automatically numbered?"
        toggledLabel="Survey responses will be kept anonymous"
        onToggle={this.handleToggleQuestionCounters}
        renderToggled={() => (
          <Actions small>
            <Label label="Question counters will display as" />
            <SelectField
              noValidation
              value={this.props.survey.question_counter}
              onChange={({ value }) => this.handleChangeQuestionCounters(value)}
            >
              {COUNTER_TYPES.map(([type, label]) => (
                <SelectField.Item key={type} value={type} primaryText={label} />
              ))}
            </SelectField>
          </Actions>
        )}
      />
    );
  }

  renderShowBranding() {
    const logoPath = this.props.organizationCurrent.data.logo_url;
    const orgEditUrl = `/user/organizations/edit/${this.props.organizationCurrent.data.id}/settings`;
    /* eslint-disable */
    return (
      <OverviewItem
        className="appcueLogo"
        title="Show organization logo"
        toggled={!!this.props.survey.organization_logo}
        label="ImpactMapper logo will be visible on surveys"
        onToggle={this.handleToggleBranding}
        renderToggled={() => (
          <div>
            Organization logo will be visible on the survey
            {!!logoPath && (
              <div
                style={{ backgroundImage: `url(${logoPath})` }}
                className={cls.imagePreview}
                alt="organization logo"
              ></div>
            )}
            <Link className={cls.secondaryAction} to={orgEditUrl}>
              {!!logoPath
                ? "You can change it in your organization's settings"
                : "You can set it in your organization's settings"}
            </Link>
          </div>
        )}
      />
    );
  }

  renderPublic() {
    const publicUrl = `${window.location.origin}/answer-survey/public/${
      this.props.survey.public_id
    }${
      this.props.survey.activeBatch
        ? `?batch=${this.props.survey.activeBatch}`
        : ''
    }`;

    return (
      <OverviewItem
        toggledLabel="Only invited respondents using a unique link will access survey"
        title="Private"
        toggled={!this.props.survey.public}
        label="Enable to restrict survey access to invited respondents with a unique link. Leave it disabled to allow anyone with the URL to access the survey"
        onToggle={this.handleTogglePublic}
      >
        {this.props.survey.public && this.props.survey.public_id ? (
          <TextWithClipboard
            notificationMsg="Survey's public link copied"
            lighter
            url={publicUrl}
            justifyEnd={false}
          />
        ) : null}
      </OverviewItem>
    );
  }

  renderAnonymous() {
    return (
      <OverviewItem
        title="Anonymous"
        className="appcueAnonymous"
        toggled={!!this.props.survey.anonymous}
        label="Should the survey responses be anonymous?"
        toggledLabel="Survey responses will be kept anonymous"
        onToggle={this.handleToggleAnonymous}
      />
    );
  }
  renderExport() {
    return (
      this.props.survey?.id && (
        <OverviewItem
          title="Export survey responses"
          renderActions={() => (
            <Actions>
              {' '}
              <Button
                secondary
                label="Export"
                onClick={this.handleExportSurvey}
              />{' '}
            </Actions>
          )}
        />
      )
    );
  }

  renderDuplicate() {
    return (
      this.props.survey?.id && (
        <OverviewItem
          title="Duplicate survey"
          label="Questions and tags will be duplicated, but not the respondents or answers."
          renderActions={() => (
            <Actions>
              {canManageSurvey() && (
                <Button
                  secondary
                  size="m"
                  label="Duplicate survey"
                  onClick={this.handleDuplicatePopoverOpenRequest}
                />
              )}
              <PopoverLight
                header={
                  <span>
                    Click the destination project below you wish to send the
                    duplicate survey to:
                  </span>
                }
                anchorEl={this.state.anchorEl}
                open={this.state.popoverOpen}
                anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
                targetOrigin={{ horizontal: 'left', vertical: 'top' }}
                className={cls.duplicatePopover}
                renderChildren={this.renderOrganizationProjects}
                onRequestClose={this.handleDuplicatePopoverCloseRequest}
                duplicateSurvey
                style={{ transform: 'translateX(-160px)' }}
                customHeight={200}
              />
            </Actions>
          )}
        />
      )
    );
  }

  renderOrganizationProjects = () => (
    <PopoverLight.Menu>
      {this.props.projects.items.map((project) => (
        <PopoverLight.MenuItem
          key={project.name}
          value={project.name}
          primaryText={<span>{project.name}</span>}
          onClick={() => this.handleConfirmDuplicateSurvey(project)}
        />
      ))}
    </PopoverLight.Menu>
  );
  renderDelete() {
    return (
      canManageSurvey() &&
      this.props.survey?.id && (
        <OverviewItem
          title="Delete survey"
          renderActions={() => (
            <Actions>
              <Button
                alert
                secondary
                size="m"
                label="Delete survey"
                onClick={this.handleDeleteSurvey}
              />
            </Actions>
          )}
        />
      )
    );
  }

  render() {
    const { survey } = this.props;
    return (
      <div>
        <Section surveyWidth collapsed>
          <Warning
            compact
            id={survey.id}
            type="surveyDeadline"
            show={
              !!survey.id &&
              !!survey.deadline &&
              isBefore(survey.deadline, Date.now(), true)
            }
            text="Your survey end date has passed."
          />
          {this.renderTitle()}
          <LanguagesSetup
            completeness={getI18nCompleteness(survey)}
            languages={survey.languages}
            onChange={this.handleLanguagesChanged}
          />
          {this.renderShowBranding()}
          {this.renderShowQuestionCounter()}
          {this.renderDeadline()}
          {this.renderPublic()}
          {this.renderAnonymous()}
          {this.renderDuplicate()}
          {this.renderExport()}
          {this.renderDelete()}
        </Section>
        <SurveyEditFooter
          survey={survey}
          completeness={getI18nCompleteness(survey)}
          onSaveSurvey={this.handleSaveSurvey}
          onRenameSurvey={this.handleNameChange}
        />
        <ExportConfirmation
          open={this.state.exportModalShow}
          onRequestClose={this.handleCloseExportModal}
        />
        <Prompt
          when={survey.dirty}
          message="You have unsaved changes that will be lost. Are you sure you want to leave?"
        />
      </div>
    );
  }
}
