import classNames from 'classnames';
import React, { useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { Icon, IconType } from '../Icon';

import { hide } from './store.actions';
import { Notification, NotificationLevel } from './types';

import cls from './Notifications.module.css';

const NOTIFICATION_TIMEOUT = 6;

interface NotificationBlipProps extends Notification {
  floating?: boolean;
}

const iconForNotificationLevel: Record<NotificationLevel, IconType> = {
  error: 'warning-triangle',
  warning: 'warning-triangle',
  info: 'question-filled',
  success: 'check',
};

export const NotificationBlip: React.VFC<NotificationBlipProps> = ({
  title,
  message,
  icon,
  level,
  autoDismis = NOTIFICATION_TIMEOUT,
  floating,
  count,
  uid,
}) => {
  const dispatch = useDispatch();
  const timeoutRef = useRef<number | null>();

  useEffect(() => {
    if (autoDismis) {
      timeoutRef.current = window.setTimeout(() => {
        dispatch(hide(uid));
      }, autoDismis * 1000);
      return () => clearTimeout(timeoutRef.current);
    }
  }, [dispatch, autoDismis, uid]);

  const handleDismiss = useCallback(() => {
    dispatch(hide(uid));
  }, [dispatch, uid]);

  const notificationIconName = icon || iconForNotificationLevel[level];

  let countLabel = null;

  if (count > 1) {
    countLabel = count > 99 ? '99+' : `${count}`;
  }

  return (
    <div
      className={classNames(cls.notificationBlip, {
        [cls[level]]: level,
        [cls.floating]: floating,
        [cls.stack]: count > 1,
        [cls.stackMore]: count > 2,
        [cls.stackTens]: count > 9,
        [cls.stackHundreds]: count > 99,
      })}
    >
      {notificationIconName && <Icon name={notificationIconName} />}
      <div className={cls.body}>
        {title && <div className={cls.title}>{title}</div>}
        {message && <div className={cls.message}>{message}</div>}
      </div>
      <Icon name="close" className={cls.close} onClick={handleDismiss} />
      {countLabel && <div className={cls.counter}>{countLabel}</div>}
    </div>
  );
};
