import mergeDeepRight from 'ramda/src/mergeDeepRight';
import { getText } from 'src/services/DictionaryService';
import { formatValueLabel } from './formatters';
import { getAxisId } from '../../Surveys/Survey/Summary/surveyAnalysisUtils';
const subsetOf = (a, b) => a?.filter((el) => b?.includes(el)) || [];
const isNumeric = (v) => !isNaN(Number(v)) && isFinite(Number(v));
const parseValue = (value) => (isNumeric(value) ? Number(value) : 0);
const flatten = (a) => a?.values?.[0] ?? a;

const getAttributeValues = (axis, axisValues) =>
  (axis?.attribute_values || [])
    .reduce((acc, serie) => {
      const cat = subsetOf(
        serie?.values,
        axisValues?.[axis?.model]?.[
          axis?.attribute_name
        ]?.attribute_values?.find((el) => el?.category === serie?.category)
          ?.values
      );
      return cat?.length > 0 ? acc.concat([{ ...serie, values: cat }]) : acc;
    }, [])
    .concat(
      subsetOf(
        axis?.attribute_values,
        axisValues?.[axis?.model]?.[axis?.attribute_name]?.attribute_values
      )
    );

export const chartDataToState = (payload, axisValues) => ({
  name: payload.name,
  chartType: payload.options.chartType,
  csv: payload.options.csv,
  settings: payload.options.settings,
  calculationType: payload.options.calculationType || ['grantees_total'],
  xAxisTypeConfig: {
    ...payload.x,
    attribute_values: getAttributeValues(payload.x, axisValues),
  },
  yAxisTypeConfig: {
    ...payload.y,
    attribute_values: getAttributeValues(payload.y, axisValues),
  },
  xAxisAttrConfig: getAttributeValues(payload.x, axisValues),
  yAxisAttrConfig: getAttributeValues(payload.y, axisValues),
});

// by default we need to flip the series and categories, if it's Tag over single series then group by tag group rather than calculation type
export const transformChartToStacked = (
  config,
  meta,
  settings,
  _categories
) => {
  config.xAxis.categories = _categories;
  function pointFormatter() {
    return `<span style="color:${this.color}">\u25CF</span>${
      this.series.name
    }: <b>${formatValueLabel(this.y, this.series.valueType)}</b><br/>`;
  }

  if (meta.x.model === 'Tag' && config.series.length === 1) {
    const getYAxisId = () => {
      if (config.series[0]?.valueType === 'funding_total') return 'money';
      if (config.series[0]?.valueType?.includes('percentage'))
        return 'percentage';
      return undefined;
    };
    const categories = meta.x.attribute_values
      .map((val) => val.category)
      .reduce((arr, cat) => {
        if (arr.indexOf(cat) === -1) arr.push(cat);
        return arr;
      }, []);

    return mergeDeepRight(config, {
      categories,
      series: meta.x.attribute_values
        .map((item, valueIndex) => ({
          name: item.values?.[0] || item,
          category: item.category,
          data: [...Array(categories.length)].map((_, categoryIndex) =>
            categories.indexOf(item.category) === categoryIndex
              ? config.series[0]?.data[valueIndex]
              : 0
          ),
          stacking: settings.stackingPercent ? 'percent' : 'normal',
          visible: true,
          yAxis: getYAxisId(),
          valueType: config.series[0]?.valueType,
          tooltip: { pointFormatter },
        }))
        .slice(),
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            filter: settings.stackingPercent
              ? { property: 'percentage', operator: '>=', value: 0.5 }
              : { property: 'y', operator: '>', value: 0 },
            format: settings.stackingPercent
              ? '{point.percentage:,.0f}%'
              : null,
            formatter: function () {
              return this.y > 0
                ? formatValueLabel(
                    this.y,
                    this.point.series.userOptions?.valueType,
                    true,
                    this.point.series.chart.options.dataLabelSuffix
                  )
                : '';
            },
          },
        },
      },
    });
  }

  const series =
    _categories?.map((name) => ({
      name,
      data: [],
      stacking: settings.stackingPercent ? 'percent' : 'normal',
      visible: true,
    })) ?? [];

  config.series.forEach((serie) => {
    serie.data?.forEach((entry, index) => {
      if (!series?.[index]) return;
      series[index].data?.push(entry);
      series[index].yAxis = getAxisId(serie.valueType);
      series[index].valueType = serie.valueType;
      series[index].tooltip = { pointFormatter };
    });
  });

  return mergeDeepRight(config, {
    xAxis: { categories: config.series.map((serie) => serie.name).slice() },
    series: series.slice(),
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          filter: settings.stackingPercent
            ? { property: 'percentage', operator: '>=', value: 0.5 }
            : { property: 'y', operator: '>', value: 0 },
          format: settings.stackingPercent ? '{point.percentage:,.0f}%' : null,
          formatter: function () {
            return this.y > 0
              ? formatValueLabel(
                  this.y,
                  this.point.series.userOptions?.valueType,
                  true,
                  this.point.series.chart.options.dataLabelSuffix
                )
              : '';
          },
        },
      },
    },
  });
};

export const transformChartToHeatmap = (config, meta, series, categories) => {
  const yCategories = [
    ...new Set(meta.y.attribute_values.map((v) => flatten(v))),
  ].concat('');

  return mergeDeepRight(config, {
    type: 'heatmap',
    series: [
      {
        stacking: false,
        name: getText(series[0]?.valueType),
        valueType: series[0]?.valueType,
        data: series.reduce((acc, serie, y) => {
          serie.data?.forEach((z, x) => acc.push([x, y, z]));
          return acc;
        }, []),
      },
    ],
    xAxis: { categories: categories, max: categories.length - 1 },
    yAxis: { categories: yCategories, title: { text: null } },
    plotOptions: {
      heatmap: {
        dataLabels: {
          enabled: true,
          formatter: function () {
            return this.point.value > 0
              ? formatValueLabel(
                  this.point.value,
                  this.point.series.userOptions?.valueType,
                  true,
                  this.point.series.chart.options.dataLabelSuffix
                )
              : '';
          },
        },
        tooltip: {
          pointFormatter: function () {
            return `${this.series.xAxis.categories[this.x]}<br/> ${
              this.series.name == this.series.yAxis.categories[this.y]
                ? ``
                : `${this.series.yAxis.categories[this.y]}<br/>`
            }<b>${formatValueLabel(
              this.value,
              this.series.userOptions.valueType
            )}</b><br/>`;
          },
        },
      },
    },
    legend: { symbolWidth: 400 },
    colorAxis: {
      min: 0,
      stops: [
        [0, '#F9F9F9'],
        [1, '#3C8DC4'],
      ],
      labels: {
        formatter: function () {
          return formatValueLabel(
            this.value,
            this.chart.series[0]?.userOptions.valueType || 'any',
            true
          );
        },
      },
    },
  });
};

export const transformChartToBubble = (config, meta, categories) => {
  const yCategories = [
    ...new Set(meta.y.attribute_values.map((v) => flatten(v))),
  ].concat('');
  return mergeDeepRight(config, {
    type: 'bubble',
    series: [
      {
        stacking: false,
        name: getText(config.series[0]?.valueType),
        valueType: config.series[0]?.valueType,
        data: config.series.reduce((acc, serie, y) => {
          serie.data?.forEach((z, x) => {
            acc.push({ x, y, z });
          });
          return acc;
        }, []),
      },
    ],
    xAxis: {
      categories: categories,
      min: -1,
      showFirstLabel: true,
      max: categories.length - 1,
      type: 'category',
    },
    yAxis: {
      categories: yCategories,
      title: { text: null },
      crosshair: yCategories.length > 1,
      type: 'linear',
    },
    plotOptions: {
      bubble: {
        zMin: 0,
        minSize: '0',
        maxSize: '25%',
        dataLabels: {
          enabled: true,
          formatter: function () {
            return this.point.z > 0
              ? formatValueLabel(
                  this.point.z,
                  this.point.series.userOptions.valueType,
                  true,
                  this.point.series.chart.options.dataLabelSuffix
                )
              : '';
          },
        },
        tooltip: {
          pointFormatter: function () {
            const { series: p, x, y, z } = this;
            return `${
              p.name === p.yAxis.categories[y]
                ? `${p.xAxis.categories[x]}<br/>`
                : `${p.xAxis.categories[x]}<br/>${p.yAxis.categories[y]}<br/>`
            }<b>${formatValueLabel(z, p.userOptions.valueType)}</b><br/>`;
          },
        },
      },
    },
  });
};

export const transformCSVColumnsToValueType = (col, csv) =>
  col.length > 2 && csv.length === 1
    ? csv[0]?.[col[0]] || 'Count'
    : col[0]?.split('|')[2] || (col.length === 2 ? col[1] : 'Count');

export const transformCsvToChartData = (csv) => {
  const col = csv.columns || [];
  const isXY = col.length > 2;
  const attr = [...col].slice(1 - col.length) || [];
  const name = transformCSVColumnsToValueType(col, csv);
  const header = col[0];
  const xIndex = col.length > 1 ? 1 : 0;
  return {
    meta: {
      x: {
        model: 'CSV',
        attribute_name: header.split('|')[0],
        attribute_values: csv.map((row) => row[header]),
      },
      ...(isXY
        ? { y: { model: 'CSV', attribute_name: name, attribute_values: attr } }
        : {}),
    },
    payload: {
      data: csv.map((row) =>
        isXY
          ? attr.reduce(
              (obj, y) =>
                obj.concat({
                  [name]: parseValue(row[y]),
                  meta: { x: row[header], y: y },
                }),
              []
            )
          : {
              [name]: parseValue(row[col[xIndex]]),
              meta: { x: row[col[xIndex - 1]] },
            }
      ),
    },
  };
};
