import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import tagCategoriesSelectorApi from 'src/api/TagCategoriesSelector';
import tagCategoriesSelectorExpandedApi from 'src/api/TagCategoriesSelectorExpanded';
import IconSubItem from 'src/components/IconSubItem/IconSubItem';
import {
  getTaggingType,
  TAGGING_ICON_PER_TYPE,
  TAGGING_TYPE_READABLE,
} from 'src/components/TaggingType/taggingTypeHelper';
import {
  TagGroupList,
  TagListItem,
  TagGroupItem,
} from 'src/components/TagList';
import { containsText } from 'src/utils/string';
import { tagsToHighlights } from 'src/utils/tagsToHighlights';

import { where } from 'im/api/Query';
import { Icon } from 'im/ui/Icon';

import { applyColoringStrategy, COLORING_STRATEGIES } from './coloringStrategy';

import cls from './TagList.module.css';
const tagWithCode = (tag = {}) =>
  tag.code?.length > 0
    ? `${tag.code}${tag.code.endsWith('.') ? '' : '.'} ${tag.title}`
    : tag.title;

@connect(
  (state) => ({
    project: state.project,
    tagCategoriesSelectorList: state.tagCategoriesSelectorList,
    tagCategoriesSelectorExpanded: state.tagCategoriesSelectorExpanded,
  }),
  {
    getTagCategories: tagCategoriesSelectorApi.list.findAllPerProject,
    getTagCategoriesExpanded:
      tagCategoriesSelectorExpandedApi.findAllPerProjectNested,
  }
)
class TagList extends React.Component {
  static propTypes = {
    coloringStrategy: PropTypes.string,
    taggings: PropTypes.object,
    tagsInContext: PropTypes.array,
    showDefinitions: PropTypes.bool,
    showCodes: PropTypes.bool,
    project: PropTypes.object,
    tagCategories: PropTypes.array,
    tagCategoriesSelectorList: PropTypes.object,
    tagCategoriesSelectorExpanded: PropTypes.object,
    tagsRecent: PropTypes.array,
    filterText: PropTypes.string,
    tagCategorySelectedId: PropTypes.string,

    onTagEdit: PropTypes.func.isRequired,
    onTagHover: PropTypes.func.isRequired,
    onTagHoverOut: PropTypes.func.isRequired,
    onTaggingAdd: PropTypes.func,
    onTaggingHighlight: PropTypes.func,
    onTaggingRemove: PropTypes.func.isRequired,
    onTagGroupEdit: PropTypes.func.isRequired,
    getTagCategories: PropTypes.func.isRequired,
    getTagCategoriesExpanded: PropTypes.func.isRequired,
    editExpandedTagCategories: PropTypes.func,
  };

  static defaultProps = { tagsInContext: [], project: {} };

  componentDidMount() {
    this.doRequestTagCategories();
  }

  componentWillReceiveProps(np) {
    if (
      np.tagCategories !== this.props.tagCategories ||
      np.tagsRecent !== this.props.tagsRecent ||
      np.filterText !== this.props.filterText ||
      np.tagCategorySelectedId !== this.props.tagCategorySelectedId
    ) {
      this.doRequestTagCategories(np.filterText, {
        enabledTagCategories:
          np.tagCategorySelectedId || np.project.enabled_tag_categories,
        isForceTagSearch: np.filterText.startsWith('#'),
      });
    }
  }

  shouldComponentUpdate(np) {
    return (
      np.coloringStrategy !== this.props.coloringStrategy ||
      np.taggings.data !== this.props.taggings.data ||
      np.taggings !== this.props.taggings ||
      np.tagsInContext !== this.props.tagsInContext ||
      np.showDefinitions !== this.props.showDefinitions ||
      np.project !== this.props.project ||
      np.project.enabled_tag_categories !==
        this.props.project.enabled_tag_categories ||
      np.tagCategories !== this.props.tagCategories ||
      np.tagCategoriesSelectorList !== this.props.tagCategoriesSelectorList ||
      np.tagCategoriesSelectorList.data !==
        this.props.tagCategoriesSelectorList.data ||
      np.tagsRecent !== this.props.tagsRecent ||
      np.filterText !== this.props.filterText ||
      np.tagCategorySelectedId !== this.props.tagCategorySelectedId ||
      np.tagCategoriesSelectorExpanded !==
        this.props.tagCategoriesSelectorExpanded
    );
  }

  getTagCategoryColor = (tagCategoryId) => {
    const showTagGroupsColors =
      this.props.coloringStrategy == COLORING_STRATEGIES.TAG_GROUPS_UNIQUE;
    if (!showTagGroupsColors) return null;

    const { taggings, coloringStrategy, tagCategories } = this.props;
    const tagCategoriesTags = tagCategories.find(
      (item) => item.id == tagCategoryId
    )?.tags;
    const highlight = tagsToHighlights(taggings.data)
      .filter((h) => h.tags.length == 1)
      .find((h) =>
        tagCategoriesTags?.find(
          (tag) =>
            tag?.id ==
            taggings.data.find((tagging) => tagging.id == h.tags[0])?.tag?.id
        )
      );

    // if there's more than 1 then we have overlapping tags, and we want base color for tag group
    if (!highlight?.tags) return undefined;
    return applyColoringStrategy(coloringStrategy, highlight.tags, taggings);
  };

  doRequestTagCategories = (
    searchText,
    { isForceTagSearch, enabledTagCategories } = {}
  ) => {
    const projectTagCategoryIds =
      enabledTagCategories || this.props.project.enabled_tag_categories;
    if (
      projectTagCategoryIds &&
      Array.isArray(projectTagCategoryIds) &&
      !projectTagCategoryIds.length
    )
      return;
    const query = where({ projectTagCategoryIds })
      .fields('tag_category', 'title', 'tags', 'child_categories', 'code')
      .filter('parent_id_null', true)
      .fields('tag', 'title', 'code')
      .sort('title')
      .paginate({ size: 600 });
    if (searchText) {
      query
        .filter(
          isForceTagSearch
            ? 'nested_tags_title_in_any'
            : 'nested_categories_with_tags_title_in_any',
          searchText
        )
        .distinct();
    }
    this.props.getTagCategories(query);
  };

  handleTagGroupExpand = (tagGroup, isOpen) => {
    if (!isOpen) return;
    if (
      this.props.tagCategoriesSelectorExpanded.data
        .map((t) => t.id)
        .includes(tagGroup.id)
    )
      return;
    const expandedIds = this.props.tagCategoriesSelectorExpanded.data
      .map((t) => t.id)
      .concat(tagGroup.id);
    this.props.editExpandedTagCategories(expandedIds);
    this.props.getTagCategoriesExpanded(
      where({ tagCategoriesExpandedIds: expandedIds })
    );
  };

  renderTagCounter = (tag) => {
    const tagTaggings = this.props.taggings.data.filter(
      (t) => t.tag?.id == tag?.id
    );
    if (!tagTaggings.length) return null;
    const tagTaggingsByType = tagTaggings
      .map(getTaggingType)
      .reduce((acc, type) => ({ ...acc, [type]: (acc[type] || 0) + 1 }), {});

    return (
      <div className={cls.tagStatContainer}>
        {Object.entries(tagTaggingsByType).map(([type, counter]) => (
          <span
            key={type}
            className={cls.tagStatItem}
            data-qa="TagEditor:TagListTagStats-display"
          >
            <Icon
              name={TAGGING_ICON_PER_TYPE[type]}
              title={TAGGING_TYPE_READABLE[type]}
            />
            {counter}
          </span>
        ))}
      </div>
    );
  };

  // If tag has title means that the parent tag group was already expanded and tags underneath it should be displayed
  renderTagGroupItem = (group) => {
    const catsAndTagsFiltered = group.tags?.filter(
      (tag) =>
        containsText(group.title, this.props.filterText) ||
        containsText(tag.title, this.props.filterText) ||
        containsText(tag.code, this.props.filterText)
    );
    const hasChildren = group.child_categories?.length > 0;
    const lastLevel = !group.tags?.length && !hasChildren;
    // const isDefaultOpen = this.props.filterText.length > 0; // TO DO, open until last level if match
    return (
      <TagGroupItem
        key={group.id}
        tagGroup={group}
        leftIcon={lastLevel && <IconSubItem />}
        isOpen={false}
        color={this.getTagCategoryColor(group.id)}
        onTagGroupEdit={this.props.onTagGroupEdit}
        onTagGroupExpand={this.handleTagGroupExpand}
        showCodes={true}
      >
        {catsAndTagsFiltered
          ?.filter((tag) => tag.title)
          .sort((tag1, tag2) =>
            tagWithCode(tag1).localeCompare(tagWithCode(tag2), 'en', {
              numeric: this.props.showCodes,
            })
          )
          .map((tag) => (
            <TagListItem
              key={tag.id}
              data-scroll-index={tag.id}
              tag={tag}
              tagInContext={this.props.tagsInContext.find(
                (c) => c.tag?.id == tag.id
              )}
              showDefinitions={tag.description && this.props.showDefinitions}
              onTagClick={this.props.onTaggingAdd}
              onTaggingHighlight={this.props.onTaggingHighlight}
              onTagEdit={this.props.onTagEdit}
              onTagHover={
                this.renderTagCounter(tag) ? this.props.onTagHover : null
              }
              onTagHoverOut={
                this.renderTagCounter(tag) ? this.props.onTagHoverOut : null
              }
              onTaggingRemove={this.props.onTaggingRemove}
              renderExtra={this.renderTagCounter}
              showCodes={true}
            />
          ))}
        {hasChildren && this.renderTagGroupsList(group.child_categories)}
      </TagGroupItem>
    );
  };

  renderTagGroupsList = (tc) =>
    tc.map((c) => [
      this.renderTagGroupItem(
        this.props.tagCategoriesSelectorExpanded.data.find(
          (t) => t.id == c?.id
        ) || c
      ),
    ]);

  render() {
    return (
      <div className={cls.tagsListWrapper}>
        <TagGroupList
          pending={
            this.props.tagCategoriesSelectorList.pending.init ||
            this.props.tagCategoriesSelectorList.pending.ui
          }
        >
          {this.renderTagGroupsList(this.props.tagCategoriesSelectorList.data)}
        </TagGroupList>
      </div>
    );
  }
}

export default TagList;
