import {
  isUpdateQueryAction,
  isPendingStatusAction,
} from '../helpers/actionTypes';
import { handleUpdateQuery } from './reducerHandlers';

export default (action, state) => {
  if (isUpdateQueryAction(action.type)) {
    return handleUpdateQuery(action, state);
  }
  if (isPendingStatusAction(action.type)) {
    return {
      ...state,
      pending: { ...state.pending, ...action.payload.pending },
    };
  }
  return state;
};
