import React from 'react';
import classNames from 'classnames/bind';
import { T, withTranslators } from 'lioness';
import RadioButton from 'material-ui/RadioButton/RadioButton';
import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import { connect } from 'react-redux';
import {
  RadioButtonGroup,
  Tag,
  Container,
  CardFooter,
} from 'src/components/IMUI';
import { getSurveyI18n } from 'src/utils/surveysI18n';
import createQuestion from './createQuestion';
import cls from './OpinionScale.module.css';
import { TagNotProvided } from './TextQuestion';
import UploadAttachment from 'src/components/Survey/UploadAttachment';
const cx = classNames.bind(cls);

@createQuestion
@connect(pick(['survey', 'surveyAnswers']))
class OpinionScale extends React.PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.number,
    question: PropTypes.object,
    answer: PropTypes.object,
    index: PropTypes.number.isRequired,
    isReview: PropTypes.bool,
    readOnly: PropTypes.bool,
    isAnswerMode: PropTypes.bool,
    isEditing: PropTypes.bool,
    onRefresh: PropTypes.func,
    survey: PropTypes.object,
    surveyAnswers: PropTypes.object,
    tagsWithTagGroups: PropTypes.array,
    t: PropTypes.func,
  };

  state = {};

  static validate(question, answers) {
    if (question.required && typeof answers !== 'number') {
      return <T>Please select a valid answer</T>;
    }
    if (question.required && !answers) {
      return <T>Please select an answer</T>;
    }
    return null;
  }

  onChange = (event, value) => {
    const newValue = `${value}` ? Number(value) : event;
    const { question } = this.props;
    const tagIds = [];
    for (let i = 0; i < (question?.options || []).length; i += 1) {
      if (question.options[i].title === newValue) {
        tagIds.push(question.options[i].tag_id);
        break;
      }
    }
    this.props.onChange?.(Number(newValue), tagIds);
  };

  getTag = (tagId) =>
    this.props.tagsWithTagGroups.find((st) => st.id === tagId);

  renderOption = (optionValue, optionIndex) => {
    const { readOnly, value } = this.props;
    const { hoveredValue } = this.state;
    const isSelected = Number(value) === optionValue;
    const isFilled = hoveredValue === optionValue;

    return (
      <RadioButton
        className={cx('radio', 'opinionScaleOption', {
          opinionScaleOptionReadonly: readOnly,
          opinionScaleOptionEmpty: !isSelected,
          opinionScaleOptionFilled: isFilled,
          opinionScaleOptionSelected: isSelected,
        })}
        onMouseEnter={() => this.setState({ hoveredValue: optionValue })}
        onMouseOut={() => this.setState({ hoveredValue: null })}
        key={optionIndex}
        label={`${optionValue}`}
        value={optionValue}
      />
    );
  };
  // only in review mode, always read-only
  renderTags() {
    if (!this.props.isReview) return null;
    return (
      <CardFooter minimal className="printHidden">
        <Container horizontal>
          {!this.props.answer.tags.length && this.props.isEditing && (
            <TagNotProvided />
          )}
          {this.props.answer.tags
            .map((i) => this.getTag(i))
            .map(
              (tag) =>
                tag && (
                  <Tag
                    key={tag.id}
                    inline
                    label={tag.code || tag.title}
                    tip={`${(tag.tagGroup || {}).title} ${
                      tag.code ? tag.title : ''
                    }`}
                  />
                )
            )}
        </Container>
      </CardFooter>
    );
  }

  render() {
    const { index, value, readOnly, question, t } = this.props;

    const min = question.settings.minValue || 0;
    const max = question.settings.maxValue || 10;

    const minLabel = getSurveyI18n(
      this.props.surveyAnswers.info.id
        ? this.props.surveyAnswers
        : this.props.survey,
      question,
      ['settings', 'minLabel']
    );
    const midLabel = getSurveyI18n(
      this.props.surveyAnswers.info.id
        ? this.props.surveyAnswers
        : this.props.survey,
      question,
      ['settings', 'midLabel']
    );
    const maxLabel = getSurveyI18n(
      this.props.surveyAnswers.info.id
        ? this.props.surveyAnswers
        : this.props.survey,
      question,
      ['settings', 'maxLabel']
    );
    const options = [...Array(max - min + 1)];
    let scaleValue = value ?? 'PLEASE_RATE';

    return (
      <div
        className={classNames({
          [cls.opinionScaleSelectFallbackVisible]: options.length > 7,
        })}
      >
        <RadioButtonGroup
          name={`radio-group_${index}`}
          onChange={this.onChange}
          valueSelected={value}
          readOnly={readOnly}
          className={cls.opinionScaleGroup}
        >
          {options.map((_, i) => this.renderOption(i + min, i))}
        </RadioButtonGroup>

        <select
          className={classNames(cls.opinionScaleSelectFallback, {
            [cls.selectEmpty]: scaleValue === 'PLEASE_RATE',
          })}
          value={scaleValue}
          onChange={(ev) => this.onChange(ev, ev.target.value)}
        >
          <option disabled value="PLEASE_RATE">
            {' '}
            {t('Please rate')}{' '}
          </option>
          {minLabel && (
            <option disabled value="MIN_LABEL">
              {' '}
              {minLabel}{' '}
            </option>
          )}
          {options.map((_, i) => (
            <option key={i} value={i + min}>
              {i + min}
            </option>
          ))}
          {maxLabel && (
            <option disabled value="MAX_LABEL">
              {' '}
              {maxLabel}{' '}
            </option>
          )}
        </select>
        <div className={cls.opinionScaleDescription}>
          <div>{minLabel}</div>
          <div>{midLabel}</div>
          <div>{maxLabel}</div>
        </div>

        {this.renderTags()}

        {this.props.question.has_attachment && this.props.isAnswerMode && (
          <CardFooter noPadding className="printHidden">
            <UploadAttachment
              isReview={false}
              questionId={this.props.question.id}
              surveyAnswerId={this.props.answer.uid}
              importUpload={this.props.answer.import_upload}
              projectId={this.props.surveyAnswers.info?.projectId}
              granteeSurveyId={this.props.surveyAnswers.info?.grantee_survey_id}
              onRefresh={this.props.onRefresh}
            />
          </CardFooter>
        )}
      </div>
    );
  }
}

const enhancedComponent = withTranslators(OpinionScale);
enhancedComponent.validate = OpinionScale.validate;
export default enhancedComponent;
