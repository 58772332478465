import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import { connect } from 'react-redux';
import uploaderSecureApi from 'src/api/UploaderSecure';
import { Section, EmptyText } from 'src/components/IMUI';
import Page from 'src/components/Page/Page';
import SuccessfulScreen from 'src/components/SuccessfulScreen/SuccessfulScreen';
import { where } from 'im/api/Query';
import { Icon } from 'im/ui/Icon';
import UploadCSV from './Import/UploadCSV';
import cls from './Import/UploadCSV.module.css';
import { getOrgText } from 'src/services/DictionaryService';
const cx = classNames.bind(cls);

@connect(pick(['user', 'importUploads']), {
  getUploadedFiles: uploaderSecureApi.findAll,
  destroyFile: uploaderSecureApi.destroy,
})
export default class UploadCsvPage extends React.PureComponent {
  static propTypes = {
    user: PropTypes.object,
    importUploads: PropTypes.object,
    destroyFile: PropTypes.func.isRequired,
    getUploadedFiles: PropTypes.func.isRequired,
  };
  state = { finished: false };
  componentDidMount() {
    this.props.getUploadedFiles(
      where()
        .filter(
          'organization_id_eq',
          this.props.user.data.current_organization_id
        )
        .sort('-created_at')
    );
  }
  componentWillReceiveProps(nextProps) {
    if (
      this.props.importUploads.data.length &&
      nextProps.importUploads.data.length > this.props.importUploads.data.length
    ) {
      this.setState({ finished: true });
    }
  }
  handleUploadAnother = () => {
    this.setState({ finished: false });
  };
  handleFileDestroy = (fileId) => {
    this.props.destroyFile({ id: fileId });
  };

  renderConfirmation() {
    return (
      <Section noBorder className={cx(cls.sectionContent, cls.limitedWidth)}>
        <SuccessfulScreen
          title="File uploaded!"
          text={`Uploaded data requires further validation and processing. We will get in touch with you as soon as it is available in the system or if there are any issues we've noticed.`}
          successLabel="Go to home page"
          successRedirectPath="/analysis"
          altText="Or upload another file"
          altAction={this.handleUploadAnother}
        />
      </Section>
    );
  }
  renderProgress() {
    return (
      <div>
        <UploadCSV
          title={getOrgText('Upload grant, grantee and fundings data')}
        />
        <Section noBorder className={cx(cls.sectionContent, cls.limitedWidth)}>
          <div>
            <h3 className={cls.importUploadsTitle}>Uploaded files:</h3>
            {!this.props.importUploads.data.length ? (
              <EmptyText text="No files" />
            ) : (
              <ol className={cls.importUploads}>
                {this.props.importUploads.data.map(({ id, file_name }) => (
                  <li key={id}>
                    <span>{file_name}</span>
                    <Icon
                      name="close"
                      className={cls.importUploadsItemIcon}
                      onClick={() => this.handleFileDestroy(id)}
                    />
                  </li>
                ))}
              </ol>
            )}
          </div>
        </Section>
      </div>
    );
  }

  render() {
    return (
      <Page hasFooter back title="Upload CSV">
        {this.state.finished
          ? this.renderConfirmation()
          : this.renderProgress()}
      </Page>
    );
  }
}
