const ATTRS = {
  tagCategory: {
    id: 'tagCategoryId',
    title: 'tagCategoryTitle',
    metatags: 'tagCategoryMetatags',
  },
  tag: { id: 'tagId', title: 'tagTitle' },
  tagging: { label: 'taggingLabel', count: 'taggingCount' },
  grantee: { count: 'granteeCount' },
  grant: { count: 'grantCount' },
  quantity: {
    max: 'quantityMax',
    min: 'quantityMin',
    sum: 'quantitySum',
    avg: 'quantityAvg',
  },
};

const ATTR_TO_COLUMN = {
  im_tag_categories_id: ATTRS.tagCategory.id,
  im_tag_categories_title: ATTRS.tagCategory.title,
  im_tag_categories_metatags: ATTRS.tagCategory.metatags,
  im_taggings_tag_id: ATTRS.tag.id,
  tags_im_taggings_title: ATTRS.tag.title,
  im_tags_title: ATTRS.tag.title,
  id_count: ATTRS.tagging.count,
  quantity_max: ATTRS.quantity.max,
  quantity_min: ATTRS.quantity.min,
  quantity_sum: ATTRS.quantity.sum,
  quantity_avg: ATTRS.quantity.avg,
  im_taggings_label: ATTRS.tagging.label,
  report_grantee_name_count: ATTRS.grantee.count,
  report_grant_name_count: ATTRS.grant.count,
};

const getColumnsAttrs = (columns = []) => columns.map((c) => ATTR_TO_COLUMN[c]);
const getRowAttr = (columns, row, attr) =>
  row[getColumnsAttrs(columns)?.indexOf(attr)];

const findRowAttr = (calculation, tagCategoryId, attrId, attr) => {
  const dataOrder = getColumnsAttrs(calculation.columns);
  const idIndex = dataOrder.indexOf(attrId);
  const specificRow = calculation.rows?.find(
    (r) => r[idIndex] === tagCategoryId
  );
  if (!specificRow) return null;
  return getRowAttr(calculation.columns, specificRow, attr);
};

const getTagCategoryTaggingsCount = (calculation = {}, tagCategoryId) =>
  findRowAttr(
    calculation,
    tagCategoryId,
    ATTRS.tagCategory.id,
    ATTRS.tagging.count
  );
const getTagCategoryEntitiesCount = (
  calculation = {},
  tagCategoryId,
  entityName = ''
) =>
  findRowAttr(
    calculation,
    tagCategoryId,
    ATTRS.tagCategory.id,
    ATTRS[entityName].count
  );
const getTagEntitiesCount = (calculation = {}, tagId, entityName = '') =>
  findRowAttr(calculation, tagId, ATTRS.tag.id, ATTRS[entityName].count);

export {
  ATTRS as CALCULATION_ATTRS,
  getTagCategoryTaggingsCount,
  getTagCategoryEntitiesCount,
  getTagEntitiesCount,
  getRowAttr,
};
