import * as Sentry from '@sentry/browser';

const IS_ENABLED =
  !!Sentry &&
  process.env.NODE_ENV === 'production' &&
  process.env.IMPACTMAPPER_API_SERVER?.includes('//api-prod.impactmapper.com');
const SENTRY_DSN = 'https://e5a887055c4b4a5782aaa29556e61fc0@sentry.io/146554';

const updateScope = (fn) => Sentry.configureScope(fn);

export default {
  init: (params) =>
    IS_ENABLED &&
    Sentry.init({
      dsn: SENTRY_DSN,
      autoSessionTracking: false,
      ignoreErrors: ['Non-Error promise rejection captured'],
      ...params,
    }),
  setUser: (params) =>
    IS_ENABLED && updateScope((scope) => scope.setUser(params)),
  setOrganization: (organizationId, organizationName) =>
    IS_ENABLED &&
    updateScope((scope) => {
      scope.setExtra('organization_id', organizationId);
      scope.setExtra('organization_name', organizationName);
    }),
  setUserRole: (role) =>
    IS_ENABLED && updateScope((scope) => scope.setTag('user_role', role)),
  addBreadcrumb: (params) => IS_ENABLED && Sentry.addBreadcrumb(params),
  clear: () => IS_ENABLED && updateScope((scope) => scope.clear()),
};

export const sentryMiddleware = () => (next) => (action) => {
  IS_ENABLED &&
    Sentry.addBreadcrumb({
      message: action?.type,
      category: 'redux action',
      level: 'info',
      data: { payload: action?.payload || {} },
    });
  return next(action);
};
