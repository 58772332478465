import PropTypes from 'prop-types';
import React from 'react';

import { autopopulationTriggers } from 'src/data/matchers';

const withAutopopulation = (WrappedComponent) => {
  class FilterAutopopulation extends React.Component {
    static propTypes = {
      ...WrappedComponent.propTypes,

      configAutopopulation: PropTypes.object,
    };

    static defaultProps = {
      configAutopopulation: {},
    };

    constructor(props) {
      super(props);

      const params = [
        props.configAutopopulation,
        (props.currentItem || {}).name,
      ];
      const autopopulationEnabled = this.hasAutopopulationEnabled(...params);
      this.state = {
        ...(autopopulationEnabled ? this.setAutopopulationData(...params) : {}),
        showAutopopulation:
          autopopulationEnabled &&
          autopopulationTriggers.includes(props.matcher),
      };
    }

    componentWillReceiveProps(nextProps) {
      const {
        configAutopopulation: newConfigAutopopulation,
        currentItem: newCurrentItem,
        matcher: newMatcher,
      } = nextProps;
      const { configAutopopulation, matcher } = this.props;

      if (
        newConfigAutopopulation.resource !== configAutopopulation.resource ||
        newMatcher !== matcher
      ) {
        const params = [newConfigAutopopulation, (newCurrentItem || {}).name];

        if (
          !this.hasAutopopulationEnabled(...params) ||
          !autopopulationTriggers.includes(newMatcher)
        ) {
          this.setState({ showAutopopulation: false });
          return;
        }

        this.setState({
          ...this.setAutopopulationData(...params),
          showAutopopulation: true,
        });
      }
    }

    setAutopopulationData(configAutopopulation, filterKey) {
      const { resource } = configAutopopulation;
      const config = configAutopopulation[filterKey] || {};

      const values = resource.data[filterKey] || [];

      return {
        config,
        values: !config.transformValues
          ? values
          : config.transformValues(values),
        pending: resource.pending.init || resource.pending.ui,
        hasMore: this.hasMore(resource.links[filterKey]),
        onRequestOptions:
          config.onRequestOptions || configAutopopulation.onRequestOptions,
      };
    }

    hasAutopopulationEnabled(configAutopopulation, filterKey) {
      return (
        !!filterKey && (configAutopopulation.allow || []).includes(filterKey)
      );
    }

    hasMore(links) {
      const meta = (links || {}).meta || {};
      return meta.last > meta.first;
    }

    render() {
      const { currentItem, ...rest } = this.props;
      const {
        values,
        pending,
        hasMore,
        config,
        onRequestOptions,
        showAutopopulation,
      } = this.state;

      return (
        <WrappedComponent
          {...rest}
          currentItem={currentItem}
          autopopulation={
            !showAutopopulation
              ? undefined
              : {
                  values,
                  pending,
                  hasMore,
                  config,
                  onRequestOptions,
                }
          }
        />
      );
    }
  }

  return FilterAutopopulation;
};

export default withAutopopulation;
