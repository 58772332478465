import PropTypes from 'prop-types';
import React from 'react';

import { SelectPopoverLight } from 'src/components/IMUI';
import { Label, Error } from 'src/components/IMUI/Forms/base';
import debounce from 'src/utils/debounce';

import cls from './SelectAutocomplete.module.css';

// const cx = classNames.bind(cls);

// TODO: Add more generic component for all autocomplete usecases
export default class SelectAutocomplete extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    error: PropTypes.string,
    selectedItems: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    storeEntity: PropTypes.object,
    onSelect: PropTypes.func.isRequired,
    onRequestOptions: PropTypes.func.isRequired,
  };

  getAutocompleteOptions = (apiOptions = []) => {
    const emptyOption = { name: '-', id: null };
    return [emptyOption, ...apiOptions];
  };

  LABEL_HINT = 'Choose';

  handleSearchTextChange = debounce((searchText) => {
    this.props.onRequestOptions(searchText);
  }, 300);

  renderSelected = (entityId, apiOptions = []) => {
    if (!entityId) return null;
    const selected = (
      apiOptions.find(({ id }) => id === entityId.toString()) || {}
    ).name;

    return !selected ? (
      this.LABEL_HINT
    ) : (
      <span className={cls.autocompleteSelectedOption}>{selected}</span>
    );
  };

  render() {
    const { name, selectedItems, storeEntity, onSelect, label, error } =
      this.props;

    const pending = storeEntity.pending.init || storeEntity.pending.ui;
    const hasMoreItems =
      (storeEntity.links.meta || {}).last >
      (storeEntity.links.meta || {}).first;
    const items = storeEntity.data.name;

    return (
      <div>
        <Label label={label} />
        <SelectPopoverLight
          fullWidth
          name={name}
          pending={!!pending}
          hasMoreItems={hasMoreItems}
          labelHint={this.LABEL_HINT}
          selectedItems={selectedItems}
          items={this.getAutocompleteOptions(items)}
          itemsConfig={{ value: 'id', text: 'name' }}
          renderSelected={(val) => this.renderSelected(val, items)}
          onSelect={(val) => onSelect(name, val)}
          popoverProps={{
            zIndex: 1500,
            onSearchTextChange: this.handleSearchTextChange,
            searchHint: hasMoreItems ? 'Type in to get more results' : 'Search',
          }}
        />
        <Error error={error} />
      </div>
    );
  }
}
