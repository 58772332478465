import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { submit, reset } from 'redux-form';

import tagCategoryTagsApi from 'src/api/TagCategoryTags';
import tagsApi from 'src/api/Tags';
import { Label } from 'src/components/IMUI/Forms/base';
import { Button, Container, Actions, Drawer } from 'src/components/IMUI/index';

import { where } from 'im/api/Query';

import TagForm from './TagForm';
import TagItemReview from './TagItemReview';

import cls from './CreateTag.module.css';
import { canCreateTag, surveyOnly } from 'src/userStorage';

// const cx = classNames.bind(cls);

@connect(
  (state) => ({
    project: state.project,
    organizationCurrent: state.organizationCurrent,
  }),
  {
    createTag: tagsApi.ofProject.create,
    addTagToCategory: tagCategoryTagsApi.create,
    submitForm: submit,
    resetForm: reset,
  }
)
export default class CreateTag extends Component {
  static propTypes = {
    open: PropTypes.bool,
    defaultMode: PropTypes.string,
    organizationCurrent: PropTypes.object,
    initialValues: PropTypes.object,
    noSync: PropTypes.bool,

    createTag: PropTypes.func.isRequired,
    addTagToCategory: PropTypes.func.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    onSuccessfulAdd: PropTypes.func,
    onTagEdit: PropTypes.func,
    submitForm: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
  };

  static defaultProps = {
    defaultMode: 'quick',
  };

  static FORM_NAME = 'createTagForm';

  constructor(props) {
    super(props);
    this.state = {
      mode: props.defaultMode,
      reviewMode: false,
      tags: null,
      tagGroup: null,
      addTagFailed: false,
      addTagToTagCategoryFailed: false,
    };
  }

  handleRequestClose = () => {
    this.setState({ reviewMode: false, tags: null });
    this.props.onRequestClose();
  };

  handleSubmit = async ({ category, tags, tagNames }) => {
    const { mode } = this.state;

    const createdTags = await Promise.all(
      (tags || tagNames).map((t) => {
        const tag = typeof t === 'string' ? { title: t } : t;
        return this.createTag(tag);
      })
    );
    if (createdTags.filter((v) => !v).length)
      return this.setState({ addTagFailed: true });

    // Handle adding tag to a tagGroup
    const addedToCategory = await Promise.all(
      createdTags.map((tag) => this.addTagToCategory(tag.data.id, category.id))
    );
    if (addedToCategory.filter((v) => !v).length) {
      return this.setState({
        addTagFailed: false,
        addTagToTagCategoryFailed: true,
      });
    }

    const createdTagsParsed = createdTags.map((ct) => ct.data);
    if (this.props.onSuccessfulAdd)
      this.props.onSuccessfulAdd(createdTagsParsed);

    return mode === 'quick'
      ? this.props.onRequestClose(createdTagsParsed)
      : this.setState({
          reviewMode: true,
          tags: createdTagsParsed,
          tagGroup: category,
          addTagFailed: false,
          addTagToTagCategoryFailed: false,
        });
  };

  addTagToCategory = (tagId, tagCategoryId) => {
    const { noSync } = this.props;

    const query = where()
      .payload({
        relationships: [
          { relName: 'tag', type: 'tags', id: tagId },
          {
            relName: 'tag_category',
            type: 'tag_categories',
            id: tagCategoryId,
          },
        ],
      })
      .actionMeta({ noSync });

    return this.props.addTagToCategory(query);
  };

  createTag = ({ title, description, code }) => {
    const { organizationCurrent, noSync } = this.props;

    const relationship = {
      relName: 'organization',
      type: 'organizations',
      id: organizationCurrent.data.id,
    };
    const query = where()
      .payload({
        title,
        description: description || undefined,
        code: code || undefined,
        relationships: relationship,
      })
      .actionMeta({ noSync });

    return this.props.createTag(query);
  };

  handleRequestCreateTag = () => {
    this.props.submitForm(CreateTag.FORM_NAME);
  };

  handleAddAnother = () => {
    this.setState({ reviewMode: false, tags: null });
    this.props.resetForm(CreateTag.FORM_NAME);
  };

  handleModeChange = (mode) => {
    this.setState({ mode });
  };

  renderFooter = () => {
    const { reviewMode } = this.state;

    return (
      <Container horizontal>
        <Actions>
          <Button
            negative
            size="l"
            label={reviewMode ? 'Close' : 'Cancel'}
            onClick={this.handleRequestClose}
          />
        </Actions>
        <Actions>
          {reviewMode ? (
            <Button
              disabled={!canCreateTag()}
              size="l"
              label="Create another tag"
              onClick={this.handleAddAnother}
            />
          ) : (
            <Button
              disabled={!canCreateTag()}
              size="l"
              label="Create tags"
              onClick={this.handleRequestCreateTag}
            />
          )}
        </Actions>
      </Container>
    );
  };

  renderForm = () => {
    const { initialValues } = this.props;
    const { mode, addTagFailed, addTagToTagCategoryFailed } = this.state;

    return (
      <Container>
        <h3>Create a tag</h3>
        <Container>
          <TagForm
            mode={mode}
            initialValues={initialValues}
            form={CreateTag.FORM_NAME}
            showTagErrors={addTagFailed}
            showTagCategoryTagsErrors={addTagToTagCategoryFailed}
            onSubmit={this.handleSubmit}
            onModeChange={this.handleModeChange}
          />
        </Container>
        {!surveyOnly() && (
          <h4 className={cls.formExtraInfo}>
            To include previously created tags&#44; visit the&#160;
            <b>Active Tags</b> section of the project&apos;s&nbsp;
            <b>Settings</b>&nbsp;page
          </h4>
        )}
      </Container>
    );
  };

  renderReview = () => {
    const { tags, tagGroup } = this.state;

    return (
      <div>
        <h3>Tag{tags.length > 1 ? 's' : ''} created</h3>
        <Container className={cls.reviewItem}>
          <Label label="Tag group" />
          <strong>{tagGroup.title}</strong>
        </Container>
        {tags.map((t) => (
          <TagItemReview
            key={t.id}
            tag={t}
            onEdit={() => this.props.onTagEdit(t.id)}
          />
        ))}
      </div>
    );
  };

  render() {
    const { open } = this.props;
    const { reviewMode } = this.state;

    return (
      <Drawer
        closable
        open={open}
        width={580}
        docked={true}
        renderFooter={this.renderFooter}
        onRequestClose={this.handleRequestClose}
      >
        {reviewMode ? this.renderReview() : this.renderForm()}
      </Drawer>
    );
  }
}
