/* eslint camelcase: 0 */
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import uploaderSecureMappingApi from 'src/api/UploaderSecureMapping';
import { Button, Section, Actions } from 'src/components/IMUI/index';
import history from 'src/historyInstance';
import { where } from 'im/api/Query';
import defaultColumns from './components/grantReadableOptions.json';
import MappingForm from './components/MappingForm';
import MappingHeader from './components/MappingHeader';
import MappingStatsBar from './components/MappingStatsBar';
import cls from './Map.module.css';
import uniq from 'ramda/src/uniq';
import { isCustomField, isMapped } from './components/MappingItem';
import { getOrgText } from 'src/services/DictionaryService';
import { canManageGrants } from 'src/userStorage';

const customizeField = (_fieldName) => {
  const fieldName = Array.isArray(_fieldName) ? _fieldName.join() : _fieldName;
  if (typeof fieldName != 'string') return 'CUSTOM:organization';
  return fieldName?.startsWith('CUSTOM:') ? fieldName : `CUSTOM:${fieldName}`;
};
const safeMap = (arr) => (Array.isArray(arr) ? arr : []);

const compareTexts = (tA, tB, useDict = false) =>
  tA?.length > 0 &&
  tB?.length > 0 &&
  (tA.trim().toLocaleLowerCase() === tB.trim().toLocaleLowerCase() ||
    (useDict &&
      getOrgText(tA).trim().toLocaleLowerCase() ===
        getOrgText(tB).trim().toLocaleLowerCase()));

@connect(
  (state) => ({
    organizationCurrent: state.organizationCurrent,
    importUploadMapping: state.importUploadMapping,
  }),
  {
    getFile: uploaderSecureMappingApi.find,
    updateFile: uploaderSecureMappingApi.put,
    submitForm: submit,
  }
)
export default class Map extends React.PureComponent {
  static propTypes = {
    getFile: PropTypes.func,
    updateFile: PropTypes.func,
    submitForm: PropTypes.func,
    match: PropTypes.object,
    organizationCurrent: PropTypes.object,
    importUploadMapping: PropTypes.object,
  };

  componentDidMount() {
    this.props.getFile({ id: this.props.match.params.fileId });
  }

  onCancelClick = () => {
    history.push('/grants/import');
  };

  onContinueClick = () => {
    this.props.submitForm('grantMappingForm');
  };

  buildInternal = (formColumns, type) => {
    const fields = Object.entries(formColumns)
      .filter(([_, v]) => !v.customField && v.column && v.headerName === type)
      .map(([k, v]) => ({ imColumn: v.imColumn, csvColumn: k }));
    const intern = Object.fromEntries(
      uniq(fields.map((i) => i.imColumn)).map((im) => [
        im,
        fields.filter((f) => f.imColumn === im).map((i) => i.csvColumn),
      ])
    );
    return intern;
  };

  buildCustom = (formColumns, type) => {
    const appendCustom = (fields) =>
      fields?.length > 0 ? { custom_fields: fields } : {};
    const filterCustom = (t, headerName) =>
      Boolean(isCustomField(t) && isMapped(t) && t.headerName === headerName);
    return appendCustom(
      Object.entries(formColumns)
        .filter(([_r, t]) => filterCustom(t, type))
        .map(([row, a]) => ({ [row]: a.customField }))
    );
  };

  onSubmit = (form) => {
    const granteeType =
      form.static.grantee_headers?.type ??
      form.static.grant_headers?.recipient_type;

    const internGrant = this.buildInternal(form.columns, 'grant_headers');
    const internFunding = this.buildInternal(form.columns, 'funding_headers');
    const internGrantee = this.buildInternal(form.columns, 'grantee_headers');

    const customGrant = this.buildCustom(form.columns, 'grant_headers');
    const customFunding = this.buildCustom(form.columns, 'funding_headers');
    const customGrantee = this.buildCustom(form.columns, 'grantee_headers');

    const staticGrant = {
      recipient_type: [granteeType],
    };

    const staticGrantee = {
      type: [granteeType],
    };

    const staticFunding = {
      ...(internGrant.name ? { grant: internGrant.name } : {}),
      ...(internGrantee.name ? { grantee: internGrantee.name } : {}),
    };

    const payload = {
      grant_headers: { ...staticGrant, ...internGrant, ...customGrant },
      funding_headers: { ...staticFunding, ...internFunding, ...customFunding },
      grantee_headers: { ...staticGrantee, ...internGrantee, ...customGrantee },
    };

    const query = where({ id: this.props.match.params.fileId }).payload(
      payload
    );
    this.props.updateFile(query);
    history.push(`/grants/import/${this.props.match.params.fileId}/review`);
  };

  get granteeType() {
    return customizeField(
      this.props.importUploadMapping.data?.grantee_headers?.type ??
        'organization'
    );
  }

  getCustomColumns() {
    const { grant_headers, grantee_headers } =
      this.props.importUploadMapping.data ?? {};
    const { custom_grant_fields, custom_grantee_fields } =
      this.props.organizationCurrent?.data ?? {};
    const buildCustom = (headerName, key, field) => ({
      headerName,
      imColumn: `CUSTOM:${key}`,
      customField: field,
      readableColumn: `Custom field: ${field}`,
    });

    return uniq([
      safeMap(grant_headers?.custom_fields).flatMap((obj) =>
        Object.entries(obj).map(([key, field]) =>
          buildCustom('grant_headers', key, field)
        )
      ),
      safeMap(grantee_headers?.custom_fields).flatMap((obj) =>
        Object.entries(obj).map(([key, field]) =>
          buildCustom('grantee_headers', key, field)
        )
      ),
      safeMap(custom_grant_fields).map((field) =>
        buildCustom('grant_headers', field, field)
      ),
      safeMap(custom_grantee_fields).map((field) =>
        buildCustom('grantee_headers', field, field)
      ),
    ])
      .flatMap((i) => i)
      .filter(Boolean);
  }

  filteredColumns() {
    const csvColumns = this.props.importUploadMapping.data.csv_columns ?? [];
    const allColumns = [
      ...defaultColumns.grant_columns,
      ...defaultColumns.funding_columns,
      ...defaultColumns.grantee_columns,
      ...(this.granteeType.includes('individual')
        ? defaultColumns.individual_grantee_columns
        : []),
      ...this.getCustomColumns(),
    ];

    const matchColumn = (item) => {
      const customMatch = csvColumns.find((col) =>
        compareTexts(col, item?.customField)
      );
      if (customMatch)
        return { ...item, key: customMatch, column: item.customField };
      const normalMatch = csvColumns.find((col) =>
        compareTexts(col, item?.readableColumn, true)
      );
      if (normalMatch)
        return { ...item, key: normalMatch, column: item.readableColumn };
      return item;
    };

    return uniq(allColumns).map(matchColumn);
  }

  getInitialValues() {
    const getEntryKey = (entry) =>
      entry.key ?? entry.column ?? entry.customField ?? entry.readableColumn;
    const objectsGroupedByColumn = Object.fromEntries(
      this.filteredColumns().map((entry) => [getEntryKey(entry), entry])
    );
    return {
      columns: objectsGroupedByColumn,
      static: {
        grantee_headers: { type: this.granteeType },
        grant_headers: { recipient_type: this.granteeType },
      },
    };
  }

  render() {
    const { funding_headers, grant_headers, grantee_headers, csv_columns } =
      this.props.importUploadMapping.data;
    const initialValues = this.getInitialValues();
    const key = Object.keys(initialValues?.columns ?? {})?.join() ?? 'default';

    return (
      <div>
        <Section noBorder className={cls.limitedWidth}>
          <h3 className={cls.titleFirst}>Map imported columns</h3>
          <MappingStatsBar csvColumns={csv_columns} />
          <MappingHeader />

          <MappingForm
            key={key}
            isEdit={!!funding_headers || !!grant_headers || !!grantee_headers}
            csvColumns={csv_columns}
            initialValues={initialValues}
            onSubmit={this.onSubmit}
          />
        </Section>

        <Section type="sticky-footer">
          <Actions>
            <Button
              negative
              size="l"
              label="Cancel"
              onClick={this.onCancelClick}
            />
          </Actions>
          <Actions>
            <Button
              disabled={!canManageGrants()}
              size="l"
              label="Continue"
              onClick={this.onContinueClick}
            />
          </Actions>
        </Section>
      </div>
    );
  }
}
