import Highcharts from 'highcharts';
export const HIGHCHARTS_COLOURS = [
  '#57E2E5',
  '#45CB85',
  '#2374AB',
  '#7f82ec',
  '#c72444',
  '#f35a7f',
  '#4DCCBD',
  '#9067C6',
  '#F79D5C',
  '#434348',
  '#CAC4CE',
  '#E08DAC',
  '#D5A021',
  '#8def77',
  '#6CCFF6',
  '#C1BDDB',
  '#93B5C6',
  '#7ab4ee',
  '#F3E37C',
  '#F3752B',
  '#249090',
  '#F2F3AE',
  '#EDD382',
  '#FC9E4F',
  '#f65a57',
  '#540D6E',
  '#FFD23F',
  '#F3FCF0',
  '#8ee8e1',
];
export const OPTIONS = ['png', 'jpeg', 'pdf', 'svg', 'csv'];
export const CHART_LABELS = {
  reports: 'Reports/Print',
  presentation: 'Presentation/Display',
  web: 'Web/screen',
};
export const CHART_DEFAULTS = {
  reports: { width: 2880, fileFormat: 'png', aspectRatio: '4×3', fontScale: 1 },
  presentation: {
    width: 1920,
    fileFormat: 'png',
    aspectRatio: '16×9',
    fontScale: 2,
  },
  web: { width: 1440, fileFormat: 'png', aspectRatio: '16×9', fontScale: 1.75 },
};
export const IMAGETYPES = {
  png: 'image/png',
  jpeg: 'image/jpeg',
  svg: 'image/svg+xml',
  pdf: 'application/pdf',
};
export const REFERENCE_RATIO = 16 / 9;
export const REFERENCE_HEIGHT = 546;
export const EMPTY_TITLE = null;
export const COLORS = {
  light: {
    backgroundColor: '#ffffff',
    legendFontColor: '#2e2f40',
    lineColor: '#e3e9ee',
    labelColor: '#282f42',
    labelsStrokeColor: '#ffffff',
  },
  dark: {
    backgroundColor: '#000000',
    legendFontColor: '#eeeeee',
    lineColor: '#7c7c7c',
    labelColor: '#ffffff',
    labelsStrokeColor: '#000000',
  },
};
export const SETTINGS_TABS = [
  { id: 'general', label: 'General' },
  { id: 'labels', label: 'Labels' },
  { id: 'colours', label: 'Colours' },
  { id: 'export', label: 'Export' },
];
export const DEFAULT_CHART_SETTINGS = {
  xAxis: [],
  yAxis: [],
  showDataLabels: true,
  showTitleLabels: false,
  hideEmpty: false,
  hideLegend: false,
  hideXAxis: false,
  hideYAxis: false,
  hideTagGroupLabel: true,
  dataLabelSuffix: '',
  dataLabelPrefix: '',
  stackChartSeries: false,
  stackingPercent: false,
  allowDataLabelOverlap: false,
  dataLabelIntoPercentByCategory: false,
  dataLabelIntoPercentBySeries: false,
  dataLabelIntoPercentByGrandTotal: false,
  showOriginalValueWithPercents: false,
  allowDecimals: false,
  labelNumbersOfDecimals: 0,
  rawValueLabelNumbersOfDecimals: 0,
  colorByPoint: false,
  markersHidden: undefined,
  palette: [...Array(16).map(() => '')],
  switchRowsAndColumns: false,
  xAxisReversed: false,
  dataSorting: false,
  yAxisMin: null,
  yAxisMax: null,
  xAxisMax: null,
  xAxisMin: null,
  xAxisLabelRotation: 0,
  dataLabelRotation: 0,
  useExponentialForRawValue: false,
  firstRunEstimateDone: false,
  withoutPercentChartType: false,
  largeNumberNotationSuffix: '',
};

const isNumeric = (v) => v != null && !isNaN(Number(v)) && isFinite(Number(v));
const isEmptyString = (input) => !input?.length;
const hideAxis = (axis) => {
  if (Array.isArray(axis)) {
    return axis.map((a) => Highcharts.merge(a, { visible: false }));
  }
  return { visible: false };
};
const updateAxisTitle = (axis, settings) =>
  Array.isArray(axis)
    ? axis.map((a, index) =>
        Highcharts.merge(a, {
          visible: true,
          title: {
            text: isEmptyString(settings[index])
              ? EMPTY_TITLE
              : settings[index] ||
                (index === 0 && path(['title', 'text'], a)) ||
                path([index, 'title', 'text'], a),
          },
        })
      )
    : {
        visible: true,
        title: { text: isEmptyString(settings[0]) ? EMPTY_TITLE : settings[0] },
      };

export const buildFinalChartSettings = (
  config,
  settings = DEFAULT_CHART_SETTINGS,
  useDarkTheme = false
) => {
  const newChart = Highcharts.merge({}, config);
  const newOptions = {
    legend: { enabled: config?.legend?.enabled || !settings.hideLegend },
    dataLabelSuffix: settings.dataLabelSuffix,
    colors: settings.palette
      ? HIGHCHARTS_COLOURS.slice(0, settings.palette.length).map(
          (colour, index) => settings.palette[index] || colour
        )
      : {},
  };
  const color = COLORS[useDarkTheme ? 'dark' : 'light'];
  const crosshair = Boolean(
    newChart.series?.length > 0 ||
      newChart.series?.[0]?.length > 0 ||
      newChart.series?.[0]?.data?.length > 0
  );

  const dataLabelPercentOfSeriesTotalFormatter = (
    seriesTotals,
    {
      decimals = 0,
      rawValueLabelNumbersOfDecimals = 0,
      showOriginalValue = false,
      useExponentialForRawValue = false,
      dataLabelSuffix = '',
      dataLabelPrefix = '',
    } = {}
  ) =>
    function () {
      const total = seriesTotals[this.series.name];
      const percentage = total !== undefined ? (this.y / total) * 100 : 0;
      const percentFormatter = new Intl.NumberFormat(undefined, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
      });
      const formattedPercentage = percentFormatter.format(percentage);

      const formattedValue = showOriginalValue
        ? useExponentialForRawValue
          ? this.y.toExponential(rawValueLabelNumbersOfDecimals)
          : new Intl.NumberFormat(undefined, {
              minimumFractionDigits: rawValueLabelNumbersOfDecimals,
              maximumFractionDigits: rawValueLabelNumbersOfDecimals,
            }).format(this.y)
        : formattedPercentage;

      return total !== undefined
        ? `${dataLabelPrefix}${formattedValue}${dataLabelSuffix}` +
            (showOriginalValue ? ` (${formattedPercentage}%)` : '%')
        : 'N/A';
    };

  const dataLabelPercentOfCategoryTotalFormatter = (
    categoryTotals,
    {
      decimals = 0,
      rawValueLabelNumbersOfDecimals = 0,
      showOriginalValue = false,
      useExponentialForRawValue = false,
      dataLabelSuffix = '',
      dataLabelPrefix = '',
    } = {}
  ) =>
    function () {
      const categoryIndex = this.series.data.findIndex(
        (point) => point.category === this.point.category
      );
      const total = categoryTotals[categoryIndex];
      const percentage = total !== undefined ? (this.y / total) * 100 : 0; // Check still needed to prevetn NA;
      const percentFormatter = new Intl.NumberFormat(undefined, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
      });
      const formattedPercentage = percentFormatter.format(percentage);

      const formattedValue = showOriginalValue
        ? useExponentialForRawValue
          ? this.y.toExponential(rawValueLabelNumbersOfDecimals)
          : new Intl.NumberFormat(undefined, {
              minimumFractionDigits: rawValueLabelNumbersOfDecimals,
              maximumFractionDigits: rawValueLabelNumbersOfDecimals,
            }).format(this.y)
        : formattedPercentage;

      return total !== undefined
        ? `${dataLabelPrefix}${formattedValue}${dataLabelSuffix}` +
            (showOriginalValue ? ` (${formattedPercentage}%)` : '%')
        : 'N/A';
    };

  const dataLabelPercentOfGrandTotalFormatter = (
    grandTotal,
    {
      decimals = 0,
      rawValueLabelNumbersOfDecimals = 0,
      showOriginalValue = false,
      useExponentialForRawValue = false,
      dataLabelSuffix = '',
      dataLabelPrefix = '',
    } = {}
  ) =>
    function () {
      const percentage = (this.y / grandTotal) * 100;
      const percentFormatter = new Intl.NumberFormat(undefined, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
      });
      const formattedPercentage = percentFormatter.format(percentage);

      const formattedValue = showOriginalValue
        ? useExponentialForRawValue
          ? this.y.toExponential(rawValueLabelNumbersOfDecimals)
          : new Intl.NumberFormat(undefined, {
              minimumFractionDigits: rawValueLabelNumbersOfDecimals,
              maximumFractionDigits: rawValueLabelNumbersOfDecimals,
            }).format(this.y)
        : formattedPercentage;

      return (
        `${dataLabelPrefix}${formattedValue}${dataLabelSuffix}` +
        (showOriginalValue ? ` (${formattedPercentage}%)` : '%')
      );
    };

  const updateSettingsForFloatingPoint = (hasFloatingPoint) => {
    if (hasFloatingPoint) {
      settings.allowDecimals = true;
      settings.rawValueLabelNumbersOfDecimals = 2;
    }
    settings.firstRunEstimateDone = true;
  };

  const checkForFloatingPoints = (series) => {
    const hasFloatingPoint =
      Array.isArray(series) &&
      series.some(
        (serie) =>
          Array.isArray(serie.data) &&
          serie.data.some((dataPoint) => {
            const value = Array.isArray(dataPoint) ? dataPoint[1] : dataPoint;
            return value % 1 !== 0;
          })
      );
    updateSettingsForFloatingPoint(hasFloatingPoint);
  };

  const calculateTotals = (series) => {
    if (!series || series.length === 0) {
      return { grandTotal: 0, seriesTotals: {}, categoryTotals: {} };
    }

    const isPairFormat =
      Array.isArray(series[0].data[0]) && series[0].data[0].length === 2;
    // calculate only what is needed when in pair format.
    if (isPairFormat) {
      const seriesTotals = series.reduce(
        (acc, serie) => ({
          ...acc,
          [serie.name]: serie.data.reduce((total, pair) => total + pair[1], 0),
        }),
        {}
      );

      return { grandTotal: 0, seriesTotals, categoryTotals: {} };
    }
    const grandTotal = series
      .flatMap((serie) => serie.data)
      .reduce((acc, value) => acc + value, 0);

    const seriesTotals = series.reduce(
      (acc, serie) => ({
        ...acc,
        [serie.name]: serie.data.reduce((total, value) => total + value, 0),
      }),
      {}
    );

    const maxCategories = Math.max(...series.map((serie) => serie.data.length));
    const categoryTotals = Array.from({ length: maxCategories }, (_, i) =>
      series.reduce((acc, serie) => acc + (serie.data[i] || 0), 0)
    ).reduce(
      (acc, total, index) => ({
        ...acc,
        [index]: total,
      }),
      {}
    );

    return { grandTotal, seriesTotals, categoryTotals };
  };

  const { grandTotal, seriesTotals, categoryTotals } = calculateTotals(
    newChart.series
  );

  if (!settings.firstRunEstimateDone) {
    checkForFloatingPoints(newChart.series);
  }

  const isNonPercentChartType = (chartType) => {
    if (['heatmap', 'bubble'].includes(chartType)) {
      settings.withoutPercentChartType = true;
      settings.dataLabelIntoPercentByCategory = false;
      settings.dataLabelIntoPercentBySeries = false;
      settings.dataLabelIntoPercentByGrandTotal = false;
      settings.showOriginalValueWithPercents = false;
    } else {
      settings.withoutPercentChartType = false;
    }
  };

  isNonPercentChartType(config.chart.type);

  const formatLargeNumber = (value, suffix, decimals = 0) => {
    let formattedValue = value;
    switch (suffix) {
      case 'K':
        formattedValue = value / 1e3;
        break;
      case 'M':
        formattedValue = value / 1e6;
        break;
      case 'B':
        formattedValue = value / 1e9;
        break;
      case 'T':
        formattedValue = value / 1e12;
        break;
      default:
        return value;
    }
    return formattedValue.toFixed(decimals) + suffix;
  };

  newChart.series?.forEach((serie) => {
    const isPairFormat =
      Array.isArray(serie.data[0]) && serie.data[0].length === 2;
    const isHeatmap = config.chart.type === 'heatmap';
    const isBubbleChart = config.chart.type === 'bubble';

    serie.dataLabels = {
      enabled: settings.showDataLabels,
      allowOverlap: settings.allowDataLabelOverlap,
      rotation: settings.dataLabelRotation,
      padding: 5,
      align:
        settings.dataLabelRotation > 1 || settings.dataLabelRotation < -1
          ? 'right'
          : 'center',
      formatter:
        isHeatmap || isBubbleChart
          ? function () {
              const valueToFormat = isHeatmap ? this.point.value : this.point.z;
              if (settings.useExponentialForRawValue) {
                return `${
                  settings.dataLabelPrefix || ''
                }${valueToFormat.toExponential(
                  settings.rawValueLabelNumbersOfDecimals || 0
                )}${settings.dataLabelSuffix || ''}`;
              }
              const rawFormatter = new Intl.NumberFormat(undefined, {
                minimumFractionDigits:
                  settings.rawValueLabelNumbersOfDecimals || 0,
                maximumFractionDigits:
                  settings.rawValueLabelNumbersOfDecimals || 0,
              });
              return `${settings.dataLabelPrefix || ''}${rawFormatter.format(
                valueToFormat
              )}${settings.dataLabelSuffix || ''}`;
            }
          : isPairFormat &&
            (settings.dataLabelIntoPercentBySeries ||
              settings.dataLabelIntoPercentByCategory ||
              settings.dataLabelIntoPercentByGrandTotal)
          ? dataLabelPercentOfSeriesTotalFormatter(seriesTotals, {
              decimals: settings.labelNumbersOfDecimals,
              rawValueLabelNumbersOfDecimals:
                settings.rawValueLabelNumbersOfDecimals,
              showOriginalValue: settings.showOriginalValueWithPercents,
              dataLabelSuffix: settings.dataLabelSuffix,
              dataLabelPrefix: settings.dataLabelPrefix,
              useExponentialForRawValue: settings.useExponentialForRawValue,
            })
          : settings.dataLabelIntoPercentBySeries
          ? dataLabelPercentOfSeriesTotalFormatter(seriesTotals, {
              decimals: settings.labelNumbersOfDecimals,
              rawValueLabelNumbersOfDecimals:
                settings.rawValueLabelNumbersOfDecimals,
              showOriginalValue: settings.showOriginalValueWithPercents,
              dataLabelSuffix: settings.dataLabelSuffix,
              dataLabelPrefix: settings.dataLabelPrefix,
              useExponentialForRawValue: settings.useExponentialForRawValue,
            })
          : settings.dataLabelIntoPercentByCategory
          ? dataLabelPercentOfCategoryTotalFormatter(categoryTotals, {
              decimals: settings.labelNumbersOfDecimals,
              rawValueLabelNumbersOfDecimals:
                settings.rawValueLabelNumbersOfDecimals,
              showOriginalValue: settings.showOriginalValueWithPercents,
              dataLabelSuffix: settings.dataLabelSuffix,
              dataLabelPrefix: settings.dataLabelPrefix,
              useExponentialForRawValue: settings.useExponentialForRawValue,
            })
          : settings.dataLabelIntoPercentByGrandTotal
          ? dataLabelPercentOfGrandTotalFormatter(grandTotal, {
              decimals: settings.labelNumbersOfDecimals,
              rawValueLabelNumbersOfDecimals:
                settings.rawValueLabelNumbersOfDecimals,
              showOriginalValue: settings.showOriginalValueWithPercents,
              dataLabelSuffix: settings.dataLabelSuffix,
              dataLabelPrefix: settings.dataLabelPrefix,
              useExponentialForRawValue: settings.useExponentialForRawValue,
            })
          : function () {
              if (settings.useExponentialForRawValue) {
                return `${settings.dataLabelPrefix || ''}${this.y.toExponential(
                  settings.rawValueLabelNumbersOfDecimals || 0
                )}${settings.dataLabelSuffix || ''}`;
              }
              const rawFormatter = new Intl.NumberFormat(undefined, {
                minimumFractionDigits:
                  settings.rawValueLabelNumbersOfDecimals || 0,
                maximumFractionDigits:
                  settings.rawValueLabelNumbersOfDecimals || 0,
              });

              let formattedValue = this.y;
              if (settings.largeNumberNotationSuffix) {
                formattedValue = formatLargeNumber(
                  this.y,
                  settings.largeNumberNotationSuffix,
                  settings.rawValueLabelNumbersOfDecimals
                );
              } else {
                formattedValue = rawFormatter.format(this.y);
              }

              return `${settings.dataLabelPrefix || ''}${formattedValue}${
                settings.dataLabelSuffix || ''
              }`;
            },
    };
  });

  if (settings.hideXAxis) {
    newOptions.xAxis = hideAxis(newChart.xAxis);
  }
  if (settings.hideYAxis) {
    newOptions.yAxis = hideAxis(newChart.yAxis);
  }
  if (!settings.hideXAxis && settings.xAxis) {
    newOptions.xAxis = updateAxisTitle(newChart.xAxis, settings.xAxis);
  }
  if (!settings.hideYAxis && settings.yAxis) {
    newOptions.yAxis = updateAxisTitle(newChart.yAxis, settings.yAxis);
  }
  newOptions.xAxis = Highcharts.merge(
    newOptions.xAxis,
    settings.xAxisMin > 0 || settings.xAxisMax > 0
      ? {
          min: settings.xAxisMin ? settings.xAxisMin : null,
          max: settings.xAxisMax ? settings.xAxisMax - 1 : null,
        }
      : { min: null, max: null }
  );
  newOptions.xAxis = Highcharts.merge(newOptions.xAxis, {
    labels: {
      rotation: settings.xAxisLabelRotation,
      align:
        settings.xAxisLabelRotation > 0
          ? 'left'
          : settings.xAxisLabelRotation < 0
          ? 'right'
          : 'center',
    },
  });
  Array.isArray(newOptions.yAxis)
    ? (newOptions.yAxis = newOptions.yAxis.map((a) =>
        Highcharts.merge(a, {
          allowDecimals: settings.allowDecimals,
          min: settings.yAxisMin,
          max: settings.yAxisMax,
        })
      ))
    : (newOptions.yAxis = Highcharts.merge(newOptions.yAxis, {
        allowDecimals: settings.allowDecimals,
        min: settings.yAxisMin,
        max: settings.yAxisMax,
      }));

  return Highcharts.merge(newChart, newOptions, {
    chart: {
      backgroundColor: color.backgroundColor,
      height: '43.75%',
      style: { fontFamily: 'averta, Arial', fontSize: '0.8rem' },
    },
    legend: {
      useHTML: true,
      itemStyle: {
        color: color.legendFontColor,
        fontWeight: 'normal',
        fontSize: '0.8rem',
      },
    },
    tooltip: { borderRadius: 6 },
    credits: {
      text: 'Powered by ImpactMapper',
      href: 'http://impactmapper.com',
      position: { align: 'right', y: -3 },
    },
    xAxis: {
      crosshair,
      reversed: settings.xAxisReversed,
      lineColor: color.lineColor,
      tickColor: color.lineColor,
      gridLineColor: color.lineColor,
      gridTickColor: color.lineColor,
      labels: { style: { color: color.labelColor } },
    },
    yAxis: {
      lineColor: color.lineColor,
      tickColor: color.lineColor,
      gridLineColor: color.lineColor,
      gridTickColor: color.lineColor,
      labels: { style: { color: color.labelColor } },
    },
    plotOptions: {
      series: {
        marker: { enabled: !settings.markersHidden },
        colorByPoint: settings.colorByPoint,
        dataSorting: { enabled: false, matchByName: true },
        dataLabels: {
          align: 'center',
          color: 'contrast',
          style: {
            color: 'contrast',
            stroke: 'none',
            fontSize: '11px',
            fontWeight: 'normal',
            textOutline: '1px contrast',
          },
        },
      },
      column: {
        animationLimit: 50,
        cropThreshold: 200,
        groupPadding: 0.05,
        dataLabels: {
          color: color.legendFontColor,
          style: {
            color: 'contrast',
            stroke: 'none',
            fontSize: '11px',
            fontWeight: 'normal',
            textOutline: '1px contrast',
          },
        },
      },
      bar: {
        animationLimit: 50,
        cropThreshold: 200,
        groupPadding: 0.05,
        pointPadding: 0.1,
        align: 'left',
        x: 20,
      },
      line: {
        animationLimit: 50,
        cropThreshold: 200,
        groupPadding: 0.025,
        turboThreshold: 50,
        align: 'left',
        x: 10,
      },
      area: { animationLimit: 50, cropThreshold: 200 },
      bubble: {
        animationLimit: 50,
        cropThreshold: 200,
        groupPadding: 0.025,
        minSize: '0',
        zMin: 0,
        marker: { lineWidth: 0 },
      },
      heatmap: { dataLabels: { style: { textOutline: '1px contrast' } } },
      pie: {
        animationLimit: 50,
        cropThreshold: 200,
        allowPointSelect: true,
        cursor: 'pointer',
        showInLegend: true,
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '11px',
            width: 200,
            textOutline: '1px contrast',
            textOverflow: 'auto',
          },
        },
      },
    },
    data: { switchRowsAndColumns: settings.switchRowsAndColumns },
    exporting: { buttons: { contextButton: { enabled: false } } }, // disable plugins default buttons
  });
};

const EMPTY_PROPS = [
  'taggings_count',
  'grantees_total',
  'grants_total',
  'grants_total',
  'funding_count',
  'reach_tag_total',
  'cross_tabs',
];
const isDefined = (q) => q != undefined;
const isDefinedArray = (d) => [].concat(d).filter(isDefined).length > 0;
const flattenCategory = (a) => {
  if (a?.category && a?.values?.[0])
    return String(`${a?.category ?? ''} > ${a?.values?.[0] ?? ''}`.trim());
  return a?.values?.[0] ?? a ?? '';
};
const flattenValue = (a) => {
  return a?.values?.[0] ?? a ?? '';
};
const rowIsEmpty = (obj) => EMPTY_PROPS.every((prop) => !obj?.[prop]);
const sumTotal = (arr) => (arr ?? []).reduce((n, acc) => n + acc, 0);
const getTotalBy = (item, property) =>
  Array.isArray(item)
    ? sumTotal(item.map((i) => i?.[property]))
    : item?.[property];

const filterByCalc = (data, keys, hideEmpty) =>
  (data || [])
    .map((item) => {
      if (!hideEmpty) return item;
      if ((keys || []).length === 0) return item;
      if (Array.isArray(item)) return item;
      return !rowIsEmpty(item) && keys.some((key) => getTotalBy(item, key) > 0)
        ? item
        : undefined;
    })
    .filter(isDefinedArray);

const sortData = (a, b, settings, calcTypes) => {
  let order = 0;
  if (!settings.dataSorting) return order;
  calcTypes.forEach(
    (type) =>
      (order ||= settings.xAxisReversed
        ? getTotalBy(a, type) - getTotalBy(b, type)
        : getTotalBy(b, type) - getTotalBy(a, type))
  );
  return order;
};
const sortCSV = (a, b) => {
  const aGetTotal = sumTotal(
    []
      .concat(a)
      .flatMap((t) => Object.values(t))
      .filter((i) => isNumeric(i))
  );
  const bGetTotal = sumTotal(
    []
      .concat(b)
      .flatMap((t) => Object.values(t))
      .filter((i) => isNumeric(i))
  );
  return bGetTotal - aGetTotal;
};
export const prepareData = (data, meta, settings, calcTypes) => {
  const isXY = Boolean(meta.y?.attribute_values && Array.isArray(data?.[0]));
  const isCSV = meta.x?.model === 'CSV';
  const orderBy = settings.xAxisReversed ? 'reverse' : 'slice';
  const metaX = (arr) => ({
    ...meta.x,
    attribute_values: [
      ...new Set(
        arr.flatMap((item) =>
          Array.isArray(item) ? item.map((i) => i?.meta?.x) : item?.meta?.x
        )
      ),
    ].filter(isDefined),
  });
  const metaY = (arr) =>
    isXY
      ? {
          ...meta.y,
          attribute_values: [
            ...new Set(arr.flatMap((item) => item.map((j) => j?.meta?.y))),
          ].filter(isDefined),
        }
      : { attribute_values: [] };

  const isHiddenCSV = (row) =>
    settings.hideEmpty &&
    isCSV &&
    row &&
    sumTotal(Object.values(row).filter((i) => isNumeric(i))) == 0;
  const showTableRow = (r) =>
    (calcTypes.length > 0 ? calcTypes : EMPTY_PROPS).some(
      (prop) => r?.[prop] > 0
    );

  if (isCSV) {
    const rawCSV = settings.dataSorting
      ? data.slice().sort(sortCSV)[orderBy]()
      : data.slice()[orderBy]();
    const filteredCSV =
      settings.dataSorting && settings.xAxisReversed
        ? rawCSV.reverse()
        : rawCSV.slice();

    return {
      raw: rawCSV,
      filteredData: filteredCSV,
      filteredTable: filteredCSV.map((row) => {
        if (!settings?.hideEmpty) return row;
        return Array.isArray(row)
          ? row.filter((sub) => !isHiddenCSV(sub))
          : !isHiddenCSV(row)
          ? row
          : null;
      }),
      meta: { x: metaX(filteredCSV), y: metaY(filteredCSV) },
      isXY: isXY,
      isCSV: isCSV,
      flattenValue,
      flatten: flattenCategory,
    };
  }

  const rawXY = filterByCalc(data, calcTypes, false)
    .sort((a, b) => sortData(a, b, settings, calcTypes))
    [orderBy]();
  const filteredXY = filterByCalc(data, calcTypes, settings.hideEmpty)
    .sort((a, b) => sortData(a, b, settings, calcTypes))
    [orderBy]();

  return {
    raw: rawXY,
    filteredData: filteredXY,
    filteredTable: filteredXY.map((row) => {
      if (!settings?.hideEmpty) return row;
      return Array.isArray(row)
        ? row.filter((r) => showTableRow(r))
        : showTableRow(row)
        ? row
        : null;
    }),
    meta: { x: metaX(rawXY), y: metaY(filteredXY) },
    isXY: isXY,
    isCSV: false,
    flattenValue,
    flatten: flattenCategory,
  };
};
