import React from 'react';
import { Container } from 'src/components/IMUI';
import { Icon } from 'im/ui';

export default class FormatEditor extends React.PureComponent {
  componentDidMount() {
    this.forceUpdate();
  }
  alignTextJustify = () => {
    window.quill?.container?.classList?.remove(
      'ql-left',
      'ql-right',
      'ql-default'
    );
    window.quill?.container?.classList?.add('ql-pdf');
    this.forceUpdate();
  };
  alignTextLeft = () => {
    window.quill?.container?.classList?.remove(
      'ql-left',
      'ql-pdf',
      'ql-right',
      'ql-default'
    );
    window.quill?.container?.classList?.add('ql-left');
    this.forceUpdate();
  };
  alignTextRight = () => {
    window.quill?.container?.classList?.remove(
      'ql-left',
      'ql-pdf',
      'ql-default'
    );
    window.quill?.container?.classList?.add('ql-right');
    this.forceUpdate();
  };
  noFormatting = () => {
    window.quill?.container?.classList?.remove('ql-left', 'ql-pdf', 'ql-right');
    this.forceUpdate();
  };
  render() {
    const isJustified =
      window.quill?.container?.classList?.contains?.('ql-pdf');
    const isRight = window.quill?.container?.classList?.contains?.('ql-right');
    const isLeft = window.quill?.container?.classList?.contains?.('ql-left');
    const noFormat = !isJustified && !isRight && !isLeft;

    return (
      <Container horizontal style={{ display: 'flex', gap: 8, padding: 8 }}>
        &nbsp;
        <Icon
          name="text-element"
          tip="No Formatting (as is)"
          disabled={noFormat}
          style={{ color: noFormat ? '#52cccc' : '', opacity: 1 }}
          onClick={() => this.noFormatting()}
        />
        <Icon
          name="arrow-left"
          tip="Align Left"
          disabled={isLeft}
          style={{ color: isLeft ? '#52cccc' : '', opacity: 1 }}
          onClick={() => this.alignTextLeft()}
        />
        <Icon
          name="list"
          tip="Justify Text (no wrap)"
          disabled={isJustified}
          style={{ color: isJustified ? '#52cccc' : '', opacity: 1 }}
          onClick={() => this.alignTextJustify()}
        />
        <Icon
          name="arrow-right"
          tip="Align Right"
          disabled={isRight}
          style={{ color: isRight ? '#52cccc' : '', opacity: 1 }}
          onClick={() => this.alignTextRight()}
        />
        &emsp; &emsp;
        {/* <Icon name="compare" tip="Change text size" onClick={() => this.onChangeTextSize()} /> */}
        {/* <Icon name="translate" tip="Change text size" /> */}
      </Container>
    );
  }
}
