import {
  GRANTS__GET_REQUEST,
  GRANTS__GET_SUCCESS,
  GRANTS__GET_FAILURE,
  GRANT__DELETE_SUCCESS,
  GRANT__UPDATE_SUCCESS,
  FUNDING__CREATE_SUCCESS,
  FUNDING__UPDATE_SUCCESS,
  FUNDING__DELETE_SUCCESS,
  GRANT__CONTACT_CREATE_SUCCESS,
  GRANT__CONTACT_DELETE_SUCCESS,
  GRANT__LOCATION_CREATE_SUCCESS,
  GRANT__LOCATION_UPDATE_SUCCESS,
  GRANT__LOCATION_DELETE_SUCCESS,
} from 'src/constants';

import { updateObject, updateItem, removeItem } from './utils';
const initialState = { items: [], pending: false };

const deleteGrant = (state, payload) =>
  updateObject(state, { items: removeItem(state.items, payload.grantId) });
const updateGrant = (state, payload) =>
  updateObject(state, {
    items: updateItem(state.items, payload.grant.id, (g) =>
      updateObject(g, payload.grant)
    ),
  });
const createFunding = (state, payload) =>
  updateObject(state, {
    items: updateItem(state.items, payload.data.grant_id, (g) =>
      updateObject(g, {
        fundings: g.fundings.concat([payload.data]),
        fundings_count: g.fundings_count + 1,
      })
    ),
  });
const updateFunding = (state, payload) =>
  updateObject(state, {
    items: updateItem(state.items, payload.data.grant_id, (g) =>
      updateObject(g, {
        fundings: updateItem(g.fundings, payload.data.id, (f) =>
          updateObject(f, payload.data)
        ),
      })
    ),
  });
const deleteFunding = (state, payload) =>
  updateObject(state, {
    items: updateItem(state.items, payload.grantId, (g) =>
      updateObject(g, {
        fundings: removeItem(g.fundings, payload.fundingId),
        fundings_count: g.fundings_count - 1,
      })
    ),
  });
const createGrantLocation = (state, payload) =>
  updateObject(state, {
    items: updateItem(state.items, payload.grantId, (g) =>
      updateObject(g, {
        locations: g.locations.concat([payload.location]),
        locations_count: g.locations_count + 1,
      })
    ),
  });
const updateGrantLocation = (state, payload) =>
  updateObject(state, {
    items: updateItem(state.items, payload.grantId, (g) =>
      updateObject(g, {
        locations: updateItem(g.locations, payload.location.id, (l) =>
          updateObject(l, location)
        ),
      })
    ),
  });
const deleteGrantLocation = (state, payload) =>
  updateObject(state, {
    items: updateItem(state.items, payload.grantId, (g) =>
      updateObject(g, {
        locations: removeItem(g.locations, payload.locationId),
        locations_count: g.locations_count - 1,
      })
    ),
  });
const createGrantContact = (state, payload) =>
  updateObject(state, {
    items: updateItem(state.items, payload.grantId, (g) =>
      updateObject(g, {
        contacts: g.contacts.concat([payload.contact]),
        contacts_count: g.contacts_count + 1,
      })
    ),
  });
const deleteGrantContact = (state, payload) =>
  updateObject(state, {
    items: updateItem(state.items, payload.grantId, (g) =>
      updateObject(g, {
        contacts: removeItem(g.contacts, payload.contactId),
        contacts_count: g.contacts_count - 1,
      })
    ),
  });

export default function grantsReducer(state = initialState, action) {
  switch (action.type) {
    case GRANTS__GET_REQUEST:
      return { ...state, pending: true };
    case GRANTS__GET_SUCCESS:
      return { ...state, items: action.payload.data, pending: false };
    case GRANTS__GET_FAILURE:
      return { ...state, items: [], pending: false };
    case GRANT__DELETE_SUCCESS:
      return deleteGrant(state, action.payload);
    case GRANT__UPDATE_SUCCESS:
      return updateGrant(state, action.payload);

    case FUNDING__CREATE_SUCCESS:
      return createFunding(state, action.payload);
    case FUNDING__UPDATE_SUCCESS:
      return updateFunding(state, action.payload);
    case FUNDING__DELETE_SUCCESS:
      return deleteFunding(state, action.payload);

    case GRANT__LOCATION_CREATE_SUCCESS:
      return createGrantLocation(state, action.payload);
    case GRANT__LOCATION_UPDATE_SUCCESS:
      return updateGrantLocation(state, action.payload);
    case GRANT__LOCATION_DELETE_SUCCESS:
      return deleteGrantLocation(state, action.payload);

    case GRANT__CONTACT_CREATE_SUCCESS:
      return createGrantContact(state, action.payload);
    case GRANT__CONTACT_DELETE_SUCCESS:
      return deleteGrantContact(state, action.payload);
    default:
      return state;
  }
}
