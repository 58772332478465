import React from 'react';
import classNames from 'classnames/bind';
import { T, withTranslators } from 'lioness';
import PropTypes from 'prop-types';
import { Container, Button } from 'src/components/IMUI';
import IMLogoSmall from 'src/static/impactmapper-logo-small.svg';
import cls from './SurveyLegalInfo.module.css';
const cx = classNames.bind(cls);

const SurveyLegalInfo = ({
  organizationTitle,
  organizationEmail,
  className,
  hasAddress,
  spaceTop,
}) => (
  <Container
    className={cx(
      cls.surveyLegalInfo,
      { [cls.surveyLegalInfoSpaceTop]: spaceTop },
      className
    )}
  >
    <svg width="32" height="32" viewBox="0 0 32 32" alt="ImpactMapper logo">
      <image x="0" y="0" width="32" height="32" xlinkHref={IMLogoSmall} />
    </svg>
    <div className={cls.surveyLegalInfoText}>
      <T
        message="This survey uses {{ impactMapper }} to collect information on behalf of {{ organizationTitle }}."
        organizationTitle={organizationTitle}
        impactMapper={
          <Button
            text
            underlined
            target="_blank"
            href="https://www.impactmapper.com/"
            label="ImpactMapper"
            className={cls.surveyLegalInfoButton}
          />
        }
      />
      <br />
      {!organizationEmail ? (
        <T
          message="If you have any questions, please contact {{ organizationTitle }}"
          organizationTitle={organizationTitle}
        />
      ) : (
        <T
          message="If you have any questions, please contact {{ organizationTitle }} at {{ organizationEmail }}"
          organizationTitle={organizationTitle}
          organizationEmail={
            <Button
              text
              underlined
              href={`mailto:${organizationEmail}`}
              label={organizationEmail}
              className={cls.surveyLegalInfoButton}
            />
          }
        />
      )}
    </div>
    <Button
      text
      underlined
      target="_blank"
      href="https://www.impactmapper.com/legal/"
      className={cls.surveyLegalInfoButton}
      label={<T>Terms of Service</T>}
    />
    {hasAddress && (
      <p className={cls.surveyLegalInfoText}>
        Impact Mapper
        <br />
        114 E 25th Street, 10th Floor
        <br />
        New York, NY 10010
        <br />
        United States
      </p>
    )}
  </Container>
);

SurveyLegalInfo.propTypes = {
  hasAddress: PropTypes.bool,
  spaceTop: PropTypes.bool,
  organizationTitle: PropTypes.string,
  organizationEmail: PropTypes.string,
  className: PropTypes.string,
};
SurveyLegalInfo.defaultProps = { organizationTitle: '' };

export default withTranslators(SurveyLegalInfo);
