import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { Progress } from '../Progress/Progress';
import Cell from './Cell';
import CellSettings from './CellSettings';
import ExpandableRow from './ExpandableRow';
import ExpandIcon from './ExpandIcon';
import HCellSettings from './HCellSettings';
import Head from './Head';
import HeaderCell from './HeaderCell';
import Row from './Row';
import cls from './tables.module.css';
const cx = classNames.bind(cls);
const STICKY_HEADER_CLASS = 'imui-table-sticky-visible';
const STICKY_COLUMN_CLASS = 'imui-table-sticky-column-first-visible';

export class Table extends React.Component {
  static propTypes = {
    head: PropTypes.node,
    body: PropTypes.node,
    pending: PropTypes.bool,
    compact: PropTypes.bool,
    className: PropTypes.string,
    stickyHeader: PropTypes.bool,
    stickyColumn: PropTypes.oneOf(['first']),
    overflowContainerEl: PropTypes.object,
    layout: PropTypes.oneOf(['auto', 'fixed']),
    children: PropTypes.node,
  };

  static defaultProps = { layout: 'auto' };

  componentDidMount() {
    this.handleAddListeners(this.props.overflowContainerEl);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.overflowContainerEl && !this.props.overflowContainerEl) {
      this.handleAddListeners(nextProps.overflowContainerEl);
    }
  }
  componentWillUnmount() {
    if (this.overflowContainerEl && this.props.stickyColumn) {
      this.overflowContainerEl.removeEventListener(
        'scroll',
        this.listenToStickyColumnEvent
      );
    }
    if (this.overflowContainerEl && this.props.stickyHeader) {
      this.overflowContainerEl.removeEventListener(
        'scroll',
        this.listenToStickyHeaderEvent
      );
    }
  }
  handleAddListeners = (overflowContainerEl) => {
    this.overflowContainerEl = overflowContainerEl;
    if (overflowContainerEl && this.props.stickyColumn) {
      overflowContainerEl.addEventListener(
        'scroll',
        this.listenToStickyColumnEvent
      );
    }
    if (overflowContainerEl && this.props.stickyHeader) {
      overflowContainerEl.addEventListener(
        'scroll',
        this.listenToStickyHeaderEvent
      );
    }
  };
  listenToStickyColumnEvent = () => {
    if (
      this.props.overflowContainerEl.scrollLeft &&
      !this.innerRef?.classList.contains(STICKY_COLUMN_CLASS)
    ) {
      this.innerRef?.classList.add(STICKY_COLUMN_CLASS);
    } else if (
      !this.props.overflowContainerEl.scrollLeft &&
      this.innerRef?.classList.contains(STICKY_COLUMN_CLASS)
    ) {
      this.innerRef?.classList.remove(STICKY_COLUMN_CLASS);
    }
  };
  listenToStickyHeaderEvent = () => {
    if (
      this.props.overflowContainerEl.scrollTop &&
      !this.innerRef?.classList.contains(STICKY_HEADER_CLASS)
    ) {
      this.innerRef?.classList.add(STICKY_HEADER_CLASS);
    } else if (
      !this.props.overflowContainerEl.scrollTop &&
      this.innerRef?.classList.contains(STICKY_HEADER_CLASS)
    ) {
      this.innerRef?.classList.remove(STICKY_HEADER_CLASS);
    }
  };

  render() {
    const {
      className,
      pending,
      compact,
      head,
      body,
      stickyHeader,
      layout,
      stickyColumn,
      children,
    } = this.props;

    return (
      <div className={cls.tableWrapper}>
        <table
          ref={(ref) => (this.innerRef = ref)}
          className={cx(
            'imui-table',
            `imui-table-layout-${layout}`,
            {
              'imui-table-compact': !!compact,
              'imui-table-sticky': stickyHeader,
              [`imui-table-sticky-column-${stickyColumn}`]: stickyColumn,
            },
            className
          )}
        >
          {pending && (
            <div className={cls.progressOverlay}>
              <Progress large className="absolute" />
            </div>
          )}
          {head || null}
          {body && <tbody>{body}</tbody>}
          {children}
        </table>
      </div>
    );
  }
}

Table.Row = Row;
Table.Head = Head;
Table.Body = ({ children }) => <tbody>{children}</tbody>;
Table.ExpandableRow = ExpandableRow;
Table.Cell = Cell;
Table.HeaderCell = HeaderCell;
Table.HCellSettings = HCellSettings;
Table.CellSettings = CellSettings;
Table.HCell = HeaderCell;
Table.ExpandIcon = ExpandIcon;
