import { Invitation } from 'src/api/Invitation';
import { Organization } from 'src/api/Organization';
import { Organizations, PublicOrganizations } from 'src/api/Organizations';
import { Password } from 'src/api/Password';
import { TagCategoriesProject } from 'src/api/TagCategories';
import { Taggings, Quotes } from 'src/api/Taggings';
import { TagsOfProject } from 'src/api/Tags';
import { User } from 'src/api/User';
import { UploaderSecureSurveyAnswer } from 'src/api/UploaderSecure';
import { Notifications } from 'im/ui/Notifications';
import {
  PROJECT__CREATE_SUCCESS,
  PROJECT__DELETE_SUCCESS,
  PROJECT__UPDATE_SUCCESS,
  REPORT__UPDATE_SUCCESS,
  REPORT__UPLOAD_SUCCESS,
  SURVEY__DELETE_SUCCESS,
  SURVEY__SAVE_SUCCESS,
  SURVEY__DUPLICATE_SUCCESS,
  SURVEY__GRANTEES_UPDATE_SUCCESS,
  CHART__SAVE_SUCCESS,
  CHART__DELETE_SUCCESS,
  SURVEY__GRANTEES_RESEND_SUCCESS,
  NOTIFICATIONS__SHOW,
  FUNDING__UPDATE_SUCCESS,
} from 'src/constants';

const notify = (dispatch, message, title, level = 'success') =>
  dispatch(Notifications.show({ title, message, level }));

export default function notificationEffects(_state, action, dispatch) {
  if (action.meta?.silent) return null;

  switch (action.type) {
    case NOTIFICATIONS__SHOW:
      return notify(
        dispatch,
        action.payload.message,
        action.payload.title,
        action.payload.level
      );
    case PROJECT__CREATE_SUCCESS:
      return notify(dispatch, 'Project created');
    case PROJECT__UPDATE_SUCCESS:
      return notify(dispatch, 'Project updated');
    case PROJECT__DELETE_SUCCESS:
      return notify(dispatch, 'Project deleted');
    case REPORT__UPDATE_SUCCESS:
      return notify(dispatch, 'Report updated');
    case TagsOfProject.actionTypes.put.success:
      return notify(dispatch, 'Tag updated');
    case REPORT__UPLOAD_SUCCESS:
      return notify(dispatch, 'Report uploaded');
    case TagsOfProject.actionTypes.create.success:
      return notify(dispatch, 'Tag created');
    case TagsOfProject.actionTypes.destroy.success:
      return notify(dispatch, 'Tag deleted');
    case TagCategoriesProject.actionTypes.destroy.success:
      return notify(dispatch, 'Tag group deleted');
    case Password.actionTypes.requestResetToken.success:
      return notify(
        dispatch,
        'Password change request sent. Check your email.'
      );
    case SURVEY__SAVE_SUCCESS:
      return notify(dispatch, 'Survey saved');
    case SURVEY__DUPLICATE_SUCCESS:
      return notify(dispatch, 'Survey duplicated');
    case SURVEY__DELETE_SUCCESS:
      return notify(dispatch, 'Survey deleted');
    case Taggings.actionTypes.create.success:
      return notify(dispatch, 'Tagging created');
    case Taggings.actionTypes.destroy.success:
      return notify(dispatch, 'Tagging deleted');
    case Taggings.actionTypes.put.success:
      return notify(dispatch, 'Tagging updated');
    case Quotes.actionTypes.create.success:
      return notify(dispatch, 'Quote created');
    case Quotes.actionTypes.destroy.success:
      return notify(dispatch, 'Quote deleted');
    case SURVEY__GRANTEES_UPDATE_SUCCESS:
      return notify(dispatch, 'Survey shared');
    case CHART__DELETE_SUCCESS:
      return notify(dispatch, 'Chart deleted');
    case CHART__SAVE_SUCCESS:
      return notify(dispatch, 'Chart saved');
    case SURVEY__GRANTEES_RESEND_SUCCESS:
      return notify(dispatch, 'Reminder sent');
    case FUNDING__UPDATE_SUCCESS:
      return notify(dispatch, 'Funding updated');
    case User.actionTypes.put.success:
      return notify(dispatch, 'User updated');
    case Organization.actionTypes.put.success:
      return notify(dispatch, 'Organization updated');
    case Organizations.actionTypes.create.success:
      return notify(dispatch, 'Organization created');
    case Organizations.actionTypes.destroy.success:
      return notify(dispatch, 'Organization deletion request sent');
    case Invitation.actionTypes.toOrganizationByEmail.success:
      return notify(dispatch, 'Invitation sent');
    case User.actionTypes.changePermissions.success:
      return notify(dispatch, 'Permissions updated');
    case PublicOrganizations.actionTypes.create.failure:
      return notify(
        dispatch,
        'Please check if name already exists or this email is already in usage',
        'Organization could not be created',
        'error'
      );
    case PublicOrganizations.actionTypes.create.success:
      return notify(dispatch, 'Organization created. Redirecting to login...');
    case UploaderSecureSurveyAnswer.actionTypes.create.success:
      return notify(dispatch, 'File upload completed');
    case UploaderSecureSurveyAnswer.actionTypes.destroy.success:
      return notify(dispatch, 'File deleted successfully');
    default:
      return null;
  }
}
