import React from 'react';
import PropTypes from 'prop-types';
import { getSurveyI18n } from 'src/utils/surveysI18n';
import { Icon } from 'im/ui/Icon';
import InjectableContent from './InjectableContent';
import rightAlignLanguages from './rightAlignLanguages';
import cls from './LayoutElements.module.css';

export const Header = ({ question, survey }) => (
  <InjectableContent
    convertUrls
    className={cls.header}
    text={getSurveyI18n(survey, question, 'question', 'question')}
    rightAlign={rightAlignLanguages.includes(survey.activeLanguage)}
  />
);
export const Subheader = ({ question, survey }) => (
  <InjectableContent
    convertUrls
    className={cls.subheader}
    text={getSurveyI18n(survey, question, 'question', 'question')}
    rightAlign={rightAlignLanguages.includes(survey.activeLanguage)}
  />
);
export const Text = ({ question, survey }) => (
  <InjectableContent
    convertUrls
    className={cls.text}
    text={getSurveyI18n(survey, question, 'question', 'question')}
    rightAlign={rightAlignLanguages.includes(survey.activeLanguage)}
  />
);
export const SectionBreak = ({ question, survey }) =>
  question.settings?.sectionHidden ? (
    survey.name ? (
      <div className={cls.sectionHidden}>
        <div className={cls.sectionHiddenTitle}>
          {getSurveyI18n(survey, question, 'question', 'question')}{' '}
          <Icon name="view-off" />
        </div>
      </div>
    ) : null
  ) : (
    <div className={cls.layoutElementSection}>
      <div className={cls.sectionTitle}>
        {getSurveyI18n(survey, question, 'question', 'question')}
      </div>
      <InjectableContent
        convertUrls
        className={cls.sectionDescription}
        text={getSurveyI18n(survey, question, 'description', 'description')}
        rightAlign={rightAlignLanguages.includes(survey.activeLanguage)}
      />
    </div>
  );

Header.propTypes = { question: PropTypes.object, survey: PropTypes.object };
Subheader.propTypes = { question: PropTypes.object, survey: PropTypes.object };
Text.propTypes = { question: PropTypes.object, survey: PropTypes.object };
SectionBreak.propTypes = {
  question: PropTypes.object,
  survey: PropTypes.object,
};
