import React, { useState } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import Page from 'src/components/Page/Page';
import { getOrgText } from 'src/services/DictionaryService';
import UploadCSV from './UploadCSV';
import ValidateCSV from './ValidateCSV';

const GrantsImport = ({ history, match, location }) => {
  const [currentFileId, setCurrentFileId] = useState();

  const steps = [
    { url: `${match.url}`, title: 'Import' },
    {
      url: `${match.path}/validate/${currentFileId}`,
      title: 'Validate',
      disabled: !currentFileId,
    },
  ];

  const onUploadSuccess = (response) => {
    setCurrentFileId(response.id);
    history.push({
      pathname: `${match.path}/validate/${response.id}`,
    });
  };

  return (
    <Page
      hasFooter
      back="/grants/list/funding"
      title={getOrgText('Import grants')}
      activeStep={steps.map((e) => e.url).indexOf(location.pathname)}
      steps={steps}
    >
      <Switch>
        <Route
          exact
          path={match.path}
          render={(props) => (
            <UploadCSV
              {...props}
              onUploadSuccess={onUploadSuccess}
              title={getOrgText('Upload Grant, Grantee and Fundings data')}
              type="Grant"
            />
          )}
        />
        <Route
          exact
          path={`${match.path}/validate/:fileId`}
          component={ValidateCSV}
        />
        <Redirect from="*" to="/error/404" />
      </Switch>
    </Page>
  );
};

export default GrantsImport;
