import React from 'react';
import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  getProjects,
  projectActiveChange,
} from 'src/actionCreators/projectActionCreators';
import {
  Actions,
  Button,
  Card,
  CardEmpty,
  Container,
  Section,
} from 'src/components/IMUI';
import Page from 'src/components/Page/Page';
import { getText } from 'src/services/DictionaryService';
import AnalysisFilters from './components/AnalysisFilters';
import welcomeEmptyImage from 'src/static/lady-empty-analysis.png';
import learningImage from 'src/static/learning.png';
import cls from './AnalysisList.module.css';
import { canManageGrants, canManageProject, surveyOnly } from 'src/userStorage';
const images = [
  'https://res.cloudinary.com/impactmapper/image/upload/v1485452936/image_0_eyuwjb.jpg',
  'https://res.cloudinary.com/impactmapper/image/upload/v1485452936/image_1_ceej1s.jpg',
  'https://res.cloudinary.com/impactmapper/image/upload/v1485452936/image_2_a51raw.jpg',
  'https://res.cloudinary.com/impactmapper/image/upload/v1485452936/image_3_mftvej.jpg',
  'https://res.cloudinary.com/impactmapper/image/upload/v1485452936/image_4_ouzkrm.jpg',
  'https://res.cloudinary.com/impactmapper/image/upload/v1485452936/image_5_is9syz.jpg',
  'https://res.cloudinary.com/impactmapper/image/upload/v1485452936/image_6_r5wutq.jpg',
  'https://res.cloudinary.com/impactmapper/image/upload/v1485452936/image_7_t2cijb.jpg',
  'https://res.cloudinary.com/impactmapper/image/upload/v1485452936/image_8_ucyaxs.jpg',
  'https://res.cloudinary.com/impactmapper/image/upload/v1485452936/image_9_f7ginu.jpg',
  'https://res.cloudinary.com/impactmapper/image/upload/v1485452936/image_10_auj0fu.jpg',
  'https://res.cloudinary.com/impactmapper/image/upload/v1485452936/image_11_vt4qv3.jpg',
].sort((a, b) => 1 / 1000 - Math.random());

@connect(pick(['projects', 'organizationCurrent']), {
  getProjects,
  projectActiveChange,
})
export default class AnalysisList extends React.PureComponent {
  static propTypes = {
    getProjects: PropTypes.func.isRequired,
    projectActiveChange: PropTypes.func.isRequired,
    projects: PropTypes.object,
    organizationCurrent: PropTypes.object,
  };
  state = { filterQuery: '', filteredProjects: [], ready: false };

  componentDidMount() {
    this.fetchProjects();
  }
  componentDidUpdate(oldProps) {
    if (
      this.props.organizationCurrent?.data?.id !=
      oldProps.organizationCurrent?.data?.id
    )
      this.fetchProjects();
  }
  fetchProjects() {
    this.state.ready != false &&
      this.setState({
        ready: false,
        filteredProjects: undefined,
        filterQuery: '',
      });
    this.props.getProjects().then(() =>
      this.setState({
        ready: true,
        filteredProjects: undefined,
        filterQuery: '',
      })
    );
  }
  onFilteredProjectsChange = (filteredProjects) => {
    this.setState({ filteredProjects });
  };
  onFilterChange = (filterQuery) => {
    this.setState({ filterQuery });
  };
  clearFilter = () => {
    this.setState({ filterQuery: '' });
  };

  renderFilteredScreen() {
    return (
      <Section className={cls.projects}>
        {(this.state.filteredProjects || this.props.projects.items).map(
          (project, i) => (
            <div
              key={i}
              tabIndex={0}
              role="button"
              onClick={(ev) => {
                ev.stopPropagation();
                this.props.projectActiveChange(project.id, '/summary');
              }}
              className={cls.projectLink}
            >
              <Card
                className={cls.project}
                style={{ backgroundImage: `url(${images[i % images.length]})` }}
              >
                {canManageProject() && (
                  <object
                    onClick={(ev) => {
                      ev.stopPropagation();
                    }}
                  >
                    <Link
                      to={`/analysis/${project.id}/edit`}
                      className={cls.editProjectLink}
                    >
                      edit
                    </Link>
                  </object>
                )}
                <Container grow />
                <div className={cls.projectText}>{project.name}</div>
              </Card>
            </div>
          )
        )}
      </Section>
    );
  }
  renderNoMatchScreen() {
    return (
      <Section>
        <Container centerHorizontal horizontal>
          <div>
            <img
              src={learningImage}
              alt="welcome"
              style={{ width: 250, height: 250, margin: 64 }}
            />
          </div>
        </Container>
        <CardEmpty
          large
          title="There are no projects matching your query"
          description={`Queried for: ${this.state.filterQuery}`}
        >
          <Container horizontal centered>
            <Actions>
              <Button
                secondary
                size="l"
                label="Clear filter"
                onClick={this.clearFilter}
              />
              <Button
                disabled={!canManageProject()}
                size="l"
                label="Add Analysis"
                to="/analysis/add"
              />
            </Actions>
          </Container>
        </CardEmpty>
      </Section>
    );
  }
  renderWelcomeScreen() {
    const suffix = surveyOnly()
      ? ''
      : getText(', or add your organizations grants');
    return (
      <Section>
        <Container centerHorizontal horizontal>
          <div>
            <img
              src={welcomeEmptyImage}
              alt="welcome"
              style={{ width: 275, height: 250, margin: 64 }}
            />
          </div>
        </Container>
        <CardEmpty
          large
          title="Welcome to Impact Mapper"
          description={`You can start creating a new project${suffix}`}
        >
          <Container centerHorizontal>
            <Actions style={{ justifyContent: 'center', marginBottom: 50 }}>
              <Button
                disabled={!canManageProject()}
                secondary
                size="l"
                label="Create Project"
                to="/analysis/add"
              />
              {!surveyOnly() && <span>or</span>}
              {!surveyOnly() && (
                <Button
                  disabled={!canManageGrants()}
                  secondary
                  size="l"
                  label={getText('Add Grants')}
                  to="/grants"
                />
              )}
            </Actions>
          </Container>
        </CardEmpty>
      </Section>
    );
  }

  render() {
    const pending = !this.state.ready || this.props.projects.pending;
    const welcome = !pending && this.props.projects.items.length == 0;
    const hasItem = !pending && this.props.projects.items.length > 0;
    const noMatch =
      hasItem &&
      this.state.filteredProjects?.length == 0 &&
      this.state.filterQuery?.length > 0;
    const topTile = this.props.organizationCurrent?.data?.title || 'Project';

    return (
      <Page
        key={this.props.organizationCurrent?.data?.id}
        pending={pending}
        title={topTile}
        helpAppcue="-LHsCCJngvDdsXS6o_7X"
      >
        {hasItem && (
          <Section type="sub-header" collapsed>
            <Button
              disabled={!canManageProject()}
              to="/analysis/add"
              label="Create Project"
              size="l"
            />
            <AnalysisFilters
              filterQuery={this.state.filterQuery}
              data={this.props.projects.items}
              onFilterChange={this.onFilterChange}
              onChange={this.onFilteredProjectsChange}
            />
          </Section>
        )}

        {welcome && this.renderWelcomeScreen()}
        {noMatch && this.renderNoMatchScreen()}
        {hasItem && this.renderFilteredScreen()}
      </Page>
    );
  }
}
