import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import Table from './Table';

const DEFAULT_TAB = 'grant';
export default class FundingTable extends Component {
  static propTypes = { match: ReactRouterPropTypes.match };
  render() {
    return (
      <Switch>
        <Route
          exact
          path={`${this.props.match.path}/:activeTab(grant|grantee|funding)/:entityId?`}
          component={Table}
        />
        <Redirect from="*" to={`${this.props.match.path}/${DEFAULT_TAB}`} />
      </Switch>
    );
  }
}
