import { NotificationInput, NOTIFICATIONS } from './store.types';

export const show = (notification: NotificationInput) => ({
  type: NOTIFICATIONS.SHOW,
  payload: notification,
});

export const hide = (uid: number) => ({
  type: NOTIFICATIONS.HIDE,
  payload: uid,
});

export const hideAll = () => ({
  type: NOTIFICATIONS.HIDE_ALL,
});

export const Notifications = {
  show,
  hide,
  hideAll,
};
