import PropTypes from 'prop-types';
import React from 'react';

const EMPTY_STYLE = {
  display: 'inline-block',
  fontSize: '14px',
  fontStyle: 'italic',
  color: '#7c91a2',
};

export const EmptyText = ({ text, className }) => (
  <span style={EMPTY_STYLE} className={className}>
    {text}
  </span>
);

EmptyText.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
};

export default EmptyText;
