import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';

import Organizations from './Organizations';
import UserSettings from './Settings/UserSettings';
import { isStaff } from 'src/userStorage';

@connect(pick(['user']))
export default class UserIndex extends Component {
  static propTypes = {
    ...ReactRouterPropTypes,
    user: PropTypes.object,
  };

  static defaultProps = {
    user: {},
  };

  get activeStep() {
    return this.steps.map((e) => e.url).indexOf(this.props.location.pathname);
  }

  get steps() {
    return [
      {
        url: `${this.props.match.url}/organizations`,
        title: 'Your organizations',
      },
      ...(isStaff()
        ? [
            {
              url: `${this.props.match.url}/organizations/staff`,
              title: 'All organizations',
            },
          ]
        : []),
      { url: `${this.props.match.url}/settings`, title: 'User Settings' },
    ];
  }

  render() {
    const headerStepsProps = { steps: this.steps, activeStep: this.activeStep };

    return (
      <Switch>
        <Route
          exact
          path={`${this.props.match.path}/settings`}
          render={(props) => <UserSettings {...headerStepsProps} {...props} />}
        />
        <Route
          path={`${this.props.match.path}/organizations`}
          render={(props) => <Organizations {...headerStepsProps} {...props} />}
        />
        <Redirect from="*" to={`${this.props.match.url}/organizations`} />
      </Switch>
    );
  }
}
