import React from 'react';

const withStaticSupport = (WrappedComponent) => {
  class FilterStaticSupport extends React.PureComponent {
    static propTypes = { ...WrappedComponent.propTypes };
    handleTransformInput = (filters, config) => {
      return (filters || []).map(({ variable, matcher, result }) => {
        const currentItem = config.find((c) => c.name == variable) || {};
        return {
          variable: currentItem.transformVariable?.(variable) ?? variable,
          matcher: currentItem.transformMatcher?.(matcher) ?? matcher,
          type:
            currentItem.transformType?.(currentItem.type) ?? currentItem.type,
          result: currentItem.transformResponse?.(result) ?? result,
        };
      });
    };

    handleTransformOutput = (filters) => filters;

    render() {
      return (
        <WrappedComponent
          {...this.props}
          onTransformInput={this.handleTransformInput}
          onTransformOutput={this.handleTransformOutput}
        />
      );
    }
  }

  return FilterStaticSupport;
};

export default withStaticSupport;
