import React from 'react';
import { formatMoney } from 'accounting';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highmaps';
import highchartsExporting from 'highcharts/modules/exporting';
import highchartsOfflineExporting from 'highcharts/modules/offline-exporting';
import PropTypes from 'prop-types';
import clone from 'ramda/src/clone';
import prop from 'ramda/src/prop';
import { Toggle } from 'src/components/IMUI';
import { getText } from 'src/services/DictionaryService';
import cls from './Map.module.css';
import MAP_DATA_MX from './mapdata/mx-all.geo.json';
import MAP_DATA_US from './mapdata/us-all.geo.json';
import MAP_DATA_GLOBE from './mapdata/world-international-feature.geo.json';
import MAP_DATA from './mapdata/world-palestine-highres-custom.geo.json';

const compareArrays = (a, b) =>
  b
    .map((s) => s.toLowerCase())
    .every(Set.prototype.has, new Set(a.map((s) => s.toLowerCase())));
const MAP_DATA_WITH_GLOBE = clone(MAP_DATA);
MAP_DATA_WITH_GLOBE.features.push(MAP_DATA_GLOBE);
const ENTITY_FUNDING = 'total_funding';
const PROPER_MAPS = [['US'], ['US', 'PR'], ['MX']];
const US_STATES = {
  ma: 'massachusetts',
  wa: 'washington',
  ca: 'california',
  or: 'oregon',
  wi: 'wisconsin',
  me: 'maine',
  mi: 'michigan',
  nv: 'nevada',
  nm: 'new mexico',
  co: 'colorado',
  wy: 'wyoming',
  ks: 'kansas',
  ne: 'nebraska',
  ok: 'oklahoma',
  mo: 'missouri',
  il: 'illinois',
  in: 'indiana',
  vt: 'vermont',
  ar: 'arkansas',
  tx: 'texas',
  ri: 'rhode island',
  al: 'alabama',
  ms: 'mississippi',
  nc: 'north carolina',
  va: 'virginia',
  ia: 'iowa',
  md: 'maryland',
  de: 'delaware',
  pa: 'pennsylvania',
  nj: 'new jersey',
  ny: 'new york',
  id: 'idaho',
  sd: 'south dakota',
  ct: 'connecticut',
  nh: 'new hampshire',
  ky: 'kentucky',
  oh: 'ohio',
  tn: 'tennessee',
  wv: 'west virginia',
  dc: 'district of columbia',
  la: 'louisiana',
  fl: 'florida',
  ga: 'georgia',
  sc: 'south carolina',
  mn: 'minnesota',
  mt: 'montana',
  nd: 'north dakota',
  az: 'arizona',
  ut: 'utah',
  hi: 'hawaii',
  ak: 'alaska',
  ti: 'tinian',
  sa: 'saipan',
  ro: 'rota',
  pr: 'puerto rico',
};

const transformProperMapData = (data, hasProperMap) => {
  if (!hasProperMap || !data?.flatMap((d) => d?.entities)?.length)
    return data.slice();
  const entities = data?.flatMap((d) => d?.entities).filter(Boolean);
  const states = [...new Set(entities.map((e) => e.state))].filter(Boolean);
  const needCast = states.length > 2 && states.every((s) => s.length <= 2);
  const castState = (s) =>
    needCast
      ? US_STATES[String(s).trim()] || 'other'
      : String(s || 'other')
          .toLocaleLowerCase()
          .trim();
  return states
    .map((state) => ({
      code: castState(state),
      entities: entities.filter((e) => e.state === state),
      value: entities.filter((e) => e.state === state).length,
    }))
    .slice();
};
const getGeoMapData = (countryCodes, hasProperMap) => {
  if (hasProperMap && countryCodes.includes('MX')) return MAP_DATA_MX;
  if (hasProperMap && countryCodes.includes('US')) return MAP_DATA_US;
  if (countryCodes.includes('UN')) return MAP_DATA_WITH_GLOBE;
  return MAP_DATA;
};
[highchartsExporting, highchartsOfflineExporting].forEach((fn) =>
  fn(Highcharts)
);

export const THEME = {
  light: {
    backgroundColor: '#f4f6f9',
    legendFontColor: '#333',
    lineColor: 'rgb(224, 224, 224)',
    labelColor: '#000',
    labelsStrokeColor: '#fff',
    emptyCountryColor: '#dadfe6',
    countryBorderColor: '#fff',
    hoverCountryColor: '#848484',
    selectCountryColor: '#848484',
    hoverBorderColor: '#f4f6f9',
    gradientStops: [
      [0, '#dadfe6'],
      [0.000000001, '#cfd0fd'],
      [0.5, '#4543bf'],
      [1, '#272680'],
    ],
  },
  dark: {
    backgroundColor: '#333',
    legendFontColor: '#eee',
    lineColor: 'rgb(124, 124, 124)',
    labelColor: '#fff',
    labelsStrokeColor: '#333',
    emptyCountryColor: '#3d3d3d',
    countryBorderColor: '#000',
    hoverCountryColor: '#b3b3b3',
    selectCountryColor: '#b3b3b3',
    hoverBorderColor: '#b3b3b3',
    gradientStops: [
      [0, '#ffeddf'],
      [0.5, '#ffa661'],
      [1, '#ff7000'],
    ],
  },
};

const getTickInterval = (data) => {
  let tickInterval = data.reduce((memo, { value }) => Math.max(memo, value), 0);
  if (tickInterval % 2 === 1) {
    tickInterval += 1;
  }
  return tickInterval / 2;
};

class Map extends React.PureComponent {
  static propTypes = {
    chartTitle: PropTypes.string,
    data: PropTypes.array.isRequired,
    height: PropTypes.number,
    onPointClick: PropTypes.func,
    showDataLabels: PropTypes.bool,
    useDarkTheme: PropTypes.bool,
    hasBackground: PropTypes.bool,
    entity: PropTypes.string,
    currencySymbol: PropTypes.string,
  };
  static defaultProps = {
    data: [],
    showDataLabels: false,
    useDarkTheme: false,
    chartTitle: '',
    height: 600,
    onPointClick: () => void 0,
  };
  state = { allAreas: undefined };

  initialLoadAllAreas = () => {
    if (!this.props.data?.[0]?.entities) return;
    if (this.state.allAreas !== undefined) return;

    const everyisUN = this.props.data.every(({ code }) => code === 'UN');
    if (everyisUN) return this.setState({ allAreas: true });

    const hasUNCode = this.props.data.some(({ code }) => code === 'UN');
    if (hasUNCode) return this.setState({ allAreas: true });

    const realCountries = this.uniqueCountryCodes().filter((c) => c !== 'UN');
    if (realCountries.length > 1) return this.setState({ allAreas: true });

    if (this.hasProperMap()) return this.setState({ allAreas: true });

    this.setState({ allAreas: false });
  };
  componentDidMount() {
    this.initialLoadAllAreas();
  }
  componentDidUpdate() {
    this.initialLoadAllAreas();
  }

  onToggleAllAreas = () => {
    this.setState({ allAreas: !this.state.allAreas });
  };
  uniqueCountryCodes = () =>
    [...new Set(this.props.data.map(({ code }) => code))]
      .filter(Boolean)
      .map((a) => String(a).toUpperCase());
  hasProperMap = () => {
    const valid = this.uniqueCountryCodes().filter((c) => c !== 'UN');
    return !valid.length
      ? false
      : PROPER_MAPS.some((arr) =>
          Boolean(arr.length === valid.length && compareArrays(valid, arr))
        );
  };

  render() {
    const { height, onPointClick, entity, currencySymbol } = this.props;
    const seriesData = transformProperMapData(
      this.props.data,
      this.hasProperMap()
    );
    const color = this.props.useDarkTheme ? 'dark' : 'light';
    const backgroundColorOverride =
      this.props.hasBackground && !this.props.useDarkTheme ? '#fff' : null;
    const config = {
      title: {
        text: this.props.chartTitle || '-',
        style: { color: THEME[color].legendFontColor },
      },
      xAxis: { minRange: 1 },
      yAxis: { softMin: 0, minRange: 1 },
      colorAxis: {
        tickInterval: getTickInterval(this.props.data),
        softMin: 0,
        min: 1,
        tickColor: 'transparent',
        stops: THEME[color].gradientStops,
        labels: {
          style: { color: THEME[color].legendFontColor, 'font-size': '10px' },
        },
      },
      chart: {
        backgroundColor:
          backgroundColorOverride || THEME[color].backgroundColor,
        allowMutatingData: false,
        spacingTop: 0,
        spacingLeft: 10,
        spacingRight: 10,
        spacingBottom: 40,
        height,
        style: { fontFamily: 'Arial' },
      },
      series: [
        {
          type: 'map',
          allowPointSelect: false,
          data: seriesData,
          allAreas: this.state.allAreas,
          joinBy: ['iso-a2', 'code'],
          dataLabels: { enabled: this.props.showDataLabels },
          cursor: 'pointer',
          point: {
            events: {
              click: (e) => {
                onPointClick({
                  code: e?.point?.code,
                  entities: e?.point?.entities,
                  name: e?.point?.name,
                });
              },
            },
          },
        },
      ],
      exporting: { buttons: { contextButton: { enabled: false } } },
      mapNavigation: {
        enabled: true,
        buttonOptions: { verticalAlign: 'bottom', align: 'right' },
      },
      plotOptions: {
        map: {
          mapData: Highcharts.geojson(
            getGeoMapData(this.uniqueCountryCodes(), this.hasProperMap())
          ),
        },
        series: {
          nullColor: THEME[color].emptyCountryColor,
          borderColor: THEME[color].countryBorderColor,
          borderWidth: 2,
          allowPointSelect: true,
          cursor: 'pointer',
          shadow: false,
          states: {
            hover: { color: THEME[color].hoverCountryColor },
            select: { color: THEME[color].selectCountryColor },
            dataLabels: { enabled: false },
          },
          dataLabels: {
            color: THEME[color].legendFontColor,
            style: { stroke: 'none', fontSize: '10px', fontWeight: 'normal' },
          },
        },
      },
      legend: {
        y: 40,
        floating: true,
        align: 'left',
        title: {
          text: `Number Of ${getText(entity, 2, true)}`,
          style: { color: THEME[color].labelColor, 'font-size': '11px' },
        },
        itemStyle: {
          color: THEME[color].legendFontColor,
          fontWeight: 'normal',
          fontSize: '11px',
        },
      },
      credits: {
        text: 'Powered by ImpactMapper',
        href: 'http://impactmapper.com',
        position: { align: 'right', y: -10 },
      },
      tooltip: {
        useHTML: true,
        formatter: function () {
          const totalFunding = this.point.entities
            .map(prop(ENTITY_FUNDING))
            .reduce((a, b) => a + b, 0);
          return `<div><h2 class="${cls.tooltipName}" style="color: #52cccc">${
            this.point.name
          }</h2>
        <div>${this.point.entities.length} ${getText(
          entity,
          this.point.entities.length,
          true
        )}</div>
        ${
          totalFunding > 0
            ? `<br/><div>${formatMoney(
                totalFunding / 100,
                currencySymbol
              )} Total Funding</div>`
            : ``
        }
        <div class="${
          cls.tooltipClickInfo
        }" style="color: #52cccc">Click to View Details</span></div>`;
        },
      },
    };

    return (
      <div style={{ position: 'relative' }}>
        <div style={{ display: 'flex', gap: 8, marginTop: 8, marginLeft: 16 }}>
          <Toggle
            mini
            label="show all areas"
            labelPosition="left"
            onToggle={this.onToggleAllAreas}
            toggled={this.state.allAreas}
          />
        </div>
        <HighchartsReact
          highcharts={Highcharts}
          options={config}
          constructorType="mapChart"
          allowChartUpdate={true}
          immutable={true}
          ref={(ref) => {
            if (!ref?.chart) return;
            window.highmap = ref.chart;
          }}
        />
      </div>
    );
  }
}

export default Map;
//https://code.highcharts.com/mapdata/
