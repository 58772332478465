import React from 'react';
import PropTypes from 'prop-types';
import {
  DownloadAsPdfWidget,
  TabSegments,
  Container,
} from 'src/components/IMUI';

import SurveyEditFooter from '../../components/SurveyEditFooter';

import cls from './SurveyPreviewFooter.module.css';

export default class SurveyPreviewFooter extends React.PureComponent {
  static propTypes = {
    isMobilePreview: PropTypes.bool,
    onPrintOpen: PropTypes.func,
    onPrintClose: PropTypes.func,
    onSetPreviewMode: PropTypes.func.isRequired,
  };

  renderRightAction = () => {
    const segments = [
      {
        id: false,
        tooltipText: 'Destkop',
        text: <span className={cls.switcherTab}>Desktop</span>,
        active: !this.props?.isMobilePreview,
      },
      {
        id: true,
        tooltipText: 'Mobile',
        text: <span className={cls.switcherTab}>Mobile</span>,
        active: this.props?.isMobilePreview,
      },
    ];
    return (
      <Container horizontal nowrap className={cls.actionContainer}>
        <DownloadAsPdfWidget
          onOpen={this.props.onPrintOpen}
          onClose={this.props.onPrintClose}
        />
        <TabSegments
          flat
          dark
          className={cls.spaceLeft}
          segments={segments}
          onToggle={this.props.onSetPreviewMode}
        />
      </Container>
    );
  };

  render() {
    const {
      isMobilePreview,
      onSetPreviewMode,
      onPrintOpen,
      onPrintClose,
      ...rest
    } = this.props;
    return (
      <SurveyEditFooter {...rest} renderRightAction={this.renderRightAction} />
    );
  }
}
