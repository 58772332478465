export const monthsLong = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export const monthsShort = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const ensureDate = (date) => {
  if (date == 'Invalid Date') return new Date();
  return date instanceof Date ? date : new Date(date);
};
const localDate = (date) =>
  new Date(
    ensureDate(date).getTime() +
      ensureDate(date).getTimezoneOffset() * 60 * 1000
  );
export const formatYYYYMMDDRaw = (date) =>
  ensureDate(date).toISOString().split('T')[0];
export const parseToDateFormat = (date) => {
  if (!date) return null;
  return date instanceof Date ? date : new Date(date);
};
export const formatYYYYMMDD = (date) =>
  localDate(date).toISOString().split('T')[0];
export const castDDMMYYYYToDate = (str) =>
  localDate(
    str.substring(4, 8) + '-' + str.substring(2, 4) + '-' + str.substring(0, 2)
  );
export const toLocaleDate = (date) =>
  ensureDate(localDate(date)).toLocaleDateString('en-GB');
export const toLocaleDateTime = (date) =>
  ensureDate(localDate(date)).toLocaleDateString('en-GB') +
  ' ' +
  ensureDate(localDate(date)).toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
  });
export const isSameDay = (_d1, _d2) => {
  const d1 = ensureDate(_d1);
  const d2 = ensureDate(_d2);
  return (
    d1.getUTCFullYear() == d2.getUTCFullYear() &&
    d1.getUTCMonth() == d2.getUTCMonth() &&
    d1.getUTCDate() == d2.getUTCDate()
  );
};
export const isAfter = (a, b, withoutEqual) =>
  withoutEqual
    ? ensureDate(a).getTime() > ensureDate(b).getTime()
    : ensureDate(a).getTime() >= ensureDate(b).getTime();
export const isBefore = (a, b, withoutEqual) =>
  withoutEqual
    ? ensureDate(a).getTime() < ensureDate(b).getTime()
    : ensureDate(a).getTime() <= ensureDate(b).getTime();
