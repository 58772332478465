import React from 'react';
import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import { connect } from 'react-redux';
import { isGrantless } from 'src/utils/analysis';
import SummaryGrantBased from './SummaryGrantBased/SummaryGrantBased';
import SummaryStandalone from './SummaryStandalone/SummaryStandalone';
import { matchPath } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { getInitProject } from 'src/actionCreators/projectActionCreators';
import history from 'src/historyInstance';

@connect(pick(['project']), (dispatch) =>
  bindActionCreators({ getInitProject }, dispatch)
)
export default class Summary extends React.PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    project: PropTypes.object,
    getInitProject: PropTypes.func.isRequired,
  };
  static defaultProps = { project: {} };

  componentDidMount() {
    const getProjId = (location) =>
      matchPath(location.pathname, {
        path: '/analysis/:projectId',
        exact: false,
        strict: false,
      })?.params.projectId;
    if (getProjId(this.props.location) == 'undefined')
      history.replace('/analysis');
    if (!this.props.project.id)
      this.props.getInitProject(getProjId(this.props.location));
  }
  render() {
    return !this.props.project?.id ? null : isGrantless(this.props.project) ? (
      <SummaryStandalone
        key={this.props.project?.id + this.props.location.search}
        project={this.props.project}
      />
    ) : (
      <SummaryGrantBased
        key={this.props.project?.id + this.props.location.search}
        match={this.props.match}
        location={location}
      />
    );
  }
}
