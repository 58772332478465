import { T } from 'lioness';
import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import React from 'react';
import { connect } from 'react-redux';

import { Section, Container } from 'src/components/IMUI';
import SurveyLegalInfo from 'src/components/Survey/SurveyLegalInfo';
import surveyIcon from 'src/static/surveySent.png';

import CompanyLogo from './components/CompanyLogo';
import SurveyContainer from './components/SurveyContainer';

import cls from './AnswerSurveySuccess.module.css';
import Storage from 'src/services/Storage';

@connect(pick(['surveyAnswers', 'surveyPublic']))
export default class AnswerSurveySuccess extends React.Component {
  static propTypes = {
    surveyAnswers: PropTypes.object,
    surveyPublic: PropTypes.object,
  };

  render() {
    const { surveyAnswers, surveyPublic } = this.props;
    const { info } = surveyAnswers;

    Storage.removeItem(`permalink-${surveyPublic.data.public_id}`);

    return (
      <SurveyContainer>
        <Section surveyWidth>
          <Container centered>
            <CompanyLogo
              isPending={surveyAnswers.fetching}
              logo={info.survey_organization_logo_url}
            />
            <h1 className={cls.answerSurveySuccessHeader}>
              <T context="header of survey submit success view">Thank you!</T>
            </h1>
            <img src={surveyIcon} alt="Survey sent icon" width={300} />
            <p className={cls.answerSurveySuccessText}>
              <T>
                We appreciate you taking the time and filling out our survey.
              </T>
            </p>
            <SurveyLegalInfo
              spaceTop
              organizationTitle={info.survey_organization_title}
              organizationEmail={info.survey_organization_contact_email}
            />
          </Container>
        </Section>
      </SurveyContainer>
    );
  }
}
