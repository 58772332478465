import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { TextField } from 'src/components/IMUI';
import cls from './Dictionary.module.css';
const cx = classNames.bind(cls);

class Dictionary extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    hideGrant: PropTypes.bool,
  };
  static defaultProps = { title: 'Project Dictionary' };
  render() {
    const { className, hideGrant, title } = this.props;
    return (
      <div className={cx('wrapper', className)}>
        <h3 className={cls.dictTitle}>
          <span>{title}</span>
          <small>
            (please provide definitions in <strong>singular</strong> form)
          </small>
        </h3>
        {hideGrant ? null : (
          <Field
            fullWidth
            className={cls.spaceBottom}
            label={
              <span className={cls.dictLabel}>
                Display <i>grant</i> as:
              </span>
            }
            name="grant"
            component={TextField}
            hintText="Grant equivalent"
          />
        )}
        <Field
          fullWidth
          label={
            <span className={cls.dictLabel}>
              Display <i>grantee</i> as:
            </span>
          }
          name="grantee"
          component={TextField}
          hintText="Grantee equivalent"
        />
      </div>
    );
  }
}
export default Dictionary;
