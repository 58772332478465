import UpdatePending from '../actionCreators/UpdatePending';

export const getIncreasedPending = (action, state) => {
  const pendingType = UpdatePending.getPendingKey(state, action);
  return { [pendingType]: state.pending[pendingType] + 1 };
};

export const getDecreasedPending = (action, state) => {
  const pendingType = UpdatePending.getPendingKey(state, action);
  return { [pendingType]: state.pending[pendingType] - 1 };
};
