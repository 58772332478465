import reducerRegistry from 'im/api/registry';
import Resource from 'im/api/Resource';

const apiConfig = {
  root: '/v3/public/surveys',
  type: 'surveys',
  api: {
    find: {
      path: ':public_id',
    },
    custom: {
      start: {
        method: 'POST',
        path: ':public_id/start',
        meta: { noSync: true },
      },
    },
  },
  derived: (survey) => ({
    canFillOut:
      !survey.deadline ||
      new Date(survey.deadline).getTime() / 1000 >= Date.now() / 1000,
    getGroupForTag: (tag = {}) => (tag.tag_categories || [])[0] || {},
  }),
};

export const SurveyPublic = new Resource(apiConfig, 'surveyPublic');
reducerRegistry.register(SurveyPublic);

export default SurveyPublic.api;
