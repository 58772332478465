import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Toggle } from 'src/components/IMUI';

import cls from './ChartOptionToggles.module.css';

export default class ChartOptionToggles extends Component {
  static propTypes = {
    toggles: PropTypes.array.isRequired,
  };

  render() {
    return (
      <div className={cls.wrapper}>
        {this.props.toggles.map(({ label, onToggle, toggled }) => {
          return (
            <Toggle
              key={label}
              label={label}
              labelPosition="right"
              onToggle={onToggle}
              toggled={toggled}
              style={{ width: 180, display: 'inline-block' }}
            />
          );
        })}
      </div>
    );
  }
}
