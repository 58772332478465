import CURRENCIES from 'src/data/currencies.json';
import { formatMoneyByCode } from 'src/utils/currency';
import { monthsLong, monthsShort, toLocaleDate } from 'src/utils/date';
import { escapeForRegExp } from 'src/utils/string';

export const TAGGING_ICON_PER_TYPE = {
  default: 'tag-default',
  number: 'tag-number',
  currency: 'tag-currency',
  location: 'tag-location',
  date: 'tag-date',
};

export const TAGGING_TYPE_READABLE = {
  currency: 'Currency',
  date: 'Date',
  location: 'Location',
  number: 'Quantity',
  default: 'Default',
};

export const TAGGING_TYPE_INPUTS = {
  default: {
    label: TAGGING_TYPE_READABLE.default,
    leftIconProps: { name: 'tag' },
    rightIconProps: { name: TAGGING_ICON_PER_TYPE.default },
  },
  number: {
    inputType: 'number',
    label: TAGGING_TYPE_READABLE.number,
    leftIconProps: { name: 'table' },
    rightIconProps: { name: TAGGING_ICON_PER_TYPE.number },
  },
  currency: {
    inputType: 'currency',
    label: TAGGING_TYPE_READABLE.currency,
    leftIconProps: { name: 'currency' },
    rightIconProps: { name: TAGGING_ICON_PER_TYPE.currency },
  },
  location: {
    inputType: 'text',
    label: TAGGING_TYPE_READABLE.location,
    leftIconProps: { name: 'location' },
    rightIconProps: { name: TAGGING_ICON_PER_TYPE.location },
  },
  // date: { inputType: "date", label: TAGGING_TYPE_READABLE.date, leftIconProps: { name: "calendar" }, rightIconProps: { name: TAGGING_ICON_PER_TYPE.date }, },
};
export const TAGGING_OPTIONS = Object.keys(TAGGING_TYPE_INPUTS).map((key) => ({
  value: key,
  label: TAGGING_TYPE_INPUTS[key].label,
  leftIconProps: TAGGING_TYPE_INPUTS[key].leftIconProps,
  rightIconProps: TAGGING_TYPE_INPUTS[key].rightIconProps,
}));

export const getTaggingType = (tagging) => {
  if (!tagging) return 'default';
  if (tagging.amount != undefined && tagging.currency != undefined)
    return 'currency';
  if (tagging.year || tagging.month || tagging.day) return 'date';
  if (tagging.location) return 'location';
  if (tagging.quantity) return 'number';
  return 'default';
};

export const getTaggingValue = (taggingType, tagging) => {
  if (!tagging || !taggingType) return '';
  if (taggingType === 'date')
    return { year: tagging.year, month: tagging.month, day: tagging.day };
  if (taggingType === 'currency')
    return { amount: tagging.amount, currency: tagging.currency };
  return tagging.location || tagging.quantity;
};
export const getTaggingDisplayValue = (taggingType, tagging) => {
  if (taggingType === 'number') return tagging.quantity;
  if (taggingType === 'location') return tagging.location;
  if (taggingType === 'currency')
    return formatMoneyByCode(tagging.amount, tagging.currency);
  if (taggingType === 'date') {
    const { year, month, day } = tagging;
    if (year && month && day) return toLocaleDate(`${year}-${month}-${day}`);
    if (year && !month && !day) return year;
    if (month && !year && !day) return monthsLong[month - 1];
    if (year && month && !day) return `${monthsShort[month - 1]} ${year}`;
    return 'Invalid Date';
  }
  return null;
};

export const getAmount = (content = '') => {
  const decimal = content.match(/[,.'˙]([0-9]{2}|[0-9]{1})$/);
  const pureNumber = content.replace(/[,.'˙]/g, '');
  if (decimal) {
    const decimalRegExp = new RegExp(`[0-9]{${decimal[1]?.length}}$`);
    return Number(pureNumber.replace(decimalRegExp, `.${decimal[1]}`));
  }
  return Number(pureNumber);
};

export const getCurrency = (content = '') =>
  CURRENCIES.find(({ symbol, name, code, name_plural: namePlural }) =>
    new RegExp(`${escapeForRegExp(symbol)}|${code}|${name}|${namePlural}`).test(
      content
    )
  );
export const removeCurrency = (
  { symbol, name, code, name_plural: namePlural },
  content = ''
) =>
  content
    .replace(
      new RegExp(
        `${escapeForRegExp(symbol)}|${code}|${name}|${namePlural}`,
        'g'
      ),
      ''
    )
    .trim();
export const getCurrencyFromText = (text) => {
  const currency = getCurrency(text);
  if (!currency) return null;
  const textWithNoCurrency = removeCurrency(currency, text);
  if (!textWithNoCurrency.match(/^[0-9.,'˙]+$/)) return null;
  return { amount: getAmount(textWithNoCurrency), currency: currency.code };
};

export const isNumeric = (text) => {
  if (text === undefined || text === null || text === '') {
    return false;
  }
  const value = getAmount(text);

  return !isNaN(Number(value)) && isFinite(Number(value));
};

export const getNumberFromText = (text = '') => {
  const parseTwo = text.match(/^[,.'˙0-9]+$/);
  if (parseTwo?.[0]) return { quantity: getAmount(parseTwo[0]) };
  if (isNumeric(text)) return { quantity: Number(text) };
  const parseOne = text.match(/((\d){1,3})+([,][\d]{3})*([.](\d)*)?/);
  if (parseOne?.[0]) return { quantity: getAmount(parseOne[0]) };
  const parseThree = text.replaceAll(/[^\d\.]/g, '');
  if (isNumeric(parseThree)) return { quantity: getAmount(parseThree) };
  return;
};

export const getAutoSuggestedTaggingDetails = (taggedContent) => {
  const parsedText = String(taggedContent || '')
    .trim()
    .replace(/\n$/, '')
    .slice(0, 10)
    .trim(); // remove trailing breakline at end and limit to first 10 chars
  if (parsedText.length === 0) return null;
  return (
    getCurrencyFromText(parsedText) || getNumberFromText(parsedText) || null
  );
};

export const getAutoSuggestedTaggingType = (taggedContent) => {
  const parsedText = String(taggedContent || '')
    .trim()
    .replace(/\n$/, '')
    .slice(0, 10)
    .trim(); // remove trailing breakline at end and limit to first 10 chars
  if (parsedText.length === 0) return 'default';
  if (getCurrencyFromText(parsedText)) return 'currency';
  if (isNumeric(taggedContent)) return 'number';

  return 'default';
};
