export default [
  { value: 'column', label: 'Column' },
  { value: 'column:stacked', label: 'Stacked' },
  { value: 'bar', label: 'Bar' },
  { value: 'line', label: 'Line' },
  { value: 'area', label: 'Area' },
  { value: 'pie', label: 'Pie' },
  { value: 'heatmap', label: 'Heatmap' },
  { value: 'bubble', label: 'Bubble' },
];
