import { where } from 'im/api/Query';
import reducerRegistry, { advancedFiltersRegistry } from 'im/api/registry';
import ResourceCollection from 'im/api/ResourceCollection';
import { numberMatchers, selectMatchers } from 'src/data/matchers';
import { getText } from 'src/services/DictionaryService';
import {
  getCountryCodeByName,
  getCountryNameByCode,
} from 'src/utils/countries';

const getGroupForTag = (tag) => tag?.tag_categories?.[0] ?? {};

const apiConfig = {
  root: '/api/v3/taggings',
  type: 'taggings',
  api: {
    allow: ['findAll', 'create', 'put', 'destroy'],
    custom: {
      findAllPerProject: {
        method: 'GET',
        path: where().filter('project_id_eq', ':project_id').toString(),
      },
      findAllPerTaggable: {
        method: 'GET',
        path: where().filter('taggable_id_eq', ':taggable_id').toString(),
      },
    },
  },
  derived: () => ({ getGroupForTag }),
};

export const Taggings = new ResourceCollection(apiConfig, 'taggings');
reducerRegistry.register(Taggings);

const apiConfigQuote = {
  ...apiConfig,
  api: {
    ...apiConfig.api,
    custom: {
      findAllPerProject: {
        method: 'GET',
        path: where()
          .filter('project_id_eq', ':project_id')
          .filter('scope', 'quote')
          .toString(),
      },
      findAllPerTaggable: {
        method: 'GET',
        path: where()
          .filter('taggable_id_eq', ':taggable_id')
          .filter('scope', 'quote')
          .toString(),
      },
    },
  },
};
export const Quotes = new ResourceCollection(apiConfigQuote, 'quotes');
reducerRegistry.register(Quotes);

const advancedFiltersConfig = {
  actionPrefix: 'taggingsAF',
  allow: ['content', 'taggable_type'],
  tag_categories_title: {
    type: 'tag_categories_title',
    root: '/api/v3/tag_categories',
    path: where()
      .sort('title')
      .paginate({ size: 1500 })
      .fields('tag_category', 'title')
      .filter('tags_taggings_count_gt', 0)
      .filter('id_eq_any', ':tagCategoryIds')
      .toString(),
  },
  tag_categories_metatags: {
    type: 'tag_categories_metatags',
    root: '/api/v3/tag_categories',
    path: where()
      .sort('title')
      .paginate({ size: 1500 })
      .fields('tag_category', 'metatags')
      .filter('tags_taggings_count_gt', 0)
      .filter('id_eq_any', ':tagCategoryIds')
      .toString(),
  },
  tag_title: {
    type: 'tag',
    root: '/api/v3/tags',
    path: where()
      .sort('title')
      .paginate({ size: 1500 })
      .fields('tag', 'title', 'taggings_count')
      .filter('taggings_count_gt', 0)
      .filter('tag_categories_id_eq_any', ':tagCategoryIds')
      .toString(),
  },
  report_name: {
    type: 'report',
    root: '/v3/reports',
    path: where()
      .sort('name')
      .paginate({ size: 1500 })
      .fields('report', 'name')
      .filter('project_reports_project_id_eq', ':projectId')
      .toString(),
  },
  report_period: {
    type: 'period',
    root: '/v3/reports',
    path: where()
      .sort('period')
      .paginate({ size: 1500 })
      .fields('report', 'period')
      .filter('project_reports_project_id_eq', ':projectId')
      .toString(),
  },
  // this required special mapping in "CUSTOM_ATTRIBUTE_NAMES"
  // in AdvancedFilters.js to map it from on resource filtered attribute
  // looks potentially dangerous - but works for now!
  survey_answer_survey_title: {
    type: 'survey',
    root: '/v3/surveys',
    path: where()
      .sort('title')
      .paginate({ size: 1000 })
      .fields('survey', 'title')
      .filter('project_id_eq', ':projectId')
      .toString(),
  },
  report_grantee_name_or_survey_answer_grantee_name: {
    type: 'report_grantee_name_or_survey_answer_grantee',
    root: '/v3/grantees',
    path: where()
      .sort('name')
      .paginate({ size: 1000 })
      .fields('grantee', 'name')
      .filter('project_reports_project_id_eq', ':projectId')
      .toString(),
  },
  grantee_survey_grantee_country_code_or_report_grantee_country_code: {
    type: 'grantee_survey_grantee_country_code_or_report_grantee_country_code',
    root: '/v3/grantees',
    path: where()
      .sort('country_code')
      .paginate({ size: 1000 })
      .fields('grantee', 'country_code')
      .filter('project_reports_project_id_eq', ':projectId')
      .toString(),
  },
};

advancedFiltersRegistry.register(Taggings, advancedFiltersConfig);

export default { ...Taggings.api, quotes: Quotes.api };

export const taggingsAdvancedFilters = Taggings.advancedFilters;

export const TAGGING_AUTO_POPULATION_FIELDS = [
  'tag_categories_title',
  'tag_title',
  'report_name',
  'report_period',
  'survey_answer_survey_title',
  'report_grantee_name_or_survey_answer_grantee_name',
  'grantee_survey_grantee_country_code_or_report_grantee_country_code',
  'tag_categories_metatags',
];
const scopeValues = [
  { name: 'quote', displayName: 'Quote' },
  { name: 'content', displayName: 'Tags' },
];
const taggableValues = [
  { name: 'Report', displayName: 'Report' },
  { name: 'SurveyAnswer', displayName: 'Survey' },
];

export const TAGGING_FILTERS = () => [
  {
    name: 'scope',
    displayName: 'Tag Type',
    type: 'select',
    matchers: ['eq'],
    values: scopeValues,
    transformKeyBeforeRequest: () => 'scope',
  },
  {
    name: 'taggable_type',
    displayName: 'Source',
    type: 'select',
    matchers: selectMatchers,
    values: taggableValues,
  },
  {
    name: 'tag_categories_title',
    displayName: 'Tag Group',
    type: 'text',
    matchers: selectMatchers,
  },
  {
    name: 'tag_title',
    displayName: 'Tag Name',
    type: 'text',
    matchers: selectMatchers,
    transformResponse: (v) => {
      const decoded = decodeURIComponent(v);
      return v === decoded ? v : decoded;
    },
    transformBeforeRequest: (v) => {
      const encoded = encodeURIComponent(v);
      return v === encoded ? v : encoded;
    },
  },
  { name: 'content', displayName: 'Tagged Text', type: 'text' },
  {
    name: 'report_name',
    displayName: 'Report',
    type: 'text',
    matchers: selectMatchers,
  },
  {
    name: 'survey_answer_survey_title',
    displayName: 'Survey',
    type: 'text',
    matchers: selectMatchers,
  },
  {
    name: 'report_grantee_name_or_survey_answer_grantee_name',
    displayName: getText('Grantee'),
    type: 'text',
    matchers: selectMatchers,
  },
  {
    name: 'quantity',
    displayName: 'Quantity',
    type: 'number',
    matchers: numberMatchers,
  },
  {
    name: 'amount',
    displayName: 'Amount',
    type: 'amount',
    matchers: numberMatchers,
  },
  {
    name: 'report_period',
    displayName: 'Period',
    type: 'text',
    matchers: selectMatchers,
  },
  {
    name: 'tag_categories_metatags',
    displayName: 'Metatags',
    type: 'text',
    matchers: ['contains'],
  },
  {
    name: 'grantee_survey_grantee_country_code_or_report_grantee_country_code',
    displayName: getText('Country'),
    type: 'text',
    matchers: selectMatchers,
    transformBeforeRequest: (v) => {
      const transformedValue = (getCountryCodeByName(v) || '').toLowerCase();
      return transformedValue;
    },
    transformResponse: (v) => {
      const transformedValue = getCountryNameByCode(v);
      return transformedValue;
    },
  },
];
