import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, getFormValues, SubmissionError } from 'redux-form';
import { updateFunding } from 'src/actionCreators/fundingActionCreators';
import {
  updateGrant,
  createGrantContact,
  deleteGrantContact,
  createGrantLocation,
  updateGrantLocation,
  deleteGrantLocation,
} from 'src/actionCreators/grantsActionCreators';
import { getIntersected } from 'src/utils/customFields';
import { isSameDay } from 'src/utils/date';
import { handleSubmitFail } from 'src/utils/validation';

import GrantAdditional from '../components/GrantAdditional';
import GrantBase, { validateGrant } from '../components/GrantBase';

class GrantEdit extends Component {
  static propTypes = {
    regions: PropTypes.array,
    fundingsArray: PropTypes.array,
    fundingTypes: PropTypes.array,
    statuses: PropTypes.array,
    customGrantFields: PropTypes.array,
    grantsFilters: PropTypes.object,
    onClose: PropTypes.func,
    onSubmitSuccessful: PropTypes.func,

    updateFunding: PropTypes.func.isRequired,
    updateGrant: PropTypes.func.isRequired,
    createGrantContact: PropTypes.func.isRequired,
    deleteGrantContact: PropTypes.func.isRequired,
    createGrantLocation: PropTypes.func.isRequired,
    updateGrantLocation: PropTypes.func.isRequired,
    deleteGrantLocation: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
    change: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    formValues: PropTypes.object,
  };

  static defaultProps = {
    fundingsArray: [],
    granteesArray: [],
    customGrantFields: [],
    initialValues: {},
    onSubmitSuccessful: () => void 0,
  };
  constructor(props) {
    super(props);
    this.state = {
      customGrantFieldsValues: getIntersected(
        props.customGrantFields,
        props.initialValues.custom_fields
      ),
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.initialValues.contacts !== nextProps.initialValues.contacts
    ) {
      this.props.initialize({
        ...this.props.formValues,
        contacts: nextProps.initialValues.contacts,
      });
    }
    if (
      this.props.initialValues.locations !== nextProps.initialValues.locations
    ) {
      this.props.initialize({
        ...this.props.formValues,
        locations: nextProps.initialValues.locations,
      });
    }
    if (
      nextProps.customGrantFields !== this.props.customGrantFields ||
      nextProps.initialValues.custom_fields !==
        this.props.initialValues.custom_fields
    ) {
      this.setState({
        customGrantFieldsValues: getIntersected(
          nextProps.customGrantFields,
          nextProps.initialValues.custom_fields
        ),
      });
    }
  }

  onFieldValueChange = ({ fieldName, value }) => {
    this.props.change(fieldName, value || null);
  };
  onContactAdd = (contact) => {
    const {
      initialValues: { id },
    } = this.props;
    this.props.createGrantContact(id, contact);
  };
  onContactDelete = (contactId) => {
    const {
      initialValues: { id },
    } = this.props;
    this.props.deleteGrantContact(id, contactId);
  };
  onLocationAdd = (location) => {
    const {
      initialValues: { id },
    } = this.props;
    return this.props.createGrantLocation(id, location);
  };
  onLocationEdit = (location) => {
    const {
      initialValues: { id },
    } = this.props;
    return this.props.updateGrantLocation(id, location);
  };
  onLocationDelete = (locationId) => {
    const {
      initialValues: { id },
    } = this.props;
    this.props.deleteGrantLocation(id, locationId);
  };
  onSubmit = async (data) => {
    const { fundingsArray } = this.props;
    const funding = fundingsArray.length && fundingsArray[0];
    const { start_date: startDate, end_date: endDate, contacts } = data;
    const contactNotCreated = (contacts || []).find(({ id }) => !id);

    try {
      if (contactNotCreated) {
        await this.onContactAdd(contactNotCreated);
      }
      if (
        funding &&
        (!isSameDay(startDate, funding.start_date) ||
          !isSameDay(endDate, funding.end_date))
      ) {
        await this.onDateChange(startDate, endDate);
      }
      await this.props.updateGrant(data);
      this.props.onSubmitSuccessful();
      this.props.onClose();
    } catch (err) {
      console.log(err);
    }
  };

  onFundingTypeChange = (newType) => {
    this.onFundingUpdate({ type: newType });
  };
  onDateChange = (startDate, endDate) => {
    this.onFundingUpdate({
      start_date: startDate,
      end_date: endDate,
      type: this.props.formValues.funding_type,
    });
  };
  onFundingUpdate = (newData) => {
    return Promise.all(
      this.props.fundingsArray
        .filter((a) => Boolean(a?.grantee?.id && a?.grantee?.id))
        .map(
          ({
            id,
            grantee,
            grant,
            amount,
            currency,
            start_date,
            end_date,
            type,
          }) =>
            this.props.updateFunding({
              id,
              grantee_id: grantee.id,
              grant_id: grant.id,
              amount: amount / 100,
              currency,
              start_date,
              end_date,
              type,
              ...newData,
            })
        )
    );
  };

  render() {
    const {
      regions,
      fundingTypes,
      grantsFilters,
      form,
      fundingsArray,
      statuses,
      handleSubmit,
    } = this.props;
    return (
      <form id={form} onSubmit={handleSubmit(this.onSubmit)} autoComplete="off">
        <GrantBase
          formName={form}
          fundingsArray={fundingsArray}
          statuses={statuses}
          regions={regions}
          fundingTypes={fundingTypes}
          onFieldValueChange={this.onFieldValueChange}
          onRecipientTypeChange={() => void 0}
          onFundingTypeChange={this.onFundingTypeChange}
          onContactAdd={this.onContactAdd}
          onContactDelete={this.onContactDelete}
          onLocationAdd={this.onLocationAdd}
          onLocationEdit={this.onLocationEdit}
          onLocationDelete={this.onLocationDelete}
          isEditMode
        />
        <div style={{ marginTop: 20, marginBottom: 20 }} />
        <GrantAdditional
          possibleValues={grantsFilters}
          customFields={this.state.customGrantFieldsValues}
        />
      </form>
    );
  }
}

function validate(errors, props) {
  return { ...validateGrant(errors, { ...props, isEditMode: true }) };
}

function mapStateToProps(state, ownProps) {
  return {
    initialValues: ownProps.initialValues,
    formValues: getFormValues(ownProps.form)(state),
  };
}

const GrantEditForm = reduxForm(
  {
    validate,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    onSubmitFail: (errors) => handleSubmitFail(errors, true),
  },
  mapStateToProps
)(GrantEdit);

const ConnectedGrantEditForm = connect(mapStateToProps, (dispatch) =>
  bindActionCreators(
    {
      updateFunding,
      updateGrant,
      createGrantContact,
      deleteGrantContact,
      createGrantLocation,
      updateGrantLocation,
      deleteGrantLocation,
    },
    dispatch
  )
)(GrantEditForm);

export default ConnectedGrantEditForm;
