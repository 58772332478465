import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import cls from './PropertyBox.module.css';

const cx = classNames.bind(cls);

const PropertyBox = ({ children, className, ...rest }) => (
  <div className={cx('property-box', className)} {...rest}>
    {children}
  </div>
);

PropertyBox.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default PropertyBox;
