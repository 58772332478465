/*eslint no-undef: 0*/
import React from 'react';
import { LionessProvider } from 'lioness';
import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import { connect } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import AnswerSurvey from './AnswerSurvey';
import AnswerSurveySuccess from './AnswerSurveySuccess';
import PublicSurvey from './PublicSurvey';
import { messages } from 'src/../i18n/index';

@connect(pick(['surveyAnswers']))
export default class AnswerSurveyIndex extends React.PureComponent {
  static propTypes = {
    surveyAnswers: PropTypes.object,
    match: ReactRouterPropTypes.match,
  };
  render() {
    return (
      <LionessProvider
        messages={messages}
        locale={this.props.surveyAnswers.activeLanguage}
        debug={false}
      >
        <Switch>
          <Route
            exact
            path={`${this.props.match.path}/public/:publicId`}
            component={PublicSurvey}
          />
          <Route
            exact
            path={`${this.props.match.path}/:id`}
            component={AnswerSurvey}
          />
          <Route
            exact
            path={`${this.props.match.path}/:id/success`}
            component={AnswerSurveySuccess}
          />
          <Redirect from="*" to="/login" />
        </Switch>
      </LionessProvider>
    );
  }
}
