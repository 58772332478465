import COUNTRIES from '../data/countries.json';
// pre compute maps to save look-up time when rendering tables etc.
const countryNamesByCode = COUNTRIES.reduce((acc, country) => {
  acc[country.code.toLowerCase()] = country.name;
  return acc;
}, {});
// returns all caps ISO ALPHA-2 country codes
const countryCodesByName = COUNTRIES.reduce((acc, country) => {
  acc[country.name.toLowerCase()] = country.code;
  return acc;
}, {});

export const getCountryNameByCode = (countryCode) => {
  if (!countryCode) {
    return null;
  }
  return countryNamesByCode[countryCode.toLowerCase()] || null;
};

export const getCountryCodeByName = (countryName) => {
  if (!countryName) {
    return null;
  }
  return countryCodesByName[countryName.toLowerCase()] || null;
};
