import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from 'im/ui/Icon';
import { PopoverLight } from './PopoverLight';
import withControllableSupport from './withControllableSupport';

import cls from './SelectPopoverLight.module.css';

const cx = classNames.bind(cls);

@withControllableSupport
export class SelectPopoverLight extends React.Component {
  static propTypes = {
    popoverProps: PropTypes.shape({
      ...PopoverLight.propTypes,
      largeWidth: PropTypes.bool,
    }),
    children: PropTypes.node,
    search: PropTypes.bool,
    searchText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    labelHint: PropTypes.string,
    open: PropTypes.bool,
    flat: PropTypes.bool,
    border: PropTypes.bool,
    borderDark: PropTypes.bool,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    small: PropTypes.bool,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    renderSelected: PropTypes.func,
    renderChildren: PropTypes.func,
  };

  static defaultOrigin = { horizontal: 'left', vertical: 'top' };

  static Actions = PopoverLight.Actions;
  static ActionItem = PopoverLight.ActionItem;
  static Menu = PopoverLight.Menu;
  static MenuItem = PopoverLight.MenuItem;
  static Content = PopoverLight.Content;
  static ContentActions = PopoverLight.ContentActions;
  static Divider = PopoverLight.Divider;

  handlePopoverOpen = (ev) => {
    if (this.props.disabled) return;
    ev?.preventDefault();
    this.props.onOpen();
  };

  handlePopoverClose = () => {
    this.props.onClose();
  };

  renderSelectedOption() {
    const {
      label,
      labelHint,
      flat,
      border,
      borderDark,
      renderSelected,
      small,
      disabled,
    } = this.props;

    return (
      <div
        className={cx(cls.selectPopoverLight, {
          [cls.selectPopoverLight_flat]: flat,
          [cls.selectPopoverLight_largeWidth]:
            this.props.popoverProps?.largeWidth,
          [cls.selectPopoverLight_border]: border,
          [cls.selectPopoverLight_borderDark]: borderDark,
          [cls.selectPopoverLight_disabled]: disabled,
          [cls.selectPopoverLight_small]: small,
        })}
        onClick={this.handlePopoverOpen}
      >
        <span
          className={cx({ [cls.selectPopoverLightHint]: !label && labelHint })}
        >
          {renderSelected && label ? renderSelected(label) : label || labelHint}
        </span>
        <Icon name="chevron-down" />
      </div>
    );
  }

  render() {
    const {
      open,
      popoverProps,
      search,
      children,
      searchText,
      renderChildren,
      fullWidth,
    } = this.props;
    const customWidth = this.innerRef?.getBoundingClientRect?.().width;

    return (
      <div ref={(ref) => (this.innerRef = ref)}>
        {this.renderSelectedOption()}
        <PopoverLight
          search={search}
          searchText={searchText}
          searchHint="Find..."
          anchorEl={this.innerRef}
          open={open}
          onRequestClose={this.handlePopoverClose}
          anchorOrigin={SelectPopoverLight.defaultOrigin}
          targetOrigin={SelectPopoverLight.defaultOrigin}
          renderChildren={renderChildren}
          customWidth={customWidth}
          {...popoverProps}
          {...fullWidth}
        >
          {children}
        </PopoverLight>
      </div>
    );
  }
}
