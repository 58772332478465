import PropTypes from 'prop-types';
import path from 'ramda/src/path';
import React, { Component } from 'react';
import { Field, FormSection } from 'redux-form';

import FlexRow from 'src/components/FlexRow/FlexRow';
import {
  Actions,
  TextField,
  FormField,
  ChipInput,
  ReduxFormField,
} from 'src/components/IMUI';

import cls from './GrantAdditional.module.css';

class GrantAdditional extends Component {
  static propTypes = {
    possibleValues: PropTypes.object,
    customFields: PropTypes.object,
  };

  renderCustomFields(customFields) {
    return (
      <FormSection name="custom_fields">
        {Object.keys(customFields).map((fieldKey) => (
          <FormField key={fieldKey}>
            <Field
              component={TextField}
              label={fieldKey}
              fullWidth
              hintText="Type in..."
              name={fieldKey}
            />
          </FormField>
        ))}
      </FormSection>
    );
  }

  renderListLabel(text) {
    return (
      <FlexRow>
        <Actions>
          <span>{text}</span>
        </Actions>
        <Actions>
          <span style={{ opacity: 0.75, textAlign: 'right' }}>
            <small>
              Press <code>enter</code> or <code>,</code> to confirm adding new
              value.
            </small>
          </span>
        </Actions>
      </FlexRow>
    );
  }

  renderAdditionalFields() {
    const { possibleValues, customFields } = this.props;

    return (
      <div>
        <FormField>
          <ReduxFormField
            type="array"
            component={ChipInput}
            label={this.renderListLabel('Portfolios')}
            fullWidth
            name="programs"
            hintText="Type in..."
            dataSource={path(['portfolio_list', 'values'], possibleValues)}
          />
        </FormField>

        <FormField>
          <ReduxFormField
            type="array"
            component={ChipInput}
            label={this.renderListLabel('Issues')}
            fullWidth
            name="issues"
            hintText="Type in..."
            dataSource={path(['issue_list', 'values'], possibleValues)}
          />
        </FormField>

        <FormField>
          <ReduxFormField
            type="array"
            component={ChipInput}
            label={this.renderListLabel('Strategies')}
            fullWidth
            name="strategies"
            hintText="Type in..."
            dataSource={path(['strategies_list', 'values'], possibleValues)}
          />
        </FormField>

        <FormField>
          <ReduxFormField
            type="array"
            component={ChipInput}
            label={this.renderListLabel('Populations of focus')}
            fullWidth
            name="populations"
            hintText="Type in..."
            dataSource={path(['population_list', 'values'], possibleValues)}
          />
        </FormField>

        <FormField>
          <ReduxFormField
            type="array"
            component={ChipInput}
            label={this.renderListLabel('Donors')}
            fullWidth
            name="donors"
            hintText="Type in..."
            dataSource={path(['donor_list', 'values'], possibleValues)}
          />
        </FormField>

        <FormField>
          <ReduxFormField
            type="array"
            component={ChipInput}
            label={this.renderListLabel('Cohorts')}
            fullWidth
            name="cohort"
            hintText="Type in..."
            dataSource={path(['cohort_list', 'values'], possibleValues)}
          />
        </FormField>

        <FormField>
          <ReduxFormField
            type="array"
            component={ChipInput}
            label={this.renderListLabel('Areas of Work')}
            fullWidth
            name="area_of_work_list"
            hintText="Type in..."
            dataSource={path(['area_of_work_list', 'values'], possibleValues)}
          />
        </FormField>

        {this.renderCustomFields(customFields)}
      </div>
    );
  }

  render() {
    return (
      <div>
        <h3 className={cls.titleCustomMargin}>Additional information</h3>
        {this.renderAdditionalFields()}
      </div>
    );
  }
}

export default GrantAdditional;
