import PropTypes from 'prop-types';
import isEmpty from 'ramda/src/isEmpty';
import path from 'ramda/src/path';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, Field, getFormValues } from 'redux-form';
import {
  getCountryStates,
  getCountryStateCities,
} from 'src/actionCreators/geoLocationActionCreators';
import { getCountryNameByCode } from 'src/utils/countries';
import FlexColumn from 'src/components/FlexColumn/FlexColumn';
import FlexRow from 'src/components/FlexRow/FlexRow';
import { SelectField, FormField, AutoCompleteField } from 'src/components/IMUI';
import debounce from 'src/utils/debounce';
import createValidator, { handleSubmitFail } from 'src/utils/validation';
import cls from './LocationInputs.module.css';

const countryList = (countries) =>
  [
    <SelectField.Item key="null" value={null}>
      -
    </SelectField.Item>,
  ].concat(
    countries
      .filter((i) => i?.name)
      .map(({ code, name }) => (
        <SelectField.Item
          key={code}
          value={code.toUpperCase()}
          primaryText={name}
        />
      ))
  );
const regionsList = (regions) =>
  [
    <SelectField.Item key="null" value={null}>
      -
    </SelectField.Item>,
  ].concat(
    regions
      .filter((i) => i?.name)
      .map(({ name }, index) => (
        <SelectField.Item key={index} value={name} primaryText={name} />
      ))
  );

const FIELD_NAMES = {
  countryCode: 'countryCode',
  state: 'state',
  city: 'city',
  region: 'region',
};
const findByName = (arr = [], name = '') =>
  arr.find(
    ({ name: elName }) =>
      (name || '').toUpperCase() == (elName || '').toUpperCase()
  );
const compare = (a, b) =>
  String(a || '')
    .toLocaleLowerCase()
    .trim()
    .includes(
      String(b || '')
        .toLocaleLowerCase()
        .trim()
    ) ||
  String(b || '')
    .toLocaleLowerCase()
    .trim()
    .includes(
      String(a || '')
        .toLocaleLowerCase()
        .trim()
    );

class LocationInputs extends React.PureComponent {
  static propTypes = {
    getCountryStates: PropTypes.func,
    getCountryStateCities: PropTypes.func,
    change: PropTypes.func,
    initialize: PropTypes.func,
    regions: PropTypes.array.isRequired,
    formValues: PropTypes.object,
    fields: PropTypes.shape({
      countryCode: PropTypes.object,
      state: PropTypes.object,
      city: PropTypes.object,
      region: PropTypes.object,
    }),
    geoLocation: PropTypes.object,
    editValues: PropTypes.object,
    organizationCurrent: PropTypes.object,
  };

  static defaultProps = {
    geoLocation: {},
    editValues: {},
    fields: {
      [FIELD_NAMES.countryCode]: { name: FIELD_NAMES.countryCode },
      [FIELD_NAMES.state]: { name: FIELD_NAMES.state },
      [FIELD_NAMES.city]: { name: FIELD_NAMES.city },
      [FIELD_NAMES.region]: { name: FIELD_NAMES.region },
    },
    formValues: {},
    regions: [],
  };

  static handleSubmitData(data, dispatch, props) {
    const { countryCode, state, city, region } = data;
    props.onSubmitAndValid({
      id: data.id,
      [props.fields.countryCode.name]: countryCode,
      [props.fields.state.name]: state && state.name,
      [props.fields.city.name]: city && city.name,
      [props.fields.region.name]: region,
    });
  }

  state = { cityQuery: '', stateQuery: '' };

  componentDidMount() {
    if (!isEmpty(this.props.editValues)) {
      this.handleInitialize(this.props.editValues);
    }
  }

  onRegionChange = (region) => {
    if (this.props.formValues.region == region) return;
    const currentRegion = this.props.regions?.find((t) =>
      t.country_codes?.includes(this.props.formValues.countryCode)
    )?.id;
    if (currentRegion == region) return;
    this.props.change(FIELD_NAMES.region, region);
    this.props.change(FIELD_NAMES.countryCode, null);
    this.props.change(FIELD_NAMES.city, null);
    this.props.change(FIELD_NAMES.state, null);
  };

  customRegionMapping = () =>
    this.props.organizationCurrent.data.custom_region_mapping;

  onCountryChange = (code) => {
    if (this.props.formValues.countryCode == code) return;
    if (code == null) {
      this.props.change(FIELD_NAMES.countryCode, null);
      this.props.change(FIELD_NAMES.state, null);
      this.props.change(FIELD_NAMES.city, null);
      this.props.change(FIELD_NAMES.region, null);
      return;
    }

    this.props.change(FIELD_NAMES.countryCode, code);
    this.props.change(FIELD_NAMES.state, null);
    this.props.change(FIELD_NAMES.city, null);
    if (!this.props.geoLocation.states[code]) {
      this.props.getCountryStates(code);
    }

    const region = Object.entries(this.customRegionMapping()).find(
      ([_, countryCodes]) =>
        countryCodes.includes(code?.toUpperCase()) ||
        countryCodes.includes(code?.toLowerCase())
    )?.[0];
    this.props.change(FIELD_NAMES.region, region);
  };
  onStateChange = (state) => {
    if (!state?.name) return;
    if (this.props.formValues.state?.name == state?.name) return;
    if (!this.props.geoLocation.states[this.props.formValues.countryCode]) {
      this.props.getCountryStates(this.props.formValues.countryCode);
    }
    if (
      !this.props.geoLocation.states?.[this.props.formValues.countryCode]?.items
    ) {
      this.props.getCountryStateCities(
        this.props.formValues.countryCode,
        state.adminCode1,
        ''
      );
    }
    this.props.change(FIELD_NAMES.state, state.name);
    this.props.change(FIELD_NAMES.city, null);
  };
  onCityChange = (city) => {
    const state = this.props.geoLocation.states?.[
      this.props.formValues.countryCode
    ]?.items?.find(({ adminCode1 }) => city?.adminCode1 == adminCode1);
    if (!this.props.geoLocation.states[this.props.formValues.countryCode]) {
      this.props.getCountryStates(this.props.formValues.countryCode);
    }
    city && this.props.change(FIELD_NAMES.state, state);
    city && this.props.change(FIELD_NAMES.city, city?.name);
  };

  onStateInputUpdate = debounce((searchText, _dataSource, params) => {
    if (params?.source !== 'change') return;
    const stateQuery = searchText?.trim() ?? '';
    if (stateQuery.length == 0) {
      this.props.change(FIELD_NAMES.state, null);
      this.props.change(FIELD_NAMES.city, null);
    }
    this.setState({ stateQuery });
  }, 150);

  onCityInputUpdate = debounce((searchText, _dataSource, params) => {
    if (params?.source !== 'change') return;
    const cityQuery = searchText?.trim() ?? '';
    if (
      this.props.fields.state.name &&
      !this.state.cityQuery?.length &&
      cityQuery?.length > 0
    )
      this.props.change(FIELD_NAMES.state, null);
    this.setState({ cityQuery });
    if (cityQuery?.length > 0) {
      this.props.getCountryStateCities(
        this.props.formValues.countryCode,
        '',
        searchText
      );
    }
  }, 150);

  handleInitialize(editValues) {
    const initValues = {
      id: editValues.id,
      [FIELD_NAMES.countryCode]: editValues[this.props.fields.countryCode.name],
      [FIELD_NAMES.state]: editValues[this.props.fields.state.name],
      [FIELD_NAMES.city]: editValues[this.props.fields.city.name],
      [FIELD_NAMES.region]: editValues[this.props.fields.region.name],
    };

    const countryCode = initValues[FIELD_NAMES.countryCode];
    let getStatesInProgress = false;
    if (!countryCode) return this.props.initialize(initValues);

    if (!this.props.geoLocation.states[countryCode]) {
      getStatesInProgress = true;
      this.props.getCountryStates(countryCode);
    }
    if (this.props.geoLocation.states[countryCode]) {
      initValues[FIELD_NAMES.state] = findByName(
        this.props.geoLocation.states[countryCode].items,
        editValues[this.props.fields.state.name]
      );
      this.forceUpdate();
    }

    const state = initValues[FIELD_NAMES.state];
    const city = initValues[FIELD_NAMES.city];
    const citiesFiltered = this.filteredCities(city);
    if (!citiesFiltered && !getStatesInProgress && city && city.trim()) {
      const adminCode1 =
        state && typeof state == 'object' ? state.adminCode1 : null;
      this.props.getCountryStateCities(countryCode, adminCode1, city);
    } else if (citiesFiltered && city) {
      initValues[FIELD_NAMES.city] = findByName(citiesFiltered.items, city);
    }

    this.props.initialize(initValues);
  }

  filteredCities(_cityQuery) {
    const cityQuery = _cityQuery || '';
    const pathLocation = [
      this.props.formValues.countryCode,
      this.props.formValues.state?.adminCode1 || '0',
    ];
    const citiesByState =
      path(pathLocation, this.props.geoLocation.cities) || {};
    const alreadySaved = Object.keys(citiesByState || []).some((key) =>
      compare(key, citiesByState)
    );
    if (alreadySaved) {
      return citiesByState[cityQuery];
    }

    const cityQueryKey = Object.keys(citiesByState || [])
      .filter((key) => key.length <= cityQuery.length)
      .sort((a, b) => b.length - a.length)
      .find((key) => cityQuery.toUpperCase().startsWith(key.toUpperCase()));

    return (
      cityQueryKey &&
      citiesByState[cityQueryKey].total &&
      citiesByState[cityQueryKey]
    );
  }

  countryOptions = () => {
    const customCountryOptions = [];

    const regionMapping = this.props.formValues.region
      ? {
          [this.props.formValues.region]:
            this.customRegionMapping()[this.props.formValues.region],
        }
      : this.customRegionMapping();

    Object.values(regionMapping)?.forEach((countryCodes) => {
      countryCodes?.forEach((countryCode) => {
        customCountryOptions.push({
          name: getCountryNameByCode(countryCode),
          code: countryCode,
        });
      });
    });

    return customCountryOptions
      .filter(({ code }) =>
        this.props.regions.find(
          ({ name }) => name == this.props.formValues.region
        )?.name
          ? compare(
              this.customRegionMapping()[
                this.props.regions.find(
                  ({ name }) => name == this.props.formValues.region
                )?.name
              ],
              code?.toLowerCase()
            )
          : true
      )
      .sort((a, b) => (a.name || '-').localeCompare(b?.name));
  };

  regionOptions = () => {
    return Object.keys(this.customRegionMapping()).map((name) => ({
      name,
    }));
  };

  render() {
    const countries = this.countryOptions();
    const regions = this.regionOptions();

    const states = {
      items: (
        this.props.geoLocation.states?.[
          this.props.formValues?.countryCode?.toUpperCase()
        ] ?? {}
      ).items?.filter((s) =>
        this.state.stateQuery ? compare(s.name, this.state.stateQuery) : true
      ),
      total: Boolean(this.state.stateQuery),
    };
    const cities = this.filteredCities(this.state.cityQuery) || [];
    const cityDataSourceConfig = {
      text: this.props.formValues.state ? 'name' : 'nameAndState',
      value: 'id',
    };
    const countryKey = this.props.formValues.region ?? 'region';
    const stateKey = this.props.formValues.countryCode ?? 'country';
    const cityKey = this.props.formValues.state?.name ?? 'state';

    return (
      <div>
        <FormField key={countryKey} anchorScrollName={FIELD_NAMES.countryCode}>
          <Field
            onChange={this.onCountryChange}
            component={SelectField}
            label={`Country ${
              this.props.formValues.countryCode
                ? ''
                : '(start with selecting country)'
            }`}
            labelClassName={cls.textNoWrap}
            name={FIELD_NAMES.countryCode}
            input={{
              value: this.props.formValues.countryCode?.toUpperCase(),
              onChange: this.onCountryChange,
            }}
            hintText="Choose"
          >
            {countryList(countries)}
          </Field>
        </FormField>
        <FlexRow key={stateKey} style={{ flexWrap: 'nowrap' }}>
          <FlexColumn neighbourSpacing>
            <FormField anchorScrollName={FIELD_NAMES.state}>
              <Field
                fullWidth
                onChange={this.onStateChange}
                onUpdateInput={this.onStateInputUpdate}
                component={AutoCompleteField}
                label="State"
                hintText="Type in state name"
                showTextTip={!states.total}
                dataSource={states.items || []}
                dataSourceConfig={{ text: 'name', value: 'id' }}
                name={FIELD_NAMES.state}
                disabled={!this.props.formValues.countryCode}
              />
            </FormField>
          </FlexColumn>
          <FlexColumn neighbourSpacing>
            <FormField key={cityKey} anchorScrollName={FIELD_NAMES.city}>
              <Field
                fullWidth
                onChange={this.onCityChange}
                onUpdateInput={this.onCityInputUpdate}
                component={AutoCompleteField}
                label="City"
                hintText="Type in city name"
                showTextTip={!cities.total}
                dataSource={cities.items || []}
                dataSourceConfig={cityDataSourceConfig}
                name={FIELD_NAMES.city}
                disabled={!this.props.formValues.countryCode}
              />
            </FormField>
          </FlexColumn>
        </FlexRow>
        <FormField anchorScrollName={FIELD_NAMES.region}>
          <Field
            onChange={this.onRegionChange}
            component={SelectField}
            label="Region"
            name={FIELD_NAMES.region}
            hintText="Choose"
          >
            {regionsList(regions)}
          </Field>
        </FormField>
      </div>
    );
  }
}

function validate(values, props) {
  const { fields = {} } = props;
  const region = values[FIELD_NAMES.region];
  return createValidator({
    [FIELD_NAMES.countryCode]: [
      [
        (v) =>
          region !== 'International' &&
          (fields[FIELD_NAMES.countryCode] || {}).required &&
          !v,
        () => 'Country is required',
      ],
    ],
    [FIELD_NAMES.state]: [
      [
        (v) =>
          region !== 'International' &&
          (fields[FIELD_NAMES.state] || {}).required &&
          !v,
        () => 'State is required',
      ],
      [
        (v) => v && !v.id,
        () => 'Unknown state. Please select one from the dropdown',
      ],
    ],
    [FIELD_NAMES.city]: [
      [
        (v) => v && !v.id,
        () => 'Unknown city. Please select one from the dropdown',
      ],
    ],
    [FIELD_NAMES.region]: [
      [
        (v) => (fields[FIELD_NAMES.region] || {}).required && !v,
        () => 'Region is required',
      ],
    ],
  })(values);
}

const LocationInputsFormsConnected = connect(
  (state, ownProps) => ({
    user: state.user,
    organizationCurrent: state.organizationCurrent,
    formValues: getFormValues(ownProps.form)(state),
    geoLocation: state.geoLocation,
    regions: state.defaultRegions,
  }),
  (dispatch) =>
    bindActionCreators({ getCountryStates, getCountryStateCities }, dispatch)
)(
  reduxForm({
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    validate,
    onSubmit: LocationInputs.handleSubmitData,
    onSubmitFail: (errors, dispatch, submitError, props) =>
      handleSubmitFail(errors, props.isInDialog),
  })(LocationInputs)
);
export default LocationInputsFormsConnected;
