import React from 'react';
import MenuItem from 'material-ui/MenuItem';
import PropTypes from 'prop-types';
import identity from 'ramda/src/identity';
import isEmpty from 'ramda/src/isEmpty';
import isNil from 'ramda/src/isNil';
import pickBy from 'ramda/src/pickBy';
import { connect } from 'react-redux';
import { Field, FieldArray, touch, focus } from 'redux-form';
import { confirm } from 'src/components/ConfirmModal/ConfirmModal';
import FlexColumn from 'src/components/FlexColumn/FlexColumn';
import FlexRow from 'src/components/FlexRow/FlexRow';
import {
  NumberField,
  FormField,
  SelectField,
  DatePicker,
  ReduxFormField,
  Divider,
} from 'src/components/IMUI';
import GrantDropdown from './GrantDropdown';
import GranteeDropdown from './GranteeDropdown';
import ProceedCancelButtons from 'src/components/ProceedCancelButtons/ProceedCancelButtons';
import CURRENCIES from 'src/data/currencies.json';
import { getOrgText } from 'src/services/DictionaryService';
import createValidator from 'src/utils/validation';
import cls from './FundingInfo.module.css';

export function validateFundings(values) {
  const not = (fn) => (v) => !fn(v);
  const isDefined = (v) => Boolean(v);
  const lessThanZero = (v) => isNil(v) || v < 0;
  const fundingsErrors = [];

  const fundingValidator = createValidator({
    grant_id: [
      [not(isDefined), () => `${getOrgText('Grant')} has to be selected`],
    ],
    grantee_id: [
      [not(isDefined), () => `${getOrgText('Grantee')} has to be selected`],
    ],
    amount: [[lessThanZero, () => 'Amount is required']],
    currency: [[not(isDefined), () => 'Currency is required']],
    start_date: [[not(isDefined), () => 'Start Date is required']],
    end_date: [[not(isDefined), () => 'End Date is required']],
  });

  values?.fundings?.forEach((i, index) => {
    fundingsErrors[index] = fundingValidator(i);
  });

  return { fundings: fundingsErrors };
}

@connect(null, { touchFields: touch, focus })
class FundingInfo extends React.PureComponent {
  static propTypes = {
    onFieldValueChange: PropTypes.func,
    fundingTypes: PropTypes.array,
    isEditMode: PropTypes.bool,
    currency: PropTypes.string,
    quickMode: PropTypes.bool,

    touchFields: PropTypes.func.isRequired,
    fundings: PropTypes.array,
    formErrors: PropTypes.object,
    formName: PropTypes.string,
    fundingIndex: PropTypes.any,

    onUpdateFunding: PropTypes.func,
    onCreateFunding: PropTypes.func,
    onRequestClose: PropTypes.func,
  };

  static defaultProps = { fundings: [] };

  onFundingUpdate = (fieldsNames, index) => {
    const granteesErrors =
      (this.props.formErrors && this.props.formErrors.fundings) || [];
    const grantsErrors =
      (this.props.formErrors && this.props.formErrors.fundings) || [];
    if (
      !isEmpty(pickBy(identity, granteesErrors[index], grantsErrors[index]))
    ) {
      this.props.touchFields(this.props.formName, ...fieldsNames);
      return;
    }

    if (this.props.fundings[index]?.id) {
      this.props.onUpdateFunding(this.props.fundings[index]);
    } else if (this.props.onCreateFunding && this.props.fundings[index]) {
      this.props.onCreateFunding(this.props.fundings[index]);
    }
    this.props.onRequestClose?.();
  };

  onFundingCurrencyChange = (ev, newValue, previousValue) => {
    ev.preventDefault?.();
    if (newValue === previousValue) return;
    if (
      (this.props.isEditMode && newValue === this.props.currency) ||
      this.props.fundings.length < 2
    ) {
      return this.props.onFieldValueChange({
        fieldName: `fundings[${this.fundingIndex}].currency`,
        value: newValue,
      });
    }
    return confirm(
      'Change funding currency',
      `Are you sure you want to change the currency? This change will be applied to every added funding`,
      true
    )
      .then(() => {
        this.props.fundings.forEach((_, index) => {
          this.props.fundings[index].id &&
            this.props.onUpdateFunding({
              ...this.props.fundings[index],
              currency: newValue,
            });
          this.props.onFieldValueChange({
            fieldName: `fundings[${index}].currency`,
            value: newValue,
          });
        });
      })
      .catch(() =>
        this.props.touchFields(
          this.props.formName,
          `fundings[${this.fundingIndex}].currency`
        )
      );
  };
  onFundingTypeChange = (ev, newValue, previousValue) => {
    ev?.preventDefault?.();
    if (newValue === previousValue) return;
    return this.props.onFieldValueChange({
      fieldName: `fundings[${this.fundingIndex}].funding_type`,
      value: newValue,
    });
  };
  get fundingIndex() {
    return this.props.fundingIndex || this.props.fundings.length - 1;
  }

  renderLastFunding = () => {
    const fieldsNames = {
      start_date: `fundings[${this.fundingIndex}].start_date`,
      end_date: `fundings[${this.fundingIndex}].end_date`,
      granteeId: `fundings[${this.fundingIndex}].grantee_id`,
      grantId: `fundings[${this.fundingIndex}].grant_id`,
      amount: `fundings[${this.fundingIndex}].amount`,
      currency: `fundings[${this.fundingIndex}].currency`,
      funding_type: `fundings[${this.fundingIndex}].funding_type`,
    };

    return (
      <div>
        <GranteeDropdown
          formName={this.props.formName}
          fieldsNames={fieldsNames}
        />

        <GrantDropdown
          formName={this.props.formName}
          fieldsNames={fieldsNames}
        />

        {!this.props.quickMode && <Divider />}

        {!this.props.quickMode && <h4>Funding Details</h4>}

        {!this.props.quickMode && (
          <FlexRow style={{ flexWrap: 'nowrap' }}>
            <FlexColumn neighbourSpacing size={1 / 2}>
              <FormField anchorScrollName={fieldsNames.start_date}>
                <ReduxFormField
                  type="date"
                  name={fieldsNames.start_date}
                  component={DatePicker}
                  label="Start Date"
                  hintText="Choose"
                  fullWidth
                />
              </FormField>
            </FlexColumn>
            <FlexColumn neighbourSpacing size={1 / 2}>
              <FormField anchorScrollName={fieldsNames.end_date}>
                <ReduxFormField
                  type="date"
                  name={fieldsNames.end_date}
                  component={DatePicker}
                  label="End Date"
                  hintText="Choose"
                  fullWidth
                />
              </FormField>
            </FlexColumn>
          </FlexRow>
        )}
        {!this.props.quickMode && (
          <FlexRow style={{ flexWrap: 'nowrap' }}>
            <FlexColumn neighbourSpacing>
              <FormField anchorScrollName={fieldsNames.amount}>
                <Field
                  component={NumberField}
                  label="Amount"
                  delimitingWithCommas
                  fullWidth
                  name={fieldsNames.amount}
                  hintText="Type in amount"
                  min={0}
                />
              </FormField>
            </FlexColumn>

            <FlexColumn neighbourSpacing>
              <FormField anchorScrollName={fieldsNames.currency}>
                <Field
                  onChange={this.onFundingCurrencyChange}
                  component={SelectField}
                  format={(value) => value && value.toLowerCase()}
                  label="Currency"
                  hintText="Choose"
                  fullWidth
                  name={fieldsNames.currency}
                >
                  {CURRENCIES.map(({ code }) => (
                    <MenuItem
                      value={code.toLowerCase()}
                      key={code}
                      primaryText={code}
                    />
                  ))}
                </Field>
              </FormField>
            </FlexColumn>
          </FlexRow>
        )}
        {!this.props.quickMode && (
          <FlexRow style={{ flexWrap: 'nowrap' }}>
            <FlexColumn neighbourSpacing>
              <FormField anchorScrollName={fieldsNames.funding_type}>
                <Field
                  onChange={this.onFundingTypeChange}
                  component={SelectField}
                  label="Funding Type"
                  hintText="Choose"
                  fullWidth
                  name={fieldsNames.funding_type}
                >
                  <MenuItem value={null} primaryText="None" />
                  {this.props.fundingTypes.map((type) => (
                    <MenuItem value={type} key={type} primaryText={type} />
                  ))}
                </Field>
              </FormField>
            </FlexColumn>
          </FlexRow>
        )}
        {this.props.isEditMode && (
          <ProceedCancelButtons
            size="l"
            cancelProps={{
              disabled:
                !this.props.isEditMode && this.props.fundings.length < 2,
              onClick: this.props.onRequestClose,
            }}
            proceedProps={{
              onClick: () =>
                this.onFundingUpdate(
                  Object.values(fieldsNames),
                  this.fundingIndex
                ),
              label: 'Update',
            }}
          />
        )}
      </div>
    );
  };

  render() {
    return (
      <div className={!this.props.onRequestClose ? cls.fundingInfoWrapper : ''}>
        <FieldArray name="fundings" component={this.renderLastFunding} />
      </div>
    );
  }
}

export default FundingInfo;
