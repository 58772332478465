import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import React from 'react';
import { connect } from 'react-redux';
import { v1 as uuidv1 } from 'uuid';
import { Button, ChipInput, Tag } from 'src/components/IMUI';
import {
  KEYCODE_ENTER,
  KEYCODE_COMMA,
} from 'src/components/IMUI/Forms/ChipInput';
import { getSurveyI18n } from 'src/utils/surveysI18n';
import { Icon } from 'im/ui/Icon';
import BaseQuestionProperties from './BaseQuestionProperties';
import RestrictAnswersSetting from './RestrictAnswersSetting';
import { canBuildSurvey } from 'src/userStorage';
import COUNTRIES from 'src/data/countries.json';

@connect(pick(['survey']))
export default class CountryQuestionProperties extends React.PureComponent {
  static propTypes = {
    survey: PropTypes.object,
    question: PropTypes.object,
    completeness: PropTypes.object,
    errors: PropTypes.object,
    tagsWithTagGroups: PropTypes.array,
    onChangeQuestionProperty: PropTypes.func,
    changeQuestionI18n: PropTypes.func,
  };

  static defaultProps = {
    errors: {},
    question: {},
  };

  static validate(question = {}) {
    const errors = {};
    if (!question?.options?.length)
      errors.noOptions = 'Must have at least one option';
    question?.options?.forEach((option, i) => {
      if (!option?.title || !option?.title.trim()) {
        errors.options ||= [];
        errors.options[i] = 'Option title is required';
      }
    });
    return errors;
  }

  get options() {
    return this.props.question.options || [];
  }

  renderSettings = () => (
    <RestrictAnswersSetting
      id={this.props.question.id}
      settings={this.props.question.settings || {}}
      options={this.options || []}
      onChangeQuestionProperty={this.props.onChangeQuestionProperty}
      label="Require a number of selected options"
    />
  );

  handleOptionRemove = (title) => {
    this.props.onChangeQuestionProperty(
      this.props.question.id,
      'options',
      this.options.filter((o) => o.title != title)
    );
  };
  handleOptionAllCountries = () => {
    this.props.onChangeQuestionProperty(
      this.props.question.id,
      'options',
      COUNTRIES.map((c) => ({ title: c.name, id: uuidv1() }))
    );
  };
  handleOptionRemoveAll = () => {
    this.props.onChangeQuestionProperty(this.props.question.id, 'options', []);
  };

  handleAddCountries = (inclusion) => {
    if (!Array.isArray(inclusion)) return;
    const newCountries = inclusion?.filter(
      (i) => !this.options.some((o) => o.title == inclusion)
    );
    const toAdd = newCountries.map((c) => ({ title: c, id: uuidv1() }));
    this.props.onChangeQuestionProperty(this.props.question.id, 'options', [
      ...this.options,
      ...toAdd,
    ]);
  };

  remainingCountries() {
    const titles = this.options.map((o) => o.title);
    return COUNTRIES.filter((c) => !titles.includes(c.name)).map((c) => c.name);
  }

  render() {
    const remaining = this.remainingCountries();
    const sortedOptions = this.options.sort((a, b) =>
      a.title?.localeCompare(b.title)
    );
    return (
      <BaseQuestionProperties
        {...this.props}
        renderSettings={this.renderSettings}
        hasRequired={false}
        hasOther={false}
      >
        <h3>
          <Icon name="globe" />
          &nbsp;Available Countries
        </h3>
        {remaining.length > 0 && (
          <Button
            disabled={!canBuildSurvey()}
            action
            size="m"
            label="Include all countries"
            onClick={() => this.handleOptionAllCountries()}
            icon={<Icon name="plus" style={{ fontSize: 14 }} />}
            style={{ marginLeft: 4, placeSelf: 'start' }}
          />
        )}
        {this.options.length > 0 && (
          <Button
            disabled={!canBuildSurvey()}
            action
            size="m"
            label="Remove all countries"
            onClick={() => this.handleOptionRemoveAll()}
            icon={<Icon name="close" style={{ fontSize: 14 }} />}
            style={{ marginLeft: 4, placeSelf: 'start' }}
          />
        )}
        <div style={{ marginTop: 24 }} />

        {remaining.length > 0 && (
          <ChipInput
            key={remaining.length}
            canAddNewValues={true}
            border
            newChipKeyCodes={[KEYCODE_ENTER, KEYCODE_COMMA]}
            chipProps={{ square: true }}
            type="array"
            name="items"
            dataSource={remaining}
            hintText="Add a new country"
            value={[]}
            onChange={this.handleAddCountries}
          />
        )}

        {sortedOptions.map((option = {}, index) => (
          <span
            key={`option-${option.id || index}`}
            style={{
              display: 'inline-block',
              marginRight: 6,
              lineHeight: '23px',
            }}
          >
            <Tag
              key={`option-${option.id || index}`}
              inline
              square
              leftAlign
              value={
                getSurveyI18n(
                  this.props.survey,
                  this.props.question,
                  option.id,
                  ['options', index, 'title']
                ) || ''
              }
              label={
                getSurveyI18n(
                  this.props.survey,
                  this.props.question,
                  option.id,
                  ['options', index, 'title']
                ) || ''
              }
              labelProps={{
                style: {
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'block',
                  fontSize: 12,
                  letterSpacing: '-0.25px',
                  maxWidth: 300,
                  maxHeight: 20,
                },
              }}
              onRemove={(ev) => {
                ev.stopPropagation();
                this.handleOptionRemove(option.title);
              }}
            />
          </span>
        ))}
      </BaseQuestionProperties>
    );
  }
}
