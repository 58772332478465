import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Icon } from 'im/ui/Icon';

import { AppcuesTooltip } from './AppcuesTooltip';

import cls from './TooltipIcon.module.css';

const cx = classNames.bind(cls);

export default class TooltipIcon extends Component {
  static propTypes = {
    iconName: PropTypes.node,
    iconClassName: PropTypes.string,
    tip: PropTypes.node,
    tipProps: PropTypes.object,
  };

  static defaultProps = { iconName: 'question-filled' };

  render() {
    const { iconName, iconClassName, tip, tipProps } = this.props;

    return (
      <div className={cls.helpAppcuesIcon}>
        <Icon
          name={iconName}
          tip={tip}
          className={cx(cls.helpAppcuesImg, iconClassName)}
        />
        <AppcuesTooltip html={false} {...tipProps} />
      </div>
    );
  }
}
