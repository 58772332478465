import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import identity from 'ramda/src/identity';
import isEmpty from 'ramda/src/isEmpty';
import isNil from 'ramda/src/isNil';
import pickBy from 'ramda/src/pickBy';
import symmetricDifference from 'ramda/src/symmetricDifference';
import { connect } from 'react-redux';
import {
  arraySplice,
  FormSection,
  arrayPush,
  arrayRemove,
  clearFields,
  touch,
  change,
} from 'redux-form';
import { Actions, Button, Container } from 'src/components/IMUI';
import COUNTRIES from 'src/data/countries.json';
import {
  getCountryCodeByName,
  getCountryNameByCode,
} from 'src/utils/countries';
import { Icon } from 'im/ui/Icon';
import CustomRegionMappingItem, {
  validateCustomRegion,
} from './CustomRegionMappingItem';
import cls from './CustomRegionMapping.module.css';
import { canManageOrganization } from 'src/userStorage';
const CUSTOM_REGION_MAPPING = 'custom_region_mapping';

export const areRegionsTheSame = (arr = [], arrOther = []) => {
  if (arr.length !== arrOther.length) {
    return false;
  }
  return !symmetricDifference(arr, arrOther).length;
};
export function validateCustomRegions(values = {}) {
  return {
    [CUSTOM_REGION_MAPPING]: validateCustomRegion(
      values[CUSTOM_REGION_MAPPING]
    ),
  };
}

@connect(null, {
  arrayPush,
  arrayRemove,
  arraySplice,
  clearFields,
  touch,
  change,
})
export default class CustomRegionMapping extends React.PureComponent {
  static propTypes = {
    form: PropTypes.string,
    formErrors: PropTypes.object,
    regions: PropTypes.object,
    arrayPush: PropTypes.func,
    arrayRemove: PropTypes.func,
    arraySplice: PropTypes.func,
    clearFields: PropTypes.func,
    touch: PropTypes.func,
    change: PropTypes.func,
    defaultValues: PropTypes.array,
  };

  static defaultProps = { regions: {}, defaultValues: [] };

  state = {
    addInProgress: false,
    editIndex: null,
    countries: COUNTRIES.map(({ name }) => name),
  };

  onCustomRegionRequest = () => {
    this.onEditItem(null);
    this.setState({ addInProgress: true });
  };

  onSubmitItem = () => {
    const { form, regions } = this.props;
    const { editIndex } = this.state;
    const { name, items: countries } = regions.current;
    const countryCodes = countries?.map(getCountryCodeByName) ?? [];

    if (isNil(editIndex)) {
      this.props.arrayPush(this.props.form, `${CUSTOM_REGION_MAPPING}.items`, {
        name,
        countryCodes,
      });
    } else {
      this.props.arraySplice(
        form,
        `${CUSTOM_REGION_MAPPING}.items`,
        editIndex,
        1,
        { name, countryCodes }
      );
    }
    this.setState({ addInProgress: false, editIndex: null });
  };

  onSubmitItemFailed = () => {
    this.props.touch(
      this.props.form,
      `${CUSTOM_REGION_MAPPING}.current.name`,
      `${CUSTOM_REGION_MAPPING}.current.items`
    );
  };
  onCancel = () => {
    this.setState({ addInProgress: false, editIndex: null });
  };

  onEditItem = (index) => {
    const editItem = this.props.regions.items?.[index] || {};
    if (!editItem) return;
    const editItemCountries = (editItem.countryCodes || []).map(
      getCountryNameByCode
    );
    this.props.change(this.props.form, `${CUSTOM_REGION_MAPPING}.current`, {
      name: editItem.name,
      items: editItemCountries,
    });
    this.setState({ editIndex: index });
  };

  onDeleteItem = (index) => {
    this.props.arrayRemove(
      this.props.form,
      `${CUSTOM_REGION_MAPPING}.items`,
      index
    );
  };
  onRestoreDefaults = () => {
    this.onEditItem(-1);
    this.props.change(
      this.props.form,
      `${CUSTOM_REGION_MAPPING}.items`,
      this.props.defaultValues
    );
  };
  isCurrentItemValid = (errors = {}) =>
    isEmpty(pickBy(identity, (errors[CUSTOM_REGION_MAPPING] || {}).current));
  resetCurrentItem = () => {
    this.props.clearFields(
      this.props.form,
      false,
      false,
      `${CUSTOM_REGION_MAPPING}.current.name`,
      `${CUSTOM_REGION_MAPPING}.current.items`
    );
  };

  renderRegions = (regions) => (
    <div>
      <ul className={cls.customRegionMappingList}>
        {(regions || []).map((region, index) => {
          const itemClasses = classNames(cls.crmListItem, {
            [cls.crmListItemActive]: index === this.state.editIndex,
          });
          if (index === this.state.editIndex) {
            return (
              <li key={`r_${index}`} className={itemClasses}>
                <h4 className={cls.crmListItemTitle}>
                  <span>{region.name}</span>
                </h4>
                {this.renderEditForm()}
              </li>
            );
          }
          return (
            <li key={`r_${index}`} className={cls.crmListItem}>
              <h4 className={cls.crmListItemTitle}>
                <span>{region.name}</span>
                <Actions small>
                  <Icon
                    disabled={!canManageOrganization()}
                    name="edit"
                    tip="Edit"
                    onClick={() => this.onEditItem(index)}
                  />
                  <Icon
                    disabled={!canManageOrganization()}
                    name="trash"
                    tip="Remove"
                    onClick={() => this.onDeleteItem(index)}
                  />
                </Actions>
              </h4>
              <div className={cls.crmListItemContent}>
                {(region.countryCodes || [])
                  .reduce(
                    (acc, countryCode) =>
                      acc.concat(`${getCountryNameByCode(countryCode)}, `),
                    ''
                  )
                  .slice(0, -2)}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );

  renderEditForm() {
    const { countries } = this.state;
    const { formErrors } = this.props;
    return (
      <CustomRegionMappingItem
        key="renderEditForm"
        isValid={this.isCurrentItemValid(formErrors)}
        countriesNames={countries}
        onResetValues={() => this.resetCurrentItem()}
        onCancel={() => this.onCancel()}
        onSubmit={(data) => this.onSubmitItem(data)}
        onSubmitFailed={() => this.onSubmitItemFailed()}
      />
    );
  }

  render() {
    const { addInProgress } = this.state;
    const { regions, defaultValues } = this.props;

    return (
      <FormSection name={CUSTOM_REGION_MAPPING} className={cls.spaceBottom}>
        {this.renderRegions(regions.items)}
        {!addInProgress ? (
          <Container horizontal className={cls.buttonsSeparated}>
            {!Object.keys(defaultValues).length ||
            areRegionsTheSame(defaultValues, regions.items) ? null : (
              <Button
                disabled={!canManageOrganization()}
                negative
                label="Restore defaults"
                onClick={this.onRestoreDefaults}
              />
            )}
            <Button
              disabled={!canManageOrganization()}
              label="Add custom region"
              onClick={this.onCustomRegionRequest}
            />
          </Container>
        ) : (
          this.renderEditForm()
        )}
      </FormSection>
    );
  }
}
