import { AnyAction, Reducer } from 'redux';
import { v1 as uuidv1 } from 'uuid';

import { NOTIFICATIONS, NotificationActionType } from './store.types';
import { Notification } from './types';

const fingerprint = ({
  title,
  message,
  icon,
  level,
}: Partial<Notification>) => {
  return `${title}${message}${level}${icon}`;
};

export const notificationsReducer: Reducer<
  Notification[],
  NotificationActionType | AnyAction
> = (state = [], action) => {
  switch (action.type) {
    case NOTIFICATIONS.SHOW: {
      const newNotification: Notification = {
        ...action.payload,
        uid: uuidv1(),
        count: 1,
      };
      const newState = [...state, newNotification];

      const currentIndex = state.findIndex(
        (notification) =>
          fingerprint(notification) === fingerprint(action.payload)
      );

      if (currentIndex === -1) {
        return newState;
      }

      const currentNotification = state[currentIndex];
      newNotification.count += currentNotification.count;
      newState.splice(currentIndex, 1);
      return newState;
    }
    case NOTIFICATIONS.HIDE:
      return state.filter((notification) => {
        return notification.uid !== action.payload;
      });
    case NOTIFICATIONS.HIDE_ALL:
      return [];
  }

  return state;
};
