import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Button, Tile, Container } from 'src/components/IMUI';
import manageSubjectsImg from 'src/static/stories.png';
import { LICENSE_UPGRADE_URL } from 'src/userStorage';
import cls from './ManageSubjectsSurveyOnlyCard.module.css';
const cx = classNames.bind(cls);

const ManageSubjectsSurveyOnlyCard = ({
  blocking,
  text,
  header,
  id,
  largeTile,
}) => {
  const renderTileContent = () => (
    <Tile size={largeTile ? 'l' : 'm'}>
      <Tile.Icon
        imgUrl={manageSubjectsImg}
        className={cx('survey-only-icon')}
        style={{ width: 250, height: 250, margin: '0 auto' }}
      />
      <Tile.Title>
        {header}
        <span className={cx('demonstration-card-gold-pro')}> PRO </span>
      </Tile.Title>
      <Tile.Text limitTextWidth={largeTile}>{text}</Tile.Text>
      <Button
        secondary
        label="TALK TO US"
        size="l"
        className={cx('demonstration-card-button')}
        onClick={() => window.open(LICENSE_UPGRADE_URL, '_blank')}
      />
    </Tile>
  );

  return (
    <div
      className={cx(
        'manage-subjects-demonstration-container',
        { 'blocking-style': blocking },
        { [id]: blocking }
      )}
    >
      {blocking ? (
        <Container centered centerHorizontal className={cx('tile-container')}>
          {renderTileContent()}
        </Container>
      ) : (
        renderTileContent()
      )}
    </div>
  );
};

ManageSubjectsSurveyOnlyCard.propTypes = {
  blocking: PropTypes.bool,
  text: PropTypes.string,
  header: PropTypes.string,
  id: PropTypes.string,
  largeTile: PropTypes.bool,
};

export default ManageSubjectsSurveyOnlyCard;
