import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Field,
  Form,
  reduxForm,
  getFormValues,
  getFormSyncErrors,
} from 'redux-form';
import { where } from 'im/api/Query';
import {
  TextField,
  Container,
  Section,
  Button,
  FormField,
} from 'src/components/IMUI';
import fluxLogo from 'src/static/Fluxx_logo.png';
import createValidator, { handleSubmitFail } from 'src/utils/validation';
import cls from '../Edit/UserOrgEditDataSources.module.css';
import { userCurrentOrgId, canManageOrganization } from 'src/userStorage';

const FORM_NAME_ORG_DATA_SOURCE = 'orgEditFluxxDataSource';

class UserOrgDataSourceFluxx extends React.PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func,
    putOrganization: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
  };

  static defaultProps = {
    formValues: {},
  };

  onSyncDemand = () => {
    const organizationId = userCurrentOrgId();
    const query = where({ id: organizationId }).payload({
      fluxx_sync: true,
      id: organizationId,
    });
    this.props.putOrganization(query);
  };

  onSubmitRequest = () => {
    this.props.submit(FORM_NAME_ORG_DATA_SOURCE);
  };

  render() {
    return (
      <Section className={cls.dataSourcesTile}>
        <Form noValidate onSubmit={this.props.handleSubmit}>
          <div className={cls.inputContainer}>
            <div className={cls.sourceHeader}>
              <Container className={cls.logoContainer}>
                <img className={cls.sourceLogo} alt="Fluxx" src={fluxLogo} />
                Fluxx
              </Container>
              <Button
                className={cls.formButton}
                secondary
                size="l"
                label="Sync"
                onClick={this.onSyncDemand}
              />
            </div>
            <FormField>
              <Field
                fullWidth
                label={
                  <span className={cls.oAuthFieldLabel}>OAuth Client ID</span>
                }
                name="fluxx_client_id"
                component={TextField}
                hintText="OAuth Client ID"
              />
            </FormField>

            <FormField>
              <Field
                fullWidth
                label={
                  <span className={cls.oAuthFieldLabel}>
                    OAuth Client Secret{' '}
                  </span>
                }
                name="fluxx_secret"
                component={TextField}
                hintText="OAuth Client Secret"
                type="password"
                autoComplete="new-password"
              />
            </FormField>

            <FormField>
              <Field
                fullWidth
                label={
                  <span className={cls.oAuthFieldLabel}>Your Fluxx URL</span>
                }
                name="fluxx_instance_url"
                component={TextField}
                hintText="Your Fluxx URL"
              />
            </FormField>

            <Container className={cls.formButtonContainer}>
              <Button
                type={'submit'}
                onClick={this.onSubmitRequest}
                disabled={!canManageOrganization()}
                className={cls.formButton}
                size="l"
                label="Save"
              />
            </Container>
          </div>
        </Form>
      </Section>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    formValues: getFormValues(ownProps.form)(state),
    formErrors: getFormSyncErrors(ownProps.form)(state),
  };
}

function validateUserOrgInputs(values = {}) {
  const not = (fn) => (v) => !fn(v);
  const isDefined = (v) => Boolean(v);
  const longerThan = (n) => (v) => v.length > n;

  const validator = createValidator({
    fluxx_client_id: [
      [not(isDefined), () => 'OAuth Client Id is required'],
      [not(longerThan(1)), () => 'OAuth Client Id is too short'],
    ],
    fluxx_secret: [
      [not(isDefined), () => 'Client Secret is required'],
      [not(longerThan(1)), () => 'Client Secret is too short'],
    ],
    fluxx_instance_url: [
      [not(isDefined), () => 'Fluxx URL is required'],
      [not(longerThan(1)), () => 'URL is too short'],
    ],
    // logo: [[v => not(isDefined)(v) || not(longerThan(0))(v), () => "Logo is required"]],
  });

  return {
    ...validator(values),
  };
}

function validate(values) {
  return validateUserOrgInputs(values);
}

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({}, dispatch)
)(
  reduxForm({
    form: FORM_NAME_ORG_DATA_SOURCE,
    enableReinitialize: true,
    validate,
    onSubmitFail: (errors) => handleSubmitFail(errors),
  })(UserOrgDataSourceFluxx)
);
