import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import { Icon } from 'im/ui/Icon';

import cls from './tables.module.css';

const cx = classNames.bind(cls);

const ExpandIcon = ({ isExpanded, className }) => (
  <Icon
    color={'#272e42'}
    name="chevron-down"
    className={cx(
      'imui-table-expand-icon',
      {
        'imui-table-expand-icon-less': isExpanded,
      },
      className
    )}
  />
);

ExpandIcon.propTypes = {
  isExpanded: PropTypes.bool,
  className: PropTypes.string,
};

export default ExpandIcon;
