import { where } from 'im/api/Query';
import reducerRegistry, { advancedFiltersRegistry } from 'im/api/registry';
import ResourceCollection from 'im/api/ResourceCollection';

const apiConfig = {
  root: '/v3/reports',
  type: 'reports',
  api: {
    allow: ['findAll', 'destroy'],
    custom: {
      findAllPerProject: {
        method: 'GET',
        path: where()
          .filter('project_reports_project_id_eq', ':project_id')
          .toString(),
      },
    },
  },
};

export const Reports = new ResourceCollection(apiConfig, 'reports');
reducerRegistry.register(Reports);

const advancedFiltersConfig = {
  actionPrefix: 'reportsAF',
  allow: ['name', 'report_type', 'period', 'code', 'global_region'],
  name: {
    type: 'report',
    root: '/v3/reports',
    path: where()
      .sort('name')
      .paginate({ size: 2000 })
      .fields('report', 'name')
      .filter('project_id_eq', ':projectId')
      .toString(),
  },
  report_type: {
    type: 'report',
    root: '/v3/reports',
    path: where()
      .sort('report_type')
      .paginate({ size: 2000 })
      .fields('report', 'report_type')
      .filter('project_id_eq', ':projectId')
      .toString(),
  },
  period: {
    type: 'report',
    root: '/v3/reports',
    path: where()
      .sort('period')
      .paginate({ size: 2000 })
      .fields('report', 'period')
      .filter('project_id_eq', ':projectId')
      .toString(),
  },
  code: {
    type: 'report',
    root: '/v3/reports',
    path: where()
      .sort('code')
      .paginate({ size: 2000 })
      .fields('report', 'code')
      .filter('project_id_eq', ':projectId')
      .toString(),
  },
  global_region: {
    type: 'report',
    root: '/v3/reports',
    path: where()
      .sort('global_region')
      .paginate({ size: 2000 })
      .fields('report', 'global_region')
      .filter('project_id_eq', ':projectId')
      .toString(),
  },
};

advancedFiltersRegistry.register(Reports, advancedFiltersConfig);

export const reportsGetAdvancedFilters = Reports.advancedFilters;

export default Reports.api;
