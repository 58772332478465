import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { splitFilterKey } from 'src/utils/queryParamFilters';
import { Icon } from 'im/ui/Icon';
import cls from './FilterTrigger.module.css';

const cx = classNames.bind(cls);

export const FilterTrigger = ({
  onToggle,
  active,
  filters,
  config,
  className,
}) => {
  const filtersApplied = Array.isArray(filters)
    ? filters?.length > 1
    : Object.keys(filters)
        .map(splitFilterKey)
        .some((filter) => config.find((cfg) => cfg?.name == filter?.variable));

  return (
    <button
      className={cx(
        cls.filterTrigger,
        {
          [cls.filterTriggerActive]: active,
          [cls.filterTriggerApplied]: filtersApplied,
        },
        className
      )}
      onClick={onToggle}
    >
      <Icon name="filter" className={cls.filterTriggerIcon} />
    </button>
  );
};

FilterTrigger.propTypes = {
  active: PropTypes.bool,
  filters: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  config: PropTypes.array,
  className: PropTypes.string,
  onToggle: PropTypes.func.isRequired,
};

FilterTrigger.defaultProps = { filters: {}, config: [] };

export default FilterTrigger;
