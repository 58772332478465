import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';

import GranteeAdd from './GranteeAdd';

export default class GranteesIndex extends Component {
  static propTypes = {
    match: ReactRouterPropTypes.match,
  };

  render() {
    const { match } = this.props;
    const rootPath = match.path;
    return (
      <Switch>
        <Route path={`${rootPath}/add`} component={GranteeAdd} />
        <Redirect from="*" to="/grants" />
      </Switch>
    );
  }
}
