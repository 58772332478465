/* eslint camelcase: 0 */

const paginate = (data, request = {}) => {
  let { page, per_page } = request;

  page = page || 0;
  per_page = per_page || 10;

  const startOffset = page * per_page;
  const endOffset = startOffset + per_page;

  const items = data.slice(startOffset, endOffset);

  const cursor = {
    total_pages: Math.ceil(data.length / per_page),
    total_items: data.length,
    per_page,
    page,
  };

  return {
    items,
    cursor,
  };
};

export default paginate;
