import React from 'react';
import PropTypes from 'prop-types';
import { Button, Container, Dialog } from 'src/components/IMUI';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import pick from 'ramda/src/pick';
import { createGrant } from 'src/actionCreators/grantsActionCreators';
import { getGrantees } from 'src/actionCreators/granteesActionCreators';
import { getGrantsFilters } from 'src/actionCreators/grantsFiltersActionCreators';
import { getRegions } from 'src/actionCreators/regionsActionCreators';
import { getOrgText } from 'src/services/DictionaryService';
import GrantStepOne from 'src/pages/App/Grants/Add/components/GrantStepOne';
import { canManageGrants } from 'src/userStorage';

@connect(
  pick(['defaultRegions', 'grantsFilters', 'grantees', 'organizationCurrent']),
  {
    getRegions,
    getGrantees,
    getGrantsFilters,
    submitForm: submit,
    createGrant,
  }
)
export default class CreateDialogsGrant extends React.PureComponent {
  static propTypes = {
    organizationCurrent: PropTypes.object,
    onCloseModal: PropTypes.func.isRequired,
    defaultRegions: PropTypes.array,
    getRegions: PropTypes.func.isRequired,
    grantees: PropTypes.object,
    getGrantees: PropTypes.func.isRequired,
    grantsFilters: PropTypes.object,
    createGrant: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    getGrantsFilters: PropTypes.func.isRequired,
    onSubmitSuccessful: PropTypes.func.isRequired,
  };

  handleSubmit = (data) => {
    const { fundings, locations, funding_type, ...grantDetails } = data;
    this.props
      .createGrant({
        fundings: fundings?.map((f) => ({ ...f, type: funding_type })),
        grant_details: { ...grantDetails, locations },
      })
      .then((grant) => {
        this.props.onCloseModal();
        this.props.onSubmitSuccessful(grant);
      })
      .catch((e) => {
        console.log(e);
        this.props.onCloseModal();
      });
  };

  componentDidMount() {
    this.props.getRegions();
    this.props.getGrantees();
    this.props.getGrantsFilters();
  }

  onClickSubmit = () => {
    this.props.submitForm('newGrant');
  };

  render() {
    return (
      <Dialog
        open
        extraLarge
        title={getOrgText('Add grant')}
        onRequestClose={this.props.onCloseModal}
        rightActions={
          <Button
            disabled={!canManageGrants()}
            size="l"
            label="CREATE GRANT"
            onClick={this.onClickSubmit}
          />
        }
        leftActions={
          <Button
            negative
            size="l"
            type="button"
            label="Cancel"
            onClick={this.props.onCloseModal}
          />
        }
      >
        <Container style={{ padding: 10 }}>
          <GrantStepOne
            hideHeader={true}
            organization={this.props.organizationCurrent.data}
            grantees={this.props.grantees}
            fundingTypes={this.props.organizationCurrent.data.funding_types}
            defaultRegions={this.props.defaultRegions}
            possibleValues={this.props.grantsFilters}
            customGrantFields={
              this.props.organizationCurrent.data.custom_grant_fields
            }
            onSubmit={this.handleSubmit}
          />
        </Container>
      </Dialog>
    );
  }
}
