import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import cls from './Card.module.css';

const cx = classNames.bind(cls);

export const Card = ({
  children,
  className,
  grow,
  expanded,
  anchor,
  noPadding,
  largePadding,
  normalPadding,
  smallPadding,
  style,
  flat,
  border,
}) => (
  <div
    className={cx(
      'imui-card',
      {
        'imui-card-anchor': anchor,
        'imui-card-grow': grow,
        'imui-card-expanded': expanded,
        'imui-card-large-padding': largePadding,
        'imui-card-normal-padding': normalPadding,
        'imui-card-small-padding': smallPadding,
        'imui-card-no-padding': noPadding,
        'imui-card-flat': flat,
        'imui-card-border': border,
      },
      className
    )}
    style={style}
  >
    {children}
  </div>
);

Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  grow: PropTypes.bool,
  expanded: PropTypes.bool,
  anchor: PropTypes.bool,
  noPadding: PropTypes.bool,
  largePadding: PropTypes.bool,
  normalPadding: PropTypes.bool,
  smallPadding: PropTypes.bool,
  style: PropTypes.object,
  flat: PropTypes.bool,
  border: PropTypes.bool,
};

export default Card;
