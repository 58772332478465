import React from 'react';

import colors from 'src/css/constants.json';

import { NO_ANSWER } from '../../constants';

const EmptyValue = () => (
  <span style={{ color: colors.grey }}>{NO_ANSWER}</span>
);

export default EmptyValue;
