import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import {
  reduxForm,
  Field,
  getFormValues,
  arrayPush,
  arrayRemove,
  touch,
  getFormSyncErrors,
  FormSection,
} from 'redux-form';
import { confirm } from 'src/components/ConfirmModal/ConfirmModal';
import { Container, SelectField } from 'src/components/IMUI';
import { getOrgText } from 'src/services/DictionaryService';
import { individual_grantee_columns } from './grantReadableOptions.json';
import { isUnmapped } from './MappingItem';
import MappingItemEditable, { isSameField } from './MappingItemEditable';
import TripleItem from './TripleItem';
import cls from './MappingForm.module.css';
import { canManageGrants } from 'src/userStorage';

const isColumnMapped = (formData, headerName, imColumn) => {
  return Boolean(
    formData &&
      Object.values(formData).some((v) =>
        isSameField(v, { headerName, imColumn })
      )
  );
};
const validate = (formData) => {
  // redux-form needs real fields names as error keys
  const hasGrantName = isColumnMapped(
    formData.columns,
    'grant_headers',
    'name'
  );
  const hasGranteeName = isColumnMapped(
    formData.columns,
    'grantee_headers',
    'name'
  );
  return {
    static: {
      grantee_headers: {
        type:
          !hasGranteeName && getOrgText('Column Grantee Name should be mapped'),
      },
      grant_headers: {
        recipient_type:
          !hasGrantName && getOrgText('Column Grant Name should be mapped'),
      },
    },
  };
};
const warning = (text) => `Map ${text} column`;
const warn = (formData) => ({
  startDate:
    !isColumnMapped(formData.columns, 'funding_headers', 'start_date') &&
    warning(getOrgText('Grant start date')),
  endData:
    !isColumnMapped(formData.columns, 'funding_headers', 'end_date') &&
    warning(getOrgText('Grant end date')),
  amount:
    !isColumnMapped(formData.columns, 'funding_headers', 'amount') &&
    warning(getOrgText('Funding Amount')),
  currency:
    !isColumnMapped(formData.columns, 'funding_headers', 'currency') &&
    warning(getOrgText('Currency')),
});

@reduxForm({
  form: 'grantMappingForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount,
  updateUnregisteredFields: true,
  validate,
  warn,
})
@connect(
  (state, ownProps) => ({
    formValues: getFormValues('grantMappingForm')(state),
    errors: getFormSyncErrors('grantMappingForm')(state),
    initialValues: ownProps.initialValues,
  }),
  { arrayPush, arrayRemove, touch }
)
export default class MappingForm extends React.Component {
  static propTypes = {
    csvColumns: PropTypes.arrayOf(PropTypes.string),
    isEdit: PropTypes.bool,
    formValues: PropTypes.object,
    initialValues: PropTypes.object,
    errors: PropTypes.object,
    submitFailed: PropTypes.bool,
    touch: PropTypes.func,
    change: PropTypes.func,
  };

  static defaultProps = { csvColumns: [], formValues: {} };

  onFieldTouch = (fieldName) => {
    this.props.touch('grantMappingForm', fieldName);
  };

  onChangeGranteeType = (ev, newValue, previousValue) => {
    ev.preventDefault?.();
    if (!canManageGrants()) return;
    if (newValue === previousValue) return;

    // Grantee.type and Grant.recipient_type should be always the same
    const onGranteeTypeChange = () => {
      this.props.change(`static.grantee_headers.type`, newValue);
      this.props.change(`static.grant_headers.recipient_type`, newValue);
    };

    if (!previousValue.includes('individual')) return onGranteeTypeChange();

    const individualColumns = individual_grantee_columns.map((i) => i.imColumn);
    const oldTypeMappings = Object.keys(this.formColumns).filter(
      (csvColumn) =>
        this.formColumns[csvColumn].headerName === 'grantee_headers' &&
        individualColumns.includes(this.formColumns[csvColumn].imColumn)
    );

    if (!oldTypeMappings.length) return onGranteeTypeChange();
    const description = `Are you sure you want to change Grantee Type? This will remove mappings in fields: ${oldTypeMappings.join(
      ', \n'
    )}`;
    confirm('Change Grantee Type', <div>{description}</div>, true).then(
      () => {
        onGranteeTypeChange();
        oldTypeMappings.forEach((csvColumn) =>
          this.props.change(`${'columns'}.${csvColumn}`, {})
        );
      },
      () => this.forceUpdate()
    );
  };

  renderErrors = () => {
    const { errors, submitFailed } = this.props;
    const errorsStatic = Object.values((errors || {}).static || {});
    const errorsTexts = errorsStatic.reduce(
      (acc, obj) =>
        Object.values(obj)[0] ? acc.concat(Object.values(obj)[0]) : acc,
      []
    );

    if (!submitFailed || !errorsTexts.length) {
      return null;
    }

    return (
      <Container className={cls.mappingFormErrorContainer}>
        {errorsTexts.map((err, index) => (
          <p key={index} className={cls.mappingFormError}>
            {err}
          </p>
        ))}
      </Container>
    );
  };

  get formColumns() {
    return this.props.formValues.columns ?? {};
  }
  get formStatic() {
    return this.props.formValues.static ?? {};
  }

  render() {
    const faultyItems = this.props.isEdit
      ? this.props.csvColumns.filter((c) => isUnmapped(this.formColumns[c]))
      : [];
    const items = this.props.isEdit
      ? this.props.csvColumns.filter((c) => !isUnmapped(this.formColumns[c]))
      : this.props.csvColumns;
    const granteeType =
      this.formStatic.grantee_headers?.type ??
      this.formStatic.grant_headers?.recipient_type;

    return (
      <div>
        {this.renderErrors()}

        <TripleItem
          border
          right={
            <FormSection name="static">
              <Field
                disabled={!canManageGrants()}
                noValidation
                fullWidth
                label={getOrgText('Grantee type')}
                labelClassName={cls.mappingFormTextToRight}
                name="grantee_headers.type"
                component={SelectField}
                onChange={this.onChangeGranteeType}
              >
                <SelectField.Item
                  primaryText="Organization"
                  value="CUSTOM:organization"
                />
                <SelectField.Item
                  primaryText="Individual"
                  value="CUSTOM:individual"
                />
              </Field>
            </FormSection>
          }
        />

        <FormSection name={'columns'} className={cls.mappingFormColumnsSection}>
          {faultyItems.map((column, index) => (
            <Field
              disabled={!canManageGrants()}
              initialValues={this.props.initialValues}
              isEdit={this.props.isEdit}
              key={`${column}_${index}`}
              component={MappingItemEditable}
              name={column}
              csvColumn={column}
              formColumns={this.formColumns}
              granteeType={granteeType}
              onFieldTouch={this.onFieldTouch}
            />
          ))}

          {items.map((column, index) => (
            <Field
              disabled={!canManageGrants()}
              key={`${column}_${index}`}
              initialValues={this.props.initialValues}
              component={MappingItemEditable}
              name={column}
              csvColumn={column}
              formColumns={this.formColumns}
              granteeType={granteeType}
              onFieldTouch={this.onFieldTouch}
            />
          ))}
        </FormSection>
      </div>
    );
  }
}
