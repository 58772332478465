import React from 'react';
import { T, withTranslators } from 'lioness';
import PropTypes from 'prop-types';
import { CardFooter, Container, DatePicker } from 'src/components/IMUI';
import TagSelector from 'src/components/TagSelector/TagSelector';
import createQuestion from './createQuestion';
import cls from './TextQuestion.module.css';
import { TagNotProvided } from './TextQuestion';
import UploadAttachment from 'src/components/Survey/UploadAttachment';

@createQuestion
class DateQuestion extends React.PureComponent {
  static propTypes = {
    question: PropTypes.object.isRequired,
    answer: PropTypes.object,
    surveyAnswers: PropTypes.object,
    tagsWithTagGroups: PropTypes.array.isRequired,
    isReview: PropTypes.bool,
    isEditing: PropTypes.bool,
    onRefresh: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    readOnly: PropTypes.bool,
    isAnswerMode: PropTypes.bool,
    onTagsChange: PropTypes.func,
  };

  static validate(question, _value) {
    if (
      typeof _value === 'string' &&
      _value !== null &&
      isNaN(new Date(_value))
    ) {
      return <T>Unexpected value type</T>;
    }
    const value = _value instanceof Date ? _value : _value || '';
    if (question.required && !value) {
      return <T>Missing answer</T>;
    }
    return null;
  }

  onDeleteTag = (tagId) => {
    this.props.onTagsChange?.(
      this.props.answer.tags.filter((tid) => tid !== tagId)
    );
  };
  onTagSelectChange = (tagIds = [], tags = []) => {
    this.props.onTagsChange?.(
      Array.isArray(tagIds) ? tagIds : [tagIds],
      Array.isArray(tags) ? tags : [tags]
    );
  };
  onDateChange(data) {
    this.props.onChange?.(data);
  }
  getTag = (tagId) =>
    this.props.tagsWithTagGroups.find((st) => st.id === tagId);

  renderTagsSelector() {
    const { question, answer, readOnly } = this.props;
    const availableTagIds = (question.tags || [])
      .map((i) => this.getTag(i))
      .filter(Boolean)
      .map((tag) => tag.id);
    const availableSelectedTagIds = (answer.tags || [])
      .map((i) => this.getTag(i))
      .filter(Boolean)
      .map((tag) => tag.id)
      .filter((tagId, index, tagIds) => tagIds.indexOf(tagId) === index);

    if (this.props.isReview && readOnly && !availableSelectedTagIds.length)
      return null;
    const selectorText = question.multiTags
      ? 'Add multiple tags that best match your response'
      : 'Add a tag that best matches your response';

    return (
      <TagSelector
        isAnswerMode={this.props.isAnswerMode}
        readOnly={this.props.readOnly}
        allowCreate={false}
        selected={availableSelectedTagIds}
        multiple={this.props.question.multiTags}
        tagsWithTagGroups={this.props.tagsWithTagGroups}
        filter={(tag) => availableTagIds.indexOf(tag.id) !== -1}
        onChange={this.onTagSelectChange}
        onRemove={this.onDeleteTag}
        noTagProps={{
          label: selectorText,
          outline: true,
          className: cls.selectorButtonText,
        }}
      />
    );
  }
  // only in review mode when editing and answer mode
  renderTags() {
    if (!this.props.isEditing && !this.props.readOnly) return null;
    const availableTagIds = (this.props.question.tags || [])
      .map((i) => this.getTag(i))
      .filter(Boolean)
      .map((tag) => tag.id);
    return (
      <CardFooter minimal className="printHidden">
        <Container horizontal>
          {!availableTagIds.length && this.props.isEditing && (
            <TagNotProvided />
          )}
          {availableTagIds.length > 0 && <div>{this.renderTagsSelector()}</div>}
        </Container>
      </CardFooter>
    );
  }

  render() {
    return (
      <div>
        <DatePicker
          value={this.props.value ? new Date(this.props.value) : null}
          onChange={(data) => this.onDateChange(data)}
          flat={!this.props.isAnswerMode}
          border={!this.props.isAnswerMode}
          flatDark={this.props.isAnswerMode}
          disabled={this.props.readOnly}
          openToYearSelection={true}
        />

        {this.renderTags()}

        {this.props.question.has_attachment && this.props.isAnswerMode && (
          <CardFooter noPadding className="printHidden">
            <UploadAttachment
              isReview={false}
              questionId={this.props.question.id}
              surveyAnswerId={this.props.answer.uid}
              importUpload={this.props.answer.import_upload}
              projectId={this.props.surveyAnswers.info?.projectId}
              granteeSurveyId={this.props.surveyAnswers.info?.grantee_survey_id}
              onRefresh={this.props.onRefresh}
            />
          </CardFooter>
        )}
      </div>
    );
  }
}

const enhancedComponent = withTranslators(DateQuestion);
enhancedComponent.validate = DateQuestion.validate;
export default enhancedComponent;
