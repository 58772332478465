import {
  STORAGE__LOAD,
  STORAGE__UPDATE,
  PROJECT__GET_INIT_SUCCESS,
  PROJECT__GET_INIT_FAILURE,
  PROJECT__UPDATE_SUCCESS,
  PROJECT__DELETE_SUCCESS,
} from 'src/constants';
import asStack from 'src/utils/asStack';

const initial = { last_projects: [] };
export default function storageReducer(state = initial, action) {
  switch (action.type) {
    case STORAGE__LOAD:
      return action.payload.storage || { ...initial };

    case STORAGE__UPDATE:
      return { ...state, ...action.payload.storage };

    case PROJECT__GET_INIT_SUCCESS:
      if (!action.payload?.data) return state;
      return {
        ...state,
        last_projects: asStack(state.last_projects).add({
          id: action.payload.data.id,
          name: action.payload.data.name,
          grantee_type: action.payload.data.grantee_type,
          uid: action.payload.data.uid,
          organization_id: action.payload.data.organization_id,
        }),
      };

    case PROJECT__UPDATE_SUCCESS:
      if (!action.payload?.data) return state;
      return {
        ...state,
        last_projects: asStack(state.last_projects).update(
          ({ id }) => id == action.payload.data.id,
          {
            name: action.payload.data.name,
            grantee_type: action.payload.data.grantee_type,
          }
        ),
      };

    case PROJECT__GET_INIT_FAILURE:
      return {
        ...state,
        last_projects: asStack(state.last_projects).remove(
          ({ id }) => id == action.payload?.projectId
        ),
      };

    case PROJECT__DELETE_SUCCESS:
      return {
        ...state,
        last_projects: asStack(state.last_projects).remove(
          ({ id }) => id == action.meta?.projectId
        ),
      };
    default:
      return state;
  }
}
