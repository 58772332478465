import classNames from 'classnames/bind';
import MenuItem from 'material-ui/MenuItem';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { TextField, PopoverLight } from 'src/components/IMUI';
import colors from 'src/css/constants.json';
import { getOrgText } from 'src/services/DictionaryService';

import { Icon } from 'im/ui/Icon';

import cls from './ColumnsSelector.module.css';

const cx = classNames.bind(cls);

const TEXT_COLOR_PER_STATUS = {
  skipped: colors['mid-grey'],
  warning: colors.red,
  default: colors.dark,
};

export default class ColumnsSelector extends Component {
  static propTypes = {
    open: PropTypes.bool,
    search: PropTypes.bool,
    value: PropTypes.string,
    status: PropTypes.string,

    onGoBack: PropTypes.func,
    onSelectorOpen: PropTypes.func,
    onRequestClose: PropTypes.func,
    renderChildren: PropTypes.func,
    renderFooter: PropTypes.func,
    renderHeader: PropTypes.func,
  };

  static Item = MenuItem;

  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  onOpen = (ev) => {
    ev?.preventDefault();
    this.setState({ anchorEl: ev.currentTarget }, this.props.onSelectorOpen);
  };

  renderHeader() {
    const { renderHeader } = this.props;
    return renderHeader ? renderHeader() : null;
  }

  renderFooter() {
    const { renderFooter } = this.props;
    return renderFooter ? renderFooter() : null;
  }

  render() {
    const { value, open, status, search } = this.props;
    const { anchorEl } = this.state;

    return (
      <div className={cx(cls.columnsSelector)}>
        <TextField
          fullWidth
          noValidation
          className={cls.columnsSelectorTarget}
          inputStyle={{
            color:
              TEXT_COLOR_PER_STATUS[status] || TEXT_COLOR_PER_STATUS.default,
          }}
          hintStyle={{
            color:
              TEXT_COLOR_PER_STATUS[status] || TEXT_COLOR_PER_STATUS.default,
          }}
          hintText="click to select a field or custom field"
          name="selectedValue"
          value={getOrgText(value)}
          icon={<Icon name="chevron-down" />}
          onClick={this.onOpen}
        />
        <PopoverLight
          search={search}
          open={open}
          anchorEl={anchorEl}
          onRequestClose={this.props.onRequestClose}
          style={{ width: 400, minWidth: 350 }}
          header={this.renderHeader()}
          footer={this.renderFooter()}
          onGoBack={this.props.onGoBack}
          renderChildren={this.props.renderChildren}
        />
      </div>
    );
  }
}
