import { where } from 'im/api/Query';
import reducerRegistry, { advancedFiltersRegistry } from 'im/api/registry';
import ResourceCollection from 'im/api/ResourceCollection';
import { selectMatchers } from 'src/data/matchers';

const apiConfig = {
  root: '/api/v3/tag_categories',
  type: 'tag_categories',
  api: {
    allow: ['findAll', 'create', 'put', 'destroy'],
    custom: {
      findAllNested: {
        method: 'GET',
        path: where().include('nested_child_categories').toString(),
        meta: {
          includeMapper: {
            nested_child_categories: 'child_categories',
          },
        },
      },
      findAllNestedWithTags: {
        method: 'GET',
        path: where()
          .include('nested_child_categories', 'nested_child_categories.tags')
          .toString(),
        meta: {
          includeMapper: {
            nested_child_categories: 'child_categories',
            'nested_child_categories.tags': ['tags', 'child_categories.tags'],
          },
        },
      },
    },
  },
};

const apiConfigProject = {
  ...apiConfig,
  api: {
    allow: ['create', 'put', 'destroy'],
    findAll: {
      path: where().filter('id_eq_any', ':projectTagCategoryIds').toString(),
    },
    custom: {
      ...apiConfig.api.custom,
      findAllNested: {
        ...apiConfig.api.custom.findAllNested,
        path: where()
          .filter('id_eq_any', ':projectTagCategoryIds')
          .include('nested_child_categories')
          .toString(),
      },
      findAllNestedWithTags: {
        ...apiConfig.api.custom.findAllNestedWithTags,
        path: where()
          .filter('id_eq_any', ':projectTagCategoryIds')
          .include('nested_child_categories', 'nested_child_categories.tags')
          .toString(),
      },
    },
  },
};

const apiConfigOrganization = {
  ...apiConfig,
  api: {
    findAll: {
      path: where()
        .filter(
          'with_default_taxonomy_or_organization_id_eq',
          ':organizationId'
        )
        .toString(),
    },
    custom: {
      ...apiConfig.api.custom,
      findAllNested: {
        ...apiConfig.api.custom.findAllNested,
        path: where()
          .filter(
            'with_default_taxonomy_or_organization_id_eq',
            ':organizationId'
          )
          .include('nested_child_categories')
          .toString(),
      },
      findAllNestedWithTags: {
        ...apiConfig.api.custom.findAllNestedWithTags,
        path: where()
          .filter(
            'with_default_taxonomy_or_organization_id_eq',
            ':organizationId'
          )
          .include('nested_child_categories', 'nested_child_categories.tags')
          .toString(),
      },
    },
  },
};

export const TagCategories = new ResourceCollection(apiConfig, 'tagCategories');
reducerRegistry.register(TagCategories);

export const TagCategoriesProject = new ResourceCollection(
  apiConfigProject,
  'projectTagCategories'
);
reducerRegistry.register(TagCategoriesProject);

export const TagCategoriesOrganization = new ResourceCollection(
  apiConfigOrganization,
  'organizationTagCategories'
);
reducerRegistry.register(TagCategoriesOrganization);

export const TagCategoriesTaggable = new ResourceCollection(
  apiConfig,
  'taggableTagCategories'
);
reducerRegistry.register(TagCategoriesTaggable);

const advancedFiltersConfig = {
  actionPrefix: 'tagCategoriesAF',
  allow: ['title'],
  nested_categories_title: {
    type: 'nested_categories',
    root: '/api/v3/tag_categories',
    path: where()
      .sort('title')
      .paginate({ size: 1500 })
      .fields('tag_category', 'title')
      .filter('id_eq_any', ':tagCategoryIds')
      .toString(),
  },
  nested_tags_title: {
    type: 'nested_tags',
    root: '/api/v3/tags',
    path: where()
      .sort('title')
      .paginate({ size: 1500 })
      .fields('tag', 'title')
      .filter('tag_categories_id_eq_any', ':tagCategoryIds')
      .toString(),
  },
};

advancedFiltersRegistry.register(TagCategories, advancedFiltersConfig);
export const tagCategoriesGetAdvancedFilters = TagCategories.advancedFilters;

export default {
  ...TagCategories.api,
  ofProject: TagCategoriesProject.api,
  ofOrganization: TagCategoriesOrganization.api,
  ofTaggable: TagCategoriesTaggable.api,
};

export const TAG_CATEGORY_AUTO_POPULATION_FIELDS = [
  'title',
  'code',
  'tags_title',
];
export const TAG_CATEGORY_FILTER = (metatags) => [
  {
    name: 'title',
    displayName: 'Tag Group',
    type: 'text',
    matchers: selectMatchers,
  },
  {
    name: 'tags_title',
    displayName: 'Tag Name',
    type: 'text',
    matchers: selectMatchers,
  },
  {
    name: 'code',
    displayName: 'Tag Group Code',
    type: 'array',
    matchers: ['eq', 'cont'],
  }, // need cont to like
  {
    name: 'metatags',
    displayName: 'Metatags',
    type: 'array',
    matchers: ['contains'],
    values: metatags,
  }, // need contains to match array
];
