import React from 'react';
import { Button, PopoverLight } from 'src/components/IMUI';
import Intercom from 'src/services/IntercomService';
import { Icon } from 'im/ui/Icon';

class HelpButton extends React.PureComponent {
  static count = 0;
  static ref;
  constructor(props) {
    super(props);
    HelpButton.ref = this;
  }
  state = { count: 0, popoverOpen: false, anchorEl: null };
  onButtonClick = (ev) => {
    ev?.preventDefault();
    this.setState({
      popoverOpen: !this.state.popoverOpen,
      anchorEl: ev.currentTarget,
    });
  };
  onPopoverClose = () => {
    this.setState({ popoverOpen: false });
  };
  render() {
    const text = (
      <span>
        Contact us
        {this.state.count > 0 && (
          <span
            style={{
              borderRadius: '50%',
              color: '#fff',
              padding: '5px 9px',
              background: '#7c90a1',
              display: 'inline-block',
              fontSize: 12,
              marginLeft: 10,
            }}
          >
            {this.state.count}
          </span>
        )}
      </span>
    );
    return (
      <div>
        <Button
          dark
          onClick={this.onButtonClick}
          label="Help"
          badge={this.state.count || undefined}
        />
        <PopoverLight
          small
          open={this.state.popoverOpen}
          anchorEl={this.state.anchorEl}
          onRequestClose={this.onPopoverClose}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          targetOrigin={{ horizontal: 'right', vertical: 'top' }}
          style={{ marginTop: 3 }}
        >
          <PopoverLight.Menu>
            <PopoverLight.MenuItem
              primaryText="Getting started"
              leftIcon={<Icon name="question-filled" />}
              onClick={() =>
                window.open(
                  'https://help.impactmapper.com/en/collections/1959117-impactmapper-101',
                  '_blank'
                )
              }
            />
            <PopoverLight.MenuItem
              primaryText="Help center"
              leftIcon={<Icon name="files" />}
              onClick={() =>
                window.open('https://help.impactmapper.com/en/', '_blank')
              }
            />
            <PopoverLight.MenuItem
              primaryText="Blog"
              leftIcon={<Icon name="blog" />}
              onClick={() =>
                window.open('https://impactmapper.com/blog', '_blank')
              }
            />
            <PopoverLight.MenuItem
              primaryText={text}
              leftIcon={<Icon name="question-1" />}
              onClick={() => Intercom.show()}
            />
          </PopoverLight.Menu>
        </PopoverLight>
      </div>
    );
  }
}

Intercom.onUnreadCountChange(
  (count) => !!HelpButton.ref && HelpButton.ref.setState({ count })
);
export default HelpButton;
