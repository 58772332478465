import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';

import userApi from 'src/api/User';
import HeaderSteps from 'src/components/HeaderSteps/HeaderSteps';
import { Section, Dialog, Button } from 'src/components/IMUI';
import Page from 'src/components/Page/Page';

import { where } from 'im/api/Query';

import UserSettingsFooter from './components/UserSettingsFooter';
import UserSettingsForm from './components/UserSettingsForm';

import cls from './UserSettings.module.css';

const FORM_NAME = 'userSettingsForm';

@connect(
  (state) => ({
    user: state.user,
    formValues: getFormValues(FORM_NAME)(state),
  }),
  { updateUser: userApi.put }
)
export default class UserSettings extends Component {
  static propTypes = {
    ...HeaderSteps.propTypes,
    updateUser: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    formValues: PropTypes.object,
    steps: PropTypes.array,
    activeStep: PropTypes.number,
  };

  static defaultProps = {
    user: {},
  };

  state = {
    confirmationOpen: false,
    updateData: null,
  };

  onUpdateUser = (data) => {
    const { user } = this.props;
    const query = where({ id: user.data.id }).payload({
      ...data,
      use_otp: data.use_otp && !!data.phone && !!data.phone.length,
    });

    this.props.updateUser(query);
  };

  onConfirmationClose = () => {
    this.onUpdateUser(this.state.updateData);
    this.setState({ confirmationOpen: false, updateData: null });
  };

  onSubmit = (data) => {
    const { user } = this.props;
    if (!user.data.use_otp && data.use_otp) {
      return this.setState({ confirmationOpen: true, updateData: data });
    }
    return this.onUpdateUser(data);
  };

  render() {
    const { user, formValues, steps, activeStep } = this.props;
    const { confirmationOpen } = this.state;

    return (
      <Page
        noSeparator
        hasFooter
        title="Account"
        steps={steps}
        activeStep={activeStep}
        pending={user.pending.init}
        helpAppcue="-LL_LBnBEkhIOd3_WNfy"
      >
        <Section collapsed>
          <div className={cls.userSettingsWrapper}>
            <h3 className={cls.userSettingsTitle}>User details</h3>
            <UserSettingsForm
              form={FORM_NAME}
              formValues={formValues}
              initialValues={user.data}
              onSubmit={this.onSubmit}
            />
          </div>
          <UserSettingsFooter formName={FORM_NAME} />
          <Dialog
            small
            title="Two Factor Authentication Enabled"
            open={confirmationOpen}
            rightActions={
              <Button label="OK" onClick={this.onConfirmationClose} />
            }
            onRequestClose={this.onConfirmationClose}
          >
            Make sure to check your phone for the activation message.
            <br />
            Please disable the 2FA if you didn&#39;t receive it within minutes
          </Dialog>
        </Section>
      </Page>
    );
  }
}
