import { formatMoney } from 'accounting';

import CURRENCIES from 'src/data/currencies.json';

export const CURRENCY_SYMBOLS = CURRENCIES.reduce(
  (acc, { symbol, code }) => ({ ...acc, [code]: symbol }),
  {}
);

export const formatMoneyByCode = (amount, currency) => {
  if (isNaN(Number(amount))) return '';

  if (!currency || !CURRENCY_SYMBOLS[currency]) {
    return amount;
  }

  return formatMoney(amount, CURRENCY_SYMBOLS[currency.toUpperCase()]);
};

export function formatMoneyIM(amount, currency = '') {
  return formatMoneyByCode(amount, currency);
}
