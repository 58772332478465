import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import FlexRow from 'src/components/FlexRow/FlexRow';

import FlexColumn from '../../FlexColumn/FlexColumn';

import cls from './PlainListItem.module.css';

const cx = classNames.bind(cls);

export const PlainListItem = ({
  title,
  titleSize,
  children,
  className,
  nowrap,
  ...rest
}) => (
  <FlexRow
    {...rest}
    className={cx(
      'lists-plain-item',
      { 'lists-plain-item-nowrap': nowrap },
      className
    )}
  >
    <FlexColumn size={titleSize || 1}>
      <strong>{title}</strong>
    </FlexColumn>
    {children}
  </FlexRow>
);

PlainListItem.propTypes = {
  title: PropTypes.node,
  titleSize: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.string,
  nowrap: PropTypes.bool,
};

export default PlainListItem;
