/* eslint-disable react/no-did-update-set-state */
import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import flatten from 'ramda/src/flatten';
import groupBy from 'ramda/src/groupBy';
import omit from 'ramda/src/omit';
import prop from 'ramda/src/prop';
import reduceBy from 'ramda/src/reduceBy';
import uniq from 'ramda/src/uniq';
import { connect } from 'react-redux';
import {
  getGranteeSummary,
  getGrantSummary,
} from 'src/actionCreators/projectActionCreators';
import summaryCalculationsApi from 'src/api/SummaryCalculations';
import taggingsApi from 'src/api/Taggings';
import { Card, Container, Section, TabSegments } from 'src/components/IMUI';
import Map from 'src/components/Map/Map';
import Page from 'src/components/Page/Page';
import history from 'src/historyInstance';
import { getText } from 'src/services/DictionaryService';
import { getCountryNameByCode } from 'src/utils/countries';

import { where } from 'im/api/Query';

import CalculationTags from '../components/CalculationTags';
import Demographics from '../components/Demographics';
import Quotes from '../components/Quotes';
import ReachTags from '../components/ReachTags';
import Stats from '../components/Stats';

import {
  getTagCategoryTaggingsCount,
  getTagCategoryEntitiesCount,
  getTagEntitiesCount,
  getRowAttr,
  CALCULATION_ATTRS,
} from './calculationsMapper';

import cls from './SummaryGrantBased.module.css';
import { Icon } from 'im/ui';

const cx = classNames.bind(cls);
const reduceToPropBySum = (sumProp) =>
  reduceBy((acc, el) => acc + el[sumProp], 0);
const sumPropByGroup = (_groupByProp, sumProp) =>
  reduceToPropBySum(sumProp)?.((el) => el[_groupByProp]);
const groupByProp = (_groupByProp) => groupBy((el) => el[_groupByProp]);
const gather = (acc, key, name, amount) => {
  acc[key] ||= {};
  acc[key][name] ||= 0;
  acc[key][name] += amount;
  return acc;
};
const toArray = (obj) =>
  Object.keys(obj).map((key) => ({ label: key, value: obj[key] }));
const calculateOccurences = (obj, total) =>
  Object.keys(obj).map((key) => ({
    label: key,
    num: obj[key].length,
    percent: obj[key].length / total,
  }));
const calculateOccurencesAndExpand = (groupListProp) => (arr, total) => {
  const expanded = arr.reduce((acc, el) => {
    el[groupListProp]?.forEach((type) => {
      acc[type] ||= { label: type, num: 0 };
      acc[type].num = acc[type].num + 1;
    });
    return acc;
  }, {});
  return Object.keys(expanded).map((key) => ({
    ...expanded[key],
    percent: expanded[key].num / total,
  }));
};

function getGrantDemographics(data) {
  const by_type = data.entities.reduce(
    (acc, e) => {
      e.fundings.forEach((f) => gather(acc, 'by_type', f.type, f.amount));
      return acc;
    },
    { by_type: {} }
  ).by_type;
  const by_issue = data.entities.reduce(
    (acc, e) => {
      e.fundings.forEach((f) =>
        f.issue_list.forEach((issue) =>
          gather(acc, 'by_issue', issue, f.amount)
        )
      );
      return acc;
    },
    { by_issue: {} }
  ).by_issue;
  const regions = data.entities.filter((e) => e.region != undefined);
  const states = data.entities.filter((e) => e.state != undefined);
  const countries = data.entities.filter((e) => e.country_code != undefined);

  return {
    populations: calculateOccurencesAndExpand('population_list')(
      data.entities,
      data.entities.length
    ),
    issues: calculateOccurencesAndExpand('issue_list')(
      data.entities,
      data.entities.length
    ),
    portfolio: calculateOccurencesAndExpand('portfolio_list')(
      data.entities,
      data.entities.length
    ),
    strategies: calculateOccurencesAndExpand('strategies_list')(
      data.entities,
      data.entities.length
    ),
    area_of_work: calculateOccurencesAndExpand('area_of_work_list')(
      data.entities,
      data.entities.length
    ),
    partner: calculateOccurencesAndExpand('partner_list')(
      data.entities,
      data.entities.length
    ),
    regions: calculateOccurences(
      groupByProp('region')(regions),
      data.entities.length
    ).map((el) => ({ ...el, label: el.label || 'N/A' })),
    states: calculateOccurences(
      groupByProp('state')(states),
      data.entities.length
    ).map((el) => ({ ...el, label: el.label || 'N/A' })),
    countries: calculateOccurences(
      groupByProp('country_code')(countries),
      data.entities.length
    ).map((el) => ({
      ...el,
      label: getCountryNameByCode(el.label || 'UN') || 'N/A',
    })),
    funding: {
      total: data.project_funding,
      by_region: toArray(
        sumPropByGroup('region', 'total_funding')(data.entities)
      ),
      by_country_code: toArray(
        sumPropByGroup('country_code', 'total_funding')(data.entities)
      ).map((el) => ({
        ...el,
        label: getCountryNameByCode(el.label || 'UN') || 'N/A',
      })),
      by_length: toArray(
        sumPropByGroup('funding_length', 'total_funding')(data.entities)
      ),
      by_type: toArray(by_type),
      by_issue: toArray(by_issue),
    },
  };
}

const flattenGrantsByLocation = (grants = []) =>
  grants.reduce((acc, grant) => {
    const grouped = [];
    grant.locations?.map((l) =>
      grouped.push(omit('locations', { ...grant, country_code: l.country }))
    );
    return acc.concat(grouped.length ? grouped : [grant]);
  }, []);

@connect(
  (state) => ({
    projectSummary: state.projectSummary,
    project: state.project,
    summaryCalculations: state.summaryCalculations,
  }),
  {
    getGranteeSummary,
    getGrantSummary,
    getProjectQuotes: taggingsApi.quotes.findAllPerProject,
    getGranteesPerTagCategoryCount:
      summaryCalculationsApi.granteesPerTagCategoryCount,
    getGrantsPerTagCategoryCount:
      summaryCalculationsApi.grantsPerTagCategoryCount,
    getGranteesPerTag: summaryCalculationsApi.granteesPerTag,
    getGrantsPerTag: summaryCalculationsApi.grantsPerTag,
    getTagsPerReportsMetatags: summaryCalculationsApi.tagsPerReportsMetatags,
    getTaggingsPerTagCategory: summaryCalculationsApi.taggingsPerTagCategory,
    getReachTaggings: summaryCalculationsApi.reachTaggings,
  }
)
export default class SummaryGrantBased extends React.PureComponent {
  static propTypes = {
    projectSummary: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    project: PropTypes.object,
    location: PropTypes.object,
    summaryCalculations: PropTypes.object,

    getProjectQuotes: PropTypes.func.isRequired,
    getGranteeSummary: PropTypes.func.isRequired,
    getGrantSummary: PropTypes.func.isRequired,

    getGranteesPerTagCategoryCount: PropTypes.func.isRequired,
    getGrantsPerTagCategoryCount: PropTypes.func.isRequired,

    getGranteesPerTag: PropTypes.func.isRequired,
    getGrantsPerTag: PropTypes.func.isRequired,

    getTagsPerReportsMetatags: PropTypes.func.isRequired,

    getTaggingsPerTagCategory: PropTypes.func.isRequired,
    getReachTaggings: PropTypes.func.isRequired,
  };

  static contextTypes = { router: PropTypes.object };

  state = {
    quotes: [],
    metatagsTagCategories: {},
    reachTaggings: {},
    mapData: [],
    outcomesExpanded: false,
  };

  componentDidMount() {
    this.doRequestQuotes(this.props.project.uid);
    this.getData(
      this.grantPrefix,
      this.props.match.params.projectId,
      this.props.project.uid
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.projectSummary.data !== this.props.projectSummary.data) {
      const taggingsByEntityId = (
        nextProps.projectSummary.data.tag_groups || []
      ).reduce((acc, { tags }) => {
        tags.forEach((tag) => {
          tag.taggings.forEach((tagging) => {
            if (!acc[tagging.entity_id]) {
              acc[tagging.entity_id] = [];
            }
            acc[tagging.entity_id].push({
              quantity: tagging.quantity,
              quantityLabel: tag.quantity_label,
              tagName: tag.name,
            });
          });
        });

        return acc;
      }, {});

      const byCountry = flattenGrantsByLocation(
        nextProps.projectSummary.data.entities
      ).reduce((acc, entity) => {
        const code = (entity.country_code || 'UN').toUpperCase();
        return { ...acc, [code]: [...(acc[code] || []), entity] };
      }, {});
      const mapData = Object.keys(byCountry).map((key) => ({
        code: key,
        entities: byCountry[key].map((entity) => ({
          ...entity,
          relatedTaggings: taggingsByEntityId[entity.id] || [],
        })),
        value: byCountry[key].length,
      }));
      this.setState({ mapData });
    }
  }

  componentDidUpdate(oldProps) {
    const oldView =
      queryString.parse(oldProps.location.search)?.['grant-view'] === 'true';
    if (oldView !== this.isGrantView) {
      this.getData(
        this.grantPrefix,
        this.props.match.params.projectId,
        this.props.project.uid
      );
    }
  }

  doRequestQuotes = (uid) => {
    this.props
      .getProjectQuotes(
        where({ project_id: uid })
          .include(
            'report',
            'survey_answer',
            'survey_answer.survey_question',
            'grantee',
            'grantee_survey',
            'project_reports'
          )
          .paginate({ size: 150 })
      )
      .then((res) => {
        if (!res) return;
        const quotes = res.data?.map((i) => ({
          ...i,
          content: i.content,
          position: i.position,
          project_report_id:
            i.report?.project_reports?.find(
              (p) => p.project_id == this.props.project.id
            )?.id || i.report?.project_reports?.[0]?.id,
          report_id: i.report?.id,
          grantee_survey_id: i.grantee_survey?.id,
          grantee_name: i.grantee_survey?.grantee_name,
          survey_id: i.grantee_survey?.survey_id,
        }));
        this.setState({ quotes });
      });
  };

  getReachTaggingsData() {
    this.setState({
      reachTaggings:
        this.props.summaryCalculations.data.reachTaggings?.rows?.reduce(
          (acc, row) => {
            const tagCategoryId = getRowAttr(
              this.props.summaryCalculations.data.reachTaggings.columns,
              row,
              CALCULATION_ATTRS.tagCategory.id
            );
            const tagCategoryTitle = getRowAttr(
              this.props.summaryCalculations.data.reachTaggings.columns,
              row,
              CALCULATION_ATTRS.tagCategory.title
            );
            const tagId = getRowAttr(
              this.props.summaryCalculations.data.reachTaggings.columns,
              row,
              CALCULATION_ATTRS.tag.id
            );
            const tagTitle = getRowAttr(
              this.props.summaryCalculations.data.reachTaggings.columns,
              row,
              CALCULATION_ATTRS.tag.title
            );

            const max = getRowAttr(
              this.props.summaryCalculations.data.reachTaggings.columns,
              row,
              CALCULATION_ATTRS.quantity.max
            );
            const min = getRowAttr(
              this.props.summaryCalculations.data.reachTaggings.columns,
              row,
              CALCULATION_ATTRS.quantity.min
            );
            const sum = getRowAttr(
              this.props.summaryCalculations.data.reachTaggings.columns,
              row,
              CALCULATION_ATTRS.quantity.sum
            );
            const avg = getRowAttr(
              this.props.summaryCalculations.data.reachTaggings.columns,
              row,
              CALCULATION_ATTRS.quantity.avg
            );
            const count = getRowAttr(
              this.props.summaryCalculations.data.reachTaggings.columns,
              row,
              CALCULATION_ATTRS.tagging.count
            );

            return {
              ...acc,
              [tagCategoryId]: {
                id: tagCategoryId,
                title: tagCategoryTitle,
                tags: [
                  ...(acc[tagCategoryId] && acc[tagCategoryId].tags
                    ? acc[tagCategoryId].tags
                    : []),
                  { id: tagId, title: tagTitle, count, max, min, sum, avg },
                ],
              },
            };
          },
          {}
        ),
    });
  }

  getCalculatedData = (tags) =>
    tags?.rows?.reduce(
      (acc, row) => ({
        ...acc,
        [getRowAttr(tags.columns, row, CALCULATION_ATTRS.tagCategory.id)]: {
          ...acc[
            getRowAttr(tags.columns, row, CALCULATION_ATTRS.tagCategory.id)
          ],
          title: getRowAttr(
            tags.columns,
            row,
            CALCULATION_ATTRS.tagCategory.title
          ),
          taggingsCount: getTagCategoryTaggingsCount(
            this.props.summaryCalculations.data.taggingsPerTagCategory,
            getRowAttr(tags.columns, row, CALCULATION_ATTRS.tagCategory.id)
          ),
          entitiesCount: getTagCategoryEntitiesCount(
            this.props.summaryCalculations.data[
              `${this.grantPrefix}sPerTagCategoryCount`
            ],
            getRowAttr(tags.columns, row, CALCULATION_ATTRS.tagCategory.id),
            this.grantPrefix
          ),
          tags: [
            ...(acc[
              getRowAttr(tags.columns, row, CALCULATION_ATTRS.tagCategory.id)
            ]?.tags ?? []),
            {
              id: getRowAttr(tags.columns, row, CALCULATION_ATTRS.tag.id),
              title: getRowAttr(tags.columns, row, CALCULATION_ATTRS.tag.title),
              taggingsCount: getRowAttr(
                tags.columns,
                row,
                CALCULATION_ATTRS.tagging.count
              ),
              entitiesCount: getTagEntitiesCount(
                this.props.summaryCalculations.data[
                  `${this.grantPrefix}sPerTag`
                ],
                getRowAttr(tags.columns, row, CALCULATION_ATTRS.tag.id),
                this.grantPrefix
              ),
            },
          ],
        },
      }),
      {}
    );

  getData(type, projectNumericId, projectId) {
    if (type === 'grant') {
      Promise.allSettled([
        this.props.getReachTaggings({ projectId }),
        this.props.getTaggingsPerTagCategory({ projectId }),
        this.props.getGrantSummary(projectNumericId),
        this.props.getGrantsPerTag({ projectId }),
        this.props.getGrantsPerTagCategoryCount({ projectId }),
      ]).then(() => {
        this.getReachTaggingsData();
        this.getMetatagsData();
      });
    }
    if (type === 'grantee') {
      Promise.allSettled([
        this.props.getReachTaggings({ projectId }),
        this.props.getTaggingsPerTagCategory({ projectId }),
        this.props.getGranteeSummary(projectNumericId),
        this.props.getGranteesPerTag({ projectId }),
        this.props.getGranteesPerTagCategoryCount({ projectId }),
      ]).then(() => {
        this.getReachTaggingsData();
        this.getMetatagsData();
      });
    }
  }

  getMetatagsData() {
    Promise.all(
      (this.props.project.metatags || []).map((metatag) =>
        this.props
          .getTagsPerReportsMetatags({
            projectId: this.props.project.uid,
            metatags: metatag.replaceAll(',', '').replaceAll('"', ''),
          })
          .then((payload) =>
            payload?.calculations?.rows.length > 0
              ? [metatag, this.getCalculatedData(payload.calculations)]
              : undefined
          )
      )
    ).then((payload) => {
      if (payload.filter(Boolean)?.length > 0)
        this.setState({
          metatagsTagCategories: Object.fromEntries(payload.filter(Boolean)),
        });
    });
  }

  getCurrencySymbol() {
    if (
      this.props.project.organization_id === 1078 ||
      this.props.project.organization_id === 1079
    )
      return 'NOK ';
    if (this.props.projectSummary.data.currency === 'USD') return '$';
    else if (this.props.projectSummary.data.currency === 'EUR') return '€';
    else if (this.props.projectSummary.data.currency === 'GBP') return '£';
    return `${this.props.projectSummary.data.currency} `;
  }

  get grantPrefix() {
    return this.isGrantView ? 'grant' : 'grantee';
  }
  get isGrantView() {
    return (
      queryString.parse(this.props.location.search)?.['grant-view'] === 'true'
    );
  }
  handleGrantGranteeToggle = (isToggled) => {
    history.replace(
      `/analysis/${this.props.match.params.projectId}/summary?grant-view=${isToggled}`
    );
  };

  renderSubHeader() {
    return (
      <Section type="sub-header" collapsed>
        <h1>{this.props.projectSummary.data.name}</h1>
        <TabSegments
          dark
          onToggle={(segmentId) =>
            this.handleGrantGranteeToggle(segmentId === 'grant')
          }
          segments={[
            {
              id: 'grantee',
              text: getText('Grantee'),
              active: !this.isGrantView,
              tooltipText: getText('Aggregate by grantee'),
            },
            {
              id: 'grant',
              text: getText('Grant'),
              active: this.isGrantView,
              tooltipText: getText('Aggregate by grant'),
            },
          ]}
        />
      </Section>
    );
  }

  renderInfoBar() {
    const { data } = this.props.projectSummary;
    if (!this.props.projectSummary.data.entities?.length)
      return (
        <Section horizontal>
          <Container>
            <h4>No Fundings selected, please add them</h4>
          </Container>
        </Section>
      );
    const chargeNorwayException =
      this.props.project.organization_id === 1078 ||
      this.props.project.organization_id === 1079;
    const currencyCode = chargeNorwayException ? 'NOK' : data.currency;
    if (!data.stats) return null;
    return (
      <Section collapsed className={cx('infoBar')}>
        <div className="years-filter" />
        <Stats
          className={cx('stats')}
          stats={{
            ...data.stats,
            currency: currencyCode,
            funding: data.project_funding,
            entities: uniq(flatten(data.entities.map(prop('name')))).length,
          }}
        />
      </Section>
    );
  }

  renderDemographicsDetails() {
    if (!this.props.projectSummary.data.entities?.length) return null;
    return (
      <Section collapsed>
        <Container className={cx('demographicsDetails')}>
          <Demographics
            grantView={this.isGrantView}
            className={cx('demographics')}
            demographics={getGrantDemographics(this.props.projectSummary.data)}
            currencySymbol={this.getCurrencySymbol()}
          />

          <a
            className={cx('mapWrapper')}
            href={`/analysis/${this.props.match.params.projectId}/summary/map?grant-view=${this.isGrantView}`}
          >
            <Card style={{ position: 'relative' }}>
              <Map
                hasBackground
                data={this.state.mapData}
                height={500}
                entity={this.grantPrefix}
                chartTitle=" "
                currencySymbol={this.getCurrencySymbol()}
                onPointClick={undefined}
                showDataLabels={false}
                useDarkTheme={false}
              />
              <span
                style={{ position: 'absolute', bottom: 10, left: '50%' }}
                className={cls.seeMap}
              >
                see full map
              </span>
            </Card>
          </a>
        </Container>
      </Section>
    );
  }

  toggleOutcomesExpand = () =>
    this.setState({ outcomesExpanded: !this.state.outcomesExpanded });

  renderOutcomes() {
    const getMetatagGroups = (key) =>
      Object.keys(this.state.metatagsTagCategories[key])
        .map((metaId) => this.state.metatagsTagCategories[key]?.[metaId])
        .filter(Boolean);
    return Object.entries(this.state.metatagsTagCategories).map(([key]) => (
      <div key={key}>
        <br />
        <h3>
          {key}&emsp;
          <small
            style={{ cursor: 'pointer' }}
            onClick={this.toggleOutcomesExpand}
          >
            <small>
              {this.state.outcomesExpanded ? 'hide tags' : 'show tags'}
            </small>
            &ensp;
            <Icon
              name={this.state.outcomesExpanded ? 'chevron-up' : 'chevron-down'}
              tip={this.state.outcomesExpanded ? 'collapse' : 'expand'}
            />
          </small>
        </h3>
        <CalculationTags
          outcomesExpanded={this.state.outcomesExpanded}
          tagGroups={getMetatagGroups(key)}
          grantView={this.isGrantView}
          projectId={this.props.match.params.projectId}
        />
      </div>
    ));
  }

  renderReachTaggings() {
    const tagGroups = Object.keys(this.state.reachTaggings ?? {}).map(
      (tagCategoryId) => this.state.reachTaggings[tagCategoryId]
    );
    return (
      <div>
        {Object.keys(this.state.reachTaggings ?? {}).length > 0 && (
          <ReachTags tagGroups={tagGroups} className={cx('reachTags')} />
        )}
      </div>
    );
  }
  renderQuotes() {
    return (
      <div>
        {this.state.quotes?.length > 0 && (
          <Quotes
            quotes={this.state.quotes}
            projectId={this.props.match.params.projectId}
            className={cx('quotes')}
          />
        )}
      </div>
    );
  }

  render() {
    return (
      <Page
        className={cx('summaryGrantedPage')}
        title="Summary"
        helpAppcue="-LHrx-SDSc-yUbmtLahf"
        pending={this.props.projectSummary.pending}
      >
        <div style={{ width: '100%', maxWidth: 1800, margin: '0 auto' }}>
          {this.renderSubHeader()}
          {this.renderInfoBar()}
          {this.renderDemographicsDetails()}
          <Section>
            <br />
            {this.renderOutcomes()}
            <br />
            {this.renderReachTaggings()}
            <br />
            <br />
            {this.renderQuotes()}
          </Section>
        </div>
      </Page>
    );
  }
}
