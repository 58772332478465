export const arrayToText = (arr) => arr && arr.join(', ');

export const formatArrayData = (arr = [], property, transform = (v) => v) =>
  arrayToText(
    arr.reduce(
      (acc, el) =>
        !el[property] || acc.includes(transform(el[property]))
          ? acc
          : acc.concat([transform(el[property])]),
      []
    )
  );
