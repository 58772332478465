import {
  PROJECT__SUMMARY_GET_SUCCESS,
  PROJECT__SUMMARY_GET_REQUEST,
  PROJECT__SUMMARY_GET_FAILURE,
} from 'src/constants';

const initialState = {
  pending: false,
  data: {},
};

export default function projectSummaryReducer(state = initialState, action) {
  switch (action.type) {
    case PROJECT__SUMMARY_GET_REQUEST:
      return { ...state, pending: true };
    case PROJECT__SUMMARY_GET_FAILURE:
      return { ...state, pending: false };
    case PROJECT__SUMMARY_GET_SUCCESS:
      return { ...state, data: action.payload.data, pending: false };
    default:
      return state;
  }
}
