import React, { Component } from 'react';

import Page from 'src/components/Page/Page';
import ManageSubjectsSurveyOnlyCard from 'src/components/SurveyOnly/ManageSubjectsSurveyOnlyCard';
import { UPGRADE } from 'src/components/SurveyOnly/surveyOnlyUpsellText';

export default class Upgrade extends Component {
  render() {
    return (
      <Page title="Upgrade">
        <ManageSubjectsSurveyOnlyCard {...UPGRADE} id="UPGRADE" largeTile />
      </Page>
    );
  }
}
