import {
  GRANTS__FILTERS_GET_SUCCESS,
  GRANTS__FILTERS_GET_FAILURE,
} from 'src/constants';

const initialState = {
  portfolio_list: { values: [] },
  cohort_list: { values: [] },
  donor_list: { values: [] },
  donor_type_list: { values: [] },
  status: { values: [] },
  funding_types: { values: [] },
  population_list: { values: [] },
  issue_list: { values: [] },
  strategies_list: { values: [] },
  area_of_work_list: { values: [] },
};

export default function grantsFiltersReducer(state = initialState, action) {
  switch (action.type) {
    case GRANTS__FILTERS_GET_SUCCESS:
      return action.payload.data;
    case GRANTS__FILTERS_GET_FAILURE:
      return state;
    default:
      return state;
  }
}
