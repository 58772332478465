import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import symmetricDifference from 'ramda/src/symmetricDifference';
import { connect } from 'react-redux';
import colors from 'src/css/constants.json';
import { numberAs } from 'src/utils/string';
import { getSurveyI18n } from 'src/utils/surveysI18n';
import { Icon } from 'im/ui/Icon';
import InjectableContent from './InjectableContent';
import rightAlignLanguages from './rightAlignLanguages';
import { conditionsCheck } from 'src/utils/surveysConditionalLogic';
import cls from './createQuestion.module.css';
const cx = classNames.bind(cls);

export default function (DecoratedComponent) {
  @connect(pick(['survey', 'surveyAnswers']))
  class QuestionHOC extends React.Component {
    static propTypes = {
      question: PropTypes.object.isRequired,
      answer: PropTypes.object.isRequired, // eslint-disable-line
      error: PropTypes.node,
      className: PropTypes.string,
      onTagsChange: PropTypes.func, // eslint-disable-line
      readOnly: PropTypes.bool,
      isReview: PropTypes.bool, // eslint-disable-line
      isEditing: PropTypes.bool,
      value: PropTypes.any,
      survey: PropTypes.object,
      surveyAnswers: PropTypes.object,
      forceMobileStyling: PropTypes.bool,
      rightAlign: PropTypes.bool,
    };

    static defaultProps = { answer: {} };
    static contextTypes = { language: PropTypes.string };
    static validate(question, answer, otherValue) {
      return DecoratedComponent.validate?.(question, answer, otherValue);
    }

    shouldComponentUpdate(nextProps, _nextState, nextContext) {
      const {
        value,
        readOnly,
        isEditing,
        error,
        question,
        answer,
        survey,
        forceMobileStyling,
      } = this.props;
      return (
        this.context.language !== nextContext.language ||
        nextProps.question !== question ||
        nextProps.readOnly !== readOnly ||
        nextProps.survey.activeLanguage !== survey.activeLanguage ||
        nextProps.isEditing !== isEditing ||
        nextProps.forceMobileStyling !== forceMobileStyling ||
        nextProps.error !== error ||
        nextProps.answer !== answer ||
        (Array.isArray(nextProps.value) &&
          (!value ||
            nextProps.value.length !== value.length ||
            !!symmetricDifference(nextProps.value, value).length)) ||
        (!Array.isArray(nextProps.value) && nextProps.value !== value)
      );
    }

    getI18nString = (path, altPath) =>
      getSurveyI18n(
        this.props.surveyAnswers.info.id
          ? this.props.surveyAnswers
          : this.props.survey,
        this.props.question,
        path,
        altPath
      );

    isQuestionRequired() {
      return (
        this.props.question.required ||
        this.props.question.settings?.minAnswers > 0
      );
    }

    renderCounter() {
      const { survey, surveyAnswers, question } = this.props;
      const counter =
        survey.question_counter || surveyAnswers.info?.survey_question_counter;
      if (!counter) return null;
      const questions = surveyAnswers.questions.length
        ? surveyAnswers.questions
        : survey.questions;
      let position = 0;
      questions.some((el) => {
        if (!el.isLayout) {
          position += 1;
          return el.id === question.id;
        }
        return false;
      });
      return numberAs(position, counter);
    }

    render() {
      const hasConditions =
        this.props.isEditing &&
        conditionsCheck(this.props.question, this.props.survey) !== null;

      return this.props.question.isLayout ? (
        <div className={cx('wrapper', this.props.className || '')}>
          {this.props.error ? (
            <div className={`${cx('error')} --error`}>
              <Icon
                name="warning-triangle"
                color={colors.red}
                className={cls.errorIcon}
              />
              {this.props.error}
            </div>
          ) : null}
          <h3
            className={cx('questionTitle', {
              questionUntitled: !this.props.question.question,
            })}
          >
            {this.props.question.question || 'Untitled'}
          </h3>
          <div className={cx('component')}>
            <DecoratedComponent {...this.props} />
          </div>
        </div>
      ) : (
        <div
          className={cx('wrapper', this.props.className || '')}
          style={{ direction: this.props.rightAlign ? 'rtl' : 'initial' }}
        >
          <h3
            className={cx('questionTitle', {
              questionUntitled: !this.getI18nString('question', 'question'),
              questionTitleError: !!this.props.error,
              questionHasConditions: hasConditions,
            })}
          >
            {this.renderCounter()}
            {this.getI18nString('question', 'question') ? (
              <InjectableContent
                convertUrls
                text={this.getI18nString('question', 'question')}
                rightAlign={rightAlignLanguages.includes(
                  this.props.survey.activeLanguage
                )}
              />
            ) : (
              'Untitled question'
            )}
            {this.isQuestionRequired() && (
              <Icon
                name="asterix"
                color={colors.red}
                className={cx('questionTitleAsterix')}
              />
            )}
          </h3>
          {hasConditions && (
            <div className={cls.questionConditionIndicator}>
              <Icon
                name={'conditional'}
                tip="This question showed based from a logic applied to an earlier question"
                placeTip="right"
              />
              <span>{'Conditional'}</span>
            </div>
          )}
          {this.getI18nString('description', 'description') && (
            <InjectableContent
              convertUrls
              className={cx('description')}
              text={this.getI18nString('description', 'description')}
              rightAlign={rightAlignLanguages.includes(
                this.props.survey.activeLanguage
              )}
            />
          )}
          {this.props.error ? (
            <div className={`${cx('error')} --error`}>
              <Icon
                name="warning-triangle"
                color={colors.red}
                className={cls.errorIcon}
              />
              {this.props.error}
            </div>
          ) : null}
          {!this.props.question.layoutElement && (
            <div>
              <DecoratedComponent {...this.props} />
            </div>
          )}
        </div>
      );
    }
  }

  return QuestionHOC;
}
