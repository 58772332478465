import React from 'react';
import { Progress, Table } from 'src/components/IMUI';
import TableBottomScroll from './TableBottomScroll';
import cls from './EntityTable.module.css';
import TableComposition from 'src/components/IMUI/Tables/TableComposition';

class EntityTable extends React.PureComponent {
  static propTypes = { ...Table.propTypes };
  static defaultProps = {
    stickyColumn: 'first',
    layout: 'fixed',
    stickyHeader: true,
  };

  render() {
    const { children, compact, stickyColumn, layout, stickyHeader } =
      this.props;
    const tableWidth = this.innerRef?.scrollWidth;

    return (
      <TableBottomScroll tableWidth={tableWidth}>
        <div ref={(ref) => (this.innerRef = ref)}>
          <TableComposition
            className={cls.entityTableSticky}
            compact={compact}
            stickyHeader={stickyHeader}
            stickyColumn={stickyColumn}
            layout={layout}
          >
            {this.props.pending && <Progress large className="absolute" />}
            {this.props.head}
            <Table.Body>{this.props.body}</Table.Body>
            {children}
          </TableComposition>
        </div>
      </TableBottomScroll>
    );
  }
}

export default EntityTable;
