import { GET_DEFAULT_REGIONS_SUCCESS } from 'src/constants';

export default function regionsReducer(state = [], action) {
  switch (action.type) {
    case GET_DEFAULT_REGIONS_SUCCESS:
      return action.payload.data;
    default:
      return state;
  }
}
