import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Button, Container } from 'src/components/IMUI';
import { getOrgText } from 'src/services/DictionaryService';
import grantBased from 'src/static/analysis-2023.png';
import standaloneIcon from 'src/static/survey-2023.png';
import AnalysisType from './AnalysisType';
import cls from './AnalysisAddTypeForm.module.css';
import { canManageProject, surveyOnly } from 'src/userStorage';
import createValidator from 'src/utils/validation';
const GRANT = 'organization';
const STANDALONE = 'none';

class AnalysisAddType extends React.PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
  };
  static defaultProps = { initialValues: { grantee_type: undefined } };
  state = { active: undefined };
  onClose = (ev) => {
    ev.stopPropagation();
    this.onActiveChange(undefined);
  };
  onSubmit = () => {
    this.props.handleSubmit();
  };
  onActiveChange = (type) => {
    if (type === this.state.active) return;
    this.props.change('grantee_type', type);
    this.setState({ active: type });
  };
  componentDidMount() {
    if (surveyOnly()) this.onActiveChange(STANDALONE);
  }
  render() {
    return (
      <Container horizontal className={cls.tilesContainer}>
        {!surveyOnly() && (
          <AnalysisType
            type={GRANT}
            active={this.state.active && [GRANT].includes(this.state.active)}
            singleInactive={
              this.state.active && ![GRANT].includes(this.state.active)
            }
            icon={grantBased}
            title={getOrgText('Grant-based')}
            text={getOrgText(
              'Track affiliations and grants associated with your project'
            )}
            onClose={this.onClose}
            onActivate={() => this.onActiveChange(GRANT)}
            inactiveBtn={
              <Button
                disabled={!canManageProject()}
                secondary
                className={cls.centeredSelf}
                size="l"
                label={getOrgText('Create Grant-based Project')}
                onClick={() => this.onActiveChange(GRANT)}
              />
            }
            activeBtn={
              <Button
                disabled={!canManageProject()}
                secondary
                size="l"
                label="Create"
                onClick={this.onSubmit}
              />
            }
          />
        )}
        <AnalysisType
          type={STANDALONE}
          active={this.state.active === STANDALONE}
          singleInactive={this.state.active && this.state.active !== STANDALONE}
          icon={standaloneIcon}
          title={surveyOnly() ? undefined : 'Standalone'}
          text="Analyze publications or surveys not linked to your affiliations"
          onClose={this.onClose}
          onActivate={() => this.onActiveChange(STANDALONE)}
          inactiveBtn={
            <Button
              disabled={!canManageProject()}
              secondary
              className={cls.centeredSelf}
              size="l"
              label="Create Standalone Project"
              onClick={() => this.onActiveChange(STANDALONE)}
            />
          }
          activeBtn={
            <Button
              disabled={!canManageProject()}
              secondary
              size="l"
              label="Create"
              onClick={this.onSubmit}
            />
          }
        />
      </Container>
    );
  }
}

const validate = (values) => {
  const not = (fn) => (v) => !fn(v);
  const isDefined = (v) => Boolean(v);
  const hasType = (v) => [GRANT, STANDALONE].includes(v);
  const minLength = (n) => (v) => v.length >= n;

  return createValidator({
    name: [
      [not(isDefined), () => 'Project Name is required'],
      [not(minLength(3)), () => 'Name must have at least 3 characters'],
    ],
    grantee_type: [[not(hasType), () => 'Grantee Type is required']],
  })(values);
};
export default connect()(
  reduxForm({ validate }, (_, props) => ({
    initialValues: props.initialValues,
  }))(AnalysisAddType)
);
