import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { TextField, FormField } from 'src/components/IMUI';
import cls from './AnalysisInputs.module.css';
const cx = classNames.bind(cls);

export default class AnalysisInputs extends React.PureComponent {
  static propTypes = { className: PropTypes.string };
  render() {
    return (
      <div className={cx('analysisInputs', this.props.className)}>
        <FormField className={cls.paddingDownSmall} anchorScrollName="name">
          <Field
            fullWidth
            name="name"
            component={TextField}
            label="Project name"
            hintText="Project name"
          />
        </FormField>
        <Field
          fullWidth
          name="description"
          label="Project description"
          hintText="Project description"
          component={TextField}
          multiLine
          rows={4}
          rowsMax={8}
        />
      </div>
    );
  }
}
