import classNames from 'classnames/bind';
import MUIAutoComplete from 'material-ui/AutoComplete';
import PropTypes from 'prop-types';
import React from 'react';

import { InputReduxForm, InputSticky, Label, Error } from './base';
import cls from './AutoCompleteField.module.css';

const cx = classNames.bind(cls);

const INPUT_STYLE = { paddingLeft: 20, height: 42 };
export class AutoCompleteField extends React.PureComponent {
  static propTypes = {
    onNewRequest: PropTypes.func,
    onUpdateInput: PropTypes.func,
    dataSourceConfig: PropTypes.object,
    dataSource: PropTypes.array,
    label: PropTypes.node,
    returnOnlyValue: PropTypes.bool,
    showTextTip: PropTypes.bool,
    flat: PropTypes.bool,
    borderDark: PropTypes.bool,
    ...InputReduxForm.propTypes,
    ...InputSticky.propTypes,
  };

  static defaultProps = {
    openOnFocus: true,
    underlineShow: false,
    noValidation: false,
  };

  state = {
    searchText: '',
  };

  onNewRequest = (item, index) => {
    const specificValue =
      this.props.returnOnlyValue && this.props.dataSourceConfig.value
        ? item[this.props.dataSourceConfig.value]
        : item;
    this.props.onNewRequest?.(item, index);
    this.props.input?.onChange?.(specificValue);
    this.setState({
      searchText: this.props.dataSourceConfig?.text
        ? item[this.props.dataSourceConfig?.text]
        : item,
    });
  };

  onBlur = (event) => {
    const {
      input,
      returnOnlyValue,
      dataSourceConfig,
      dataSource,
      onUpdateInput,
      onNewRequest,
    } = this.props;
    const runBlur = input ? input.onBlur : onUpdateInput;

    let value = event.target.value;
    const itemByValue = dataSourceConfig
      ? dataSource.find((d) => d[dataSourceConfig.text] === value)
      : dataSource.find((d) => d === value);

    if (!itemByValue) {
      runBlur(undefined);
      this.setState({ searchText: '' });
      return;
    }
    if (onNewRequest) {
      onNewRequest(itemByValue, 0);
    }
    if (input) {
      input.onChange(itemByValue[dataSourceConfig.value] || itemByValue);
    }
    runBlur(
      returnOnlyValue ? itemByValue[dataSourceConfig.value] : itemByValue
    );
  };

  onUpdateInput = (_searchText, _dataSource, _params) => {
    if (_searchText == '' && _params.source == 'change') {
      this.setState({ searchText: null });
      this.props.onNewRequest?.({}, 0);
      this.props.input?.onChange?.(null);
      this.setState({ searchText: '' });
    } else {
      this.props.onUpdateInput?.(_searchText, _dataSource, _params);
      this.props.input?.onChange?.(null);
      this.setState({ searchText: _searchText });
    }
  };

  getValue() {
    const { returnOnlyValue, dataSourceConfig, dataSource } = this.props;
    const parsedInput =
      String(this.props.input?.value) === String(Number.NaN)
        ? ''
        : this.props.input?.value;
    if (this.state.searchText) return String(this.state.searchText);
    if (
      !dataSourceConfig ||
      (!returnOnlyValue && typeof parsedInput !== 'object')
    )
      return String(parsedInput);
    if (!returnOnlyValue) return String(parsedInput?.[dataSourceConfig.text]);
    return (
      dataSource.find((d) => d[dataSourceConfig.value] === parsedInput)?.[
        dataSourceConfig.text
      ] || String(parsedInput)
    );
  }

  render() {
    /* eslint-disable */
    const {
      children,
      className,
      noValidation,
      meta,
      dataSourceConfig,
      input,
      label,
      stick,
      dataSource,
      showTextTip,
      error,
      flat,
      borderDark,
      fullWidth,
      hintText,
      openOnFocus,
      underlineShow,
      disabled,
      // returnOnlyValue,
    } = this.props;
    return (
      <div>
        <Label label={label} />
        <MUIAutoComplete
          {...input}
          className={cx(
            'imui-text-field',
            'imui-autocomplete-field',
            {
              'imui-text-field-has-hint': !!this.props.hintText,
              'imui-text-field-flat': flat,
              'imui-text-field-border-dark': borderDark,
              [`imui-text-field-stick-${stick}`]: stick && stick.length,
            },
            className
          )}
          inputStyle={{ ...INPUT_STYLE, ...(this.props.inputStyle || {}) }}
          searchText={this.getValue()}
          dataSource={dataSource}
          dataSourceConfig={dataSourceConfig}
          filter={MUIAutoComplete.caseInsensitiveFilter}
          onNewRequest={this.onNewRequest}
          onUpdateInput={this.onUpdateInput}
          disabled={disabled}
          onBlur={this.onBlur}
          maxSearchResults={60}
          menuProps={{
            desktop: true,
            className: `imui-input-dropdown ${
              showTextTip ? 'imui-input-dropdown-has-tip' : ''
            }`,
          }}
          menuStyle={{
            maxHeight: 400,
            overflowY: 'auto',
            ...this.props.menuStyle,
          }}
          fullWidth={fullWidth}
          hintText={hintText}
          openOnFocus={openOnFocus}
          underlineShow={underlineShow}
        >
          {children}
        </MUIAutoComplete>
        {!noValidation && <Error meta={meta} error={error} />}
      </div>
    );
  }
}

export default AutoCompleteField;
