import PropTypes from 'prop-types';
import React from 'react';

import debounce from 'src/utils/debounce';
import { containsText } from 'src/utils/string';

import { Error } from '../Forms/base';
import {
  TextField,
  DatePicker,
  NumberField,
  SelectPopoverLight,
} from '../index';

import cls from './FilterItem.module.css';

export default class FilterItemResult extends React.Component {
  static propTypes = {
    ...TextField.propTypes,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    currentItem: PropTypes.object,
    autopopulation: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = { popoverOpen: false };

    if (props.currentItem && !!props.autopopulation) {
      props.autopopulation.onRequestOptions(
        props.currentItem.name,
        props.value
      );
    }
  }

  getInputType = (configType) => {
    return (
      this.inputs[
        this.isAutopopulationEnabled ? 'autopopulation' : configType
      ] || this.inputs.default
    );
  };

  get isAutopopulationEnabled() {
    return !!this.props.autopopulation;
  }

  get commonInputProps() {
    const { disabled } = this.props;

    return {
      disabled,
      flat: true,
      border: true,
    };
  }

  get fieldProps() {
    const { currentItem = {}, error, value } = this.props;

    return {
      ...this.commonInputProps,
      fullWidth: true,
      value: (
        this.inputFormatterValue[currentItem.type] ||
        this.inputFormatterValue.default
      )(value),
      error: error || '',
      onChange: this.handleChange,
    };
  }

  get inputProps() {
    const { currentItem = {}, value, hintText, autopopulation } = this.props;
    const { popoverOpen } = this.state;

    const hint =
      hintText ||
      this.inputHintText[currentItem.type] ||
      this.inputHintText.default;

    if (this.isAutopopulationEnabled) {
      return {
        ...this.commonInputProps,
        selectedItems: value,
        labelHint: hint,
        items: autopopulation.values,
        hasMoreItems: autopopulation.hasMore,
        customValueEnabled: true,
        pending: !!autopopulation.pending,
        renderOption:
          autopopulation.config && autopopulation.config.renderOption,
        renderSelected:
          autopopulation.config && autopopulation.config.renderSelected,
        onSelect: this.handleChange,
        popoverProps: {
          onSearchTextChange: this.handleRequestOptions,
          searchHint: autopopulation.hasMore
            ? 'Type in to get more results'
            : hint,
          selectedItemAsSearchText: true,
        },
      };
    }

    if (
      (currentItem.values || currentItem.getValues) &&
      (currentItem.type === 'select' || currentItem.type === 'array')
    ) {
      return {
        ...this.commonInputProps,
        open: popoverOpen,
        labelHint: hint,
        selectedItems: value,
        renderItems: this.renderSelectItems,
        onOpen: this.handleSelectPopoverOpen,
        onClose: this.handleSelectPopoverClose,
      };
    }

    return {
      ...this.fieldProps,
      hintText: hint,
    };
  }

  handleChange = (result = '') => {
    const { currentItem } = this.props;
    const { popoverOpen } = this.state;

    const value = (
      this.inputFormatterResult[currentItem?.type] ||
      this.inputFormatterResult.default
    )(result);
    this.props.onChange(value);

    if (popoverOpen) this.handleSelectPopoverClose();
  };

  handleRequestOptions = debounce((value) => {
    const { currentItem, autopopulation } = this.props;

    if (!currentItem || !this.isAutopopulationEnabled) return;

    autopopulation.onRequestOptions(currentItem.name, value);
  }, 300);

  handleSelectPopoverOpen = (ev) => {
    ev?.preventDefault();
    this.setState({ popoverOpen: true });
  };

  handleSelectPopoverClose = () => {
    this.setState({ popoverOpen: false });
  };

  inputs = {
    text: TextField,
    date: DatePicker,
    number: NumberField,
    boolean: null,
    array: SelectPopoverLight,
    select: SelectPopoverLight,
    autopopulation: SelectPopoverLight,
    default: TextField,
  };

  inputFormatterValue = {
    date: (v) => (v ? new Date(v) : undefined),
    default: (v) => v,
  };

  inputFormatterResult = {
    date: (v) => (v || new Date()).toISOString(),
    default: (v) => v,
  };

  inputHintText = {
    date: 'Choose',
    select: 'Choose',
    array: 'Choose',
    default: 'Type in',
  };

  renderSelectItems = (searchText) => {
    const { currentItem = {} } = this.props;
    const values = currentItem.getValues
      ? currentItem.getValues(currentItem)
      : currentItem.values;
    const filteredValues = values.filter((el) => {
      return containsText(
        typeof el === 'string' ? el : el.displayName || el.name,
        searchText
      );
    });

    return filteredValues.map((el, index) => {
      const currentEl = typeof el === 'string' ? { name: el } : el;

      return (
        <SelectPopoverLight.MenuItem
          key={index}
          value={currentEl.name}
          primaryText={currentEl.displayName || currentEl.name}
          onClick={() => this.handleChange(currentEl.name)}
        />
      );
    });
  };

  render() {
    const { currentItem, error } = this.props;
    const type = currentItem?.type;
    const Input = this.getInputType(type);

    if (type === 'boolean') {
      return null;
    }

    return (
      <div className={cls.filterItemResult}>
        <Input {...this.inputProps} />
        {(this.isAutopopulationEnabled ||
          type === 'select' ||
          type === 'array') && <Error error={error || ''} />}
      </div>
    );
  }
}
