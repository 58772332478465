import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import projectReportsApi from 'src/api/ProjectReports';
import taggingsApi from 'src/api/Taggings';
import IconSubItem from 'src/components/IconSubItem/IconSubItem';
import { Card, Container, Progress, Table } from 'src/components/IMUI';
import { getText } from 'src/services/DictionaryService';
import { convertToPercent } from 'src/utils/number';
import { where } from 'im/api/Query';
import ModalLinks from './ModalLinks';
import cls from './CalculationTags.module.css';
import TableComposition from 'src/components/IMUI/Tables/TableComposition';
import { Icon } from 'im/ui';
import { isGrantless } from 'src/utils/analysis';
const cx = classNames.bind(cls);

@connect(
  (state) => ({
    project: state.project,
    projectReports: state.projectReportsJsonapi,
    taggings: state.taggings,
  }),
  {
    getProjectReports: projectReportsApi.findAllPerProject,
    getTaggings: taggingsApi.findAllPerProject,
  }
)
export default class CalculationTags extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    tagGroups: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    grantView: PropTypes.bool.isRequired,
    project: PropTypes.object,
    isDefault: PropTypes.bool,
    taggings: PropTypes.object,
    projectReports: PropTypes.object,
    getTaggings: PropTypes.func,
    getProjectReports: PropTypes.func,
    outcomesExpanded: PropTypes.bool,
    max: PropTypes.number,
  };

  componentWillReceiveProps(nextProps) {
    if (
      (!nextProps.taggings.pending.init && this.props.taggings.pending.init) ||
      (!nextProps.taggings.pending.ui && this.props.taggings.pending.ui)
    ) {
      const reportsIds = nextProps.taggings.data
        .map((tagging) => tagging.report?.id)
        .filter(Boolean);
      if (reportsIds.length > 0)
        this.doRequestProjectReports([...new Set(reportsIds)]);
    }
  }

  doRequestProjectReports = (reportsIds) => {
    this.props.getProjectReports(
      where({ project_id: this.props.project.id })
        .fields('project_report', 'report', 'grantee')
        .fields('grantee', 'name')
        .fields('report', 'id', 'name')
        .filter('report_id_eq_any', reportsIds)
        .include('report', 'grantee')
    );
  };
  doRequestTagTaggings = (tagIds) => {
    this.props.getTaggings(
      where({ project_id: this.props.project.uid })
        .fields(
          'tagging',
          'report',
          'report_content_start',
          'report_content_end'
        )
        .fields('report', 'id', 'name')
        .filter('scope', 'content')
        .filter(`tag_id_eq${Array.isArray(tagIds) ? '_any' : ''}`, tagIds)
        .filter('ignore_orphans', true)
    );
  };

  render() {
    const tagGroupsArray = Array.isArray(this.props.tagGroups)
      ? this.props.tagGroups
      : [this.props.tagGroups];
    const pending =
      this.props.taggings.pending.init ||
      this.props.taggings.pending.ui ||
      this.props.projectReports.pending.init ||
      this.props.projectReports.pending.ui;
    const expandedLabel = this.props.outcomesExpanded
      ? 'Tag Groups & Tags'
      : 'Tag Groups';
    const Cell = this.props.outcomesExpanded ? Table.HCell : Table.Cell;
    const grantless = isGrantless(this.props.project);

    return (
      <div className={cx('wrapper', this.props.className)}>
        <Card smallPadding>
          <Container
            max={this.props.max ?? (this.props.outcomesExpanded ? 2000 : 800)}
          >
            {(this.props.taggings.pending.init ||
              this.props.taggings.pending.ui) && (
              <Progress large className="absolute" />
            )}
            <TableComposition stickyHeader compact>
              <Table.Head bold>
                <Table.HCell
                  textAlign="left"
                  nowrap
                  text={this.props.isDefault ? 'Tag' : expandedLabel}
                />
                {!grantless && (
                  <Table.HCell
                    textAlign="right"
                    nowrap
                    text={
                      this.props.grantView
                        ? getText('Grants')
                        : getText('Grantees')
                    }
                  />
                )}
                {!grantless && this.props.outcomesExpanded && (
                  <Table.HCell textAlign="right" text="%" />
                )}
                <Table.HCell textAlign="right" nowrap text={'Taggings'} />
                {this.props.outcomesExpanded && (
                  <Table.HCell textAlign="right" text="%" />
                )}
              </Table.Head>
              <Table.Body>
                {tagGroupsArray.filter(Boolean).map((tagGroup, i) => (
                  <React.Fragment key={i}>
                    {this.props.isDefault ? null : (
                      <Table.Row
                        key={`tag-group-${tagGroup?.id}`}
                        className={cls.tagGroupRow}
                      >
                        <Cell>
                          <ModalLinks
                            title={tagGroup.title}
                            pending={pending}
                            projectId={this.props.project.id}
                            taggings={this.props.taggings.data}
                            projectReports={this.props.projectReports.data}
                            onRequestTaggings={() =>
                              this.doRequestTagTaggings(
                                tagGroup.tags.map((tag) => tag.id)
                              )
                            }
                          >
                            <Icon name="folder" />
                            &emsp;{tagGroup.title}
                          </ModalLinks>
                        </Cell>
                        {!grantless && (
                          <Cell textAlign="right">
                            {tagGroup.entitiesCount || '-'}
                          </Cell>
                        )}
                        {!grantless && this.props.outcomesExpanded && <Cell />}
                        <Cell textAlign="right">
                          {tagGroup.taggingsCount || '-'}
                        </Cell>
                        {this.props.outcomesExpanded && <Cell />}
                      </Table.Row>
                    )}
                    {!this.props.outcomesExpanded
                      ? null
                      : tagGroup.tags
                          .sort((a, b) => b.taggingsCount - a.taggingsCount)
                          .map((tag) => (
                            <Table.Row key={`tag-group-${tag.id}`}>
                              <Table.Cell>
                                <Container horizontal nowrap centered>
                                  {!this.props.isDefault && (
                                    <IconSubItem style={{ color: '#bcbdc0' }} />
                                  )}
                                  <ModalLinks
                                    title={tag.title}
                                    pending={pending}
                                    projectId={this.props.project.id}
                                    taggings={this.props.taggings.data}
                                    projectReports={
                                      this.props.projectReports.data
                                    }
                                    onRequestTaggings={() =>
                                      this.doRequestTagTaggings(tag.id)
                                    }
                                  />
                                </Container>
                              </Table.Cell>
                              {!grantless && (
                                <Table.Cell textAlign="right">
                                  {tag.entitiesCount || '-'}
                                </Table.Cell>
                              )}
                              {!grantless && this.props.outcomesExpanded && (
                                <Table.Cell textAlign="right">
                                  <span style={{ opacity: 0.66 }}>
                                    {tag.entitiesCount > 0
                                      ? `${convertToPercent(
                                          tag.entitiesCount /
                                            tagGroup.entitiesCount
                                        )}%`
                                      : '-'}
                                  </span>
                                </Table.Cell>
                              )}
                              <Table.Cell textAlign="right">
                                {tag.taggingsCount || '-'}
                              </Table.Cell>
                              {this.props.outcomesExpanded && (
                                <Table.Cell textAlign="right">
                                  <span style={{ opacity: 0.66 }}>
                                    {tag.taggingsCount > 0
                                      ? `${convertToPercent(
                                          tag.taggingsCount /
                                            tagGroup.taggingsCount
                                        )}%`
                                      : '-'}
                                  </span>
                                </Table.Cell>
                              )}
                            </Table.Row>
                          ))}
                  </React.Fragment>
                ))}
              </Table.Body>
            </TableComposition>
          </Container>
        </Card>
      </div>
    );
  }
}
