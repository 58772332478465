import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { deleteGrantee } from 'src/actionCreators/granteesActionCreators';
import { deleteGrant } from 'src/actionCreators/grantsActionCreators';
import grantsExportApi from 'src/api/GrantsExport';
import { confirm } from 'src/components/ConfirmModal/ConfirmModal';
import { Table } from 'src/components/IMUI/index';
import { getOrgText } from 'src/services/DictionaryService';
import { getCountryNameByCode } from 'src/utils/countries';
import { dedummy } from 'src/utils/string';
import { formatMoneyIM } from 'src/utils/currency';
import EntityTable from './EntityTable';
import GrantPane from './GrantPane';
import { arrayToText } from './grantHelpers';
import TableHCellSettings from './TableHCellSettings';
import DatesIndicator from './DatesIndicator';
import ExportConfirmation from 'src/components/ExportConfirmation/ExportConfirmation';
import EditDialogsFunding from './EditDialogsFunding';
import EditDialogsGrant from './EditDialogsGrant';
import EditDialogsGrantee from './EditDialogsGrantee';
import { canManageGrants } from 'src/userStorage';
import { formatYYYYMMDD, toLocaleDate } from 'src/utils/date';
import { deleteFunding } from 'src/actionCreators/fundingActionCreators';
import { where } from 'im/api/Query';

@connect(
  (state) => ({
    organizationCurrent: state.organizationCurrent,
    defaultRegions: state.defaultRegions,
  }),
  {
    deleteGrant,
    deleteGrantee,
    deleteFunding,
    exportGrant: grantsExportApi.single,
    exportGrants: grantsExportApi.all,
  },
  null,
  { forwardRef: true }
)
export default class GrantTable extends React.PureComponent {
  static propTypes = {
    items: PropTypes.object,
    organizationCurrent: PropTypes.object,
    deleteGrant: PropTypes.func.isRequired,
    deleteFunding: PropTypes.func.isRequired,
    exportGrant: PropTypes.func.isRequired,
    exportGrants: PropTypes.func.isRequired,
    urlParam: PropTypes.string,
    isCompact: PropTypes.bool,
    onSort: PropTypes.func.isRequired,
    onReload: PropTypes.func.isRequired,
    onRowClick: PropTypes.func.isRequired,
    onDrawerHide: PropTypes.func.isRequired,
    onToggleCompact: PropTypes.func.isRequired,
  };

  state = {
    granteeToEdit: null,
    grantToEdit: null,
    fundingToEdit: null,
    editingFunding: false,
    editingGrant: false,
    editingGrantee: false,
    exportModalShow: false,
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.items !== this.props.items && this.state.grantToEdit) {
      this.setState({
        grantToEdit: nextProps.items.data.find(
          ({ id }) => this.state.grantToEdit.id == id
        ),
      });
    }
  }
  handleCloseModal = () => {
    this.setState({
      grantToEdit: null,
      granteeToEdit: null,
      fundingToEdit: null,
      editingGrant: false,
      editingGrantee: false,
      editingFunding: false,
      exportModalShow: false,
    });
  };
  handleGranteeEdit = (granteeToEdit) => {
    canManageGrants() &&
      granteeToEdit &&
      this.setState({ granteeToEdit, editingGrantee: true });
  };
  handleGrantEdit = (grantToEdit) => {
    canManageGrants() &&
      grantToEdit &&
      this.setState({ grantToEdit, editingGrant: true });
  };
  handleFundingEdit = (fundingToEdit, grantToEdit, granteeToEdit) => {
    canManageGrants() &&
      fundingToEdit &&
      this.setState({
        grantToEdit,
        fundingToEdit,
        granteeToEdit,
        editingFunding: true,
      });
  };
  handleEdit = (ev, grant) => {
    ev.preventDefault();
    ev.stopPropagation();
    canManageGrants() && this.handleGrantEdit(grant);
  };
  handleDelete = (ev, grant) => {
    ev.preventDefault();
    ev.stopPropagation();
    canManageGrants() && this.handleDeleteGrant(grant);
  };
  handleDeleteGrant = (grant) =>
    canManageGrants() &&
    confirm(
      'Delete Grant',
      <p>Are you sure you want to delete this grant {grant.name}?</p>,
      true
    )
      .then(() => {
        this.props.deleteGrant(grant.id).then(() => {
          this.props.onReload();
          this.props.onDrawerHide();
        });
      })
      .catch(() => this.forceUpdate());
  handleDeleteFunding = (funding) =>
    canManageGrants() &&
    confirm(
      'Delete Funding',
      <p>Are you sure you want to delete this funding?</p>,
      true
    )
      .then(() => {
        this.props.deleteFunding(funding.id).then(() => this.props.onReload());
      })
      .catch(() => this.forceUpdate());
  // eslint-disable-next-line react/no-unused-class-component-methods
  handleExport = () => {
    this.props
      .exportGrants(
        where(null, (this.props.items.meta.getAction || {}).queryParams).sort(
          'name'
        )
      )
      .then(() => this.setState({ exportModalShow: true }));
  };
  handleExportGrant = (grantId) =>
    this.props
      .exportGrant({ id: grantId })
      .then(() => this.setState({ exportModalShow: true }));

  render() {
    const customGrantFields =
      this.props.organizationCurrent.data?.custom_grant_fields ?? [];
    const funding = (grant) => grant.fundings?.find((f) => f.start_date) || {};
    const limitSize = (text) =>
      this.props.isCompact && String(text).length > 150
        ? `${String(text).substring(0, 150)}...`
        : text; // around 7 lines
    const hasAnyField = (field) => {
      if (field.includes('_list'))
        return this.props.items.data.some(
          (g) => Array.isArray(g?.[field]) && g[field].length > 0
        );
      return this.props.items.data.some(
        (g) => g?.[field] != undefined && g?.[field]?.length > 0
      );
    };
    return (
      <div>
        <EntityTable
          compact={this.props.isCompact}
          head={
            <Table.Head
              jsonapi
              sortBy={this.props.items.meta.getAction?.queryParams?.sort}
              onSortChange={this.props.onSort}
            >
              <Table.HCell
                width={250}
                text={getOrgText('Grant Name')}
                sortBy="name"
              />
              {hasAnyField('number') && (
                <Table.HCell width={176} text="Number" sortBy="number" />
              )}
              <Table.HCell width={76} text="Fundings" />
              <Table.HCell width={150} text="Total amount" />
              <Table.HCell width={100} text={getOrgText('Grantees')} />
              <Table.HCell width={400} text={getOrgText('Grantees')} />
              <Table.HCell
                width={200}
                text="Duration"
                sortBy="fundings.start_date"
              />
              <Table.HCell width={176} text="Funding Type" />
              {hasAnyField('status') && (
                <Table.HCell width={120} text="Status" sortBy="status" />
              )}
              {hasAnyField('approx_region') && (
                <Table.HCell
                  width={176}
                  text="Regions"
                  sortBy="approx_region"
                />
              )}
              {hasAnyField('country_code') && (
                <Table.HCell
                  width={176}
                  text="Countries"
                  sortBy="country_code"
                />
              )}
              {hasAnyField('portfolio_list') && (
                <Table.HCell
                  width={176}
                  text="Portfolios"
                  sortBy="portfolio_list"
                />
              )}
              {hasAnyField('strategies_list') && (
                <Table.HCell
                  width={176}
                  text="Strategies"
                  sortBy="strategies_list"
                />
              )}
              {hasAnyField('cohort_list') && (
                <Table.HCell width={176} text="Cohorts" sortBy="cohort_list" />
              )}
              {hasAnyField('donor_list') && (
                <Table.HCell width={176} text="Donors" sortBy="donor_list" />
              )}
              {hasAnyField('population_list') && (
                <Table.HCell
                  width={176}
                  text="Populations of Focus"
                  sortBy="population_list"
                />
              )}
              {hasAnyField('area_of_work_list') && (
                <Table.HCell
                  width={176}
                  text="Areas of Work"
                  sortBy="area_of_work_list"
                />
              )}
              {customGrantFields?.map((field, i) => (
                <Table.HCell key={i} width={200} text={field} />
              ))}
              <Table.HCell width={96} text="Created" sortBy="created_at" />
              <TableHCellSettings
                isCompact={this.props.isCompact}
                onToggleCompact={this.props.onToggleCompact}
              />
            </Table.Head>
          }
          body={this.props.items.data.map((grant) => (
            <Table.Row
              key={grant.id}
              onClick={() => this.props.onRowClick(grant.id)}
            >
              <Table.Cell
                text={dedummy(grant.name)}
                to={`/grants/list/grant/${grant.id}${window.location.search}`}
              />
              {hasAnyField('number') && <Table.Cell text={grant.number} />}
              <Table.Cell text={grant.meta?.funding_count} />
              <Table.Cell
                text={formatMoneyIM(
                  grant.meta?.total_funding,
                  funding(grant).currency
                )}
              />
              <Table.Cell text={grant.grantees?.length} />
              <Table.Cell
                text={
                  grant.grantees?.map((grantee) => grantee?.name).join(', ') ??
                  ''
                }
              />
              <Table.Cell>
                {funding(grant).start_date && funding(grant).end_date && (
                  <span>
                    <DatesIndicator
                      startDate={funding(grant).start_date}
                      endDate={funding(grant).end_date}
                    />
                    {`${formatYYYYMMDD(
                      funding(grant).start_date
                    )}\u00A0- ${formatYYYYMMDD(funding(grant).end_date)}`}
                  </span>
                )}
              </Table.Cell>
              <Table.Cell text={funding(grant).type} />
              {hasAnyField('status') && <Table.Cell text={grant.status} />}
              {hasAnyField('approx_region') && (
                <Table.Cell text={grant.approx_region} />
              )}
              {hasAnyField('country_code') && (
                <Table.Cell text={getCountryNameByCode(grant.country_code)} />
              )}
              {hasAnyField('portfolio_list') && (
                <Table.Cell text={arrayToText(grant.portfolio_list)} />
              )}
              {hasAnyField('strategies_list') && (
                <Table.Cell text={arrayToText(grant.strategies_list)} />
              )}
              {hasAnyField('cohort_list') && (
                <Table.Cell text={arrayToText(grant.cohort_list)} />
              )}
              {hasAnyField('donor_list') && (
                <Table.Cell text={arrayToText(grant.donor_list)} />
              )}
              {hasAnyField('population_list') && (
                <Table.Cell text={arrayToText(grant.population_list)} />
              )}
              {hasAnyField('area_of_work_list') && (
                <Table.Cell text={arrayToText(grant.area_of_work_list)} />
              )}
              {customGrantFields?.map((f, i) => (
                <Table.Cell
                  key={i}
                  width={200}
                  text={limitSize(grant.custom_fields?.[f])}
                />
              ))}
              <Table.Cell text={toLocaleDate(grant.created_at)} />
              <Table.CellSettings
                showOnHover
                onEdit={(ev) => this.handleEdit(ev, grant, grant.fundings)}
                onDelete={(ev) => this.handleDelete(ev, grant)}
              />
            </Table.Row>
          ))}
        />

        <GrantPane
          key={this.props.urlParam}
          urlParam={this.props.urlParam}
          onRequestClose={this.props.onDrawerHide}
          onExport={this.handleExportGrant}
          onDelete={this.handleDeleteGrant}
          onEdit={this.handleGrantEdit}
          onEditGrantee={this.handleGranteeEdit}
          onEditFunding={this.handleFundingEdit}
          onDeleteFunding={this.handleDeleteFunding}
        />

        {this.state.editingFunding && (
          <EditDialogsFunding
            fundingToEdit={this.state.fundingToEdit}
            grants={{ items: this.props.items.data }}
            grant={this.state.grantToEdit}
            grantee={this.state.granteeToEdit}
            onSubmitSuccessful={this.props.onReload}
            onCloseModal={this.handleCloseModal}
          />
        )}
        {this.state.editingGrant && (
          <EditDialogsGrant
            grants={{ items: this.props.items.data }}
            grant={this.state.grantToEdit}
            onSubmitSuccessful={this.props.onReload}
            onCloseModal={this.handleCloseModal}
          />
        )}
        {this.state.editingGrantee && (
          <EditDialogsGrantee
            onSubmitSuccessful={this.props.onReload}
            grantee={this.state.granteeToEdit}
            onCloseModal={this.handleCloseModal}
          />
        )}
        {this.state.exportModalShow && (
          <ExportConfirmation
            open={true}
            onRequestClose={this.handleCloseModal}
          />
        )}
      </div>
    );
  }
}
