import React from 'react';
import { Button } from 'src/components/IMUI';
import cls from './TipInfo.module.css';
const TAGS_TEMPLATE_URL =
  'https://s3.amazonaws.com/im-public/CSV_Templates/TagsTemplate.csv';
const CSV_PREPARE_URL =
  'https://help.impactmapper.com/en/articles/2443884-how-do-i-prepare-my-reports-csv-before-upload';
const CSV_CLEANING_URL =
  'https://help.impactmapper.com/en/articles/5900396-how-do-i-clean-csv-files-for-import';

const TipInfoTag = () => (
  <p className={cls.subTitle}>
    <strong>Important: </strong>Make sure to prepare your data in a compatible
    CSV format according to&nbsp;
    <Button
      text
      underlined
      target="_blank"
      className={cls.templateButton}
      href={TAGS_TEMPLATE_URL}
      label="this tag template"
    />{' '}
    and use best practices for&nbsp;
    <Button
      text
      underlined
      target="_blank"
      className={cls.templateButton}
      href={CSV_PREPARE_URL}
      label="preparing your CSV"
    />{' '}
    and&nbsp;
    <Button
      text
      underlined
      target="_blank"
      className={cls.templateButton}
      href={CSV_CLEANING_URL}
      label="data cleaning"
    />
    .
  </p>
);
export default TipInfoTag;
