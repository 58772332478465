import {
  CHART__CLEANUP_STORE,
  CHART__AXIS_DATA_GET_REQUEST,
  CHART__AXIS_DATA_GET_SUCCESS,
  CHART__AXIS_DATA_GET_FAILURE,
  CHART__CSV_UPLOAD,
} from 'src/constants';

const initialState = {
  data: [],
  pending: false,
  error: null,
  meta: {},
};

export default function chartsAxisDataReducer(state = initialState, action) {
  switch (action.type) {
    case CHART__CSV_UPLOAD:
      return {
        ...state,
        data: action.payload.data,
        pending: false,
        meta: action.meta,
        error: null,
      };
    case CHART__AXIS_DATA_GET_REQUEST:
      return {
        ...state,
        pending: true,
        data: [],
        meta: action.meta,
        error: null,
      };
    case CHART__AXIS_DATA_GET_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        pending: false,
        meta: action.meta,
        error: null,
      };
    case CHART__AXIS_DATA_GET_FAILURE:
      return {
        ...initialState,
        error: 'Failed to fetch chart data',
      };
    case CHART__CLEANUP_STORE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
