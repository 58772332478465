import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import cls from './Table.module.css';

const cx = classNames.bind(cls);

export default class TableHeader extends Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
  };

  render() {
    const { className, children } = this.props;
    return <table className={cx('table', className)}>{children}</table>;
  }
}
