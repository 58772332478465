import omit from 'ramda/src/omit';
import {
  PROJECT__CREATE_SUCCESS,
  PROJECT__GET_INIT_SUCCESS,
  PROJECT__UPDATE_SUCCESS,
  PROJECT__DELETE_SUCCESS,
  PROJECT__GRANTS_GET_SUCCESS,
  PROJECT__GRANTEES_GET_SUCCESS,
  PROJECT__GET_SUCCESS,
  PROJECT__GET_INIT_REQUEST,
  PROJECT__GET_REQUEST,
} from 'src/constants';
const omitGrantData = (state, project) =>
  state.grants || state.grantees
    ? omit(['grants', 'grantees'], project)
    : project;

export default function projectReducer(state = {}, action) {
  switch (action.type) {
    case PROJECT__GET_INIT_REQUEST:
    case PROJECT__GET_REQUEST:
      const sameProject = action.payload.projectId == state.id;
      return sameProject ? { ...state } : {};

    case PROJECT__GET_INIT_SUCCESS:
    case PROJECT__GET_SUCCESS:
      return { ...state, ...omitGrantData(state, action.payload.data) };

    case PROJECT__UPDATE_SUCCESS:
      window.project_need_tags_refresh = true;
      return { ...state, ...omitGrantData(state, action.payload.data) };
    // TODO: remove this ugly workaround // grants differ if requested through /project/:projectId, or project/:projectId/grants we care about response from the second one, that's why we have to omit "grants" here

    case PROJECT__CREATE_SUCCESS:
      return { ...action.payload.data };

    case PROJECT__DELETE_SUCCESS:
      return {};

    case PROJECT__GRANTS_GET_SUCCESS:
      return { ...state, grants: action.payload.data };

    case PROJECT__GRANTEES_GET_SUCCESS:
      return { ...state, grantees: action.payload.data };

    default:
      return state;
  }
}
