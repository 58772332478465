export default function uiReducer(state = { actionsInProgress: 0 }, action) {
  if (action.type.match(/(_REQUEST|\/request)$/)) {
    return { ...state, actionsInProgress: state.actionsInProgress + 1 };
  }
  if (action.type.match(/(_SUCCESS|_FAILURE|\/success|\/failure)$/)) {
    return {
      ...state,
      actionsInProgress: Math.max(state.actionsInProgress - 1, 0),
    };
  }
  return state;
}
