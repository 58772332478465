import React from 'react';
import PropTypes from 'prop-types';
import { Table, CardEmpty, Button, Progress } from 'src/components/IMUI';
import { getText } from 'src/services/DictionaryService';
import { canManageReport } from 'src/userStorage';
import TableComposition from 'src/components/IMUI/Tables/TableComposition';
import ReportItem from './ReportItem';
import historyInstance from 'src/historyInstance';

export default class ReportList extends React.PureComponent {
  static propTypes = {
    reports: PropTypes.object,
    noGrantAndGrantee: PropTypes.bool,
    projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onDeleteReport: PropTypes.func.isRequired,
    onEditReport: PropTypes.func.isRequired,
    onCreateReport: PropTypes.func.isRequired,
    onSortChange: PropTypes.func.isRequired,
  };

  reloadReport = () => {
    window.resetState = 'reload';
    historyInstance.replace(`/analysis/${this.props.projectId}/reports/#`);
  };
  render() {
    const hasFilters =
      Object.keys(this.props.reports.meta?.getAction?.queryParams?.filter || {})
        .length > 1;
    if (!this.props.reports.data.length)
      return (
        <CardEmpty
          title="No reports to display"
          description="please create a new one"
        >
          <Button
            style={{ minWidth: 120 }}
            disabled={!canManageReport()}
            label="Create report"
            secondary
            onClick={this.props.onCreateReport}
          />
          {hasFilters && (
            <div>
              <p>or</p>
              <Button
                style={{ minWidth: 120 }}
                label="Clear Filters"
                secondary
                onClick={this.reloadReport}
              />
            </div>
          )}
        </CardEmpty>
      );
    return (
      <TableComposition>
        {(this.props.reports.pending.init || this.props.reports.pending.ui) && (
          <Progress large className="absolute" />
        )}
        <Table.Head
          jsonapi
          sortBy={this.props.reports.meta.getAction?.queryParams?.sort}
          onSortChange={this.props.onSortChange}
        >
          <Table.HCell width={28} />
          <Table.HCell text="Report name" sortBy="name" />
          <Table.HCell text="Report type" sortBy="report_type" />
          {this.props.noGrantAndGrantee ? null : (
            <Table.HCell text={getText('Grantee')} sortBy="grantee_name" />
          )}
          {this.props.noGrantAndGrantee ? null : (
            <Table.HCell text={getText('Grant')} sortBy="grant_name" />
          )}
          <Table.HCell width={110} text="Code" sortBy="code" />
          <Table.HCell text="Period" sortBy="period" />
          <Table.HCell text="Global Region" sortBy="global_region" />
          <Table.HCell width={100} text="Date" sortBy="date" />
          <Table.HCell width={100} text="Created" sortBy="created_at" />
          <Table.HCell width={70} text="Taggings" />
          <Table.HCell width={70} />
        </Table.Head>
        <Table.Body>
          {this.props.reports.data.map((report, i) => (
            <ReportItem
              key={report.id + i}
              report={report}
              projectId={this.props.projectId}
              noGrantAndGrantee={this.props.noGrantAndGrantee}
              onDeleteReport={this.props.onDeleteReport}
              onEditReport={this.props.onEditReport}
            />
          ))}
        </Table.Body>
      </TableComposition>
    );
  }
}
