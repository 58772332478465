import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Icon } from 'im/ui/Icon';
import { getHeaderDetailsForType } from './QuestionEdit';

import cls from './QuestionAvatar.module.css';

export default class QuestionAvatar extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    isLayout: PropTypes.bool,
    onClick: PropTypes.func,
  };
  onClick = () => {
    this.props.onClick(this.props.type, this.props.isLayout);
  };

  render() {
    const question = getHeaderDetailsForType(this.props.type);
    return (
      <div className={cls.questionAvatar} onClick={this.onClick}>
        <Icon name={question.icon} /> {question.title}
      </div>
    );
  }
}
