import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import organizationsApi from 'src/api/Organizations';
import { Section, Actions, Container, Button } from 'src/components/IMUI/index';
import Page from 'src/components/Page/Page';
import { where } from 'im/api/Query';
import UserOrgInputs from '../components/UserOrgInputs';
import { fromFormDataToApi } from '../components/utils';
import { canManageOrganization, isStaff } from 'src/userStorage';

const FORM_NAME = 'userOrganizationAdd';
@connect(null, { submit, createOrganization: organizationsApi.create })
export default class UserOrganizationAdd extends React.PureComponent {
  static propTypes = {
    submit: PropTypes.func.isRequired,
    createOrganization: PropTypes.func.isRequired,
  };
  onSubmitRequest = () => this.props.submit(FORM_NAME);
  onSubmit = ({ logo, ...data }) => {
    this.props.createOrganization(
      where()
        .payload(fromFormDataToApi(data))
        .actionMeta({ logo: Array.isArray(logo) ? logo[0] : logo })
    );
  };
  render() {
    return (
      <Page
        hasFooter
        back="/user/organizations"
        title="Create organization"
        id="scrollContainer_userOrgAdd"
      >
        <Section collapsed>
          <UserOrgInputs
            canEdit
            isNew
            form={FORM_NAME}
            initialValues={{ currency: 'USD' }}
            onSubmit={this.onSubmit}
          />
        </Section>
        {canManageOrganization() && (
          <Section type="sticky-footer">
            <Container horizontal>
              <Actions />
              <Actions>
                <Button
                  size="l"
                  label={isStaff() ? 'Create' : 'Submit request'}
                  onClick={this.onSubmitRequest}
                />
              </Actions>
            </Container>
          </Section>
        )}
      </Page>
    );
  }
}
