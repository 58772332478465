import reducerRegistry from 'im/api/registry';
import Resource from 'im/api/Resource';

const getScreenType = (screenWidth) => {
  if (screenWidth < 768) {
    return 'mobile';
  }
  if (screenWidth >= 768 && screenWidth < 992) {
    return 'tablet';
  }
  if (screenWidth >= 992 && screenWidth < 1200) {
    return 'desktop';
  }
  return 'largeDesktop';
};

const isSmallChange = (newWidth, curWidth) => {
  const curType = getScreenType(curWidth);
  const newType = getScreenType(newWidth);
  return curType === newType && Math.abs(newWidth - curWidth) < 50;
};

const reducers = (actionTypes, action, state) => {
  if (action.type !== actionTypes.resize) return state;
  if (isSmallChange(action.payload.screenWidth, state.data.width)) return state;

  return {
    ...state,
    data: {
      width: action.payload.screenWidth,
      type: getScreenType(action.payload.screenWidth),
    },
  };
};

const apiConfig = {
  type: 'screen',
  actions: {
    resize: (type, screenWidth) => (dispatch) =>
      dispatch({ type, payload: { screenWidth } }),
  },
  reducers,
};

export const Screen = new Resource(apiConfig);
reducerRegistry.register(Screen);
