import React from 'react';
import PropTypes from 'prop-types';
import matchers from 'src/data/matchers';
import { FilterBreadcrumbs } from './FilterBreadcrumbs';
import { toLocaleDate } from 'src/utils/date';

export default class FilterSelected extends React.PureComponent {
  static propTypes = {
    config: PropTypes.array,
    filters: PropTypes.object,
    onAdd: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
    renderSelected: PropTypes.func,
  };

  static defaultProps = { filters: {} };

  constructor(props) {
    super(props);
    this.state = {
      filtersFormatted: this.formatFilters(props.filters, props.config),
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.filters !== this.props.filters) {
      this.setState({
        filtersFormatted: this.formatFilters(
          nextProps.filters,
          nextProps.config
        ),
      });
    }
  }
  getDisplayVariable = (currentItem) => currentItem?.displayName ?? '';
  getDisplayMatcher = (list, matcher) =>
    list?.[Object.keys(list || {}).find((m) => m == matcher) ?? ''] ?? '';
  getDisplayResult = (currentItem, result) => {
    if (currentItem?.type == 'date') return result && toLocaleDate(result);
    if (currentItem?.type == 'select')
      return (
        currentItem?.values?.find(({ name }) => name == result)?.displayName ||
        currentItem?.values?.find(({ name }) => name == result)?.name
      );
    return result;
  };
  handleClear = (item) => this.props.onClear(item?.key);
  formatFilters = (filters, config) =>
    Object.keys(filters || {}).map((key) => {
      const currentItem = config.find(
        (el) => filters[key].variable == el?.name
      );
      return {
        key,
        variable: this.getDisplayVariable(currentItem),
        matcher:
          this.getDisplayMatcher(matchers, filters[key].matcher) ||
          currentItem?.matchers?.[filters[key].matcher],
        result:
          this.getDisplayResult(currentItem, filters[key].result) ||
          filters[key].result,
      };
    });

  render() {
    return (
      <FilterBreadcrumbs
        filters={this.state.filtersFormatted}
        onAdd={this.props.onAdd}
        onEdit={this.props.onEdit}
        onClear={this.handleClear}
        renderSelected={this.props.renderSelected}
      />
    );
  }
}
