import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';
import MuiWrapper from 'src/components/MuiWrapper/MuiWrapper';
import history from 'src/historyInstance';
import ScreenResizeService from 'src/services/ScreenResizeService';
import store from 'src/store';
import AnswerSurveyIndex from './pages/AnswerSurvey/index';
import App from './pages/App';
import Login from './pages/Login/Login';
import ForgotPassword from './pages/Password/ForgotPassword';
import ForgotPasswordSuccess from './pages/Password/ForgotPasswordSuccess';
import ResetPassword from './pages/Password/ResetPassword';
import Register from './pages/Register/Register';
import RegisterSuccess from './pages/Register/RegisterSuccess';
import Signup from './pages/Signup/Signup';
import RegisterOrganization from './pages/Register/RegisterOrganization';

class AppRouter extends React.PureComponent {
  componentDidMount() {
    ScreenResizeService.run(store.dispatch);
  }
  componentWillUnmount() {
    ScreenResizeService.cancel();
  }
  render() {
    return (
      <Provider store={store}>
        <MuiWrapper>
          <ConnectedRouter history={history}>
            <div>
              <Switch>
                <Route exact path="/login" component={Login} />
                <Route exact path="/register" component={Signup} />
                <Route
                  path="/checkout/:checkoutId"
                  component={RegisterOrganization}
                />
                <Route
                  exact
                  path="/invitations/success"
                  component={RegisterSuccess}
                />
                <Route
                  exact
                  path="/invitations/:invitationCode"
                  component={Register}
                />
                <Redirect from="/invitations/*" to="/login" />
                <Route
                  exact
                  path="/password/reset/:resetToken"
                  component={ResetPassword}
                />
                <Route
                  exact
                  path="/password/forgot/success"
                  component={ForgotPasswordSuccess}
                />
                <Route
                  exact
                  path="/password/forgot"
                  component={ForgotPassword}
                />
                <Redirect from="/password/*" to="/login" />
                <Route path="/answer-survey" component={AnswerSurveyIndex} />
                <Route path="/" component={App} /> {/* logged in user */}
              </Switch>
            </div>
          </ConnectedRouter>
        </MuiWrapper>
      </Provider>
    );
  }
}

export default AppRouter;
