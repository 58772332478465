import classNames from 'classnames/bind';
import React from 'react';

import { Icon } from 'im/ui/Icon';

import { TextField } from './TextField';

import cls from './SearchField.module.css';

const cx = classNames.bind(cls);

const INPUT_STYLE = { paddingLeft: 42 };
const HINT_STYLE = { paddingLeft: 25 };

export class SearchField extends React.Component {
  static propTypes = {
    ...TextField.propTypes,
  };

  render() {
    const { ...rest } = this.props;

    return (
      <div className={cx('search-field-wrapper')}>
        <Icon name="search" className={cx('search-field-icon')} />
        <TextField {...rest} inputStyle={INPUT_STYLE} hintStyle={HINT_STYLE} />
      </div>
    );
  }
}

export default SearchField;
