import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field, getFormSyncErrors } from 'redux-form';
import isEmail from 'validator/lib/isEmail';

import FlexColumn from 'src/components/FlexColumn/FlexColumn';
import FlexRow from 'src/components/FlexRow/FlexRow';
import {
  TextField,
  Toggle,
  SelectField,
  FormField,
  Divider,
  Container,
  PhoneInput,
} from 'src/components/IMUI';
import colors from 'src/css/constants.json';
import COUNTRIES from 'src/data/countries.json';
import createValidator from 'src/utils/validation';
import { Icon } from 'im/ui/Icon';
import cls from './UserSettingsForm.module.css';

const MFA_TOOLTIP =
  'Login will require not only<br />a password and email, but also SMS code';

function validate(values) {
  const not = (fn) => (v) => !fn(v);
  const isDefined = (v) => Boolean(v);
  const longerThan = (n) => (v) => v.length > n;
  return createValidator({
    first_name: [
      [not(isDefined), () => 'First name is required'],
      [not(longerThan(1)), () => 'First name is too short'],
    ],
    last_name: [
      [not(isDefined), () => 'Last name is required'],
      [not(longerThan(1)), () => 'Last name is too short'],
    ],
    email: [
      [not(isDefined), () => 'Email is required'],
      [not(isEmail), () => 'It is not a valid email'],
    ],
    phone: [
      [
        (v) => v && v.length && !PhoneInput.validate(v),
        () => 'It is not a valid phone number',
      ],
    ],
  })(values);
}

@reduxForm({ enableReinitialize: true, validate })
@connect((state, ownProps) => ({
  errors: getFormSyncErrors(ownProps.form)(state),
}))
export default class UserSettingsForm extends React.PureComponent {
  static propTypes = {
    submitting: PropTypes.bool.isRequired,
    formValues: PropTypes.object,
    initialValues: PropTypes.object,
    errors: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };
  static defaultProps = { formValues: {}, errors: {} };
  render() {
    const countryCode =
      this.props.initialValues.country_code ||
      PhoneInput.getCountryCode(this.props.initialValues.phone);
    return (
      <form onSubmit={this.props.handleSubmit(this.props.onSubmit)} noValidate>
        <FlexRow style={{ flexWrap: 'nowrap' }}>
          <FlexColumn neighbourSpacing>
            <FormField anchorScrollName="first_name">
              <Field
                fullWidth
                name="first_name"
                component={TextField}
                label="First name"
                hintText="Type in..."
                disabled={this.props.submitting}
              />
            </FormField>
          </FlexColumn>
          <FlexColumn neighbourSpacing>
            <FormField anchorScrollName="last_name">
              <Field
                fullWidth
                name="last_name"
                component={TextField}
                label="Last name"
                hintText="Type in..."
                disabled={this.props.submitting}
              />
            </FormField>
          </FlexColumn>
        </FlexRow>

        <FormField anchorScrollName="email">
          <Field
            type="email"
            name="email"
            component={TextField}
            label="Email"
            hintText="Type in..."
            disabled={this.props.submitting}
          />
        </FormField>

        <FormField anchorScrollName="country_code">
          <Field
            name="country_code"
            component={SelectField}
            label="Country"
            hintText="Choose"
          >
            <SelectField.Item key="null" value={null}>
              {' '}
              -{' '}
            </SelectField.Item>
            {COUNTRIES.map(({ code, name }) => (
              <SelectField.Item value={code} key={code} primaryText={name} />
            ))}
          </Field>
        </FormField>

        <Divider className={cls.userSettingsDivider} />

        <FlexRow style={{ flexWrap: 'nowrap' }}>
          <FlexColumn neighbourSpacing>
            <FormField anchorScrollName="phone">
              <Field
                fullWidth
                countryCode={countryCode}
                name="phone"
                label="Phone"
                component={PhoneInput}
              />
            </FormField>
          </FlexColumn>

          <FlexColumn neighbourSpacing className={cls.columnCentered}>
            <Container horizontal nowrap className={cls.toggleContainer}>
              <Field
                component={Toggle}
                name="use_otp"
                label="2-step Verification"
                labelPosition="right"
                className={cls.mfaToggle}
                disabled={
                  !this.props.formValues.phone ||
                  Boolean(
                    this.props.formValues.phone && this.props.errors.phone
                  )
                }
                style={{ width: 'auto' }}
              />
              <Icon
                name="question-filled"
                color={colors['mid-grey']}
                tip={MFA_TOOLTIP}
              />
            </Container>
          </FlexColumn>
        </FlexRow>

        <small className={cls.phoneInputDescription}>
          Your phone number will be used for security purposes, such as helping
          you get back into your account if you ever forget your password.
        </small>
      </form>
    );
  }
}
