import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { Toggle, SelectField } from 'src/components/IMUI';
import cls from './RestrictAnswersSetting.module.css';
const cx = classNames.bind(cls);
const MIN_ANSWERS = 'minAnswers';
const MAX_ANSWERS = 'maxAnswers';
const RESTRICT_ANSWERS = 'restrictAnswers';

const mapAnswerOptions = (options) =>
  options.map((_, i) => ({ value: i + 1, label: i + 1 }));

const getState = (options) => ({
  minAnswerOptions: [{ value: 0, label: '–' }].concat(
    mapAnswerOptions(options)
  ),
  maxAnswerOptions: [...mapAnswerOptions(options)].reverse(),
});

class RestrictAnswersSetting extends React.PureComponent {
  static propTypes = {
    onChangeQuestionProperty: PropTypes.func.isRequired,
    settings: PropTypes.object.isRequired,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    options: PropTypes.array.isRequired,
    label: PropTypes.string.isRequired,
  };

  state = getState(this.props.options);

  handleAnswersRestrictionToggle = () => {
    const nextState = getState(this.props.options);
    this.props.onChangeQuestionProperty(
      this.props.id,
      ['settings', MAX_ANSWERS],
      this.props.settings.restrictAnswers
        ? null
        : nextState.maxAnswerOptions[0]?.value
    );
    this.props.onChangeQuestionProperty(
      this.props.id,
      ['settings', MIN_ANSWERS],
      this.props.settings.restrictAnswers
        ? null
        : nextState.minAnswerOptions[0]?.value
    );
    this.props.onChangeQuestionProperty(
      this.props.id,
      ['settings', RESTRICT_ANSWERS],
      !this.props.settings.restrictAnswers
    );
    this.setState(nextState);
  };

  handleAnswersRestrictionChange = (key, value) => {
    this.props.onChangeQuestionProperty(
      this.props.id,
      ['settings', key],
      value
    );
    if (key === MIN_ANSWERS && this.props.settings[MAX_ANSWERS] < value) {
      this.props.onChangeQuestionProperty(
        this.props.id,
        ['settings', MAX_ANSWERS],
        value
      );
    }
    if (key === MAX_ANSWERS && this.props.settings[MIN_ANSWERS] > value) {
      this.props.onChangeQuestionProperty(
        this.props.id,
        ['settings', MIN_ANSWERS],
        value
      );
    }
  };

  renderAnswersRestrictionSelection = () => {
    if (!this.props.settings.restrictAnswers) return null;
    return (
      <div className={cx('answersRestrictionSelectWrapper')}>
        <span>Minimum:</span>
        <SelectField
          flatDark
          noValidation
          value={this.props.settings.minAnswers}
          onChange={({ value }) =>
            this.handleAnswersRestrictionChange(MIN_ANSWERS, value)
          }
        >
          {this.state.minAnswerOptions.map((o) => (
            <SelectField.Item
              key={o.value}
              value={o.value}
              primaryText={o.label}
            />
          ))}
        </SelectField>
        <span>Maximum:</span>
        <SelectField
          flatDark
          noValidation
          value={this.props.settings.maxAnswers}
          onChange={({ value }) =>
            this.handleAnswersRestrictionChange(MAX_ANSWERS, value)
          }
        >
          {this.state.maxAnswerOptions.map((o) => (
            <SelectField.Item
              key={o.value}
              value={o.value}
              primaryText={o.label}
            />
          ))}
        </SelectField>
      </div>
    );
  };

  render() {
    return (
      <div>
        <Toggle
          mini
          label={<span>{this.props.label}</span>}
          labelPosition="right"
          toggled={this.props.settings.restrictAnswers}
          onToggle={this.handleAnswersRestrictionToggle}
        />
        {this.renderAnswersRestrictionSelection()}
      </div>
    );
  }
}

export default RestrictAnswersSetting;
