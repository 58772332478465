/* eslint-disable react/no-unused-class-component-methods */
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import cls from './tables.module.css';
const cx = classNames.bind(cls);

export class TableComposition extends React.PureComponent {
  static propTypes = {
    compact: PropTypes.bool,
    className: PropTypes.string,
    stickyHeader: PropTypes.bool,
    stickyColumn: PropTypes.oneOf(['first']),
    layout: PropTypes.oneOf(['auto', 'fixed']),
    children: PropTypes.node,
  };

  static defaultProps = { layout: 'auto' };

  render() {
    const { className, compact, stickyHeader, layout, stickyColumn, children } =
      this.props;
    return (
      <div className={cls.tableWrapper}>
        <table
          ref={(ref) => (this.innerRef = ref)}
          className={cx(
            'imui-table',
            `imui-table-layout-${layout}`,
            {
              'imui-table-compact': !!compact,
              'imui-table-sticky': stickyHeader,
              [`imui-table-sticky-column-${stickyColumn}`]: stickyColumn,
            },
            className
          )}
        >
          {children}
        </table>
      </div>
    );
  }
}

export default TableComposition;
