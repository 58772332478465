import axios from 'src/axiosInstance';
import {
  GRANTEES__GET_REQUEST,
  GRANTEES__GET_SUCCESS,
  GRANTEES__GET_FAILURE,
  GRANTEE__CREATE_REQUEST,
  GRANTEE__CREATE_SUCCESS,
  GRANTEE__CREATE_FAILURE,
  GRANTEE__DELETE_REQUEST,
  GRANTEE__DELETE_SUCCESS,
  GRANTEE__DELETE_FAILURE,
  GRANTEE__UPDATE_REQUEST,
  GRANTEE__UPDATE_SUCCESS,
  GRANTEE__UPDATE_FAILURE,
  GRANTEE__CONTACT_CREATE_REQUEST,
  GRANTEE__CONTACT_CREATE_SUCCESS,
  GRANTEE__CONTACT_CREATE_FAILURE,
  GRANTEE__CONTACT_UPDATE_REQUEST,
  GRANTEE__CONTACT_UPDATE_SUCCESS,
  GRANTEE__CONTACT_UPDATE_FAILURE,
  GRANTEE__CONTACT_DELETE_REQUEST,
  GRANTEE__CONTACT_DELETE_SUCCESS,
  GRANTEE__CONTACT_DELETE_FAILURE,
} from 'src/constants';
const doGetGrantees = () =>
  axios
    .get('/v2/grantees')
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
const doCreateGrantee = (payload) =>
  axios
    .post('/v2/grantees/', payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
const doDeleteGrantee = (granteeId) =>
  axios
    .delete(`/v2/grantees/${granteeId}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
const doUpdateGrantee = (grantee) =>
  axios
    .put(`/v2/grantees/${grantee.id}`, grantee)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
const doCreateGranteeContact = (granteeId, contact) =>
  axios
    .post('/v2/contacts', { ...contact, grantee_id: granteeId })
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
const doUpdateGranteeContact = (granteeId, contact) =>
  axios
    .put(`/v2/contacts/${contact.id}`, { grantee_id: granteeId, ...contact })
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
const doDeleteGranteeContact = (contactId) =>
  axios
    .delete(`/v2/contacts/${contactId}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));

export const getGrantees = () => async (dispatch) => {
  dispatch({ type: GRANTEES__GET_REQUEST });
  const { response, error } = await doGetGrantees();
  if (response) {
    dispatch({ type: GRANTEES__GET_SUCCESS, payload: response });
  } else {
    dispatch({ type: GRANTEES__GET_FAILURE, payload: error, error: true });
  }
};

export const createGrantee = (payload) => async (dispatch) => {
  dispatch({ type: GRANTEE__CREATE_REQUEST });
  const { response, error } = await doCreateGrantee(payload);
  if (response) {
    dispatch({
      type: GRANTEE__CREATE_SUCCESS,
      payload: { grantee: response.data },
    });
    return response.data;
  }
  dispatch({ type: GRANTEE__CREATE_FAILURE, payload: error, error: true });
};

export const deleteGrantee = (granteeId) => async (dispatch) => {
  dispatch({ type: GRANTEE__DELETE_REQUEST });
  const { response, error } = await doDeleteGrantee(granteeId);
  if (response) {
    dispatch({ type: GRANTEE__DELETE_SUCCESS, payload: { granteeId } });
    return response;
  }
  dispatch({ type: GRANTEE__DELETE_FAILURE, payload: error, error: true });
};

export const updateGrantee = (grantee) => async (dispatch) => {
  dispatch({ type: GRANTEE__UPDATE_REQUEST });
  const { response, error } = await doUpdateGrantee(grantee);
  if (response) {
    dispatch({
      type: GRANTEE__UPDATE_SUCCESS,
      payload: { grantee: response.data },
    });
    return response.data;
  }
  dispatch({ type: GRANTEE__UPDATE_FAILURE, payload: error, error: true });
};

export const createGranteeContact =
  (granteeId, contact) => async (dispatch) => {
    dispatch({ type: GRANTEE__CONTACT_CREATE_REQUEST });
    const { response, error } = await doCreateGranteeContact(
      granteeId,
      contact
    );
    if (response) {
      dispatch({
        type: GRANTEE__CONTACT_CREATE_SUCCESS,
        payload: { granteeId, contact: response.data },
      });
      return response.data;
    }
    dispatch({
      type: GRANTEE__CONTACT_CREATE_FAILURE,
      payload: error,
      error: true,
    });
  };

export const updateGranteeContact =
  (granteeId, contact) => async (dispatch) => {
    dispatch({ type: GRANTEE__CONTACT_UPDATE_REQUEST });
    const { response, error } = await doUpdateGranteeContact(
      granteeId,
      contact
    );
    if (response) {
      dispatch({
        type: GRANTEE__CONTACT_UPDATE_SUCCESS,
        payload: { granteeId, contact: response.data },
      });
      return response.data;
    }
    dispatch({
      type: GRANTEE__CONTACT_UPDATE_FAILURE,
      payload: error,
      error: true,
    });
  };

export const deleteGranteeContact =
  (granteeId, contactId) => async (dispatch) => {
    dispatch({ type: GRANTEE__CONTACT_DELETE_REQUEST });
    const { response, error } = await doDeleteGranteeContact(contactId);
    if (response) {
      dispatch({
        type: GRANTEE__CONTACT_DELETE_SUCCESS,
        payload: { granteeId, contactId },
      });
      return response;
    }
    dispatch({
      type: GRANTEE__CONTACT_DELETE_FAILURE,
      payload: error,
      error: true,
    });
  };
