import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Container, Divider, Tag } from 'src/components/IMUI';
import { Label } from 'src/components/IMUI/Forms/base';

import { Icon } from 'im/ui/Icon';

import cls from './TagItemReview.module.css';
import { canManageTag } from 'src/userStorage';

export default class TagItemReview extends Component {
  static propTypes = {
    tag: PropTypes.object,
    onEdit: PropTypes.func.isRequired,
  };

  handleEdit = () => {
    const { tag } = this.props;
    this.props.onEdit(tag);
  };

  render() {
    const { tag } = this.props;

    return (
      <div>
        <Divider className={cls.tagItemReviewDivider} />

        <Container className={cls.tagItemReviewChunk}>
          <Label label="Tag name" />

          <Container
            horizontal
            nowrap
            className={cls.tagItemReviewChunkContainer}
          >
            <Tag inline label={tag.title} />
            <Icon
              disabled={!canManageTag()}
              name="edit"
              tip="Edit"
              onClick={this.handleEdit}
              className={cls.tagItemReviewIcon}
            />
          </Container>
        </Container>

        {!!tag.description && (
          <Container className={cls.tagItemReviewChunk}>
            <Label label="Description" />

            <Container
              horizontal
              nowrap
              className={cls.tagItemReviewChunkContainer}
            >
              <p>{tag.description}</p>
              <Icon
                disabled={!canManageTag()}
                name="edit"
                tip="Edit"
                onClick={this.handleEdit}
                className={cls.tagItemReviewIcon}
              />
            </Container>
          </Container>
        )}

        {!!tag.code && (
          <Container className={cls.tagItemReviewChunk}>
            <Label label="Shortcode" />

            <Container
              horizontal
              nowrap
              className={cls.tagItemReviewChunkContainer}
            >
              <span>{tag.code}</span>
              <Icon
                disabled={!canManageTag()}
                name="edit"
                tip="Edit"
                onClick={this.handleEdit}
                className={cls.tagItemReviewIcon}
              />
            </Container>
          </Container>
        )}
      </div>
    );
  }
}
