import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Icon } from 'im/ui/Icon';

import TooltipIcon from '../TooltipIcon';

import cls from './MergeTagForm.module.css';

const cx = classnames.bind(cls);

// const mergeOptionsValues = {
//   FROM_IMPACT_MAPPER: "impact_mapper",
//   FROM_PROJECT: "project",
// };

// const mergeOptions = [
//   {
//     label: "Remove tags only from current project",
//     value: mergeOptionsValues.FROM_PROJECT,
//     tooltip: "Tag will be removed from project,
// but not be deleted from ImpactMapper and will be available for use in other projects.",
//   },
//   {
//     label: "Delete tag after merging",
//     value: mergeOptionsValues.FROM_IMPACT_MAPPER,
//     tooltip: "Tags will be permanently deleted from all projects.",
//   },
// ];

const MergeOption = ({ value, label, tooltip }) => (
  <div>
    <span>{label}</span>
    <TooltipIcon
      tip={tooltip}
      id={value}
      tipProps={{ className: cls.mergeOptionTooltip }}
    />
  </div>
);

MergeOption.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
};

const MergeTagConfirmationForm = ({ tag, tagsToMerge }) => (
  <div>
    <h3>Merging Tags</h3>
    <div
      className={cx(cls.editTagMergeIconWrapper, cls.editTagMergeIconWarning)}
    >
      <Icon
        name="warning-triangle"
        style={{ fontSize: '24px', color: '#fa3e3e' }}
      />
    </div>
    <span>
      You are about to merge <b>{tagsToMerge.titles}</b> into <b>{tag.title}</b>
      , affecting {tagsToMerge.count} taggings. How do you want to proceed?
    </span>
    {/* Do not have delete options by now
    <RadioButtonGroup
      name="radio-group_remove-options"
      valueSelected={value}
      className={cls.mergeOptions}
      onChange={onChange}
    >
      {mergeOptions.map(option => (
        <RadioButton
          key={option.value}
          label={<MergeOption {...option} />}
          value={option.value}
          data-qa={`MergeTagConfirmationForm:radioButton--${option.value}`}
        />
      ))}
      </RadioButtonGroup>
    */}
  </div>
);

MergeTagConfirmationForm.propTypes = {
  tagsToMerge: PropTypes.object.isRequired,
  tag: PropTypes.object.isRequired,
};

// MergeTagConfirmationForm.mergeOptions = mergeOptionsValues;

export default MergeTagConfirmationForm;
