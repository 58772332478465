import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { getCountryCallingCode } from 'libphonenumber-js';
import MenuItem from 'material-ui/MenuItem';
import colors from 'src/css/constants.json';
import COUNTRIES from 'src/data/countries.json';
import { Icon } from 'im/ui/Icon';
import { Container } from '../../Layout/Container';
import { Menu } from '../../Menu/Menu';
import { PopoverLight } from '../../Popover/PopoverLight';
import cls from './CountryCodesDropdown.module.css';
const cx = classNames.bind(cls);
function isoCountryToEmoji(countryCode) {
  return countryCode
    ? String.fromCodePoint(
        ...countryCode
          .toUpperCase()
          .split('')
          .map((char) => 127397 + char.charCodeAt())
      )
    : '';
}
const Flag = ({ countryCode, style, className }) => (
  <span style={style} className={cx('countryCodeFlag', className)}>
    {countryCode ? isoCountryToEmoji(countryCode) : '+??'}
  </span>
);
Flag.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  countryCode: PropTypes.string,
};
export default class CountryCodesDropdown extends React.PureComponent {
  static propTypes = {
    dropdownOpen: PropTypes.bool,
    countryCode: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onRequestOpen: PropTypes.func.isRequired,
    onRequestClose: PropTypes.func.isRequired,
  };
  static defaultProps = { countryCode: COUNTRIES[0]?.code };
  constructor(props) {
    super(props);
    this.state = {
      countries: this.getCountries(),
      allowSearch: false,
      currentCountry: this.getCurrentCountry(
        this.getCountries(),
        props.countryCode
      ),
    };
  }
  componentDidMount() {
    const hasSome = this.state.countries.some(
      ({ code }) => code === this.props.countryCode
    );
    if (!hasSome) {
      this.props.onChange(this.state.currentCountry.code);
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.countryCode === this.props.countryCode) return;
    this.setState(
      {
        currentCountry: this.getCurrentCountry(
          this.state.countries,
          nextProps.countryCode
        ),
      },
      () => {
        const hasSome = this.state.countries.some(
          ({ code }) => code === nextProps.countryCode
        );
        if (!hasSome) {
          this.props.onChange(this.state.currentCountry.code);
        }
      }
    );
  }
  onDropdownToggle = () =>
    this.props.dropdownOpen
      ? this.props.onRequestClose()
      : this.props.onRequestOpen();
  onDropdownClose = () => {
    this.setState({ allowSearch: false, countries: this.getCountries() });
    this.props.onRequestClose();
  };
  onMenuItemSelect = (countryCode) => {
    this.setState(
      { currentCountry: COUNTRIES.find((c) => c.code === countryCode) },
      () => {
        //Make it a callback so the update is correct.
        this.props.onChange(countryCode);
        this.onDropdownClose();
      }
    );
  };
  onAllowSearch = () => {
    this.setState({ allowSearch: true });
  };
  onSearchTextChange = (searchText) => {
    this.setState({ countries: this.getCountries(searchText) });
  };
  getCallingCode(code) {
    let twoDigit = null;
    try {
      twoDigit = getCountryCallingCode(code);
    } catch (e) {
      if (!e) console.info(e);
    }
    return twoDigit;
  }
  getCountries = (searchText) =>
    COUNTRIES.map((c) => ({
      name: c.name,
      code: c.code,
      callingCode: this.getCallingCode(c.code),
    }))
      .filter((c) => c.callingCode && c.name && c.code)
      .filter((c) => {
        if (!searchText?.length) return true;
        if (c.name.toLowerCase().includes(searchText.toLowerCase()))
          return true;
        if (searchText.includes(c.callingCode)) return true;
        return false;
      });
  getCurrentCountry = (filteredCountries, current) =>
    filteredCountries.find((filtered) =>
      filteredCountries.some((c) => c.code === current)
        ? filtered.code === current
        : filtered.code === COUNTRIES[0]?.code
    ) || {};

  renderHeader = (country) =>
    country &&
    !this.state.allowSearch && (
      <div className={cls.countryCodeHeader}>
        <div className={cx(cls.countryCodeHeaderText, cls.countryCodeText)}>
          <Flag
            countryCode={country.code}
            className={cls.countryCodeHeaderFlag}
          />
          {country.name}&nbsp;
          <span className={cls.countryCode}>{`+ ${country.callingCode}`}</span>
        </div>
        <Icon
          name="close"
          className={cls.countryCodeHeaderIcon}
          onClick={this.onAllowSearch}
        />
      </div>
    );

  render() {
    return (
      <div
        className={this.props.className}
        ref={(ref) => (this.innerRef = ref)}
      >
        <Container
          horizontal
          nowrap
          className={cls.countryCodeSelected}
          onClick={this.onDropdownToggle}
        >
          <Flag countryCode={this.state.currentCountry.code} />
          <Icon name="chevron-down" className={cls.countryCodeSelectedIcon} />
        </Container>

        <PopoverLight
          open={this.props.dropdownOpen}
          search={this.state.allowSearch}
          header={this.renderHeader(this.state.currentCountry)}
          anchorEl={this.innerRef}
          anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
          targetOrigin={{ horizontal: 'left', vertical: 'top' }}
          onRequestClose={this.onDropdownClose}
          onSearchTextChange={this.onSearchTextChange}
        >
          <Menu
            value={this.state.currentCountry.code}
            maxHeight={270}
            listStyle={{ paddingTop: '0', paddingBottom: '0' }}
            selectedMenuItemStyle={{ color: colors.seafoam }}
          >
            {this.state.countries.map((country) => (
              <MenuItem
                key={country.code}
                value={country.code}
                style={{ margin: '12px 0' }}
                onClick={() => this.onMenuItemSelect(country.code)}
                leftIcon={<Flag countryCode={country.code} />}
                primaryText={
                  <span className={cls.countryCodeText}>
                    {country.name}&nbsp;
                    <span
                      className={cls.countryCode}
                    >{`+ ${country.callingCode}`}</span>
                  </span>
                }
              />
            ))}
          </Menu>
        </PopoverLight>
      </div>
    );
  }
}
