import cond from 'ramda/src/cond';
import flatten from 'ramda/src/flatten';
import { scroller } from 'react-scroll';

import { normalizeFormName } from 'src/utils/string';

export default function createValidator(validators) {
  return function validator(values) {
    return Object.keys(validators).reduce(
      (acc, key) => ({
        ...acc,
        [key]: cond(validators[key])(values[key]),
      }),
      {}
    );
  };
}

function getErrorFieldNames(obj = {}, name = '') {
  const errorArr = [];
  errorArr.push(
    Object.keys(obj)
      .map((key) => {
        const next = obj[key];
        if (next) {
          if (typeof next === 'string') {
            return name + key;
          }
          // Keep looking
          if (next.map) {
            errorArr.push(
              next
                .map((item, index) =>
                  getErrorFieldNames(item, `${name}${key}[${index}].`)
                )
                .filter((o) => o)
            );
          }
        }
        return null;
      })
      .filter((o) => o)
  );
  return flatten(errorArr);
}

function getFirstError(errors = [], wrapper) {
  return errors
    .map((e) => `position-${normalizeFormName(e)}`)
    .reduce((k, l) =>
      (wrapper?.querySelector(`[name=${k}]`) || {})?.offsetTop <
      (wrapper?.querySelector(`[name=${l}]`) || {})?.offsetTop
        ? k
        : l
    );
}

export function handleSubmitFail(errors, isDialog) {
  if (!errors) {
    return;
  }

  const wrapper = isDialog
    ? document.querySelector('[class^=imui-dialog-body-container]')
    : document.querySelector('[id^=scrollContainer_]');
  const errorFields = getErrorFieldNames(errors);
  const firstField = getFirstError(errorFields, wrapper);

  if (wrapper && !wrapper?.id && isDialog) {
    wrapper.id = `scroller-${wrapper.className}`;
  }

  scroller.scrollTo(firstField, {
    smooth: true,
    duration: 500,
    containerId: wrapper?.id,
  });
}
