import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import createValidator, { handleSubmitFail } from 'src/utils/validation';
import { connect } from 'react-redux';
import {
  Form,
  FieldArray,
  reduxForm,
  getFormValues,
  getFormSyncErrors,
} from 'redux-form';
import {
  Button,
  Container,
  Table,
  Section,
  Progress,
} from 'src/components/IMUI';
import { getOrgText } from 'src/services/DictionaryService';

import { getTrintDocuments } from 'src/actionCreators/reportActionCreators';
import TableComposition from 'src/components/IMUI/Tables/TableComposition';
import cls from './SyncReportForm.module.css';
import TrintDocumentField from './TrintDocumentField';
import store from 'src/store';
import { userCurrentOrgId } from 'src/userStorage';

const FORM_NAME_TRINT_IMPORT = 'reportImportTrintDocuments';

function mapStateToProps(state, ownProps) {
  return {
    formValues: getFormValues(ownProps.form)(state),
    formErrors: getFormSyncErrors(ownProps.form)(state),
  };
}

const renderTrintDocumentField = ({ fields, grants, grantees }) => {
  return (
    <Table.Body>
      {fields.map((field, i) => (
        <TrintDocumentField
          name={`trint_documents[${i}]`}
          grants={grants}
          grantees={grantees}
          key={field.id + i}
          document={field}
        />
      ))}
    </Table.Body>
  );
};

const SyncReportForm = ({ project, grants, grantees, handleSubmit }) => {
  const [loading, setLoading] = useState(true);
  const [trintDocuments, setTrintDocuments] = useState([]);

  useEffect(() => {
    store.dispatch(getTrintDocuments()).then((response) => {
      setTrintDocuments(response.data);
      setLoading(false);
    });
  }, []);

  return (
    <Section>
      <h3>
        Import Trint API Documents to <strong>{project.name}</strong>.
      </h3>
      <p>
        <strong>Important: </strong> The following documents are fetched
        from&nbsp;
        <Button
          text
          underlined
          target="_blank"
          className={cls.infoButton}
          href={`/user/organizations/edit/${userCurrentOrgId()}/datasources`}
          label="Trint API"
        />
        .&nbsp;
        <br />
        Please select the reports reports you want to import to this project.
      </p>

      <Form onSubmit={handleSubmit}>
        {loading ? (
          <Progress large />
        ) : (
          <TableComposition>
            <Table.Head>
              <Table.HCell text="Title" />
              <Table.HCell width={200} text={getOrgText('Grant')} />
              <Table.HCell width={200} text={getOrgText('Grantee')} />
              <Table.HCell width={200} text="Preview" />
              <Table.HCell text="Import?" />
            </Table.Head>
            <FieldArray
              name="trint_documents"
              component={renderTrintDocumentField}
              fields={trintDocuments}
              grants={grants}
              grantees={grantees}
            />
          </TableComposition>
        )}

        <Container className={cls.formButtonContainer}>
          <Button
            type={'submit'}
            size="l"
            label="Import to this Project"
            className={cls.formButton}
          />
        </Container>
      </Form>
    </Section>
  );
};

function validateTrintImportInputs(values = {}) {
  const not = (fn) => (v) => !fn(v);
  const isDefined = (v) => Boolean(v);
  const documentErrors = [];

  const validator = createValidator({
    grant_id: [[not(isDefined), () => 'Grant is required']],
    grantee_id: [[not(isDefined), () => 'Grantee is required']],
  });

  if (values && values.trint_documents) {
    values.trint_documents.forEach((document, index) => {
      documentErrors[index] = validator(document);
    });
  }

  return { trint_documents: documentErrors };
}

function validate(values) {
  return validateTrintImportInputs(values);
}

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({}, dispatch)
)(
  reduxForm({
    form: FORM_NAME_TRINT_IMPORT,
    enableReinitialize: true,
    validate,
    onSubmitFail: (errors) => handleSubmitFail(errors),
  })(SyncReportForm)
);
