import PropTypes from 'prop-types';
import React from 'react';

import { Divider, Container, Button } from 'src/components/IMUI';

import { Icon } from 'im/ui/Icon';

import cls from './ActionButtons.module.css';
import { canManageTag } from 'src/userStorage';

const QUICK_ADD_TOOLTIP_TEXT = `
  Quick add allows you to quickly add multiple tags.<br />
  Description and shortcode can be applied to the tags<br />
  individually after they’ve been added.`;
const INDIVIDUAL_TOOLTIP_TEXT = `
  Adding tags individually allows you<br />
  to add multiple tags one-by-one by providing<br />
  a name, description and shortcode for each.`;

const ActionButtons = ({ onAddAnother, onModeChange, mode }) => {
  if (mode === 'edit') return null;

  return (
    <div className={cls.actionButtons}>
      <Divider className={cls.actionButtonsDivider} />
      {mode === 'quick' ? (
        <Button
          disabled={!canManageTag()}
          text
          className={cls.actionButton}
          icon={<Icon name="question-filled" tip={INDIVIDUAL_TOOLTIP_TEXT} />}
          label="Add tags individually"
          onClick={() => onModeChange('detailed')}
        />
      ) : (
        <Container horizontal spaceBetween centered>
          <Button
            disabled={!canManageTag()}
            text
            label="Add another tag"
            onClick={onAddAnother}
          />
          <Button
            disabled={!canManageTag()}
            text
            className={cls.actionButton}
            icon={<Icon name="question-filled" tip={QUICK_ADD_TOOLTIP_TEXT} />}
            label="Quick add&nbsp;"
            onClick={() => onModeChange('quick')}
          />
        </Container>
      )}
    </div>
  );
};

ActionButtons.propTypes = {
  mode: PropTypes.oneOf(['quick', 'detailed', 'edit']),
  onAddAnother: PropTypes.func,
  onModeChange: PropTypes.func.isRequired,
};

export default ActionButtons;
