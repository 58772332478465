import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import StoriesImg from 'src/static/stories.png';
import FlexColumn from 'src/components/FlexColumn/FlexColumn';
import {
  Card,
  Container,
  Section,
  CardEmpty,
  Button,
} from 'src/components/IMUI';
import Page from 'src/components/Page/Page';

export default class StoriesList extends React.PureComponent {
  static propTypes = {
    match: ReactRouterPropTypes.match,
    history: ReactRouterPropTypes.history,
  };
  onCreateStoryClick = () => {
    this.props.history.push({
      pathname: `/analysis/${this.props.match.params.projectId}/stories/new`,
    });
  };

  render() {
    return (
      <Page title="Stories">
        <Section horizontal sunken grow>
          <FlexColumn
            style={{ position: 'relative', paddingRight: 16, height: '100%' }}
          >
            <Card style={{ height: '100%' }}>
              <CardEmpty large title="No stories to display">
                <Container>
                  <img
                    src={StoriesImg}
                    alt="time to create a story"
                    style={{ height: 300 }}
                  />
                  <Button
                    size="l"
                    secondary
                    label="Create your first story"
                    onClick={this.onCreateStoryClick}
                  />
                </Container>
              </CardEmpty>
            </Card>
          </FlexColumn>
        </Section>
      </Page>
    );
  }
}
