import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { destroy, getFormValues } from 'redux-form';
import { bindActionCreators } from 'redux';
import { createProject } from 'src/actionCreators/projectActionCreators';
import { Section } from 'src/components/IMUI';
import Page from 'src/components/Page/Page';
import { toApiFromCreateProject } from 'src/serializers/projectSerializer';
import AnalysisAddType from './components/AnalysisAddTypeForm';
export const FORM_NAME = 'newAnalysis';

class AnalysisAdd extends React.PureComponent {
  static propTypes = {
    createProject: PropTypes.func.isRequired,
    formValues: PropTypes.object,
    destroy: PropTypes.func,
  };
  static defaultProps = { formValues: {} };
  componentWillUnmount() {
    this.props.destroy(FORM_NAME);
  }
  render() {
    return (
      <Page
        back
        hasFooter
        helpAppcue="-LLZuCfwPI59FOOxCL6N"
        title="New project"
        pending={false}
      >
        <Section collapsed fullHeight={false}>
          <AnalysisAddType
            form={FORM_NAME}
            activeValue={this.props.formValues.grantee_type}
            onSubmit={(data) =>
              this.props.createProject(toApiFromCreateProject(data))
            }
          />
        </Section>
      </Page>
    );
  }
}

export default connect(
  (state) => ({
    destroy,
    formValues: getFormValues(FORM_NAME)(state),
  }),
  (dispatch) => bindActionCreators({ createProject }, dispatch)
)(AnalysisAdd);
