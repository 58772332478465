import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Container } from 'src/components/IMUI';
import { Icon } from 'im/ui/Icon';
import Cell from './Cell';
import cls from './CellSettings.module.css';
const cx = classNames.bind(cls);

export default class CellSettings extends React.PureComponent {
  static propTypes = {
    ...Cell.propTypes,
    showOnHover: PropTypes.bool,
    children: PropTypes.node,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
  };

  render() {
    const { className, showOnHover, children, ...rest } = this.props;
    return (
      <Cell
        className={cx(
          cls.actionsCell,
          { [cls.showOnHover]: showOnHover },
          className
        )}
        {...rest}
      >
        <Container
          horizontal
          nowrap
          centered
          className={cls.cellSettingsContainer}
        >
          {this.props.onDelete && (
            <Icon name="trash" tip="Remove" onClick={this.props.onDelete} />
          )}
          {this.props.onEdit && (
            <Icon name="edit" tip="Edit" onClick={this.props.onEdit} />
          )}
          {children}
        </Container>
      </Cell>
    );
  }
}
