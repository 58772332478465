import axios from 'axios';
import normalizeLinks from '../normalization/normalizeLinks';
import normalizePayload from '../normalization/normalizePayload';

const doRequest = (request) =>
  request()
    .then(({ data }) => ({ response: data, success: true }))
    .catch((err) => {
      if (axios.isCancel(err)) {
        return { wasCancelled: true };
      }

      const { response: { data, status } = {} } = err;
      return { response: data, status };
    });

const entityActionCreator =
  (actionTypes, request, actionMeta = {}) =>
  async (dispatch) => {
    dispatch({ type: actionTypes.request, meta: actionMeta });
    const { response, success, wasCancelled } = await doRequest(request);

    if (wasCancelled) {
      dispatch({
        type: actionTypes.failure,
        payload: { errors: [] },
        meta: { silent: true },
      });
      return null;
    }

    if (success) {
      const includeParams = (actionMeta.queryParams || {}).include || [];
      const includeParamsArray = Array.isArray(includeParams)
        ? includeParams
        : [includeParams];
      const includeFilter = includeParamsArray?.flatMap((param) => {
        const paramMapping = actionMeta.includeMapper?.[param];
        return !paramMapping
          ? [param]
          : [param].concat(
              Array.isArray(paramMapping) ? paramMapping : [paramMapping]
            );
      });

      const payload = {
        ...response,
        data: normalizePayload(response.data, response.included, includeFilter),
        links: normalizeLinks(response.links),
      };
      dispatch({
        payload,
        type: actionTypes.success,
        meta: actionMeta,
      });

      return payload;
    }

    dispatch({
      type: actionTypes.failure,
      payload: { response },
      error: true,
      meta: actionMeta,
    });
    return Promise.reject({ errors: response?.errors || [] });
  };

export default entityActionCreator;
