import {
  STORAGE__UPDATE,
  PROJECT__GET_INIT_SUCCESS,
  PROJECT__GET_INIT_FAILURE,
  PROJECT__DELETE_SUCCESS,
  PROJECT__UPDATE_SUCCESS,
} from 'src/constants';
import { saveStorage } from 'src/userStorage';

export default function storageEffects(state, action) {
  switch (action.type) {
    case STORAGE__UPDATE:
    case PROJECT__GET_INIT_SUCCESS:
    case PROJECT__GET_INIT_FAILURE:
    case PROJECT__UPDATE_SUCCESS:
    case PROJECT__DELETE_SUCCESS:
      saveStorage(state);
      break;
    default:
      break;
  }
}
