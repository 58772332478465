import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRegions } from 'src/actionCreators/regionsActionCreators';
import {
  Section,
  Container,
  Button,
  Actions,
  SelectField,
} from 'src/components/IMUI/index';
import Page from 'src/components/Page/Page';
import history from 'src/historyInstance';
import { getOrgText } from 'src/services/DictionaryService';
import GranteeForm, { FORM_NAME } from '../components/GranteeForm';
import cls from './GranteeAdd.module.css';
import { canManageGrants } from 'src/userStorage';
import pick from 'ramda/src/pick';
import { getIntersected } from 'src/utils/customFields';

@connect(pick(['organizationCurrent', 'defaultRegions']), { getRegions })
export default class GranteeAdd extends React.PureComponent {
  static propTypes = {
    organizationCurrent: PropTypes.object,
    defaultRegions: PropTypes.array,
    getRegions: PropTypes.func.isRequired,
  };
  state = { type: 'organization' };

  componentDidMount() {
    this.props.getRegions();
  }
  handleTypeChange = ({ value }) => {
    this.setState({ type: value });
  };
  onSubmitSuccess = (grantee) => {
    history.push(`/grants/list/grantee/${grantee?.id}`);
  };

  render() {
    return (
      <Page back hasFooter title={getOrgText('Add Grantee')}>
        <Section collapsed className={cls.grantAddSection}>
          <div className={cls.grantAddCentered}>
            <h3 className={cls.titleCustomMargin}>
              {getOrgText('Grantee details')}
            </h3>

            <SelectField
              onChange={this.handleTypeChange}
              label="Type"
              hintText="Choose"
              name="type"
              value={this.state.type}
            >
              <SelectField.Item
                primaryText="Organization"
                value="organization"
              />
              <SelectField.Item primaryText="Individual" value="individual" />
            </SelectField>

            <GranteeForm
              type={this.state.type}
              allGrantees={[]}
              customFields={getIntersected(
                this.props.organizationCurrent.data?.custom_grantee_fields ||
                  [],
                []
              )}
              regions={this.props.defaultRegions}
              customRegionMapping={
                this.props.organizationCurrent.data?.custom_region_mapping
              }
              onSubmitSuccessful={this.onSubmitSuccess}
            />
          </div>
        </Section>

        <Section type="sticky-footer">
          <Container horizontal>
            <Actions>
              <Button
                negative
                size="l"
                label="Back"
                onClick={this.onSubmitSuccess}
              />
            </Actions>
            <Actions>
              <Button
                disabled={!canManageGrants()}
                size="l"
                key="save"
                label={getOrgText('Add Grantee')}
                type="submit"
                form={FORM_NAME}
              />
            </Actions>
          </Container>
        </Section>
      </Page>
    );
  }
}
