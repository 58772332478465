import { Screen } from 'src/api/Screen';

const dispatchResize = (dispatch) =>
  dispatch(Screen.actions.resize(window.innerWidth));
export default {
  run: (dispatch) => {
    window.addEventListener('resize', () => dispatchResize(dispatch));
    dispatchResize(dispatch);
  },
  cancel: () => {
    window.removeEventListener('resize', dispatchResize);
  },
};
