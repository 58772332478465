import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import cls from './Actions.module.css';

const cx = classNames.bind(cls);

export const Actions = ({
  children,
  className,
  grow,
  nogrow,
  grid,
  small,
  nowrap,
  style,
}) => (
  <div
    className={cx(
      'imui-actions',
      {
        'imui-actions--grow': grow,
        'imui-actions--nogrow': nogrow,
        'imui-actions--nowrap': nowrap,
        'imui-actions--small': small,
        'imui-actions--equal-grid': grid,
      },
      className
    )}
    style={style}
  >
    {children}
  </div>
);

Actions.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  nowrap: PropTypes.bool,
  grow: PropTypes.bool,
  grid: PropTypes.bool,
  nogrow: PropTypes.bool,
  small: PropTypes.bool,
  style: PropTypes.object,
};

export default Actions;
