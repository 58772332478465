import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import ChartDownloadMenu from 'src/components/Chart/ChartDownloadMenu';
import {
  Actions,
  Button,
  Container,
  Drawer,
  NumberField,
  Toggle,
} from 'src/components/IMUI';
import { Icon } from 'im/ui/Icon';
import cls from './SummaryItemSettings.module.css';
const cx = classNames.bind(cls);

export default class SummaryItemSettings extends React.PureComponent {
  static propTypes = {
    chartType: PropTypes.oneOf([
      'column',
      'line',
      'bar',
      'responseList',
      'pie',
    ]),
    onSettingChanged: PropTypes.func.isRequired,
    zIndex: PropTypes.number,
    onPopoverClose: PropTypes.func,
    onClearSettings: PropTypes.func,
    settings: PropTypes.object,
    filteredSettings: PropTypes.array,
    title: PropTypes.string,
  };
  static defaultProps = { zIndex: 1400 };
  state = { popoverOpen: false };
  onPopoverOpen = (ev) => {
    ev?.preventDefault();
    this.setState({ popoverOpen: true });
  };
  onPopoverClose = () => {
    this.setState({ popoverOpen: false });
    this.props.onPopoverClose?.();
  };
  onSettingChanged = (setting, state) => {
    if (state && setting.name === 'groupBySeries')
      return this.props.onSettingChanged({
        groupBySeries: true,
        aggregateColumns: false,
      });
    if (state && setting.name === 'aggregateColumns')
      return this.props.onSettingChanged({
        groupBySeries: false,
        aggregateColumns: true,
      });
    this.props.onSettingChanged({ [setting?.name]: state });
  };
  renderFooter = () => (
    <Container horizontal>
      <Actions />
      <Actions>
        <Button
          negative
          size="l"
          label="Reset"
          onClick={this.props.onClearSettings}
        />
      </Actions>
    </Container>
  );
  render() {
    const canExport =
      !['responseList'].includes(this.props.chartType) &&
      Boolean(window.highchart || window.highmap);
    return (
      <div style={{ display: 'flex', gap: 4 }}>
        <Icon
          name="settings"
          tip="Settings"
          className={cx('toggleSettingsButton')}
          onClick={this.onPopoverOpen}
        />
        <Drawer
          openSecondary={true}
          containerClassName={cx('chart-drawer')}
          className={cx('chart-drawer-class')}
          closable
          docked={false}
          zIndex={this.props.zIndex || 1400}
          width={400}
          open={this.state.popoverOpen}
          onRequestClose={this.onPopoverClose}
          renderFooter={this.renderFooter}
        >
          <section
            style={{ display: 'flex', flexDirection: 'column', gap: 12 }}
          >
            <h5 style={{ marginBottom: 8 }}>Chart general settings</h5>
            <Toggle
              compact
              className={cx('listItemToggle')}
              label="Descending Chart"
              toggled={this.props.settings.dataSorting}
              onToggle={(_ev, toggled) =>
                this.onSettingChanged({ name: 'dataSorting' }, toggled)
              }
            />
            <Toggle
              compact
              className={cx('listItemToggle')}
              style={{ opacity: this.props.settings.dataSorting ? 1 : 0.3 }}
              disabled={!this.props.settings.dataSorting}
              label="Ascending Chart"
              toggled={this.props.settings.xAxisReversed}
              onToggle={(_ev, toggled) =>
                this.onSettingChanged({ name: 'xAxisReversed' }, toggled)
              }
            />
            <Container horizontal nowrap>
              <NumberField
                className={cx('listItemToggle')}
                id="xAxisMax"
                key="xAxisMax"
                noValidation
                label="Top trends"
                hintText="Limit of trends"
                border
                flat
                value={this.props.settings.xAxisMax || null}
                onChange={(value) =>
                  this.onSettingChanged({ name: 'xAxisMax' }, value)
                }
              />
              &emsp;
              <NumberField
                className={cx('listItemToggle')}
                id="xAxisMin"
                key="xAxisMin"
                noValidation
                label="Starting from"
                hintText="Set the trend start"
                border
                flat
                value={this.props.settings.xAxisMin || null}
                onChange={(value) =>
                  this.onSettingChanged({ name: 'xAxisMin' }, value)
                }
              />
            </Container>
            <h5 style={{ marginTop: 8, marginBottom: 8 }}>Other settings</h5>
            {this.props.filteredSettings.map((set) => (
              <Toggle
                key={set.name}
                label={set.label}
                compact
                className={cx('listItemToggle')}
                toggled={this.props.settings[set.name]}
                onToggle={(ev, toggled) => this.onSettingChanged(set, toggled)}
              />
            ))}

            <h5 style={{ marginBottom: 8 }}>Y axis settings</h5>
            <Container horizontal nowrap>
              <NumberField
                className={cx('listItemToggle')}
                id="yAxisMin"
                key="yAxisMin"
                noValidation
                label="Minimum"
                hintText="Restrict min.value"
                border
                flat
                value={this.props.settings.yAxisMin || null}
                onChange={(value) =>
                  this.onSettingChanged({ name: 'yAxisMin' }, value)
                }
              />
              &emsp;
              <NumberField
                className={cx('listItemToggle')}
                id="yAxisMax"
                key="yAxisMax"
                noValidation
                label="Maximum"
                hintText="Restrict max.value"
                border
                flat
                value={this.props.settings.yAxisMax || null}
                onChange={(value) =>
                  this.onSettingChanged({ name: 'yAxisMax' }, value)
                }
              />
            </Container>
          </section>
        </Drawer>

        <ChartDownloadMenu
          key={`${this.props.title}-${this.props.chartType}`}
          className={cx('toggleSettingsButton')}
          showIcon
          title={this.props.title}
          disabled={!canExport}
        />
      </div>
    );
  }
}
