import classNames from 'classnames/bind';
import MUIRadioButtonGroup from 'material-ui/RadioButton/RadioButtonGroup';
import PropTypes from 'prop-types';
import React from 'react';

import cls from './RadioButtonGroup.module.css';

const cx = classNames.bind(cls);

export class RadioButtonGroup extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    readOnly: PropTypes.bool,
  };

  render() {
    const { children, className, readOnly, ...rest } = this.props;

    return (
      <MUIRadioButtonGroup
        className={cx(
          'imui-radiobutton-group',
          { 'imui-radiobutton-group-readonly': readOnly },
          className
        )}
        {...rest}
      >
        {children}
      </MUIRadioButtonGroup>
    );
  }
}

export default RadioButtonGroup;
