import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import cls from './CardFooter.module.css';

const cx = classNames.bind(cls);

export const CardFooter = ({
  children,
  noScroll,
  light,
  minimal,
  noPadding,
  className,
  style = {},
}) => (
  <div
    className={cx(
      'imui-card-footer',
      {
        'imui-card-footer-no-scroll': noScroll,
        'imui-card-footer-light': light,
        'imui-card-footer-minimal': minimal,
        'imui-card-footer-no-padding': noPadding,
      },
      className
    )}
    style={style}
  >
    {children}
  </div>
);

CardFooter.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  light: PropTypes.bool,
  noScroll: PropTypes.bool,
  noPadding: PropTypes.bool,
  minimal: PropTypes.bool,
  style: PropTypes.object,
};

export default CardFooter;
