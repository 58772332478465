import Cell from './Cell';
import CellSettings from './CellSettings';
import ExpandableRow from './ExpandableRow';
import ExpandIcon from './ExpandIcon';
import HCellSettings from './HCellSettings';
import Head from './Head';
import HeaderCell from './HeaderCell';
import Row from './Row';
import { Table as TableComponent } from './Table';

TableComponent.Row = Row;
TableComponent.Head = Head;
TableComponent.ExpandableRow = ExpandableRow;
TableComponent.Cell = Cell;
TableComponent.HeaderCell = HeaderCell;
TableComponent.HCellSettings = HCellSettings;
TableComponent.CellSettings = CellSettings;
TableComponent.HCell = HeaderCell;
TableComponent.ExpandIcon = ExpandIcon;

export const Table = TableComponent;
export { Table as default };
