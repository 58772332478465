import React from 'react';
import classNames from 'classnames/bind';
import { T } from 'lioness';
import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import { connect } from 'react-redux';
import isCurrency from 'validator/lib/isCurrency';
import { NumberField, CardFooter, Container, Tag } from 'src/components/IMUI';
import createQuestion from './createQuestion';
import rightAlignLanguages from './rightAlignLanguages';
import cls from './FinancialQuestion.module.css';
import { TagNotProvided } from './TextQuestion';
import UploadAttachment from 'src/components/Survey/UploadAttachment';
const cx = classNames.bind(cls);

@createQuestion
@connect(pick(['survey']))
export default class FinancialQuestion extends React.PureComponent {
  static propTypes = {
    answer: PropTypes.object,
    surveyAnswers: PropTypes.object,
    isReview: PropTypes.bool,
    isEditing: PropTypes.bool,
    onRefresh: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    question: PropTypes.object,
    readOnly: PropTypes.bool,
    index: PropTypes.number,
    isAnswerMode: PropTypes.bool,
    survey: PropTypes.object,
  };

  static validate(question, _value) {
    if (typeof _value === 'object' && _value !== null)
      return <T>Unexpected value type</T>;
    const parsed = String(_value ?? '')?.trim();
    const shouldValidate = question.required || parsed.length > 0;
    if (
      shouldValidate &&
      !isCurrency(parsed, { digits_after_decimal: [1, 2, 3] })
    ) {
      return <T>Provide valid currency value</T>;
    }
    return null;
  }

  onChange(value) {
    this.props.onChange?.(value);
  }
  renderTag() {
    if (!this.props.isReview) return null;
    if (!this.props.isEditing) return null;
    return (
      <CardFooter minimal className="printHidden">
        <Container horizontal>
          {!this.props.answer.question_taggings.length &&
            this.props.isEditing && <TagNotProvided />}
          {this.props.answer.question_taggings?.map((tagging) => (
            <Tag label={tagging.tag.title} key={tagging.id} />
          ))}
        </Container>
      </CardFooter>
    );
  }

  render() {
    const { question, value, readOnly, index, isAnswerMode, survey } =
      this.props;
    const rightAlign = rightAlignLanguages.includes(survey.activeLanguage);
    return (
      <div className={cx('financial-question')}>
        <div className={cx('financial-question-input')}>
          <NumberField
            name={`financial_${index}`}
            value={value ?? ''}
            onChange={(v) => this.onChange(v)}
            delimitingWithCommas
            readOnly={readOnly}
            noValidation
            flat={!isAnswerMode}
            border={!isAnswerMode}
            flatDark={isAnswerMode}
            style={rightAlign ? { marginRight: '-8px' } : null}
          />
        </div>
        <span style={rightAlign ? { marginRight: '14px' } : {}}>
          {question.currency}
        </span>

        {this.renderTag()}

        {this.props.question.has_attachment && this.props.isAnswerMode && (
          <CardFooter noPadding className="printHidden">
            <UploadAttachment
              isReview={false}
              questionId={this.props.question.id}
              surveyAnswerId={this.props.answer.uid}
              importUpload={this.props.answer.import_upload}
              projectId={this.props.surveyAnswers.info?.projectId}
              granteeSurveyId={this.props.surveyAnswers.info?.grantee_survey_id}
              onRefresh={this.props.onRefresh}
            />
          </CardFooter>
        )}
      </div>
    );
  }
}
