import React from 'react';
import { formatMoney, formatNumber } from 'accounting';
import classNames from 'classnames/bind';
import { Tabs, Tab } from 'material-ui/Tabs';
import PropTypes from 'prop-types';
import { Card, CardEmpty } from 'src/components/IMUI';
import Table from 'src/components/Table/Table';
import TableBody from 'src/components/Table/TableBody';
import TableHeader from 'src/components/Table/TableHeader';
import { getText } from 'src/services/DictionaryService';
import cls from './Demographics.module.css';
const cx = classNames.bind(cls);
const sortDescending = (a, b) => Number(b.num) - Number(a.num);

export default class Demographics extends React.PureComponent {
  static propTypes = {
    demographics: PropTypes.object.isRequired,
    currencySymbol: PropTypes.string,
    grantView: PropTypes.bool.isRequired,
  };
  static defaultProps = { demographics: {} };

  renderFunding = () => {
    if (!this.props.demographics.funding)
      return <CardEmpty title="No data available" />;
    const headers = [
      'Total funding',
      formatMoney(
        this.props.demographics.funding.total / 100,
        this.props.currencySymbol
      ),
    ];
    const toRows = (data) =>
      data.map((v) => [
        v.label && v.label !== 'null' ? v.label : 'N/A',
        formatMoney(v.value / 100, this.props.currencySymbol),
      ]);
    const table = [
      [
        'Funding by country',
        this.props.grantView
          ? []
          : this.props.demographics.funding.by_country_code.sort(
              (a, b) => Number(b.value) - Number(a.value)
            ),
      ],
      [
        'Funding by region',
        this.props.grantView
          ? []
          : this.props.demographics.funding.by_region.sort(
              (a, b) => Number(b.value) - Number(a.value)
            ),
      ],
      [
        'Funding by issue',
        this.props.demographics.funding.by_issue.sort(
          (a, b) => Number(b.value) - Number(a.value)
        ),
      ],
      [
        'Type of support',
        this.props.demographics.funding.by_type.sort(
          (a, b) => Number(b.value) - Number(a.value)
        ),
      ],
      [
        'Length of funding',
        this.props.demographics.funding.by_length
          .sort((a, b) => Number(a.label) - Number(b.label))
          .map((el) => ({ ...el, label: `${el.label} months` })),
      ],
    ].filter(
      ([_, fundingRows]) => !!fundingRows && toRows(fundingRows)?.length > 0
    );

    return (
      <Table>
        <TableHeader headers={headers} className={cx('fundingTableBody')} />
        {table.map(([fundingHeaders, fundingRows], i) => (
          <React.Fragment key={i}>
            <TableHeader
              key={`header-${i}`}
              headers={[fundingHeaders]}
              className={cx('fundingTableHeader')}
            />
            <TableBody
              key={`body-${i}`}
              rows={toRows(fundingRows)}
              className={cx('fundingTableBody')}
            />
          </React.Fragment>
        ))}
      </Table>
    );
  };

  renderRegions = () => {
    const headers = [
      'Region',
      `# ${this.props.grantView ? getText('Grant', 2) : getText('Grantee', 2)}`,
      `% ${this.props.grantView ? getText('Grant', 2) : getText('Grantee', 2)}`,
    ];
    const rows = this.props.demographics.regions
      .sort(sortDescending)
      .map((r) => [
        r.label && r.label !== 'null' ? r.label : 'N/A',
        formatNumber(r.num),
        formatNumber(r.percent * 100),
      ]);
    return { headers, rows };
  };

  renderCountries = () => {
    const headers = [
      'Country',
      `# ${this.props.grantView ? getText('Grant', 2) : getText('Grantee', 2)}`,
      `% ${this.props.grantView ? getText('Grant', 2) : getText('Grantee', 2)}`,
    ];
    const rows = this.props.demographics.countries
      .sort(sortDescending)
      .reduce((acc, r) => {
        const idx = acc.findIndex(([label]) => label == r.label);
        if (idx === -1) acc.push([r.label, r.num, r.percent]);
        if (idx > -1) acc[idx][1] += r.num;
        if (idx > -1) acc[idx][2] += r.percent;
        return acc;
      }, [])
      .sort((a, b) => Number(b[1]) - Number(a[1]))
      .map((r) => [
        r[0] || 'N/A',
        formatNumber(r[1]),
        formatNumber(r[2] * 100),
      ]);
    return { headers, rows };
  };

  renderStates = () => {
    const headers = [
      'State',
      `# ${this.props.grantView ? getText('Grant', 2) : getText('Grantee', 2)}`,
      `% ${this.props.grantView ? getText('Grant', 2) : getText('Grantee', 2)}`,
    ];
    const rows = this.props.demographics.states
      .sort(sortDescending)
      .reduce((acc, r) => {
        const idx = acc.findIndex(([label]) => label == r.label);
        if (idx === -1) acc.push([r.label, r.num, r.percent]);
        if (idx > -1) acc[idx][1] += r.num;
        if (idx > -1) acc[idx][2] += r.percent;
        return acc;
      }, [])
      .sort((a, b) => Number(b[1]) - Number(a[1]))
      .map((r) => [r[0], formatNumber(r[1]), formatNumber(r[2] * 100)]);
    return { headers, rows };
  };

  renderPopulations = () => {
    const headers = [
      'Population',
      `# ${this.props.grantView ? getText('Grant', 2) : getText('Grantee', 2)}`,
      `% ${this.props.grantView ? getText('Grant', 2) : getText('Grantee', 2)}`,
    ];
    const rows = this.props.demographics.populations
      .sort(sortDescending)
      .map((r) => [
        r.label || 'N/A',
        formatNumber(r.num),
        formatNumber(r.percent * 100),
      ]);
    return { headers, rows };
  };

  renderIssues = () => {
    const headers = [
      'Issue',
      `# ${this.props.grantView ? getText('Grant', 2) : getText('Grantee', 2)}`,
      `% ${this.props.grantView ? getText('Grant', 2) : getText('Grantee', 2)}`,
    ];
    const rows = this.props.demographics.issues
      .sort(sortDescending)
      .map((r) => [
        r.label || 'N/A',
        formatNumber(r.num),
        formatNumber(r.percent * 100),
      ]);
    return { headers, rows };
  };

  renderPortfolios = () => {
    const headers = [
      'Portfolio',
      `# ${this.props.grantView ? getText('Grant', 2) : getText('Grantee', 2)}`,
      `% ${this.props.grantView ? getText('Grant', 2) : getText('Grantee', 2)}`,
    ];
    const rows = this.props.demographics.portfolio
      .sort(sortDescending)
      .map((r) => [
        r.label || 'N/A',
        formatNumber(r.num),
        formatNumber(r.percent * 100),
      ]);
    return { headers, rows };
  };
  renderStrategies = () => {
    const headers = [
      'Strategy',
      `# ${this.props.grantView ? getText('Grant', 2) : getText('Grantee', 2)}`,
      `% ${this.props.grantView ? getText('Grant', 2) : getText('Grantee', 2)}`,
    ];
    const rows = this.props.demographics.strategies
      .sort(sortDescending)
      .map((r) => [
        r.label || 'N/A',
        formatNumber(r.num),
        formatNumber(r.percent * 100),
      ]);
    return { headers, rows };
  };
  renderAreas = () => {
    const headers = [
      'Area of Work',
      `# ${this.props.grantView ? getText('Grant', 2) : getText('Grantee', 2)}`,
      `% ${this.props.grantView ? getText('Grant', 2) : getText('Grantee', 2)}`,
    ];
    const rows = this.props.demographics.area_of_work
      .sort(sortDescending)
      .map((r) => [
        r.label || 'N/A',
        formatNumber(r.num),
        formatNumber(r.percent * 100),
      ]);
    return { headers, rows };
  };
  renderPartners = () => {
    const headers = [
      'Partner',
      `# ${this.props.grantView ? getText('Grant', 2) : getText('Grantee', 2)}`,
      `% ${this.props.grantView ? getText('Grant', 2) : getText('Grantee', 2)}`,
    ];
    const rows = this.props.demographics.partner
      .sort(sortDescending)
      .map((r) => [
        r.label || 'N/A',
        formatNumber(r.num),
        formatNumber(r.percent * 100),
      ]);
    return { headers, rows };
  };

  renderRows = (items, { headers, rows }) => {
    if (!items) return <CardEmpty title="No data available" />;
    return rows.length > 0 ? (
      <Table>
        <TableHeader headers={headers} />
        <TableBody rows={rows} className={cx('fundingTableBody')} />
      </Table>
    ) : (
      <CardEmpty title="No data available" />
    );
  };

  render() {
    const hiddenTabs =
      this.props.demographics?.funding?.total == 0 ? ['funding'] : [];
    const availableTabs = [
      { label: 'Funding', name: 'funding' },
      { label: 'Regions', name: 'regions' },
      { label: 'Countries', name: 'countries' },
      { label: 'States', name: 'states' },
      { label: 'Populations', name: 'populations' },
      { label: 'Issues', name: 'issues' },
      { label: 'Portfolios', name: 'portfolio' },
      { label: 'Strategies', name: 'strategies' },
      { label: 'Areas of Work', name: 'area_of_work' },
      { label: 'Partners', name: 'partner' },
    ].filter(({ name }) => {
      if (hiddenTabs.includes(name)) return false;
      if (name == 'regions')
        return (
          this.props.demographics.regions?.filter(
            (r) => !['null', 'N/A', 'undefined'].includes(r?.label)
          )?.length > 0
        );
      if (name == 'countries')
        return (
          this.props.demographics.countries?.filter(
            (r) => !['null', 'N/A', 'undefined'].includes(r?.label)
          )?.length > 0
        );
      if (name == 'states')
        return (
          this.props.demographics.states?.filter(
            (r) => !['null', 'N/A', 'undefined'].includes(r?.label)
          )?.length > 0
        );
      if (name == 'populations')
        return this.props.demographics.populations?.length > 0;
      if (name == 'issues') return this.props.demographics.issues?.length > 0;
      if (name == 'portfolio')
        return this.props.demographics.portfolio?.length > 0;
      if (name == 'strategies')
        return this.props.demographics.strategies?.length > 0;
      if (name == 'area_of_work')
        return this.props.demographics.area_of_work?.length > 0;
      if (name == 'partner') return this.props.demographics.partner?.length > 0;
      return true;
    });

    return (
      <Card style={{ padding: 10 }}>
        <Tabs
          contentContainerClassName={cls.tabContentContainer}
          contentContainerStyle={{
            flexGrow: 1,
            overflow: 'auto',
            position: 'relative',
          }}
          tabItemContainerStyle={{
            borderBottom: '1px solid #f0f2f5',
            width: 'auto',
          }}
        >
          {availableTabs.map((tab) => (
            <Tab
              className={cx('tab')}
              key={tab.name}
              label={tab.label}
              value={tab.name}
            >
              <div className={cx('tabContent')}>
                {tab.name == 'funding' && this.renderFunding()}
                {tab.name == 'regions' &&
                  this.renderRows(
                    this.props.demographics.regions,
                    this.renderRegions()
                  )}
                {tab.name == 'countries' &&
                  this.renderRows(
                    this.props.demographics.countries,
                    this.renderCountries()
                  )}
                {tab.name == 'states' &&
                  this.renderRows(
                    this.props.demographics.states,
                    this.renderStates()
                  )}
                {tab.name == 'populations' &&
                  this.renderRows(
                    this.props.demographics.populations,
                    this.renderPopulations()
                  )}
                {tab.name == 'issues' &&
                  this.renderRows(
                    this.props.demographics.issues,
                    this.renderIssues()
                  )}
                {tab.name == 'portfolio' &&
                  this.renderRows(
                    this.props.demographics.portfolio,
                    this.renderPortfolios()
                  )}
                {tab.name == 'strategies' &&
                  this.renderRows(
                    this.props.demographics.strategies,
                    this.renderStrategies()
                  )}
                {tab.name == 'area_of_work' &&
                  this.renderRows(
                    this.props.demographics.area_of_work,
                    this.renderAreas()
                  )}
                {tab.name == 'partner' &&
                  this.renderRows(
                    this.props.demographics.partner,
                    this.renderPartners()
                  )}
              </div>
            </Tab>
          ))}
        </Tabs>
      </Card>
    );
  }
}
