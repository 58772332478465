import reducerRegistry from 'im/api/registry';
import Resource from 'im/api/Resource';

const apiConfig = {
  root: '/api/v3/tag_categories',
  type: 'tag_categories',
  api: {
    allow: ['find'],
  },
};

export const TagCategory = new Resource(apiConfig, 'tagCategory');
reducerRegistry.register(TagCategory);

export default TagCategory.api;
