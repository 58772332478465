/* eslint no-undef: 0 */
const IS_ENABLED = false; // Seems not be used anymore

// https://docs.appcues.com/article/161-javascript-api
// test Appcues API by appending ?hey_appcues to url proxy with env and service availability check
window.Appcues = window.Appcues || undefined;

let AppcuesUserData = {};

export default {
  start: () => IS_ENABLED && !!Appcues && Appcues.start(), // required to fire flows
  identify: (userId, properties = {}) => {
    if (
      AppcuesUserData.userId !== userId ||
      AppcuesUserData.properties !== properties
    ) {
      AppcuesUserData = { userId, properties };
      return IS_ENABLED && !!Appcues && Appcues.identify(userId, properties);
    }
    return false;
  },
  anonymous: () => {
    AppcuesUserData = {};
    return IS_ENABLED && !!Appcues && Appcues.anonymous();
  },
  page: () => IS_ENABLED && !!Appcues && Appcues.page(),
  track: (eventName, properties) =>
    IS_ENABLED && !!Appcues && Appcues.track(eventName, properties),
  show: (flowId) => IS_ENABLED && !!Appcues && Appcues.show(flowId),
  on: (eventName, callbackFn, context) =>
    IS_ENABLED && !!Appcues && Appcues.on(eventName, callbackFn, context),
  off: (eventName, callbackFn, context) =>
    IS_ENABLED && !!Appcues && Appcues.off(eventName, callbackFn, context),
  once: (eventName, callbackFn, context) =>
    IS_ENABLED && !!Appcues && Appcues.once(eventName, callbackFn, context),
  reset: () => IS_ENABLED && !!Appcues && Appcues.reset(),
  debug: (enable) => IS_ENABLED && !!Appcues && Appcues.debug(enable),
};
