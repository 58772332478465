import { REPORTS__IDS_GET_SUCCESS } from 'src/constants';

export default function reportsIdsReducer(state = [], action) {
  switch (action.type) {
    case REPORTS__IDS_GET_SUCCESS:
      return action.payload.data;
    default:
      return state;
  }
}
