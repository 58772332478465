import MenuItem from 'material-ui/MenuItem';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Actions,
  Button,
  Container,
  SelectField,
  Dialog,
} from 'src/components/IMUI';
import {
  COLORING_STRATEGIES,
  COLORING_STRATEGIES_READABLE,
} from './coloringStrategy';
import cls from './Settings.module.css';
import StorageService from 'src/services/Storage';

export const HIGHLIGHT_COLOURS = [
  '#c1fa8b',
  '#7f82ec',
  '#c72444',
  '#f35a7f',
  '#4DCCBD',
  '#9067C6',
  '#F79D5C',
  '#CAC4CE',
  '#E08DAC',
  '#D5A021',
  '#8def77',
  '#6CCFF6',
  '#C1BDDB',
  '#93B5C6',
  '#7ab4ee',
  '#F3E37C',
  '#F3752B',
  '#F2F3AE',
  '#EDD382',
  '#FC9E4F',
  '#f65a57',
  '#FFD23F',
  '#F3FCF0',
  '#8ee8e1',
];
export default class Settings extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    coloringStrategy: PropTypes.oneOf(Object.keys(COLORING_STRATEGIES)),
    onChangeColoringStrategy: PropTypes.func.isRequired,
    onRequestClose: PropTypes.func.isRequired,
  };

  handleClose = () => {
    this.props.onRequestClose();
  };

  onChangeHighlightColor = (color) => {
    StorageService.removeItem('--highlight-color');
    StorageService.setItem('--highlight-color', color);
    document?.documentElement?.style?.setProperty(
      '--highlight-color',
      StorageService.getItem('--highlight-color')
    );
  };

  render() {
    return (
      <Dialog
        title="Settings"
        open={this.props.open}
        onRequestClose={this.handleClose}
      >
        <section
          onClick={(ev) => {
            ev?.stopPropagation();
            ev?.preventDefault();
          }}
        >
          <h5 style={{ marginBottom: 8 }}>Highlight color</h5>
          <div
            style={{ display: 'flex', flexWrap: 'wrap', gap: 8 }}
            onClick={(ev) => {
              ev?.stopPropagation();
              ev?.preventDefault();
            }}
          >
            {HIGHLIGHT_COLOURS.map((colour, i) => (
              <div
                key={`${i}`}
                className={cls.swatchSquare}
                onClick={(ev) => {
                  ev?.stopPropagation();
                  ev?.preventDefault();
                  this.onChangeHighlightColor(colour);
                }}
              >
                <div className={cls.swatchBody} style={{ background: colour }}>
                  <input
                    type="color"
                    className={cls.swatchInner}
                    defaultValue={colour}
                  />
                </div>
              </div>
            ))}
          </div>
        </section>

        <SelectField
          noValidation
          fullWidth
          className={cls.colorSelect}
          label="Tag Color Scheme"
          value={this.props.coloringStrategy}
          onChange={this.props.onChangeColoringStrategy}
        >
          {Object.keys(COLORING_STRATEGIES_READABLE).map((key) => (
            <MenuItem
              value={key}
              key={key}
              primaryText={COLORING_STRATEGIES_READABLE[key]}
            />
          ))}
        </SelectField>
        <Container>
          <Actions />
          <Actions>
            <Button size="l" label="Close" onClick={this.handleClose} />
          </Actions>
        </Container>
      </Dialog>
    );
  }
}
