import React from 'react';
import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import { connect } from 'react-redux';
import taggingsApi from 'src/api/Taggings';
import {
  Button,
  Card,
  Container,
  Dialog,
  Progress,
  CardEmpty,
  Table,
} from 'src/components/IMUI';
import { where } from 'im/api/Query';
import { Icon } from 'im/ui/Icon';
import QuoteTextDialog from './QuoteTextDialog';
import cls from './Quotes.module.css';
import { canManageTag } from 'src/userStorage';
import { QuoteSymbol } from '../../Summary/components/Quotes';
import TableComposition from 'src/components/IMUI/Tables/TableComposition';
import QuoteIcon from './QuoteIcon';

@connect(pick(['quotes', 'user', 'organizationCurrent', 'project']), {
  createQuote: taggingsApi.quotes.create,
  updateQuote: taggingsApi.quotes.put,
  deleteQuote: taggingsApi.quotes.destroy,
  getQuotes: taggingsApi.quotes.findAllPerTaggable,
})
export default class QuotesList extends React.PureComponent {
  static propTypes = {
    taggableType: PropTypes.oneOf(['reports', 'survey_answers']).isRequired,
    taggableUid: PropTypes.string.isRequired,
    selectedText: PropTypes.string,
    selectionStart: PropTypes.number.isRequired,
    selectionEnd: PropTypes.number.isRequired,
    setSelection: PropTypes.func,
    type: PropTypes.oneOf(['footer', 'minimal']),

    user: PropTypes.object,
    organizationCurrent: PropTypes.object,
    project: PropTypes.object,
    quotes: PropTypes.object,
    createQuote: PropTypes.func.isRequired,
    updateQuote: PropTypes.func.isRequired,
    deleteQuote: PropTypes.func.isRequired,
    getQuotes: PropTypes.func.isRequired,
  };

  state = { quoteToEdit: undefined, createQuote: false, showQuotes: false };

  getQuotes() {
    this.props.getQuotes(
      where({ taggable_id: this.props.taggableUid })
        .filter('project_id_eq', this.props.project.uid)
        .paginate({ size: 601 })
    );
  }
  onUpdateQuote = (text) => {
    this.props
      .updateQuote(
        where({ id: this.state.quoteToEdit?.id }).payload({
          attributes: { content: text, type: 'quotes' },
          relationships: [
            {
              relName: 'project',
              type: 'projects',
              id: this.props.project.uid,
            },
            {
              relName: 'taggable',
              type: this.props.taggableType,
              id: this.props.taggableUid,
            },
          ],
        })
      )
      .then(this.onCloseDialog);
  };
  onSaveQuote = (text = '') => {
    this.props
      .createQuote(
        where()
          .payload({
            attributes: {
              content: text,
              type: 'quotes',
              report_content_start: this.props.selectionStart,
              report_content_end: this.props.selectionEnd,
            },
            relationships: [
              {
                relName: 'organization',
                type: 'organizations',
                id: this.props.organizationCurrent.data.id,
              },
              { relName: 'user', type: 'users', id: this.props.user.data.id },
              {
                relName: 'project',
                type: 'projects',
                id: this.props.project.uid,
              },
              {
                relName: 'taggable',
                type: this.props.taggableType,
                id: this.props.taggableUid,
              },
            ],
          })
          .actionMeta({ noSync: true })
      )
      .then(() => this.onCloseDialog())
      .then(() => this.getQuotes());
  };
  onDeleteQuote = (quoteId) => {
    this.props.deleteQuote({ id: quoteId }).then(this.onCloseDialog);
  };
  onShowQuotes = () => {
    this.getQuotes();
    this.setState({ showQuotes: true });
  };
  onAddQuotes = () => {
    this.setState({ createQuote: true });
  };
  onEditQuote = (quote) => {
    this.setState({ quoteToEdit: quote });
  };
  onCloseDialog = () => {
    this.setState({ createQuote: false, quoteToEdit: undefined });
  };
  onCloseList = () => {
    this.setState({ showQuotes: false });
  };
  onClickQuote = (quote) => {
    this.props.setSelection?.({
      startPosition: quote.report_content_start,
      endPosition: quote.report_content_end,
      scrollToSelection: true,
    });
    this.onCloseList();
  };

  render() {
    const pending =
      this.props.quotes.pending.init || this.props.quotes.pending.ui;
    const noContent = !pending && !this.props.quotes.data.length;
    const hasContent = !pending && this.props.quotes.data.length > 0;

    return (
      <>
        {this.props.type === 'minimal' && canManageTag() && (
          <QuoteIcon
            style={{ width: 21, height: 21 }}
            onClick={this.onAddQuotes}
          />
        )}

        {this.props.type === 'footer' && (
          <Container horizontal className={cls.quotesWrapper}>
            <Button
              style={{ height: 30, width: 85, fontSize: 13 }}
              size="l"
              secondary
              label="Quotes"
              onClick={this.onShowQuotes}
            />
            <Button
              style={{ height: 30, width: 85, fontSize: 13, marginLeft: 10 }}
              size="l"
              secondary
              icon={
                <QuoteIcon
                  style={{
                    width: 20,
                    height: 20,
                    lineHeight: 0.8,
                    marginRight: 4,
                  }}
                  fill="#36b5b5"
                />
              }
              label="Add"
              onClick={this.onShowQuotes}
            />
          </Container>
        )}

        <Dialog
          title="Quotes"
          extraLarge
          modal={false}
          open={this.state.showQuotes}
          onRequestClose={this.onCloseList}
          leftActions={
            <Button
              size="l"
              negative
              onClick={this.onCloseList}
              label="Close"
            />
          }
          rightActions={
            canManageTag() && (
              <Button
                style={{ height: 34, width: 110 }}
                size="l"
                label="Add Quote"
                onClick={this.onAddQuotes}
              />
            )
          }
        >
          <Card noPadding>
            <TableComposition>
              <Table.Body>
                {pending && <Progress large />}
                {noContent && <CardEmpty title="No quotes added" />}
                {hasContent &&
                  this.props.quotes.data.map((quote) => (
                    <Table.Row key={quote.id} className={cls.quotesTableRow}>
                      <Table.Cell
                        width={40}
                        textAlign="left"
                        vAlign="top"
                        style={{ paddingTop: 40, paddingLeft: 32 }}
                      >
                        <div>
                          <QuoteSymbol />
                          &emsp;
                        </div>
                      </Table.Cell>

                      <Table.Cell width="100%">
                        <div
                          className={cls.quotesContent}
                          onClick={() => this.onClickQuote(quote)}
                        >
                          <blockquote style={{ margin: '4px 0 0 16px' }}>
                            <i>
                              {String(quote.content).includes('“') &&
                              String(quote.content).includes('”')
                                ? '‘'
                                : '“'}
                            </i>
                            {String(quote.content).trim()}
                            <i>
                              {String(quote.content).includes('“') &&
                              String(quote.content).includes('”')
                                ? '’'
                                : '”'}
                            </i>
                          </blockquote>
                        </div>
                      </Table.Cell>

                      <Table.Cell
                        width={80}
                        textAlign="right"
                        vAlign="top"
                        style={{
                          display: 'inline-flex',
                          paddingRight: 32,
                          paddingTop: 40,
                        }}
                      >
                        <Icon
                          disabled={!canManageTag()}
                          name="trash"
                          style={{ fontSize: 16 }}
                          onClick={() => this.onDeleteQuote(quote.id)}
                        />
                        &emsp;&emsp;
                        <Icon
                          disabled={!canManageTag()}
                          name="edit"
                          style={{ fontSize: 16 }}
                          onClick={() => this.onEditQuote(quote)}
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </TableComposition>
          </Card>
        </Dialog>
        <QuoteTextDialog
          open={!!this.state.createQuote}
          quoteText={this.props.selectedText}
          handleSaveQuote={this.onSaveQuote}
          handleCancel={this.onCloseDialog}
        />
        <QuoteTextDialog
          open={!!this.state.quoteToEdit}
          quoteText={this.state.quoteToEdit?.content}
          handleSaveQuote={this.onUpdateQuote}
          handleCancel={this.onCloseDialog}
        />
      </>
    );
  }
}
