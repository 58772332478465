import axios, { responseInterceptors } from 'src/axiosInstance';
import store from 'src/store';
import Storage from './Storage';
import Tracking from './TrackingService';
import { User } from 'src/api/User';
import history from 'src/historyInstance';
const parseJwt = (token) =>
  JSON.parse(
    window.atob(token.split('.')[1].replace('-', '+').replace('_', '/'))
  );
const isTokenExpired = () =>
  Date.now() > Storage.getItem('id_token_expiration');
export const getUserToken = () => Storage.getItem('id_token');
export const getUserProfileInfo = () => Storage.getItem('user_profile') || {};

// const autocodemock = { "project_reports": [{ "grant_id": 20097, "grantee_id": 14006, "report_id": 7964 }, { "grant_id": 23268, "grantee_id": 15539, "report_id": 168 }], "selected_projects": [] };
export const getAutocodeReports = () =>
  JSON.parse(
    localStorage.getItem('autocode_reports') ||
      '{"project_reports":[{"grant_id":20097,"grantee_id":14006,"report_id":7964 }],"selected_projects":[]}'
  );
export const setAutocodeReports = (payload) =>
  localStorage.setItem('autocode_reports', JSON.stringify(payload));

export const clearStorage = () => {
  Storage.removeItem('id_token');
  Storage.removeItem('user_profile');
  Storage.removeItem('id_token_expiration');
  delete axios.defaults.headers.common.Authorization;
  Tracking.setUserId('');
};

export const doLogout = () => {
  store.dispatch(User.actions.logOut());
  clearStorage();
  history.replace({ pathname: '/login' });
};

const updateUserParams = () => {
  axios.defaults.headers.common.Authorization = getUserToken();
  axios.interceptors.response.use(
    responseInterceptors(doLogout).success,
    responseInterceptors(doLogout).failure
  );
  Tracking.setUserId(getUserProfileInfo().user_id);
  store.dispatch(
    User.actions.authenticated(getUserProfileInfo().user_id, getUserToken())
  );
};

export const authenticateUser = (token) => {
  Storage.setItem('id_token', token);
  Storage.setItem(
    'user_profile',
    JSON.stringify({ user_id: parseJwt(token)?.sub })
  );
  Storage.setItem('id_token_expiration', 1000 * (parseJwt(token)?.exp || 0));
  updateUserParams();
};

class AuthService {
  constructor() {
    const PUBLIC_PATHS = ['answer-survey', 'login', 'invitations', 'password'];
    const publicPathRegexp = new RegExp(`(${PUBLIC_PATHS.join('|')})`);
    if (!getUserToken()) {
      if (
        !publicPathRegexp.test(window.location.pathname) &&
        !window.location.hash?.match(/access_token/)
      )
        clearStorage();
      return;
    }
    if (isTokenExpired()) {
      doLogout();
      return;
    }
    updateUserParams();
  }
  authenticateUser = authenticateUser;
  clearStorage = clearStorage;
}
export default new AuthService();
