import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import cls from './Table.module.css';

const cx = classNames.bind(cls);

export default class TableBody extends Component {
  static propTypes = {
    rows: PropTypes.array.isRequired,
    className: PropTypes.string,
  };

  render() {
    const { rows, className } = this.props;
    return (
      <tbody className={cx('tableBody', className)}>
        {rows.map((row, rowIndex) => (
          <tr key={rowIndex} className={cx('tableRow')}>
            {row.map((col, colIndex) => (
              <td key={colIndex} className={cx('tableCell', 'tableBodyCell')}>
                {col}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    );
  }
}
