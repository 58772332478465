import PropTypes from 'prop-types';
import React from 'react';

import { Button } from 'src/components/IMUI';
import {
  SHORT_TEXT,
  LONG_TEXT,
  SINGLE_CHOICE,
  MULTIPLE_CHOICE,
  SINGLE_MATRIX,
  NUMERICAL,
  FINANCIAL,
  HEADER,
  SUB_HEADER,
  TEXT,
  SECTION_BREAK,
  DATE,
  RATING,
  OPINION_SCALE,
  COUNTRY,
} from 'src/data/questionTypes';

import QuestionAvatar from './QuestionAvatar';

import cls from './QuestionPicker.module.css';
import { canBuildSurvey } from 'src/userStorage';

export default class QuestionPicker extends React.PureComponent {
  static propTypes = {
    position: PropTypes.number,
    survey: PropTypes.object,
    onQuestionAdd: PropTypes.func,
    onSetQuestionPickerIndex: PropTypes.func,
  };

  onQuestionAvatarClick = (type, isLayout) => {
    this.props.onQuestionAdd(
      type,
      isLayout,
      this.props.position !== null ? this.props.position + 1 : null
    );
  };

  render() {
    // const isProd = process.env.NODE_ENV === "production" && process.env.IMPACTMAPPER_API_SERVER?.includes("//api-prod.impactmapper.com");
    return (
      <div className={cls.questionPicker}>
        <div className={cls.questionPickerBlockQuestion}>
          <div className={cls.sectionHeader}>
            {this.props.survey.questions.length > 0
              ? 'Add another question'
              : 'Get started by adding a question'}
          </div>
          <QuestionAvatar
            type={SHORT_TEXT}
            onClick={this.onQuestionAvatarClick}
          />
          <QuestionAvatar
            type={LONG_TEXT}
            onClick={this.onQuestionAvatarClick}
          />
          <QuestionAvatar
            type={SINGLE_CHOICE}
            onClick={this.onQuestionAvatarClick}
          />
          <QuestionAvatar
            type={MULTIPLE_CHOICE}
            onClick={this.onQuestionAvatarClick}
          />
          <QuestionAvatar type={RATING} onClick={this.onQuestionAvatarClick} />
          <QuestionAvatar
            type={OPINION_SCALE}
            onClick={this.onQuestionAvatarClick}
          />
          <QuestionAvatar
            type={SINGLE_MATRIX}
            onClick={this.onQuestionAvatarClick}
          />
          <QuestionAvatar
            type={NUMERICAL}
            onClick={this.onQuestionAvatarClick}
          />
          <QuestionAvatar
            type={FINANCIAL}
            onClick={this.onQuestionAvatarClick}
          />
          <QuestionAvatar type={DATE} onClick={this.onQuestionAvatarClick} />
          <QuestionAvatar type={COUNTRY} onClick={this.onQuestionAvatarClick} />
        </div>
        <div className={cls.questionPickerBlockLayout}>
          <div className={cls.sectionHeader}>Add a layout element</div>
          <QuestionAvatar
            isLayout
            type={HEADER}
            onClick={this.onQuestionAvatarClick}
          />
          <QuestionAvatar
            isLayout
            type={SUB_HEADER}
            onClick={this.onQuestionAvatarClick}
          />
          <QuestionAvatar
            isLayout
            type={TEXT}
            onClick={this.onQuestionAvatarClick}
          />
          <QuestionAvatar
            isLayout
            type={SECTION_BREAK}
            onClick={this.onQuestionAvatarClick}
          />
        </div>
        {this.props.position !== null && (
          <div>
            <Button
              disabled={!canBuildSurvey()}
              negative
              label="Cancel"
              size="m"
              onClick={() => this.props.onSetQuestionPickerIndex(null)}
            />
          </div>
        )}
      </div>
    );
  }
}
