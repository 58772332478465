export const isEqual = (_str1, _str2, { caseSensitive } = {}) => {
  if (typeof _str1 !== 'string' || typeof _str2 !== 'string') {
    return false;
  }

  const str1 = caseSensitive ? _str1 : _str1.toLowerCase();
  const str2 = caseSensitive ? _str2 : _str2.toLowerCase();

  return str1 === str2;
};

export const isGetMethod = (str) => isEqual(str, 'get');
