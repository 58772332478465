import { where } from 'im/api/Query';
import reducerRegistry from 'im/api/registry';
import ResourceCollection from 'im/api/ResourceCollection';

const apiConfig = {
  root: '/api/v3/organizations',
  type: 'organizations',
  api: { allow: ['create', 'destroy', 'findAll'] },
};
const publicConfig = {
  root: '/v3/public/organizations',
  type: 'organizations',
  api: {
    allow: ['create'],
    custom: { checkout: { method: 'GET', path: 'checkout/:checkoutId' } },
  },
};
const userOrgsConfig = {
  ...apiConfig,
  api: { findAll: { path: where().filter('users_id_eq', ':id').toString() } },
};
const userOrgsRecentConfig = {
  ...apiConfig,
  api: {
    findAll: {
      path: where()
        .filter('users_id_eq', ':id')
        .fields('organization', 'uid', 'id', 'title')
        .pending('background')
        .paginate({ size: 15 })
        .sort('-updated_at')
        .toString(),
    },
  },
};

export const Organizations = new ResourceCollection(apiConfig, 'organizations');
export const PublicOrganizations = new ResourceCollection(
  publicConfig,
  'publicOrganizations'
);
export const UserOrganizations = new ResourceCollection(
  userOrgsConfig,
  'userOrganizations'
);
export const UserOrganizationsRecent = new ResourceCollection(
  userOrgsRecentConfig,
  'userRecentOrganizations'
);

reducerRegistry.register(Organizations);
reducerRegistry.register(UserOrganizations);
reducerRegistry.register(PublicOrganizations);
reducerRegistry.register(UserOrganizationsRecent);

export default {
  ...Organizations.api,
  ofUser: UserOrganizations.api,
  public: PublicOrganizations.api,
  recentOfUser: UserOrganizationsRecent.api,
};
