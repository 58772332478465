import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { submit } from 'redux-form';

import passwordResource from 'src/api/Password';
import { Section, Container, Actions, Button } from 'src/components/IMUI';

import { where } from 'im/api/Query';

@connect(pick(['user']), {
  requestResetToken: passwordResource.requestResetToken,
  submit,
})
export default class UserSettingsFooter extends Component {
  static propTypes = {
    user: PropTypes.object,
    formName: PropTypes.string,
    requestResetToken: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
  };

  onChangePassword = () => {
    const { user } = this.props;
    const payload = where().payload({ email: user.data.email });
    this.props.requestResetToken(payload);
  };

  onUpdateAccount = () => {
    const { formName } = this.props;
    this.props.submit(formName);
  };

  render() {
    return (
      <Section type="sticky-footer">
        <Container horizontal>
          <Actions>
            <Button
              secondary
              size="l"
              label="Request password change"
              onClick={this.onChangePassword}
            />
          </Actions>
          <Actions>
            <Button
              size="l"
              label="Update account"
              onClick={this.onUpdateAccount}
            />
          </Actions>
        </Container>
      </Section>
    );
  }
}
