import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import identity from 'ramda/src/identity';
import isEmpty from 'ramda/src/isEmpty';
import pickBy from 'ramda/src/pickBy';
import { connect } from 'react-redux';
import {
  Field,
  FieldArray,
  arrayPush,
  arrayRemove,
  arrayRemoveAll,
  arrayPop,
  touch,
} from 'redux-form';
import isEmail from 'validator/lib/isEmail';
import FlexColumn from 'src/components/FlexColumn/FlexColumn';
import FlexRow from 'src/components/FlexRow/FlexRow';
import {
  Button,
  TextField,
  Divider,
  FormField,
  PlainListItem,
  Actions,
  Container,
} from 'src/components/IMUI';
import ProceedCancelButtons from 'src/components/ProceedCancelButtons/ProceedCancelButtons';
import createValidator from 'src/utils/validation';
import { Icon } from 'im/ui/Icon';
import cls from './AddEditContact.module.css';
import { canManageContact } from 'src/userStorage';

export function validateContacts(values) {
  const not = (fn) => (v) => !fn(v);
  const isDefined = (v) => Boolean(v);
  const contactsErrors = [];
  const contactValidator = createValidator({
    name: [[not(isDefined), () => 'Name is required']],
    email: [
      [not(isDefined), () => 'Email is required'],
      [(v) => (v ? not(isEmail)(v) : false), () => 'Invalid email'],
    ],
  });
  if (values && values.contacts) {
    values.contacts.forEach((contact, index) => {
      contactsErrors[index] = contactValidator(contact);
    });
  }
  return { contacts: contactsErrors };
}
const cx = classNames.bind(cls);

@connect(null, { arrayRemoveAll, arrayRemove, arrayPop, arrayPush, touch })
class AddEditContact extends React.PureComponent {
  static propTypes = {
    arrayPop: PropTypes.func.isRequired,
    arrayRemove: PropTypes.func.isRequired,
    arrayPush: PropTypes.func.isRequired,
    touch: PropTypes.func.isRequired,

    formName: PropTypes.string.isRequired,
    contacts: PropTypes.array.isRequired,
    formErrors: PropTypes.object.isRequired,
    isEdit: PropTypes.bool,
    onAddContact: PropTypes.func,
    onDeleteContact: PropTypes.func,
  };

  static defaultProps = { initialValues: {}, formErrors: {} };
  state = { isAddNewContactInProgress: false };
  onContactAddCancel = () => {
    this.props.arrayPop(this.props.formName, 'contacts');
    this.setState({ isAddNewContactInProgress: false });
  };
  onContactAddSubmit = (e, fieldsNames, index) => {
    e.preventDefault();
    const { isEdit, contacts } = this.props;
    const contactsErrors =
      (this.props.formErrors && this.props.formErrors.contacts) || [];
    if (!isEmpty(pickBy(identity, contactsErrors[index]))) {
      this.props.touch(this.props.formName, ...fieldsNames);
      return;
    }
    if (isEdit) {
      this.props.onAddContact(contacts.slice(0).pop());
    }
    this.setState({ isAddNewContactInProgress: false });
  };
  onAddNewContact = () => {
    this.props.arrayPush(this.props.formName, 'contacts', {});
    this.setState({ isAddNewContactInProgress: true });
  };
  onContactRemove = (e, index) => {
    e.preventDefault();
    const contactToRemove = this.props.contacts[index];
    if (contactToRemove.id) {
      this.props.onDeleteContact(contactToRemove.id);
    }
    this.props.arrayRemove(this.props.formName, 'contacts', index);
  };

  renderLastContact = ({ fields: contacts }) => {
    const lastItemIndex = contacts.length ? contacts.length - 1 : 0;
    const fieldsNames = [
      `contacts[${lastItemIndex}].name`,
      `contacts[${lastItemIndex}].email`,
    ];

    return (
      <div>
        <FlexRow style={{ flexWrap: 'nowrap' }}>
          <FlexColumn neighbourSpacing>
            <FormField anchorScrollName={fieldsNames[0]}>
              <Field
                disabled={!canManageContact()}
                component={TextField}
                label="Contact name"
                fullWidth
                name={fieldsNames[0]}
                hintText="Type in..."
              />
            </FormField>
          </FlexColumn>
          <FlexColumn neighbourSpacing>
            <FormField anchorScrollName={fieldsNames[1]}>
              <Field
                disabled={!canManageContact()}
                fullWidth
                component={TextField}
                label="Contact Email"
                name={fieldsNames[1]}
                hintText="Type in..."
              />
            </FormField>
          </FlexColumn>
        </FlexRow>
        <ProceedCancelButtons
          size="l"
          cancelProps={{ onClick: this.onContactAddCancel }}
          proceedProps={{
            onClick: (e) =>
              this.onContactAddSubmit(e, fieldsNames, lastItemIndex),
          }}
        />
      </div>
    );
  };

  renderContactList() {
    const contacts = this.props.contacts.slice(0);
    if (!contacts.length) {
      return null;
    }
    if (this.state.isAddNewContactInProgress) {
      contacts.pop();
    }
    return (
      <div>
        {contacts.map(({ name, email }, index) => (
          <PlainListItem
            nowrap
            key={index}
            className={cls.contactItem}
            title={
              <span>
                {name || <span className={cls.placeholder}>No name</span>}
                <span className={cls.contactItemLight}>
                  &nbsp;-&nbsp;{email}
                </span>
              </span>
            }
          >
            <FlexColumn style={{ width: 'auto', paddingRight: 0 }}>
              {canManageContact() && (
                <Icon
                  name="trash"
                  tip="Remove"
                  className={cls.contactItemIcon}
                  onClick={(e) => this.onContactRemove(e, index)}
                />
              )}
            </FlexColumn>
          </PlainListItem>
        ))}
      </div>
    );
  }

  render() {
    return (
      <div>
        <Divider />
        <Container style={{ minHeight: 40 }} horizontal>
          <Actions>
            <h3>
              Contacts&emsp;
              <Icon
                name="account"
                className={cls.spaceLeft}
                style={{ fontSize: '0.6em', color: '#e3e9ee' }}
              />
            </h3>
          </Actions>
          {!this.state.isAddNewContactInProgress && (
            <Actions>
              <Button
                disabled={!canManageContact()}
                size="l"
                label="Add new contact"
                onClick={this.onAddNewContact}
              />
            </Actions>
          )}
        </Container>
        <br />
        {this.renderContactList()}
        {this.state.isAddNewContactInProgress && (
          <FieldArray name="contacts" component={this.renderLastContact} />
        )}

        <Divider className={cx('spaceTop', 'spaceBottom')} />
      </div>
    );
  }
}

export default AddEditContact;
