import reducerRegistry from 'im/api/registry';
import ResourceCollection from 'im/api/ResourceCollection';

const headers = { 'Content-Type': 'multipart/form-data' };

const apiConfig = {
  root: '/api/v3',
  type: 'uploader',
  api: {
    custom: {
      uploadOrganizationLogo: {
        headers,
        method: 'POST',
        path: 'logos',
        meta: { noSync: true },
      },
      destroyOrganizationLogo: {
        method: 'DELETE',
        path: 'logos/:id',
        meta: { noSync: true },
      },
    },
  },
};

export const Uploader = new ResourceCollection(apiConfig);
reducerRegistry.register(Uploader);

export default Uploader.api;
