import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import { Container, Actions } from 'src/components/IMUI';

import cls from './TripleItem.module.css';

const cx = classNames.bind(cls);

const TripleItem = ({ left, middle, right, border, className }) => (
  <Container horizontal className={cx({ tripleItemBorder: border }, className)}>
    <Actions className={cls.tripleItemSpaceRight}>
      {typeof left === 'function' ? left() : left}
    </Actions>
    <Actions>{typeof middle === 'function' ? middle() : middle}</Actions>
    <Actions className={cls.tripleItemFixedAction}>
      {typeof right === 'function' ? right() : right}
    </Actions>
  </Container>
);

TripleItem.propTypes = {
  left: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  middle: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  right: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  border: PropTypes.bool,
  className: PropTypes.string,
};

TripleItem.defaultProps = {
  left: null,
  middle: null,
  right: null,
};

export default TripleItem;
