import queryString from 'query-string';
import React, { Component } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';

import { Section, CardEmpty } from 'src/components/IMUI';
import Page from 'src/components/Page/Page';

export default class Error extends Component {
  static propTypes = {
    match: ReactRouterPropTypes.match,
    location: ReactRouterPropTypes.location,
  };

  static contents = {
    404: {
      headerTitle: 'Error 404',
      title: 'Resource not found',
      message: `
        If you expected something to be here it could mean that this
        resource was deleted, url is wrong or it belongs to another organisation.
      `,
    },
    503: {
      headerTitle: 'Error 503',
      title: 'Service not responding',
      message: `
        It looks like our servers are having problems responding, our technicians are probably
        already working on solution but contact us if it doesn't work for a longer period of time.
      `,
    },
    unknown: {
      headerTitle: 'Error',
      title: 'Unknown error',
      message: 'An unrecognized error happend!',
    },
  };

  renderError(error, sourcePath = '') {
    return (
      <CardEmpty owl large title={error.title}>
        <div>
          {sourcePath && (
            <h3>
              You tried to access <code>{sourcePath}</code>
            </h3>
          )}
          {error.message && <h3>{error.message}</h3>}
          <div>
            If you need more help get in touch with us at{' '}
            <a href="mailto:team@impactmapper.com">team@impactmapper.com</a>
          </div>
        </div>
      </CardEmpty>
    );
  }

  render() {
    const { errorCode } = this.props.match.params;
    const sourcePath = queryString.parse(this.props.location.search).source;
    const error = Error.contents[errorCode] || Error.contents.unknown;
    return (
      <Page title={error.headerTitle}>
        <Section grow>{this.renderError(error, sourcePath)}</Section>
      </Page>
    );
  }
}
