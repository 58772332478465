import React from 'react';
import TextQuestion from './TextQuestion';

const LongTextQuestion = (props) => <TextQuestion multiLine {...props} />;
LongTextQuestion.validate =
  TextQuestion?.validate ??
  function () {
    return null;
  };

export default LongTextQuestion;
