import {
  DICTIONARY__PROJECT_SET,
  DICTIONARY__PROJECT_CLEAR,
  DICTIONARY__ORGANIZATION_SET,
  DICTIONARY__ORGANIZATION_CLEAR,
} from 'src/constants';

const initialState = {
  project: {},
  organization: {},
  language: {},
};

export default function dictionaryReducer(state = initialState, action) {
  switch (action.type) {
    case DICTIONARY__PROJECT_SET:
      return { ...state, project: { ...action.payload.dictionary } };
    case DICTIONARY__PROJECT_CLEAR:
      return { ...state, project: {} };
    case DICTIONARY__ORGANIZATION_SET:
      return { ...state, organization: { ...action.payload.dictionary } };
    case DICTIONARY__ORGANIZATION_CLEAR:
      return { ...state, organization: {} };
    default:
      return state;
  }
}
