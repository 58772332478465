import reducerRegistry from 'im/api/registry';
import Resource from 'im/api/Resource';

const apiConfig = {
  root: '/api/v3/tags',
  type: 'tags',
  api: {
    allow: ['find'],
  },
  derived: (tag) => ({
    group: (tag.tag_categories || [])[0] || {},
  }),
};

export const Tag = new Resource(apiConfig, 'tag');
reducerRegistry.register(Tag);

export default Tag.api;
