function storageAvailable(type = 'localStorage') {
  try {
    const storage = window[type];
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
}

function stringify(data) {
  let result = null;
  try {
    result = typeof data === 'object' ? JSON.stringify(data) : data;
  } catch (e) {
    console.error("can't serialize data for localStorage");
  }
  return result;
}

function parse(data) {
  let result = data;
  try {
    result = JSON.parse(data);
  } catch (e) {
    // eslint-disable-next-line no-unused-expressions
    null;
  }
  return result;
}
const localAdapter = {
  name: 'localStorage',
  setItem: (key, data) => data && localStorage.setItem(key, data),
  getItem: (key) => localStorage.getItem(key),
  removeItem: (key) => localStorage.removeItem(key),
};
export class StorageService {
  static fallbackStore = {};
  constructor(name) {
    this.name = name;
    this.adapter = localAdapter;
    this.available = storageAvailable(this.adapter.name);
  }
  setItem(key = this.name, value) {
    return this.available
      ? this.adapter.setItem(key, stringify(value))
      : (StorageService.adapterStub = {
          ...StorageService.fallbackStore,
          [key]: value,
        });
  }
  getItem(key = this.name, transform = (data) => data) {
    return this.available
      ? transform(parse(this.adapter.getItem(key)))
      : transform(StorageService.fallbackStore[key]);
  }
  removeItem(key = this.name) {
    return this.available
      ? this.adapter.removeItem(key)
      : delete StorageService.fallbackStore[key];
  }
}

export default new StorageService();
