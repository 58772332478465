export const MANAGE_SUBJECTS = {
  text: 'Upload, manage and analyze grant and grantee data, text reports and create advanced charts.',
  header: 'Manage Grants, Grantees',
  blocking: true,
};
export const UPGRADE_LICENSE = {
  text: 'Create and manage a custom dictionary, analyze grant and grantee data, and create advanced charts.',
  header: 'Professional License',
  blocking: false,
};
export const NEW_ANALYSIS = {
  text: 'Track affiliations and grants associated with your analysis.',
  header: 'Grant-based Analysis',
  blocking: false,
};
export const CUSTOM_DEFINITIONS = {
  text: 'Create and manage a custom dictionary, analyze grant and grantee data, and create advanced charts.',
  header: 'Use Custom Definitions',
  blocking: false,
};
export const REPORTS = {
  text: 'Upload, manage and analyze text reports, grants and grantee data, and create advanced charts.',
  header: 'Analyze Text Reports',
  blocking: true,
};
export const GRANTS = {
  text: 'Upload, manage and analyze grant and grantee data, text reports and create advanced charts.',
  header: 'Manage Grants, Grantees',
  blocking: true,
};
export const CHARTS = {
  text: 'Analyze your grant and grantee data, text reports and create advanced charts.',
  header: 'Create Custom Charts',
  blocking: true,
};
export const UPGRADE = {
  header: 'Analyze Reports, Manage Grants and More',
  text: 'Upgrade to analyze your text documents, including grant and investment reports, interviews, focus groups, stories of change, etc, as well as gain access to our grantmaking, investment management and advanced charting tools.',
  blocking: true,
};
