import React from 'react';
import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import { connect } from 'react-redux';
import { LionessProvider } from 'lioness';
import { Route, Redirect, Switch } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import history from 'src/historyInstance';
import Analysis from 'src/pages/App/Analysis';
import Activities from 'src/pages/App/Activities/Activities';
import Layout from 'src/pages/App/components/Layout';
import Error from 'src/pages/App/Error';
import Grants from 'src/pages/App/Grants';
import UserModule from 'src/pages/App/User';
import { getUserToken } from 'src/services/AuthService';

class AppIndex extends React.PureComponent {
  static propTypes = {
    organizationCurrent: PropTypes.object,
    location: ReactRouterPropTypes.location,
    match: ReactRouterPropTypes.match,
  };
  render() {
    if (!getUserToken()) {
      history.replace('/login');
      return null;
    }
    const rootPath = this.props.match.path === '/' ? '' : this.props.match.path;
    const rootUrl = this.props.match.url === '/' ? '' : this.props.match.url;
    const organizationId = this.props.organizationCurrent?.data?.id;

    return (
      <LionessProvider locale="en" messages={{}} debug={false}>
        <Layout location={this.props.location}>
          <Switch key={organizationId}>
            <Route path={`${rootPath}/error`} component={Error} />
            <Route path={`${rootPath}/grants`} component={Grants} />
            <Route path={`${rootPath}/user`} component={UserModule} />
            <Route path={`${rootPath}/analysis`} component={Analysis} />
            <Route path={`${rootPath}/activities`} component={Activities} />
            <Redirect from="*" to={`${rootUrl}/analysis`} />
          </Switch>
        </Layout>
      </LionessProvider>
    );
  }
}

export default connect(pick(['organizationCurrent', 'user']))(AppIndex);
// to avoid having to differentiate sub paths between root and nested paths
// check if it's on path root (`/`) or not - if yes we leave rootPath as empty string else we just pass whatever is there
