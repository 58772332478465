import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import { Container } from 'src/components/IMUI/index';

import { Icon } from 'im/ui/Icon';

import cls from './Expandable.module.css';

const cx = classNames.bind(cls);

export default class ExpandableHeader extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    isExpanded: PropTypes.bool,
    iconRight: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
  };

  render() {
    const { children, isExpanded, onClick, iconRight, className } = this.props;

    return (
      <Container
        horizontal
        centered
        className={cx(cls.expandableHeader, className)}
        onClick={onClick}
      >
        <Icon
          name="chevron-down"
          className={cx(cls.expandableHeaderIcon, {
            [cls.expandableHeaderIconExpanded]: isExpanded,
            [cls.expandableHeaderIconRight]: iconRight,
          })}
        />
        {children}
      </Container>
    );
  }
}
