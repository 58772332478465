import PropTypes from 'prop-types';
import React from 'react';

import CSVUploadButton from 'src/components/CSVUploadButton/CSVUploadButton';

export default class ContactCSVUploadButton extends React.Component {
  static propTypes = {
    handleUpload: PropTypes.func,
    text: PropTypes.bool,
  };

  render() {
    return (
      <CSVUploadButton
        size="m"
        text={this.props.text}
        secondary
        onFileUploaded={this.props.handleUpload}
        tip={`<div>
            Please provide CSV without a header row.<br>
            You can submit either just emails in a column<br>
            <code>email1<br>
            email2<br>
            ...</code><br>
            or name and email<br>
            <code>name1,email1<br>
            name2,email2<br>
            ...</code><br>
            This is an experimental feature,<br>
            if you have problems using it get in touch.
          </div>
        `}
        rowParser={(row) => {
          if (row.length === 1) {
            return { email: row[0] };
          }
          return {
            name: row[0],
            email: row[1],
          };
        }}
      />
    );
  }
}
