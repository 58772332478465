import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import helpOwlIcon from 'src/components/HelpOwlButton/HelpOwlIcon';

import cls from './CardEmpty.module.css';

const cx = classNames.bind(cls);

export const CardEmpty = ({
  children,
  className,
  title,
  description,
  owl,
  large,
  compact,
  style,
}) => (
  <div
    className={cx(
      'imui-card-empty',
      {
        'imui-card-empty-compact': compact,
        'imui-card-empty-large': large,
      },
      className
    )}
    style={style}
  >
    {title && <div className={cx('imui-card-empty-title')}>{title}</div>}
    {description && (
      <div className={cx('imui-card-empty-description')}>{description}</div>
    )}
    {children && (
      <div className={cx('imui-card-empty-content')}>{children}</div>
    )}
    {owl && <div className={cls.owl}>{helpOwlIcon}</div>}
  </div>
);

CardEmpty.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.string,
  large: PropTypes.bool,
  owl: PropTypes.bool,
  description: PropTypes.string,
  compact: PropTypes.bool,
  style: PropTypes.object,
};

export default CardEmpty;
