import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { TextField } from 'src/components/IMUI';
import { escapeForRegExp } from 'src/utils/string';
import { Icon } from 'im/ui/Icon';
import cls from './AnalysisFilters.module.css';
const filterProjectsByName = (projects, query) =>
  projects.filter((el) =>
    el.name.match(new RegExp(escapeForRegExp(query), 'i'))
  );
const cx = classNames.bind(cls);

export default class AnalysisFilters extends React.PureComponent {
  static propTypes = {
    filterQuery: PropTypes.string,
    data: PropTypes.array,
    onChange: PropTypes.func,
    onFilterChange: PropTypes.func,
  };
  static defaultProps = { data: [], filterQuery: '' };
  state = { query: '' };
  componentWillReceiveProps(nextProps) {
    if (nextProps.filterQuery == this.props.filterQuery) return;
    this.setState({ query: nextProps.filterQuery });
    this.props.onChange(
      filterProjectsByName(this.props.data, nextProps.filterQuery)
    );
  }
  onTextFilterChange(query) {
    this.props.onFilterChange(query);
  }
  render() {
    return (
      <div className={cx('analysis-filters')}>
        <TextField
          name="search"
          fullWidth
          noValidation
          hintText={
            <span style={{ display: 'flex' }}>
              <Icon name="search" style={{ marginRight: 5 }} />
              Search for project
            </span>
          }
          onChange={(value) => this.onTextFilterChange(value)}
          value={this.state.query}
        />
      </div>
    );
  }
}
