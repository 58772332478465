import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import partial from 'ramda/src/partial';
import times from 'ramda/src/times';
import React from 'react';

import cls from './SteppedProgress.module.css';

const cx = classNames.bind(cls);

const isActive = (el, i, roundFn) => {
  return roundFn(el) > i;
};

const getTertile = (el, i) => {
  const fraction = el - i;
  if (fraction <= 0.5) return 1;
  if (fraction < 1) return 2;
  return 3;
};

const calculateProgress = (value, max, steps) => {
  return (value / max) * steps;
};

const percent = (x, y) => `${(x / (y || 1)).toPrecision(2) * 100}%`;

export const SteppedProgress = ({
  value,
  max,
  steps,
  spacing,
  roundFn,
  showDetailsOnHover,
  noMargins,
  className,
}) => {
  return (
    <div
      className={cx('imui-stepped-progress', className, {
        [cx('imui-stepped-progress-hover-enabled')]: showDetailsOnHover,
        [cx('imui-stepped-no-margins')]: noMargins,
      })}
    >
      {times(partial(calculateProgress, [value, max, steps]), steps).map(
        (el, i) => (
          <div
            key={i}
            className={cx('imui-stepped-progress-step', {
              [cx('imui-stepped-progress-step-active')]: isActive(
                el,
                i,
                roundFn
              ),
              [`tertile-${getTertile(el, i)}`]: true,
            })}
            style={{ marginRight: i < steps - 1 ? `${spacing}px` : 0 }}
          />
        )
      )}
      <p className={cx('imui-stepped-progress-details')}>
        {percent(value, max)} ({`${value}/${max}`})
      </p>
    </div>
  );
};

SteppedProgress.propTypes = {
  value: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  steps: PropTypes.number,
  spacing: PropTypes.number,
  roundFn: PropTypes.func,
  showDetailsOnHover: PropTypes.bool,
  className: PropTypes.string,
  noMargins: PropTypes.bool,
};

SteppedProgress.defaultProps = {
  roundFn: Math.ceil,
  steps: 4,
  spacing: 1,
};

export default SteppedProgress;
