import reducerRegistry from 'im/api/registry';
import ResourceCollection from 'im/api/ResourceCollection';

const apiConfig = {
  root: '/v3/ai/tagging_responses',
  type: 'ai_tagging_responses',
  api: {
    allow: ['findAll', 'put'],
  },
};

export const AITaggingResponses = new ResourceCollection(
  apiConfig,
  'aiTaggingResponses'
);
reducerRegistry.register(AITaggingResponses);

export default AITaggingResponses.api;
