import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import cls from './TileHeader.module.css';

const cx = classNames.bind(cls);

const TileHeader = ({ children, className }) => (
  <div className={cx('imui-tile-header', className)}>{children}</div>
);
TileHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default TileHeader;
