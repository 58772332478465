import { combineReducers } from 'redux';

import ResourceBase from '../entities/resource/ResourceBase';

import advancedFiltersRegistry from './advancedFiltersRegistry';

export class ReducerRegistry {
  constructor() {
    this._emitChange = null;
    this._reducers = {};
  }

  get reducers() {
    return { ...this._reducers };
  }

  register(resource) {
    let { name, reducer } = resource;

    if (resource instanceof ResourceBase) {
      name = resource.reducerName;
      reducer = resource.makeReducer();
    }

    this._reducers = { ...this._reducers, [name]: reducer };
    if (this._emitChange) {
      this._emitChange(this.reducers);
    }
  }

  registerInit(reducers) {
    // Registering all the static reducers doesn't have to be first
    this._reducers = { ...reducers, ...this._reducers };
  }

  setChangeListener(listener) {
    this._emitChange = listener;
  }

  combine(reducers, rootReducer) {
    const allReducer = combineReducers({
      ...reducers,
      // TODO: When we move to Typescript, it would be nice to have IRegistry interface with reducers and hasReducers
      ...(advancedFiltersRegistry.hasReducers && {
        advancedFilters: combineReducers(advancedFiltersRegistry.reducers),
      }),
    });
    return rootReducer(allReducer);
  }
}

const reducerRegistry = new ReducerRegistry();
export default reducerRegistry;
