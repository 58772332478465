import reducerRegistry from 'im/api/registry';
import Resource from 'im/api/Resource';

const apiConfig = {
  root: '/api/v3/invitations',
  type: 'invitations',
  api: {
    custom: {
      toOrganizationByEmail: {
        method: 'POST',
        meta: { noSync: true },
      },
    },
  },
};

export const Invitation = new Resource(apiConfig, 'invitations');
reducerRegistry.register(Invitation);

export default Invitation.api;
