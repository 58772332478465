import PropTypes from 'prop-types';
import React from 'react';

import { Toggle } from 'src/components/IMUI';

import BaseQuestionProperties from './BaseQuestionProperties';

export default class SectionBreakProperties extends React.Component {
  static propTypes = {
    question: PropTypes.object,
    onChangeQuestionProperty: PropTypes.func.isRequired,
  };

  handleToggle = (setting) => {
    const { question, onChangeQuestionProperty } = this.props;
    onChangeQuestionProperty(
      question.id,
      ['settings', setting],
      !(question.settings || {})[setting]
    );
  };

  render() {
    const { question } = this.props;
    return (
      <BaseQuestionProperties
        hasRequired={false}
        contextLabel="section"
        {...this.props}
        renderSettings={() => [
          <Toggle
            mini
            name="sectionNewPage"
            key="sectionNewPage"
            label="Start a new page"
            labelPosition="right"
            toggled={question.settings && !!question.settings.sectionNewPage}
            onToggle={() => this.handleToggle('sectionNewPage')}
          />,
          <Toggle
            mini
            name="sectionHidden"
            key="sectionHidden"
            label="Invisible section break"
            labelPosition="right"
            toggled={question.settings && !!question.settings.sectionHidden}
            onToggle={() => this.handleToggle('sectionHidden')}
          />,
        ]}
      />
    );
  }
}
