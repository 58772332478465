import PropTypes from 'prop-types';
import React from 'react';
import { Table, CardEmpty, Button, Progress } from 'src/components/IMUI';
import { getText } from 'src/services/DictionaryService';
import TagRow from './TagRow';
import history from 'src/historyInstance';
import { surveyOnly } from 'src/userStorage';
import TableComposition from 'src/components/IMUI/Tables/TableComposition';

class TagTable extends React.PureComponent {
  static propTypes = {
    projectId: PropTypes.string,
    taggings: PropTypes.object,
    tags: PropTypes.object,
    noGrantAndGrantee: PropTypes.bool,
    onSortChange: PropTypes.func,
  };

  renderNoTags() {
    return (
      <CardEmpty title="There are no taggings yet">
        <Button
          secondary
          label="Go to surveys to tag answers"
          onClick={() =>
            history.push(`/analysis/${this.props.projectId}/surveys/list`)
          }
        />
        {!surveyOnly() && (
          <span>
            <p>or</p>
            <Button
              secondary
              label="Go to reports to tag analysis"
              onClick={() =>
                history.push(`/analysis/${this.props.projectId}/reports`)
              }
            />
          </span>
        )}
      </CardEmpty>
    );
  }

  render() {
    const { taggings, tags, noGrantAndGrantee } = this.props;
    if (
      !taggings.data.length &&
      taggings.pending.init !== false &&
      taggings.pending.init === undefined
    )
      return this.renderNoTags();
    return (
      <TableComposition compact>
        {(taggings.pending.init || taggings.pending.ui) && (
          <Progress large className="absolute" />
        )}
        <Table.Head
          jsonapi
          sortBy={taggings.meta.getAction?.queryParams?.sort}
          onSortChange={this.props.onSortChange}
        >
          <Table.HCell text="Document" sortBy="report_name" />
          {noGrantAndGrantee ? null : (
            <Table.HCell
              text={getText('Grantee')}
              sortBy="report_grantee_name"
            />
          )}
          <Table.HCell text="Tag" width="35%" />
          <Table.HCell text="Tagged Text" width="35%" sortBy="content" />
          <Table.HCell text="Updated" width={140} sortBy="updated_at" />
        </Table.Head>
        <Table.Body>
          {taggings.data.map((tagging) => (
            <TagRow
              key={tagging.id}
              tagging={tagging}
              tag={tags.data.find(({ id }) => id === tagging.tag?.id)}
              noGrantAndGrantee={noGrantAndGrantee}
              projectId={this.props.projectId}
            />
          ))}
        </Table.Body>
      </TableComposition>
    );
  }
}

export default TagTable;
