import PropTypes from 'prop-types';
import React from 'react';
import { TextField, Card, Container, Toggle } from 'src/components/IMUI';
import { Icon } from 'im/ui/Icon';
import seriesOff from 'src/static/series-off.svg';
import seriesOn from 'src/static/series-on.svg';
import ManageSeriesPopover from './ManageSeriesPopover';
import cls from './SettingBox.module.css';
export const DEFAULT_SERIES = 'default';

export default class SettingBatch extends React.PureComponent {
  static defaultProps = { series: [] };
  static propTypes = {
    active: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    series: PropTypes.array,
    onSeriesChange: PropTypes.func,
  };
  constructor(props) {
    super(props);
    this.state = {
      toggled: !!props.active,
      seriesLocal: props.series,
      popoverOpen: false,
      anchorEl: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    // Request resolved after constructor fired
    if (nextProps.series && !this.state.seriesLocal.length) {
      this.setState({ seriesLocal: nextProps.series });
    }
    // There are series with respondents (not default). Set active to the last possible series
    if (
      nextProps.series.length > 0 &&
      (nextProps.series.length !== 1 ||
        nextProps.series[0] !== DEFAULT_SERIES) &&
      !nextProps.active
    ) {
      const active = nextProps.series[nextProps.series.length - 1];
      this.handleChangeSeries(active);
    }
    // QueryParams active has value so toggle should be enabled
    if (nextProps.active && !this.state.toggled) {
      this.setState({ toggled: !!nextProps.active });
    }
    // QueryParams active has value but local series doesn't have it
    if (
      nextProps.active &&
      this.state.seriesLocal.indexOf(nextProps.active) === -1
    ) {
      this.setState({
        seriesLocal: [...this.state.seriesLocal, nextProps.active],
      });
    }
  }

  handleToggleSeries = (ev) => {
    // const isDefaultActive = this.state.seriesLocal.length === 1 && this.state.seriesLocal[0] === DEFAULT_SERIES;
    // const active = this.state.seriesLocal.length > 0 && !isDefaultActive ? this.state.seriesLocal[this.state.seriesLocal.length - 1] : undefined;
    const active = this.state.seriesLocal[this.state.seriesLocal.length - 1];
    this.props.onSeriesChange({
      value: ev.target.checked ? active : undefined,
    });
    this.setState({ toggled: ev.target.checked });
  };

  handleAddSeries = (newBatch) => {
    this.handleChangeSeries(newBatch);
    this.setState({
      seriesLocal: [...this.state.seriesLocal, newBatch],
      popoverOpen: false,
    });
  };

  handleChangeSeries = (series) => {
    this.props.onSeriesChange({ value: series });
  };

  handlePopoverRequestOpen = (ev) => {
    ev?.preventDefault();
    this.setState({
      popoverOpen: true,
      anchorEl: ev.currentTarget,
      popoverAnchorWidth: ev.currentTarget.getBoundingClientRect().width,
    });
  };

  handlePopoverRequestClose = () => {
    this.setState({ popoverOpen: false });
  };

  tipText = `
    <div style="max-width: 200px">
      Survey Series allow you to group and compare series of responses
      by labeling any responses falling into selected series and
      llowing you to compare them between each other.
    </div>
  `;

  render() {
    const { toggled, popoverOpen, anchorEl, seriesLocal, popoverAnchorWidth } =
      this.state;
    const additionalSeries = this.props.series.filter(
      (b) => b != DEFAULT_SERIES
    );
    const hasOnlyDefault = additionalSeries.length === 0;
    const icon = toggled ? seriesOn : seriesOff;
    const activeBatchLabel = this.props.active || 'Add series';

    return (
      <div className={cls.settingBox}>
        <Card smallPadding border>
          <Container horizontal spaceBetween nowrap className={cls.boxTop}>
            <div>
              <h3 className={cls.boxHeader}>
                <span>Series</span>
                <Icon name="question-filled" tip={this.tipText} />
              </h3>
              <Toggle
                className={cls.boxToggle}
                toggled={toggled}
                disabled={additionalSeries.length > 0}
                onToggle={this.handleToggleSeries}
              />
            </div>

            <div className={cls.boxCenter}>
              {toggled && (
                <TextField
                  fullWidth
                  noValidation
                  flat
                  border
                  className={cls.clickableField}
                  hintText="Align"
                  name="activeBatch"
                  value={activeBatchLabel}
                  icon={<Icon name="chevron-down" />}
                  onClick={this.handlePopoverRequestOpen}
                />
              )}
            </div>

            <img className={cls.boxIcon} src={icon} width={48} alt="" />
          </Container>
        </Card>

        <ManageSeriesPopover
          width={popoverAnchorWidth}
          open={popoverOpen}
          anchorEl={anchorEl}
          series={seriesLocal}
          active={this.props.active}
          showAddSeries={
            !this.props.active && (seriesLocal.length === 0 || hasOnlyDefault)
          }
          onRequestClose={this.handlePopoverRequestClose}
          onSeriesChange={this.handleChangeSeries}
          onSeriesAdd={this.handleAddSeries}
        />
      </div>
    );
  }
}
