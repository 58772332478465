import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Quill from 'quill';
import {
  TAGGING_OPTIONS,
  getAutoSuggestedTaggingType,
  getCurrencyFromText,
  getNumberFromText,
} from 'src/components/TaggingType/taggingTypeHelper';

import { Icon } from 'im/ui/Icon/Icon';
import {
  Button,
  Container,
  Dialog,
  SelectField,
  Tag,
} from 'src/components/IMUI';
import colors from 'src/css/constants.json';
import cls from './AITaggingSuggestionEditor.module.css';

import StorageService from 'src/services/Storage';

const AITaggingSuggestionEditor = ({
  taggingSuggestion,
  onRequestClose,
  onRequestSaveAndAccept,
  onRequestDecline,
}) => {
  const [selection, setSelection] = useState({
    content: '',
    report_content_start: null,
    report_content_end: null,
  });
  const [selectedTextType, setSelectedTextType] = useState('default');
  const [taggingTypeParams, setTaggingTypeParams] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [quill, setQuill] = useState(null);

  const getQuillIndicesFor = (content) => {
    const quillContent = quill.getText();
    const startIndex = quillContent.indexOf(content);

    return {
      startIndex: startIndex,
      endIndex: startIndex + content.length,
    };
  };

  const updateHighlights = (range) => {
    const { startIndex } = getQuillIndicesFor(taggingSuggestion.content);

    // Remove all highlights
    quill?.removeFormat(0, taggingSuggestion.report.content_html.length, false);

    // Recolor original tagging suggestion
    quill?.formatText(startIndex, taggingSuggestion.content.length, {
      background: '#fff59d',
    });

    // Apply hight to current selection
    quill?.formatText(range.index, range.length, {
      background: StorageService.getItem('--highlight-color') ?? '#c1fa8b',
    });
  };

  const handleSelectionChange = (range) => {
    const hasHighlight = (range?.length || 0) > 0;

    if (!quill) return;
    if (!hasHighlight) return;

    updateHighlights(range);

    const currentSelectedText = quill.getText(range.index, range.length);
    setSelection({
      content: currentSelectedText,
      report_content_start: range.index,
      report_content_end: range.index + range.length,
    });
  };

  const prepareParams = (suggestedType) => {
    if (suggestedType === 'currency') {
      setTaggingTypeParams(getCurrencyFromText(selection.content) || {});
    } else if (suggestedType === 'location') {
      setTaggingTypeParams({ location: selection.content });
    } else if (suggestedType === 'number') {
      setTaggingTypeParams(getNumberFromText(selection.content) || {});
    } else if (suggestedType === 'default') {
      setTaggingTypeParams({});
    }

    setSelectedTextType(suggestedType);
  };

  useEffect(() => {
    const suggestedType = getAutoSuggestedTaggingType(selection.content);
    prepareParams(suggestedType);
    setSelectedTextType(suggestedType);
  }, [selection]);

  const onSelectedTextTypeChange = ({ value }) => {
    prepareParams(value);
  };

  const bindQuillEvents = () => {
    quill?.on('selection-change', (range) => handleSelectionChange(range));
  };

  const resetDocument = () => {
    const contentHtml = taggingSuggestion.report.content_html;

    if (quill?.root?.parentElement) {
      const parent = quill.root.parentElement;
      parent.removeChild(quill.root);

      const mappedDeltas = quill.clipboard.convert(
        contentHtml.replaceAll('<p><hr></p>', '\r\n').replaceAll('\n\n\n', '\n')
      );

      quill.root.classList.add(cls.qlEditorOverride);
      quill.setContents(mappedDeltas);
      parent.appendChild(quill.root);

      const { startIndex, endIndex } = getQuillIndicesFor(
        taggingSuggestion.content
      );

      if (startIndex) {
        const bounds = quill.getBounds(startIndex);
        quill?.scrollingContainer?.scrollTo({ top: bounds.top - 100 });

        quill?.formatText(startIndex, taggingSuggestion.content.length, {
          background: '#fff59d',
        });

        setSelection({
          content: taggingSuggestion.content,
          report_content_start: startIndex,
          report_content_end: endIndex,
        });
      }

      bindQuillEvents();
    }
  };

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (!loaded) return;

    const newQuill = new Quill(`.${cls.editor}`, {
      formats: ['background'],
      modules: { toolbar: false, history: { userOnly: true } },
      readOnly: true,
    });

    if (newQuill.container) {
      setQuill(newQuill);
    }
  }, [loaded]);

  useEffect(resetDocument, [taggingSuggestion, quill]);

  return (
    <Dialog
      title="Edit Tagging Suggestion"
      forceTop
      extraLarge
      open={true}
      onRequestClose={onRequestClose}
      bodyStyle={{
        width: '100%',
        overflow: 'hidden !important',
      }}
      contentStyle={{
        width: '100%',
      }}
      leftActions={
        <Button size="l" label="Close" negative onClick={onRequestClose} />
      }
      rightActions={
        <div className={cls.taggingSuggestionEditorActions}>
          <Button
            size="l"
            alert
            secondary
            label="Delete Suggestion"
            onClick={() => {
              onRequestDecline(taggingSuggestion);
              onRequestClose();
            }}
          />
          <Button
            size="l"
            label="Save and Accept"
            onClick={() => {
              onRequestSaveAndAccept(taggingSuggestion, {
                ...selection,
                ...taggingTypeParams,
              });
              onRequestClose();
            }}
          />
        </div>
      }
    >
      <Container>
        <div className={cls.taggingSuggestionEditorContent}>
          <section className={cls.reportPreviewContainer}>
            <label style={{ fontSize: 15 }}>Report preview:</label>
            <div className={cls.editor} />
          </section>

          <section className={cls.suggestedTaggingContainer}>
            <div className={cls.suggestedTaggingHeader}>
              Suggested Tagging:
              <Tag
                label={
                  <span
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    {taggingSuggestion.tag.title}
                  </span>
                }
                grey
                square
              />
              <SelectField
                flat
                outline
                noValidation
                className={cls.suggestedTaggingTypeDropdown}
                value={selectedTextType}
                selectedMenuItemStyle={{
                  fontWeight: 'bold',
                  color: colors.dark,
                }}
                onChange={onSelectedTextTypeChange}
              >
                {TAGGING_OPTIONS.map((option) => (
                  <SelectField.Item
                    className={cls.detailsSelectorDropdownItem}
                    key={option.value}
                    value={option.value}
                    primaryText={option.label}
                    leftIcon={
                      <Icon {...option.leftIconProps} color="##666666" />
                    }
                    rightIcon={
                      <Icon {...option.rightIconProps} color={colors.green} />
                    }
                  />
                ))}
              </SelectField>
            </div>
            <div className={cls.suggestedTaggingSelectedText}>
              {selection.content || taggingSuggestion.content}
            </div>
          </section>
        </div>
      </Container>
    </Dialog>
  );
};

AITaggingSuggestionEditor.propTypes = {
  taggingSuggestion: PropTypes.object,
  onRequestClose: PropTypes.func.isRequired,
  onRequestSaveAndAccept: PropTypes.func.isRequired,
  onRequestDecline: PropTypes.func.isRequired,
};

export default AITaggingSuggestionEditor;
