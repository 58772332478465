import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { Tooltip } from 'src/components/IMUI';
import { Icon } from 'im/ui/Icon';
import cls from './Tag.module.css';
import { canManageTag } from 'src/userStorage';
import { v1 as uuidv1 } from 'uuid';
const cx = classNames.bind(cls);

export class Tag extends React.PureComponent {
  static propTypes = {
    inline: PropTypes.bool,
    reach: PropTypes.bool,
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.node,
    ]),
    onRemove: PropTypes.func,
    onEdit: PropTypes.func,
    className: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    positive: PropTypes.bool,
    unstable: PropTypes.bool,
    alt: PropTypes.bool,
    title: PropTypes.string,
    informative: PropTypes.bool,
    grey: PropTypes.bool,
    dim: PropTypes.bool,
    dashed: PropTypes.bool,
    outline: PropTypes.bool,
    inheritColor: PropTypes.bool,
    small: PropTypes.bool,
    leftAlign: PropTypes.bool,
    warning: PropTypes.bool,
    tip: PropTypes.string,
    squished: PropTypes.bool,
    square: PropTypes.bool,
    borderGrey: PropTypes.bool,
    minWidth: PropTypes.number,
    renderIcon: PropTypes.func,
    renderIconLeft: PropTypes.func,
    labelProps: PropTypes.object,
  };

  render() {
    const {
      inline,
      reach,
      label,
      onRemove,
      onEdit,
      className,
      tip,
      dim,
      dashed,
      outline,
      grey,
      inheritColor,
      small,
      squished,
      disabled,
      positive,
      unstable,
      alt,
      informative,
      warning,
      minWidth,
      leftAlign,
      onClick,
      renderIcon,
      renderIconLeft,
      square,
      borderGrey,
      title,
      labelProps = {},
    } = this.props;

    const tagClassNames = cx(
      'imui-tag',
      {
        'imui-tag-inline': inline,
        'imui-tag-positive': positive,
        'imui-tag-unstable': unstable,
        'imui-tag-alt': alt,
        'imui-tag-informative': informative,
        'imui-tag-warning': warning,
        'imui-tag-grey': grey,
        'imui-tag-dim': dim,
        'imui-tag-dashed': dashed,
        'imui-tag-outline': outline,
        'imui-tag-disabled': disabled,
        'imui-tag-inherit': inheritColor,
        'imui-tag-small': small,
        'imui-tag-leftAlign': leftAlign,
        'imui-tag-squished': squished,
        'imui-tag-square': square,
        'imui-tag-borderGrey': borderGrey,
      },
      className
    );

    const styles = { ...labelProps.style, ...(minWidth && { minWidth }) };
    const TagElement = onClick ? 'button' : 'div';
    const hasIcon = onRemove || onEdit || renderIcon;
    const id = uuidv1();
    return (
      <TagElement
        type={onClick && 'button'}
        role={onClick ? 'button' : undefined}
        className={tagClassNames}
        onClick={disabled ? undefined : this.handleTopClick}
        disabled={disabled}
        title={title}
        data-tip={!!tip}
        data-for={id}
      >
        <div
          className={cx('imui-tag-label', labelProps.className)}
          style={styles}
        >
          {renderIconLeft?.()}
          {reach && <b># </b>}
          {label}
        </div>
        {hasIcon && (
          <div className={cx('imui-tag-icon')}>
            <span style={{ display: 'inline-flex' }}>
              {canManageTag() && onRemove && (
                <Icon
                  style={{ fontSize: 18, width: 21, maxWidth: 21 }}
                  name="close"
                  onClick={this.onRemoveClick}
                />
              )}
              {canManageTag() && onEdit && (
                <Icon
                  style={{
                    paddingLeft: 3,
                    paddingRight: 3,
                    fontSize: 15,
                    width: 21,
                    maxWidth: 21,
                  }}
                  name="edit"
                  onClick={this.onEditClick}
                />
              )}
              {renderIcon?.()}
            </span>
          </div>
        )}

        {tip && <Tooltip id={id}>{tip}</Tooltip>}
      </TagElement>
    );
  }
  onRemoveClick = (ev) => {
    ev.stopPropagation();
    this.props.onRemove?.(ev);
  };
  onEditClick = (ev) => {
    ev.stopPropagation();
    this.props.onEdit?.(ev);
  };
  handleTopClick = (ev) => {
    ev.stopPropagation();
    this.props.onClick?.(ev);
  };
}
