import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import uploaderSecureApi from 'src/api/UploaderSecure';
import Dropzone from 'src/components/Dropzone/Dropzone';
import {
  Button,
  Section,
  Actions,
  MAX_UPLOAD_SIZE,
  Progress,
} from 'src/components/IMUI/index';
import { where } from 'im/api/Query';
import cls from 'src/pages/App/Grants/Import/UploadCSV.module.css';
import { userCurrentOrgId } from 'src/userStorage';
import { Icon } from 'im/ui';
import pick from 'ramda/src/pick';
import { getSurveyReview } from 'src/actionCreators/surveyActionCreators';
import { getAttachment } from 'src/actionCreators/surveyAnalysisActionCreators';
const cx = classNames.bind(cls);
const EXTENSIONS = [
  '.aac',
  '.abw',
  '.avif',
  '.avi',
  '.azw',
  '.bmp',
  '.csv',
  '.doc',
  '.docx',
  '.eot',
  '.epub',
  '.gif',
  '.htm',
  '.html',
  '.ics',
  '.jpeg',
  '.jpg',
  '.json',
  '.jsonld',
  '.mp3',
  '.mp4',
  '.mpeg',
  '.odp',
  '.ods',
  '.odt',
  '.oga',
  '.ogv',
  '.ogx',
  '.png',
  '.pdf',
  '.php',
  '.ppt',
  '.pptx',
  '.rtf',
  '.svg',
  '.tif',
  '.tiff',
  '.txt',
  '.vsd',
  '.wav',
  '.xls',
  '.xlsx',
  '.xml',
];
const initialState = {
  files: [],
  success: false,
  error: false,
  url: null,
  file_name: null,
  id: null,
  deleted: false,
  uploading: false,
  completed: false,
};

@connect(pick(['importUploadsSurveyAnswer']), {
  destroyFile: uploaderSecureApi.surveyAnswer.destroy,
  uploadFile: uploaderSecureApi.surveyAnswer.create,
  getSurveyReview,
})
export default class UploadAttachment extends React.PureComponent {
  static propTypes = {
    importUpload: PropTypes.object,
    surveyAnswerId: PropTypes.string,
    granteeSurveyId: PropTypes.any,
    questionId: PropTypes.number,
    isReview: PropTypes.bool.isRequired,
    projectId: PropTypes.any,
    destroyFile: PropTypes.func.isRequired,
    uploadFile: PropTypes.func.isRequired,
    getSurveyReview: PropTypes.func.isRequired,
  };
  state = { ...initialState };
  static defaultProps = {
    importUpload: {},
    isAnswerMode: false,
    getSurveyReview: () => void 0,
  };

  handleDrop = (files) => {
    this.setState({ ...initialState, files });
  };
  handleClear = () => {
    this.setState({ ...initialState });
  };
  doDownloadAttachment = (ev) => {
    ev.stopPropagation();
    this.state.url
      ? window.open(this.state.url, '_blank')
      : getAttachment(this.props.importUpload?.id).then((url) =>
          window.open(url, '_blank')
        );
  };

  handleUpload = async () => {
    this.setState({ uploading: true });
    const target_id = this.props.surveyAnswerId
      ? { target_id: this.props.surveyAnswerId }
      : {};
    await Promise.all(
      this.state.files.map((file) =>
        this.props.uploadFile(
          where().payloadMultipart(
            {
              ...target_id,
              grantee_survey_id: this.props.granteeSurveyId,
              asset: file,
              question_id: this.props.questionId,
              target_type: 'SurveyAnswer',
              organization_id: userCurrentOrgId(),
            },
            'import_upload'
          )
        )
      )
    )
      .then((result) => {
        this.setState({
          id: result?.[0]?.data?.id,
          url: result?.[0]?.data?.url,
          file_name: result?.[0]?.data?.file_name,
          uploading: false,
          success: Boolean(result?.[0]?.data?.url),
        });
      })
      .then(
        () =>
          this.props.isReview &&
          this.props.getSurveyReview(
            this.props.projectId,
            this.props.granteeSurveyId
          )
      )
      .catch((e) => {
        console.error('UPLOAD ERROR', e);
        this.setState({ ...initialState, uploading: false, error: true });
      });
  };

  handleRemove = () => {
    this.props
      .destroyFile(
        where({ id: this.state.id ?? this.props.importUpload?.id }).actionMeta({
          noNotify: true,
        })
      )
      .then(() =>
        this.setState({
          ...initialState,
          id: null,
          error: false,
          deleted: true,
        })
      )
      .catch((e) =>
        this.setState({
          ...initialState,
          id: null,
          error: false,
          deleted: true,
        })
      );
  };

  render() {
    const hasUploadInProgress =
      this.state.files.length > 0 && !this.state.success;
    const serverHasAttachment =
      !this.state.deleted && (this.props.importUpload?.id || this.state.url);
    return (
      <div
        className={cx(
          this.state.completed ? cls.dropzoneContainerCompleted : null
        )}
      >
        {serverHasAttachment && (
          <div
            style={{
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'space-between',
              gap: 32,
              fontSize: 16,
            }}
          >
            <small role="button" onClick={this.doDownloadAttachment}>
              <strong>Attached File:</strong>{' '}
              {this.props.importUpload?.asset_data?.metadata?.filename ||
                this.state.file_name}
            </small>
            {this.state.uploading && <Progress size={16} className="upload" />}
            {
              <Button
                style={{ textDecoration: 'none' }}
                text
                alert
                className={cls.buttonAlert}
                label="Delete file"
                onClick={this.handleRemove}
                icon={<Icon name="trash" tip="Remove" />}
              />
            }
          </div>
        )}

        {!serverHasAttachment && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: 8,
              color: '#666',
              maxHeight: 24,
            }}
          >
            {this.state.uploading && (
              <span>
                <Progress size={16} className="upload" />
              </span>
            )}
            <Dropzone
              iconInsteadOfText={true}
              disablePreview={true}
              containerClassName={cls.dropzoneAttachment}
              className={cls.dropzone}
              multiple={false}
              name="upload_attachment"
              accept={EXTENSIONS.join(',')}
              acceptType={
                'safe formats (documents, images, texts, spreadsheets)'
              }
              onDrop={this.handleDrop}
              onClear={this.handleClear}
              accepted={this.state.files}
              maxSize={MAX_UPLOAD_SIZE}
              style={{
                maxWidth: '100%',
                marginRight: 0,
                minHeight: 32,
                maxHeight: 32,
                lineHeight: 1,
                display: 'inline',
                fontSize: 12,
              }}
            >
              {this.state.error && <small>upload error</small>}
              {this.state.files.length == 0 && (
                <span>
                  &emsp;Drag and drop or click to upload your media file
                </span>
              )}
              {this.props.isReview && this.state.files.length > 0 && (
                <span>
                  &emsp;
                  {this.props.importUpload?.asset_data?.metadata?.filename}
                </span>
              )}
            </Dropzone>
          </div>
        )}

        {hasUploadInProgress && (
          <Section
            type="sticky-footer-survey"
            secondary={true}
            className={cls.stickyFooter11}
          >
            <Actions>
              <Button
                negative
                size="l"
                label="Cancel"
                onClick={this.handleClear}
              />
            </Actions>
            <Actions>
              <Button size="l" label="Upload" onClick={this.handleUpload} />
            </Actions>
          </Section>
        )}
      </div>
    );
  }
}
// https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
// https://react-dropzone.js.org/#section-accepting-specific-file-types
