import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import find from 'ramda/src/find';
import flatten from 'ramda/src/flatten';
import identity from 'ramda/src/identity';
import path from 'ramda/src/path';
import uniq from 'ramda/src/uniq';
import React from 'react';
import {
  Button,
  Popover,
  SelectField,
  Tag,
  TextField,
  DatePickerDialog,
  Checkbox,
} from 'src/components/IMUI';

import cls from './DataFilter.module.css';

const cx = classNames.bind(cls);
const CATEGORY_DATE_TYPE = 'date';

const matches = (candidate, values, transform, customMatch) => {
  const applyCustomMatch = (item) =>
    customMatch.call(this, (transform || identity)(candidate), item);

  if (Array.isArray(candidate)) {
    const transformedCandidates = candidate.map(transform || identity);
    return !customMatch
      ? transformedCandidates.some((item) => values.indexOf(item) !== -1)
      : transformedCandidates.some(applyCustomMatch);
  }
  return !customMatch
    ? values.indexOf((transform || identity)(candidate)) !== -1
    : values.some(applyCustomMatch);
};

const defaultValuesGetter = (data, propPath, transform) => {
  const values = uniq(flatten(data.map(path(propPath)))).filter(identity);
  if (!transform) return values;
  return values.map(transform);
};

/**
 * takes data as a full set and returns filtered subset using the onChange callback
 */
export default class DataFilter extends React.PureComponent {
  static propTypes = {
    data: PropTypes.array,
    label: PropTypes.string,
    showBadge: PropTypes.bool,
    showInputFilter: PropTypes.bool,
    config: PropTypes.arrayOf(
      PropTypes.shape({
        path: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        values: PropTypes.array,
        valueTransform: PropTypes.func,
      })
    ),
    className: PropTypes.string,
    onChange: PropTypes.func,
    onPopoverClose: PropTypes.func,
  };

  state = {
    popoverOpen: false,
    config: [],
    selectedCategory: {},
    filterText: '',
  };

  componentDidMount() {
    this.updateConfig(this.props.data);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data?.length !== this.props.data?.length) {
      this.updateConfig(this.props.data);
    }

    if (prevProps.config?.length !== this.props.config?.length) {
      this.updateConfig(this.props.data, this.props.config);
    }
  }

  onAddFilterClicked = (ev) => {
    ev?.preventDefault();
    this.setState({ popoverOpen: true });
  };

  onClearFiltersClicked = () => {
    const config = this.state.config.map((conf) => ({
      ...conf,
      selectedValues: [],
    }));

    this.setState({ config }, () => {
      this.broadcastFilterChanged();
      this.onPopoverClose();
    });
  };

  onPopoverClose = () => {
    if (this.props.onPopoverClose) {
      this.props.onPopoverClose();
    }
    this.setState({
      popoverOpen: false,
      selectedCategory: {},
    });
  };

  onClearItem = (name, category) => {
    const { config } = this.state;
    const categoryConf = find((conf) => conf.path === category, config);
    const itemIndex = categoryConf.selectedValues.indexOf(name);

    categoryConf.selectedValues.splice(itemIndex, 1);
    // create shallow copy to satisfy PureComponent
    this.setState({ config: [...config] });
    this.broadcastFilterChanged();
  };

  onClearCategory = (category) => {
    const { config } = this.state;
    const categoryConf = find((conf) => conf.path === category, config);

    categoryConf.selectedValues = [];
    // create shallow copy to satisfy PureComponent
    this.setState({ config: [...config] }, () => {
      this.broadcastFilterChanged();
    });
  };

  onCategorySelectChanged = (val) => {
    const { config } = this.state;
    const newSelectedCategory = config[val.index];

    this.setState({
      selectedCategory: {
        ...newSelectedCategory,
        filteredValues: newSelectedCategory.values,
      },
      filterText: '',
    });

    if (newSelectedCategory.type === CATEGORY_DATE_TYPE) {
      this.innerRef?.show();
    }
  };

  onCategoryItemClicked = (name) => {
    const { selectedCategory, config } = this.state;
    const newConfig = [...config];
    const newSelectedCategory = { ...selectedCategory };
    const configIndex = config.findIndex(
      (c) => c.path === selectedCategory.path
    );
    const itemIndex = selectedCategory.selectedValues.indexOf(name);

    if (itemIndex === -1) {
      newSelectedCategory.selectedValues.push(name);
    } else {
      newSelectedCategory.selectedValues.splice(itemIndex, 1);
    }

    newConfig[configIndex] = newSelectedCategory;
    this.setState(
      { selectedCategory: newSelectedCategory, config: newConfig },
      () => {
        this.broadcastFilterChanged();
      }
    );
  };

  onCategorySingleItemClicked = (value) => {
    const { selectedCategory, config } = this.state;
    const transformedValue = selectedCategory.valueTransform.call(this, value);
    const newSelectedCategory = {
      ...selectedCategory,
      selectedValues: [transformedValue],
    };
    const configIndex = config.findIndex(
      (c) => c.path === selectedCategory.path
    );
    const newConfig = [...config];
    newConfig[configIndex] = newSelectedCategory;

    this.setState(
      { selectedCategory: newSelectedCategory, config: newConfig },
      () => {
        this.broadcastFilterChanged();
      }
    );
  };

  onInputFilterChange = (_filterText) => {
    const { selectedCategory } = this.state;
    const filterText = _filterText && _filterText.toLowerCase();

    if (!filterText) {
      const newSelectedCategory = {
        ...selectedCategory,
        filteredValues: selectedCategory.values,
      };
      this.setState({ filterText, selectedCategory: newSelectedCategory });
      return;
    }

    const filteredValues = selectedCategory.values.filter((item) =>
      item.toLowerCase().includes(filterText)
    );
    const newSelectedCategory = { ...selectedCategory, filteredValues };
    this.setState({ filterText, selectedCategory: newSelectedCategory });
  };

  onToggleSelectAll(ev, isChecked) {
    const { selectedCategory, config } = this.state;
    const newConfig = [...config];
    const newSelectedCategory = { ...selectedCategory };
    const configIndex = config.findIndex(
      (c) => c.path === selectedCategory.path
    );

    if (isChecked) {
      const allValues = selectedCategory.selectedValues.concat(
        selectedCategory.filteredValues
      );
      newSelectedCategory.selectedValues = allValues.filter(
        (item, index) => allValues.indexOf(item) === index
      );
    } else {
      newSelectedCategory.selectedValues =
        selectedCategory.selectedValues.filter(
          (item) => selectedCategory.filteredValues.indexOf(item) === -1
        );
    }

    newConfig[configIndex] = newSelectedCategory;
    this.setState(
      { selectedCategory: newSelectedCategory, config: newConfig },
      () => {
        this.broadcastFilterChanged();
      }
    );
  }

  broadcastFilterChanged() {
    const { onChange, data } = this.props;
    const { config } = this.state;

    if (onChange) {
      // we could implement here custom strategies for matching against multiple values
      //  AND/OR/XOR/NOT/NAND/NOR/XNOR ?
      const filteredData = data.filter((item) => {
        return config.every((categoryConf) => {
          const { selectedValues } = categoryConf;
          const rPath = categoryConf.path.split('.');
          return (
            !selectedValues.length ||
            matches(
              path(rPath, item),
              categoryConf.selectedValues,
              categoryConf.valueTransform,
              categoryConf.customMatch
            )
          );
        });
      });

      onChange(filteredData);
    }
  }

  updateConfig(data, config) {
    const parsedConfig = [];
    (config || this.props.config).forEach((conf) => {
      const rPath = conf.path.split('.');
      parsedConfig.push({
        ...conf,
        values:
          (conf.getter
            ? conf.getter(data, rPath)
            : defaultValuesGetter(data, rPath, conf.valueTransform)) || [],
        selectedValues: [],
        get filteredValues() {
          return this.values;
        },
      });
    });
    this.setState({ config: parsedConfig }, () => {
      this.broadcastFilterChanged();
    });
  }

  renderSelectedItems(selectedItems) {
    return (
      <div className={cls.dataFilterItems}>
        {selectedItems.map((item) => {
          return item.selectedValues ? (
            <Tag
              dim
              label={item.label}
              onRemove={() => this.onClearCategory(item.path)}
              key={item.label}
            />
          ) : (
            <Tag
              label={item.label}
              onRemove={() => this.onClearItem(item.label, item.path)}
              key={item.label}
            />
          );
        })}
      </div>
    );
  }

  render() {
    const { data, label, showBadge, className, showInputFilter } = this.props;
    const { config, selectedCategory, filterText, popoverOpen } = this.state;

    const areValuesHidden = selectedCategory.type === CATEGORY_DATE_TYPE;
    const showDatePicker = config.some(
      ({ type }) => type === CATEGORY_DATE_TYPE
    );
    const isSelected = (name) =>
      selectedCategory.selectedValues.indexOf(name) !== -1;
    const selectedCount = config.reduce(
      (prev, curr) => prev + curr.selectedValues.length,
      0
    );
    const selectedItems = config.reduce((acc, conf) => {
      if (!conf.selectedValues.length) {
        return acc;
      }
      return acc.concat(
        conf,
        conf.selectedValues.map((item) => ({ path: conf.path, label: item }))
      );
    }, []);

    return (
      <div
        ref={(ref) => (this.outerRef = ref)}
        className={cx('dataFilter', className)}
      >
        <Button
          secondary
          label="Add filter"
          size="l"
          disabled={!data || !data.length}
          onClick={this.onAddFilterClicked}
          badge={showBadge && (selectedCount || null)}
        />
        {!selectedItems.length ? null : this.renderSelectedItems(selectedItems)}
        <Popover
          dark
          fixedWidth
          absoluteRight
          zIndex={1400}
          large
          open={popoverOpen}
          anchorEl={this.outerRef}
          onRequestClose={this.onPopoverClose}
          header={
            <div>
              <Popover.Header text={label} />
              <SelectField
                value={selectedCategory.path}
                onChange={this.onCategorySelectChanged}
                hintText="Select filter"
                fullWidth
                noValidation
              >
                {config.map((item) => (
                  <SelectField.Item
                    key={item.path}
                    value={item.path}
                    disabled={!item.values.length}
                    primaryText={item.label}
                  />
                ))}
              </SelectField>
              {!showInputFilter ||
              !selectedCategory.filteredValues ||
              areValuesHidden ? null : (
                <TextField
                  fullWidth
                  noValidation
                  clearable
                  name="filterText"
                  hintText="Type in to filter"
                  value={filterText}
                  onChange={(text) => this.onInputFilterChange(text)}
                />
              )}
            </div>
          }
          footerAction={this.onClearFiltersClicked}
          footerLabel="Clear all filters"
        >
          <Popover.List>
            {!selectedCategory.filteredValues || areValuesHidden ? (
              <Popover.ListItem
                empty
                primaryText="Select category to show filter options"
              />
            ) : (
              <div>
                <div className={cx('checkboxSelectAllWrapper')}>
                  <Checkbox
                    className={cx('checkboxSelectAll')}
                    label="Select All"
                    onCheck={(ev, isChecked) =>
                      this.onToggleSelectAll(ev, isChecked)
                    }
                  />
                </div>
                {selectedCategory.filteredValues.map((item) => (
                  <Popover.ListItemSimple
                    key={item}
                    onClick={() => this.onCategoryItemClicked(item)}
                  >
                    <Checkbox
                      readOnly
                      label={item}
                      checked={isSelected(item)}
                    />
                  </Popover.ListItemSimple>
                ))}
              </div>
            )}
          </Popover.List>
        </Popover>

        {!showDatePicker ? null : (
          <DatePickerDialog
            ref={(ref) => (this.innerRef = ref)}
            name="dateFilter"
            onAccept={(date) => this.onCategorySingleItemClicked(date)}
            onDismiss={() => this.onPopoverClose()}
          />
        )}
      </div>
    );
  }
}
