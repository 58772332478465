import classNames from 'classnames/bind';
import MenuItem from 'material-ui/MenuItem';
import MUISelectField from 'material-ui/SelectField';
import PropTypes from 'prop-types';
import React from 'react';

import { Icon } from 'im/ui/Icon';

import { InputReduxForm, InputSticky, Label, Error, ClearButton } from './base';

import cls from './SelectField.module.css';

const cx = classNames.bind(cls);

export class SelectField extends React.Component {
  static Item = MenuItem;

  static propTypes = {
    outline: PropTypes.bool,
    transparent: PropTypes.bool,
    flat: PropTypes.bool,
    border: PropTypes.bool,
    flatDark: PropTypes.bool,
    selectIcon: PropTypes.node,
    compactSelection: PropTypes.bool,
    clearable: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    label: PropTypes.node,
    labelClassName: PropTypes.string,
    selectedValueAccessory: PropTypes.node,
    dropDownMenuProps: PropTypes.object,

    underlineStyle: PropTypes.object,
    maxHeight: PropTypes.number,
    selectedMenuItemStyle: PropTypes.object,
    ...InputReduxForm.propTypes,
    ...InputSticky.propTypes,
  };

  static defaultProps = {
    underlineStyle: { display: 'none' },
    underlineFocusStyle: { display: 'none' },
    selectedMenuItemStyle: { color: '#52cccc' },
    menuItemStyle: { fontFamily: 'averta, Arial, sans-serif' },
    selectIcon: (
      <Icon name="chevron-down" className={cx('imui-select-field-icon')} />
    ),
    maxHeight: 300,
    noValidation: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      value: props.input?.value || props.value,
    };
  }

  componentWillReceiveProps({ input, value }) {
    // const { input, value } = this.props;

    if (
      input &&
      typeof input.value !== 'undefined' &&
      input.value !== this.state.value
    ) {
      this.setState({ value: input.value });
    }

    if (!input && typeof value !== 'undefined' && value !== this.state.value) {
      this.setState({ value }, () => this.forceUpdate());
    }
  }

  onChange = (ev, index, value) => {
    const { input, onChange, name } = this.props;
    if (input?.onChange) {
      input.onChange(value);
    }

    if (onChange) {
      onChange({ ev, index, value, name });
    }

    this.setState({ value });
  };

  onClear = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    this.onChange(null, -1, null);
  };

  selectionRenderer = (value) => {
    const { multiple, children, compactSelection, selectedValueAccessory } =
      this.props;
    const valuesArray = multiple ? value : [value];
    let selectedItem;

    const childElems = React.Children.toArray(children);

    if (compactSelection && valuesArray.length > 1) {
      return (
        <span className={cx('imui-select-field-input-value')}>
          {selectedValueAccessory} {valuesArray.length} items selected
        </span>
      );
    }

    return valuesArray.reduce((acc, v) => {
      selectedItem = childElems.find(
        ({ props: { value: menuItemValue } }) => menuItemValue === v
      );
      return acc.concat([
        <span key={v} className={cx('imui-select-field-input-value')}>
          {selectedValueAccessory} {selectedItem.props.primaryText}
        </span>,
      ]);
    }, []);
  };

  render() {
    /* eslint-disable */
    const {
      children,
      className,
      outline,
      transparent,
      flat,
      border,
      flatDark,
      name,
      stick,
      input,
      meta,
      noValidation,
      label,
      underlineStyle,
      maxHeight,
      selectedMenuItemStyle,
      selectIcon,
      compactSelection,
      clearable,
      dropDownMenuProps,
      selectedValueAccessory,
      error,
      labelClassName,
      onRef,
      ...rest
    } = this.props;
    /* eslint-enable */
    const { value } = this.state;

    return (
      <div className={cx({ 'imui-input-wrapper-fullwidth': rest.fullWidth })}>
        <Label label={label} className={labelClassName} />
        <MUISelectField
          {...input}
          {...rest}
          ref={(ref) => this.props.onRef?.(ref)}
          className={cx(
            'imui-select-field-input',
            'imui-text-field',
            {
              'imui-select-field-flat': flat,
              'imui-select-field-border': border,
              'imui-select-field-input-outline': outline,
              'imui-select-field-input-transparent': transparent,
              'imui-select-field-flatDark': flatDark,
              [`imui-select-field-input-stick-${stick}`]: stick && stick.length,
              'imui-text-field-has-hint': !!rest.hintText,
            },
            className
          )}
          selectionRenderer={this.selectionRenderer}
          dropDownMenuProps={{
            iconButton: selectIcon,
            style: { borderRadius: '6px' },
            ...dropDownMenuProps,
          }}
          name={input?.name || name}
          value={input?.value || value}
          onChange={this.onChange}
          onBlur={() => void 0}
          underlineStyle={underlineStyle}
          maxHeight={maxHeight}
          selectedMenuItemStyle={selectedMenuItemStyle}
        >
          {children}
        </MUISelectField>
        <ClearButton
          spaceRight
          visible={clearable && !!value}
          onClear={this.onClear}
        />
        {!noValidation && <Error meta={meta} error={error} />}
      </div>
    );
  }
}

export default SelectField;
