import reducerRegistry from 'im/api/registry';
import Resource from 'im/api/Resource';

const apiConfig = {
  root: '/v3/grants',
  type: 'grants',
  api: {
    allow: ['find'],
  },
};

export const Grant = new Resource(apiConfig, 'grant');

reducerRegistry.register(Grant);

export default Grant.api;
