import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import { Checkbox } from 'src/components/IMUI';

import { Icon } from 'im/ui/Icon';

import EditIcons from './EditIcons';

import cls from './TagGroupItem.module.css';

const cx = classNames.bind(cls);
const tagWithCode = (tag = {}, showCodes) =>
  showCodes && tag.code?.length > 0
    ? `${tag.code}${tag.code.endsWith('.') ? '' : '.'} ${tag.title}`
    : tag.title;

export class TagGroupItem extends React.PureComponent {
  static propTypes = {
    tagGroup: PropTypes.object,
    color: PropTypes.string,
    children: PropTypes.node,
    leftIcon: PropTypes.node,
    isSelected: PropTypes.bool,
    isExpandable: PropTypes.bool,
    isOpen: PropTypes.bool,
    showCodes: PropTypes.bool,

    onTagGroupExpand: PropTypes.func,
    onTagGroupEdit: PropTypes.func,
    onToggleSelect: PropTypes.func,
  };

  static defaultProps = { isExpandable: true, onTagGroupExpand: () => void 0 };
  constructor(props) {
    super(props);
    this.state = { isOpen: props.isOpen !== undefined ? props.isOpen : true };
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.isOpen !== this.props.isOpen &&
      nextProps.isOpen !== this.state.isOpen
    ) {
      this.setState({ isOpen: nextProps.isOpen });
    }
  }

  handleToggleListOpen = () => {
    const isOpen = !this.state.isOpen;
    this.setState({ isOpen });
    this.props.onTagGroupExpand(this.props.tagGroup, isOpen);
  };

  handleToggleSelect = (ev, isChecked) => {
    this.props.onToggleSelect(isChecked, this.props.tagGroup);
  };

  render() {
    const {
      color,
      children,
      tagGroup,
      onTagGroupEdit,
      onToggleSelect,
      isSelected,
      leftIcon,
      isExpandable,
    } = this.props;
    return (
      <li className={cls.listItem}>
        <div
          className={cls.listItemTextWrapper}
          style={{ borderLeft: `4px solid ${color || 'white'}` }}
          data-qa="TagList:TagGroupItem-display"
        >
          {leftIcon ||
            (isExpandable && (
              <Icon
                className={cls.listLeftIcon}
                name={this.state.isOpen ? 'folder' : 'folder-closed'}
                onClick={this.handleToggleListOpen}
              />
            ))}
          {onToggleSelect && (
            <Checkbox
              noLabel
              className={cls.listItemCheckbox}
              checked={isSelected}
              onCheck={this.handleToggleSelect}
            />
          )}
          <div
            className={cx(cls.listItemText, cls.listItemTextClickable)}
            onClick={this.handleToggleListOpen}
          >
            <strong>{tagWithCode(tagGroup, this.props.showCodes)}</strong>
          </div>
          {onTagGroupEdit && (
            <div className={cls.listRightIcons}>
              <EditIcons onClickEdit={() => onTagGroupEdit(tagGroup)} />
            </div>
          )}
        </div>
        <ul className={cls.subList} style={{ marginLeft: 24 }}>
          {this.state.isOpen && children}
        </ul>
      </li>
    );
  }
}
