import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Button, Container, Tile } from 'src/components/IMUI/index';
import { Icon } from 'im/ui/Icon';
import cls from './OrganizationTileItem.module.css';
import { canManageOrganization } from 'src/userStorage';
const cx = classNames.bind(cls);

export default class OrganizationTileItem extends React.PureComponent {
  static propTypes = {
    organization: PropTypes.object,
    isPendingApproval: PropTypes.bool,
    isPendingDeletion: PropTypes.bool,
    isCurrent: PropTypes.bool,
    onEditClick: PropTypes.func.isRequired,
    onChangeOrganization: PropTypes.func.isRequired,
  };
  static defaultProps = { organization: {} };
  render() {
    const { organization, isPendingApproval, isPendingDeletion, isCurrent } =
      this.props;
    return (
      <Tile
        size="s"
        grow
        key={organization.id}
        className={cx('user-orgs-tile')}
      >
        {canManageOrganization() && (
          <Icon
            name="edit"
            tip="Edit"
            className={cx('user-orgs-tile-icon')}
            onClick={() => this.props.onEditClick(organization.id)}
          />
        )}
        {organization.logoUrl ? (
          <Tile.Icon
            className={cx('user-orgs-tile-img')}
            backgroundImgUrl={organization.logo_url}
          />
        ) : (
          <Tile.Title className={cx('user-orgs-tile-title')}>
            {organization.title}
          </Tile.Title>
        )}
        {!organization.description ? null : (
          <Tile.Text className={cx('user-orgs-tile-desc')}>
            {' '}
            {organization.description}{' '}
          </Tile.Text>
        )}
        <Container className={cx('user-orgs-tile-button-container')}>
          {organization.disabled ? (
            <span
              className={cx('user-orgs-tile-info', {
                'user-orgs-tile-info-warning': isPendingApproval,
                'user-orgs-tile-info-alarming': isPendingDeletion,
              })}
            >
              <Icon
                name={isPendingApproval ? 'processing' : 'trash'}
                className={cx('user-orgs-tile-info-icon')}
              />
              {organization.disabled}
            </span>
          ) : (
            this.props.onChangeOrganization && (
              <Button
                secondary
                size="l"
                className={cx('user-orgs-tile-button')}
                disabled={isCurrent}
                icon={<Icon name="compare" />}
                label={isCurrent ? 'Current' : 'Set as Current'}
                onClick={() => this.props.onChangeOrganization(organization.id)}
              />
            )
          )}
        </Container>
      </Tile>
    );
  }
}
