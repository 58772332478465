import PropTypes from 'prop-types';
import cond from 'ramda/src/cond';
import React from 'react';
import { connect } from 'react-redux';
import {
  reduxForm,
  Field,
  getFormValues,
  getFormSyncErrors,
  registerField,
} from 'redux-form';

import tagApi from 'src/api/Tag';
import {
  TextField,
  SelectField,
  Container,
  Actions,
  Button,
} from 'src/components/IMUI';
import { Error, Label } from 'src/components/IMUI/Forms/base';
import TagSelector from 'src/components/TagSelector/TagSelector';
import {
  REPORT__EXPAND_STRATEGY_WORD,
  REPORT__EXPAND_STRATEGY_SENTENCE,
  REPORT__EXPAND_STRATEGY_NONE,
  REPORT__EXPAND_STRATEGY_PARAGRAPH,
} from 'src/constants';
import { canManageTag } from 'src/userStorage';

const FORM_NAME = 'searchAndTagForm';

class SearchAndTag extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    getTag: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    registerFormField: PropTypes.func.isRequired,

    formValues: PropTypes.object,
    formErrors: PropTypes.object,
    tag: PropTypes.object,
    submitFailed: PropTypes.bool,
  };

  static defaultProps = {
    formValues: {},
    formErrors: {},
  };

  componentDidMount() {
    // Needed to have tagId initialized to make validation work
    this.props.registerFormField(FORM_NAME, 'tagId', 'Field');
  }

  handleTagChange = (tagId) => {
    this.props.change('tagId', tagId);
    this.props.getTag({ id: tagId });
  };

  render() {
    const {
      handleSubmit,
      onRequestClose,
      formValues,
      tag,
      formErrors,
      submitFailed,
    } = this.props;

    return (
      <form onSubmit={handleSubmit} noValidate>
        <Field
          name="text"
          label="Search for"
          component={TextField}
          hintText="enter phrase..."
        />
        <Field
          label="When found"
          noValidation
          name="selection"
          component={SelectField}
        >
          <SelectField.Item
            key={0}
            value={REPORT__EXPAND_STRATEGY_NONE}
            primaryText="Don't expand selection"
          />
          <SelectField.Item
            key={1}
            value={REPORT__EXPAND_STRATEGY_WORD}
            primaryText="Expand selection to word"
          />
          <SelectField.Item
            key={2}
            value={REPORT__EXPAND_STRATEGY_SENTENCE}
            primaryText="Expand selection to sentence"
          />
          <SelectField.Item
            key={3}
            value={REPORT__EXPAND_STRATEGY_PARAGRAPH}
            primaryText="Expand selection to paragraph"
          />
        </Field>
        <br />
        <div>
          <Label label="And tag it with" />
          <TagSelector
            allowCreate={false}
            zIndex={1501}
            noTagProps={{ label: 'Choose a tag' }}
            tagsWithTagGroups={[tag.data]}
            selected={formValues.tagId}
            onChange={(tagIds) => this.handleTagChange(tagIds)}
          />
          <Error error={(submitFailed && formErrors.tagId) || ''} />
        </div>

        <br />
        <Container horizontal>
          <Actions>
            <Button label="Cancel" negative onClick={onRequestClose} />
          </Actions>
          <Actions>
            <Button
              disabled={!canManageTag()}
              type="submit"
              label="Review matches"
            />
          </Actions>
        </Container>
      </form>
    );
  }
}

function validate(values) {
  const not = (fn) => (v) => !fn(v);
  const isDefined = (v) => Boolean(v);

  return {
    text: cond([[not(isDefined), () => 'Search phrase is required']])(
      values.text
    ),
    tagId: cond([[not(isDefined), () => 'Tag is required']])(values.tagId),
  };
}

function mapStateToProps(state) {
  return {
    formValues: getFormValues(FORM_NAME)(state),
    formErrors: getFormSyncErrors(FORM_NAME)(state),
    tag: state.tag,
  };
}

export default connect(mapStateToProps, {
  getTag: tagApi.find,
  registerFormField: registerField,
})(
  reduxForm({
    form: FORM_NAME,
    validate,
    initialValues: {
      selection: REPORT__EXPAND_STRATEGY_NONE,
      tagId: null,
    },
  })(SearchAndTag)
);
