import clone from 'ramda/src/clone';
import difference from 'ramda/src/difference';
import uniq from 'ramda/src/uniq';
import {
  SURVEY__REVIEW_GET_REQUEST,
  SURVEY__REVIEW_GET_FAILURE,
  SURVEY__REVIEW_GET_SUCCESS,
  SURVEY__REVIEW_CHANGE_ANSWER_VALUE,
  SURVEY__REVIEW_CHANGE_ANSWER_TAGS,
  SURVEY__REVIEW_CHANGE_ANSWER_TAGGINGS,
  SURVEY__REVIEW_CHANGE_ANSWER_TAGGINGS_REMOVE,
  SURVEY__REVIEW_SURVEY_ANSWER_PUT_SUCCESS,
  SURVEY__REVIEW_SURVEY_ANSWER_POST_SUCCESS,
  SURVEY__REVIEW_CHANGE_ANSWER_OTHER_CHOICE_VALUE,
} from 'src/constants';
import {
  fromApiToQuestions,
  fromApiToAnswers,
  fromApiToInfo,
  fromQuestionsToLayout,
} from 'src/serializers/surveySerializer';

const initialState = () => ({
  valid: false,
  done: false,
  pending: false,
  saving: false,
  isDirty: false,
  dirtyIds: [],
  questions: [],
  errors: {},
  answers: [],
  info: {},
  survey_id: null,
  layout: [],
});

const getTags = (tagIds) => [...new Set([...(tagIds || [])].filter(Boolean))];

export default function surveyReview(
  state = initialState(),
  { type, payload, meta = {} }
) {
  switch (type) {
    case SURVEY__REVIEW_GET_REQUEST: {
      return meta.background ? state : { ...initialState(), pending: true };
    }

    case SURVEY__REVIEW_GET_FAILURE: {
      return { ...initialState(), errors: payload.response.data.errors };
    }

    case SURVEY__REVIEW_GET_SUCCESS: {
      return {
        ...state,
        pending: false,
        isDirty: false,
        errors: {},
        questions: fromApiToQuestions(payload?.data),
        answers: fromApiToAnswers(payload?.data),
        info: fromApiToInfo(payload?.data),
        get layout() {
          return fromQuestionsToLayout(this.questions);
        },
      };
    }

    case SURVEY__REVIEW_CHANGE_ANSWER_VALUE: {
      const answerIndex = state.answers.findIndex(
        (a) => a.id == payload.questionId
      );
      if (answerIndex === -1) return state;
      const newState = clone(state);
      const newAnswer = newState.answers[answerIndex] || {};
      const answer = {
        ...newAnswer,
        value: payload.value ?? '',
        hasOther: false,
        tags: getTags(payload.tagIds),
      };
      newState.answers.splice(answerIndex, 1, answer);

      return {
        ...newState,
        isDirty: true,
        dirtyIds: uniq([...newState.dirtyIds, payload.questionId]),
      };
    }

    case SURVEY__REVIEW_CHANGE_ANSWER_OTHER_CHOICE_VALUE: {
      const answerIndex = state.answers.findIndex(
        (a) => a.id == payload.questionId
      );
      if (answerIndex === -1) return state;

      const newState = clone(state);
      const newAnswer = newState.answers[answerIndex] || {};
      const answer = {
        ...newAnswer,
        otherValue: payload.otherValue,
        hasOther: payload.otherValue?.length > 0,
        tags: getTags(payload.tagIds),
      };
      newState.answers.splice(answerIndex, 1, answer);

      return {
        ...newState,
        isDirty: true,
        dirtyIds: uniq([...newState.dirtyIds, payload.questionId]),
      };
    }

    case SURVEY__REVIEW_CHANGE_ANSWER_TAGS: {
      const answerIndex = state.answers.findIndex(
        (a) => a.id == payload.questionId
      );
      if (answerIndex === -1) return state;

      const newState = clone(state);
      const answer = Object.assign({}, newState.answers[answerIndex] || {});
      if (answerIndex > -1) newState.answers.splice(answerIndex, 1, answer);
      if (payload.tags) {
        answer.value =
          payload.tags
            .map((tag) => tag.name)
            .join(', ')
            .indexOf(answer.value) !== -1 || !answer.value
            ? payload.tags.map((tag) => tag.name).join(', ')
            : answer.value;
      }
      answer.tags = getTags(payload.tagIds);

      return {
        ...newState,
        isDirty: true,
        dirtyIds: uniq([...newState.dirtyIds, payload.questionId]),
      };
    }

    case SURVEY__REVIEW_CHANGE_ANSWER_TAGGINGS: {
      const answerIndex = state.answers.findIndex(
        (a) => a.id == payload.questionId
      );
      const newState = clone(state);
      const answer = Object.assign({}, newState.answers[answerIndex] || {});
      if (answerIndex > -1) newState.answers.splice(answerIndex, 1, answer);
      answer.manual_taggings = payload.taggings || [];

      return {
        ...newState,
        isDirty: true,
        dirtyIds: uniq([...newState.dirtyIds, payload.questionId]),
      };
    }

    case SURVEY__REVIEW_CHANGE_ANSWER_TAGGINGS_REMOVE: {
      const answerIndex = state.answers.findIndex(
        (a) => a.id == payload.questionId
      );
      const newState = clone(state);
      const answer = Object.assign({}, newState.answers[answerIndex] || {});
      if (answerIndex > -1) newState.answers.splice(answerIndex, 1, answer);
      answer.manual_taggings = difference(
        answer.manual_taggings,
        payload.taggings || []
      );

      return {
        ...newState,
        isDirty: true,
        dirtyIds: uniq([...newState.dirtyIds, payload.questionId]),
      };
    }

    case SURVEY__REVIEW_SURVEY_ANSWER_PUT_SUCCESS: {
      return {
        ...state,
        isDirty: false,
        dirtyIds: [],
        errors: {},
        pending: false,
      };
    }

    case SURVEY__REVIEW_SURVEY_ANSWER_POST_SUCCESS: {
      if (!payload?.data?.survey_answer) return state;

      const newState = clone(state);
      const answer = newState.answers.find(
        (a) => a.id === payload?.data?.survey_answer.survey_question_id
      );
      answer.answerId = payload?.data?.survey_answer.id;
      return {
        ...newState,
        isDirty: false,
        dirtyIds: [],
        errors: {},
        pending: false,
      };
    }
    default:
      return state;
  }
}
