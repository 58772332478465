import React from 'react';
import { Table } from 'src/components/IMUI';
import { getOrgText } from 'src/services/DictionaryService';
import classNames from 'classnames/bind';
import cls from './PreviewDataTable.module.css';
const cx = classNames.bind(cls);

const PreviewDataRow = ({ row, index }) => {
  return (
    <Table.Row>
      <Table.Cell
        className={cx({
          'table-row-valid': !row.has_error,
          'table-row-error': row.has_error,
        })}
      >
        {index + 1}
      </Table.Cell>
      <Table.Cell>
        {row.notes.map((note, noteIndex) => (
          <li key={`notes-${noteIndex}`}>
            {getOrgText(note)}
            <br />
          </li>
        ))}
      </Table.Cell>
      {Object.values(row.row).map((value, colIndex) => (
        <Table.Cell width={70} key={`preview-col-${colIndex}`}>
          {value?.toString() || ''}
        </Table.Cell>
      ))}
    </Table.Row>
  );
};

export default PreviewDataRow;
