import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import { Button as IMUIButton } from 'src/components/IMUI';

import cls from './TileButton.module.css';

const cx = classNames.bind(cls);

const TileButton = ({ className, label, onClick, ...rest }) => (
  <IMUIButton
    secondary
    size="l"
    className={cx('imui-tile-button', className)}
    label={label}
    onClick={onClick}
    {...rest}
  />
);
TileButton.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default TileButton;
