import React from 'react';

import { Container, Section } from 'src/components/IMUI';
import ManageSubjectsSurveyOnlyCard from 'src/components/SurveyOnly/ManageSubjectsSurveyOnlyCard';
import { UPGRADE_LICENSE } from 'src/components/SurveyOnly/surveyOnlyUpsellText';

export default function UserOrgEditLicensePlan() {
  return (
    <Section>
      <Container horizontal centerHorizontal>
        <ManageSubjectsSurveyOnlyCard {...UPGRADE_LICENSE} />
      </Container>
    </Section>
  );
}
