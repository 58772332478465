import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import { Icon } from 'im/ui/Icon';

import cls from './Label.module.css';

const cx = classNames.bind(cls);

export const Label = ({ label, icon, className, required }) =>
  label || icon ? (
    <div className={cx('imui-field-label', className)}>
      {icon}
      {typeof label === 'function' ? label() : label}
      {required && (
        <Icon name="asterix" className={cx('imui-field-label-icon')} />
      )}
    </div>
  ) : null;

Label.propTypes = {
  label: PropTypes.node,
  icon: PropTypes.node,
  required: PropTypes.bool,
  className: PropTypes.string,
};

export default Label;
