import React, { useEffect, useRef, useState } from 'react';
import { Table } from 'src/components/IMUI';
import { getOrgText } from 'src/services/DictionaryService';
import TableComposition from 'src/components/IMUI/Tables/TableComposition';
import TableBottomScroll from '../../Table/components/TableBottomScroll';
import PreviewDataRow from './PreviewDataRow';

const PreviewDataTable = ({ results, invalidOnly }) => {
  const innerRef = useRef();
  const [tableWidth, setTableWidth] = useState(0);
  const headers = results.headers;

  useEffect(() => {
    setTableWidth(innerRef?.current?.scrollWidth || 0);
  }, []);

  return (
    <TableBottomScroll
      style={{
        bottom: -40,
        height: 'unset',
      }}
      tableWidth={tableWidth}
    >
      <div ref={innerRef}>
        <TableComposition>
          <Table.Head>
            <Table.HCell text={'#'} />
            <Table.HCell style={{ minWidth: 300 }} text={'Import Status'} />
            {headers.map((header, index) => (
              <Table.HCell
                style={{ minWidth: 200 }}
                key={header + index}
                text={getOrgText(header)}
              />
            ))}
          </Table.Head>
          <Table.Body>
            {results.rows.map(
              (row, index) =>
                (!invalidOnly || row.has_error) && (
                  <PreviewDataRow
                    key={`preview-row-${index}`}
                    index={index}
                    row={row}
                  />
                )
            )}
          </Table.Body>
        </TableComposition>
      </div>
    </TableBottomScroll>
  );
};

export default PreviewDataTable;
