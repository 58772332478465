import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import {
  isSkipped,
  isMerged,
  isUnmapped,
  isMapped,
  isCustomField,
} from './MappingItem';
import { StatsBar, StatItem } from './StatsBar';

import cls from './MappingStatsBar.module.css';

@connect((state) => ({ formValues: getFormValues('grantMappingForm')(state) }))
export default class MappingStatsBar extends React.PureComponent {
  static propTypes = {
    showWarning: PropTypes.bool,
    csvColumns: PropTypes.arrayOf(PropTypes.string),
    formValues: PropTypes.object,
  };

  static defaultProps = { formValues: {}, csvColumns: [] };

  getStats = () => {
    const formColumns = this.props.formValues.columns || {};
    return {
      mapped: [
        ...new Set(
          this.props.csvColumns.filter((c) => isMapped(formColumns[c]))
        ),
      ].length,
      unmapped: [
        ...new Set(
          this.props.csvColumns.filter((c) => isUnmapped(formColumns[c]))
        ),
      ].length,
      skipped: [
        ...new Set(
          this.props.csvColumns.filter((c) => isSkipped(formColumns[c]))
        ),
      ].length,
      merged: [
        ...new Set(
          this.props.csvColumns.filter((c) =>
            isMerged(formColumns[c], formColumns)
          )
        ),
      ].length,
      custom: [
        ...new Set(
          this.props.csvColumns.filter((c) => isCustomField(formColumns[c]))
        ),
      ].length,
    };
  };

  render() {
    const stats = this.getStats();
    return (
      <StatsBar>
        {Object.entries(stats).map(([statName, total]) => {
          const className =
            !this.props.showWarning || statName !== 'unmapped'
              ? ''
              : cls.colorRed;
          return !total ? null : (
            <StatItem
              key={statName}
              className={className}
              name={statName}
              total={total}
            />
          );
        })}
      </StatsBar>
    );
  }
}
