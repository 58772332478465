import React from 'react';
import { formatNumber } from 'accounting';
import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import { connect } from 'react-redux';
import { saveChart } from 'src/actionCreators/chartsActionCreators';
import { showNotification } from 'src/actionCreators/notificationsActionCreators';
import { Actions, CardEmpty, Container, Table } from 'src/components/IMUI';
import { getMedian, getMode } from 'src/utils/number';
import { Icon } from 'im/ui/Icon';
import { NO_ANSWER } from '../../constants';
import EmptyValue from './EmptyValue';
import TableComposition from 'src/components/IMUI/Tables/TableComposition';

const handleDownloadTabularData = (
  tabularData,
  ignoredRows = [],
  ignoredColumns = [],
  doDownload = true
) => {
  const output = tabularData
    .filter((row, index) => ignoredRows.indexOf(index) === -1)
    .map((row) =>
      row.filter((col, index) => ignoredColumns.indexOf(index) === -1)
    )
    .map((row) =>
      row.map((cell) => (typeof cell !== 'string' ? cell : `"${cell}"`))
    )
    .map((row) => row.join(','))
    .join('\n'); // wrap cells with "" and convert to CSV string

  if (doDownload) {
    const a = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);
    const blob = new Blob([output], { type: 'tex/csv' });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = `${new Date().toISOString()}-${
      tabularData[0][0] || 'datatable'
    }-impactmapper-export.csv`;
    a.click();
    window.URL.revokeObjectURL(url);
  }
  return output;
};

@connect(pick(['chart']), { saveChart, showNotification })
export default class DataTable extends React.PureComponent {
  static propTypes = {
    exportTitle: PropTypes.string,
    filteredSource: PropTypes.array,
    source: PropTypes.array,
    onIgnoredColumnsAndRows: PropTypes.func,
    saveChart: PropTypes.func,
    showNotification: PropTypes.func,
    chart: PropTypes.object,
    actionsRenderer: PropTypes.func,
    formatValue: PropTypes.func,
  };

  state = { ignoredColumns: [], ignoredRows: [] };
  style = { overflow: 'auto', maxHeight: 768 };
  disabledStyles = { opacity: 0.4 };

  handleDownloadChart = () => {
    handleDownloadTabularData(
      this.props.filteredSource,
      this.state.ignoredRows,
      this.state.ignoredColumns,
      true
    );
  };
  handleSaveToCharts = () => {
    const rawCSV = handleDownloadTabularData(
      this.props.filteredSource,
      this.state.ignoredRows,
      this.state.ignoredColumns,
      false
    );
    const projectId = +location.pathname.match(/analysis\/(\d+)/)[1];

    const payload = {
      x: { model: 'CSV' },
      y: { model: 'CSV', attribute_name: 'Count' },
      name: this.props.exportTitle || 'Survey analysis export',
      options: { rawCSV, chartType: 'column', calculationType: ['Count'] },
    };

    this.props.saveChart(payload, projectId, true).then(() =>
      this.props.showNotification({
        title: 'Chart saved',
        message: (
          <span>
            <a
              href={`${window.location.origin}/analysis/${projectId}/charts/${this.props.chart.data.id}`}
            >
              View your chart
            </a>
          </span>
        ),
        level: 'success',
        autoDismiss: 10,
      })
    );
  };

  handleIgnoreCol = (index) => {
    this.handleIgnoreTableDimension('ignoredColumns', index);
  };
  handleIgnoreRow = (index) => {
    this.handleIgnoreTableDimension('ignoredRows', index);
  };

  handleIgnoreTableDimension = (dimension, index) => {
    const ignored = [...this.state[dimension]];
    const currIndex = ignored.indexOf(index);
    if (currIndex === -1) {
      ignored.push(index);
    } else {
      ignored.splice(currIndex, 1);
    }
    this.setState({ [dimension]: ignored }, () => {
      this.props.onIgnoredColumnsAndRows?.(
        this.state.ignoredColumns,
        this.state.ignoredRows
      );
      this.forceUpdate();
    });
  };

  renderRowFooter = (title, stats, statsKey) => {
    return (
      <Table.Row footer key={title}>
        <Table.Cell text={title} />
        {Object.keys(stats).map((keyIndex) => {
          const singleStat = stats[keyIndex] || {};
          const style = singleStat.disabled ? this.disabledStyles : undefined;
          let value;
          if (Array.isArray(singleStat[statsKey])) {
            if (singleStat[statsKey].length === 1)
              value = formatNumber(Number(singleStat[statsKey][0]));
            else
              value = singleStat[statsKey]
                .map((val) => formatNumber(val))
                .join(', ');
          } else if (
            typeof singleStat[statsKey] === 'number' ||
            typeof Number(singleStat[statsKey]) === 'number'
          ) {
            value = formatNumber(singleStat[statsKey]);
          } else {
            value = '–';
          }
          return (
            <Table.Cell
              text={value}
              style={style}
              key={`${title}-${keyIndex}`}
            />
          );
        })}
      </Table.Row>
    );
  };

  renderBodyFooter = () => {
    if (this.props.source?.length <= 2) return [];
    const rowValues = this.props.source.slice(1).map((r) => r.slice(1));
    const allColumn = this.props.source.slice(1)[0].slice(1);

    const stats = allColumn.map((c, index) => {
      if (this.state.ignoredColumns.includes(index + 1))
        return { disabled: true };
      const values = rowValues
        .filter((r, i) => !this.state.ignoredRows.includes(i + 1))
        .map((r) => parseFloat(r[index]) || 0);
      const total = values.reduce((acc, el) => acc + el, 0);

      return {
        total: this.props.formatValue?.(total) || total,
        count: rowValues.length,
        avg: Math.round((total / values.length) * 100) / 100,
        median: getMedian(values),
        min: Math.min(...values),
        max: Math.max(...values),
        mode: getMode(values),
      };
    });

    return [
      this.renderRowFooter('Total', stats, 'total'),
      this.renderRowFooter('Count', stats, 'count'),
      this.renderRowFooter('Average', stats, 'avg'),
      this.renderRowFooter('Median', stats, 'median'),
      this.renderRowFooter('Min', stats, 'min'),
      this.renderRowFooter('Max', stats, 'max'),
      this.renderRowFooter('Mode', stats, 'mode'),
    ];
  };

  render() {
    if (this.props.source.slice(1).length === 0) {
      return <CardEmpty title="No results to Display" description="" />;
    }
    return (
      <div>
        <Container horizontal>
          <Actions>{this.props.actionsRenderer()}</Actions>
          <Actions>
            <Icon
              name="download"
              onClick={this.handleDownloadChart}
              tip="Export csv"
            />
            <Icon
              name="bars"
              onClick={this.handleSaveToCharts}
              tip={'Save to charts'}
            />
          </Actions>
        </Container>
        <br />
        <div style={this.style}>
          <TableComposition stickyHeader>
            <Table.Head>
              <Table.Cell />
              {this.props.source?.[0]?.slice(1).map((category, i) => (
                <Table.HCell
                  key={category}
                  text={category}
                  onClick={() => this.handleIgnoreCol(i + 1)}
                  style={
                    this.state.ignoredColumns.includes(i + 1)
                      ? this.disabledStyles
                      : undefined
                  }
                />
              ))}
            </Table.Head>
            <Table.Body>
              {this.props.source.slice(1).map((row, catIndex) => (
                <Table.Row
                  key={catIndex + 1}
                  style={
                    this.state.ignoredRows.indexOf(catIndex + 1) !== -1
                      ? this.disabledStyles
                      : undefined
                  }
                >
                  {row.map((column, columnIndex) => {
                    const colStyle =
                      this.state.ignoredColumns.indexOf(columnIndex) !== -1
                        ? this.disabledStyles
                        : undefined;
                    let value = column;
                    if (columnIndex !== 0 && value) {
                      value =
                        this.props.formatValue?.(value) ||
                        formatNumber?.(value) ||
                        value;
                    }
                    return columnIndex === 0 ? (
                      <Table.HCell
                        key={columnIndex}
                        text={value === null ? <EmptyValue /> : value}
                        onClick={() => this.handleIgnoreRow(catIndex + 1)}
                      />
                    ) : (
                      <Table.Cell
                        key={columnIndex}
                        text={
                          value === null || column === NO_ANSWER ? (
                            <EmptyValue />
                          ) : (
                            value
                          )
                        }
                        style={colStyle}
                      />
                    );
                  })}
                </Table.Row>
              ))}
              {this.renderBodyFooter()}
            </Table.Body>
          </TableComposition>
        </div>
      </div>
    );
  }
}
