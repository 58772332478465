import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import { confirm } from 'src/components/ConfirmModal/ConfirmModal';
import { Popover } from 'src/components/IMUI';

import { Icon } from 'im/ui/Icon';

import cls from './MoreAddAll.module.css';

const cx = classNames.bind(cls);

export class MoreAddAll extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    itemsToAddLen: PropTypes.number,
    itemsToRemoveLen: PropTypes.number,
    isFilterApplied: PropTypes.bool,
    onAddAll: PropTypes.func.isRequired,
    onRemoveAll: PropTypes.func.isRequired,
  };

  state = { popoverOpen: false };

  onPopoverOpen = (ev) => {
    ev?.preventDefault();
    this.setState({ popoverOpen: true });
  };

  onPopoverClose = () => {
    this.setState({ popoverOpen: false });
  };

  onConfirmationShow = (isAdd) => {
    const { itemsToAddLen, itemsToRemoveLen } = this.props;
    const itemsLen = isAdd ? itemsToAddLen : itemsToRemoveLen;
    const text = `Are you sure you want to ${
      isAdd ? 'add' : 'remove added'
    } ${itemsLen} item${itemsLen > 1 ? 's' : ''}?`;
    const title = `${isAdd ? 'Add' : 'Remove'} Items`;

    this.onPopoverClose();
    confirm(title, text, !isAdd)
      .then(() => (isAdd ? this.onAddAll() : this.onRemoveAll()))
      .catch(() => void 0);
  };

  onAddAll = () => {
    this.props.onAddAll();
  };

  onRemoveAll = () => {
    this.props.onRemoveAll();
  };

  render() {
    const { className, itemsToAddLen, itemsToRemoveLen, isFilterApplied } =
      this.props;

    return (
      <div
        className={cx('imui-more-add-all', className)}
        ref={(ref) => (this.innerRef = ref)}
      >
        <Icon
          tip="batch actions (add or remove all)"
          name="dot-dot-dot"
          onClick={this.onPopoverOpen}
        />
        <Popover
          small
          header={<Popover.Header text="Batch actions" />}
          open={this.state.popoverOpen}
          anchorEl={this.innerRef}
          style={{ minWidth: 100, maxWidth: 100 }}
          anchorOrigin={{ horizontal: 'middle', vertical: 'bottom' }}
          targetOrigin={{ horizontal: 'left', vertical: 'top' }}
          onRequestClose={this.onPopoverClose}
        >
          <Popover.List>
            <Popover.ListItem
              className={cx('imui-more-add-all-item-positive', {
                'imui-more-add-all-item-disabled': !itemsToAddLen,
              })}
              primaryText={`Add ${isFilterApplied ? 'Filtered' : 'All'}`}
              onClick={() => this.onConfirmationShow(true)}
            />
            <Popover.ListItem
              className={cx('imui-more-add-all-item-alert', {
                'imui-more-add-all-item-disabled': !itemsToRemoveLen,
              })}
              primaryText={`Remove ${isFilterApplied ? 'Filtered' : 'All'}`}
              onClick={() => this.onConfirmationShow()}
            />
          </Popover.List>
        </Popover>
      </div>
    );
  }
}

export default MoreAddAll;
