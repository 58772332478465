import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import Add from './Add';
import Fundings from './Fundings';
import Grantees from './Grantees';
import Import from './Import';
import Import2 from './Import2';
import FundingTable from './Table';
import UploadCsvPage from './UploadCsvPage';

export default class AppIndex extends React.PureComponent {
  static propTypes = { match: ReactRouterPropTypes.match };

  render() {
    return (
      <Switch>
        <Route
          path={`${this.props.match.path}/list`}
          component={FundingTable}
        />
        <Route
          path={`${this.props.match.path}/grantees`}
          component={Grantees}
        />
        <Route
          path={`${this.props.match.path}/fundings`}
          component={Fundings}
        />
        <Route path={`${this.props.match.path}/add`} component={Add} />
        <Route path={`${this.props.match.path}/import`} component={Import} />
        <Route path={`${this.props.match.path}/import2`} component={Import2} />
        <Route
          path={`${this.props.match.path}/upload`}
          component={UploadCsvPage}
        />
        <Redirect from="*" to={`${this.props.match.path}/list`} />
      </Switch>
    );
  }
}
