import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';

import FundingAdd from './FundingAdd';

export default class FundingsIndex extends Component {
  static propTypes = {
    match: ReactRouterPropTypes.match,
  };

  render() {
    const { match } = this.props;
    const rootPath = match.path;
    return (
      <Switch>
        <Route
          path={`${rootPath}/add/grant/:grantId/:quick?`}
          component={FundingAdd}
        />
        <Route
          path={`${rootPath}/add/grantee/:granteeId/:quick?`}
          component={FundingAdd}
        />
        <Route path={`${rootPath}/add`} component={FundingAdd} />
        <Redirect from="*" to="/grants" />
      </Switch>
    );
  }
}
