import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import cls from './SurveyContainer.module.css';

const cx = classNames.bind(cls);

// hasFooter class required for notificaitons to recognize positioning
const SurveyContainer = ({ children, className }) => (
  <div className={cx(cls.surveyContainer, 'hasFooter', className)}>
    {children}
  </div>
);

SurveyContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default SurveyContainer;
