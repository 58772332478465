import PropTypes from 'prop-types';
import React from 'react';
import { Field, FormSection } from 'redux-form';
import {
  TextField,
  FormField,
  ReduxFormField,
  ChipInput,
  Button,
  Container,
  Actions,
} from 'src/components/IMUI/index';
import { canManageOrganization } from 'src/userStorage';
import createValidator from 'src/utils/validation';

export function validateCustomRegion(values = {}) {
  const not = (fn) => (v) => !fn(v);
  const isDefined = (v) => Boolean(v);
  const longerThan = (n) => (v) => v.length > n;
  const validator = createValidator({
    name: [
      [not(isDefined), () => 'Name is required'],
      [not(longerThan(1)), () => 'Name is too short'],
    ],
    items: [
      [
        (v) => not(isDefined)(v) || not(longerThan(0))(v),
        () => 'Select min. 1 country',
      ],
    ],
  });
  return { current: validator(values.current || {}) };
}

export default class CustomRegionMappingItem extends React.PureComponent {
  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onSubmitFailed: PropTypes.func.isRequired,
    onResetValues: PropTypes.func.isRequired,
    countriesNames: PropTypes.array,
    isValid: PropTypes.bool,
  };

  componentWillUnmount() {
    this.props.onResetValues();
  }
  onBeforeRequestAdd = (countryName) => {
    this.props.countriesNames.includes(countryName);
  };
  onSave = (ev) => {
    ev.preventDefault();
    if (!this.props.isValid) {
      this.props.onSubmitFailed();
      return;
    }

    this.props.onSubmit();
  };

  render() {
    return (
      <FormSection name="current">
        <FormField>
          <Field
            fullWidth
            component={TextField}
            name="name"
            label="Name"
            hintText="Type in..."
          />
        </FormField>
        <FormField>
          <ReduxFormField
            type="array"
            component={ChipInput}
            label="Countries"
            name="items"
            hintText="Type in..."
            dataSource={this.props.countriesNames}
            onBeforeRequestAdd={this.onBeforeRequestAdd}
          />
        </FormField>

        <Container horizontal>
          <Actions>
            <Button negative label="Cancel" onClick={this.props.onCancel} />
          </Actions>
          <Actions>
            <Button
              disabled={!canManageOrganization()}
              label="Save"
              onClick={this.onSave}
            />
          </Actions>
        </Container>
      </FormSection>
    );
  }
}
