import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Checkbox, ListItemSimple } from '../IMUI';
import cls from './TagSelectorCategory.module.css';
const cx = classNames.bind(cls);

export default class TagSelectorCategory extends React.PureComponent {
  static propTypes = {
    category: PropTypes.object,
    isSelectable: PropTypes.bool,
    isSelected: PropTypes.bool,
    treeLevel: PropTypes.number,
    onClick: PropTypes.func.isRequired,
  };

  render() {
    const { category, isSelectable, isSelected, treeLevel } = this.props;
    return (
      <div style={{ marginLeft: 8 * treeLevel }}>
        {!isSelectable ? (
          <ListItemSimple className={cx('tagSelector-popover-tagGroup')}>
            {category.title}
          </ListItemSimple>
        ) : (
          <ListItemSimple
            className={cx('tagSelector-popover-selectable-tagGroup')}
            onClick={() => this.props.onClick(category)}
          >
            <Checkbox readOnly label={category.title} checked={isSelected} />
          </ListItemSimple>
        )}
      </div>
    );
  }
}
