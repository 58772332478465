/* eslint-disable */
import axios from 'axios';
import {
  GEO_LOCATION__COUNTRIES_GET_REQUEST,
  GEO_LOCATION__COUNTRIES_GET_SUCCESS,
  GEO_LOCATION__COUNTRIES_GET_FAILURE,
  GEO_LOCATION__STATES_GET_REQUEST,
  GEO_LOCATION__STATES_GET_SUCCESS,
  GEO_LOCATION__STATES_GET_FAILURE,
  GEO_LOCATION__CITIES_GET_REQUEST,
  GEO_LOCATION__CITIES_GET_SUCCESS,
  GEO_LOCATION__CITIES_GET_FAILURE,
} from 'src/constants';
const GEO_USERNAME = 'impactmapper';
const safeParams = (url) =>
  `${url}username=${GEO_USERNAME}&maxRows=100&lang=en`;

const doGetCountries = () =>
  axios
    .get(safeParams(`https://secure.geonames.org/countryInfoJSON?`))
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
export const getAllCountries = () => async (dispatch) => {
  dispatch({ type: GEO_LOCATION__COUNTRIES_GET_REQUEST });
  const { response, error } = await doGetCountries();
  response
    ? dispatch({ type: GEO_LOCATION__COUNTRIES_GET_SUCCESS, payload: response })
    : dispatch({
        type: GEO_LOCATION__COUNTRIES_GET_FAILURE,
        payload: error,
        error: true,
      });
};

export const getCountryStates = (countryCode) => async (dispatch) => {
  dispatch({ type: GEO_LOCATION__STATES_GET_REQUEST });
  const { response, error } = await (() =>
    axios
      .get(
        safeParams(
          `https://secure.geonames.org/searchJSON?q=&country=${countryCode}&featureCode=ADM1&`
        )
      )
      .then((response) =>
        response.data?.geonames
          ? { response }
          : Promise.reject({ error: 'Webservice error' })
      )
      .catch((error) => ({ error })))();
  if (response) {
    dispatch({
      type: GEO_LOCATION__STATES_GET_SUCCESS,
      payload: { countryCode, data: response.data },
    });
    return response;
  }
  dispatch({
    type: GEO_LOCATION__STATES_GET_FAILURE,
    payload: error,
    error: true,
  });
};

export const getCountryStateCities =
  (countryCode, stateCode, cityQuery) => async (dispatch) => {
    dispatch({ type: GEO_LOCATION__CITIES_GET_REQUEST });
    const { response, error } = await (() =>
      axios
        .get(
          safeParams(
            true &&
              `https://secure.geonames.org/searchJSON?q=&name_startsWith=${encodeURIComponent(
                cityQuery || ''
              )}&country=${countryCode}&adminCode1=${
                stateCode || ''
              }&featureCode=PPLC&featureCode=PPLA&featureCode=PPLA2&featureCode=PPLA3&featureCode=PPLA4&featureCode=PPL&`
          )
        )
        .then((response) =>
          response.data?.geonames
            ? { response }
            : Promise.reject({ error: 'Webservice error' })
        )
        .catch((error) => ({ error })))();
    if (response) {
      dispatch({
        type: GEO_LOCATION__CITIES_GET_SUCCESS,
        payload: { stateCode, countryCode, cityQuery, data: response.data },
      });
      return response.data;
    }
    dispatch({
      type: GEO_LOCATION__CITIES_GET_FAILURE,
      payload: error,
      error: true,
    });
  };
