import PropTypes from 'prop-types';
import React from 'react';

import cls from './EditorMinimap.module.css';

function percent(n, total) {
  return `${(n / total) * 100}%`;
}

export default class EditorMiniMap extends React.PureComponent {
  static propTypes = {
    marks: PropTypes.array,
    onScrollTo: PropTypes.func.isRequired,
  };

  get editorScrollHeight() {
    return window.quill?.scrollingContainer?.scrollHeight ?? 0;
  }
  onClickMinimap = (ev) => {
    const rect = ev?.currentTarget?.getBoundingClientRect() ?? {};
    this.props.onScrollTo(
      ((ev?.pageY - rect.top) * this.editorScrollHeight) / rect.height -
        rect.height / 2
    );
  };
  render() {
    return (
      <div className={cls.wrapper} onClick={this.onClickMinimap}>
        {this.props.marks?.map((mark, i) => (
          <div
            key={i}
            className={cls.mark}
            style={{
              top: percent(mark.offsetTop, this.editorScrollHeight),
              background: mark.color,
            }}
          />
        ))}
      </div>
    );
  }
}
