import { isGetMethod } from '../helpers/string';
import { removeSharedProps } from '../helpers/utils';

export const handleUpdateQuery = (action, state) => {
  const queryParams = state.meta.getAction?.queryParams || {};
  const qp = action.payload.queryParams;
  return {
    ...state,
    meta: {
      ...state.meta,
      getAction: {
        ...state.meta.getAction,
        queryParams: action.meta?.doDelete
          ? removeSharedProps(queryParams, qp)
          : Object.keys(qp).reduce(
              (acc, key) => ({
                ...acc,
                [key]:
                  typeof qp[key] === 'object' && !Array.isArray(qp[key])
                    ? { ...queryParams[key], ...qp[key] }
                    : qp[key],
              }),
              queryParams
            ),
      },
    },
  };
};
export const handleRequest = (state) => state;
export const handleFailure = (action, state) => ({
  ...state,
  errors: (action.payload.response || {}).errors,
});
export const handleSuccess = (action, state) =>
  isGetMethod(action.meta?.method)
    ? {
        ...state,
        errors: null,
        data: action.payload.data,
        links: action.payload.links,
        meta: {
          ...(action.meta?.derived
            ? { ...action.meta?.derived.call(this, action.payload.data) }
            : {}),
          getAction: {
            urlParams: action.meta?.urlParams,
            queryParams: action.meta?.queryParams,
          },
          includeMapper: action.meta?.includeMapper,
        },
      }
    : state;
