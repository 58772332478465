import PropTypes from 'prop-types';
import React from 'react';

import DataFilter from 'src/components/DataFilter/DataFilter';

export default class GlobalMapFilters extends React.Component {
  static propTypes = {
    data: PropTypes.array,
    onChange: PropTypes.func,
    onPopoverClose: PropTypes.func,
  };

  label = 'Filter the map view';

  filtersConfig = [
    {
      path: 'donor_list',
      label: 'Donors',
    },
    {
      path: 'issue_list',
      label: 'Issues',
    },
    {
      path: 'portfolio_list',
      label: 'Portfolio',
    },
    {
      path: 'region',
      label: 'Region',
    },
    {
      path: 'strategies_list',
      label: 'Strategy',
    },
  ];

  render() {
    const { data, onChange, onPopoverClose } = this.props;
    return (
      <DataFilter
        config={this.filtersConfig}
        label={this.label}
        data={data}
        onChange={onChange}
        onPopoverClose={onPopoverClose}
      />
    );
  }
}
