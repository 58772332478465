import { StepsTheme } from 'chakra-ui-steps';

const globalStyles = {
  global: {
    '.rdg': {
      fontFamily: 'averta',
    },
    '.rdg-cell': {
      fontFamily: 'averta',
    },
    '.chakra-switch': {
      fontFamily: 'averta',
    },
    '.chakra-button': {
      fontFamily: 'averta',
    },
  },
};
const uploadStep = {
  UploadStep: {
    baseStyle: {
      heading: {
        fontFamily: 'averta',
      },
      title: {
        fontFamily: 'averta',
      },
      subtitle: {
        fontFamily: 'averta',
      },
      tableWrapper: {
        fontFamily: 'averta',
      },
      dropzoneText: {
        fontFamily: 'averta',
      },
      dropzoneButton: {
        fontFamily: 'averta',
      },
    },
  },
};

const selectSheetStep = {
  SelectSheetStep: {
    baseStyle: {
      heading: {
        fontFamily: 'averta',
      },
      radioLabel: {
        fontFamily: 'averta',
      },
    },
  },
};

const selectHeaderStep = {
  SelectHeaderStep: {
    baseStyle: {
      heading: {
        fontFamily: 'averta',
      },
    },
  },
};

const matchColumnStep = {
  MatchColumnsStep: {
    baseStyle: {
      heading: {
        fontFamily: 'averta',
      },
      title: {
        fontFamily: 'averta',
      },
      userTable: {
        header: {
          fontFamily: 'averta',
        },
        cell: {
          fontFamily: 'averta',
        },
        ignoreButton: {
          fontFamily: 'averta',
        },
      },
      selectColumn: {
        text: {
          fontFamily: 'averta',
        },
        accordionLabel: {
          fontFamily: 'averta',
        },
        selectLabel: {
          fontFamily: 'averta',
        },
      },
      select: {
        control: (provided) => ({
          ...provided,
          fontFamily: 'averta',
        }),
        menu: (provided) => ({
          ...provided,
          fontFamily: 'averta',
        }),
        menuList: (provided) => ({
          ...provided,
          fontFamily: 'averta',
        }),
        placeholder: (provided) => ({
          ...provided,
          fontFamily: 'averta',
        }),
        option: (provided) => ({
          ...provided,
          fontFamily: 'averta',
        }),
        noOptionsMessage: (provided) => ({
          ...provided,
          fontFamily: 'averta',
        }),
      },
    },
  },
};

const validationStep = {
  ValidationStep: {
    baseStyle: {
      heading: {
        fontFamily: 'averta',
      },
      select: {
        valueContainer: (provided) => ({
          ...provided,
          fontFamily: 'averta',
        }),
        inputContainer: (provided) => ({
          ...provided,
          fontFamily: 'averta',
        }),
        control: (provided) => ({ ...provided, fontFamily: 'averta' }),
        input: (provided) => ({ ...provided, fontFamily: 'averta' }),
        menu: (provided) => ({
          ...provided,
          fontFamily: 'averta',
        }),
        menuList: (provided) => ({
          ...provided,
          fontFamily: 'averta',
        }),
        option: (provided) => ({
          ...provided,
          fontFamily: 'averta',
        }),
        noOptionsMessage: (provided) => ({
          ...provided,
          fontFamily: 'averta',
        }),
      },
    },
  },
};

const modalStep = {
  Modal: {
    baseStyle: {
      header: {
        fontFamily: 'averta',
      },
      backButton: {
        fontFamily: 'averta',
      },
      continueButton: {
        fontFamily: 'averta',
      },
    },
  },
};

const steps = {
  Steps: {
    baseStyle: {
      ...StepsTheme.baseStyle({}),
      fontFamily: 'averta',
      stepContainer: {
        ...StepsTheme.baseStyle({}).stepContainer,
        fontFamily: 'averta',
      },
      iconLabel: {
        ...StepsTheme.baseStyle({}).iconLabel,
        fontFamily: 'averta',
      },
      label: {
        ...StepsTheme.baseStyle({}).label,
        fontFamily: 'averta',
      },
    },
  },
};

const colors = {
  rsi: {
    500: '#52cccc',
    600: '#36b5b5',
    700: '#52cccc',
  },
  red: {
    500: '#fa3e3e',
    600: '#f82020',
  },
};

const importerTheme = {
  styles: {
    ...globalStyles,
  },
  components: {
    ...uploadStep,
    ...selectSheetStep,
    ...selectHeaderStep,
    ...matchColumnStep,
    ...validationStep,
    ...steps,
  },
  colors: {
    ...colors,
  },
};

export default importerTheme;
