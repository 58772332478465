import {
  SURVEYS__GET_SUCCESS,
  SURVEYS__GET_REQUEST,
  SURVEYS__GET_FAILURE,
  SURVEY__DELETE_SUCCESS,
} from 'src/constants';
import { toListSurveyFromApi } from 'src/serializers/surveySerializer';

const initialState = {
  pending: false,
  data: [],
};

export default function surveysReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case SURVEYS__GET_REQUEST:
      return { ...state, pending: true };
    case SURVEYS__GET_FAILURE:
      return { ...state, pending: false };
    case SURVEYS__GET_SUCCESS:
      return {
        ...state,
        pending: false,
        data: payload.data
          .map(toListSurveyFromApi)
          .sort((s1, s2) => s2.id - s1.id),
      };
    case SURVEY__DELETE_SUCCESS:
      return {
        ...state,
        data: state.data.filter((s) => s.id !== payload.id),
      };
    default:
      return state;
  }
}
