class StandardSyncActions {
  actionTypes = {
    clear: 'clear',
  };

  action = (type, payload) => {
    return (dispatch) =>
      dispatch({
        type,
        payload,
      });
  };

  getActions = () => {
    return Object.values(this.actionTypes).reduce(
      (acc, type) => ({
        [type]: this.action,
      }),
      {}
    );
  };
}

export default new StandardSyncActions();
