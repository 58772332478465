import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import cls from './Divider.module.css';
const cx = classNames.bind(cls);

export const Divider = ({ style, className, compact, small }) => (
  <div
    className={cx(
      'imui-divider',
      { 'imui-divider-compact': !!compact, 'imui-divider-small': !!small },
      className
    )}
    style={style}
  />
);
Divider.propTypes = {
  compact: PropTypes.bool,
  small: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
};
export default Divider;
