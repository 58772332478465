import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import { Button } from 'src/components/IMUI';
import { InputSticky } from 'src/components/IMUI/Forms/base';

import cls from './ButtonSticky.module.css';

const cx = classNames.bind(cls);

export const ButtonSticky = ({ onClick, className, stick, ...rest }) => (
  <Button
    className={cx(
      'imui-button-sticky',
      {
        [`imui-button-sticky-${stick}`]: stick && stick.length,
      },
      className
    )}
    onClick={onClick}
    {...rest}
  />
);

ButtonSticky.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  ...InputSticky.propTypes,
};

export default ButtonSticky;
