import reducerRegistry, { advancedFiltersRegistry } from 'im/api/registry';
import ResourceCollection from 'im/api/ResourceCollection';

const apiConfig = {
  root: '/v3/surveys/:survey_id/respondents',
  type: 'respondents',
  api: {
    allow: ['findAll', 'create', 'put', 'destroy'],
    custom: {
      deliver: {
        path: ':id/deliver',
        method: 'POST',
      },
      remind: {
        path: ':id/remind',
        method: 'POST',
      },
    },
  },
};

export const SurveyRespondents = new ResourceCollection(
  apiConfig,
  'surveyRespondents'
);
reducerRegistry.register(SurveyRespondents);

const advancedFiltersConfig = {
  actionPrefix: 'surveyRespondentsAF',
  allow: ['name', 'email'],
  // grants and grantees included by default
  grant_name: {
    // root: "",
    // path: where().filter("organization_id_eq", ":id").toString(),
    type: 'grant',
  },
  grantee_name: { type: 'grantee' },
};

advancedFiltersRegistry.register(SurveyRespondents, advancedFiltersConfig);

export const surveyRespondentsGetAdvancedFilters =
  SurveyRespondents.advancedFilters;

export default SurveyRespondents.api;
