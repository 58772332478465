import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import { Icon } from 'im/ui/Icon';

import cls from './ClearButton.module.css';

const cx = classNames.bind(cls);

export const ClearButton = ({ visible, onClear, spaceRight, className }) => {
  return !visible ? null : (
    <div
      className={cx(
        'imui-clear-button-wrapper',
        { 'imui-clear-button-wrapper-space-right': spaceRight },
        className
      )}
    >
      <div role="button" className={cx('imui-clear-button')} onClick={onClear}>
        <Icon name="close" />
      </div>
    </div>
  );
};

ClearButton.propTypes = {
  visible: PropTypes.bool,
  spaceRight: PropTypes.bool,
  className: PropTypes.string,
  onClear: PropTypes.func.isRequired,
};

export default ClearButton;
