import { getProject } from 'src/actionCreators/projectActionCreators';
import { TagCategoriesProject } from 'src/api/TagCategories';
import { TagCategoryTags } from 'src/api/TagCategoryTags';
import tagsApi, { TagsOfProject } from 'src/api/Tags';

export default function tagCategoryEffects(_state, action, dispatch) {
  const project = _state.project;
  let projectTagCategoryIds = project.enabled_tag_categories;

  switch (action.type) {
    // Update project.enabled_tag_categories
    case TagCategoriesProject.actionTypes.create.success:
    case TagCategoriesProject.actionTypes.destroy.success: {
      dispatch(getProject(project.id));

      // Update recent project tags
      if (action.type === TagCategoriesProject.actionTypes.destroy.success) {
        const tagCategoryId = action.meta.urlParams.id;
        projectTagCategoryIds = !projectTagCategoryIds.includes(tagCategoryId)
          ? projectTagCategoryIds
          : projectTagCategoryIds.filter((id) => id !== tagCategoryId);

        dispatch(tagsApi.ofProjectRecent.findAll({ projectTagCategoryIds }));
      }

      break;
    }

    case TagCategoryTags.actionTypes.create.success: {
      const data = action.payload.data;
      const currentTagCategoryId = data.tag_category.id;

      if (!projectTagCategoryIds.includes(currentTagCategoryId)) {
        projectTagCategoryIds = projectTagCategoryIds.concat([
          currentTagCategoryId,
        ]);
      }

      dispatch(tagsApi.ofProjectRecent.findAll({ projectTagCategoryIds }));
      break;
    }

    // Update recent project tags
    case TagsOfProject.actionTypes.put.success:
    case TagsOfProject.actionTypes.destroy.success:
    case TagCategoriesProject.actionTypes.put.success:
      dispatch(tagsApi.ofProjectRecent.findAll({ projectTagCategoryIds }));
      break;

    default:
      break;
  }
}
