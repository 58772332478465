import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import { connect } from 'react-redux';
import { submit, destroy } from 'redux-form';
import { getGrantees } from 'src/actionCreators/granteesActionCreators';
import { createGrant } from 'src/actionCreators/grantsActionCreators';
import { getGrantsFilters } from 'src/actionCreators/grantsFiltersActionCreators';
import { getRegions } from 'src/actionCreators/regionsActionCreators';
import { Actions, Section, Container, Button } from 'src/components/IMUI';
import Page from 'src/components/Page/Page';
import history from 'src/historyInstance';
import { getOrgText } from 'src/services/DictionaryService';
import GrantStepOne from 'src/pages/App/Grants/Add/components/GrantStepOne';
import cls from './GrantAddSteps.module.css';
import { canManageGrants } from 'src/userStorage';
const cx = classNames.bind(cls);

@connect(
  pick([
    'defaultRegions',
    'grantsFilters',
    'grantees',
    'user',
    'organizationCurrent',
  ]),
  {
    getRegions,
    getGrantsFilters,
    createGrant,
    getGrantees,
    submitForm: submit,
    destroyForm: destroy,
  }
)
export default class GrantAddSteps extends React.PureComponent {
  static propTypes = {
    getGrantees: PropTypes.func.isRequired,
    getGrantsFilters: PropTypes.func.isRequired,
    getRegions: PropTypes.func.isRequired,
    createGrant: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    grantsFilters: PropTypes.object,
    organizationCurrent: PropTypes.object,
    grantees: PropTypes.object,
    defaultRegions: PropTypes.array,
  };

  static defaultProps = { organizationCurrent: { data: {} } };
  componentDidMount() {
    this.props.getGrantsFilters();
    this.props.getGrantees();
    this.props.getRegions();
  }

  onClickBack = () => {
    history.goBack();
  };
  onClickSubmit = () => {
    this.props.submitForm('newGrant');
  };
  handleSubmit = (data) => {
    const { fundings, locations, funding_type, ...grantDetails } = data;
    this.props
      .createGrant({
        fundings: fundings?.map((f) => ({ ...f, type: funding_type })),
        grant_details: { ...grantDetails, locations },
      })
      .then((grant) => {
        history.push(`/grants/list/grant/${grant.id}`);
      })
      .catch((e) => {
        history.goBack();
        console.log(e);
      });
  };

  render() {
    return (
      <Page
        back
        hasFooter
        title={`Add ${getOrgText('Grant')}`}
        id={`scrollContainer_${cx('grantAddSection')}`}
      >
        <Section collapsed className={cls.grantAddSection}>
          <div className={cls.grantAddCentered}>
            <GrantStepOne
              organization={this.props.organizationCurrent.data}
              grantees={this.props.grantees}
              fundingTypes={this.props.organizationCurrent.data.funding_types}
              defaultRegions={this.props.defaultRegions}
              possibleValues={this.props.grantsFilters}
              customGrantFields={
                this.props.organizationCurrent.data.custom_grant_fields
              }
              onSubmit={this.handleSubmit}
            />
          </div>
        </Section>
        <Section type="sticky-footer">
          <Container horizontal>
            <Actions>
              <Button size="l" label="BACK" onClick={this.onClickBack} />
            </Actions>
            <Actions>
              <Button
                disabled={!canManageGrants()}
                size="l"
                label="CREATE GRANT"
                onClick={this.onClickSubmit}
              />
            </Actions>
          </Container>
        </Section>
      </Page>
    );
  }
}
