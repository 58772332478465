import reducerRegistry from 'im/api/registry';
import ResourceCollection from 'im/api/ResourceCollection';

const apiConfig = {
  root: '/v3',
  type: 'surveys',
  api: {
    findAll: {
      path: 'surveys',
    },
    destroy: {
      path: 'surveys/:id',
    },
  },
};

export const SurveysList = new ResourceCollection(apiConfig, 'surveysList');
reducerRegistry.register(SurveysList);

export default SurveysList.api;
