import React from 'react';
import { Link } from 'react-router-dom';

import logo from './impact-logo.svg';

import cls from './Logo.module.css';

const Logo = () => (
  <Link to="/" className={cls.logo}>
    <img src={logo} height={30} alt="Impact Mapper" />
  </Link>
);

export default Logo;
