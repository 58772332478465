import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getGrantees } from 'src/actionCreators/granteesActionCreators';
import { Dialog, Button } from 'src/components/IMUI/index';
import { getOrgText } from 'src/services/DictionaryService';
import { getIntersected } from 'src/utils/customFields';
import GranteeForm from '../../components/GranteeForm';
import { canManageGrants } from 'src/userStorage';

@connect(
  (state) => ({
    organizationCurrent: state.organizationCurrent,
    defaultRegions: state.defaultRegions,
    grantees: state.grantees,
  }),
  { getGrantees }
)
export default class EditDialogsGrantee extends React.PureComponent {
  static propTypes = {
    organizationCurrent: PropTypes.object,
    grantee: PropTypes.object,
    defaultRegions: PropTypes.array,
    grantees: PropTypes.object,
    getGrantees: PropTypes.func,
    onCloseModal: PropTypes.func.isRequired,
    onSubmitSuccessful: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.getGrantees();
  }
  render() {
    const { organizationCurrent, defaultRegions, grantees, grantee } =
      this.props;
    const initialValues = {
      ...grantee,
      region_id: (
        defaultRegions.find(({ name }) => name === grantee.region) || {}
      ).id,
    };

    return (
      <Dialog
        open
        extraLarge
        title={getOrgText('Edit grantee')}
        onRequestClose={this.props.onCloseModal}
        leftActions={
          <Button
            negative
            size="l"
            type="button"
            label="Cancel"
            onClick={this.props.onCloseModal}
          />
        }
        rightActions={
          <Button
            disabled={!canManageGrants()}
            size="l"
            key="save"
            label="Update"
            type="submit"
            form="newGrantee"
          />
        }
      >
        <GranteeForm
          isEdit
          allGrantees={grantees.items.filter(
            ({ type }) => type === grantee.type
          )}
          type={grantee.type}
          initialValues={initialValues}
          customFields={getIntersected(
            organizationCurrent.data.custom_grantee_fields,
            grantee.custom_fields
          )}
          regions={defaultRegions}
          customRegionMapping={organizationCurrent.data.custom_region_mapping}
          onCancel={this.props.onCloseModal}
          onSubmitSuccessful={this.props.onSubmitSuccessful}
        />
      </Dialog>
    );
  }
}
