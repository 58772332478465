import axios from 'src/axiosInstance';

export const SURVEY_ANSWERS_GET_REQUEST = 'SURVEY_ANSWERS_GET_REQUEST';
export const SURVEY_ANSWERS_GET_SUCCESS = 'SURVEY_ANSWERS_GET_SUCCESS';
export const SURVEY_ANSWERS_GET_FAILURE = 'SURVEY_ANSWERS_GET_FAILURE';
export const SURVEY_ANSWERS_CACHE_CLEAR = 'SURVEY_ANSWERS_CACHE_CLEAR';

const CACHE_LIFETIME = 1000 * 60 * 10; // 10 minutes?

export const getAttachment = (uuid) =>
  axios
    .get(`/api/v3/import_uploads/${uuid}`)
    .then((response) => response.data?.data?.attributes?.url)
    .catch((error) => ({ error }));

const doGetSurveyAnswers = (questionId, pageSize = 6000) =>
  axios
    .get(
      `/v3/survey_answers/?filter[survey_question_id_eq]=${questionId}&page[size]=${pageSize}`
    )
    .then((response) => ({ response }))
    .catch((error) => ({ error }));

export const getSurveyAnswers =
  (questionId, options = {}) =>
  async (dispatch, getState) => {
    const cache = getState().cachedSurveyAnswers[questionId];
    const expired = Date.now() - cache?.created_at > CACHE_LIFETIME;
    const shouldRefetch = options.force || expired || !cache?.created_at;
    if (!shouldRefetch) return cache;

    const silent = expired || options.silent;
    const meta = { silent, questionId, timestamp: Date.now() };

    if (!silent) dispatch({ type: SURVEY_ANSWERS_GET_REQUEST, meta });
    const { response, error } =
      (await doGetSurveyAnswers(questionId, options.pageSize)) ?? {};

    return response
      ? dispatch({
          type: SURVEY_ANSWERS_GET_SUCCESS,
          payload: response,
          error: false,
          meta,
        })
      : dispatch({
          type: SURVEY_ANSWERS_GET_FAILURE,
          payload: error,
          error: true,
          meta,
        });
  };

export const clearCache = () => ({ type: SURVEY_ANSWERS_CACHE_CLEAR });
