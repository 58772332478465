import axios from 'src/axiosInstance';
import {
  REPORTS__IDS_GET_REQUEST,
  REPORTS__IDS_GET_SUCCESS,
  REPORTS__IDS_GET_FAILURE,
  REPORTS__TRINT_DOCUMENTS_GET_REQUEST,
  REPORTS__TRINT_DOCUMENTS_GET_SUCCESS,
  REPORTS__TRINT_DOCUMENTS_GET_FAILURE,
  REPORTS__IMPORT_TRINT_DOCUMENTS_REQUEST,
  REPORTS__IMPORT_TRINT_DOCUMENTS_SUCCESS,
  REPORTS__IMPORT_TRINT_DOCUMENTS_FAILURE,
  REPORT__GET_REQUEST,
  REPORT__GET_SUCCESS,
  REPORT__GET_FAILURE,
  REPORT__UPDATE_REQUEST,
  REPORT__UPDATE_SUCCESS,
  REPORT__UPDATE_FAILURE,
  REPORT__UPLOAD_REQUEST,
  REPORT__UPLOAD_SUCCESS,
  REPORT__UPLOAD_FAILURE,
  REPORT__EDIT_REQUEST,
  REPORT__EDIT_SUCCESS,
  REPORT__EDIT_FAILURE,
} from 'src/constants';

const doGetReportsIds = () =>
  axios
    .get('/v1/reports/ids')
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
const doGetTrintDocuments = () =>
  axios
    .get('/v1/reports/trint_documents')
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
const doImportTrintDocuments = (payload) =>
  axios
    .post('/v1/reports/trint_import', payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
const doGetReport = (projectId, projectReportId) =>
  axios
    .get(`/v1/projects/${projectId}/reports/${projectReportId}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
const doUpdateReport = (projectId, projectReportId, payload) =>
  axios
    .put(`/v1/projects/${projectId}/reports/${projectReportId}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
const doCreateReport = (payload) =>
  axios
    .post('/v1/reports', payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
const doEditReport = (reportId, payload) =>
  axios
    .put(`/v1/reports/${reportId}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));

export const getReportsIds = () => async (dispatch) => {
  dispatch({ type: REPORTS__IDS_GET_REQUEST });
  const { response, error } = await doGetReportsIds();
  response
    ? dispatch({ type: REPORTS__IDS_GET_SUCCESS, payload: response })
    : dispatch({ type: REPORTS__IDS_GET_FAILURE, payload: error, error: true });
  return response;
};

export const getTrintDocuments = () => async (dispatch) => {
  dispatch({ type: REPORTS__TRINT_DOCUMENTS_GET_REQUEST });
  const { response, error } = await doGetTrintDocuments();
  response
    ? dispatch({
        type: REPORTS__TRINT_DOCUMENTS_GET_SUCCESS,
        payload: response,
      })
    : dispatch({
        type: REPORTS__TRINT_DOCUMENTS_GET_FAILURE,
        payload: error,
        error: true,
      });
  return response;
};

export const importTrintDocuments = (payload) => async (dispatch) => {
  dispatch({ type: REPORTS__IMPORT_TRINT_DOCUMENTS_REQUEST });
  const { response, error } = await doImportTrintDocuments(payload);
  response
    ? dispatch({
        type: REPORTS__IMPORT_TRINT_DOCUMENTS_SUCCESS,
        payload: response,
      })
    : dispatch({
        type: REPORTS__IMPORT_TRINT_DOCUMENTS_FAILURE,
        payload: error,
        error: true,
      });
  return response;
};

export const getReport = (projectId, projectReportId) => async (dispatch) => {
  dispatch({ type: REPORT__GET_REQUEST, meta: { projectId, projectReportId } });
  const { response, error } = await doGetReport(projectId, projectReportId);
  response
    ? dispatch({ type: REPORT__GET_SUCCESS, payload: response })
    : dispatch({ type: REPORT__GET_FAILURE, payload: error, error: true });
  return response;
};

export const updateReport =
  (projectId, projectReportId, payload) => async (dispatch) => {
    dispatch({ type: REPORT__UPDATE_REQUEST });
    const { response, error } = await doUpdateReport(
      projectId,
      projectReportId,
      payload
    );
    response
      ? dispatch({
          type: REPORT__UPDATE_SUCCESS,
          payload: { projectId, response },
        })
      : dispatch({ type: REPORT__UPDATE_FAILURE, payload: error, error: true });
    return response;
  };

export const createReport = (payload) => async (dispatch) => {
  dispatch({ type: REPORT__UPLOAD_REQUEST });
  const { response, error } = await doCreateReport(payload);
  if (response) {
    dispatch({ type: REPORT__UPLOAD_SUCCESS, payload: response });
    return response.data;
  }
  return dispatch({
    type: REPORT__UPLOAD_FAILURE,
    payload: error,
    error: true,
  });
};

export const editReport = (reportId, payload) => async (dispatch) => {
  dispatch({ type: REPORT__EDIT_REQUEST });
  const { response, error } = await doEditReport(reportId, payload);
  response
    ? dispatch({ type: REPORT__EDIT_SUCCESS, payload: response })
    : dispatch({ type: REPORT__EDIT_FAILURE, payload: error, error: true });
  return response;
};
