const importerTheme = {
  matchColumnsStep: {
    userTableTitle: 'Your data',
  },
  validationStep: {
    filterSwitchTitle: 'Show rows with errors only',
  },
};

export default importerTheme;
