import clone from 'ramda/src/clone';
import lensPath from 'ramda/src/lensPath';
import prop from 'ramda/src/prop';
import set from 'ramda/src/set';
import uniqBy from 'ramda/src/uniqBy';

import {
  GEO_LOCATION__COUNTRIES_GET_SUCCESS,
  GEO_LOCATION__COUNTRIES_GET_FAILURE,
  GEO_LOCATION__STATES_GET_SUCCESS,
  GEO_LOCATION__STATES_GET_FAILURE,
  GEO_LOCATION__CITIES_GET_SUCCESS,
  GEO_LOCATION__CITIES_GET_FAILURE,
} from 'src/constants';

const initialState = {
  countries: [],
  states: {},
  cities: {},
};

const MAX_RESULTS = 60;
const toShortObject = ({ geonameId: id, toponymName: name, adminCode1 }) => ({
  id,
  name,
  adminCode1,
});
const byName = ({ name: name1 }, { name: name2 }) => name1.localeCompare(name2);

function updateCountries(state, payload) {
  const {
    data: { geonames },
  } = payload;
  const countries = (geonames || [])
    .map(({ countryCode: code, countryName: name }) => ({ code, name }))
    .sort(byName);
  return { ...state, countries };
}

function updateStates(state, payload) {
  const {
    countryCode,
    data: { geonames, totalResultsCount },
  } = payload;

  if (!countryCode) {
    return state;
  }

  const newStates = clone(state.states);
  const thatState = geonames.map(toShortObject).sort(byName);

  newStates[countryCode] = {
    items: thatState,
    total: totalResultsCount <= MAX_RESULTS,
  };
  return { ...state, states: newStates };
}

function updateCities(state, payload) {
  const {
    countryCode,
    cityQuery,
    stateCode,
    data: { geonames, totalResultsCount },
  } = payload;
  const xLens = lensPath([countryCode, stateCode || '0', cityQuery]);

  if (!countryCode || !cityQuery) {
    return state;
  }

  const cities = uniqBy(
    prop('nameAndState'),
    (geonames || [])
      .map(({ adminCode1, geonameId: id, toponymName: name }) => {
        const statesOfCountry = (state.states[countryCode] || {}).items || [];
        const stateOfCity = statesOfCountry.find(
          (s) => s.adminCode1 === adminCode1
        );

        return {
          adminCode1,
          id,
          name,
          nameAndState: stateOfCity ? `${name}, ${stateOfCity.name}` : name,
        };
      })
      .sort(byName)
  );

  const thatCity = { items: cities, total: totalResultsCount <= MAX_RESULTS };
  const newCities = set(xLens, thatCity, state.cities);
  return { ...state, cities: newCities };
}

export default function geoLocationReducer(state = initialState, action) {
  switch (action.type) {
    case GEO_LOCATION__COUNTRIES_GET_SUCCESS:
      return updateCountries(state, action.payload);
    case GEO_LOCATION__COUNTRIES_GET_FAILURE:
      return { ...state, countries: [] };

    case GEO_LOCATION__STATES_GET_SUCCESS:
      return updateStates(state, action.payload);
    case GEO_LOCATION__STATES_GET_FAILURE:
      return state;

    case GEO_LOCATION__CITIES_GET_SUCCESS:
      return updateCities(state, action.payload);
    case GEO_LOCATION__CITIES_GET_FAILURE:
      return state;

    default:
      return state;
  }
}
