import AdvancedFilters from '../entities/advancedFilters/AdvancedFilters';

export class AdvancedFiltersRegistry {
  constructor() {
    this._reducers = {};
  }

  get reducers() {
    return this._reducers;
  }

  get hasReducers() {
    return !!Object.keys(this._reducers).length;
  }

  register = (resource, config) => {
    const AdvancedFilter = new AdvancedFilters(config, resource);
    /* eslint-disable no-param-reassign */
    resource.advancedFilters = AdvancedFilter.actions;
    /* eslint-enable no-param-reassign */

    this._reducers = {
      ...this._reducers,
      [resource.reducerName]: AdvancedFilter.makeReducer(),
    };
  };
}

export default new AdvancedFiltersRegistry();
