const encodedColon = encodeURIComponent(':');

export const applyPlaceholders = (text, placeholders) => {
  if (!placeholders) {
    return text;
  }

  return Object.keys(placeholders).reduce((acc, key) => {
    const token = new RegExp(`:${key}|${encodedColon}${key}`, 'g');

    return acc.match(token)
      ? acc.replace(token, encodeURIComponent(placeholders[key]))
      : acc;
  }, text);
};
