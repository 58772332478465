import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import taggingsExportApi from 'src/api/TaggingsExport';
import taggingsApi, {
  taggingsAdvancedFilters,
  TAGGING_AUTO_POPULATION_FIELDS,
  TAGGING_FILTERS,
} from 'src/api/Taggings';
import { getCountryNameByCode } from 'src/utils/countries';
import tagsApi from 'src/api/Tags';
import ExportConfirmation from 'src/components/ExportConfirmation/ExportConfirmation';
import {
  Card,
  Container,
  Section,
  CardFooter,
  FilterJsonapi,
  FilterTrigger,
  Actions,
  Button,
} from 'src/components/IMUI';
import PaginationIm from 'src/components/IMUI/Pagination/PaginationIm';
import { isGrantless } from 'src/utils/analysis';
import { where } from 'im/api/Query';
import TagTable from './components/TagTable';

const TAGGING_HIDDEN_FILTER = {
  key: 'report_grantee_name_or_survey_answer_grantee_name_present',
  value: true,
};
@connect(
  (state) => ({
    taggings: state.taggings,
    tags: state.tags,
    storage: state.storage,
    project: state.project,
    advancedFilters: state.advancedFilters.taggings,
  }),
  {
    getTaggings: taggingsApi.findAllPerProject,
    updateQuery: taggingsApi.findAllPerProject.updateQuery,
    getTags: tagsApi.findAllTree,
    exportTaggings: taggingsExportApi.all,
    ...taggingsAdvancedFilters,
  }
)
class TagReview extends React.PureComponent {
  static propTypes = {
    match: ReactRouterPropTypes.match,
    storage: PropTypes.object,
    project: PropTypes.object,
    taggings: PropTypes.object,
    tags: PropTypes.object,
    advancedFilters: PropTypes.object,
    tagCategoryIds: PropTypes.array,
    location: PropTypes.object,

    getTaggings: PropTypes.func.isRequired,
    exportTaggings: PropTypes.func.isRequired,
    getTags: PropTypes.func.isRequired,
    updateQuery: PropTypes.func.isRequired,
  };

  static APPCUES_FLOW_ID = '-LHs7SLaNC0OCYZ51qSW';

  constructor(props) {
    super(props);
    this.state = { showFilterCreator: false, exportModalShow: false };
  }

  componentDidMount() {
    this.doRequestTaggings(this.props.project?.uid);
  }
  componentDidUpdate(prevProps) {
    const currentTagIds = this.props.taggings.data
      .map(({ tag }) => tag?.id)
      .filter((id) => id);
    const prevTagIds = prevProps.taggings.data
      .map(({ tag }) => tag?.id)
      .filter((id) => id);

    // Need to explicitly determine if there's a change in the tag IDs
    const hasTagIdsChanged =
      currentTagIds.some((id) => !prevTagIds.includes(id)) ||
      prevTagIds.some((id) => !currentTagIds.includes(id));

    if (hasTagIdsChanged || prevProps.project.uid !== this.props.project.uid) {
      this.props.getTags(
        where()
          .filter('id_eq_any', currentTagIds)
          .fields(
            'tag',
            'taggings_count',
            'title',
            'tag_categories',
            'created_at'
          )
      );
    }

    if (prevProps.project.uid !== this.props.project.uid) {
      this.doRequestTaggings(this.props.project.uid);
    }
  }

  doRequestTaggings = (projectUid) => {
    if (!projectUid) return;
    this.props.getTaggings(
      where({ project_id: projectUid })
        .filter(
          'report_grantee_name_or_survey_answer_grantee_name_present',
          true
        )
        .filter('ignore_orphans', true)
        .include(
          'tag',
          'type',
          'report',
          'report.project_reports',
          'report.grantee',
          'survey_answer',
          'survey_answer.grantee_survey',
          'survey_answer.survey_question',
          'grantee_survey.survey'
        )
        .fields('report', 'name', 'grantee', 'project_reports', 'uid', 'period')
        .fields('project_report', 'project_id', 'id', 'report_id')
        .fields('grantee', 'name')
        .fields('survey_answer', 'grantee_survey', 'survey_question', 'uid')
        .fields('grantee_survey', 'grantee_name', 'survey', 'survey_id')
        .fields('survey_question', 'id', 'position')
        .sort('-updated_at')
        .paginate({ number: 1, size: 30 })
        .pending('init')
        .fromString(this.props.location.search)
    );
  };

  get filterAutopopulationConfig() {
    return {
      allow: TAGGING_AUTO_POPULATION_FIELDS,
      resource: this.props.advancedFilters,
      onRequestOptions: this.handleRequestFilterOptions,
      grantee_survey_grantee_country_code_or_report_grantee_country_code: {
        transformValues: (values) =>
          values
            .map((v) => getCountryNameByCode(v.toUpperCase()))
            .filter((v) => v !== null),
      },
    };
  }

  handleRequestFilterOptions = (key, value) => {
    const query = where({
      tagCategoryIds: this.props.tagCategoryIds,
      projectId: this.props.project.id,
    });
    this.props[`taggingsAF_${key}`]({ key, value }, query);
  };

  handlePaginationChange = ({ page, per_page }) => {
    const query = where().paginate({ number: page, size: per_page });
    this.props.updateQuery(query);
  };

  handleToggleFilter = (showFilterCreator) => {
    this.setState({ showFilterCreator });
  };

  handleFilterApply = (filterValues) => {
    const query = where().filter(filterValues).paginate({ number: 1 });
    this.props.updateQuery(query);
  };

  handleClearFilter = (filterValues) => {
    const query = where().filter(filterValues).actionMeta({ doDelete: true });
    this.props.updateQuery(query);
  };

  handleExport = () => {
    this.props
      .exportTaggings(
        where()
          .filter(
            'report_grantee_name_or_survey_answer_grantee_name_present',
            true
          )
          .filter('ignore_orphans', true)
          .filter('project_id_eq', this.props.project.uid)
          .include(
            'tag',
            'type',
            'report',
            'report.project_reports',
            'report.grantee',
            'survey_answer',
            'survey_answer.grantee_survey',
            'survey_answer.survey_question',
            'grantee_survey.survey'
          )
          .fields(
            'report',
            'name',
            'grantee',
            'project_reports',
            'uid',
            'period'
          )
          .fields('project_report', 'project_id', 'id', 'report_id')
          .fields('grantee', 'name')
          .fields('survey_answer', 'grantee_survey', 'survey_question', 'uid')
          .fields('grantee_survey', 'grantee_name', 'survey', 'survey_id')
          .fields('survey_question', 'id', 'position')
          .sort('-updated_at')
          .paginate({ number: 1, size: 30 })
          .pending('init')
          .fromString(this.props.location.search)
      )
      .then(() => this.setState({ exportModalShow: true }));
  };

  handleCloseExportModal = () => {
    this.setState({ exportModalShow: false });
  };

  handleSortChange = (sortKey) => {
    const query = where().sort(sortKey).paginate({ number: 1 });
    this.props.updateQuery(query);
  };

  render() {
    const { taggings, storage, tags } = this.props;
    const { showFilterCreator, exportModalShow } = this.state;
    const filters = Object.keys(
      taggings.meta.getAction?.queryParams.filter || {}
    ).reduce(
      (acc, el) => ({
        ...acc,
        ...(TAGGING_HIDDEN_FILTER.key != el
          ? { [el]: taggings.meta.getAction?.queryParams.filter[el] }
          : {}),
      }),
      {}
    );
    const uniqueKey = `${this.props.project?.id}-${isGrantless(
      this.props.project
    )}`;
    return (
      <div key={uniqueKey}>
        <Section type="sub-header" collapsed>
          <Container horizontal>
            <Actions>
              <FilterTrigger
                active={showFilterCreator}
                filters={filters}
                config={TAGGING_FILTERS()}
                onToggle={() => this.handleToggleFilter(!showFilterCreator)}
              />
            </Actions>
            <Actions>
              <Button
                secondary
                disabled={!taggings.data.length}
                size="l"
                label="Export"
                onClick={this.handleExport}
              />
            </Actions>
          </Container>
        </Section>
        <Section noBorder>
          <FilterJsonapi
            showCreator={showFilterCreator}
            filters={filters}
            config={TAGGING_FILTERS()}
            configAutopopulation={this.filterAutopopulationConfig}
            onToggleCreator={this.handleToggleFilter}
            onClear={this.handleClearFilter}
            onSubmit={this.handleFilterApply}
          />
          <Card>
            <Container>
              <TagTable
                taggings={taggings}
                tags={tags}
                noGrantAndGrantee={isGrantless(this.props.project)}
                onSortChange={this.handleSortChange}
                projectId={this.props.match.params.projectId}
              />
            </Container>
            <CardFooter>
              <PaginationIm
                {...taggings.links.meta}
                label="tags"
                items_label="tags"
                onChange={this.handlePaginationChange}
              />
            </CardFooter>
          </Card>
        </Section>

        <ExportConfirmation
          open={exportModalShow}
          onRequestClose={this.handleCloseExportModal}
        />
      </div>
    );
  }
}

export default TagReview;
