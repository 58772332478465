import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import cls from './Container.module.css';

const cx = classNames.bind(cls);

export const Container = ({
  children,
  style,
  className,
  anchor,
  grow,
  centered,
  content,
  horizontal,
  centerHorizontal,
  spaceBetween,
  max,
  nowrap,
  onClick,
}) => {
  let styles = style;
  if (max) {
    styles = styles || {};
    styles[horizontal ? 'maxWidth' : 'maxHeight'] = max;
  }
  return (
    <div
      style={styles}
      className={cx(
        'imui-container',
        {
          'imui-container-centered': centered,
          'imui-container-content': content,
          'imui-container-nowrap': nowrap,
          'imui-container-grow': grow,
          'imui-container-anchor': anchor,
          'imui-container-horizontal': horizontal,
          'imui-container-center-horizontal': centerHorizontal,
          'imui-container-space-between': spaceBetween,
          'imui-container-max': !!max,
        },
        className
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

Container.propTypes = {
  style: PropTypes.object,
  children: PropTypes.node,
  className: PropTypes.string,
  horizontal: PropTypes.bool,
  centered: PropTypes.bool,
  content: PropTypes.bool,
  grow: PropTypes.bool,
  nowrap: PropTypes.bool,
  anchor: PropTypes.bool,
  centerHorizontal: PropTypes.bool,
  spaceBetween: PropTypes.bool,
  max: PropTypes.number,

  onClick: PropTypes.func,
};

export default Container;
