export const removeSharedProps = (obj1, obj2) => {
  return Object.keys(obj1).reduce((acc, key) => {
    if (obj2[key] === undefined) {
      return { ...acc, [key]: obj1[key] };
    }

    if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
      return {
        ...acc,
        [key]: obj1[key].filter((item) => !obj2[key].includes(item)),
      };
    }

    if (
      key === 'filter' &&
      typeof obj1[key] === 'object' &&
      obj1[key] != null &&
      typeof obj2[key] === 'object'
    ) {
      return {
        ...acc,
        [key]: Object.fromEntries(
          Object.entries(obj1[key]).filter(
            ([subKey]) =>
              !Object.keys(obj2[key]).some(
                (k) =>
                  k.length > 0 &&
                  subKey.length > 0 &&
                  (subKey.startsWith(k) || k.startsWith(subKey))
              )
          )
        ),
      };
    }

    if (
      typeof obj1[key] === 'object' &&
      obj1[key] != null &&
      (typeof obj2[key] === 'object' || obj2[key] === undefined)
    ) {
      return { ...acc, [key]: removeSharedProps(obj1[key], obj2[key] || {}) };
    }

    return acc;
  }, {});
};
