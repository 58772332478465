import PropTypes from 'prop-types';
import React from 'react';
import DOMPurify from 'dompurify';

const urlify = (text = '', ignoreHTMLAnchors = true) => {
  return text.replace(
    /(href="|')?((?:(?:https?:\/\/)|(?:www\.)|(?:[\w-_]\.?)+(\.\w{2,3}?))[^\s"']+)/g,
    (substring, startsWithHref, url) =>
      ignoreHTMLAnchors && startsWithHref
        ? substring
        : `<a href="${
            url.startsWith('http') ? url : `https://${url}`
          }" target="_blank">${url.replace(/^https?:\/\//, '')}</a>`
  );
};

const InjectableContent = ({
  text,
  convertUrls,
  rightAlign,
  className = '',
}) => (
  <div
    className={className}
    dangerouslySetInnerHTML={{
      __html: DOMPurify.sanitize(convertUrls ? urlify(text) : text),
    }}
    style={rightAlign ? { textAlign: 'right' } : {}}
  />
);
InjectableContent.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  convertUrls: PropTypes.bool,
  rightAlign: PropTypes.bool,
};

export default InjectableContent;
