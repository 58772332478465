import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm, Form, submit } from 'redux-form';
import isEmail from 'validator/lib/isEmail';

import passwordApi from 'src/api/Password';
import { FullBgView } from 'src/components/FullBgView';
import { Button, TextField, FormField } from 'src/components/IMUI/index';
import history from 'src/historyInstance';
import createValidator from 'src/utils/validation';

import { where } from 'im/api/Query';

class ForgotPassword extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    requestResetToken: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
  };

  static FORM_NAME = 'forgotPassword';

  onChangePassword = (data) => {
    const query = where().payload({ email: data.email });
    this.props.requestResetToken(query);
  };

  onSubmitRequest = () => {
    this.props.submit(ForgotPassword.FORM_NAME);
  };

  onRedirectToLogin = () => {
    history.push('/login');
  };

  render() {
    return (
      <FullBgView hasLogo>
        <FullBgView.Box
          limitedWidth
          title="Forgot your password?"
          rightAction={
            <Button
              dark
              type="submit"
              size="l"
              label="Send"
              onClick={this.onSubmitRequest}
            />
          }
          leftAction={
            <Button
              dark
              text
              underlined
              label="Back to login"
              onClick={this.onRedirectToLogin}
            />
          }
        >
          <Form
            onSubmit={this.props.handleSubmit(this.onChangePassword)}
            noValidate
          >
            <FormField>
              <Field
                fullWidth
                component={TextField}
                name="email"
                type="email"
                hintText="Enter your email"
              />
            </FormField>
          </Form>
        </FullBgView.Box>
      </FullBgView>
    );
  }
}

function validate(values) {
  const not = (fn) => (v) => !fn(v);
  const isDefined = (v) => Boolean(v);

  return createValidator({
    email: [
      [not(isDefined), () => 'Email is required'],
      [not(isEmail), () => 'It is not a valid email'],
    ],
  })(values);
}

export default connect(null, (dispatch) =>
  bindActionCreators(
    {
      requestResetToken: passwordApi.requestResetTokenPublic,
      submit,
    },
    dispatch
  )
)(
  reduxForm({
    form: ForgotPassword.FORM_NAME,
    validate,
  })(ForgotPassword)
);
