import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm, getFormValues, getFormSyncErrors } from 'redux-form';
import Dropzone from 'src/components/Dropzone/Dropzone';
import {
  TextField,
  FormField,
  Container,
  Divider,
  SelectField,
} from 'src/components/IMUI/index';
import CURRENCIES from 'src/data/currencies.json';
import createValidator, { handleSubmitFail } from 'src/utils/validation';
import BillingSettings, { validateBilling } from './BillingSettings';
import CustomArrayFields, { validateCustomArray } from './CustomArrayFields';
import CustomRegionMapping, {
  validateCustomRegions,
} from './CustomRegionMapping';
import cls from './UserOrgInputs.module.css';
import { getOrgText } from 'src/services/DictionaryService';
import { canManageOrganization, surveyOnly } from 'src/userStorage';
const MISC_SECTION = 'misc';
const cx = classNames.bind(cls);

const noFormSubmit = (ev) => {
  ev.preventDefault();
  ev.stopPropagation();
};

const UserOrgInputs = ({
  form,
  change,
  formValues,
  formErrors,
  isNew,
  canEdit,
  defaultCustomValues,
  onLogoChange,
}) => {
  const onChangeFormValue = (fieldName, fieldValue) => {
    change(fieldName, fieldValue);
  };

  const renderCustoms = () => {
    if (surveyOnly()) return null;
    return (
      <div>
        <Divider className={cx('user-org-inputs-divider')} />

        {canEdit && (
          <BillingSettings
            disabled={formValues.disabled}
            onChangeFormValue={onChangeFormValue}
          />
        )}

        <Divider className={cx('user-org-inputs-divider')} />
        <Container horizontal className={cx('container-divided-equally')}>
          <CustomArrayFields
            form={form}
            title="Funding types"
            name="funding_types"
            hintText="Funding type"
            items={formValues?.funding_types || []}
            formErrors={formErrors}
            defaultValues={defaultCustomValues.funding_types}
          />
          <CustomArrayFields
            form={form}
            title="Grant statuses"
            name="grant_statuses"
            hintText="Grant status"
            items={formValues?.grant_statuses || []}
            formErrors={formErrors}
            defaultValues={defaultCustomValues.grant_statuses}
          />
        </Container>

        <Divider className={cx('user-org-inputs-divider')} />
        <h4 className={cx('user-org-inputs-subtitle')}>Regions</h4>
        <CustomRegionMapping
          form={form}
          regions={formValues?.custom_region_mapping}
          formErrors={formErrors}
          defaultValues={defaultCustomValues.regions}
        />

        <Divider className={cx('user-org-inputs-divider')} />
        <h3 className={cx('user-org-inputs-title')}>Custom fields</h3>
        <Container horizontal className={cx('container-divided-equally')}>
          <CustomArrayFields
            form={form}
            title={getOrgText('Grant fields')}
            name="custom_grant_fields"
            hintText="Grant field"
            previewType="list"
            items={formValues?.custom_grant_fields || []}
            formErrors={formErrors}
          />
          <CustomArrayFields
            form={form}
            title={getOrgText('Grantee fields')}
            name="custom_grantee_fields"
            hintText="Grantee field"
            previewType="list"
            items={formValues?.custom_grantee_fields || []}
            formErrors={formErrors}
          />
        </Container>
      </div>
    );
  };

  return (
    <form
      noValidate
      className={cx('user-org-inputs', 'inputs-wrapper')}
      onSubmit={noFormSubmit}
    >
      <h3 className={cx('inputs-wrapper-title-first')}>Organization details</h3>
      <Container horizontal nowrap>
        {canManageOrganization() && (
          <FormField anchorScrollName="logo" className={cx('input-dropzone')}>
            <Field
              disabled={!canManageOrganization() || !canEdit}
              iconInsteadOfText
              multiple={false}
              style={{ height: 100 }}
              noValidation={false}
              component={Dropzone}
              accept={'image/jpg,image/jpeg,image/png,image/gif'}
              onChange={onLogoChange}
              notificationError={{
                message: 'Only images (.jpg, .png, .svg...) are supported',
              }}
              name="logo"
              label="Logo"
            />
          </FormField>
        )}

        <FormField anchorScrollName="title" className={cx('input-name')}>
          <Field
            disabled={!canEdit}
            fullWidth
            component={TextField}
            name="title"
            label="Title"
            hintText="Type in..."
          />
        </FormField>
      </Container>

      <FormField>
        <Field
          disabled={!canEdit}
          multiLine
          fullWidth
          rowsMax={5}
          component={TextField}
          name="description"
          label="Description"
          hintText="Type in..."
        />
      </FormField>

      <FormField className={cx('input-half-width')}>
        <Field
          disabled={!canEdit}
          fullWidth
          component={TextField}
          label="Contact email"
          hintText="Type in"
          name="contact_email"
        />
      </FormField>

      <FormField className={cx('input-half-width')}>
        <Field
          disabled={!canEdit}
          fullWidth
          component={SelectField}
          format={(value) => value && value.toLowerCase()}
          label="Currency"
          hintText="Choose"
          name="currency"
        >
          {CURRENCIES.map(({ code: c }) => (
            <SelectField.Item value={c.toLowerCase()} key={c} primaryText={c} />
          ))}
        </Field>
      </FormField>

      {isNew ? null : renderCustoms()}
    </form>
  );
};

UserOrgInputs.propTypes = {
  form: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  formValues: PropTypes.object,
  formErrors: PropTypes.object,
  isNew: PropTypes.bool,
  canEdit: PropTypes.bool,
  defaultCustomValues: PropTypes.object,
  onLogoChange: PropTypes.func,
};

UserOrgInputs.defaultProps = {
  formValues: {},
  defaultCustomValues: { fundingTypes: [], grantStatuses: [], regions: [] },
};

function mapStateToProps(state, ownProps) {
  return {
    formValues: getFormValues(ownProps.form)(state),
    formErrors: getFormSyncErrors(ownProps.form)(state),
  };
}

function validateUserOrgInputs(values = {}) {
  const not = (fn) => (v) => !fn(v);
  const isDefined = (v) => Boolean(v);
  const longerThan = (n) => (v) => v.length > n;

  const validator = createValidator({
    title: [
      [not(isDefined), () => 'Title is required'],
      [not(longerThan(1)), () => 'Title is too short'],
    ],
  });

  return {
    ...validator(values),
    ...validateBilling(values),
    [MISC_SECTION]: {
      ...validateCustomRegions(values),
      funding_types: validateCustomArray(values, 'funding_types'),
      grant_statuses: validateCustomArray(values, 'grant_statuses'),
      custom_grant_fields: validateCustomArray(values, 'custom_grant_fields'),
      custom_grantee_fields: validateCustomArray(
        values,
        'custom_grantee_fields'
      ),
    },
  };
}

function validate(values) {
  return validateUserOrgInputs(values);
}
export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({}, dispatch)
)(
  reduxForm({
    enableReinitialize: true,
    validate,
    onSubmitFail: (errors) => handleSubmitFail(errors),
  })(UserOrgInputs)
);
