import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { toBeDeleted, toBeApproved } from 'src/api/Organization';
import { Tile, EmptyText, Progress } from 'src/components/IMUI';
import PaginationIm from 'src/components/IMUI/Pagination/PaginationIm';
import { where } from 'im/api/Query';
import OrganizationTileItem from './OrganizationTileItem';
import cls from './OrganizationsTiles.module.css';
const cx = classNames.bind(cls);

export default class OrganizationsList extends React.PureComponent {
  static propTypes = {
    organizations: PropTypes.object,
    currentOrg: PropTypes.object,
    onPaginationChange: PropTypes.func.isRequired,
    onEditClick: PropTypes.func.isRequired,
    onChangeOrganization: PropTypes.func.isRequired,
  };
  static defaultProps = { organizations: { data: [], pending: {} } };

  onPaginationChange = ({ page, per_page }) => {
    this.props.onPaginationChange(
      where().paginate({ number: page, size: per_page })
    );
  };
  render() {
    if (this.props.organizations.pending.init) {
      return <Progress large />;
    }
    if (
      !this.props.organizations.data.length &&
      !this.props.currentOrg &&
      !this.props.organizations.pending.init
    )
      return (
        <Tile.Container>
          <EmptyText text="No organizations" className={cx('empty-text')} />
        </Tile.Container>
      );
    return (
      <div>
        <Tile.Container>
          {!this.props.currentOrg ||
          this.props.organizations.links?.meta?.number != 1 ? null : (
            <OrganizationTileItem
              isCurrent
              organization={this.props.currentOrg}
              onEditClick={this.props.onEditClick}
              onChangeOrganization={this.props.onChangeOrganization}
            />
          )}
          {this.props.organizations.data.map((org) => (
            <OrganizationTileItem
              key={org.id}
              organization={org}
              isPendingApproval={toBeApproved(org)}
              isPendingDeletion={toBeDeleted(org)}
              onChangeOrganization={this.props.onChangeOrganization}
              onEditClick={this.props.onEditClick}
            />
          ))}
        </Tile.Container>
        <PaginationIm
          {...(this.props.organizations?.links?.meta || {})}
          compact
          label="organizations"
          onChange={this.onPaginationChange}
        />
      </div>
    );
  }
}
