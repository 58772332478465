import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Table, Container, Tag } from 'src/components/IMUI';
import TaggingTypeBlock from 'src/components/TaggingType/TaggingTypeBlock';
import {
  TagGroupList,
  TagGroupItem,
  TagListItem,
} from 'src/components/TagList';
import { getParentCategories } from 'src/api/Tags';
import {
  dedummy,
  dummyGrant,
  dummyGrantee,
  escapeForRegExp,
} from 'src/utils/string';
import QueryParamsManager from 'im/api/QueryParamsManager';
import cls from './TagRow.module.css';
import { toLocaleDateTime } from 'src/utils/date';
import TaggingDetailsSelector from '../../../TagEditor/components/TaggingDetailsSelector';
import { where } from 'im/api/Query';
import taggingsApi from 'src/api/Taggings';

@connect(null, { updateTagging: taggingsApi.put })
class TagRow extends React.PureComponent {
  static propTypes = {
    tagging: PropTypes.object,
    tag: PropTypes.object,
    projectId: PropTypes.string,
    noGrantAndGrantee: PropTypes.bool,
    updateTagging: PropTypes.func,
  };

  static defaultProps = { tag: {} };
  state = { editInProgress: null };

  titleName() {
    const { report, survey_answer } = this.props.tagging;
    if (survey_answer?.grantee_survey?.survey?.title) {
      return `Survey: ${survey_answer.grantee_survey.survey.title}`;
    }
    if (!report?.name) return '';
    const regex = new RegExp(
      `- (${escapeForRegExp(dummyGrantee)})|(${escapeForRegExp(dummyGrant)})`
    );
    return `Report: ${report.name.replace(regex, '')}`;
  }
  grantName = () => {
    const { report, survey_answer } = this.props.tagging;
    if (report?.grantee) return dedummy(report.grantee.name, '-');
    if (survey_answer?.grantee_survey)
      return dedummy(survey_answer.grantee_survey.grantee_name, '-');
    return dedummy(null, '-');
  };

  handleGoToReportTag = (e) => {
    if (e.metaKey || e.ctrlKey) return;
    e.preventDefault();
    e.stopPropagation();
    const { report, survey_answer } = this.props.tagging;
    if (report) {
      const projectReportId =
        report.project_reports?.find(
          (pr) => pr.project_id == this.props.projectId
        )?.id ?? report.project_reports?.slice(-1)[0]?.id;
      const search = QueryParamsManager.stringify({
        start: this.props.tagging.report_content_start,
        end: this.props.tagging.report_content_end,
      });
      const url = `/analysis/${this.props.projectId}/reports/${projectReportId}?${search}`;
      return window.open(url, '_blank');
    }

    if (survey_answer?.grantee_survey) {
      const search = QueryParamsManager.stringify({
        position: survey_answer.survey_question?.position ?? 0,
      });
      const url = `/analysis/${this.props.projectId}/surveys/${survey_answer.grantee_survey.survey_id}/responses/${survey_answer.grantee_survey.id}?${search}`;
      return window.open(url, '_blank');
    }

    alert('No link found');
    return null;
  };

  handleEditTagging = (tagging, taggingDetails) => {
    const taggableUid = tagging.report?.uid || tagging.survey_answer?.uid;
    this.props.updateTagging(
      where({ id: tagging.id }).payload({
        attributes: {
          quantity: null,
          amount: null,
          currency: null,
          year: null,
          month: null,
          day: null,
          location: null,
          ...taggingDetails,
        },
        relationships: [
          { relName: 'taggable', type: tagging.taggable.type, id: taggableUid },
          { relName: 'project', type: 'projects', id: tagging.project.id },
        ],
      })
    );
    this.taggingEditOff();
  };

  taggingEditOff = () => this.setState({ editInProgress: null });
  taggingEditOn = () => this.setState({ editInProgress: this.props.tagging });

  renderTree = () => {
    if (this.props.tagging?.type?.includes('Tagging::Quote'))
      return <Tag alt square label="Quote" />;
    if (!this.props.tagging?.tag?.id && this.props.tagging?.report)
      return <Tag alt square label="Quote" />;

    const showTagFullName = () => this.props.tag.title?.length > 50;
    const [root, ...categories] = getParentCategories(this.props.tag);
    if (!root) return null;

    const isRoot = !categories?.length || !root.child_categories?.length;
    if (isRoot)
      return (
        <TagGroupList className={cls.tagRowTagRootList}>
          <TagGroupItem key={root.id} tagGroup={root} isExpandable={true}>
            <Container horizontal className={cls.tagRowTagContainer}>
              <Tag
                leftAlign
                tip={showTagFullName() ? this.props.tag.title : null}
                label={this.props.tag.title}
                labelProps={{ className: cls.tagRowTagContainerLabel }}
                className={cls.tagRowTagTitle}
                onEdit={this.taggingEditOn}
              />
              <TaggingTypeBlock
                tagging={this.props.tagging}
                className={cls.tagRowTagging}
              />
            </Container>
          </TagGroupItem>
        </TagGroupList>
      );

    return (
      <TagGroupList className={cls.tagRowTagGroupList}>
        <TagGroupItem key={root.id} tagGroup={root} isExpandable={true}>
          {categories.map((category, i) => (
            <TagListItem
              key={category.id}
              tag={category}
              style={{ marginLeft: i * 12 }}
            >
              <Container horizontal className={cls.tagRowTagContainer}>
                {categories.length === i + 1 && (
                  <Tag
                    leftAlign
                    tip={showTagFullName() ? this.props.tag.title : null}
                    label={this.props.tag.title}
                    labelProps={{ className: cls.tagRowTagContainerLabel }}
                    className={cls.tagRowTagTitle}
                    onEdit={this.taggingEditOn}
                  />
                )}
                <TaggingTypeBlock
                  tagging={this.props.tagging}
                  className={cls.tagRowTagging}
                />
              </Container>
            </TagListItem>
          ))}
        </TagGroupItem>
      </TagGroupList>
    );
  };

  render() {
    const rawContent = String(this.props.tagging.content || '');
    const isLonger = rawContent.length > 400;
    const content = isLonger ? `${rawContent.slice(0, 400)}...` : rawContent;
    return (
      <>
        <tr style={{ position: 'absolute', display: 'block' }}>
          {this.state.editInProgress?.id && (
            <TaggingDetailsSelector
              open={true}
              isEdit={true}
              tagging={this.props.tagging}
              position={{ top: 30, left: 250 }}
              onSubmit={this.state.editInProgress?.id && this.handleEditTagging}
              onRequestClose={this.taggingEditOff}
            />
          )}
        </tr>
        <Table.Row>
          <Table.Cell onClick={this.handleGoToReportTag}>
            {this.titleName()}
          </Table.Cell>
          {this.props.noGrantAndGrantee ? null : (
            <Table.Cell>{this.grantName()}</Table.Cell>
          )}
          <Table.Cell>{this.renderTree()}</Table.Cell>
          <Table.Cell
            style={{ fontSize: 10 }}
            onClick={this.handleGoToReportTag}
          >
            {content}
          </Table.Cell>
          <Table.Cell onClick={this.handleGoToReportTag}>
            {toLocaleDateTime(
              this.props.tagging.updated_at ?? this.props.tag?.updated_at
            )}
          </Table.Cell>
        </Table.Row>
      </>
    );
  }
}

export default TagRow;
