import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Container } from 'src/components/IMUI';
import cls from './Stats.module.css';
const cx = classNames.bind(cls);
const StatPropShape = PropTypes.shape({
  small: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  insight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  descr: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClick: PropTypes.func,
});

export default class Stats extends React.PureComponent {
  static propTypes = {
    data: PropTypes.arrayOf(StatPropShape),
    small: PropTypes.bool,
  };
  render() {
    return (
      <Container
        horizontal
        className={cx(cls.statsWrapper, {
          [cls.statsWrapperSmall]: this.props.small,
        })}
      >
        {this.props.data.map((stat, index) => (
          <div
            key={`${stat.title}-${index}`}
            className={cx(cls.statsItem, {
              [cls.statsItemClickable]: typeof stat.onClick === 'function',
            })}
            onClick={stat.onClick}
          >
            {stat.title && (
              <div className={cls.statsItemTitle}>{stat.title}</div>
            )}
            {(typeof stat.value === 'number' || stat.value || stat.insight) && (
              <div className={cls.statsItemValueWrapper}>
                {stat.insight && (
                  <div className={cls.statsItemInsight}>{stat.insight}</div>
                )}
                {this.props.small && stat.descr && (
                  <div className={cls.statsItemDescr}>{stat.descr}</div>
                )}
                <div className={cls.statsItemValue}>
                  {stat.value || typeof stat.value === 'number'
                    ? stat.value
                    : '-'}
                </div>
              </div>
            )}
            {!this.props.small && stat.descr && (
              <div className={cls.statsItemDescr}>{stat.descr}</div>
            )}
          </div>
        ))}
      </Container>
    );
  }
}
