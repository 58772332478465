import { isPendingStatusAction } from 'im/api/helpers/actionTypes';

export default function blockEffects(state, action) {
  const pending = isPendingStatusAction(action.type) && action.payload.pending;

  if (action.type.match(/_REQUEST$/) || (pending && pending.init)) {
    document.body.classList.add('blocked');
  }
  if (action.type.match(/(_SUCCESS|_FAILURE|\/success|\/failure)$/)) {
    if (state.ui.actionsInProgress === 0) {
      document.body.classList.remove('blocked');
    }
  }
}
