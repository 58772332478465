import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { NotificationBlip } from './NotificationBlip';
import { selectNotifications } from './store.selectors';

import cls from './Notifications.module.css';

export const NotificationsWidget: React.FC = () => {
  const notifications = useSelector(selectNotifications);

  const fixedFooterOffset = document.querySelector("[class*='hasFooter']");

  return (
    <div
      className={classNames(cls.notificationsWidget, {
        [cls.footerOffset]: fixedFooterOffset,
      })}
    >
      {notifications.map((notification) => (
        <NotificationBlip floating {...notification} key={notification.uid} />
      ))}
    </div>
  );
};
