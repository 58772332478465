import authEffects from './authEffects';
import blockEffects from './blockEffects';
import dictionaryEffects from './dictionaryEffects';
import errorEffects from './errorEffects';
import grantEffects from './grantEffects';
import notificationEffects from './notificationEffects';
import organizationEffects from './organizationEffects';
import projectEffects from './projectEffects';
import routerEffects from './routerEffects';
import storageEffects from './storageEffects';
import surveyEffects from './surveyEffects';
import tagsEffects from './tagsEffects';
import uploaderSecureEffects from './uploaderSecureEffects';
import userEffects from './userEffects';

export default [
  blockEffects,
  errorEffects,
  notificationEffects,
  projectEffects,
  userEffects,
  storageEffects,
  routerEffects,
  authEffects,
  organizationEffects,
  surveyEffects,
  dictionaryEffects,
  uploaderSecureEffects,
  grantEffects,
  tagsEffects,
];
