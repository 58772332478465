import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import { connect } from 'react-redux';
import { destroy } from 'redux-form';
import uploaderSecureApi from 'src/api/UploaderSecure';
import Dropzone from 'src/components/Dropzone/Dropzone';
import {
  Button,
  Section,
  Container,
  Actions,
  MAX_UPLOAD_SIZE,
  Progress,
} from 'src/components/IMUI';
import { where } from 'im/api/Query';
import TipInfo from './components/TipInfo';
import cls from './UploadCSV.module.css';
import TipInfoTag from './components/TipInfoTag';
import history from 'src/historyInstance';
import { matchPath } from 'react-router-dom';
import { userCurrentOrgId } from 'src/userStorage';
const cx = classNames.bind(cls);
const getProjId = (location) =>
  matchPath(location.pathname, {
    path: '/analysis/:projectId',
    exact: false,
    strict: false,
  })?.params.projectId;
@connect(pick(['importUploadMapping']), {
  destroyFile: uploaderSecureApi.destroy,
  uploadFile: uploaderSecureApi.create,
  uploadFileTag: uploaderSecureApi.tags.create,
  destroyForm: destroy,
})
export default class UploadCSV extends React.PureComponent {
  state = { assets: [] };
  static propTypes = {
    location: PropTypes.object,
    importUploadMapping: PropTypes.object,
    destroyForm: PropTypes.func.isRequired,
    uploadFile: PropTypes.func.isRequired,
    destroyFile: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    target_id: PropTypes.string,
    uploadFileTag: PropTypes.func,
    target_type: PropTypes.oneOf([
      'IM::Organization',
      'Project',
      'Report',
      'Survey',
      'SurveyAnswer',
      'Grant',
      'IM::TagCategory',
    ]).isRequired,
    type: PropTypes.oneOf(['Tag', 'Grant']).isRequired,
  };

  clearForm = () => {
    this.props.destroyForm('grantMappingForm');
    this.props.destroyFile({ id: this.props.importUploadMapping.data.id });
  };
  handleDrop = (files) => {
    if (this.props.importUploadMapping.data.id) {
      this.clearForm();
    }
    this.setState({ assets: files });
  };
  handleClear = () => {
    if (this.props.importUploadMapping.data.id) {
      this.clearForm();
    }
    this.setState({ assets: [] });
  };
  handleUpload = () => {
    this.props.type === 'Grant' && this.handleUploadGrant();
    this.props.type === 'Tag' && this.handleUploadTag();
  };
  handleUploadGrant = () => {
    this.setState({ uploading: true });
    this.props
      .uploadFile(
        where().payloadMultipart(
          {
            asset: this.state.assets[0],
            organization_id: userCurrentOrgId(),
            target_id: this.props.target_id || userCurrentOrgId(),
            target_type: this.props.target_type,
          },
          'import_upload'
        )
      )
      .then(
        (response) =>
          response?.data &&
          history.push(`/grants/import/${response?.data?.id}/map`)
      )
      .finally(() => this.setState({ uploading: false }));
  };

  handleUploadTag = () => {
    this.props
      .uploadFileTag(
        where().payloadMultipart(
          {
            asset: this.state.assets[0],
            organization_id: userCurrentOrgId(),
            target_id: this.props.target_id || userCurrentOrgId(),
            target_type: this.props.target_type,
          },
          'import_upload'
        )
      )
      .then(
        (response) =>
          response?.data &&
          history.push(
            `/analysis/${getProjId(this.props.location)}/tags/${
              response?.data?.id
            }/report`
          )
      )
      .finally(() => this.setState({ uploading: false }));
    this.setState({ uploading: false });
  };

  render() {
    return (
      <Container>
        <Section noBorder className={cx(cls.sectionContent, cls.limitedWidth)}>
          <h3 className={cls.title}>{this.props.title}</h3>
          {this.props.type === 'Grant' && <TipInfo />}
          {this.props.type === 'Tag' && <TipInfoTag />}
          {this.state.uploading && <Progress large className="absolute" />}
          <Dropzone
            disablePreview
            containerClassName={cls.dropzoneContainer}
            className={cls.dropzone}
            multiple={false}
            name="upload_csv"
            accept=".csv, text/csv"
            acceptType=".csv"
            onDrop={this.handleDrop}
            onClear={this.handleClear}
            accepted={this.state.assets}
            maxSize={MAX_UPLOAD_SIZE}
            style={{
              backgroundColor:
                this.state.assets.length > 0 ? '#eaf5fb' : '#ffffff',
              fontSize: 14,
            }}
          />
        </Section>

        {this.state.assets.length > 0 && (
          <Section type="sticky-footer">
            <Actions>
              <Button
                negative
                size="l"
                label="Cancel"
                onClick={this.handleClear}
              />
            </Actions>
            <Actions>
              <Button size="l" label="Import" onClick={this.handleUpload} />
            </Actions>
          </Section>
        )}
      </Container>
    );
  }
}
