import {
  PROJECT__REPORTS_GET_SUCCESS,
  PROJECT__REPORTS_GET_FAILURE,
} from 'src/constants';

export default function projectReportsReducer(state = [], action) {
  switch (action.type) {
    case PROJECT__REPORTS_GET_SUCCESS:
      return [...action.payload.data];
    case PROJECT__REPORTS_GET_FAILURE:
    default:
      return state;
  }
}
