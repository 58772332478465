import React from 'react';
import PropTypes from 'prop-types';
import clone from 'ramda/src/clone';
import isEmpty from 'ramda/src/isEmpty';
import mergeDeepRight from 'ramda/src/mergeDeepRight';
import path from 'ramda/src/path';
import { Icon } from 'im/ui/Icon';
import {
  Button,
  Container,
  TextField,
  NumberField,
  Toggle,
  Drawer,
  Actions,
  SelectField,
  Slider,
  SelectPopoverLight,
  Label,
  Tooltip,
} from 'src/components/IMUI';
import colors from 'src/css/constants.json';
import ColourPicker from 'src/components/ColourPicker/ColourPicker';
import cls from './ChartSettings.module.css';
import classNames from 'classnames';
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import highchartsAnnotations from 'highcharts/modules/annotations';
import highchartsData from 'highcharts/modules/data';
import highchartsExporting from 'highcharts/modules/exporting';
import highchartsHeatmap from 'highcharts/modules/heatmap';
import highchartsOfflineExporting from 'highcharts/modules/offline-exporting';
import highchartsExportCSV from 'highcharts-export-csv';
import {
  HIGHCHARTS_COLOURS,
  OPTIONS,
  CHART_LABELS,
  CHART_DEFAULTS,
  IMAGETYPES,
  SETTINGS_TABS,
  DEFAULT_CHART_SETTINGS,
} from 'src/components/Chart/chartUtils';
[
  highchartsMore,
  highchartsHeatmap,
  highchartsAnnotations,
  highchartsData,
  highchartsExportCSV,
  highchartsExporting,
  highchartsOfflineExporting,
].forEach((fn) => fn(Highcharts));
Highcharts.setOptions({ colors: HIGHCHARTS_COLOURS });
const cx = classNames.bind(cls);

export default class ChartSettings extends React.PureComponent {
  static propTypes = {
    buttonProps: PropTypes.shape({ ...Button.propTypes }),
    config: PropTypes.object,
    initSettings: PropTypes.object,
    onSettingsChanged: PropTypes.func.isRequired,
    displayTitle: PropTypes.string,
    title: PropTypes.string,
  };

  getBaseInitialState = () => ({
    settingsDialogOpen: false,
    activeTab: 'general',
    settings: { ...DEFAULT_CHART_SETTINGS },
    imageDialogVisible: false,
    fileFormat: 'png',
    showTitle: false,
    isFixedWidth: true,
    exportFixedWidth: 1920,
    exportTitle: this.props.displayTitle || this.props.title || '',
    filename: this.props.title || '',
    imageScale: 1,
    fontScale: 1,
    aspectRatio: '16×9',
    exportTarget: 'reports',
    baseFontSize: 11,
  });

  constructor(props) {
    super(props);
    const initialState = this.getBaseInitialState();
    if (props.initSettings) {
      initialState.settings = mergeDeepRight(
        DEFAULT_CHART_SETTINGS,
        props.initSettings
      );
    }
    this.state = initialState;
  }

  chartInstance() {
    return (
      window.highchart ||
      window.highmap ||
      Highcharts.charts?.filter(Boolean)?.findLast((x) => x)
    );
  }
  onShowSettings = () => {
    if (this.state.settingsDialogOpen == true) return;
    this.setState({ settingsDialogOpen: true });
  };
  onHideSettings = () => {
    if (this.state.settingsDialogOpen == false) return;
    this.setState({ settingsDialogOpen: false });
  };
  onTabChange = (activeTab) => {
    if (this.state.activeTab == activeTab) return;
    this.setState({ activeTab });
  };
  onClearSettings = () => {
    const initialState = this.getBaseInitialState();
    initialState.settingsDialogOpen = true;
    initialState.activeTab = this.state.activeTab;
    this.setState(initialState, () => {
      this.props.onSettingsChanged(this.state.settings);
      this.onDownloadImage(false);
    });
  };
  changeFn = (field, value, index) => {
    const settings = clone(this.state.settings);
    settings[field] ||= [];
    index >= 0 ? (settings[field][index] = value) : (settings[field] = value);
    this.setState({ settings });
    this.props.onSettingsChanged(settings);
  };
  onChangeScale = (_, imageScale) => {
    if (this.state.imageScale == imageScale) return;
    this.setState({ imageScale });
    this.onDownloadImage(false);
  };
  onChangeFontScale = (_, fontScale) => {
    if (this.state.fontScale == fontScale) return;
    this.setState({ fontScale });
    this.onDownloadImage(false);
  };
  onAspectRatioChange = (selectedVal) => {
    if (this.state.aspectRatio == selectedVal.value) return;
    this.setState({ aspectRatio: selectedVal.value });
    this.onDownloadImage(false);
  };
  onExportTitleChange = (exportTitle) => {
    if (this.state.exportTitle == exportTitle) return;
    this.setState({ exportTitle });
  };
  onCloseMenu = () => {
    if (this.state.imageDialogVisible == false) return;
    this.setState({ imageDialogVisible: false });
  };
  onDownloadCSV = () => {
    const link = document.createElement('a');
    link.setAttribute(
      'href',
      encodeURI(`data:text/csv;charset=utf-8,${this.chartInstance()?.getCSV()}`)
    );
    link.setAttribute('download', 'data.csv');
    link.click();
    this.onCloseMenu();
  };
  onFileFormatChange = (fileFormat) => {
    if (this.state.fileFormat == fileFormat) return;
    return fileFormat == 'csv'
      ? this.onDownloadCSV()
      : this.setState({ fileFormat });
  };
  onPercentToggle = (toggleKey, toggled) =>
    this.setState(
      (prevState) => ({
        settings: {
          ...prevState.settings,
          dataLabelIntoPercentByCategory: false,
          dataLabelIntoPercentBySeries: false,
          dataLabelIntoPercentByGrandTotal: false,
        },
      }),
      () => this.changeFn(toggleKey, toggled)
    );

  onExportFixedWidthChange = (_, exportFixedWidth) => {
    if (!(Number(exportFixedWidth) > 0 && Number.isFinite(exportFixedWidth)))
      return;
    if (this.state.exportFixedWidth == Number(exportFixedWidth)) return;
    this.setState({ exportFixedWidth: Number(exportFixedWidth) });
  };
  handleTargetChanged = (exportTarget) => {
    this.onExportFixedWidthChange(null, CHART_DEFAULTS[exportTarget].width);
    this.onChangeFontScale(null, CHART_DEFAULTS[exportTarget].fontScale);
    this.onAspectRatioChange(CHART_DEFAULTS[exportTarget].aspectRatio);
    this.onFileFormatChange(CHART_DEFAULTS[exportTarget]);
    this.setState({ exportTarget });
    this.onDownloadImage(false);
  };
  onDownloadImage = (doDownload) => {
    const chart = this.chartInstance();
    const showTitle = this.state.showTitle;
    const exportTitle = this.state.exportTitle;
    const targetWidth =
      this.state.isFixedWidth || CHART_DEFAULTS[this.state.exportTarget].width
        ? this.state.exportFixedWidth ||
          CHART_DEFAULTS[this.state.exportTarget].width
        : chart.chartWidth;
    const aspectRatioMod = (
      this.state.aspectRatio ||
      CHART_DEFAULTS[this.state.exportTarget].aspectRatio
    )
      .split('×')
      .reduce((a, b) => Number(a) / Number(b));
    const scaling =
      (this.state.fontScale ||
        CHART_DEFAULTS[this.state.exportTarget].fontScale) *
      (targetWidth > 1000 ? 0.51 : 1);
    const targetHeight = Math.round(targetWidth / aspectRatioMod);
    const baseFontSize = this.state.baseFontSize * this.state.fontScale;

    function onLoad() {
      const instance =
        window.highchart ||
        window.highmap ||
        Highcharts.charts?.filter(Boolean)?.findLast((x) => x);
      const isPie = /pie/.test(instance.options?.chart?.type);
      const isBar = instance.options?.chart?.type === 'bar';
      const hasTitle = showTitle && exportTitle;
      const sizeBase = { fontSize: `${baseFontSize}px` };
      const sizeMediumSmall = { fontSize: `${baseFontSize * 1.15}px` };
      const sizeMedium = { fontSize: `${baseFontSize * 1.3}px` };
      const sizeLarge = { fontSize: `${baseFontSize * 2.0}px` };
      const dataLabelsSize = isPie ? sizeMediumSmall : sizeBase;
      const baseMargin = baseFontSize * 2 * 3;
      const marginTop = hasTitle ? baseMargin : baseMargin * 0.33;
      const pieSize = instance.spacingBox.height * 0.5;
      instance.update({
        legend: {
          itemStyle: { fontSize: `${baseFontSize * 0.9}px` },
          symbolHeight: baseFontSize * 0.6,
          symbolPadding: 5 * scaling,
          margin: baseFontSize,
        },
        chart: { spacing: 0, margin: undefined, marginTop },
      });
      instance.update({
        title: {
          text: exportTitle,
          style: {
            display: hasTitle ? 'block' : 'none',
            ...(hasTitle ? sizeLarge : {}),
          },
        },
      });
      if (isPie) {
        instance.update({
          chart: { margin: [marginTop, 0, baseMargin / 2, 0] },
          plotOptions: {
            pie: {
              size: pieSize,
              minSize: pieSize,
              dataLabels: {
                style: { textOverflow: 'auto', width: targetWidth * 0.5 },
                distance: instance.spacingBox.height * 0.08,
                connectorWidth:
                  (instance.options?.plotOptions.pie.dataLabels
                    .connectorWidth || 1) *
                  scaling *
                  2,
                padding:
                  instance.options?.plotOptions.pie.dataLabels.padding *
                  scaling,
                x: 5 * scaling,
                y: -20 * scaling,
              },
            },
          },
        });
      }
      if (isBar) {
        instance.update({
          plotOptions: {
            bar: {
              dataLabels: {
                x: instance.options?.plotOptions.bar.dataLabels.x * scaling,
              },
            },
          },
        });
      }
      instance.colorAxis.forEach((item) =>
        item.update({ labels: { style: sizeBase } })
      );
      instance.xAxis.forEach((item) =>
        item.update({
          labels: {
            style: sizeBase,
            overflow: 'left',
            y: isBar
              ? undefined
              : Math.max(baseFontSize, baseFontSize * scaling),
          },
          title: { style: sizeMedium, overflow: 'left' },
        })
      );
      instance.yAxis.forEach((item) =>
        item.update({
          labels: { style: sizeBase, overflow: 'left' },
          title: {
            style: {
              fontSize: `${baseFontSize}px`,
              margin: (baseFontSize / 2) * scaling,
            },
            overflow: 'left',
          },
        })
      );
      instance.series.forEach((item) =>
        item.update({
          dataLabels: { style: { ...dataLabelsSize, textOutline: 'none' } },
          showInLegend: item.visible,
        })
      );
    }
    if (chart.options?.chart?.events)
      chart.options.chart.events = { load: onLoad };
    if (!doDownload) {
      onLoad();
      return;
    } //This updates changes in real time.
    try {
      onLoad();
      chart.exportChartLocal({
        filename: this.state.filename,
        fallbackToExportServer: false,
        type: IMAGETYPES[this.state.fileFormat] || Object.values(IMAGETYPES)[0],
        scale: this.state.imageScale,
        sourceWidth: targetWidth,
        sourceHeight: targetHeight,
      });
    } catch {
      chart.exportChart({
        filename: this.state.filename,
        fallbackToExportServer: false,
        type: IMAGETYPES[this.state.fileFormat] || Object.values(IMAGETYPES)[0],
        scale: this.state.imageScale,
        sourceWidth: targetWidth,
        sourceHeight: targetHeight,
      });
    }
    this.onCloseMenu();
  };

  renderExportTargetItems = () => (
    <SelectPopoverLight.Menu>
      {Object.keys(CHART_LABELS).map((key) => (
        <SelectPopoverLight.MenuItem
          key={key}
          leftIcon={
            <Icon
              name="check"
              color={
                this.state.exportTarget === key
                  ? colors.seafoam
                  : colors['light-grey']
              }
            />
          }
          primaryText={CHART_LABELS[key]}
          onClick={() => this.handleTargetChanged(key)}
        />
      ))}
    </SelectPopoverLight.Menu>
  );

  renderFooter = () => {
    const hasXLS = typeof this.chartInstance()?.downloadXLS == 'function';
    const hasCSV = typeof this.chartInstance()?.downloadCSV == 'function';
    const hasExport =
      typeof this.chartInstance()?.exportChartLocal == 'function';
    return (
      <div>
        <Container horizontal style={{ marginBottom: 16 }}>
          <Actions>
            {hasXLS && (
              <Button
                size="l"
                primary
                label="XLS"
                onClick={() => this.chartInstance()?.downloadXLS()}
              />
            )}
            {hasCSV && (
              <Button
                size="l"
                primary
                label="CSV"
                onClick={() => this.chartInstance()?.downloadCSV()}
              />
            )}
          </Actions>
          <Actions>
            {hasExport && (
              <Button
                size="l"
                primary
                label={`Download ${this.state.fileFormat}`}
                onClick={() => this.onDownloadImage(true)}
              />
            )}
          </Actions>
        </Container>
        <Container horizontal>
          <Actions>
            <Button size="l" label="Close" onClick={this.onHideSettings} />
          </Actions>
          <Actions>
            <Button
              negative
              size="l"
              label="Reset"
              onClick={this.onClearSettings}
            />
          </Actions>
        </Container>
      </div>
    );
  };

  render() {
    const xx =
      this.props.config?.xAxis &&
      (Array.isArray(this.props.config.xAxis)
        ? this.props.config.xAxis
        : [this.props.config.xAxis]);
    const yy =
      this.props.config?.yAxis &&
      (Array.isArray(this.props.config.yAxis)
        ? this.props.config.yAxis
        : [this.props.config.yAxis]);

    return (
      <div>
        <Button
          style={{ height: 40, maxHeight: 40 }}
          secondary
          size="l"
          icon={<Icon style={{ fontSize: 16 }} name="settings" />}
          onClick={this.onShowSettings}
          label="Settings"
          {...this.props.buttonProps}
        />
        <Drawer
          openSecondary={true}
          containerClassName={cx('chart-drawer')}
          className={cx('chart-drawer-class')}
          closable
          docked={false}
          zIndex={1400}
          width={400}
          open={this.state.settingsDialogOpen}
          onRequestClose={this.onHideSettings}
          overlayStyle={{ padding: 20 }}
          renderFooter={this.renderFooter}
        >
          <Container className={cls.chartSettings} horizontal grow>
            <div className={cls.chartSettingsContent}>
              {this.state.activeTab === 'general' && (
                <section
                  style={{ display: 'flex', flexDirection: 'column', gap: 12 }}
                >
                  <h5 style={{ marginBottom: 8 }}>Chart general settings</h5>
                  <Toggle
                    label="Descending Chart"
                    labelPosition="right"
                    toggled={this.state.settings.dataSorting}
                    onToggle={(_ev, toggled) =>
                      this.changeFn('dataSorting', toggled)
                    }
                  />
                  <Toggle
                    style={{
                      opacity: this.state.settings.dataSorting ? 1 : 0.3,
                    }}
                    disabled={!this.state.settings.dataSorting}
                    label="Ascending Chart"
                    labelPosition="right"
                    toggled={this.state.settings.xAxisReversed}
                    onToggle={(_ev, toggled) =>
                      this.changeFn('xAxisReversed', toggled)
                    }
                  />
                  <Toggle
                    label="Hide Empties from Chart"
                    labelPosition="right"
                    toggled={this.state.settings.hideEmpty}
                    onToggle={(_ev, toggled) =>
                      this.changeFn('hideEmpty', toggled)
                    }
                  />

                  <NumberField
                    id="xAxisMax"
                    key="xAxisMax"
                    noValidation
                    label="Top trends"
                    hintText="Set the number of top trends displayed"
                    border
                    flat
                    value={this.state.settings.xAxisMax || null}
                    onChange={(value) => this.changeFn('xAxisMax', value)}
                  />
                  <h5 style={{ marginBottom: 8 }}>X axis settings</h5>
                  <NumberField
                    id="xAxisMin"
                    key="xAxisMin"
                    noValidation
                    label="Start from"
                    hintText="Set the trend start (default: 0)"
                    border
                    flat
                    value={this.state.settings.xAxisMin || null}
                    onChange={(value) => this.changeFn('xAxisMin', value)}
                  />
                  <h5 style={{ marginBottom: 8 }}>Y axis settings</h5>
                  <Container horizontal nowrap>
                    <NumberField
                      id="yAxisMin"
                      key="yAxisMin"
                      noValidation
                      label="Minimum"
                      hintText="Restrict min.value"
                      border
                      flat
                      value={this.state.settings.yAxisMin || null}
                      onChange={(value) => this.changeFn('yAxisMin', value)}
                    />
                    &emsp;
                    <NumberField
                      id="yAxisMax"
                      key="yAxisMax"
                      noValidation
                      label="Maximum"
                      hintText="Restrict max.value"
                      border
                      flat
                      value={this.state.settings.yAxisMax || null}
                      onChange={(value) => this.changeFn('yAxisMax', value)}
                    />
                  </Container>

                  <h5 style={{ marginBottom: 8 }}>Stacking</h5>
                  <Toggle
                    label="Stack charts (bar, column, area)"
                    labelPosition="right"
                    toggled={this.state.settings.stackChartSeries}
                    onToggle={(_ev, toggled) =>
                      this.changeFn('stackChartSeries', toggled)
                    }
                  />
                  <Toggle
                    style={{
                      opacity: this.state.settings.stackChartSeries ? 1 : 0.3,
                    }}
                    disabled={!this.state.settings.stackChartSeries}
                    label="Stacking charts distribution in %"
                    labelPosition="right"
                    toggled={this.state.settings.stackingPercent}
                    onToggle={(_ev, toggled) =>
                      this.changeFn('stackingPercent', toggled)
                    }
                  />
                  <Toggle
                    label="Multicolor datapoints"
                    labelPosition="right"
                    toggled={this.state.settings.colorByPoint}
                    onToggle={(_ev, toggled) =>
                      this.changeFn('colorByPoint', toggled || undefined)
                    }
                  />
                  <Toggle
                    label="Show title labels"
                    labelPosition="right"
                    toggled={this.state.settings.showTitleLabels}
                    onToggle={(_ev, toggled) =>
                      this.changeFn('showTitleLabels', toggled)
                    }
                  />

                  <h5 style={{ marginBottom: 8 }}>Chart elements visibility</h5>
                  <Toggle
                    label="Hide legend"
                    labelPosition="right"
                    toggled={this.state.settings.hideLegend}
                    onToggle={(_ev, toggled) =>
                      this.changeFn('hideLegend', toggled)
                    }
                  />
                  <Toggle
                    label="Hide x axis"
                    labelPosition="right"
                    toggled={this.state.settings.hideXAxis}
                    onToggle={(_ev, toggled) =>
                      this.changeFn('hideXAxis', toggled)
                    }
                  />
                  <Toggle
                    label="Hide y axis"
                    labelPosition="right"
                    toggled={this.state.settings.hideYAxis}
                    onToggle={(_ev, toggled) =>
                      this.changeFn('hideYAxis', toggled)
                    }
                  />
                  <Toggle
                    label="Hide line markers"
                    labelPosition="right"
                    toggled={this.state.settings.markersHidden}
                    onToggle={(_ev, toggled) =>
                      this.changeFn('markersHidden', toggled)
                    }
                  />
                  <Toggle
                    label={
                      <div>
                        Hide prefix group{' '}
                        <small style={{ float: 'right', display: 'contents' }}>
                          (prefix group &gt; value)
                        </small>
                      </div>
                    }
                    labelPosition="right"
                    toggled={this.state.settings.hideTagGroupLabel}
                    onToggle={(_ev, toggled) =>
                      this.changeFn('hideTagGroupLabel', toggled)
                    }
                  />
                </section>
              )}

              {this.state.activeTab === 'labels' && (
                <section
                  style={{ display: 'flex', flexDirection: 'column', gap: 12 }}
                >
                  <h5 style={{ marginBottom: 8 }}> Data Label Prefix/Suffix</h5>
                  <TextField
                    id="dataLabelPrefix"
                    key="dataLabelPrefix"
                    label="Data label prefix"
                    hintText="$, #..."
                    border
                    flat
                    value={this.state.settings.dataLabelPrefix || ''}
                    onChange={(value) =>
                      this.changeFn('dataLabelPrefix', value)
                    }
                  />
                  <TextField
                    id="dataLabelSuffix"
                    key="dataLabelSuffix"
                    label="Data label suffix"
                    hintText="%, USD..."
                    border
                    flat
                    value={this.state.settings.dataLabelSuffix || ''}
                    onChange={(value) =>
                      this.changeFn('dataLabelSuffix', value)
                    }
                  />

                  <h5 style={{ marginBottom: 8 }}>General Label Settings</h5>
                  <Toggle
                    label="Show data labels"
                    labelPosition="right"
                    toggled={this.state.settings.showDataLabels}
                    onToggle={(_ev, toggled) =>
                      this.changeFn('showDataLabels', toggled)
                    }
                  />
                  <Toggle
                    data-tip
                    data-for="toggle-tooltip"
                    label={<div>Allow data label overlap</div>}
                    labelPosition="right"
                    toggled={this.state.settings.allowDataLabelOverlap}
                    onToggle={(_ev, toggled) =>
                      this.changeFn('allowDataLabelOverlap', toggled)
                    }
                  />
                  <Tooltip id="toggle-tooltip">{`Some labels may disappear to avoid collision.`}</Tooltip>
                  <h5 style={{ marginBottom: 8 }}>
                    Percentage Display Options
                  </h5>
                  <Toggle
                    style={{
                      opacity: !this.state.settings.withoutPercentChartType
                        ? 1
                        : 0.3,
                    }}
                    disabled={this.state.settings.withoutPercentChartType}
                    label="Show % within category"
                    labelPosition="right"
                    toggled={this.state.settings.dataLabelIntoPercentByCategory}
                    onToggle={(_ev, toggled) =>
                      this.onPercentToggle(
                        'dataLabelIntoPercentByCategory',
                        toggled
                      )
                    }
                  />
                  <Toggle
                    style={{
                      opacity: !this.state.settings.withoutPercentChartType
                        ? 1
                        : 0.3,
                    }}
                    disabled={this.state.settings.withoutPercentChartType}
                    label="Show % within series"
                    labelPosition="right"
                    toggled={this.state.settings.dataLabelIntoPercentBySeries}
                    onToggle={(_ev, toggled) =>
                      this.onPercentToggle(
                        'dataLabelIntoPercentBySeries',
                        toggled
                      )
                    }
                  />
                  <Toggle
                    style={{
                      opacity: !this.state.settings.withoutPercentChartType
                        ? 1
                        : 0.3,
                    }}
                    disabled={this.state.settings.withoutPercentChartType}
                    label="Show % within grand total"
                    labelPosition="right"
                    toggled={
                      this.state.settings.dataLabelIntoPercentByGrandTotal
                    }
                    onToggle={(_ev, toggled) =>
                      this.onPercentToggle(
                        'dataLabelIntoPercentByGrandTotal',
                        toggled
                      )
                    }
                  />
                  <Toggle
                    style={{
                      opacity:
                        this.state.settings.dataLabelIntoPercentByCategory ||
                        this.state.settings.dataLabelIntoPercentBySeries ||
                        this.state.settings.dataLabelIntoPercentByGrandTotal
                          ? 1
                          : 0.3,
                    }}
                    disabled={
                      !this.state.settings.dataLabelIntoPercentByCategory &&
                      !this.state.settings.dataLabelIntoPercentBySeries &&
                      !this.state.settings.dataLabelIntoPercentByGrandTotal
                    }
                    label="Show original value with the chosen percent calculation"
                    labelPosition="right"
                    toggled={this.state.settings.showOriginalValueWithPercents}
                    onToggle={(_ev, toggled) =>
                      this.changeFn('showOriginalValueWithPercents', toggled)
                    }
                  />
                  <Slider
                    disabled={
                      !this.state.settings.dataLabelIntoPercentByCategory &&
                      !this.state.settings.dataLabelIntoPercentBySeries &&
                      !this.state.settings.dataLabelIntoPercentByGrandTotal
                    }
                    value={this.state.settings.labelNumbersOfDecimals}
                    onChange={(event, value) =>
                      this.changeFn(
                        'labelNumbersOfDecimals',
                        value || event.target.value
                      )
                    }
                    label={
                      <div>
                        % Number of decimal places:{' '}
                        {this.state.settings.labelNumbersOfDecimals || 0}
                      </div>
                    }
                    max={8}
                    step={1}
                  />
                  <h5 style={{ marginBottom: 8 }}>Number Formatting</h5>
                  <Toggle
                    label="Use Thousands (K)"
                    labelPosition="right"
                    toggled={
                      this.state.settings.largeNumberNotationSuffix === 'K'
                    }
                    onToggle={(_ev, toggled) =>
                      this.changeFn(
                        'largeNumberNotationSuffix',
                        toggled ? 'K' : ''
                      )
                    }
                  />
                  <Toggle
                    label="Use Millions (M)"
                    labelPosition="right"
                    toggled={
                      this.state.settings.largeNumberNotationSuffix === 'M'
                    }
                    onToggle={(_ev, toggled) =>
                      this.changeFn(
                        'largeNumberNotationSuffix',
                        toggled ? 'M' : ''
                      )
                    }
                  />
                  <Toggle
                    label="Use Billions (B)"
                    labelPosition="right"
                    toggled={
                      this.state.settings.largeNumberNotationSuffix === 'B'
                    }
                    onToggle={(_ev, toggled) =>
                      this.changeFn(
                        'largeNumberNotationSuffix',
                        toggled ? 'B' : ''
                      )
                    }
                  />
                  <Toggle
                    label="Use Trillions (T)"
                    labelPosition="right"
                    toggled={
                      this.state.settings.largeNumberNotationSuffix === 'T'
                    }
                    onToggle={(_ev, toggled) =>
                      this.changeFn(
                        'largeNumberNotationSuffix',
                        toggled ? 'T' : ''
                      )
                    }
                  />
                  <Toggle
                    label="Use scientific notation in the labels"
                    labelPosition="right"
                    toggled={this.state.settings.useExponentialForRawValue}
                    onToggle={(_ev, toggled) =>
                      this.changeFn('useExponentialForRawValue', toggled)
                    }
                  />
                  <Toggle
                    label="Allow decimals on Y axis labels"
                    labelPosition="right"
                    toggled={this.state.settings.allowDecimals}
                    onToggle={(_ev, toggled) => {
                      if (!toggled) {
                        this.setState(
                          (prevState) => ({
                            settings: {
                              ...prevState.settings,
                              rawValueLabelNumbersOfDecimals: 0,
                            },
                          }),
                          () => {
                            this.changeFn('allowDecimals', toggled);
                          }
                        );
                      } else {
                        this.changeFn('allowDecimals', toggled);
                      }
                    }}
                  />
                  <Slider
                    disabled={!this.state.settings.allowDecimals}
                    value={this.state.settings.rawValueLabelNumbersOfDecimals}
                    style
                    onChange={(event, value) =>
                      this.changeFn(
                        'rawValueLabelNumbersOfDecimals',
                        value || event.target.value
                      )
                    }
                    label={
                      <div>
                        Number of decimal places:{' '}
                        {this.state.settings.rawValueLabelNumbersOfDecimals ||
                          0}
                      </div>
                    }
                    max={8}
                    step={1}
                  />
                  <h5 style={{ marginBottom: 8 }}>Scale and Position</h5>
                  <Slider
                    data-tip
                    data-for="slider-tooltip"
                    value={this.state.fontScale}
                    onChange={this.onChangeFontScale}
                    label={
                      <div>
                        Font size scale: {this.state.fontScale} x{' '}
                        <small style={{ float: 'right', display: 'contents' }}>
                          (approx.{' '}
                          {Math.round(
                            this.state.baseFontSize * this.state.fontScale
                          )}
                          px)
                        </small>
                      </div>
                    }
                    min={0.5}
                    max={3}
                    step={0.1}
                  />
                  <Tooltip
                    id="slider-tooltip"
                    place="right"
                  >{`Download only preview, won't be persisted between sessions.`}</Tooltip>
                  <Slider
                    value={this.state.settings.xAxisLabelRotation || 0}
                    onChange={(event, value) =>
                      this.changeFn(
                        'xAxisLabelRotation',
                        value || event.target.value
                      )
                    }
                    label={
                      <div>
                        X Axis Label Rotation:{' '}
                        {this.state.settings.xAxisLabelRotation || 0}°
                      </div>
                    }
                    min={-90}
                    max={90}
                    step={5}
                  />
                  <Slider
                    value={this.state.settings.dataLabelRotation || 0}
                    onChange={(event, value) =>
                      this.changeFn(
                        'dataLabelRotation',
                        value || event.target.value
                      )
                    }
                    label={
                      <div>
                        Data Label Rotation:{' '}
                        {this.state.settings.dataLabelRotation || 0}°
                      </div>
                    }
                    min={-90}
                    max={90}
                    step={5}
                  />
                  {xx && <h5 style={{ marginBottom: 8 }}>X axis labels</h5>}
                  {xx?.map((x, i) => (
                    <TextField
                      id={`${x.id}-${i}`}
                      key={`${x.id}-${i}`}
                      hintText="Type in..."
                      border
                      flat
                      value={
                        isEmpty(path([i], this.state.settings.xAxis))
                          ? ''
                          : path([i], this.state.settings.xAxis) ||
                            path(['title', 'text'], this.props.config.xAxis) ||
                            path(
                              [i, 'title', 'text'],
                              this.props.config.xAxis
                            ) ||
                            ''
                      }
                      onChange={(v) => this.changeFn('xAxis', v, i)}
                    />
                  ))}
                  {yy && <h5 style={{ marginBottom: 8 }}>Y axis labels</h5>}
                  {yy?.map((y, i) => (
                    <TextField
                      id={`${y.id}-${i}`}
                      key={`${y.id}-${i}`}
                      hintText="Type in..."
                      border
                      flat
                      value={
                        isEmpty(path([i], this.state.settings.yAxis))
                          ? ''
                          : path([i], this.state.settings.yAxis) ||
                            path(['title', 'text'], this.props.config.yAxis) ||
                            path(
                              [i, 'title', 'text'],
                              this.props.config.yAxis
                            ) ||
                            ''
                      }
                      onChange={(v) => this.changeFn('yAxis', v, i)}
                    />
                  ))}
                </section>
              )}

              {this.state.activeTab === 'colours' && (
                <section>
                  <h5 style={{ marginBottom: 8 }}>Colour palette</h5>
                  <div className={cls.paletteSettings}>
                    {this.state.settings.palette.map((colour, i) => (
                      <ColourPicker
                        square
                        key={`${i}`}
                        initColour={colour || HIGHCHARTS_COLOURS[i]}
                        onChange={(value) => this.changeFn('palette', value, i)}
                      />
                    ))}
                  </div>
                  <div>
                    <small>
                      Changing palette does not affect individual colours of
                      data points or lines but rather each data series. To
                      change first data series colour edit first colour on the
                      palette and so on. Close color picker to apply change.
                    </small>
                  </div>
                </section>
              )}

              {this.state.activeTab === 'export' && (
                <section
                  style={{ display: 'flex', flexDirection: 'column', gap: 12 }}
                >
                  <h5 style={{ marginBottom: 8 }}>Export settings</h5>
                  <Toggle
                    mini
                    label="Display title for export"
                    labelPosition="right"
                    toggled={this.state.showTitle}
                    onToggle={(_ev, toggled) =>
                      this.setState({ showTitle: toggled })
                    }
                  />
                  <TextField
                    fullWidth
                    disabled={!this.state.showTitle}
                    name="exportTitle"
                    value={this.state.exportTitle}
                    onChange={this.onExportTitleChange}
                  />

                  <Label label="Export target style template" />
                  <SelectPopoverLight
                    label={CHART_LABELS[this.state.exportTarget]}
                    popoverProps={{ zIndex: 1410 }}
                    renderItems={this.renderExportTargetItems}
                  />
                  <br />

                  <SelectField
                    label="File format"
                    value={this.state.fileFormat || OPTIONS[0]}
                    onChange={(e) => this.onFileFormatChange(e.value)}
                  >
                    {OPTIONS.map((fileFormat) => (
                      <SelectField.Item
                        key={fileFormat}
                        value={fileFormat}
                        primaryText={fileFormat.toUpperCase()}
                      />
                    ))}
                  </SelectField>

                  <SelectField
                    label="Image aspect ratio"
                    value={this.state.aspectRatio}
                    onChange={this.onAspectRatioChange}
                  >
                    <SelectField.Item
                      value="16×9"
                      primaryText="Full HD"
                      secondaryText="16×9"
                    />
                    <SelectField.Item
                      value="16×7"
                      primaryText="Ultra Wide"
                      secondaryText="16×7"
                    />
                    <SelectField.Item
                      value="4×3"
                      primaryText="PPT/Photo"
                      secondaryText="4×3"
                    />
                    <SelectField.Item
                      value="1×1"
                      primaryText="Square"
                      secondaryText="1×1"
                    />
                    <SelectField.Item
                      value="3×4"
                      primaryText="↻ PPT/Photo"
                      secondaryText="3×4"
                    />
                    <SelectField.Item
                      value="9×16"
                      primaryText="↻ Full HD"
                      secondaryText="9×16"
                    />
                  </SelectField>

                  <Toggle
                    mini
                    label={`Fixed target image width: ${this.state.exportFixedWidth} px`}
                    labelPosition="right"
                    toggled={this.state.isFixedWidth}
                    onToggle={(_ev, toggled) =>
                      this.setState({ isFixedWidth: toggled })
                    }
                  />
                  <Slider
                    disabled={this.state.isFixedWidth}
                    value={this.state.exportFixedWidth}
                    onChange={this.onExportFixedWidthChange}
                    min={800}
                    max={4000}
                    step={4}
                  />
                  <Slider
                    value={this.state.imageScale}
                    onChange={this.onChangeScale}
                    label={
                      <div>
                        Chart image scale: {this.state.imageScale} dpi{' '}
                        <small style={{ float: 'right', display: 'contents' }}>
                          (for improved resolution)
                        </small>
                      </div>
                    }
                    min={0.5}
                    max={4}
                    step={0.5}
                  />
                </section>
              )}
            </div>
            <div className={cls.chartSettingsTabs}>
              {SETTINGS_TABS.map((tab) => (
                <Button
                  text
                  size="m"
                  label={tab.label}
                  key={tab.id}
                  className={`${cls.chartSettingsTab} ${
                    this.state.activeTab === tab.id
                      ? cls.chartSettingsTabActive
                      : ''
                  }`}
                  onClick={() => this.onTabChange(tab.id)}
                />
              ))}
            </div>
          </Container>
        </Drawer>
      </div>
    );
  }
}
