import { where } from 'im/api/Query';
import reducerRegistry from 'im/api/registry';
import Resource from 'im/api/Resource';

const reducer = (actionTypes, action, state) => {
  /* eslint-disable */
  const [x, y, method, status] = action.type.split('/');
  /* eslint-enable */

  if (status === 'success') {
    return {
      ...state,
      data: {
        ...state.data,
        [method]: action.payload.calculations,
      },
    };
  }

  return state;
};

export const SurveyCalculations = new Resource(
  {
    root: '/api/v3/calculations',
    type: 'calculations',
    asyncReducers: reducer,
    reducerConfig: { hasNumericPending: true },
    api: {
      custom: {
        tagsForQuestion: {
          method: 'GET',
          path: where(null, {
            source: 'taggings',
            calculations: 'id_count',
            group: ['tag_title'],
          })
            .sort('tag_title')
            .filter('survey_answer_survey_question_id_eq', ':surveyQuestionId')
            .filter('survey_answer_survey_title_eq', ':surveyTitle')
            .toString(),
        },
        tagsForQuestionBySeries: {
          method: 'GET',
          path: where(null, {
            source: 'taggings',
            calculations: 'id_count',
            group: ['tag_title', 'grantee_survey_batch'],
          })
            .sort('tag_title')
            .filter('survey_answer_survey_question_id_eq', ':surveyQuestionId')
            .filter('survey_answer_survey_title_eq', ':surveyTitle')
            .toString(),
        },
      },
    },
  },
  'surveyCalculations'
);

reducerRegistry.register(SurveyCalculations);
export default SurveyCalculations.api;
