import React, { useState, useCallback } from 'react';
import { Field } from 'redux-form';
import {
  AutoCompleteField,
  Checkbox,
  Container,
  FormField,
  Table,
} from 'src/components/IMUI';
import { getOrgText } from 'src/services/DictionaryService';
import cls from './TrintDocumentField.module.css';

const TrintDocumentField = ({ name, grants, grantees, document }) => {
  const [isChecked, setIsChecked] = useState(false);

  const onCheck = useCallback((event) => {
    setIsChecked(event.target.checked);
  }, []);

  return (
    <Table.Row>
      <Table.Cell>
        {isChecked && (
          <Container>
            <Field
              component={({ input, val }) => {
                input.onChange(() => val);

                return (
                  <input
                    value={document.id}
                    name={`${name}.trint_id`}
                    type="hidden"
                  />
                );
              }}
              type="hidden"
              name={`${name}.trint_id`}
              val={document.id}
            />
            <Field
              component={({ input, val }) => {
                input.onChange(() => val);

                return (
                  <input
                    value={document.title}
                    name={`${name}.trint_title`}
                    type="hidden"
                  />
                );
              }}
              type="hidden"
              name={`${name}.trint_title`}
              val={document.title}
            />
          </Container>
        )}
        {document.title}
      </Table.Cell>

      <Table.Cell>
        <FormField
          anchorScrollName={`${name}.grant_id`}
          className={cls.autocompleteColumn}
        >
          {isChecked && grants && (
            <Field
              component={AutoCompleteField}
              hintText={`Type in ${getOrgText('grant')} name`}
              className={cls.trintDocumentDropdown}
              returnOnlyValue
              dataSource={grants}
              dataSourceConfig={{ text: 'name', value: 'id' }}
              name={`${name}.grant_id`}
              flat
            />
          )}
        </FormField>
      </Table.Cell>

      <Table.Cell>
        <FormField
          anchorScrollName={`${name}.grantee_id`}
          className={cls.autocompleteColumn}
        >
          {isChecked && grantees && (
            <Field
              component={AutoCompleteField}
              className={cls.trintDocumentDropdown}
              inputStyle={{ paddingLeft: 0 }}
              hintText={`Type in ${getOrgText('grantee')} name`}
              returnOnlyValue
              dataSource={grantees}
              dataSourceConfig={{ text: 'name', value: 'id' }}
              name={`${name}.grantee_id`}
              flat
            />
          )}
        </FormField>
      </Table.Cell>

      <Table.Cell>{document.excerpt}</Table.Cell>

      <Table.Cell actions width={24}>
        <span style={{ lineHeight: 2, cursor: 'pointer' }}>
          <Checkbox noLabel checked={isChecked} onCheck={onCheck} />
        </span>
      </Table.Cell>
    </Table.Row>
  );
};

export default TrintDocumentField;
