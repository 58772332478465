import axios from 'axios';
import Tracking from 'src/services/TrackingService';

const instance = axios.create({ baseURL: process.env.IMPACTMAPPER_API_SERVER });
instance.interceptors.request.use(
  (config) => ({ ...config, requestStart: Date.now() }),
  (error) => Promise.reject(error)
);

// Wrap network errors eg 500 errors which fail on CORS, to have expected structure
export const responseInterceptors = (logOut) => ({
  success: (res) => {
    const url = res.request.responseURL.replace(/^https?:\/\/[^/]+/, '');
    Tracking.event(
      'xhr',
      url.replace(/\b\d+\b/g, '__'),
      Date.now() - res.config.requestStart,
      Date.now() - res.config.requestStart
    );
    return res;
  },
  failure: (_error) => {
    let error = _error;
    if (error.response?.status === 401) {
      logOut();
    }
    if (error.message === 'Network Error') {
      error = { response: { data: { ...error } } };
    }
    return Promise.reject(error);
  },
});

export default instance;
