import classNames from 'classnames/bind';
import MUICheckbox from 'material-ui/Checkbox';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { Error, InputReduxForm } from 'src/components/IMUI/Forms/base';

import cls from './Checkbox.module.css';

const cx = classNames.bind(cls);

export const Checkbox = ({
  className,
  readOnly,
  noLabel,
  noValidation,
  error,
  input,
  meta,
  onCheck,
  ...muiCheckboxProps
}) => {
  const onChange = useCallback(
    (event, isInputChecked) => {
      if (input || onCheck) {
        InputReduxForm.onChange(input, onCheck)(event, isInputChecked);
      }
    },
    [input, onCheck]
  );

  return (
    <div>
      <MUICheckbox
        className={cx(
          'imui-checkbox',
          {
            'imui-checkbox-readonly': readOnly,
            'imui-checkbox-no-label': noLabel,
          },
          className
        )}
        {...input}
        {...muiCheckboxProps}
        checked={input ? input.value : muiCheckboxProps.checked}
        onCheck={onChange}
      />
      {!noValidation && <Error meta={meta} error={error} />}
    </div>
  );
};

Checkbox.propTypes = {
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  noLabel: PropTypes.bool,
  noValidation: PropTypes.bool,
  error: PropTypes.string,
  onCheck: PropTypes.func,
  ...InputReduxForm.propTypes,
};

Checkbox.defaultProps = {
  noValidation: true,
};

export default Checkbox;
