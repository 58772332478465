import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { PopoverLight, TextField, Button } from 'src/components/IMUI';

import SeriesPopover from '../../components/SeriesPopover';
import { canManageSurvey } from 'src/userStorage';

export default class ManageSeriesPopover extends Component {
  static propTypes = {
    ...SeriesPopover.propTypes,
    active: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    showAddSeries: PropTypes.bool,
    onSeriesAdd: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      showAddSeries: props.showAddSeries,
      seriesName: '',
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.showAddSeries !== this.state.showAddSeries) {
      this.setState({ showAddSeries: nextProps.showAddSeries });
    }
  }

  handleAddSeriesCancel = () => {
    this.setState({ seriesName: '', showAddSeries: false });
  };

  handleAddSeries = () => {
    const { seriesName } = this.state;
    this.props.onSeriesAdd(seriesName);
    this.handleAddSeriesCancel();
  };

  handleAddSeriesRequest = () => {
    this.setState({ showAddSeries: true });
  };

  handleSeriesNameChange = (seriesName) => {
    this.setState({ seriesName });
  };

  renderAddSeries = () => {
    const { showAddSeries, seriesName } = this.state;

    if (!showAddSeries) {
      return null;
    }

    return (
      <PopoverLight.Content>
        <TextField
          noValidation
          fullWidth
          flat
          border
          hintText="Type in..."
          name="series_name"
          value={seriesName}
          onChange={this.handleSeriesNameChange}
        />

        <PopoverLight.ContentActions>
          <Button
            disabled={!canManageSurvey() || !seriesName}
            secondary
            label="Add"
            onClick={this.handleAddSeries}
          />
          <Button
            negative
            label="Cancel"
            onClick={this.handleAddSeriesCancel}
          />
        </PopoverLight.ContentActions>
      </PopoverLight.Content>
    );
  };

  renderHeader = () => {
    const { showAddSeries } = this.state;

    if (!showAddSeries) {
      return null;
    }

    return <span>Add series</span>;
  };

  renderFooter = () => {
    const { showAddSeries } = this.state;

    if (showAddSeries) {
      return null;
    }

    return (
      <PopoverLight.Actions>
        <PopoverLight.ActionItem
          iconName="add-element"
          text="Add series"
          onClick={this.handleAddSeriesRequest}
        />
      </PopoverLight.Actions>
    );
  };

  render() {
    const { open, anchorEl, series, active, width } = this.props;

    return (
      <SeriesPopover
        open={open}
        anchorEl={anchorEl}
        series={series}
        activeSeries={active}
        width={width}
        onRequestClose={this.props.onRequestClose}
        renderFooter={this.renderFooter}
        renderHeader={this.renderHeader}
        renderChildren={this.renderAddSeries}
        onGoBack={this.handleAddSeriesCancel}
        onSeriesChange={this.props.onSeriesChange}
      />
    );
  }
}
