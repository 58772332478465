export const objectsToCsv = (data) => {
  const headers = Object.keys(data[0]);
  const rows = data
    .map((obj) => {
      return Object.values(obj)
        .map((val) => {
          if (typeof val === 'string') {
            // Escape double quotes with another double quote
            return '"' + val.replace(/"/g, '""') + '"';
          }
          return val;
        })
        .join(',');
    })
    .join('\n');

  return headers.join(',') + '\n' + rows;
};

export const replaceFileNameExtensionWithCsv = (filename) => {
  // Find the last occurrence of '.' to get the position of the file extension
  var dotIndex = filename.lastIndexOf('.');

  // Check if the dotIndex is found and greater than 0
  if (dotIndex > 0) {
    // Remove the file extension and replace it with .csv
    var csvFilename = filename.substring(0, dotIndex) + '.csv';
    return csvFilename;
  }
  // If there's no file extension, just add .csv to the filename
  return filename + '.csv';
};
