import PropTypes from 'prop-types';
import React from 'react';

const ChartTypeIcon = ({ type, active = false, color, style }) => {
  const iconColor = !color && active === true ? '#52cccc' : color || '#9AAAB8';
  const svgStyle = { width: '100%', height: 'auto', ...style };
  switch (type) {
    case 'area':
    case 'area:stacked':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="42"
          height="28"
          viewBox="0 0 42 28"
          style={svgStyle}
        >
          <path
            fill={iconColor}
            fillRule="nonzero"
            d="M38 26.001h3.034c.255-.003.5.097.683.279a.986.986 0 0 1 0 1.382.948.948 0 0 1-.683.279H.999a.941.941 0 0 1-.687-.252.969.969 0 0 1-.311-.672.977.977 0 0 1 .248-.7c.169-.19.407-.304.75-.316H5v-9.77L16.073 5.422l11.256 5.385L38 0v26.001z"
          />
        </svg>
      );
    case 'bar':
    case 'bar:stacked':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="37"
          height="33"
          viewBox="0 0 37 33"
          style={svgStyle}
        >
          <path
            fill={iconColor}
            fillRule="nonzero"
            d="M37 13.143H1.516V9.398h25.636v-6.74H1.516V.784c-.009-.27-.098-.457-.247-.59A.76.76 0 0 0 0 .785V32.24A.747.747 0 0 0 .758 33a.747.747 0 0 0 .758-.76v-1.872h31.696v-6.74H1.516v-3.745H37v-6.74z"
          />
        </svg>
      );
    case 'bubble':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="28"
          height="29"
          viewBox="0 0 28 29"
          style={svgStyle}
        >
          <path
            fill={iconColor}
            fillRule="nonzero"
            d="M6 25.5c0 3.333-5 3.333-5 0s5-3.333 5 0zm10-11c0 2-3 2-3 0s3-2 3 0zm-10 .501C6 19 0 19 0 15.001 0 11 6 11 6 15.001zm22-.501c0 3.333-5 3.333-5 0s5-3.333 5 0zm-9 10.001A4.5 4.5 0 1 1 14.499 20 4.502 4.502 0 0 1 19 24.501zm8 0c0 1.999-3 1.999-3 0 0-2.001 3-2.001 3 0zM6 3.5c0 3.333-5 3.333-5 0s5-3.333 5 0zm12 .001c0 4.665-7 4.665-7 0 0-4.668 7-4.668 7 0zm9 0c0 1.999-3 1.999-3 0 0-2.001 3-2.001 3 0z"
          />
        </svg>
      );
    case 'scatter':
      return (
        <svg
          width="42"
          height="29"
          viewBox="0 0 42 29"
          xmlns="http://www.w3.org/2000/svg"
          style={svgStyle}
        >
          <path
            fill={iconColor}
            d="M16.727 27.001h24.307c.255-.004.501.097.683.278.181.184.283.432.283.691 0 .259-.102.508-.283.691-.182.182-.428.282-.683.278h-40.035c-.253.011-.501-.078-.687-.252-.188-.172-.3-.415-.311-.672-.011-.258.076-.509.248-.699.169-.191.408-.305.75-.316h15.728zm-11.227-8.001c-.828 0-1.5-.672-1.5-1.5s.672-1.5 1.5-1.5 1.5.672 1.5 1.5-.672 1.5-1.5 1.5zm12.8-11c-.828 0-1.5-.672-1.5-1.5s.672-1.5 1.5-1.5 1.5.672 1.5 1.5-.672 1.5-1.5 1.5zm6.4 5c-.828 0-1.5-.672-1.5-1.5s.672-1.5 1.5-1.5 1.5.672 1.5 1.5-.672 1.5-1.5 1.5zm7.4-3c-.828 0-1.5-.672-1.5-1.5s.672-1.5 1.5-1.5 1.5.672 1.5 1.5-.672 1.5-1.5 1.5zm-20.2 4c-.828 0-1.5-.672-1.5-1.5s.672-1.5 1.5-1.5 1.5.672 1.5 1.5-.672 1.5-1.5 1.5zm25.6-11c-.828 0-1.5-.672-1.5-1.5s.672-1.5 1.5-1.5 1.5.672 1.5 1.5-.672 1.5-1.5 1.5z"
            fillRule="nonzero"
          />
        </svg>
      );
    case 'column':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="42"
          height="32"
          viewBox="0 0 42 32"
          style={svgStyle}
        >
          <path
            fill={iconColor}
            fillRule="nonzero"
            d="M16.727 0v30.06H11.96V12.606H3.38V30.06H1a.939.939 0 0 0-.75.316.977.977 0 0 0-.248.699c.011.257.123.5.31.672A.941.941 0 0 0 1 32h40.035c.255.004.5-.097.683-.278a.986.986 0 0 0 0-1.383.948.948 0 0 0-.683-.278H38.65V4.848h-8.58V30.06h-4.765V0h-8.58z"
          />
        </svg>
      );
    case 'heatmap':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="22"
          viewBox="0 0 40 22"
          style={svgStyle}
        >
          <path
            fill={iconColor}
            fillRule="evenodd"
            d="M0 16h12v6H0v-6zm14 0h12v6H14v-6zm14 0h12v6H28v-6zM0 8h12v6H0V8zm14 0h12v6H14V8zm2 4h8v-2h-8v2zm12-4h12v6H28V8zM0 0h12v6H0V0zm14 0h12v6H14V0zm14 0h12v6H28V0zm2 4h8V2h-8v2z"
          />
        </svg>
      );
    case 'line':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="42"
          height="26"
          viewBox="0 0 42 26"
          style={svgStyle}
        >
          <path
            fill={iconColor}
            fillRule="nonzero"
            d="M16.727 24.001h24.307c.255-.003.5.097.683.279a.986.986 0 0 1 0 1.382.948.948 0 0 1-.683.279H.999a.941.941 0 0 1-.687-.252.969.969 0 0 1-.311-.672.977.977 0 0 1 .248-.7c.169-.19.407-.304.75-.316h15.728zM34.089 1.066l-7.465 14.401-5.468-7.245L14.046 16 6 9.866l1.378-1.778 6.4 4.89 7.555-8.311 4.934 6.578L32.09 0l2 1.066z"
          />
        </svg>
      );
    case 'pie':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="31"
          height="31"
          viewBox="0 0 31 31"
          style={svgStyle}
        >
          <path
            fill={iconColor}
            fillRule="nonzero"
            d="M14.693 0C6.505.416 0 7.183 0 15.475 0 24.033 6.938 30.97 15.497 30.97c4.01 0 7.665-1.527 10.417-4.027L15.071 16.1c-.219-.156-.306-.362-.33-.626V0c-.016.002-.033-.001-.048 0zm1.56 0v14.115L28.5 7.04A15.471 15.471 0 0 0 16.252 0zm13.004 8.351l-12.544 7.253 10.264 10.264a15.43 15.43 0 0 0 4.016-10.394c0-2.57-.63-4.99-1.737-7.123z"
          />
        </svg>
      );
    case 'column:stacked':
    case 'stacked':
    case 'stackedColumn':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="42"
          height="32"
          viewBox="0 0 42 32"
          style={svgStyle}
        >
          <path
            fill={iconColor}
            fillRule="nonzero"
            d="M16.727 0h8.579v30.06h4.766V4.848h8.579V30.06h2.383c.255-.004.5.096.683.278a.986.986 0 0 1 0 1.383.948.948 0 0 1-.683.278H.999a.941.941 0 0 1-.687-.252.969.969 0 0 1-.311-.672.977.977 0 0 1 .248-.699.939.939 0 0 1 .75-.316h2.383V12.606h8.579V30.06h4.766V0zM18 1v17h6V1h-6zm13.5 5v14h6V6h-6zm-27 8v8h6v-8h-6z"
          />
        </svg>
      );
    default:
      throw new Error(`Unknown chart type icon: ${type}`);
  }
};

ChartTypeIcon.propTypes = {
  type: PropTypes.oneOf([
    'bar',
    'bar:stacked',
    'column',
    'column:stacked',
    'stacked',
    'stackedColumn',
    'line',
    'area',
    'area:stacked',
    'pie',
    'bubble',
    'heatmap',
  ]),
  active: PropTypes.bool,
  style: PropTypes.object,
  color: PropTypes.string,
};

export default ChartTypeIcon;
