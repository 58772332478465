import React from 'react';
import PropTypes from 'prop-types';
import uniq from 'ramda/src/uniq';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, submit } from 'redux-form';
import GrantSelector from 'src/components/GrantSelector/GrantSelector';
import { Section, Container, Actions, Button } from 'src/components/IMUI';
import { handleSubmitFail } from 'src/utils/validation';
import {
  findMatch,
  findMatchReport,
  flatProjectReports,
  flatten,
} from 'src/serializers/projectSerializer';
import { getOrgText, getText } from 'src/services/DictionaryService';
import { isBefore, toLocaleDate } from 'src/utils/date';
import { canManageProject } from 'src/userStorage';
import { bindActionCreators } from 'redux';
import cls from 'src/components/AddProjectForm/GrantSelectorForm.module.css';
const reportTableValues = [
  { path: 'name', label: 'Report', defaultSort: true, ascending: true },
  { path: 'report_type', label: 'Type' },
  { path: 'grantee_name', label: getText('Grantee') || getOrgText('Grantee') },
  { path: 'grant_name', label: getText('Grant') || getOrgText('Grant') },
  { path: 'period', label: 'Period', className: cls.fixedColumn },
  { path: 'code', label: 'Code', className: cls.fixedColumn },
  {
    path: 'date',
    label: 'Created',
    className: cls.fixedColumn,
    type: 'date',
    customMatch: (a, b) => isBefore(a, b),
    valueTransform: toLocaleDate,
  },
];
export class ReportSelectorForm extends React.PureComponent {
  static propTypes = {
    project_reports: PropTypes.array,
    selected_projects: PropTypes.array,
    reportSet: PropTypes.array.isRequired,
    initialValues: PropTypes.object,
    change: PropTypes.func,
    overview: PropTypes.func,
    reset: PropTypes.func,
    dirty: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
  };
  static defaultProps = {
    formValues: {},
    selected_projects: [],
    project_reports: [],
    initialValues: { project_reports: [] },
  };

  handleReset = () => {
    this.props.reset();
  };
  isAddedReport = (entity) =>
    this.props.project_reports.some(findMatchReport(entity));
  prevAddReport = (entity) =>
    this.props.initialValues.project_reports.some(findMatchReport(entity));
  isCheckReport = (entity) =>
    this.props.selected_projects.some(findMatchReport(entity));
  selectedToAddReport = () =>
    this.props.selected_projects.filter((el) => !this.isAddedReport(el));
  selectedToRemReport = () =>
    this.props.selected_projects.filter((el) => this.isAddedReport(el));
  handleToggleSelectProject = (entity, isChecked) =>
    isChecked
      ? this.props.change(
          'selected_projects',
          uniq(this.props.selected_projects.concat(flatten(entity)))
        )
      : this.props.change(
          'selected_projects',
          this.props.selected_projects.filter(
            (pr) => !flatten(entity).some(findMatch(pr))
          )
        );
  handleAddRows = (el) => {
    this.props.change(
      'project_reports',
      uniq(this.props.project_reports.concat(flatProjectReports(el)))
    );
    this.clearSelection();
  };
  handleRemoveRows = (el) => {
    this.props.change(
      'project_reports',
      this.props.project_reports.filter(
        (pr) => !flatProjectReports(el).some(findMatchReport(pr))
      )
    );
    this.clearSelection();
  };
  clearSelection = () => this.props.change('selected_projects', []);

  render() {
    return (
      <Section collapsed fullHeight>
        <GrantSelector
          type="reports"
          key="reports"
          tableValues={reportTableValues}
          dictionaryPriority={'project'}
          data={this.props.reportSet}
          isCheck={this.isCheckReport}
          prevAdd={this.prevAddReport}
          isAdded={this.isAddedReport}
          onAdd={this.handleAddRows}
          onRemove={this.handleRemoveRows}
          onToggleSelect={this.handleToggleSelectProject}
          onSelectedRowsReset={this.clearSelection}
          selectedToRemoveLength={this.selectedToRemReport().length}
          selectedToAddLength={this.selectedToAddReport().length}
          handleRemoveSelected={() =>
            this.handleRemoveRows(this.selectedToRemReport())
          }
          handleAddedSelected={() =>
            this.handleAddRows(this.selectedToAddReport())
          }
        />

        {canManageProject() && (
          <Section type="sticky-footer" secondary={false}>
            <Container horizontal>
              <Actions>
                <Button
                  size="l"
                  label="Overview"
                  onClick={this.props.overview}
                />
              </Actions>
              <Actions>
                {this.props.dirty && (
                  <Button
                    size="l"
                    alert
                    secondary
                    label="Reset Changes"
                    disabled={!this.props.dirty}
                    onClick={this.handleReset}
                  />
                )}
                <Button
                  size="l"
                  label="Update autotagging Reports"
                  disabled={!this.props.dirty}
                  onClick={this.props.handleSubmit}
                />
              </Actions>
            </Container>
          </Section>
        )}
      </Section>
    );
  }
}

export default connect(
  (state, props) => ({
    selected_projects: formValueSelector(props.form)(
      state,
      'selected_projects'
    ),
    project_reports: formValueSelector(props.form)(state, 'project_reports'),
  }),
  (dispatch) => bindActionCreators({ submitForm: submit }, dispatch)
)(
  reduxForm({
    enableReinitialize: true,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmitFail: (errors) => handleSubmitFail(errors),
  })(ReportSelectorForm)
);
