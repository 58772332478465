import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Progress } from 'src/components/IMUI';
import cls from './Tree.module.css';
const cx = classNames.bind(cls);

const Tree = ({ className, children, pending, ...rest }) => (
  <div className={cls.treeContainer}>
    {pending && (
      <div className={cls.treePendingOverlay}>
        <Progress />
      </div>
    )}
    <ul className={cx(cls.tree, className)} {...rest}>
      {children}
    </ul>
  </div>
);

Tree.propTypes = {
  pending: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};
export default Tree;
