import React from 'react';
import PropTypes from 'prop-types';
import MappingItem, { isUnmappedWithWarning, isSkipped } from './MappingItem';
import MappingItemSelector from './MappingItemSelector';
import { getOrgText } from 'src/services/DictionaryService';

export const isSameField = (previous, curr) =>
  curr?.headerName === previous?.headerName &&
  curr?.imColumn === previous?.imColumn;

const matchTexts = (field, search) =>
  field?.length > 0 &&
  search?.length > 0 &&
  (field
    ?.toLocaleLowerCase()
    .trim()
    .includes(search.toLocaleLowerCase().trim()) ||
    getOrgText(field ?? '')
      .toLocaleLowerCase()
      .trim()
      .includes(search.toLocaleLowerCase().trim()));

export default class MappingItemEditable extends React.Component {
  static propTypes = {
    input: PropTypes.object,
    meta: PropTypes.object,
    csvColumn: PropTypes.string,
    formColumns: PropTypes.object,
    isEdit: PropTypes.bool,
    onFieldTouch: PropTypes.func,
    initialValues: PropTypes.object,
  };

  static defaultProps = { formColumns: {}, initialValues: {} };
  state = {
    selectorOpen: false,
    mergeInput: null,
    showColumnsMergeWarning: false,
  };

  onSelectorOpen = () => {
    this.setState({ selectorOpen: true });
  };

  onSelectorClose = () => {
    this.props.onFieldTouch(this.props.input.name);
    this.setState({
      selectorOpen: false,
      mergeInput: null,
      showColumnsMergeWarning: false,
    });
  };

  onColumnsMergeCancel = () => {
    this.setState({
      selectorOpen: false,
      mergeInput: null,
      showColumnsMergeWarning: false,
    });
  };

  onColumnChange = (current) => {
    this.props.input.onChange(current);
  };

  onColumnsMerge = () => {
    this.onColumnChange(this.state.mergeInput);
    this.onColumnsMergeCancel();
  };

  onColumnSkip = () => {
    const current = {
      column: 'Skipped',
      skip: true,
      imColumn: this.props.input.value?.imColumn,
      customField: this.props.input.value?.customField,
    };
    this.onColumnChange(current);
  };

  onAddCustomField = (headerName, customField) => {
    const current = {
      headerName,
      customField,
      imColumn: `CUSTOM:${customField}`,
      column: customField,
      readableColumn: `Custom field: ${customField}`,
    };
    this.onColumnChange(current);
  };

  onColumnSelect = (
    ev,
    { headerName, imColumn, readableColumn, customField }
  ) => {
    ev?.preventDefault();
    if (isSameField(this.props.input.value, { headerName, imColumn }))
      return null;

    const needMerge = Object.values(this.props.formColumns).some(
      (v) => v.column && isSameField(v, { headerName, imColumn })
    );
    const current = {
      headerName,
      imColumn,
      column: readableColumn,
      readableColumn,
      customField,
    };
    if (needMerge) {
      return this.setState({
        mergeInput: current,
        showColumnsMergeWarning: true,
      });
    }

    this.onSelectorClose();
    return this.onColumnChange(current);
  };

  onColumnFilter = (searchText) => {
    const shouldNotFilter = searchText?.length === 0;
    const results = Object.values(this.props.initialValues?.columns ?? {})
      .map(({ headerName, imColumn, readableColumn, customField }) => ({
        headerName,
        imColumn,
        readableColumn,
        customField,
      }))
      .filter(
        (column) =>
          shouldNotFilter ||
          (column.readableColumn &&
            matchTexts(column.readableColumn, searchText))
      );
    return results;
  };

  renderDropdown = () => {
    const _isUnmappedWithWarning = isUnmappedWithWarning(
      this.props.input.value,
      this.props.meta
    );
    const _isSkipped = isSkipped(this.props.input.value);
    const selectedStatus =
      (_isSkipped && 'skipped') || (_isUnmappedWithWarning && 'warning') || '';
    return (
      <MappingItemSelector
        open={this.state.selectorOpen}
        input={this.props.input}
        formColumns={this.props.formColumns}
        showColumnsMergeWarning={this.state.showColumnsMergeWarning}
        selectedStatus={selectedStatus}
        mergeColumn={this.state.mergeInput?.column}
        onSelectorOpen={this.onSelectorOpen}
        onSelectorClose={this.onSelectorClose}
        onColumnFilter={this.onColumnFilter}
        onColumnSkip={this.onColumnSkip}
        onAddCustomField={this.onAddCustomField}
        onColumnsMerge={this.onColumnsMerge}
        onColumnsMergeCancel={this.onColumnsMergeCancel}
        onColumnSelect={this.onColumnSelect}
      />
    );
  };

  render() {
    const { formColumns, input, meta, csvColumn, isEdit } = this.props;
    return (
      <MappingItem
        isEdit={isEdit}
        csvColumn={csvColumn}
        value={input.value || {}}
        meta={meta}
        formColumns={formColumns}
        renderDropdown={this.renderDropdown}
      />
    );
  }
}
