import React from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';
import { TextField, Container } from 'src/components/IMUI';
import Survey from 'src/components/Survey/Survey';
import {
  SECTION_BREAK,
  MULTIPLE_CHOICE,
  SINGLE_MATRIX,
} from 'src/data/questionTypes';
import { conditionsCheck } from 'src/utils/surveysConditionalLogic';

import { Icon } from 'im/ui/Icon';

import QuestionEdit from './components/QuestionEdit';
import QuestionPicker from './components/QuestionPicker';
import QuestionPreview from './components/QuestionPreview';
import StatusIndicator from './components/StatusIndicator';

import cls from './QuestionsList.module.css';
import { canBuildSurvey } from 'src/userStorage';

export default class QuestionsList extends React.PureComponent {
  static propTypes = {
    survey: PropTypes.object.isRequired,
    completeness: PropTypes.object.isRequired,
    onQuestionAdd: PropTypes.func.isRequired,
    onQuestionMove: PropTypes.func.isRequired,
    onQuestionClick: PropTypes.func.isRequired,
    onQuestionDeselect: PropTypes.func.isRequired,
    onScrollToQuestion: PropTypes.func.isRequired,
    changeQuestionProperty: PropTypes.func.isRequired,
    updateQuestion: PropTypes.func.isRequired,
    onQuestionDelete: PropTypes.func.isRequired,
    onQuestionClone: PropTypes.func.isRequired,
    onQuestionTagAdd: PropTypes.func.isRequired,
    onQuestionTagRemove: PropTypes.func.isRequired,
    onSaveSurvey: PropTypes.func.isRequired,
    selectedQuestionId: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    tagsWithTagGroups: PropTypes.array.isRequired,
  };

  state = { editCounterQuestionId: null, questionPickerPosition: null };

  componentWillReceiveProps(nextProps, nextState) {
    const { selectedQuestionId, survey } = this.props;
    const { editCounterQuestionId, handleSetQuestionPickerIndex } = this.state;
    if (
      nextProps.selectedQuestionId !== selectedQuestionId ||
      survey.questions !== nextProps.survey.questions
    ) {
      if (editCounterQuestionId !== nextProps.selectedQuestionId) {
        this.setState({ editCounterQuestionId: null });
      }
      if (nextProps.selectedQuestionId !== selectedQuestionId) {
        if (
          nextProps.selectedQuestionId &&
          nextState.handleSetQuestionPickerIndex ===
            handleSetQuestionPickerIndex
        ) {
          this.handleSetQuestionPickerIndex(null);
        }
        this.props.onScrollToQuestion(
          nextProps.selectedQuestionId || selectedQuestionId
        );
      }
    }
  }

  handleMoveQuestion = (question, positionOrOffset, isRelative = false) => {
    const { questions } = this.props.survey;
    const currentIndex = questions.findIndex((q) => q.id === question.id);
    let newIndex;
    if (isRelative) {
      newIndex = currentIndex + positionOrOffset;
    } else {
      newIndex = positionOrOffset - 1;
    }
    newIndex = Math.max(0, Math.min(questions.length - 1, newIndex));

    if (question.id === this.state.editCounterQuestionId) {
      this.setState({ editCounterQuestionId: null });
    }

    if (newIndex === currentIndex) return;

    this.props.onQuestionMove(currentIndex, newIndex);
    this.props.onScrollToQuestion(question.id);
  };

  handleEditCounter = (question) => {
    this.setState({ editCounterQuestionId: question.id });
  };
  handleSetQuestionPickerIndex = (questionPickerPosition) => {
    this.setState({ questionPickerPosition });
    if (questionPickerPosition) {
      this.props.onQuestionClick(null);
    }
  };

  questionIsRequired(question) {
    const { isLayout, type, settings, required } = question;
    if (isLayout) {
      return false;
    }
    if (type === MULTIPLE_CHOICE || type === SINGLE_MATRIX) {
      return settings && settings.minAnswers > 0;
    }
    return required;
  }

  renderQuestionMover = (question, index) => {
    const { survey } = this.props;
    const { editCounterQuestionId } = this.state;
    const questionsCount = survey.questions.length;
    const isFirst = index === 0;
    const isLast = index === questionsCount - 1;
    let position = index + 1;
    return (
      <div className={cls.questionMover}>
        {editCounterQuestionId === question.id ? (
          <div className={cls.questionMoverForm}>
            <Icon
              name="check"
              className={cls.questionMoveConfirm}
              onClick={() => this.handleMoveQuestion(question, position)}
            />
            <TextField
              disabled={!canBuildSurvey()}
              flat
              border
              noValidation
              name="p_c"
              value={position}
              ref={(ref) => ref?.input?.focus()}
              onChange={(value) => (position = value)}
              onKeyUp={(ev) =>
                ev.keyCode === 13 && this.handleMoveQuestion(question, position)
              }
            />
          </div>
        ) : (
          <div className={cls.questionMoverCounter}>
            <span className={cls.quesitonMoverActions}>
              {!isFirst && (
                <Icon
                  disabled={!canBuildSurvey()}
                  name="arrow-up"
                  onClick={() => this.handleMoveQuestion(question, -1, true)}
                />
              )}
              {!isLast && (
                <Icon
                  disabled={!canBuildSurvey()}
                  name="arrow-down"
                  onClick={() => this.handleMoveQuestion(question, 1, true)}
                />
              )}
              {questionsCount > 1 && (
                <Icon
                  disabled={!canBuildSurvey()}
                  name="edit"
                  onClick={() => this.handleEditCounter(question)}
                />
              )}
            </span>
            {index + 1}
          </div>
        )}
      </div>
    );
  };

  renderSectionMarker(question, index, pageInfo) {
    if (
      (question.type !== SECTION_BREAK && index !== 0) ||
      pageInfo.pages.length === 1
    )
      return null;
    let pageNumber =
      question.settings && question.settings.sectionNewPage
        ? pageInfo.pageStarters[question.id]
        : null;
    if (index === 0) pageNumber = 1;
    return (
      !!pageNumber && (
        <div className={cls.questionSectionPageMarker}>
          {' '}
          <div>Page: {pageNumber}</div>{' '}
        </div>
      )
    );
  }

  renderStatusIndicators(question) {
    const { completeness, survey, onQuestionClick } = this.props;
    const hasConditions = conditionsCheck(question, survey) !== null;
    const hasTags = question.tags?.length > 0;
    const acceptAttachment = question.has_attachment;
    const hasI18nErrors =
      survey.languages.length > 1 &&
      !!completeness.questions[question.id].errors.length;
    return (
      <Container className={cls.statusIndicators}>
        {hasI18nErrors && (
          <StatusIndicator
            status="alert"
            iconName="translate"
            text="Translation missing"
            onClick={() => onQuestionClick(question)}
          />
        )}
        {this.questionIsRequired(question) && (
          <StatusIndicator
            status="warning"
            iconName="asterix"
            text="Required"
            onClick={() => onQuestionClick(question)}
          />
        )}
        {hasConditions && (
          <StatusIndicator
            iconName="conditional"
            text="Conditional"
            onClick={() => onQuestionClick(question)}
          />
        )}
        {hasTags && (
          <StatusIndicator
            iconName="tag"
            text={question.multiTags ? 'Accept Multiple Tags' : 'Accept Tags'}
            onClick={() => onQuestionClick(question)}
          />
        )}
        {acceptAttachment && (
          <StatusIndicator
            iconName="file"
            text="Accept Attachment"
            onClick={() => onQuestionClick(question)}
          />
        )}
      </Container>
    );
  }

  renderQuestion = (question, index, pagesInfo) => {
    const { onQuestionDeselect, onQuestionClick, completeness } = this.props;
    const { survey, selectedQuestionId, tagsWithTagGroups } = this.props;
    const { questionPickerPosition } = this.state;

    const isSelected = selectedQuestionId && selectedQuestionId === question.id;
    const name = `question-${question.id}`;

    return (
      <Element name={name} key={name} className={cls.questionItem}>
        {this.renderSectionMarker(question, index, pagesInfo)}
        {this.renderQuestionMover(question, index)}
        {index === 0 &&
          questionPickerPosition !== index &&
          selectedQuestionId !== question.id &&
          index !== survey.questions.length - 1 && (
            <div className={cls.addQuestion}>
              <button onClick={() => this.handleSetQuestionPickerIndex(-1)}>
                ＋
              </button>
            </div>
          )}
        {isSelected ? (
          <QuestionEdit
            question={question}
            tagsWithTagGroups={tagsWithTagGroups}
            index={index}
            completeness={completeness}
            onClose={onQuestionDeselect}
            onChangeQuestionProperty={this.props.changeQuestionProperty}
            onUpdateQuestion={this.props.updateQuestion}
            onDelete={this.props.onQuestionDelete}
            onClone={this.props.onQuestionClone}
            onAddTag={this.props.onQuestionTagAdd}
            onRemoveTag={this.props.onQuestionTagRemove}
            onSaveSurvey={this.props.onSaveSurvey}
          />
        ) : (
          <QuestionPreview
            activeLanguage={survey.activeLanguage}
            selected={isSelected}
            disabled={isSelected}
            overText="Add here"
            question={question}
            tagsWithTagGroups={tagsWithTagGroups}
            onClick={onQuestionClick}
            index={index}
          />
        )}
        {this.renderStatusIndicators(question)}
        {questionPickerPosition !== index &&
          selectedQuestionId !== question.id &&
          index !== survey.questions.length - 1 && (
            <div className={cls.addQuestion}>
              <button onClick={() => this.handleSetQuestionPickerIndex(index)}>
                ＋
              </button>
            </div>
          )}
      </Element>
    );
  };

  renderQuestionPicker() {
    const { survey, onQuestionAdd } = this.props;
    const { questionPickerPosition } = this.state;
    return (
      <Element name="questionPicker" key="questionPicker">
        <QuestionPicker
          position={questionPickerPosition}
          survey={survey}
          onSetQuestionPickerIndex={this.handleSetQuestionPickerIndex}
          onQuestionAdd={onQuestionAdd}
        />
      </Element>
    );
  }

  render() {
    const { questions } = this.props.survey;
    const { questionPickerPosition } = this.state;
    // TODO should happen in componentWllReceiveProps?
    const pagesInfo = Survey.getPagesInfo(questions);
    const items = questions.map((question, index) =>
      this.renderQuestion(question, index, pagesInfo)
    );
    items.splice(
      questionPickerPosition !== null
        ? questionPickerPosition + 1
        : questions.length,
      0,
      this.renderQuestionPicker()
    );
    return <div className={cls.surveyElementsList}>{items}</div>;
  }
}
