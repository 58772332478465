import React from 'react';
import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import { connect } from 'react-redux';
import { reduxForm, Field, change } from 'redux-form';
import isEmail from 'validator/lib/isEmail';
import { getToken } from 'src/actionCreators/authActionCreators';
import AuthForm from 'src/components/AuthForm/AuthForm';
import { Button, FormField, TextField } from 'src/components/IMUI';
import history from 'src/historyInstance';
import createValidator from 'src/utils/validation';
import cls from './Login.module.css';
import { clearStorage } from 'src/services/AuthService';

@connect(pick(['auth']), { getToken, change })
class LoginForm extends React.PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    getToken: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    untouch: PropTypes.func.isRequired,
    location: PropTypes.object,
    auth: PropTypes.object,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.errors !== this.props.auth.errors) {
      this.props.change('password', '');
      this.props.change('otp', '');
      this.props.untouch('password', 'otp');
      this.forceUpdate();
    }
  }
  componentDidMount() {
    clearStorage();
  }
  onSubmit = ({ email, password, otp }) => {
    this.props.getToken({ email, password, otp }).then((payload) => {
      if (payload.failure) return;
      if (payload.errors == 'OFFLINE') return;
      history.replace({ pathname: '/analysis' });
    });
  };

  render() {
    const isProd =
      process.env.NODE_ENV === 'production' &&
      process.env.IMPACTMAPPER_API_SERVER?.includes(
        '//api-prod.impactmapper.com'
      );
    const FEATURE_FLAG_REGISTER = !isProd;
    return (
      <AuthForm
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        title="Login"
        submitButtonLabel="Login"
        pending={this.props.auth.pending && !this.props.auth.twoFactor}
        errors={this.props.auth.errors}
        errorsTemplate={{
          403: 'Authentication failed.\nEmail or password is incorrect',
          422: 'Problems with authentication',
          500: 'Server cannot process your request. Please try again later',
        }}
        info={
          (this.props.location.state || {}).resetPasswordSuccess &&
          'Password has been changed successfully'
        }
        additionalAction={
          <div>
            <Button
              text
              dark
              underlined
              type="button"
              label="Forgot password?"
              onClick={() => history.push('/password/forgot')}
            />
            {FEATURE_FLAG_REGISTER && (
              <Button
                text
                dark
                underlined
                type="button"
                label="Register"
                onClick={() => history.push('/register')}
              />
            )}
          </div>
        }
      >
        {this.props.auth.twoFactor && (
          <div>
            <p className={cls.loginInfo}>
              Please check your phone for SMS code to login
            </p>
            <FormField>
              <Field
                fullWidth
                component={TextField}
                name="otp"
                hintText="SMS code"
                label="SMS code"
              />
            </FormField>
          </div>
        )}
      </AuthForm>
    );
  }
}

export function validate(values) {
  const not = (fn) => (v) => !fn(v);
  const isDefined = (v) => Boolean(v);
  return createValidator({
    email: [
      [not(isDefined), () => 'Email is required'],
      [not(isEmail), () => 'It is not a valid email'],
    ],
    password: [[not(isDefined), () => 'Password is required']],
  })(values);
}

export default reduxForm({ form: 'loginForm', validate })(LoginForm);
