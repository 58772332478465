import reducerRegistry from 'im/api/registry';
import ResourceCollection from 'im/api/ResourceCollection';

const headers = { 'Content-Type': 'multipart/form-data' };

const apiConfig = {
  root: '/api/v3/report_uploads',
  type: 'report_uploads',
  api: {
    allow: ['destroy'],
    custom: {
      uploadReport: {
        headers,
        method: 'POST',
      },
    },
  },
};

export const ReportUpload = new ResourceCollection(apiConfig, 'reportUploads');
reducerRegistry.register(ReportUpload);

const ReportUploadApi = ReportUpload.api;
export default ReportUploadApi;
