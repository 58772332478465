import axios from 'src/axiosInstance';
import {
  GRANTS__FILTERS_GET_REQUEST,
  GRANTS__FILTERS_GET_SUCCESS,
  GRANTS__FILTERS_GET_FAILURE,
} from 'src/constants';

function doGetGrantsFilters() {
  return axios
    .get('/v1/grants/filter_attributes')
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export function getGrantsFilters() {
  return async (dispatch) => {
    dispatch({ type: GRANTS__FILTERS_GET_REQUEST });
    const { response, error } = await doGetGrantsFilters();
    if (response) {
      dispatch({ type: GRANTS__FILTERS_GET_SUCCESS, payload: response });
    } else {
      dispatch({
        type: GRANTS__FILTERS_GET_FAILURE,
        payload: error,
        error: true,
      });
    }
  };
}
