import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import { Checkbox, ListItemSimple } from '../IMUI';

import cls from './TagSelectorCategory.module.css';

const cx = classNames.bind(cls);

export default class TagSelectorTag extends React.PureComponent {
  static propTypes = {
    tag: PropTypes.object,
    isSelected: PropTypes.bool,
    tip: PropTypes.string,

    onClick: PropTypes.func.isRequired,
  };

  render() {
    const { tag, tip, isSelected } = this.props;

    return (
      <div data-tip={tip}>
        <ListItemSimple
          className={cx('tagSelector-popover-tag')}
          onClick={() => this.props.onClick(tag)}
        >
          <Checkbox readOnly label={tag.title} checked={isSelected} />
        </ListItemSimple>
      </div>
    );
  }
}
