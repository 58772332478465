import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import cls from './tables.module.css';

const cx = classNames.bind(cls);

const Row = (props) => {
  const {
    className,
    footer,
    children,
    onClick,
    noBorder,
    borderDashed,
    ...rest
  } = props;
  return (
    <tr
      className={cx(
        'imui-table-row',
        {
          'imui-table-row-clickable': !!onClick,
          'imui-table-row-no-border': noBorder,
          'imui-table-row-border-dashed': borderDashed,
          'imui-table-row-footer': footer,
        },
        className
      )}
      onClick={onClick}
      {...rest}
    >
      {children}
    </tr>
  );
};

Row.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  className: PropTypes.string,
  footer: PropTypes.bool,
  noBorder: PropTypes.bool,
  borderDashed: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Row;
