import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import { Container } from 'src/components/IMUI';

import cls from './PopoverContent.module.css';

const cx = classNames.bind(cls);

const Actions = ({ className, children, ...rest }) => (
  <Container
    {...rest}
    horizontal
    className={cx(cls.popoverContentActions, className)}
  >
    {children}
  </Container>
);

Actions.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

const PopoverContent = ({ children, className }) => (
  <div className={cx(cls.popoverContent, className)}>{children}</div>
);

PopoverContent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

PopoverContent.Actions = Actions;

export default PopoverContent;
