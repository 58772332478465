import React from 'react';
import { Container, Section, Table } from 'src/components/IMUI';
import TableComposition from 'src/components/IMUI/Tables/TableComposition';

const ExamplesForAutoCleaning = () => {
  return (
    <Container>
      <h5>Auto-cleaning</h5>
      <TableComposition>
        <Table.Head>
          <Table.HCell width={200} text={'Attribute'} />
          <Table.HCell width={200} text={'Original'} />
          <Table.HCell width={200} text={'Auto Cleaned'} />
          <Table.HCell text={'Explanation'} />
        </Table.Head>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Email</Table.Cell>
            <Table.Cell>user@example.com&lt;space&gt;</Table.Cell>
            <Table.Cell>user@example.com</Table.Cell>
            <Table.Cell>
              <li>Removes extra whitespaces</li>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </TableComposition>
    </Container>
  );
};

export default ExamplesForAutoCleaning;
