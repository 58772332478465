import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import identity from 'ramda/src/identity';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFormValues, getFormSyncWarnings } from 'redux-form';
import uploaderSecureMappingApi from 'src/api/UploaderSecureMapping';
import {
  Button,
  Section,
  Container,
  Actions,
  Expandable,
  Divider,
} from 'src/components/IMUI/index';
import colors from 'src/css/constants.json';
import history from 'src/historyInstance';
import { where } from 'im/api/Query';
import { Icon } from 'im/ui/Icon';
import MappingHeader from './components/MappingHeader';
import MappingItem, {
  isSkipped,
  isUnmapped,
  isMerged,
  isMapped,
  isCustomField,
} from './components/MappingItem';
import MappingStatsBar from './components/MappingStatsBar';
import cls from './Review.module.css';
import { canManageGrants } from 'src/userStorage';
const cx = classNames.bind(cls);

@connect(
  (state) => ({
    importUploadMapping: state.importUploadMapping,
    formValues: getFormValues('grantMappingForm')(state),
    warnings: getFormSyncWarnings('grantMappingForm')(state),
  }),
  {
    getFile: uploaderSecureMappingApi.find,
    updateFile: uploaderSecureMappingApi.put,
  }
)
export default class Review extends Component {
  static propTypes = {
    getFile: PropTypes.func,
    updateFile: PropTypes.func,

    match: PropTypes.object,

    importUploadMapping: PropTypes.object,
    formValues: PropTypes.object,
    warnings: PropTypes.object,
  };

  static defaultProps = { formValues: {} };

  componentDidMount() {
    this.props.getFile({ id: this.props.match.params.fileId });
  }

  onFinishClick = () => {
    const query = where({ id: this.props.match.params.fileId })
      .payload({ import: true })
      .actionMeta({ noSync: true, finish: true });

    this.props.updateFile(query);
  };

  onRedirectToEdit = () => {
    history.push(`/grants/import/${this.props.match.params.fileId}/map`);
  };

  getGroupedColumns() {
    const csvColumns = this.props.importUploadMapping.data.csv_columns ?? [];
    const formColumns = this.props.formValues.columns ?? {};
    const group = {
      unmapped: [],
      mapped: [],
      merged: [],
      skipped: [],
      customFields: [],
    };
    csvColumns.forEach((col) => {
      const object = { csvColumn: col, value: formColumns[col] };
      if (isSkipped(formColumns[col])) return group.skipped.push(object);
      if (isUnmapped(formColumns[col])) return group.unmapped.push(object);
      if (isMerged(formColumns[col], formColumns))
        return group.merged.push(object);
      if (isCustomField(formColumns[col]))
        return group.customFields.push(object);
      if (isMapped(formColumns[col])) return group.mapped.push(object);
      return;
    });
    return group;
  }

  renderSection = (title, group, hasWarning = false) => {
    const formColumns = this.props.formValues.columns;
    if (!group.length) return null;

    return (
      <Expandable isExpanded className={cls.reviewSection}>
        <Expandable.Header className={cls.reviewSectionHeader}>
          <h3
            className={cx(cls.titleThick, { [cls.titleWarning]: hasWarning })}
          >
            {title}
          </h3>
        </Expandable.Header>

        <Expandable.List>
          {hasWarning && (
            <div className={cls.reviewSectionWarningText}>
              <span>
                <Icon
                  name="warning-triangle"
                  color={colors.red}
                  className={cls.reviewSectionWarningTextIcon}
                />
                Unmapped fields will be dismissed and not be imported.
              </span>
              <Divider />
            </div>
          )}
          {group.map(({ csvColumn, value }, index) => (
            <MappingItem
              isReview
              key={`${csvColumn}_${index}`}
              className={cls.reviewSectionListItem}
              csvColumn={csvColumn}
              value={value}
              formColumns={formColumns}
              onRedirectToEdit={this.onRedirectToEdit}
            />
          ))}
        </Expandable.List>
      </Expandable>
    );
  };

  renderWarnings = () => {
    const warningsTexts = Object.values(this.props.warnings).filter(identity);
    if (!warningsTexts.length) return null;

    return (
      <Container className={cls.reviewWarningsContainer}>
        <span className={cls.reviewWarningTitle}>
          If you want to have fundings imported, please follow rules below
        </span>
        {warningsTexts.map((warning, index) => (
          <p key={index} className={cls.reviewWarningError}>
            {warning}
          </p>
        ))}
      </Container>
    );
  };

  render() {
    const { unmapped, mapped, merged, skipped, customFields } =
      this.getGroupedColumns();
    const csvColumns = this.props.importUploadMapping.data.csv_columns;

    return (
      <div>
        <Section noBorder className={cls.limitedWidth}>
          <h3>Map imported columns</h3>
          <MappingStatsBar showWarning csvColumns={csvColumns} />
          <MappingHeader />
          {this.renderWarnings()}
          {this.renderSection('Unmapped Fields', unmapped, true)}
          {this.renderSection('Mapped Fields', mapped)}
          {this.renderSection('Merged Fields', merged)}
          {this.renderSection('Custom Fields', customFields)}
          {this.renderSection('Skipped Fields', skipped)}
        </Section>
        <Section type="sticky-footer">
          <Actions>
            <Button
              disabled={!canManageGrants()}
              secondary
              size="l"
              label="Edit"
              onClick={this.onRedirectToEdit}
            />
          </Actions>
          <Actions>
            <Button
              disabled={!canManageGrants()}
              size="l"
              label="Finish"
              onClick={this.onFinishClick}
            />
          </Actions>
        </Section>
      </div>
    );
  }
}
