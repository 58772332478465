import reducerRegistry from 'im/api/registry';
import ResourceCollection from 'im/api/ResourceCollection';

const apiConfig = {
  root: '/api/v3/users',
  type: 'users',
  api: {
    allow: ['create'],
  },
};

export const Users = new ResourceCollection(apiConfig);
reducerRegistry.register(Users);

export default Users.api;
