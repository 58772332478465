import PropTypes from 'prop-types';
import React from 'react';

import LanguageSelector from 'src/components/LanguageSelector/LanguageSelector';

import cls from './SurveyLanguageSelector.module.css';

const SurveyLanguageSelector = ({ activeLanguage, languages, onChange }) => {
  if (!languages || languages.length <= 1) {
    return null;
  }

  return (
    <div className={cls.surveyLanguageSelector}>
      <LanguageSelector
        outline
        fullWidth
        activeLanguage={activeLanguage}
        languages={languages}
        onChange={onChange}
        isLocalLanguages
      />
    </div>
  );
};

SurveyLanguageSelector.propTypes = {
  activeLanguage: PropTypes.string,
  languages: PropTypes.array,
  onChange: PropTypes.func,
};

export default SurveyLanguageSelector;
