import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import cls from './DrawerFooter.module.css';

const cx = classNames.bind(cls);

const DrawerFooter = ({ className, children }) => (
  <div className={cx(cls.drawerFooter, className)}>{children}</div>
);

DrawerFooter.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default DrawerFooter;
