import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Container, Actions } from 'src/components/IMUI';
import logo from 'src/static/impactmapper-logo-dark.svg';
import cls from './FullBgView.module.css';
const cx = classNames.bind(cls);

const renderErrors = (errors, errorsTemplate) =>
  [
    ...new Set(
      errors
        ?.map((error) => errorsTemplate[error?.status] || error?.detail)
        .filter(Boolean)
    ),
  ].join('\n');

const LightBox = ({
  title,
  rightAction,
  leftAction,
  className,
  limitedWidth,
  info,
  errors,
  children,
  errorsTemplate,
  innerLogo,
}) => (
  <div
    className={cx(
      'light-box',
      { 'light-box-limited-width': limitedWidth },
      className
    )}
  >
    {innerLogo && (
      <img
        className={cx('full-bg-view-logo')}
        src={logo}
        alt="ImpactMapper"
        style={{ margin: '0 auto 36px auto', display: 'block' }}
      />
    )}
    {title && <h2 className={cx('light-box-title')}>{title}</h2>}
    <p className={cx('light-box-info', { 'light-box-info-displayed': info })}>
      {info}
    </p>
    <p
      data-qa="login:auth-errors"
      className={cx('light-box-info', 'light-box-error', {
        'light-box-info-displayed': errors,
      })}
    >
      {renderErrors(errors, errorsTemplate)}
    </p>
    {errors && <br />}

    {children}
    <Container horizontal className={cx('light-box-actions')}>
      <Actions>{leftAction}</Actions>
      <Actions>{rightAction}</Actions>
    </Container>
  </div>
);

LightBox.propTypes = {
  title: PropTypes.any,
  rightAction: PropTypes.node,
  leftAction: PropTypes.node,
  className: PropTypes.string,
  info: PropTypes.node,
  errors: PropTypes.array,
  errorsTemplate: PropTypes.object,
  limitedWidth: PropTypes.bool,
  children: PropTypes.node,
  innerLogo: PropTypes.bool,
};
LightBox.defaultProps = { errorsTemplate: {} };

export const FullBgView = ({ bgImgUrl, children, className, hasLogo }) => (
  <div
    className={cx('full-bg-view-wrapper', className)}
    style={{ backgroundImage: `url(${bgImgUrl})` }}
  >
    {hasLogo ? (
      <img className={cx('full-bg-view-logo')} src={logo} alt="ImpactMapper" />
    ) : null}
    {children}
  </div>
);

FullBgView.propTypes = {
  children: PropTypes.node,
  bgImgUrl: PropTypes.string,
  className: PropTypes.string,
  hasLogo: PropTypes.bool,
};
FullBgView.defaultProps = {
  bgImgUrl:
    'https://res.cloudinary.com/impactmapper/image/upload/v1488140194/top-bg_f2qsjr.jpg',
};
FullBgView.Box = LightBox;
export default FullBgView;
