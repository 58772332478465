import { where } from 'im/api/Query';
import Resource from 'im/api/Resource';

const commonProps = {
  headers: { 'Content-Type': 'application/json' },
  meta: { noSync: true },
};

const apiConfig = {
  root: '/v1/projects/:project_id',
  type: 'survey_export',
  api: {
    custom: {
      single: {
        ...commonProps,
        method: 'POST',
        root: '/v3/grantee_surveys/export',
        path: where().filter('survey_id_eq', ':surveyId').toString(),
      },
      all: {
        ...commonProps,
        method: 'POST',
        path: 'export_surveys',
      },
    },
  },
};

const apiConfigSingle = {
  root: '/v3/grantee_surveys/export',
  type: 'survey_export',
  api: {
    custom: {
      single: {
        ...commonProps,
        method: 'POST',
        path: where()
          .filter('survey_id_eq', ':id')
          .paginate({ number: 0, size: 10000 })
          .toString(),
      },
    },
  },
};

export const SurveyExport = new Resource(apiConfig);
export default SurveyExport.api;

export const surveyExportApi = new Resource(apiConfigSingle).api;
