import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { SelectField } from 'src/components/IMUI';
import TagSelector from 'src/components/TagSelector/TagSelector';
import CURRENCIES from 'src/data/currencies.json';

import BaseQuestionProperties from './BaseQuestionProperties';
import PropertyBox from './PropertyBox';

export default class FinancialQuestionProperties extends Component {
  static propTypes = {
    question: PropTypes.object,
    errors: PropTypes.object,
    tagsWithTagGroups: PropTypes.array,
    onChangeQuestionProperty: PropTypes.func,
    readOnly: PropTypes.bool,
    isAnswerMode: PropTypes.bool,
  };

  static defaultProps = {
    errors: {},
    question: {},
  };

  static validate(question) {
    const errors = {};
    const { currency = '' } = question || {};
    if (!currency.trim()) errors.currency = 'Invalid currency';
    return errors;
  }

  handleCurrencyChange = ({ value }) => {
    this.props.onChangeQuestionProperty(
      this.props.question.id,
      'currency',
      value
    );
  };

  handleTagsChanged = (tagId) => {
    const { question, onChangeQuestionProperty } = this.props;
    question.tags = [tagId];
    onChangeQuestionProperty(question.id, 'tags', question.tags);
  };

  handleTagRemoved = (tagId) => {
    const { question, onChangeQuestionProperty } = this.props;
    const tagIndex = question.tags.indexOf(tagId);
    if (tagIndex !== -1) {
      const newTags = question.tags.filter((id) => id !== tagId);
      onChangeQuestionProperty(question.id, 'tags', newTags);
    }
  };

  render() {
    return (
      <BaseQuestionProperties {...this.props}>
        <PropertyBox>
          <SelectField
            flatDark
            label="Select Currency"
            value={this.props.question.currency}
            onChange={this.handleCurrencyChange}
            error={this.props.errors.currency}
            maxHeight={300}
            fullWidth
            hintText="Choose"
          >
            <SelectField.Item disabled value="" primaryText="Select Currency" />
            {CURRENCIES.map((currencyModel) => (
              <SelectField.Item
                key={currencyModel.code}
                value={currencyModel.code}
                primaryText={currencyModel.code}
              />
            ))}
          </SelectField>
        </PropertyBox>
        <PropertyBox>
          <TagSelector
            isAnswerMode={this.props.isAnswerMode}
            tagsWithTagGroups={this.props.tagsWithTagGroups}
            readOnly={this.props.readOnly}
            selected={this.props.question.tags}
            noTagProps={{
              label: 'Add tag',
              grey: true,
              square: true,
              outline: false,
            }}
            onChange={this.handleTagsChanged}
            onRemove={this.handleTagRemoved}
          />
        </PropertyBox>
      </BaseQuestionProperties>
    );
  }
}
