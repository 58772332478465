/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import { connect } from 'react-redux';
import Logo from './Logo';
import ProjectSwitcher from './ProjectSwitcher';
import cls from './Menu.module.css';
import { NavLink, matchPath } from 'react-router-dom';
import { Icon } from 'im/ui/Icon';
import { isStaff, surveyOnly } from 'src/userStorage';
import clsi from './NavigationItem.module.css';
import { currentProject } from 'src/reducers/utils';

const Menu = ({ location, storage, user }) => {
  const cx = classNames.bind(cls);
  const cxi = classNames.bind(clsi);

  const getProjId = () =>
    matchPath(location.pathname, {
      path: '/analysis/:projectId',
      exact: false,
      strict: false,
    })?.params.projectId;

  const navLinks = (projectId) => {
    if (surveyOnly()) {
      return [
        {
          icon: 'globe',
          text: 'Summary',
          link: `/analysis/${projectId}/summary`,
          disabled: !projectId,
        },
        {
          icon: 'clipboard',
          text: 'Surveys',
          link: `/analysis/${projectId}/surveys`,
          disabled: !projectId,
        },
        {
          icon: 'tag',
          text: 'Tags',
          link: `/analysis/${projectId}/tags`,
          disabled: !projectId,
        },
        {
          icon: 'folder',
          text: 'Project Data',
          link: `/analysis/${projectId}/edit`,
          disabled: !projectId,
        },
      ];
    }

    const baseMenus = [
      {
        icon: 'globe',
        text: 'Summary',
        link: `/analysis/${projectId}/summary`,
        disabled: !projectId,
      },
      {
        icon: 'files',
        text: 'Reports',
        link: `/analysis/${projectId}/reports`,
        disabled: !projectId,
      },
      {
        icon: 'tag',
        text: 'Tags',
        link: `/analysis/${projectId}/tags`,
        disabled: !projectId,
      },
      {
        icon: 'bars',
        text: 'Charts',
        link: `/analysis/${projectId}/charts`,
        disabled: !projectId,
      },
      {
        icon: 'clipboard',
        text: 'Surveys',
        link: `/analysis/${projectId}/surveys`,
        disabled: !projectId,
      },
      {
        icon: 'folder',
        text: 'Project Data',
        link: `/analysis/${projectId}/edit`,
        disabled: !projectId,
      },
    ];

    return baseMenus;
  };
  // hasStories() && { icon: "stories", text: "Stories", link: `/analysis/${projectId}/stories` },

  const bottomLinks = (projectId, currentOrganizationId) => {
    if (surveyOnly()) {
      return [
        {
          icon: 'star-empty',
          text: 'Upgrade',
          className: cls.grants,
          link: `/analysis/${projectId}/upgrade`,
          disabled: false,
          showProLabel: true,
        },
      ];
    }

    const baseMenus = [
      {
        icon: 'data',
        text: 'Organization Data',
        className: cls.grants,
        link: '/grants',
        disabled: !currentOrganizationId,
      },
    ];

    const staffMenus = {
      icon: 'stories',
      text: 'User Logs',
      link: `/activities`,
      disabled: false,
    };

    if (isStaff()) return [...baseMenus, staffMenus];

    return baseMenus;
  };

  const handleActive = (isActive, link) => {
    if (isActive && link == '/analysis')
      return !window.location.pathname?.split('/analysis')[1]?.length;
    return isActive;
  };
  const currentOrganizationId = user?.data?.current_organization_id;
  const currentProjectId = currentProject(
    storage,
    getProjId(),
    currentOrganizationId
  )?.id;

  return (
    <div className={cx('menu', { 'menu-disabled': !currentOrganizationId })}>
      <Logo />
      <div className={cls.links}>
        <ul className={cls.list}>
          <li className={cls.projectSwitcher} key={`Project Switcher`}>
            <div className={cxi('link')}>
              <ProjectSwitcher organizationId={currentOrganizationId || 'im'} />
            </div>
          </li>

          {navLinks(currentProjectId).map((i, index) => (
            <li
              key={`${i.text}-${index}`}
              className={cxi('item', i.className, {
                'item-disabled': i.disabled,
              })}
            >
              <NavLink
                to={i.link}
                className={(isActive) =>
                  cxi(
                    'link',
                    handleActive(isActive, i.link) ? 'linkActive' : null
                  )
                }
              >
                <span
                  className={
                    i.showProLabel ? cxi('text', 'gold-text') : cxi('text')
                  }
                >
                  <span className={cxi('text-icon')}>
                    {i.icon?.match(/.png$/) ? (
                      <img
                        src={i.icon}
                        alt="grants"
                        style={{ width: 16, height: 16 }}
                      />
                    ) : (
                      <Icon name={i.icon} />
                    )}
                  </span>
                  <span>{i.text}</span>
                </span>
                {i.showProLabel && <div className={cxi('gold-pro')}>PRO</div>}
              </NavLink>
            </li>
          ))}
        </ul>

        <ul className={cls.bottomList}>
          {bottomLinks(currentProjectId, currentOrganizationId).map(
            (i, index) => (
              <li
                key={`${i.text}-${index}`}
                className={cxi('item', i.className, {
                  'item-disabled': i.disabled,
                })}
              >
                <NavLink
                  to={i.link}
                  className={(isActive) =>
                    cxi(
                      'link',
                      handleActive(isActive, i.link) ? 'linkActive' : null
                    )
                  }
                >
                  <span
                    className={
                      i.showProLabel ? cxi('text', 'gold-text') : cxi('text')
                    }
                  >
                    <span className={cxi('text-icon')}>
                      {i.icon?.match(/.png$/) ? (
                        <img
                          src={i.icon}
                          alt="grants"
                          style={{ width: 16, height: 16 }}
                        />
                      ) : (
                        <Icon name={i.icon} />
                      )}
                    </span>
                    <span>{i.text}</span>
                  </span>
                  {i.showProLabel && <div className={cxi('gold-pro')}>PRO</div>}
                </NavLink>
              </li>
            )
          )}
        </ul>
      </div>
    </div>
  );
};

Menu.propTypes = {
  location: PropTypes.object.isRequired,
  storage: PropTypes.object,
  user: PropTypes.object,
};

export default connect(pick(['storage', 'user']))(Menu);
