/* eslint no-use-before-define: 0 */
import axios from 'src/axiosInstance';
import {
  CHART__SAVE_FAILURE,
  CHART__SAVE_REQUEST,
  CHART__SAVE_SUCCESS,
  CHART__GET_FAILURE,
  CHART__GET_REQUEST,
  CHART__GET_SUCCESS,
  CHART__CSV_UPLOAD,
  CHART__EDITED,
  CHART__CLEANUP_STORE,
  CHART__AXIS_VALUE_TYPES_GET_REQUEST,
  CHART__AXIS_VALUE_TYPES_GET_SUCCESS,
  CHART__AXIS_VALUE_TYPES_GET_FAILURE,
  CHART__AXIS_VALUES_GET_REQUEST,
  CHART__AXIS_VALUES_GET_SUCCESS,
  CHART__AXIS_VALUES_GET_FAILURE,
  CHART__AXIS_DATA_GET_REQUEST,
  CHART__AXIS_DATA_GET_SUCCESS,
  CHART__AXIS_DATA_GET_FAILURE,
} from 'src/constants';

const doPutChart = (chart, projectId) => {
  const payload = { ...chart };
  delete payload.id;
  return axios
    .put(`/v1/projects/${projectId}/charts/${chart.id}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
};
const doPostChart = (chart, projectId) => {
  return axios
    .post(`/v1/projects/${projectId}/charts/`, chart)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
};
const doGetChart = (chartId, projectId) => {
  return axios
    .get(`/v1/projects/${projectId}/charts/${chartId}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
};
const doGetAxisValueTypes = (projectId) => {
  return axios
    .get(`/v1/projects/${projectId}/charts/axis_value_types`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
};
const doGetAxisValues = (model, attribute, projectId) => {
  return axios
    .get(`/v1/projects/${projectId}/charts/axis_values/${model}/${attribute}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
};
const doPostAxisData = (axisDataConfig, projectId) => {
  return axios
    .post(`/v1/projects/${projectId}/charts/axis_data`, axisDataConfig)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
};

export const saveChart =
  (chart, projectId, silent = false) =>
  async (dispatch) => {
    dispatch({ type: CHART__SAVE_REQUEST, meta: { chart } });
    const doRequest = chart.id ? doPutChart : doPostChart;
    const { response, error } = await doRequest(chart, projectId);
    response
      ? dispatch({
          type: CHART__SAVE_SUCCESS,
          payload: response,
          meta: { chart, silent },
        })
      : dispatch({
          type: CHART__SAVE_FAILURE,
          payload: error,
          meta: { chart },
          error: true,
        });
  };

export const getChart = (chartId, projectId) => async (dispatch) => {
  dispatch({ type: CHART__GET_REQUEST });
  const { response, error } = await doGetChart(chartId, projectId);
  response
    ? dispatch({ type: CHART__GET_SUCCESS, payload: response })
    : dispatch({ type: CHART__GET_FAILURE, payload: error, error: true });
};

export const getAxisValueTypes = (projectId) => async (dispatch) => {
  dispatch({ type: CHART__AXIS_VALUE_TYPES_GET_REQUEST });
  const { response, error } = await doGetAxisValueTypes(projectId);
  response
    ? dispatch({ type: CHART__AXIS_VALUE_TYPES_GET_SUCCESS, payload: response })
    : dispatch({
        type: CHART__AXIS_VALUE_TYPES_GET_FAILURE,
        payload: error,
        error: true,
      });
};

export const getAxisValues =
  (model, attribute, projectId) => async (dispatch) => {
    dispatch({ type: CHART__AXIS_VALUES_GET_REQUEST });
    const { response, error } = await doGetAxisValues(
      model,
      attribute,
      projectId
    );
    response
      ? dispatch({
          type: CHART__AXIS_VALUES_GET_SUCCESS,
          payload: response,
          meta: { model, attribute },
        })
      : dispatch({
          type: CHART__AXIS_VALUES_GET_FAILURE,
          payload: error,
          error: true,
        });
  };

export const postAxisData = (axisDataConfig, projectId) => async (dispatch) => {
  dispatch({ type: CHART__AXIS_DATA_GET_REQUEST });
  if (!axisDataConfig.x.attribute_values.length)
    return dispatch({
      type: CHART__AXIS_DATA_GET_SUCCESS,
      payload: { data: [] },
      meta: axisDataConfig,
    });
  const { response, error } = await doPostAxisData(axisDataConfig, projectId);
  response
    ? dispatch({
        type: CHART__AXIS_DATA_GET_SUCCESS,
        payload: response,
        meta: axisDataConfig,
      })
    : dispatch({
        type: CHART__AXIS_DATA_GET_FAILURE,
        payload: error,
        error: true,
      });
};

export const csvUploaded = (payload) => (dispatch) =>
  dispatch({ type: CHART__CSV_UPLOAD, ...payload });
export const cleanupChartsData = () => (dispatch) =>
  dispatch({ type: CHART__CLEANUP_STORE });
export const chartChanged = () => ({ type: CHART__EDITED });
