export const toApiFromCreateProject = (input) => {
  const { grantee, grant, project_reports, ...projectData } = input;
  return {
    project: {
      ...projectData,
      dictionary: { grantee, grant },
      project_reports:
        project_reports?.map(({ grant_id, grantee_id, report_id }) => ({
          grant_id,
          grantee_id,
          report_id,
        })) || [],
    },
  };
};

export const flatten = (item) => [].concat(item).flat().filter(Boolean);

export const flatProjectReports = (item) =>
  []
    .concat(item)
    .flat()
    .filter(Boolean)
    .flatMap((el) => el.project_reports);

export const findSurvey = (a) => (b) =>
  b != undefined && a != undefined && a?.id == b?.id;

export const findMatch = (a) => (b) =>
  b != undefined &&
  a != undefined &&
  (b.grant?.id || b.grant_id) == (a.grant?.id || a.grant_id) &&
  (b.grantee?.id || b.grantee_id) == (a.grantee?.id || a.grantee_id);

export const findMatchReport = (a) => (b) =>
  b != undefined &&
  a != undefined &&
  (b.report_id || b.report?.id) == (a.report_id || a.report?.id) &&
  (b.grant?.id || b.grant_id) == (a.grant?.id || a.grant_id) &&
  (b.grantee?.id || b.grantee_id) == (a.grantee?.id || a.grantee_id);

const parseReport = (el) => ({
  report_id:
    el.report_id || el.report?.id
      ? Number(el.report_id || el.report?.id)
      : null,
});
const parseGrants = (el) => ({
  grant_id: Number(el.grant?.id || el.grant_id),
  grantee_id: Number(el.grantee?.id || el.grantee_id),
});

export const retrieveDataSets = (grants, reports) => {
  const reports_full = [];
  reports.forEach((report) =>
    report.project_reports
      .filter((i) => i?.grant?.id)
      .forEach((pr) => {
        const el = {
          ...report,
          ...pr,
          ...parseGrants(report),
          ...parseReport(pr),
        };
        if (
          !reports_full.some(
            (r) => findMatch(el) && parseReport(r).report_id == el.report_id
          )
        )
          reports_full.push(el);
      })
  );
  const active_prs = reports_full.map((report) => ({
    ...parseGrants(report),
    ...parseReport(report),
  }));
  const everyGrant = grants.map((i) => ({ ...parseGrants(i) }));
  const reportless = everyGrant
    .filter((g) => !active_prs.some(findMatch(g)))
    .map((r) => ({ ...r, ...parseGrants(r), report_id: null }));
  const every_project_report = active_prs.concat(reportless);

  const availGrant = grants.map((i) => ({
    ...i,
    grant: { id: i.grant_id, name: i.name },
    ...parseGrants(i),
    project_reports: [],
    reports: [],
  }));

  return availGrant.map((grant) => {
    const available_prs = every_project_report.filter(findMatch(grant));
    const project_reports =
      available_prs.length > 0
        ? available_prs
        : [{ report_id: null, ...parseGrants(grant) }];
    return {
      ...grant,
      reports_count: String(reports_full.filter(findMatch(grant)).length),
      project_reports,
      reports: null,
    };
  });
};

export const retrieveReportSet = (grants, reports) => {
  const reports_full = [];
  reports.forEach((report) =>
    report.project_reports
      .filter((i) => i?.grant?.id)
      .forEach((pr) => {
        const el = {
          ...report,
          ...pr,
          ...parseGrants(report),
          ...parseReport(pr),
          taggings_count: report.meta?.taggings_count,
        };
        if (
          !reports_full.some(
            (r) => findMatch(el) && parseReport(r).report_id == el.report_id
          )
        )
          reports_full.push(el);
      })
  );

  const availGrant = grants.map((i) => ({
    ...i,
    grant: { id: i.grant_id, name: i.name },
    ...parseGrants(i),
    project_reports: [],
    reports: [],
  }));

  return reports_full.map((report) => {
    const grant = availGrant.find(findMatch(report));
    return {
      ...parseGrants(report),
      ...parseReport(report),
      id: +report.report_id,
      name: report.name,
      date: report.date,
      grant_name: grant?.grant?.name || grant?.name || 'internal',
      grantee_name: grant?.grantee?.name || 'internal',
      created_at: report.created_at,
      report_type: report.report_type,
      taggings_count: report.taggings_count,
      code: report.code,
      period: report.period,
      project_reports: [{ ...parseGrants(report), ...parseReport(report) }],
    };
  });
};
