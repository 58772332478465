import reducerRegistry from 'im/api/registry';
import Resource from 'im/api/Resource';

const PENDING_DELETION_TEXT = 'Deletion scheduled for';
const PENDING_APPROVAL_TEXT = 'Needs a review';
export const toBeDeleted = (org) =>
  org.disabled && org.disabled.includes(PENDING_DELETION_TEXT);
export const toBeApproved = (org) =>
  org.disabled && org.disabled.includes(PENDING_APPROVAL_TEXT);

const apiConfig = {
  root: '/api/v3/organizations',
  type: 'organizations',
  api: { allow: ['find', 'put'] },
  derived: (org) => ({
    toBeDeleted: toBeDeleted(org),
    toBeApproved: toBeApproved(org),
  }),
};

export const Organization = new Resource(apiConfig, 'organization');
reducerRegistry.register(Organization);

export const OrganizationCurrent = new Resource(
  apiConfig,
  'organizationCurrent'
);
reducerRegistry.register(OrganizationCurrent);

export default { ...Organization.api, current: OrganizationCurrent.api };
