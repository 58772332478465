import React, { useEffect, useState } from 'react';
import CircularProgress from 'material-ui/CircularProgress';
import { Field, change } from 'redux-form';
import { AutoCompleteField, Button, FormField } from 'src/components/IMUI';
import { getOrgText } from 'src/services/DictionaryService';
import FlexColumn from 'src/components/FlexColumn/FlexColumn';
import FlexRow from 'src/components/FlexRow/FlexRow';
import CreateDialogsGrantee from 'src/pages/App/Grants/Table/components/CreateDialogsGrantee';
import granteesApi from 'src/api/Grantees';
import uniq from 'ramda/src/uniq';
import { where } from 'im/api/Query';
import store from 'src/store';

const GranteeDropdown = ({ formName, fieldsNames }) => {
  const [granteeOptions, setGranteeOptions] = useState([]);
  const [granteeMissing, setGranteeMissing] = useState(true);
  const [isAddingGrantee, setIsAddingGrantee] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchOptions = (value = null) => {
    const query = where().sort('-created_at').paginate({ size: 9999 });

    store.dispatch(granteesApi.findAll(query)).then((resp) => {
      const grantees = resp.data;
      setGranteeOptions(
        uniq(grantees?.map(({ id, name }) => ({ id: Number(id), name })) || [])
      );

      if (value) {
        store.dispatch(change(formName, fieldsNames.granteeId, value));
      }

      setLoading(false);
    });
  };

  useEffect(fetchOptions, []);

  const onGranteeChange = (event) => {
    setGranteeMissing(event === null);
  };

  const onAddGranteeClick = () => {
    setIsAddingGrantee(true);
  };

  const onCloseModal = () => {
    setIsAddingGrantee(false);
  };

  const onSubmitSuccessful = (payload) => {
    fetchOptions(payload.id);
    setGranteeMissing(false);
  };

  return (
    <FormField anchorScrollName={fieldsNames.granteeId}>
      <FlexRow style={{ flexWrap: 'nowrap', alignItems: 'flex-end' }}>
        <FlexColumn>
          <Field
            component={AutoCompleteField}
            label={() => (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                {getOrgText('Grantee')}
                {loading && <CircularProgress size={16} />}{' '}
              </div>
            )}
            onChange={onGranteeChange}
            hintText={`Type in ${getOrgText('grantee')} name`}
            fullWidth
            returnOnlyValue
            dataSource={granteeOptions}
            dataSourceConfig={{ text: 'name', value: 'id' }}
            name={fieldsNames.granteeId}
            {...(loading ? { format: () => '' } : {})}
          />
          {granteeMissing && (
            <div
              style={{
                display: 'flex',
                fontSize: 12,
                marginBottom: 20,
              }}
            >
              Can&apos;t find the {getOrgText('grantee')}?
              <Button
                style={{ marginLeft: 4 }}
                onClick={onAddGranteeClick}
                text
                underlined
                label={`Add a new ${getOrgText('grantee')}`}
              />
            </div>
          )}

          {isAddingGrantee && (
            <CreateDialogsGrantee
              onCloseModal={onCloseModal}
              onSubmitSuccessful={onSubmitSuccessful}
            />
          )}
        </FlexColumn>
      </FlexRow>
    </FormField>
  );
};

export default GranteeDropdown;
