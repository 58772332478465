import { showLoading, hideLoading } from 'react-redux-loading-bar';
import ResourceBase from './entities/resource/ResourceBase';
import runPendingStatus from './middleware/pendingStatusMiddleware';
import runSync from './middleware/syncMiddleware';
import runUrlSearchParams from './middleware/urlSearchParamsMiddleware';

const getResourceState = (store, currentResource) =>
  currentResource ? store?.getState()?.[currentResource.reducerName] : null;

const beforeReducerResolved = (store, action) => {
  const _state = getResourceState(
    store,
    ResourceBase.getResourceByActionType(action.type)
  );
  if (!_state) return;

  if (action.payload?.pending?.init && !_state.pending?.init)
    return store.dispatch(showLoading());
  if (action.payload?.pending?.ui && !_state.pending?.ui)
    return store.dispatch(showLoading());
};

const afterReducerResolved = (store, action) => {
  const _state = getResourceState(
    store,
    ResourceBase.getResourceByActionType(action.type)
  );
  if (!_state) return;

  runPendingStatus(
    store.dispatch,
    action,
    _state,
    ResourceBase.getResourceByActionType(action.type)
  );
  runSync(
    store.dispatch,
    action,
    _state,
    ResourceBase.getResourceByActionType(action.type)
  );
  runUrlSearchParams(store.dispatch, action, _state);

  if (!action.payload?.pending?.init && _state.pending?.init)
    return store.dispatch(hideLoading());
  if (!action.payload?.pending?.ui && _state.pending?.ui)
    return store.dispatch(hideLoading());
};

export default (store) => (next) => (action) => {
  beforeReducerResolved(store, action);
  next(action);
  afterReducerResolved(store, action);
};
