import PropTypes from 'prop-types';
import React from 'react';

import { containsText } from 'src/utils/string';

import { Error } from '../Forms/base';
import { TextField, PopoverLight, SelectPopoverLight } from '../index';

import cls from './FilterItem.module.css';

export default class FilterItem extends React.Component {
  static propTypes = {
    ...TextField.propTypes,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        displayName: PropTypes.string,
      })
    ),
  };

  static defaultProps = {
    hintText: 'Choose',
  };

  constructor(props) {
    super(props);
    this.state = {
      popoverVisible: false,
      displayValue: this.getDisplayName(props.items, props.value),
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({
        displayValue: this.getDisplayName(nextProps.items, nextProps.value),
      });
    }
  }

  getDisplayName(items, value) {
    if (!items || !value) {
      return '';
    }

    const currItem = items.find(({ name }) => name === value);
    return (currItem && (currItem.displayName || currItem.name)) || '';
  }

  handleHidePopover = () => {
    this.setState({ popoverVisible: false });
  };

  handleOpenPopover = () => {
    this.setState({ popoverVisible: true });
  };

  handleMenuItemClick = (item) => {
    this.props.onChange(item.name);
    this.handleHidePopover();
  };

  renderItems = (searchText) => {
    const { items } = this.props;
    const filteredItems =
      items?.filter((i) => containsText(i.displayName || i.name, searchText)) ??
      [];

    return filteredItems.map((item, index) => (
      <PopoverLight.MenuItem
        key={`${item.name}_$${index}`}
        value={item.name}
        primaryText={item.displayName || item.name}
        onClick={() => this.handleMenuItemClick(item)}
      />
    ));
  };

  render() {
    const { disabled, hintText, error } = this.props;
    const { popoverVisible, displayValue } = this.state;

    return (
      <div className={cls.filterItem}>
        <SelectPopoverLight
          flat
          border
          disabled={disabled}
          open={popoverVisible}
          labelHint={hintText}
          label={displayValue}
          renderItems={this.renderItems}
          onClose={this.handleHidePopover}
          onOpen={this.handleOpenPopover}
        />

        <Error error={error || ''} />
      </div>
    );
  }
}
