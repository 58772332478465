import React from 'react';
import classNames from 'classnames/bind';
import { withTranslators } from 'lioness';
import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import { connect } from 'react-redux';
import { Actions, Button, Container } from 'src/components/IMUI';
import { Icon } from 'im/ui/Icon';
import cls from './SurveyFooter.module.css';
const cx = classNames.bind(cls);

@connect(pick(['screen']))
class SurveyFooter extends React.PureComponent {
  static propTypes = {
    answersSavedSinceLastChange: PropTypes.bool,
    isPending: PropTypes.bool,
    isPreview: PropTypes.bool,
    isFixed: PropTypes.bool,
    forceMobileStyling: PropTypes.bool,
    screen: PropTypes.object,
    pageCount: PropTypes.number,
    currentPage: PropTypes.number,
    onChangePage: PropTypes.func,
    onSubmit: PropTypes.func,
    tp: PropTypes.func,
    t: PropTypes.func,
    terminated: PropTypes.bool,
    showTerminationError: PropTypes.object,
    layout: PropTypes.array,
  };

  constructor(props) {
    super(props);
    this.state = {
      isMobile: props.screen.data.type === 'mobile' || props.forceMobileStyling,
    };
  }
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.screen.data.type !== this.props.screen.data.type ||
      nextProps.forceMobileStyling !== this.props.forceMobileStyling
    ) {
      this.setState({
        isMobile:
          nextProps.screen.data.type === 'mobile' ||
          nextProps.forceMobileStyling,
      });
    }
  }
  getSubmitButtonLabel() {
    const { isPending, tp } = this.props;
    return isPending
      ? tp('saving in progress, button', 'Saving...')
      : tp('button', 'Submit');
  }
  renderAutosaveStatus = () => (
    <span className={cls.statusText}>
      {this.props.answersSavedSinceLastChange
        ? this.props.t('Survey autosaved')
        : `${this.props.t('Waiting for autosave')}...`}
    </span>
  );
  renderTerminationObject = () =>
    this.props.layout.find(
      (l) =>
        l.id ===
        Object.keys(this.props.showTerminationError).filter(
          (questionId) => this.props.showTerminationError[questionId]
        )[0]
    );
  renderMobileFooter() {
    const { onChangePage, currentPage, pageCount, isPreview } = this.props;
    const submitButtonLabel = this.getSubmitButtonLabel();
    const isLastPage = currentPage === pageCount - 1;
    const isFirstPage = pageCount > 1 && currentPage !== 0;
    const canForward = pageCount > 1 && currentPage < pageCount - 1;

    return (
      <div className={cx(cls.footerChild, cls.footerChildMobile)}>
        <Container horizontal className={cls.spaceBottom}>
          <Actions>
            {isFirstPage && (
              <Button
                secondary
                icon={<Icon name="chevron-left" />}
                onClick={() => onChangePage(-1, true)}
              />
            )}
          </Actions>

          <Actions className={cls.centeredAction}>
            <Container centered>
              {this.renderAutosaveStatus()}
              {pageCount > 1 && (
                <small>
                  {currentPage + 1} / {pageCount}
                </small>
              )}
            </Container>
          </Actions>

          <Actions>
            {canForward && (
              <Button
                secondary
                className={cls.buttonLast}
                icon={<Icon name="chevron-right" />}
                onClick={() => onChangePage(1, true)}
              />
            )}
            {isLastPage && (
              <Button
                style={{ maxHeight: 34 }}
                className={cx('submitButton', { noAction: isPreview })}
                label={submitButtonLabel}
                onClick={() => this.props.onSubmit(true)}
              />
            )}
          </Actions>
        </Container>
      </div>
    );
  }
  renderDesktopFooter() {
    const { onChangePage, currentPage, pageCount, isPreview, tp, terminated } =
      this.props;
    const submitButtonLabel = this.getSubmitButtonLabel();
    const terminationObject = this.renderTerminationObject();
    const isLastPage = currentPage === pageCount - 1;
    const isFirstPage = pageCount > 1 && currentPage !== 0;
    const isTerminated =
      terminated && terminationObject?.settings?.clEarlyDisable !== 'NONE';
    const canForward = pageCount > 1 && currentPage < pageCount - 1;

    if (terminationObject?.settings?.clEarlyTerminateMessage) {
      return (
        <div className={cx(cls.footerChild, cls.footerChildMaxWidth)}>
          <div className={cx(cls.terminationMessage)}>
            {terminationObject.settings.clEarlyTerminateMessage}
          </div>
          <Actions>
            {isLastPage && (
              <Button
                size="l"
                className={cx('submitButton', { noAction: isPreview })}
                label={submitButtonLabel}
                onClick={() => this.props.onSubmit(true)}
                disabled={isTerminated}
              />
            )}
          </Actions>
        </div>
      );
    }
    return (
      <div className={cx(cls.footerChild, cls.footerChildMaxWidth)}>
        <Actions>
          {isFirstPage && (
            <Button
              secondary
              size="l"
              label={tp('button', 'Previous')}
              onClick={() => onChangePage(-1, true)}
            />
          )}
        </Actions>

        <Actions className={cls.centeredAction}>
          <Container centered>
            {this.renderAutosaveStatus()}
            {pageCount > 1 && (
              <small>
                {currentPage + 1} / {pageCount}
              </small>
            )}
          </Container>
        </Actions>

        <Actions>
          {canForward && (
            <Button
              secondary
              size="l"
              label={tp('button', 'Next')}
              onClick={() => onChangePage(1, true)}
            />
          )}
          {isLastPage && (
            <Button
              size="l"
              className={cx('submitButton', { noAction: isPreview })}
              label={submitButtonLabel}
              onClick={() => this.props.onSubmit(true)}
              disabled={isTerminated}
            />
          )}
        </Actions>
      </div>
    );
  }

  render() {
    return (
      <div className={cx('footer', { footerFixed: this.props.isFixed })}>
        {this.state.isMobile
          ? this.renderMobileFooter()
          : this.renderDesktopFooter()}
      </div>
    );
  }
}

export default withTranslators(SurveyFooter);
