import { actionStates } from '../entities/AsyncActionsGenerator';
import { isType } from '../helpers/actionTypes';

import { handleRequest, handleSuccess, handleFailure } from './reducerHandlers';

const resourceReducer = (action, state) => {
  if (isType(action.type, actionStates.request)) {
    return handleRequest(state);
  }

  if (isType(action.type, actionStates.success)) {
    return handleSuccess(action, state);
  }

  if (isType(action.type, actionStates.failure)) {
    return handleFailure(action, state);
  }

  return state;
};

export default resourceReducer;
