import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Container } from 'src/components/IMUI';

import cls from './NestedTree.module.css';
import { Icon } from 'im/ui';

const cx = classNames.bind(cls);

const NestedTree = ({
  icon,
  title,
  created_rows,
  updated_rows,
  failed_to_create_rows,
  failed_to_update_rows,
  update_skipped_rows,
  create_skipped_rows,
}) => (
  <div>
    <Container className={cx(cls.textSoft)}>
      <div>
        {icon && (
          <span>
            <Icon name={icon} />
            &nbsp;
          </span>
        )}
        {created_rows +
          updated_rows +
          update_skipped_rows +
          create_skipped_rows}{' '}
        <strong>{title}</strong> successfully processed
      </div>

      <ul className={cls.listSuccessful}>
        {created_rows > 0 && (
          <li className={cls.listSuccessfulItem}>
            <span className={cls.listSuccessfulIndicator} />
            {created_rows} new {title} have been imported
          </li>
        )}
        {updated_rows > 0 && (
          <li className={cls.listSuccessfulItem}>
            <span className={cls.listSuccessfulIndicator} />
            {updated_rows} existing {title} were updated
          </li>
        )}
        {update_skipped_rows + create_skipped_rows > 0 && (
          <li className={cls.listSuccessfulItem}>
            <span className={cls.listSuccessfulIndicator} />
            {update_skipped_rows + create_skipped_rows} rows had no changes and
            were skipped
          </li>
        )}
        {failed_to_create_rows > 0 && (
          <li className={cls.listSuccessfulItem}>
            <span className={cls.listSuccessfulIndicator} />
            {failed_to_create_rows} rows were not imported (skipped)
          </li>
        )}
        {failed_to_update_rows > 0 && (
          <li className={cls.listSuccessfulItem}>
            <span className={cls.listSuccessfulIndicator} />
            {failed_to_update_rows} rows failed to update (skipped)
          </li>
        )}
      </ul>
    </Container>
  </div>
);

NestedTree.propTypes = {
  update_skipped_rows: PropTypes.number,
  create_skipped_rows: PropTypes.number,
  title: PropTypes.string,
  icon: PropTypes.string,
  created_rows: PropTypes.number,
  updated_rows: PropTypes.number,
  failed_to_create_rows: PropTypes.number,
  failed_to_update_rows: PropTypes.number,
};
export default NestedTree;
