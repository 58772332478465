import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CircularProgress from 'material-ui/CircularProgress';
import { Field, change } from 'redux-form';
import { AutoCompleteField, Button, FormField } from 'src/components/IMUI';
import { getOrgText } from 'src/services/DictionaryService';
import FlexColumn from 'src/components/FlexColumn/FlexColumn';
import FlexRow from 'src/components/FlexRow/FlexRow';
import CreateDialogsGrant from 'src/pages/App/Grants/Table/components/CreateDialogsGrant';
import grantsApi from 'src/api/Grants';
import uniq from 'ramda/src/uniq';
import { where } from 'im/api/Query';
import store from 'src/store';

const GrantDropdown = ({ formName, fieldsNames }) => {
  const grantFieldValue = useSelector(
    (state) => state.form[formName]?.values?.fundings?.[0]?.grant_id
  );

  const [grantOptions, setGrantOptions] = useState([]);
  const [grantMissing, setGrantMissing] = useState(!grantFieldValue);
  const [isAddingGrant, setIsAddingGrant] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchOptions = (value = null) => {
    const query = where().sort('-created_at').paginate({ size: 9999 });

    store.dispatch(grantsApi.findAll(query)).then((resp) => {
      const grants = resp.data;
      setGrantOptions(
        uniq(grants?.map(({ id, name }) => ({ id: Number(id), name })) || [])
      );

      if (value) {
        store.dispatch(change(formName, fieldsNames.grantId, value));
      }

      setLoading(false);
    });
  };

  useEffect(fetchOptions, []);

  const onGrantChange = (event) => {
    setGrantMissing(event === null);
  };

  const onAddGrantClick = () => {
    setIsAddingGrant(true);
  };

  const onCloseModal = () => {
    setIsAddingGrant(false);
  };

  const onSubmitSuccessful = (payload) => {
    fetchOptions(payload.id);
    setGrantMissing(false);
  };

  return (
    <FormField anchorScrollName={fieldsNames.grantId}>
      <FlexRow style={{ flexWrap: 'nowrap', alignItems: 'flex-end' }}>
        <FlexColumn style={{ paddingRight: '0' }}>
          <Field
            component={AutoCompleteField}
            label={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                {getOrgText('Grant')}
                {loading && <CircularProgress size={16} />}{' '}
              </div>
            }
            onChange={onGrantChange}
            hintText={`Type in ${getOrgText('grant')} name`}
            fullWidth
            returnOnlyValue
            dataSource={grantOptions}
            dataSourceConfig={{ text: 'name', value: 'id' }}
            name={fieldsNames.grantId}
            {...(loading ? { format: () => '' } : {})}
          />
          {grantMissing && (
            <div
              style={{
                display: 'flex',
                fontSize: 12,
                marginBottom: 20,
              }}
            >
              Can&apos;t find the {getOrgText('grant')}?
              <Button
                style={{ marginLeft: 4 }}
                onClick={onAddGrantClick}
                text
                underlined
                label={`Add a new ${getOrgText('grant')}`}
              />
            </div>
          )}

          {isAddingGrant && (
            <CreateDialogsGrant
              onCloseModal={onCloseModal}
              onSubmitSuccessful={onSubmitSuccessful}
            />
          )}
        </FlexColumn>
      </FlexRow>
    </FormField>
  );
};

export default GrantDropdown;
