import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Divider, Container, TextField } from 'src/components/IMUI';
import { Icon } from 'im/ui/Icon';
import { Popover } from './Popover';
import PopoverActions from './PopoverActions';
import PopoverContent from './PopoverContent';
import PopoverMenu from './PopoverMenu';

import cls from './PopoverLight.module.css';

const cx = classNames.bind(cls);

export class PopoverLight extends React.PureComponent {
  static propTypes = {
    search: PropTypes.bool,
    searchText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    searchHint: PropTypes.string,
    selectedItemAsSearchText: PropTypes.bool,
    onGoBack: PropTypes.func,
    onSearchTextChange: PropTypes.func,
    renderChildren: PropTypes.func,
    ...Popover.propTypes,
  };

  static defaultProps = {
    onSearchTextChange: () => void 0,
    anchorOrigin: { horizontal: 'middle', vertical: 'top' },
    targetOrigin: { horizontal: 'middle', vertical: 'top' },
    canAutoPosition: true,
  };

  static Actions = PopoverActions;
  static ActionItem = PopoverActions.Item;
  static Menu = PopoverMenu;
  static MenuItem = PopoverMenu.Item;
  static Content = PopoverContent;
  static ContentActions = PopoverContent.Actions;
  static Divider = Divider;

  constructor(props) {
    super(props);
    this.state = { searchText: props.searchText ?? '' };
  }
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.open &&
      !this.props.open &&
      !nextProps.selectedItemAsSearchText
    ) {
      this.onSearchTextChange('');
    }
    if (
      nextProps.open &&
      !this.props.open &&
      nextProps.selectedItemAsSearchText
    ) {
      if (nextProps.searchText === this.state.searchText) return;
      this.onSearchTextChange(nextProps.searchText);
    }
  }

  onSearchTextChange = (searchText) => {
    this.setState({ searchText });
    this.props.onSearchTextChange?.(searchText);
  };

  renderHeader = () => {
    const { header, onGoBack, search, searchHint } = this.props;
    const { searchText } = this.state;
    const showSearch = search && !header;

    return (
      <>
        <Container
          horizontal
          className={cx(cls.popoverLightContainer, {
            [cls.popoverLightContainerNoPadding]: showSearch,
          })}
        >
          {!onGoBack || !header ? null : (
            <Icon
              name="chevron-left"
              className={cls.popoverLightContainerIcon}
              onClick={onGoBack}
            />
          )}
          {!showSearch ? null : (
            <TextField
              autoFocus
              fullWidth
              noValidation
              flat
              autoComplete="off"
              name="dropdownSearch"
              hintText={searchHint || 'Type in to filter the list'}
              value={searchText}
              onChange={this.onSearchTextChange}
            />
          )}
          {header}
        </Container>
        <Divider compact />
      </>
    );
  };

  renderFooter(footer) {
    return footer ? (
      <div>
        <Divider compact />
        {footer}
      </div>
    ) : null;
  }

  render() {
    const {
      header,
      footer,
      onGoBack,
      search,
      customHeight,
      children,
      renderChildren,
      ...rest
    } = this.props;
    return (
      <Popover
        {...rest}
        header={
          !header && !onGoBack && !search
            ? null
            : this.renderHeader(header, onGoBack, search)
        }
        headerProps={{ className: cls.popoverLightHeader }}
        footer={this.renderFooter(footer)}
        onRequestClose={this.props.onRequestClose}
        customHeight={customHeight}
      >
        {children}
        {renderChildren && renderChildren(this.state.searchText)}
      </Popover>
    );
  }
}
