import authApi from 'src/api/Auth';
import {
  USER__GET_TOKEN_SUCCESS,
  USER__GET_TOKEN_FAILURE,
  USER__GET_TOKEN_REQUEST,
  USER__GET_2FA_TOKEN_SUCCESS,
} from 'src/constants';
import { where } from 'im/api/Query';
import AuthService from 'src/services/AuthService';

const doGetToken = (payload) =>
  authApi
    .getToken(where().dispatch(false).payload(payload, { raw: true }))
    .then(({ data }) => ({ response: data }))
    .catch(({ response }) => ({
      response: response?.data,
      need2FA: response?.data?.job_id != undefined && response?.status === 400,
    }));

export const getToken = (payload) => async (dispatch) => {
  dispatch({ type: USER__GET_TOKEN_REQUEST });
  const { response, need2FA } = await doGetToken(payload);

  if (response?.token) {
    dispatch({ type: USER__GET_TOKEN_SUCCESS, payload: response });
    response.token?.length > 0 && AuthService.authenticateUser(response.token);
    return { token: response.token, need2FA: false, failure: false };
  }

  if (need2FA) {
    dispatch({ type: USER__GET_2FA_TOKEN_SUCCESS, payload: response });
    return { token: null, need2FA: true, failure: false };
  }

  dispatch({
    type: USER__GET_TOKEN_FAILURE,
    payload: { response, errors: response?.errors },
    meta: { silent: true },
  });
  return {
    response,
    need2FA: undefined,
    failure: true,
    errors: response?.errors,
  };
};
