import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import ReactTooltip from 'react-tooltip';

import cls from './Tooltip.module.css';

const cx = classNames.bind(cls);

export const Tooltip = ({ className = undefined, ...rest }) => (
  <ReactTooltip className={cx('imui-tooltip', className)} {...rest} />
);

Tooltip.rebuild = ReactTooltip.rebuild;

Tooltip.propTypes = {
  html: PropTypes.bool,
  multiline: PropTypes.bool,
  className: PropTypes.string,
  effect: PropTypes.oneOf(['solid', 'float']),
  place: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  offset: PropTypes.object,
};

Tooltip.defaultProps = {
  html: true,
  multiline: true,
  effect: 'solid',
  place: 'top',
  offset: { top: 0, left: 0 },
};

export default Tooltip;
