import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, getFormValues, registerField } from 'redux-form';

import tagCategoriesSelectorApi from 'src/api/TagCategoriesSelector';
import {
  TextField,
  ChipInput,
  FormField,
  ReduxFormField,
  Divider,
  Container,
  Actions,
} from 'src/components/IMUI';
import { KEYCODE_ENTER } from 'src/components/IMUI/Forms/ChipInput';
import TagCategorySelector from 'src/components/TagCategorySelector/TagCategorySelector';
import Warning from 'src/components/Warning/Warning';
import createValidator from 'src/utils/validation';

import { where } from 'im/api/Query';

import cls from './TagCategoryForm.module.css';

// TODO: Use error.source instead of error.title when backend ready
const BACKEND_ERRORS = {
  code: { title: 'Invalid code', details: 'Shortcode has already been taken' },
  title: { title: 'Invalid title', details: 'Title has already been taken' },
};

class TagCategory extends Component {
  static propTypes = {
    allMetatags: PropTypes.array,
    project: PropTypes.object,
    formValues: PropTypes.object,
    tagCategories: PropTypes.object,
    initialValues: PropTypes.object,
    projectTagCategories: PropTypes.object,
    form: PropTypes.string,

    change: PropTypes.func.isRequired,
    submitSucceeded: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    getTagCategories: PropTypes.func.isRequired,
    registerFormField: PropTypes.func.isRequired,
  };

  static defaultProps = {
    metatags: [],
    formValues: {},
  };

  componentDidMount() {
    this.props.registerFormField(this.props.form, 'parent', 'Field');

    if (!this.props.project.id) return;
    this.doRequestTagCategories();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.project.id !== this.props.project.id) {
      this.doRequestTagCategories();
    }
  }

  getBackendErrors(errors) {
    return Object.keys(BACKEND_ERRORS).reduce((acc, attrName) => {
      const error = errors.find(
        ({ title }) => title === BACKEND_ERRORS[attrName].title
      );
      return !error
        ? acc
        : { ...acc, [attrName]: BACKEND_ERRORS[attrName].details };
    }, {});
  }

  doRequestTagCategories = (searchText) => {
    const query = where({
      projectTagCategoryIds: this.props.project.enabled_tag_categories,
    })
      .fields('tag_category', 'title', 'tags', 'child_categories', 'parent')
      .filter('parent_id_null', true)
      .fields('tag', 'title')
      .paginate({ size: 1000 })
      .sort('title');

    searchText?.length > 0
      ? this.props.getTagCategories(
          query.filter('nested_categories_with_tags_title_in_any', searchText)
        )
      : this.props.getTagCategories(query);
  };

  handleParentTagCategoryChange = (parent) => {
    if (parent?.id === this.props.initialValues?.id) return;
    this.props.change('parent', parent);
  };

  renderBackendErrors() {
    const { projectTagCategories, submitSucceeded } = this.props;
    if (!projectTagCategories.errors || !submitSucceeded) return null;

    const parsedErrors = this.getBackendErrors(projectTagCategories.errors);

    return (
      <Warning show compact mode="error" ignorable={false}>
        <ul className={cls.formErrorList}>
          {Object.values(parsedErrors).map((err, index) => (
            <li key={index}>{err}</li>
          ))}
        </ul>
      </Warning>
    );
  }

  render() {
    const {
      allMetatags = [],
      handleSubmit,
      tagCategories,
      formValues,
    } = this.props;

    return (
      <form onSubmit={handleSubmit} noValidate className={cls.tagCategoryForm}>
        {this.renderBackendErrors()}

        <TagCategorySelector
          isParentOptional
          id={formValues.id}
          value={formValues.parent}
          inputLabel="Parent tag group"
          labelHint="Skip or select"
          allTagCategories={tagCategories.data}
          onChange={this.handleParentTagCategoryChange}
          onSearchText={(text) => this.doRequestTagCategories(text)}
        />

        <FormField>
          <Field
            fullWidth
            flat
            borderDark
            required
            name="title"
            component={TextField}
            label="Tag group name"
            hintText="Type in a tag group name..."
          />
        </FormField>

        <Divider className={cls.dividerSpacing} />

        <ReduxFormField
          flat
          borderDark
          type="array"
          name="metatags"
          label={
            <Container horizontal nowrap>
              <Actions>
                <span>Metatags</span>
              </Actions>
              <Actions>
                <small>
                  Press <code>enter</code> to confirm creating metatag.
                </small>
              </Actions>
            </Container>
          }
          newChipKeyCodes={[KEYCODE_ENTER]}
          chipProps={{ square: true, alt: true }}
          component={ChipInput}
          dataSource={allMetatags}
          hintText="Type in a metatag..."
        />

        <FormField>
          <Field
            flat
            borderDark
            fullWidth
            name="description"
            component={TextField}
            label="Description"
            hintText="Type in a description..."
          />
        </FormField>

        <FormField>
          <Field
            flat
            borderDark
            style={{ maxWidth: 242 }}
            name="code"
            component={TextField}
            label="Shortcode"
            hintText="Type in shortcode..."
          />
        </FormField>
      </form>
    );
  }
}

function validate(values) {
  const not = (fn) => (v) => !fn(v);
  const isDefined = (v) => Boolean(v);

  return createValidator({
    title: [[not(isDefined), () => 'Tag group name is required']],
  })(values);
}

const TagCategoryForm = reduxForm({
  validate,
})(TagCategory);
const ConnectedCreateTagCategoryForm = connect(
  (state, props) => ({
    project: state.project,
    projectTagCategories: state.projectTagCategories,
    tagCategories: state.tagCategoriesSelectorForm,
    formValues: getFormValues(props.form)(state),
  }),
  {
    getTagCategories: tagCategoriesSelectorApi.form.findAllPerProjectNested,
    registerFormField: registerField,
  }
)(TagCategoryForm);

export default ConnectedCreateTagCategoryForm;
