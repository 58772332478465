import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from 'im/ui/Icon';
import { Container } from '../index';
import cls from './FilterBreadcrumbItem.module.css';
const cx = classNames.bind(cls);

export default class FilterBreadcrumbItem extends React.Component {
  static propTypes = {
    variable: PropTypes.string,
    matcher: PropTypes.string,
    result: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onEdit: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
  };

  handleEdit = () => {
    this.props.onEdit();
  };

  render() {
    const { variable, matcher, result } = this.props;
    const refHeight = this.innerRef?.parentElement?.offsetHeight ?? 23;

    return (
      <Container centered horizontal className={cls.filterSelectedItem}>
        {variable && (
          <button
            className={cx(
              cls.filterSelectedItemElement,
              cls.filterSelectedItemElementFirst
            )}
            onClick={this.handleEdit}
          >
            {' '}
            {variable}{' '}
          </button>
        )}
        {matcher && (
          <button
            className={cx(
              cls.filterSelectedItemElement,
              cls.filterSelectedItemElementMiddle
            )}
            onClick={this.handleEdit}
          >
            {matcher}
          </button>
        )}
        {result && (
          <button
            className={cls.filterSelectedItemElement}
            onClick={this.handleEdit}
            title={result}
          >
            <span className={cls.filterSelectedItemTruncatedText}>
              {result}
            </span>
          </button>
        )}

        <div
          className={cx(
            cls.filterSelectedItemElement,
            cls.filterSelectedItemAddContainer
          )}
        >
          <Icon
            name="close"
            className={cls.filterSelectedItemAdd}
            onClick={this.props.onClear}
          />
        </div>

        <span
          ref={(ref) => (this.innerRef = ref)}
          role="img"
          className={cls.filterSelectedItemTriangle}
          style={{
            borderTopWidth: refHeight / 2,
            borderBottomWidth: refHeight / 2,
          }}
        >
          <span role="img" className={cls.filterSelectedItemSubTriangle} />
        </span>
      </Container>
    );
  }
}
