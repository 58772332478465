import { isStandardSyncAction } from '../helpers/actionTypes';

import standardSyncActionsReducer from './standardSyncActionsReducer';

const typeExists = (allTypes, type) => allTypes.some((t) => t === type);

const entityReducer =
  (childReducer = (st) => st) =>
  (actionTypes, { isCollection, syncReducers, hasNumericPending } = {}) => {
    const emptyState = isCollection ? [] : {};
    const initialState = {
      pending: {
        init: hasNumericPending ? 0 : false,
        ui: hasNumericPending ? 0 : false,
        background: hasNumericPending ? 0 : false,
      },
      errors: null,
      data: emptyState,
      links: {},
      meta: {},
    };

    return (state = initialState, action = {}) => {
      if (!typeExists(actionTypes, action.type)) {
        return state;
      }

      if (isStandardSyncAction(action.type)) {
        return standardSyncActionsReducer(action, state, initialState);
      }

      const newState = syncReducers
        ? syncReducers(action, state, initialState)
        : state;

      return childReducer(action, newState, initialState);
    };
  };

export default entityReducer;
