import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Field,
  Form,
  reduxForm,
  getFormValues,
  getFormSyncErrors,
} from 'redux-form';
import {
  TextField,
  Container,
  Section,
  Button,
  FormField,
} from 'src/components/IMUI';
import trintLogo from 'src/static/Trint_logo.jpeg';
import createValidator, { handleSubmitFail } from 'src/utils/validation';
import cls from '../Edit/UserOrgEditDataSources.module.css';
import { canManageOrganization } from 'src/userStorage';

const FORM_NAME_ORG_DATA_SOURCE = 'orgEditTrintDataSource';

class UserOrgDataSourceTrint extends React.PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func,
    submit: PropTypes.func.isRequired,
  };

  static defaultProps = {
    formValues: {},
  };

  onSubmitRequest = () => {
    this.props.submit(FORM_NAME_ORG_DATA_SOURCE);
  };

  render() {
    return (
      <Section className={cls.dataSourcesTile}>
        <Form noValidate onSubmit={this.props.handleSubmit}>
          <div className={cls.inputContainer}>
            <div className={cls.sourceHeader}>
              <Container className={cls.logoContainer}>
                <img
                  className={cls.sourceLogo}
                  alt="Trint API"
                  src={trintLogo}
                />
                Trint
              </Container>
            </div>
            <FormField>
              <Field
                fullWidth
                label={
                  <span className={cls.oAuthFieldLabel}>
                    Your Trint API key
                  </span>
                }
                name="trint_api_key"
                type="password"
                component={TextField}
                hintText="Your Trint API key"
              />
            </FormField>

            <Container className={cls.formButtonContainer}>
              <Button
                type={'submit'}
                disabled={!canManageOrganization()}
                className={cls.formButton}
                size="l"
                label="Save"
                onClick={this.onSubmitRequest}
              />
            </Container>
          </div>
        </Form>
      </Section>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    formValues: getFormValues(ownProps.form)(state),
    formErrors: getFormSyncErrors(ownProps.form)(state),
  };
}

function validateUserOrgInputs(values = {}) {
  const not = (fn) => (v) => !fn(v);
  const isDefined = (v) => Boolean(v);
  const longerThan = (n) => (v) => v.length > n;

  const validator = createValidator({
    trint_api_key: [
      [not(isDefined), () => 'Trint API Key is required'],
      [not(longerThan(1)), () => 'Trint API key is too short'],
    ],
  });

  return {
    ...validator(values),
  };
}

function validate(values) {
  return validateUserOrgInputs(values);
}

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({}, dispatch)
)(
  reduxForm({
    form: FORM_NAME_ORG_DATA_SOURCE,
    enableReinitialize: true,
    validate,
    onSubmitFail: (errors) => handleSubmitFail(errors),
  })(UserOrgDataSourceTrint)
);
