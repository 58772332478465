import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { submit, reset } from 'redux-form';

import tagCategoriesApi from 'src/api/TagCategories';
import TagCategoryForm from 'src/components/EditTagCategory/TagCategoryForm';
import { Button, Container, Actions, Drawer, Tag } from 'src/components/IMUI';
import { Label } from 'src/components/IMUI/Forms/base';

import { where } from 'im/api/Query';
import { Icon } from 'im/ui/Icon';

import cls from './CreateTagCategory.module.css';
import { canManageTag } from 'src/userStorage';

// const cx = classNames.bind(cls);

@connect(
  (state) => ({
    project: state.project,
    organizationCurrent: state.organizationCurrent,
  }),
  {
    createTagCategory: tagCategoriesApi.ofProject.create,
    submitForm: submit,
    resetForm: reset,
  }
)
export default class CreateTagCategory extends Component {
  static propTypes = {
    open: PropTypes.bool,
    project: PropTypes.object,
    organizationCurrent: PropTypes.object,
    noSync: PropTypes.bool,
    initialValues: PropTypes.object,

    createTagCategory: PropTypes.func.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    onTagCategoryEdit: PropTypes.func.isRequired,
    onSuccessfulAdd: PropTypes.func,
    submitForm: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
  };

  static FORM_NAME = 'createTagCategoryForm';

  state = {
    reviewMode: false,
    tagGroup: null,
  };

  handleRequestClose = () => {
    this.setState({ reviewMode: false, tagGroup: null });
    this.props.onRequestClose();
  };

  handleCreateTagCategory = async ({
    metatags,
    title,
    description,
    code,
    parent,
  }) => {
    const { organizationCurrent, noSync, project } = this.props;

    const query = where()
      .payload({
        metatags,
        title,
        description,
        code,
        relationships: [
          {
            relName: 'organization',
            type: 'organizations',
            id: organizationCurrent.data.id,
          },
          { relName: 'parent', type: 'tag_category', id: (parent || {}).id },
          { relName: 'project', type: 'projects', id: project.uid },
        ],
      })
      .actionMeta({ noSync });

    const tagCategoryCreated = await this.props.createTagCategory(query);
    if (!tagCategoryCreated) return;

    this.setState({ tagGroup: tagCategoryCreated.data, reviewMode: true });
    if (this.props.onSuccessfulAdd)
      this.props.onSuccessfulAdd(tagCategoryCreated);
  };

  handleRequestCreateTagCategory = () => {
    this.props.submitForm(CreateTagCategory.FORM_NAME);
  };

  handleAddAnother = () => {
    this.setState({ reviewMode: false, tagGroup: null });
    this.props.resetForm(CreateTagCategory.FORM_NAME);
  };

  handleEdtiTagCategory = () => {
    const { tagGroup } = this.state;
    this.props.onTagCategoryEdit(tagGroup.id);
  };

  renderFooter = () => {
    const { reviewMode } = this.state;

    return (
      <Container horizontal>
        <Actions>
          <Button
            negative
            size="l"
            label={reviewMode ? 'Close' : 'Cancel'}
            onClick={this.handleRequestClose}
          />
        </Actions>
        <Actions>
          {reviewMode ? (
            <Button
              disabled={!canManageTag()}
              size="l"
              label="Create another tag group"
              onClick={this.handleAddAnother}
            />
          ) : (
            <Button
              disabled={!canManageTag()}
              size="l"
              label="Create tag group"
              onClick={this.handleRequestCreateTagCategory}
            />
          )}
        </Actions>
      </Container>
    );
  };

  renderForm = () => {
    const { project, initialValues } = this.props;

    return (
      <div>
        <h3>Create a tag group</h3>
        <TagCategoryForm
          form={CreateTagCategory.FORM_NAME}
          initialValues={initialValues}
          allMetatags={project.metatags}
          onSubmit={this.handleCreateTagCategory}
        />
      </div>
    );
  };

  renderReview = () => {
    const { tagGroup } = this.state;

    return (
      <div>
        <h3 className={cls.createTagCategoryReviewTitle}>Tag group created</h3>
        <Container>
          <Label label="Tag group name" />

          <Container
            horizontal
            nowrap
            className={cls.createTagCategoryReviewItem}
          >
            <span>{tagGroup.title}</span>
            <Icon
              disabled={!canManageTag()}
              name="edit"
              className={cls.createTagCategoryReviewIcon}
              onClick={this.handleEdtiTagCategory}
            />
          </Container>
        </Container>

        {!!tagGroup.metatags.length && (
          <Container>
            <Label label="Metatags" />

            <Container
              horizontal
              nowrap
              className={cls.createTagCategoryReviewItem}
            >
              <div className={cls.createTagCategoryReviewMetatags}>
                {tagGroup.metatags.map((metatag) => (
                  <Tag alt square key={metatag} label={metatag} />
                ))}
              </div>
              <Icon
                disabled={!canManageTag()}
                name="edit"
                className={cls.createTagCategoryReviewIcon}
                onClick={this.handleEdtiTagCategory}
              />
            </Container>
          </Container>
        )}

        {!!tagGroup.description && (
          <Container>
            <Label label="Description" />

            <Container
              horizontal
              nowrap
              className={cls.createTagCategoryReviewItem}
            >
              <p>{tagGroup.description}</p>
              <Icon
                disabled={!canManageTag()}
                name="edit"
                className={cls.createTagCategoryReviewIcon}
                onClick={this.handleEdtiTagCategory}
              />
            </Container>
          </Container>
        )}

        {!!tagGroup.code && (
          <Container>
            <Label label="Shortcode" />

            <Container
              horizontal
              nowrap
              className={cls.createTagCategoryReviewItem}
            >
              <span>{tagGroup.code}</span>
              <Icon
                disabled={!canManageTag()}
                name="edit"
                className={cls.createTagCategoryReviewIcon}
                onClick={this.handleEdtiTagCategory}
              />
            </Container>
          </Container>
        )}
      </div>
    );
  };

  render() {
    const { open } = this.props;
    const { reviewMode } = this.state;

    return (
      <Drawer
        closable
        open={open}
        width={580}
        docked={true}
        renderFooter={this.renderFooter}
        onRequestClose={this.handleRequestClose}
      >
        {reviewMode ? this.renderReview() : this.renderForm()}
      </Drawer>
    );
  }
}
