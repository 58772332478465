import StandardSyncActions from '../../actionCreators/StandardSyncActions';
import UpdatePending from '../../actionCreators/UpdatePending';
import UpdateQuery from '../../actionCreators/UpdateQuery';
import resourceReducer from '../../reducers/resourceReducer';
import resourceReducerAsync from '../../reducers/resourceReducerAsync';
import AsyncActionsGenerator from '../AsyncActionsGenerator';
import EntityBase from '../EntityBase';

export default class ResourceBase extends EntityBase {
  static allResources = {};

  static defaultApi = {
    create: { method: 'POST' },
    put: { method: 'PUT', path: ':id' },
    patch: { method: 'PATCH', path: ':id' },
    destroy: { method: 'DELETE', path: ':id' },
  };

  static defaultActions = {
    [UpdatePending.actionType]: UpdatePending.action,
    ...StandardSyncActions.getActions(),
  };

  static getResourceByActionType = (actionType) => {
    const [type, reducerName] = actionType.split('/');
    const actionTypeRoot = `${type}/${reducerName}`;

    return ResourceBase.allResources[actionTypeRoot];
  };

  constructor(apiConfig, reducerName, { isCollection } = {}) {
    super(apiConfig, reducerName);
    this.isCollection = isCollection;
    this.derived = { derived: apiConfig.derived };

    const { syncActions, syncActionsConfig } = this.makeSyncActions(apiConfig);
    const { api, actionsConfig } = this.makeApiActions(
      apiConfig,
      this.constructor.defaultApi
    );
    this.api = api;
    this.actions = syncActions;
    this._actionsConfig = { ...actionsConfig, ...syncActionsConfig };

    this.syncReducers = this.makeSyncReducer(apiConfig);
    this.asyncReducers = this.makeAsyncReducer(apiConfig);
    ResourceBase.allResources = {
      ...ResourceBase.allResources,
      [this.actionTypeRoot]: this,
    };
  }

  get actionTypes() {
    return super.getActionTypes(this._actionsConfig);
  }

  get defaultGetMethodName() {
    const defaultApiGet = this.isCollection ? 'findAll' : 'find';
    const getMethod = this.api[defaultApiGet];

    if (getMethod) {
      return defaultApiGet;
    }

    const [firstGetMethodName] = Object.keys(
      AsyncActionsGenerator.filterGetMethods(this._actionsConfig) || {}
    ).slice(0, 1);

    return firstGetMethodName;
  }

  /**
   *
   * @param action - passed internally, this._actionsConfig for a certain action
   * @param params - can be either Query instance or [{ urlParams }, { queryParams }]
   * @returns promise or actionCreator
   * @private
   */
  _action(action, ...params) {
    const config = { action, params, meta: this.derived };
    return super.runRequest(config);
  }

  makeSyncActions(apiConfig = {}) {
    return super.getSyncActions(apiConfig.actions, ResourceBase.defaultActions);
  }

  makeApiActions(apiConfig = {}, defaultActions) {
    const { asyncActions, asyncActionsConfig } = super.getAsyncActions(
      apiConfig.api,
      this._action.bind(this),
      defaultActions
    );
    const getMethodActionsConfig =
      AsyncActionsGenerator.filterGetMethods(asyncActionsConfig);

    const updateQueryActionsConfig = !this.isCollection
      ? {}
      : UpdateQuery.makeActions(getMethodActionsConfig, this.actionTypeRoot);
    const actionsConfig = {
      ...asyncActionsConfig,
      ...updateQueryActionsConfig,
    };

    const apiExtended = Object.keys(asyncActions).reduce((acc, action) => {
      const updateQueryActionType =
        actionsConfig[action].types[UpdateQuery.actionType];
      if (updateQueryActionType) {
        acc[action].updateQuery = (...params) =>
          UpdateQuery.action(updateQueryActionType, ...params);
      }
      return acc;
    }, asyncActions || {});

    return { api: apiExtended, actionsConfig };
  }

  makeSyncReducer(apiConfig = {}) {
    return super.getReducers(this.actionTypes, apiConfig.reducers);
  }

  makeAsyncReducer(apiConfig = {}) {
    return super.getReducers(this.actionTypes, apiConfig.asyncReducers);
  }

  makeReducer() {
    const reducerAsync = this.asyncReducers || resourceReducerAsync;
    const reducer = (action, state) =>
      reducerAsync(action, resourceReducer(action, state));
    return super.getReducer(this._actionsConfig, reducer, {
      isCollection: this.isCollection,
      syncReducers: this.syncReducers,
    });
  }
}
