import { ListItem } from 'material-ui/List';
import PropTypes from 'prop-types';
import React from 'react';

export default class PopoverListItem extends React.Component {
  static propTypes = {
    style: PropTypes.object,
    bold: PropTypes.bool,
    empty: PropTypes.bool,
  };

  static defaultProps = {
    style: {},
  };

  render() {
    const { empty, style, bold, ...listItemProps } = this.props;

    return (
      <ListItem
        disabled={empty}
        {...listItemProps}
        style={{
          fontSize: 12,
          fontFamily: 'averta, Arial',
          fontWeight: bold ? 600 : 500,
          letterSpacing: '0.5px',
          lineHeight: 1.35,
          color: empty ? '#7c91a2' : '#2e2f40',
          textAlign: empty ? 'center' : 'left',
          ...style,
        }}
      />
    );
  }
}
