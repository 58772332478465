import React, { Component } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import chartsApi, { chartsGetAdvancedFilters } from 'src/api/Charts';
import { confirm } from 'src/components/ConfirmModal/ConfirmModal';
import {
  Button,
  Card,
  CardEmpty,
  Section,
  Table,
  Tooltip,
  CardFooter,
  Container,
  Actions,
  FilterTrigger,
  FilterJsonapi,
} from 'src/components/IMUI';
import PaginationIm from 'src/components/IMUI/Pagination/PaginationIm';
import Page from 'src/components/Page/Page';
import ManageSubjectsSurveyOnlyCard from 'src/components/SurveyOnly/ManageSubjectsSurveyOnlyCard';
import { CHARTS } from 'src/components/SurveyOnly/surveyOnlyUpsellText';
import { getText } from 'src/services/DictionaryService';
import { canManageChart, surveyOnly } from 'src/userStorage';
import { toLocaleDate, toLocaleDateTime } from 'src/utils/date';

import { where } from 'im/api/Query';
import { Icon } from 'im/ui/Icon';

import ChartTypeIcon from './components/ChartTypeIcon';
import chartTypes from './components/chartTypes';

import cls from './List.module.css';
import TableComposition from 'src/components/IMUI/Tables/TableComposition';
import emptyCharts from 'src/static/empty_chart.png';
const cx = classNames.bind(cls);

const CHART_TYPES_FILTER_VALUES = chartTypes.map(({ value, label }) => ({
  name: value,
  displayName: label,
}));

@connect(
  (state) => ({
    charts: state.charts,
    chartsAdvancedFilters: state.advancedFilters.charts,
  }),
  {
    getCharts: chartsApi.findAll,
    updateQuery: chartsApi.findAll.updateQuery,
    destroyChart: chartsApi.destroy,

    ...chartsGetAdvancedFilters,
  }
)
export default class ChartsList extends Component {
  static propTypes = {
    match: ReactRouterPropTypes.match,
    location: PropTypes.object,
    history: ReactRouterPropTypes.history,
    charts: PropTypes.object,
    chartsAdvancedFilters: PropTypes.object,

    getCharts: PropTypes.func,
    destroyChart: PropTypes.func,
    updateQuery: PropTypes.func,
  };

  state = {
    ready: false,
    showFilterCreator: false,
  };

  componentDidMount() {
    const { location } = this.props;

    const query = where()
      .filter('project_id_eq', this.props.match.params.projectId)
      .sort('-updated_at')
      .fromString(location.search)
      .pending('init');
    this.props.getCharts(query);
  }

  componentWillReceiveProps(nextProps) {
    if (
      !nextProps.charts.pending.init &&
      this.props.charts.pending.init &&
      !this.state.ready
    ) {
      this.setState({ ready: true });
    }
  }

  get filterConfig() {
    return [
      { name: 'name', displayName: 'Name', type: 'text' },
      {
        name: 'chart_type',
        displayName: 'Type',
        type: 'select',
        matchers: ['eq', 'not_eq'],
        values: CHART_TYPES_FILTER_VALUES,
      },
      { name: 'created_at', displayName: 'Created', type: 'date' },
      { name: 'updated_at', displayName: 'Updated', type: 'date' },
    ];
  }

  get filterAutopopulationConfig() {
    const { chartsAdvancedFilters } = this.props;

    return {
      allow: ['name'],
      resource: chartsAdvancedFilters,
      onRequestOptions: this.handleRequestFilterOptions,
    };
  }

  handleCreateChartClick = () => {
    this.props.history.push({
      pathname: `/analysis/${this.props.match.params.projectId}/charts/new`,
    });
  };
  handleEditChartClick = (chart) => {
    this.props.history.push({
      pathname: `/analysis/${this.props.match.params.projectId}/charts/${chart.id}`,
    });
  };
  handleDeleteChartClick = (chart) => {
    confirm(
      'Delete chart',
      'Are you sure that you want to delete this chart?',
      true
    ).then(
      () => this.props.destroyChart({ id: chart.id }),
      () => void 0
    );
  };
  handleToggleFilter = (showFilterCreator) => {
    this.setState({ showFilterCreator });
  };
  handleClearFilter = (filterValues) => {
    this.props.updateQuery(
      where().filter(filterValues).actionMeta({ doDelete: true })
    );
  };
  handleFilterApply = (filterValues) => {
    this.props.updateQuery(
      where().filter(filterValues).paginate({ number: 1 })
    );
  };
  handleSortChange = (sortKey) => {
    this.props.updateQuery(where().sort(sortKey).paginate({ number: 1 }));
  };
  handleRequestFilterOptions = (key, value) => {
    this.props[`chartsAF_${key}`](
      { key, value },
      where().filter('project_id_eq', this.props.match.params.projectId)
    );
  };
  handlePaginationChange = ({ page, per_page }) => {
    this.props.updateQuery(where().paginate({ number: page, size: per_page }));
  };

  renderRow = (chart) => {
    return (
      <Table.Row key={chart.id}>
        <Table.Cell className={cls.chartIconCell}>
          <ChartTypeIcon type={chart.options.chartType} />
        </Table.Cell>
        <Table.Cell onClick={() => this.handleEditChartClick(chart)}>
          {chart.name}
        </Table.Cell>
        <Table.Cell
          text={getText(chart.x.attribute_name)}
          label={getText(chart.x.model)}
        />
        <Table.Cell
          text={
            chart.y && chart.y.attribute_name
              ? getText(chart.y.attribute_name)
              : '–'
          }
          label={chart.y ? getText(chart.y.model) : null}
        />
        <Table.Cell text={toLocaleDate(chart.created_at)} />
        <Table.Cell text={toLocaleDateTime(chart.updated_at)} />
        <Table.Cell textAlign="center">
          {canManageChart() && (
            <Icon
              name="edit"
              tip="Edit"
              className={cx('chartAction')}
              onClick={() => this.handleEditChartClick(chart)}
            />
          )}
          {canManageChart() && (
            <Icon
              name="trash"
              tip="Remove"
              className={cx('chartAction')}
              onClick={() => this.handleDeleteChartClick(chart)}
            />
          )}
          <Tooltip />
        </Table.Cell>
      </Table.Row>
    );
  };

  render() {
    const { charts } = this.props;
    const { ready, showFilterCreator } = this.state;
    const data = charts.pending.init ? [] : charts.data;
    const { queryParams = {} } = charts.meta.getAction || {};

    if (surveyOnly())
      return (
        <Page title="Charts">
          <ManageSubjectsSurveyOnlyCard {...CHARTS} id="CHARTS" />
        </Page>
      );

    return (
      <Page
        pending={!ready || charts.pending.init}
        helpAppcue="-LHs64hOO49VFBbHCIJi"
        title="Charts"
      >
        <div>
          <Section type="sub-header" collapsed>
            <Container horizontal nowrap>
              <Actions>
                <FilterTrigger
                  active={showFilterCreator}
                  filters={queryParams.filter}
                  config={this.filterConfig}
                  onToggle={() => this.handleToggleFilter(!showFilterCreator)}
                />
              </Actions>
              <Actions>
                <Button
                  size="l"
                  label="Create chart"
                  onClick={this.handleCreateChartClick}
                />
              </Actions>
            </Container>
          </Section>
          <Section>
            <FilterJsonapi
              showCreator={showFilterCreator}
              filters={queryParams.filter}
              config={this.filterConfig}
              configAutopopulation={this.filterAutopopulationConfig}
              onToggleCreator={this.handleToggleFilter}
              onClear={this.handleClearFilter}
              onSubmit={this.handleFilterApply}
            />

            <Card>
              {!data.length && (
                <Section>
                  <Container centerHorizontal horizontal>
                    <div>
                      <img
                        src={emptyCharts}
                        alt="emptyCharts"
                        style={{
                          width: 300,
                          height: 260,
                          marginTop: 24,
                          marginBottom: 24,
                        }}
                      />
                    </div>
                  </Container>
                  <CardEmpty
                    large
                    title="No charts to display"
                    description="please create a new one"
                  >
                    <Button
                      label="Create chart"
                      secondary
                      onClick={this.handleCreateChartClick}
                    />
                  </CardEmpty>
                </Section>
              )}
              {!data.length ? null : (
                <Container>
                  <TableComposition>
                    <Table.Head
                      jsonapi
                      sortBy={queryParams.sort}
                      onSortChange={this.handleSortChange}
                    >
                      <Table.HCell width={40} text="Type" />
                      <Table.HCell text="Chart name" sortBy="name" />
                      <Table.HCell text="X axis" />
                      <Table.HCell text="Y axis" />
                      <Table.HCell
                        width={100}
                        text="Created"
                        sortBy="created_at"
                      />
                      <Table.HCell
                        width={140}
                        text="Updated"
                        sortBy="updated_at"
                      />
                      <Table.HCell width={80} />
                    </Table.Head>
                    <Table.Body>{charts.data.map(this.renderRow)}</Table.Body>
                  </TableComposition>

                  <CardFooter>
                    <PaginationIm
                      {...charts.links.meta}
                      label="charts"
                      items_label="charts"
                      onChange={this.handlePaginationChange}
                    />
                  </CardFooter>
                </Container>
              )}
            </Card>
          </Section>
        </div>
      </Page>
    );
  }
}
