import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import { Progress, EmptyText } from 'src/components/IMUI';

import cls from './TagGroupList.module.css';

const cx = classNames.bind(cls);

export const TagGroupList = ({ children, className, pending }) => (
  <ul
    className={cx(cls.tagGroupList, className)}
    data-qa="TagList:TagGroupList-display"
  >
    {pending && (
      <li className={cls.tagsListPendingOverlay}>
        <Progress />
      </li>
    )}
    {!pending && React.Children.count(children) === 0 && (
      <EmptyText text="No results found" />
    )}
    {children}
  </ul>
);

TagGroupList.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  pending: PropTypes.bool,
};
