import axios from 'src/axiosInstance';
import {
  SURVEY_PUBLIC__GET_REQUEST,
  SURVEY_PUBLIC__GET_SUCCESS,
  SURVEY_PUBLIC__GET_FAILURE,
  SURVEY_PUBLIC__PUT_REQUEST,
  SURVEY_PUBLIC__PUT_SUCCESS,
  SURVEY_PUBLIC__PUT_FAILURE,
  SURVEY_PUBLIC__LANGUAGE_CHANGE,
  SURVEY__CHANGE_ANSWER_VALUE,
  SURVEY__CHANGE_ANSWER_TAGS,
  SURVEY__VALIDATION_FAILURE,
  SURVEY_PUBLIC__CLEAR,
  SURVEY__CHANGE_ANSWER_OTHER_CHOICE_VALUE,
} from 'src/constants';
import { toApiFromSurveyAnswers } from 'src/serializers/surveySerializer';

export const getPublicSurvey = (surveyId) => async (dispatch) => {
  const doGetPublicSurvey = (id) =>
    axios
      .get(`/v1/survey/${id}`)
      .then((response) => ({ response }))
      .catch((error) => ({ error }));
  dispatch({ type: SURVEY_PUBLIC__GET_REQUEST });
  const { response, error } = await doGetPublicSurvey(surveyId);
  response
    ? dispatch({ type: SURVEY_PUBLIC__GET_SUCCESS, payload: response })
    : dispatch({
        type: SURVEY_PUBLIC__GET_FAILURE,
        payload: error,
        error: true,
        meta: { silent: true },
      });
};
export const savePublicSurveyAnswers =
  (questions, answers, info, isFinished, language) => async (dispatch) => {
    dispatch({ type: SURVEY_PUBLIC__PUT_REQUEST, payload: { isFinished } });
    const doPutPublicSurveyAnswers = (id, payload) =>
      axios
        .put(`/v1/survey/${id}`, payload)
        .then((response) => ({ response }))
        .catch((error) => ({ error }));
    const payload = toApiFromSurveyAnswers(questions, answers, info);
    payload.grantee_survey.is_finished = isFinished;
    payload.grantee_survey.language = language;
    const { response, error } = await doPutPublicSurveyAnswers(
      info.permalink,
      payload
    );
    response
      ? dispatch({
          type: SURVEY_PUBLIC__PUT_SUCCESS,
          payload: response,
          meta: { isFinished },
        })
      : dispatch({
          type: SURVEY_PUBLIC__PUT_FAILURE,
          payload: error,
          error: true,
        });
  };
export const changeAnswerValue = (questionId, value) => ({
  type: SURVEY__CHANGE_ANSWER_VALUE,
  payload: { questionId, value },
});
export const changeAnswerOtherChoiceValue = (questionId, value) => ({
  type: SURVEY__CHANGE_ANSWER_OTHER_CHOICE_VALUE,
  payload: { questionId, value },
});
export const changeAnswerTags = (questionId, tags) => ({
  type: SURVEY__CHANGE_ANSWER_TAGS,
  payload: { questionId, tags },
});
export const setSurveyLanguage = (locale) => ({
  type: SURVEY_PUBLIC__LANGUAGE_CHANGE,
  payload: locale,
});
export const clearPublicSurvey = (survey) => ({
  type: SURVEY_PUBLIC__CLEAR,
  payload: survey,
});
export const surveyValidationFailed = ({ title, status, statusText }) => ({
  type: SURVEY__VALIDATION_FAILURE,
  payload: { title, status, statusText },
});
