import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { SearchField } from 'src/components/IMUI/index';
import debounce from 'src/utils/debounce';

import { where } from 'im/api/Query';

export default class OrgSearchInput extends Component {
  static propTypes = {
    queryParams: PropTypes.object,
    searchKey: PropTypes.string,
    onSearchChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    searchKey: 'title_cont',
    queryParams: {},
  };

  onSearchChange = debounce((txt) => {
    const { searchKey } = this.props;
    const query = where().filter(searchKey, txt).paginate({ number: 1 });

    this.props.onSearchChange(query);
  }, 300);

  render() {
    const { queryParams, searchKey } = this.props;

    return (
      <SearchField
        noValidation
        name="search"
        hintText="Find organization"
        value={(queryParams.filter || {})[searchKey] || ''}
        onChange={this.onSearchChange}
      />
    );
  }
}
