import { Action } from 'im/utils/redux';

import { Notification } from './types';

/* eslint-disable */
export enum NOTIFICATIONS {
  SHOW = 'NOTIFICATIONS/SHOW',
  HIDE = 'NOTIFICATIONS/HIDE',
  HIDE_ALL = 'NOTIFICATIONS/HIDE_ALL',
}

export interface NotificationsState {
  notifications: Notification[];
}

export type NotificationInput = Omit<Notification, 'uid' | 'count'>;

export type NotificationShow = Action<NOTIFICATIONS.SHOW, NotificationInput>;
export type NotificationHide = Action<NOTIFICATIONS.HIDE, number>;
export type NotificationHideAll = Action<NOTIFICATIONS.HIDE_ALL>;

export type NotificationActionType =
  | NotificationShow
  | NotificationHide
  | NotificationHideAll;
