import organizationApi from 'src/api/Organization';
import { User } from 'src/api/User';
import history from 'src/historyInstance';
import Appcues from 'src/services/AppcuesService';
import Intercom from 'src/services/IntercomService';
import Sentry from 'src/services/SentryService';
import { isStaff } from 'src/userStorage';

export default function userEffects(_state, action, dispatch) {
  switch (action.type) {
    case User.actionTypes.changeCurrentOrganization.success:
      dispatch(
        organizationApi.current.find({
          id: action.payload.data.current_organization_id,
        })
      );
      history.replace('/analysis/');
      break;

    case User.actionTypes.put.success:
      return history.push('/user/organizations');

    case User.actionTypes.find.success: {
      if (!action.meta.isInit || !action.payload.data) return;
      const user = _state.user?.data;
      const meta = {
        user_id: user.id,
        email: user.email,
        name: `${user.first_name || (user.email || '').split('@')[0]} ${
          user.last_name || ''
        }`.trim(),
        current_organization_id: user.current_organization_id,
        use_otp: user.use_otp,
        user_hash: user.intercom_signature,
      };
      Appcues.identify(user.id, meta);
      Appcues.start();
      Intercom.boot(meta);
      Sentry.setUser(meta);
      Sentry.setUserRole(isStaff() ? 'staff' : 'regular');
      if (user.current_organization_id)
        dispatch(
          organizationApi.current.find({ id: user.current_organization_id })
        );
      break;
    }
    default:
      break;
  }
}
