import {
  CHART__AXIS_VALUES_GET_REQUEST,
  CHART__AXIS_VALUES_GET_SUCCESS,
  CHART__AXIS_VALUES_GET_FAILURE,
} from 'src/constants';

const initialState = {
  data: {},
  pending: false,
  error: null,
};

export default function chartsAxisValuesReducer(state = initialState, action) {
  switch (action.type) {
    case CHART__AXIS_VALUES_GET_REQUEST:
      return { ...state, pending: true, error: null };

    case CHART__AXIS_VALUES_GET_SUCCESS:
      state.data[action.meta.model] ||= {};
      state.data[action.meta.model][action.meta.attribute] =
        action.payload.data;
      return { ...state, pending: false, error: null };

    case CHART__AXIS_VALUES_GET_FAILURE:
      return {
        ...state,
        pending: false,
        error: `Failed to fetch charts axis values for ${action?.meta?.model}: ${action?.meta?.attribute}`,
      };

    default:
      return state;
  }
}
