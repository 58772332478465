import PropTypes from 'prop-types';
import React from 'react';
import { Table, Checkbox, MoreAddAll } from 'src/components/IMUI';
import GrantTableItem from './GrantTableItem';
import cls from './GrantSelector.module.css';
import TableComposition from '../IMUI/Tables/TableComposition';

export default class GrantTableList extends React.PureComponent {
  static propTypes = {
    values: PropTypes.array,
    dataPaginated: PropTypes.array,
    dataAll: PropTypes.array,
    filteredData: PropTypes.array,
    compact: PropTypes.bool,
    onRemove: PropTypes.func,
    onAdd: PropTypes.func,
    onSortChange: PropTypes.func,
    isCheck: PropTypes.func.isRequired,
    onToggleSelect: PropTypes.func.isRequired,
    isAdded: PropTypes.func.isRequired,
    prevAdd: PropTypes.func.isRequired,
  };
  static defaultProps = { values: [] };

  render() {
    const defaultSort = this.props.values.find((v) => v.defaultSort) || {};
    const isAllPageAdded =
      this.props.dataPaginated.filter(this.props.isCheck).length ===
      this.props.dataPaginated.length;
    return (
      <TableComposition compact={this.props.compact}>
        <Table.Head
          data={this.props.dataAll}
          filteredData={this.props.filteredData}
          defaultSort={{
            key: defaultSort?.path,
            direction: defaultSort?.ascending || false,
            transform: defaultSort?.valueTransform,
          }}
          onSortChange={this.props.onSortChange}
          className={cls.tableHead}
        >
          <Table.HCell width={46} textAlign="center" style={{ fontSize: 16 }}>
            <MoreAddAll
              isFilterApplied={
                this.props.dataAll.length !== this.props.filteredData.length
              }
              itemsToAddLen={
                this.props.filteredData.filter((el) => !this.props.isAdded(el))
                  .length
              }
              itemsToRemoveLen={
                this.props.filteredData.filter(this.props.isAdded).length
              }
              onAddAll={() => this.props.onAdd(this.props.filteredData)}
              onRemoveAll={() => this.props.onRemove(this.props.filteredData)}
            />
          </Table.HCell>
          <Table.HCell className={cls.staticColumn} textAlign="center">
            <Checkbox
              noLabel
              checked={isAllPageAdded}
              onCheck={(_ev, allChecked) =>
                this.props.onToggleSelect(this.props.dataPaginated, allChecked)
              }
            />
          </Table.HCell>
          {this.props.values.map((col) => (
            <Table.HCell
              key={col.label}
              text={col.label}
              sortBy={col.path}
              sortTransform={col.valueTransform}
              className={col.className}
              width={col.width}
            />
          ))}
        </Table.Head>
        <Table.Body>
          {this.props.dataPaginated.map((entity) => (
            <GrantTableItem
              key={`${entity.id}-${entity.funding_id}-${entity.grant_id}`}
              values={this.props.values}
              entity={entity}
              selected={this.props.isCheck(entity)}
              multiSelected={
                this.props.dataAll.filter(this.props.isCheck).length > 0
              }
              currentAdded={this.props.isAdded(entity)}
              previousAdded={this.props.prevAdd(entity)}
              onAdd={this.props.onAdd}
              onRemove={this.props.onRemove}
              onToggleSelect={this.props.onToggleSelect}
            />
          ))}
        </Table.Body>
      </TableComposition>
    );
  }
}
