import React from 'react';

import Page from 'src/components/Page/Page';
import SuccessfulScreen from 'src/components/SuccessfulScreen/SuccessfulScreen';

const UserOrganizationAddSuccess = () => (
  <Page title="Create organization">
    <SuccessfulScreen
      title="Your organization request was sent!"
      text="Impact Mapper team will review this request in short time"
      successLabel="Go to organizations"
      successRedirectPath="/user/organizations"
    />
  </Page>
);

export default UserOrganizationAddSuccess;
