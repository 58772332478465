import React from 'react';
import PropTypes from 'prop-types';
import TagGroupRow from './TagGroupRow';
import TableComposition from 'src/components/IMUI/Tables/TableComposition';
import { Table } from 'src/components/IMUI';

export default class TagsTable extends React.PureComponent {
  static propTypes = {
    tagCategories: PropTypes.object,
    tags: PropTypes.object,
    onSortChange: PropTypes.func.isRequired,
    onDeleteTag: PropTypes.func.isRequired,
    onDeleteTagCategory: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onAddTagToTagCategory: PropTypes.func.isRequired,
    showCodes: PropTypes.bool,
    allExpanded: PropTypes.bool,
  };

  handleEditTagGroup = (tagGroup) => this.props.onEdit('category', tagGroup.id);
  handleEditTag = (tag) => this.props.onEdit('tag', tag.id);
  handleDeleteTag = (tag) => this.props.onDeleteTag(tag);
  handleDeleteTagGroup = (tagGroup) => this.props.onDeleteTagCategory(tagGroup);

  render() {
    return (
      <TableComposition
        key={
          this.props.allExpanded
            ? 'expanded-'
            : 'collapsed-' + this.props.showCodes
            ? 'showCodes-'
            : 'hideCodes-'
        }
        compact={true}
        layout={this.props.showCodes ? 'fixed' : 'auto'}
        pending={
          this.props.tagCategories.pending.init ||
          this.props.tagCategories.pending.ui
        }
      >
        <Table.Head
          jsonapi
          sortBy={this.props.tagCategories.meta.getAction?.queryParams.sort}
          onSortChange={this.props.onSortChange}
        >
          {this.props.showCodes && (
            <Table.HCell
              colSpan={1}
              width={100}
              text="Shortcode"
              sortBy="code"
            />
          )}
          <Table.HCell
            colSpan={1}
            width={120}
            text="Tag Group"
            sortBy="title"
          />
          <Table.HCell colSpan={1} width={0} text="Tag" sortBy="id" />
          <Table.HCell colSpan={1} width={100} sortBy="metatags" />
        </Table.Head>

        {this.props.tagCategories.data.map((tagGroup) => (
          <TagGroupRow
            isCompact={true}
            key={tagGroup.id}
            tagGroup={tagGroup}
            tags={this.props.tags.data}
            onEdit={this.handleEditTagGroup}
            onDelete={this.handleDeleteTagGroup}
            onTagEdit={this.handleEditTag}
            onTagDelete={this.handleDeleteTag}
            onAddTagToTagCategory={this.props.onAddTagToTagCategory}
            defaultExpanded={this.props.allExpanded}
            showCodes={this.props.showCodes}
          />
        ))}
      </TableComposition>
    );
  }
}
