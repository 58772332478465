import axios from 'src/axiosInstance';
import {
  PROJECTS__GET_REQUEST,
  PROJECTS__GET_SUCCESS,
  PROJECTS__GET_FAILURE,
  PROJECT__GET_FAILURE,
  PROJECT__GET_REQUEST,
  PROJECT__GET_SUCCESS,
  PROJECT__GET_INIT_REQUEST,
  PROJECT__GET_INIT_SUCCESS,
  PROJECT__GET_INIT_FAILURE,
  PROJECT__UPDATE_REQUEST,
  PROJECT__UPDATE_SUCCESS,
  PROJECT__UPDATE_FAILURE,
  PROJECT__GRANTEES_GET_REQUEST,
  PROJECT__GRANTEES_GET_SUCCESS,
  PROJECT__GRANTEES_GET_FAILURE,
  PROJECT__CREATE_REQUEST,
  PROJECT__CREATE_SUCCESS,
  PROJECT__CREATE_FAILURE,
  PROJECT__SUMMARY_GET_REQUEST,
  PROJECT__SUMMARY_GET_SUCCESS,
  PROJECT__SUMMARY_GET_FAILURE,
  PROJECT__DELETE_REQUEST,
  PROJECT__DELETE_SUCCESS,
  PROJECT__DELETE_FAILURE,
  PROJECT__ACTIVE_CHANGE,
} from 'src/constants';

const doGetProject = (projectId) =>
  axios
    .get(`/v1/projects/${projectId}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
const doGetGrantees = (projectId) =>
  axios
    .get(`/v1/projects/${projectId}/grantees`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
const doCreateProject = (payload) =>
  axios
    .post('/v1/projects', payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
const doUpdateProject = (projectId, payload) =>
  axios
    .put(`/v1/projects/${projectId}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
const doGetProjects = () =>
  axios
    .get('/v1/projects')
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
const doGetGranteeSummary = (projectId) =>
  axios
    .get(`/v1/projects/${projectId}/grantee_summary`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
const doGetGrantSummary = (projectId) =>
  axios
    .get(`/v1/projects/${projectId}/grant_summary`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
const doDeleteProject = (projectId) =>
  axios
    .delete(`/v1/projects/${projectId}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));

export const projectActiveChange = (id, path) => ({
  type: PROJECT__ACTIVE_CHANGE,
  payload: { id, path },
});
export const getInitProject = (projectId, meta) => async (dispatch) => {
  dispatch({ type: PROJECT__GET_INIT_REQUEST, payload: { projectId } });
  const { response, error } = await doGetProject(projectId);
  response
    ? dispatch({ type: PROJECT__GET_INIT_SUCCESS, payload: response, meta })
    : dispatch({
        type: PROJECT__GET_INIT_FAILURE,
        payload: { ...error, projectId },
        error: true,
        meta,
      });
  return response;
};
export const getProject = (projectId, meta) => async (dispatch) => {
  dispatch({ type: PROJECT__GET_REQUEST, payload: { projectId } });
  const { response, error } = await doGetProject(projectId);
  response
    ? dispatch({ type: PROJECT__GET_SUCCESS, payload: response, meta })
    : dispatch({
        type: PROJECT__GET_FAILURE,
        payload: error,
        error: true,
        meta,
      });
  return response;
};
export const getProjectGrantees = (projectId) => async (dispatch) => {
  dispatch({ type: PROJECT__GRANTEES_GET_REQUEST });
  const { response, error } = await doGetGrantees(projectId);
  response
    ? dispatch({ type: PROJECT__GRANTEES_GET_SUCCESS, payload: response })
    : dispatch({
        type: PROJECT__GRANTEES_GET_FAILURE,
        payload: error,
        error: true,
      });
  return response;
};
export const createProject = (payload) => async (dispatch) => {
  dispatch({ type: PROJECT__CREATE_REQUEST });
  const { response, error } = await doCreateProject(payload);
  response
    ? dispatch({
        type: PROJECT__CREATE_SUCCESS,
        payload: { id: response.data.id, ...response },
      })
    : dispatch({ type: PROJECT__CREATE_FAILURE, payload: error, error: true });
  return response;
};
export const updateProject = (id, payload) => async (dispatch) => {
  dispatch({ type: PROJECT__UPDATE_REQUEST });
  const { response, error } = await doUpdateProject(id, payload);
  response
    ? dispatch({
        type: PROJECT__UPDATE_SUCCESS,
        payload: { ...response, noSideEffect: payload.noSideEffect },
      })
    : dispatch({ type: PROJECT__UPDATE_FAILURE, payload: error, error: true });
  if (response) dispatch(getProject(id));
  return response;
};
export const getProjects = () => async (dispatch) => {
  dispatch({ type: PROJECTS__GET_REQUEST });
  const { response, error } = await doGetProjects();
  response
    ? dispatch({ type: PROJECTS__GET_SUCCESS, payload: response })
    : dispatch({ type: PROJECTS__GET_FAILURE, payload: error, error: true });
  return response;
};
export const getGranteeSummary = (projectId) => async (dispatch) => {
  dispatch({ type: PROJECT__SUMMARY_GET_REQUEST });
  const { response, error } = await doGetGranteeSummary(projectId);
  response
    ? dispatch({ type: PROJECT__SUMMARY_GET_SUCCESS, payload: response })
    : dispatch({
        type: PROJECT__SUMMARY_GET_FAILURE,
        payload: error,
        error: true,
      });
  return response;
};
export const getGrantSummary = (projectId) => async (dispatch) => {
  dispatch({ type: PROJECT__SUMMARY_GET_REQUEST });
  const { response, error } = await doGetGrantSummary(projectId);
  if (response) {
    dispatch({ type: PROJECT__SUMMARY_GET_SUCCESS, payload: response });
  } else {
    dispatch({
      type: PROJECT__SUMMARY_GET_FAILURE,
      payload: error,
      error: true,
    });
  }
};
export const deleteProject = (projectId) => async (dispatch) => {
  dispatch({ type: PROJECT__DELETE_REQUEST });
  const { response, error } = await doDeleteProject(projectId);
  response
    ? dispatch({
        type: PROJECT__DELETE_SUCCESS,
        payload: response,
        meta: { projectId },
      })
    : dispatch({ type: PROJECT__DELETE_FAILURE, payload: error, error: true });
  return response;
};
