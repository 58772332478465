import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Card, Container } from 'src/components/IMUI';
import TileButton from './TileButton';
import TileContainer from './TileContainer';
import TileHeader from './TileHeader';
import TileIcon from './TileIcon';
import TileText from './TileText';
import TileTitle from './TileTitle';
import cls from './Tile.module.css';
const cx = classNames.bind(cls);
export const Tile = ({
  children,
  onClick,
  className,
  size,
  cardProps,
  grow,
  containerProps,
}) => (
  <div
    className={cx(
      'imui-tile',
      `imui-tile-size-${size}`,
      { 'imui-tile-clickable': onClick, 'imui-tile-grow': grow },
      className
    )}
    onClick={onClick}
    role={onClick ? 'button' : 'none'}
  >
    <Card
      largePadding={size === 'm' || size === 'l'}
      className={cx(
        'imui-tile-card',
        { 'imui-tile-card-grow': grow },
        cardProps.className
      )}
      {...cardProps}
    >
      <Container
        {...containerProps}
        className={cx('imui-tile-container', containerProps.className)}
      >
        {children}
      </Container>
    </Card>
  </div>
);

Tile.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  size: PropTypes.oneOf(['s', 'm', 'l']),
  cardProps: PropTypes.object,
  containerProps: PropTypes.object,
  grow: PropTypes.bool,
  onClick: PropTypes.func,
};
Tile.defaultProps = { size: 'm', cardProps: {}, containerProps: {} };

Tile.Title = TileTitle;
Tile.Text = TileText;
Tile.Icon = TileIcon;
Tile.Button = TileButton;
Tile.Container = TileContainer;
Tile.Header = TileHeader;

export default Tile;
