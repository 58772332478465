import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import cls from './Table.module.css';

const cx = classNames.bind(cls);

export default class TableHeader extends Component {
  static propTypes = {
    headers: PropTypes.array.isRequired,
    className: PropTypes.string,
  };

  render() {
    const { headers, className } = this.props;
    return (
      <thead className={cx('tableHeader', className)}>
        <tr>
          {headers.map((header, headerIndex) => (
            <th
              key={headerIndex}
              className={cx('tableCell', 'tableHeaderCell')}
            >
              {header}
            </th>
          ))}
        </tr>
      </thead>
    );
  }
}
