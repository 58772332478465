import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';
import { Section, Container, Actions, Button } from 'src/components/IMUI/index';
import { Icon } from 'im/ui/Icon';
import cls from 'src/pages/App/Analysis/Tags/TagsReport/Report.module.css';
import { toLocaleDateTime } from 'src/utils/date';
import { getAutocodeReports } from 'src/services/AuthService';
const cx = classNames.bind(cls);

export default class ReportOverview extends PureComponent {
  static defaultProps = { reports: [], unFinish: () => void 0 };

  render() {
    const project_reports = getAutocodeReports()?.project_reports || [];
    const reportNames = this.props.reports
      ?.filter((r) => project_reports?.some((p) => p.report_id == r.id))
      ?.map((r) => `${r.name} (id ${r.id})`);
    const uniqueReportids = [
      ...new Set(
        project_reports
          .filter((r) => r.report_id != null)
          .map((r) => r.report_id)
      ),
    ];
    const uniqueNames = [...new Set(reportNames)];

    return (
      <Section noBorder className={cls.limitedWidth}>
        <h1>
          {uniqueReportids?.length} reports are being processed by our AI tool
        </h1>
        <div className={cx(cls.subtitleSoft)}>
          <h3>
            Based on your current tag groups, tags and previous selections, soon
            we will bring tagging suggestions
          </h3>
        </div>

        <Container className={cx(cls.subtitleSoft)}>
          <ul>
            {uniqueNames.map((report) => (
              <li key={report}>
                <span>{report}</span>
              </li>
            ))}
          </ul>
          <strong>
            <Icon name="wait" tip="started at" />
            &nbsp;&nbsp;Request submitted at &nbsp;
            {toLocaleDateTime(new Date())}
          </strong>
        </Container>

        <Section type="sticky-footer" secondary={false}>
          <Container horizontal>
            <Actions />
            <Actions>
              <Button
                size="l"
                label="Back to Reports"
                onClick={this.props.unFinish}
              />
            </Actions>
          </Container>
        </Section>
      </Section>
    );
  }
}
