import { where } from 'im/api/Query';
import Resource from 'im/api/Resource';

const common = {
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
  meta: { noSync: true },
};

const apiConfig = {
  root: '/v3/grants/export',
  type: 'grantsExport',
  api: {
    custom: {
      single: {
        ...common,
        path: where().filter('id_eq', ':id').toString(),
      },
      all: {
        ...common,
      },
    },
  },
};

export const GrantsExport = new Resource(apiConfig);
export default GrantsExport.api;
