import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { PopoverLight } from 'src/components/IMUI';
import colors from 'src/css/constants.json';
import { containsText } from 'src/utils/string';

import { Icon } from 'im/ui/Icon';

import cls from './SeriesPopover.module.css';

const cx = classNames.bind(cls);

export default class SeriesPopover extends Component {
  static propTypes = {
    ...PopoverLight.propTypes,
    renderHeader: PropTypes.func,
    renderFooter: PropTypes.func,
    series: PropTypes.array,
    activeSeries: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array,
    ]),
    multiple: PropTypes.bool,
    onSeriesChange: PropTypes.func,
  };

  static defaultProps = {
    renderHeader: () => void 0,
    renderFooter: () => void 0,
    onSearchTextChange: () => void 0,
    activeSeries: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      activeSeries: Array.isArray(props.activeSeries)
        ? props.activeSeries
        : [props.activeSeries],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeSeries !== this.props.activeSeries) {
      this.setState({
        activeSeries: Array.isArray(nextProps.activeSeries)
          ? nextProps.activeSeries
          : [nextProps.activeSeries],
      });
    }
  }

  getFilteredSeries(series, text = '') {
    return (series || []).filter((s) => containsText(s, text));
  }

  handleSeriesChange = (ev, series) => {
    const { multiple } = this.props;
    const { activeSeries } = this.state;
    let newSeries;

    if (multiple) {
      newSeries = this.isActive(series)
        ? activeSeries.filter((s) => s !== series)
        : [...activeSeries, series];
    } else {
      newSeries = [series];
    }

    this.setState({ activeSeries: newSeries }, () => {
      this.props.onSeriesChange(multiple ? newSeries : series);
      return multiple ? null : this.props.onRequestClose();
    });
  };

  isActive = (series) => {
    const { activeSeries } = this.state;
    return activeSeries.indexOf(series) !== -1;
  };

  renderSeries = (searchText) => {
    const { renderChildren, series, multiple } = this.props;

    if (renderChildren && !!renderChildren()) {
      return renderChildren(searchText);
    }

    const seriesFiltered = this.getFilteredSeries(series, searchText);
    return (
      <PopoverLight.Menu>
        {seriesFiltered.map((s, index) => (
          <PopoverLight.MenuItem
            key={`${s}_${index}`}
            className={cx({
              [cls.popoverItemActiveSingle]: !multiple && this.isActive(s),
            })}
            value={s}
            primaryText={s}
            onClick={(ev) => this.handleSeriesChange(ev, s)}
            rightIcon={
              (multiple && this.isActive(s) && (
                <Icon name="check" color={colors.green} />
              )) ||
              undefined
            }
          />
        ))}
      </PopoverLight.Menu>
    );
  };

  render() {
    const { open, anchorEl, children, width } = this.props;

    return (
      <PopoverLight
        search
        open={open}
        anchorEl={anchorEl}
        customWidth={width}
        style={{ minWidth: 220 }}
        onRequestClose={this.props.onRequestClose}
        header={this.props.renderHeader()}
        footer={this.props.renderFooter()}
        renderChildren={this.renderSeries}
        onSearchTextChange={this.props.onSearchTextChange}
        onGoBack={this.props.onGoBack}
      >
        {children}
      </PopoverLight>
    );
  }
}
