import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getParentCategories } from 'src/api/Tags';
import {
  Actions,
  Button,
  Card,
  CardEmpty,
  Container,
  Dialog,
  Table,
  Tag,
} from 'src/components/IMUI';
import { Icon } from 'im/ui/Icon';
import cls from './Quotes.module.css';
import TableComposition from 'src/components/IMUI/Tables/TableComposition';
import TaggingTypeBlock from 'src/components/TaggingType/TaggingTypeBlock';
import {
  TagGroupList,
  TagGroupItem,
  TagListItem,
} from 'src/components/TagList';

const AITaggingSuggestions = ({
  taggingSuggestions,
  onViewTaggingSuggestionsToggle,
  onApproveTaggingSuggestion,
  onDeclineTaggingSuggestion,
  onEditTaggingSuggestion,
  showTaggingSuggestions,
}) => {
  const [openAllTaggingSuggestions, setOpenAllTaggingSuggestions] =
    useState(false);

  const onCloseTaggingSuggestionsModal = () => {
    setOpenAllTaggingSuggestions(false);
  };

  const renderTree = (taggingSuggestion) => {
    if (taggingSuggestion?.type?.includes('Tagging::Quote'))
      return <Tag alt square label="Quote" />;
    if (!taggingSuggestion?.tag?.id && taggingSuggestion?.report)
      return <Tag alt square label="Quote" />;

    const showTagFullName = () => taggingSuggestion.tag.title?.length > 50;
    const [root, ...categories] = getParentCategories(taggingSuggestion.tag);
    if (!root) return null;

    const isRoot = !categories?.length || !root.child_categories?.length;
    if (isRoot)
      return (
        <TagGroupList className={cls.tagRowTagRootList}>
          <TagGroupItem key={root.id} tagGroup={root} isExpandable={true}>
            <Container horizontal className={cls.tagRowTagContainer}>
              <Tag
                leftAlign
                tip={showTagFullName() ? taggingSuggestion.tag.title : null}
                label={taggingSuggestion.tag.title}
                labelProps={{ className: cls.tagRowTagContainerLabel }}
                className={cls.tagRowTagTitle}
              />
              <TaggingTypeBlock
                tagging={taggingSuggestion}
                className={cls.tagRowTagging}
              />
            </Container>
          </TagGroupItem>
        </TagGroupList>
      );

    return (
      <TagGroupList className={cls.tagRowTagGroupList}>
        <TagGroupItem key={root.id} tagGroup={root} isExpandable={true}>
          {categories.map((category, i) => (
            <TagListItem
              key={category.id}
              tag={category}
              style={{ marginLeft: i * 12 }}
            >
              <Container horizontal className={cls.tagRowTagContainer}>
                {categories.length === i + 1 && (
                  <Tag
                    leftAlign
                    tip={showTagFullName() ? taggingSuggestion.tag.title : null}
                    label={taggingSuggestion.tag.title}
                    labelProps={{ className: cls.tagRowTagContainerLabel }}
                    className={cls.tagRowTagTitle}
                  />
                )}
                <TaggingTypeBlock
                  tagging={taggingSuggestion}
                  className={cls.tagRowTagging}
                />
              </Container>
            </TagListItem>
          ))}
        </TagGroupItem>
      </TagGroupList>
    );
  };

  return (
    <Container horizontal className={cls.quotesWrapper}>
      <Actions />
      <Actions style={{ flexFlow: 'nowrap' }}>
        <Button
          size="s"
          secondary
          label={`${
            showTaggingSuggestions ? 'Hide' : 'Show'
          } tagging suggestions (${taggingSuggestions.length})`}
          onClick={onViewTaggingSuggestionsToggle}
        />
        <Button
          size="s"
          secondary
          label={'View all tagging suggestions'}
          onClick={() => setOpenAllTaggingSuggestions(true)}
        />
      </Actions>
      <Dialog
        open={openAllTaggingSuggestions}
        bodyStyle={{
          width: '100%',
        }}
        contentStyle={{
          width: '100%',
        }}
        title={`AI-Suggested taggings (${taggingSuggestions.length})`}
        extraLarge
        onRequestClose={() => {
          setOpenAllTaggingSuggestions(false);
        }}
        leftActions={
          <Button
            size="l"
            negative
            onClick={onCloseTaggingSuggestionsModal}
            label="Close"
          />
        }
      >
        {taggingSuggestions.length == 0 && (
          <CardEmpty title="No taggings to validate" />
        )}
        {taggingSuggestions.length > 0 && (
          <Card noPadding>
            <TableComposition>
              <Table.Body>
                {taggingSuggestions.map((taggingSuggestion) => (
                  <Table.Row
                    key={taggingSuggestion.id}
                    className={cls.quotesTableRow}
                  >
                    <Table.Cell>{renderTree(taggingSuggestion)}</Table.Cell>
                    <Table.Cell>
                      <div className={cls.quotesContent}>
                        {taggingSuggestion.content}
                      </div>
                    </Table.Cell>
                    <Table.Cell
                      textAlign="right"
                      vAlign="top"
                      style={{ display: 'inline-flex' }}
                    >
                      <Icon
                        name="edit"
                        tip="Edit suggestion"
                        placeTip={'left'}
                        style={{ fontSize: 16 }}
                        onClick={() => {
                          onEditTaggingSuggestion(taggingSuggestion);
                        }}
                      />
                      &emsp;&emsp;
                      <Icon
                        name="close"
                        tip="Reject suggestion"
                        placeTip={'left'}
                        style={{ fontSize: 16, color: 'red' }}
                        onClick={() =>
                          onDeclineTaggingSuggestion(taggingSuggestion)
                        }
                      />
                      &emsp;&emsp;
                      <Icon
                        name="check"
                        tip="Accept suggestion"
                        placeTip={'left'}
                        style={{ fontSize: 16, color: 'green' }}
                        onClick={() =>
                          onApproveTaggingSuggestion(taggingSuggestion)
                        }
                      />
                      &emsp;&emsp;
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </TableComposition>
          </Card>
        )}
      </Dialog>
    </Container>
  );
};

AITaggingSuggestions.propTypes = {
  showTaggingSuggestions: PropTypes.bool,
  taggingSuggestions: PropTypes.object,
  onEditTaggingSuggestion: PropTypes.func.isRequired,
  onApproveTaggingSuggestion: PropTypes.func.isRequired,
  onDeclineTaggingSuggestion: PropTypes.func.isRequired,
  onViewTaggingSuggestionsToggle: PropTypes.func.isRequired,
};

export default AITaggingSuggestions;
