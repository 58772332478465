import React from 'react';
import PropTypes from 'prop-types';
import { Actions, FormField, Label, TextField } from 'src/components/IMUI';
import TagSelector from 'src/components/TagSelector/TagSelector';
import TranslateButton from 'src/components/TranslateButton/TranslateButton';
import { getSurveyI18n } from 'src/utils/surveysI18n';
import BaseQuestionProperties from './BaseQuestionProperties';
import PropertyBox from './PropertyBox';
import cls from './BaseQuestionProperties.module.css';

export default class RatingProperties extends React.PureComponent {
  static propTypes = {
    question: PropTypes.object,
    readOnly: PropTypes.bool,
    isAnswerMode: PropTypes.bool,
    tagsWithTagGroups: PropTypes.array,
    onChangeQuestionProperty: PropTypes.func.isRequired,
    survey: PropTypes.object,
    completeness: PropTypes.object,
    onChangeI18nProperty: PropTypes.func,
    errors: PropTypes.object,
  };
  static defaultProps = { errors: {}, question: {} };
  static validate() {
    return {};
  }

  handleTagsChanged = (tagId) => {
    this.props.question.tags = [tagId];
    this.props.onChangeQuestionProperty(
      this.props.question.id,
      'tags',
      this.props.question.tags
    );
  };
  handleTagRemoved = (tagId) => {
    if (this.props.question.tags.indexOf(tagId) !== -1) {
      this.props.onChangeQuestionProperty(
        this.props.question.id,
        'tags',
        this.props.question.tags.filter((id) => id !== tagId)
      );
    }
  };

  render() {
    const { question, survey, completeness, onChangeI18nProperty, errors } =
      this.props;
    const minLabel = getSurveyI18n(survey, question, ['settings', 'minLabel']);
    const maxLabel = getSurveyI18n(survey, question, ['settings', 'maxLabel']);

    return (
      <BaseQuestionProperties {...this.props}>
        <PropertyBox>
          <TagSelector
            isAnswerMode={this.props.isAnswerMode}
            tagsWithTagGroups={this.props.tagsWithTagGroups}
            noTagProps={{
              label: 'Add tag',
              grey: true,
              square: true,
              outline: false,
            }}
            selected={this.props.question.tags}
            readOnly={this.props.readOnly}
            onChange={this.handleTagsChanged}
            onRemove={this.handleTagRemoved}
          />
        </PropertyBox>
        <br />
        <PropertyBox>
          <Actions grid>
            <FormField>
              <div className={cls.propertyLabelWrapper}>
                <Label label="Minimum label" />
                <TranslateButton
                  owner={question}
                  languages={survey.languages}
                  error={
                    completeness.questions[question.id].settings.minLabel
                      ?.length
                  }
                  values={question.i18n}
                  path={['settings', 'minLabel']}
                  title={`Translate Minimum label`}
                  onChange={onChangeI18nProperty}
                  multiLine
                />
              </div>
              <TextField
                autoFocus
                name="minLabel"
                multiLine
                rows={1}
                rowsMax={10}
                fullWidth
                flatDark
                hintText={!minLabel && 'Type in...'}
                value={minLabel}
                onChange={(value) =>
                  onChangeI18nProperty(value, ['settings', 'minLabel'])
                }
                error={errors.question}
              />
            </FormField>
            <FormField>
              <div className={cls.propertyLabelWrapper}>
                <Label label="Max label" />
                <TranslateButton
                  owner={question}
                  languages={survey.languages}
                  error={
                    completeness.questions[question.id].settings.maxLabel
                      ?.length
                  }
                  values={question.i18n}
                  path={['settings', 'maxLabel']}
                  title={`Translate Maximum label`}
                  onChange={onChangeI18nProperty}
                  multiLine
                />
              </div>
              <TextField
                autoFocus
                name="maxLabel"
                multiLine
                rows={1}
                rowsMax={10}
                fullWidth
                flatDark
                hintText={!maxLabel && 'Type in...'}
                value={maxLabel}
                onChange={(value) =>
                  onChangeI18nProperty(value, ['settings', 'maxLabel'])
                }
                error={errors.question}
              />
            </FormField>
          </Actions>
        </PropertyBox>
      </BaseQuestionProperties>
    );
  }
}
