import reducerRegistry from 'im/api/registry';
import Resource from 'im/api/Resource';

const apiConfig = {
  root: '/v3/grants/import',
  type: 'grants_import',
  api: {
    allow: ['find', 'put'],
    create: {
      headers: { 'Content-Type': 'multipart/form-data' },
      method: 'POST',
      meta: { noSync: true },
    },
  },
};

const GrantsImport = new Resource(apiConfig, 'grantsImport');
reducerRegistry.register(GrantsImport);

export default GrantsImport.api;
