import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { PopoverLight, Toggle, Button } from 'src/components/IMUI';
import { Icon } from 'im/ui/Icon';
import cls from './TableSettings.module.css';
import { canManageGrants } from 'src/userStorage';
const cx = classNames.bind(cls);

export default class TableSettings extends React.PureComponent {
  static propTypes = {
    isCompact: PropTypes.bool,
    hasBorder: PropTypes.bool,
    onToggleCompact: PropTypes.func.isRequired,
  };
  state = { popoverOpen: false, anchorEl: null };
  handleOpen = (ev) => {
    ev?.preventDefault();
    this.setState({ popoverOpen: true, anchorEl: ev.currentTarget });
  };
  handleClose = () => {
    this.setState({ popoverOpen: false });
  };
  handleToggle = () => {
    this.props.onToggleCompact();
    this.handleClose();
  };
  render() {
    const { popoverOpen, anchorEl } = this.state;
    const { isCompact, hasBorder } = this.props;

    return (
      <div>
        {canManageGrants() && (
          <Button
            clean
            className={cx(cls.buttonSettings, {
              [cls.buttonSettingsBorder]: hasBorder,
            })}
            icon={<Icon name="settings" />}
            onClick={this.handleOpen}
          />
        )}
        <PopoverLight
          anchorEl={anchorEl}
          open={popoverOpen}
          anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
          targetOrigin={{ horizontal: 'left', vertical: 'top' }}
          onRequestClose={this.handleClose}
          header={<span>Table Settings</span>}
        >
          <PopoverLight.Menu>
            <PopoverLight.MenuItem>
              <Toggle
                mini
                label="Compact"
                labelPosition="right"
                toggled={isCompact}
                onToggle={this.handleToggle}
              />
            </PopoverLight.MenuItem>
          </PopoverLight.Menu>
        </PopoverLight>
      </div>
    );
  }
}
