import { where } from 'im/api/Query';
import reducerRegistry from 'im/api/registry';
import ResourceCollection from 'im/api/ResourceCollection';

const apiConfig = {
  root: '/api/v3/tag_categories',
  type: 'tag_categories',
  api: {
    allow: ['findAll'],
    custom: {
      findAllNestedWithTags: {
        method: 'GET',
        path: where()
          .include('nested_child_categories', 'nested_child_categories.tags')
          .paginate({ size: 1500 })
          .toString(),
        meta: {
          includeMapper: {
            nested_child_categories: 'child_categories',
            'nested_child_categories.tags': ['tags', 'child_categories.tags'],
          },
        },
      },
      findAllNestedIds: {
        method: 'GET',
        path: where()
          .include('nested_child_categories')
          .fields('tag_category', 'id', 'nested_child_categories')
          .paginate({ size: 1500 })
          .toString(),
      },
    },
  },
  derived: (tagCategories = []) => ({
    flatIds: tagCategories.flatMap(({ id, nested_child_categories }) =>
      [id].concat(nested_child_categories.map((child) => child.id))
    ),
  }),
};

export const TagCategoriesAll = new ResourceCollection(
  apiConfig,
  'tagCategoriesAll'
);
reducerRegistry.register(TagCategoriesAll);

export default TagCategoriesAll.api;
