import PropTypes from 'prop-types';
import React from 'react';

export class ClickOutside extends React.Component {
  static propTypes = {
    onClickOutside: PropTypes.func,
    children: PropTypes.node,
    className: PropTypes.string,
    allowModalClicks: PropTypes.bool,
  };

  componentDidMount() {
    document.addEventListener('mousedown', (e) => this.handleClickOutside(e));
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', (e) =>
      this.handleClickOutside(e)
    );
  }

  anyModalContains(target) {
    const allModals = document.querySelectorAll('[data-reactroot]');
    return [...allModals]
      .map((el) => el.parentElement)
      .filter((el) => el.id !== 'root')
      .some((el) => el.contains(target));
  }

  handleClickOutside(event) {
    const { allowModalClicks } = this.props;

    if (!this.innerRef) {
      return;
    }
    if (
      this.innerRef.contains(event.target) ||
      (allowModalClicks && this.anyModalContains(event.target))
    ) {
      return;
    }

    this.props.onClickOutside();
  }

  render() {
    const { children, className } = this.props;

    return (
      <div ref={(ref) => (this.innerRef = ref)} className={className}>
        {children}
      </div>
    );
  }
}

export default ClickOutside;
