import { Password } from 'src/api/Password';
import userApi, { User } from 'src/api/User';
import { Users } from 'src/api/Users';
import history from 'src/historyInstance';
import Appcues from 'src/services/AppcuesService';
import Intercom from 'src/services/IntercomService';
import Sentry from 'src/services/SentryService';
import { where } from 'im/api/Query';
import { Notifications } from 'im/ui/Notifications';

const USER_FIELDS = [
  'user',
  'id',
  'city',
  'country_code',
  'current_organization_id',
  'disabled',
  'email',
  'first_name',
  'phone',
  'flags',
  'intercom_signature',
  'last_name',
  'memberships_count',
  'permissions',
  'state',
  'use_otp',
]; // removed orgnaization and disabled

export default function authEffects(_state, action, dispatch) {
  switch (action.type) {
    case User.actionTypes.authenticated: {
      dispatch(Notifications.hideAll());
      Appcues.identify(action.payload.userId);
      dispatch(
        userApi.find(
          where({ id: action.payload.userId })
            .actionMeta({ isInit: true })
            .pending('init')
            .fields(...USER_FIELDS)
        )
      );
      break;
    }
    case Password.actionTypes.put.success:
      history.push('/login', { resetPasswordSuccess: true });
      break;

    case Password.actionTypes.requestResetTokenPublic.success:
      history.push('/password/forgot/success');
      break;

    case Users.actionTypes.create.success:
      history.push('/invitations/success');
      break;

    case User.actionTypes.logOut:
      Appcues.track('LOGGED_OUT');
      Appcues.anonymous();
      Intercom.shutdown();
      Sentry.clear();
      break;

    default:
      break;
  }
}
