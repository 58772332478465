import React from 'react';
import {
  indigo400,
  indigo100,
  indigo700,
  teal100,
  teal700,
  white,
  darkBlack,
  red400,
} from 'material-ui/styles/colors';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import PropTypes from 'prop-types';

export default class MuiWrapper extends React.PureComponent {
  static propTypes = { children: PropTypes.object };
  render() {
    return (
      <MuiThemeProvider
        muiTheme={getMuiTheme({
          fontFamily: 'averta, Arial, sans-serif',
          palette: {
            primary1Color: '#52cccc',
            primary2Color: teal100,
            primary3Color: teal700,
            primaryTextColor: white,
            accent1Color: indigo400,
            accent2Color: indigo100,
            accent3Color: indigo700,
            accentTextColor: darkBlack,
            warningColor: red400,
          },
          tableHeaderColumn: { height: 32, spacing: 12 },
          tableRow: { height: 24 },
          tableRowColumn: { height: 24, spacing: 12 },
          tabs: {
            backgroundColor: 'transparent',
            textColor: '#a1b1bf',
            selectedTextColor: '#52cccc',
          },
          inkBar: { backgroundColor: '#52cccc' },
          borderRadius: 6,
          textField: {
            textColor: '#221e20',
            hintColor: '#b4c1cc',
            floatingLabelColor: '#7c91a2',
            disabledTextColor: '#b4c1cc',
            errorColor: '#fa3e3e',
            focusColor: '#7c91a2',
            backgroundColor: 'transparent',
            borderColor: 'transparent',
          },
          checkbox: {
            boxColor: '#9aaab8',
            checkedColor: '#52cccc',
            requiredColor: '#52cccc',
            disabledColor: '#bbbbbb',
            labelColor: '#221e20',
            labelDisabledColor: '#7c91a5',
          },
          radioButton: {
            borderColor: '#9aaab8',
            backgroundColor: 'transparent',
            checkedColor: '#52cccc',
            requiredColor: '#52cccc',
            disabledColor: '#bbbbbb',
            labelColor: '#221e20',
            labelDisabledColor: '#7c91a5',
            size: 17,
          },
          toggle: {
            thumbOnColor: '#ffffff',
            thumbOffColor: '#ffffff',
            thumbDisabledColor: '#e3e9ee',
            thumbRequiredColor: '#fa3e3e',
            trackOnColor: '#52cccc',
            trackOffColor: '#f4f6f9',
            trackDisabledColor: '#9aaab8',
            labelColor: '#7c91a2',
            labelDisabledColor: '#e3e9ee',
            trackRequiredColor: '#fa3e3e',
          },
          slider: {
            trackSize: 2,
            trackColor: '#e3e9ee',
            trackColorSelected: '#AAE9E3',
            handleSize: 12,
            handleSizeDisabled: 8,
            handleSizeActive: 18,
            handleColorZero: '#52cccc',
            handleFillColor: white,
            selectionColor: '#52cccc',
            rippleColor: '#52cccc',
          },
          datePicker: {
            color: '#52cccc',
            textColor: white,
            calendarTextColor: '#221e20',
            selectColor: '#52cccc',
            selectTextColor: white,
            calendarYearBackgroundColor: '#fff',
            headerColor: '#52cccc',
          },
        })}
      >
        {this.props.children}
      </MuiThemeProvider>
    );
  }
}
