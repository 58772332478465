import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import { connect } from 'react-redux';
import { submit, destroy } from 'redux-form';
import { createFunding } from 'src/actionCreators/fundingActionCreators';
import { Actions, Section, Container, Button } from 'src/components/IMUI';
import Page from 'src/components/Page/Page';
import history from 'src/historyInstance';
import FundingInfoAdd from './components/FundingInfoAdd';
import cls from './FundingAdd.module.css';
import { canManageGrants } from 'src/userStorage';
import { getOrgText } from 'src/services/DictionaryService';
import ReactRouterPropTypes from 'react-router-prop-types';
const cx = classNames.bind(cls);

@connect(pick(['organizationCurrent']), {
  createFunding,
  submitForm: submit,
  destroyForm: destroy,
})
export default class FundingAdd extends React.PureComponent {
  static propTypes = {
    createFunding: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    match: ReactRouterPropTypes.match,
    organizationCurrent: PropTypes.object,
  };

  static defaultProps = { initialValues: {}, organizationCurrent: {} };

  handleSubmit = async (data) => {
    const normalizedData = {
      fundings: data.fundings.map((f) => ({
        ...f,
        type: f.funding_type || data.funding_type,
      })),
    };
    const singleFunding = normalizedData.fundings?.[0];
    if (!singleFunding) return null;
    await this.props
      .createFunding(singleFunding)
      .then((funding) => {
        history.push(`/grants/list/funding/${funding.id}`);
      })
      .catch((_e) => {
        history.goBack();
      });
  };
  render() {
    const granteeId = this.props.match.params.granteeId
      ? +this.props.match.params.granteeId
      : null;
    const grantId = this.props.match.params.grantId
      ? +this.props.match.params.grantId
      : null;
    const initialValues = this.props.match.params.quick
      ? {
          start_date: '1900-01-01',
          end_date: '2100-01-01',
          recipient_type: 'organization',
          fundings: [
            {
              grantee_id: granteeId,
              grant_id: grantId,
              amount: 0,
              currency: 'USD',
              start_date: '1900-01-01',
              end_date: '2100-01-01',
            },
          ],
        }
      : {
          start_date: null,
          end_date: null,
          recipient_type: 'organization',
          fundings: [
            {
              grantee_id: granteeId,
              grant_id: grantId,
              amount: null,
              currency: null,
              start_date: null,
              end_date: null,
            },
          ],
        };

    return (
      <Page
        back
        hasFooter
        title={
          this.props.match.params.quick
            ? `Link ${getOrgText('Grant')} to a ${getOrgText('Grantee')}`
            : this.props.match.params.grantId
            ? `Add funding to ${getOrgText('Grant')}`
            : 'Add Grant'
        }
        id={`scrollContainer_${cx('grantAddSection')}`}
      >
        <Section collapsed className={cls.grantAddSection}>
          <div className={cls.grantAddCentered}>
            <FundingInfoAdd
              initialValues={initialValues}
              quickMode={Boolean(this.props.match.params.quick)}
              fundingTypes={
                this.props.organizationCurrent.data?.funding_types || []
              }
              onSubmit={this.handleSubmit}
              showCustomFields={false}
            />
          </div>
        </Section>
        <Section type="sticky-footer">
          <Container horizontal>
            <Actions>
              <Button
                negative
                size="l"
                label="Back"
                onClick={() => history.go(-1)}
              />
            </Actions>
            <Actions>
              <Button
                disabled={!canManageGrants()}
                size="l"
                label={this.props.match.params.quick ? 'LINK' : 'CREATE'}
                onClick={() => this.props.submitForm('newFunding')}
              />
            </Actions>
          </Container>
        </Section>
      </Page>
    );
  }
}
