import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import colors from 'src/css/constants.json';

import { Icon } from 'im/ui/Icon';

import cls from './PopoverActions.module.css';

const cx = classNames.bind(cls);

const Item = ({ onClick, buttonProps, liProps, iconName, text, children }) => (
  <li {...liProps} className={cx(cls.popoverActionsItem, liProps.className)}>
    <button
      {...buttonProps}
      className={cx(cls.popoverActionsItemButton, buttonProps.className)}
      onClick={onClick}
    >
      {iconName && <Icon name={iconName} color={colors['mid-grey']} />}
      {text && <span className={cls.popoverActionsItemText}>{text}</span>}
      {children}
    </button>
  </li>
);

Item.propTypes = {
  onClick: PropTypes.func,
  buttonProps: PropTypes.object,
  liProps: PropTypes.object,
  iconName: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.node,
};

Item.defaultProps = {
  liProps: {},
  buttonProps: {},
};

const PopoverActions = ({ children, className }) => (
  <ul className={cx(cls.popoverActions, className)}>{children}</ul>
);

PopoverActions.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

PopoverActions.Item = Item;

export default PopoverActions;
