import classNames from 'classnames/bind';
import MUIDrawer from 'material-ui/Drawer';
import PropTypes from 'prop-types';
import React from 'react';

import { Icon } from 'im/ui/Icon';

import DrawerFooter from './DrawerFooter';

import cls from './Drawer.module.css';

const cx = classNames.bind(cls);

const OVERLAY_STYLE = { opacity: 0 };

export class Drawer extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    open: PropTypes.bool,
    disableSwipeToOpen: PropTypes.bool,
    docked: PropTypes.bool,
    openSecondary: PropTypes.bool,
    overlayStyle: PropTypes.object,
    className: PropTypes.string,
    closable: PropTypes.bool,
    urlParam: PropTypes.string,
    containerClassName: PropTypes.string,
    renderFooter: PropTypes.func,
    onRequestClose: PropTypes.func.isRequired,
  };

  static defaultProps = {
    disableSwipeToOpen: true,
    openSecondary: true,
    docked: false,
    overlayStyle: {},
    onRequestClose: () => void 0,
  };
  constructor(props) {
    super(props);
    this.state = { open: !!props.urlParam };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.urlParam !== this.props.urlParam) {
      this.handleToggle(!!nextProps.urlParam);
    }
  }
  handleToggle = (open) => {
    this.setState({ open });
  };
  handleRequestChange = (open) => {
    if (open) return;
    this.props.onRequestClose();
  };
  render() {
    const {
      disableSwipeToOpen,
      openSecondary,
      children,
      open,
      closable,
      className,
      containerClassName,
      docked,
      overlayStyle,
      renderFooter,
      ...rest
    } = this.props;
    const { open: openByParamChange } = this.state;

    return (
      <MUIDrawer
        {...rest}
        docked={docked}
        overlayStyle={{ ...OVERLAY_STYLE, ...overlayStyle }}
        containerClassName={cx(cls.imuiDrawer, containerClassName)}
        open={open || openByParamChange}
        disableSwipeToOpen={disableSwipeToOpen}
        openSecondary={openSecondary}
        onRequestChange={this.handleRequestChange}
        data-qa="[material-ui]Drawer"
      >
        <div
          className={cx(cls.imuiDrawerContent, className)}
          data-qa="Drawer:__content"
        >
          {closable && (
            <div className={cls.imuiDrawerCloseContainer}>
              <Icon
                className={cls.imuiDrawerIconClose}
                name="close-large"
                onClick={this.props.onRequestClose}
              />
            </div>
          )}
          {children}
        </div>
        {renderFooter && <DrawerFooter>{renderFooter()}</DrawerFooter>}
      </MUIDrawer>
    );
  }
}
