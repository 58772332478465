import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import cls from './TabSegment.module.css';

const cx = classNames.bind(cls);

export const TabSegment = (props) => {
  const {
    id,
    active,
    text,
    onClick: handleClick,
    className,
    flat,
    compact,
    border,
    dark,
    filter,
    ...rest
  } = props;

  const onClick = () => {
    !active && handleClick(id);
  };
  return (
    <div
      className={cx(
        cls.tabSegment,
        {
          [cls.tabSegmentActive]: active,
          [cls.tabSegmentInactive]: !active,
          [cls.tabSegmentCompact]: !!compact,
          [cls.tabSegmentFlat]: flat,
          [cls.tabSegmentBorder]: border,
          [cls.tabSegmentDark]: dark,
        },
        className
      )}
      onClick={onClick}
      {...rest}
    >
      <span>{text}</span>
    </div>
  );
};

TabSegment.propTypes = {
  id: PropTypes.any,
  active: PropTypes.bool,
  text: PropTypes.node,
  compact: PropTypes.bool,
  flat: PropTypes.bool,
  dark: PropTypes.bool,
  border: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  filter: PropTypes.any,
};

export default TabSegment;
