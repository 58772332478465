import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import cls from './Error.module.css';

const cx = classNames.bind(cls);

export const Error = ({
  meta: { touched, submitFailed, error: reduxFormError } = {},
  error,
}) => (
  <div
    className={cx('imui-text-field-error', {
      'imui-text-field-error-visible':
        ((submitFailed || touched) && reduxFormError) || error,
    })}
  >
    {reduxFormError || error}
  </div>
);

Error.propTypes = {
  meta: PropTypes.object,
  error: PropTypes.string,
};

export default Error;
