import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import IconSubItem from 'src/components/IconSubItem/IconSubItem';
import { Table, Tag } from 'src/components/IMUI';
import { Icon } from 'im/ui/Icon';
import cls from './TagGroupRow.module.css';
import { canManageTag } from 'src/userStorage';
import TableComposition from 'src/components/IMUI/Tables/TableComposition';

const cx = classNames.bind(cls);
const tagWithCode = (tag = {}, showCodes) =>
  showCodes && tag.code?.length > 0
    ? `${tag.code}${tag.code.endsWith('.') ? '' : '.'} ${tag.title}`
    : tag.title;
const childItemNotch = 16;

export default class TagGroupRow extends React.PureComponent {
  static propTypes = {
    tagGroup: PropTypes.object,
    tags: PropTypes.array,
    isCompact: PropTypes.bool,
    defaultExpanded: PropTypes.bool,
    showCodes: PropTypes.bool,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onTagEdit: PropTypes.func.isRequired,
    onTagDelete: PropTypes.func.isRequired,
    onAddTagToTagCategory: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { isExpanded: this.props.defaultExpanded };
  }

  handleToggleRow = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ isExpanded: !this.state.isExpanded });
  };
  handleEdit = (ev, tagGroup) => {
    ev.preventDefault();
    ev.stopPropagation();
    canManageTag() && this.props.onEdit(tagGroup);
  };
  handleDelete = (ev, tagGroup) => {
    ev.preventDefault();
    ev.stopPropagation();
    canManageTag() && this.props.onDelete(tagGroup);
  };
  handleEditTag = (e, tag, tagGroup) => {
    e.preventDefault();
    e.stopPropagation();
    canManageTag() && this.props.onTagEdit(tag, tagGroup);
  };
  handleDeleteTag = (e, tag) => {
    e.preventDefault();
    e.stopPropagation();
    canManageTag() && this.props.onTagDelete(tag);
  };
  handleAddToCat = (e, tagGroup) => {
    e.preventDefault();
    e.stopPropagation();
    canManageTag() && this.props.onAddTagToTagCategory(tagGroup);
  };
  renderTagIcon({ onClick, isHidden }) {
    return (
      <Icon
        name="tag"
        tip="create tag in this group"
        className={cx(cls.tagGroupRowIconFirst, { [cls.isHidden]: isHidden })}
        onClick={onClick}
      />
    );
  }

  renderTagCategory(tagGroup, treeLevel = 0) {
    const subItemStyle = { marginLeft: childItemNotch * treeLevel };
    const isRoot = !tagGroup.parent?.id;
    const hasTags =
      tagGroup.tags.length > 0 ||
      tagGroup.child_categories.some((i) => i.tags?.length > 0);
    const toggleRow = isRoot ? (ev) => this.handleToggleRow(ev) : undefined;

    return (
      <Table.Row
        key={tagGroup.id}
        onClick={toggleRow}
        className={cx({ [cls.tagGroupRowHasChildren]: true })}
        borderDashed={!isRoot || (hasTags && this.state.isExpanded)}
      >
        {this.props.showCodes && (
          <Table.Cell colSpan={1} text={tagGroup.code} />
        )}

        <Table.Cell
          colSpan={2}
          className={cx({ [cls.tagGroupRowBold]: isRoot && hasTags })}
        >
          {isRoot &&
            (hasTags ? (
              <Icon
                name={this.state.isExpanded ? 'folder' : 'folder-closed'}
                className={cls.tagGroupRowIcon}
              />
            ) : (
              <Icon name="folder-closed" className={cls.tagGroupRowIcon} />
            ))}
          {!isRoot && <IconSubItem style={subItemStyle} />}
          {tagGroup.title}

          <div style={{ float: 'right' }} className={cls.metatagTagsList}>
            {tagGroup.metatags.length > 0 && <label>Metatags </label>}
            {tagGroup.metatags?.map((m) => (
              <Tag alt square key={m} label={m} />
            ))}
          </div>
        </Table.Cell>

        {canManageTag() && (
          <Table.CellSettings
            colSpan={1}
            onEdit={(ev) => this.handleEdit(ev, tagGroup)}
            onDelete={(ev) => this.handleDelete(ev, tagGroup)}
          >
            {this.renderTagIcon({
              onClick: (ev) => this.handleAddToCat(ev, tagGroup),
            })}
          </Table.CellSettings>
        )}
        {!canManageTag() && <Table.Cell />}
      </Table.Row>
    );
  }

  renderTags = (tagGroup) =>
    this.props.tags
      .filter(({ tag_categories }) =>
        tag_categories.find(({ id }) => id === tagGroup.id)
      )
      .sort((tag1, tag2) =>
        tagWithCode(tag1, this.props.showCodes).localeCompare(
          tagWithCode(tag2, this.props.showCodes),
          'en',
          { numeric: this.props.showCodes }
        )
      )
      .map((tag) => (
        <Table.Row
          borderDashed
          key={`${tagGroup.id}-${tag.id}`}
          onClick={(ev) => this.handleEditTag(ev, tag, tagGroup)}
        >
          {this.props.showCodes && <Table.Cell colSpan={1} text={tag.code} />}
          <Table.Cell colSpan={1} />
          <Table.Cell colSpan={1}>
            <Tag
              outline
              leftAlign
              label={tag.title}
              className={cls.tagGroupTag}
            />
          </Table.Cell>
          {canManageTag() ? (
            <Table.CellSettings
              colSpan={1}
              onEdit={(ev) => this.handleEditTag(ev, tag, tagGroup)}
              onDelete={(ev) => this.handleDeleteTag(ev, tag)}
            >
              {this.renderTagIcon({ isHidden: true })}
            </Table.CellSettings>
          ) : (
            <Table.Cell />
          )}
        </Table.Row>
      ));

  renderChildren(tagGroup, treeLevel = 0) {
    return (
      tagGroup.parent ? [this.renderTagCategory(tagGroup, treeLevel)] : []
    )
      .concat(this.renderTags(tagGroup))
      .concat(
        tagGroup.child_categories.map((child) =>
          this.renderChildren(child, treeLevel + 1)
        )
      );
  }

  render() {
    const hasNestedTags =
      this.props.tagGroup.tags?.length > 0 ||
      this.props.tagGroup.child_categories?.length > 0;
    return (
      <tbody key={this.props.showCodes ? '1' : '2'}>
        {this.renderTagCategory(this.props.tagGroup)}

        {hasNestedTags && (
          <Table.ExpandableRow isExpanded={this.state.isExpanded} colSpan={4}>
            <TableComposition compact={this.props.isCompact} layout="fixed">
              <Table.Head className={cls.tableHeaderCollapsed}>
                {this.props.showCodes && (
                  <Table.HCell
                    colSpan={1}
                    width={100}
                    text="Shortcode"
                    sortBy="code"
                  />
                )}
                <Table.HCell
                  colSpan={1}
                  width={120}
                  text="Tag Group"
                  sortBy="title"
                />
                <Table.HCell colSpan={1} width={0} text="Tag" />
                <Table.HCell colSpan={1} width={100} />
              </Table.Head>
              <Table.Body>
                {this.renderChildren(this.props.tagGroup, 0)}
              </Table.Body>
            </TableComposition>
          </Table.ExpandableRow>
        )}
      </tbody>
    );
  }
}
