import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import { Tooltip } from 'src/components/IMUI';

import { TabSegment } from './TabSegment';

import cls from './TabSegments.module.css';

const cx = classNames.bind(cls);

export const TabSegments = ({
  segments,
  onToggle,
  className,
  compact,
  flat,
  border,
  dark,
}) => {
  const getOrder = (index, lastIndex) => {
    switch (index) {
      case 0:
        return 'first';
      case lastIndex:
        return 'last';
      default:
        return 'middle';
    }
  };

  return (
    <div className={cx('tabSegments', className)}>
      {segments.map(({ text, active, id, tooltipText, ...rest }, index) => (
        <TabSegment
          key={id || index}
          onClick={onToggle}
          text={text}
          active={active}
          id={id}
          data-tip={tooltipText}
          compact={compact}
          flat={flat}
          border={border}
          dark={dark}
          className={cx(`tabSegment-${getOrder(index, segments.length - 1)}`)}
          {...rest}
        />
      ))}
      <Tooltip />
    </div>
  );
};

TabSegments.propTypes = {
  compact: PropTypes.bool,
  flat: PropTypes.bool,
  dark: PropTypes.bool,
  border: PropTypes.bool,
  className: PropTypes.string,
  onToggle: PropTypes.func.isRequired,
  segments: PropTypes.arrayOf(PropTypes.shape(TabSegment.propTypes)).isRequired,
};

export default TabSegments;
