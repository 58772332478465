import { actionStates } from '../entities/AsyncActionsGenerator';
import { isType, isUpdateQueryAction } from '../helpers/actionTypes';
import { isGetMethod } from '../helpers/string';

const getFindMethodName = (actionType) => actionType.split('/').slice(-2)[0];
const isSuccessfulAction = (actionType) =>
  isType(actionType, actionStates.success);

export default function runSync(
  dispatch,
  action,
  storeElement,
  currentResource
) {
  const actionMeta = action.meta || {};
  const isUpdateQuery = isUpdateQueryAction(action.type);

  if (!isSuccessfulAction(action.type) && !isUpdateQuery) {
    return;
  }

  // Request GET on POST, PUT, DELETE or Query updated - filters, pagination, sorting etc
  if (
    (!isGetMethod(actionMeta.method || '') && !actionMeta.noSync) ||
    isUpdateQuery
  ) {
    const { urlParams, queryParams } =
      (storeElement.meta || {}).getAction || {};
    const { includeMapper } = storeElement.meta || {};
    const methodName = isUpdateQuery
      ? getFindMethodName(action.type)
      : currentResource.defaultGetMethodName;
    const meta = !isUpdateQuery
      ? { pending: 'background', includeMapper }
      : { includeMapper };
    if (!methodName || !currentResource.api[methodName]) {
      return;
    }
    dispatch(
      currentResource.api[methodName].call(this, urlParams, queryParams, meta)
    );
  }
}
