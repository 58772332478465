import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { PopoverLight } from 'src/components/IMUI';

import { Icon } from 'im/ui/Icon';

import HCell from './HeaderCell';

import cls from './HCellSettings.module.css';

export default class HCellSettings extends Component {
  static propTypes = {
    ...HCell.propTypes,

    renderSettingsOptions: PropTypes.func,
  };

  state = {
    popoverOpen: false,
  };

  handleToggle = (ev) => {
    ev?.preventDefault();
    this.setState({
      popoverOpen: !this.state.popoverOpen,
      anchorEl: ev.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({ popoverOpen: false });
  };

  render() {
    const { popoverOpen, anchorEl } = this.state;
    const { renderSettingsOptions } = this.props;

    return (
      <HCell width={92} textAlign="center" {...this.props}>
        <Icon
          name="settings"
          className={cls.tableHCellSettingsIcon}
          onClick={this.handleToggle}
        />

        {renderSettingsOptions && (
          <PopoverLight
            anchorEl={anchorEl}
            open={popoverOpen}
            anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
            targetOrigin={{ horizontal: 'left', vertical: 'top' }}
            onRequestClose={this.handleClose}
            header={<span>Table Settings</span>}
          >
            {renderSettingsOptions()}
          </PopoverLight>
        )}
      </HCell>
    );
  }
}
