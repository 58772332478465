import React from 'react';
import PropTypes from 'prop-types';
import { Drawer, Container, Actions, Divider } from 'src/components/IMUI/index';
import { Icon } from 'im/ui/Icon';
import {
  DrawerDetailsItem,
  DrawerDetailsSubTitleItem,
  DrawerDetailsTable,
  DrawerDetailsTitle,
  DrawerDetailsList,
  DrawerDetailsSectionTitle,
  DrawerDetailsEmpty,
} from './DrawerDetailsElements';
import cls from './DrawerDetails.module.css';
import { canManageGrants } from 'src/userStorage';

export default class DrawerDetails extends React.PureComponent {
  static propTypes = {
    ...Drawer.propTypes,
    renderTitle: PropTypes.func,
    renderSubTitle: PropTypes.func,
    renderContent: PropTypes.func,
    renderDetails: PropTypes.func,
    renderFooter: PropTypes.func,
    onExport: PropTypes.func,
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
  };

  static Title = DrawerDetailsTitle;
  static SectionTitle = DrawerDetailsSectionTitle;
  static Table = DrawerDetailsTable;
  static SubTitleItem = DrawerDetailsSubTitleItem;
  static Item = DrawerDetailsItem;
  static List = DrawerDetailsList;
  static Empty = DrawerDetailsEmpty;

  render() {
    const {
      urlParam,
      renderTitle,
      renderSubTitle,
      renderContent,
      renderDetails,
      renderFooter,
    } = this.props;

    return (
      <Drawer
        width={690}
        urlParam={urlParam}
        onRequestClose={this.props.onRequestClose}
      >
        <Container horizontal className={cls.drawerDetailsHeader}>
          <Actions>{renderTitle && renderTitle()}</Actions>
          <Actions className={cls.drawerDetailsActions}>
            {canManageGrants() && (
              <Icon
                name="trash"
                tip="Remove"
                placeTip="bottom"
                className={cls.icon}
                onClick={this.props.onDelete}
              />
            )}
            {this.props.onExport && (
              <Icon
                name="download"
                tip="Export"
                placeTip="bottom"
                className={cls.icon}
                onClick={this.props.onExport}
              />
            )}
            {canManageGrants() && (
              <Icon
                name="edit"
                tip="Edit"
                placeTip="bottom"
                className={cls.icon}
                onClick={this.props.onEdit}
              />
            )}
            <Icon
              name="close-large"
              className={cls.icon}
              onClick={this.props.onRequestClose}
            />
          </Actions>
        </Container>

        {renderSubTitle && renderSubTitle()}
        {renderContent && renderContent()}
        {renderDetails && (
          <div>
            <DrawerDetails.SectionTitle text="Details" />
            {renderDetails()}
          </div>
        )}
        {renderFooter && (
          <div>
            <Divider />
            {renderFooter()}
          </div>
        )}
      </Drawer>
    );
  }
}
