import React from 'react';
import PropTypes from 'prop-types';
import propOr from 'ramda/src/propOr';
import { connect } from 'react-redux';
import {
  Field,
  formValueSelector,
  getFormSyncErrors,
  arrayRemove,
} from 'redux-form';
import { confirm } from 'src/components/ConfirmModal/ConfirmModal';
import FlexColumn from 'src/components/FlexColumn/FlexColumn';
import FlexRow from 'src/components/FlexRow/FlexRow';
import {
  TextField,
  SelectField,
  FormField,
  DatePicker,
  Divider,
  Tooltip,
  Button,
  PlainListItem,
  ReduxFormField,
  Actions,
  Container,
} from 'src/components/IMUI';
import { getOrgText } from 'src/services/DictionaryService';
import { getCountryNameByCode } from 'src/utils/countries';
import { isAfter } from 'src/utils/date';
import { capitalize as capitalizeString } from 'src/utils/string';
import createValidator from 'src/utils/validation';
import { Icon } from 'im/ui/Icon';
import AddEditContact, { validateContacts } from './AddEditContact';
import GrantLocationDialog from './GrantLocationDialog';
import cls from './GrantBase.module.css';
import { canManageGrants } from 'src/userStorage';

export function validateGrant(values, props = {}) {
  const not = (fn) => (v) => !fn(v);
  const isDefined = (v) => Boolean(v);
  const longerThan = (n) => (v) => v.length > n;
  const grantLocationErrors = [];
  values?.locations?.forEach((location, index) => {
    grantLocationErrors[index] = createValidator({
      country: [[not(isDefined), () => 'Country has to be selected']],
      city: [[not(isDefined), () => 'City is required']],
    })(location);
  });
  return {
    ...createValidator({
      name: [
        [not(isDefined), () => 'Name is required'],
        [not(longerThan(1)), () => 'Name is too short'],
      ],
      recipient_type: [[not(isDefined), () => 'Recipient type is required']],
      start_date: [
        [
          (v) =>
            not(isDefined)(v) &&
            !(props.isEditMode && !props.fundingsArray?.length),
          () => 'Start date is required',
        ],
      ],
      end_date: [
        [
          (v) =>
            not(isDefined)(v) &&
            !(props.isEditMode && !props.fundingsArray?.length),
          () => 'End date is required',
        ],
        [
          (v) =>
            isAfter(values.start_date, v, true) &&
            !(props.isEditMode && !props.fundingsArray?.length),
          () => 'End date must be later than start date',
        ],
      ],
    })(values),
    ...validateContacts(values),
    locations: grantLocationErrors,
  };
}

@connect(
  (state, ownProps) => ({
    locationsForm: formValueSelector(ownProps.formName)(state, 'locations'),
    contacts: formValueSelector(ownProps.formName)(state, 'contacts'),
    formErrors: getFormSyncErrors(ownProps.formName)(state),
  }),
  { locationsRemove: arrayRemove }
)
export default class GrantBase extends React.PureComponent {
  static propTypes = {
    regions: PropTypes.array,
    fundingTypes: PropTypes.array,
    statuses: PropTypes.array,
    isEditMode: PropTypes.bool,
    onFieldValueChange: PropTypes.func,
    onFundingTypeChange: PropTypes.func,
    onRecipientTypeChange: PropTypes.func,
    onContactAdd: PropTypes.func,
    onContactDelete: PropTypes.func,
    onLocationAdd: PropTypes.func,
    onLocationEdit: PropTypes.func,
    onLocationDelete: PropTypes.func,

    contacts: PropTypes.array,
    formName: PropTypes.string,
    formErrors: PropTypes.object,
    locationsForm: PropTypes.array,
    fundingsArray: PropTypes.array,
    locationsRemove: PropTypes.func,
  };

  static defaultProps = {
    locationsForm: [],
    contacts: [],
    fundingTypes: [],
    statuses: [],
    fundingsArray: [],
  };
  state = { isNewLocationDialogOpen: false, locationEditIndex: null };

  onChangeFundingRelatedInput = (
    evOrString,
    newValue,
    previousValue,
    { text, changeCallback }
  ) => {
    evOrString?.preventDefault?.();
    if (newValue === previousValue) {
      return null;
    }
    if (
      this.props.fundingsArray.length < 1 ||
      (this.props.fundingsArray.length === 1 &&
        !this.props.fundingsArray[0].grantee_id)
    )
      return changeCallback();
    return confirm(...text, true)
      .then(changeCallback)
      .catch(() => this.forceUpdate());
  };

  onChangeRecipientType = (ev, newValue, previousValue) => {
    if (!canManageGrants()) return;
    this.onChangeFundingRelatedInput(ev, newValue, previousValue, {
      text: [
        'Change Recipient type',
        `Are you sure you want to change the recipient type? This will result in removing all added fundings`,
      ],
      changeCallback: () => {
        this.props.onFieldValueChange({
          fieldName: 'recipient_type',
          value: newValue,
        });
        return (
          this.props.onRecipientTypeChange &&
          this.props.onRecipientTypeChange(newValue)
        );
      },
    });
  };

  onChangeFundingType = (ev, newValue, previousValue) => {
    if (!canManageGrants()) return;
    this.onChangeFundingRelatedInput(ev, newValue, previousValue, {
      text: [
        'Change Funding type',
        `Are you sure you want to change the funding type? If you change the type of funding it will change for all fundings in this ${getOrgText(
          'grant'
        )}`,
      ],
      changeCallback: () => {
        this.props.onFieldValueChange({
          fieldName: 'funding_type',
          value: newValue,
        });
        return (
          this.props.onFundingTypeChange &&
          this.props.onFundingTypeChange(newValue)
        );
      },
    });
  };

  onRequestAddLocation = () => {
    this.setState({ isNewLocationDialogOpen: true, locationEditIndex: null });
  };
  onRequestCancelLocation = () => {
    this.setState({ isNewLocationDialogOpen: false });
  };
  onRemoveLocation = (index) => {
    if (this.props.locationsForm?.[index]?.id) {
      this.props.onLocationDelete(this.props.locationsForm[index].id);
    }
    this.props.locationsRemove(this.props.formName, 'locations', index);
  };
  onEditLocation = (index) => {
    this.setState({ locationEditIndex: index, isNewLocationDialogOpen: true });
  };

  render() {
    const locations = this.props.locationsForm.slice(0);
    if (this.state.isNewLocationDialogOpen) {
      locations.pop();
    }
    const locationsWithCountries =
      locations?.map(
        ({ country: countryCode, region_id: regionId, ...rest }, index) => ({
          ...rest,
          index,
          countryName: getCountryNameByCode(countryCode) || null,
          regionName: propOr(
            '',
            'name',
            this.props.regions.find(({ id }) => id === regionId)
          ),
        })
      ) ?? [];

    return (
      <div>
        {this.renderFundingDetails()}

        <div data-qa="Grants/Add:GrantBase-form">
          <FormField anchorScrollName="name">
            <Field
              component={TextField}
              label={getOrgText('Grant Name *')}
              hintText="Type in..."
              fullWidth
              name="name"
            />
          </FormField>
          <FormField>
            <Field
              component={TextField}
              label={getOrgText('Grant Number')}
              hintText="Type in..."
              fullWidth
              name="number"
            />
          </FormField>
          <FormField>
            <Field
              component={TextField}
              rows={2}
              multiLine
              label="Description"
              hintText="Type in..."
              fullWidth
              name="description"
            />
          </FormField>
          <FormField>
            <FlexRow>
              <FlexColumn size={1 / 2} style={{ paddingRight: 10 }}>
                <Field
                  component={SelectField}
                  label="Status"
                  hintText="Choose"
                  fullWidth
                  name="status"
                >
                  <SelectField.Item value={null} primaryText="-" />
                  {this.props.statuses.map((f) => (
                    <SelectField.Item
                      key={f}
                      value={f.toLowerCase()}
                      primaryText={capitalizeString(f)}
                    />
                  ))}
                </Field>
              </FlexColumn>
            </FlexRow>
          </FormField>
        </div>

        <Divider className={cls.marginBottom} />

        <div>
          <Container style={{ minHeight: 40 }} horizontal>
            <Actions>
              <h3>
                {getOrgText('Grant Destination Countries')}&emsp;
                <Icon
                  name="question-filled"
                  className={cls.spaceLeft}
                  style={{ fontSize: '0.6em', color: '#e3e9ee' }}
                  tip={getOrgText('Add countries where the grant operates')}
                />
              </h3>
            </Actions>
            <Button
              size="l"
              label={
                this.props.locationsForm.length
                  ? 'ADD ANOTHER LOCATION'
                  : 'ADD NEW LOCATION'
              }
              onClick={this.onRequestAddLocation}
            />
          </Container>
          <div>
            <br />
            {locationsWithCountries.map(
              ({ countryName, city, state, regionName, index }) => (
                <PlainListItem
                  key={index}
                  nowrap
                  className={cls.listItem}
                  title={
                    <span>
                      {city || <span className={cls.placeholder}>No city</span>}
                      {state ? (
                        <span className={cls.textNormal}>
                          &nbsp;-&nbsp;{state}
                        </span>
                      ) : null}
                    </span>
                  }
                >
                  <FlexRow style={{ flexWrap: 'nowrap', alignItems: 'center' }}>
                    <FlexColumn>
                      {' '}
                      <strong>{countryName || regionName}</strong>{' '}
                    </FlexColumn>
                    <FlexColumn
                      style={{ minWidth: 65, width: 65, paddingRight: 0 }}
                    >
                      <Actions>
                        {canManageGrants() && (
                          <Icon
                            name="edit"
                            tip="Edit"
                            onClick={() => this.onEditLocation(index)}
                          />
                        )}
                        {canManageGrants() && (
                          <Icon
                            name="trash"
                            tip="Remove"
                            onClick={() => this.onRemoveLocation(index)}
                          />
                        )}
                      </Actions>
                    </FlexColumn>
                  </FlexRow>
                </PlainListItem>
              )
            )}
          </div>
        </div>

        <GrantLocationDialog
          formName={`${this.props.formName}`}
          locationEditIndex={this.state.locationEditIndex}
          isOpen={this.state.isNewLocationDialogOpen}
          onClose={this.onRequestCancelLocation}
          onLocationAdd={this.props.onLocationAdd}
          onLocationEdit={this.props.onLocationEdit}
        />

        <FormField>
          <AddEditContact
            formName={this.props.formName}
            contacts={this.props.contacts}
            formErrors={this.props.formErrors}
            isEdit={this.props.isEditMode}
            onAddContact={this.props.onContactAdd}
            onDeleteContact={this.props.onContactDelete}
          />
        </FormField>

        <Tooltip />
      </div>
    );
  }
  renderFundingDetails() {
    if (!this.props.isEditMode) return null;
    if (!this.props.fundingsArray?.some((a) => a.start_date)) return null;
    return (
      <>
        <h3 className={cls.marginBottom}>
          <FlexRow style={{ alignItems: 'center' }}>
            {getOrgText('Funding Details')} ({this.props.fundingsArray.length}{' '}
            fundings)
            <Icon
              name="currency"
              className={cls.spaceLeft}
              style={{ fontSize: '0.6em', color: '#e3e9ee' }}
            />
          </FlexRow>
        </h3>

        {!this.props.isEditMode && (
          <FlexRow style={{ flexWrap: 'nowrap' }}>
            <FlexColumn neighbourSpacing>
              <FormField>
                <Field
                  component={SelectField}
                  onChange={this.onChangeRecipientType}
                  label="Recipient Type*"
                  hintText="Choose"
                  fullWidth
                  name="recipient_type"
                  disabled={this.props.isEditMode}
                >
                  <SelectField.Item
                    primaryText="Organization"
                    value="organization"
                  />
                  <SelectField.Item
                    primaryText="Individual"
                    value="individual"
                  />
                </Field>
              </FormField>
            </FlexColumn>
          </FlexRow>
        )}

        <FlexRow style={{ flexWrap: 'nowrap' }}>
          <FlexColumn neighbourSpacing size={1 / 2}>
            <FormField>
              <Field
                component={SelectField}
                onChange={this.onChangeFundingType}
                label="Type of Funding"
                hintText="Choose"
                fullWidth
                name="funding_type"
              >
                <SelectField.Item value={null} primaryText="-" />
                {this.props.fundingTypes.map((f) => (
                  <SelectField.Item key={f} value={f} primaryText={f} />
                ))}
              </Field>
            </FormField>
          </FlexColumn>
          <FlexColumn neighbourSpacing size={1 / 4}>
            <FormField anchorScrollName="start_date">
              <ReduxFormField
                type="date"
                name="start_date"
                component={DatePicker}
                label="Start Date *"
                hintText="Choose"
                fullWidth
              />
            </FormField>
          </FlexColumn>
          <FlexColumn neighbourSpacing size={1 / 4}>
            <FormField anchorScrollName="end_date">
              <ReduxFormField
                type="date"
                name="end_date"
                component={DatePicker}
                label="End Date *"
                hintText="Choose"
                fullWidth
              />
            </FormField>
          </FlexColumn>
        </FlexRow>

        <Divider className={cls.marginBottom} />
      </>
    );
  }
}
