import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import React from 'react';
import { connect } from 'react-redux';

import { changeQuestionI18n } from 'src/actionCreators/surveyActionCreators';
import { SelectField, Toggle } from 'src/components/IMUI';
import CURRENCIES from 'src/data/currencies.json';
import {
  FINANCIAL,
  MULTIPLE_CHOICE,
  NUMERICAL,
  SHORT_TEXT,
  SINGLE_CHOICE,
} from 'src/data/questionTypes';

import BaseQuestionProperties from './BaseQuestionProperties';
import PropertyBox from './PropertyBox';
import RestrictAnswersSetting from './RestrictAnswersSetting';
import { SingleMatrixQuestionPropertiesColumns } from './SingleMatrixQuestionPropertiesColumns';
import { SingleMatrixQuestionPropertiesRows } from './SingleMatrixQuestionPropertiesRows';

const QuestionTypes = [
  [SHORT_TEXT, 'Short text'],
  [NUMERICAL, 'Numerical'],
  [FINANCIAL, 'Financial'],
  [SINGLE_CHOICE, 'Single Choice'],
  // TODO SEB-188: Serializing object_value resposne
  // [MULTIPLE_CHOICE, 'Multiple Choice'],
];

const QuestionReachTypes = [
  ['unit', 'Unit'],
  ['year', 'Year'],
  ['percent', 'Percent'],
];

@connect(pick(['survey']), { changeQuestionI18n })
export default class SingleMatrixQuestionProperties extends React.PureComponent {
  static propTypes = {
    survey: PropTypes.object,
    question: PropTypes.object,
    completeness: PropTypes.object,
    errors: PropTypes.object,
    tagsWithTagGroups: PropTypes.array,
    onChangeQuestionProperty: PropTypes.func,
    changeQuestionI18n: PropTypes.func,
    readOnly: PropTypes.bool,
    isAnswerMode: PropTypes.bool,
  };

  static defaultProps = {
    errors: {},
    question: {},
    onChangeQuestionProperty: () => undefined,
  };

  static validate(question = {}) {
    const errors = {};
    if (!question?.matrixValues?.length) {
      errors.noRows = 'Must have at least one row';
    }
    question?.matrixValues?.forEach((matrixRow, i) => {
      if (!matrixRow?.title || !matrixRow?.title.trim()) {
        if (!errors.matrixValues) errors.matrixValues = [];
        errors.matrixValues[i] = 'Row title is required';
      }
    });

    if (question.settings.singleMatrixColumns) {
      if (!question.settings.columns?.length) {
        errors.noColumns = 'Must have at least one column';
      }

      question.settings.columns?.forEach((column, index) => {
        if (!column.title || !column.title.trim()) {
          errors.columns ||= [];
          errors.columns[index] = {
            ...errors.columns[index],
            title: 'Column title is required',
          };
        }
      });
    }

    return errors;
  }

  componentDidMount() {
    const { question } = this.props;
    if (!question.matrixQuestionType) {
      this.props.onChangeQuestionProperty(
        question.id,
        'matrixQuestionType',
        SHORT_TEXT,
        { silent: true }
      );
    }
  }

  onQuestionTypeChange = ({ value }) => {
    if (value === this.props.question.matrixQuestionType) return;
    switch (value) {
      case NUMERICAL:
      case FINANCIAL:
        this.props.onChangeQuestionProperty(
          this.props.question.id,
          'matrixReachType',
          'unit'
        );
        if (this.props.question.matrixQuestionType === SHORT_TEXT) {
          this.onClearColumnValues();
        }
        break;
      case SINGLE_CHOICE:
        if (!this.props.question.settings.singleMatrixColumns) {
          this.onClearColumns();
        }

        this.props.onChangeQuestionProperty(
          this.props.question.id,
          ['settings', 'singleMatrixColumns'],
          true,
          { silent: true }
        );

        this.props.onChangeQuestionProperty(
          this.props.question.id,
          'matrixReachType',
          undefined
        );

        break;
      case MULTIPLE_CHOICE:
        if (!this.props.question.settings.singleMatrixColumns) {
          this.onClearColumns();
        }

        this.props.onChangeQuestionProperty(
          this.props.question.id,
          ['settings', 'singleMatrixColumns'],
          true,
          { silent: true }
        );

        this.props.onChangeQuestionProperty(
          this.props.question.id,
          'matrixReachType',
          undefined
        );

        break;
      default:
        this.props.onChangeQuestionProperty(
          this.props.question.id,
          'matrixReachType',
          undefined
        );
        this.onClearColumnValues();
        break;
    }

    this.props.onChangeQuestionProperty(
      this.props.question.id,
      'matrixQuestionType',
      value
    );

    if (value !== FINANCIAL) {
      this.props.onChangeQuestionProperty(
        this.props.question.id,
        'currency',
        undefined
      );
    }
  };

  onClearColumnValues = () => {
    this.props.onChangeQuestionProperty(
      this.props.question.id,
      ['settings', 'columns'],
      this.props.question.settings.columns?.map((column) => ({
        ...column,
        value: '',
      }))
    );
  };

  onClearColumns = () => {
    this.props.onChangeQuestionProperty(
      this.props.question.id,
      ['settings', 'columns'],
      undefined
    );
  };

  onQuestionReachTypeChange = ({ value }) => {
    this.props.onChangeQuestionProperty(
      this.props.question.id,
      'matrixReachType',
      value
    );
  };

  onCurrencyChange = ({ value }) => {
    this.props.onChangeQuestionProperty(
      this.props.question.id,
      'currency',
      value
    );
  };

  handleLimitedOptionsToggle = (ev) => {
    this.props.onChangeQuestionProperty(
      this.props.question.id,
      ['settings', 'singleMatrixColumns'],
      ev?.target.checked,
      { silent: true }
    );

    this.onClearColumnValues();

    if (!ev?.target.checked) {
      this.onClearColumns();
    }
  };

  handleUpdateI18n = (values, path, altPath) => {
    if (typeof values === 'string') {
      this.props.changeQuestionI18n?.(
        this.props.question,
        values,
        path,
        altPath
      );
    } else {
      this.props.survey.languages.forEach((langCode) => {
        this.props.changeQuestionI18n?.(
          this.props.question,
          values[langCode],
          path,
          altPath,
          langCode
        );
      });
    }
  };

  updateMatrixValues = (matrixValues) => {
    const { question } = this.props;
    this.props.onChangeQuestionProperty(
      question.id,
      'matrixValues',
      matrixValues
    );
  };

  updateColumns = (columns) => {
    const { question } = this.props;
    this.props.onChangeQuestionProperty(
      question.id,
      ['settings', 'columns'],
      columns
    );
  };

  renderSettings = () => {
    if (!this.props.question.matrixValues?.length) return null;
    return (
      <RestrictAnswersSetting
        key={this.props.question.matrixValues.length}
        id={this.props.question.id}
        settings={this.props.question.settings || {}}
        options={this.props.question.matrixValues}
        onChangeQuestionProperty={this.props.onChangeQuestionProperty}
        label="Require a number of answered rows"
      />
    );
  };

  render() {
    const { question, survey, errors, completeness } = this.props;

    return (
      <BaseQuestionProperties
        {...this.props}
        renderSettings={this.renderSettings}
        hasRequired={false}
      >
        <PropertyBox>
          <SelectField
            fullWidth
            flatDark
            hintText="Choose"
            label="Answer type"
            value={question.matrixQuestionType || SHORT_TEXT}
            onChange={this.onQuestionTypeChange}
            error={errors.matrixQuestionType}
          >
            <SelectField.Item disabled value="" primaryText="Question type" />
            {QuestionTypes.map((questionType) => (
              <SelectField.Item
                key={questionType[0]}
                value={questionType[0]}
                primaryText={questionType[1]}
              />
            ))}
          </SelectField>

          {question.matrixQuestionType === NUMERICAL && (
            <SelectField
              fullWidth
              flatDark
              hintText="Choose"
              label="Tag reach type"
              value={question.matrixReachType}
              onChange={this.onQuestionReachTypeChange}
              error={errors.matrixReachType}
            >
              <SelectField.Item
                disabled
                value=""
                primaryText="Tag reach type"
              />
              {QuestionReachTypes.map((questionReachType) => (
                <SelectField.Item
                  key={questionReachType[0]}
                  value={questionReachType[0]}
                  primaryText={questionReachType[1]}
                />
              ))}
            </SelectField>
          )}

          {question.matrixQuestionType === FINANCIAL && (
            <SelectField
              fullWidth
              flatDark
              hintText="Choose"
              label="Select currency"
              value={question.currency}
              onChange={this.onCurrencyChange}
              error={errors.currency}
            >
              <SelectField.Item
                disabled
                value=""
                primaryText="Select currency"
              />
              {CURRENCIES.map((currencyModel) => (
                <SelectField.Item
                  key={currencyModel.code}
                  value={currencyModel.code}
                  primaryText={currencyModel.code}
                />
              ))}
            </SelectField>
          )}

          {question.matrixQuestionType !== SINGLE_CHOICE &&
            question.matrixQuestionType !== MULTIPLE_CHOICE && (
              <Toggle
                mini
                label={'Multi columns'}
                labelPosition="right"
                toggled={this.props.question?.settings?.singleMatrixColumns}
                onToggle={this.handleLimitedOptionsToggle}
              />
            )}

          <SingleMatrixQuestionPropertiesRows
            matrixValues={question.matrixValues || []}
            question={question}
            survey={survey}
            errors={errors}
            completeness={completeness}
            tagsWithTagGroups={this.props.tagsWithTagGroups}
            onUpdateMatrixValues={this.updateMatrixValues}
            onUpdateI18n={this.handleUpdateI18n}
            readOnly={this.props.readOnly}
            isAnswerMode={this.props.isAnswerMode}
          />

          {question.settings.singleMatrixColumns && (
            <SingleMatrixQuestionPropertiesColumns
              columns={question.settings.columns}
              question={question}
              survey={survey}
              errors={errors}
              completeness={completeness}
              onUpdateColumns={this.updateColumns}
              onUpdateI18n={this.handleUpdateI18n}
              readOnly={this.props.readOnly}
            />
          )}
        </PropertyBox>
      </BaseQuestionProperties>
    );
  }
}
